// framework
import { call, put } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IPaymentViewModel } from "../../models/paymentViewModel";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as LogHelper from "../../../../../common/LogHelper";
import * as shellHelper from "../../../../../common/shell/shellHelper";

export default function* createPaymentRequested(action: Actions.IAction<Models.IRootViewModel>) {
    let vm: Models.IRootViewModel = action.payload;
    const params: IPaymentViewModel = vm.paymentViewModel!;

    try {
        yield put(shellHelper.createSetBusyAction());

        const result: CallHelper.CallResult<Client.CreateCompanyFinanceFnListCreditCardPaymentResponseDto> = yield call(async () => {
            const client = await ClientFactory.createCompanyFinanceClient();
            const request = new Client.CreateCompanyFinanceFnListCreditCardPaymentRequestDto({
                allocatedFinancialNotifications: vm.selectedFinancialNotifications!.financialNotifications!.map(
                    (fn) => new Client.AllocatedFinancialNotificationDetailsDto({ financialNotificationId: fn.financialNotificationLink.id, versionNumber: fn.versionNumber })
                ),
                comments: params.comments,
                secureTransactionToken: params.secureTransactionToken,
            });
            return await CallHelper.call(() => client.createCompanyFinanceFnListCreditCardPayment(request));
        });

        if (result.IsSuccess) {
            // success
            const r = result.Response!;
            if (r.statusMessages?.isSuccess) {
                const fnListResponse: Client.GetCompanyFinanceFnListResponseDto = yield call(async () => {
                    const client = await ClientFactory.createCompanyFinanceClient();
                    return await CallHelper.simpleCall(() => client.getCompanyFinanceFnList(new Client.GetCompanyFinanceFnListRequestDto()));
                });
                vm = vm.refreshList(fnListResponse);
            }
            vm = vm.setStatusMessages(r.statusMessages);
            yield put(Actions.actionFactory.sagaCompleted(vm));
        } else if (result.IsConflict) {
            // conflict
            vm = vm.refreshConflict();
            yield put(Actions.actionFactory.sagaCompleted(vm));
        } else {
            // all other errors
            result.ShowToastNotification();
            vm = vm.setStatusMessages(undefined);
            yield put(Actions.actionFactory.sagaCompleted(vm));
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
