// framework
import { useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
// views
import UserListView from "./UserListView";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";

interface IUserListControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function UserListController(props: IUserListControllerProps) {
    const vm = props.rootViewModel;

    // on mount
    useEffect(() => {
        props.initialise();
        return function () {
            props.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BodyLayout
            title="My Users"
            selectedLhsNavItem={Level1ItemTypeEnum.JointAuthority_UserManagement_ViewMyUserList}
            onReloadRequested={() => {
                props.initialise();
            }}
        >
            <UserListView users={vm.users} />
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.JointAuthority_UserManagement_UserList }), Actions.actionFactory)(UserListController);
