import * as Client from "../../../../api/Client";
import { clone } from "lodash";

export interface IRootViewModel {
    draftApplications: ListItems;

    refreshList(response: Client.GetCompanyOpggsDraftApplicationListResponseDto): IRootViewModel;
}

export type ListItems = Array<Client.GetCompanyOpggsDraftApplicationListItemDto> | undefined;

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.draftApplications = undefined;
    }

    public draftApplications: ListItems;

    public refreshList(response: Client.GetCompanyOpggsDraftApplicationListResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.draftApplications = response.draftApplications;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
