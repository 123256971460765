// views
import CommonNavBar from "../../../../common/CommonNavBarView";
// common
import * as geoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";

export enum TabsEnum {
    Details,
    DataMonitoring,
    Reports,
    Attachments,
}

export interface IProps {
    wellId: number;
    boreholeId: number;
    activeNavItem: TabsEnum;
}

export default function NavBarView(props: IProps): JSX.Element {
    return (
        <CommonNavBar
            activeNavItemKey={props.activeNavItem}
            navItems={[
                {
                    key: TabsEnum.Details,
                    title: "Details",
                    url: geoscienceAustraliaRouteHelper.boreholeDetails(props.wellId, props.boreholeId),
                },
                {
                    key: TabsEnum.DataMonitoring,
                    title: "Data Monitoring",
                    url: geoscienceAustraliaRouteHelper.boreholeDataMonitoring(props.wellId, props.boreholeId),
                },
                {
                    key: TabsEnum.Reports,
                    title: "Reports",
                    url: geoscienceAustraliaRouteHelper.boreholeReports(props.wellId, props.boreholeId),
                },
                {
                    key: TabsEnum.Attachments,
                    title: "Attachments",
                    url: geoscienceAustraliaRouteHelper.boreholeAttachments(props.wellId, props.boreholeId),
                },
            ]}
        />
    );
}
