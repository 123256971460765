// framework
import { useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
// views
import OeiApplicationListView from "./OeiApplicationListView";

interface IOeiApplicationListControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IOeiApplicationListControllerProps) {
    const vm = props.rootViewModel;

    // on mount
    useEffect(() => {
        props.initialiseRequested();
        return function () {
            props.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BodyLayout
            title="In Progress OEI Act Applications"
            selectedLhsNavItem={Level1ItemTypeEnum.Company_Oei_ApplicationList}
            onReloadRequested={() => {
                props.initialiseRequested();
            }}
        >
            <OeiApplicationListView vm={vm.applications} />
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_Oei_ApplicationList }), Actions.actionFactory)(Controller);
