// redux
import { call, put, select } from "redux-saga/effects";
import { IDraftApplicationDetailsReduxState } from "../../../../../draftApplicationDetails/draftApplicationDetailsReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";
// common
import * as LogHelper from "../../../../../../../common/LogHelper";
import * as shellHelper from "../../../../../../../common/shell/shellHelper";
// calls
import { getCompanyOpggsDraftApplicationDetailsTitleholderForeignInvestment, saveCompanyOpggsDraftApplicationDetailsTitleholderForeignInvestment } from "./calls";

const getRootViewModel = (state: IDraftApplicationDetailsReduxState) => state.Company_Opggs_DraftApplicationDetails_Controls_TitleholderForeignInvestment;

export default function* save() {
    let vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        // save
        const saveResult: CallHelper.CallResult<Client.SaveCompanyOpggsDraftApplicationDetailsTitleholderForeignInvestmentResponseDto> = yield call(async () => {
            return await saveCompanyOpggsDraftApplicationDetailsTitleholderForeignInvestment(vm);
        });

        if (saveResult.IsSuccess) {
            // success - refresh
            const r = saveResult.Response!;
            vm = vm.refreshSaveStatusMessages(r.statusMessages);
            if (r.statusMessages.isSuccess) {
                const getResponse: Client.GetCompanyOpggsDraftApplicationDetailsTitleholderForeignInvestmentResponseDto = yield call(async () => {
                    return await getCompanyOpggsDraftApplicationDetailsTitleholderForeignInvestment(vm.id!);
                });
                vm = vm.refreshDetails(vm.id!, getResponse);
                vm = vm.onSaved();
            }
            yield put(Actions.actionFactory.sagaCompleted(vm));
        } else if (saveResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            saveResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
