import * as Models from "../models/models";

// -------
// Actions
// -------

export enum actions {
    // lifecycle events
    clearRequested = "company.oei.invitationToApplyList.clearRequested",
    initialiseRequested = "company.oei.invitationToApplyList.initialiseRequested",
    clearDraftApplicationDataRequested = "company.oei.invitationToApplyList.clearDraftApplicationDataRequested",
    createDraftApplicationRequested = "company.title.invitationToApplyList.createDraftApplicationRequested",
    // user input
    viewChanged = "company.oei.invitationToApplyList.viewChanged",
    // saga
    sagaCompleted = "company.oei.invitationToApplyList.sagaCompleted",
}

// --------
// Payloads
// --------

export interface IAction<T> {
    type: actions;
    payload: T;
}

// ---------
// Factories
// ---------

export interface IActionFactory {
    clearRequested(): IAction<undefined>;
    initialiseRequested(): IAction<undefined>;
    clearDraftApplicationDataRequested(): IAction<undefined>;
    createDraftApplicationRequested(): IAction<undefined>;
    // user input
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    // saga
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    initialiseRequested: function (): IAction<undefined> {
        return {
            type: actions.initialiseRequested,
            payload: undefined,
        };
    },
    clearDraftApplicationDataRequested: function (): IAction<undefined> {
        return {
            type: actions.clearDraftApplicationDataRequested,
            payload: undefined,
        };
    },
    createDraftApplicationRequested: function (): IAction<undefined> {
        return {
            type: actions.createDraftApplicationRequested,
            payload: undefined,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
