// api
import * as Client from "../../../../../../../api/Client";
// common
import * as Forms from "../../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../../common/GlobalHelpers";
// views
import DataMonitoringItemsCategoryView from "./DataMonitoringItemsCategoryView";

interface IProps {
    activityType?: Client.AuthorisationActivityTypeEnum;
    category: Client.GetGeoscienceAustraliaAuthorisationsAuthorisationDetailsResponseCategoryDetailsDto;
    canViewBoreholes: boolean;
    canViewAcquisitions: boolean;
    canViewProjects: boolean;
    canViewDataSubmissions: boolean;
}

export default function SamplingCategoryView(props: IProps): JSX.Element {
    const { activityType, category, canViewBoreholes, canViewAcquisitions, canViewProjects, canViewDataSubmissions } = props;

    return (
        <>
            <Forms.Row>
                <Forms.QuarterColumn>
                    <Forms.ReadonlyField
                        id="sampleToBeReturnedField"
                        label="Sample to be returned"
                        value={category.samplingDetails?.sampleToBeReturned === undefined ? "Unknown" : category.samplingDetails?.sampleToBeReturned ? "Yes" : "No"}
                    />
                </Forms.QuarterColumn>
                <Forms.QuarterColumn>
                    <Forms.ReadonlyField id="reportingDueMonthsField" label="Reporting Due Months" value={category.samplingDetails?.reportingDueMonths?.toString()} />
                </Forms.QuarterColumn>
                <Forms.QuarterColumn>
                    <Forms.ReadonlyField id="reportingDueDateField" label="Reporting Due Date" value={GlobalHelpers.toNoptaDateString(category.samplingDetails?.reportingDueDate)} />
                </Forms.QuarterColumn>
                <Forms.QuarterColumn>
                    <Forms.ReadonlyField id="dataMonitoringItemTypeField" label="Data Monitoring Item Type" value={category.samplingDetails?.dataMonitoringItemTypeName} />
                </Forms.QuarterColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyTextArea id="sampleDepthField" label="Sample Depths" value={category.samplingDetails?.sampleDepth} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyTextArea
                        id="analysisTypeField"
                        label="Analysis Type(s)"
                        value={category.samplingDetails?.analysisTypeNames.length === 0 ? undefined : category.samplingDetails?.analysisTypeNames.join(", ")}
                    />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyTextArea
                        id="sampleTypeField"
                        label="Sample Type(s)"
                        value={category.samplingDetails?.sampleTypeNames.length === 0 ? undefined : category.samplingDetails?.sampleTypeNames.join(", ")}
                    />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField id="repositoryField" label="Repository" value={category.samplingDetails?.repositoryTypeName} />
                </Forms.HalfColumn>
            </Forms.Row>
            <DataMonitoringItemsCategoryView
                activityType={activityType}
                category={category}
                canViewBoreholes={canViewBoreholes}
                canViewAcquisitions={canViewAcquisitions}
                canViewProjects={canViewProjects}
                canViewDataSubmissions={canViewDataSubmissions}
            />
        </>
    );
}
