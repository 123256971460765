// framework
import { useState } from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// api
import * as Client from "../../../../../../../api/Client";

export default function BlocksView(props: { blocks: Client.GetBlockDetailsItemDto[]; showLocationDetails: boolean }) {
    const initialGridState: State = {
        sort: [
            { field: "mapSheetCode", dir: "asc" },
            { field: "blockNumber", dir: "asc" },
        ],
        skip: 0,
        take: 20,
    };
    const [gridState, setGridState] = useState(initialGridState);

    return (
        <Grid
            pageable
            sortable
            resizable
            navigatable
            data={process(props.blocks, gridState)}
            {...gridState}
            onDataStateChange={(e) => {
                setGridState(e.dataState);
            }}
        >
            <GridColumn field="mapSheetCode" title="Map Sheet Code" width={200} />
            <GridColumn field="mapSheetName" title="Map Sheet Name" width={300} />
            <GridColumn field="blockNumber" title="Block Number" width={200} />
            <GridColumn field="isPartBlock" title="Part Block" width={125} />
            {props.showLocationDetails && <GridColumn field="locationName" title="Location Name" width={300} />}
        </Grid>
    );
}
