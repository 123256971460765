// framework
import { useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// api
import * as Client from "../../../../../../api/Client";
// other
import * as companyRouteHelper from "../../../../../companyRouteHelper";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";

export default function PendingFormRequestsView(props: { vm: Client.GetCompanyOpggsTitleDetailsLegislativeFormRequestDto[] }): React.ReactElement {
    const pendingRequests = props.vm;

    const initialGridState: State = {
        sort: [
            { field: "formTypeId", dir: "asc" },
            { field: "lodgedByCompanyName", dir: "asc" },
        ],
    };
    const [gridState, setGridState] = useState(initialGridState);

    // view
    return (
        <div className="card mb-2">
            <div className="card-body">
                <h3 className="card-title">Pending Form Requests</h3>
                <Grid
                    sortable
                    resizable
                    navigatable
                    data={process(pendingRequests, gridState)}
                    {...gridState}
                    onDataStateChange={(e) => {
                        setGridState(e.dataState);
                    }}
                >
                    <GridColumn
                        field="formTypeId"
                        title="Form"
                        width={150}
                        filter="text"
                        columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, pendingRequests)}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                <Link to={companyRouteHelper.legislativeFormsFormRequestDetails(props.dataItem.id, props.dataItem.formTypeId)}>{props.dataItem.formTypeId}</Link>
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn field="lodgedByCompanyName" title="Lodged By" width={500} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, pendingRequests)} />
                    <GridColumn field="formRequestState" title="Status" width={250} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, pendingRequests)} />
                    <GridColumn field="submittedDatetime" title="Submitted Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} filter="date" columnMenu={createGridColumnFilterMenu} />
                </Grid>
            </div>
        </div>
    );
}
