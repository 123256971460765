// redux
import * as Models from "../../../../company/oei/licenceDetails/models/models";
// common
import * as Forms from "../../../forms/BootstrapForms";
import * as GlobalHelpers from "../../../GlobalHelpers";
import LicenceSummaryRelatedLicencesField from "./LicenceSummaryRelatedLicencesField";

export default function LicenceSummaryView(props: { vm: Models.IRootViewModel }) {
    const vm = props.vm;
    const summary = vm.details?.summary;

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Licence Summary</h2>
                    <Forms.Row>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField label="Licence Number" id="licenceNumberField" value={summary?.licenceNumber} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField label="Licence Status" id="licenceStatusField" value={summary?.licenceStatusDescription} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField label="Licence Type" id="licenceTypeField" value={summary?.licenceTypeDescription} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField label="Legislation" id="legislationField" value={summary?.legislationDescription} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyTextArea label="Project Name" id="projectNameField" value={summary?.projectName} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField label="Declared Area" id="declaredAreaField" value={summary?.declaredAreaName} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <LicenceSummaryRelatedLicencesField label="Related Licences" relatedLicences={summary?.relatedLicences} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField label="Licence Holder" id="licenceHolderField" value={summary?.licenceHolderName} />
                        </Forms.HalfColumn>
                        <Forms.Row>
                            <Forms.FullColumn>
                                <h4>Key Dates</h4>
                            </Forms.FullColumn>
                        </Forms.Row>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField label="Granted Date" id="grantedDateField" value={GlobalHelpers.toNoptaDateString(summary?.grantDate)} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField label="Comes into Force Date" id="comesIntoForceDateField" value={GlobalHelpers.toNoptaDateString(summary?.comesIntoForceDate)} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField label="End Date" id="endDateField" value={GlobalHelpers.toNoptaDateString(summary?.endDate)} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField label="Licence Cease Date" id="ceaseDateField" value={GlobalHelpers.toNoptaDateString(summary?.ceaseDate)} />
                        </Forms.HalfColumn>
                    </Forms.Row>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Project Characteristics</h2>
                    <Forms.Row>
                        <Forms.HalfColumn>
                            <Forms.Label htmlFor="finalisedLicenceAreaField">
                                Licence Area km<sup>2</sup>
                            </Forms.Label>
                            <Forms.ReadonlyTextInput id="finalisedLicenceAreaField" value={GlobalHelpers.toNoptaNumberString(vm.details?.summary.finalisedArea, "n2")} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField
                                label="Generation Capacity within Licence Area (in GW)"
                                id="finalisedGenearationCapacityField"
                                value={GlobalHelpers.toNoptaNumberString(vm.details?.summary.finalisedGenerationCapacity, "n2")}
                            />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField label="Renewable Energy Resource" id="oeiRenewableEnergyResourceField" value={vm.details?.summary.renewableEnergyResourceDescription} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField label="Technology to be Investigated" id="oeiTechnologyField" value={vm.details?.summary.technologyToBeInvestigatedDescription} />
                        </Forms.HalfColumn>
                    </Forms.Row>
                </div>
            </div>
        </>
    );
}
