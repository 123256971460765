import { CreateReportRelatedActivityTypeEnum } from "../../../../../api/Client";
import * as Models from "../models/models";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "geoscienceAustralia.wellsBoreholes.reportCreate.initialiseRequested",
    createRequested = "geoscienceAustralia.wellsBoreholes.reportCreate.createRequested",
    viewChanged = "geoscienceAustralia.wellsBoreholes.reportCreate.viewChanged",
    clearRequested = "geoscienceAustralia.wellsBoreholes.reportCreate.clearRequested",
    // saga events
    sagaCompleted = "geoscienceAustralia.wellsBoreholes.reportCreate.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

export interface IInitialiseRequestedPayload {
    relatedActivityType?: CreateReportRelatedActivityTypeEnum | undefined;
    relatedActivityId?: number | undefined;
}

export interface ICreateRequestedPayload {
    createAnother?: boolean;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(relatedActivityType: CreateReportRelatedActivityTypeEnum | undefined, reportId: number): IAction<IInitialiseRequestedPayload>;
    createRequested(createAnother: boolean): IAction<ICreateRequestedPayload>;
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    clearRequested(): IAction<undefined>;
    // saga events
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (relatedActivityType: CreateReportRelatedActivityTypeEnum, relatedActivityId?: number | undefined): IAction<IInitialiseRequestedPayload> {
        return {
            type: actions.initialiseRequested,
            payload: { relatedActivityId: relatedActivityId, relatedActivityType: relatedActivityType },
        };
    },
    createRequested: function (createAnother: boolean): IAction<ICreateRequestedPayload> {
        return {
            type: actions.createRequested,
            payload: { createAnother: createAnother },
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
