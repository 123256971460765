// framework
import { useEffect, useState } from "react";
// kendo
import { DropDownList } from "@progress/kendo-react-dropdowns";
// redux
import * as Models from "../../models/models";
// api
import * as Client from "../../../../../../../api/Client";
// common
import { ValidationModel } from "../../../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../../../common/validation/ValidationMessageView";
import * as Forms from "../../../../../../../common/forms/BootstrapForms";

export default function EvaView(props: { vm: Models.IRootViewModel; v: ValidationModel; updateViewModel: (value: Client.GetCompanyOpggsDraftApplicationDetailsEvaTypeDto | undefined) => void }) {
    const vm = props.vm;
    const v = props.v;

    const [evaType, setEvaType] = useState<Client.GetCompanyOpggsDraftApplicationDetailsEvaTypeDto | undefined>(undefined);

    useEffect(() => {
        setEvaType(vm.evaType);
    }, [vm.evaType]);

    function onBlur() {
        props.updateViewModel(evaType);
    }

    return (
        <>
            <Forms.Group>
                <Forms.ReadonlyField label="Nominated Titleholder for Eligible Voluntary Action (Form 6)" id="nominatedTitleholderField" value={vm.nominatedTitleholderCompanyName} />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="evaTypeField">Application made under a valid EVA Nomination (under subsection 775B(2) of the OPGGS Act)</Forms.Label>
                <DropDownList
                    id="evaTypeField"
                    valid={v.applyValidityForKendo("Eva.EvaType")}
                    data={vm.availableEvaTypes}
                    value={evaType}
                    dataItemKey="type"
                    textField="name"
                    onChange={(e) => setEvaType(e.value)}
                    onBlur={onBlur}
                />
                <ValidationMessageView name="Eva.EvaType" validationModel={v} />
            </Forms.Group>
        </>
    );
}
