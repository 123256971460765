// framework
import { useEffect, useState } from "react";
// api
import { AssignmentGroupEnum, GetGeoscienceAustraliaFeedbackReferenceDataResponseAssignedUserDto } from "../../../../../api/Client";
// kendo
import KendoComboBox from "../../../../../common/kendo/KendoComboBox";
// common
import DirtyScope from "../../../../../common/shell/DirtyScopeController";
import * as Forms from "../../../../../common/forms/BootstrapForms";
import StatusMessagesAlertsView from "../../../../../common/alerts/StatusMessagesAlertsView";
import * as toastHelper from "../../../../../common/toastHelper";
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
import { ValidationVisibilityEnum } from "../../../../../common/validation/ValidationModel";
//redux
import * as Actions from "../../redux/actions";
// model
import { IFeedbackResponseViewModel, IRootViewModel } from "../../models/models";
import validator from "../../models/feedbackResponseValidator";

interface IProps {
    vm: IRootViewModel;
    actions: Actions.IActionFactory;
}

export default function FeedbackResponseEditView(props: IProps): JSX.Element {
    const { vm, actions } = props;

    const [state, setState] = useState<IFeedbackResponseViewModel>(vm.feedbackResponse);
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const v = validator(vm, validationVisibility);

    const assignmentGroups = vm.referenceData.assignmentGroups;
    const assignedUsers = vm.referenceData.assignedUsers;

    function updateViewModel() {
        actions.viewChanged(vm.onFeedbackResponseChanged(state));
    }

    function updateStateAndViewModel(state: IFeedbackResponseViewModel) {
        setState(state);
        actions.viewChanged(vm.onFeedbackResponseChanged(state));
    }

    // assigned user cascading reference data
    const [filteredAssignedUsers, setFilteredAssignedUsers] = useState<GetGeoscienceAustraliaFeedbackReferenceDataResponseAssignedUserDto[]>([]);
    useEffect(() => {
        setFilteredAssignedUsers(
            vm.feedbackResponse.assignmentGroup ? assignedUsers.filter((u) => u.assignmentGroup === vm.feedbackResponse.assignmentGroup!.assignmentGroup && u.isActive && u.canManage) : []
        );
    }, [assignedUsers, vm.feedbackResponse.assignmentGroup]);

    const onCancel = () => {
        actions.initialiseRequested(vm.id!);
    };

    const onSave = () => {
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.None);
        actions.saveRequested(vm);
    };

    return (
        <>
            <DirtyScope scopeName="geoscienceAustralia/feedback/details" isDirty={vm.isDirty}>
                <div className="card mb-2">
                    <div className="card-body">
                        <h2 className="card-title">Feedback Response</h2>
                        <StatusMessagesAlertsView statusMessages={vm.feedbackResponse.statusMessages} />
                        <Forms.Group>
                            <Forms.Row>
                                <Forms.ThirdColumn>
                                    <Forms.ReadonlyField id="statusField" label="Status" value={state.status} />
                                </Forms.ThirdColumn>
                                <Forms.ThirdColumn>
                                    <Forms.Label htmlFor="assignmentGroupField">Assignment Group</Forms.Label>
                                    <KendoComboBox
                                        id="assignmentGroupField"
                                        value={state.assignmentGroup}
                                        data={assignmentGroups}
                                        keyField="assignmentGroup"
                                        textField="name"
                                        valid={v.applyValidity("FeedbackResponse.AssignmentGroup")}
                                        filterable={true}
                                        suggest={true}
                                        onChange={(value) =>
                                            updateStateAndViewModel({ ...state, assignmentGroup: value, assignedUser: undefined, isComplete: undefined, completionComments: undefined })
                                        }
                                    />
                                    <ValidationMessageView name="FeedbackResponse.AssignmentGroup" validationModel={v} />
                                </Forms.ThirdColumn>
                                <Forms.ThirdColumn>
                                    <Forms.Label htmlFor="assignedUserField">Assigned To</Forms.Label>
                                    <KendoComboBox
                                        id="assignedUserField"
                                        value={state.assignedUser}
                                        data={filteredAssignedUsers}
                                        keyField="id"
                                        textField="name"
                                        disabled={!state.assignmentGroup}
                                        valid={v.applyValidity("FeedbackResponse.AssignedUser")}
                                        filterable={true}
                                        suggest={true}
                                        onChange={(value) => updateStateAndViewModel({ ...state, assignedUser: value })}
                                    />
                                    <ValidationMessageView name="FeedbackResponse.AssignedUser" validationModel={v} />
                                </Forms.ThirdColumn>
                            </Forms.Row>
                        </Forms.Group>
                        {state.assignmentGroup?.assignmentGroup === AssignmentGroupEnum.Ga && (
                            <>
                                <Forms.Group>
                                    <Forms.Row>
                                        <Forms.FullColumn>
                                            <Forms.Checkbox
                                                id="isCompleteField"
                                                label="Mark as Completed"
                                                checked={state.isComplete}
                                                onChange={(value) => {
                                                    updateStateAndViewModel({
                                                        ...state,
                                                        isComplete: value,
                                                    });
                                                }}
                                            />
                                        </Forms.FullColumn>
                                    </Forms.Row>
                                </Forms.Group>
                                <Forms.Group>
                                    <Forms.Row>
                                        <Forms.FullColumn>
                                            <Forms.TextArea
                                                id="completionCommentsField"
                                                label="Completion Comments"
                                                value={state.completionComments}
                                                maxLength={4000}
                                                valid={v.applyValidity("FeedbackResponse.CompletionComments")}
                                                onChange={(value) => setState({ ...state, completionComments: value })}
                                                onBlur={updateViewModel}
                                            />
                                            <ValidationMessageView name="FeedbackResponse.CompletionComments" validationModel={v} />
                                        </Forms.FullColumn>
                                    </Forms.Row>
                                </Forms.Group>
                            </>
                        )}
                        <div className="mt-3">
                            <button type="button" className="btn btn-primary me-2" onClick={onSave}>
                                Save
                            </button>
                            <button type="button" className="btn btn-secondary me-2" onClick={onCancel}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </DirtyScope>
        </>
    );
}
