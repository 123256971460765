// framework
import { Link as RouterLink } from "react-router-dom";
// styles
import styles from "./BootstrapForms.module.css";

// standard Bootstrap form controls
// - centralising layout and css so that future upgrades are less painful
// - have exposed the bare minimum of functionality to achieve what we have in place currently
// - modify as required, but if introducing complexity please consider your design and whether or not complexity is necessary

// standard classes
export const TextClass = "form-text";
export const LabelClass = "form-label";
export const CheckLabelClass = "form-check-label";
export const ControlClass = "form-control";
export const ControlPlaintextClass = "form-control-plaintext";
export const CheckClass = "form-check";
export const CheckInputClass = "form-check-input";
export const CheckInlineClass = "form-check form-check-inline";
export const RowClass = "row g-3";
export const GroupClass = "mb-3";
export const FullColumnClass = "col-12";
export const HalfColumnClass = "col-md-6";
export const ThirdColumnClass = "col-md-4";
export const QuarterColumnClass = "col-md-3";
export const IsInvalidClass = "is-invalid";

// standard layout
export function Row(props: { children: React.ReactNode }) {
    return <div className={RowClass}>{props.children}</div>;
}

export function Group(props: { children: React.ReactNode }) {
    return <div className={GroupClass}>{props.children}</div>;
}

export function Fieldset(props: { children: React.ReactNode; disabled?: boolean }) {
    return (
        <fieldset className={GroupClass} disabled={props.disabled}>
            {props.children}
        </fieldset>
    );
}

export function FullColumn(props: { children: React.ReactNode }) {
    return <div className={FullColumnClass}>{props.children}</div>;
}

export function HalfColumn(props: { children?: React.ReactNode }) {
    return <div className={HalfColumnClass}>{props.children}</div>;
}

export function ThirdColumn(props: { children?: React.ReactNode }) {
    return <div className={ThirdColumnClass}>{props.children}</div>;
}

export function QuarterColumn(props: { children?: React.ReactNode }) {
    return <div className={QuarterColumnClass}>{props.children}</div>;
}

// standard display controls i.e. links or readonly fields styled to appear as regular form controls
export function Text(props: { children: React.ReactNode }) {
    return <div className={TextClass}>{props.children}</div>;
}

export function Label(props: { htmlFor: string; children: React.ReactNode; className?: string }) {
    const className = props.className ?? LabelClass;

    return (
        <label htmlFor={props.htmlFor} className={className}>
            {props.children}
        </label>
    );
}

export function Element(props: { label: string; children: React.ReactNode }) {
    return (
        <>
            <span className="d-inline-block mb-2">{props.label}</span>
            <span className={ControlPlaintextClass}>{props.children}</span>
        </>
    );
}

export function Link(props: { label: string; linkTo: string | undefined; linkText: string | undefined }) {
    return (
        <>
            <span className="d-inline-block mb-2">{props.label}</span>
            {props.linkTo && props.linkText && (
                <span className={ControlPlaintextClass}>
                    <RouterLink to={props.linkTo}>{props.linkText}</RouterLink>
                </span>
            )}
        </>
    );
}

export function ReadonlyTextInput(props: { id: string; value?: string | undefined; placeholder?: string; valid?: boolean }) {
    const value = props.value ?? "";
    const valid = props.valid ?? true;

    return <input id={props.id} type="text" className={valid ? ControlClass : `${ControlClass} ${IsInvalidClass}`} value={value} placeholder={props.placeholder} disabled />;
}

export function ReadonlyField(props: { label: string; id: string; value?: string | undefined | null; placeholder?: string; valid?: boolean }) {
    const value = props.value ?? "";

    return (
        <>
            <Label htmlFor={props.id}>{props.label}</Label>
            <ReadonlyTextInput id={props.id} value={value} placeholder={props.placeholder} valid={props.valid} />
        </>
    );
}

// Note: UX standards dictate that a readonly textarea should size itself according to content, hence the choice of a styled span over a disabled textarea
export function ReadonlyTextArea(props: { label: string; id: string; value?: string | undefined }) {
    const value = props.value ?? "\n";

    return (
        <>
            <Label htmlFor={props.id}>{props.label}</Label>
            <span className={`${ControlClass} ${styles.readonlyTextArea}`}>{value}</span>
        </>
    );
}

// Note: Should only be supplied sanitized html.
export function ReadonlyHtml(props: { label: string; id: string; value?: string | undefined }) {
    const value = props.value ?? "\n";

    return (
        <>
            <Label htmlFor={props.id}>{props.label}</Label>
            <div className={`${ControlClass} ${styles.readonlyTextArea}`} dangerouslySetInnerHTML={{ __html: value }} />
        </>
    );
}

export function ReadonlyCheckbox(props: { id: string; label: string; checked: boolean | undefined; inline?: boolean }) {
    const checked = props.checked ?? false;

    return (
        <div className={props.inline ? CheckInlineClass : CheckClass}>
            <input id={props.id} type="checkbox" className={CheckInputClass} checked={checked} disabled />
            <Label htmlFor={props.id} className={CheckLabelClass}>
                {props.label}
            </Label>
        </div>
    );
}

export function ReadonlyRadioButton(props: { name: string; id: string; label: string; checked?: boolean; value?: any; inline?: boolean }) {
    const value = props.value ?? "";
    const checked = props.checked ?? false;

    return (
        <div className={props.inline ? CheckInlineClass : CheckClass}>
            <input name={props.name} id={props.id} type="radio" className={CheckInputClass} value={value} checked={checked} disabled />
            <Label htmlFor={props.id} className={CheckLabelClass}>
                {props.label}
            </Label>
        </div>
    );
}

// standard form controls
export function Checkbox(props: { id: string; label: string; checked: boolean | undefined; inline?: boolean; disabled?: boolean; valid?: boolean; onChange: (checked: boolean) => void }) {
    const checked = props.checked ?? false;
    const valid = props.valid ?? true;

    return (
        <div className={props.inline ? CheckInlineClass : CheckClass}>
            <input id={props.id} type="checkbox" className={getClassName(valid, CheckInputClass)} checked={checked} disabled={props.disabled} onChange={(e) => props.onChange(e.target.checked)} />
            <Label htmlFor={props.id} className={CheckLabelClass}>
                {props.label}
            </Label>
        </div>
    );
}

export function RadioButton(props: { name: string; id: string; label: string; checked?: boolean; value?: any; inline?: boolean; disabled?: boolean; valid?: boolean; onChange: (value: any) => void }) {
    const value = props.value ?? "";
    const checked = props.checked ?? false;
    const valid = props.valid ?? true;

    return (
        <div className={props.inline ? CheckInlineClass : CheckClass}>
            <input
                name={props.name}
                id={props.id}
                type="radio"
                className={getClassName(valid, CheckInputClass)}
                value={value}
                checked={checked}
                disabled={props.disabled}
                onChange={(e) => props.onChange(e.target.value)}
            />
            <Label htmlFor={props.id} className={CheckLabelClass}>
                {props.label}
            </Label>
        </div>
    );
}

export function TextInput(props: {
    id: string;
    label: string;
    value: string | undefined;
    maxLength?: number;
    disabled?: boolean;
    valid?: boolean;
    onChange: (value: string) => void;
    onBlur?: () => void;
}) {
    const value = props.value ?? "";
    const valid = props.valid ?? true;

    return (
        <>
            <Label htmlFor={props.id}>{props.label}</Label>
            <input
                id={props.id}
                type="text"
                className={getClassName(valid, ControlClass)}
                value={value}
                maxLength={props.maxLength}
                disabled={props.disabled}
                onChange={(e) => props.onChange(e.target.value)}
                onBlur={props.onBlur}
            />
        </>
    );
}

export function NumericInput(props: {
    id: string;
    label: string;
    value: string | undefined;
    step?: number | undefined;
    min?: number | undefined;
    max?: number | undefined;
    disabled?: boolean;
    valid?: boolean;
    onChange: (value: string) => void;
    onBlur: () => void;
}) {
    const value = props.value ?? "";
    const valid = props.valid ?? true;

    return (
        <>
            <Label htmlFor={props.id}>{props.label}</Label>
            <input
                id={props.id}
                type="number"
                step={props.step}
                min={props.min}
                max={props.max}
                className={getClassName(valid, ControlClass)}
                value={value}
                disabled={props.disabled}
                onChange={(e) => props.onChange(e.target.value)}
                onBlur={props.onBlur}
            />
        </>
    );
}

export function PasswordInput(props: { id: string; label: string; value: string | undefined; valid?: boolean; onChange: (value: string) => void; onBlur: () => void }) {
    const value = props.value ?? "";
    const valid = props.valid ?? true;

    return (
        <>
            <Label htmlFor={props.id}>{props.label}</Label>
            <input id={props.id} type="password" className={getClassName(valid, ControlClass)} value={value} onChange={(e) => props.onChange(e.target.value)} onBlur={props.onBlur} />
        </>
    );
}

export function TextArea(props: {
    id: string;
    label: string;
    value: string | undefined;
    rows?: number;
    maxLength?: number;
    disabled?: boolean;
    valid?: boolean;
    onChange: (value: string) => void;
    onBlur?: () => void;
}) {
    const value = props.value ?? "";
    const valid = props.valid ?? true;

    return (
        <>
            <Label htmlFor={props.id}>{props.label}</Label>
            <textarea
                id={props.id}
                className={getClassName(valid, ControlClass)}
                value={value}
                rows={props.rows}
                maxLength={props.maxLength}
                disabled={props.disabled}
                onChange={(e) => props.onChange(e.target.value)}
                onBlur={props.onBlur}
            />
        </>
    );
}

function getClassName(valid: boolean, className: string) {
    return valid ? className : `${className} ${IsInvalidClass}`;
}
