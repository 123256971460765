// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import { IInitialiseRequestedPayload } from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_WellsBoreholes_ReportCreate;

export default function* initialise(action: Actions.IAction<IInitialiseRequestedPayload>) {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const { relatedActivityId, relatedActivityType } = action.payload;

    try {
        yield put(shellHelper.createSetBusyAction());

        const referenceResponse: Client.GetCreateGeoscienceAustraliaReportReferenceDataResponseDto = yield call(async () => {
            const client = await ClientFactory.createGeoscienceAustraliaReportClient();
            return await CallHelper.simpleCall(() =>
                client.getCreateGeoscienceAustraliaReportReferenceData(
                    new Client.GetCreateGeoscienceAustraliaReportReferenceDataRequestDto({
                        relatedActivityType: relatedActivityType,
                        relatedActivityId: relatedActivityId,
                    })
                )
            );
        });
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshReferenceData(relatedActivityType, referenceResponse)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
