// framework
import { useEffect, useState } from "react";
// redux
import * as Models from "../../models/models";
// common
import { ValidationModel } from "../../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../../common/validation/ValidationMessageView";
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import { DatePicker } from "@progress/kendo-react-dateinputs";

export default function IarlDetailsPpView(props: { vm: Models.IIarlPpDetailsViewModel; v: ValidationModel; updateViewModel: (vm: Models.IIarlViewModel) => void }) {
    const vm = props.vm;
    const v = props.v;

    const [state, setState] = useState<Models.IIarlPpDetailsViewModel>({
        existingNeatsFieldName: undefined,
        isCreateNewField: undefined,
        newFieldName: undefined,
        lastDayOfPetroleumRecoveryOperationsDate: undefined,
    });

    useEffect(() => {
        setState({
            existingNeatsFieldName: vm.existingNeatsFieldName,
            initialGrantDate: vm.initialGrantDate,
            isCreateNewField: vm.isCreateNewField,
            newFieldName: vm.newFieldName ?? "",
            lastDayOfPetroleumRecoveryOperationsDate: vm.lastDayOfPetroleumRecoveryOperationsDate,
        });
    }, [vm.existingNeatsFieldName, vm.initialGrantDate, vm.isCreateNewField, vm.newFieldName, vm.lastDayOfPetroleumRecoveryOperationsDate]);

    function onChange(values: Models.IIarlPpDetailsViewModel) {
        setState({ ...state, ...values });
    }

    function onBlur() {
        props.updateViewModel({ ppDetails: state });
    }

    const isCreateNewFieldRadioValue = "isCreateNewField";
    const isNotCreateNewFieldRadioValue = "isNotCreateNewField";
    const [selectedCreateNewFieldRadioValue, setCreateNewFieldRadioValue] = useState(
        GlobalHelpers.convertBooleanToRadioValue(state.isCreateNewField, isCreateNewFieldRadioValue, isNotCreateNewFieldRadioValue)
    );

    useEffect(() => {
        setCreateNewFieldRadioValue(GlobalHelpers.convertBooleanToRadioValue(vm.isCreateNewField, isCreateNewFieldRadioValue, isNotCreateNewFieldRadioValue));
    }, [vm.isCreateNewField]);

    function onIsCreateNewFieldGroupChange(value: string) {
        setCreateNewFieldRadioValue(value);
        if (value === isCreateNewFieldRadioValue)
            props.updateViewModel({
                ppDetails: {
                    ...state,
                    isCreateNewField: true,
                },
            });
        if (value === isNotCreateNewFieldRadioValue)
            props.updateViewModel({
                ppDetails: {
                    ...state,
                    isCreateNewField: false,
                    newFieldName: undefined,
                },
            });
    }

    return (
        <>
            <div className="mb-2">
                <Forms.RadioButton
                    name="isCreateNewFieldGroup"
                    id="ppDetailsIsNotCreateNewFieldField"
                    value={isNotCreateNewFieldRadioValue}
                    checked={selectedCreateNewFieldRadioValue === isNotCreateNewFieldRadioValue}
                    label="Use Existing Field"
                    onChange={onIsCreateNewFieldGroupChange}
                    valid={v.applyValidity("IarlDetails.PpDetails.IsCreateNewField")}
                    inline
                />
                <Forms.RadioButton
                    name="isCreateNewFieldGroup"
                    id="ppDetailsIsCreateNewFieldField"
                    value={isCreateNewFieldRadioValue}
                    checked={selectedCreateNewFieldRadioValue === isCreateNewFieldRadioValue}
                    label="Create New Field"
                    onChange={onIsCreateNewFieldGroupChange}
                    valid={v.applyValidity("IarlDetails.PpDetails.IsCreateNewField")}
                    inline
                />
            </div>
            <ValidationMessageView name="IarlDetails.PpDetails.IsCreateNewField" validationModel={v} />
            <Forms.Group>
                <Forms.ReadonlyField label="Field Name" id="ppDetailsExistingLocationField" value={vm.existingNeatsFieldName} />
            </Forms.Group>
            {selectedCreateNewFieldRadioValue === isCreateNewFieldRadioValue && (
                <Forms.Group>
                    <Forms.TextInput
                        id="ppDetailsNewFieldNameField"
                        label="New Field Name"
                        value={state.newFieldName}
                        valid={v.applyValidity("IarlDetails.PpDetails.NewFieldName")}
                        onChange={(value) => onChange({ ...state, newFieldName: value })}
                        onBlur={onBlur}
                    />
                    <ValidationMessageView name="IarlDetails.PpDetails.NewFieldName" validationModel={v} />
                </Forms.Group>
            )}
            <strong>If titleholder of a life-of-field petroleum production licence (section 147 of the OPGGS Act)</strong>
            <Forms.Group>
                <Forms.Label htmlFor="ppDetailsLastDayOfPetroleumRecoveryOperationsField">Last day of petroleum recovery operations (if none, insert production licence grant date)</Forms.Label>
                <DatePicker
                    id="ppDetailsLastDayOfPetroleumRecoveryOperationsDateField"
                    value={state.lastDayOfPetroleumRecoveryOperationsDate}
                    min={vm.initialGrantDate}
                    valid={v.applyValidityForKendo("IarlDetails.PpDetails.LastDayOfPetroleumRecoveryOperationsDate")}
                    onChange={(e) => onChange({ ...state, lastDayOfPetroleumRecoveryOperationsDate: e.value })}
                    onBlur={onBlur}
                />
                <ValidationMessageView name="IarlDetails.PpDetails.LastDayOfPetroleumRecoveryOperationsDate" validationModel={v} />
            </Forms.Group>
        </>
    );
}
