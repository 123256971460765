// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IActivityViewModel {
    key: string;
    displayName: string;
    activityType: Client.NopimsActivityTypeEnum;
    borehole?: Client.GetGeoscienceAustraliaAuthorisationsAuthorisationListResponseItemBoreholeDto;
    acquisition?: Client.GetGeoscienceAustraliaAuthorisationsAuthorisationListResponseItemAcquisitionDto;
    reprocessingProject?: Client.GetGeoscienceAustraliaAuthorisationsAuthorisationListResponseItemReprocessingProjectDto;
}

export interface IAuthorisationSearchItemViewModel {
    id: number;
    identifier: string | undefined;
    requestDate: Date;
    operatorCompanyId: number | undefined;
    operatorCompanyName: string | undefined;
    activityType: string | undefined;
    activities: IActivityViewModel[];
    activitySearchText: string;
    type: string | undefined;
    categories: string[];
    categorySearchText: string;
    status: string | undefined;
    decisionDate: Date | undefined;
}

export interface IRootViewModel {
    authorisations: IAuthorisationSearchItemViewModel[];
    isResultSetLimited: boolean;
    searchText: string;
    canViewBoreholes: boolean;
    canViewAcquisitions: boolean;
    canViewProjects: boolean;

    refreshList(response: Client.GetGeoscienceAustraliaAuthorisationsAuthorisationListResponseDto): IRootViewModel;
    changeSearchText(value: string): IRootViewModel;
}

export class ActivityViewModel implements IActivityViewModel {
    constructor(dto: Client.IGetGeoscienceAustraliaAuthorisationsAuthorisationListResponseItemActivityDto) {
        this.key =
            dto.activityType === Client.NopimsActivityTypeEnum.Acquisition
                ? "A" + dto.acquisition?.id!
                : dto.activityType === Client.NopimsActivityTypeEnum.Borehole
                ? "B" + dto.borehole?.id!
                : dto.activityType === Client.NopimsActivityTypeEnum.Project
                ? "P" + dto.reprocessingProject?.id!
                : "0";

        this.displayName =
            dto.activityType === Client.NopimsActivityTypeEnum.Acquisition
                ? dto.acquisition?.name!
                : dto.activityType === Client.NopimsActivityTypeEnum.Borehole
                ? dto.borehole?.name!
                : dto.activityType === Client.NopimsActivityTypeEnum.Project
                ? dto.reprocessingProject?.name!
                : "Unknown";

        this.activityType = dto.activityType;
        this.borehole = dto.borehole;
        this.acquisition = dto.acquisition;
        this.reprocessingProject = dto.reprocessingProject;
    }

    public key: string;
    public displayName: string;
    public activityType: Client.NopimsActivityTypeEnum;
    public borehole?: Client.GetGeoscienceAustraliaAuthorisationsAuthorisationListResponseItemBoreholeDto;
    public acquisition?: Client.GetGeoscienceAustraliaAuthorisationsAuthorisationListResponseItemAcquisitionDto;
    public reprocessingProject?: Client.GetGeoscienceAustraliaAuthorisationsAuthorisationListResponseItemReprocessingProjectDto;
}

export class AuthorisationSearchItemViewModel implements IAuthorisationSearchItemViewModel {
    constructor(dto: Client.GetGeoscienceAustraliaAuthorisationsAuthorisationListResponseItemDto) {
        this.id = dto.id;
        this.identifier = dto.identifier;
        this.requestDate = dto.requestDate;
        this.operatorCompanyId = dto.operatorCompany?.id;
        this.operatorCompanyName = dto.operatorCompany?.name;
        this.activityType = dto.activityType;
        this.activities = dto.activities.map((a) => new ActivityViewModel(a)).sort((a, b) => a.displayName.localeCompare(b.displayName));
        this.activitySearchText = this.activities.map((a) => a.displayName).join(" ");
        this.type = dto.type;
        this.categories = dto.categories;
        this.categorySearchText = dto.categories.sort((a, b) => a.localeCompare(b)).join(" ");
        this.status = dto.status;
        this.decisionDate = dto.decisionDate;
    }

    public id: number;
    public identifier: string | undefined;
    public requestDate: Date;
    public operatorCompanyId: number | undefined;
    public operatorCompanyName: string | undefined;
    public activityType: string | undefined;
    public activities: IActivityViewModel[];
    public activitySearchText: string;
    public type: string | undefined;
    public categories: string[];
    public categorySearchText: string;
    public status: string | undefined;
    public decisionDate: Date | undefined;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.authorisations = [];
        this.isResultSetLimited = false;
        this.searchText = "";
        this.canViewBoreholes = false;
        this.canViewAcquisitions = false;
        this.canViewProjects = false;
    }

    public authorisations: IAuthorisationSearchItemViewModel[];
    public isResultSetLimited: boolean;
    public searchText: string;
    public canViewBoreholes: boolean;
    public canViewAcquisitions: boolean;
    public canViewProjects: boolean;

    public refreshList(response: Client.GetGeoscienceAustraliaAuthorisationsAuthorisationListResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.authorisations = response.items.map((a) => new AuthorisationSearchItemViewModel(a));
        vm.isResultSetLimited = response.isResultSetLimited;
        vm.canViewBoreholes = response.canViewBoreholes;
        vm.canViewAcquisitions = response.canViewAcquisitions;
        vm.canViewProjects = response.canViewProjects;
        return vm;
    }

    public changeSearchText(value: string): IRootViewModel {
        const vm = this._clone();
        vm.searchText = value;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
