// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Models from "../../models/models";
import * as Actions from "../actions";
import { IShellReduxState } from "../../../shellReduxRegistration";
// infrastructure
import msalAuthService from "../../../../infrastructure/msalAuthService";
// api
import * as Client from "../../../../api/Client";
import * as ClientFactory from "../../../../api/clientFactory";
import * as CallHelper from "../../../../api/callHelper";
// common
import * as shellHelper from "../../../../common/shell/shellHelper";
import * as logHelper from "../../../../common/LogHelper";

const getRootViewModel = (state: IShellReduxState) => state.Shell_SignIn;

export function* trySilentSignIn() {
    try {
        yield put(shellHelper.createSetBusyAction());
        // attempt silent sign-in
        const response: Client.SignInResponseDto | undefined = yield call(trySilentSignInInteral);
        // msal couldn't sign in - return
        if (!response) return;
        // set as signed in
        let vm: Models.IRootViewModel = yield select(getRootViewModel);
        vm = vm.refreshOnSignIn(response!);
        yield put(Actions.actionFactory.sagaCompletedAction(vm));
    } catch (ex) {
        // log & suppress
        logHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function trySilentSignInInteral(): Promise<Client.SignInResponseDto | undefined> {
    const signedIn = await msalAuthService.trySilentSignIn();
    if (!signedIn) return undefined;

    // record the sign-in
    const client = await ClientFactory.createShellClient();
    const request = new Client.SignInRequestDto();
    const response = await CallHelper.simpleCall(() => client.signIn(request));

    // return
    return response;
}
