// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
import * as Common from "../../../../../common/draftApplicationDetails/components/models/models";
// common
import CommonApplicantView from "../../../../../oei/draftApplicationDetails/common/components/ApplicantView";

export default function ApplicantDetailsView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; lastRefreshedDatetime: Date | undefined }) {
    const vm = props.vm;
    const a = props.actions;
    const state = vm.state;

    function onSaveApplicant() {
        a.initialiseRequested(state.id!);
    }

    function onEditRequested(task: Common.ApplicationTaskEnum) {
        a.viewChanged(vm.setInEditTask(task));
    }

    function onCancelRequested() {
        a.initialiseRequested(state.id!);
    }

    return (
        <CommonApplicantView
            id={state.id!}
            versionNumber={state.versionNumber!}
            canManage={state.canManageApplicantOnly}
            validationState={state.validationState}
            viewState={state.viewState!}
            inEditTask={state.inEditTask}
            onEdit={() => onEditRequested(Common.ApplicationTaskEnum.Applicant)}
            onSave={onSaveApplicant}
            onCancel={onCancelRequested}
            lastRefreshedDatetime={props.lastRefreshedDatetime}
        />
    );
}
