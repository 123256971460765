// framework
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// common
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
// models
import { IRootViewModel } from "../../models/models";
// views
import DataMonitoringItemGridColumns from "../../../../../dataMonitoringItem/shared/views/DataMonitoringListGridColumnsView";

interface IProps {
    vm: IRootViewModel;
}

export default function DataMonitoringListGridView(props: IProps): JSX.Element {
    const { vm } = props;

    const initialState: State = {
        group: [{ field: "dataMonitoringItemType.classificationType" }],
        sort: [{ field: "dataMonitoringItemType.sortOrder", dir: "asc" }],
    };

    const [gridState, setGridState] = useState(initialState);
    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [vm.items]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid
                groupable
                data={process(vm.items, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn
                    field="dataMonitoringItem.identifier"
                    title="Identifier"
                    width={185}
                    cell={(props: GridCellProps) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.dataMonitoringItem && vm.permissions.canViewReprocessings ? (
                                <Link
                                    to={geoscienceAustraliaRouteHelper.reprocessingDataMonitoringDetails(vm.reprocessingId!, props.dataItem.dataMonitoringItem.id)}
                                    title="Click to view Data Monitoring Item."
                                >
                                    {props.dataItem.dataMonitoringItem.identifier}
                                </Link>
                            ) : (
                                props.dataItem.dataMonitoringItem?.identifier
                            )}
                        </CustomGridCell>
                    )}
                />
                {DataMonitoringItemGridColumns(vm.permissions.canViewDataSubmissions, vm.permissions.canViewAuthorisations)}
            </Grid>
        </>
    );
}
