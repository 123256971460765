import { useEffect, useState } from "react";
import { Redirect } from "react-router";
// api
import * as Client from "../../api/Client";
// routes
import * as homeHelper from "../../home/homeRouteHelper";
import * as companyRouteHelper from "../../company/companyRouteHelper";
import * as jointAuthorityRouteHelper from "../../jointAuthority/jointAuthorityRouteHelper";
import * as geoscienceAustraliaRouteHelper from "../../geoscienceAustralia/geoscienceAustraliaRouteHelper";
// common
import * as shellHelper from "../../common/shell/shellHelper";

export default function RootRedirectController() {
    // this component is responsible for redirecting the root to the appropriate home page

    // the typical flows are:
    //
    // GIVEN the user is not signed in
    // WHEN the user navigates to /
    // THEN the user is redirected to /SignIn
    //
    // GIVEN the user is now signed in
    // WHEN the user is navigated to /
    // THEN the root redirect requests their identity information
    // ANDTHEN the root redirect redirecs the user to the appropriate dashboard

    const isSignedIn = shellHelper.useIsSignedIn();
    const secureEntityType = shellHelper.useGetEntityType();
    const [path, setPath] = useState<string | undefined>(undefined);

    // Note: secureEntityType has been deliberately omitted from the dependency array.
    // 1. Secure Entity Type is immutable. A user cannot change their Entity Type for any reason. If the requirements change then we'll need to address how the system handles this; I am certain it will not be trivial.
    // 2. The only way the Secure Entity Type could change is through a sign-out and sign-in process. The Secure Entity Type is set at the same time as the Is Signed In flag.
    useEffect(() => {
        // if we're not authenticated, redirect the user to the sign-in page
        if (!isSignedIn) {
            setPath(homeHelper.signIn);
            return;
        }

        // if we are authenticated then set the path based on the entity type
        switch (secureEntityType!) {
            case Client.SecureEntityTypeEnum.Company:
                setPath(companyRouteHelper.dashboard());
                break;
            case Client.SecureEntityTypeEnum.JointAuthority:
                setPath(jointAuthorityRouteHelper.dashboard());
                break;
            case Client.SecureEntityTypeEnum.GeoscienceAustralia:
                setPath(geoscienceAustraliaRouteHelper.dashboard());
                break;
            default:
                throw new Error("Unknown Secure Entity Type.");
        }
    }, [isSignedIn]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!path) return <></>;
    return <Redirect to={path}></Redirect>;
}
