import { call, put, select } from "redux-saga/effects";
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { ICompanyReduxState } from "../../../../companyReduxRegistration";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as LogHelper from "../../../../../common/LogHelper";
import * as shellHelper from "../../../../../common/shell/shellHelper";

const getRootViewModel = (state: ICompanyReduxState) => state.Company_Finance_PaymentHistoryList;

export default function* getListRequested() {
    try {
        yield put(shellHelper.createSetBusyAction());

        const response: Client.GetCompanyFinancePaymentHistoryListResponseDto = yield call(async () => {
            const client = await ClientFactory.createCompanyFinanceClient();
            return await CallHelper.simpleCall(() => client.getCompanyFinancePaymentHistoryList(new Client.GetCompanyFinancePaymentHistoryListRequestDto()));
        });
        const vm: Models.IRootViewModel = yield select(getRootViewModel);
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshSaga(response)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
