// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";
// common
import { AdvancedSearchFilter, IAdvancedSearchFilter, IAdvancedSearchReferenceData } from "../../../../../common/advancedSearch/models";

export interface IRootViewModel {
    reprocessings: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingListResponseItemDto[];
    isResultSetLimited: boolean;

    searchText: string;
    advancedSearchFilter?: IAdvancedSearchFilter | undefined;
    advancedSearchReferenceData: IAdvancedSearchReferenceData;

    refreshList(response: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingListResponseDto): IRootViewModel;
    refreshReferenceData(response: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingListReferenceDataResponseDto): IRootViewModel;

    changeSearchFilter(searchText: string, advancedSearchFilter: IAdvancedSearchFilter): IRootViewModel;
    clearAllSearchFilters(): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.reprocessings = [];
        this.isResultSetLimited = false;
        this.advancedSearchReferenceData = {
            searchTypes: [],
            searchOperations: [],
        };
        this.searchText = "";
    }

    public reprocessings: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingListResponseItemDto[];
    public isResultSetLimited: boolean;

    public searchText: string;
    public advancedSearchFilter?: IAdvancedSearchFilter | undefined;
    public advancedSearchReferenceData: IAdvancedSearchReferenceData;

    public refreshList(response: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingListResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.reprocessings = response.items;
        vm.isResultSetLimited = response.isResultSetLimited;
        return vm;
    }

    public refreshReferenceData(response: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingListReferenceDataResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.advancedSearchFilter = new AdvancedSearchFilter(response.advancedSearchTypes[0], response.advancedSearchOperations[0]);
        vm.advancedSearchReferenceData = {
            titles: response.titles,
            acquisitions: response.acquisitions,
            searchTypes: response.advancedSearchTypes,
            searchOperations: response.advancedSearchOperations,
        };
        return vm;
    }

    public changeSearchFilter(searchText: string, advancedSearchFilter: IAdvancedSearchFilter): IRootViewModel {
        const vm = this._clone();
        vm.searchText = searchText;
        vm.advancedSearchFilter = advancedSearchFilter;
        return vm;
    }

    public clearAllSearchFilters(): IRootViewModel {
        const vm = this._clone();
        vm.searchText = "";
        vm.advancedSearchFilter = new AdvancedSearchFilter(vm.advancedSearchReferenceData.searchTypes[0], vm.advancedSearchReferenceData.searchOperations[0]);
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.advancedSearchFilter = clone(this.advancedSearchFilter);
        return vm;
    }
}
