// framework
import { useEffect, useState } from "react";
// kendo
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
// redux
import * as Models from "../../../models/models";
import * as Actions from "../../../redux/actions";
// api
import * as Client from "../../../../../../../../../api/Client";
// common
import ValidationMessageView from "../../../../../../../../../common/validation/ValidationMessageView";
import { ValidationModel } from "../../../../../../../../../common/validation/ValidationModel";
import YesNoDropDownList from "../../../../../../../../../common/dropdowns/YesNoDropDownListView";
import * as Forms from "../../../../../../../../../common/forms/BootstrapForms";

export default function DigitalSignatureSignView(props: { vm: Models.IDigitalSignatureViewModel; v: ValidationModel; actions: Actions.IActionFactory }): React.ReactElement {
    const { vm, actions, v } = props;

    const [state, setState] = useState<Models.IDigitalSignatureDetailsNewCompanySignatureViewModel>({
        signingFor: undefined,
        signingAs: undefined,
        poaLocation: "",
        poaPosition: "",
        isPoaPreviouslyProvided: undefined,
        poaDate: undefined,
    });

    const [signingAsOptions, setSigningAsOptions] = useState<Array<Client.GetCompanyOpggsLegislativeFormsFormRequestDigitalSignatureAvailableSignatureTypeItemDto>>();

    // signing option dropdowns - update viewModel immediately, these determine form state
    const onDropdownChange = (values: Models.IDigitalSignatureDetailsNewCompanySignatureViewModel) => {
        actions.viewChanged(vm.refreshNewCompanySignatureDetailsUpdated({ ...state, ...values }));
    };

    // generic input - update state on change
    const onChange = (values: Models.IDigitalSignatureDetailsNewCompanySignatureViewModel) => {
        setState({ ...state, ...values });
    };

    // generic input - update viewModel on blur
    function updateViewModel() {
        actions.viewChanged(vm.refreshNewCompanySignatureDetailsUpdated(state));
    }

    useEffect(() => {
        setState({
            signingFor: vm.digitalSignatureDetails.newCompanySignatureDetails?.signingFor,
            signingAs: vm.digitalSignatureDetails.newCompanySignatureDetails?.signingAs,
            poaLocation: vm.digitalSignatureDetails.newCompanySignatureDetails?.poaLocation ?? "",
            poaPosition: vm.digitalSignatureDetails.newCompanySignatureDetails?.poaPosition ?? "",
            isPoaPreviouslyProvided: vm.digitalSignatureDetails.newCompanySignatureDetails?.isPoaPreviouslyProvided,
            poaDate: vm.digitalSignatureDetails.newCompanySignatureDetails?.poaDate,
        });

        // cascade the available signature types to the Signing As dropdown
        setSigningAsOptions(
            vm.digitalSignatureDetails?.availableSignatureTypes?.find((d) => d.companyId === vm.digitalSignatureDetails.newCompanySignatureDetails?.signingFor?.companyId)?.availableSignatureTypes
        );
    }, [vm.digitalSignatureDetails?.availableSignatureTypes, vm.digitalSignatureDetails.newCompanySignatureDetails]);

    return (
        <>
            <h5>Signing Options</h5>
            <Forms.Group>
                <Forms.Label htmlFor="signingForField">I am signing this document for</Forms.Label>
                <DropDownList
                    id="signingForField"
                    valid={v.applyValidityForKendo("NewCompanySignature.SigningFor")}
                    data={vm.digitalSignatureDetails?.availableSignatureTypes}
                    value={state.signingFor}
                    dataItemKey="companyId"
                    textField="companyName"
                    onChange={(e) => onDropdownChange({ signingFor: e.value, signingAs: null })}
                />
                <ValidationMessageView name="NewCompanySignature.SigningFor" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="signingAsField">I am signing this document as a</Forms.Label>
                <DropDownList
                    id="signingAsField"
                    valid={v.applyValidityForKendo("NewCompanySignature.SigningAs")}
                    data={signingAsOptions}
                    value={state.signingAs}
                    dataItemKey="type"
                    textField="name"
                    onChange={(e) => onDropdownChange({ signingAs: e.value })}
                    disabled={!state.signingFor} // disable it if they haven't picked a signing for yet
                />
                <ValidationMessageView name="NewCompanySignature.SigningAs" validationModel={v} />
            </Forms.Group>
            {state.signingAs?.type === Client.LegislativeFormRequestFormDocumentationDigitalSignatureTypeEnum.PowerOfAttorney && (
                <>
                    <Forms.Group>
                        <Forms.TextInput
                            id="poaLocationField"
                            label="Location"
                            value={state.poaLocation}
                            valid={v.applyValidity("NewCompanySignature.PoaLocation")}
                            onChange={(value) => onChange({ poaLocation: value })}
                            onBlur={updateViewModel}
                        />
                        <ValidationMessageView name="NewCompanySignature.PoaLocation" validationModel={v} />
                    </Forms.Group>
                    <Forms.Group>
                        <Forms.TextInput
                            id="poaPositionField"
                            label="Position"
                            value={state.poaPosition}
                            valid={v.applyValidity("NewCompanySignature.PoaPosition")}
                            onChange={(value) => onChange({ poaPosition: value })}
                            onBlur={updateViewModel}
                        />
                        <ValidationMessageView name="NewCompanySignature.PoaPosition" validationModel={v} />
                    </Forms.Group>
                    <Forms.Group>
                        <Forms.Label htmlFor="isPoaPreviouslyProvidedField">Has a POA previously been provided?</Forms.Label>
                        <YesNoDropDownList
                            id="isPoaPreviouslyProvidedField"
                            valid={v.applyValidityForKendo("NewCompanySignature.IsPoaPreviouslyProvided")}
                            value={state.isPoaPreviouslyProvided}
                            onChange={(value) => onChange({ isPoaPreviouslyProvided: value })}
                            onBlur={updateViewModel}
                        />
                        <ValidationMessageView name="NewCompanySignature.IsPoaPreviouslyProvided" validationModel={v} />
                    </Forms.Group>
                    <Forms.Group>
                        <Forms.Label htmlFor="poaDate">Date of POA</Forms.Label>
                        <DatePicker
                            id="poaDate"
                            value={state.poaDate}
                            valid={v.applyValidityForKendo("NewCompanySignature.PoaDate")}
                            onChange={(e) => onChange({ poaDate: e.value ?? undefined })}
                            max={new Date()}
                            onBlur={updateViewModel}
                        />
                        <ValidationMessageView name="NewCompanySignature.PoaDate" validationModel={v} />
                    </Forms.Group>
                </>
            )}
            <p>
                I/We confirm that the information provided in this form is true and correct. Please note that giving false or misleading information is an offence under Part 7.4 of the Criminal Code
                Act 1995 (see in particular sections 136.1 and 137.1 of the Criminal Code).
            </p>
        </>
    );
}
