// framework
import { useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// api
import * as Client from "../../../../api/Client";
// other
import * as companyRouteHelper from "../../../companyRouteHelper";
import * as GlobalHelpers from "../../../../common/GlobalHelpers";
import CustomGridCell from "../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
import { FormRequestsListItems } from "../models/models";
import TitleLinkController from "../../../../common/links/TitleLinkController";
import { TitleDetailsTabsEnum } from "../../titleDetails/components/views/TitleDetailsView";

export default function LegislativeFormRequestsView(props: { vm: FormRequestsListItems }): React.ReactElement {
    const legislativeFormRequests = props.vm ?? new Array<Client.GetCompanyOpggsLegislativeFormsFormRequestListItemDto>();

    // TODO is this key sufficient given it is in a grid? Or do we need a composite key incorporating e.g. FormRequestId?
    function getTitleLinks(titles: Client.TitleLinkDto[]) {
        return titles.map((t, i) => [i > 0 && ", ", <TitleLinkController key={i} linkDto={t} tab={TitleDetailsTabsEnum.LegislativeForms} secureEntityType="Company" />]);
    }

    const initialGridState: State = {
        sort: [{ field: "titleNumbersDisplay", dir: "asc" }],
    };
    const [gridState, setGridState] = useState(initialGridState);

    // view
    return (
        <div className="card mb-2">
            <div className="card-body">
                <h3 className="card-title">Legislative Form Requests</h3>
                <Grid
                    sortable
                    resizable
                    navigatable
                    data={process(legislativeFormRequests, gridState)}
                    {...gridState}
                    onDataStateChange={(e) => {
                        setGridState(e.dataState);
                    }}
                >
                    <GridColumn
                        field="titleNumbersDisplay"
                        title="Title Number"
                        width={350}
                        filter="text"
                        columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, legislativeFormRequests)}
                        cell={(props) => <CustomGridCell gridCellProps={props}>{getTitleLinks(props.dataItem.titleLinks)}</CustomGridCell>}
                    />
                    <GridColumn
                        field="formTypeId"
                        title="Form"
                        width={100}
                        filter="text"
                        columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, legislativeFormRequests)}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                <Link to={companyRouteHelper.legislativeFormsFormRequestDetails(props.dataItem.id, props.dataItem.formTypeId)}>{props.dataItem.formTypeId}</Link>
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn field="relatedTitleholderName" title="Company" width={500} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, legislativeFormRequests)} />
                    <GridColumn field="lodgedByCompanyName" title="Lodged By" width={500} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, legislativeFormRequests)} />
                    <GridColumn field="status" title="Status" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, legislativeFormRequests)} />
                    <GridColumn field="submittedDatetime" title="Submitted Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} filter="date" columnMenu={createGridColumnFilterMenu} />
                </Grid>
            </div>
        </div>
    );
}
