import { IClient, Client } from "./client";
import { IOrderedSet, OrderedSet } from "./orderedSet";

const MaxConcurrentUploads = 5;
const RetryWaitMs = 50;

interface ICoordinator {
    CreateNew(uid: string): Promise<IClient>;
    TryGetExisting(uid: string): IClient | undefined;
    TryRemove(uid: string): boolean;
    GetNumberOfClients(): number;
    IsActive(): boolean;
}

// provides a simple coordinator that:
// 1. helps throttle the creation of upload clients, and
// 2. allows clients to be retrieved across events using the 'uid'
// the throttling is rudimentary and achieves its purposes simply.

class Coordinator implements ICoordinator {
    private _clients: { [uid: string]: IClient } = {};
    private _queue: IOrderedSet<string> = new OrderedSet<string>();

    constructor() {
        console.debug(`secureFileUpload/coordinator: Maximum Concurrent Uploads = ${MaxConcurrentUploads}.`);
        console.debug(`secureFileUpload/coordinator: Wait Before Retry (ms) = ${RetryWaitMs}.`);
    }

    public async CreateNew(uid: string): Promise<IClient> {
        if (this._clients[uid]) throw new Error();

        // enqueue
        this._queue.add(uid);

        // wait if client is not first in the queue or max concurrent upload limit is reached
        while (this.GetNumberOfClients() >= MaxConcurrentUploads || this._queue.first() !== uid) {
            await new Promise((r) => setTimeout(r, RetryWaitMs));
        }

        // create the client
        const client = new Client();
        this._clients[uid] = client;

        // dequeue
        this._queue.remove(uid);

        return client;
    }

    public TryGetExisting(uid: string): IClient | undefined {
        return this._clients[uid];
    }

    public TryRemove(uid: string): boolean {
        const client = this._clients[uid];
        if (client) {
            delete this._clients[uid];
            return true;
        }
        return false;
    }

    public GetNumberOfClients(): number {
        return Object.keys(this._clients).length;
    }

    public IsActive(): boolean {
        return this.GetNumberOfClients() > 0 || this._queue.length > 0;
    }
}

// maintain a singleton instance
const coordinator: ICoordinator = new Coordinator();
export default coordinator;

// provides rudimentary reporting over the number of active uploads running
setInterval(() => {
    const numberOfClients = coordinator.GetNumberOfClients();
    if (numberOfClients === 0) return;

    console.debug(`secureFileUpload/coordinator: Active Uploads = ${numberOfClients}.`);
}, 1000);
