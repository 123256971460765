// framework
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
// api
import { GetGeoscienceAustraliaSurveysAcquisitionsSurveyTitleDetailsResponseWorkProgramActivityDto } from "../../../../../../api/Client";
// common
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import ExternalLinkView from "../../../../../../common/externalLink/ExternalLinkView";
import * as shellRouteHelper from "../../../../../../shell/shellRouteHelper";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
import CommentSummaryView from "../../../../../../common/commentSummary/commentSummaryView";

export default function ActivityListGridView(props: {
    surveyId: number;
    surveyWorkProgramActivities: GetGeoscienceAustraliaSurveysAcquisitionsSurveyTitleDetailsResponseWorkProgramActivityDto[];
}): JSX.Element {
    const { surveyId, surveyWorkProgramActivities } = props;

    const initialState: State = {
        sort: [
            { field: "acquisitionName", dir: "asc" },
            { field: "titleNumber", dir: "asc" },
        ],
    };

    const [gridState, setGridState] = useState(initialState);
    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [surveyWorkProgramActivities]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <h2 className="mt-4">Work Program Items</h2>
            <Grid
                resizable
                sortable
                data={process(surveyWorkProgramActivities, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn
                    field="acquisitionName"
                    title="Acquisition"
                    width={300}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <Link to={geoscienceAustraliaRouteHelper.acquisitionDetails(surveyId, props.dataItem.acquisitionId)} title="Click to view Acquisition details.">
                                {props.dataItem.acquisitionName}
                            </Link>
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    field="titleNumber"
                    title="Title Number"
                    width={150}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.titleId ? (
                                <ExternalLinkView href={shellRouteHelper.publicPortalTitleDetails(props.dataItem.titleNumber)}>{props.dataItem.titleNumber}</ExternalLinkView>
                            ) : (
                                props.dataItem.titleNumber
                            )}
                        </CustomGridCell>
                    )}
                />
                <GridColumn field="workProgram2YearActivityType" title="Activity" width={400} />
                <GridColumn field="workProgramStartDate" title="WP Start Date" width={180} format={GlobalHelpers.NoptaDateFormatForKendo} />
                <GridColumn field="workProgramEndDate" title="WP End Date" width={180} format={GlobalHelpers.NoptaDateFormatForKendo} />
                <GridColumn
                    field="workProgramQuantity"
                    title="Quantity"
                    width={120}
                    cell={(props) => (
                        <td style={{ textAlign: "right" }}>
                            <span>{GlobalHelpers.toNoptaNumberString(props.dataItem.workProgramQuantity, "n0")}</span>
                        </td>
                    )}
                />
                <GridColumn field="workProgramUnits" title="Unit" width={100} />
                <GridColumn
                    title="Comments"
                    width={250}
                    field="comments"
                    columnMenu={createGridColumnFilterMenu}
                    cell={(props: GridCellProps) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <CommentSummaryView comment={props.dataItem.comments} maxSummaryLength={20} title="Comments"></CommentSummaryView>
                        </CustomGridCell>
                    )}
                />
            </Grid>
        </>
    );
}
