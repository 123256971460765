// framework
import { useEffect, useState } from "react";
// redux
import * as Models from "../../models/models";
// api
import * as Client from "../../../../../../api/Client";
// common
import { ValidationModel } from "../../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../../common/validation/ValidationMessageView";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import { orderBy } from "@progress/kendo-data-query";

export default function IarlDetailsEpView(props: { vm: Models.IIarlEpDetailsViewModel; v: ValidationModel; updateViewModel: (vm: Models.IIarlViewModel) => void }) {
    const vm = props.vm;
    const v = props.v;
    const isLongerApplicationPeriodAllowed = GlobalHelpers.convertBooleanToStringValue(vm.isLongerApplicationPeriodAllowed);

    const [existingLocationId, setExistingLocationId] = useState<number | undefined>();

    useEffect(() => {
        setExistingLocationId(vm.existingLocationId);
    }, [vm.existingLocationId]);

    const [existingLocationsData, setExistingLocationsData] = useState<Array<Client.GetCompanyOpggsDraftApplicationDetailsIarlEpDetailsExistingLocationDto>>();

    useEffect(() => {
        setExistingLocationsData(orderBy(vm.existingLocations, [{ field: "name", dir: "asc" }]));
    }, [vm.existingLocations]);

    function onChange(e: DropDownListChangeEvent) {
        const existingLocation = e.target.value as Client.GetCompanyOpggsDraftApplicationDetailsIarlEpDetailsExistingLocationDto;
        setExistingLocationId(existingLocation.id);
    }

    function onBlur() {
        props.updateViewModel({ epDetails: { ...vm, existingLocationId: existingLocationId } });
    }

    return (
        <>
            <Forms.Group>
                <Forms.Label htmlFor="epDetailsExistingLocationField">Location Name</Forms.Label>
                <DropDownList
                    id="epDetailsExistingLocationField"
                    valid={v.applyValidityForKendo("IarlDetails.EpDetails.ExistingLocation")}
                    data={existingLocationsData}
                    value={existingLocationsData?.find((l) => l.id === existingLocationId)}
                    dataItemKey="id"
                    textField="name"
                    onChange={onChange}
                    onBlur={onBlur}
                />
                <ValidationMessageView name="IarlDetails.EpDetails.ExistingLocation" validationModel={v} />
            </Forms.Group>
            <strong>If titleholder of a petroleum exploration permit (section 141 of the OPGGS Act)</strong>
            <Forms.Group>
                <Forms.ReadonlyField label="Date location declared" id="dateLocationDeclaredField" value={GlobalHelpers.toNoptaDateString(vm.dateLocationDeclared)} />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="Longer application period allowed (if applicable)" id="isLongerApplicationPeriodAllowedField" value={isLongerApplicationPeriodAllowed || ""} />
            </Forms.Group>
        </>
    );
}
