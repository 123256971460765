import * as Models from "../models/models";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "geoscienceAustralia.feedback.feedbackDetails.initialiseRequested",
    clearRequested = "geoscienceAustralia.feedback.feedbackDetails.clearRequested",
    saveRequested = "geoscienceAustralia.feedback.feedbackDetails.saveRequested",
    // user input
    viewChanged = "geoscienceAustralia.feedback.feedbackDetails.viewChanged",
    // saga events
    sagaCompleted = "geoscienceAustralia.feedback.feedbackDetails.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

export interface IInitialiseRequestedPayload {
    feedbackId: number;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(feedbackId: number): IAction<IInitialiseRequestedPayload>;
    clearRequested(): IAction<undefined>;
    saveRequested(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    // user input
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    // saga events
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (feedbackId: number): IAction<IInitialiseRequestedPayload> {
        return {
            type: actions.initialiseRequested,
            payload: { feedbackId: feedbackId },
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    saveRequested: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.saveRequested,
            payload: vm,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
