// framework
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { PersonIcon } from "@primer/octicons-react";
// custom
import * as Actions from "./redux/actions";
import * as Models from "./models/models";
import { IShellReduxState } from "../shellReduxRegistration";
// api
import { SecureEntityTypeEnum } from "../../api/Client";
// common
import * as companyRouteHelper from "../../company/companyRouteHelper";
import * as jointAuthorityRouteHelper from "../../jointAuthority/jointAuthorityRouteHelper";
import * as geoscienceAustraliaRouteHelper from "../../geoscienceAustralia/geoscienceAustraliaRouteHelper";

interface IProps extends Actions.IActionFactory {
    viewModel: Models.IRootViewModel;
    className: string | undefined;
}

// provides the My Account link in the shell's top nav (rhs)

function Controller(props: IProps) {
    const { isSignedIn, secureEntityType } = props.viewModel;
    if (!isSignedIn || !secureEntityType) return <></>;

    let path: string;
    switch (secureEntityType) {
        case SecureEntityTypeEnum.Company:
            path = companyRouteHelper.myDetails();
            break;
        case SecureEntityTypeEnum.JointAuthority:
            path = jointAuthorityRouteHelper.myDetails();
            break;
        case SecureEntityTypeEnum.GeoscienceAustralia:
            path = geoscienceAustraliaRouteHelper.myDetails();
            break;
        default:
            throw new Error("Unknown entity type.");
    }

    return (
        <NavLink className={props.className} title="My Account" to={path} exact={true}>
            <PersonIcon size="medium" />
        </NavLink>
    );
}

// connect redux
export default connect((state: IShellReduxState) => ({ viewModel: state.Shell_SignIn }), Actions.actionFactory)(Controller);
