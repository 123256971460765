// framework
import { clone, cloneDeep } from "lodash";
// api
import * as Client from "../../../../api/Client";
// redux
import { IRequestDetailsRoleViewModel, RequestDetailsRoleViewModel } from "./requestDetailsRoleViewModel";
// other
import { RoleCategoryEnum } from "../../../../common/roleGrid/RoleViewModel";
import { BaseGeoscienceAustraliaRoleRootViewModel, IGeoscienceAustraliaRoleRootViewModel } from "../../../common/GeoscienceAustraliaRoleRootViewModel";

export interface ISecureRequestRejectionsDetailsViewModel {
    isSuspicious?: boolean | undefined;
    comments?: string | undefined;
}

export interface ISecureRequestGeoscienceAustraliaStandardRoleDecisionViewModel {
    isApproved?: boolean | undefined;
}

export interface ISecureRequestGeoscienceAustraliaSignerDecisionViewModel {
    isApproved?: boolean | undefined;
    isIdentificationAndVerificationConfirmed?: boolean | undefined;
    isRoleConfirmed?: boolean | undefined;
    isAuthorityConfirmed?: boolean | undefined;
    isElectronicSignatureConfirmed?: boolean | undefined;
    isPoaValidityConfirmed?: boolean | undefined;
}

export interface ISecureRequestGeoscienceAustraliaStandardRoleDetailsViewModel extends ISecureRequestGeoscienceAustraliaStandardRoleDecisionViewModel {
    existingRoles?: IRequestDetailsRoleViewModel;
    requestedRoles?: IRequestDetailsRoleViewModel;
    grantedRoles?: IRequestDetailsRoleViewModel;
    hasAllGeneralRoles?: boolean;
}

export interface IRootViewModel extends IGeoscienceAustraliaRoleRootViewModel {
    id?: number;
    versionNumber?: number;
    isNewUserRequest?: boolean;
    isGeoscienceAustraliaStandardRoleRequest?: boolean;

    summary: Client.GeoscienceAustraliaUserManagementRequestDetailsSummaryDto;
    newUserDetails?: Client.GeoscienceAustraliaUserManagementRequestDetailsNewUserDetailsDto;
    existingUserDetails?: Client.GeoscienceAustraliaUserManagementRequestDetailsExistingUserDetailsDto;
    geoscienceAustraliaStandardRoleDetails: ISecureRequestGeoscienceAustraliaStandardRoleDetailsViewModel;
    rejectionDetails: ISecureRequestRejectionsDetailsViewModel;

    service: {
        statusMessages?: Client.StatusMessagesDto;
    };

    isDirty: boolean;
    isConflict: boolean;

    refreshRequestDetails(
        summary: Client.GeoscienceAustraliaUserManagementRequestDetailsSummaryDto,
        newUserDetails?: Client.GeoscienceAustraliaUserManagementRequestDetailsNewUserDetailsDto | undefined,
        existingUserDetails?: Client.GeoscienceAustraliaUserManagementRequestDetailsExistingUserDetailsDto | undefined,
        geoscienceAustraliaStandardRoleDetails?: Client.GeoscienceAustraliaUserManagementRequestDetailsStandardRoleDetailsDto | undefined
    ): IRootViewModel;
    refreshApproveRejectRequestGroupResponse(statusMessages: Client.StatusMessagesDto | undefined): IRootViewModel;
    refreshConflict(): IRootViewModel;
    // view triggered state changes
    refreshGeoscienceAustraliaStandardRoleDecisionChanged(geoscienceAustraliaStandardRoleDecision: ISecureRequestGeoscienceAustraliaStandardRoleDecisionViewModel): IRootViewModel;
    refreshGeoscienceAustraliaStandardRolesGrantedChanged(values: IRequestDetailsRoleViewModel): IRootViewModel;
    refreshDownloadedDocument(response: Client.SecurePortalDocumentDto): IRootViewModel;
    refreshRejectionDetailsChanged(values: ISecureRequestRejectionsDetailsViewModel): IRootViewModel;
    clearDownloadedDocument(): IRootViewModel;
}

export class RootViewModel extends BaseGeoscienceAustraliaRoleRootViewModel implements IRootViewModel {
    constructor() {
        super();

        // initial state
        this.id = undefined;
        this.versionNumber = undefined;
        this.isNewUserRequest = undefined;
        this.isGeoscienceAustraliaStandardRoleRequest = undefined;

        this.summary = new Client.GeoscienceAustraliaUserManagementRequestDetailsSummaryDto();
        this.newUserDetails = undefined;
        this.existingUserDetails = undefined;
        this.geoscienceAustraliaStandardRoleDetails = {};
        this.rejectionDetails = {};

        this.service = { statusMessages: undefined };

        this.isDirty = false;
        this.isConflict = false;
    }

    public id: number | undefined;
    public versionNumber: number | undefined;
    public isNewUserRequest: boolean | undefined;
    public isGeoscienceAustraliaStandardRoleRequest: boolean | undefined;

    public summary: Client.GeoscienceAustraliaUserManagementRequestDetailsSummaryDto;
    public newUserDetails: Client.GeoscienceAustraliaUserManagementRequestDetailsNewUserDetailsDto | undefined;
    public existingUserDetails: Client.GeoscienceAustraliaUserManagementRequestDetailsExistingUserDetailsDto | undefined;
    public geoscienceAustraliaStandardRoleDetails: ISecureRequestGeoscienceAustraliaStandardRoleDetailsViewModel;
    public rejectionDetails: ISecureRequestRejectionsDetailsViewModel;

    public service: { statusMessages?: Client.StatusMessagesDto };

    public isDirty: boolean;
    public isConflict: boolean;

    public refreshRequestDetails(
        summary: Client.GeoscienceAustraliaUserManagementRequestDetailsSummaryDto,
        newUserDetails?: Client.GeoscienceAustraliaUserManagementRequestDetailsNewUserDetailsDto | undefined,
        existingUserDetails?: Client.GeoscienceAustraliaUserManagementRequestDetailsExistingUserDetailsDto | undefined,
        geoscienceAustraliaStandardRoleDetails?: Client.GeoscienceAustraliaUserManagementRequestDetailsStandardRoleDetailsDto | undefined
    ): IRootViewModel {
        const vm = this._clone();

        vm.id = summary.id;
        vm.versionNumber = summary.versionNumber;
        vm.isNewUserRequest = summary.isNewUserRequest;
        vm.isGeoscienceAustraliaStandardRoleRequest = summary.isGeoscienceAustraliaStandardRoleRequest;

        vm.summary = summary;
        vm.newUserDetails = newUserDetails;
        vm.existingUserDetails = existingUserDetails;

        // standard role details
        // - reset
        vm.geoscienceAustraliaStandardRoleDetails = {};
        // refresh and set default selections - note this will need to be expanded if there is a future requirement to show completed requests
        if (geoscienceAustraliaStandardRoleDetails) {
            const existingRoles = new RequestDetailsRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Existing, geoscienceAustraliaStandardRoleDetails.rolesExisting);
            const requestedRoles = new RequestDetailsRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Requested, geoscienceAustraliaStandardRoleDetails.rolesRequested);
            const defaultGrantedRoles = requestedRoles.getDirectRoles().filter((r) => !existingRoles.hasRole(r));
            const grantedRoles = new RequestDetailsRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Granted, defaultGrantedRoles);
            vm.geoscienceAustraliaStandardRoleDetails.existingRoles = existingRoles;
            vm.geoscienceAustraliaStandardRoleDetails.requestedRoles = requestedRoles;
            vm.geoscienceAustraliaStandardRoleDetails.grantedRoles = grantedRoles;
            vm.geoscienceAustraliaStandardRoleDetails.hasAllGeneralRoles = geoscienceAustraliaStandardRoleDetails.hasAllGeneralRoles;
        }

        // rejection details
        // - reset
        vm.rejectionDetails = {};

        // reset state - on a successful approve / reject we redirect to the request list i.e. refreshRequestDetails is only called on initialise and we always want to reset state
        vm.service = { statusMessages: undefined };
        vm.isDirty = false;
        vm.isConflict = false;

        return vm;
    }

    public refreshApproveRejectRequestGroupResponse(statusMessages: Client.StatusMessagesDto | undefined): IRootViewModel {
        const vm = this._clone();
        const isSuccess = statusMessages?.isSuccess;

        vm.service.statusMessages = statusMessages;
        // on success set the vm as clean, otherwise leave dirty
        vm.isDirty = !isSuccess;
        vm.isConflict = false;

        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        vm.service.statusMessages = undefined;
        return vm;
    }

    refreshGeoscienceAustraliaStandardRoleDecisionChanged(values: ISecureRequestGeoscienceAustraliaStandardRoleDecisionViewModel): IRootViewModel {
        const vm = this._clone();

        vm.geoscienceAustraliaStandardRoleDetails = { ...vm.geoscienceAustraliaStandardRoleDetails, ...values };

        // clear reject-specific fields if request is approved
        if (vm.geoscienceAustraliaStandardRoleDetails.isApproved === true) {
            vm.rejectionDetails = {};
        }
        // clear all roles if request is rejected
        else if (vm.geoscienceAustraliaStandardRoleDetails.isApproved === false) {
            vm.geoscienceAustraliaStandardRoleDetails.grantedRoles = new RequestDetailsRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Granted);
        }

        vm.isDirty = true;
        return vm;
    }

    refreshGeoscienceAustraliaStandardRolesGrantedChanged(values: IRequestDetailsRoleViewModel): IRootViewModel {
        const vm = this._clone();

        vm.geoscienceAustraliaStandardRoleDetails.grantedRoles = values;

        vm.isDirty = true;
        return vm;
    }

    refreshRejectionDetailsChanged(values: ISecureRequestRejectionsDetailsViewModel): IRootViewModel {
        const vm = this._clone();

        vm.rejectionDetails = values;

        vm.isDirty = true;
        return vm;
    }

    public refreshDownloadedDocument(response: Client.SecurePortalDocumentDto): IRootViewModel {
        const vm = this._clone();

        return vm;
    }

    public clearDownloadedDocument(): IRootViewModel {
        const vm = this._clone();

        return vm;
    }

    // private implementation
    // ----------------------
    private _clone(): RootViewModel {
        const vm = clone(this);

        vm.summary = clone(this.summary);
        vm.newUserDetails = clone(this.newUserDetails);
        vm.existingUserDetails = clone(this.existingUserDetails);
        vm.geoscienceAustraliaStandardRoleDetails = cloneDeep(this.geoscienceAustraliaStandardRoleDetails);
        vm.rejectionDetails = clone(this.rejectionDetails);
        vm.service = clone(this.service);

        return vm;
    }
}
