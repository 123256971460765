// framework
import { useState } from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// api
import * as Client from "../../../../../../api/Client";
// other
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import TitleDetailsField from "../TitleDetailsField";

function BlocksView(props: { blocks: Client.GetCompanyOpggsTitleDetailsBlockDto[]; areaSqKm: number | undefined; showLocations: boolean }) {
    const blocks = props.blocks;
    const areaSqKm = props.areaSqKm;
    const showLocationName = props.showLocations;

    const [gridState, setGridState] = useState({
        sort: [
            { field: "mapSheetCode", dir: "asc" },
            { field: "blockNumber", dir: "asc" },
        ],
    } as State);

    return (
        <div className="card mb-2">
            <div className="card-body">
                <div className="d-flex flex-row flex-wrap">
                    <TitleDetailsField label="Number of Blocks">{GlobalHelpers.toNoptaIntegerString(blocks.length)}</TitleDetailsField>
                    <TitleDetailsField label="Area of Blocks">{GlobalHelpers.toNoptaNumberString(areaSqKm, "n2", "km²")}</TitleDetailsField>
                </div>
                {/* blocks grid */}
                <Grid
                    sortable
                    resizable
                    navigatable
                    data={process(blocks, gridState)}
                    {...gridState}
                    onDataStateChange={(e) => {
                        setGridState(e.dataState);
                    }}
                >
                    <GridColumn field="mapSheetCode" title="Map Sheet Code" width={200} />
                    <GridColumn field="mapSheetName" title="Map Sheet Name" width={300} />
                    <GridColumn field="blockNumber" title="Block Number" width={200} />
                    <GridColumn field="isPartBlock" title="Part Block" width={125} />
                    {showLocationName && <GridColumn field="locationName" title="Location Name" width={300} />}
                </Grid>
            </div>
        </div>
    );
}

export default BlocksView;
