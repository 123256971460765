// framework
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import DirtyScope from "../../../common/shell/DirtyScopeController";
// redux
import * as Actions from "../redux/actions";
import { IRootReduxState } from "../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import validator from "../models/validator";
// api
import { ExternalLinkEnum, StatusMessagesDto } from "../../../api/Client";
// views
import { QuestionsView } from "./views/QuestionsView";
import { NewGeoscienceAustraliaAdministratorRequestView } from "./views/NewGeoscienceAustraliaAdministratorRequestView";
import { GeoscienceAustraliaStandardRoleRequestView } from "./views/GeoscienceAustraliaStandardRoleRequestView";
import { NewUserRequestView } from "./views/NewUserRequestView";
import { UserAgreementView } from "./views/UserAgreementView";
import { CommentsView } from "./views/CommentsView";
import { RequestSubmittedView } from "./views/RequestSubmittedView";
// common
import { ValidationVisibilityEnum } from "../../../common/validation/ValidationModel";
import * as toastHelper from "../../../common/toastHelper";
import * as ConfigurationHelper from "../../../common/shell/configurationHelper";

interface IRegistrationControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
    statusMessages: (statusMessages: StatusMessagesDto | undefined) => void;
}

function RegistrationController(props: IRegistrationControllerProps) {
    const rootVm: Models.IRootViewModel = props.rootViewModel;
    const questions: Models.IQuestionsViewModel = rootVm.user.questions;
    const actions: Actions.IActionFactory = props;
    const isSuccess: boolean = rootVm.service.statusMessages?.isSuccess ?? false;
    const setStatusMessages = props.statusMessages;

    const userAgreementLink = ConfigurationHelper.usePortalLink(ExternalLinkEnum.CompanyUserAgreement); // todo
    const GeoscienceAustraliaAdministratorRequestFormLink = ConfigurationHelper.usePortalLink(ExternalLinkEnum.GeoscienceAustraliaAdministratorRequestForm);

    // on mount
    useEffect(() => {
        actions.initialiseRequested();
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // status messages
    useEffect(() => {
        setStatusMessages(rootVm.service.statusMessages);
    }, [setStatusMessages, rootVm.service.statusMessages]);

    // validation
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const validation = validator(rootVm, validationVisibility);

    // submission
    function onSubmitClicked(e: any) {
        e.preventDefault();

        // validation
        // - validation is hidden until the user attempts the action... it then stays visible until they have successfully completed the action
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);

        // submit
        actions.createSecureRequestRequested();
    }

    // content
    return (
        <>
            {isSuccess && <RequestSubmittedView vm={rootVm} actions={actions} />}
            {!isSuccess && (
                <DirtyScope scopeName="GeoscienceAustralia/home/registration" isDirty={rootVm.isDirty}>
                    <QuestionsView vm={rootVm} actions={actions} />
                    {rootVm.viewState.showRequestForm && (
                        <form onSubmit={onSubmitClicked}>
                            {questions.isGeoscienceAustraliaAdministratorRequest && (
                                <NewGeoscienceAustraliaAdministratorRequestView vm={rootVm} v={validation} actions={actions} requestFormLink={GeoscienceAustraliaAdministratorRequestFormLink} />
                            )}
                            {!questions.isGeoscienceAustraliaAdministratorRequest && <GeoscienceAustraliaStandardRoleRequestView vm={rootVm} v={validation} actions={actions} />}
                            <NewUserRequestView vm={rootVm} v={validation} actions={actions} />
                            <UserAgreementView vm={rootVm} v={validation} actions={actions} userAgreementLink={userAgreementLink} />
                            <CommentsView vm={rootVm} v={validation} actions={actions} />
                            <button className="btn btn-outline-primary" type="submit" disabled={!rootVm.isDirty}>
                                Submit Request
                            </button>
                        </form>
                    )}
                </DirtyScope>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_Home_Registration }), Actions.actionFactory)(RegistrationController);
