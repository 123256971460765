// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
import { IRegistrationRoleViewModel } from "../../models/registrationRoleViewModel";
// common
import RoleGrid, { RoleGridModeEnum } from "../../../../common/roleGrid/RoleGrid";
import { ValidationModel } from "../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../common/validation/ValidationMessageView";
import * as Forms from "../../../../common/forms/BootstrapForms";
import { RoleCategoryEnum } from "../../../../common/roleGrid/RoleViewModel";

export function JointAuthorityStandardRoleRequestView(props: { vm: Models.IRootViewModel; v: ValidationModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const request = vm.user.jointAuthorityStandardRoleRequest;
    const v = props.v;
    const actions = props.actions;

    // grid data
    function updateViewModel(updatedRole: IRegistrationRoleViewModel): void {
        if (updatedRole.getKey() !== RoleCategoryEnum.Requested) throw new Error("Invalid field update.");
        actions.viewChanged(vm.refreshJarStandardRoleRequestUpdated(updatedRole));
    }

    return (
        <div className="card mb-2">
            <div className="card-header">General Permissions Request</div>
            <div className="card-body">
                <Forms.Group>
                    <p>Select the permissions that you are requesting.</p>
                    <RoleGrid model={[request.requestedRoles!]} roleGroups={vm.roleConfiguration.roleGroups} mode={RoleGridModeEnum.Editable} onRoleChange={updateViewModel} />
                    <ValidationMessageView name="JointAuthorityStandardRoleRequest" validationModel={v} />
                </Forms.Group>
            </div>
        </div>
    );
}
