// redux
import * as Models from "../../../models/models";
import * as Actions from "../../../redux/actions";
// common
import { ValidationModel } from "../../../../../../../../../common/validation/ValidationModel";
import * as SecureFileDownloadButtonControl from "../../../../../../../../../common/secureFileDownload/SecureFileDownloadButtonControl";
// views
import DigitalSignaturesGridView from "./DigitalSignaturesGridView";
import DigitalSignatureSignView from "./DigitalSignatureSignView";
import InformationView from "./InformationView";
import FilesGridView from "../FilesGridView";

type DigitalSignatureViewProps = {
    vm: Models.IDigitalSignatureViewModel;
    v: ValidationModel;
    actions: Actions.IActionFactory;
    showCommands: boolean;
    isBusy: boolean;
    onBusyChanged: (busy: boolean) => void;
    generateDocumentRequested: () => void;
    updateSelection: (fileId: number) => void;
    updateHeaderSelection: (isChecked: boolean) => void;
};

export default function DigitalSignatureView(props: DigitalSignatureViewProps): React.ReactElement {
    const { vm, v, actions, isBusy, generateDocumentRequested, updateSelection, updateHeaderSelection } = props;

    const files: Array<SecureFileDownloadButtonControl.IFile> = vm.digitalSignatureDetails.files
        .filter((f) => f.isSelected)
        .map((f) => {
            return { id: f.fileId, size: f.fileSize };
        });

    return (
        <>
            {/* when in the ForSigning view state, all of this text and the signing progress table is visible */}
            {vm.digitalSignatureDetails.isForSigning && (
                <>
                    <InformationView vm={vm} />
                    <h5>Signing options selected for Legislative Form</h5>
                    <ul>
                        <li>Signing via Secure Portal Digital Signature</li>
                    </ul>

                    {/* the preview button is only visible when not fully signed */}
                    {!vm.digitalSignatureDetails.isSigned && (
                        <>
                            <p>Download a prepopulated Legislative Form. The digitally signed copy will be available once all signatures have been provided.</p>

                            <button className="btn btn-outline-secondary me-2 mb-4" type="button" title="Download" onClick={generateDocumentRequested}>
                                Download
                            </button>
                        </>
                    )}

                    <h5>Signing Progress</h5>

                    <DigitalSignaturesGridView vm={vm} actions={actions} />

                    {/* hide the sign form if there's no companies they can sign for */}
                    {vm.viewState === Models.ViewStateEnum.Edit && vm.digitalSignatureDetails.canSign && <DigitalSignatureSignView vm={vm} v={v} actions={actions} />}
                </>
            )}

            {/* if it's signed, add a filesgridview containing the completed legislative form */}
            {vm.digitalSignatureDetails.isSigned && (
                <>
                    <FilesGridView files={vm.digitalSignatureDetails.files} updateSelection={updateSelection} updateHeaderSelection={updateHeaderSelection} />
                    {/* grid action buttons */}
                    <div className="mb-4">
                        <SecureFileDownloadButtonControl.ButtonControl disabled={isBusy || vm.digitalSignatureDetails.numberOfFilesSelected === 0} files={files} className="me-1" />
                        <div>
                            <SecureFileDownloadButtonControl.ValidationControl files={files} />
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
