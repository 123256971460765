import { SecureUserNotificationTypesDto } from "../../../../api/Client";

export interface IEditableSecureUser {
    correspondenceEmailAddress?: string | undefined;
    position?: string | undefined;
    contactNumber?: string | undefined;
    notificationTypes: SecureUserNotificationTypesDto[];
}
export class EditableSecureUser implements IEditableSecureUser {
    constructor() {
        this.correspondenceEmailAddress = undefined;
        this.position = undefined;
        this.contactNumber = undefined;
        this.notificationTypes = [];
    }

    public correspondenceEmailAddress?: string;
    public position?: string;
    public contactNumber?: string;
    public notificationTypes: SecureUserNotificationTypesDto[];
}
