// framework
import { call, put, select } from "redux-saga/effects";
// redux
import { IJointAuthorityReduxState } from "../../../../jointAuthorityReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/rootViewModel";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";
import * as ConfigurationHelper from "../../../../../common/shell/configurationHelper";

interface IResponse {
    summary: Client.JointAuthorityUserManagementRequestDetailsSummaryDto;
    newUserDetails?: Client.JointAuthorityUserManagementRequestDetailsNewUserDetailsDto;
    existingUserDetails?: Client.JointAuthorityUserManagementRequestDetailsExistingUserDetailsDto;
    jointAuthorityStandardRoleDetails?: Client.JointAuthorityUserManagementRequestDetailsStandardRoleDetailsDto;
    // todo nduja jointAuthoritySignerDetails?: Client.JointAuthorityUserManagementRequestDetailsSignerDetailsDto;
}

const getRootViewModel = (state: IJointAuthorityReduxState) => state.JointAuthority_UserManagement_RequestDetails;

export default function* initialise(action: Actions.IAction<Actions.IRequestDetailsKeyPayload>) {
    const requestDetailsKey = action.payload;

    // save role reference data to the vm
    let vm: Models.IRootViewModel = yield select(getRootViewModel);
    vm = vm.refreshRoleConfiguration(yield select(ConfigurationHelper.getRoleConfiguration));
    yield put(Actions.actionFactory.sagaCompleted(vm));

    // load request details
    try {
        yield put(shellHelper.createSetBusyAction());

        const response: IResponse = yield call(getRequestDetails, requestDetailsKey.id, requestDetailsKey.type);
        yield put(
            Actions.actionFactory.sagaCompleted(
                vm.refreshRequestDetails(response.summary, response.newUserDetails, response.existingUserDetails, response.jointAuthorityStandardRoleDetails) // todo nduja replace null with response.jointAuthoritySignerDetails
            )
        );
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

export async function getRequestDetails(secureRequestGroupId: number, secureRequestGroupType: Client.SecureRequestGroupTypeEnum): Promise<IResponse> {
    const client = await ClientFactory.createJointAuthorityUserManagementClient();

    switch (secureRequestGroupType) {
        case Client.SecureRequestGroupTypeEnum.JAR:
            const jarRequest = new Client.GetJointAuthorityUserManagementRequestDetailsJarRequestDto({ secureRequestGroupId: secureRequestGroupId });
            return await CallHelper.simpleCall(() => client.getJointAuthorityUserManagementRequestDetailsJar(jarRequest));
        case Client.SecureRequestGroupTypeEnum.NU_JAR:
            const nuJarRequest = new Client.GetJointAuthorityUserManagementRequestDetailsNuJarRequestDto({ secureRequestGroupId: secureRequestGroupId });
            return await CallHelper.simpleCall(() => client.getJointAuthorityUserManagementRequestDetailsNuJar(nuJarRequest));
        // todo nduja signer
        // case Client.SecureRequestGroupTypeEnum.CS:
        //     const csRequest = new Client.GetJointAuthorityUserManagementRequestDetailsCsRequestDto({ secureRequestGroupId: secureRequestGroupId });
        //     return await CallHelper.simpleCall(() => client.getJointAuthorityUserManagementRequestDetailsCs(csRequest));
        // case Client.SecureRequestGroupTypeEnum.CR_CS:
        //     const crCsRequest = new Client.GetJointAuthorityUserManagementRequestDetailsCrCsRequestDto({ secureRequestGroupId: secureRequestGroupId });
        //     return await CallHelper.simpleCall(() => client.getJointAuthorityUserManagementRequestDetailsCrCs(crCsRequest));
        // case Client.SecureRequestGroupTypeEnum.NU_CR_CS:
        //     const nuCrCsRequest = new Client.GetJointAuthorityUserManagementRequestDetailsNuCrCsRequestDto({ secureRequestGroupId: secureRequestGroupId });
        //     return await CallHelper.simpleCall(() => client.getJointAuthorityUserManagementRequestDetailsNuCrCs(nuCrCsRequest));
        default:
            throw new Error("Unsupported Request Type.");
    }
}
