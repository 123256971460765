import * as Models from "../models/rootViewModel";
import { Level1ItemTypeEnum } from "../navigationItemTypes";

// -------
// Actions
// -------

export enum actions {
    // body layout
    updateBodyLayout = "shell.layout.updateBodyLayout",

    // busy state
    setBusy = "shell.layout.setBusy",
    clearBusy = "shell.layout.clearBusy",

    // dirty state
    setDirty = "shell.layout.setDirty",
    clearDirty = "shell.layout.clearDirty",

    // configuration
    refreshLhsNavRequested = "shell.layout.refreshLhsNavRequested",

    // saga
    sagaCompleted = "shell.layout.sagaCompleted",
}

// --------
// Payloads
// --------

export interface IAction<T> {
    type: actions;
    payload: T;
}

export interface IBodyLayoutPayload {
    pageTitle: string;
    showPageHeader: boolean;
    includePadding: boolean;
    selectedLhsNavItem: Level1ItemTypeEnum | undefined;
}

// ---------
// Factories
// ---------

export interface IActionFactory {
    // body layout
    updateBodyLayout(pageTitle: string, showPageHeader: boolean, includePadding: boolean, selectedLhsNavItem: Level1ItemTypeEnum | undefined): IAction<IBodyLayoutPayload>;
    // busy state
    setBusy(): IAction<undefined>;
    clearBusy(): IAction<undefined>;
    // dirty state
    setDirty(scope: string): IAction<string>;
    clearDirty(scope: string): IAction<string>;
    // configuration
    refreshLhsNavRequested(isSignedIn: boolean, isSilent: boolean): IAction<{ isSignedIn: boolean; isSilent: boolean }>;
    // saga
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    // body layout
    updateBodyLayout: function (pageTitle: string, showPageHeader: boolean, includePadding: boolean, selectedLhsNavItem: Level1ItemTypeEnum | undefined): IAction<IBodyLayoutPayload> {
        return {
            type: actions.updateBodyLayout,
            payload: {
                pageTitle: pageTitle,
                showPageHeader: showPageHeader,
                includePadding: includePadding,
                selectedLhsNavItem: selectedLhsNavItem,
            },
        };
    },

    // busy state

    setBusy: function (): IAction<undefined> {
        return {
            type: actions.setBusy,
            payload: undefined,
        };
    },

    clearBusy: function (): IAction<undefined> {
        return {
            type: actions.clearBusy,
            payload: undefined,
        };
    },

    // dirty state

    setDirty: function (scope: string): IAction<string> {
        return { type: actions.setDirty, payload: scope };
    },

    clearDirty: function (scope: string): IAction<string> {
        return { type: actions.clearDirty, payload: scope };
    },

    // configuration
    refreshLhsNavRequested: function (isSignedIn: boolean, isSilent: boolean): IAction<{ isSignedIn: boolean; isSilent: boolean }> {
        return {
            type: actions.refreshLhsNavRequested,
            payload: { isSignedIn, isSilent },
        };
    },

    // saga
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
