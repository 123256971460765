// redux
import { call, put, select } from "redux-saga/effects";
import { ICompanyReduxState } from "../../../../../companyReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: ICompanyReduxState) => state.Company_Opggs_LegislativeForms_FormRequestDetails;

export default function* deleteFormRequest() {
    let vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const deleteResult: CallHelper.CallResult<Client.DeleteCompanyOpggsLegislativeFormsFormRequestResponseDto> = yield call(async () => {
            const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();
            const request = new Client.DeleteCompanyOpggsLegislativeFormsFormRequestRequestDto({
                id: vm.id!,
                versionNumber: vm.versionNumber!,
            });

            return await CallHelper.call(() => client.deleteCompanyOpggsLegislativeFormsFormRequest(request));
        });

        if (deleteResult.IsSuccess) {
            // success
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshDeleteFormRequestResponse(deleteResult.Response!.statusMessages)));
        } else if (deleteResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            deleteResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
