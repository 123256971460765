// api
import * as ClientFactory from "../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";

export async function getCompanyOpggsApplicationDetailsBlockDetails(id: string): Promise<Client.GetCompanyOpggsApplicationDetailsBlockDetailsResponseDto> {
    // console.info("getCompanyOpggsApplicationDetailsBlockDetails");
    const client = await ClientFactory.createCompanyOpggsApplicationDetailsClient();
    return await CallHelper.simpleCall(() => client.getCompanyOpggsApplicationDetailsBlockDetails(new Client.GetCompanyOpggsApplicationDetailsBlockDetailsRequestDto({ applicationId: id })));
}
