// redux
import * as Validation from "../../../../common/validation/ValidationModel";
import * as Model from "./rootViewModel";

export default function validate(vm: Model.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateGeoscienceAustraliaAdministratorRequestsDecisionDetails(vm.geoscienceAustraliaAdministrator, v);
    return v;
}

function validateGeoscienceAustraliaAdministratorRequestsDecisionDetails(vm: Model.IGeoscienceAustraliaAdministratorRequestsDecisionViewModel, v: Validation.ValidationModel): void {
    if (vm.comments && vm.comments.length > 1000) v.addError("GeoscienceAustraliaAdministratorRequestList.Comments", "Reason must be 1000 characters or less.");
}
