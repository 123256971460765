// framework
import { useState } from "react";
// kendo
import { process, State } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridItemChangeEvent } from "@progress/kendo-react-grid";
// redux
import * as Models from "../../models/models";
import * as Actions from "../../redux/actions";
// api
import { GetCompanyOpggsDraftApplicationDetailsTitleholderForeignInvestmentApprovalDto as TitleholderForeignInvestmentApprovalDto } from "../../../../../../../api/Client";
import { ForeignInvestmentApprovalTypeDto } from "../../../../../../../api/Client";
// views
import TitleholderForeignInvestmentApprovalTypeCell from "./TitleholderForeignInvestmentApprovalTypeCell";
// common
import { IValidationModel } from "../../../../../../../common/validation/ValidationModel";

export default function TitleholderForeignInvestmentApprovalGridView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; v: IValidationModel }): React.ReactElement {
    const { vm, actions, v } = props;

    const initialGridState = {
        sort: [{ field: "companyName", dir: "asc" }],
    } as State;
    const [gridState, setGridState] = useState(initialGridState);

    function onGridItemChange(e: GridItemChangeEvent): void {
        if (vm.viewState === Models.ViewStateEnum.View) return;

        const a = e.dataItem as TitleholderForeignInvestmentApprovalDto;
        const foreignInvestmentApprovalType = e.value as ForeignInvestmentApprovalTypeDto | undefined;

        actions.viewChanged(vm.onTitleholderForeignInvestmentApprovalsChanged(a.companyId, foreignInvestmentApprovalType));
    }

    return (
        <>
            <Grid
                className="mb-2"
                resizable
                navigatable
                onItemChange={onGridItemChange}
                data={process(vm.titleholderForeignInvestmentTypeApprovals, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn field="companyName" title="Company Name" width={400} />
                <GridColumn field="acnOrArbn" title="ACN/ARBN" width={200} />
                <GridColumn
                    field="titleholderForeignInvestmentApproval.description"
                    title="Foreign investment approval required?"
                    cell={(props) => {
                        return TitleholderForeignInvestmentApprovalTypeCell(props, vm.availableForeignInvestmentTypes, vm.viewState, v);
                    }}
                    width={270}
                />
            </Grid>
        </>
    );
}
