// redux
import { call, put, select } from "redux-saga/effects";
import { IDraftApplicationDetailsReduxState } from "../../../../draftApplicationDetailsReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as Client from "../../../../../../../api/Client";
// common
import * as LogHelper from "../../../../../../../common/LogHelper";
import * as shellHelper from "../../../../../../../common/shell/shellHelper";
// calls
import { getCompanyOeiDraftApplicationDetailsContact } from "./calls";

const getRootViewModel = (state: IDraftApplicationDetailsReduxState) => state.Company_Oei_DraftApplicationDetails_Controls_Contact;

export default function* initialise(action: Actions.IAction<number>) {
    let vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const response: Client.GetCompanyOeiDraftApplicationDetailsContactResponseDto = yield call(async () => {
            return await getCompanyOeiDraftApplicationDetailsContact(action.payload);
        });

        vm = vm.refreshDetails(action.payload, response);
        vm = vm.onInitialised();

        yield put(Actions.actionFactory.sagaCompleted(vm));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
