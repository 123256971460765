// CoCn
import * as CoCnReducers from "./coCn/redux/reducers";
import * as CoCnModels from "./coCn/models/models";
import CoCnSaga from "./coCn/redux/saga";

// EP Renewal
import * as EpRenewalReducers from "./epRenewal/redux/reducers";
import * as EpRenewalModels from "./epRenewal/models/models";
import EpRenewalSaga from "./epRenewal/redux/saga";

// RL Renewal
import * as RlRenewalReducers from "./rlRenewal/redux/reducers";
import * as RlRenewalModels from "./rlRenewal/models/models";
import RlRenewalSaga from "./rlRenewal/redux/saga";

// Eottlr
import * as EottlrReducers from "./eottlr/redux/reducers";
import * as EottlrModels from "./eottlr/models/models";
import EottlrSaga from "./eottlr/redux/saga";

// Iarl
import * as IarlReducers from "./iarl/redux/reducers";
import * as IarlModels from "./iarl/models/models";
import IarlSaga from "./iarl/redux/saga";

// Iapp
import * as IappReducers from "./iapp/redux/reducers";
import * as IappModels from "./iapp/models/models";
import IappSaga from "./iapp/redux/saga";

// Contact
import * as ContactReducers from "./controls/contact/redux/reducers";
import * as ContactModels from "./controls/contact/models/models";
import ContactSaga from "./controls/contact/redux/saga";

// EVA
import * as EvaReducers from "./controls/eva/redux/reducers";
import * as EvaModels from "./controls/eva/models/models";
import EvaSaga from "./controls/eva/redux/saga";

// Work Program
import * as WorkProgramReducers from "./controls/workProgram/redux/reducers";
import * as WorkProgramModels from "./controls/workProgram/models/models";
import WorkProgramSaga from "./controls/workProgram/redux/saga";

// Titleholder Foreign Investment
import * as TitleholderForeignInvestmentReducers from "./controls/titleholderForeignInvestment/redux/reducers";
import * as TitleholderForeignInvestmentModels from "./controls/titleholderForeignInvestment/models/models";
import TitleholderForeignInvestmentSaga from "./controls/titleholderForeignInvestment/redux/saga";

// state
export interface IDraftApplicationDetailsReduxState {
    Company_Opggs_DraftApplicationDetails: CoCnModels.IRootViewModel;
    Company_Opggs_DraftApplicationDetails_EpRenewal: EpRenewalModels.IRootViewModel;
    Company_Opggs_DraftApplicationDetails_RlRenewal: RlRenewalModels.IRootViewModel;
    Company_Opggs_DraftApplicationDetails_Eottlr: EottlrModels.IRootViewModel;
    Company_Opggs_DraftApplicationDetails_Iarl: IarlModels.IRootViewModel;
    Company_Opggs_DraftApplicationDetails_Iapp: IappModels.IRootViewModel;
    Company_Opggs_DraftApplicationDetails_Controls_Contact: ContactModels.IRootViewModel;
    Company_Opggs_DraftApplicationDetails_Controls_Eva: EvaModels.IRootViewModel;
    Company_Opggs_DraftApplicationDetails_Controls_WorkProgram: WorkProgramModels.IRootViewModel;
    Company_Opggs_DraftApplicationDetails_Controls_TitleholderForeignInvestment: TitleholderForeignInvestmentModels.IRootViewModel;
}

// reducers
export function buildReducers() {
    return {
        Company_Opggs_DraftApplicationDetails: CoCnReducers.reducer,
        Company_Opggs_DraftApplicationDetails_EpRenewal: EpRenewalReducers.reducer,
        Company_Opggs_DraftApplicationDetails_RlRenewal: RlRenewalReducers.reducer,
        Company_Opggs_DraftApplicationDetails_Eottlr: EottlrReducers.reducer,
        Company_Opggs_DraftApplicationDetails_Iarl: IarlReducers.reducer,
        Company_Opggs_DraftApplicationDetails_Iapp: IappReducers.reducer,
        Company_Opggs_DraftApplicationDetails_Controls_Contact: ContactReducers.reducer,
        Company_Opggs_DraftApplicationDetails_Controls_Eva: EvaReducers.reducer,
        Company_Opggs_DraftApplicationDetails_Controls_WorkProgram: WorkProgramReducers.reducer,
        Company_Opggs_DraftApplicationDetails_Controls_TitleholderForeignInvestment: TitleholderForeignInvestmentReducers.reducer,
    };
}

// sagas
export function buildSagas(): Array<any> {
    return [CoCnSaga, EpRenewalSaga, RlRenewalSaga, EottlrSaga, IarlSaga, IappSaga, ContactSaga, EvaSaga, WorkProgramSaga, TitleholderForeignInvestmentSaga];
}
