// redux
import * as Models from "../../models/models";
// common
import SignatureTypeControl from "../../controls/signatureType/components/Controller";
import * as LegislativeFormsHelper from "../../../common/LegislativeFormsHelper";

type Props = {
    id: number;
    versionNumber: number;
    viewState: Models.ViewStateEnum;
    inEditTask: Models.FormRequestTaskEnum | undefined;
    onEdit: () => void;
    onSave: () => void;
    onCancel: () => void;
    lastRefreshedDatetime: Date | undefined;
};

export default function SignatureTypeView(props: Props) {
    return (
        <SignatureTypeControl
            id={props.id}
            versionNumber={props.versionNumber}
            cardTitle="Signing Options"
            showCommands={LegislativeFormsHelper.showCommands(props.viewState)}
            disableEdit={LegislativeFormsHelper.isInEdit(props.inEditTask)}
            lastRefreshedDatetime={props.lastRefreshedDatetime}
            onEdit={props.onEdit}
            onSave={props.onSave}
            onCancel={props.onCancel}
            className="mb-2"
        />
    );
}
