// framework
import { Link } from "react-router-dom";
// kendo
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
// common
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
// api
import * as Client from "../../../../../../api/Client";
// models
import * as Models from "../../models/models";

type Props = {
    resubmission: Models.IResubmissionViewModel;
    dataSubmissionType: Client.NopimsActivityTypeEnum | undefined;
    canViewBoreholes: boolean;
    canViewAcquisitions: boolean;
    canViewReprocessingProjects: boolean;
};

export default function ResubmissionDataItemsGrid(props: Props): JSX.Element {
    const { resubmission, dataSubmissionType, canViewBoreholes, canViewAcquisitions, canViewReprocessingProjects } = props;
    return (
        <>
            {resubmission && resubmission.status && (
                <Grid data={resubmission.resubmissionItems}>
                    <GridNoRecords>There are currently no Items for this Data Submission.</GridNoRecords>
                    <GridColumn
                        field="dataMonitoringItem.identifier"
                        title="Identifier"
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                {dataSubmissionType === Client.NopimsActivityTypeEnum.Borehole && (
                                    <>
                                        {canViewBoreholes ? (
                                            <Link
                                                to={geoscienceAustraliaRouteHelper.wellDataMonitoringDetails(props.dataItem.dataMonitoringItem.borehole?.wellId, props.dataItem.dataMonitoringItem?.id)}
                                                title="Click to view Data Monitoring Item."
                                            >
                                                {props.dataItem.dataMonitoringItem.identifier}
                                            </Link>
                                        ) : (
                                            <>{props.dataItem.dataMonitoringItem.identifier}</>
                                        )}
                                    </>
                                )}
                                {dataSubmissionType === Client.NopimsActivityTypeEnum.Acquisition && (
                                    <>
                                        {canViewAcquisitions ? (
                                            <Link
                                                to={geoscienceAustraliaRouteHelper.surveyDataMonitoringDetails(
                                                    props.dataItem.dataMonitoringItem.acquisition?.surveyId,
                                                    props.dataItem.dataMonitoringItem?.id
                                                )}
                                                title="Click to view Data Monitoring Item."
                                            >
                                                {props.dataItem.dataMonitoringItem.identifier}
                                            </Link>
                                        ) : (
                                            <>{props.dataItem.dataMonitoringItem.identifier}</>
                                        )}
                                    </>
                                )}
                                {dataSubmissionType === Client.NopimsActivityTypeEnum.Project && (
                                    <>
                                        {canViewReprocessingProjects ? (
                                            <Link
                                                to={geoscienceAustraliaRouteHelper.reprocessingDataMonitoringDetails(
                                                    props.dataItem.dataMonitoringItem.project?.reprocessingId,
                                                    props.dataItem.dataMonitoringItem?.id
                                                )}
                                                title="Click to view Data Monitoring Item."
                                            >
                                                {props.dataItem.dataMonitoringItem.identifier}
                                            </Link>
                                        ) : (
                                            <>{props.dataItem.dataMonitoringItem.identifier}</>
                                        )}
                                    </>
                                )}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn field="dataMonitoringItem.name" title="Name" />
                    <GridColumn field="dataMonitoringItem.classification" title="Type" />
                    {dataSubmissionType === Client.NopimsActivityTypeEnum.Borehole && (
                        <GridColumn
                            field="borehole"
                            title="Borehole"
                            cell={(props) => (
                                <CustomGridCell gridCellProps={props}>
                                    {canViewBoreholes ? (
                                        <Link
                                            to={geoscienceAustraliaRouteHelper.boreholeDataMonitoring(
                                                props.dataItem.dataMonitoringItem.borehole?.wellId,
                                                props.dataItem.dataMonitoringItem.borehole?.id
                                            )}
                                            title="Click to view Borehole Data Monitoring."
                                        >
                                            {props.dataItem.dataMonitoringItem.borehole?.name}
                                        </Link>
                                    ) : (
                                        <>{props.dataItem.dataMonitoringItem.borehole?.name}</>
                                    )}
                                </CustomGridCell>
                            )}
                        />
                    )}
                    {dataSubmissionType === Client.NopimsActivityTypeEnum.Acquisition && (
                        <GridColumn
                            field="acquisition"
                            title="Acquisition"
                            cell={(props) => (
                                <CustomGridCell gridCellProps={props}>
                                    {canViewAcquisitions ? (
                                        <Link
                                            to={geoscienceAustraliaRouteHelper.acquisitionDataMonitoring(
                                                props.dataItem.dataMonitoringItem.acquisition?.surveyId,
                                                props.dataItem.dataMonitoringItem.acquisition?.id
                                            )}
                                            title="Click to view Acquisition Data Monitoring."
                                        >
                                            {props.dataItem.dataMonitoringItem.acquisition?.name}
                                        </Link>
                                    ) : (
                                        <>{props.dataItem.dataMonitoringItem.acquisition?.name}</>
                                    )}
                                </CustomGridCell>
                            )}
                        />
                    )}
                    {dataSubmissionType === Client.NopimsActivityTypeEnum.Project && (
                        <GridColumn
                            field="project"
                            title="Project"
                            cell={(props) => (
                                <CustomGridCell gridCellProps={props}>
                                    {canViewReprocessingProjects ? (
                                        <Link
                                            to={geoscienceAustraliaRouteHelper.reprocessingProjectDataMonitoring(
                                                props.dataItem.dataMonitoringItem.project?.reprocessingId,
                                                props.dataItem.dataMonitoringItem.project?.id
                                            )}
                                            title="Click to view Project Data Monitoring."
                                        >
                                            {props.dataItem.dataMonitoringItem.project?.name}
                                        </Link>
                                    ) : (
                                        <>{props.dataItem.dataMonitoringItem.project?.name}</>
                                    )}
                                </CustomGridCell>
                            )}
                        />
                    )}
                    <GridColumn field="dataMonitoringItem.status" title="Status" />
                    <GridColumn field="reason" title="Reason" />
                    {resubmission.status &&
                        (resubmission.status === Client.DataSubmissionResubmissionStatusEnum.Received || resubmission.status === Client.DataSubmissionResubmissionStatusEnum.Completed) && (
                            <GridColumn field="outcome" title="Outcome" />
                        )}
                </Grid>
            )}
        </>
    );
}
