// framework
import { useEffect, useState } from "react";
// api
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/rootViewModel";
// common
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
import * as Validation from "../../../../../common/validation/ValidationModel";
import ExternalLink from "../../../../../common/externalLink/ExternalLinkView";
import * as Forms from "../../../../../common/forms/BootstrapForms";
import * as AlertsHelper from "../../../../../common/alerts/AlertsHelper";
import * as documentHelper from "../../../../../common/documentHelper";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import SecureFileDownloadLinkControl from "../../../../../common/secureFileDownload/SecureFileDownloadLinkControl";
// other
import { DashIcon } from "@primer/octicons-react";

export default function CompanySignerDetailsView(props: {
    vm: Models.IRootViewModel;
    actions: Actions.IActionFactory;
    validation: Validation.IValidationModel;
    csNoptaSignatureGuidanceLink: string | undefined;
}): React.ReactElement {
    const vm = props.vm;
    const actions = props.actions;
    const v = props.validation;
    const signerDetails = vm.companySignerDetails;

    // signer decision state
    const [state, setState] = useState<Models.ISecureRequestCompanySignerDecisionViewModel>({
        isApproved: undefined,
        isIdentificationAndVerificationConfirmed: false,
        isRoleConfirmed: false,
        isAuthorityConfirmed: false,
        isElectronicSignatureConfirmed: false,
        isPoaValidityConfirmed: false,
    });
    // update decision if changed
    useEffect(() => {
        setState({
            isApproved: signerDetails.isApproved,
            isIdentificationAndVerificationConfirmed: signerDetails.isIdentificationAndVerificationConfirmed ?? false,
            isRoleConfirmed: signerDetails.isRoleConfirmed ?? false,
            isAuthorityConfirmed: signerDetails.isAuthorityConfirmed ?? false,
            isElectronicSignatureConfirmed: signerDetails.isElectronicSignatureConfirmed ?? false,
            isPoaValidityConfirmed: signerDetails.isPoaValidityConfirmed ?? false,
        });
    }, [signerDetails]);

    // approve / reject radio button state
    const isSignerApprovedRadioValue = "isSignerApproved";
    const isSignerRejectedRadioValue = "isSignerRejected";
    const [approveRejectRadioValue, setApproveRejectRadioValue] = useState<string | undefined>(undefined);
    // update selection if changed
    useEffect(() => {
        setApproveRejectRadioValue(GlobalHelpers.convertBooleanToRadioValue(signerDetails.isApproved, isSignerApprovedRadioValue, isSignerRejectedRadioValue));
    }, [signerDetails.isApproved]);

    // download document
    const downloadedDocument = signerDetails.downloadedDocument;

    // - download when response received
    useEffect(() => {
        documentHelper.tryDownloadDocument(downloadedDocument);
        return function () {
            actions.clearDownloadedDocumentRequested();
        };
    }, [downloadedDocument]); // eslint-disable-line react-hooks/exhaustive-deps

    // - check if CS Authority document has been downloaded - note this state is retained if the screen is reloaded without a route change
    const [isCsAuthoritySupportingDocumentDownloaded, setIsCsAuthoritySupportingDocumentDownloaded] = useState(false);
    function handleCsAuthoritySupportingDocumentDownloaded() {
        setIsCsAuthoritySupportingDocumentDownloaded(true);
    }

    // - check if CS POA document has been downloaded - note this state is retained if the screen is reloaded without a route change
    const [isCsPoaSupportingDocumentDownloaded, setIsCsPoaSupportingDocumentDownloaded] = useState(false);
    function handleCsPoaDocumentDownloaded() {
        setIsCsPoaSupportingDocumentDownloaded(true);
    }

    // approve / reject radio button change
    function onApproveRejectRadioChange(value: string): void {
        setApproveRejectRadioValue(value);

        if (value === isSignerApprovedRadioValue) {
            setState({ ...state, isApproved: true });
            actions.viewChanged(vm.refreshCompanySignerDecisionChanged({ isApproved: true }));
        }
        if (value === isSignerRejectedRadioValue) {
            setState({ ...state, isApproved: false });
            actions.viewChanged(vm.refreshCompanySignerDecisionChanged({ isApproved: false }));
        }
    }

    // checkbox change event
    function onChange(values: Models.ISecureRequestCompanySignerDecisionViewModel): void {
        const updatedState = { ...state, ...values };
        setState(updatedState);
        actions.viewChanged(vm.refreshCompanySignerDecisionChanged(updatedState));
    }

    // view
    return (
        <div className="card my-2">
            <div className="card-body">
                {AlertsHelper.renderInfo(["Approving this request will send the Company Signer request to NOPTA for approval."])}
                <h2 className="card-title">Company Signer Requested</h2>
                <h4>Identification and Verification</h4>
                <Forms.Fieldset disabled={state.isApproved === false}>
                    <Forms.Checkbox
                        id="isIdentificationAndVerificationConfirmedField"
                        label="I confirm that I have taken measures to validate the identity of the authorised signatory named on this form."
                        checked={state.isIdentificationAndVerificationConfirmed}
                        valid={v.applyValidity("CompanySignerDetails.IsIdentificationChecklistComplete")}
                        onChange={(checked) => onChange({ isIdentificationAndVerificationConfirmed: checked })}
                    />
                    <ValidationMessageView name="CompanySignerDetails.IsIdentificationChecklistComplete" validationModel={v} />
                </Forms.Fieldset>
                <h4>Acknowledgement and Signatures</h4>
                <Forms.Group>
                    Company Signer Form Request:{" "}
                    {signerDetails.csAuthoritySupportingDocument ? (
                        <SecureFileDownloadLinkControl id={signerDetails.csAuthoritySupportingDocument.fileId} onDownloadClicked={handleCsAuthoritySupportingDocumentDownloaded}>
                            {signerDetails.csAuthoritySupportingDocument.fileName}
                        </SecureFileDownloadLinkControl>
                    ) : (
                        <DashIcon />
                    )}
                    <ValidationMessageView name="CompanySignerDetails.IsAcknowledgementChecklistComplete" validationModel={v} />
                </Forms.Group>
                <Forms.Fieldset disabled={state.isApproved === false || !isCsAuthoritySupportingDocumentDownloaded}>
                    <Forms.Checkbox
                        id="isRoleConfirmedField"
                        label="I confirm that the stated role/position of the authorised signatory is correct."
                        checked={state.isRoleConfirmed}
                        valid={v.applyValidity("CompanySignerDetails.IsAcknowledgementChecklistComplete")}
                        onChange={(checked) => onChange({ isRoleConfirmed: checked })}
                    />
                    <Forms.Checkbox
                        id="isElectronicSignatureConfirmedField"
                        label="I confirm that the authorised signatory is specifically permitted to execute NOPTA forms on behalf of the titleholder by use of an electronic signature."
                        checked={state.isElectronicSignatureConfirmed}
                        valid={v.applyValidity("CompanySignerDetails.IsAcknowledgementChecklistComplete")}
                        onChange={(checked) => onChange({ isElectronicSignatureConfirmed: checked })}
                    />
                    <Forms.Checkbox
                        id="isAuthorityConfirmedField"
                        label="I confirm that the authorised signatory named on this form has authority from the titleholder to execute documents on behalf of the titleholder."
                        checked={state.isAuthorityConfirmed}
                        valid={v.applyValidity("CompanySignerDetails.IsAcknowledgementChecklistComplete")}
                        onChange={(checked) => onChange({ isAuthorityConfirmed: checked })}
                    />
                    <Forms.Text>
                        For information on who may bind a titleholder on NOPTA forms see NOPTA's <ExternalLink href={props.csNoptaSignatureGuidanceLink}>Signature Guidance</ExternalLink>
                    </Forms.Text>
                </Forms.Fieldset>
                {signerDetails.isCompanySignerPoaRequest && (
                    <>
                        <h4>Power of Attorney</h4>
                        <p>The authorised signatory is the holder of a power of attorney from the titleholder.</p>
                        <Forms.Group>
                            POA document:{" "}
                            {signerDetails.csPoaSupportingDocument ? (
                                <SecureFileDownloadLinkControl id={signerDetails.csPoaSupportingDocument.fileId} onDownloadClicked={handleCsPoaDocumentDownloaded}>
                                    {signerDetails.csPoaSupportingDocument.fileName}
                                </SecureFileDownloadLinkControl>
                            ) : (
                                <DashIcon />
                            )}
                            <ValidationMessageView name="CompanySignerDetails.IsPowerOfAttorneyChecklistComplete" validationModel={v} />
                        </Forms.Group>
                        <Forms.Fieldset disabled={state.isApproved === false || !isCsPoaSupportingDocumentDownloaded}>
                            {" "}
                            <Forms.Checkbox
                                id="isPoaValidityConfirmedField"
                                label="I have reviewed the power of attorney to ensure it provides the authority required, and I am confident of its validity."
                                checked={state.isPoaValidityConfirmed}
                                valid={v.applyValidity("CompanySignerDetails.IsPowerOfAttorneyChecklistComplete")}
                                onChange={(checked) => onChange({ isPoaValidityConfirmed: checked })}
                            />
                        </Forms.Fieldset>
                    </>
                )}
                <h4>Approval</h4>
                <p>
                    The information I have provided above is accurate, complete and not misleading in accordance with section 137.1 of the Schedule to the Criminal Code 1995 (Cth). It is an offence
                    for a person to knowingly give false or misleading information to the Commonwealth.
                </p>
                <fieldset className={Forms.GroupClass}>
                    <Forms.RadioButton
                        name="isSignerApprovedGroup"
                        id="isSignerApprovedRadioField"
                        label="Approve"
                        value={isSignerApprovedRadioValue}
                        checked={approveRejectRadioValue === isSignerApprovedRadioValue}
                        valid={v.applyValidity("CompanySignerDetails.IsApproved")}
                        onChange={onApproveRejectRadioChange}
                        inline
                    />
                    <Forms.RadioButton
                        name="isSignerApprovedGroup"
                        id="isSignerRejectedRadioField"
                        label="Reject"
                        value={isSignerRejectedRadioValue}
                        checked={approveRejectRadioValue === isSignerRejectedRadioValue}
                        valid={v.applyValidity("CompanySignerDetails.IsApproved")}
                        onChange={onApproveRejectRadioChange}
                        inline
                    />
                    <ValidationMessageView name="CompanySignerDetails.IsApproved" validationModel={v} />
                </fieldset>
            </div>
        </div>
    );
}
