// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Models from "../../models/models";
import * as Actions from "../actions";
import { IShellReduxState } from "../../../shellReduxRegistration";
// infrastructure
import msalAuthService from "../../../../infrastructure/msalAuthService";
// common
import * as shellHelper from "../../../../common/shell/shellHelper";
import * as toastHelper from "../../../../common/toastHelper";
import * as logHelper from "../../../../common/LogHelper";

const getRootViewModel = (state: IShellReduxState) => state.Shell_SignIn;

export function* signOut() {
    try {
        yield put(shellHelper.createSetBusyAction());
        yield call(signOutInternal);

        let vm: Models.IRootViewModel = yield select(getRootViewModel);
        vm = vm.refreshOnSignOut();
        yield put(Actions.actionFactory.sagaCompletedAction(vm));
    } catch (ex) {
        logHelper.logError(ex);
        toastHelper.showExceptionNotification("Sign Out failed.");
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function signOutInternal(): Promise<void> {
    await msalAuthService.signOut();
}
