// views
import BadgeView from "./BadgeView";

export function getPendingReviewBadge(toolTiptext?: string) {
    const titleText =
        toolTiptext === undefined
            ? "This user has a pending Request awaiting approval. The pending Request must be completed before the user's permissions for that company can be manually adjusted."
            : toolTiptext;
    return <BadgeView text="PR" title={titleText} variant="primary" />;
}

export function getCompanyThirtyDayWarningBadge() {
    return <BadgeView text="30" title="The User has no permissions for one or more of your Companies. The User will be removed in 30 days or less." variant="warning" />;
}

export function getGeoscienceAustraliaThirtyDayWarningBadge() {
    return <BadgeView text="30" title="The User has no permissions to Geoscience Australia. The User will be removed in 30 days or less." variant="warning" />;
}

export function getBlockedUserBadge(toolTiptext?: string) {
    const titleText =
        toolTiptext === undefined ? "This user has been blocked and can not access any company information. Please contact neats.admin@nopta.gov.au for further information." : toolTiptext;
    return <BadgeView text="BLKD" title={titleText} variant="danger" />;
}
