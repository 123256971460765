// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
// common
import * as Forms from "../../../../common/forms/BootstrapForms";

export function RequestSubmittedView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;

    return (
        <div className="card mb-2">
            <div className="card-body">
                <Forms.Group>
                    <p>Your request has been submitted (Reference: {vm.service.referenceNumber}). You will receive an email shortly.</p>
                </Forms.Group>
            </div>
        </div>
    );
}
