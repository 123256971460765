import { call, put, select } from "redux-saga/effects";
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { ICompanyReduxState } from "../../../../companyReduxRegistration";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as LogHelper from "../../../../../common/LogHelper";

const getRootViewModel = (state: ICompanyReduxState) => state.Company_Opggs_TitleDetails;

export default function* getMap(action: Actions.IAction<string>) {
    const titleId = action.payload;

    // note, the map can be quite slow
    // it has its own special busy indicator, do not set a global busy state
    try {
        const result: CallHelper.CallResult<Client.GetCompanyOpggsTitleDetailsMapResponseDto> = yield call(async () => {
            const client = await ClientFactory.createCompanyOpggsTitleDetailsClient();
            return await CallHelper.call(() => client.getCompanyOpggsTitleDetailsMap(new Client.GetCompanyOpggsTitleDetailsMapRequestDto({ titleId: titleId })));
        });

        if (!result.IsSuccess) {
            result.ShowToastNotification();
        } else {
            const vm: Models.IRootViewModel = yield select(getRootViewModel);
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshMap(result.Response!)));
        }
    } catch (ex) {
        LogHelper.logError(ex);
    }
}
