// framework
import { useEffect, useState } from "react";
// kendo
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
// api
import * as Client from "../../../../../api/Client";
// common
import Help from "../../../../../common/help/HelpView";
//import ExternalLink from "../../../../../common/externalLink/ExternalLinkView";
import * as Forms from "../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";

export function QuestionsView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; jasNoptaSignatureGuidanceLink: string | undefined }) {
    const vm = props.vm;
    const questions = vm.user.questions;
    const actions = props.actions;

    return (
        <div className="card mb-2">
            <div className="card-body">
                {vm.viewState.showJointAuthorityList && (
                    <Forms.Group>
                        <JointAuthoritiesListView vm={vm} actions={actions} />
                    </Forms.Group>
                )}
                {(vm.viewState.showExistingJointAuthorityNewAdministratorWizard ||
                    vm.viewState.showExistingJointAuthorityExistingAdministratorWizard ||
                    vm.viewState.showJointAuthoritySignerWizard ||
                    !vm.viewState.showJointAuthorityList) && (
                    <p>
                        You have requested access to <i>{questions.selectedJointAuthority?.jointAuthorityName} Joint Authority</i>
                    </p>
                )}
                {vm.viewState.showExistingAccessRequestWizard && <ExistingAccessRequestView />}
                {vm.viewState.showExistingFullAccessWizard && <ExistingFullAccessView />}
                {vm.viewState.showExistingJointAuthorityAdministratorWizard && <ExistingJointAuthorityAdministratorView />}
                {vm.viewState.showExistingJointAuthorityStandardRolesWizard && <ExistingJointAuthorityStandardRolesView />}
                {vm.viewState.showExistingJointAuthorityNewAdministratorWizard && (
                    <Forms.Group>
                        <JointAuthorityAdministratorInformationView />
                    </Forms.Group>
                )}
                {vm.viewState.showExistingJointAuthorityExistingAdministratorWizard && (
                    <Forms.Group>
                        <p className="fw-bold">Are you applying to be a Joint Authority Administrator?</p>
                        <JointAuthorityAdministratorRadioButtonView vm={vm} actions={actions} />
                        <JointAuthorityAdministratorInformationView />
                    </Forms.Group>
                )}
                {/* todo nduja 
                {vm.viewState.showJointAuthoritySignerWizard && (
                    <Forms.Group>
                        <p className="fw-bold">Are you applying to be a JointAuthority Signer?</p>
                        <JointAuthoritySignerRadioButtonView vm={vm} actions={actions} />
                        <JointAuthoritySignerInformationView csNoptaSignatureGuidanceLink={props.csNoptaSignatureGuidanceLink} />
                    </Forms.Group>
                )} */}
                {vm.viewState.showInvalidRequestWizard && <InvalidRequestView />}
            </div>
        </div>
    );
}

function JointAuthoritiesListView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const questions = vm.user.questions;
    const jointAuthorities = vm.reference.jointAuthorities;
    const actions = props.actions;

    const [data, setData] = useState<Array<Client.SecureUserJointAuthorityDto>>();
    const [selectedItem, setSelectedItem] = useState<Client.SecureUserJointAuthorityDto | undefined | null>(questions.selectedJointAuthority);

    // update reference data if changed
    useEffect(() => {
        setData(jointAuthorities);
    }, [jointAuthorities]);

    // update selected item if changed - must be set to null in order to programatically clear the combobox
    useEffect(() => {
        if (questions.selectedJointAuthority) {
            setSelectedItem(questions.selectedJointAuthority);
        } else {
            setSelectedItem(null);
            setData(jointAuthorities);
        }
    }, [questions.selectedJointAuthority, jointAuthorities]);

    // filter
    const filterChange = (event: any) => {
        const data = filterBy(jointAuthorities!.slice(), event.filter);
        setData(data);
    };

    // on change
    const onChange = (event: any) => {
        const i: Client.SecureUserJointAuthorityDto | undefined = event.value;

        setSelectedItem(i);
        actions.viewChanged(vm.refreshSelectedJointAuthorityChanged(i ?? undefined));
    };

    return (
        <>
            <Forms.Label htmlFor="jointAuthoritiesList">
                <strong>Select the Jurisdiction that you are requesting access to</strong>
            </Forms.Label>
            <ComboBox
                id="jointAuthoritiesList"
                className="mb-2"
                data={data}
                dataItemKey="jointAuthorityId"
                value={selectedItem}
                textField="jointAuthorityName"
                filterable={true}
                onFilterChange={filterChange}
                onChange={onChange}
                popupSettings={{
                    height: "auto",
                }}
            />
        </>
    );
}

function ExistingAccessRequestView() {
    return <Forms.Group>You have an existing request in place for this Joint Authority. Please wait for this to complete before lodging any further requests.</Forms.Group>;
}

function ExistingFullAccessView() {
    return (
        <Forms.Group>
            <span className="fw-bold">You already have full access for this Joint Authority.</span>
        </Forms.Group>
    );
}

function ExistingJointAuthorityAdministratorView() {
    return <Forms.Group>You already have Joint Authority Administrator access which includes all access except Joint Authority Application Signer.</Forms.Group>;
}

function ExistingJointAuthorityStandardRolesView() {
    return (
        <Forms.Group>
            You already have all <span className="fw-bold">Joint Authority General Permissions</span> which includes all access except Joint Authority Administrator and Application Signer.
        </Forms.Group>
    );
}

function JointAuthorityAdministratorInformationView() {
    return (
        <Help title="What is a Joint Authority Administrator?">
            A Joint Authority Administrator is a role that is approved by delegated decision maker of the Joint Authority, Commonwealth Minister, or Greenhouse Gas Cross Boundary Authority under the
            OPGGSA 2006 and then is able to approve access for other users. A Joint Authority Administrator is able to assign all permissions within NEATS for their Joint Authority with the exception
            of approving another Joint Authority Administrator.
        </Help>
    );
}

// todo nduja
// function JointAuthoritySignerInformationView(props: { jasNoptaSignatureGuidanceLink: string | undefined }) {
//     return (
//         <Help title="What is a Joint Authority Signer?">
//             A JointAuthority Signer will have the authority to digitally sign on behalf of the Joint Authority within this portal. The form needs to be executed in accordance with NOPTA's{" "}
//             <ExternalLink href={props.jasNoptaSignatureGuidanceLink}>Signature Guidance</ExternalLink>.
//         </Help>
//     );
// }

function JointAuthorityAdministratorRadioButtonView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const questions = vm.user.questions;
    const actions = props.actions;

    const isJaaRequestRadioValue = "isJaaRequest";
    const isNotJaaRequestRadioValue = "isNotJaaRequest";
    const [selectedJaaRequestRadioValue, setSelectedJaaRequestRadioValue] = useState(
        GlobalHelpers.convertBooleanToRadioValue(questions.isJointAuthorityAdministratorRequest, isJaaRequestRadioValue, isNotJaaRequestRadioValue)
    );

    // update selection if changed
    useEffect(() => {
        setSelectedJaaRequestRadioValue(GlobalHelpers.convertBooleanToRadioValue(questions.isJointAuthorityAdministratorRequest, isJaaRequestRadioValue, isNotJaaRequestRadioValue));
    }, [questions.isJointAuthorityAdministratorRequest]);

    // on change
    function onChange(value: string) {
        setSelectedJaaRequestRadioValue(value);
        if (value === isJaaRequestRadioValue) actions.viewChanged(vm.refreshQuestionsUpdated({ isJointAuthorityAdministratorRequest: true }));
        if (value === isNotJaaRequestRadioValue) actions.viewChanged(vm.refreshQuestionsUpdated({ isJointAuthorityAdministratorRequest: false }));
    }

    return (
        <>
            <Forms.RadioButton
                name="isJaaRequestGroup"
                id="isJaaRequestRadioField"
                value={isJaaRequestRadioValue}
                checked={selectedJaaRequestRadioValue === isJaaRequestRadioValue}
                label="Yes"
                onChange={onChange}
                inline
            />
            <Forms.RadioButton
                name="isJaaRequestGroup"
                id="isNotJaaRequestRadioField"
                value={isNotJaaRequestRadioValue}
                checked={selectedJaaRequestRadioValue === isNotJaaRequestRadioValue}
                label="No"
                onChange={onChange}
                inline
            />
        </>
    );
}

// todo nduja
// function JointAuthoritySignerRadioButtonView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }) {
//     const vm = props.vm;
//     const questions = vm.user.questions;
//     const actions = props.actions;

//     const isCsRequestRadioValue = "isCsRequest";
//     const isNotCsRequestRadioValue = "isNotCsRequest";
//     const [selectedCsRequestRadioValue, setSelectedCsRequestRadioValue] = useState(
//         GlobalHelpers.convertBooleanToRadioValue(questions.isJointAuthoritySignerRequest, isCsRequestRadioValue, isNotCsRequestRadioValue)
//     );

//     // update selection if changed
//     useEffect(() => {
//         setSelectedCsRequestRadioValue(GlobalHelpers.convertBooleanToRadioValue(questions.isJointAuthoritySignerRequest, isCsRequestRadioValue, isNotCsRequestRadioValue));
//     }, [questions.isJointAuthoritySignerRequest]);

//     // on change
//     function onChange(value: string) {
//         setSelectedCsRequestRadioValue(value);
//         if (value === isCsRequestRadioValue) actions.viewChanged(vm.refreshQuestionsUpdated({ isJointAuthoritySignerRequest: true }));
//         if (value === isNotCsRequestRadioValue) actions.viewChanged(vm.refreshQuestionsUpdated({ isJointAuthoritySignerRequest: false }));
//     }

//     return (
//         <>
//             <Forms.RadioButton
//                 name="isCsRequestGroup"
//                 id="isCsRequestRadioField"
//                 value={isCsRequestRadioValue}
//                 checked={selectedCsRequestRadioValue === isCsRequestRadioValue}
//                 label="Yes"
//                 onChange={onChange}
//                 inline
//             />
//             <Forms.RadioButton
//                 name="isCsRequestGroup"
//                 id="isNotCsRequestRadioField"
//                 value={isNotCsRequestRadioValue}
//                 checked={selectedCsRequestRadioValue === isNotCsRequestRadioValue}
//                 label="No"
//                 onChange={onChange}
//                 inline
//             />
//         </>
//     );
// }

function InvalidRequestView() {
    return (
        <Forms.Group>
            <span className="fw-bold">You have not requested any new permissions. Change your selections to make a request.</span>
        </Forms.Group>
    );
}
