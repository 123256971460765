// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../api/Client";

export enum ViewStateEnum {
    Initialising,
    View,
    EditFeedbackResponse,
}

interface IReferenceDataViewModel {
    assignmentGroups: Client.GetGeoscienceAustraliaFeedbackReferenceDataResponseAssignmentGroupDto[];
    assignedUsers: Client.GetGeoscienceAustraliaFeedbackReferenceDataResponseAssignedUserDto[];
}

export interface IRequestDetailsViewModel {
    feedbackNumber?: string | undefined;
    submittedDate?: Date | undefined;
    feedbackType?: Client.FeedbackTypeEnum | undefined;
    feedbackTypeName?: string | undefined;
    customerComments?: string | undefined;
    nopimsActivityTypeName?: string | undefined;
    dataMonitoringItemTypeName?: string | undefined;
    dataFeedbackActivityName?: string | undefined;
}

export interface IContactDetailsViewModel {
    honorificName?: string | undefined;
    customerFirstName?: string | undefined;
    customerLastName?: string | undefined;
    customerCompanyName?: string | undefined;
    customerContactNumber?: string | undefined;
    customerEmailAddress?: string | undefined;
}

export interface IFeedbackResponseViewModel {
    statusMessages?: Client.StatusMessagesDto | undefined;
    status?: string | undefined;
    assignmentGroup?: Client.GetGeoscienceAustraliaFeedbackReferenceDataResponseAssignmentGroupDto | undefined;
    assignedUser?: Client.GetGeoscienceAustraliaFeedbackReferenceDataResponseAssignedUserDto | undefined;
    completionComments?: string | undefined;
    isComplete?: boolean | undefined;
}

export interface IRootViewModel {
    id: number | undefined;
    identifier?: string | undefined;
    canManage: boolean;
    versionNumber: number | undefined;
    audit?: Client.SecurePortalSimpleAuditEventsDto | undefined;
    statusMessages?: Client.StatusMessagesDto | undefined;
    viewState: ViewStateEnum;
    isConflict: boolean;
    isDirty: boolean;

    referenceData: IReferenceDataViewModel;
    requestDetails: IRequestDetailsViewModel;
    contactDetails: IContactDetailsViewModel;
    feedbackResponse: IFeedbackResponseViewModel;

    onEditFeedbackResponse(): IRootViewModel;
    onFeedbackResponseChanged(feedbackResponse: IFeedbackResponseViewModel): IRootViewModel;
    refreshReferenceData(response: Client.GetGeoscienceAustraliaFeedbackReferenceDataResponseDto): IRootViewModel;
    refreshFeedbackDetails(response: Client.GetGeoscienceAustraliaFeedbackDetailsResponseDto): IRootViewModel;
    refreshStatusMessages(isComplete: boolean, statusMessages: Client.StatusMessagesDto): IRootViewModel;
    refreshConflict(): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.canManage = false;
        this.viewState = ViewStateEnum.Initialising;
        this.isConflict = false;
        this.isDirty = false;

        this.referenceData = {
            assignmentGroups: [],
            assignedUsers: [],
        };
        this.requestDetails = {};
        this.contactDetails = {};
        this.feedbackResponse = {};
    }

    public id: number | undefined;
    public identifier: string | undefined;
    public canManage: boolean;
    public versionNumber: number | undefined;
    public audit?: Client.SecurePortalSimpleAuditEventsDto | undefined;
    public statusMessages?: Client.StatusMessagesDto | undefined;
    public viewState: ViewStateEnum;
    public isConflict: boolean;
    public isDirty: boolean;

    public referenceData: IReferenceDataViewModel;
    public requestDetails: IRequestDetailsViewModel;
    public contactDetails: IContactDetailsViewModel;
    public feedbackResponse: IFeedbackResponseViewModel;

    public onEditFeedbackResponse(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.View) throw new Error("Invalid state.");
        const vm = this._clone();
        vm.viewState = ViewStateEnum.EditFeedbackResponse;
        vm.isDirty = false;
        vm.statusMessages = undefined;
        vm.feedbackResponse.statusMessages = undefined;
        return vm;
    }

    onFeedbackResponseChanged(feedbackResponse: IFeedbackResponseViewModel): IRootViewModel {
        const vm = this._clone();
        vm.feedbackResponse = feedbackResponse;
        vm.isDirty = true;
        return vm;
    }

    public refreshReferenceData(response: Client.GetGeoscienceAustraliaFeedbackReferenceDataResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.referenceData = {
            assignmentGroups: response.assignmentGroups,
            assignedUsers: response.assignedUsers,
        };
        return vm;
    }

    public refreshFeedbackDetails(response: Client.GetGeoscienceAustraliaFeedbackDetailsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.id = response.id;
        vm.identifier = response.identifier;
        vm.canManage = response.canManage;
        vm.versionNumber = response.versionNumber;
        vm.audit = response.audit;
        vm.statusMessages = undefined;
        vm.viewState = ViewStateEnum.View;
        vm.isConflict = false;
        vm.isDirty = false;
        vm.requestDetails = {
            feedbackNumber: response.identifier,
            submittedDate: response.submittedDate,
            feedbackType: response.feedbackType,
            feedbackTypeName: response.feedbackTypeName,
            customerComments: response.customerComments,
            nopimsActivityTypeName: response.nopimsActivityTypeName,
            dataMonitoringItemTypeName: response.dataMonitoringItemTypeName,
            dataFeedbackActivityName: response.dataFeedbackActivityName,
        };
        vm.contactDetails = {
            honorificName: response.honorificName,
            customerFirstName: response.customerFirstName,
            customerLastName: response.customerLastName,
            customerCompanyName: response.customerCompanyName,
            customerContactNumber: response.customerContactNumber,
            customerEmailAddress: response.customerEmailAddress,
        };
        vm.feedbackResponse = {
            statusMessages: undefined,
            status: response.status,
            assignmentGroup: this.referenceData.assignmentGroups.find((g) => g.assignmentGroup === response.assignmentGroup),
            assignedUser: this.referenceData.assignedUsers.find((u) => u.id === response.assignedUserId),
            completionComments: response.completionComments,
            isComplete: response.isComplete,
        };
        return vm;
    }

    refreshStatusMessages(isComplete: boolean, statusMessages: Client.StatusMessagesDto): IRootViewModel {
        const vm = this._clone();
        if (isComplete) {
            vm.statusMessages = statusMessages;
            vm.feedbackResponse.statusMessages = undefined;
        } else {
            vm.statusMessages = undefined;
            vm.feedbackResponse.statusMessages = statusMessages;
        }
        return vm;
    }

    refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        vm.statusMessages = undefined;
        vm.feedbackResponse.statusMessages = undefined;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.requestDetails = clone(this.requestDetails);
        vm.contactDetails = clone(this.contactDetails);
        vm.feedbackResponse = clone(this.feedbackResponse);
        return vm;
    }
}
