// framework
import * as Validation from "../../../../common/validation/ValidationModel";
// models
import * as Models from "./models";

export default function validate(vm: Models.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateAdhocUpload(vm, v);
    return v;
}

function validateAdhocUpload(vm: Models.IRootViewModel, v: Validation.ValidationModel): void {
    if (!vm.configuration) return;

    if (vm.files.length < 1) v.addError("AdhocUpload.Files", "At least one file is required.");

    if (vm.comments && vm.comments.length > 1000) {
        v.addError("AdhocUpload.Comments", "Comments must be 1000 characters or less.");
    }
    if (!vm.applicantCompany) {
        v.addError("AdhocUpload.Applicant", "Applicant is required.");
    }
}
