// redux
import { call, put, select } from "redux-saga/effects";
import { ICompanyReduxState } from "../../../../../companyReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { getIaflDetails } from "./initialise";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: ICompanyReduxState) => state.Company_Oei_DraftApplicationDetails_Iafl;

export default function* setApplicationAsPaying() {
    let vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const saveResult: CallHelper.CallResult<Client.SetCompanyCommonDraftApplicationDetailsDraftApplicationAsPayingResponseDto> = yield call(async () => {
            const client = await ClientFactory.createCompanyCommonDraftApplicationDetailsClient();
            const request = new Client.SetCompanyCommonDraftApplicationDetailsDraftApplicationAsPayingRequestDto({
                id: vm.state.id!,
                versionNumber: vm.state.versionNumber!,
            });
            return await CallHelper.call(() => client.setCompanyCommonDraftApplicationDetailsDraftApplicationAsPaying(request));
        });

        if (saveResult.IsSuccess) {
            // success
            const r = saveResult.Response!;
            if (r.statusMessages.isSuccess) {
                const iarlResponse: Client.GetCompanyOeiDraftApplicationDetailsIaflResponseDto = yield call(getIaflDetails, vm.state.id!);
                vm = vm.refreshDetails(iarlResponse);
            }
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshSaveApplicationDetailsResponse(r.statusMessages)));
        } else if (saveResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            saveResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
