// framework
import { useState, useEffect } from "react";
// common
import HelpView from "../../common/help/HelpView";

interface IProps {
    searchText: string;
    searchTextDescription: string;
    onSearchSubmit: (value: string) => void;
    onSearchClear: () => void;
}

export default function SearchBarView(props: IProps): JSX.Element {
    const { searchText, searchTextDescription, onSearchSubmit, onSearchClear } = props;

    const [value, setValue] = useState<string>("");
    useEffect(() => {
        setValue(searchText);
    }, [searchText]);

    function onClear() {
        setValue("");
        onSearchClear();
    }

    return (
        <>
            <div className="row my-2">
                <div className="col-3">
                    <input
                        id="searchText"
                        className="form-control"
                        maxLength={100}
                        type="text"
                        placeholder="Search"
                        value={value}
                        onKeyUp={(e) => {
                            if (e.key === "Enter") onSearchSubmit(value);
                        }}
                        onChange={(e) => setValue(e.target.value)}
                    />
                </div>
                <button type="button" className="btn btn-secondary me-2 col-auto" onClick={() => onSearchSubmit(value)}>
                    Search
                </button>
                <button type="button" className="btn btn-secondary me-2 col-auto" onClick={onClear}>
                    Clear
                </button>
            </div>
            <HelpView title="What does this search on?" className="mb-2">
                {searchTextDescription}
            </HelpView>
        </>
    );
}
