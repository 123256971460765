interface IEnvironmentBadgeViewProps {
    className?: string | undefined;
}

export default function EnvironmentBadgeView(props: IEnvironmentBadgeViewProps): React.ReactElement {
    const environment: string = process.env.REACT_APP_CONFIGURATION_ENVIRONMENT!;
    const version: string = process.env.REACT_APP_CONFIGURATION_VERSION!;
    const showBadge: boolean = process.env.REACT_APP_CONFIGURATION_SHOW_ENVIRONMENT_BADGE! === "true";

    if (!showBadge) return <></>;

    return (
        <span className={props.className}>
            <span className="badge bg-danger align-middle mx-md-4" style={{ fontSize: 18 }}>
                <span>{environment}</span>
                <br />
                <span>Version: {version}</span>
            </span>
        </span>
    );
}
