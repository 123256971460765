// framework
import { useState, useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// views
import FinancialNotificationView from "./views/FinancialNotificationView";

interface IControllerProps extends Actions.IActionFactory {
    id: string;
    cardTitle: string;
    lastRefreshedDatetime: Date | undefined;
    className?: string | undefined;
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const [lastRefreshedDatetime, setLastRefreshedDatetime] = useState<Date | undefined>(undefined);

    // on mount
    // - initial load
    useEffect(() => {
        actions.initialiseRequested(props.id);
        return function () {
            actions.clearRequested();
        };
    }, [props.id]); // eslint-disable-line react-hooks/exhaustive-deps

    // refresh
    // - reasons to refresh include (1) the user pressing the refresh button
    // - don't refresh just because the parent refreshed, this can cause us to immediately lose status changes when a save occurs here
    // - also be careful to ensure a refresh only occurs once, it's easy to make a mistake and have the service call happen multiple times
    useEffect(() => {
        // console.info(vm.viewState, props.lastRefreshedDatetime, lastRefreshedDatetime);

        // if it's initialising, just wait
        if (vm.viewState === Models.ViewStateEnum.Initialising) return;

        // a manual refresh has been requested
        if (lastRefreshedDatetime !== props.lastRefreshedDatetime) {
            setLastRefreshedDatetime(props.lastRefreshedDatetime);
            actions.initialiseRequested(props.id);
        }
    }, [vm.viewState, props.lastRefreshedDatetime, lastRefreshedDatetime]); // eslint-disable-line react-hooks/exhaustive-deps

    // return
    return (
        <div className={props.className}>
            <div className="card" style={{ minWidth: 250 }}>
                <div className="card-body">
                    <h4 className="card-title">{props.cardTitle}</h4>

                    {/* financial notification */}
                    <div className="mb-4">{vm.viewState === Models.ViewStateEnum.View && <FinancialNotificationView vm={vm} />}</div>
                </div>
            </div>
        </div>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_Opggs_ApplicationDetails_Controls_FinancialNotification }), Actions.actionFactory)(Controller);
