// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IDataMonitoringItemViewModel {
    name?: string | undefined;
    identifier?: string | undefined;

    reprocessing?: Client.GetGeoscienceAustraliaReprocessingsProjectsDataMonitoringItemDetailsResponseReferenceDto | undefined;
    project?: Client.GetGeoscienceAustraliaReprocessingsProjectsDataMonitoringItemDetailsResponseReferenceDto | undefined;
    duplicateDataMonitoringItems: Client.GetGeoscienceAustraliaReprocessingsProjectsDataMonitoringItemDetailsResponseDuplicateDataMonitoringItemDto[];
    dataSubmission?: Client.GetGeoscienceAustraliaReprocessingsProjectsDataMonitoringItemDetailsResponseDataSubmissionDto | undefined;
    authorisations: Client.GetGeoscienceAustraliaReprocessingsProjectsDataMonitoringItemDetailsResponseAuthorisationDto[];
    resubmissions: Client.GetGeoscienceAustraliaReprocessingsProjectsDataMonitoringItemDetailsResponseResubmissionDto[];

    status?: string | undefined;
    dueDate?: Date | undefined;
    projectCalcDueDate?: Date | undefined;
    extensionDueDate?: Date | undefined;
    resubmissionDueDate?: Date | undefined;
    receivedDate?: Date | undefined;
    contentsCheckCompletedDate?: Date | undefined;
    dataCheckCompletedDate?: Date | undefined;
    sentToGaDate?: Date | undefined;

    comments?: string | undefined;
}

export interface IRootViewModel {
    dataMonitoringItem: IDataMonitoringItemViewModel;

    permissions: {
        canViewReprocessings: boolean;
        canViewProjects: boolean;
        canViewDataSubmissions: boolean;
        canViewAuthorisations: boolean;
    };

    audit?: Client.SecurePortalSimpleAuditEventsDto;

    refreshDataMonitoringItemDetails(response: Client.GetGeoscienceAustraliaReprocessingsProjectsDataMonitoringItemDetailsResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.dataMonitoringItem = {
            reprocessing: undefined,
            project: undefined,
            duplicateDataMonitoringItems: [],
            dataSubmission: undefined,
            authorisations: [],
            resubmissions: [],

            dueDate: undefined,
            projectCalcDueDate: undefined,
            extensionDueDate: undefined,
            resubmissionDueDate: undefined,
            receivedDate: undefined,
            contentsCheckCompletedDate: undefined,
            dataCheckCompletedDate: undefined,
            sentToGaDate: undefined,

            comments: undefined,
        };

        this.permissions = {
            canViewReprocessings: false,
            canViewProjects: false,
            canViewDataSubmissions: false,
            canViewAuthorisations: false,
        };
    }

    public dataMonitoringItem: IDataMonitoringItemViewModel;

    public permissions: {
        canViewReprocessings: boolean;
        canViewProjects: boolean;
        canViewDataSubmissions: boolean;
        canViewAuthorisations: boolean;
    };

    public audit?: Client.SecurePortalSimpleAuditEventsDto | undefined;

    public refreshDataMonitoringItemDetails(response: Client.GetGeoscienceAustraliaReprocessingsProjectsDataMonitoringItemDetailsResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.dataMonitoringItem = {
            name: response.name,
            identifier: response.identifier,

            reprocessing: response.reprocessingDetails?.reprocessing,
            project: response.reprocessingDetails?.project,
            duplicateDataMonitoringItems: response.duplicateDataMonitoringItems,
            dataSubmission: response.dataSubmission,
            authorisations: response.authorisations,
            resubmissions: response.resubmissions,

            status: response.status,
            dueDate: response.dueDate,
            projectCalcDueDate: response.reprocessingDetails?.projectCalcDueDate,
            extensionDueDate: response.extensionDueDate,
            resubmissionDueDate: response.resubmissionDueDate,
            receivedDate: response.receivedDate,
            contentsCheckCompletedDate: response.contentsCheckCompletedDate,
            dataCheckCompletedDate: response.dataCheckCompletedDate,
            sentToGaDate: response.sentToGaDate,

            comments: response.comments,
        };

        vm.permissions = {
            canViewReprocessings: response.canViewReprocessings,
            canViewProjects: response.canViewProjects,
            canViewDataSubmissions: response.canViewDataSubmissions,
            canViewAuthorisations: response.canViewAuthorisations,
        };

        vm.audit = response.audit;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.dataMonitoringItem = clone(this.dataMonitoringItem);
        return vm;
    }
}
