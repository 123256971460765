// framework
import { Redirect } from "react-router-dom";
// shell
import BodyLayout from "../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../shell/layout/navigationItemTypes";
import SignInAction from "../../shell/signIn/SignInActionController";
import ResetPasswordAction from "../../shell/signIn/ResetPasswordActionController";
// common
import * as shellHelper from "../../common/shell/shellHelper";
import EmailLinkView from "../../common/externalLink/EmailLinkView";

export default function Controller() {
    // redirect the user to the default page after-sign in, from the sign-in page only
    // (if the user signs in from somewhere else, we want to keep them on that page)
    if (shellHelper.useIsSignedIn()) {
        return <Redirect to="/" />;
    }

    return (
        <BodyLayout title="Sign In" selectedLhsNavItem={Level1ItemTypeEnum.Common_Home_SignIn}>
            <div className="mb-2">
                <div className="mb-1">
                    You will need to sign-in to access the system. Please click the link below to start the sign-in process. To access the system, you will need to use the email address you have
                    registered with the system, or your NEATS user name.
                </div>
                <SignInAction className="mb-2" />
            </div>
            <div className="mb-2">
                <div className="mb-1">If you have forgotten your password, you may reset it here.</div>
                <ResetPasswordAction className="mb-2" />
            </div>
            <div className="mb-2">
                If you have forgotten the email address you have registered with the system, and your NEATS user name, then please contact your Company Administrator. If they are unavailable or you
                are not sure who they are please contact <EmailLinkView emailAddress="neats.admin@nopta.gov.au" />.
            </div>
        </BodyLayout>
    );
}
