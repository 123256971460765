// api
import * as Client from "../../../../../api/Client";
// common
import * as Forms from "../../../../../common/forms/BootstrapForms";
import EmailLinkView from "../../../../../common/externalLink/EmailLinkView";

export default function NewUserDetailsView(props: { vm: Client.CompanyUserManagementRequestDetailsNewUserDetailsDto | undefined }): React.ReactElement {
    const vm = props.vm;
    if (!vm) return <></>;

    return (
        <div className="card mb-2">
            <div className="card-body">
                <h2 className="card-title">New User Details</h2>
                <Forms.Row>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="fullNameField" label="Full Name" value={vm?.fullName} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.Element label="Email">
                            <EmailLinkView emailAddress={vm.emailAddress} />
                        </Forms.Element>
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="positionField" label="Position" value={vm?.position} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="contactNumberField" label="Contact Number" value={vm?.contactNumber} />
                    </Forms.HalfColumn>
                </Forms.Row>
            </div>
        </div>
    );
}
