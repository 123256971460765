// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../../api/Client";

export interface IRootViewModel {
    id: number | undefined;
    versionNumber: number | undefined;

    titleholderForeignInvestmentTypeApprovals: ITitleholderForeignInvestmentApproval[];
    availableForeignInvestmentTypes: Client.ForeignInvestmentApprovalTypeDto[];

    viewState: ViewStateEnum;
    isDirty: boolean;
    isConflict: boolean;
    statusMessages: Client.StatusMessagesDto | undefined;
    audit: Client.SecurePortalSimpleAuditEventsDto | undefined;

    refreshDetails(id: number, response: Client.GetCompanyOpggsDraftApplicationDetailsTitleholderForeignInvestmentResponseDto): IRootViewModel;
    refreshSaveStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel;
    refreshConflict(): IRootViewModel;
    onInitialised(): IRootViewModel;
    onSaved(): IRootViewModel;
    onEdit(): IRootViewModel;
    onTitleholderForeignInvestmentApprovalsChanged(companyId: number, foreignInvestmentApprovalType: Client.ForeignInvestmentApprovalTypeDto | undefined): IRootViewModel;
}

export enum ViewStateEnum {
    Initialising,
    View,
    Edit,
}

export interface ITitleholderForeignInvestmentApproval {
    companyId: number;
    companyName: string;
    acnOrArbn: string;
    foreignInvestmentApprovalType?: Client.ForeignInvestmentApprovalTypeDto | undefined;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.id = undefined;
        this.versionNumber = undefined;

        this.titleholderForeignInvestmentTypeApprovals = [];
        this.availableForeignInvestmentTypes = [];

        this.viewState = ViewStateEnum.Initialising;
        this.isDirty = false;
        this.isConflict = false;
        this.statusMessages = undefined;
        this.audit = undefined;
    }

    public id: number | undefined;
    public versionNumber: number | undefined;

    public titleholderForeignInvestmentTypeApprovals: ITitleholderForeignInvestmentApproval[];
    public availableForeignInvestmentTypes: Client.ForeignInvestmentApprovalTypeDto[];

    public viewState: ViewStateEnum;
    public isDirty: boolean;
    public isConflict: boolean;
    public statusMessages: Client.StatusMessagesDto | undefined;
    public audit: Client.SecurePortalSimpleAuditEventsDto | undefined;

    public refreshDetails(id: number, response: Client.GetCompanyOpggsDraftApplicationDetailsTitleholderForeignInvestmentResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.id = id;
        vm.versionNumber = response.versionNumber;

        vm.titleholderForeignInvestmentTypeApprovals = response.titleholderForeignInvestmentApprovals.map((a) => {
            return new TitleholderForeignInvestmentApproval(a.companyId, a.companyName, a.acnOrArbn, a.foreignInvestmentApprovalType);
        });
        vm.availableForeignInvestmentTypes = response.availableForeignInvestmentApprovalTypes;

        vm.isDirty = false;
        vm.isConflict = false;
        vm.audit = response.lastModifiedAudit;

        return vm;
    }

    public refreshSaveStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.statusMessages = statusMessages;
        if (statusMessages.isSuccess) {
            vm.isDirty = false;
        }

        return vm;
    }

    public refreshConflict(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.statusMessages = undefined;
        vm.isConflict = true; // this is only reset on initialise from the reducer

        return vm;
    }

    public onInitialised(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Initialising) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    public onSaved(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    public onEdit(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.View) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.Edit;
        vm.isDirty = false;
        vm.statusMessages = undefined;

        return vm;
    }

    public onTitleholderForeignInvestmentApprovalsChanged(companyId: number, foreignInvestmentApprovalType: Client.ForeignInvestmentApprovalTypeDto | undefined): IRootViewModel {
        // assert
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");
        const titleholderForeignInvestmentApproval = this.titleholderForeignInvestmentTypeApprovals.find((t) => t.companyId === companyId);
        if (!titleholderForeignInvestmentApproval) throw new Error("Invalid Titleholder.");

        // update
        const updatedTitleholderForeignInvestmentApproval = new TitleholderForeignInvestmentApproval(
            titleholderForeignInvestmentApproval.companyId,
            titleholderForeignInvestmentApproval.companyName,
            titleholderForeignInvestmentApproval.acnOrArbn,
            foreignInvestmentApprovalType
        );

        const vm = this._clone();

        vm.isDirty = true;
        vm.titleholderForeignInvestmentTypeApprovals = this.titleholderForeignInvestmentTypeApprovals.map((t) => (t.companyId === companyId ? updatedTitleholderForeignInvestmentApproval : t));

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}

class TitleholderForeignInvestmentApproval implements ITitleholderForeignInvestmentApproval {
    companyId: number;
    companyName: string;
    acnOrArbn: string;
    foreignInvestmentApprovalType?: Client.ForeignInvestmentApprovalTypeDto | undefined;

    constructor(companyId: number, companyName: string, acnOrArbn: string, foreignInvestmentApprovalType?: Client.ForeignInvestmentApprovalTypeDto | undefined) {
        this.companyId = companyId;
        this.companyName = companyName;
        this.acnOrArbn = acnOrArbn;
        this.foreignInvestmentApprovalType = foreignInvestmentApprovalType;
    }
}
