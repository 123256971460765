// framework
import { useEffect, useState } from "react";
// api
import { GetGeoscienceAustraliaSurveyDataLineItemDto } from "../../../../../../api/Client";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// models
import { IRootViewModel, ISaveSurveyDataLine } from "../../models/models";
// common
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
import CommentSummaryView from "../../../../../../common/commentSummary/commentSummaryView";

export default function SurveyDataLinesListGridView(props: { vm: IRootViewModel; onEdit: (updateItem: ISaveSurveyDataLine) => void; onDelete: (surveyDataLineId: number) => void }): JSX.Element {
    const { vm, onEdit, onDelete } = props;

    const initialState: State = {
        sort: [{ field: "lineName", dir: "asc" }],
        filter: { logic: "and", filters: [] },
        skip: 0,
        take: 20,
    };

    const [gridState, setGridState] = useState(initialState);

    useEffect(() => {
        setGridState(initialState);
    }, [vm.lines]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid
                pageable
                resizable
                sortable
                navigatable
                data={process(vm.lines!, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn field="lineName" title="Line Name" width={300} columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="fsl" title="First Shot Point" width={190} columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="lsl" title="Last Shot Point" width={190} columnMenu={createGridColumnFilterMenu} />
                <GridColumn
                    field="lineComments"
                    title="Line Comments"
                    width={600}
                    columnMenu={createGridColumnFilterMenu}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <CommentSummaryView comment={props.dataItem.lineComments} maxSummaryLength={160} title="Line Comments"></CommentSummaryView>
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    title="Edit"
                    width={70}
                    sortable={false}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <button
                                className="btn btn-secondary"
                                type="button"
                                title="Edit"
                                onClick={() => {
                                    var item = props.dataItem as GetGeoscienceAustraliaSurveyDataLineItemDto;
                                    const saveSurveyDataLine = {
                                        surveyDataLineId: item.id,
                                        lineName: item.lineName,
                                        fsl: item.fsl,
                                        lsl: item.lsl,
                                        lineComments: item.lineComments,
                                    } as ISaveSurveyDataLine;
                                    onEdit(saveSurveyDataLine);
                                }}
                            >
                                Edit
                            </button>
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    title="Delete"
                    width={90}
                    sortable={false}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <button
                                className="btn btn-secondary"
                                type="button"
                                title="Delete"
                                onClick={() => {
                                    onDelete(props.dataItem.id);
                                }}
                            >
                                Delete
                            </button>
                        </CustomGridCell>
                    )}
                />
            </Grid>
        </>
    );
}
