// framework
import * as Validation from "../../../../../common/validation/ValidationModel";
// models
import * as Models from "./models";

export default function validate(vm: Models.IEottlrApplicationDetailsViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateEottlrDetails(vm.eottlrDetails, v);
    return v;
}

function validateEottlrDetails(vm: Models.IEottlrDetailsViewModel, v: Validation.ValidationModel): void {
    if (!vm.extensionDate) {
        v.addError("EottlrDetails.ExtensionDate", "Length of longer period requested is required.");
    } else if (vm.extensionDate > vm.titleExpiryDate!) {
        v.addError("EottlrDetails.ExtensionDate", "Length of longer period requested cannot be after the title expiry date.");
    }
}
