// redux
import * as Models from "../models/models";

// -------
// Actions
// -------

export enum actions {
    // view events
    getDetailsRequested = "company.title.legislativeForms.formRequestDetails.getDetailsRequested",
    clearDetailsRequested = "company.title.legislativeForms.formRequestDetails.clearDetailsRequested",
    saveFormRequestDetailsRequested = "company.title.legislativeForms.formRequestDetails.saveFormRequestDetailsRequested",
    setFormRequestAsReadyForSigningRequested = "company.title.legislativeForms.formRequestDetails.setFormRequestAsReadyForSigningRequested",
    deleteFormRequestRequested = "company.title.legislativeForms.formRequestDetails.deleteFormRequestRequested",
    submitFormRequestToNoptaForApprovalRequested = "company.title.legislativeForms.formRequestDetails.submitFormRequestToNoptaForApprovalRequested",
    // user input
    viewChanged = "company.title.legislativeForms.formRequestDetails.viewChanged",
    // saga events
    sagaCompleted = "company.title.legislativeForms.formRequestDetails.sagaCompleted",
}

// --------
// Payloads
// --------

export interface IAction<T> {
    type: actions;
    payload: T;
}

export interface IFormRequestDetailsKeyPayload {
    id: number;
    type: number;
}

// ---------
// Factories
// ---------

export interface IActionFactory {
    // view events
    getDetailsRequested(formRequestId: number, formRequestType: number): IAction<IFormRequestDetailsKeyPayload>;
    clearDetailsRequested(): IAction<undefined>;
    saveFormRequestDetailsRequested(): IAction<undefined>;
    setFormRequestAsReadyForSigningRequested(): IAction<undefined>;
    deleteFormRequestRequested(): IAction<undefined>;
    submitFormRequestToNoptaForApprovalRequested(): IAction<undefined>;
    // user input
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    // saga events
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    getDetailsRequested: function (formRequestId: number, formRequestType: number): IAction<IFormRequestDetailsKeyPayload> {
        return {
            type: actions.getDetailsRequested,
            payload: { id: formRequestId, type: formRequestType },
        };
    },
    clearDetailsRequested: function (): IAction<undefined> {
        return {
            type: actions.clearDetailsRequested,
            payload: undefined,
        };
    },
    saveFormRequestDetailsRequested: function (): IAction<undefined> {
        return {
            type: actions.saveFormRequestDetailsRequested,
            payload: undefined,
        };
    },
    setFormRequestAsReadyForSigningRequested: function (): IAction<undefined> {
        return {
            type: actions.setFormRequestAsReadyForSigningRequested,
            payload: undefined,
        };
    },
    deleteFormRequestRequested: function (): IAction<undefined> {
        return {
            type: actions.deleteFormRequestRequested,
            payload: undefined,
        };
    },
    submitFormRequestToNoptaForApprovalRequested: function (): IAction<undefined> {
        return {
            type: actions.submitFormRequestToNoptaForApprovalRequested,
            payload: undefined,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
