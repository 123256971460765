// api
import { DraftApplicationTypeEnum } from "../../../api/Client";

// Note: If we just use the enum we don't need the switch...
export function details(id: number, type: DraftApplicationTypeEnum): string {
    switch (type) {
        // opggs
        case DraftApplicationTypeEnum.ChangeOfCompanyName:
            return `/Company/Opggs/DraftApplicationDetails/CoCn/${id}`;
        case DraftApplicationTypeEnum.ExplorationPermitRenewal:
            return `/Company/Opggs/DraftApplicationDetails/EpRenewal/${id}`;
        case DraftApplicationTypeEnum.RetentionLeaseRenewal:
            return `/Company/Opggs/DraftApplicationDetails/RlRenewal/${id}`;
        case DraftApplicationTypeEnum.ExtensionOfTimeToLodgeRenewal:
            return `/Company/Opggs/DraftApplicationDetails/Eottlr/${id}`;
        case DraftApplicationTypeEnum.InitialApplicationRetentionLease:
            return `/Company/Opggs/DraftApplicationDetails/Iarl/${id}`;
        case DraftApplicationTypeEnum.InitialApplicationProductionLicence:
            return `/Company/Opggs/DraftApplicationDetails/Iapp/${id}`;
        // oei
        case DraftApplicationTypeEnum.InitialApplicationFeasibilityLicence:
            return `/Company/Oei/DraftApplicationDetails/Iafl/${id}`;
        default:
            throw new Error("Unsupported Draft Application Type.");
    }
}
