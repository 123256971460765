// kendo
import { GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
// redux
import * as Models from "../../models/models";
// api
import * as Client from "../../../../../api/Client";
// other
import RoleGrid, { RoleGridModeEnum } from "../../../../../common/roleGrid/RoleGrid";
import CustomGridCell from "../../../../../common/kendoGrid/CustomGridCell";
import * as BadgeHelper from "../../../../../common/badges/BadgeHelper";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import EmailLinkView from "../../../../../common/externalLink/EmailLinkView";

export default function ExistingPermissionsView(props: { vm: Models.IRootViewModel }): React.ReactElement {
    function getAdministrators(companyAdministrators: Client.UserContactDto[] | undefined, dataIndex: number): React.ReactNode {
        if (companyAdministrators === undefined) return "";

        const sortedCompanyAdministrators = orderBy(companyAdministrators, [{ field: "fullName", dir: "asc" }]);

        let result: React.ReactElement[] = [];
        sortedCompanyAdministrators.forEach((ca, index) => {
            if (index > 0)
                result.push(
                    <span key={ca.emailAddress + "-span"} className="me-2">
                        ,
                    </span>
                );
            result.push(
                <EmailLinkView key={ca.emailAddress + "-a"} emailAddress={ca.emailAddress}>
                    {ca.fullName}
                </EmailLinkView>
            );
        });
        return result;
    }

    return (
        <div className="card my-2">
            <div className="card-body">
                <h2 className="card-title">Existing Permissions</h2>
                <RoleGrid
                    model={props.vm.existingPermissions}
                    roleGroups={props.vm.roleConfiguration.roleGroups}
                    mode={RoleGridModeEnum.Sortable}
                    showAdmin
                    showSigner
                    preColumns={[
                        <GridColumn key="companyName" field="companyName" title="Company" width={300} />,
                        <GridColumn
                            key="administrators"
                            field="administrators"
                            title="Admin Contact(s)"
                            width={250}
                            cell={(props) => <CustomGridCell gridCellProps={props}>{getAdministrators(props.dataItem!.companyAdministrators, props.dataIndex)}</CustomGridCell>}
                            sortable={false}
                        />,
                    ]}
                    postColumns={[
                        <GridColumn key="lastUpdatedBy" field="lastUpdatedBy" title="Updated By" width={200} />,
                        <GridColumn key="lastUpdatedDatetime" field="lastUpdatedDatetime" title="Updated Date (AWST)" width={250} format={GlobalHelpers.NoptaDatetimeFormatForKendo} />,
                        <GridColumn
                            key="hasPendingRequest"
                            field="hasPendingRequest"
                            title="Information"
                            width={160}
                            cell={(props) => (
                                <CustomGridCell gridCellProps={props}>
                                    {props.dataItem.hasPendingRequest && BadgeHelper.getPendingReviewBadge("Additional permissions requested. See Pending Requests below.")}
                                </CustomGridCell>
                            )}
                        />,
                    ]}
                />
            </div>
        </div>
    );
}
