// framework
import { useState } from "react";
// kendo
import { DatePicker } from "@progress/kendo-react-dateinputs";
// api
import { BasicInterpTypeEnum } from "../../../../../../api/Client";
// redux
import * as Actions from "../../redux/actions";
// models
import { ISurveyDataDetails, IRootViewModel } from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import DirtyScope from "../../../../../../common/shell/DirtyScopeController";
import { IValidationModel } from "../../../../../../common/validation/ValidationModel";
import YesNoDropDownList from "../../../../../../common/dropdowns/YesNoDropDownListView";
import ValidationMessageView from "../../../../../../common/validation/ValidationMessageView";
import KendoComboBox from "../../../../../../common/kendo/KendoComboBox";

interface IProps {
    vm: IRootViewModel;
    v: IValidationModel;
    actions: Actions.IActionFactory;
}

export default function SurveyDataDetailsView(props: IProps): JSX.Element {
    const { vm, v, actions } = props;

    const [state, setState] = useState<ISurveyDataDetails>(vm.surveyDataDetails);
    const [publicTitle, setPublicTitle] = useState(vm.surveyDataDetails.publicTitle);

    function updateVm() {
        actions.viewChanged(vm.onSurveyDataDetailsChanged(state));
    }

    function updateVmAndState(updatedState: ISurveyDataDetails) {
        setState(updatedState);
        actions.viewChanged(vm.onSurveyDataDetailsChanged(updatedState));
    }

    function updatePublicTitle() {
        // default to empty string instead of undefined so that the string 'undefined' is not displayed on screen
        const surveyDataType = state.surveyDataType?.name ?? "";
        const surveyDataTitle = state.title ?? "";

        setPublicTitle(!surveyDataType ? surveyDataTitle : `${surveyDataType}; ${surveyDataTitle}`.substring(0, 1000));
    }

    return (
        <DirtyScope scopeName="geoscienceAustralia/wellBoreholes/surveyData/details" isDirty={vm.isDirty}>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">General</h2>
                    <Forms.Row>
                        <Forms.FullColumn>
                            <Forms.Label htmlFor="publicTitleField">Public Title</Forms.Label>
                            <Forms.ReadonlyTextInput id="publicTitleField" value={publicTitle} />
                            <ValidationMessageView name="SurveyDataDetails.PublicTitle" validationModel={v} />
                        </Forms.FullColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="surveyDataTypeField">Data Type</Forms.Label>
                            <KendoComboBox
                                id="surveyDataTypeField"
                                value={state.surveyDataType}
                                data={vm.referenceData.surveyDataTypes}
                                keyField="id"
                                textField="name"
                                valid={v.applyValidityForKendo("SurveyDataDetails.SurveyDataType")}
                                filterable={true}
                                onChange={(event) => updateVmAndState({ ...state, surveyDataType: event })}
                                onBlur={() => {
                                    updateVm();
                                    updatePublicTitle();
                                }}
                            />
                            <ValidationMessageView name="SurveyDataDetails.SurveyDataType" validationModel={v} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.TextInput
                                label="Item ID"
                                id="itemIdField"
                                maxLength={9}
                                valid={v.applyValidityForKendo("SurveyDataDetails.ItemID")}
                                value={state.itemId}
                                onChange={(value) => setState({ ...state, itemId: value })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="SurveyDataDetails.ItemID" validationModel={v} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="copiedToNopimsField">Is Copied to NOPIMS</Forms.Label>
                            <YesNoDropDownList
                                id="copiedToNopimsField"
                                allowNulls={true}
                                valid={v.applyValidityForKendo("SurveyDataDetails.IsCopiedToNopims")}
                                value={state.isCopiedToNopims}
                                onChange={(value) => updateVmAndState({ ...state, isCopiedToNopims: value })}
                            />
                        </Forms.ThirdColumn>
                        <Forms.FullColumn>
                            <Forms.TextArea
                                label="Survey Data Title"
                                id="surveyDataTitleField"
                                rows={3}
                                maxLength={1000}
                                valid={v.applyValidityForKendo("SurveyDataDetails.SurveyDataTitle")}
                                value={state.title}
                                onChange={(value) => setState({ ...state, title: value })}
                                onBlur={() => {
                                    updateVm();
                                    updatePublicTitle();
                                }}
                            />
                            <ValidationMessageView name="SurveyDataDetails.SurveyDataTitle" validationModel={v} />
                        </Forms.FullColumn>
                    </Forms.Row>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Confidentiality</h2>
                    <Forms.Row>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="receivedDateField">Received Date</Forms.Label>
                            <DatePicker id="receivedDateField" value={state.receivedDate} onChange={(event) => setState({ ...state, receivedDate: event.value ?? undefined })} onBlur={updateVm} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="writtenDateField">Written Date</Forms.Label>
                            <DatePicker id="writtenDateField" value={state.writtenDate} onChange={(event) => setState({ ...state, writtenDate: event.value ?? undefined })} onBlur={updateVm} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="isConfidentialField">Confidentiality</Forms.Label>
                            <YesNoDropDownList
                                id="isConfidentialField"
                                valid={v.applyValidityForKendo("SurveyDataDetails.IsConfidential")}
                                value={state.isConfidential}
                                trueValue="Confidential"
                                falseValue="Not Confidential"
                                onChange={(value) => updateVmAndState({ ...state, isConfidential: value })}
                            />
                            <ValidationMessageView name="SurveyDataDetails.IsConfidential" validationModel={v} />
                        </Forms.ThirdColumn>
                        <Forms.FullColumn>
                            <Forms.TextArea
                                label="Confidentiality Remarks"
                                id="confidentialityRemarksField"
                                value={state.confidentialityRemarks}
                                maxLength={4000}
                                valid={v.applyValidityForKendo("SurveyDataDetails.ConfidentialityRemarks")}
                                rows={3}
                                onChange={(value) => setState({ ...state, confidentialityRemarks: value })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="SurveyDataDetails.ConfidentialityRemarks" validationModel={v} />
                        </Forms.FullColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="basicInterpTypeField">Basic Interp Type</Forms.Label>
                            <KendoComboBox
                                id="basicInterpTypeField"
                                value={state.basicInterpType}
                                data={vm.referenceData.basicInterpTypes}
                                keyField="id"
                                textField="name"
                                filterable={false}
                                suggest={false}
                                onChange={(value) => updateVmAndState({ ...state, basicInterpType: value })}
                            />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            {state.basicInterpType?.value === BasicInterpTypeEnum.B || state.basicInterpType?.value === BasicInterpTypeEnum.Bi ? (
                                <>
                                    <Forms.Label htmlFor="basicRelevantDateField">Basic Relevant Date</Forms.Label>
                                    <DatePicker
                                        id="basicRelevantDateField"
                                        value={state.basicRelevantDate}
                                        onChange={(event) => setState({ ...state, basicRelevantDate: event.value ?? undefined })}
                                        onBlur={updateVm}
                                    />
                                </>
                            ) : (
                                <>
                                    <Forms.ReadonlyField label="Basic Relevant Date" id="basicRelevantDateField" value={""} />
                                </>
                            )}
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            {state.basicInterpType?.value === BasicInterpTypeEnum.I || state.basicInterpType?.value === BasicInterpTypeEnum.Bi ? (
                                <>
                                    <Forms.Label htmlFor="interpRelevantDateField">Interp Relevant Date</Forms.Label>
                                    <DatePicker
                                        id="interpRelevantDateField"
                                        value={state.interpRelevantDate}
                                        onChange={(event) => setState({ ...state, interpRelevantDate: event.value ?? undefined })}
                                        onBlur={updateVm}
                                    />
                                </>
                            ) : (
                                <>
                                    <Forms.ReadonlyField label="Interp Relevant Date" id="interpRelevantDateField" value={""} />
                                </>
                            )}
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="releasedDateField">Released Date</Forms.Label>
                            <DatePicker
                                id="releasedDateField"
                                value={state.releasedDate}
                                valid={v.applyValidityForKendo("SurveyDataDetails.ReleasedDate")}
                                onChange={(event) => setState({ ...state, releasedDate: event.value ?? undefined })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="SurveyDataDetails.ReleasedDate" validationModel={v} />
                        </Forms.ThirdColumn>
                    </Forms.Row>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Survey Data Specific</h2>
                    <Forms.Row>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="companyField">Company</Forms.Label>
                            <KendoComboBox
                                id="companyField"
                                value={state.company}
                                data={vm.referenceData.contractorCompanies}
                                keyField="id"
                                textField="name"
                                filterable={true}
                                suggest={true}
                                onChange={(value) => setState({ ...state, company: value })}
                                onBlur={updateVm}
                            />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.TextInput
                                label="Inline Range"
                                id="inlineRangeField"
                                maxLength={100}
                                valid={v.applyValidityForKendo("SurveyDataDetails.InlineRange")}
                                value={state.inlineRange}
                                onChange={(value) => setState({ ...state, inlineRange: value })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="SurveyDataDetails.InlineRange" validationModel={v} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn></Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="contractorField">Contractor</Forms.Label>
                            <KendoComboBox
                                id="contractorField"
                                value={state.contractor}
                                data={vm.referenceData.contractorCompanies}
                                keyField="id"
                                textField="name"
                                filterable={true}
                                suggest={true}
                                onChange={(value) => setState({ ...state, contractor: value })}
                                onBlur={updateVm}
                            />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.TextInput
                                label="Crossline Range"
                                id="crossLineRangeField"
                                maxLength={100}
                                valid={v.applyValidityForKendo("SurveyDataDetails.CrossLineRange")}
                                value={state.crossLineRange}
                                onChange={(value) => setState({ ...state, crossLineRange: value })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="SurveyDataDetails.CrossLineRange" validationModel={v} />
                        </Forms.ThirdColumn>
                    </Forms.Row>
                </div>
            </div>
        </DirtyScope>
    );
}
