// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
// api
import * as Client from "../../../../../../api/Client";
// other
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";

function LocationsView(props: { locations: Client.GetCompanyOpggsTitleDetailsLocationDto[] }) {
    const sortedLocations = orderBy(props.locations, [
        { field: "isActive", dir: "desc" },
        { field: "locationName", dir: "asc" },
    ]);

    return (
        <div className="card mb-2">
            <div className="card-body">
                <div className="mb-2 text-muted">For more information please refer to the Memorial tab</div>
                {/* locations grid */}
                <Grid resizable navigatable data={sortedLocations}>
                    <GridColumn field="locationName" title="Location" width={300} />
                    <GridColumn field="substanceName" title="Type" width={200} />
                    <GridColumn field="statusName" title="Status" width={120} />
                    <GridColumn field="originalExpiryDate" title="Original Expiry Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="currentExpiryDate" title="Current Expiry Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                </Grid>
            </div>
        </div>
    );
}

export default LocationsView;
