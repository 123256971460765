// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    surveyId: number | undefined;
    surveyTitleDetails: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyTitleDetailsResponseDto | undefined;

    refreshSurveyTitleDetails(surveyId: number, response: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyTitleDetailsResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.surveyId = undefined;
        this.surveyTitleDetails = undefined;
    }

    public surveyId: number | undefined;
    public surveyTitleDetails: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyTitleDetailsResponseDto | undefined;

    public refreshSurveyTitleDetails(surveyId: number, response: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyTitleDetailsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.surveyId = surveyId;
        vm.surveyTitleDetails = response;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.surveyTitleDetails = clone(this.surveyTitleDetails);
        return vm;
    }
}
