// framework
import * as Validation from "../../../../../../common/validation/ValidationModel";
// redux
import * as Models from "./models";

export default function validate(vm: Models.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateApplicantDetails(vm, v);
    return v;
}

function validateApplicantDetails(vm: Models.IRootViewModel, v: Validation.ValidationModel): void {
    if (!vm.applicantDetails.foreignInvestmentApprovalType) v.addError("ApplicantDetails.ForeignInvestmentApprovalType", "Foreign investment approval is required.");
    if (vm.applicantDetails.isApplicantEligibleUnderSection8 !== true)
        v.addError("ApplicantDetails.IsApplicantEligibleUnderSection8", "The applicant must be an eligible person under section 8 of the OEI Act.");
}
