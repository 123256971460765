import { takeEvery } from "redux-saga/effects";
import * as Actions from "./actions";

import initialise from "./sagas/initialise";
import processCreate from "./sagas/create";
import processUpdate from "./sagas/update";
import processDelete from "./sagas/delete";

export default function* watcherSaga() {
    yield takeEvery(Actions.actions.initialiseRequested, initialise);
    yield takeEvery(Actions.actions.createRequested, processCreate);
    yield takeEvery(Actions.actions.updateRequested, processUpdate);
    yield takeEvery(Actions.actions.deleteRequested, processDelete);
}
