// common
import * as Client from "../../../../../api/Client";
import * as Validation from "../../../../../common/validation/ValidationModel";
// models
import { IRootViewModel } from "./models";

function isSurveyAcquisitionsSet(surveyAcquisitions: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseSurveyAcquisitionDto[] | undefined): boolean {
    return surveyAcquisitions !== undefined && surveyAcquisitions.length > 0;
}

export default function validate(vm: IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    const createDetails = vm.createDetails;

    const validItemIdRegex = /^[DEILMPYGXQRS][\d]{8}$/;

    if (Validation.isNullOrWhiteSpace(createDetails.itemId)) {
        v.addError("createDetails.ItemID", "Item ID is required.");
    } else if (!validItemIdRegex.test(createDetails.itemId!)) {
        v.addError(
            "createDetails.ItemID",
            "Item ID is invalid. It must be 9 characters long. Starting with either one of the following characters D, E, I, L, M, P, Y, G, X, Q, R or S, and the remaining characters being all digits."
        );
    }

    if (!isSurveyAcquisitionsSet(createDetails.surveyAcquisitions)) {
        v.addError("createDetails.RelatedActivities", "At least one activity (Acquisition) is required.");
        v.addError("createDetails.SurveyAcquisitions", "At least one Acquisition is required.");
    }

    if (!createDetails.surveyDataType) {
        v.addError("createDetails.SurveyDataType", "Survey Data Type is required.");
    }

    if (createDetails.isConfidential === undefined) {
        v.addError("createDetails.IsConfidential", "Confidentiality is required.");
    }

    if (createDetails.surveyDataTitle && createDetails.surveyDataTitle.length > 1000) {
        v.addError("createDetails.SurveyDataTitle", "Survey Data Title must be 1,000 characters or less.");
    }

    if (Validation.isNullOrWhiteSpace(createDetails.confidentialityRemarks)) {
        v.addError("createDetails.ConfidentialityRemarks", "Confidentiality Remarks are required.");
    } else if (createDetails.confidentialityRemarks && createDetails.confidentialityRemarks.length > 4000) {
        v.addError("createDetails.ConfidentialityRemarks", "Confidentiality Remarks must be 4,000 characters or less.");
    }

    if (createDetails.tapeId && createDetails.tapeId.length > 100) {
        v.addError("createDetails.TapeID", "Tape ID must be 100 characters or less.");
    }

    if (createDetails.remarks && createDetails.remarks.length > 1000) {
        v.addError("createDetails.Remarks", "Remarks must be 1,000 characters or less.");
    }

    if (createDetails.qcRemarks && createDetails.qcRemarks.length > 1000) {
        v.addError("createDetails.QcRemarks", "QC Remarks must be 1,000 characters or less.");
    }

    if (createDetails.consignment && createDetails.consignment.length > 1000) {
        v.addError("createDetails.Consignment", "Consignment must be 1,000 characters or less.");
    }

    if (createDetails.rddsComments && createDetails.rddsComments.length > 1000) {
        v.addError("createDetails.RDDSComments", "RDDS Comments must be 1,000 characters or less.");
    }

    if (createDetails.releasedDate && createDetails.releasedDate > new Date()) {
        v.addError("createDetails.ReleasedDate", "Released Date cannot be in the future.");
    }

    return v;
}
