// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../geoscienceAustraliaReduxRegistration";
import { IInitialiseRequestedPayload } from "../actions";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_Feedback_FeedbackDetails;

export default function* initialise(action: Actions.IAction<IInitialiseRequestedPayload>) {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const { feedbackId } = action.payload;

    try {
        yield put(shellHelper.createSetBusyAction());
        const referenceDataResponse: Client.GetGeoscienceAustraliaFeedbackReferenceDataResponseDto = yield call(getReferenceData);
        const response: Client.GetGeoscienceAustraliaFeedbackDetailsResponseDto = yield call(() => getDetails(feedbackId));
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshReferenceData(referenceDataResponse).refreshFeedbackDetails(response)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function getReferenceData(): Promise<Client.GetGeoscienceAustraliaFeedbackReferenceDataResponseDto> {
    const client = await ClientFactory.createGeoscienceAustraliaFeedbackClient();
    return await CallHelper.simpleCall(() => client.getGeoscienceAustraliaFeedbackReferenceData(new Client.GetGeoscienceAustraliaFeedbackReferenceDataRequestDto()));
}

export async function getDetails(feedbackId: number): Promise<Client.GetGeoscienceAustraliaFeedbackDetailsResponseDto> {
    const client = await ClientFactory.createGeoscienceAustraliaFeedbackClient();
    return await CallHelper.simpleCall(() => client.getGeoscienceAustraliaFeedbackDetails(new Client.GetGeoscienceAustraliaFeedbackDetailsRequestDto({ id: feedbackId })));
}
