import { takeEvery } from "redux-saga/effects";
import * as Actions from "./actions";

import initialise from "./sagas/initialise";
import batchReject from "./sagas/batchReject";
import batchApprove from "./sagas/batchApprove";

export default function* watcherSaga() {
    yield takeEvery(Actions.actions.initialiseRequested, initialise);
    yield takeEvery(Actions.actions.batchRejectRequested, batchReject);
    yield takeEvery(Actions.actions.batchApproveRequested, batchApprove);
}
