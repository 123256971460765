import { Payment2MethodEnum } from "../../../../api/Client";
import * as Validation from "../../../../common/validation/ValidationModel";
import * as Model from "./models";
import { IPaymentViewModel } from "./paymentViewModel";

export default function validate(vm: Model.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    if (vm.paymentViewModel) {
        validateMakePaymentRequest(vm.paymentViewModel, vm.financialNotification.outstandingAmount, v);
    }
    return v;
}

function validateMakePaymentRequest(vm: IPaymentViewModel, maxAmount: number, v: Validation.ValidationModel): void {
    if (!vm.paymentMethod) {
        v.addError("MakePayment.PaymentMethod", "Payment Method is required.");
    }

    if (vm.paymentMethod === Payment2MethodEnum.CreditCard) {
        if (typeof vm.secureTransactionToken === "undefined") {
            v.addError("MakePayment.VerifyDetails", "The Credit Card must be verified.");
        }
    } else {
        if (!vm.paymentMadeDate) {
            v.addError("MakePayment.PaymentMadeDate", "Payment Made is required.");
        } else if (vm.paymentMadeDate > new Date()) {
            v.addError("MakePayment.PaymentMadeDate", "Payment Made cannot be in the future.");
        }

        if (!vm.receivedAmount) {
            v.addError("MakePayment.ReceivedAmount", "Payment Amount is required.");
        } else if (vm.receivedAmount <= 0) {
            v.addError("MakePayment.ReceivedAmount", "Payment Amount must be greater than zero.");
        } else if (vm.receivedAmount > maxAmount) {
            v.addError("MakePayment.ReceivedAmount", "Payment Amount must be less than or equal to the Balance.");
        }

        if (vm.bankReceiptNumber && vm.bankReceiptNumber.length > 100) {
            v.addError("MakePayment.BankReceiptNumber", "Bank Receipt Number must be 100 characters or less.");
        }
        if (vm.bankName && vm.bankName.length > 100) {
            v.addError("MakePayment.BankName", "Bank must be 100 characters or less.");
        }
        if (vm.chequeNumber && vm.chequeNumber.length > 100) {
            v.addError("MakePayment.ChequeNumber", "Cheque Number must be 100 characters or less.");
        }
    }

    if (vm.comments && vm.comments.length > 1000) {
        v.addError("Comments.Comments", "Comments must be 1000 characters or less.");
    }
}
