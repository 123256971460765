// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export enum ViewStateEnum {
    Initialising,
    View,
    SelectBatchUpdate,
    EditBatchUpdate,
}

export interface IBatchUpdateViewModel {
    updateConfidentiality: boolean;
    confidentiality?: boolean | undefined;
    updateConfidentialityRemarks: boolean;
    confidentialityRemarksUpdateType: Client.SurveyAcquisitionStringUpdateTypeEnum;
    confidentialityRemarks?: string | undefined;
    updateReceivedDate: boolean;
    receivedDate?: Date | undefined;
    updateConsignment: boolean;
    consignmentUpdateType: Client.SurveyAcquisitionStringUpdateTypeEnum;
    consignment?: string | undefined;
    updateConditionStatus: boolean;
    conditionStatus: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySurveyDataConditionStatusDto | undefined;
    updateLoanStatus: boolean;
    loanStatus: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySurveyDataLoanStatusDto | undefined;
}

export interface ISurveyDataItemViewModel {
    id: number;
    versionNumber: number;
    itemId: string;
    title: string | undefined;
    dataType: string | undefined;
    tapeId: string | undefined;
    receivedDate: Date | undefined;
    isConfidential: string | undefined;
    confidentialityRemarks: string;
    isSelected: boolean;
    updateFailed: boolean;
    consignment: string | undefined;
    loanStatus: string | undefined;
    conditionStatus: string | undefined;
}

export interface IRootViewModel {
    surveyId: number | undefined;
    surveyName: string | undefined;

    surveyData: ISurveyDataItemViewModel[];
    surveyAcquisitions: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySurveyDataSurveyAcquisitionItemDto[];

    batchUpdateDetails: IBatchUpdateViewModel;

    referenceData: {
        loanStatuses: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySurveyDataLoanStatusDto[];
        conditionStatuses: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySurveyDataConditionStatusDto[];
    };

    statusMessages: Client.StatusMessagesDto | undefined;

    viewState: ViewStateEnum;

    isDirty: boolean;
    isConflict: boolean;

    refreshSurveySurveyData(surveyId: number, response: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySurveyDataResponseDto): IRootViewModel;
    refreshUpdateResponse(response: Client.UpdateGeoscienceAustraliaSurveysAcquisitionsAcquisitionSurveyDataResponseDto): IRootViewModel;
    refreshConflict(): IRootViewModel;

    onSurveyDataUpdated(item: ISurveyDataItemViewModel): IRootViewModel;
    onBatchDetailsUpdate(batchUpdateDetails: IBatchUpdateViewModel): IRootViewModel;

    onSelectBatchUpdate(): IRootViewModel;
    onEditBatchUpdate(): IRootViewModel;
}

export class SurveyDataItemViewModel implements ISurveyDataItemViewModel {
    constructor(surveyData: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySurveyDataItemDto) {
        this.id = surveyData.id;
        this.versionNumber = surveyData.versionNumber;
        this.itemId = surveyData.itemId;
        this.title = surveyData.title;
        this.dataType = surveyData.dataType;
        this.tapeId = surveyData.tapeId;
        this.receivedDate = surveyData.receivedDate;
        this.surveyAcquisitions = surveyData.surveyAcquisitions ? surveyData.surveyAcquisitions! : [];
        this.surveyAcquisitionDescriptions = surveyData.surveyAcquisitionDescriptions;
        this.isConfidential = surveyData.isConfidential;
        this.confidentialityRemarks = surveyData.confidentialityRemarks;
        this.isSelected = false;
        this.updateFailed = false;
        this.consignment = surveyData.consignment;
        this.loanStatus = surveyData.loanStatus;
        this.conditionStatus = surveyData.conditionStatus;
    }

    public id: number;
    public versionNumber: number;
    public itemId: string;
    public title: string | undefined;
    public dataType: string | undefined;
    public tapeId: string | undefined;
    public receivedDate: Date | undefined;
    public surveyAcquisitions: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySurveyDataSurveyAcquisitionItemDto[];
    public surveyAcquisitionDescriptions: string | undefined;
    public isConfidential: string | undefined;
    public confidentialityRemarks: string;
    public isSelected: boolean;
    public updateFailed: boolean;
    public consignment: string | undefined;
    public loanStatus: string | undefined;
    public conditionStatus: string | undefined;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.surveyId = undefined;
        this.surveyName = undefined;
        this.surveyData = [];
        this.surveyAcquisitions = [];

        this.batchUpdateDetails = {
            updateConfidentiality: false,
            confidentiality: undefined,
            updateConfidentialityRemarks: false,
            confidentialityRemarksUpdateType: Client.SurveyAcquisitionStringUpdateTypeEnum.Append,
            confidentialityRemarks: undefined,
            updateReceivedDate: false,
            receivedDate: undefined,
            updateConsignment: false,
            consignmentUpdateType: Client.SurveyAcquisitionStringUpdateTypeEnum.Append,
            consignment: undefined,
            updateConditionStatus: false,
            conditionStatus: undefined,
            updateLoanStatus: false,
            loanStatus: undefined,
        };

        this.referenceData = {
            loanStatuses: [],
            conditionStatuses: [],
        };

        this.viewState = ViewStateEnum.Initialising;
        this.isDirty = false;
        this.isConflict = false;
    }

    public surveyId: number | undefined;
    public surveyName: string | undefined;

    public surveyData: ISurveyDataItemViewModel[];
    public surveyAcquisitions: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySurveyDataSurveyAcquisitionItemDto[];

    public batchUpdateDetails: IBatchUpdateViewModel;

    public referenceData: {
        loanStatuses: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySurveyDataLoanStatusDto[];
        conditionStatuses: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySurveyDataConditionStatusDto[];
    };

    public statusMessages: Client.StatusMessagesDto | undefined;

    public viewState: ViewStateEnum;

    public isDirty: boolean;
    public isConflict: boolean;

    public refreshSurveySurveyData(surveyId: number, response: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySurveyDataResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.surveyId = surveyId;

        vm.surveyName = response.surveyName;
        vm.surveyData = response.items.map((i) => new SurveyDataItemViewModel(i));
        vm.surveyAcquisitions = response.surveyAcquisitions;

        vm.referenceData = {
            loanStatuses: response.loanStatuses,
            conditionStatuses: response.conditionStatuses,
        };

        vm.viewState = ViewStateEnum.View;

        vm.batchUpdateDetails = {
            updateConfidentiality: false,
            confidentiality: undefined,
            updateConfidentialityRemarks: false,
            confidentialityRemarksUpdateType: Client.SurveyAcquisitionStringUpdateTypeEnum.Append,
            confidentialityRemarks: undefined,
            updateReceivedDate: false,
            receivedDate: undefined,
            updateConsignment: false,
            consignmentUpdateType: Client.SurveyAcquisitionStringUpdateTypeEnum.Append,
            consignment: undefined,
            updateConditionStatus: false,
            conditionStatus: undefined,
            updateLoanStatus: false,
            loanStatus: undefined,
        };

        vm.statusMessages = undefined;

        vm.isDirty = false;
        vm.isConflict = false;

        return vm;
    }

    public refreshUpdateResponse(response: Client.UpdateGeoscienceAustraliaSurveysAcquisitionsAcquisitionSurveyDataResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = response.statusMessages;

        vm.surveyData = vm.surveyData.map((r) => {
            if (!response.failedSurveyDataIds!.some((id) => id === r.id)) return r;

            return { ...r, updateFailed: true };
        });

        // reset batch update details if update succeeded
        if (response.statusMessages?.isSuccess) {
            vm.batchUpdateDetails = {
                updateConfidentiality: false,
                confidentiality: undefined,
                updateConfidentialityRemarks: false,
                confidentialityRemarksUpdateType: Client.SurveyAcquisitionStringUpdateTypeEnum.Append,
                confidentialityRemarks: undefined,
                updateReceivedDate: false,
                receivedDate: undefined,
                updateConsignment: false,
                consignmentUpdateType: Client.SurveyAcquisitionStringUpdateTypeEnum.Append,
                consignment: undefined,
                updateConditionStatus: false,
                conditionStatus: undefined,
                updateLoanStatus: false,
                loanStatus: undefined,
            };
        }
        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        return vm;
    }

    public onSurveyDataUpdated(item: ISurveyDataItemViewModel): IRootViewModel {
        const vm = this._clone();

        vm.surveyData = vm.surveyData.map((r) => {
            if (r.id !== item.id) return r;
            return { ...r, isSelected: !r.isSelected };
        });

        vm.isDirty = true;
        return vm;
    }

    public onBatchDetailsUpdate(batchUpdateDetails: IBatchUpdateViewModel): IRootViewModel {
        const vm = this._clone();
        vm.batchUpdateDetails = batchUpdateDetails;
        return vm;
    }

    public onSelectBatchUpdate(): IRootViewModel {
        const vm = this._clone();
        vm.viewState = ViewStateEnum.SelectBatchUpdate;
        vm.statusMessages = undefined;
        return vm;
    }

    public onEditBatchUpdate(): IRootViewModel {
        const vm = this._clone();
        vm.viewState = ViewStateEnum.EditBatchUpdate;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.surveyData = clone(this.surveyData);
        vm.surveyAcquisitions = clone(this.surveyAcquisitions);
        return vm;
    }
}
