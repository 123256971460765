// kendo
import { GridCellProps } from "@progress/kendo-react-grid";
// other
import * as BadgeHelper from "../../../common/badges/BadgeHelper";
import CustomGridCell from "../../../common/kendoGrid/CustomGridCell";

// current set of properties that can result in a badge being shown in the Information column of joint authority / user roles grids
// at present applicable to userManagement only and as such have not polluted IRoleViewModel with this information
export interface IJointAuthorityRoleInformationViewModel {
    isSecureUserBlocked: boolean;
    hasPendingRequest: boolean;
}

export const TOOL_TIP_BLOCKED = "This user has been blocked and can not access any joint authority information. Please contact neats.admin@nopta.gov.au for further information.";
export const TOOL_TIP_PENDING_REVIEW = "This user has a pending Request awaiting approval. The pending Request must be completed before the user's permissions can be manually adjusted.";

export function getInformationCell(props: GridCellProps) {
    const role = props.dataItem as IJointAuthorityRoleInformationViewModel;

    return (
        <CustomGridCell gridCellProps={props}>
            {role.isSecureUserBlocked && BadgeHelper.getBlockedUserBadge(TOOL_TIP_BLOCKED)}
            {role.hasPendingRequest && BadgeHelper.getPendingReviewBadge(TOOL_TIP_PENDING_REVIEW)}
        </CustomGridCell>
    );
}
