// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IJointAuthorityReduxState } from "../../../../jointAuthorityReduxRegistration";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

const getRootViewModel = (state: IJointAuthorityReduxState) => state.JointAuthority_Home_Dashboard;

export default function* initialise() {
    try {
        yield put(shellHelper.createSetBusyAction());
        const response: Client.GetJointAuthorityHomeDashboardResponseDto = yield call(async () => {
            const client = await ClientFactory.createJointAuthorityHomeDashboardClient();
            return await CallHelper.simpleCall(() => client.getJointAuthorityHomeDashboard(new Client.GetJointAuthorityHomeDashboardRequestDto()));
        });
        const vm: Models.IRootViewModel = yield select(getRootViewModel);
        yield put(Actions.actionFactory.initialiseCompleted(vm.refreshSaga(response)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
