// framework
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import CustomGridCell from "../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
// models
import { IRootViewModel } from "../../models/models";
// common
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import * as GeoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";

export default function RigListGridView(props: { vm: IRootViewModel }): JSX.Element {
    const { vm } = props;

    const initialState: State = {
        sort: [{ field: "name", dir: "asc" }],
        filter: { logic: "and", filters: [] },
        skip: 0,
        take: 20,
    };

    const [gridState, setGridState] = useState(initialState);
    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [vm.rigs]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid
            pageable
            sortable
            resizable
            navigatable
            data={process(vm.rigs, gridState)}
            {...gridState}
            onDataStateChange={(e) => {
                setGridState(e.dataState);
            }}
        >
            <GridColumn
                field="name"
                title="Name"
                width={240}
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        <Link to={GeoscienceAustraliaRouteHelper.rigDetails(props.dataItem.id)}>{props.dataItem.name}</Link>
                    </CustomGridCell>
                )}
                filter="text"
                columnMenu={createGridColumnFilterMenu}
            />
            <GridColumn field="type" title="Type" width={240} filter="text" columnMenu={createGridColumnFilterMenu} />
            <GridColumn field="make" title="Make" width={240} filter="text" columnMenu={createGridColumnFilterMenu} />
            <GridColumn field="model" title="Model" width={240} filter="text" columnMenu={createGridColumnFilterMenu} />
            <GridColumn field="effectiveFromDate" title="Effective From Date" width={220} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
            <GridColumn field="companyOwner" title="Company Owner" width={250} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.rigs)} />
            <GridColumn field="status" title="Status" width={180} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.rigs)} />
        </Grid>
    );
}
