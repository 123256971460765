// framework
import { useState } from "react";
// redux
import { IApplicationStateViewModel, ApplicationViewStateEnum } from "./models/models";
// api
import { DraftApplicationValidationStateEnum } from "../../../../api/Client";
// common
import * as ViewHelpers from "../ViewHelpers";
import ConfirmationView, { DefaultButtonEnum } from "../../../../common/confirmation/ConfirmationView";

export default function CommandsView(props: {
    vm: IApplicationStateViewModel;
    isPaymentRequired: boolean | undefined;
    isDigitalSignatures: boolean | undefined;
    onReadyForSigning: () => void;
    onRevertToPreparing: () => void;
    onPaying: () => void;
    onReadyForSubmission: () => void;
    onSubmit: () => void;
    onDelete: () => void;
}) {
    const state = props.vm;
    const isPaymentRequired = props.isPaymentRequired ?? false;
    const isDigitalSignatures = props.isDigitalSignatures;

    // ready for signing confirmation
    const [showSigningConfirmation, setShowSigningConfirmation] = useState(false);

    function onSigningConfirmationAccepted() {
        setShowSigningConfirmation(false);
        // submit
        props.onReadyForSigning();
    }

    function onSigningConfirmationClosed() {
        setShowSigningConfirmation(false);
    }

    // preparing confirmation
    const [showPreparingConfirmation, setShowPreparingConfirmation] = useState(false);

    function onPreparingConfirmationAccepted() {
        setShowPreparingConfirmation(false);
        // submit
        props.onRevertToPreparing();
    }

    function onPreparingConfirmationClosed() {
        setShowPreparingConfirmation(false);
    }

    // paying confirmation
    const [showPayingConfirmation, setShowPayingConfirmation] = useState(false);

    function onPayingConfirmationAccepted() {
        setShowPayingConfirmation(false);
        // submit
        props.onPaying();
    }

    function onPayingConfirmationClosed() {
        setShowPayingConfirmation(false);
    }

    // ready for submission confirmation
    const [showForSubmissionConfirmation, setShowForSubmissionConfirmation] = useState(false);

    function onForSubmissionConfirmationAccepted() {
        setShowForSubmissionConfirmation(false);
        // submit
        props.onReadyForSubmission();
    }

    function onForSubmissionConfirmationClosed() {
        setShowForSubmissionConfirmation(false);
    }

    // delete confirmation
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    function onDeleteConfirmationAccepted() {
        setShowDeleteConfirmation(false);
        // submit
        props.onDelete();
    }

    function onDeleteConfirmationClosed() {
        setShowDeleteConfirmation(false);
    }

    const viewState = state.viewState;
    const isInEdit = ViewHelpers.isInEdit(state.inEditTask);
    const blockProgress = state.validationState !== DraftApplicationValidationStateEnum.Valid || isInEdit;
    const blockRevert = state.validationState === DraftApplicationValidationStateEnum.UnrecoverableError || isInEdit;

    return (
        <div className="d-flex flex-wrap">
            {state.canManageApplicantOnly && (
                <>
                    {viewState !== ApplicationViewStateEnum.Submitted && (
                        <>
                            <button className="btn btn-outline-secondary me-2 mb-2" type="button" onClick={() => setShowDeleteConfirmation(true)}>
                                Delete Application
                            </button>
                            {showDeleteConfirmation && (
                                <ConfirmationView title="Delete Application" onAccepted={onDeleteConfirmationAccepted} onClosed={onDeleteConfirmationClosed} defaultButton={DefaultButtonEnum.Cancel}>
                                    This will permanently delete the Application and any uploaded files.
                                </ConfirmationView>
                            )}
                        </>
                    )}
                    <div className="ms-auto">
                        {viewState === ApplicationViewStateEnum.Preparing && (
                            <>
                                <button className="btn btn-outline-primary mb-2" type="button" disabled={blockProgress} onClick={() => setShowSigningConfirmation(true)}>
                                    Progress to Signing
                                </button>
                                {showSigningConfirmation && (
                                    <ConfirmationView
                                        title="Progress to Signing"
                                        onAccepted={onSigningConfirmationAccepted}
                                        onClosed={onSigningConfirmationClosed}
                                        defaultButton={DefaultButtonEnum.Cancel}
                                    >
                                        You won't be able to make any more changes to the Application.
                                    </ConfirmationView>
                                )}
                            </>
                        )}
                        {viewState === ApplicationViewStateEnum.ForSigning && (
                            <>
                                <button className="btn btn-outline-secondary me-2 mb-2" type="button" disabled={blockRevert} onClick={() => setShowPreparingConfirmation(true)}>
                                    Revert to Preparing
                                </button>
                                {showPreparingConfirmation && (
                                    <ConfirmationView
                                        title="Revert to Preparing"
                                        onAccepted={onPreparingConfirmationAccepted}
                                        onClosed={onPreparingConfirmationClosed}
                                        defaultButton={DefaultButtonEnum.Cancel}
                                    >
                                        {isDigitalSignatures
                                            ? "Reverting the Application to Preparing will delete any Digital Signatures."
                                            : "Reverting the Application to Preparing will delete any uploaded Application Form(s)."}
                                    </ConfirmationView>
                                )}
                                {isPaymentRequired ? (
                                    <>
                                        <button className="btn btn-outline-primary mb-2" type="button" disabled={blockProgress} onClick={() => setShowPayingConfirmation(true)}>
                                            Progress to Paying
                                        </button>
                                        {showPayingConfirmation && (
                                            <ConfirmationView
                                                title="Progress to Paying"
                                                onAccepted={onPayingConfirmationAccepted}
                                                onClosed={onPayingConfirmationClosed}
                                                defaultButton={DefaultButtonEnum.Cancel}
                                            >
                                                You won't be able to revert the Application once progressed.
                                            </ConfirmationView>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <button className="btn btn-outline-primary mb-2" type="button" disabled={blockProgress} onClick={() => setShowForSubmissionConfirmation(true)}>
                                            Progress to Submission
                                        </button>
                                        {showForSubmissionConfirmation && (
                                            <ConfirmationView
                                                title="Progress to Submission"
                                                onAccepted={onForSubmissionConfirmationAccepted}
                                                onClosed={onForSubmissionConfirmationClosed}
                                                defaultButton={DefaultButtonEnum.Cancel}
                                            >
                                                You won't be able to revert the Application once progressed.
                                            </ConfirmationView>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </>
            )}
            {state.canSubmit && viewState === ApplicationViewStateEnum.ForSubmission && (
                <div className="ms-auto">
                    <button className="btn btn-outline-primary mb-2" type="button" disabled={blockProgress} onClick={() => props.onSubmit()}>
                        Submit Application
                    </button>
                </div>
            )}
        </div>
    );
}
