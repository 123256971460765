// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import ConflictView from "../../../../common/alerts/ConflictAlertView";
import StatusMessagesAlertsView from "../../../../common/alerts/StatusMessagesAlertsView";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/rootViewModel";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
// views
import JointAuthorityAdministratorRequestListView from "./views/JointAuthorityAdministratorRequestListView";
import NoptaRequestListView from "./views/NoptaRequestListView";

interface IRequestListControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function RequestListController(props: IRequestListControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    // on mount
    useEffect(() => {
        props.initialiseRequested();
        return function () {
            props.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BodyLayout
            title="My Requests"
            selectedLhsNavItem={Level1ItemTypeEnum.JointAuthority_UserManagement_ViewRequestList}
            onReloadRequested={() => {
                props.initialiseRequested();
            }}
        >
            <ConflictView isConflict={vm.isConflict} />
            <StatusMessagesAlertsView statusMessages={vm.service.statusMessages} />
            <JointAuthorityAdministratorRequestListView vm={vm} actions={actions} />
            <NoptaRequestListView vm={vm.nopta} />
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.JointAuthority_UserManagement_RequestList }), Actions.actionFactory)(RequestListController);
