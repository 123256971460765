import * as Actions from "./actions";
import * as Models from "../models/models";

// reducers
export function reducer(state: Models.IConfigurationModel = new Models.ConfigurationModel(), action: Actions.IAction<any>): Models.IConfigurationModel {
    switch (action.type) {
        case Actions.actions.configurationLoaded:
            return action.payload as Models.IConfigurationModel;
        default:
            return state;
    }
}
