// framework
import { clone } from "lodash";
// client
import * as Client from "../../../api/Client";

export interface IRootViewModel {
    isSignedIn: boolean;
    secureEntityType: Client.SecureEntityTypeEnum | undefined;

    refreshOnSignIn(response: Client.SignInResponseDto): IRootViewModel;
    refreshOnSignOut(): IRootViewModel;
    refreshOnSignInExpired(): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.isSignedIn = false;
        this.secureEntityType = undefined;
    }

    isSignedIn: boolean;
    secureEntityType: Client.SecureEntityTypeEnum | undefined;

    refreshOnSignIn(response: Client.SignInResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.isSignedIn = true;
        vm.secureEntityType = response.secureEntityType;
        return vm;
    }

    refreshOnSignOut(): IRootViewModel {
        const vm = this._clone();
        vm.isSignedIn = false;
        vm.secureEntityType = undefined;
        return vm;
    }

    refreshOnSignInExpired(): IRootViewModel {
        const vm = this._clone();
        vm.isSignedIn = false;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
