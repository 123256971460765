// framework
import { useState, useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// api
import { DraftApplicationFormDocumentationSignatureTypeEnum } from "../../../../../../api/Client";
// common
import StatusMessagesAlertsView from "../../../../../../common/alerts/StatusMessagesAlertsView";
import ConflictView from "../../../../../../common/alerts/ConflictAlertView";
import SimpleAuditEventsView from "../../../../../../common/audit/SimpleAuditEventsView";
import DirtyScope from "../../../../../../common/shell/DirtyScopeController";
import * as documentHelper from "../../../../../../common/documentHelper";
import * as toastHelper from "../../../../../../common/toastHelper";
// views
import DigitalSignatureView from "./views/digitalSignature/DigitalSignatureView";
import WetSignatureView from "./views/wetSignature/WetSignatureView";
import validator from "../models/validator";
import { ValidationVisibilityEnum } from "../../../../../../common/validation/ValidationModel";

interface IControllerProps extends Actions.IActionFactory {
    id: number;
    versionNumber: number;
    cardTitle: string;
    lastRefreshedDatetime: Date | undefined;
    showCommands: boolean;
    disableEdit: boolean;
    onEdit: () => void;
    onSave: () => void;
    onCancel: () => void;
    className?: string | undefined;
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const [lastRefreshedDatetime, setLastRefreshedDatetime] = useState<Date | undefined>(undefined);
    const [isBusy, setIsBusy] = useState(false);

    // on mount
    // - initial load
    useEffect(() => {
        actions.initialiseRequested(props.id);
        return function () {
            actions.clearRequested();
        };
    }, [props.id]); // eslint-disable-line react-hooks/exhaustive-deps

    // generate document
    const generatedDocumentResponse = vm.wetSignatureDetails!.generatedDocument;

    // - download when response received
    useEffect(() => {
        documentHelper.tryDownloadDocument(generatedDocumentResponse);
        return function () {
            actions.clearGeneratedDocumentRequested();
        };
    }, [generatedDocumentResponse]); // eslint-disable-line react-hooks/exhaustive-deps

    // refresh
    // - reasons to refresh include (1) the user pressing the refresh button, or (2) saves occurring in panels that could invalidate the content here
    // - don't refresh just because the parent refreshed, this can cause us to immediately lose status changes when a save occurs here
    // - also be careful to ensure a refresh only occurs once, it's easy to make a mistake and have the get-list service call happen multiple times
    useEffect(() => {
        //console.info(vm.viewState, props.lastRefreshedDatetime, lastRefreshedDatetime, vm.versionNumber, props.versionNumber);

        // if it's initialising, just wait
        if (vm.viewState === Models.ViewStateEnum.Initialising) return;

        // a save has happened here, so trigger the upstream save call
        if (vm.versionNumber! > props.versionNumber) {
            props.onSave();
            return;
        }

        // a save has happened externally, so trigger a refresh here
        if (vm.versionNumber! < props.versionNumber) {
            actions.initialiseRequested(props.id);
            return;
        }

        // a manual refresh has been requested
        if (lastRefreshedDatetime !== props.lastRefreshedDatetime) {
            setLastRefreshedDatetime(props.lastRefreshedDatetime);
            actions.initialiseRequested(props.id);
        }
    }, [vm.viewState, props.lastRefreshedDatetime, lastRefreshedDatetime, vm.versionNumber, props.versionNumber]); // eslint-disable-line react-hooks/exhaustive-deps

    // validation
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const validation = validator(vm.digitalSignatureDetails.newCompanySignatureDetails, validationVisibility);

    function onEditClick(): void {
        props.onEdit();
        actions.viewChanged(vm.onEdit());
    }

    function onSaveWetSignatureClick(): void {
        actions.saveWetSignatureRequested();
    }

    function onSaveDigitalSignatureClick(): void {
        // validation
        // - validation is hidden until the user attempts the action... it then stays visible until they have successfully completed the action
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);

        actions.saveDigitalSignatureRequested();
    }

    function onCancelClick(): void {
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        props.onCancel();
        actions.initialiseRequested(vm.id!);
    }

    function onBusyChanged(busy: boolean): void {
        setIsBusy(busy);
    }

    function generateDocumentRequested(): void {
        actions.generateDocumentRequested(vm.id!);
    }

    function onSelectionChange(fileId: number): void {
        actions.viewChanged(vm.onFileSelected(fileId));
    }

    function onHeaderSelectionChange(isChecked: boolean): void {
        actions.viewChanged(vm.onFilesSelected(isChecked));
    }

    // return
    return (
        <div className={props.className}>
            <div className="card" style={{ minWidth: 250 }}>
                <div className="card-body">
                    <h4 className="card-title">{props.cardTitle}</h4>

                    <DirtyScope scopeName="company/opggs/draftApplicationDetails/controls/formDocumentation" isDirty={vm.isDirty} />
                    <ConflictView isConflict={vm.isConflict} />
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} scrollWindowToTop={false} />

                    {vm.signatureType === DraftApplicationFormDocumentationSignatureTypeEnum.Wet && (
                        <WetSignatureView
                            vm={vm}
                            actions={actions}
                            showCommands={props.showCommands}
                            isBusy={isBusy}
                            onBusyChanged={onBusyChanged}
                            generatedDocumentRequested={generateDocumentRequested}
                            updateSelection={onSelectionChange}
                            updateHeaderSelection={onHeaderSelectionChange}
                        />
                    )}
                    {vm.signatureType === DraftApplicationFormDocumentationSignatureTypeEnum.Digital && (
                        <DigitalSignatureView
                            vm={vm}
                            v={validation}
                            actions={actions}
                            showCommands={props.showCommands}
                            isBusy={isBusy}
                            onBusyChanged={onBusyChanged}
                            generateDocumentRequested={generateDocumentRequested}
                            updateSelection={onSelectionChange}
                            updateHeaderSelection={onHeaderSelectionChange}
                        />
                    )}

                    {/* audit and edit/save/cancel buttons */}
                    <div className="d-flex flex-wrap mt-2">
                        <SimpleAuditEventsView className="mb-1" simpleAuditEvents={vm.audit} />
                        {vm.viewState === Models.ViewStateEnum.View && props.showCommands && (
                            <div className="ms-auto">
                                <button className="btn btn-outline-primary" onClick={onEditClick} disabled={props.disableEdit}>
                                    Edit
                                </button>
                            </div>
                        )}
                        {vm.viewState === Models.ViewStateEnum.Edit && (
                            <div className="ms-auto">
                                <button className="btn btn-outline-secondary me-2" onClick={onCancelClick} disabled={isBusy}>
                                    Cancel
                                </button>

                                {vm.signatureType === DraftApplicationFormDocumentationSignatureTypeEnum.Wet ? (
                                    <button className="btn btn-outline-primary" onClick={onSaveWetSignatureClick} disabled={isBusy}>
                                        Save
                                    </button>
                                ) : (
                                    <button className="btn btn-outline-primary" onClick={onSaveDigitalSignatureClick} disabled={isBusy || !vm.digitalSignatureDetails.canSign}>
                                        Click to Sign
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_Common_DraftApplicationDetails_Controls_FormDocumentation }), Actions.actionFactory)(Controller);
