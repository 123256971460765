import * as Models from "../models/rootViewModel";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "company.userManagement.userDetails.initialiseRequested",
    clearRequested = "company.userManagement.userDetails.clearRequested",
    savePermissionsRequested = "company.userManagement.userDetails.savePermissionsRequested",
    removeUserRequested = "company.userManagement.userDetails.removeUserRequested",
    // user input
    viewChanged = "company.userManagement.userDetails.viewChanged",
    // saga
    sagaCompleted = "company.userManagement.userDetails.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(secureUserId: number): IAction<number>;
    clearRequested(): IAction<undefined>;
    savePermissionsRequested(): IAction<undefined>;
    removeUserRequested(): IAction<undefined>;
    // user input
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    // saga
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (secureUserId: number): IAction<number> {
        return {
            type: actions.initialiseRequested,
            payload: secureUserId,
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    savePermissionsRequested: function (): IAction<undefined> {
        return {
            type: actions.savePermissionsRequested,
            payload: undefined,
        };
    },
    removeUserRequested: function (): IAction<undefined> {
        return {
            type: actions.removeUserRequested,
            payload: undefined,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
