import * as Client from "../../../../api/Client";
import { clone } from "lodash";

export interface IRootViewModel {
    titles: titlesType;

    refreshSaga(response: Client.GetJointAuthorityOpggsTitleListResponseDto): IRootViewModel;
}

export type titlesType = Array<Client.GetJointAuthorityOpggsTitleListTitleDto> | undefined;

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.titles = undefined;
    }

    public titles: titlesType;

    public refreshSaga(response: Client.GetJointAuthorityOpggsTitleListResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.titles = response.titles;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
