// framework
import { useEffect, useState, ReactElement, ReactNode } from "react";
import Alert from "react-bootstrap/alert";
import { AlertIcon, CheckIcon, InfoIcon } from "@primer/octicons-react";

function SimpleAlertView(props: { className?: string; message?: string; alertType: "error" | "information" | "success" | "warning"; dismissable?: boolean }) {
    const { className, message, alertType, dismissable } = props;

    const [show, setShow] = useState(true);
    useEffect(() => {
        setShow(true);
    }, [message, alertType, dismissable]);

    if (!show) return <></>;

    switch (alertType) {
        case "error":
            return showAlert("danger", <AlertIcon />);

        case "information":
            return showAlert("info", <InfoIcon />);

        case "success":
            return showAlert("success", <CheckIcon />);

        case "warning":
            return showAlert("warning", <AlertIcon />);

        default:
            throw new Error("Unknown");
    }

    function showAlert(variant: string, icon: ReactNode): ReactElement {
        return (
            <div className={className}>
                <Alert variant={variant} className="mb-2" dismissible={dismissable ?? true} onClose={() => setShow(false)}>
                    <span className="me-1">{icon}</span>
                    <span>{message}</span>
                </Alert>
            </div>
        );
    }
}

export default SimpleAlertView;
