// redux
import * as Models from "../../models/models";
// common
import SimpleAlertView from "../../../../../../../common/simpleAlerts/SimpleAlertView";

// using a warnings view in lieu of having a warnings capability built into our validation framework
export default function WarningsView(props: { viewState: Models.ViewStateEnum; hasUnavailableDocuments: boolean }): React.ReactElement {
    // don't show warnings while loading
    if (props.viewState === Models.ViewStateEnum.Initialising) return <></>;

    return props.hasUnavailableDocuments ? (
        <SimpleAlertView alertType="warning" dismissable={false} message="One or more files are unable to be downloaded via Secure Portal. Please contact NOPTA if you require a copy." />
    ) : (
        <></>
    );
}
