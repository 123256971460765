// framework
import { useState, useEffect } from "react";
// kendo
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
// redux
import * as Models from "../../../models/models";
import * as Actions from "../../../redux/actions";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import ExternalLinkView from "../../../../../../common/externalLink/ExternalLinkView";

interface IProps {
    vm: Models.IRootViewModel;
    actions: Actions.IActionFactory;
    applicationGuideLink: string | undefined;
    onCreate: () => void;
    onCancel: () => void;
}

export default function CreateDraftApplicationView(props: IProps): JSX.Element {
    const { vm, actions } = props;

    // - draft application details state
    const [state, setState] = useState<Models.IDraftApplicationDetailsViewModel>({
        applicant: undefined,
        applicationType: undefined,
    });

    useEffect(() => {
        setState({
            applicant: vm.draftApplicationDetails.applicant,
            applicationType: vm.draftApplicationDetails.applicationType,
        });
    }, [vm.draftApplicationDetails]);

    // onChange - update state and viewModel together onChange as selections are used to drive Create button state and can be lost in race condition with title map load
    function onChange(values: Models.IDraftApplicationDetailsViewModel) {
        const updatedState = { ...state, ...values };

        setState(updatedState);
        actions.viewChanged(vm.refreshDraftApplicationDetailsUpdated(updatedState));
    }

    function onCreate() {
        props.onCreate();
    }

    function onCancel() {
        props.onCancel();
    }

    return (
        <>
            <Dialog title="Create Application" onClose={onCancel} width={400}>
                <Forms.Group>
                    <Forms.ReadonlyField label="Title" id="titleField" value={vm.details?.titleNumber} />
                </Forms.Group>
                <Forms.Group>
                    <Forms.Label htmlFor="applicantField">Applicant</Forms.Label>
                    <DropDownList
                        id="applicantField"
                        data={vm.createDraftApplicationReferenceData?.applicants}
                        value={state.applicant}
                        dataItemKey="companyId"
                        textField="companyName"
                        onChange={(e) => onChange({ applicant: e.value })}
                    />
                </Forms.Group>
                <Forms.Group>
                    <Forms.Label htmlFor="applicationTypeField">Application Type</Forms.Label>
                    <DropDownList
                        id="applicationTypeField"
                        data={vm.createDraftApplicationReferenceData?.applicationTypes}
                        value={state.applicationType}
                        dataItemKey="type"
                        textField="name"
                        onChange={(e) => onChange({ applicationType: e.value })}
                    />
                </Forms.Group>
                <DialogActionsBar>
                    <ExternalLinkView className="text-nowrap" href={props.applicationGuideLink}>
                        Application Guide
                    </ExternalLinkView>
                    <button className="btn btn-primary m-2" style={{ width: 75 }} onClick={onCreate} disabled={!(state.applicant && state.applicationType)}>
                        Create
                    </button>
                    <button className="btn btn-primary m-2" style={{ width: 75 }} autoFocus onClick={onCancel}>
                        Cancel
                    </button>
                </DialogActionsBar>
            </Dialog>
        </>
    );
}
