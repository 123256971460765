// framework
import { useState } from "react";
// kendo
import { process, State } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridSelectionChangeEvent, GridHeaderSelectionChangeEvent } from "@progress/kendo-react-grid";
// redux
import * as Models from "../../models/models";
import * as Actions from "../../redux/actions";
// common
import CustomGridCell from "../../../../../common/kendoGrid/CustomGridCell";
import SecureFileDownloadLinkControl from "../../../../../common/secureFileDownload/SecureFileDownloadLinkControl";
import { IValidationModel } from "../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";

export default function FilesGridView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; v: IValidationModel }): React.ReactElement {
    const vm = props.vm;
    const files = vm.files;
    const actions = props.actions;
    const v = props.v;

    const initialGridState = {
        sort: [{ field: "fileName", dir: "asc" }],
        skip: 0,
        take: 10,
    } as State;
    const [gridState, setGridState] = useState(initialGridState);

    function onSelectionChange(e: GridSelectionChangeEvent): void {
        const f = e.dataItem as Models.IFileViewModel;
        if (!f) return;
        actions.viewChanged(vm.onFileSelected(f.fileId));
    }

    function onHeaderSelectionChange(e: GridHeaderSelectionChangeEvent): void {
        const headerCheckbox = e.syntheticEvent.target as HTMLInputElement;
        actions.viewChanged(vm.onFilesSelected(headerCheckbox.checked));
    }

    return (
        <>
            <Grid
                className="mb-2"
                pageable
                sortable
                resizable
                navigatable
                selectedField="isSelected"
                onSelectionChange={onSelectionChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
                pageSize={10}
                data={process(files, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn field="isSelected" title="Select" width={100} headerSelectionValue={files.length > 0 && files.every((f) => f.isSelected)} />
                <GridColumn
                    field="fileName"
                    title="File Name"
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <SecureFileDownloadLinkControl id={props.dataItem.fileId}>{props.dataItem.fileName}</SecureFileDownloadLinkControl>
                        </CustomGridCell>
                    )}
                    width={750}
                />
                <GridColumn
                    field="fileSizeMb"
                    title="File Size"
                    width={150}
                    cell={(props) => <CustomGridCell gridCellProps={props}>{GlobalHelpers.convertMegabytesToString(props.dataItem.fileSizeMb)}</CustomGridCell>}
                />
            </Grid>
            <ValidationMessageView name="AdhocUpload.Files" validationModel={v} />
        </>
    );
}
