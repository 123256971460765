// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_WellsBoreholes_ReportList;

export default function* search() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const reportResponse: Client.GetGeoscienceAustraliaReportListResponseDto = yield call(async () => {
            const client = await ClientFactory.createGeoscienceAustraliaReportClient();
            return await CallHelper.simpleCall(() =>
                client.getGeoscienceAustraliaReportList(
                    new Client.GetGeoscienceAustraliaReportListRequestDto({
                        filterFullTextSearch: vm.searchText,
                        advancedFilters:
                            vm.advancedSearchFilter && vm.advancedSearchFilter.filterItems
                                ? vm.advancedSearchFilter.filterItems.map(
                                      (fi) =>
                                          new Client.SearchAdvancedFilterDto({
                                              searchType: fi.searchType.value,
                                              operation: fi.operationType?.value,
                                              ids: fi.wellBoreholes
                                                  .map((b) => b.id)
                                                  .concat(fi.acquisitions.map((a) => a.acquisitionId))
                                                  .concat(fi.projects.map((p) => p.id)),
                                              guids: [],
                                              items: fi.enos
                                                  .filter((e) => e.eno)
                                                  .map((e) => e.eno!)
                                                  .concat(!fi.items ? [] : fi.items.split(",")),
                                              text: fi.text,
                                          })
                                  )
                                : [],
                    })
                )
            );
        });

        yield put(Actions.actionFactory.sagaCompleted(vm.refreshList(reportResponse)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
