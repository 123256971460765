// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_UserManagement_UserList;

export default function* initialise() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());
        const response: Client.GetGeoscienceAustraliaUserManagementUserListResponseDto = yield call(async () => {
            const client = await ClientFactory.createGeoscienceAustraliaUserManagementClient();
            return await CallHelper.simpleCall(() => client.getGeoscienceAustraliaUserManagementUserList(new Client.GetGeoscienceAustraliaUserManagementUserListRequestDto()));
        });
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshSaga(response)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
