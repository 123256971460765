import * as Validation from "../../../../common/validation/ValidationModel";
import * as Model from "./models";
import * as EditableSecureUser from "./editableSecureUserViewModel";

export default function validate(vm: Model.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    if (vm.editableSecureUser) {
        validateSecureUserDetails(vm.editableSecureUser, v);
    }
    return v;
}

function validateSecureUserDetails(vm: EditableSecureUser.IEditableSecureUser, v: Validation.ValidationModel): void {
    if (Validation.isNullOrWhiteSpace(vm.correspondenceEmailAddress)) {
        v.addError("SecureUser.CorrespondenceEmailAddress", "Correspondence Email is required.");
    } else if (!Validation.isValidEmail(vm.correspondenceEmailAddress!)) {
        v.addError("SecureUser.CorrespondenceEmailAddress", "Correspondence Email is not in the correct format.");
    } else if (!vm.correspondenceEmailAddress!.endsWith(".gov.au")) {
        v.addError("SecureUser.CorrespondenceEmailAddress", "Correspondence Email must be a .gov.au domain.");
    } else if (vm.correspondenceEmailAddress!.length > 100) {
        v.addError("SecureUser.CorrespondenceEmailAddress", "Correspondence Email must be 100 characters or less.");
    }

    if (vm.contactNumber && vm.contactNumber.length > 100) v.addError("SecureUser.ContactNumber", "Contact Number must be 100 characters or less.");
    if (vm.position && vm.position.length > 100) v.addError("SecureUser.Position", "Position must be 100 characters or less.");
}
