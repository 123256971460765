import { IAttachmentItemViewModel } from "./models";
import * as Validation from "../../../../../common/validation/ValidationModel";
import * as documentHelper from "../../../../../common/documentHelper";

export default function validate(selectedAttachments: IAttachmentItemViewModel[], visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    const maxBatchDownloads = documentHelper.maxBatchDownloads();

    if (!selectedAttachments.some((item) => item.isSelected)) {
        v.addError("batchDownload.ItemSelected", "At least one Attachment must be selected.");
    }

    if (selectedAttachments.filter((item) => item.isSelected).length > maxBatchDownloads) {
        v.addError("batchDownload.ItemSelected", `Cannot select more than ${maxBatchDownloads} items.`);
    }

    return v;
}
