// redux
import * as Validation from "../../../../common/validation/ValidationModel";
import * as Model from "./rootViewModel";

export default function validate(vm: Model.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);

    if (vm.isGeoscienceAustraliaStandardRoleRequest) validateSecureRequestGeoscienceAustraliaStandardRoleDecision(vm.geoscienceAustraliaStandardRoleDetails, v);
    validateRejectionDetails(vm.rejectionDetails, v);

    return v;
}

function validateSecureRequestGeoscienceAustraliaStandardRoleDecision(vm: Model.ISecureRequestGeoscienceAustraliaStandardRoleDetailsViewModel, v: Validation.ValidationModel): void {
    if (vm.hasAllGeneralRoles && vm.isApproved !== false) {
        v.addError("GeoscienceAustraliaStandardRoleDetails.IsApproved", "The user has all available permissions already. Please reject the request.");
    } else if (vm.isApproved === true) {
        if (!vm.grantedRoles || !vm.grantedRoles.hasRole()) {
            v.addError("GeoscienceAustraliaStandardRoleDetails.IsApproved", "The user must have at least one role assigned in order to be approved.");
        } else if (vm.grantedRoles.getDirectRoles().some((role) => vm.existingRoles!.hasRole(role))) {
            v.addError("GeoscienceAustraliaStandardRoleDetails.IsApproved", "The user cannot be granted an existing permission.");
        }
    } else if (vm.isApproved === undefined) {
        v.addError("GeoscienceAustraliaStandardRoleDetails.IsApproved", "Approve or Reject must be selected.");
    }
}

function validateRejectionDetails(vm: Model.ISecureRequestRejectionsDetailsViewModel, v: Validation.ValidationModel): void {
    if (vm.comments && vm.comments.length > 1000) v.addError("RejectionDetails.Comments", "Reason must be 1000 characters or less.");
}
