// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    surveyId: number | undefined;
    surveyAcquisitions: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyAcquisitionsResponseDto | undefined;

    refreshSurveyAcquisitions(surveyId: number, response: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyAcquisitionsResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.surveyId = undefined;
        this.surveyAcquisitions = undefined;
    }

    public surveyId: number | undefined;
    public surveyAcquisitions: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyAcquisitionsResponseDto | undefined;

    public refreshSurveyAcquisitions(surveyId: number, response: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyAcquisitionsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.surveyId = surveyId;
        vm.surveyAcquisitions = response;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.surveyAcquisitions = clone(this.surveyAcquisitions);
        return vm;
    }
}
