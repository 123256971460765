import * as Models from "../models/models";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "geoscienceAustralia.surveysAcquisitions.surveyReports.initialiseRequested",
    batchUpdateRequested = "geoscienceAustralia.surveysAcquisitions.surveyReports.batchUpdateRequested",
    clearRequested = "geoscienceAustralia.surveysAcquisitions.surveyReports.clearRequested",
    // user input
    viewChanged = "geoscienceAustralia.surveysAcquisitions.surveyReports.viewChanged",
    // saga events
    sagaCompleted = "geoscienceAustralia.surveysAcquisitions.surveyReports.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

export interface IInitialiseRequestedPayload {
    surveyId: number;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(surveyId: number): IAction<IInitialiseRequestedPayload>;
    batchUpdateRequested(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    clearRequested(): IAction<undefined>;
    // user input
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    // saga events
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (surveyId: number): IAction<IInitialiseRequestedPayload> {
        return {
            type: actions.initialiseRequested,
            payload: { surveyId: surveyId },
        };
    },
    batchUpdateRequested: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.batchUpdateRequested,
            payload: vm,
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
