// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    boreholes: Client.GetGeoscienceAustraliaWellsBoreholesBoreholeListItemDto[];
    isResultSetLimited: boolean;
    searchText: string;

    refreshList(response: Client.GetGeoscienceAustraliaWellsBoreholesBoreholeListResponseDto): IRootViewModel;
    changeSearchText(value: string): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.boreholes = [];
        this.isResultSetLimited = false;
        this.searchText = "";
    }

    public boreholes: Client.GetGeoscienceAustraliaWellsBoreholesBoreholeListItemDto[];
    public isResultSetLimited: boolean;
    public searchText: string;

    public refreshList(response: Client.GetGeoscienceAustraliaWellsBoreholesBoreholeListResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.boreholes = response.boreholes;
        vm.isResultSetLimited = response.isResultSetLimited;

        return vm;
    }

    public changeSearchText(value: string): IRootViewModel {
        const vm = this._clone();

        vm.searchText = value;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
