import * as Client from "../../../../../api/Client";
import * as Models from "../models/models";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "geoscienceAustralia.wellsBoreholes.reportRelatedActivities.initialiseRequested",
    addRequested = "geoscienceAustralia.wellsBoreholes.reportRelatedActivities.updateRequested",
    deleteRequested = "geoscienceAustralia.wellsBoreholes.reportRelatedActivities.deleteRequested",
    viewChanged = "geoscienceAustralia.wellsBoreholes.reportRelatedActivities.viewChanged",
    clearRequested = "geoscienceAustralia.wellsBoreholes.reportRelatedActivities.clearRequested",
    // saga events
    sagaCompleted = "geoscienceAustralia.wellsBoreholes.reportRelatedActivities.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

export interface IInitialiseRequestedPayload {
    reportId: number;
}

export interface IDeleteRequestedPayload {
    reportId: number;
    reportVersionNumber: number;
    activityId: number;
    activityType: Client.ReportRelatedActivityTypeEnum;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(reportId: number): IAction<IInitialiseRequestedPayload>;
    addRequested(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    deleteRequested(reportId: number, reportVersionNumber: number, activityId: number, activityType: Client.ReportRelatedActivityTypeEnum): IAction<IDeleteRequestedPayload>;
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    clearRequested(): IAction<undefined>;
    // saga events
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (reportId: number): IAction<IInitialiseRequestedPayload> {
        return {
            type: actions.initialiseRequested,
            payload: { reportId: reportId },
        };
    },
    addRequested: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.addRequested,
            payload: vm,
        };
    },
    deleteRequested: function (reportId: number, reportVersionNumber: number, activityId: number, activityType: Client.ReportRelatedActivityTypeEnum): IAction<IDeleteRequestedPayload> {
        return {
            type: actions.deleteRequested,
            payload: { reportId: reportId, reportVersionNumber: reportVersionNumber, activityId: activityId, activityType: activityType },
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
