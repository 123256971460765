// redux
import * as Models from "../../models/models";
// common
import ApprovalTrackingControl from "../../controls/approvalTracking/components/Controller";

export default function ApprovalTrackingView(props: { vm: Models.IRootViewModel; lastRefreshedDatetime: Date | undefined }) {
    const vm = props.vm;
    const id = vm.id!;

    if (!id) return <></>;

    return (
        <>
            <ApprovalTrackingControl id={id} cardTitle="Approval Tracking" lastRefreshedDatetime={props.lastRefreshedDatetime} className="mb-2" />
        </>
    );
}
