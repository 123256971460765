// framework
import { useEffect, useState } from "react";
// kendo
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { orderBy } from "@progress/kendo-data-query";
// redux
import { IAddressDetailsViewModel } from "./addressDetailsViewModel";
// api
import * as Client from "../../../../api/Client";
// common
import { ValidationModel } from "../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../common/validation/ValidationMessageView";
import * as Forms from "../../../../common/forms/BootstrapForms";

export default function AddressDetailsView(props: {
    vm: IAddressDetailsViewModel;
    v: ValidationModel;
    countries: Array<Client.SecurePortalCountryDto>;
    updateViewModel: (vm: IAddressDetailsViewModel) => void;
}) {
    const vm = props.vm;
    const v = props.v;
    const countries = props.countries;

    const [state, setState] = useState<IAddressDetailsViewModel>({
        addressType: undefined,
        streetAddress: "",
        suburb: "",
        postcode: "",
        state: undefined,
        country: undefined,
    });

    useEffect(() => {
        setState({
            addressType: vm.addressType,
            streetAddress: vm.streetAddress ?? "",
            suburb: vm.suburb ?? "",
            postcode: vm.postcode ?? "",
            state: vm.state,
            country: vm.country,
        });
    }, [vm]);

    // reference data
    const [countryData, setCountryData] = useState<Array<Client.SecurePortalCountryDto>>();

    useEffect(() => {
        setCountryData(countries);
    }, [countries]);

    // address dropdowns
    const [stateData, setStateData] = useState<Array<Client.SecurePortalCountrySubdivisionDto>>();
    const [selectedState, setSelectedState] = useState<Client.SecurePortalCountrySubdivisionDto | undefined | null>();
    const [selectedCountry, setSelectedCountry] = useState<Client.SecurePortalCountryDto | undefined | null>();

    // update selected country if changed - must be set to null in order to programatically clear the combobox and update state dropdown data
    useEffect(() => {
        setSelectedCountry(vm.country ?? null);
        const stateData = vm.country?.subdivisions ?? new Array<Client.SecurePortalCountrySubdivisionDto>();
        setStateData(orderBy(stateData, [{ field: "countrySubdivisionName", dir: "asc" }]));
    }, [vm.country]);
    // update selected state if changed - must be set to null in order to programatically clear the combobox
    useEffect(() => {
        setSelectedState(vm.state ?? null);
    }, [vm.state]);

    // country dropdown change - set state dropdown data and clear current state selection
    const onCountryChange = (event: DropDownListChangeEvent) => {
        const i: Client.SecurePortalCountryDto | undefined = event.value;
        const stateData = i?.subdivisions ?? new Array<Client.SecurePortalCountrySubdivisionDto>();

        setSelectedCountry(i);
        setStateData(orderBy(stateData, [{ field: "countrySubdivisionName", dir: "asc" }]));
        setSelectedState(null);
        setState({ ...state, country: i, state: undefined });
    };
    // state dropdown change
    const onStateChange = (event: DropDownListChangeEvent) => {
        const i: Client.SecurePortalCountrySubdivisionDto | undefined = event.value;

        setSelectedState(i);
        setState({ ...state, state: i });
    };

    // address type change
    function onAddressTypeChange(value: Client.LegislativeFormCompanyAddressTypeEnum) {
        const updatedState = { ...state, addressType: value };
        setState(updatedState);
        props.updateViewModel(updatedState);
    }

    // generic input change
    function onChanged(values: any) {
        setState({ ...state, ...values });
    }

    function onBlur() {
        props.updateViewModel({ ...state });
    }

    return (
        <div className="card mb-2">
            <div className="card-body">
                <h3 className="card-title">Head office, registered office or a principal office address (if body corporate)</h3>
                <Forms.Row>
                    <Forms.FullColumn>
                        <Forms.RadioButton
                            name="addressTypeGroup"
                            id="isBusinessAddressRadioField"
                            label="Business Address"
                            value={Client.LegislativeFormCompanyAddressTypeEnum.Business}
                            checked={state.addressType === Client.LegislativeFormCompanyAddressTypeEnum.Business}
                            valid={v.applyValidity("AddressDetails.AddressType")}
                            onChange={onAddressTypeChange}
                            inline
                        />
                        <Forms.RadioButton
                            name="addressTypeGroup"
                            id="isPostalAddressRadioField"
                            label="Street Address"
                            value={Client.LegislativeFormCompanyAddressTypeEnum.Postal}
                            checked={state.addressType === Client.LegislativeFormCompanyAddressTypeEnum.Postal}
                            valid={v.applyValidity("AddressDetails.AddressType")}
                            onChange={onAddressTypeChange}
                            inline
                        />
                        <ValidationMessageView name="AddressDetails.AddressType" validationModel={v} />
                    </Forms.FullColumn>
                    <Forms.FullColumn>
                        <Forms.TextArea
                            id="streetAddressField"
                            label="Street Address"
                            rows={5}
                            maxLength={1000}
                            value={state.streetAddress}
                            valid={v.applyValidity("AddressDetails.StreetAddress")}
                            onChange={(value) => onChanged({ streetAddress: value })}
                            onBlur={onBlur}
                        />
                        <ValidationMessageView name="AddressDetails.StreetAddress" validationModel={v} />
                    </Forms.FullColumn>
                    <Forms.HalfColumn>
                        <Forms.TextInput
                            id="suburbField"
                            label="Town/Suburb"
                            value={state.suburb}
                            valid={v.applyValidity("AddressDetails.Suburb")}
                            onChange={(value) => onChanged({ suburb: value })}
                            onBlur={onBlur}
                        />
                        <ValidationMessageView name="AddressDetails.Suburb" validationModel={v} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.TextInput
                            id="postcodeField"
                            label="Postcode"
                            value={state.postcode}
                            valid={v.applyValidity("AddressDetails.Postcode")}
                            onChange={(value) => onChanged({ postcode: value })}
                            onBlur={onBlur}
                        />
                        <ValidationMessageView name="AddressDetails.Postcode" validationModel={v} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.Label htmlFor="stateField">State/Territory</Forms.Label>
                        <DropDownList
                            id="stateField"
                            valid={v.applyValidityForKendo("AddressDetails.State")}
                            data={stateData}
                            disabled={!selectedCountry}
                            value={selectedState}
                            dataItemKey="countrySubdivisionId"
                            textField="countrySubdivisionName"
                            onChange={onStateChange}
                            onBlur={onBlur}
                            popupSettings={{
                                height: 300, // add more height so we can see all the australian states
                            }}
                        />
                        <ValidationMessageView name="AddressDetails.State" validationModel={v} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.Label htmlFor="countryField">Country</Forms.Label>
                        <DropDownList
                            id="countryField"
                            valid={v.applyValidityForKendo("AddressDetails.Country")}
                            data={countryData}
                            value={selectedCountry}
                            dataItemKey="countryId"
                            textField="countryName"
                            onChange={onCountryChange}
                            onBlur={onBlur}
                        />
                        <ValidationMessageView name="AddressDetails.Country" validationModel={v} />
                    </Forms.HalfColumn>
                </Forms.Row>
            </div>
        </div>
    );
}
