// api
import * as Client from "../../../../../../api/Client";
// other
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import TitleDetailsField from "../TitleDetailsField";
import TitleSummaryPriorUsageRightField from "./TitleSummaryPriorUsageRightField";

function TitleSummaryPlView(props: { summary: Client.GetCompanyOpggsTitleDetailsSummaryDto | undefined }) {
    const summary = props.summary;

    return (
        <>
            <TitleDetailsField label="Status">{summary?.titleStatus}</TitleDetailsField>
            <TitleDetailsField label="Offshore Area">{summary?.offshoreAreaName}</TitleDetailsField>
            <TitleDetailsField label="Number of Renewals">{GlobalHelpers.toNoptaIntegerString(summary?.numberOfRenewals)}</TitleDetailsField>
            <TitleDetailsField label="Initial Grant Date">{GlobalHelpers.toNoptaDateString(summary?.initialGrantDate)}</TitleDetailsField>
            <TitleDetailsField label="Expiry Date">{GlobalHelpers.toNoptaDateString(summary?.currentExpiryDate, "Indefinite")}</TitleDetailsField>
            <TitleDetailsField label="Last Renewal Date">{GlobalHelpers.toNoptaDateString(summary?.lastRenewalDate)}</TitleDetailsField>
            <TitleDetailsField label="Pipeline Name">{summary?.pipelineName}</TitleDetailsField>
            <TitleDetailsField label="Pipeline Substance">{summary?.substanceName}</TitleDetailsField>
            <TitleDetailsField label="Start Point">{summary?.startPointName}</TitleDetailsField>
            <TitleDetailsField label="End Point">{summary?.endPointName}</TitleDetailsField>
            <TitleSummaryPriorUsageRightField hasPriorUsageRight={summary?.hasPriorUsageRight} />
            <TitleDetailsField label="Length">{GlobalHelpers.toNoptaNumberString(summary?.pipelineLength, "n", "km")}</TitleDetailsField>
        </>
    );
}

export default TitleSummaryPlView;
