// framework
import { Link } from "react-router-dom";
// models
import { IRootViewModel } from "../../models/models";
// api
import { NopimsTitleTypeEnum } from "../../../../../../api/Client";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import ExternalLinkView from "../../../../../../common/externalLink/ExternalLinkView";
import SimpleAuditEventsView from "../../../../../../common/audit/SimpleAuditEventsView";
import * as shellRouteHelper from "../../../../../../shell/shellRouteHelper";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";

interface IProps {
    vm: IRootViewModel;
}

export default function AcquisitionDetailsView(props: IProps): JSX.Element {
    const { vm } = props;
    const acquisition = vm.acquisition;

    return (
        <>
            {acquisition && (
                <>
                    <div className="card mb-2">
                        <div className="card-body">
                            <h2 className="card-title">General</h2>
                            <Forms.Group>
                                <Forms.Row>
                                    <Forms.QuarterColumn>
                                        <Forms.ReadonlyField id="acquisitionIdField" label="Acquisition ID" value={acquisition.acquisitionIdentifier} />
                                    </Forms.QuarterColumn>
                                    <Forms.QuarterColumn>
                                        <Forms.ReadonlyField id="nameField" label="Name" value={acquisition.acquisitionName} />
                                    </Forms.QuarterColumn>
                                    <Forms.QuarterColumn>
                                        <Forms.ReadonlyField id="surveyNameField" label="Survey Name" value={acquisition.surveyName} />
                                    </Forms.QuarterColumn>
                                    <Forms.QuarterColumn>
                                        <Forms.Element label="Survey ID">
                                            <Link to={geoscienceAustraliaRouteHelper.surveyDetails(acquisition.surveyId)} title="Click to view Survey details.">
                                                {acquisition.surveyIdentifier}
                                            </Link>
                                        </Forms.Element>
                                    </Forms.QuarterColumn>
                                    <Forms.QuarterColumn>
                                        <Forms.ReadonlyField id="aliasField" label="Alias(es)" value={acquisition.acquisitionAlias} />
                                    </Forms.QuarterColumn>
                                    <Forms.QuarterColumn>
                                        <Forms.ReadonlyField id="stateField" label="Status" value={acquisition.acquisitionStatus} />
                                    </Forms.QuarterColumn>
                                    <Forms.QuarterColumn>
                                        <Forms.ReadonlyField id="typeField" label="Type" value={acquisition.acquisitionType} />
                                    </Forms.QuarterColumn>
                                    <Forms.QuarterColumn>
                                        <Forms.ReadonlyField id="administrationTypeField" label="Petroleum/GHG" value={acquisition.titleAdministrationType} />
                                    </Forms.QuarterColumn>
                                </Forms.Row>
                            </Forms.Group>
                            <Forms.Group>
                                <Forms.Row>
                                    <Forms.QuarterColumn>
                                        <Forms.ReadonlyField id="linePrefixField" label="Line Prefix" value={acquisition.linePrefix} />
                                    </Forms.QuarterColumn>
                                    <Forms.QuarterColumn>
                                        <Forms.ReadonlyField id="terrainTypeField" label="Terrain Type" value={acquisition.terrainType} />
                                    </Forms.QuarterColumn>
                                    <Forms.QuarterColumn>
                                        <Forms.ReadonlyField id="exclusivityField" label="Exclusivity" value={acquisition.nopimsExclusivityStatus} />
                                    </Forms.QuarterColumn>
                                </Forms.Row>
                            </Forms.Group>
                            <Forms.Group>
                                <Forms.Row>
                                    <Forms.QuarterColumn>
                                        <Forms.Label htmlFor="titleConditionField">Title Condition(s)</Forms.Label>
                                        <div>
                                            {acquisition.surveyTitleConditions && acquisition.surveyTitleConditions.length > 0 ? (
                                                <>
                                                    {acquisition.surveyTitleConditions.map((title, index) => (
                                                        <span key={index}>
                                                            {title.nopimsTitleType === NopimsTitleTypeEnum.Nopta && title.titleNumber && (
                                                                <ExternalLinkView href={shellRouteHelper.publicPortalTitleDetails(title.titleNumber)}>{title.titleNumber}</ExternalLinkView>
                                                            )}
                                                            {title.nopimsTitleType === NopimsTitleTypeEnum.Other && <span>{title.otherTitleNumber}</span>}
                                                            {index < acquisition.surveyTitleConditions.length - 1 && <span>{", "}</span>}
                                                        </span>
                                                    ))}
                                                </>
                                            ) : (
                                                <Forms.ReadonlyTextInput id="titleConditionField" />
                                            )}
                                        </div>
                                    </Forms.QuarterColumn>
                                    <Forms.QuarterColumn>
                                        <Forms.Label htmlFor="authorisingTitlesField">Authorising Title(s)</Forms.Label>
                                        <div>
                                            {acquisition.authorisingTitles && acquisition.authorisingTitles.length > 0 ? (
                                                <>
                                                    {acquisition.authorisingTitles.map((title, index) => (
                                                        <span key={index}>
                                                            {title.nopimsTitleType === NopimsTitleTypeEnum.Nopta && title.titleNumber && (
                                                                <ExternalLinkView href={shellRouteHelper.publicPortalTitleDetails(title.titleNumber)}>{title.titleNumber}</ExternalLinkView>
                                                            )}
                                                            {title.nopimsTitleType === NopimsTitleTypeEnum.Other && <span>{title.otherTitleNumber}</span>}
                                                            {index < acquisition.authorisingTitles.length - 1 && <span>{", "}</span>}
                                                        </span>
                                                    ))}
                                                </>
                                            ) : (
                                                <Forms.ReadonlyTextInput id="authorisingTitlesField" />
                                            )}
                                        </div>
                                    </Forms.QuarterColumn>
                                    <Forms.QuarterColumn>
                                        <Forms.Label htmlFor="titleCoverageField">Title Coverage</Forms.Label>
                                        <div>
                                            {acquisition.titlesCoverage && acquisition.titlesCoverage.length > 0 ? (
                                                <>
                                                    {acquisition.titlesCoverage.map((title, index) => (
                                                        <span key={index}>
                                                            {title.nopimsTitleType === NopimsTitleTypeEnum.Nopta && title.titleNumber && (
                                                                <ExternalLinkView href={shellRouteHelper.publicPortalTitleDetails(title.titleNumber)}>{title.titleNumber}</ExternalLinkView>
                                                            )}
                                                            {title.nopimsTitleType === NopimsTitleTypeEnum.Other && <span>{title.otherTitleNumber}</span>}
                                                            {index < acquisition.titlesCoverage.length - 1 && <span>{", "}</span>}
                                                        </span>
                                                    ))}
                                                </>
                                            ) : (
                                                <Forms.ReadonlyTextInput id="titleConditionField" />
                                            )}
                                        </div>
                                    </Forms.QuarterColumn>
                                </Forms.Row>
                            </Forms.Group>
                            <Forms.Group>
                                <Forms.Row>
                                    <Forms.QuarterColumn>
                                        <Forms.ReadonlyField id="contractorField" label="Contractor" value={acquisition.contractor} />
                                    </Forms.QuarterColumn>
                                    <Forms.QuarterColumn>
                                        <Forms.ReadonlyField id="vesselField" label="Vessel" value={acquisition.vessel} />
                                    </Forms.QuarterColumn>
                                    <Forms.QuarterColumn>
                                        <Forms.ReadonlyField id="operatorField" label="Operator" value={acquisition.surveyOperatorCompany} />
                                    </Forms.QuarterColumn>
                                    <Forms.FullColumn>
                                        <Forms.ReadonlyTextArea id="commentsField" label="Comments" value={acquisition.comments} />
                                    </Forms.FullColumn>
                                    <Forms.FullColumn>
                                        <Forms.ReadonlyTextArea id="publicCommentsField" label="Public Comments" value={acquisition.publicComments} />
                                    </Forms.FullColumn>
                                </Forms.Row>
                            </Forms.Group>
                        </div>
                    </div>
                    <div className="card mb-2">
                        <div className="card-body">
                            <h2 className="card-title">Spatial</h2>
                            <Forms.Row>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="totalLineLengthField" label="Total Line Length" value={GlobalHelpers.toNoptaNumberString(acquisition.totalLineLength, "n2")} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.Label htmlFor="areaSqKmsField">
                                        Area (km<sup>2</sup>)
                                    </Forms.Label>
                                    <Forms.ReadonlyField id="finalisedAreaField" label="" value={GlobalHelpers.toNoptaNumberString(acquisition.areaSqKms, "n2")} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField
                                        id="fullFoldTotalLineLengthField"
                                        label="Full Fold Total Line Length"
                                        value={GlobalHelpers.toNoptaNumberString(acquisition.fullFoldTotalLineLength, "n2")}
                                    />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.Label htmlFor="fullFoldAreaSqKmsField">
                                        Full Load Area (km<sup>2</sup>)
                                    </Forms.Label>
                                    <Forms.ReadonlyField id="fullFoldFinalisedAreaField" label="" value={GlobalHelpers.toNoptaNumberString(acquisition.fullFoldAreaSqKms, "n2")} />
                                </Forms.QuarterColumn>
                            </Forms.Row>
                        </div>
                    </div>
                    <div className="card mb-2">
                        <div className="card-body">
                            <h2 className="card-title">Dates</h2>
                            <Forms.Row>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="startDateField" label="Start Date" value={GlobalHelpers.toNoptaDateString(acquisition.startDate)} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="endDateField" label="End Date" value={GlobalHelpers.toNoptaDateString(acquisition.endDate)} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="basicRelevantDateField" label="Basic Relevant Date" value={GlobalHelpers.toNoptaDateString(acquisition.basicRelevantDate)} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="basicReleaseDateField" label="Basic Release Date" value={GlobalHelpers.toNoptaDateString(acquisition.basicReleaseDate)} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField
                                        id="interpretativeRelevantDateField"
                                        label="Interpretative Relevant Date"
                                        value={GlobalHelpers.toNoptaDateString(acquisition.interpretativeRelevantDate)}
                                    />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField
                                        id="interpretativeReleaseDateField"
                                        label="Interpretative Release Date"
                                        value={GlobalHelpers.toNoptaDateString(acquisition.interpretativeReleaseDate)}
                                    />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="subset2dRelevantDateField" label="Subset 2D Relevant Date" value={GlobalHelpers.toNoptaDateString(acquisition.subset2dRelevantDate)} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="subset2dReleaseDateField" label="Subset 2D Release Date" value={GlobalHelpers.toNoptaDateString(acquisition.subset2dReleaseDate)} />
                                </Forms.QuarterColumn>

                                <Forms.QuarterColumn />
                                <Forms.QuarterColumn />
                                <Forms.QuarterColumn />
                                <Forms.QuarterColumn>
                                    <Forms.Checkbox id="isAutoCalculateDateField" label="Auto Calculate Dates" checked={acquisition.isAutoCalculateDates} disabled={true} onChange={(v) => {}} />
                                </Forms.QuarterColumn>
                            </Forms.Row>
                        </div>
                    </div>
                    <div className="card mb-2">
                        <div className="card-body">
                            <h2 className="card-title">Confidentiality</h2>
                            <Forms.Row>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField
                                        id="confidentialityField"
                                        label="Effective Status"
                                        value={GlobalHelpers.convertBooleanToConfidentialityValue(acquisition.confidentiality?.isConfidential)}
                                    />
                                </Forms.QuarterColumn>
                                {acquisition.confidentiality.isUserOverridden && (
                                    <Forms.QuarterColumn>
                                        <Forms.ReadonlyField
                                            id="systemConfidentialityField"
                                            label="System Status"
                                            value={GlobalHelpers.convertBooleanToConfidentialityValue(acquisition.confidentiality?.isSystemConfidential)}
                                        />
                                    </Forms.QuarterColumn>
                                )}
                                {acquisition.confidentiality.isUserOverridden && (
                                    <Forms.QuarterColumn>
                                        <Forms.ReadonlyField
                                            id="userConfidentialityField"
                                            label="User Status"
                                            value={GlobalHelpers.convertBooleanToConfidentialityValue(acquisition.confidentiality?.isUserConfidential)}
                                        />
                                    </Forms.QuarterColumn>
                                )}
                                {acquisition.confidentiality.isUserOverridden && (
                                    <Forms.FullColumn>
                                        <Forms.ReadonlyTextArea id="confidentialityCommentsField" label="Comments" value={acquisition.confidentiality?.comments} />
                                    </Forms.FullColumn>
                                )}
                            </Forms.Row>
                            <div className="mt-2">
                                <SimpleAuditEventsView simpleAuditEvents={acquisition.confidentiality.confidentialityAudit} />
                            </div>
                        </div>
                    </div>
                    <SimpleAuditEventsView simpleAuditEvents={acquisition.acquisitionAudit} />
                </>
            )}
        </>
    );
}
