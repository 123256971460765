// framework
import { TitleTypeEnum } from "../../../../../api/Client";
import * as Validation from "../../../../../common/validation/ValidationModel";
// models
import * as Models from "./models";

export default function validate(vm: Models.IIappApplicationDetailsViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateIappDetails(vm.iappDetails, v);
    return v;
}

function validateIappDetails(vm: Models.IIappDetailsViewModel, v: Validation.ValidationModel): void {
    // ep
    if (vm.originatingTitleType === TitleTypeEnum.ExplorationPermit) {
        if (!vm.epDetails!.locationId) {
            v.addError("IappDetails.EpDetails.Location", "A selection is required.");
        }
    }

    // rl
    if (vm.originatingTitleType === TitleTypeEnum.RetentionLease) {
        if (vm.rlDetails!.dateOfRevocationOrRefusalOfRenewalOfRetentionLeaseDate && vm.rlDetails!.dateOfRevocationOrRefusalOfRenewalOfRetentionLeaseDate < vm.rlDetails!.initialGrantDate!) {
            v.addError(
                "IappDetails.RlDetails.DateOfRevocationOrRefusalOfRenewalOfRetentionLeaseDate",
                "Date of Revocation or Refusal of Renewal of Retention Lease Date cannot be before the intial grant date."
            );
        }
    }
}
