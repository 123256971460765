// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";
import * as documentHelper from "../../../../../../common/documentHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_ReportsAndData_SurveyDataList;

export default function* exportSurveyData(action: Actions.IAction<Actions.IExportRequestedPayload>) {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const { onlySearchResults } = action.payload;

    try {
        yield put(shellHelper.createSetBusyAction());

        const downloadResponse: Client.ExportGeoscienceAustraliaSurveyDataResponseDto = yield call(async () => {
            const client = await ClientFactory.createGeoscienceAustraliaSurveyDataClient();

            let request = new Client.ExportGeoscienceAustraliaSurveyDataRequestDto({ filterFullTextSearch: "", advancedFilters: [] });

            if (onlySearchResults)
                request = new Client.ExportGeoscienceAustraliaSurveyDataRequestDto({
                    filterFullTextSearch: vm.searchText,
                    advancedFilters:
                        vm.advancedSearchFilter && vm.advancedSearchFilter.filterItems
                            ? vm.advancedSearchFilter.filterItems.map(
                                  (fi) =>
                                      new Client.SearchAdvancedFilterDto({
                                          searchType: fi.searchType.value,
                                          operation: fi.operationType?.value,
                                          ids: fi.wellBoreholes
                                              .map((b) => b.id)
                                              .concat(fi.acquisitions.map((a) => a.acquisitionId))
                                              .concat(fi.projects.map((p) => p.id)),
                                          guids: [],
                                          items: fi.enos
                                              .filter((e) => e.eno)
                                              .map((e) => e.eno!)
                                              .concat(!fi.items ? [] : fi.items.split(",")),
                                          text: fi.text,
                                      })
                              )
                            : [],
                });

            return await CallHelper.simpleCall(() => client.exportGeoscienceAustraliaSurveyData(request));
        });

        documentHelper.download(downloadResponse.fileName!, downloadResponse.mimeType!, downloadResponse.content!);
        yield put(Actions.actionFactory.sagaCompleted(vm));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
