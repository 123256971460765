// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export enum ViewStateEnum {
    Initialising,
    View,
    SelectBatchUpdate,
    EditBatchUpdate,
}

export interface IBatchUpdateViewModel {
    updateConfidentiality: boolean;
    confidentiality?: boolean | undefined;
    updateConfidentialityRemarks: boolean;
    confidentialityRemarksUpdateType: Client.ReportsStringUpdateTypeEnum;
    confidentialityRemarks?: string | undefined;
    updateReceivedDate: boolean;
    receivedDate?: Date | undefined;
    updateConsignment: boolean;
    consignmentUpdateType: Client.ReportsStringUpdateTypeEnum;
    consignment?: string | undefined;
    updateConditionStatus: boolean;
    conditionStatus: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingReportsConditionStatusDto | undefined;
    updateLoanStatus: boolean;
    loanStatus: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingReportsLoanStatusDto | undefined;
}

export interface IReportItemViewModel {
    id: number;
    versionNumber: number;
    itemId: string;
    publicTitle: string;
    reportType: string;
    receivedDate: Date | undefined;
    isConfidential: string | undefined;
    confidentialityRemarks: string;
    reportTitle: string;
    isSelected: boolean;
    updateFailed: boolean;
}

export interface IRootViewModel {
    reprocessingId: number | undefined;
    reprocessingName: string | undefined;
    projectId: number | undefined;
    projectName: string | undefined;
    canViewReprocessings: boolean;
    reports: IReportItemViewModel[];

    batchUpdateDetails: IBatchUpdateViewModel;

    referenceData: {
        loanStatuses: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectReportsLoanStatusDto[];
        conditionStatuses: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectReportsConditionStatusDto[];
    };

    statusMessages: Client.StatusMessagesDto | undefined;

    viewState: ViewStateEnum;

    isDirty: boolean;
    isConflict: boolean;

    refreshProjectReports(reprocessingId: number, projectId: number, response: Client.GetGeoscienceAustraliaReprocessingsProjectsProjectReportsResponseDto): IRootViewModel;
    refreshUpdateResponse(response: Client.UpdateGeoscienceAustraliaReprocessingsProjectsReprocessingReportsResponseDto): IRootViewModel;
    refreshConflict(): IRootViewModel;

    onReportUpdated(report: IReportItemViewModel): IRootViewModel;
    onBatchDetailsUpdate(batchUpdateDetails: IBatchUpdateViewModel): IRootViewModel;

    onSelectBatchUpdate(): IRootViewModel;
    onEditBatchUpdate(): IRootViewModel;
}

export class ReportItemViewModel implements IReportItemViewModel {
    constructor(report: Client.GetGeoscienceAustraliaReprocessingsProjectsProjectReportsItemDto) {
        this.id = report.id;
        this.versionNumber = report.versionNumber;
        this.itemId = report.itemId;
        this.publicTitle = report.publicTitle;
        this.reportType = report.reportType;
        this.receivedDate = report.receivedDate;
        this.isConfidential = report.isConfidential;
        this.confidentialityRemarks = report.confidentialityRemarks;
        this.reportTitle = report.reportTitle;
        this.isSelected = false;
        this.updateFailed = false;
        this.consignment = report.consignment;
        this.loanStatus = report.loanStatus;
        this.conditionStatus = report.conditionStatus;
        this.dateCreated = report.dateCreated;
        this.dateLastModified = report.dateLastModified;
    }

    public id: number;
    public versionNumber: number;
    public itemId: string;
    public publicTitle: string;
    public reportType: string;
    public receivedDate: Date | undefined;
    public isConfidential: string | undefined;
    public confidentialityRemarks: string;
    public reportTitle: string;
    public isSelected: boolean;
    public updateFailed: boolean;
    public consignment: string | undefined;
    public loanStatus: string | undefined;
    public conditionStatus: string | undefined;
    public dateCreated: Date;
    public dateLastModified: Date;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.reprocessingId = undefined;
        this.projectId = undefined;
        this.projectName = undefined;
        this.canViewReprocessings = false;
        this.reports = [];

        this.batchUpdateDetails = {
            updateConfidentiality: false,
            confidentiality: undefined,
            updateConfidentialityRemarks: false,
            confidentialityRemarksUpdateType: Client.ReportsStringUpdateTypeEnum.Append,
            confidentialityRemarks: undefined,
            updateReceivedDate: false,
            receivedDate: undefined,
            updateConsignment: false,
            consignmentUpdateType: Client.ReportsStringUpdateTypeEnum.Append,
            consignment: undefined,
            updateConditionStatus: false,
            conditionStatus: undefined,
            updateLoanStatus: false,
            loanStatus: undefined,
        };

        this.referenceData = {
            loanStatuses: [],
            conditionStatuses: [],
        };

        this.viewState = ViewStateEnum.Initialising;
        this.isDirty = false;
        this.isConflict = false;
    }

    public reprocessingId: number | undefined;
    public reprocessingName: string | undefined;
    public projectId: number | undefined;
    public projectName: string | undefined;
    public canViewReprocessings: boolean;

    public reports: IReportItemViewModel[];

    public batchUpdateDetails: IBatchUpdateViewModel;

    public referenceData: {
        loanStatuses: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectReportsLoanStatusDto[];
        conditionStatuses: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectReportsConditionStatusDto[];
    };

    public statusMessages: Client.StatusMessagesDto | undefined;

    public viewState: ViewStateEnum;

    public isDirty: boolean;
    public isConflict: boolean;

    public refreshProjectReports(reprocessingId: number, projectId: number, response: Client.GetGeoscienceAustraliaReprocessingsProjectsProjectReportsResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.reprocessingId = reprocessingId;
        vm.projectId = projectId;
        vm.projectName = response.projectName;
        vm.reprocessingName = response.reprocessingName;
        vm.canViewReprocessings = response.canViewReprocessings;
        vm.reports = response.items.map((i) => new ReportItemViewModel(i));

        vm.statusMessages = undefined;

        vm.viewState = ViewStateEnum.View;

        vm.referenceData = {
            loanStatuses: response.loanStatuses,
            conditionStatuses: response.conditionStatuses,
        };

        vm.batchUpdateDetails = {
            updateConfidentiality: false,
            confidentiality: undefined,
            updateConfidentialityRemarks: false,
            confidentialityRemarksUpdateType: Client.ReportsStringUpdateTypeEnum.Append,
            confidentialityRemarks: undefined,
            updateReceivedDate: false,
            receivedDate: undefined,
            updateConsignment: false,
            consignmentUpdateType: Client.ReportsStringUpdateTypeEnum.Append,
            consignment: undefined,
            updateConditionStatus: false,
            conditionStatus: undefined,
            updateLoanStatus: false,
            loanStatus: undefined,
        };

        vm.isDirty = false;
        vm.isConflict = false;

        return vm;
    }

    public refreshUpdateResponse(response: Client.UpdateGeoscienceAustraliaReprocessingsProjectsReprocessingReportsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = response.statusMessages;

        vm.reports = vm.reports.map((r) => {
            if (!response.failedReportIds!.some((id) => id === r.id)) return r;

            return { ...r, updateFailed: true };
        });

        // reset batch update details if update succeeded
        if (response.statusMessages?.isSuccess) {
            vm.batchUpdateDetails = {
                updateConfidentiality: false,
                confidentiality: undefined,
                updateConfidentialityRemarks: false,
                confidentialityRemarksUpdateType: Client.ReportsStringUpdateTypeEnum.Append,
                confidentialityRemarks: undefined,
                updateReceivedDate: false,
                receivedDate: undefined,
                updateConsignment: false,
                consignmentUpdateType: Client.ReportsStringUpdateTypeEnum.Append,
                consignment: undefined,
                updateConditionStatus: false,
                conditionStatus: undefined,
                updateLoanStatus: false,
                loanStatus: undefined,
            };
        }
        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        return vm;
    }

    public onReportUpdated(item: IReportItemViewModel): IRootViewModel {
        const vm = this._clone();

        vm.reports = vm.reports.map((r) => {
            if (r.id !== item.id) return r;
            return { ...r, isSelected: !r.isSelected };
        });

        vm.isDirty = true;
        return vm;
    }

    public onBatchDetailsUpdate(batchUpdateDetails: IBatchUpdateViewModel): IRootViewModel {
        const vm = this._clone();
        vm.batchUpdateDetails = batchUpdateDetails;
        return vm;
    }

    public onSelectBatchUpdate(): IRootViewModel {
        const vm = this._clone();
        vm.viewState = ViewStateEnum.SelectBatchUpdate;
        vm.statusMessages = undefined;
        return vm;
    }

    public onEditBatchUpdate(): IRootViewModel {
        const vm = this._clone();
        vm.viewState = ViewStateEnum.EditBatchUpdate;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.reports = clone(this.reports);
        return vm;
    }
}
