import { clone } from "lodash";

// todo ga all placeholder
export interface IRootViewModel {
    refreshSaga(response: undefined): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    public refreshSaga(response: undefined): IRootViewModel {
        const vm = this._clone();

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
