import { takeEvery } from "redux-saga/effects";
import * as Actions from "./actions";

import { resetPassword } from "./sagas/resetPassword";
import { signIn } from "./sagas/signIn";
import { signOut } from "./sagas/signOut";
import { trySilentSignIn } from "./sagas/trySilentSignIn";
import { signInExpired } from "./sagas/signInExpired";

export default function* watcherSaga() {
    yield takeEvery(Actions.actions.trySilentSignInRequested, trySilentSignIn);
    yield takeEvery(Actions.actions.signInExpired, signInExpired);
    yield takeEvery(Actions.actions.signInRequested, signIn);
    yield takeEvery(Actions.actions.signOutRequested, signOut);
    yield takeEvery(Actions.actions.resetPasswordRequested, resetPassword);
}
