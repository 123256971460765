// framework
import * as Validation from "../../../../../common/validation/ValidationModel";
// models
import * as Models from "./models";

export default function validate(vm: Models.IEpRenewalBlockDetailsViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateBlockDetails(vm, v);
    return v;
}

function validateBlockDetails(vm: Models.IEpRenewalBlockDetailsViewModel, v: Validation.ValidationModel): void {
    if (vm.blocksRenewed === undefined) {
        v.addError("BlockDetails.BlocksRenewed", "Blocks Renewed is required.");
    }
    if (vm.blocksRelinquished === undefined) {
        v.addError("BlockDetails.BlocksRelinquished", "Blocks Relinquished is required.");
    }

    if (vm.blocksRenewed !== undefined && vm.blocksRelinquished !== undefined) {
        const blocksCalculated = vm.blocksRenewed + vm.blocksRelinquished;
        if (blocksCalculated !== vm.numberOfBlocks) {
            v.addError("BlockDetails.BlocksRenewed", "Blocks to be renewed + Blocks to be relinquished must equal the current number of Blocks.");
            v.addError("BlockDetails.BlocksRelinquished", "Blocks to be renewed + Blocks to be relinquished must equal the current number of Blocks.");
        }
    }
}
