// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_WellsBoreholes_ReportDetails;

export default function* update() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const saveResult: CallHelper.CallResult<Client.UpdateGeoscienceAustraliaReportDetailsResponseDto> = yield call(async () => await updateReportDetails(vm));

        if (saveResult.IsSuccess) {
            // success
            let updatedVm = vm;
            const r = saveResult.Response!;
            if (r.statusMessages.isSuccess) {
                const detailsResponse: CallHelper.CallResult<Client.GetGeoscienceAustraliaReportDetailsResponseDto> = yield call(async () => await getReportDetails(vm));
                updatedVm = vm.refreshDetails(detailsResponse.Response!);
            }
            yield put(Actions.actionFactory.sagaCompleted(updatedVm.refreshUpdateResponse(saveResult.Response!)));
        } else if (saveResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            saveResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function updateReportDetails(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.UpdateGeoscienceAustraliaReportDetailsResponseDto>> {
    const request = new Client.UpdateGeoscienceAustraliaReportDetailsRequestDto({
        reportId: vm.reportId!,
        reportVersionNumber: vm.reportVersionNumber!,
        itemId: vm.reportDetails.itemId,
        reportTypeId: vm.reportDetails.reportType!.id,
        isCopiedToNopims: vm.reportDetails.isCopiedToNopims,
        comments: vm.reportDetails.reportTitle,
        writtenDate: vm.reportDetails.writtenDate,
        writtenDateOffset: GlobalHelpers.getDateOffsetMinutes(vm.reportDetails.writtenDate),
        receivedDate: vm.reportDetails.receivedDate,
        receivedDateOffset: GlobalHelpers.getDateOffsetMinutes(vm.reportDetails.receivedDate),
        isConfidential: vm.reportDetails.isConfidential!,
        confidentialityRemarks: vm.reportDetails.confidentialityRemarks,
        basicInterpType: vm.reportDetails.basicInterpType?.value,
        basicRelevantDate:
            vm.reportDetails.basicInterpType?.value === Client.BasicInterpTypeEnum.B || vm.reportDetails.basicInterpType?.value === Client.BasicInterpTypeEnum.Bi
                ? vm.reportDetails.basicRelevantDate
                : undefined,
        basicRelevantDateOffset:
            vm.reportDetails.basicInterpType?.value === Client.BasicInterpTypeEnum.B || vm.reportDetails.basicInterpType?.value === Client.BasicInterpTypeEnum.Bi
                ? GlobalHelpers.getDateOffsetMinutes(vm.reportDetails.basicRelevantDate)
                : undefined,
        interpRelevantDate:
            vm.reportDetails.basicInterpType?.value === Client.BasicInterpTypeEnum.I || vm.reportDetails.basicInterpType?.value === Client.BasicInterpTypeEnum.Bi
                ? vm.reportDetails.interpRelevantDate
                : undefined,
        interpRelevantDateOffset:
            vm.reportDetails.basicInterpType?.value === Client.BasicInterpTypeEnum.I || vm.reportDetails.basicInterpType?.value === Client.BasicInterpTypeEnum.Bi
                ? GlobalHelpers.getDateOffsetMinutes(vm.reportDetails.interpRelevantDate)
                : undefined,
        releasedDate: vm.reportDetails.releasedDate,
        releasedDateOffset: GlobalHelpers.getDateOffsetMinutes(vm.reportDetails.releasedDate),
        companyId: vm.reportDetails.company?.id,
        author: vm.reportDetails.author,
        contractorId: vm.reportDetails.contractor?.id,
    });

    const client = await ClientFactory.createGeoscienceAustraliaReportClient();
    return await CallHelper.call(() => client.updateGeoscienceAustraliaReportDetails(request));
}

async function getReportDetails(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.GetGeoscienceAustraliaReportDetailsResponseDto>> {
    const request = new Client.GetGeoscienceAustraliaReportDetailsRequestDto({
        reportId: vm.reportId!,
    });

    const client = await ClientFactory.createGeoscienceAustraliaReportClient();
    return await CallHelper.call(() => client.getGeoscienceAustraliaReportDetails(request));
}
