import { useState } from "react";
import { flushSync } from "react-dom";
import { Upload, UploadFileInfo } from "@progress/kendo-react-upload";

export function KendoSingleSmallFileUpload(props: {
    allowedExtensions?: string[] | undefined;
    minFileSizeMb?: number | undefined;
    maxFileSizeMb?: number | undefined;
    onFileValidlySelected: (fileName: string | undefined, content: string | undefined) => void;
}) {
    // The Upload component accepts files from users only, an initial 'system' state e.g. pre-selected file determined by a viewmodel is not a valid use case.
    const [files, setFiles] = useState(new Array<UploadFileInfo>());

    const convertMbToBytes = (mb: number) => mb * 1024 * 1024;
    // The Upload component does not allow empty files, convert file size restrictions to bytes and set a minimum value if not provided.
    const minFileSizeBytes = props.minFileSizeMb ? convertMbToBytes(props.minFileSizeMb) : 1;
    const maxFileSizeBytes = props.maxFileSizeMb ? convertMbToBytes(props.maxFileSizeMb) : undefined;

    // Fires when user clicks on the Remove button while the file upload is in progress. Can be used when the saveUrl option is set to a function that cancels custom requests.
    // For small files, conversion to a byte array is so quick it is impossible to Cancel through the UI and in any case is automatically followed by Remove so no implementation is required.
    // For large files we may need to consider aborting the upload process through the FileReader API.
    function onCancel(event: any) {}

    // The Upload component currently selected file can be cleared by a Remove request but also by Adding a new file, so it is necessary to clear the viewmodel onAdd and onRemove rather than onRemoveRequest.
    function onFileChange(event: any) {
        props.onFileValidlySelected(undefined, undefined);
        setFiles(event.newState);
    }

    // TODO fushSync workaround for batch update issue with Upload component, track the issue and remove as appropriate: https://feedback.telerik.com/kendo-react-ui/1583378-upload-event-newstate-batches-state-updates
    function onStatusChange(event: any) {
        flushSync(() => setFiles(event.newState));
    }

    function onSaveRequest(
        files: UploadFileInfo[],
        options: { formData: FormData; requestOptions: any },
        onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
    ): Promise<{ uid: string }> {
        const currentFile = files[0];
        const uid = currentFile.uid;

        return new Promise<{ uid: string }>((resolve, reject) => {
            // as currently configured it is impossible to request a save from the UI if there are validation errors, but that can be changed so keeping this check in place
            if (currentFile.validationErrors && currentFile.validationErrors.length > 0) {
                reject({ uid: uid });
            } else {
                const reader = new FileReader();
                // onload is executed when the load event is fired i.e. when content read with readAsArrayBuffer, readAsBinaryString, readAsDataURL or readAsText is available
                reader.onload = () => {
                    if (reader.result && typeof reader.result === "string") {
                        // stripping the data-url declaration as per https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
                        const base64Result = reader.result.split(",")[1];
                        // update viewModel and resolve
                        props.onFileValidlySelected(currentFile.name, base64Result);
                        resolve({ uid: uid });
                    } else {
                        reject({ uid: uid });
                    }
                };
                // onprogress is fired periodically as the FileReader reads data and the ProgressEvent can be passed directly to the Upload control, handy!
                reader.onprogress = (data) => {
                    onProgress(uid, data);
                };
                // if the read is not completed due to error or user intervention, reject
                reader.onabort = () => {
                    reject({ uid: uid });
                };
                reader.onerror = () => {
                    reject({ uid: uid });
                };

                reader.readAsDataURL(currentFile.getRawFile!());
            }
        });
    }

    function onRemoveRequest(files: UploadFileInfo[], options: { formData: FormData; requestOptions: any }): Promise<{ uid: string }> {
        const currentFile = files[0];
        const uid = currentFile.uid;

        return new Promise<{ uid: string }>((resolve) => {
            resolve({ uid: uid });
        });
    }

    return (
        <>
            <Upload
                className="mb-2"
                autoUpload={true}
                batch={false}
                files={files}
                multiple={false}
                onAdd={onFileChange}
                onCancel={onCancel}
                onProgress={onStatusChange}
                onRemove={onFileChange}
                onStatusChange={onStatusChange}
                withCredentials={false}
                removeUrl={onRemoveRequest}
                saveUrl={onSaveRequest}
                restrictions={{ allowedExtensions: props.allowedExtensions, minFileSize: minFileSizeBytes, maxFileSize: maxFileSizeBytes }}
            />
            <div className="d-flex flex-row flex-wrap small">
                {props.allowedExtensions && (
                    <div className="d-flex flex-row me-2 mb-2">
                        <span className="label fw-bold me-1">Supported File Types: </span>
                        <span>{props.allowedExtensions.join(", ")}.</span>
                    </div>
                )}
                {props.minFileSizeMb && (
                    <div className="d-flex flex-row me-2 mb-2">
                        <span className="label fw-bold me-1">Minimum File Size: </span>
                        <span>{props.minFileSizeMb} MB.</span>
                    </div>
                )}
                {props.maxFileSizeMb && (
                    <div className="d-flex flex-row me-2 mb-2">
                        <span className="label fw-bold me-1">Maximum File Size: </span>
                        <span>{props.maxFileSizeMb} MB.</span>
                    </div>
                )}
            </div>
        </>
    );
}
