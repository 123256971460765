// framework
import * as Validation from "../../../common/validation/ValidationModel";
// api
import { EmailVerificationStatusEnum } from "../../../api/Client";
// models
import * as Models from "./models";

export default function validate(vm: Models.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateNewUserRequest(vm.user.newUserRequest, v);
    validateEmailVerification(vm.user.newUserRequest.emailVerification, v);
    validateGeoscienceAustraliaStandardRoleRequest(vm.user.geoscienceAustraliaStandardRoleRequest, vm.user.questions, v);
    validateGaaAuthoritySupportingDocument(vm.user.gaaAuthoritySupportingDocument, vm.user.questions, v);
    validateUserAgreement(vm.user.isUserAgreementAcknowledged, v);
    validateComments(vm.user.comments, v);
    return v;
}

function validateGeoscienceAustraliaStandardRoleRequest(vm: Models.IGeoscienceAustraliaStandardRoleRequestViewModel, questions: Models.IQuestionsViewModel, v: Validation.ValidationModel): void {
    if (questions.isGeoscienceAustraliaAdministratorRequest) return;

    if (!vm.requestedRoles || !vm.requestedRoles.hasRole()) {
        v.addError("GeoscienceAustraliaStandardRoleRequest", "At least one permission must be requested.");
    }
}

function validateNewUserRequest(vm: Models.INewUserRequestViewModel, v: Validation.ValidationModel): void {
    if (Validation.isNullOrWhiteSpace(vm.email)) {
        v.addError("NewUserRequest.Email", "Email address is required.");
    } else if (!Validation.isValidEmail(vm.email!)) {
        v.addError("NewUserRequest.Email", "Email address is not in the correct format.");
    } else if (vm.email!.length > 100) {
        v.addError("NewUserRequest.Email", "Email address must be 100 characters or less.");
    } else if (!vm.email!.endsWith("@ga.gov.au")) {
        v.addError("NewUserRequest.Email", "A Geoscience Australia email address is required.");
    }

    if (Validation.isNullOrWhiteSpace(vm.firstName)) {
        v.addError("NewUserRequest.FirstName", "First Name is required.");
    } else if (vm.firstName!.length > 64) {
        v.addError("NewUserRequest.FirstName", "First Name must be 64 characters or less.");
    } else if (!Validation.isValidName(vm.firstName!)) {
        v.addError("NewUserRequest.FirstName", "First Name can only contain letters, spaces, hyphens and apostrophes and must contain at least one letter.");
    }

    if (Validation.isNullOrWhiteSpace(vm.lastName)) {
        v.addError("NewUserRequest.LastName", "Last Name is required.");
    } else if (vm.lastName!.length > 64) {
        v.addError("NewUserRequest.LastName", "Last Name must be 64 characters or less.");
    } else if (!Validation.isValidName(vm.lastName!)) {
        v.addError("NewUserRequest.LastName", "Last Name can only contain letters, spaces, hyphens and apostrophes and must contain at least one letter.");
    }

    if (vm.contactNumber && vm.contactNumber.length > 100) v.addError("NewUserRequest.ContactNumber", "Contact Number must be 100 characters or less.");
    if (vm.position && vm.position.length > 100) v.addError("NewUserRequest.Position", "Position must be 100 characters or less.");

    if (!vm.password) {
        v.addError("NewUserRequest.Password", "Password is required.");
    } else if (vm.password.length < 6 || vm.password.length > 64) {
        v.addError("NewUserRequest.Password", "Password length must be between 6 and 64 characters.");
    } else if (vm.password !== vm.password.trim()) {
        v.addError("NewUserRequest.Password", "Password must not begin or end with a whitespace character.");
    }

    if (!vm.passwordConfirmation) {
        v.addError("NewUserRequest.PasswordConfirmation", "Password is required.");
    } else if (vm.passwordConfirmation.length < 6 || vm.passwordConfirmation.length > 64) {
        v.addError("NewUserRequest.PasswordConfirmation", "Password length must be between 6 and 64 characters.");
    } else if (vm.passwordConfirmation !== vm.passwordConfirmation.trim()) {
        v.addError("NewUserRequest.PasswordConfirmation", "Password must not begin or end with a whitespace character.");
    } else if (vm.passwordConfirmation !== vm.password) {
        v.addError("NewUserRequest.PasswordConfirmation", "Passwords do not match.");
    }
}

function validateEmailVerification(vm: Models.IEmailVerificationViewModel, v: Validation.ValidationModel): void {
    switch (vm.status) {
        case EmailVerificationStatusEnum.FailedWithTooManyAttempts:
            v.addError("EmailVerification.Status", "Too many incorrect attempts.");
            break;
        case EmailVerificationStatusEnum.Unverified:
            v.addError("EmailVerification.Status", "Email address must be verified.");
            break;
        case EmailVerificationStatusEnum.InvalidToken:
            v.addError("EmailVerification.Status", "Verification code expired.");
            break;
    }

    if (!vm.requestEmailVerificationStatusMessages?.isSuccess && vm.status === EmailVerificationStatusEnum.Unverified)
        v.addError("EmailVerification.VerificationCode", "Incorrect verification code. Please try again.");
}

function validateSupportingDocument(validatorName: string, supportingDocument: Models.ISupportingDocumentViewModel, v: Validation.ValidationModel) {
    if (Validation.isNullOrWhiteSpace(supportingDocument.fileName) || !supportingDocument.content) {
        v.addError(validatorName, "Supporting Documentation must be supplied.");
        return;
    }

    if (Validation.isNullOrWhiteSpace(supportingDocument.fileName) || supportingDocument.fileName!.length === 0) {
        v.addError(validatorName, "Supporting Documentation file names cannot be blank.");
    }

    if (supportingDocument.fileName && supportingDocument.fileName.length > 100) {
        v.addError(validatorName, "Supporting Documentation file names must be 100 characters or less.");
    }
}

function validateGaaAuthoritySupportingDocument(gaaAuthoritySupportingDocument: Models.ISupportingDocumentViewModel, questions: Models.IQuestionsViewModel, v: Validation.ValidationModel): void {
    if (!questions.isGeoscienceAustraliaAdministratorRequest) return;

    validateSupportingDocument("GaaAuthoritySupportingDocument", gaaAuthoritySupportingDocument, v);
}

function validateUserAgreement(isUserAgreementAcknowledged: boolean, v: Validation.ValidationModel): void {
    if (!isUserAgreementAcknowledged) v.addError("UserAgreement", "To agree to the User Agreement you must first download and read the document.");
}

// TODO: this will probably need a common textarea maxlength solution
function validateComments(comments: string, v: Validation.ValidationModel): void {
    if (!comments) return;
    if (comments.length > 1000) v.addError("Comments.Comments", "Comments must be 1000 characters or less.");
}
