import { GetGeoscienceAustraliaReportAttachmentsItemDto } from "../../api/Client";
import * as Validation from "../../common/validation/ValidationModel";
import { SelectableItem } from "./ReportAttachmentsControl";
import * as documentHelper from "../../common/documentHelper";

export default function validate(attachments: SelectableItem<GetGeoscienceAustraliaReportAttachmentsItemDto>[], visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    const maxBatchDownloads = documentHelper.maxBatchDownloads();

    if (!attachments.some((item) => item.isSelected)) {
        v.addError("batchDownload.ItemSelected", "At least one Attachment must be selected.");
    }

    if (attachments.filter((item) => item.isSelected).length > maxBatchDownloads) {
        v.addError("batchDownload.ItemSelected", `Cannot select more than ${maxBatchDownloads} items.`);
    }

    return v;
}
