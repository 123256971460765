import { takeEvery } from "redux-saga/effects";
import * as Actions from "./actions";

import initialise from "./sagas/initialise";
import getCreditCardSurcharge from "./sagas/getCreditCardSurcharge";
import createPaymentRequested from "./sagas/createPaymentRequested";

export default function* watcherSaga() {
    yield takeEvery(Actions.actions.initialiseRequested, initialise);
    yield takeEvery(Actions.actions.getCreditCardSurcharge, getCreditCardSurcharge);
    yield takeEvery(Actions.actions.createPaymentRequested, createPaymentRequested);
}
