// redux
import * as Models from "../models/models";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "company.opggs.draftApplicationDetails.iapp.initialiseRequested",
    clearRequested = "company.opggs.draftApplicationDetails.iapp.clearRequested",
    saveApplicationDetailsRequested = "company.opggs.draftApplicationDetails.iapp.saveApplicationDetailsRequested",
    setApplicationAsReadyForSigningRequested = "company.opggs.draftApplicationDetails.iapp.setApplicationAsReadyForSigningRequested",
    revertApplicationToPreparingRequested = "company.opggs.draftApplicationDetails.iapp.revertApplicationToPreparingRequested",
    setApplicationAsPayingRequested = "company.opggs.draftApplicationDetails.iapp.setApplicationAsPayingRequested",
    setApplicationAsReadyForSubmissionRequested = "company.opggs.draftApplicationDetails.iapp.setApplicationAsReadyForSubmissionRequested",
    submitApplicationToNoptaRequested = "company.opggs.draftApplicationDetails.iapp.submitApplicationToNoptaRequested",
    deleteApplicationRequested = "company.opggs.draftApplicationDetails.iapp.deleteApplicationRequested",
    // user input
    viewChanged = "company.opggs.draftApplicationDetails.iapp.viewChanged",
    // saga events
    sagaCompleted = "company.opggs.draftApplicationDetails.iapp.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(id: number): IAction<number>;
    clearRequested(): IAction<undefined>;
    saveApplicationDetailsRequested(): IAction<undefined>;
    setApplicationAsReadyForSigningRequested(): IAction<undefined>;
    revertApplicationToPreparingRequested(): IAction<undefined>;
    setApplicationAsPayingRequested(): IAction<undefined>;
    setApplicationAsReadyForSubmissionRequested(): IAction<undefined>;
    submitApplicationToNoptaRequested(): IAction<undefined>;
    deleteApplicationRequested(): IAction<undefined>;
    // user input
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    // saga events
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (id: number): IAction<number> {
        return {
            type: actions.initialiseRequested,
            payload: id,
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    saveApplicationDetailsRequested: function (): IAction<undefined> {
        return {
            type: actions.saveApplicationDetailsRequested,
            payload: undefined,
        };
    },
    setApplicationAsReadyForSigningRequested: function (): IAction<undefined> {
        return {
            type: actions.setApplicationAsReadyForSigningRequested,
            payload: undefined,
        };
    },
    revertApplicationToPreparingRequested: function (): IAction<undefined> {
        return {
            type: actions.revertApplicationToPreparingRequested,
            payload: undefined,
        };
    },
    setApplicationAsPayingRequested: function (): IAction<undefined> {
        return {
            type: actions.setApplicationAsPayingRequested,
            payload: undefined,
        };
    },
    setApplicationAsReadyForSubmissionRequested: function (): IAction<undefined> {
        return {
            type: actions.setApplicationAsReadyForSubmissionRequested,
            payload: undefined,
        };
    },
    submitApplicationToNoptaRequested: function (): IAction<undefined> {
        return {
            type: actions.submitApplicationToNoptaRequested,
            payload: undefined,
        };
    },
    deleteApplicationRequested: function (): IAction<undefined> {
        return {
            type: actions.deleteApplicationRequested,
            payload: undefined,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
