// framework
import React from "react";
import { Link } from "react-router-dom";
// api
import * as Client from "../../api/Client";
// company
import * as companyRouteHelper from "../../company/companyRouteHelper";

interface IControllerProps {
    linkDto: Client.DraftApplicationLinkDto;
    secureEntityType: "Company";
}

// provides for the following capabilities:
// (1) Company links only (JA and GA will never navigate to a Draft Application)
// (2) Draft Applications links will route to the internal Draft Application Details page
// (3) Unauthorised links will render Tracking Number only

function Controller(props: IControllerProps): React.ReactElement {
    if (!props.linkDto) throw new Error("Draft Application Link cannot be undefined!");

    const link = props.linkDto;
    return link.isAuthorised ? <Link to={companyRouteHelper.draftApplicationDetails(link.id, link.type)}>{link.trackingNumber}</Link> : <>{link.trackingNumber}</>;
}

export default Controller;
