// framework
import * as Msal from "@azure/msal-browser";
import * as AnalyticsHelper from "./AnalyticsHelper";
// common
import * as LogHelper from "../common/LogHelper";

interface IMsalAuthService {
    /** Determines whether the account has been signed in **/
    isSignedIn(): boolean;

    /** Gets the currently logged in identity **/
    tryGetIdentity(): string | undefined;

    /** Is this the login redirect call? **/
    isLoginRedirect(): boolean;

    /** Quietly attempts a sign-in, in the scenario where the there is sufficient and valid sign-in information in the local session to attempt a successful sign **/
    trySilentSignIn(): Promise<boolean>;

    /** Performs an interactive sign-in **/
    signIn(): Promise<boolean>;

    /** Gets an access token for the signed-in user; note this may attempt an interactive sign-in **/
    getAccessToken(): Promise<string>;

    /** Performs a password reset, and signs in on success **/
    resetPassword(): Promise<boolean>;

    /** Performs an interactive sign-out **/
    signOut(): Promise<void>;
}

/*
    Azure B2C Configuration Requirements
    - don't forget to grant permissions to your own scope  (this will work from swagger without it, but not from the web app)
    - configure to ensure 'email address' is returned as a claim, this is required to support SSO
*/

class MsalAuthService implements IMsalAuthService {
    private readonly _instanceId = Math.round(Math.random() * 1000000);
    private readonly _showDebugMessages = false; // <-- turn this on temporarily to debug any issues, just remember to turn it off when you're done debugging!

    private readonly _signInAuthority: string;
    private readonly _passwordResetAuthority: string;

    private readonly _postLogoutRedirectUri: string;

    private readonly _environment: string;
    private readonly _scope: string;
    private readonly _publicClientApplication: Msal.PublicClientApplication;

    private _isInitialised: boolean = false;
    private _isSignedIn: boolean = false;

    private _onSignIn: ((uniqueId: string) => void) | undefined;
    private _onSignOut: (() => void) | undefined;

    constructor(
        clientId: string,
        knownAuthority: string,
        signInAuthority: string,
        passwordResetAuthority: string,
        redirectUri: string,
        postLogoutRedirectUri: string,
        scope: string,
        onSignIn?: (uniqueId: string) => void,
        onSignOut?: () => void
    ) {
        const configuration: Msal.Configuration = {
            auth: {
                clientId: clientId,
                knownAuthorities: [knownAuthority],
                redirectUri: redirectUri,
                postLogoutRedirectUri: postLogoutRedirectUri,
                navigateToLoginRequestUrl: true,
            },
            cache: {
                // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/FAQ.md#will-msal-2x-support-b2c
                // sessionStorage is more secure, but localStorage will allow for single-sign-on across tabs
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true, // setting this apparently improves compatibility
            },
        };

        this._signInAuthority = signInAuthority;
        this._passwordResetAuthority = passwordResetAuthority;
        this._postLogoutRedirectUri = postLogoutRedirectUri;
        this._environment = knownAuthority;
        this._publicClientApplication = new Msal.PublicClientApplication(configuration);
        this._scope = scope;

        this._onSignIn = onSignIn;
        this._onSignOut = onSignOut;
    }

    private async _tryInitialise(): Promise<void> {
        if (this._isInitialised) return;

        this._logTrace("_tryInitialise");
        await this._publicClientApplication.initialize();

        this._isInitialised = true;
    }

    private async _signIn(authority: string): Promise<void> {
        this._logTrace("_signIn", authority);

        const loginRequest: Msal.PopupRequest = {
            authority: authority,
            scopes: [this._scope],
        };

        const loginResponse = await this._publicClientApplication.loginPopup(loginRequest);

        // set the active account so subsequent 'acquire token async' calls know which account to use
        this._publicClientApplication.setActiveAccount(loginResponse!.account);
        this._onSignIn?.(loginResponse.uniqueId);
    }

    private async _ssoSilent(authority: string): Promise<boolean> {
        this._logTrace("_ssoSilent", authority);

        // as very little is stored in the session (and doesn't survive an F5), we need to fish out the right accounts
        // the tenant id check is probably a little overcooked but it is better to be safe than sorry (i'd rather they get re-prompted)
        // i can't think of how a user might be logged in multiple times, but i have put the defensive logic in place

        // this will fail if it's done on the non-active tab
        // for instance, if you right click a link and say 'open in a new tab' - it won't work.
        // the user will see the screen unauthenticated, and they have to press F5

        const accounts = this._publicClientApplication.getAllAccounts();
        const accountsInThisTenancy = accounts.filter((a) => a.environment === this._environment);

        if (accountsInThisTenancy.length === 0) {
            this._logTrace("_ssoSilent", "...no signed in accounts detected.");
            return false;
        }

        if (accountsInThisTenancy.length > 1) {
            this._logTrace("_ssoSilent", `...too many signed in users detected; names = ${accountsInThisTenancy.map((a) => `${a.username}`).join(", ")}.`);
            return false;
        }

        this._logTrace("_ssoSilent", `...found ${accountsInThisTenancy[0].username}`);

        const silentRequest: Msal.SsoSilentRequest = {
            authority: authority,
            loginHint: accountsInThisTenancy[0].username, // for the username to be populated, ensure the flow is configured to supply email addresses in the id token
            scopes: [this._scope],
        };

        try {
            // sign-in
            const ssoSilentResponse = await this._publicClientApplication.ssoSilent(silentRequest);

            // set the active account so subsequent 'acquire token async' calls know which account to use
            this._publicClientApplication.setActiveAccount(ssoSilentResponse!.account);
            this._onSignIn?.(ssoSilentResponse.uniqueId);
        } catch (error) {
            if (error instanceof Msal.InteractionRequiredAuthError) {
                this._logTrace("_ssoSilent", "...interaction required.");
                return false;
            }

            this._logError("_ssoSilent", error);
            throw error;
        }

        this._logTrace("_ssoSilent", "...signed in.");

        return true;
    }

    private async _acquireTokenInteractive(authority: string): Promise<string> {
        this._logTrace("_acquireTokenInteractive");

        const loginRequest: Msal.PopupRequest = {
            authority: authority,
            scopes: [this._scope],
        };

        const acquireResponse = await this._publicClientApplication.acquireTokenPopup(loginRequest);
        return acquireResponse!.accessToken;
    }

    private async _acquireTokenSilent(authority: string): Promise<string> {
        this._logTrace("_acquireTokenSilent");

        const loginRequest: Msal.SilentRequest = {
            authority: authority,
            scopes: [this._scope],
        };

        const acquireResponse = await this._publicClientApplication.acquireTokenSilent(loginRequest);
        return acquireResponse!.accessToken;
    }

    private async _signOut(authority: string): Promise<void> {
        this._logTrace("_signOut");

        // use the active account - this is important as it will otherwise ask you to sign out of a bunch of other accounts

        const activeAccount = this._publicClientApplication.getActiveAccount();

        if (!activeAccount) {
            this._logTrace("_signOut", "...no active account, aborting!");
            this._onSignOut?.();
            return;
        }

        const logoutRequest: Msal.EndSessionPopupRequest = {
            authority: authority,
            mainWindowRedirectUri: this._postLogoutRedirectUri,
            account: activeAccount ?? undefined,
        };

        await this._publicClientApplication.logoutPopup(logoutRequest);
        this._onSignOut?.();
    }

    public isSignedIn(): boolean {
        this._logTrace("isSignedIn", this._isSignedIn ? "true" : "false");

        return this._isSignedIn;
    }

    public tryGetIdentity(): string | undefined {
        let account = this._publicClientApplication.getActiveAccount();
        return account?.localAccountId;
    }

    public isLoginRedirect(): boolean {
        // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/3126
        const path = window.location.pathname;
        const result = path === postLoginRedirectPath;
        this._logTrace("isLoginRedirect", result ? "true" : "false");
        return result;
    }

    public async trySilentSignIn(): Promise<boolean> {
        this._logTrace("trySilentSignIn");

        await this._tryInitialise();

        const isSignedIn = await this._ssoSilent(this._signInAuthority);

        this._isSignedIn = isSignedIn;
        return isSignedIn;
    }

    public async signIn(): Promise<boolean> {
        this._logTrace("signIn");
        if (this._isSignedIn) throw new Error("Already signed in!");

        await this._tryInitialise();

        try {
            await this._signIn(this._signInAuthority);
        } catch (error: any) {
            if (error instanceof Msal.BrowserAuthError) {
                // this occurs when the user cancels sign-in
                this._logTrace("signIn", "...sign-in cancelled.");
                return false;
            }

            if (error.errorMessage && error.errorMessage.indexOf("AADB2C90118") > -1) {
                // this occurs if the user requested to reset password
                // (see note below)
                return this.resetPassword();
            }

            // give up - something bad happened
            this._logError("signIn", error);
            throw error;
        }

        // set as signed in
        this._logTrace("signIn", "...signed in.");
        this._isSignedIn = true;
        return true;
    }

    public async getAccessToken(): Promise<string> {
        this._logTrace("getAccessToken");
        if (!this._isSignedIn) throw new Error("Not signed in!");
        if (!this._isInitialised) throw new Error("Not initialised!");

        try {
            const accessToken = await this._acquireTokenSilent(this._signInAuthority);
            this._logTrace("getAccessToken", `...access token length = ${accessToken.length}`);
            return accessToken;
        } catch (e1) {
            this._logError("getAccessToken", e1); // i'd like to know why it failed and needed to go interactive!

            if (e1 instanceof Msal.InteractionRequiredAuthError || e1 instanceof Msal.BrowserAuthError) {
                try {
                    const accessToken = await this._acquireTokenInteractive(this._signInAuthority);
                    this._logTrace("getAccessToken", `...access token length = ${accessToken.length}`);
                    return accessToken;
                } catch (e2) {
                    this._logError("getAccessToken", e2); // i'm curious to know what happened now, but in a lot of cases it could be related to the user aborting the flow
                    // don't throw e2 - we're more interested in why e1 failed
                }
            }

            // if we get an error here, it's pretty bad
            // we've already determined the user has signed in, and the system is attempting to make a service call!
            throw e1;
        }
    }

    public async resetPassword(): Promise<boolean> {
        this._logTrace("resetPassword");

        await this._tryInitialise();

        try {
            await this._signIn(this._passwordResetAuthority);
        } catch (error) {
            if (error instanceof Msal.BrowserAuthError) {
                // this occurs when the user cancels password reset
                this._logTrace("resetPassword", "...password reset cancelled.");
                return false;
            }

            // give up - something bad happened
            this._logError("resetPassword", error);
            throw error;
        }

        // set as reset & signed in
        this._logTrace("resetPassword", "...password reset and signed in.");
        this._isSignedIn = true;
        return true;
    }

    public async signOut(): Promise<void> {
        this._logTrace("signOut");
        if (!this._isSignedIn) throw new Error("Not signed in!");
        if (!this._isInitialised) throw new Error("Not initialised!");

        try {
            await this._signOut(this._signInAuthority);
        } catch (error) {
            this._logError("signOut", error);
            throw error;
        }

        // set as signed-out only after we're sure we got signed out
        this._isSignedIn = false;
    }

    private _logTrace(method: string, message: string = ""): void {
        if (!this._showDebugMessages) return;
        console.debug(`msalAuthService/${this._instanceId}.${method}(${message})`);
        //LogHelper.logTrace(`msalAuthService/${this._instanceId}.${method}(${message})`);
    }

    private _logError(method: string, error: any): void {
        LogHelper.logError(`msalAuthService/${this._instanceId}.${method}`);
        LogHelper.logError(error);
    }
}

// singleton instance
declare global {
    interface Window {
        // this is the singleton instance of the adapter
        msalAuthService: IMsalAuthService | undefined;
    }
}

// this must be configured in the Application in Azure AD
const postLoginRedirectPath = "/auth-redirect";

export function getMsalAuthService(): IMsalAuthService {
    if (!window.msalAuthService) {
        const clientId = process.env.REACT_APP_AZUREB2C_CLIENT_ID as string;
        const scope = process.env.REACT_APP_AZUREB2C_SCOPE as string;
        const redirectUri = window.location.protocol + "//" + window.location.host + postLoginRedirectPath;
        const postLogoutRedirectUri = window.location.protocol + "//" + window.location.host;
        const knownAuthority = process.env.REACT_APP_AZUREB2C_KNOWN_AUTHORITY as string;
        const signInAuthority = process.env.REACT_APP_AZUREB2C_POLICY_SIGNIN as string;
        const passwordResetAuthority = process.env.REACT_APP_AZUREB2C_POLICY_PASSWORD_RESET as string;

        const msal = new MsalAuthService(
            clientId,
            knownAuthority,
            signInAuthority,
            passwordResetAuthority,
            redirectUri,
            postLogoutRedirectUri,
            scope,
            AnalyticsHelper.setUserId,
            AnalyticsHelper.clearUserId
        );
        window.msalAuthService = msal;
    }
    return window.msalAuthService;
}

export default getMsalAuthService();
