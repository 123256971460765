// framework
import { useEffect, useState } from "react";
// kendo
import { MultiSelect, MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { orderBy } from "@progress/kendo-data-query";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
// api
import * as Client from "../../../../../../api/Client";
// common
import { ValidationModel } from "../../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../../common/validation/ValidationMessageView";
import * as Forms from "../../../../../../common/forms/BootstrapForms";

export default function TitlesListView(props: { vm: Models.IRootViewModel; v: ValidationModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const formRequestDetails = vm.formRequestDetails;
    const v = props.v;
    const a = props.actions;

    const [titleData, setTitleData] = useState(new Array<Client.TitleInfoDto>());
    const [selectedTitles, setSelectedTitles] = useState(new Array<Client.TitleInfoDto>());

    // update title reference data if changed
    useEffect(() => {
        setTitleData(vm.reference.titles);
    }, [vm.reference.titles]);

    // update selected titles if changed
    useEffect(() => {
        const titles = formRequestDetails.titles ?? new Array<Client.TitleInfoDto>();
        setSelectedTitles(orderBy(titles, [{ field: "titleNumber", dir: "asc" }]));
        setAllSelected(titleData.every((t) => titles.some((et) => et.id === t.id)));
    }, [formRequestDetails.titles, titleData]);

    function onChange(e: MultiSelectChangeEvent) {
        setSelectedTitles(e.value);
        a.viewChanged(vm.refreshSelectedTitlesChanged(e.value));
    }

    // select all state
    const [allSelected, setAllSelected] = useState(false);

    function onSelectAllClicked(checked: boolean) {
        setAllSelected(checked);

        if (checked) {
            setSelectedTitles(titleData);
            a.viewChanged(vm.refreshSelectedTitlesChanged(titleData));
        } else {
            setSelectedTitles(new Array<Client.TitleInfoDto>());
            a.viewChanged(vm.refreshSelectedTitlesChanged(new Array<Client.TitleInfoDto>()));
        }
    }

    return (
        <Forms.Group>
            <span className="me-2">
                <Forms.Label htmlFor="titlesField">Titles</Forms.Label>
            </span>
            {titleData && titleData.length > 0 && <Forms.Checkbox id="isAllSelectedField" label="Select All" checked={allSelected} onChange={onSelectAllClicked} inline />}
            <MultiSelect
                id="titlesField"
                valid={v.applyValidityForKendo("FormRequestDetails.Titles")}
                data={titleData}
                value={selectedTitles}
                dataItemKey="id"
                textField="titleNumber"
                onChange={onChange}
            />
            <ValidationMessageView name="FormRequestDetails.Titles" validationModel={v} />
        </Forms.Group>
    );
}
