// redux
import { call, put, select } from "redux-saga/effects";
import { IGeoscienceAustraliaReduxState } from "../../../../geoscienceAustraliaReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/rootViewModel";
import { getRequestList } from "./initialise";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_UserManagement_RequestList;

export default function* batchApprove() {
    try {
        yield put(shellHelper.createSetBusyAction());
        const requestVm: Models.IRootViewModel = yield select(getRootViewModel);
        const result: CallHelper.CallResult<Client.BatchApproveGeoscienceAustraliaUserManagementRequestListResponseDto> = yield call(async () => await batchApproveRequestList(requestVm));

        let vm: Models.IRootViewModel = yield select(getRootViewModel);

        if (result.IsSuccess) {
            // success
            const r = result.Response!;
            if (r.statusMessages.isSuccess) {
                const requestList: Client.GetGeoscienceAustraliaUserManagementRequestListResponseDto = yield call(getRequestList);
                vm = vm.refreshRequestDetails(requestList);
            }
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshBatchProcessResponse(result.Response!.statusMessages)));
        } else if (result.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            result.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function batchApproveRequestList(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.BatchApproveGeoscienceAustraliaUserManagementRequestListResponseDto>> {
    const requestGroups = vm.geoscienceAustraliaAdministrator.requests
        .filter((r) => r.isSelected)
        .map(
            (r) =>
                new Client.SecureRequestGroupKeyDto({
                    id: r.getKey(),
                    versionNumber: r.requestVersionNumber,
                })
        );
    const request = new Client.BatchApproveGeoscienceAustraliaUserManagementRequestListRequestDto({ secureRequestGroups: requestGroups });

    const client = await ClientFactory.createGeoscienceAustraliaUserManagementClient();
    return await CallHelper.call(() => client.batchApproveGeoscienceAustraliaUserManagementRequestList(request));
}
