// framework
import React from "react";
import { Link } from "react-router-dom";
// api
import * as Client from "../../api/Client";
// company
import * as companyRouteHelper from "../../company/companyRouteHelper";
import * as jointAuthorityRouteHelper from "../../jointAuthority/jointAuthorityRouteHelper";

interface IControllerProps {
    linkDto: Client.ApplicationLinkDto;
    secureEntityType: string;
}

// provides for the following capabilities:
// (1) Authorised links will route to the internal In-Progress Application Details page
//     - may route differently depending on state, we don't yet know if In Progress Apps and Completed Apps will land on the same component
// (2) Unauthorised links will render Tracking Number only
// (3) No OEI applications are currently supported as we have no details screen for them. Block them here

function Controller(props: IControllerProps): React.ReactElement {
    if (!props.linkDto) throw new Error("Application Link cannot be undefined!");

    const link = props.linkDto;
    if (props.linkDto.administrationType === Client.AdministrationTypeEnum.Opggs) {
        if (props.secureEntityType === "Company") {
            return link.isAuthorised ? <Link to={companyRouteHelper.applicationDetails(link.id)}>{link.trackingNumber}</Link> : <>{link.trackingNumber}</>;
        } else if (props.secureEntityType === "JointAuthority") {
            return link.isAuthorised ? <Link to={jointAuthorityRouteHelper.applicationDetails(link.id)}>{link.trackingNumber}</Link> : <>{link.trackingNumber}</>;
        }
    }
    return <>{link.trackingNumber}</>;
}

export default Controller;
