// framework
import React from "react";
// redux
import * as Models from "../../models/rootViewModel";
// other
import * as BadgeHelper from "../../../../../common/badges/BadgeHelper";
import * as Forms from "../../../../../common/forms/BootstrapForms";
import EmailLinkView from "../../../../../common/externalLink/EmailLinkView";

export default function UserDetailsView(props: { vm: Models.IDetailsViewModel }): React.ReactElement {
    const vm = props.vm;

    // view
    return (
        <div className="card my-2">
            <div className="card-body">
                <h2 className="card-title">User Details</h2>
                <Forms.Row>
                    <Forms.HalfColumn>
                        <Forms.Label htmlFor="usernameField">Username {vm.isSecureUserBlocked && BadgeHelper.getBlockedUserBadge()}</Forms.Label>
                        <Forms.ReadonlyTextInput id="usernameField" value={vm.username} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="accountStatusField" label="Account Status" value={vm.accountStatus} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="fullNameField" label="Full Name" value={vm.fullName} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.Element label="Email">
                            <EmailLinkView emailAddress={vm.emailAddress} />
                        </Forms.Element>
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="positionField" label="Position" value={vm.position} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="contactNumberField" label="Contact Number" value={vm.contactNumber} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="lastLoginField" label="Last Login (AWST)" value={vm.lastLoginDatetime} valid={!vm.hasInactivityWarning} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="registrationDateField" label="Registration Date (AWST)" value={vm.registrationDatetime} />
                    </Forms.HalfColumn>
                </Forms.Row>
            </div>
        </div>
    );
}
