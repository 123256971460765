// framework
import React from "react";
import { Link } from "react-router-dom";
import * as Client from "../../api/Client";
// company
import * as companyRouteHelper from "../../company/companyRouteHelper";
// joint Authority
import * as jointAuthorityRouteHelper from "../../jointAuthority/jointAuthorityRouteHelper";
// common
import ExternalLinkView from "../externalLink/ExternalLinkView";
import * as shellRouteHelper from "../../shell/shellRouteHelper";
import { LicenceDetailsTabsEnum } from "../oei/licenceDetails/views/LicenceDetailsView";

interface ILicenceLinkControllerProps {
    linkDto: Client.LicenceLinkDto;
    secureEntityType: string;
    tab?: LicenceDetailsTabsEnum;
}

// provides for the following capabilities:
// (1) Company links only  (JA/GA unknown)
// (2) Authorised links will route to:
//     - Company
//       - my licences will route to the internal licence Details page
//       - not my licences will not route ( to go to public portal when implemented )

function licenceLinkController(props: ILicenceLinkControllerProps): React.ReactElement {
    if (!props.linkDto) throw new Error("Licence Link cannot be undefined!");
    const link = props.linkDto;
    const secureEntityType = props.secureEntityType;
    const tab = props.tab;

    const licenceId = link.id;

    const licenceNumber = link.licenceNumber;
    const isAuthorised = link.isAuthorised;

    function renderlicenceLink(licenceId: string, licenceNumber: string, isAuthorised: boolean, secureEntityType: string): React.ReactElement {
        if (isAuthorised) {
            if (secureEntityType === "Company") {
                return <Link to={companyRouteHelper.licenceDetails(licenceId, tab)}>{licenceNumber}</Link>;
            }
            if (secureEntityType === "JointAuthority") {
                return <Link to={jointAuthorityRouteHelper.licenceDetails(licenceId, tab)}>{licenceNumber}</Link>;
            }
        }
        return <ExternalLinkView href={shellRouteHelper.publicPortalLicenceDetails(licenceNumber)}>{licenceNumber}</ExternalLinkView>;
    }

    return renderlicenceLink(licenceId, licenceNumber, isAuthorised, secureEntityType);
}

export default licenceLinkController;
