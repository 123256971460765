// api
import { GetCompanyOpggsTitleDetailsLegislativeFormAddressDto } from "../../../../../../api/Client";

export default function AddressDetailsView(props: { address: GetCompanyOpggsTitleDetailsLegislativeFormAddressDto }): React.ReactElement {
    const address = props.address;

    return (
        <div className="col-md-6 mt-2">
            <span className="fw-bold">
                Address Details
                <br />
            </span>
            <span>
                {address.streetAddress}, {address.suburb}
                <br />
            </span>
            <span>
                {address.countrySubdivision} {address.postcode} {address.country}
                <br />
            </span>
        </div>
    );
}
