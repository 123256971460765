// redux
import { call, put } from "redux-saga/effects";
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { getMyUserDetails } from "./initialise";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

export default function* saveMyDetails(action: Actions.IAction<Models.IRootViewModel>) {
    let vm: Models.IRootViewModel = action.payload;

    try {
        yield put(shellHelper.createSetBusyAction());

        const result: CallHelper.CallResult<Client.SaveCompanyAccountMyDetailsResponseDto> = yield call(async () => {
            const client = await ClientFactory.createCompanyAccountClient();
            const request = new Client.SaveCompanyAccountMyDetailsRequestDto({
                versionNumber: vm.secureUser.versionNumber,
                correspondenceEmailAddress: vm.editableSecureUser.correspondenceEmailAddress,
                position: vm.editableSecureUser.position,
                contactNumber: vm.editableSecureUser.contactNumber,
            });
            return await CallHelper.call(() => client.saveCompanyAccountMyDetails(request));
        });

        if (result.IsSuccess) {
            // success
            if (result.Response!.statusMessages!.isSuccess) {
                const userResponse: Client.GetCompanyAccountMyDetailsResponseDto = yield call(getMyUserDetails);
                vm = vm.refreshDetails(userResponse);
            }
            vm = vm.setStatusMessages(result.Response!.statusMessages);
            yield put(Actions.actionFactory.sagaCompleted(vm));
        } else if (result.IsConflict) {
            // conflict
            vm = vm.refreshConflict();
            yield put(Actions.actionFactory.sagaCompleted(vm));
        } else {
            // all other errors
            result.ShowToastNotification();
            vm = vm.setStatusMessages(undefined);
            yield put(Actions.actionFactory.sagaCompleted(vm));
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
