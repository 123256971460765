// redux
import { call, put, select } from "redux-saga/effects";
import { IGeoscienceAustraliaReduxState } from "../../../geoscienceAustraliaReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../api/clientFactory";
import * as CallHelper from "../../../../api/callHelper";
import * as Client from "../../../../api/Client";
// shell
import * as ShellActions from "../../../../shell/layout/redux/actions";
// common
import * as LogHelper from "../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_Home_Registration;

export default function* verifyEmailVerificationCode() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const actions = Actions.actionFactory;

    try {
        yield put(ShellActions.actionFactory.setBusy());

        const response: Client.VerifyRegistrationEmailVerificationCodeResponseDto = yield call(async () => {
            const client = ClientFactory.createRegistrationAnonymousRequestService();
            return await CallHelper.simpleCall(() =>
                client.verifyRegistrationEmailVerificationCode(
                    new Client.VerifyRegistrationEmailVerificationCodeRequestDto({
                        token: vm.user.newUserRequest.emailVerification.token!,
                        verificationCode: vm.user.newUserRequest.emailVerification.verificationCode!,
                    })
                )
            );
        });

        const { token, verificationCode } = vm.user.newUserRequest.emailVerification;

        if (response.status === Client.EmailVerificationStatusEnum.Verified) {
            yield put(
                actions.sagaCompleted(vm.refreshEmailVerificationUpdated({ token: token, isVisible: false, status: response.status, verifyEmailVerificationStatusMessages: response.statusMessages }))
            );
        } else if (response.status === Client.EmailVerificationStatusEnum.Unverified) {
            // leave the dialog visible if a solid Unverified comes back, give them another chance
            yield put(
                actions.sagaCompleted(
                    vm.refreshEmailVerificationUpdated({
                        token: token,
                        verificationCode: verificationCode,
                        isVisible: true,
                        status: response.status,
                        verifyEmailVerificationStatusMessages: response.statusMessages,
                    })
                )
            );
        } else {
            // otherwise they've had a more drastic issue which needs a full retry
            yield put(actions.sagaCompleted(vm.refreshEmailVerificationUpdated({ isVisible: false, status: response.status, verifyEmailVerificationStatusMessages: response.statusMessages })));
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(ShellActions.actionFactory.clearBusy());
    }
}
