// framework
import { useState } from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// redux
import * as Models from "../../models/models";
// other
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import TitleLinkController from "../../../../../common/links/TitleLinkController";
import { createGridColumnFilterMenu } from "../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
import CustomGridCell from "../../../../../common/kendoGrid/CustomGridCell";

export default function TitlesTileView(props: { vm: Models.titlesType }): React.ReactElement {
    if (!props.vm) throw new Error("Titles must not be undefined.");
    const titles = props.vm;

    const initialGridState: State = {
        sort: [{ field: "currentExpiryDate", dir: "asc" }],
        skip: 0,
        take: 10,
    };
    const [gridState, setGridState] = useState(initialGridState);

    return (
        <div className="card mb-2">
            <div className="card-header">Titles Expiring in the next 6 Months</div>
            <div className="card-body">
                <Grid
                    pageable
                    sortable
                    resizable
                    navigatable
                    data={process(titles, gridState)}
                    {...gridState}
                    onDataStateChange={(e) => {
                        setGridState(e.dataState);
                    }}
                >
                    <GridColumn
                        field="titleLink.titleNumber"
                        title="Title Number"
                        width={150}
                        filter="text"
                        columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, titles)}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                <TitleLinkController linkDto={props.dataItem.titleLink} secureEntityType="Company" />
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn field="titleTypeDisplay" title="Title Type" width={250} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, titles)} />
                    <GridColumn
                        field="currentExpiryDate"
                        title="Current Expiry Date"
                        width={200}
                        filter="date"
                        format={GlobalHelpers.NoptaDateFormatForKendo}
                        columnMenu={createGridColumnFilterMenu}
                    />
                    <GridColumn field="anniversaryDate" title="Anniversary Date" width={160} format={GlobalHelpers.NoptaDateFormatForKendo} filter="date" columnMenu={createGridColumnFilterMenu} />
                    <GridColumn field="titleHoldersDisplay" title="Titleholders" filter="text" width={380} columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, titles)} />
                    <GridColumn field="titleStatus" title="Title Status" width={220} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, titles)} />
                </Grid>
            </div>
        </div>
    );
}
