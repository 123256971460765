// framework
import * as Validation from "../../../../../common/validation/ValidationModel";
// models
import * as Models from "./models";

export default function validate(vm: Models.IIaflApplicationDetailsViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateIaflDetails(vm.iaflDetails, v);
    return v;
}

function validateIaflDetails(vm: Models.IIaflDetailsViewModel, v: Validation.ValidationModel): void {
    if (!vm.declaredAreaId) v.addError("IaflDetails.DeclaredArea", "Declared Area is required.");

    if (!vm.projectNameOrReference) v.addError("IaflDetails.ProjectNameOrReference", "Project Name of Reference is required.");
    else if (vm.projectNameOrReference.length > 100) v.addError("IaflDetails.ProjectNameOrReference", "Project Name of Reference must be 100 characters or less.");

    if (vm.proposedLicenceArea === undefined) v.addError("IaflDetails.ProposedLicenceArea", "Proposed Licence Area is required.");
    else if (vm.proposedLicenceArea <= 0) v.addError("IaflDetails.ProposedLicenceArea", "Proposed Licence Area must be greater than zero.");

    if (vm.proposedGenerationCapacity === undefined) v.addError("IaflDetails.ProposedGenerationCapacity", "Proposed generation capacity within Licence Area is required.");
    else if (vm.proposedGenerationCapacity <= 0) v.addError("IaflDetails.ProposedGenerationCapacity", "Proposed generation capacity within Licence Area must be greater than zero.");

    if (!vm.renewableEnergyResourceTypeId) v.addError("IaflDetails.RenewableEnergyResource", "Renewable energy resource is required.");

    if (!vm.technologyTypeId) v.addError("IaflDetails.TechnologyToBeInvestigated", "Technology to be investigated is required.");
}
