// framework
import { useState } from "react";
// redux
import * as Common from "../../../../../common/draftApplicationDetails/components/models/models";
import * as Models from "../../models/models";
import validator from "../../models/applicationDetailsValidator";
// api
import { DraftApplicationValidationStateEnum } from "../../../../../../api/Client";
// views
import EottlrDetailsView from "./EottlrDetailsView";
import EottlrDetailsReadonlyView from "./EottlrDetailsReadonlyView";
// common
import * as ViewHelpers from "../../../../../common/draftApplicationDetails/ViewHelpers";
import { ValidationVisibilityEnum } from "../../../../../../common/validation/ValidationModel";
import DirtyScope from "../../../../../../common/shell/DirtyScopeController";
import * as toastHelper from "../../../../../../common/toastHelper";
import SimpleAuditEventsView from "../../../../../../common/audit/SimpleAuditEventsView";

export default function ApplicationDetailsView(props: {
    vm: Models.IEottlrApplicationDetailsViewModel;
    canManage: boolean;
    validationState: DraftApplicationValidationStateEnum;
    viewState: Common.ApplicationViewStateEnum;
    inEditTask: Common.ApplicationTaskEnum | undefined;
    updateEottlrDetails: (vm: Models.IEottlrDetailsViewModel) => void;
    onEdit: () => void;
    onSave: () => void;
    onCancel: () => void;
}) {
    const vm = props.vm;

    // validation
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const validation = validator(vm, validationVisibility);

    // save
    function onSaveClicked(e: any) {
        e.preventDefault();

        // validation
        // - validation is hidden until the user attempts the action... it then stays visible until they have successfully completed the action
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);

        // submit
        props.onSave();
    }

    function onCancelClicked(e: any) {
        e.preventDefault();
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        props.onCancel();
    }

    return (
        <div className="card mb-2">
            <div className="card-body">
                <h4 className="card-title">Extension of Time to Lodge Renewal Application Details</h4>
                {props.inEditTask === Common.ApplicationTaskEnum.ApplicationDetails ? (
                    <DirtyScope scopeName="company/opggs/draftApplicationDetails/eottlr/applicationDetails" isDirty={vm.isDirty}>
                        <form onSubmit={onSaveClicked}>
                            <EottlrDetailsView vm={vm.eottlrDetails} v={validation} updateViewModel={props.updateEottlrDetails} />
                            <div className="d-flex flex-wrap mt-2">
                                <SimpleAuditEventsView className="mb-1" simpleAuditEvents={vm.audit} />
                                <div className="ms-auto">
                                    <button className="btn btn-outline-secondary me-2" type="button" onClick={onCancelClicked}>
                                        Cancel
                                    </button>
                                    <button className="btn btn-outline-primary" type="submit">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </DirtyScope>
                ) : (
                    <>
                        <EottlrDetailsReadonlyView vm={vm.eottlrDetails} />
                        <div className="d-flex flex-wrap mt-2">
                            <SimpleAuditEventsView className="mb-1" simpleAuditEvents={vm.audit} />
                            {ViewHelpers.showCommands(props.validationState, props.canManage, props.viewState) && (
                                <div className="ms-auto">
                                    <button className="btn btn-outline-primary float-end" type="button" disabled={ViewHelpers.isInEdit(props.inEditTask)} onClick={props.onEdit}>
                                        Edit
                                    </button>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
