import * as Models from "../models/models";

// -------
// Actions
// -------

export enum actions {
    // lifecycle events
    clearRequested = "company.userManagement.companyList.clearRequested",

    // saga
    sagaGetContentRequested = "company.userManagement.companyList.sagaGetContentRequested",
    sagaCompleted = "company.userManagement.companyList.sagaCompleted",
}

// --------
// Payloads
// --------

export interface IAction<T> {
    type: actions;
    payload: T;
}

// ---------
// Factories
// ---------

export interface IActionFactory {
    clearRequested(): IAction<undefined>;
    sagaGetContentRequested(): IAction<undefined>;
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    sagaGetContentRequested: function (): IAction<undefined> {
        return {
            type: actions.sagaGetContentRequested,
            payload: undefined,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
