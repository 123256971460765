// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    borehole: Client.GetGeoscienceAustraliaWellsBoreholesBoreholeDetailsResponseDto | undefined;

    refreshBoreholeDetails(response: Client.GetGeoscienceAustraliaWellsBoreholesBoreholeDetailsResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.borehole = undefined;
    }

    public borehole: Client.GetGeoscienceAustraliaWellsBoreholesBoreholeDetailsResponseDto | undefined;

    public refreshBoreholeDetails(response: Client.GetGeoscienceAustraliaWellsBoreholesBoreholeDetailsResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.borehole = response;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.borehole = clone(this.borehole);
        return vm;
    }
}
