// framework
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { Dialog } from "@progress/kendo-react-dialogs";
// api
import { GetGeoscienceAustraliaReprocessingsProjectsReprocessingAcquisitionsResponseAcquisitionDto } from "../../../../../../api/Client";
// common
import BootstrapFormsStyles from "../../../../../../common/forms/BootstrapForms.module.css";
import { ControlClass } from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";

export default function AcquisitionsListGridView(props: {
    reprocessingId: number;
    reprocessingAcquisitions: GetGeoscienceAustraliaReprocessingsProjectsReprocessingAcquisitionsResponseAcquisitionDto[];
}): JSX.Element {
    const { reprocessingId, reprocessingAcquisitions } = props;

    const [dialogComments, setDialogComments] = useState("");
    const [showCommentsDialog, setShowCommentsDialog] = useState(false);

    const initialState: State = {
        sort: [
            { field: "projectIdentifier", dir: "desc" },
            { field: "surveyIdentifier", dir: "asc" },
            { field: "acquisitionIdentifier", dir: "asc" },
        ],
    };

    const [gridState, setGridState] = useState(initialState);
    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [reprocessingAcquisitions]); // eslint-disable-line react-hooks/exhaustive-deps

    function onShowCommentsDialog(comments: string) {
        setDialogComments(comments);
        setShowCommentsDialog(true);
    }
    function onCloseCommentsDialog() {
        setShowCommentsDialog(false);
    }

    return (
        <>
            <Grid
                resizable
                sortable
                data={process(reprocessingAcquisitions, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn
                    field="projectIdentifier"
                    title="Project ID"
                    width={200}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <Link to={geoscienceAustraliaRouteHelper.reprocessingProjectDetails(reprocessingId, props.dataItem.projectId)} title="Click to view Project details.">
                                {props.dataItem.projectIdentifier}
                            </Link>
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    field="surveyIdentifier"
                    title="Survey ID"
                    width={200}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <Link to={geoscienceAustraliaRouteHelper.surveyDetails(props.dataItem.surveyId)} title="Click to view Survey details.">
                                {props.dataItem.surveyIdentifier}
                            </Link>
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    field="acquisitionIdentifier"
                    title="Acquisition ID"
                    width={200}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <Link to={geoscienceAustraliaRouteHelper.acquisitionDetails(props.dataItem.surveyId, props.dataItem.acquisitionId)} title="Click to view Acquisition details.">
                                {props.dataItem.acquisitionIdentifier}
                            </Link>
                        </CustomGridCell>
                    )}
                />
                <GridColumn field="acquisitionName" title="Acquisition Name" width={500} />
                <GridColumn field="acquisitionEndDate" title="Acquisition End Date" width={200} format={GlobalHelpers.NoptaDateFormatForKendo} />
                <GridColumn field="basicRelevantDate" title="Basic Relevant Date" width={200} format={GlobalHelpers.NoptaDateFormatForKendo} />
                <GridColumn field="interpretativeRelevantDate" title="Interpretative Relevant Date" width={240} format={GlobalHelpers.NoptaDateFormatForKendo} />
                <GridColumn field="exclusivity" title="Exclusivity" width={200} />
                <GridColumn
                    field="comments"
                    title="Comments"
                    sortable={false}
                    width={100}
                    cell={(props: GridCellProps) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.comments && (
                                <button className="btn btn-secondary" type="button" title="Click to view Comments." onClick={() => onShowCommentsDialog(props.dataItem.comments)}>
                                    View
                                </button>
                            )}
                        </CustomGridCell>
                    )}
                />
            </Grid>
            {showCommentsDialog && (
                <Dialog title={"Comments"} width={600} onClose={onCloseCommentsDialog}>
                    <div>
                        <span className={`${ControlClass} ${BootstrapFormsStyles.readonlyTextArea}`}>{dialogComments}</span>
                    </div>
                </Dialog>
            )}
        </>
    );
}
