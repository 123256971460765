// models
import * as Models from "../../../../common/draftApplicationDetails/components/models/models";
// api
import * as Client from "../../../../../api/Client";

export interface IIappEpDetailsViewModel {
    locations: Array<Client.GetCompanyOpggsDraftApplicationDetailsIappEpDetailsLocationDto>;
    locationId: number | undefined;
    dateLocationDeclared?: Date | undefined;
    isLongerApplicationPeriodAllowed?: boolean | undefined;
}

export interface IIappRlDetailsViewModel {
    fieldName: string | undefined;
    initialGrantDate?: Date | undefined;
    dateOfRevocationOrRefusalOfRenewalOfRetentionLeaseDate?: Date | null | undefined;
}

export interface IIappViewModel {
    epDetails?: IIappEpDetailsViewModel | undefined;
    rlDetails?: IIappRlDetailsViewModel | undefined;
}

export interface IIappDetailsViewModel extends IIappViewModel {
    titleId?: string | undefined;
    titleNumber?: string | undefined;
    titleExpiryDate?: Date | undefined;
    originatingTitleType?: Client.TitleTypeEnum | undefined;
}

export interface IIappApplicationDetailsViewModel extends Models.IApplicationDetailsViewModel {
    iappDetails: IIappDetailsViewModel;
}

export interface IRootViewModel extends Models.IDraftApplicationRootViewModel {
    applicationDetails: IIappApplicationDetailsViewModel;

    // view triggered state changes
    refreshIappUpdated(values: IIappViewModel): IRootViewModel;
}

export class RootViewModel extends Models.BaseDraftApplicationRootViewModel implements IRootViewModel {
    constructor() {
        super();
        this.applicationDetails = {
            isDigitalSignatures: false,
            iappDetails: {
                epDetails: {
                    locations: [],
                    locationId: undefined,
                    dateLocationDeclared: undefined,
                    isLongerApplicationPeriodAllowed: undefined,
                },
                rlDetails: {
                    fieldName: undefined,
                    initialGrantDate: undefined,
                    dateOfRevocationOrRefusalOfRenewalOfRetentionLeaseDate: undefined,
                },
            },
            isDirty: false,
        };
    }

    public override applicationDetails: IIappApplicationDetailsViewModel;

    public override refreshDetails(response: Client.GetCompanyOpggsDraftApplicationDetailsIappResponseDto): this {
        const vm = super.refreshDetails(response);

        vm.applicationDetails.iappDetails.titleId = response.titleId;
        vm.applicationDetails.iappDetails.titleNumber = response.titleNumber;
        vm.applicationDetails.iappDetails.titleExpiryDate = response.titleExpiryDate;
        vm.applicationDetails.iappDetails.originatingTitleType = response.originatingTitleType;

        switch (vm.applicationDetails.iappDetails.originatingTitleType) {
            case Client.TitleTypeEnum.ExplorationPermit:
                var locationId = response.epDetails!.locationId;
                vm.applicationDetails.iappDetails.epDetails!.locations = response.epDetails!.locations!;
                vm.applicationDetails.iappDetails.epDetails!.locationId = locationId;
                vm.applicationDetails.iappDetails.epDetails!.dateLocationDeclared = response.epDetails!.locations?.find((l) => l.id === locationId)?.dateLocationDeclared;
                vm.applicationDetails.iappDetails.epDetails!.isLongerApplicationPeriodAllowed = response.epDetails!.locations?.find((l) => l.id === locationId)?.isLongerApplicationPeriodAllowed;
                break;
            case Client.TitleTypeEnum.RetentionLease:
                vm.applicationDetails.iappDetails.rlDetails!.fieldName = response.rlDetails!.fieldName;
                vm.applicationDetails.iappDetails.rlDetails!.initialGrantDate = response.rlDetails!.initialGrantDate;
                vm.applicationDetails.iappDetails.rlDetails!.dateOfRevocationOrRefusalOfRenewalOfRetentionLeaseDate = response.rlDetails!.dateOfRevocationOrRefusalOfRenewalOfRetentionLeaseDate;
                break;
            default:
                throw new Error("Invalid Title Type.");
        }

        vm.applicationDetails.isDirty = false;

        return vm;
    }

    public refreshIappUpdated(values: IIappViewModel): IRootViewModel {
        const vm = this._clone();

        vm.applicationDetails.iappDetails = { ...vm.applicationDetails.iappDetails, ...values };
        vm.applicationDetails.isDirty = true;

        return vm;
    }
}
