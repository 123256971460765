import * as Models from "../models/rootViewModel";

// -------
// Actions
// -------

export enum actions {
    // lifecycle events
    initialise = "geoscienceAustralia.userManagement.geoscienceAustraliaDetails.initialise",
    clearRequested = "geoscienceAustralia.userManagement.geoscienceAustraliaDetails.clearRequested",
    savePermissionsRequested = "geoscienceAustralia.userManagement.geoscienceAustraliaDetails.savePermissionsRequested",
    // user input
    viewChanged = "geoscienceAustralia.userManagement.geoscienceAustraliaDetails.viewChanged",
    // saga
    sagaCompleted = "geoscienceAustralia.userManagement.geoscienceAustraliaDetails.sagaCompleted",
}

// --------
// Payloads
// --------

export interface IAction<T> {
    type: actions;
    payload: T;
}

// ---------
// Factories
// ---------

export interface IActionFactory {
    savePermissionsRequested(): IAction<undefined>;
    clearRequested(): IAction<undefined>;
    initialise(): IAction<undefined>;
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    savePermissionsRequested: function (): IAction<undefined> {
        return {
            type: actions.savePermissionsRequested,
            payload: undefined,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    initialise: function (): IAction<undefined> {
        return {
            type: actions.initialise,
            payload: undefined,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
