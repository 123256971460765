// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import ConflictView from "../../../../common/alerts/ConflictAlertView";
import StatusMessagesAlertsView from "../../../../common/alerts/StatusMessagesAlertsView";
import DirtyScope from "../../../../common/shell/DirtyScopeController";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/rootViewModel";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
// views
import CompanyDetailsView from "./views/CompanyDetailsView";
import CompanyPermissionsView from "./views/CompanyPermissionsView";
import CompanyPermissionsEditView from "./views/CompanyPermissionsEditView";

interface ICompanyDetailsControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}
interface ICompanyDetailsRouteParameters {
    id: string;
}

function CompanyDetailsController(props: ICompanyDetailsControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;
    const params = useParams<ICompanyDetailsRouteParameters>();
    const companyId = parseInt(params.id);

    // on mount
    useEffect(() => {
        props.initialiseRequested(companyId);
        return function () {
            props.clearRequested();
        };
    }, [companyId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BodyLayout
            title="Manage Company Access"
            selectedLhsNavItem={Level1ItemTypeEnum.Company_UserManagement_ViewCompanyList}
            onReloadRequested={() => {
                props.initialiseRequested(companyId);
            }}
        >
            <ConflictView isConflict={vm.isConflict} />
            <StatusMessagesAlertsView statusMessages={vm.service.statusMessages} />
            <CompanyDetailsView vm={vm.details} />
            {vm.viewState === Models.ViewStateEnum.View && <CompanyPermissionsView vm={vm} actions={actions} />}
            {vm.viewState === Models.ViewStateEnum.Edit && (
                <DirtyScope scopeName="company/userManagement/companyDetails" isDirty={vm.isDirty}>
                    <CompanyPermissionsEditView vm={vm} actions={actions} />
                </DirtyScope>
            )}
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_UserManagement_CompanyDetails }), Actions.actionFactory)(CompanyDetailsController);
