// redux
import * as Models from "../../models/models";
// common
import SimpleAuditEventsView from "../../../../../../../common/audit/SimpleAuditEventsView";
import * as Forms from "../../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../../common/GlobalHelpers";

export default function ApplicantDetailsReadonlyView(props: { vm: Models.IRootViewModel }): React.ReactElement {
    const vm = props.vm;

    const isApplicantEligibleUnderSection8 = GlobalHelpers.convertBooleanToStringValue(vm.applicantDetails.isApplicantEligibleUnderSection8);

    return (
        <>
            <Forms.Group>
                <Forms.ReadonlyField label="Company Name" id="companyNameField" value={vm.applicantDetails.companyName} />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="ACN/ARBN" id="acnOrArbnField" value={vm.applicantDetails.acnOrArbn} />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="foreignInvestmentApprovalTypeField">Foreign investment approval required?</Forms.Label>
                <p>
                    If applicable, an applicant is responsible for obtaining foreign investment approval. Confirmation of Foreign Investment approval should be provided with submission of an
                    applicable feasibility licence application. If it is not available at the time of submission, it may be provided separately once received. Applicant should be aware that the
                    Minister will not make a decision on the application or offer award of licence whilst a foreign investment decision is pending.
                </p>
                <Forms.Group>
                    <Forms.ReadonlyTextInput id="foreignInvestmentApprovalTypeField" value={vm.applicantDetails.foreignInvestmentApprovalType?.description} />
                </Forms.Group>
            </Forms.Group>
            <Forms.Group>
                <Forms.Group>
                    <Forms.ReadonlyField
                        label="Is the applicant an eligible person under section 8 of the OEI Act?"
                        id="isApplicantEligibleUnderSection8Field"
                        value={isApplicantEligibleUnderSection8 || ""}
                    />
                </Forms.Group>
            </Forms.Group>
            <SimpleAuditEventsView className="mt-4" simpleAuditEvents={vm.audit} />
        </>
    );
}
