export interface IFinancialNotificationDueDateDetails {
    isAwaitingConfirmation: boolean;
    isOverdue: boolean;
    isDueWithinSevenDays: boolean;
}

export function getDueDateStyle(props: IFinancialNotificationDueDateDetails) {
    let style = {};
    if (props.isAwaitingConfirmation) {
        style = { backgroundColor: "orange" };
    } else if (props.isOverdue) {
        style = { backgroundColor: "crimson", color: "white" };
    } else if (props.isDueWithinSevenDays) {
        style = { backgroundColor: "yellow" };
    }
    return style;
}
