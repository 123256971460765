// framework
import { useEffect, useState } from "react";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
// common
import { ValidationModel } from "../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../common/validation/ValidationMessageView";
import { KendoSingleSmallFileUpload } from "../../../../common/fileUpload/KendoSingleSmallFileUpload";
import ExternalLink from "../../../../common/externalLink/ExternalLinkView";
import * as Forms from "../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../common/GlobalHelpers";

export function CompanySignerRequestView(props: { vm: Models.IRootViewModel; v: ValidationModel; actions: Actions.IActionFactory; requestFormLink: string | undefined }) {
    const vm = props.vm;
    const validation = props.v;
    const actions = props.actions;
    const requestFormLink = props.requestFormLink;

    function onCsAuthoritySupportingDocumentChange(fileName: string | undefined, content?: string | undefined) {
        actions.viewChanged(vm.refreshCsAuthoritySupportingDocumentUpdated({ fileName, content }));
    }

    function onCsPoaAuthoritySupportingDocumentChange(fileName: string | undefined, content?: string | undefined) {
        actions.viewChanged(vm.refreshCsPoaSupportingDocumentUpdated({ fileName, content }));
    }

    return (
        <div className="card mb-2">
            <h2 className="card-header">Company Signer Supporting Documentation</h2>
            <div className="card-body">
                <Forms.Group>
                    <p>A Company Signer request form signed by Company Directors is required in order to apply for Signer access.</p>
                    <ExternalLink href={requestFormLink}>Company Signer Request Form</ExternalLink>
                </Forms.Group>
                <h4 className="card-subtitle">Upload Company Signer Request Form</h4>
                <Forms.Group>
                    <p>A copy of the signed Company Signer form must be supplied</p>
                    <KendoSingleSmallFileUpload
                        onFileValidlySelected={onCsAuthoritySupportingDocumentChange}
                        allowedExtensions={[".pdf", ".tiff", ".png", ".jpg", ".jpeg", ".docx"]}
                        maxFileSizeMb={20}
                    />
                    <ValidationMessageView name="CsAuthoritySupportingDocument" validationModel={validation} />
                </Forms.Group>
                <h4 className="card-subtitle">Power of Attorney</h4>
                <Forms.Group>
                    <p>
                        For a registered Australian company, will you be signing as: a person holding a legal power of attorney executed by the company in accordance with section 127 of the
                        Corporations Act
                    </p>
                    <CompanySignerPoaRadioButtonView vm={vm} actions={actions} validationModel={validation} />
                </Forms.Group>
                {vm.user.questions.isCompanySignerPoaRequest && (
                    <>
                        <h4 className="card-subtitle">Upload Power of Attorney document</h4>
                        <Forms.Group>
                            <p>A copy of the POA must be supplied.</p>
                            <KendoSingleSmallFileUpload
                                onFileValidlySelected={onCsPoaAuthoritySupportingDocumentChange}
                                allowedExtensions={[".pdf", ".tiff", ".png", ".jpg", ".jpeg", ".docx"]}
                                maxFileSizeMb={20}
                            />
                            <ValidationMessageView name="CsPoaSupportingDocument" validationModel={validation} />
                        </Forms.Group>
                    </>
                )}
            </div>
        </div>
    );
}

function CompanySignerPoaRadioButtonView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; validationModel: ValidationModel }) {
    const vm = props.vm;
    const questions = vm.user.questions;
    const actions = props.actions;
    const validation = props.validationModel;

    const isCsPoaRequestRadioValue = "isCsPoaRequest";
    const isNotCsPoaRequestRadioValue = "isNotCsPoaRequest";
    const [selectedCsPoaRequestRadioValue, setSelectedCsPoaRequestRadioValue] = useState(
        GlobalHelpers.convertBooleanToRadioValue(questions.isCompanySignerPoaRequest, isCsPoaRequestRadioValue, isNotCsPoaRequestRadioValue)
    );

    // update selection if changed
    useEffect(() => {
        setSelectedCsPoaRequestRadioValue(GlobalHelpers.convertBooleanToRadioValue(questions.isCompanySignerPoaRequest, isCsPoaRequestRadioValue, isNotCsPoaRequestRadioValue));
    }, [questions.isCompanySignerPoaRequest]);

    // on change
    function onChange(value: string) {
        setSelectedCsPoaRequestRadioValue(value);
        if (value === isCsPoaRequestRadioValue) actions.viewChanged(vm.refreshQuestionsUpdated({ isCompanySignerPoaRequest: true }));
        if (value === isNotCsPoaRequestRadioValue) actions.viewChanged(vm.refreshQuestionsUpdated({ isCompanySignerPoaRequest: false }));
    }

    return (
        <>
            <Forms.RadioButton
                name="isCsPoaRequestGroup"
                id="isCsPoaRequestRadioField"
                label="Yes"
                value={isCsPoaRequestRadioValue}
                checked={selectedCsPoaRequestRadioValue === isCsPoaRequestRadioValue}
                valid={validation.applyValidity("CompanySignerRequest.isCsPoaRequestGroup")}
                onChange={onChange}
                inline
            />
            <Forms.RadioButton
                name="isCsPoaRequestGroup"
                id="isNotCsPoaRequestRadioField"
                label="No"
                value={isNotCsPoaRequestRadioValue}
                checked={selectedCsPoaRequestRadioValue === isNotCsPoaRequestRadioValue}
                valid={validation.applyValidity("CompanySignerRequest.isCsPoaRequestGroup")}
                onChange={onChange}
                inline
            />
            <ValidationMessageView name="CompanySignerRequest.isCsPoaRequestGroup" validationModel={validation} />
        </>
    );
}
