// models
import { ApplicationTaskEnum, ApplicationViewStateEnum } from "./components/models/models";
// api
import { DraftApplicationValidationStateEnum } from "../../../api/Client";

export function showCommands(
    validationState: DraftApplicationValidationStateEnum,
    canManage: boolean,
    viewState: ApplicationViewStateEnum,
    editableViewState: ApplicationViewStateEnum = ApplicationViewStateEnum.Preparing
): boolean {
    return (validationState === DraftApplicationValidationStateEnum.Valid || validationState === DraftApplicationValidationStateEnum.Invalid) && canManage && viewState === editableViewState;
}

export function isInEdit(inEditTask?: ApplicationTaskEnum | undefined): boolean {
    return inEditTask !== undefined;
}

export function showApplicationFormSection(validationState: DraftApplicationValidationStateEnum, viewState: ApplicationViewStateEnum): boolean {
    if (validationState === DraftApplicationValidationStateEnum.RecoverableError || validationState === DraftApplicationValidationStateEnum.UnrecoverableError) return false;

    return (
        viewState === ApplicationViewStateEnum.ForSigning ||
        viewState === ApplicationViewStateEnum.Paying ||
        viewState === ApplicationViewStateEnum.ForSubmission ||
        viewState === ApplicationViewStateEnum.Submitted
    );
}

export function canReload(viewState: ApplicationViewStateEnum | undefined): boolean {
    if (viewState === undefined) return false;
    return activeViewStates.includes(viewState);
}

const activeViewStates: readonly ApplicationViewStateEnum[] = [
    ApplicationViewStateEnum.Created,
    ApplicationViewStateEnum.Preparing,
    ApplicationViewStateEnum.ForSigning,
    ApplicationViewStateEnum.Paying,
    ApplicationViewStateEnum.ForSubmission,
];
