// framework
import { useEffect, useState } from "react";
// redux
import { IRegisteredTitleholderDetailsViewModel } from "./registeredTitleholderDetailsViewModel";
// common
import { ValidationModel } from "../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../common/validation/ValidationMessageView";
import MaskedTextBoxView from "../../../../common/maskedTextbox/MaskedTextboxView";
import * as Forms from "../../../../common/forms/BootstrapForms";

export default function RegisteredTitleholderDetailsView(props: {
    vm: IRegisteredTitleholderDetailsViewModel;
    v: ValidationModel;
    formTypeId: number | undefined;
    isAcnOrArbnRequired: boolean | undefined;
    updateViewModel: (vm: IRegisteredTitleholderDetailsViewModel) => void;
}) {
    const vm = props.vm;
    const v = props.v;

    const [state, setState] = useState<IRegisteredTitleholderDetailsViewModel>({
        acnOrArbn: "",
        becomingARegisteredTitleholderDate: "",
        phone: "",
        fax: "",
        email: "",
    });

    useEffect(() => {
        setState({
            acnOrArbn: vm.acnOrArbn ?? "",
            becomingARegisteredTitleholderDate: vm.becomingARegisteredTitleholderDate ?? "",
            phone: vm.phone ?? "",
            fax: vm.fax ?? "",
            email: vm.email ?? "",
        });
    }, [vm]);

    // generic input change
    function onChanged(values: any) {
        setState({ ...state, ...values });
    }

    function onBlur() {
        props.updateViewModel({ ...state });
    }

    return (
        <Forms.Row>
            <Forms.HalfColumn>
                <Forms.Label htmlFor="acnOrArbnField">ACN/ARBN</Forms.Label>
                {props.isAcnOrArbnRequired ? (
                    <MaskedTextBoxView
                        id="acnOrArbnField"
                        mask="000 000 000"
                        value={state.acnOrArbn}
                        valid={v.applyValidityForKendo("RegisteredTitleholderDetails.AcnOrArbn")}
                        onChange={(value) => onChanged({ acnOrArbn: value })}
                        onBlur={onBlur}
                    />
                ) : (
                    <Forms.ReadonlyTextInput id="acnOrArbnField" value={state.acnOrArbn} />
                )}
                <ValidationMessageView name="RegisteredTitleholderDetails.AcnOrArbn" validationModel={v} />
            </Forms.HalfColumn>
            <Forms.HalfColumn>
                {props.formTypeId === 2 && (
                    <>
                        <Forms.ReadonlyField
                            id="becomingARegisteredTitleholderDateField"
                            label="Date of becoming a Registered Titleholder"
                            value={state.becomingARegisteredTitleholderDate}
                            valid={v.applyValidity("RegisteredTitleholderDetails.BecomingARegisteredTitleholderDate")}
                        />
                        <ValidationMessageView name="RegisteredTitleholderDetails.BecomingARegisteredTitleholderDate" validationModel={v} />
                    </>
                )}
            </Forms.HalfColumn>
            <Forms.HalfColumn>
                <Forms.TextInput
                    id="phoneField"
                    label="Registered Titleholder's Phone"
                    value={state.phone}
                    valid={v.applyValidity("RegisteredTitleholderDetails.Phone")}
                    onChange={(value) => onChanged({ phone: value })}
                    onBlur={onBlur}
                />
                <ValidationMessageView name="RegisteredTitleholderDetails.Phone" validationModel={v} />
            </Forms.HalfColumn>
            <Forms.HalfColumn>
                <Forms.TextInput
                    id="faxField"
                    label="Registered Titleholder's Fax"
                    value={state.fax}
                    valid={v.applyValidity("RegisteredTitleholderDetails.Fax")}
                    onChange={(value) => onChanged({ fax: value })}
                    onBlur={onBlur}
                />
                <ValidationMessageView name="RegisteredTitleholderDetails.Fax" validationModel={v} />
            </Forms.HalfColumn>
            <Forms.HalfColumn>
                <Forms.TextInput
                    id="emailField"
                    label="Registered Titleholder's Email"
                    value={state.email}
                    valid={v.applyValidity("RegisteredTitleholderDetails.Email")}
                    onChange={(value) => onChanged({ email: value })}
                    onBlur={onBlur}
                />
                <ValidationMessageView name="RegisteredTitleholderDetails.Email" validationModel={v} />
            </Forms.HalfColumn>
        </Forms.Row>
    );
}
