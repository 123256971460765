// api
import * as Client from "../../../../api/Client";
// other
import { BaseRoleViewModel, IRoleViewModel } from "../../../../common/roleGrid/RoleViewModel";

export interface IGeoscienceAustraliaAdministratorRequestViewModel extends IRoleViewModel {
    requestLink: Client.SecureRequestGroupLinkDto;
    requestVersionNumber: number;
    requestDatetime: Date;
    secureUserId?: number | undefined;
    secureUserDisplayName?: string | undefined;
    isSecureUserBlocked: boolean;
    isSecureUserRevokedButVisible: boolean;
}

export class GeoscienceAustraliaAdministratorRequestViewModel extends BaseRoleViewModel implements IGeoscienceAustraliaAdministratorRequestViewModel {
    constructor(
        roleRelationships: Array<Client.GetShellSecureUserRoleConfigurationRoleRelationshipDto>,
        data: Client.IGeoscienceAustraliaUserManagementGeoscienceAustraliaRequestListAdministratorGeoscienceAustraliaRequestListItemDto
    ) {
        super(roleRelationships, data.rolesRequested);

        this.requestLink = data.secureRequestGroupLink;
        this.requestVersionNumber = data.requestVersionNumber;
        this.requestDatetime = data.requestDatetime;
        this.secureUserId = data.secureUserId;
        this.secureUserDisplayName = data.secureUserDisplayName;
        this.isSecureUserBlocked = data.isSecureUserBlocked;
        this.isSecureUserRevokedButVisible = data.isSecureUserRevokedButVisible;
    }

    public readonly requestLink: Client.SecureRequestGroupLinkDto;
    public readonly requestVersionNumber: number;
    public readonly requestDatetime: Date;
    public readonly secureUserId?: number | undefined;
    public readonly secureUserDisplayName?: string | undefined;
    public readonly isSecureUserBlocked: boolean;
    public readonly isSecureUserRevokedButVisible: boolean;

    public getKey(): number {
        return this.requestLink.id;
    }

    public canEdit(): boolean {
        return false;
    }
}
