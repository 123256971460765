// framework
import { call, put, select } from "redux-saga/effects";
// redux
import { ICompanyReduxState } from "../../../../companyReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/rootViewModel";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";
import * as ConfigurationHelper from "../../../../../common/shell/configurationHelper";

interface IResponse {
    summary: Client.CompanyUserManagementRequestDetailsSummaryDto;
    newUserDetails?: Client.CompanyUserManagementRequestDetailsNewUserDetailsDto;
    existingUserDetails?: Client.CompanyUserManagementRequestDetailsExistingUserDetailsDto;
    companyStandardRoleDetails?: Client.CompanyUserManagementRequestDetailsStandardRoleDetailsDto;
    companySignerDetails?: Client.CompanyUserManagementRequestDetailsSignerDetailsDto;
}

const getRootViewModel = (state: ICompanyReduxState) => state.Company_UserManagement_RequestDetails;

export default function* initialise(action: Actions.IAction<Actions.IRequestDetailsKeyPayload>) {
    const requestDetailsKey = action.payload;

    // save role reference data to the vm
    let vm: Models.IRootViewModel = yield select(getRootViewModel);
    vm = vm.refreshRoleConfiguration(yield select(ConfigurationHelper.getRoleConfiguration));
    yield put(Actions.actionFactory.sagaCompleted(vm));

    // load request details
    try {
        yield put(shellHelper.createSetBusyAction());

        const response: IResponse = yield call(getRequestDetails, requestDetailsKey.id, requestDetailsKey.type);
        yield put(
            Actions.actionFactory.sagaCompleted(
                vm.refreshRequestDetails(response.summary, response.newUserDetails, response.existingUserDetails, response.companyStandardRoleDetails, response.companySignerDetails)
            )
        );
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

export async function getRequestDetails(secureRequestGroupId: number, secureRequestGroupType: Client.SecureRequestGroupTypeEnum): Promise<IResponse> {
    const client = await ClientFactory.createCompanyUserManagementClient();

    switch (secureRequestGroupType) {
        case Client.SecureRequestGroupTypeEnum.CR:
            const cRequest = new Client.GetCompanyUserManagementRequestDetailsCrRequestDto({ secureRequestGroupId: secureRequestGroupId });
            return await CallHelper.simpleCall(() => client.getCompanyUserManagementRequestDetailsCr(cRequest));
        case Client.SecureRequestGroupTypeEnum.NU_CR:
            const nuCrRequest = new Client.GetCompanyUserManagementRequestDetailsNuCrRequestDto({ secureRequestGroupId: secureRequestGroupId });
            return await CallHelper.simpleCall(() => client.getCompanyUserManagementRequestDetailsNuCr(nuCrRequest));
        case Client.SecureRequestGroupTypeEnum.CS:
            const csRequest = new Client.GetCompanyUserManagementRequestDetailsCsRequestDto({ secureRequestGroupId: secureRequestGroupId });
            return await CallHelper.simpleCall(() => client.getCompanyUserManagementRequestDetailsCs(csRequest));
        case Client.SecureRequestGroupTypeEnum.CR_CS:
            const crCsRequest = new Client.GetCompanyUserManagementRequestDetailsCrCsRequestDto({ secureRequestGroupId: secureRequestGroupId });
            return await CallHelper.simpleCall(() => client.getCompanyUserManagementRequestDetailsCrCs(crCsRequest));
        case Client.SecureRequestGroupTypeEnum.NU_CR_CS:
            const nuCrCsRequest = new Client.GetCompanyUserManagementRequestDetailsNuCrCsRequestDto({ secureRequestGroupId: secureRequestGroupId });
            return await CallHelper.simpleCall(() => client.getCompanyUserManagementRequestDetailsNuCrCs(nuCrCsRequest));
        default:
            throw new Error("Unsupported Request Type.");
    }
}
