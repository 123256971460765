// framework
import React, { useEffect, useState } from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
// models
import { IBatchUpdateViewModel, IRootViewModel } from "../../models/models";
// kendo
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TextArea } from "@progress/kendo-react-inputs";
// common
import * as Client from "../../../../../../api/Client";
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import ValidationMessageView from "../../../../../../common/validation/ValidationMessageView";
import { IValidationModel } from "../../../../../../common/validation/ValidationModel";
import YesNoDropDownList from "../../../../../../common/dropdowns/YesNoDropDownListView";
import KendoComboBox from "../../../../../../common/kendo/KendoComboBox";

type Props = {
    vm: IRootViewModel;
    updateVm: (vm: IRootViewModel) => void;
    v: IValidationModel;
};

export default function SurveyDataBatchUpdateView(props: Props): JSX.Element {
    const { vm, updateVm, v } = props;

    const [state, setState] = useState<IBatchUpdateViewModel>(vm.batchUpdateDetails);

    const confidentialityRemarksUpdateTypes = new Array<Client.SurveyAcquisitionStringUpdateTypeEnum>(
        Client.SurveyAcquisitionStringUpdateTypeEnum.Append,
        Client.SurveyAcquisitionStringUpdateTypeEnum.Prepend,
        Client.SurveyAcquisitionStringUpdateTypeEnum.Overwrite
    );

    const consignmentUpdateTypes = new Array<Client.SurveyAcquisitionStringUpdateTypeEnum>(
        Client.SurveyAcquisitionStringUpdateTypeEnum.Append,
        Client.SurveyAcquisitionStringUpdateTypeEnum.Prepend,
        Client.SurveyAcquisitionStringUpdateTypeEnum.Overwrite
    );

    function updateStateAndViewModel(state: IBatchUpdateViewModel) {
        setState(state);
        updateVm(vm.onBatchDetailsUpdate(state));
    }

    useEffect(() => {
        setState(vm.batchUpdateDetails);
    }, [vm.batchUpdateDetails]);

    return (
        <div className="my-4">
            <h4 className="card-title">Batch Update</h4>
            <div className="card-body my-2">
                <div className="my-3">
                    <Forms.Row>
                        <div className="col-md-auto">
                            <Forms.Checkbox
                                id="updateConfidentialityField"
                                label=""
                                checked={state.updateConfidentiality}
                                onChange={(value) => updateStateAndViewModel({ ...state, updateConfidentiality: value, confidentiality: undefined })}
                            />
                        </div>
                        <Forms.QuarterColumn>
                            {!state.updateConfidentiality ? (
                                <Forms.ReadonlyField id="confidentialityField" label="Confidentiality" value={GlobalHelpers.convertBooleanToConfidentialityValue(state.confidentiality)} />
                            ) : (
                                <>
                                    <Forms.Label htmlFor="isConfidentialField">Confidentiality</Forms.Label>
                                    <YesNoDropDownList
                                        id="isConfidentialField"
                                        valid={v.applyValidityForKendo("batchUpdate.IsConfidential")}
                                        value={state.confidentiality}
                                        trueValue="Confidential"
                                        falseValue="Not Confidential"
                                        onChange={(value) => updateStateAndViewModel({ ...state, confidentiality: value })}
                                    />
                                    <ValidationMessageView name="batchUpdate.IsConfidential" validationModel={v} />
                                </>
                            )}
                        </Forms.QuarterColumn>
                    </Forms.Row>
                </div>

                <div className="my-3">
                    <Forms.Row>
                        <div className="col-md-auto">
                            <Forms.Checkbox
                                id="UpdateConfidentialityRemarksField"
                                label=""
                                checked={state.updateConfidentialityRemarks}
                                onChange={(value) =>
                                    updateStateAndViewModel({
                                        ...state,
                                        updateConfidentialityRemarks: value,
                                        confidentialityRemarksUpdateType: Client.SurveyAcquisitionStringUpdateTypeEnum.Append,
                                        confidentialityRemarks: undefined,
                                    })
                                }
                            />
                        </div>
                        <Forms.QuarterColumn>
                            {!state.updateConfidentialityRemarks ? (
                                <Forms.ReadonlyTextArea id="confidentialityRemarksField" label="Confidentiality Remarks" />
                            ) : (
                                <>
                                    <Forms.Label htmlFor="confidentialityRemarksField">Confidentiality Remarks</Forms.Label>
                                    <DropDownList
                                        id={"confidentialityRemarksField"}
                                        className="mb-2"
                                        data={confidentialityRemarksUpdateTypes}
                                        value={state.confidentialityRemarksUpdateType}
                                        onChange={(e) => updateStateAndViewModel({ ...state, confidentialityRemarksUpdateType: e.target.value })}
                                    />
                                    <div style={{ resize: "none" }}>
                                        <TextArea
                                            id="confidentialityRemarksField"
                                            value={state.confidentialityRemarks}
                                            valid={v.applyValidityForKendo("batchUpdate.ConfidentialityRemarks")}
                                            maxLength={4000}
                                            rows={3}
                                            onChange={(e) => updateStateAndViewModel({ ...state, confidentialityRemarks: e.target.value })}
                                        />
                                        <ValidationMessageView name="batchUpdate.ConfidentialityRemarks" validationModel={v} />
                                    </div>
                                </>
                            )}
                        </Forms.QuarterColumn>
                    </Forms.Row>
                </div>

                <div className="my-3">
                    <Forms.Row>
                        <div className="col-md-auto">
                            <Forms.Checkbox
                                id="updateReceivedField"
                                label=""
                                checked={state.updateReceivedDate}
                                onChange={(value) => updateStateAndViewModel({ ...state, updateReceivedDate: value, receivedDate: undefined })}
                            />
                        </div>
                        <Forms.QuarterColumn>
                            {!state.updateReceivedDate ? (
                                <Forms.ReadonlyField label="Received Date" id="receivedDateField" value={GlobalHelpers.toNoptaDateString(state.receivedDate)} />
                            ) : (
                                <>
                                    <Forms.Label htmlFor="receivedDateField">Received Date</Forms.Label>
                                    <DatePicker
                                        id="receivedDateField"
                                        value={state.receivedDate}
                                        valid={v.applyValidityForKendo("batchUpdate.ReceivedDate")}
                                        onChange={(event) => updateStateAndViewModel({ ...state, receivedDate: event.value ?? undefined })}
                                    />
                                    <ValidationMessageView name="batchUpdate.ReceivedDate" validationModel={v} />
                                </>
                            )}
                        </Forms.QuarterColumn>
                    </Forms.Row>
                </div>

                <div className="my-3">
                    <Forms.Row>
                        <div className="col-md-auto">
                            <Forms.Checkbox id="consignmentField" label="" checked={state.updateConsignment} onChange={(value) => updateStateAndViewModel({ ...state, updateConsignment: value })} />
                        </div>
                        <Forms.QuarterColumn>
                            {!state.updateConsignment ? (
                                <Forms.ReadonlyField label="Consignment" id="consignmentField" value={state.consignment} />
                            ) : (
                                <>
                                    <Forms.Label htmlFor="consignmentField">Consignment</Forms.Label>
                                    <DropDownList
                                        id={"consignmentField"}
                                        className="mb-2"
                                        data={consignmentUpdateTypes}
                                        value={state.consignmentUpdateType}
                                        onChange={(e) => updateStateAndViewModel({ ...state, consignmentUpdateType: e.target.value })}
                                    />
                                    <Forms.TextArea
                                        label="Consignment"
                                        id="consignmentField"
                                        maxLength={100}
                                        rows={3}
                                        valid={v.applyValidity("batchUpdate.Consignment")}
                                        value={state.consignment}
                                        onChange={(value) => updateStateAndViewModel({ ...state, consignment: value })}
                                    />
                                    <ValidationMessageView name="batchUpdate.Consignment" validationModel={v} />
                                </>
                            )}
                        </Forms.QuarterColumn>
                    </Forms.Row>
                </div>

                <div className="my-3">
                    <Forms.Row>
                        <div className="col-md-auto">
                            <Forms.Checkbox
                                id="conditionStatusField"
                                label=""
                                checked={state.updateConditionStatus}
                                onChange={(value) => updateStateAndViewModel({ ...state, updateConditionStatus: value, conditionStatus: undefined })}
                            />
                        </div>
                        <Forms.QuarterColumn>
                            {!state.updateConditionStatus ? (
                                <Forms.ReadonlyField label="Condition Status" id="conditionStatusField" value={state.conditionStatus?.name} />
                            ) : (
                                <>
                                    <Forms.Label htmlFor="conditionStatusField">Condition Status</Forms.Label>
                                    <KendoComboBox
                                        id="conditionStatusField"
                                        value={state.conditionStatus}
                                        data={vm.referenceData.conditionStatuses}
                                        keyField="id"
                                        textField="name"
                                        valid={v.applyValidity("batchUpdate.ConditionStatus")}
                                        filterable={true}
                                        suggest={true}
                                        onChange={(value) => updateStateAndViewModel({ ...state, conditionStatus: value })}
                                    />
                                    <ValidationMessageView name="batchUpdate.ConditionStatus" validationModel={v} />
                                </>
                            )}
                        </Forms.QuarterColumn>
                    </Forms.Row>
                </div>

                <div className="my-3">
                    <Forms.Row>
                        <div className="col-md-auto">
                            <Forms.Checkbox
                                id="loanStatusField"
                                label=""
                                checked={state.updateLoanStatus}
                                onChange={(value) => updateStateAndViewModel({ ...state, updateLoanStatus: value, loanStatus: undefined })}
                            />
                        </div>
                        <Forms.QuarterColumn>
                            {!state.updateLoanStatus ? (
                                <Forms.ReadonlyField label="Loan Status" id="loanStatusField" value={state.loanStatus?.name} />
                            ) : (
                                <>
                                    <Forms.Label htmlFor="loanStatusField">Loan Status</Forms.Label>
                                    <KendoComboBox
                                        id="loanStatusField"
                                        value={state.loanStatus}
                                        data={vm.referenceData.loanStatuses}
                                        keyField="id"
                                        textField="name"
                                        valid={v.applyValidity("batchUpdate.LoanStatus")}
                                        filterable={true}
                                        suggest={true}
                                        onChange={(value) => updateStateAndViewModel({ ...state, loanStatus: value })}
                                    />
                                    <ValidationMessageView name="batchUpdate.LoanStatus" validationModel={v} />
                                </>
                            )}
                        </Forms.QuarterColumn>
                    </Forms.Row>
                </div>
            </div>
        </div>
    );
}
