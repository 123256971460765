// framework
import { clone } from "lodash";
// api
import * as Client from "../../api/Client";

export interface IJointAuthorityRoleRootViewModel {
    roleConfiguration: {
        roleGroups?: Array<Client.GetShellSecureUserRoleConfigurationRoleGroupDto> | undefined;
        roleRelationships?: Array<Client.GetShellSecureUserRoleConfigurationRoleRelationshipDto> | undefined;
    };

    refreshRoleConfiguration(roleConfiguration: Client.GetShellSecureUserRoleConfigurationResponseDto | undefined): this;
}

export abstract class BaseJointAuthorityRoleRootViewModel implements IJointAuthorityRoleRootViewModel {
    constructor() {
        this.roleConfiguration = {};
    }

    public roleConfiguration: {
        roleGroups?: Array<Client.GetShellSecureUserRoleConfigurationRoleGroupDto> | undefined;
        roleRelationships?: Array<Client.GetShellSecureUserRoleConfigurationRoleRelationshipDto> | undefined;
    };

    public refreshRoleConfiguration(roleConfiguration: Client.GetShellSecureUserRoleConfigurationResponseDto | undefined): this {
        const vm = clone(this);
        vm.roleConfiguration = clone(this.roleConfiguration);

        vm.roleConfiguration.roleGroups = roleConfiguration?.jointAuthorityRoleGroups;
        vm.roleConfiguration.roleRelationships = roleConfiguration?.roleRelationships;

        return vm;
    }
}
