// redux
import * as Models from "../../models/rootViewModel";
// common
import * as Forms from "../../../../../common/forms/BootstrapForms";

export default function CompanyDetailsView(props: { vm: Models.IDetailsViewModel }): React.ReactElement {
    const vm = props.vm;

    // view
    return (
        <div className="card my-2">
            <div className="card-body">
                <h2 className="card-title">Company Details</h2>
                <Forms.Row>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="companyNameField" label="Company Name" value={vm.companyName} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="acnArbnField" label="ACN / ARBN" value={vm.acnOrArbn} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="abnField" label="ABN" value={vm.abn} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn></Forms.HalfColumn>
                </Forms.Row>
            </div>
        </div>
    );
}
