// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
import * as Common from "../../../../../common/draftApplicationDetails/components/models/models";
// api
import { DraftApplicationSupportingDocumentationChecklistItemTypeEnum } from "../../../../../../api/Client";
// views
import ApplicationDetailsView from "./ApplicationDetailsView";
// common
import * as ViewHelpers from "../../../../../common/draftApplicationDetails/ViewHelpers";
import ContactDetailsView from "../../../common/components/ContactDetailsView";
import SupportingDocumentationView from "../../../../../common/draftApplicationDetails/components/SupportingDocumentationView";
import { IChecklistTemplateViewModel } from "../../../../../common/draftApplicationDetails/controls/supportingDocumentation/models/models";
import PaymentDetailsView from "../../../../../common/draftApplicationDetails/components/PaymentDetailsView";
import SignatureTypeView from "../../../../../common/draftApplicationDetails/components//SignatureTypeView";
import FormDocumentationView from "../../../../../common/draftApplicationDetails/components/FormDocumentationView";
import CommandsView from "../../../../../common/draftApplicationDetails/components/CommandsView";
import SimpleAuditEventsView from "../../../../../../common/audit/SimpleAuditEventsView";

export default function DetailsView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; lastRefreshedDatetime: Date | undefined }) {
    const vm = props.vm;
    const a = props.actions;
    const payment = vm.paymentDetails!;
    const isDigitalSignatures = vm.applicationDetails.isDigitalSignatures;

    const state = vm.state;
    const id = state.id!;
    const versionNumber = state.versionNumber!;
    const viewState = state.viewState!;

    function onSaveApplicationDetails() {
        a.saveApplicationDetailsRequested();
    }

    function onSaveContactDetails() {
        a.initialiseRequested(id);
    }

    function onSaveSupportingDocumentation() {
        a.initialiseRequested(id);
    }

    function onSaveFormDocumentation() {
        a.initialiseRequested(id);
    }

    function onSaveSignatureType() {
        a.initialiseRequested(id);
    }

    function onEditRequested(task: Common.ApplicationTaskEnum) {
        a.viewChanged(vm.setInEditTask(task));
    }

    function onCancelRequested() {
        a.initialiseRequested(id);
    }

    function updateCoCnDetails(values: Models.ICoCnDetailsViewModel) {
        a.viewChanged(vm.refreshCoCnDetailsUpdated(values));
    }

    function onReadyForSigning() {
        a.setApplicationAsReadyForSigningRequested();
    }

    function onRevertToPreparing() {
        a.revertApplicationToPreparingRequested();
    }

    function onPaying() {
        a.setApplicationAsPayingRequested();
    }

    function onReadyForSubmission() {
        a.setApplicationAsReadyForSubmissionRequested();
    }

    function onSubmit() {
        a.submitApplicationToNoptaRequested();
    }

    function onDelete() {
        a.deleteApplicationRequested();
    }

    return (
        <>
            <ApplicationDetailsView
                vm={vm.applicationDetails}
                canManage={state.canManageApplicantOnly}
                validationState={state.validationState}
                viewState={viewState}
                inEditTask={state.inEditTask}
                updateCoCnDetails={updateCoCnDetails}
                onEdit={() => onEditRequested(Common.ApplicationTaskEnum.ApplicationDetails)}
                onSave={onSaveApplicationDetails}
                onCancel={onCancelRequested}
            />
            <ContactDetailsView
                id={id}
                versionNumber={versionNumber}
                canManage={state.canManageApplicantOnly}
                validationState={state.validationState}
                viewState={viewState}
                inEditTask={state.inEditTask}
                onEdit={() => onEditRequested(Common.ApplicationTaskEnum.ContactDetails)}
                onSave={onSaveContactDetails}
                onCancel={onCancelRequested}
                lastRefreshedDatetime={props.lastRefreshedDatetime}
            />
            <SupportingDocumentationView
                id={id}
                versionNumber={versionNumber}
                canManage={state.canManageApplicantOrPartnerCompany}
                validationState={state.validationState}
                viewState={viewState}
                inEditTask={state.inEditTask}
                title="Certificate or equivalent confirmation"
                checklistTemplate={getChecklistTemplate()}
                onEdit={() => onEditRequested(Common.ApplicationTaskEnum.SupportingDocumentation)}
                onSave={onSaveSupportingDocumentation}
                onCancel={onCancelRequested}
                lastRefreshedDatetime={props.lastRefreshedDatetime}
            />
            <PaymentDetailsView vm={payment} viewState={viewState} />
            <SignatureTypeView
                id={id}
                versionNumber={versionNumber}
                canManage={state.canManageApplicantOnly}
                validationState={state.validationState}
                viewState={viewState}
                inEditTask={state.inEditTask}
                onEdit={() => onEditRequested(Common.ApplicationTaskEnum.SignatureType)}
                onSave={onSaveSignatureType}
                onCancel={onCancelRequested}
                lastRefreshedDatetime={props.lastRefreshedDatetime}
            />
            {ViewHelpers.showApplicationFormSection(state.validationState, viewState) && (
                <FormDocumentationView
                    id={id}
                    versionNumber={versionNumber}
                    canManage={state.canManageFormDocumentation}
                    validationState={state.validationState}
                    viewState={viewState}
                    inEditTask={state.inEditTask}
                    onEdit={() => onEditRequested(Common.ApplicationTaskEnum.FormDocumentation)}
                    onSave={onSaveFormDocumentation}
                    onCancel={onCancelRequested}
                    lastRefreshedDatetime={props.lastRefreshedDatetime}
                />
            )}
            <CommandsView
                vm={state}
                isPaymentRequired={payment.isPaymentRequired}
                isDigitalSignatures={isDigitalSignatures}
                onReadyForSigning={onReadyForSigning}
                onRevertToPreparing={onRevertToPreparing}
                onPaying={onPaying}
                onReadyForSubmission={onReadyForSubmission}
                onSubmit={onSubmit}
                onDelete={onDelete}
            />
            {viewState !== Common.ApplicationViewStateEnum.Submitted && <SimpleAuditEventsView simpleAuditEvents={state.stateAudit} />}
        </>
    );
}

function getChecklistTemplate(): IChecklistTemplateViewModel {
    return {
        items: [
            {
                type: DraftApplicationSupportingDocumentationChecklistItemTypeEnum.CoCN_Certificate,
                description: <span>A copy of the company's Certificate of Registration on Change of Name or equivalent confirmation has been uploaded.</span>,
            },
        ],
    };
}
