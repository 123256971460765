// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../../api/Client";
// common
import { IWorkProgramViewModel, WorkProgramViewModel } from "../../../../../../common/workProgram/models";

export interface IRootViewModel {
    id: string | undefined;

    workProgram: IWorkProgramViewModel | undefined;

    viewState: ViewStateEnum;

    refreshDetails(id: string, response: Client.GetCompanyOpggsApplicationDetailsWorkProgramResponseDto): IRootViewModel;
    onInitialised(): IRootViewModel;
}

export enum ViewStateEnum {
    Initialising,
    View,
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.id = undefined;

        this.workProgram = undefined;

        this.viewState = ViewStateEnum.Initialising;
    }

    public id: string | undefined;

    public workProgram: IWorkProgramViewModel | undefined;

    public viewState: ViewStateEnum;

    public refreshDetails(id: string, response: Client.GetCompanyOpggsApplicationDetailsWorkProgramResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.id = id;

        vm.workProgram = WorkProgramViewModel.fromApplicationDetailsDto(response);

        return vm;
    }

    public onInitialised(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Initialising) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.workProgram = clone(this.workProgram);

        return vm;
    }
}
