// framework
import { useEffect, useState } from "react";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
// api
import { EmailVerificationStatusEnum } from "../../../../api/Client";
// views
import { EmailVerificationView } from "./EmailVerificationView";
// common
import { ValidationModel } from "../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../common/validation/ValidationMessageView";
import * as Forms from "../../../../common/forms/BootstrapForms";

export function NewUserRequestView(props: { vm: Models.IRootViewModel; v: ValidationModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const request = vm.user.newUserRequest;
    const v = props.v;
    const actions = props.actions;
    const status = vm.user.newUserRequest.emailVerification.status;

    const [state, setState] = useState<Models.INewUserRequestViewModel>({
        email: "",
        firstName: "",
        lastName: "",
        contactNumber: "",
        position: "",
        password: "",
        passwordConfirmation: "",
        emailVerification: {
            isVisible: false,
            token: "",
            verificationCode: "",
            status: EmailVerificationStatusEnum.Unverified,
            requestEmailVerificationStatusMessages: undefined,
            verifyEmailVerificationStatusMessages: undefined,
        },
    });

    useEffect(() => {
        setState({
            email: request.email ?? "",
            firstName: request.firstName ?? "",
            lastName: request.lastName ?? "",
            contactNumber: request.contactNumber ?? "",
            position: request.position ?? "",
            password: request.password ?? "",
            passwordConfirmation: request.passwordConfirmation ?? "",
            emailVerification: {
                isVisible: request.emailVerification.isVisible ?? false,
                token: request.emailVerification.token ?? "",
                verificationCode: request.emailVerification.verificationCode ?? "",
                status: request.emailVerification.status ?? EmailVerificationStatusEnum.Unverified,
                requestEmailVerificationStatusMessages: request.emailVerification.requestEmailVerificationStatusMessages ?? undefined,
                verifyEmailVerificationStatusMessages: request.emailVerification.verifyEmailVerificationStatusMessages ?? undefined,
            },
        });
    }, [request]);

    function onChanged(values: any) {
        setState({ ...state, ...values });
    }

    function updateViewModel() {
        actions.viewChanged(vm.refreshNewUserRequestUpdated({ ...state }));
    }

    return (
        <div className="card mb-2">
            <div className="card-header">New User Details</div>
            <div className="card-body">
                <Forms.Group>
                    <Forms.TextInput
                        id="emailAddressField"
                        label="Email"
                        value={state.email}
                        valid={v.applyValidity("NewUserRequest.Email")}
                        onChange={(value) => onChanged({ email: value })}
                        onBlur={updateViewModel}
                        disabled={status === EmailVerificationStatusEnum.Verified}
                    />
                    <ValidationMessageView name="NewUserRequest.Email" validationModel={v} />
                    <EmailVerificationView vm={vm} v={v} actions={actions} />
                </Forms.Group>
                <Forms.Group>
                    <Forms.TextInput
                        id="firstNameField"
                        label="First Name"
                        value={state.firstName}
                        valid={v.applyValidity("NewUserRequest.FirstName")}
                        onChange={(value) => onChanged({ firstName: value })}
                        onBlur={updateViewModel}
                    />
                    <ValidationMessageView name="NewUserRequest.FirstName" validationModel={v} />
                </Forms.Group>
                <Forms.Group>
                    <Forms.TextInput
                        id="lastNameField"
                        label="Last Name"
                        value={state.lastName}
                        valid={v.applyValidity("NewUserRequest.LastName")}
                        onChange={(value) => onChanged({ lastName: value })}
                        onBlur={updateViewModel}
                    />
                    <ValidationMessageView name="NewUserRequest.LastName" validationModel={v} />
                </Forms.Group>
                <Forms.Group>
                    <Forms.TextInput
                        id="contactNumberField"
                        label="Contact Number"
                        value={state.contactNumber}
                        valid={v.applyValidity("NewUserRequest.ContactNumber")}
                        onChange={(value) => onChanged({ contactNumber: value })}
                        onBlur={updateViewModel}
                    />
                    <ValidationMessageView name="NewUserRequest.ContactNumber" validationModel={v} />
                </Forms.Group>
                <Forms.Group>
                    <Forms.TextInput
                        id="positionField"
                        label="Position"
                        value={state.position}
                        valid={v.applyValidity("NewUserRequest.Position")}
                        onChange={(value) => onChanged({ position: value })}
                        onBlur={updateViewModel}
                    />
                    <ValidationMessageView name="NewUserRequest.Position" validationModel={v} />
                </Forms.Group>
                <Forms.Group>
                    <Forms.PasswordInput
                        id="passwordField"
                        label="Password"
                        value={state.password}
                        valid={v.applyValidity("NewUserRequest.Password")}
                        onChange={(value) => onChanged({ password: value })}
                        onBlur={updateViewModel}
                    />
                    <ValidationMessageView name="NewUserRequest.Password" validationModel={v} />
                </Forms.Group>
                <Forms.Group>
                    <Forms.PasswordInput
                        id="passwordConfirmationField"
                        label="Password Confirmation"
                        value={state.passwordConfirmation}
                        valid={v.applyValidity("NewUserRequest.PasswordConfirmation")}
                        onChange={(value) => onChanged({ passwordConfirmation: value })}
                        onBlur={updateViewModel}
                    />
                    <ValidationMessageView name="NewUserRequest.PasswordConfirmation" validationModel={v} />
                </Forms.Group>
            </div>
        </div>
    );
}
