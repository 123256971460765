// kendo
import { saveAs } from "@progress/kendo-file-saver";
// api
import { SecurePortalDocumentDto } from "../api/Client";
// common
import * as toastHelper from "../common/toastHelper";

export interface IDownloadEntry {
    url: string;
    fileName: string;
}

const delay = (milliseconds: number) =>
    new Promise((resolve) => {
        setTimeout(resolve, milliseconds);
    });

export function download(fileName: string, mimeType: string, content: string): void {
    const dataUri = "data:" + mimeType + ";base64," + content;
    saveAs(dataUri, fileName);
}

export function maxBatchDownloads() {
    return 20;
}

export async function multipleDownloads(downloadEntries: IDownloadEntry[]) {
    // arbitrary maximum downloads allowed
    const maxDownloads = maxBatchDownloads();

    if (downloadEntries.length > maxDownloads) {
        toastHelper.showValidationErrorNotification("Maximum Batch Downloads", `Maximum number of batch downloads allowed is ${maxDownloads}`);
        return;
    }
    for (const downloadEntry of downloadEntries) {
        // wait a second between each download, otherwise the browser just skips over downloading some files
        await delay(1000);

        // .txt files require the data attribute added to the URL for the browser to download the file instead of opening it in the browser
        const dataUri = downloadEntry.url.endsWith(".txt") ? `data:text/plain;charset=UTF-8,${downloadEntry.url}` : downloadEntry.url;

        saveAs(dataUri, downloadEntry.fileName);
    }
}

export function tryDownloadDocument(document: SecurePortalDocumentDto | undefined): void {
    if (!document) return;
    download(document.fileName, document.mimeType, document.content);
}
