// api
import * as Client from "../api/Client";
// common
import * as applicationDetailsRouteHelper from "./opggs/applicationDetails/applicationDetailsRouteHelper";
import * as draftApplicationDetailsRouteHelper from "./common/draftApplicationDetails/draftApplicationDetailsRouteHelper";
import { TitleDetailsTabsEnum } from "./opggs/titleDetails/components/views/TitleDetailsView";
import { LicenceDetailsTabsEnum } from "../common/oei/licenceDetails/views/LicenceDetailsView";

// Dashboard
export function dashboard(): string {
    return "/Company/Home/Dashboard";
}

// Application
export function opggsApplicationList(): string {
    return "/Company/Opggs/ApplicationList";
}

export function applicationDetails(applicationId: string): string {
    return applicationDetailsRouteHelper.details(applicationId);
}

export function applicationAdhocUpload(): string {
    return "/Company/Opggs/UploadTitleApplication";
}

export function draftApplicationList(): string {
    return "/Company/Opggs/DraftApplicationList";
}

export function draftApplicationDetails(id: number, type: Client.DraftApplicationTypeEnum): string {
    return draftApplicationDetailsRouteHelper.details(id, type);
}

//OEI
export function oeiDraftApplicationList(): string {
    return "/Company/Oei/DraftApplicationList";
}

export function oeiApplicationList(): string {
    return "/Company/Oei/ApplicationList";
}

export function oeiAdhocUpload(): string {
    return "/Company/Oei/UploadLicenceApplication";
}

export function invitationToApplyList(): string {
    return "/Company/Oei/InvitationToApplyList";
}

export function licenceList(): string {
    return "/Company/Oei/LicenceList";
}

export function licenceDetails(licenceId: string, tab?: LicenceDetailsTabsEnum): string {
    if (tab) {
        return `/Company/Oei/LicenceDetails/${licenceId}/${tab}`;
    }
    return `/Company/Oei/LicenceDetails/${licenceId}`;
}

// User Management
export function companyList(): string {
    return "/Company/User/CompanyList";
}

export function companyDetails(companyId: number): string {
    return `/Company/User/CompanyDetails/${companyId}`;
}

export function userList(): string {
    return "/Company/User/UserList";
}

export function userDetails(secureUserId: number): string {
    return `/Company/User/UserDetails/${secureUserId}`;
}

export function requestList(): string {
    return "/Company/User/RequestList";
}

export function requestDetails(secureRequestId: number, secureRequestGroupType: Client.SecureRequestGroupTypeEnum): string {
    return `/Company/User/RequestDetails/${secureRequestGroupType}/${secureRequestId}`;
}

// Finance
export function financialNotificationList(): string {
    return "/Company/Finance/FinancialNotificationList";
}

export function paymentHistoryList(): string {
    return "/Company/Finance/PaymentHistoryList";
}

export function financialNotificationDetails(financialNotificationId: number, isComplete: boolean): string {
    return isComplete ? `/Company/Finance/PaymentHistoryDetails/${financialNotificationId}` : `/Company/Finance/FinancialNotificationDetails/${financialNotificationId}`;
}

// Titles
export function titlesList(): string {
    return "/Company/Opggs/TitleList";
}

export function titleDetails(titleId: string, tab?: TitleDetailsTabsEnum): string {
    if (tab) {
        return `/Company/Opggs/TitleDetails/${titleId}/${tab}`;
    }
    return `/Company/Opggs/TitleDetails/${titleId}`;
}

export function legislativeFormsList(): string {
    return "/Company/Opggs/LegislativeFormsList";
}

export function legislativeFormsFormRequestCreate(titleId: string): string {
    return `/Company/Opggs/LegislativeForms/FormRequestCreate/${titleId}`;
}

export function legislativeFormsFormRequestDetails(formRequestId: number, formRequestType: number): string {
    return `/Company/Opggs/LegislativeForms/FormRequestDetails/${formRequestType}/${formRequestId}`;
}

// My Account
export function myDetails(): string {
    return "/Company/Account/MyDetails";
}

export function requestAccess(): string {
    return "/Company/Account/RequestAccess";
}
