// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
import * as Common from "../../../../../common/draftApplicationDetails/components/models/models";
// common
import CommonWorkProgramView from "../../../common/components/WorkProgramView";

export default function WorkProgramView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; lastRefreshedDatetime: Date | undefined }) {
    const vm = props.vm;
    const a = props.actions;
    const state = vm.state;

    function onSaveWorkProgram() {
        a.initialiseRequested(state.id!);
    }

    function onEditRequested(task: Common.ApplicationTaskEnum) {
        a.viewChanged(vm.setInEditTask(task));
    }

    function onCancelRequested() {
        a.initialiseRequested(state.id!);
    }

    return (
        <CommonWorkProgramView
            id={state.id!}
            versionNumber={state.versionNumber!}
            canManage={state.canManageApplicantOnly}
            validationState={state.validationState}
            viewState={state.viewState!}
            inEditTask={state.inEditTask}
            guidance={getGuidance()}
            onEdit={() => onEditRequested(Common.ApplicationTaskEnum.WorkProgram)}
            onSave={onSaveWorkProgram}
            onCancel={onCancelRequested}
            lastRefreshedDatetime={props.lastRefreshedDatetime}
        />
    );
}

function getGuidance(): React.ReactElement {
    return (
        <>
            <p>
                The titleholder(s) should outline all activities proposed for the renewal term with detailed information of each proposed activity. The indicative expenditure of each activity proposed
                should be provided.
            </p>
            <p>
                Enter the primary term work program as a single block of three years (i.e. years 1-3). The secondary term work program must be entered as individual years (i.e. years 4 and 5).
                Additional rows may be added for multiple activities within a permit year(s).
            </p>
        </>
    );
}
