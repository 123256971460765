// models
import { IRootViewModel } from "../../models/models";
// api
import { NopimsTitleTypeEnum } from "../../../../../../api/Client";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import ExternalLinkView from "../../../../../../common/externalLink/ExternalLinkView";
import SimpleAuditEventsView from "../../../../../../common/audit/SimpleAuditEventsView";
import * as shellRouteHelper from "../../../../../../shell/shellRouteHelper";

interface IProps {
    vm: IRootViewModel;
}

export default function ReprocessingDetailsView(props: IProps): JSX.Element {
    const { vm } = props;
    const reprocessing = vm.reprocessing;

    return (
        <>
            {reprocessing && (
                <>
                    <div className="card mb-2">
                        <div className="card-body">
                            <h2 className="card-title">General</h2>
                            <Forms.Row>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField label="Reprocessing ID" id="identifierField" value={reprocessing.identifier} />
                                </Forms.QuarterColumn>
                                <Forms.HalfColumn>
                                    <Forms.ReadonlyField label="Name" id="nameField" value={reprocessing.name} />
                                </Forms.HalfColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField label="Legislation" id="nopimsLegislationField" value={reprocessing.nopimsLegislationName} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.Label htmlFor="titlesField">Title(s)</Forms.Label>
                                    <div>
                                        {reprocessing.titles && reprocessing.titles.length > 0 ? (
                                            <>
                                                {reprocessing.titles.map((title, index) => (
                                                    <span key={index}>
                                                        {title.nopimsTitleType === NopimsTitleTypeEnum.Nopta && title.titleNumber && (
                                                            <ExternalLinkView href={shellRouteHelper.publicPortalTitleDetails(title.titleNumber)}>{title.titleNumber}</ExternalLinkView>
                                                        )}
                                                        {title.nopimsTitleType === NopimsTitleTypeEnum.Other && <span>{title.otherTitleNumber}</span>}
                                                        {index < reprocessing.titles.length - 1 && <span>{", "}</span>}
                                                    </span>
                                                ))}
                                            </>
                                        ) : (
                                            <Forms.ReadonlyTextInput id="titlesField" />
                                        )}
                                    </div>
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField label="Operator" id="operatorField" value={reprocessing.operatorCompanyName} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="basinsField" label="Basin(s)" value={reprocessing.basinNames} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField label="Confidentiality" id="confidentialityField" value={GlobalHelpers.convertBooleanToConfidentialityValue(reprocessing.isConfidential)} />
                                </Forms.QuarterColumn>
                                <Forms.FullColumn>
                                    <Forms.ReadonlyTextArea id="commentsField" label="Comments" value={reprocessing.comments} />
                                </Forms.FullColumn>
                            </Forms.Row>
                        </div>
                    </div>
                    <SimpleAuditEventsView simpleAuditEvents={vm.reprocessing?.audit} />
                </>
            )}
        </>
    );
}
