// framework
import { useEffect, useState } from "react";
// kendo
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { NumericTextBox } from "@progress/kendo-react-inputs";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
import { IPaymentViewModel } from "../../models/paymentViewModel";
// api
import * as Client from "../../../../../api/Client";
// common
import * as Validation from "../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
import * as Forms from "../../../../../common/forms/BootstrapForms";

export default function MakeChequePaymentDetailsView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; validator: Validation.IValidationModel }): React.ReactElement {
    let vm = props.vm;
    let actions = props.actions;
    let v = props.validator;

    const [state, setState] = useState<IPaymentViewModel>({
        paymentMethod: Client.Payment2MethodEnum.Cheque,
        paymentMadeDate: undefined,
        receivedAmount: vm.financialNotification.outstandingAmount,
        bankName: undefined,
        bankReceiptNumber: undefined,
        chequeNumber: undefined,
        comments: undefined,
        creditCardSurchargeAmount: undefined,
        secureTransactionToken: undefined,
    });

    useEffect(() => {
        actions.viewChanged(vm.refreshPaymentDetails({ ...state }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function onChanged(values: any) {
        setState({ ...state, ...values });
    }

    function updateViewModel() {
        actions.viewChanged(vm.refreshPaymentDetails({ ...state }));
    }

    return (
        <>
            <Forms.Group>
                <Forms.Label htmlFor="paymentMadeDate">Payment Made</Forms.Label>
                <DatePicker
                    id="paymentMadeDate"
                    value={state.paymentMadeDate}
                    valid={v.applyValidityForKendo("MakePayment.PaymentMadeDate")}
                    onChange={(e) => {
                        onChanged({ paymentMadeDate: e.value });
                    }}
                    max={new Date()}
                    onBlur={updateViewModel}
                />
                <ValidationMessageView name="MakePayment.PaymentMadeDate" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="receivedAmount">Payment Amount</Forms.Label>
                <NumericTextBox
                    id="receivedAmount"
                    value={state.receivedAmount}
                    valid={v.applyValidityForKendo("MakePayment.ReceivedAmount")}
                    format="c2"
                    onChange={(e) => onChanged({ receivedAmount: e.value })}
                    onBlur={updateViewModel}
                />
                <ValidationMessageView name="MakePayment.ReceivedAmount" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.TextInput
                    id="chequeNumber"
                    label="Cheque Number"
                    maxLength={100}
                    value={state.chequeNumber}
                    valid={v.applyValidity("MakePayment.ChequeNumber")}
                    onChange={(value) => onChanged({ chequeNumber: value })}
                    onBlur={updateViewModel}
                />
                <ValidationMessageView name="MakePayment.ChequeNumber" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.TextArea
                    id="comments"
                    label="Comments"
                    rows={5}
                    maxLength={1000}
                    value={state.comments}
                    valid={v.applyValidity("MakePayment.Comments")}
                    onChange={(value) => onChanged({ comments: value })}
                    onBlur={updateViewModel}
                />
                <ValidationMessageView name="MakePayment.Comments" validationModel={v} />
            </Forms.Group>
        </>
    );
}
