// redux
import * as Models from "../../models/models";
// common
import TitleholdersControl from "../../controls/titleholders/components/Controller";

export default function TitleholdersView(props: { vm: Models.IRootViewModel; lastRefreshedDatetime: Date | undefined }) {
    const vm = props.vm;
    const id = vm.id!;

    if (!id) return <></>;

    return (
        <>
            <TitleholdersControl id={id} cardTitle="Titleholders" lastRefreshedDatetime={props.lastRefreshedDatetime} className="mb-2" />
        </>
    );
}
