import { takeEvery } from "redux-saga/effects";
import * as Models from "../models/models";
import * as Actions from "./actions";

import getDetailsRequested from "./sagas/sagaGetDetailsRequested";
import createPaymentRequested from "./sagas/sagaCreatePaymentRequested";
import getCreditCardSurcharge from "./sagas/getCreditCardSurcharge";

export default function* watcherSaga() {
    yield takeEvery(Actions.actions.sagaGetDetailsRequested, (action) => getDetailsRequested(action as Actions.IAction<number>));
    yield takeEvery(Actions.actions.sagaCreatePaymentRequested, (action) => createPaymentRequested(action as Actions.IAction<Models.IRootViewModel>));
    yield takeEvery(Actions.actions.getCreditCardSurcharge, (action) => getCreditCardSurcharge(action as Actions.IAction<Models.IRootViewModel>));
}
