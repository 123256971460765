// redux
import * as Models from "../../models/rootViewModel";
// common
import * as Forms from "../../../../../common/forms/BootstrapForms";

export default function JointAuthorityDetailsView(props: { vm: Models.IDetailsViewModel }): React.ReactElement {
    const vm = props.vm;

    // view
    return (
        <div className="card my-2">
            <div className="card-body">
                <h2 className="card-title">Joint Authority Details</h2>
                <Forms.Row>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="jointAuthorityNameField" label="Joint Authority Name" value={vm.jointAuthorityName + " Joint Authority"} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn></Forms.HalfColumn>
                </Forms.Row>
            </div>
        </div>
    );
}
