// framework
import { DashIcon } from "@primer/octicons-react";
// redux
import { IDocumentViewModel } from "../models/documentViewModel";
// api
import * as Client from "../../../../api/Client";
// common
import ApplicationLinkController from "../../../../common/links/ApplicationLinkController";
import DraftApplicationLinkController from "../../../../common/links/DraftApplicationLinkController";
import FinancialNotificationLinkController from "../../../../common/links/FinancialNotificationLinkController";
import TitleLinkController from "../../../../common/links/TitleLinkController";
import * as Forms from "../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../common/GlobalHelpers";
import SecureFileDownloadLinkControl from "../../../../common/secureFileDownload/SecureFileDownloadLinkControl";
import LicenceLinkController from "../../../../common/links/LicenceLinkController";

export default function FinancialNotificationDetailsView(props: { vm: Client.GetCompanyFinanceFnDetailsResponseDto; getDocument: (vm: IDocumentViewModel) => void }): React.ReactElement {
    const vm = props.vm;

    function getPaymentReferences(paymentReferences: Client.GetCompanyFinanceFnDetailsResponsePaymentDto[]): string {
        let result = "";
        paymentReferences.forEach((p) => {
            if (result) result += ", ";
            result += p.paymentCode;
        });
        return result;
    }

    function getTitleType(): string {
        let result = ``;
        if (vm.titleType) result += ` (${vm.titleType})`;
        return result;
    }

    function getLicenceType(): string {
        let result = ``;
        if (vm.licenceType) result += ` (${vm.licenceType})`;
        return result;
    }

    function getFinancialNotificationTypeRow() {
        if (vm.financialNotificationTypeEnum === Client.FinancialNotificationTypeEnum.ApplicationFee) {
            return (
                <>
                    <Forms.HalfColumn></Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.Element label="Tracking Number">
                            {vm.applicationLink ? (
                                <ApplicationLinkController linkDto={vm.applicationLink} secureEntityType="Company" />
                            ) : vm.draftApplicationLink ? (
                                <DraftApplicationLinkController linkDto={vm.draftApplicationLink} secureEntityType="Company" />
                            ) : (
                                <></>
                            )}
                        </Forms.Element>
                    </Forms.HalfColumn>
                </>
            );
        } else if (vm.financialNotificationTypeEnum === Client.FinancialNotificationTypeEnum.LatePaymentPenalty) {
            return (
                <>
                    <Forms.HalfColumn></Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.Element label="Late Financial Notification">
                            <FinancialNotificationLinkController linkDto={vm.lateFinancialNotificationLink!} secureEntityType="Company" />
                        </Forms.Element>
                    </Forms.HalfColumn>
                </>
            );
        } else {
            let anniversaryPeriod = ``;
            if (vm.anniversaryPeriodStart && vm.anniversaryPeriodEnd) {
                anniversaryPeriod = GlobalHelpers.toNoptaDateString(vm.anniversaryPeriodStart) + ` - ` + GlobalHelpers.toNoptaDateString(vm.anniversaryPeriodEnd);
            }
            return (
                <>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="anniversaryDateField" label="Anniversary Date" value={GlobalHelpers.toNoptaDateString(vm.anniversaryDate)} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="anniversaryPeriodField" label="Anniversary Period" value={anniversaryPeriod} />
                    </Forms.HalfColumn>
                </>
            );
        }
    }

    function getDocumentDownloadLink(document: Client.GetCompanyFinanceFnDetailsResponseDocumentDto | undefined) {
        if (!document) {
            return (
                <span title="None Found">
                    <DashIcon />
                </span>
            );
        }
        return (
            <span>
                <SecureFileDownloadLinkControl id={document.fileId}>{document.documentName}</SecureFileDownloadLinkControl>
            </span>
        );
    }

    // view
    return (
        <div className="card-body">
            <h2 className="card-title">Details</h2>
            <Forms.Row>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField id="notificationIdField" label="Notification ID" value={vm.financialNotificationCode} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.Element label="Title/Licence Number">
                        {vm.administrationType === Client.AdministrationTypeEnum.Opggs && vm.titleLink && (
                            <>
                                <TitleLinkController linkDto={vm.titleLink} secureEntityType="Company" /> {getTitleType()}
                            </>
                        )}
                        {vm.administrationType === Client.AdministrationTypeEnum.Oei && vm.licenceLink && (
                            <>
                                <LicenceLinkController linkDto={vm.licenceLink} secureEntityType="Company" /> {getLicenceType()}
                            </>
                        )}
                    </Forms.Element>
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField id="companyNameField" label="Company" value={vm.companyName} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField id="notificationTypeField" label="Notification Type" value={vm.financialNotificationTypeDisplay} />
                </Forms.HalfColumn>
                {getFinancialNotificationTypeRow()}
                <Forms.HalfColumn>
                    <Forms.ReadonlyField id="issueDateField" label="Issue Date" value={GlobalHelpers.toNoptaDateString(vm.financialNotificationIssueDatetime)} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField id="dueDateField" label="Due Date" value={GlobalHelpers.toNoptaDateString(vm.financialNotificationDueDate)} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField id="totalAmountField" label="Total Amount" value={GlobalHelpers.toNoptaNumberString(vm.totalAmount, "c")} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField id="balanceField" label="Balance" value={GlobalHelpers.toNoptaNumberString(vm.outstandingAmount, "c")} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField id="notificationStatusField" label="Notification Status" value={vm.financialNotificationStatus} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField id="paymentReferencesField" label="Payment Reference(s)" value={getPaymentReferences(vm.paymentReferences)} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.Element label="Notification Document">{getDocumentDownloadLink(vm.notificationDocument)}</Forms.Element>
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.Element label="Payment Statement">{getDocumentDownloadLink(vm.paymentStatement)}</Forms.Element>
                </Forms.HalfColumn>
                {vm.creditCardReceipt && (
                    <Forms.HalfColumn>
                        <Forms.Element label="Credit Card Receipt">{getDocumentDownloadLink(vm.creditCardReceipt)}</Forms.Element>
                    </Forms.HalfColumn>
                )}
            </Forms.Row>
        </div>
    );
}
