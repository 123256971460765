// framework
import * as Validation from "../../../../../../../common/validation/ValidationModel";
// redux
import * as Models from "./models";

export default function validate(vm: Models.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateSignatureType(vm, v);
    return v;
}

export function validateSignatureType(vm: Models.IRootViewModel, v: Validation.ValidationModel): void {
    // can't be null, can't be undefined
    if (!vm.signatureType) {
        v.addError("SignatureType.SignatureType", "Signing Method is required.");
    }
}
