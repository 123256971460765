// framework
import { useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/rootViewModel";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
import ConflictView from "../../../../common/alerts/ConflictAlertView";
import DirtyScope from "../../../../common/shell/DirtyScopeController";
// views
import GeoscienceAustraliaPermissionsView from "./views/GeoscienceAustraliaPermissionsView";
import StatusMessagesAlertsView from "../../../../common/alerts/StatusMessagesAlertsView";
import GeoscienceAustraliaPermissionsEditView from "./views/GeoscienceAustraliaPermissionsEditView";

interface IGeoscienceAustraliaControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function GeoscienceAustraliaController(props: IGeoscienceAustraliaControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    // on mount
    useEffect(() => {
        props.initialise();
        return function () {
            props.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BodyLayout
            title="Manage Geoscience Australia Access"
            selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_UserManagement_ManageGeoscienceAustralia}
            onReloadRequested={() => {
                props.initialise();
            }}
        >
            <ConflictView isConflict={vm.isConflict} />
            <StatusMessagesAlertsView statusMessages={vm.service.statusMessages} />
            {vm.viewState === Models.ViewStateEnum.View && <GeoscienceAustraliaPermissionsView vm={vm} actions={actions} />}
            {vm.viewState === Models.ViewStateEnum.Edit && (
                <DirtyScope scopeName="geoscienceAustralia/userManagement/geoscienceAustralia" isDirty={vm.isDirty}>
                    <GeoscienceAustraliaPermissionsEditView vm={vm} actions={actions} />
                </DirtyScope>
            )}
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_UserManagement_GeoscienceAustraliaDetails }), Actions.actionFactory)(GeoscienceAustraliaController);
