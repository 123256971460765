// common
import * as Validation from "../../../../../common/validation/ValidationModel";
// models
import { IRootViewModel } from "./models";

export default function validate(vm: IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    const batchUpdateDetails = vm.batchUpdateDetails;

    if (!vm.surveyData.some((item) => item.isSelected)) {
        v.addError("batchUpdate.ItemSelected", "At least one Survey Data must be selected.");
    }
    if (vm.surveyData.filter((item) => item.isSelected).length > 100) {
        v.addError("batchUpdate.ItemSelected", "Cannot select more than 100 survey data.");
    }

    if (batchUpdateDetails.updateConfidentiality && batchUpdateDetails.confidentiality === undefined) {
        v.addError("batchUpdate.IsConfidential", "Confidentiality is required.");
    }

    if (batchUpdateDetails.updateConfidentialityRemarks && Validation.isNullOrWhiteSpace(batchUpdateDetails.confidentialityRemarks)) {
        v.addError("batchUpdate.ConfidentialityRemarks", "Confidentiality Remarks are required.");
    } else if (vm.batchUpdateDetails.updateConfidentialityRemarks && vm.batchUpdateDetails.confidentialityRemarks && vm.batchUpdateDetails.confidentialityRemarks.length > 4000) {
        v.addError("batchUpdate.ConfidentialityRemarks", "Confidentiality Remarks must be 4,000 characters or less.");
    }

    if (batchUpdateDetails.updateReceivedDate && !batchUpdateDetails.receivedDate) {
        v.addError("batchUpdate.ReceivedDate", "Received Date is required.");
    }

    if (batchUpdateDetails.updateConsignment && !batchUpdateDetails.consignment) {
        v.addError("batchUpdate.Consignment", "Consignment is required.");
    } else if (vm.batchUpdateDetails.updateConsignment && vm.batchUpdateDetails.consignment && vm.batchUpdateDetails.consignment.length > 100) {
        v.addError("batchUpdate.Consignment", "Consignment must be 100 characters or less.");
    }

    if (batchUpdateDetails.updateConditionStatus && !batchUpdateDetails.conditionStatus) {
        v.addError("batchUpdate.ConditionStatus", "Condition Status is required.");
    }

    if (batchUpdateDetails.updateLoanStatus && !batchUpdateDetails.loanStatus) {
        v.addError("batchUpdate.LoanStatus", "Loan Status is required.");
    }

    return v;
}
