// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../geoscienceAustraliaReduxRegistration";
import { IInitialiseRequestedPayload } from "../actions";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_WellsBoreholes_RigHistoryList;

export default function* initialise(action: Actions.IAction<IInitialiseRequestedPayload>) {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const { rigId } = action.payload;

    try {
        yield put(shellHelper.createSetBusyAction());

        const response: Client.GetGeoscienceAustraliaWellsBoreholesRigHistoryListResponseDto = yield call(async () => {
            const client = await ClientFactory.createGeoscienceAustraliaWellsBoreholesClient();
            return await CallHelper.simpleCall(() => client.getGeoscienceAustraliaWellsBoreholesRigHistoryList(new Client.GetGeoscienceAustraliaWellsBoreholesRigHistoryListRequestDto({ id: rigId })));
        });
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshRigHistoryList(response)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
