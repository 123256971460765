// framework
import { useState } from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// api
import * as Client from "../../../../../../../api/Client";

export default function TitleholdersView(props: { titleholders: Client.GetTitleholdersItemDto[] }) {
    const initialGridState: State = {
        sort: [{ field: "companyName", dir: "asc" }],
        skip: 0,
        take: 20,
    };
    const [gridState, setGridState] = useState(initialGridState);

    return (
        <Grid
            pageable
            sortable
            resizable
            navigatable
            data={process(props.titleholders, gridState)}
            {...gridState}
            onDataStateChange={(e) => {
                setGridState(e.dataState);
            }}
        >
            <GridColumn field="companyName" title="Company Name" width={500} />
            <GridColumn field="acnOrArbn" title="ACN / ARBN" width={200} />
        </Grid>
    );
}
