// common
import * as Validation from "../../../../common/validation/ValidationModel";
// models
import { IRootViewModel } from "./models";

export default function validate(vm: IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    const feedbackResponse = vm.feedbackResponse;

    if (!feedbackResponse.assignmentGroup) {
        v.addError("FeedbackResponse.AssignmentGroup", "Assignment Group is required.");
    }
    if (feedbackResponse.assignmentGroup && !feedbackResponse.assignedUser) {
        v.addError("FeedbackResponse.AssignedUser", "Assigned User is required.");
    } else if (feedbackResponse.assignedUser && (!feedbackResponse.assignedUser.isActive || !feedbackResponse.assignedUser.canManage)) {
        v.addError("FeedbackResponse.AssignedUser", "Assigned User is not a valid user, and may be inactive or not have permission.");
    }
    if (feedbackResponse.isComplete && !feedbackResponse.completionComments) {
        v.addError("FeedbackResponse.CompletionComments", "Completion Comments are required when marked as completed.");
    }

    return v;
}
