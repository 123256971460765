// kendo
import { GridCellProps } from "@progress/kendo-react-grid";
// other
import * as BadgeHelper from "../../../common/badges/BadgeHelper";
import CustomGridCell from "../../../common/kendoGrid/CustomGridCell";

// current set of properties that can result in a badge being shown in the Information column of company / user roles grids
// at present applicable to userManagement only and as such have not polluted ICompanyRoleViewModel with this information
export interface ICompanyRoleInformationViewModel {
    isSecureUserBlocked: boolean;
    hasPendingRequest: boolean;
    isCompanyRevokedButVisible: boolean;
}

export function getInformationCell(props: GridCellProps) {
    const role = props.dataItem as ICompanyRoleInformationViewModel;

    return (
        <CustomGridCell gridCellProps={props}>
            {role.isSecureUserBlocked && BadgeHelper.getBlockedUserBadge()}
            {role.isCompanyRevokedButVisible && BadgeHelper.getCompanyThirtyDayWarningBadge()}
            {role.hasPendingRequest && BadgeHelper.getPendingReviewBadge()}
        </CustomGridCell>
    );
}
