// framework
import { clone, cloneDeep } from "lodash";
// kendo
import { orderBy } from "@progress/kendo-data-query";
// client
import * as Client from "../../../../api/Client";
// redux
import { IRequestAccessRoleViewModel, RequestAccessRoleViewModel } from "./requestAccessRoleViewModel";
// common
import { RoleCategoryEnum } from "../../../../common/roleGrid/RoleViewModel";
import { BaseJointAuthorityRoleRootViewModel, IJointAuthorityRoleRootViewModel } from "../../../common/JointAuthorityRoleRootViewModel";

export interface IQuestionsViewModel {
    selectedJointAuthority?: Client.SecureUserJointAuthorityDto;
    isJointAuthorityAdministratorRequest?: boolean;
    isJointAuthoritySignerRequest?: boolean;
    isJointAuthoritySignerPoaRequest?: boolean;
    isJointAuthorityStandardRoleRequest?: boolean;
}

export interface ISupportingDocumentViewModel {
    fileName?: string;
    content?: string;
}

export interface IJointAuthorityStandardRoleRequestViewModel {
    existingRoles?: IRequestAccessRoleViewModel;
    requestedRoles?: IRequestAccessRoleViewModel;
}

export interface IViewStateViewModel {
    showJointAuthorityList: boolean;
    showExistingAccessRequestWizard: boolean;
    showExistingFullAccessWizard: boolean;
    showExistingJointAuthorityAdministratorWizard: boolean;
    showExistingJointAuthorityStandardRolesWizard: boolean;
    showExistingJointAuthorityNewAdministratorWizard: boolean;
    showExistingJointAuthorityExistingAdministratorWizard: boolean;
    showJointAuthoritySignerWizard: boolean;
    showInvalidRequestWizard: boolean;
    showRequestForm: boolean;
}

export interface IRootViewModel extends IJointAuthorityRoleRootViewModel {
    reference: {
        jointAuthorities: Array<Client.SecureUserJointAuthorityDto>;
    };

    user: {
        questions: IQuestionsViewModel;
        jointAuthorityStandardRoleRequest: IJointAuthorityStandardRoleRequestViewModel;
        jaaAuthoritySupportingDocument: ISupportingDocumentViewModel;
        // csAuthoritySupportingDocument: ISupportingDocumentViewModel;
        // csPoaSupportingDocument: ISupportingDocumentViewModel;
        comments: string;
    };

    service: {
        id?: Client.SecureRequestGroupIdDto | undefined;
        statusMessages?: Client.StatusMessagesDto;
    };

    isDirty: boolean;

    viewState: IViewStateViewModel;

    // saga triggered state changes
    refreshReferenceData(jointAuthorities: Array<Client.SecureUserJointAuthorityDto>): IRootViewModel;
    refreshSecureRequestCreateResponse(statusMessages: Client.StatusMessagesDto, id: Client.SecureRequestGroupIdDto | undefined): IRootViewModel;

    // view triggered state changes
    refreshSelectedJointAuthorityChanged(jointAuthority?: Client.SecureUserJointAuthorityDto): IRootViewModel;
    refreshQuestionsUpdated(values: IQuestionsViewModel): IRootViewModel;
    refreshJointAuthorityStandardRoleRequestUpdated(values: IRequestAccessRoleViewModel): IRootViewModel;
    refreshJaaAuthoritySupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel;
    // todo nduja
    //refreshCsAuthoritySupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel;
    //refreshCsPoaSupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel;
    refreshCommentsUpdated(comments: string): IRootViewModel;
}

export class RootViewModel extends BaseJointAuthorityRoleRootViewModel implements IRootViewModel {
    constructor() {
        super();
        // initial state
        this.reference = {
            jointAuthorities: new Array<Client.SecureUserJointAuthorityDto>(),
        };
        this.user = {
            questions: {
                selectedJointAuthority: undefined,
                isJointAuthorityAdministratorRequest: undefined,
                isJointAuthoritySignerRequest: false, // todo nduja set to undefined once signer online
                isJointAuthorityStandardRoleRequest: undefined,
                isJointAuthoritySignerPoaRequest: false, // todo nduja set to undefined once signer online
            },
            jointAuthorityStandardRoleRequest: {},
            jaaAuthoritySupportingDocument: {},
            // csAuthoritySupportingDocument: {},
            // csPoaSupportingDocument: {},
            comments: "",
        };

        this.service = {
            id: undefined,
            statusMessages: undefined,
        };

        this.isDirty = false;
        this.viewState = {
            showJointAuthorityList: false,
            showExistingAccessRequestWizard: false,
            showExistingFullAccessWizard: false,
            showExistingJointAuthorityAdministratorWizard: false,
            showExistingJointAuthorityStandardRolesWizard: false,
            showExistingJointAuthorityNewAdministratorWizard: false,
            showExistingJointAuthorityExistingAdministratorWizard: false,
            showJointAuthoritySignerWizard: false,
            showInvalidRequestWizard: false,
            showRequestForm: false,
        };
    }

    reference: {
        jointAuthorities: Array<Client.SecureUserJointAuthorityDto>;
    };

    user: {
        questions: IQuestionsViewModel;
        jointAuthorityStandardRoleRequest: IJointAuthorityStandardRoleRequestViewModel;
        jaaAuthoritySupportingDocument: ISupportingDocumentViewModel;
        // csAuthoritySupportingDocument: ISupportingDocumentViewModel;
        // csPoaSupportingDocument: ISupportingDocumentViewModel;
        comments: string;
    };

    service: {
        id?: Client.SecureRequestGroupIdDto | undefined;
        statusMessages?: Client.StatusMessagesDto;
    };

    isDirty: boolean;
    viewState: IViewStateViewModel;

    // saga triggered state changes
    refreshReferenceData(jointAuthorities: Array<Client.SecureUserJointAuthorityDto>): IRootViewModel {
        const vm = this._clone();
        vm.reference.jointAuthorities = orderBy(jointAuthorities, [{ field: "jointAuthorityName", dir: "asc" }]);
        // set defaults
        vm.user.jointAuthorityStandardRoleRequest.existingRoles = new RequestAccessRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Existing);
        vm.user.jointAuthorityStandardRoleRequest.requestedRoles = new RequestAccessRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Requested);

        vm.isDirty = false; // reference data load is part of the initialisation phase

        updateFlags(vm); // update the flags based on the intial load, no selection may be necessary if the user already has a role

        return vm;
    }

    refreshSecureRequestCreateResponse(statusMessages: Client.StatusMessagesDto, id: Client.SecureRequestGroupIdDto | undefined): IRootViewModel {
        const vm = this._clone();
        vm.service.id = id;
        vm.service.statusMessages = statusMessages;
        vm.isDirty = !statusMessages!.isSuccess; // on success, set the vm as clean
        return vm;
    }

    // user triggered state changes
    refreshSelectedJointAuthorityChanged(selectedJointAuthority?: Client.SecureUserJointAuthorityDto): IRootViewModel {
        const vm = this._clone();
        vm.user.questions.selectedJointAuthority = selectedJointAuthority;

        if (selectedJointAuthority) {
            const existingJointAuthorityRoles = new RequestAccessRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Existing, selectedJointAuthority.activeRoles);
            vm.user.jointAuthorityStandardRoleRequest.existingRoles = existingJointAuthorityRoles;
            // if user already has a standard role they cannot request it, otherwise do not change existing form answers as per requirements
            if (selectedJointAuthority.activeRoles.length > 0) {
                const currentRequestedRoles = vm.user.jointAuthorityStandardRoleRequest.requestedRoles!.getDirectRoles();
                const allowedRequestedRoles = currentRequestedRoles.filter((r) => !existingJointAuthorityRoles.hasRole(r));
                vm.user.jointAuthorityStandardRoleRequest.requestedRoles = new RequestAccessRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Requested, allowedRequestedRoles);
            }

            if (!selectedJointAuthority.hasJointAuthorityAdministrator) {
                // if jointAuthority doesn't have an administrator, user must request admin and therefore cannot request general roles or signer
                vm.user.questions.isJointAuthorityAdministratorRequest = true;
                vm.user.questions.isJointAuthorityStandardRoleRequest = false;
                // vm.user.questions.isJointAuthoritySignerRequest = false;
                // vm.user.questions.isJointAuthoritySignerPoaRequest = false;
            } else {
                if (selectedJointAuthority.isJointAuthorityAdministrator) {
                    // if user already has administrator role they cannot request anything other than signer
                    vm.user.questions.isJointAuthorityAdministratorRequest = false;
                    vm.user.questions.isJointAuthorityStandardRoleRequest = false;
                } else {
                    // if user already has all general roles they cannot request more, otherwise reset existing question answers
                    vm.user.questions.isJointAuthorityAdministratorRequest = undefined;
                    vm.user.questions.isJointAuthorityStandardRoleRequest = selectedJointAuthority.hasAllGeneralRoles ? false : undefined;
                }

                // const isSignerRequest = selectedJointAuthority.isJointAuthoritySigner ? false : undefined;
                // vm.user.questions.isJointAuthoritySignerRequest = isSignerRequest;
                // vm.user.questions.isJointAuthoritySignerPoaRequest = isSignerRequest;
            }
        }

        updateFlags(vm);
        vm.isDirty = true;
        return vm;
    }

    refreshQuestionsUpdated(values: IQuestionsViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.questions = { ...vm.user.questions, ...values };

        if (values.isJointAuthorityAdministratorRequest === true) {
            // vm.user.questions.isJointAuthoritySignerRequest = false;
            // vm.user.questions.isJointAuthoritySignerPoaRequest = false;
            vm.user.questions.isJointAuthorityStandardRoleRequest = false;
        } else if (values.isJointAuthorityAdministratorRequest === false) {
            vm.user.questions.isJointAuthorityStandardRoleRequest = !vm.user.questions.selectedJointAuthority!.hasAllGeneralRoles;

            // Clear Signer and POA selection when CA selection is changed
            //     const isJointAuthoritySigner = vm.user.questions.selectedJointAuthority!.isJointAuthoritySigner ? false : undefined;
            //     vm.user.questions.isJointAuthoritySignerRequest = isJointAuthoritySigner;
            //     vm.user.questions.isJointAuthoritySignerPoaRequest = isJointAuthoritySigner;
            // } else if (values.isJointAuthoritySignerRequest === false) {
            //     // Clear POA selection when CS selection is changed
            //     vm.user.questions.isJointAuthoritySignerPoaRequest = undefined;
        }

        updateFlags(vm);
        vm.isDirty = true;
        return vm;
    }

    refreshJointAuthorityStandardRoleRequestUpdated(values: IRequestAccessRoleViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.jointAuthorityStandardRoleRequest.requestedRoles = values;
        vm.isDirty = true;
        return vm;
    }

    refreshJaaAuthoritySupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.jaaAuthoritySupportingDocument = value;
        vm.isDirty = true;
        return vm;
    }

    // refreshCsAuthoritySupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel {
    //     const vm = this._clone();
    //     vm.user.csAuthoritySupportingDocument = value;
    //     vm.isDirty = true;
    //     return vm;
    // }

    // refreshCsPoaSupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel {
    //     const vm = this._clone();
    //     vm.user.csPoaSupportingDocument = value;
    //     vm.isDirty = true;
    //     return vm;
    // }

    refreshCommentsUpdated(comments: string): IRootViewModel {
        const vm = this._clone();
        vm.user.comments = comments;
        vm.isDirty = true;
        return vm;
    }

    // private implementation
    // ----------------------
    _clone(): RootViewModel {
        const vm = clone(this);
        vm.reference = clone(this.reference);
        vm.user = cloneDeep(this.user);
        vm.service = clone(this.service);
        vm.viewState = clone(this.viewState);
        return vm;
    }
}

// private
function updateFlags(vm: IRootViewModel): void {
    const questions = vm.user.questions;

    vm.viewState = {
        showJointAuthorityList: false,
        showExistingAccessRequestWizard: false,
        showExistingFullAccessWizard: false,
        showExistingJointAuthorityAdministratorWizard: false,
        showExistingJointAuthorityStandardRolesWizard: false,
        showExistingJointAuthorityNewAdministratorWizard: false,
        showExistingJointAuthorityExistingAdministratorWizard: false,
        showJointAuthoritySignerWizard: false,
        showInvalidRequestWizard: false,
        showRequestForm: false,
    };

    // determine if secure user has a role or is pending a role with a joint authority already
    const existingRelationship: Client.SecureUserJointAuthorityDto | undefined =
        vm.reference.jointAuthorities.find((j) => j.activeRoles.some((r) => r)) || vm.reference.jointAuthorities.find((r) => r.hasPendingRequest);

    let selectedJointAuthority = undefined;

    // if an existing/pending relationship is detected, don't show the joint authority list
    if (existingRelationship) {
        // update the existing roles for the role grid
        const existingRelationshipRoles = new RequestAccessRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Existing, existingRelationship.activeRoles);
        vm.user.jointAuthorityStandardRoleRequest.existingRoles = existingRelationshipRoles;
        // don't show the joint authority picker
        vm.viewState.showJointAuthorityList = false;
        // set the selected joint authority
        selectedJointAuthority = existingRelationship;
        vm.user.questions.selectedJointAuthority = existingRelationship;
    } else {
        // no existing/pending relationships, let the user pick a new one
        selectedJointAuthority = questions.selectedJointAuthority;
        vm.viewState.showJointAuthorityList = true;
    }

    // existing joint authority
    if (selectedJointAuthority) {
        if (selectedJointAuthority.hasPendingRequest) {
            // pending request - cannot proceed
            vm.viewState.showExistingAccessRequestWizard = true;
            return;
        } else if (!selectedJointAuthority.hasJointAuthorityAdministrator) {
            // no JAA - must select new JAA
            vm.viewState.showExistingJointAuthorityNewAdministratorWizard = true;
        } else {
            if (selectedJointAuthority.hasAllSpecialRoles) {
                // full access - cannot proceed
                vm.viewState.showExistingFullAccessWizard = true;
                return;
            } else if (selectedJointAuthority.isJointAuthorityAdministrator) {
                // JAA access - can only select JAS
                vm.viewState.showExistingJointAuthorityAdministratorWizard = true;
            } else {
                // all general roles - can only select JAA or JAS
                vm.viewState.showExistingJointAuthorityStandardRolesWizard = selectedJointAuthority.hasAllGeneralRoles;
                // show JAA access wizard
                vm.viewState.showExistingJointAuthorityExistingAdministratorWizard = true;
            }
        }
    }

    // break if no JAA selection has been made
    if (questions.isJointAuthorityAdministratorRequest === undefined) return;
    // JAS selection is only applicable if the user is not already a signer and if the request is not a JAA request
    if (questions.isJointAuthorityAdministratorRequest === false && selectedJointAuthority?.isJointAuthoritySigner === false) {
        vm.viewState.showJointAuthoritySignerWizard = true;
    }
    // break if no JAS selection has been made
    if (questions.isJointAuthoritySignerRequest === undefined) return;

    // reveal form
    vm.viewState.showInvalidRequestWizard =
        questions.isJointAuthorityAdministratorRequest === false && questions.isJointAuthoritySignerRequest === false && questions.isJointAuthorityStandardRoleRequest === false;
    vm.viewState.showRequestForm =
        questions.isJointAuthorityAdministratorRequest === true || questions.isJointAuthoritySignerRequest === true || questions.isJointAuthorityStandardRoleRequest === true;
}
