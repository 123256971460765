// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    well: Client.GetGeoscienceAustraliaWellsBoreholesWellDetailsResponseDto | undefined;

    refreshWellDetails(response: Client.GetGeoscienceAustraliaWellsBoreholesWellDetailsResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.well = undefined;
    }

    public well: Client.GetGeoscienceAustraliaWellsBoreholesWellDetailsResponseDto | undefined;

    public refreshWellDetails(response: Client.GetGeoscienceAustraliaWellsBoreholesWellDetailsResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.well = response;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.well = clone(this.well);
        return vm;
    }
}
