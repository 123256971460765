// framework
import { useState } from "react";
// kendo
import { Grid, GridColumn, GridSelectionChangeEvent, GridHeaderSelectionChangeEvent } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// redux
import { IFinancialNotificationRequiringPaymentViewModel } from "../../models/models";
// common
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import * as FinanceHelpers from "../../../../../common/finance/FinanceHelpers";
import FinancialNotificationLinkController from "../../../../../common/links/FinancialNotificationLinkController";
import TitleLinkController from "../../../../../common/links/TitleLinkController";
import ApplicationLinkController from "../../../../../common/links/ApplicationLinkController";
import DraftApplicationLinkController from "../../../../../common/links/DraftApplicationLinkController";
import { createGridColumnFilterMenu } from "../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
import CustomGridCell from "../../../../../common/kendoGrid/CustomGridCell";
import { AdministrationTypeEnum } from "../../../../../api/Client";
import LicenceLinkController from "../../../../../common/links/LicenceLinkController";

export default function RequiringPaymentView(props: {
    vm: Array<IFinancialNotificationRequiringPaymentViewModel>;
    onSelectionChange: (financialNotifications: Array<IFinancialNotificationRequiringPaymentViewModel>) => void;
}): React.ReactElement {
    const fns = props.vm;

    const initialGridState: State = {
        sort: [{ field: "financialNotificationDueDate", dir: "asc" }],
        skip: 0,
        take: 20,
    };
    const [gridState, setGridState] = useState(initialGridState);

    // selectable
    // - select one
    function onSelectionChange(e: GridSelectionChangeEvent): void {
        const gridItem = e.dataItem as IFinancialNotificationRequiringPaymentViewModel;
        if (!gridItem) return;

        const updatedFns = fns.map((fn) => (fn.financialNotificationLink.id === gridItem.financialNotificationLink.id ? { ...gridItem, isSelected: !gridItem.isSelected } : fn));

        props.onSelectionChange(updatedFns);
    }

    // - select all
    function onHeaderSelectionChange(e: GridHeaderSelectionChangeEvent): void {
        const headerCheckbox = e.syntheticEvent.target as HTMLInputElement;

        const updatedFns = fns.map((fn) => ({ ...fn, isSelected: headerCheckbox.checked }));

        props.onSelectionChange(updatedFns);
    }

    return (
        <>
            <h2>Requiring Payment</h2>
            <Grid
                pageable
                sortable
                resizable
                navigatable
                selectedField="isSelected"
                onSelectionChange={onSelectionChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
                data={process(fns, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn field="isSelected" title="Select" width={100} headerSelectionValue={fns.length > 0 && fns.every((fn) => fn.isSelected)} />
                <GridColumn
                    field="financialNotificationDueDate"
                    title="Due Date"
                    width={120}
                    filter="date"
                    columnMenu={createGridColumnFilterMenu}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} style={FinanceHelpers.getDueDateStyle(props.dataItem)}>
                            {GlobalHelpers.toNoptaDateString(props.dataItem.financialNotificationDueDate)}
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    field="financialNotificationLink.code"
                    title="Notification ID"
                    width={140}
                    filter="text"
                    columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, fns)}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <FinancialNotificationLinkController linkDto={props.dataItem.financialNotificationLink} secureEntityType="Company" />
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    field="financialNotificationIssueDatetime"
                    title="Issue Date"
                    width={140}
                    format={GlobalHelpers.NoptaDateFormatForKendo}
                    filter="date"
                    columnMenu={createGridColumnFilterMenu}
                />
                <GridColumn
                    field="titleLicenceNumber"
                    title="Title/Licence Number"
                    width={200}
                    filter="text"
                    columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, fns)}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.administrationType === AdministrationTypeEnum.Opggs && props.dataItem.titleLink && (
                                <TitleLinkController linkDto={props.dataItem.titleLink} secureEntityType="Company" />
                            )}
                            {props.dataItem.administrationType === AdministrationTypeEnum.Oei && props.dataItem.licenceLink && (
                                <LicenceLinkController linkDto={props.dataItem.licenceLink} secureEntityType="Company" />
                            )}
                        </CustomGridCell>
                    )}
                />
                <GridColumn field="companyName" title="Company" filter="text" width={300} columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, fns)} />
                <GridColumn field="financialNotificationType" title="Notification Type" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, fns)} />
                <GridColumn field="anniversaryDate" title="Anniversary Date" width={170} format={GlobalHelpers.NoptaDateFormatForKendo} filter="date" columnMenu={createGridColumnFilterMenu} />
                <GridColumn
                    field="trackingNumberDisplay"
                    title="Tracking Number"
                    width={170}
                    filter="text"
                    columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, fns)}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.applicationLink ? (
                                <ApplicationLinkController linkDto={props.dataItem.applicationLink} secureEntityType="Company" />
                            ) : props.dataItem.draftApplicationLink ? (
                                <DraftApplicationLinkController linkDto={props.dataItem.draftApplicationLink} secureEntityType="Company" />
                            ) : (
                                props.dataItem.trackingNumberDisplay
                            )}
                        </CustomGridCell>
                    )}
                />
                <GridColumn field="totalAmount" title="Total Amount" filter="numeric" width={150} format="{0:c}" className="text-end" columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="outstandingAmount" title="Balance" filter="numeric" width={140} format="{0:c}" className="text-end" columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="financialNotificationStatus" title="Notification Status" width={180} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, fns)} />
            </Grid>
        </>
    );
}
