// framework
import { useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
// views
import NoAssociatedJointAuthoritiesTileView from "./views/NoAssociatedJointAuthoritiesTileView";
import TitlesTileView from "./views/TitlesTileView";
import ApplicationsTileView from "./views/ApplicationsTileView";

interface IControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IControllerProps): React.ReactElement {
    const vm = props.rootViewModel;
    const selectedLhsNavItem = Level1ItemTypeEnum.JointAuthority_Home_Dashboard;

    // on mount
    useEffect(() => {
        props.initialiseRequested();
        return function () {
            props.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BodyLayout
            title="Dashboard"
            selectedLhsNavItem={selectedLhsNavItem}
            onReloadRequested={() => {
                props.initialiseRequested();
            }}
        >
            {vm.showNoAssociatedJointAuthoritiesTile && <NoAssociatedJointAuthoritiesTileView />}

            <div className="mb-4">
                {vm.showApplicationsTile && <ApplicationsTileView vm={vm.applications} isJaApplications={true} />}
                {vm.showApplicationsTile && <ApplicationsTileView vm={vm.applications} isJaApplications={false} />}
                {vm.showTitlesTile && <TitlesTileView vm={vm.titles} />}
            </div>
            <p></p>
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.JointAuthority_Home_Dashboard }), Actions.actionFactory)(Controller);
