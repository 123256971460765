// framework
import { Link } from "react-router-dom";
// redux
import * as Models from "./models/models";
// common
import * as companyRouteHelper from "../../../companyRouteHelper";
// views
import ProgressChecklistView from "./ProgressChecklistView";
import ProgressStepperView from "./ProgressStepperView";

export default function HeaderView(props: { vm: Models.IDraftApplicationRootViewModel }) {
    const state = props.vm.state;
    const header = props.vm.header;

    // Note: submitted state display value must be calculated as a submitted draft application is no longer visible to secure users i.e. data cannot be refreshed from service
    const stateDisplay = state.viewState === Models.ApplicationViewStateEnum.Submitted ? "Submitted" : header.stateDisplay;

    return (
        <>
            <div className="row">
                <div className="col-lg-8 align-self-center">
                    <ProgressStepperView viewState={state.viewState!} />
                </div>
                <div className="col-lg-4">
                    <ProgressChecklistView vm={header.checklist} />
                </div>
            </div>
            <div className="d-flex flex-row flex-wrap">
                {header.stateDisplay && (
                    <div className="d-flex flex-row me-2 mb-2">
                        <span className="label me-2" style={{ fontWeight: "bold" }}>
                            Status:
                        </span>
                        <span>Draft - {stateDisplay}</span>
                    </div>
                )}
                {header.titles && header.titles.length >= 1 && (
                    <div className="d-flex flex-row me-2 mb-2">
                        <span className="label me-2" style={{ fontWeight: "bold" }}>
                            {header.titles.length === 1 ? "Title:" : "Titles:"}
                        </span>
                        <span>
                            {header.titles.map((t, i) => [
                                i > 0 && ", ",
                                <Link key={i} to={companyRouteHelper.titleDetails(t.titleId)}>
                                    {t.titleNumber}
                                </Link>,
                            ])}
                        </span>
                    </div>
                )}
            </div>
        </>
    );
}
