// framework
import { clone, cloneDeep } from "lodash";
// client
import * as Client from "../../../api/Client";
// redux
import { IRegistrationRoleViewModel, RegistrationRoleViewModel } from "./registrationRoleViewModel";
// common
import { BaseGeoscienceAustraliaRoleRootViewModel, IGeoscienceAustraliaRoleRootViewModel } from "../../common/GeoscienceAustraliaRoleRootViewModel";
import { RoleCategoryEnum } from "../../../common/roleGrid/RoleViewModel";

export interface IRootViewModel extends IGeoscienceAustraliaRoleRootViewModel {
    user: {
        questions: IQuestionsViewModel;
        newUserRequest: INewUserRequestViewModel;
        geoscienceAustraliaStandardRoleRequest: IGeoscienceAustraliaStandardRoleRequestViewModel;
        gaaAuthoritySupportingDocument: ISupportingDocumentViewModel;
        isUserAgreementAcknowledged: boolean;
        comments: string;
    };

    service: {
        referenceNumber?: string;
        statusMessages?: Client.StatusMessagesDto;
    };

    isDirty: boolean;

    viewState: IViewStateViewModel;

    // saga triggered state changes
    refreshSecureRequestCreateResponse(statusMessages: Client.StatusMessagesDto, referenceNumber: string | undefined): IRootViewModel;
    // view triggered state changes
    refreshGeoscienceAustraliaDetails(hasGeoscienceAustraliaAdministrator: boolean): IRootViewModel;
    refreshQuestionsUpdated(values: IQuestionsViewModel): IRootViewModel;
    refreshNewUserRequestUpdated(values: INewUserRequestViewModel): IRootViewModel;
    refreshEmailVerificationUpdated(values: IEmailVerificationViewModel): IRootViewModel;
    refreshGarStandardRoleRequestUpdated(values: IRegistrationRoleViewModel): IRootViewModel;
    refreshGaaAuthoritySupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel;
    refreshUserAgreementUpdated(isUserAgreementAcknowledged: boolean): IRootViewModel;
    refreshCommentsUpdated(comments: string): IRootViewModel;
}

export interface IQuestionsViewModel {
    hasGeoscienceAustraliaAdministrator?: boolean;
    isGeoscienceAustraliaAdministratorRequest?: boolean;
}

export interface INewUserRequestViewModel {
    email?: string;
    emailVerification: IEmailVerificationViewModel;
    firstName?: string;
    lastName?: string;
    contactNumber?: string;
    position?: string;
    password?: string;
    passwordConfirmation?: string;
}

export interface IEmailVerificationViewModel {
    isVisible: boolean;
    token?: string;
    verificationCode?: string;
    status: Client.EmailVerificationStatusEnum;
    requestEmailVerificationStatusMessages?: Client.StatusMessagesDto;
    verifyEmailVerificationStatusMessages?: Client.StatusMessagesDto;
}

export interface ISupportingDocumentViewModel {
    fileName?: string;
    content?: string;
}

export interface IGeoscienceAustraliaStandardRoleRequestViewModel {
    requestedRoles?: IRegistrationRoleViewModel;
}

export interface IViewStateViewModel {
    showGeoscienceAustraliaNewAdministratorWizard: boolean;
    showGeoscienceAustraliaExistingAdministratorWizard: boolean;
    showRequestForm: boolean;
}

export class RootViewModel extends BaseGeoscienceAustraliaRoleRootViewModel implements IRootViewModel {
    constructor() {
        super();

        this.user = {
            questions: {
                hasGeoscienceAustraliaAdministrator: undefined,
                isGeoscienceAustraliaAdministratorRequest: undefined,
            },
            newUserRequest: { emailVerification: { isVisible: false, status: Client.EmailVerificationStatusEnum.Unverified } },
            geoscienceAustraliaStandardRoleRequest: {},
            gaaAuthoritySupportingDocument: {},
            comments: "",
            isUserAgreementAcknowledged: false,
        };

        this.service = {
            referenceNumber: undefined,
            statusMessages: undefined,
        };

        this.isDirty = false;
        this.viewState = {
            showGeoscienceAustraliaNewAdministratorWizard: false,
            showGeoscienceAustraliaExistingAdministratorWizard: false,
            showRequestForm: false,
        };
    }

    user: {
        questions: IQuestionsViewModel;
        newUserRequest: INewUserRequestViewModel;
        geoscienceAustraliaStandardRoleRequest: IGeoscienceAustraliaStandardRoleRequestViewModel;
        gaaAuthoritySupportingDocument: ISupportingDocumentViewModel;
        comments: string;
        isUserAgreementAcknowledged: boolean;
    };

    service: {
        referenceNumber?: string;
        statusMessages?: Client.StatusMessagesDto;
    };

    isDirty: boolean;
    viewState: IViewStateViewModel;

    // saga triggered state changes
    refreshGeoscienceAustraliaDetails(hasGeoscienceAustraliaAdministrator: boolean): IRootViewModel {
        const vm = this._clone();
        vm.user.questions.hasGeoscienceAustraliaAdministrator = hasGeoscienceAustraliaAdministrator;
        if (!hasGeoscienceAustraliaAdministrator) vm.user.questions.isGeoscienceAustraliaAdministratorRequest = true;
        // set defaults
        vm.user.geoscienceAustraliaStandardRoleRequest.requestedRoles = new RegistrationRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Requested);

        updateFlags(vm);
        vm.isDirty = false; // reference data load is part of the initialisation phase
        return vm;
    }

    refreshSecureRequestCreateResponse(statusMessages: Client.StatusMessagesDto, referenceNumber: string | undefined): IRootViewModel {
        const vm = this._clone();
        vm.service.referenceNumber = referenceNumber;
        vm.service.statusMessages = statusMessages;

        // blow away the email verification status if the service fails
        // otherwise, they're blocked from trying to verify again
        // which is awkward, given it can fail because the token has expired
        if (!statusMessages.isSuccess) vm.user.newUserRequest.emailVerification.status = Client.EmailVerificationStatusEnum.Unverified;

        vm.isDirty = !statusMessages!.isSuccess; // on success, set the vm as clean
        return vm;
    }

    // user triggered state changes
    refreshQuestionsUpdated(values: IQuestionsViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.questions = { ...vm.user.questions, ...values };

        updateFlags(vm);
        vm.isDirty = true;
        return vm;
    }

    refreshNewUserRequestUpdated(values: INewUserRequestViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.newUserRequest = values;
        vm.isDirty = true;
        return vm;
    }

    refreshEmailVerificationUpdated(values: IEmailVerificationViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.newUserRequest.emailVerification = { ...values };
        vm.isDirty = true;
        return vm;
    }

    refreshGarStandardRoleRequestUpdated(values: IRegistrationRoleViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.geoscienceAustraliaStandardRoleRequest.requestedRoles = values;
        vm.isDirty = true;
        return vm;
    }

    refreshGaaAuthoritySupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.gaaAuthoritySupportingDocument = value;
        vm.isDirty = true;
        return vm;
    }

    refreshUserAgreementUpdated(isUserAgreementAcknowledged: boolean): IRootViewModel {
        const vm = this._clone();
        vm.user.isUserAgreementAcknowledged = isUserAgreementAcknowledged;
        vm.isDirty = true;
        return vm;
    }

    refreshCommentsUpdated(comments: string): IRootViewModel {
        const vm = this._clone();
        vm.user.comments = comments;
        vm.isDirty = true;
        return vm;
    }

    // private implementation
    // ----------------------
    _clone(): RootViewModel {
        const vm = clone(this);

        vm.user = cloneDeep(this.user);
        vm.service = clone(this.service);
        vm.viewState = clone(this.viewState);

        return vm;
    }
}

// private
function updateFlags(vm: IRootViewModel): void {
    const questions = vm.user.questions;
    vm.viewState = {
        showGeoscienceAustraliaNewAdministratorWizard: false,
        showGeoscienceAustraliaExistingAdministratorWizard: false,
        showRequestForm: false,
    };

    // already has an administrator
    if (questions.hasGeoscienceAustraliaAdministrator) {
        vm.viewState.showGeoscienceAustraliaExistingAdministratorWizard = true;
    } else {
        vm.viewState.showGeoscienceAustraliaNewAdministratorWizard = true;
    }

    // break if no GAA selection has been made
    if (questions.isGeoscienceAustraliaAdministratorRequest === undefined) return;

    // reveal form
    vm.viewState.showRequestForm = true;
}
