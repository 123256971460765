// framework
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
// views
import HeaderView from "./views/HeaderView";
import TabsView from "./views/TabsView";

interface IControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

interface IRouteParameters {
    id: string;
}

function Controller(props: IControllerProps) {
    const vm = props.rootViewModel;
    const header = vm.header;
    const actions: Actions.IActionFactory = props;
    const params = useParams<IRouteParameters>();
    const applicationId = params.id;

    // this is used to trigger smart components to refresh when the refresh button is manually pressed
    const [lastRefreshedDatetime, setLastRefreshedDatetime] = useState<Date | undefined>(undefined);

    // on mount
    // - initial title load
    useEffect(() => {
        actions.initialiseRequested(applicationId);
        return function () {
            actions.clearRequested();
        };
    }, [applicationId]); // eslint-disable-line react-hooks/exhaustive-deps

    let pageTitle = "Application Details";
    if (header.applicationTypeName) pageTitle = header.applicationTypeName;
    if (header.trackingNumber) pageTitle += ` (${header.trackingNumber})`;

    return (
        <BodyLayout
            title={pageTitle}
            selectedLhsNavItem={Level1ItemTypeEnum.JointAuthority_Application_ApplicationList}
            onReloadRequested={() => {
                props.initialiseRequested(applicationId);
                setLastRefreshedDatetime(new Date());
            }}
        >
            {vm.viewState === Models.ViewStateEnum.View && (
                <>
                    <HeaderView vm={vm.header} />
                    <TabsView vm={vm} lastRefreshedDatetime={lastRefreshedDatetime} />
                </>
            )}
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.JointAuthority_Opggs_ApplicationDetails }), Actions.actionFactory)(Controller);
