// Iafl
import IaflController from "./iafl/components/Controller";

import { IRouteItem, RouteBuilder } from "../../../infrastructure/routeItem";

const draftApplicationDetailsRouteBuilder: RouteBuilder = (isSignedIn: boolean) => {
    let routes = new Array<IRouteItem>();

    // not authenticated? don't register routes
    if (!isSignedIn) return routes;

    routes.push({ path: "/Company/Oei/DraftApplicationDetails/Iafl/:id", exact: true, element: <IaflController /> });

    return routes;
};

export default draftApplicationDetailsRouteBuilder;
