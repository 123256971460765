// api
import * as Client from "../../../../../api/Client";
// redux
import * as Models from "../../models/models";

function TitleholdersView(props: { vm: Models.IRootViewModel }) {
    const titleholders = props.vm.details?.titleholders ?? new Array<Client.GetCompanyOpggsTitleDetailsTitleholderDto>();
    const sortedTitleholders = [...titleholders].sort(function (t1, t2) {
        // sort by operator
        if (t1.isOperator > t2.isOperator) return -1;
        if (t1.isOperator < t2.isOperator) return 1;
        // then by percentage ownership descending
        if (t1.percentageOwnership > t2.percentageOwnership) return -1;
        if (t1.percentageOwnership < t2.percentageOwnership) return 1;
        // then by company name
        if (t1.companyName && (!t2.companyName || t1.companyName < t2.companyName)) return -1;
        if (t2.companyName && (!t1.companyName || t2.companyName < t1.companyName)) return 1;

        return 0;
    });

    return (
        <div className="card mb-2">
            <div className="card-body">
                <h2 className="card-title">Titleholders</h2>
                {sortedTitleholders && (
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <caption>* Contact Titleholder</caption>
                            <thead className="table-light">
                                <tr>
                                    <th scope="col">Company Name</th>
                                    <th scope="col">ACN / ARBN</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedTitleholders.map((titleholder) => (
                                    <tr key={titleholder.companyId}>
                                        <td>
                                            {titleholder.companyName}
                                            {titleholder.isOperator && "*"}
                                        </td>
                                        <td>{titleholder.acnOrArbn}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}

export default TitleholdersView;
