// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
import * as Common from "../../../../../common/draftApplicationDetails/components/models/models";
// common
import CommonTitleholderForeignInvestmentView from "../../../common/components/TitleholderForeignInvestmentView";

export default function TitleholderForeignInvestmentView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; lastRefreshedDatetime: Date | undefined }) {
    const vm = props.vm;
    const a = props.actions;
    const state = vm.state;

    function onSaveTitleholderForeignInvestment() {
        a.initialiseRequested(state.id!);
    }

    function onEditRequested(task: Common.ApplicationTaskEnum) {
        a.viewChanged(vm.setInEditTask(task));
    }

    function onCancelRequested() {
        a.initialiseRequested(state.id!);
    }

    return (
        <CommonTitleholderForeignInvestmentView
            id={state.id!}
            versionNumber={state.versionNumber!}
            canManage={state.canManageApplicantOrPartnerCompany}
            validationState={state.validationState}
            viewState={state.viewState!}
            inEditTask={state.inEditTask}
            onEdit={() => onEditRequested(Common.ApplicationTaskEnum.TitleholderForeignInvestment)}
            onSave={onSaveTitleholderForeignInvestment}
            onCancel={onCancelRequested}
            lastRefreshedDatetime={props.lastRefreshedDatetime}
        />
    );
}
