// framework
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { Dialog } from "@progress/kendo-react-dialogs";
// api
import { GetGeoscienceAustraliaReprocessingsProjectsReprocessingTitlesResponseTitleDto } from "../../../../../../api/Client";
// common
import BootstrapFormsStyles from "../../../../../../common/forms/BootstrapForms.module.css";
import { ControlClass } from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import ExternalLinkView from "../../../../../../common/externalLink/ExternalLinkView";
import * as shellRouteHelper from "../../../../../../shell/shellRouteHelper";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";

export default function TitlesListGridView(props: { reprocessingId: number; titles: GetGeoscienceAustraliaReprocessingsProjectsReprocessingTitlesResponseTitleDto[] }): JSX.Element {
    const { reprocessingId, titles } = props;

    const [dialogComments, setDialogComments] = useState("");
    const [showCommentsDialog, setShowCommentsDialog] = useState(false);

    const initialState: State = {
        sort: [
            { field: "projectIdentifier", dir: "desc" },
            { field: "titleNumber", dir: "asc" },
        ],
    };

    const [gridState, setGridState] = useState(initialState);
    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [titles]); // eslint-disable-line react-hooks/exhaustive-deps

    function onShowCommentsDialog(comments: string) {
        setDialogComments(comments);
        setShowCommentsDialog(true);
    }
    function onCloseCommentsDialog() {
        setShowCommentsDialog(false);
    }

    return (
        <>
            <h2 className="mt-2">Title Relationships</h2>
            <Grid
                resizable
                sortable
                data={process(titles, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn
                    field="projectIdentifier"
                    title="Project ID"
                    width={200}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <Link to={geoscienceAustraliaRouteHelper.reprocessingProjectTitles(reprocessingId, props.dataItem.projectId)} title="Click to view Project details.">
                                {props.dataItem.projectIdentifier}
                            </Link>
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    field="titleNumber"
                    title="Title"
                    width={150}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.titleId ? (
                                <ExternalLinkView href={shellRouteHelper.publicPortalTitleDetails(props.dataItem.titleNumber)}>{props.dataItem.titleNumber}</ExternalLinkView>
                            ) : (
                                props.dataItem.titleNumber
                            )}
                        </CustomGridCell>
                    )}
                />
                <GridColumn field="titlePermitYearEndDate" title="Permit Year End Date" width={190} format={GlobalHelpers.NoptaDateFormatForKendo} />
                <GridColumn field="titleType" title="Title Type" width={300} />
                <GridColumn field="titleRelationshipType" title="Relationship" width={300} />
                <GridColumn
                    title="Comments"
                    width={90}
                    cell={(props: GridCellProps) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            {props.dataItem.comments && (
                                <button className="btn btn-secondary btn-sm" type="button" title="Click to view Comments." onClick={() => onShowCommentsDialog(props.dataItem.comments)}>
                                    View
                                </button>
                            )}
                        </CustomGridCell>
                    )}
                />
            </Grid>
            {showCommentsDialog && (
                <Dialog title={"Comments"} width={600} onClose={onCloseCommentsDialog}>
                    <div>
                        <span className={`${ControlClass} ${BootstrapFormsStyles.readonlyTextArea}`}>{dialogComments}</span>
                    </div>
                </Dialog>
            )}
        </>
    );
}
