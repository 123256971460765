import { IRootViewModel } from "../../models/models";
import ReportAttachments from "../../../../../../common/reportAttachments/ReportAttachmentsControl";
import { GetGeoscienceAustraliaReportAttachmentsItemDto } from "../../../../../../api/Client";
import LinksView, { LinkDetail } from "../../../../../common/links/LinksView";
import * as GeoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";

interface IProps {
    vm: IRootViewModel;
    onEdit: (reportId: number, reportVersionNumber: number, reportAttachmentId: number, name: string, documentLink: string, fileName: string) => void;
    onDelete: (reportId: number, reportVersionNumber: number, reportAttachmentId: number) => void;
    onBatchDownload?: (reportAttachments: GetGeoscienceAustraliaReportAttachmentsItemDto[]) => void;
}

export default function ReprocessingReportAttachmentsView(props: IProps): JSX.Element {
    const { vm, onEdit, onDelete, onBatchDownload } = props;

    return (
        <>
            <LinksView
                title="Project(s)"
                canViewLink={true}
                linkTooltip="Click to view Project Attachments."
                links={vm.projectIdentifiers.map(
                    (identifier) => new LinkDetail(identifier.name, GeoscienceAustraliaRouteHelper.reprocessingProjectAttachments(vm.reprocessingId!, identifier.projectId))
                )}
            />
            <ReportAttachments attachments={vm.attachments} statusMessages={vm.statusMessages} onEdit={onEdit} onDelete={onDelete} onBatchDownload={onBatchDownload}></ReportAttachments>
        </>
    );
}
