// react
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// redux
import store from "./infrastructure/reduxConfigureStore";
import { Provider as ReduxProvider } from "react-redux";

// bootstrap/kendo
import "./index.scss";

// app insights
import TelemetryProvider from "./infrastructure/telemetry-provider";

// localisation/internationalisation
import KendoIntlProvider from "./infrastructure/KendoIntlProvider";

// local
import "./index.css";
import App from "./App";

// google analytics
import * as AnalyticsHelper from "./infrastructure/AnalyticsHelper";
AnalyticsHelper.initialise(process.env.REACT_APP_GOOGLEANALYTICS_MEASUREMENTID);

const root = createRoot(document.getElementById("root")!);

root.render(
    <ReduxProvider store={store}>
        <BrowserRouter>
            <TelemetryProvider connectionString={process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING}>
                <KendoIntlProvider>
                    <App />
                </KendoIntlProvider>
            </TelemetryProvider>
        </BrowserRouter>
    </ReduxProvider>
);
