import * as Client from "../../api/Client";
import * as GlobalHelpers from "../GlobalHelpers";
import { orderBy } from "@progress/kendo-data-query";

function SimpleAuditEventsView(props: { className?: string | undefined; simpleAuditEvents: Client.SecurePortalSimpleAuditEventsDto | undefined }) {
    if (!props.simpleAuditEvents) return <></>;
    if (!props.simpleAuditEvents.events || props.simpleAuditEvents.events.length === 0) return <></>;

    const events = orderBy(props.simpleAuditEvents!.events!, [{ field: "timestamp", dir: "asc" }]);

    return (
        <div className={props.className}>
            <small>
                {events.map((e, i) => {
                    const timestamp = GlobalHelpers.toNoptaDatetimeString(e.timestamp);
                    let message;
                    if (!e.identity) message = `${e.action} on ${timestamp} (AWST)`;
                    else message = `${e.action} by ${e.identity} on ${timestamp} (AWST)`;
                    return (
                        <div key={i.toString()}>
                            <span>{message}</span>
                            <br />
                        </div>
                    );
                })}
            </small>
        </div>
    );
}

export default SimpleAuditEventsView;
