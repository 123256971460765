// redux
import * as Models from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as companyRouteHelper from "../../../../../companyRouteHelper";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";

export default function EottlrDetailsReadonlyView(props: { vm: Models.IEottlrDetailsViewModel }) {
    const vm = props.vm;

    return (
        <>
            <Forms.Group>
                <Forms.Link label="Title Number" linkText={vm.titleNumber} linkTo={vm.titleId && companyRouteHelper.titleDetails(vm.titleId)} />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="Expiry Date" id="expiryDateField" value={GlobalHelpers.toNoptaDateString(vm.titleExpiryDate)} />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="Length of longer period requested" id="extensionDateField" value={GlobalHelpers.toNoptaDateString(vm.extensionDate)} />
            </Forms.Group>
        </>
    );
}
