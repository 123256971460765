import React from "react";
// redux
import * as Models from "../../models/models";
// common
import SimpleAlertView from "../../../../../../common/simpleAlerts/SimpleAlertView";

export default function WarningView(props: { vm: Models.IEottlrDetailsViewModel }): React.ReactElement {
    const { vm } = props;

    return vm.isWarningPeriod ? (
        <SimpleAlertView alertType="warning" dismissable={false} message="The 'Length of longer period requested' can be up to but no later than 14 days prior to the expiry date." />
    ) : (
        <></>
    );
}
