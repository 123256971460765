import * as Client from "../../../../api/Client";
import { clone } from "lodash";

export interface IRootViewModel {
    financialNotifications: Array<Client.CompanyFinanceFnListDetailDto>;
    refreshSaga(response: Client.GetCompanyFinancePaymentHistoryListResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.financialNotifications = Array<Client.CompanyFinanceFnListDetailDto>();
    }

    public financialNotifications: Array<Client.CompanyFinanceFnListDetailDto>;

    public refreshSaga(response: Client.GetCompanyFinancePaymentHistoryListResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.financialNotifications = response.financialNotifications;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
