// redux
import { call, put, select } from "redux-saga/effects";
import { ICompanyReduxState } from "../../../companyReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IRegistrationRoleViewModel } from "../../models/registrationRoleViewModel";
// api
import * as ClientFactory from "../../../../api/clientFactory";
import * as CallHelper from "../../../../api/callHelper";
import * as Client from "../../../../api/Client";
// common
import * as LogHelper from "../../../../common/LogHelper";
import * as shellHelper from "../../../../common/shell/shellHelper";

const getRootViewModel = (state: ICompanyReduxState) => state.Company_Home_Registration;

export default function* createSecureRequest() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const actions = Actions.actionFactory;

    try {
        yield put(shellHelper.createSetBusyAction());

        let response: IResponse;
        const questions = vm.user.questions;

        if (questions.isNewCompanyRequest) {
            response = yield call(async () => await createNuNcCaSecureRequest(vm));
        } else if (questions.isCompanyAdministratorRequest) {
            response = yield call(async () => await createNuCaSecureRequest(vm));
        } else if (questions.isCompanySignerRequest) {
            response = yield call(async () => await createNuCrCsSecureRequest(vm));
        } else {
            response = yield call(async () => await createNuCrSecureRequest(vm));
        }

        yield put(actions.sagaCompleted(vm.refreshSecureRequestCreateResponse(response.statusMessages, response.secureRequestGroupId?.code)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

interface IResponse {
    statusMessages: Client.StatusMessagesDto;
    secureRequestGroupId?: Client.SecureRequestGroupIdDto | undefined;
}

async function createNuNcCaSecureRequest(vm: Models.IRootViewModel): Promise<IResponse> {
    const request = new Client.CreateRegistrationCompanyNuNcCaRequestDto({
        submittedComments: vm.user.comments,
        newUserRequest: getNewUserRequestDto(vm.user.newUserRequest),
        newCompanyRequest: getNewCompanyRequestDto(vm.user.newCompanyRequest),
        caAuthoritySupportingDocument: getSupportingDocumentDto(vm.user.caAuthoritySupportingDocument),
    });

    const client = ClientFactory.createRegistrationAnonymousRequestService();
    return await CallHelper.simpleCall(() => client.createRegistrationCompanyNuNcCa(request));
}

async function createNuCaSecureRequest(vm: Models.IRootViewModel): Promise<IResponse> {
    const request = new Client.CreateRegistrationCompanyNuCaRequestDto({
        companyId: vm.user.questions.selectedCompany!.companyId,
        submittedComments: vm.user.comments,
        newUserRequest: getNewUserRequestDto(vm.user.newUserRequest),
        caAuthoritySupportingDocument: getSupportingDocumentDto(vm.user.caAuthoritySupportingDocument),
    });

    const client = ClientFactory.createRegistrationAnonymousRequestService();
    return await CallHelper.simpleCall(() => client.createRegistrationCompanyNuCa(request));
}

async function createNuCrSecureRequest(vm: Models.IRootViewModel): Promise<IResponse> {
    const request = new Client.CreateRegistrationCompanyNuCrRequestDto({
        companyId: vm.user.questions.selectedCompany!.companyId,
        submittedComments: vm.user.comments,
        newUserRequest: getNewUserRequestDto(vm.user.newUserRequest),
        companyStandardRolesRequest: getCompanyStandardRolesRequestDto(vm.user.companyStandardRoleRequest.requestedRoles!),
    });

    const client = ClientFactory.createRegistrationAnonymousRequestService();
    return await CallHelper.simpleCall(() => client.createRegistrationCompanyNuCr(request));
}

async function createNuCrCsSecureRequest(vm: Models.IRootViewModel): Promise<IResponse> {
    const request = new Client.CreateRegistrationCompanyNuCrCsRequestDto({
        companyId: vm.user.questions.selectedCompany!.companyId,
        submittedComments: vm.user.comments,
        newUserRequest: getNewUserRequestDto(vm.user.newUserRequest),
        companyStandardRolesRequest: getCompanyStandardRolesRequestDto(vm.user.companyStandardRoleRequest.requestedRoles!),
        csAuthoritySupportingDocument: getSupportingDocumentDto(vm.user.csAuthoritySupportingDocument),
        isCompanySignerPoaRequest: vm.user.questions.isCompanySignerPoaRequest!,
        csPoaSupportingDocument: vm.user.questions.isCompanySignerPoaRequest ? getSupportingDocumentDto(vm.user.csPoaSupportingDocument) : undefined,
    });

    const client = ClientFactory.createRegistrationAnonymousRequestService();
    return await CallHelper.simpleCall(() => client.createRegistrationCompanyNuCrCs(request));
}

// private
function getNewCompanyRequestDto(vm: Models.INewCompanyRequestViewModel): Client.NewCompanyRequestDetailsDto {
    return new Client.NewCompanyRequestDetailsDto({
        companyName: vm.companyName,
        companyAbn: vm.companyAbn,
        companyAcnOrArbn: vm.companyAcnOrArbn,
        businessAddress: vm.businessAddress,
        businessSuburb: vm.businessSuburb,
        businessPostcode: vm.businessPostcode,
        businessCountrySubdivisionId: vm.businessState?.countrySubdivisionId,
        businessCountryId: vm.businessCountry?.countryId,
        postalAddress: vm.isPostalAddressSameAsBusinessAddress ? vm.businessAddress : vm.postalAddress,
        postalSuburb: vm.isPostalAddressSameAsBusinessAddress ? vm.businessSuburb : vm.postalSuburb,
        postalPostcode: vm.isPostalAddressSameAsBusinessAddress ? vm.businessPostcode : vm.postalPostcode,
        postalCountrySubdivisionId: vm.isPostalAddressSameAsBusinessAddress ? vm.businessState?.countrySubdivisionId : vm.postalState?.countrySubdivisionId,
        postalCountryId: vm.isPostalAddressSameAsBusinessAddress ? vm.businessCountry?.countryId : vm.postalCountry?.countryId,
    });
}

function getNewUserRequestDto(vm: Models.INewUserRequestViewModel): Client.NewUserRequestDetailsWithPasswordDto {
    return new Client.NewUserRequestDetailsWithPasswordDto({
        token: vm.emailVerification.token!,
        emailAddress: vm.email,
        firstName: vm.firstName,
        lastName: vm.lastName,
        position: vm.position,
        contactNumber: vm.contactNumber,
        password: vm.password,
    });
}

function getCompanyStandardRolesRequestDto(vm: IRegistrationRoleViewModel): Client.CompanyStandardRoleRequestDetailsDto {
    return new Client.CompanyStandardRoleRequestDetailsDto({
        rolesRequested: vm.getDirectRoles(),
        rolesApproved: undefined,
    });
}

function getSupportingDocumentDto(document: Models.ISupportingDocumentViewModel): Client.SecureRequestGroupSupportingDocumentDto {
    return new Client.SecureRequestGroupSupportingDocumentDto({ fileName: document.fileName, content: document.content });
}
