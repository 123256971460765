// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import StatusMessagesAlertsView from "../../../../common/alerts/StatusMessagesAlertsView";
import ConflictAlertView from "../../../../common/alerts/ConflictAlertView";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
import { IDocumentViewModel } from "../../common/models/documentViewModel";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
// views
import FinancialNotificationDetailsView from "../../common/components/FinancialNotificationDetailsView";
import MakePaymentView from "./views/MakePaymentDetailsView";
// other
import * as documentHelper from "../../../../common/documentHelper";
import SimpleAuditEventsView from "../../../../common/audit/SimpleAuditEventsView";

interface IFinancialNotificationDetailsControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

// this is the parameters passed on the route.
interface IFinancialNotificationDetailsRouteParameters {
    id: string;
}

function Controller(props: IFinancialNotificationDetailsControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;
    const params = useParams<IFinancialNotificationDetailsRouteParameters>();
    const financialNotificationId = parseInt(params.id);

    // on mount
    useEffect(() => {
        props.sagaGetDetailsRequested(financialNotificationId);
        return function () {
            props.clearRequested();
        };
    }, [financialNotificationId]); // eslint-disable-line react-hooks/exhaustive-deps

    let title = "Financial Notification Details";
    if (vm.financialNotification?.financialNotificationCode) {
        title = vm.financialNotification.financialNotificationTypeDisplay + " - " + vm.financialNotification.financialNotificationCode;
    }

    function getDocument(vm: IDocumentViewModel) {
        actions.sagaGetDocumentRequested(vm);
    }

    useEffect(() => {
        documentHelper.tryDownloadDocument(vm.document);
        return function () {
            props.clearDocumentRequested();
        };
    }, [vm.document]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BodyLayout
            title={title}
            selectedLhsNavItem={Level1ItemTypeEnum.Company_Finance_FinancialNotificationList}
            onReloadRequested={() => {
                props.sagaGetDetailsRequested(financialNotificationId);
            }}
        >
            <ConflictAlertView isConflict={vm.isConflict} />
            <StatusMessagesAlertsView statusMessages={vm.service.statusMessages} />
            <div className="card my-2">
                <FinancialNotificationDetailsView vm={vm.financialNotification} getDocument={getDocument} />
            </div>
            {vm.financialNotification?.canPay && !vm.showMakePayment && (
                <>
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-outline-primary" onClick={(e) => actions.viewChanged(vm.setShowMakePayment(true))}>
                                Make/Record Payment
                            </button>
                        </div>
                    </div>
                </>
            )}

            {vm.showMakePayment && (
                <div className="card my-2">
                    <MakePaymentView vm={vm} actions={actions} />
                </div>
            )}

            <SimpleAuditEventsView className="mt-4" simpleAuditEvents={vm.financialNotification?.audit} />
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_Finance_FinancialNotificationDetails }), Actions.actionFactory)(Controller);
