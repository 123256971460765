// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_ReportsAndData_SurveyDataList;

export default function* initialise() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const dataReferenceResponse: Client.GetGeoscienceAustraliaSurveyDataListReferenceDataResponseDto = yield call(async () => {
            const client = await ClientFactory.createGeoscienceAustraliaSurveyDataClient();
            return await CallHelper.simpleCall(() => client.getGeoscienceAustraliaSurveyDataListReferenceData(new Client.GetGeoscienceAustraliaSurveyDataListReferenceDataRequestDto()));
        });
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshReferenceData(dataReferenceResponse).initialise()));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
