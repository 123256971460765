// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    surveyId: number | undefined;
    surveyName: string | undefined;
    acquisitions: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyDataMonitoringItemsResponseAcquisitionDto[];
    items: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyDataMonitoringItemsResponseItemDto[];
    permissions: {
        canViewAuthorisations: boolean;
        canViewDataSubmissions: boolean;
        canViewSurveyAcquisitions: boolean;
    };

    refreshSurveyDataMonitoring(response: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyDataMonitoringItemsResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.surveyId = undefined;
        this.surveyName = undefined;
        this.acquisitions = [];
        this.items = [];
        this.permissions = {
            canViewAuthorisations: false,
            canViewDataSubmissions: false,
            canViewSurveyAcquisitions: false,
        };
    }

    public surveyId: number | undefined;
    public surveyName: string | undefined;
    public acquisitions: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyDataMonitoringItemsResponseAcquisitionDto[];
    public items: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyDataMonitoringItemsResponseItemDto[];
    public permissions: {
        canViewAuthorisations: boolean;
        canViewDataSubmissions: boolean;
        canViewSurveyAcquisitions: boolean;
    };

    public refreshSurveyDataMonitoring(response: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyDataMonitoringItemsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.surveyId = response.surveyId;
        vm.surveyName = response.surveyName;
        vm.acquisitions = response.acquisitions;
        vm.items = response.items;
        vm.permissions = {
            canViewAuthorisations: response.canViewAuthorisations,
            canViewDataSubmissions: response.canViewDataSubmissions,
            canViewSurveyAcquisitions: response.canViewSurveyAcquisitions,
        };
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.acquisitions = clone(this.acquisitions);
        vm.items = clone(this.items);
        return vm;
    }
}
