// redux
import * as Models from "../../models/models";
// common
import WorkProgramControl from "../../controls/workProgram/components/Controller";

export default function WorkProgramView(props: { vm: Models.IRootViewModel; lastRefreshedDatetime: Date | undefined }) {
    const vm = props.vm;
    const id = vm.id!;

    if (!id) return <></>;

    return (
        <>
            <WorkProgramControl id={id} cardTitle="Work Program" lastRefreshedDatetime={props.lastRefreshedDatetime} className="mb-2" />
        </>
    );
}
