// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_WellsBoreholes_ReportAttachmentList;

export default function* update() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const saveResult: CallHelper.CallResult<Client.CreateGeoscienceAustraliaReportAttachmentResponseDto> = yield call(async () => await createReportAttachment(vm));

        if (saveResult.IsSuccess) {
            // success
            let updatedVm = vm;
            const r = saveResult.Response!;
            if (r.statusMessages!.isSuccess) {
                const getAttachmentsResponse: CallHelper.CallResult<Client.GetGeoscienceAustraliaReportAttachmentsResponseDto> = yield call(async () => await getReportAttachments(vm));
                updatedVm = vm.refreshAttachments(getAttachmentsResponse.Response!);
            }
            yield put(Actions.actionFactory.sagaCompleted(updatedVm.refreshStatusMessages(saveResult.Response!.statusMessages!)));
        } else if (saveResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict(true)));
        } else {
            // all other errors
            saveResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function createReportAttachment(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.CreateGeoscienceAustraliaReportAttachmentResponseDto>> {
    const request = new Client.CreateGeoscienceAustraliaReportAttachmentRequestDto({
        reportId: vm.reportId!,
        reportVersionNumber: vm.reportVersionNumber!,
        name: vm.saveReportAttachment?.name,
        documentLink: vm.saveReportAttachment?.documentLink,
        fileName: vm.saveReportAttachment?.fileName,
    });

    const client = await ClientFactory.createGeoscienceAustraliaReportClient();
    return await CallHelper.call(() => client.createGeoscienceAustraliaReportAttachment(request));
}

async function getReportAttachments(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.GetGeoscienceAustraliaReportAttachmentsResponseDto>> {
    const request = new Client.GetGeoscienceAustraliaReportAttachmentsRequestDto({
        reportId: vm.reportId!,
    });

    const client = await ClientFactory.createGeoscienceAustraliaReportClient();
    return await CallHelper.call(() => client.getGeoscienceAustraliaReportAttachments(request));
}
