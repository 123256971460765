// framework
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import ConfirmationView, { DefaultButtonEnum } from "../../../common/confirmation/ConfirmationView";
import DirtyScope from "../../../common/shell/DirtyScopeController";
// redux
import * as Actions from "../redux/actions";
import { IRootReduxState } from "../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import validator from "../models/validator";
// api
import { ExternalLinkEnum, StatusMessagesDto } from "../../../api/Client";
// views
import { QuestionsView } from "./views/QuestionsView";
import { NewCompanyAdministratorRequestView } from "./views/NewCompanyAdministratorRequestView";
import { CompanySignerRequestView } from "./views/CompanySignerRequestView";
import { CompanyStandardRoleRequestView } from "./views/CompanyStandardRoleRequestView";
import { NewUserRequestView } from "./views/NewUserRequestView";
import { NewCompanyRequestView } from "./views/NewCompanyRequestView";
import { UserAgreementView } from "./views/UserAgreementView";
import { CommentsView } from "./views/CommentsView";
import { RequestSubmittedView } from "./views/RequestSubmittedView";
// common
import { ValidationVisibilityEnum } from "../../../common/validation/ValidationModel";
import * as toastHelper from "../../../common/toastHelper";
import * as ConfigurationHelper from "../../../common/shell/configurationHelper";

interface IRegistrationControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
    statusMessages: (statusMessages: StatusMessagesDto | undefined) => void;
}

function RegistrationController(props: IRegistrationControllerProps) {
    const rootVm: Models.IRootViewModel = props.rootViewModel;
    const questions: Models.IQuestionsViewModel = rootVm.user.questions;
    const actions: Actions.IActionFactory = props;
    const isSuccess: boolean = rootVm.service.statusMessages?.isSuccess ?? false;
    const setStatusMessages = props.statusMessages;

    const userAgreementLink = ConfigurationHelper.usePortalLink(ExternalLinkEnum.CompanyUserAgreement);
    const companyAdministratorRequestFormLink = ConfigurationHelper.usePortalLink(ExternalLinkEnum.CompanyAdministratorRequestForm);
    const companySignerRequestFormLink = ConfigurationHelper.usePortalLink(ExternalLinkEnum.CompanySignerRequestForm);
    const csNoptaSignatureGuidanceLink = ConfigurationHelper.usePortalLink(ExternalLinkEnum.SignatureGuidance);

    // on mount
    useEffect(() => {
        actions.initialiseRequested();
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // status messages
    useEffect(() => {
        setStatusMessages(rootVm.service.statusMessages);
    }, [setStatusMessages, rootVm.service.statusMessages]);

    // validation
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const validation = validator(rootVm, validationVisibility);

    // submission
    function onSubmitClicked(e: any) {
        e.preventDefault();

        // validation
        // - validation is hidden until the user attempts the action... it then stays visible until they have successfully completed the action
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);

        // confirmation
        setShowConfirmation(true);
    }

    // confirmation
    const [showConfirmation, setShowConfirmation] = useState(false);

    function onConfirmationAccepted() {
        setShowConfirmation(false);
        // submit
        actions.createSecureRequestRequested();
    }

    function onConfirmationClosed() {
        setShowConfirmation(false);
    }

    // content
    return (
        <>
            {isSuccess && <RequestSubmittedView vm={rootVm} actions={actions} />}
            {!isSuccess && (
                <DirtyScope scopeName="company/home/registration" isDirty={rootVm.isDirty}>
                    <QuestionsView vm={rootVm} actions={actions} csNoptaSignatureGuidanceLink={csNoptaSignatureGuidanceLink} />
                    {rootVm.viewState.showRequestForm && (
                        <form onSubmit={onSubmitClicked}>
                            {questions.isCompanyAdministratorRequest && (
                                <NewCompanyAdministratorRequestView vm={rootVm} v={validation} actions={actions} requestFormLink={companyAdministratorRequestFormLink} />
                            )}
                            {questions.isCompanySignerRequest && <CompanySignerRequestView vm={rootVm} v={validation} actions={actions} requestFormLink={companySignerRequestFormLink} />}
                            {!questions.isCompanyAdministratorRequest && <CompanyStandardRoleRequestView vm={rootVm} v={validation} actions={actions} />}
                            {questions.isNewCompanyRequest && <NewCompanyRequestView vm={rootVm} v={validation} actions={actions} />}
                            <NewUserRequestView vm={rootVm} v={validation} actions={actions} />
                            <UserAgreementView vm={rootVm} v={validation} actions={actions} userAgreementLink={userAgreementLink} />
                            <CommentsView vm={rootVm} v={validation} actions={actions} />
                            <button className="btn btn-outline-primary" type="submit" disabled={!rootVm.isDirty}>
                                Submit Request
                            </button>
                        </form>
                    )}
                    {showConfirmation && (
                        <ConfirmationView title="Confirm Request Submission" onAccepted={onConfirmationAccepted} onClosed={onConfirmationClosed} defaultButton={DefaultButtonEnum.Ok}>
                            {questions.isNewCompanyRequest ? (
                                <div>
                                    <span>Please confirm the supplied Company Name is correct: </span>
                                    <span>{rootVm.user.newCompanyRequest.companyName}</span>
                                </div>
                            ) : (
                                <div>
                                    <span>Please confirm the selected Company Name is correct: </span>
                                    <span>{rootVm.user.questions.selectedCompany!.companyName}</span>
                                </div>
                            )}
                        </ConfirmationView>
                    )}
                </DirtyScope>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_Home_Registration }), Actions.actionFactory)(RegistrationController);
