// framework
import { useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
// common
import * as documentHelper from "../../../../common/documentHelper";
// views
import LegislativeFormRequestsListView from "./LegislativeFormRequestsView";
import LegislativeFormsListView from "./LegislativeFormsView";

interface ILegislativeFormsListControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: ILegislativeFormsListControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    // Legislative Form document
    const legislativeFormDocumentResponse = vm.legislativeFormDocument;
    // - download when response received
    useEffect(() => {
        documentHelper.tryDownloadDocument(legislativeFormDocumentResponse);
        return function () {
            actions.clearLegislativeFormDocumentRequested();
        };
    }, [legislativeFormDocumentResponse]); // eslint-disable-line react-hooks/exhaustive-deps

    // on mount
    useEffect(() => {
        actions.initialiseRequested();
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BodyLayout
            title="Legislative Forms (2-7)"
            selectedLhsNavItem={Level1ItemTypeEnum.Company_Opggs_LegislativeForms2To7List}
            onReloadRequested={() => {
                props.initialiseRequested();
            }}
        >
            {<LegislativeFormRequestsListView vm={vm.legislativeFormRequests} />}
            {<LegislativeFormsListView vm={vm.legislativeForms} />}
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_Opggs_LegislativeFormsList }), Actions.actionFactory)(Controller);
