import { useEffect } from "react";
import { connect } from "react-redux";
import { IRootReduxState } from "../../../infrastructure/reduxRootReducer";
import * as Models from "../models/rootViewModel";

interface IBusyOverlayControllerProps {
    rootVm: Models.IRootViewModel;
}

function BusyOverlayController(props: IBusyOverlayControllerProps) {
    const isBusy = props.rootVm.busyCount > 0;

    return <LoadingPanel isBusy={isBusy} />;
}

function LoadingPanel(props: { isBusy: boolean }) {
    const isBusy = props.isBusy;

    // must always be visible on the screen; it can be on a long screen and the user can still scroll up and down
    // capture and suppress keyboard events, to stop the users from tabbing through content whilst the spinner is active

    useEffect(() => {
        if (isBusy) {
            window.addEventListener("keydown", disableKeys);
            return function enableKeys() {
                window.removeEventListener("keydown", disableKeys);
            };
        }
    }, [isBusy]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!isBusy) return <></>;

    return (
        <div
            className="k-loading-mask"
            style={{ position: "fixed", display: "block", width: "100%", height: "100%", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(0,0,0,0.2)", zIndex: 99999, cursor: "progress" }}
        >
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );
}

function disableKeys(e: KeyboardEvent) {
    e.preventDefault();
    return false;
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootVm: state.Shell_Layout }))(BusyOverlayController);
