// api
import * as ClientFactory from "../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";

export async function getJointAuthorityOpggsApplicationDetailsTitleholders(id: string): Promise<Client.GetJointAuthorityOpggsApplicationDetailsTitleholdersResponseDto> {
    const client = await ClientFactory.createJointAuthorityOpggsApplicationDetailsClient();
    return await CallHelper.simpleCall(() =>
        client.getJointAuthorityOpggsApplicationDetailsTitleholders(new Client.GetJointAuthorityOpggsApplicationDetailsTitleholdersRequestDto({ applicationId: id }))
    );
}
