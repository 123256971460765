// framework
import { useState, useEffect } from "react";
// redux
import * as Models from "../../../models/models";
import * as Actions from "../../../redux/actions";
// common
import CreateDraftApplicationView from "./CreateDraftApplicationView";

export default function OnlineSubmissionsView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; applicationGuideLink: string | undefined }) {
    const vm = props.vm;
    const actions = props.actions;
    const details = vm.details;
    const titleId = details?.titleId;

    // draft application
    const [showCreateWindow, setShowCreateWindow] = useState(false);

    // - show window when reference data received
    useEffect(() => {
        if (vm.createDraftApplicationReferenceData) {
            setShowCreateWindow(true);
        }
    }, [vm.createDraftApplicationReferenceData]); // eslint-disable-line react-hooks/exhaustive-deps

    function onCreateRequested() {
        actions.createDraftApplicationRequested();
        setShowCreateWindow(false);
    }

    function onCreateCancelled() {
        actions.clearDraftApplicationDataRequested();
        setShowCreateWindow(false);
    }

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Online Submissions</h2>
                    {titleId && (
                        <button className="btn btn-outline-primary" type="button" onClick={() => actions.getCreateDraftApplicationReferenceDataRequested(titleId)}>
                            Create Application
                        </button>
                    )}
                </div>
            </div>
            {showCreateWindow && <CreateDraftApplicationView vm={vm} actions={actions} applicationGuideLink={props.applicationGuideLink} onCreate={onCreateRequested} onCancel={onCreateCancelled} />}
        </>
    );
}
