// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../api/Client";

export interface IRootViewModel {
    items: Client.GetGeoscienceAustraliaFeedbackListItemDto[];
    refreshList(response: Client.GetGeoscienceAustraliaFeedbackListResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.items = [];
    }

    public items: Client.GetGeoscienceAustraliaFeedbackListItemDto[];

    public refreshList(response: Client.GetGeoscienceAustraliaFeedbackListResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.items = response.items;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
