// framework
import { call, put, select } from "redux-saga/effects";
// redux
import { IDraftApplicationDetailsReduxState } from "../../../../draftApplicationDetails/draftApplicationDetailsReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// shell
import * as ShellActions from "../../../../../../shell/layout/redux/actions";
// common
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: IDraftApplicationDetailsReduxState) => state.Company_Opggs_DraftApplicationDetails_Eottlr;

export default function* initialise(action: Actions.IAction<number>) {
    let vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(ShellActions.actionFactory.setBusy());

        const response: Client.GetCompanyOpggsDraftApplicationDetailsEottlrResponseDto = yield call(async () => getEottlrDetails(action.payload));
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshDetails(response)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(ShellActions.actionFactory.clearBusy());
    }
}

export async function getEottlrDetails(id: number): Promise<Client.GetCompanyOpggsDraftApplicationDetailsEottlrResponseDto> {
    const request = new Client.GetCompanyOpggsDraftApplicationDetailsEottlrRequestDto({ id: id });
    const client = await ClientFactory.createCompanyOpggsDraftApplicationDetailsClient();

    return await CallHelper.simpleCall(() => client.getCompanyOpggsDraftApplicationDetailsEottlr(request));
}
