// framework
import { Link } from "react-router-dom";
// common
import * as CompanyRouteHelper from "../../../../companyRouteHelper";

export default function SubmittedView() {
    const oeiApplicationsListUrl = CompanyRouteHelper.oeiApplicationList();

    return (
        <p>
            Your Application has been submitted. This will need to be processed by NOPTA and once lodged will be available in the system. Click <Link to={oeiApplicationsListUrl}>here</Link> to view
            the In Progress OEI Act Applications list.
        </p>
    );
}
