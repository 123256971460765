import * as Models from "../models/rootViewModel";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "company.userManagement.requestList.initialiseRequested",
    clearRequested = "company.userManagement.requestList.clearRequested",
    batchRejectRequested = "company.userManagement.requestList.batchRejectRequested",
    batchApproveRequested = "company.userManagement.requestList.batchApproveRequested",
    // user input
    viewChanged = "company.userManagement.requestList.viewChanged",
    // saga
    sagaCompleted = "company.userManagement.requestList.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(): IAction<undefined>;
    clearRequested(): IAction<undefined>;
    batchRejectRequested(): IAction<undefined>;
    batchApproveRequested(): IAction<undefined>;
    // user input
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    // saga
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (): IAction<undefined> {
        return {
            type: actions.initialiseRequested,
            payload: undefined,
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    batchRejectRequested: function (): IAction<undefined> {
        return {
            type: actions.batchRejectRequested,
            payload: undefined,
        };
    },
    batchApproveRequested: function (): IAction<undefined> {
        return {
            type: actions.batchApproveRequested,
            payload: undefined,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
