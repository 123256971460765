// framework
import { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Actions from "../redux/actions";
import * as Models from "../models/models";
import validator from "../models/validator";
// shell
import BodyLayout from "../../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// views
import FormRequestDetailsView from "./views/FormRequestDetailsView";
// common
import StatusMessagesAlertsView from "../../../../../common/alerts/StatusMessagesAlertsView";
import { ValidationVisibilityEnum } from "../../../../../common/validation/ValidationModel";
import DirtyScope from "../../../../../common/shell/DirtyScopeController";
import * as toastHelper from "../../../../../common/toastHelper";
import * as companyRouteHelper from "../../../../companyRouteHelper";
import * as LegislativeFormsHelper from "../../common/LegislativeFormsHelper";

interface IControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

interface IRouteParameters {
    id: string;
}

function Controller(props: IControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;
    const params = useParams<IRouteParameters>();

    // on mount
    // - initial title load
    const titleId = params.id;
    useEffect(() => {
        actions.getReferenceDataRequested(titleId);
        return function () {
            actions.clearRequested();
        };
    }, [titleId]); // eslint-disable-line react-hooks/exhaustive-deps

    // validation
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const validation = validator(vm, validationVisibility);

    // submission
    function onSubmitClicked(e: any) {
        e.preventDefault();

        // validation
        // - validation is hidden until the user attempts the action... it then stays visible until they have successfully completed the action
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);

        // submit
        actions.sagaCreateFormRequestRequested();
    }

    const id = vm.service.id;
    const isSuccess = vm.service.statusMessages?.isSuccess ?? false;
    if (id && isSuccess) {
        return <Redirect to={companyRouteHelper.legislativeFormsFormRequestDetails(id.formRequestId, id.formTypeId)} />;
    }

    const pageTitle = LegislativeFormsHelper.getFormRequestHeader(vm.formRequestDetails.formTypeId, vm.titleNumber, vm.formRequestDetails.selectedTitleholder?.companyName);

    // return
    return (
        <BodyLayout title={pageTitle} selectedLhsNavItem={Level1ItemTypeEnum.Company_Opggs_TitlesList}>
            <StatusMessagesAlertsView statusMessages={vm.service.statusMessages} />
            <DirtyScope scopeName="company/opggs/legislativeForms/formRequestCreate" isDirty={vm.isDirty}>
                <form onSubmit={onSubmitClicked}>
                    <FormRequestDetailsView vm={vm} v={validation} actions={actions} />
                    <button className="btn btn-outline-primary" type="submit" disabled={!vm.isDirty}>
                        Create
                    </button>
                </form>
            </DirtyScope>
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_Opggs_LegislativeForms_FormRequestCreate }), Actions.actionFactory)(Controller);
