// api
import * as Client from "../../../../../../api/Client";
// views
import CommonNavBar from "../../../../../common/CommonNavBarView";
// common
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";

export interface IProps {
    searchType: Client.SearchAuthorisationsTypeEnum;
}

export default function NavBarView(props: IProps): JSX.Element {
    return (
        <CommonNavBar
            activeNavItemKey={props.searchType}
            navItems={[
                {
                    key: Client.SearchAuthorisationsTypeEnum.InProgress,
                    title: "In Progress",
                    url: geoscienceAustraliaRouteHelper.authorisationList(Client.SearchAuthorisationsTypeEnum.InProgress),
                },
                {
                    key: Client.SearchAuthorisationsTypeEnum.Last12Months,
                    title: "Last 12 Months",
                    url: geoscienceAustraliaRouteHelper.authorisationList(Client.SearchAuthorisationsTypeEnum.Last12Months),
                },
                {
                    key: Client.SearchAuthorisationsTypeEnum.All,
                    title: "All",
                    url: geoscienceAustraliaRouteHelper.authorisationList(Client.SearchAuthorisationsTypeEnum.All),
                },
            ]}
        />
    );
}
