// framework
import { clone, cloneDeep } from "lodash";
// client
import * as Client from "../../../../api/Client";
// redux
import { IRequestAccessRoleViewModel, RequestAccessRoleViewModel } from "./requestAccessRoleViewModel";
// common
import { RoleCategoryEnum } from "../../../../common/roleGrid/RoleViewModel";
import { BaseGeoscienceAustraliaRoleRootViewModel, IGeoscienceAustraliaRoleRootViewModel } from "../../../common/GeoscienceAustraliaRoleRootViewModel";

export interface IQuestionsViewModel {
    isGeoscienceAustraliaAdministratorRequest?: boolean;
    isGeoscienceAustraliaStandardRoleRequest?: boolean;
}

export interface ISupportingDocumentViewModel {
    fileName?: string;
    content?: string;
}

export interface IGeoscienceAustraliaStandardRoleRequestViewModel {
    existingRoles?: IRequestAccessRoleViewModel;
    requestedRoles?: IRequestAccessRoleViewModel;
}

export interface IViewStateViewModel {
    showExistingAccessRequestWizard: boolean;
    showExistingFullAccessWizard: boolean;
    showGeoscienceAustraliaAdministratorWizard: boolean;
    showGeoscienceAustraliaStandardRolesWizard: boolean;
    showGeoscienceAustraliaNewAdministratorWizard: boolean;
    showGeoscienceAustraliaExistingAdministratorWizard: boolean;
    showInvalidRequestWizard: boolean;
    showRequestForm: boolean;
}

export interface IRootViewModel extends IGeoscienceAustraliaRoleRootViewModel {
    reference: {
        geoscienceAustralia: Client.GetGeoscienceAustraliaAccountRequestAccessReferenceDataResponseDto | undefined;
    };

    user: {
        questions: IQuestionsViewModel;
        geoscienceAustraliaStandardRoleRequest: IGeoscienceAustraliaStandardRoleRequestViewModel;
        gaaAuthoritySupportingDocument: ISupportingDocumentViewModel;
        comments: string;
    };

    service: {
        id?: Client.SecureRequestGroupIdDto | undefined;
        statusMessages?: Client.StatusMessagesDto;
    };

    isDirty: boolean;

    viewState: IViewStateViewModel;

    // saga triggered state changes
    refreshGeoscienceAustraliaDetails(geoscienceAustralia: Client.GetGeoscienceAustraliaAccountRequestAccessReferenceDataResponseDto | undefined): IRootViewModel;
    refreshSecureRequestCreateResponse(statusMessages: Client.StatusMessagesDto, id: Client.SecureRequestGroupIdDto | undefined): IRootViewModel;

    // view triggered state changes
    refreshQuestionsUpdated(values: IQuestionsViewModel): IRootViewModel;
    refreshGeoscienceAustraliaStandardRoleRequestUpdated(values: IRequestAccessRoleViewModel): IRootViewModel;
    refreshGaaAuthoritySupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel;
    refreshCommentsUpdated(comments: string): IRootViewModel;
}

export class RootViewModel extends BaseGeoscienceAustraliaRoleRootViewModel implements IRootViewModel {
    constructor() {
        super();
        // initial state
        this.reference = {
            geoscienceAustralia: undefined,
        };
        this.user = {
            questions: {
                isGeoscienceAustraliaAdministratorRequest: undefined,
                isGeoscienceAustraliaStandardRoleRequest: undefined,
            },
            geoscienceAustraliaStandardRoleRequest: {},
            gaaAuthoritySupportingDocument: {},
            comments: "",
        };

        this.service = {
            id: undefined,
            statusMessages: undefined,
        };

        this.isDirty = false;
        this.viewState = {
            showExistingAccessRequestWizard: false,
            showExistingFullAccessWizard: false,
            showGeoscienceAustraliaAdministratorWizard: false,
            showGeoscienceAustraliaStandardRolesWizard: false,
            showGeoscienceAustraliaNewAdministratorWizard: false,
            showGeoscienceAustraliaExistingAdministratorWizard: false,
            showInvalidRequestWizard: false,
            showRequestForm: false,
        };
    }

    reference: {
        geoscienceAustralia: Client.GetGeoscienceAustraliaAccountRequestAccessReferenceDataResponseDto | undefined;
    };

    user: {
        questions: IQuestionsViewModel;
        geoscienceAustraliaStandardRoleRequest: IGeoscienceAustraliaStandardRoleRequestViewModel;
        gaaAuthoritySupportingDocument: ISupportingDocumentViewModel;
        comments: string;
    };

    service: {
        id?: Client.SecureRequestGroupIdDto | undefined;
        statusMessages?: Client.StatusMessagesDto;
    };

    isDirty: boolean;
    viewState: IViewStateViewModel;

    // saga triggered state changes
    refreshGeoscienceAustraliaDetails(geoscienceAustralia: Client.GetGeoscienceAustraliaAccountRequestAccessReferenceDataResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.reference.geoscienceAustralia = geoscienceAustralia;
        if (!geoscienceAustralia.hasGeoscienceAustraliaAdministrator) vm.user.questions.isGeoscienceAustraliaAdministratorRequest = true;
        if (!geoscienceAustralia.hasGeoscienceAustraliaAdministrator) vm.user.questions.isGeoscienceAustraliaAdministratorRequest = true;

        // set defaults
        vm.user.geoscienceAustraliaStandardRoleRequest.existingRoles = new RequestAccessRoleViewModel(
            vm.roleConfiguration.roleRelationships!,
            RoleCategoryEnum.Existing,
            geoscienceAustralia.activeRoles
        );
        vm.user.geoscienceAustraliaStandardRoleRequest.requestedRoles = new RequestAccessRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Requested);

        vm.isDirty = false; // reference data load is part of the initialisation phase

        updateFlags(vm); // update the flags based on the intial load, no selection may be necessary if the user already has a role

        return vm;
    }

    refreshSecureRequestCreateResponse(statusMessages: Client.StatusMessagesDto, id: Client.SecureRequestGroupIdDto | undefined): IRootViewModel {
        const vm = this._clone();
        vm.service.id = id;
        vm.service.statusMessages = statusMessages;
        vm.isDirty = !statusMessages!.isSuccess; // on success, set the vm as clean
        return vm;
    }

    refreshQuestionsUpdated(values: IQuestionsViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.questions = { ...vm.user.questions, ...values };

        if (values.isGeoscienceAustraliaAdministratorRequest === true) {
            vm.user.questions.isGeoscienceAustraliaStandardRoleRequest = false;
        } else if (values.isGeoscienceAustraliaAdministratorRequest === false) {
            vm.user.questions.isGeoscienceAustraliaStandardRoleRequest = !vm.reference.geoscienceAustralia!.hasAllGeneralRoles;
        }

        updateFlags(vm);
        vm.isDirty = true;
        return vm;
    }

    refreshGeoscienceAustraliaStandardRoleRequestUpdated(values: IRequestAccessRoleViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.geoscienceAustraliaStandardRoleRequest.requestedRoles = values;
        vm.isDirty = true;
        return vm;
    }

    refreshGaaAuthoritySupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.gaaAuthoritySupportingDocument = value;
        vm.isDirty = true;
        return vm;
    }

    refreshCommentsUpdated(comments: string): IRootViewModel {
        const vm = this._clone();
        vm.user.comments = comments;
        vm.isDirty = true;
        return vm;
    }

    // private implementation
    // ----------------------
    _clone(): RootViewModel {
        const vm = clone(this);
        vm.reference = clone(this.reference);
        vm.user = cloneDeep(this.user);
        vm.service = clone(this.service);
        vm.viewState = clone(this.viewState);
        return vm;
    }
}

// private
function updateFlags(vm: IRootViewModel): void {
    const questions = vm.user.questions;

    vm.viewState = {
        showExistingAccessRequestWizard: false,
        showExistingFullAccessWizard: false,
        showGeoscienceAustraliaAdministratorWizard: false,
        showGeoscienceAustraliaStandardRolesWizard: false,
        showGeoscienceAustraliaNewAdministratorWizard: false,
        showGeoscienceAustraliaExistingAdministratorWizard: false,
        showInvalidRequestWizard: false,
        showRequestForm: false,
    };

    // existing geoscience australia
    if (vm.reference.geoscienceAustralia)
        if (vm.reference.geoscienceAustralia.hasPendingRequest) {
            // pending request - cannot proceed
            vm.viewState.showExistingAccessRequestWizard = true;
            return;
        } else if (!vm.reference.geoscienceAustralia.hasGeoscienceAustraliaAdministrator) {
            // no GAA - must select new GAA
            vm.viewState.showGeoscienceAustraliaNewAdministratorWizard = true;
        } else {
            if (vm.reference.geoscienceAustralia.hasAllSpecialRoles) {
                // full access - cannot proceed
                vm.viewState.showExistingFullAccessWizard = true;
                return;
            } else if (vm.reference.geoscienceAustralia.isGeoscienceAustraliaAdministrator) {
                // GAA access - can only select GAA
                vm.viewState.showGeoscienceAustraliaAdministratorWizard = true;
            } else {
                // all general roles - can only select GAA
                vm.viewState.showGeoscienceAustraliaStandardRolesWizard = vm.reference.geoscienceAustralia.hasAllGeneralRoles;
                // show GAA access wizard
                vm.viewState.showGeoscienceAustraliaExistingAdministratorWizard = true;
            }
        }

    // break if no GAA selection has been made
    if (questions.isGeoscienceAustraliaAdministratorRequest === undefined) return;

    // reveal form
    vm.viewState.showInvalidRequestWizard = questions.isGeoscienceAustraliaAdministratorRequest === false && questions.isGeoscienceAustraliaStandardRoleRequest === false;
    vm.viewState.showRequestForm = questions.isGeoscienceAustraliaAdministratorRequest === true || questions.isGeoscienceAustraliaStandardRoleRequest === true;
}
