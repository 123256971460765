// framework
import { useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/rootViewModel";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
import ConflictView from "../../../../common/alerts/ConflictAlertView";
import DirtyScope from "../../../../common/shell/DirtyScopeController";
// views
import JointAuthorityPermissionsView from "./views/JointAuthorityPermissionsView";
import JointAuthorityDetailsView from "./views/JointAuthorityDetailsView";
import StatusMessagesAlertsView from "../../../../common/alerts/StatusMessagesAlertsView";
import JointAuthorityPermissionsEditView from "./views/JointAuthorityPermissionsEditView";

interface IJointAuthorityControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function JointAuthorityController(props: IJointAuthorityControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    // on mount
    useEffect(() => {
        props.initialise();
        return function () {
            props.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BodyLayout
            title="Manage Joint Authority Access"
            selectedLhsNavItem={Level1ItemTypeEnum.JointAuthority_UserManagement_ViewMyJointAuthority}
            onReloadRequested={() => {
                props.initialise();
            }}
        >
            <ConflictView isConflict={vm.isConflict} />
            <StatusMessagesAlertsView statusMessages={vm.service.statusMessages} />
            <JointAuthorityDetailsView vm={vm.details} />
            {vm.viewState === Models.ViewStateEnum.View && <JointAuthorityPermissionsView vm={vm} actions={actions} />}
            {vm.viewState === Models.ViewStateEnum.Edit && (
                <DirtyScope scopeName="jointAuthority/userManagement/jointAuthority" isDirty={vm.isDirty}>
                    <JointAuthorityPermissionsEditView vm={vm} actions={actions} />
                </DirtyScope>
            )}
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.JointAuthority_UserManagement_JointAuthorityDetails }), Actions.actionFactory)(JointAuthorityController);
