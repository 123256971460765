// framework
import { useState } from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// redux
import * as Models from "../models/models";
// api
import { JointAuthorityApplicationListItemDto, TitleLinkDto } from "../../../../api/Client";
// other
import * as GlobalHelpers from "../../../../common/GlobalHelpers";
import ApplicationLinkController from "../../../../common/links/ApplicationLinkController";
import TitleLinkController from "../../../../common/links/TitleLinkController";
import { createGridColumnFilterMenu } from "../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
import CustomGridCell from "../../../../common/kendoGrid/CustomGridCell";

export default function ApplicationListView(props: { vm: Models.applicationListType }): React.ReactElement {
    const applications = props.vm ?? new Array<JointAuthorityApplicationListItemDto>();

    const initialGridState: State = {
        sort: [{ field: "statusDate", dir: "desc" }],
        skip: 0,
        take: 20,
    };
    const [gridState, setGridState] = useState(initialGridState);

    return (
        <Grid
            pageable
            sortable
            resizable
            navigatable
            data={process(applications, gridState)}
            {...gridState}
            onDataStateChange={(e) => {
                setGridState(e.dataState);
            }}
        >
            <GridColumn
                field="applicationLink.trackingNumber"
                title="Tracking Number"
                width={170}
                filter="text"
                columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, applications)}
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        <ApplicationLinkController linkDto={props.dataItem.applicationLink} secureEntityType="JointAuthority" />
                    </CustomGridCell>
                )}
            />
            <GridColumn field="applicationTypeName" title="Application Type" width={165} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, applications)} />
            <GridColumn field="applicationName" title="Application Name" width={230} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, applications)} />
            <GridColumn field="applicationStatusDisplay" title="Application Status" width={175} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, applications)} />
            <GridColumn field="statusDate" title="Status Date" width={135} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
            <GridColumn
                field="titleNumbers"
                title="Title Number(s)"
                width={150}
                filter="text"
                columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, applications)}
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        {props.dataItem.titleLinks.map((l: TitleLinkDto, i: number) => [i > 0 && ", ", <TitleLinkController key={i} linkDto={l} secureEntityType="JointAuthority" />])}
                    </CustomGridCell>
                )}
            />
            <GridColumn field="titleTypeDisplay" title="Title Type" filter="text" width={150} columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, applications)} />
            <GridColumn field="companyName" title="Applicant" width={195} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, applications)} />
            <GridColumn field="submittedDate" title="Submitted Date" width={160} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
        </Grid>
    );
}
