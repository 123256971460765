// framework
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import validator from "../models/validator";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// common
import * as toastHelper from "../../../../../common/toastHelper";
import StatusMessagesAlertsView from "../../../../../common/alerts/StatusMessagesAlertsView";
import DirtyScope from "../../../../../common/shell/DirtyScopeController";
import { ValidationVisibilityEnum } from "../../../../../common/validation/ValidationModel";
import * as GeoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";
// views
import CreateSurveyDataView from "./views/CreateSurveyDataView";
// Kendo
import { DropDownButton, DropDownButtonItem } from "@progress/kendo-react-buttons";
import { caretAltDownIcon } from "@progress/kendo-svg-icons";
// api
import { CreateSurveyDataRelatedActivityTypeEnum } from "../../../../../api/Client";

interface IRouteParameters {
    relatedActivityType: CreateSurveyDataRelatedActivityTypeEnum | undefined;
    relatedActivityId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { relatedActivityType, relatedActivityId } = params;

    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const v = validator(vm, validationVisibility);

    const createAndViewText = "Create and View";
    const createAndCreateAnotherText = "Create and Create Another";

    // on mount
    useEffect(() => {
        window.scrollTo(0, 0);
        actions.initialiseRequested(relatedActivityType, Number(relatedActivityId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // on create
    const history = useHistory();
    useEffect(() => {
        if (vm.viewState === Models.ViewStateEnum.Create) {
            history.push(GeoscienceAustraliaRouteHelper.surveyDataDetails(vm.surveyDataId!));
        }
    }, [vm.viewState]); // eslint-disable-line react-hooks/exhaustive-deps

    const onCreate = (event: any) => {
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }

        if (event.item.text === createAndViewText) {
            setValidationVisibility(ValidationVisibilityEnum.None);
            actions.createRequested(false);
        } else if (event.item.text === createAndCreateAnotherText) {
            setValidationVisibility(ValidationVisibilityEnum.Messages);
            actions.createRequested(true);
        }
    };

    return (
        <>
            {vm.viewState === Models.ViewStateEnum.Edit && (
                <BodyLayout title={`Create Survey Data`} selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_ReportsAndData_SurveyData}>
                    <DirtyScope scopeName="geoscienceAustralia.reportsAndData.surveyDataDetails.models.viewState.create" isDirty={vm.isDirty} />
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
                    <CreateSurveyDataView vm={vm} v={v} actions={actions} />
                    <div className="mt-3">
                        <DropDownButton text="Create" themeColor={"primary"} svgIcon={caretAltDownIcon} onItemClick={onCreate}>
                            <DropDownButtonItem text={createAndViewText}></DropDownButtonItem>
                            <DropDownButtonItem text={createAndCreateAnotherText}></DropDownButtonItem>
                        </DropDownButton>
                    </div>
                </BodyLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_ReportsAndData_SurveyDataCreate }), Actions.actionFactory)(Controller);
