// kendo
import { DatePicker } from "@progress/kendo-react-dateinputs";
// redux
import * as Models from "../../models/models";
// views
import WarningView from "./WarningView";
// common
import ValidationMessageView from "../../../../../../common/validation/ValidationMessageView";
import { ValidationModel } from "../../../../../../common/validation/ValidationModel";
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as companyRouteHelper from "../../../../../companyRouteHelper";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";

export default function EottlrDetailsView(props: { vm: Models.IEottlrDetailsViewModel; v: ValidationModel; updateViewModel: (vm: Models.IEottlrViewModel) => void }) {
    const vm = props.vm;
    const v = props.v;

    function updateViewModel(value: Date | null) {
        if (value === null) return;
        props.updateViewModel({ extensionDate: value });
    }

    return (
        <>
            <WarningView vm={vm} />
            <Forms.Group>
                <Forms.Link label="Title Number" linkText={vm.titleNumber} linkTo={vm.titleId && companyRouteHelper.titleDetails(vm.titleId)} />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="Expiry Date" id="expiryDateField" value={GlobalHelpers.toNoptaDateString(vm.titleExpiryDate)} />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="extensionDateField">
                    Length of longer period requested
                    <br />
                    (Up to but no later than 14 days prior to the expiry date)
                </Forms.Label>
                <DatePicker
                    id="extensionDateField"
                    value={vm.extensionDate}
                    valid={v.applyValidityForKendo("EottlrDetails.ExtensionDate")}
                    max={vm.titleExpiryDate}
                    onChange={(e) => updateViewModel(e.value)}
                />
                <ValidationMessageView name="EottlrDetails.ExtensionDate" validationModel={v} />
            </Forms.Group>
        </>
    );
}
