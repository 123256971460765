// !!! this requires the CDN script to be loaed in /public/index.html
// - see bambora documentation at: https://dev.na.bambora.com/docs/guides/custom_checkout/setup/
// - note, there are outstanding issues with the implementation related to leaks in the bambora libraries, this has been raised for resolution

declare global {
    interface Window {
        // this is the bambora library registered in the window
        customcheckout(): any;

        // this is the singleton instance of the adapter
        customCheckoutAdaptor: ICustomCheckoutAdaptor | undefined;
    }
}

export const CardNumberElementId = "bambora-card-number";
export const ExpiryElementId = "bambora-card-expiry";
export const CVVElementId = "bambora-card-cvv";

export type onStateChangedType = (state: ICustomCheckoutState) => void;

export interface ICustomCheckoutState {
    token: string | undefined;
    cardNumberMessage: string | undefined;
    cardBrand: string | undefined;
    cardExpiryMessage: string | undefined;
    cardCvvMessage: string | undefined;
    isValid: boolean;
    isComplete: boolean;
}

export const defaultState: ICustomCheckoutState = {
    token: undefined,
    cardNumberMessage: undefined,
    cardBrand: undefined,
    cardExpiryMessage: undefined,
    cardCvvMessage: undefined,
    isValid: false,
    isComplete: false,
};

enum customCheckoutFieldEnum {
    cardNumber = "card-number",
    expiry = "expiry",
    cvv = "cvv",
}

interface ICustomCheckoutAdaptor {
    instanceId: number;

    initialise(): void;
    dispose(): void;
    startGetToken(): void;
}

const showDebugMessages = false;

class CustomCheckoutAdaptor implements ICustomCheckoutAdaptor {
    constructor(merchantId: string, onStateChanged: onStateChangedType) {
        this.instanceId = Math.round(Math.random() * 1000000);
        this._merchantId = merchantId;

        this._onStateChanged = onStateChanged;
        this._state = defaultState;

        this._fields = { cardNumber: undefined, expiry: undefined, cvv: undefined };
        this._initialised = false;

        this._onStateChanged(this._state);
    }

    instanceId: number;

    _merchantId: string;
    _state: ICustomCheckoutState;
    _onStateChanged: onStateChangedType;

    _customCheckout: any;
    _fields: {
        cardNumber: any;
        expiry: any;
        cvv: any;
    };

    _initialised: boolean;

    initialise(): void {
        if (showDebugMessages) console.debug(`customCheckoutAdaptor.initialise():${this.instanceId}`);
        if (this._initialised) throw new Error("Already initialised!");

        // create the javascript customcheckout instance
        const cc = window.customcheckout();
        this._customCheckout = cc;

        // mount the on-screen elements
        const options = {};
        const cardNumber = cc.create(customCheckoutFieldEnum.cardNumber, { ...options, placeholder: "Card Number" });
        cardNumber.mount(`#${CardNumberElementId}`);
        const expiry = cc.create(customCheckoutFieldEnum.expiry, { ...options, placeholder: "MM / YY" });
        expiry.mount(`#${ExpiryElementId}`);
        const cvv = cc.create(customCheckoutFieldEnum.cvv, { ...options, placeholder: "CVV" });
        cvv.mount(`#${CVVElementId}`);

        this._fields = {
            cardNumber: cardNumber,
            expiry: expiry,
            cvv: cvv,
        };

        const self = this;

        // attach event listeners
        cc.on("error", function (e: any): void {
            if (showDebugMessages) console.debug(`customCheckoutAdaptor.onError():${self.instanceId}`);
            if (self._isOrphanedInstance(self.instanceId)) return;
            switch (e.field) {
                case customCheckoutFieldEnum.cardNumber:
                    self._updateState({ ...self._state, cardNumberMessage: e.message });
                    break;
                case customCheckoutFieldEnum.expiry:
                    self._updateState({ ...self._state, cardExpiryMessage: e.message });
                    break;
                case customCheckoutFieldEnum.cvv:
                    self._updateState({ ...self._state, cardCvvMessage: e.message });
                    break;
            }
        });

        cc.on("complete", function (e: any): void {
            if (showDebugMessages) console.debug(`customCheckoutAdaptor.onComplete():${self.instanceId}`);
            if (self._isOrphanedInstance(self.instanceId)) return;
            switch (e.field) {
                case customCheckoutFieldEnum.cardNumber:
                    self._updateState({ ...self._state, cardNumberMessage: undefined });
                    break;
                case customCheckoutFieldEnum.expiry:
                    self._updateState({ ...self._state, cardExpiryMessage: undefined });
                    break;
                case customCheckoutFieldEnum.cvv:
                    self._updateState({ ...self._state, cardCvvMessage: undefined });
                    break;
            }
        });

        cc.on("brand", function (e: any): void {
            let cardBrand: string | undefined = e.brand && e.brand !== "unknown" ? e.brand : undefined;
            self._updateState({ ...self._state, cardBrand: cardBrand });
        });

        this._initialised = true;
    }

    dispose(): void {
        if (showDebugMessages) console.debug(`customCheckoutAdaptor.dispose():${this.instanceId}`);
        if (this._isOrphanedInstance(this.instanceId)) return;
        if (!this._initialised) throw new Error("Not initialised!");

        this._customCheckout.dispose();
        this._customCheckout = undefined;

        this._initialised = false;
    }

    startGetToken(): void {
        if (showDebugMessages) console.debug(`customCheckoutAdaptor.startGetToken():${this.instanceId}`);
        if (this._isOrphanedInstance(this.instanceId)) return;
        if (!this._initialised) throw new Error("Not initialised!");

        const getTokenCallback = (result: any): void => {
            if (showDebugMessages) console.debug(`customCheckoutAdaptor._getTokenCallback():${this.instanceId}`);

            if (result.error) {
                // these are only internal errors
                console.error(result.error);
            } else {
                this._updateState({ ...this._state, token: result.token });
            }
        };

        this._customCheckout.createOneTimeToken(this._merchantId, getTokenCallback);
    }

    _updateState(state: ICustomCheckoutState) {
        if (showDebugMessages) console.debug(`customCheckoutAdaptor.updateState():${this.instanceId}`);
        if (this._isOrphanedInstance(this.instanceId)) return;

        const s = state;
        s.isValid = s.cardNumberMessage === undefined && s.cardExpiryMessage === undefined && s.cardCvvMessage === undefined;
        s.token = s.isValid ? s.token : undefined;
        s.isComplete = s.token !== undefined && s.isValid;

        this._state = s;

        this._onStateChanged(s);
    }

    _isOrphanedInstance(instanceId: number): boolean {
        // this is required as there are lots of leaky instance, hopefully Bambora support can make this redundant!
        if (!window.customCheckoutAdaptor) return true;
        return instanceId !== window.customCheckoutAdaptor!.instanceId;
    }
}

// ensure there is only a single instance, this is the only way this is supported

export function load(merchantId: string, onStateChanged: onStateChangedType) {
    if (!window.customCheckoutAdaptor) {
        const cca = new CustomCheckoutAdaptor(merchantId, onStateChanged);
        cca.initialise();
        window.customCheckoutAdaptor = cca;
    }
    return window.customCheckoutAdaptor;
}

export function unload() {
    if (window.customCheckoutAdaptor) {
        const cca = window.customCheckoutAdaptor;
        cca.dispose();
        window.customCheckoutAdaptor = undefined;
    }
}

export function startGetToken() {
    if (!window.customCheckoutAdaptor) throw new Error("Not initialised!");

    const cca = window.customCheckoutAdaptor!;
    cca.startGetToken();
}
