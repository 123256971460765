// framework
import { useEffect, useState } from "react";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
// common
import { ValidationModel } from "../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../common/validation/ValidationMessageView";
import * as Forms from "../../../../common/forms/BootstrapForms";
import ExternalLinkView from "../../../../common/externalLink/ExternalLinkView";

export function UserAgreementView(props: { vm: Models.IRootViewModel; v: ValidationModel; actions: Actions.IActionFactory; userAgreementLink: string | undefined }) {
    const vm = props.vm;
    const v = props.v;
    const actions = props.actions;

    const [isUserAgreementAcknowledged, setIsUserAgreementAcknowledged] = useState(vm.user.isUserAgreementAcknowledged);
    const [isUserAgreementDownloaded, setIsUserAgreementDownloaded] = useState(vm.user.isUserAgreementAcknowledged);

    useEffect(() => {
        setIsUserAgreementAcknowledged(vm.user.isUserAgreementAcknowledged);
    }, [vm.user.isUserAgreementAcknowledged]);

    // update state and viewModel
    function onChanged(checked: boolean) {
        setIsUserAgreementAcknowledged(checked);
        actions.viewChanged(vm.refreshUserAgreementUpdated(checked));
    }

    function onUserAgreementDownloaded() {
        setIsUserAgreementDownloaded(true);
    }

    function onUserAgreementMouseDown(e: any) {
        if (e.button === 1) {
            // middle mouse button downloads the link
            setIsUserAgreementDownloaded(true);
        }
    }

    return (
        <div className="card mb-2">
            <div className="card-header">User Agreement</div>
            <div className="card-body">
                <Forms.Group>
                    <ExternalLinkView
                        href={props.userAgreementLink}
                        title="Opens the User Agreement in a new browser tab or window."
                        onClick={onUserAgreementDownloaded}
                        onContextMenu={(e) => e.preventDefault()}
                        onMouseDown={(e) => onUserAgreementMouseDown(e)}
                    >
                        NEATS Secure Portal: User Agreement
                    </ExternalLinkView>
                </Forms.Group>
                <Forms.Group>
                    <Forms.Checkbox
                        id="isUserAgreementAcknowledgedField"
                        label="By clicking this checkbox, I acknowledge and agree to all of the terms of the User Agreement"
                        checked={isUserAgreementAcknowledged}
                        valid={v.applyValidity("UserAgreement")}
                        disabled={!isUserAgreementDownloaded}
                        onChange={onChanged}
                    />
                    <ValidationMessageView name="UserAgreement" validationModel={v} />
                </Forms.Group>
            </div>
        </div>
    );
}
