// framework
import { useState, ReactNode } from "react";
// other
import { Client } from "./client";
import { DownloadIcon } from "@primer/octicons-react";

interface IProps {
    id: string;
    className?: string | undefined;
    children: ReactNode;
}

export default function SecureDocumentDownloadLinkControl(props: IProps) {
    // we won't trigger a global busy state, however we'll just show the link as acknowledging the download by disabling it
    const [isBusy, setIsBusy] = useState(false);

    async function onDownloadClick(): Promise<void> {
        setIsBusy(true);

        // generate the token and get the URL
        const client = new Client();
        client.initialise([props.id]);
        await client.getToken();
        const url = client.getUrl();

        // initiate the download
        // - this opens a new browser window, but given it's a file response it will automatically close
        // - if anything goes wrong, the window is left open
        window.open(url);

        setIsBusy(false);
    }

    return (
        <div className={props.className}>
            <button
                className="btn btn-link"
                title="Download the file."
                disabled={isBusy}
                style={{ padding: 0 }}
                onClick={async (e) => {
                    await onDownloadClick();
                }}
            >
                {props.children}
            </button>
            <DownloadIcon className="ms-1" size={12} verticalAlign="text-top" />
        </div>
    );
}
