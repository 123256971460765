// Contact
import * as ContactReducers from "./controls/contact/redux/reducers";
import * as ContactModels from "./controls/contact/models/models";
import ContactSaga from "./controls/contact/redux/saga";
// Applicant
import * as ApplicantReducers from "./controls/applicant/redux/reducers";
import * as ApplicantModels from "./controls/applicant/models/models";
import ApplicantSaga from "./controls/applicant/redux/saga";
// Iafl
import * as IaflReducers from "./iafl/redux/reducers";
import * as IaflModels from "./iafl/models/models";
import IaflSaga from "./iafl/redux/saga";

// state
export interface IDraftApplicationDetailsReduxState {
    Company_Oei_DraftApplicationDetails_Controls_Contact: ContactModels.IRootViewModel;
    Company_Oei_DraftApplicationDetails_Controls_Applicant: ApplicantModels.IRootViewModel;
    Company_Oei_DraftApplicationDetails_Iafl: IaflModels.IRootViewModel;
}

// reducers
export function buildReducers() {
    return {
        Company_Oei_DraftApplicationDetails_Controls_Contact: ContactReducers.reducer,
        Company_Oei_DraftApplicationDetails_Controls_Applicant: ApplicantReducers.reducer,
        Company_Oei_DraftApplicationDetails_Iafl: IaflReducers.reducer,
    };
}

// sagas
export function buildSagas(): Array<any> {
    return [ContactSaga, ApplicantSaga, IaflSaga];
}
