// redux
import * as Models from "../../models/models";
import * as Actions from "../../redux/actions";
// common
import * as Forms from "../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import ResetPasswordActionController from "../../../../../shell/signIn/ResetPasswordActionController";

export default function UserDetailsView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }): React.ReactElement {
    const rootViewModel = props.vm;
    const secureUser = props.vm.secureUser;
    const actions = props.actions;

    // view
    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Geoscience Australia User Details</h2>
                    <Forms.Row>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="usernameField" label="Username" value={secureUser.username} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.Element label="Email">
                                <a href={"mailto:" + secureUser.emailAddress}>{secureUser.emailAddress}</a>
                            </Forms.Element>
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="fullNameField" label="Full Name" value={secureUser.fullName} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.Element label="Correspondence Email">
                                <a href={"mailto:" + secureUser.correspondenceEmailAddress}>{secureUser.correspondenceEmailAddress}</a>
                            </Forms.Element>
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="positionField" label="Position" value={secureUser.position} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="contactNumberField" label="Contact Number" value={secureUser.contactNumber} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="lastLoginField" label="Last Login (AWST)" value={GlobalHelpers.toNoptaDatetimeString(secureUser.lastLoginDatetime)} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="registrationDateField" label="Registration Date (AWST)" value={GlobalHelpers.toNoptaDatetimeString(secureUser.registrationDatetime)} />
                        </Forms.HalfColumn>
                    </Forms.Row>
                    <div className="mt-2">
                        <button
                            className="btn btn-outline-primary me-2"
                            type="button"
                            onClick={() => {
                                actions.viewChanged(rootViewModel.setEditState(true));
                            }}
                        >
                            Edit Details
                        </button>

                        <ResetPasswordActionController />
                    </div>
                </div>
            </div>
        </>
    );
}
