// common
import * as Forms from "../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";

export default function TabsView(props: { cardTitle: string; className?: string | undefined }) {
    // return
    return (
        <div className={props.className}>
            <div className="card" style={{ minWidth: 250 }}>
                <div className="card-body">
                    <h4 className="card-title">{props.cardTitle}</h4>

                    {/* application fee */}
                    <div className="mb-4">
                        <Forms.Group>
                            <Forms.ReadonlyField label="Application Fee" id="applicationFeeEstimateField" value={GlobalHelpers.toNoptaNumberString(0, "c")} />
                        </Forms.Group>
                    </div>
                </div>
            </div>
        </div>
    );
}
