// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    reprocessingId?: number;
    reprocessingName?: string;
    attachments: Client.GetGeoscienceAustraliaReportAttachmentsItemDto[];
    projectIdentifiers: Client.GetGeoscienceAustraliaProjectIdentifierDto[];
    isConflict: boolean;
    statusMessages?: Client.StatusMessagesDto;

    refreshReprocessingReportAttachments(response: Client.GetGeoscienceAustraliaReprocessingReportAttachmentsResponseDto): IRootViewModel;
    refreshConflict(isConflict: boolean): IRootViewModel;
    refreshStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.attachments = [];
        this.projectIdentifiers = [];
        this.isConflict = false;
    }

    public reprocessingId?: number;
    public reprocessingName?: string;
    public attachments: Client.GetGeoscienceAustraliaReportAttachmentsItemDto[];
    public projectIdentifiers: Client.GetGeoscienceAustraliaProjectIdentifierDto[];
    public isConflict: boolean;
    public statusMessages?: Client.StatusMessagesDto;

    public refreshReprocessingReportAttachments(response: Client.GetGeoscienceAustraliaReprocessingReportAttachmentsResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.reprocessingId = response.reprocessingId;
        vm.reprocessingName = response.reprocessingName;
        vm.attachments = response.attachments;
        vm.projectIdentifiers = response.projectIdentifiers;
        vm.isConflict = false;
        vm.statusMessages = new Client.StatusMessagesDto();

        return vm;
    }

    public refreshConflict(isConflict: boolean): IRootViewModel {
        const vm = this._clone();

        vm.isConflict = isConflict;

        return vm;
    }

    public refreshStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel {
        const vm = this._clone();

        vm.statusMessages = statusMessages;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.attachments = clone(this.attachments);
        return vm;
    }
}
