// redux
import * as Models from "../../models/models";
import * as Actions from "../../redux/actions";
// common
import { ValidationModel } from "../../../../../../../common/validation/ValidationModel";
import SimpleAuditEventsView from "../../../../../../../common/audit/SimpleAuditEventsView";
// views
import TitleholderForeignInvestmentApprovalGridView from "./TitleholderForeignInvestmentApprovalGridView";

export default function TitleholderForeignInvestmentDetailsView(props: { vm: Models.IRootViewModel; v: ValidationModel; actions: Actions.IActionFactory }): React.ReactElement {
    const vm = props.vm;
    const validation = props.v;
    const actions = props.actions;

    return (
        <>
            <p>Details must be set for all Companies in order to proceed.</p>
            <TitleholderForeignInvestmentApprovalGridView vm={vm} actions={actions} v={validation} />
            <p>
                If applicable, applicants are responsible for obtaining foreign investment approval. Confirmation of foreign investment approval should be provided to NOPTA with submission of an
                applicable petroleum production licence application. If it is not available at the time of submission, it may be provided separately once received. Applicants should be aware that the
                Joint Authority will not make a decision on the application whilst a foreign investment decision is pending.
            </p>
            <SimpleAuditEventsView className="mt-4" simpleAuditEvents={vm.audit} />
        </>
    );
}
