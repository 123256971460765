// framework
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
// redux
import * as Models from "../../models/models";
import * as Actions from "../../redux/actions";
// children views
import DetailsView from "./DetailsView";

export enum TabsEnum {
    Details,
}

// note: whilst preferred tab isn't used, it is likely this will be needed down the track.
export default function TabsView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; preferredTab?: TabsEnum; lastRefreshedDatetime: Date | undefined }) {
    const vm = props.vm;
    const a = props.actions;

    const preferredTab = props.preferredTab ?? TabsEnum.Details;

    return (
        <Tabs defaultActiveKey={preferredTab} variant="pills" mountOnEnter className="mb-2">
            {/* Details Tab */}
            <Tab eventKey={TabsEnum.Details} title="Details">
                <DetailsView vm={vm} actions={a} lastRefreshedDatetime={props.lastRefreshedDatetime} />
            </Tab>
        </Tabs>
    );
}
