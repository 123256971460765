// redux
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";
// common
import * as GlobalHelpers from "../../../../../../../common/GlobalHelpers";

export async function getCompanyCommonDraftApplicationDetailsFormDocumentation(id: number): Promise<Client.GetCompanyCommonDraftApplicationDetailsFormDocumentationResponseDto> {
    // console.info("getCompanyCommonDraftApplicationDetailsFormDocumentation");
    const client = await ClientFactory.createCompanyCommonDraftApplicationDetailsClient();
    return await CallHelper.simpleCall(() =>
        client.getCompanyCommonDraftApplicationDetailsFormDocumentation(new Client.GetCompanyCommonDraftApplicationDetailsFormDocumentationRequestDto({ id: id }))
    );
}

export async function saveCompanyCommonDraftApplicationDetailsWetFormDocumentation(
    vm: Models.IRootViewModel
): Promise<CallHelper.CallResult<Client.SaveCompanyCommonDraftApplicationDetailsWetFormDocumentationResponseDto>> {
    const client = await ClientFactory.createCompanyCommonDraftApplicationDetailsClient();
    return await CallHelper.call(() =>
        client.saveCompanyCommonDraftApplicationDetailsWetFormDocumentation(
            new Client.SaveCompanyCommonDraftApplicationDetailsWetFormDocumentationRequestDto({
                id: vm.id!,
                versionNumber: vm.versionNumber!,
                fileIds: vm.wetSignatureDetails.files.map((f) => f.fileId),
            })
        )
    );
}

export async function saveCompanyCommonDraftApplicationDetailsDigitalSignature(
    vm: Models.IRootViewModel
): Promise<CallHelper.CallResult<Client.SaveCompanyCommonDraftApplicationDetailsDigitalSignatureResponseDto>> {
    const client = await ClientFactory.createCompanyCommonDraftApplicationDetailsClient();
    return await CallHelper.call(() =>
        client.saveCompanyCommonDraftApplicationDetailsDigitalSignature(
            new Client.SaveCompanyCommonDraftApplicationDetailsDigitalSignatureRequestDto({
                id: vm.id!,
                versionNumber: vm.versionNumber!,
                companyId: vm.digitalSignatureDetails.newCompanySignatureDetails.signingFor!.companyId,
                digitalSignatureType: vm.digitalSignatureDetails.newCompanySignatureDetails.signingAs!.type,
                clientOffsetMinutes: GlobalHelpers.getDateOffsetMinutes(new Date())!, // user's current timezone
                poaLocation: vm.digitalSignatureDetails.newCompanySignatureDetails.poaLocation,
                poaPosition: vm.digitalSignatureDetails.newCompanySignatureDetails.poaPosition,
                isPoaPreviouslyProvided: vm.digitalSignatureDetails.newCompanySignatureDetails.isPoaPreviouslyProvided,
                poaDate: vm.digitalSignatureDetails.newCompanySignatureDetails.poaDate,
                poaDateOffsetMinutes: GlobalHelpers.getDateOffsetMinutes(vm.digitalSignatureDetails.newCompanySignatureDetails.poaDate),
            })
        )
    );
}

export async function generateCompanyCommonDraftApplicationDetailsDraftApplicationFormDocument(
    id: number
): Promise<Client.GenerateCompanyCommonDraftApplicationDetailsDraftApplicationFormDocumentResponseDto> {
    const client = await ClientFactory.createCompanyCommonDraftApplicationDetailsClient();
    return await CallHelper.simpleCall(() =>
        client.generateCompanyCommonDraftApplicationDetailsDraftApplicationFormDocument(new Client.GenerateCompanyCommonDraftApplicationDetailsDraftApplicationFormDocumentRequestDto({ id: id }))
    );
}
