// framework
import { useEffect, useState } from "react";
// kendo
import { GridColumn } from "@progress/kendo-react-grid";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/rootViewModel";
import { IRequestDetailsRoleViewModel } from "../../models/requestDetailsRoleViewModel";
// common
import { RoleCategoryEnum } from "../../../../../common/roleGrid/RoleViewModel";
import RoleGrid, { RoleGridModeEnum } from "../../../../../common/roleGrid/RoleGrid";
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
import * as Validation from "../../../../../common/validation/ValidationModel";
import * as Forms from "../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import * as AlertsHelper from "../../../../../common/alerts/AlertsHelper";

export default function CompanyStandardRoleDetailsView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; validation: Validation.IValidationModel }): React.ReactElement {
    const vm = props.vm;
    const actions = props.actions;
    const v = props.validation;
    const roles = vm.companyStandardRoleDetails!;

    // standard role decision state
    const [state, setState] = useState<Models.ISecureRequestCompanyStandardRoleDecisionViewModel>({
        isApproved: undefined,
    });
    // update decision if changed
    useEffect(() => {
        setState({
            isApproved: roles.isApproved,
        });
    }, [roles]);

    // approve / reject radio button state
    const isApprovedRadioValue = "isStandardRoleApproved";
    const isRejectedRadioValue = "isStandardRoleRejected";
    const [approveRejectRadioValue, setApproveRejectRadioValue] = useState<string | undefined>(undefined);
    // update selection if changed
    useEffect(() => {
        setApproveRejectRadioValue(GlobalHelpers.convertBooleanToRadioValue(roles.isApproved, isApprovedRadioValue, isRejectedRadioValue));
    }, [roles.isApproved]);

    // display alert message
    const alertMessages: string[] = [];

    if (vm.isNewUserRequest) alertMessages.push("Approving this request will create a new Secure Portal user account.");

    if (vm.isCompanyStandardRoleRequest && !roles.requestedRoles!.hasRole()) {
        const noRolesRequestedMessage = `No general permissions have been requested. Select 'Reject' to not add any general permissions, or grant permissions and select 'Approve' to set the user up with ${
            vm.isNewUserRequest ? "an account" : "those permissions"
        } immediately.`;
        alertMessages.push(noRolesRequestedMessage);
    }

    // approve / reject radio button change
    function onApproveRejectRadioChange(value: string) {
        setApproveRejectRadioValue(value);

        if (value === isApprovedRadioValue) {
            setState({ ...state, isApproved: true });
            actions.viewChanged(vm.refreshCompanyStandardRoleDecisionChanged({ isApproved: true }));
        }
        if (value === isRejectedRadioValue) {
            setState({ ...state, isApproved: false });
            actions.viewChanged(vm.refreshCompanyStandardRoleDecisionChanged({ isApproved: false }));
        }
    }

    // grid data
    function updateGrantedRoles(updatedRole: IRequestDetailsRoleViewModel): void {
        if (updatedRole.getKey() !== RoleCategoryEnum.Granted) throw new Error("Invalid field update.");
        actions.viewChanged(vm.refreshCompanyStandardRolesGrantedChanged(updatedRole));
    }

    // view
    return (
        <div className="card mb-2">
            <div className="card-body">
                {alertMessages.length > 0 && AlertsHelper.renderInfo(alertMessages)}
                <h2 className="card-title">Permissions Requested</h2>
                <h4 className="card-title">Company: {vm.summary.companyName}</h4>
                <Forms.Group>
                    <RoleGrid
                        model={vm.isNewUserRequest ? [roles.requestedRoles!, roles.grantedRoles!] : [roles.existingRoles!, roles.requestedRoles!, roles.grantedRoles!]}
                        roleGroups={vm.roleConfiguration.roleGroups}
                        mode={state.isApproved === false ? RoleGridModeEnum.Readonly : RoleGridModeEnum.Editable}
                        onRoleChange={updateGrantedRoles}
                        preColumns={<GridColumn key="category" field="category" title=" " width={200} />}
                    />
                </Forms.Group>
                <Forms.Group>
                    <Forms.RadioButton
                        name="isStandardRoleApprovedGroup"
                        id="isStandardRoleApprovedRadioField"
                        label="Approve"
                        value={isApprovedRadioValue}
                        checked={approveRejectRadioValue === isApprovedRadioValue}
                        valid={v.applyValidity("CompanyStandardRoleDetails.IsApproved")}
                        onChange={onApproveRejectRadioChange}
                        inline
                    />
                    <Forms.RadioButton
                        name="isStandardRoleApprovedGroup"
                        id="isStandardRoleRejectedRadioField"
                        label="Reject"
                        value={isRejectedRadioValue}
                        checked={approveRejectRadioValue === isRejectedRadioValue}
                        valid={v.applyValidity("CompanyStandardRoleDetails.IsApproved")}
                        onChange={onApproveRejectRadioChange}
                        inline
                    />
                    <ValidationMessageView name="CompanyStandardRoleDetails.IsApproved" validationModel={v} />
                </Forms.Group>
            </div>
        </div>
    );
}
