// framework
import { Link } from "react-router-dom";
// models
import { IRootViewModel } from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import SimpleAuditEventsView from "../../../../../../common/audit/SimpleAuditEventsView";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";

interface IProps {
    vm: IRootViewModel;
}

export default function ReprocessingProjectDetailsView(props: IProps): JSX.Element {
    const { vm } = props;
    const reprocessingProjectDetails = vm.reprocessingProjectDetails;

    return (
        <>
            {reprocessingProjectDetails && (
                <>
                    <div className="card mb-2">
                        <div className="card-body">
                            <h2 className="card-title">General</h2>
                            <Forms.Row>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="projectIdentifierField" label="Project ID" value={reprocessingProjectDetails.identifier} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="projectNameField" label="Name" value={reprocessingProjectDetails.name} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="reprocessingNameField" label="Reprocessing Name" value={reprocessingProjectDetails.reprocessingName} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.Element label="Reprocessing ID">
                                        {reprocessingProjectDetails.reprocessingId && (
                                            <Link to={geoscienceAustraliaRouteHelper.reprocessingDetails(reprocessingProjectDetails.reprocessingId!)} title="Click to view Reprocessing details.">
                                                {reprocessingProjectDetails.reprocessingIdentifier}
                                            </Link>
                                        )}
                                    </Forms.Element>
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="reprocessingTypeField" label="Reprocessing Type" value={reprocessingProjectDetails.reprocessingType} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="contractorField" label="Contractor" value={reprocessingProjectDetails.contractor} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="jurisdictionField" label="Jurisdiction" value={reprocessingProjectDetails.nopimsJurisdiction} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn></Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="reprocessingLicenceTypeField" label="Licenced Reprocessing" value={reprocessingProjectDetails.reprocessingLicenceType} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="nopimsExclusivityStatusField" label="Exclusivity" value={reprocessingProjectDetails.nopimsExclusivityStatus} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField
                                        id="finalDeliverableDateField"
                                        label="Final Deliverable Date"
                                        value={GlobalHelpers.toNoptaDateString(reprocessingProjectDetails.finalDeliverableDate)}
                                    />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField
                                        id="permitYearEndDateField"
                                        label="Permit Year End Date"
                                        value={GlobalHelpers.toNoptaDateString(reprocessingProjectDetails.permitYearEndDate)}
                                    />
                                </Forms.QuarterColumn>
                                <Forms.FullColumn>
                                    <Forms.ReadonlyTextArea
                                        id="licencedReprocessingCommentsField"
                                        label="Licenced Reprocessing Comment"
                                        value={reprocessingProjectDetails.reprocessingLicenceComment}
                                    />
                                </Forms.FullColumn>
                            </Forms.Row>
                        </div>
                    </div>
                    <div className="card mb-2">
                        <div className="card-body">
                            <h2 className="card-title">Spatial</h2>
                            <Forms.Row>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField
                                        id="totalLinesProcessedField"
                                        label="Total Lines Processed (km)"
                                        value={GlobalHelpers.toNoptaNumberString(reprocessingProjectDetails.totalLineProcessedKm, "n2")}
                                    />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField
                                        id="totalAreaProcessedField"
                                        label="Total Area Processed (km²)"
                                        value={GlobalHelpers.toNoptaNumberString(reprocessingProjectDetails.totalAreaProcessedKm2, "n2")}
                                    />
                                </Forms.QuarterColumn>
                            </Forms.Row>
                        </div>
                    </div>
                    <div className="card mb-2">
                        <div className="card-body">
                            <h2 className="card-title">Dates</h2>
                            <Forms.Row>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField
                                        id="basicRelevantDateField"
                                        label="Basic Relevant Date"
                                        value={GlobalHelpers.toNoptaDateString(reprocessingProjectDetails.basicRelevantDate)}
                                    />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="basicReleaseDateField" label="Basic Release Date" value={GlobalHelpers.toNoptaDateString(reprocessingProjectDetails.basicReleaseDate)} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField
                                        id="interpretativeRelevantDateField"
                                        label="Interpretative Relevant Date"
                                        value={GlobalHelpers.toNoptaDateString(reprocessingProjectDetails.interpretativeRelevantDate)}
                                    />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField
                                        id="interpretativeReleaseDateField"
                                        label="Interpretative Release Date"
                                        value={GlobalHelpers.toNoptaDateString(reprocessingProjectDetails.interpretativeReleaseDate)}
                                    />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn />
                                <Forms.QuarterColumn />
                                <Forms.QuarterColumn />
                                <Forms.QuarterColumn>
                                    <Forms.Checkbox
                                        id="isAutoCalculateDateField"
                                        label="Auto Calculate Dates"
                                        checked={reprocessingProjectDetails.isAutoCalculateDates}
                                        disabled={true}
                                        onChange={(v) => {}}
                                    />
                                </Forms.QuarterColumn>
                            </Forms.Row>
                        </div>
                    </div>
                    <div className="card mb-2">
                        <div className="card-body">
                            <h2 className="card-title">Comments</h2>
                            <Forms.Row>
                                <Forms.FullColumn>
                                    <Forms.ReadonlyTextArea id="commentsField" label="Comments" value={reprocessingProjectDetails.comments} />
                                </Forms.FullColumn>
                                <Forms.FullColumn>
                                    <Forms.ReadonlyTextArea id="publicCommentsField" label="Public Comments" value={reprocessingProjectDetails.publicComments} />
                                </Forms.FullColumn>
                                <Forms.FullColumn>
                                    <Forms.ReadonlyTextArea id="releaseCommentsField" label="Release Comments" value={reprocessingProjectDetails.releaseComments} />
                                </Forms.FullColumn>
                            </Forms.Row>
                        </div>
                    </div>
                    <div className="card mb-2">
                        <div className="card-body">
                            <h2 className="card-title">Confidentiality</h2>
                            <Forms.Row>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField
                                        id="confidentialityField"
                                        label="Effective Status"
                                        value={GlobalHelpers.convertBooleanToConfidentialityValue(reprocessingProjectDetails.confidentiality.isConfidential)}
                                    />
                                </Forms.QuarterColumn>
                                {reprocessingProjectDetails.confidentiality.isUserOverridden && (
                                    <Forms.QuarterColumn>
                                        <Forms.ReadonlyField
                                            id="systemConfidentialityField"
                                            label="System Status"
                                            value={GlobalHelpers.convertBooleanToConfidentialityValue(reprocessingProjectDetails.confidentiality.isSystemConfidential)}
                                        />
                                    </Forms.QuarterColumn>
                                )}
                                {reprocessingProjectDetails.confidentiality.isUserOverridden && (
                                    <Forms.QuarterColumn>
                                        <Forms.ReadonlyField
                                            id="userConfidentialityField"
                                            label="User Status"
                                            value={GlobalHelpers.convertBooleanToConfidentialityValue(reprocessingProjectDetails.confidentiality.isUserConfidential)}
                                        />
                                    </Forms.QuarterColumn>
                                )}
                                {reprocessingProjectDetails.confidentiality.isUserOverridden && (
                                    <Forms.FullColumn>
                                        <Forms.ReadonlyTextArea id="confidentialityCommentsField" label="Confidentiality Comments" value={reprocessingProjectDetails.confidentiality.comments} />
                                    </Forms.FullColumn>
                                )}
                            </Forms.Row>
                            <div className="mt-2">
                                <SimpleAuditEventsView simpleAuditEvents={reprocessingProjectDetails.confidentiality.audit} />
                            </div>
                        </div>
                    </div>
                    <SimpleAuditEventsView simpleAuditEvents={reprocessingProjectDetails.audit} />
                </>
            )}
        </>
    );
}
