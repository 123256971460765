// framework
import { Link } from "react-router-dom";
// bootstrap
import { Badge } from "react-bootstrap";

export default function BadgeView(props: { text: string; title: string; variant: string; link?: string | undefined }) {
    return props.link ? (
        <Link to={props.link} title={props.title} className="me-1">
            <Badge pill bg={props.variant}>
                {props.text}
            </Badge>
        </Link>
    ) : (
        <Badge pill bg={props.variant} title={props.title} className="me-1">
            {props.text}
        </Badge>
    );
}
