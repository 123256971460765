// framework
import { useState, useRef, useEffect } from "react";
import coordinator from "./coordinator";
import { Upload, UploadFileInfo, UploadOnAddEvent, UploadOnCancelEvent, UploadOnProgressEvent, UploadOnRemoveEvent, UploadOnStatusChangeEvent } from "@progress/kendo-react-upload";
import { logError } from "../../common/LogHelper";
import SimpleAlertView from "../simpleAlerts/SimpleAlertView";
import * as GlobalHelpers from "../GlobalHelpers";

interface IProps {
    container: string;
    allowedExtensions: string[];
    maximumFileSizeMb?: number | undefined;
    onFilesUploaded?: (files: Array<IFile>) => void;
    onFileUploaded?: (file: IFile) => void;
    onBusyChanged: (isBusy: boolean) => void;
    className?: string | undefined;
    isSingleFile?: boolean | undefined;
}

export interface IFile {
    id: number;
    fileName: string;
    size: number;
}

export default function SecureFileUploadControl(props: IProps) {
    const container = props.container;

    // currently hard coding these constants
    // a minimum of 1 byte ensures empty files don't get uploaded.
    // a maximum of 1GB is specified for now, it's an arbitrary value.  the technical storage limit is 100GB.
    const minFileSizeBytes: number = 1; // 1 byte
    const maxFileSizeBytes: number = props.maximumFileSizeMb ? GlobalHelpers.convertMbToBytes(props.maximumFileSizeMb!) : GlobalHelpers.convertMbToBytes(1 * 1024); // 1GB

    const [errors, setErrors] = useState(new Array<string>());
    const [files, setFiles] = useState(new Array<UploadFileInfo>());
    const [successfulUploadUids, setSuccessfulUploadUids] = useState(new Array<string>());
    const successfulUploads = useRef(new Array<IFile>());
    const isBusyRef = useRef(false);

    useEffect(() => {
        // remove successful uploads
        const successfulFiles = files.filter((f) => !successfulUploadUids.includes(f.uid));
        if (successfulFiles.length === files.length) return; // if nothing changed, carry on
        setSuccessfulUploadUids(new Array<string>());
        setFiles(successfulFiles);
    }, [successfulUploadUids, files]);

    function onChange(event: UploadOnAddEvent | UploadOnRemoveEvent | UploadOnProgressEvent | UploadOnStatusChangeEvent) {
        setFiles(event.newState);
    }

    function onUploadRequested(
        files: UploadFileInfo[],
        options: { formData: FormData; requestOptions: any },
        onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
    ): Promise<{ uid: string }> {
        if (files.length !== 1) throw new Error("Only one file is supported! (batch={false})");
        const currentFile = files[0] as UploadFileInfo;
        const uid = currentFile.uid;
        console.debug(`SecureFileUpload/control: onUploadRequested(${uid})`);

        return new Promise<{ uid: string }>(async (resolve, reject) => {
            // as currently configured it is impossible to request a save from the UI if there are validation errors, but that can be changed so keeping this check in place
            if (currentFile.validationErrors && currentFile.validationErrors.length > 0) {
                reject({ uid: uid });
                return;
            }

            try {
                // get the file
                const file = currentFile.getRawFile!();

                // create and initialise the client
                // - this initialises the upload at the server
                const client = await coordinator.CreateNew(uid);
                updateBusy();
                await client.initialise(
                    container,
                    file,
                    (id: number, uploaded: number, total: number) => {
                        onProgress(uid, new ProgressEvent("progress", { lengthComputable: true, loaded: uploaded, total: total }));
                    },
                    (message) => {
                        setErrors((errors) => [...errors, message]);
                    }
                );

                // start uploading
                // - this repeatedly uploads chunks until complete
                // - will mark the upload as finalised
                const success = await client.upload();

                // mark as completed
                resolve({ uid: uid });

                if (success) {
                    const f = { id: client.getId(), fileName: file.name, size: file.size };
                    // report it as complete
                    if (props.onFileUploaded) props.onFileUploaded!(f);
                    // capture for the completed event
                    successfulUploads.current = [...successfulUploads.current, f];
                    // remove from the list of files
                    setSuccessfulUploadUids((successfulUploadUids) => [...successfulUploadUids, uid]);
                }
            } catch (ex) {
                // on exception, log and fail the upload
                logError(ex);
                reject({ uid: uid });
            } finally {
                coordinator.TryRemove(uid);
                updateBusy();
            }
        });
    }

    function onCancelRequested(event: UploadOnCancelEvent) {
        console.debug(`SecureFileUpload/control: onCancelRequested(${event.uid})`);

        const client = coordinator.TryGetExisting(event.uid);
        if (!client) return;
        client.cancel();
        coordinator.TryRemove(event.uid);
        updateBusy();
    }

    function onRemoveRequested(files: UploadFileInfo[], options: { formData: FormData; requestOptions: any }): Promise<{ uid: string }> {
        // removing a file does nothing other than dismissing it from the UI
        // the file has already been uploaded and associated with whatever entity needs it

        if (files.length !== 1) throw new Error("Only one file is supported! (batch={false})");
        const currentFile = files[0] as UploadFileInfo;
        const uid = currentFile.uid;
        console.debug(`SecureFileUpload/control: onRemoveRequested(${uid})`);

        return Promise.resolve({ uid: uid });
    }

    function updateBusy() {
        const isBusy = coordinator.IsActive();

        if (isBusy !== isBusyRef.current) {
            props.onBusyChanged(isBusy);

            // this logic is somewhat redundant, but trying to make it very clear
            // we only want the Queue Completed event to fire when the queue is empty when previously it wasn't
            if (isBusy === false && isBusyRef.current === true) {
                if (props.onFilesUploaded) props.onFilesUploaded!(successfulUploads.current);
                successfulUploads.current = new Array<IFile>();
            }

            isBusyRef.current = isBusy;
        }
    }

    return (
        <div className={props.className}>
            {errors.map((e, i) => (
                <SimpleAlertView key={i} alertType="error" message={e}></SimpleAlertView>
            ))}

            <Upload
                autoUpload={true}
                batch={false}
                files={files}
                multiple={!props.isSingleFile}
                onAdd={onChange}
                onCancel={onCancelRequested}
                onProgress={onChange}
                onRemove={onChange}
                onStatusChange={onChange}
                withCredentials={false}
                removeUrl={onRemoveRequested}
                saveUrl={onUploadRequested}
                restrictions={{ allowedExtensions: props.allowedExtensions, minFileSize: minFileSizeBytes, maxFileSize: maxFileSizeBytes }}
            ></Upload>

            <div className="d-flex flex-row flex-wrap small">
                {props.isSingleFile && (
                    <div className="d-flex flex-row me-2 mb-2">
                        <span className="label fw-bold me-1">Maximum Number of Files: </span>
                        <span>1</span>
                    </div>
                )}
                {props.allowedExtensions && (
                    <div className="d-flex flex-row me-2 mb-2">
                        <span className="label fw-bold me-1">Supported File Types: </span>
                        <span>{props.allowedExtensions.join(", ")}.</span>
                    </div>
                )}
                {props.maximumFileSizeMb && (
                    <div className="d-flex flex-row me-2 mb-2">
                        <span className="label fw-bold me-1">Maximum File Size: </span>
                        <span>{GlobalHelpers.convertMegabytesToString(props.maximumFileSizeMb)}</span>
                    </div>
                )}
            </div>
        </div>
    );
}
