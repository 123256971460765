import * as Models from "../models/models";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "company.licence.licenceDetails.initialiseRequested",
    clearRequested = "company.licence.licenceDetails.clearRequested",
    getApplicationDocumentRequested = "company.licence.licenceDetails.getApplicationDocumentRequested",
    clearApplicationDocumentRequested = "company.licence.licenceDetails.clearApplicationDocumentRequested",
    // user input
    viewChanged = "company.licence.licenceDetails.viewChanged",
    // saga events
    sagaCompleted = "company.licence.licenceDetails.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

export interface ILicenceApplicationKeyPayload {
    licenceId: string;
    applicationId: string;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(licenceId: string): IAction<string>;
    clearRequested(): IAction<undefined>;
    getApplicationDocumentRequested(licenceId: string, applicationId: string): IAction<ILicenceApplicationKeyPayload>;
    clearApplicationDocumentRequested(): IAction<undefined>;
    // user input
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    // saga events
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (licenceId: string): IAction<string> {
        return {
            type: actions.initialiseRequested,
            payload: licenceId,
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    getApplicationDocumentRequested: function (licenceId: string, applicationId: string): IAction<ILicenceApplicationKeyPayload> {
        return {
            type: actions.getApplicationDocumentRequested,
            payload: { licenceId, applicationId },
        };
    },
    clearApplicationDocumentRequested: function (): IAction<undefined> {
        return {
            type: actions.clearApplicationDocumentRequested,
            payload: undefined,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
