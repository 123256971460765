// common
import * as Validation from "../../../../../common/validation/ValidationModel";
// models
import { IRootViewModel } from "./models";

export default function validate(vm: IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    const saveSurveyDataLine = vm.saveSurveyDataLine;

    if (!saveSurveyDataLine!.lineName?.trim()) {
        v.addError("LineName", "Line Name is required.");
    }

    if (!saveSurveyDataLine!.fsl?.trim()) {
        v.addError("Fsl", "First Shot Point is required.");
    } else if (saveSurveyDataLine!.fsl.length > 100) {
        v.addError("Fsl", "First Shot Point must be 100 characters or less.");
    }

    if (!saveSurveyDataLine!.lsl?.trim()) {
        v.addError("Lsl", "Last Shot Point is required.");
    } else if (saveSurveyDataLine!.lsl.length > 100) {
        v.addError("Lsl", "Last Shot Point must be 100 characters or less.");
    }

    if (saveSurveyDataLine!.lineComments && saveSurveyDataLine!.lineComments.length > 4000) {
        v.addError("LineComments", "Line Comments must be 4000 characters or less.");
    }

    return v;
}
