// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../api/Client";

export enum ViewStateEnum {
    Initialising,
    View,
}

export interface IApplicationLayoutViewModel {
    hasTitleholders: boolean;
    hasBlockDetails: boolean;
    hasWorkProgram: boolean;
}

export interface IApplicationHeaderViewModel {
    applicationTypeName?: string | undefined;
    trackingNumber?: string | undefined;
    applicationStatus?: string | undefined;
    applicationStatusDatetime?: Date | undefined;
    applicationName?: string | undefined;
    submittedDate?: Date | undefined;
    titlesLabel?: string | undefined;
    titleLinks?: Array<Client.TitleLinkDto> | undefined;
}

export interface IRootViewModel {
    id?: string | undefined;
    layout: IApplicationLayoutViewModel;
    header: IApplicationHeaderViewModel;

    viewState: ViewStateEnum;

    refreshLayoutAndHeader(response: Client.GetJointAuthorityOpggsApplicationDetailsLayoutAndHeaderResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.id = undefined;
        this.layout = {
            hasTitleholders: false,
            hasBlockDetails: false,
            hasWorkProgram: false,
        };
        this.header = {};

        this.viewState = ViewStateEnum.Initialising;
    }

    public id: string | undefined;
    public layout: IApplicationLayoutViewModel;
    public header: IApplicationHeaderViewModel;

    public viewState: ViewStateEnum;

    public refreshLayoutAndHeader(response: Client.GetJointAuthorityOpggsApplicationDetailsLayoutAndHeaderResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.id = response.applicationId;

        // layout
        vm.layout.hasTitleholders = response.hasTitleholders;
        vm.layout.hasBlockDetails = response.hasBlockDetails;
        vm.layout.hasWorkProgram = response.hasWorkProgram;
        // header
        vm.header.applicationTypeName = response.applicationTypeName;
        vm.header.trackingNumber = response.trackingNumber;
        vm.header.applicationStatus = response.applicationStatus;
        vm.header.applicationStatusDatetime = response.applicationStatusDatetime;
        vm.header.applicationName = response.applicationName;
        vm.header.submittedDate = response.submittedDate;
        vm.header.titlesLabel = response.titlesLabel;
        vm.header.titleLinks = response.titleLinks;

        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);

        vm.layout = clone(this.layout);
        vm.header = clone(this.header);

        return vm;
    }
}
