// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../../api/Client";

export interface IApplicantDetailsViewModel {
    companyName: string;
    acnOrArbn: string;
    foreignInvestmentApprovalType: Client.ForeignInvestmentApprovalTypeDto | undefined;
    isApplicantEligibleUnderSection8: boolean | undefined;
}

export interface IRootViewModel {
    id: number | undefined;
    versionNumber: number | undefined;

    applicantDetails: IApplicantDetailsViewModel;

    availableForeignInvestmentApprovalTypes: Client.ForeignInvestmentApprovalTypeDto[];

    viewState: ViewStateEnum;
    isDirty: boolean;
    isConflict: boolean;
    statusMessages: Client.StatusMessagesDto | undefined;
    audit: Client.SecurePortalSimpleAuditEventsDto | undefined;

    refreshDetails(id: number, response: Client.GetCompanyOeiDraftApplicationDetailsApplicantResponseDto): IRootViewModel;
    refreshSaveStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel;
    refreshConflict(): IRootViewModel;
    onInitialised(): IRootViewModel;
    onSaved(): IRootViewModel;
    onEdit(): IRootViewModel;
    onChange(values: IApplicantDetailsViewModel): IRootViewModel;
}

export enum ViewStateEnum {
    Initialising,
    View,
    Edit,
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.id = undefined;
        this.versionNumber = undefined;

        this.applicantDetails = {
            companyName: "",
            acnOrArbn: "",
            foreignInvestmentApprovalType: undefined,
            isApplicantEligibleUnderSection8: undefined,
        };

        this.availableForeignInvestmentApprovalTypes = [];

        this.viewState = ViewStateEnum.Initialising;
        this.isDirty = false;
        this.isConflict = false;
        this.statusMessages = undefined;
        this.audit = undefined;
    }

    public id: number | undefined;
    public versionNumber: number | undefined;

    public applicantDetails: IApplicantDetailsViewModel;

    public availableForeignInvestmentApprovalTypes: Client.ForeignInvestmentApprovalTypeDto[];

    public viewState: ViewStateEnum;
    public isDirty: boolean;
    public isConflict: boolean;
    public statusMessages: Client.StatusMessagesDto | undefined;
    public audit: Client.SecurePortalSimpleAuditEventsDto | undefined;

    public refreshDetails(id: number, response: Client.GetCompanyOeiDraftApplicationDetailsApplicantResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.id = id;
        vm.versionNumber = response.versionNumber;

        vm.applicantDetails.companyName = response.companyName;
        vm.applicantDetails.acnOrArbn = response.acnOrArbn;
        vm.applicantDetails.foreignInvestmentApprovalType = response.foreignInvestmentApprovalType;
        vm.applicantDetails.isApplicantEligibleUnderSection8 = response.isApplicantEligibleUnderSection8;

        vm.availableForeignInvestmentApprovalTypes = response.availableForeignInvestmentApprovalTypes;

        vm.isDirty = false;
        vm.isConflict = false;
        vm.audit = response.lastModifiedAudit;

        return vm;
    }

    public refreshSaveStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.statusMessages = statusMessages;
        if (statusMessages.isSuccess) {
            vm.isDirty = false;
        }

        return vm;
    }

    public refreshConflict(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.statusMessages = undefined;
        vm.isConflict = true; // this is only reset on initialise from the reducer

        return vm;
    }

    public onInitialised(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Initialising) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    public onSaved(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    public onEdit(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.View) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.Edit;
        vm.isDirty = false;
        vm.statusMessages = undefined;

        return vm;
    }

    public onChange(values: IApplicantDetailsViewModel): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.applicantDetails = values;
        vm.isDirty = true;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.applicantDetails = clone(this.applicantDetails);
        return vm;
    }
}
