// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    surveyId: number | undefined;
    acquisitionId: number | undefined;
    surveyName: string | undefined;
    acquisitionName: string | undefined;

    acquisitions: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersResponseAcquisitionDto[];
    items: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersResponseParameterDto[];

    canViewAcquisitions: boolean;

    refreshSurveyAcquisitionParameters(response: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.surveyId = undefined;
        this.acquisitionId = undefined;
        this.surveyName = undefined;
        this.acquisitionName = undefined;
        this.acquisitions = [];
        this.items = [];
        this.canViewAcquisitions = false;
    }

    public surveyId: number | undefined;
    public acquisitionId: number | undefined;
    public surveyName: string | undefined;
    public acquisitionName: string | undefined;
    public acquisitions: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersResponseAcquisitionDto[];
    public items: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersResponseParameterDto[];
    public audit: Client.SecurePortalSimpleAuditEventsDto | undefined;

    public canViewAcquisitions: boolean;

    public refreshSurveyAcquisitionParameters(response: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.surveyId = response.surveyId;
        vm.surveyName = response.surveyName;
        vm.acquisitions = response.acquisitions;
        vm.items = response.parameter;
        vm.canViewAcquisitions = response.canViewAcquisition;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.items = clone(this.items);
        return vm;
    }
}
