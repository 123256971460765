// framework
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
// redux
import * as Models from "../../../../company/oei/licenceDetails/models/models";
// children views
import LicenceSummaryView from "./LicenceSummaryView";
import MemorialView from "./MemorialView";

export enum LicenceDetailsTabsEnum {
    Summary,
    Memorial,
}

function LicenceDetailsView(props: { vm: Models.IRootViewModel; preferredTab?: LicenceDetailsTabsEnum }) {
    const vm = props.vm;

    const preferredTab = props.preferredTab ?? LicenceDetailsTabsEnum.Summary;

    return (
        <Tabs defaultActiveKey={preferredTab.toString()} variant="pills" mountOnEnter className="mb-2">
            {/* Summary Tab */}
            <Tab eventKey={LicenceDetailsTabsEnum.Summary.toString()} title="Licence Summary">
                <LicenceSummaryView vm={vm} />
            </Tab>

            {/* Memorial Tab */}
            <Tab eventKey={LicenceDetailsTabsEnum.Memorial.toString()} title="Register">
                <MemorialView vm={vm} />
            </Tab>
        </Tabs>
    );
}

export default LicenceDetailsView;
