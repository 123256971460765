// framework
import React from "react";
import { Link } from "react-router-dom";
// api
import * as Client from "../../api/Client";
// common
import * as companyRouteHelper from "../../company/companyRouteHelper";

interface IControllerProps {
    linkDto: Client.FinancialNotificationLinkDto;
    secureEntityType: "Company";
}

// provides for the following capabilities:
// (1) Company links only (JA and GA will never navigate to a Financial Notification)
// (2) Financial Notification links will route to the internal Financial Notification Details page
// (3) Unauthorised links will render Code only

function Controller(props: IControllerProps): React.ReactElement {
    if (!props.linkDto) throw new Error("Financial Notification Link cannot be undefined!");
    const link = props.linkDto;
    return link.isAuthorised ? <Link to={companyRouteHelper.financialNotificationDetails(link.id, link.isComplete)}>{link.code}</Link> : <>{link.code}</>;
}

export default Controller;
