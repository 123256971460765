// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../api/Client";

export interface IRootViewModel {
    licenceId?: string | undefined;
    details?: Client.GetCompanyOeiLicenceDetailsResponseDto | undefined;
    applicationDocument?: Client.SecurePortalDocumentDto | undefined;

    refreshLicence(licenceId: string): IRootViewModel;
    refreshDetails(response: Client.GetCompanyOeiLicenceDetailsResponseDto): IRootViewModel;
    refreshApplicationDocument(response: Client.SecurePortalDocumentDto | undefined): IRootViewModel;
    clearApplicationDocument(): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.licenceId = undefined;
        this.details = undefined;
        this.applicationDocument = undefined;
    }

    public licenceId?: string | undefined;
    public details?: Client.GetCompanyOeiLicenceDetailsResponseDto | undefined;
    public applicationDocument?: Client.SecurePortalDocumentDto | undefined;

    public refreshLicence(licenceId: string): IRootViewModel {
        const vm = this._clone();
        vm.licenceId = licenceId;
        return vm;
    }

    public refreshDetails(response: Client.GetCompanyOeiLicenceDetailsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.details = response;
        return vm;
    }

    public refreshApplicationDocument(response: Client.SecurePortalDocumentDto): IRootViewModel {
        const vm = this._clone();
        vm.applicationDocument = response;
        return vm;
    }

    public clearApplicationDocument(): IRootViewModel {
        const vm = this._clone();
        vm.applicationDocument = undefined;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
