import * as Client from "../../../../../api/Client";
import * as Models from "../models/models";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "geoscienceAustralia.reportsAndData.surveyDataRelatedActivities.initialiseRequested",
    addRequested = "geoscienceAustralia.reportsAndData.surveyDataRelatedActivities.updateRequested",
    deleteRequested = "geoscienceAustralia.reportsAndData.surveyDataRelatedActivities.deleteRequested",
    viewChanged = "geoscienceAustralia.reportsAndData.surveyDataRelatedActivities.viewChanged",
    clearRequested = "geoscienceAustralia.reportsAndData.surveyDataRelatedActivities.clearRequested",
    // saga events
    sagaCompleted = "geoscienceAustralia.reportsAndData.surveyDataRelatedActivities.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

export interface IInitialiseRequestedPayload {
    surveyDataId: number;
}

export interface IDeleteRequestedPayload {
    surveyDataId: number;
    surveyDataVersionNumber: number;
    activityId: number;
    activityType: Client.SurveyDataRelatedActivityTypeEnum;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(surveyDataId: number): IAction<IInitialiseRequestedPayload>;
    addRequested(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    deleteRequested(surveyDataId: number, surveyDataVersionNumber: number, activityId: number, activityType: Client.SurveyDataRelatedActivityTypeEnum): IAction<IDeleteRequestedPayload>;
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    clearRequested(): IAction<undefined>;
    // saga events
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (surveyDataId: number): IAction<IInitialiseRequestedPayload> {
        return {
            type: actions.initialiseRequested,
            payload: { surveyDataId: surveyDataId },
        };
    },
    addRequested: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.addRequested,
            payload: vm,
        };
    },
    deleteRequested: function (surveyDataId: number, surveyDataVersionNumber: number, activityId: number, activityType: Client.SurveyDataRelatedActivityTypeEnum): IAction<IDeleteRequestedPayload> {
        return {
            type: actions.deleteRequested,
            payload: { surveyDataId: surveyDataId, surveyDataVersionNumber: surveyDataVersionNumber, activityId: activityId, activityType: activityType },
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
