// common
import ExternalLinkView from "../../../../common/externalLink/ExternalLinkView";

export default function GuidanceView(props: { petroleumGuidanceLink?: string | undefined; ghgGuidanceLink?: string | undefined; oeiGuidanceLink?: string | undefined }) {
    // opggs
    if (props.petroleumGuidanceLink && props.ghgGuidanceLink) {
        return (
            <p>
                In making an application please refer to <ExternalLinkView href={props.petroleumGuidanceLink}>Petroleum Guidance</ExternalLinkView> or{" "}
                <ExternalLinkView href={props.ghgGuidanceLink}>GHG Guidance</ExternalLinkView>
            </p>
        );
    } else if (props.petroleumGuidanceLink && !props.ghgGuidanceLink) {
        return (
            <p>
                In making an application please refer to <ExternalLinkView href={props.petroleumGuidanceLink}>Petroleum Guidance</ExternalLinkView>
            </p>
        );
    } else if (!props.petroleumGuidanceLink && props.ghgGuidanceLink) {
        return (
            <p>
                In making an application please refer to <ExternalLinkView href={props.ghgGuidanceLink}>GHG Guidance</ExternalLinkView>
            </p>
        );
        // oei
    } else if (props.oeiGuidanceLink) {
        return (
            <p>
                In making an application please refer to <ExternalLinkView href={props.oeiGuidanceLink}>Offshore Electricity Infrastructure Guidance</ExternalLinkView>
            </p>
        );
    } else return <></>;
}
