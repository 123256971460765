// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../../api/callHelper";
import * as Client from "../../../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_WellsBoreholes_AttachmentList_BulkDelete;

export default function* bulkDelete(action: Actions.IAction<Actions.IDeleteRequestedPayload>) {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const { batchItem } = action.payload;

    try {
        yield put(shellHelper.createSetBusyAction());

        const deleteResult: CallHelper.CallResult<Client.DeleteGeoscienceAustraliaAttachmentsImportResponseDto> = yield call(async () => await bulkItemDelete(batchItem));

        if (deleteResult.IsSuccess) {
            // success
            let updatedVm = vm;
            const r = deleteResult.Response!;
            yield put(Actions.actionFactory.sagaCompleted(updatedVm.refreshDeleteResponse(r)));
        } else if (deleteResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            deleteResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function bulkItemDelete(
    batchItem: Client.GetDeleteGeoscienceAustraliaAttachmentsImportReferenceDataAttachmentBatchItem
): Promise<CallHelper.CallResult<Client.DeleteGeoscienceAustraliaAttachmentsImportResponseDto>> {
    const request = new Client.DeleteGeoscienceAustraliaAttachmentsImportRequestDto({
        attachmentBulkImportNumber: batchItem.batchNumber,
        attachmentDetails: batchItem.attachmentItems.map(
            (ai) =>
                new Client.DeleteGeoscienceAustraliaAttachmentsImportAttachmentDto({
                    attachmentType: ai.attachmentType,
                    attachmentId: ai.attachmentId,
                    attachmentTypeId: ai.attachmentTypeId,
                    attachmentTypeVersionNumber: ai.attachmentTypeVersionNumber,
                })
        ),
    });

    const client = await ClientFactory.createGeoscienceAustraliaAttachmentClient();
    return await CallHelper.call(() => client.deleteGeoscienceAustraliaAttachmentsImport(request));
}
