export enum Level0ItemTypeEnum {
    Home,
    Opggs,
    Oei,
    Finance,
    UserManagement,
    Account,
    WellsBoreholes,
    SurveysAcquisitions,
    ReprocessingsProjects,
    DataSubmissions,
    Feedback,
    ReportAndData,
}

export enum Level1ItemTypeEnum {
    // common
    // - home
    Common_Home_SignIn,
    Common_Home_NewUserRequest,

    // company
    // - dashboard
    Company_Home_Dashboard,
    // - application
    Company_Application_ApplicationList,
    Company_Application_DraftApplicationList,
    Company_Application_UploadTitleApplication,
    Company_Opggs_TitlesList,
    Company_Opggs_LegislativeForms2To7List,
    // - oei: Offshore Electricity Infrastructure
    Company_Oei_DraftApplicationList,
    Company_Oei_ApplicationList,
    Company_Oei_InvitationToApplyList,
    Company_Oei_UploadLicenceApplication,
    Company_Oei_LicenceList,
    // - finance
    Company_Finance_FinancialNotificationList,
    Company_Finance_PaymentHistoryList,
    // - user management
    Company_UserManagement_ViewRequestList,
    Company_UserManagement_ViewUserList,
    Company_UserManagement_ViewCompanyList,
    // - my account
    Company_Account_ManageMyDetails,
    Company_Account_ViewMyAccessAndRequests,
    Company_Account_RequestAdditionalAccess,

    // joint authority
    // - dashboard
    JointAuthority_Home_Dashboard,
    // - application
    JointAuthority_Application_ApplicationList,
    JointAuthority_Opggs_TitlesList,
    // - user management
    JointAuthority_UserManagement_ViewMyJointAuthority,
    JointAuthority_UserManagement_ViewMyUserList,
    JointAuthority_UserManagement_ViewRequestList,
    // - my account
    JointAuthority_Account_ManageMyDetails,
    JointAuthority_Account_RequestAdditionalAccess,
    // - oei
    JointAuthority_Oei_LicenceList,

    // geoscience australia
    // - dashboard
    GeoscienceAustralia_Home_Dashboard,
    // - wells/boreholes
    GeoscienceAustralia_WellsBoreholes_Wells,
    GeoscienceAustralia_WellsBoreholes_Boreholes,
    GeoscienceAustralia_WellsBoreholes_Rigs,
    // - surveys/acquisitions
    GeoscienceAustralia_SurveysAcquisitions_Surveys,
    GeoscienceAustralia_SurveysAcquisitions_Acquisitions,
    // - reprocessings/projects
    GeoscienceAustralia_ReprocessingsProjects_Reprocessings,
    GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjects,
    // - dataSubmissions
    GeoscienceAustralia_DataSubmissions_Authorisations,
    // - feedback
    GeoscienceAustralia_FeedbackManagement_ViewFeedback,
    // - user management
    GeoscienceAustralia_UserManagement_ManageGeoscienceAustralia,
    GeoscienceAustralia_UserManagement_ViewMyUserList,
    GeoscienceAustralia_UserManagement_ViewRequestList,
    // - my account
    GeoscienceAustralia_Account_ManageMyDetails,
    GeoscienceAustralia_Account_RequestAdditionalAccess,
    // Reports and Data
    GeoscienceAustralia_ReportsAndData_Reports,
    GeoscienceAustralia_ReportsAndData_Attachments,
    GeoscienceAustralia_ReportsAndData_SurveyData,
}
