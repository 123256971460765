import { Link } from "react-router-dom";
import * as Forms from "../../../common/forms/BootstrapForms";
import { clone } from "lodash";
import { ReactElement } from "react";
import ListSummaryView from "../../../common/listSummary/listSummaryView";
import * as StringHelper from "../../../common/stringHelper";

export class LinkDetail {
    constructor(public name: string | undefined, public url: string) {}
}

export default function LinksView(props: { title: string; canViewLink: boolean; links?: LinkDetail[]; linkTooltip?: string | undefined }) {
    const { title, canViewLink, linkTooltip: linkTooltipText } = props;
    const links = clone(props.links);
    const maxTextLength = 100;

    function compare(a: LinkDetail, b: LinkDetail): number {
        return a.name! < b.name! ? -1 : 1;
    }

    function generateIdentifierListSummaryViewChild(linkDetail: LinkDetail, truncatedDisplayName?: string): ReactElement {
        return canViewLink ? (
            <Link to={linkDetail.url} title={linkTooltipText}>
                {truncatedDisplayName ?? linkDetail.name}
            </Link>
        ) : (
            <span>{truncatedDisplayName ?? linkDetail.name}</span>
        );
    }

    return (
        <Forms.Group>
            <Forms.Row>
                <Forms.FullColumn>
                    <Forms.Label htmlFor={"titleField"}>{`${title}`}</Forms.Label>
                    <div>
                        {(!links || links!.length === 0) && <span>{`No ${title}`}</span>}

                        {links && links!.length > 0 && (
                            <ListSummaryView
                                title={`${title}`}
                                fill={false}
                                maxChildrenDisplayCount={5}
                                children={links!.sort(compare).map((identifier: LinkDetail) =>
                                    identifier.name!.length <= maxTextLength
                                        ? { displayElement: generateIdentifierListSummaryViewChild(identifier) }
                                        : {
                                              displayElement: generateIdentifierListSummaryViewChild(identifier, StringHelper.truncate(identifier.name!, maxTextLength)),
                                              popupDisplayElement: generateIdentifierListSummaryViewChild(identifier),
                                          }
                                )}
                            />
                        )}
                    </div>
                </Forms.FullColumn>
            </Forms.Row>
        </Forms.Group>
    );
}
