import * as Actions from "./actions";
import * as Models from "../models/models";

// reducers
export function reducer(state: Models.IRootViewModel = new Models.RootViewModel(), action: Actions.IAction<any>): Models.IRootViewModel {
    switch (action.type) {
        // lifecycle events
        case Actions.actions.initialiseRequested:
            return state;
        case Actions.actions.deleteReportAttachmentRequested:
            return state;
        case Actions.actions.updateReportAttachmentRequested:
            return state;
        case Actions.actions.clearRequested:
            return new Models.RootViewModel();
        // saga events
        case Actions.actions.sagaCompleted:
            return action.payload;
        // default
        default:
            return state;
    }
}
