// framework
import { useState, useEffect } from "react";
// redux
import * as Models from "../../models/models";
import * as Actions from "../../redux/actions";
// common
import { IValidationModel } from "../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
import * as Forms from "../../../../../common/forms/BootstrapForms";

export default function CommentsView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; v: IValidationModel; isBusy: boolean }): React.ReactElement {
    const vm = props.vm;
    const actions = props.actions;
    const v = props.v;

    const [comments, setComments] = useState(vm.comments);

    useEffect(() => {
        setComments(vm.comments);
    }, [vm.comments]);

    return (
        <Forms.Group>
            <Forms.TextArea
                id="commentsField"
                label="Comments"
                valid={v.applyValidity("AdhocUpload.Comments")}
                value={comments}
                rows={5}
                maxLength={1000}
                disabled={props.isBusy}
                onChange={(value) => setComments(value)}
                onBlur={() => actions.viewChanged(vm.onCommentsChanged(comments))}
            />
            <ValidationMessageView name="AdhocUpload.Comments" validationModel={v} />
        </Forms.Group>
    );
}
