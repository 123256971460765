// framework
import { useState } from "react";
import { Link } from "react-router-dom";
// api
import * as Client from "../../../../api/Client";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// redux
import * as Models from "../models/models";
// other
import * as companyRouteHelper from "../../../companyRouteHelper";
import * as GlobalHelpers from "../../../../common/GlobalHelpers";
import DraftApplicationLinkController from "../../../../common/links/DraftApplicationLinkController";
// import TitleLinkController from "../../../../common/links/TitleLinkController";
import { createGridColumnFilterMenu } from "../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
import CustomGridCell from "../../../../common/kendoGrid/CustomGridCell";

export default function DraftApplicationListView(props: { listItems: Models.ListItems }): React.ReactElement {
    const draftApplications = props.listItems ?? new Array<Client.GetCompanyOpggsDraftApplicationListItemDto>();

    const initialGridState: State = {
        sort: [{ field: "createdDate", dir: "asc" }],
        skip: 0,
        take: 20,
    };
    const [gridState, setGridState] = useState(initialGridState);

    return (
        <Grid
            pageable
            sortable
            resizable
            navigatable
            data={process(draftApplications, gridState)}
            {...gridState}
            onDataStateChange={(e) => {
                setGridState(e.dataState);
            }}
        >
            <GridColumn
                field="link.trackingNumber"
                title="Tracking Number"
                width={150}
                filter="text"
                columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, draftApplications)}
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        <DraftApplicationLinkController linkDto={props.dataItem.link} secureEntityType="Company" />
                    </CustomGridCell>
                )}
            />
            <GridColumn field="typeDisplay" title="Application Type" width={210} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, draftApplications)} />
            <GridColumn field="applicationName" title="Application Name" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, draftApplications)} />
            <GridColumn field="status" title="Draft Status" width={150} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, draftApplications)} />
            <GridColumn
                field="titleNumberDisplay"
                title="Title Number"
                width={150}
                filter="text"
                columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, draftApplications)}
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        {props.dataItem.titles.length === 1 ? (
                            <Link to={companyRouteHelper.titleDetails(props.dataItem.titles[0].titleId)}>{props.dataItem.titles[0].titleNumber}</Link>
                        ) : (
                            props.dataItem.titleNumberDisplay
                        )}
                    </CustomGridCell>
                )}
            />
            <GridColumn field="titleTypeDisplay" title="Title Type" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, draftApplications)} />
            <GridColumn field="applicantName" title="Applicant" width={300} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, draftApplications)} />
            <GridColumn field="createdDatetime" title="Created Date" width={160} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
        </Grid>
    );
}
