// framework
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// common
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
// models
import { IRootViewModel } from "../../models/models";
// views
import DataMonitoringItemGridColumns from "../../../../../dataMonitoringItem/shared/views/DataMonitoringListGridColumnsView";

interface IProps {
    vm: IRootViewModel;
}

export default function DataMonitoringListGridView(props: IProps): JSX.Element {
    const { vm } = props;

    const initialState: State = {
        group: [{ field: "borehole.name" }],
        sort: [{ field: "dataMonitoringItemType.sortOrder", dir: "asc" }],
    };

    const [gridState, setGridState] = useState(initialState);
    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [vm.items]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid
                groupable
                sortable={false}
                data={process(vm.items, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn
                    field="borehole.name"
                    title="Borehole"
                    width={175}
                    cell={(props: GridCellProps) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.borehole?.id && vm.permissions.canViewBoreholes ? (
                                <Link to={geoscienceAustraliaRouteHelper.boreholeDataMonitoring(vm.wellId!, props.dataItem.borehole.id)} title="Click to view Borehole Data Monitoring.">
                                    {props.dataItem.borehole.name}
                                </Link>
                            ) : (
                                props.dataItem.borehole?.name
                            )}
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    field="dataMonitoringItem.identifier"
                    title="Identifier"
                    width={185}
                    cell={(props: GridCellProps) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.dataMonitoringItem && vm.permissions.canViewBoreholes ? (
                                <Link to={geoscienceAustraliaRouteHelper.wellDataMonitoringDetails(vm.wellId!, props.dataItem.dataMonitoringItem.id)} title="Click to view Data Monitoring Item.">
                                    {props.dataItem.dataMonitoringItem.identifier}
                                </Link>
                            ) : (
                                props.dataItem.dataMonitoringItem?.identifier
                            )}
                        </CustomGridCell>
                    )}
                />
                {DataMonitoringItemGridColumns(vm.permissions.canViewDataSubmissions, vm.permissions.canViewAuthorisations)}
            </Grid>
        </>
    );
}
