// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    surveyId: number | undefined;
    acquisitionId: number | undefined;
    surveyName: string | undefined;
    acquisitionName: string | undefined;

    items: Client.GetGeoscienceAustraliaSurveysAcquisitionsAcquisitionParametersResponseParameterDto[];

    canViewSurvey: boolean;

    refreshAcquisitionParameters(response: Client.GetGeoscienceAustraliaSurveysAcquisitionsAcquisitionParametersResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.surveyId = undefined;
        this.acquisitionId = undefined;
        this.surveyName = undefined;
        this.acquisitionName = undefined;
        this.items = [];
        this.canViewSurvey = false;
    }

    public surveyId: number | undefined;
    public acquisitionId: number | undefined;
    public surveyName: string | undefined;
    public acquisitionName: string | undefined;

    public items: Client.GetGeoscienceAustraliaSurveysAcquisitionsAcquisitionParametersResponseParameterDto[];

    public canViewSurvey: boolean;

    public refreshAcquisitionParameters(response: Client.GetGeoscienceAustraliaSurveysAcquisitionsAcquisitionParametersResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.surveyId = response.surveyId;
        vm.acquisitionId = response.acquisitionId;
        vm.surveyName = response.surveyName;
        vm.acquisitionName = response.acquisitionName;
        vm.items = response.parameters;
        vm.canViewSurvey = response.canViewSurvey;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.items = clone(this.items);
        return vm;
    }
}
