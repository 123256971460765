// framework
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { process, State } from "@progress/kendo-data-query";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
// common
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
// api
import { NopimsActivityTypeEnum } from "../../../../../../api/Client";
// models
import { IRootViewModel } from "../../models/models";

interface IProps {
    vm: IRootViewModel;
}

export default function DataMonitoringListGridView(props: IProps): JSX.Element {
    const { vm } = props;

    let initialState: State = {
        sort: [{ field: "dataMonitoringItemType.sortOrder" }],
        filter: { logic: "and", filters: [] },
        skip: 0,
    };

    if (vm.dataSubmissionType === NopimsActivityTypeEnum.Borehole) {
        initialState.group = [{ field: "borehole.name" }];
    }
    if (vm.dataSubmissionType === NopimsActivityTypeEnum.Acquisition) {
        initialState.group = [{ field: "acquisition.name" }];
    }
    if (vm.dataSubmissionType === NopimsActivityTypeEnum.Project) {
        initialState.group = [{ field: "project.name" }];
    }

    const [gridState, setGridState] = useState(initialState);
    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [vm.items]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid
                groupable
                data={process(vm.items, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn
                    field="dataMonitoringItem.identifier"
                    title="Identifier"
                    width={185}
                    cell={(props: GridCellProps) => (
                        <CustomGridCell gridCellProps={props}>
                            {vm.dataSubmissionType === NopimsActivityTypeEnum.Borehole && props.dataItem.borehole && props.dataItem.dataMonitoringItem && vm.permissions.canViewBoreholes ? (
                                <Link
                                    to={geoscienceAustraliaRouteHelper.wellDataMonitoringDetails(props.dataItem.borehole.wellId, props.dataItem.dataMonitoringItem.id)}
                                    title="Click to view Data Monitoring Item."
                                >
                                    {props.dataItem.dataMonitoringItem.identifier}
                                </Link>
                            ) : vm.dataSubmissionType === NopimsActivityTypeEnum.Acquisition &&
                              props.dataItem.acquisition &&
                              props.dataItem.dataMonitoringItem &&
                              vm.permissions.canViewAcquisitions ? (
                                <Link
                                    to={geoscienceAustraliaRouteHelper.surveyDataMonitoringDetails(props.dataItem.acquisition.surveyId, props.dataItem.dataMonitoringItem.id)}
                                    title="Click to view Data Monitoring Item."
                                >
                                    {props.dataItem.dataMonitoringItem.identifier}
                                </Link>
                            ) : vm.dataSubmissionType === NopimsActivityTypeEnum.Project && props.dataItem.project && props.dataItem.dataMonitoringItem && vm.permissions.canViewReprocessingProject ? (
                                <Link
                                    to={geoscienceAustraliaRouteHelper.reprocessingDataMonitoringDetails(props.dataItem.project.reprocessingId, props.dataItem.dataMonitoringItem.id)}
                                    title="Click to view Data Monitoring Item."
                                >
                                    {props.dataItem.dataMonitoringItem.identifier}
                                </Link>
                            ) : (
                                props.dataItem.dataMonitoringItem?.identifier
                            )}
                        </CustomGridCell>
                    )}
                />
                {vm.dataSubmissionType === NopimsActivityTypeEnum.Acquisition && <GridColumn field="dataMonitoringItemType.acquisitionType" title="Acquisition Type" width={150} />}
                <GridColumn field="dataMonitoringItemType.name" title="Name" width={350} />
                <GridColumn field="dataMonitoringItemType.classification" title="Classification Type" width={150} />
                {vm.dataSubmissionType === NopimsActivityTypeEnum.Borehole && (
                    <GridColumn
                        field="borehole.name"
                        title="Borehole"
                        width={350}
                        cell={(props: GridCellProps) => (
                            <CustomGridCell gridCellProps={props}>
                                {props.dataItem.borehole && vm.permissions.canViewBoreholes ? (
                                    <Link
                                        to={geoscienceAustraliaRouteHelper.boreholeDataMonitoring(props.dataItem.borehole.wellId, props.dataItem.borehole.id)}
                                        title="Click to view Borehole Data Monitoring."
                                    >
                                        {props.dataItem.borehole?.name}
                                    </Link>
                                ) : (
                                    props.dataItem.borehole?.name
                                )}
                            </CustomGridCell>
                        )}
                    />
                )}
                {vm.dataSubmissionType === NopimsActivityTypeEnum.Acquisition && (
                    <GridColumn
                        field="acquisition.name"
                        title="Acquisition"
                        width={350}
                        cell={(props: GridCellProps) => (
                            <CustomGridCell gridCellProps={props}>
                                {props.dataItem.acquisition && vm.permissions.canViewAcquisitions ? (
                                    <Link
                                        to={geoscienceAustraliaRouteHelper.acquisitionDataMonitoring(props.dataItem.acquisition.surveyId, props.dataItem.acquisition.id)}
                                        title="Click to view Acquisition Data Monitoring."
                                    >
                                        {props.dataItem.acquisition?.name}
                                    </Link>
                                ) : (
                                    props.dataItem.acquisition?.name
                                )}
                            </CustomGridCell>
                        )}
                    />
                )}
                {vm.dataSubmissionType === NopimsActivityTypeEnum.Project && (
                    <GridColumn
                        field="project.name"
                        title="Project"
                        width={350}
                        cell={(props: GridCellProps) => (
                            <CustomGridCell gridCellProps={props}>
                                {props.dataItem.project && vm.permissions.canViewReprocessingProject ? (
                                    <Link
                                        to={geoscienceAustraliaRouteHelper.reprocessingProjectDataMonitoring(props.dataItem.project.reprocessingId, props.dataItem.project.id)}
                                        title="Click to view Project Data Monitoring."
                                    >
                                        {props.dataItem.project?.name}
                                    </Link>
                                ) : (
                                    props.dataItem.project?.name
                                )}
                            </CustomGridCell>
                        )}
                    />
                )}
                <GridColumn field="status" title="Status" width={175} />
                <GridColumn field="receivedDate" title="Received Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={135} />
            </Grid>
        </>
    );
}
