// framework
import { connect } from "react-redux";
import { useEffect } from "react";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
// models
import * as Models from "../models/models";
// views
import FeedbackListGridView from "./views/FeedbackListGridView";

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    // on mount
    useEffect(() => {
        actions.initialiseRequested();
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BodyLayout title="Feedback Forms" selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_FeedbackManagement_ViewFeedback} onReloadRequested={actions.initialiseRequested}>
            <FeedbackListGridView vm={vm} />
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_Feedback_FeedbackList }), Actions.actionFactory)(Controller);
