export interface IProps {
    boreholeName: string | undefined;
    isBoreholePrimary: boolean | undefined;
}

export default function CustomBoreholeTitleView(props: IProps): JSX.Element {
    const boreholeTitle = props.boreholeName ? `Borehole - ${props.boreholeName}` : "Borehole";
    return (
        <div className="d-inline">
            <h1 style={{ display: "inline-block" }}>{boreholeTitle}</h1>
            {props.isBoreholePrimary && (
                <h4 style={{ display: "inline-block" }}>
                    <span
                        className="mx-2 badge rounded-pill"
                        style={{ backgroundColor: "#5d5d5d" }}
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        title="This is the Primary Borehole for the Well."
                    >
                        Primary
                    </span>
                </h4>
            )}
        </div>
    );
}
