// redux
import { call, put, select } from "redux-saga/effects";
import { IDraftApplicationDetailsReduxState } from "../../../../draftApplicationDetailsReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as Client from "../../../../../../../api/Client";
// shell
import * as ShellActions from "../../../../../../../shell/layout/redux/actions";
// common
import * as LogHelper from "../../../../../../../common/LogHelper";
// calls
import { getCompanyCommonDraftApplicationDetailsFormDocumentation } from "./calls";

const getRootViewModel = (state: IDraftApplicationDetailsReduxState) => state.Company_Common_DraftApplicationDetails_Controls_FormDocumentation;

export default function* initialise(action: Actions.IAction<number>) {
    let vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(ShellActions.actionFactory.setBusy());

        const response: Client.GetCompanyCommonDraftApplicationDetailsFormDocumentationResponseDto = yield call(async () => {
            return await getCompanyCommonDraftApplicationDetailsFormDocumentation(action.payload);
        });

        vm = vm.refreshSignatureDetails(action.payload, response);
        vm = vm.onInitialised();

        yield put(Actions.actionFactory.sagaCompleted(vm));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(ShellActions.actionFactory.clearBusy());
    }
}
