// kendo
import { MaskedTextBox, MaskedTextBoxChangeEvent } from "@progress/kendo-react-inputs";

interface IMaskedTextBoxViewProps {
    id: string;
    value: string | undefined;
    mask: string;
    onChange: (value: string) => void;
    onBlur: () => void;
    className?: string;
    valid?: boolean;
}

// the onChange implementation below allows for clearing of user input once set, which the MaskedTextBox does not allow
// this has been requested as default / optional functionality here: https://feedback.telerik.com/kendo-react-ui/1510790-maskedtextbox-value-cannot-be-cleared
export default function MaskedTextBoxView(props: IMaskedTextBoxViewProps) {
    function onMaskedCheckboxChanged(e: MaskedTextBoxChangeEvent) {
        if (e.target.rawValue.trim() === "") {
            props.onChange("");
            return;
        }
        props.onChange(e.target.value);
    }

    return (
        <MaskedTextBox
            id={props.id}
            mask={props.mask}
            includeLiterals={false}
            validityStyles={false}
            value={props.value}
            className={props.className}
            valid={props.valid}
            onChange={onMaskedCheckboxChanged}
            onBlur={props.onBlur}
        />
    );
}
