// framework
import { useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";

import LicencesView from "./LicenceView";

interface ILicenceListControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function LicenceListController(props: ILicenceListControllerProps) {
    const vm = props.rootViewModel;

    // on mount
    useEffect(() => {
        props.sagaGetListRequested();
        return function () {
            props.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BodyLayout
            title="Licences"
            selectedLhsNavItem={Level1ItemTypeEnum.Company_Oei_LicenceList}
            onReloadRequested={() => {
                props.sagaGetListRequested();
            }}
        >
            <LicencesView vm={vm.licences} />
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_Oei_LicenceList }), Actions.actionFactory)(LicenceListController);
