// Main
import Controller from "./components/Controller";
// common
import { IRouteItem, RouteBuilder } from "../../../infrastructure/routeItem";

const applicationDetailsRouteBuilder: RouteBuilder = (isSignedIn: boolean) => {
    let routes = new Array<IRouteItem>();

    // not authenticated? don't register routes
    if (!isSignedIn) return routes;

    routes.push({ path: "/JointAuthority/Opggs/ApplicationDetails/:id", exact: true, element: <Controller /> });

    return routes;
};

export default applicationDetailsRouteBuilder;
