// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IApplicationDetailsReduxState } from "../../applicationDetailsReduxRegistration";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as LogHelper from "../../../../../common/LogHelper";
import * as shellHelper from "../../../../../common/shell/shellHelper";

const getRootViewModel = (state: IApplicationDetailsReduxState) => state.JointAuthority_Opggs_ApplicationDetails;

export default function* initialise(action: Actions.IAction<string>) {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const response: Client.GetJointAuthorityOpggsApplicationDetailsLayoutAndHeaderResponseDto = yield call(async () => getLayoutAndHeader(action.payload));
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshLayoutAndHeader(response)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function getLayoutAndHeader(applicationId: string): Promise<Client.GetJointAuthorityOpggsApplicationDetailsLayoutAndHeaderResponseDto> {
    const request = new Client.GetJointAuthorityOpggsApplicationDetailsLayoutAndHeaderRequestDto({ applicationId: applicationId });
    const client = await ClientFactory.createJointAuthorityOpggsApplicationDetailsClient();

    return await CallHelper.simpleCall(() => client.getJointAuthorityOpggsApplicationDetailsLayoutAndHeader(request));
}
