// framework
import { clone, cloneDeep } from "lodash";
// kendo
import { orderBy } from "@progress/kendo-data-query";
// client
import * as Client from "../../../../../api/Client";

// enums
export enum ApplicationViewStateEnum {
    Created,
    Preparing,
    ForSigning,
    Paying,
    ForSubmission,
    Submitted,
    Deleted,
}

export enum ApplicationTaskEnum {
    ApplicationDetails,
    ContactDetails,
    EvaDetails,
    BlockDetails,
    SupportingDocumentation,
    SignatureType,
    FormDocumentation,
    WorkProgram,
    TitleholderForeignInvestment,
    Applicant,
}

// common response dto
export interface IApplicationDetailsResponseDto {
    id: number;
    trackingNumber?: string | undefined;
    type: Client.DraftApplicationTypeEnum;
    typeDisplay?: string | undefined;
    state: Client.DraftApplicationStateEnum;
    stateDisplay?: string | undefined;
    applicationName?: string | undefined;
    titles?: Client.DraftApplicationTitleDto[] | undefined;
    payment: Client.GetCompanyOpggsDraftApplicationDetailsPaymentDto;
    isDigitalSignatures: boolean;
    canManageApplicantOnly: boolean;
    canManageApplicantOrPartnerCompany: boolean;
    canManageFormDocumentation: boolean;
    canSubmit: boolean;
    statusMessages?: Client.StatusMessagesDto;
    validationState: Client.DraftApplicationValidationStateEnum;
    versionNumber: number;
    applicationDetailsAudit: Client.SecurePortalSimpleAuditEventsDto;
    stateAudit?: Client.SecurePortalSimpleAuditEventsDto;
    progressChecklist: Client.ProgressChecklistItemDto[];
}

// common viewmodels
export interface IApplicationStateViewModel {
    id?: number | undefined;
    versionNumber?: number | undefined;
    type?: Client.DraftApplicationTypeEnum | undefined;
    applicationState?: Client.DraftApplicationStateEnum | undefined;

    canManageApplicantOnly: boolean;
    canManageApplicantOrPartnerCompany: boolean;
    canManageFormDocumentation: boolean;
    canSubmit: boolean;

    isConflict: boolean;
    inEditTask?: ApplicationTaskEnum | undefined;
    viewState?: ApplicationViewStateEnum | undefined;
    stateAudit?: Client.SecurePortalSimpleAuditEventsDto | undefined;

    validationState: Client.DraftApplicationValidationStateEnum;
    statusMessages?: Client.StatusMessagesDto | undefined;
}

export interface IApplicationHeaderViewModel {
    trackingNumber?: string | undefined;
    typeDisplay?: string | undefined;
    applicationState?: Client.DraftApplicationStateEnum | undefined;
    stateDisplay?: string | undefined;
    applicationName?: string | undefined;
    titles?: Array<Client.DraftApplicationTitleDto> | undefined;
    checklist?: Array<Client.ProgressChecklistItemDto> | undefined;
}

export interface IApplicationDetailsViewModel {
    isDigitalSignatures: boolean;
    audit?: Client.SecurePortalSimpleAuditEventsDto | undefined;
    isDirty: boolean;
}

// root viewmodel interface
export interface IDraftApplicationRootViewModel {
    state: IApplicationStateViewModel;
    header: IApplicationHeaderViewModel;
    applicationDetails: IApplicationDetailsViewModel;
    paymentDetails: Client.GetCompanyOpggsDraftApplicationDetailsPaymentDto | undefined;

    refreshDetails(response: IApplicationDetailsResponseDto): this;
    refreshSaveApplicationDetailsResponse(statusMessages: Client.StatusMessagesDto): this;
    refreshSubmitApplicationResponse(statusMessages: Client.StatusMessagesDto): this;
    refreshDeleteApplicationResponse(statusMessages: Client.StatusMessagesDto): this;
    refreshConflict(): this;
    // view triggered state changes
    setInEditTask(task: ApplicationTaskEnum): this;
}

// base viewmodel
export abstract class BaseDraftApplicationRootViewModel implements IDraftApplicationRootViewModel {
    constructor() {
        this.state = {
            canManageApplicantOnly: false,
            canManageApplicantOrPartnerCompany: false,
            canManageFormDocumentation: false,
            canSubmit: false,
            isConflict: false,
            validationState: Client.DraftApplicationValidationStateEnum.Valid,
        };
        this.header = {};
        this.paymentDetails = undefined;
    }

    public state: IApplicationStateViewModel;
    public header: IApplicationHeaderViewModel;
    public abstract applicationDetails: IApplicationDetailsViewModel;
    public paymentDetails: Client.GetCompanyOpggsDraftApplicationDetailsPaymentDto | undefined;

    public refreshDetails(response: IApplicationDetailsResponseDto): this {
        const vm = this._clone();

        // State
        vm.state.id = response.id;
        vm.state.versionNumber = response.versionNumber;
        vm.state.type = response.type;
        vm.state.applicationState = response.state;
        vm.state.canManageApplicantOnly = response.canManageApplicantOnly;
        vm.state.canManageApplicantOrPartnerCompany = response.canManageApplicantOrPartnerCompany;
        vm.state.canManageFormDocumentation = response.canManageFormDocumentation;
        vm.state.canSubmit = response.canSubmit;
        vm.state.isConflict = false;
        vm.state.inEditTask = undefined;
        vm.state.validationState = response.validationState;
        vm.state.statusMessages = response.statusMessages;

        // Header
        vm.header.trackingNumber = response.trackingNumber;
        vm.header.typeDisplay = response.typeDisplay;
        vm.header.applicationState = response.state;
        vm.header.stateDisplay = response.stateDisplay;
        vm.header.applicationName = response.applicationName;
        const titles = response.titles ?? new Array<Client.DraftApplicationTitleDto>();
        vm.header.titles = orderBy(titles, [{ field: "titleNumber", dir: "asc" }]);
        vm.header.checklist = response.progressChecklist;
        // Audit
        vm.state.stateAudit = response.stateAudit;
        vm.applicationDetails.audit = response.applicationDetailsAudit;
        // Payment
        vm.paymentDetails = response.payment;
        // Signature Type
        vm.applicationDetails.isDigitalSignatures = response.isDigitalSignatures;
        // Calculated fields
        vm.state.viewState = this._getViewState(response.state);
        return vm;
    }

    public refreshSaveApplicationDetailsResponse(statusMessages: Client.StatusMessagesDto): this {
        const vm = this._clone();

        vm.state.statusMessages = statusMessages;
        vm.state.isConflict = false;

        return vm;
    }

    public refreshSubmitApplicationResponse(statusMessages: Client.StatusMessagesDto): this {
        const vm = this._clone();

        vm.state.statusMessages = statusMessages;
        vm.state.isConflict = false;

        if (statusMessages.isSuccess) vm.state.viewState = ApplicationViewStateEnum.Submitted;

        return vm;
    }

    public refreshDeleteApplicationResponse(statusMessages: Client.StatusMessagesDto): this {
        const vm = this._clone();

        vm.state.statusMessages = statusMessages;
        vm.state.isConflict = false;

        if (statusMessages.isSuccess) vm.state.viewState = ApplicationViewStateEnum.Deleted;

        return vm;
    }

    public refreshConflict(): this {
        const vm = this._clone();

        vm.state.statusMessages = undefined;
        vm.state.isConflict = true;

        return vm;
    }

    public setInEditTask(task: ApplicationTaskEnum): this {
        if (this.state.inEditTask !== undefined) throw new Error("An Application Task is already being edited.");
        const vm = this._clone();

        vm.state.inEditTask = task;

        return vm;
    }

    protected _clone(): this {
        const vm = clone(this);

        vm.state = clone(this.state);
        vm.header = clone(this.header);
        vm.applicationDetails = cloneDeep(this.applicationDetails);
        vm.paymentDetails = clone(this.paymentDetails);

        return vm;
    }

    protected _getViewState(state: Client.DraftApplicationStateEnum): ApplicationViewStateEnum {
        switch (state) {
            case Client.DraftApplicationStateEnum.Preparing:
                return ApplicationViewStateEnum.Preparing;
            case Client.DraftApplicationStateEnum.ForSigning:
                return ApplicationViewStateEnum.ForSigning;
            case Client.DraftApplicationStateEnum.Paying:
                return ApplicationViewStateEnum.Paying;
            case Client.DraftApplicationStateEnum.ForSubmission:
                return ApplicationViewStateEnum.ForSubmission;
            case Client.DraftApplicationStateEnum.Submitted:
                return ApplicationViewStateEnum.Submitted;
            default:
                throw new Error(`Unsupported Application State '${state}'.`);
        }
    }
}
