// framework
import { useState } from "react";
import { Link } from "react-router-dom";
// api
import { IJointAuthoritySecureUserDetailsDto } from "../../../../api/Client";
// kendo
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import { createGridColumnFilterMenu } from "../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
import CustomGridCell from "../../../../common/kendoGrid/CustomGridCell";
// other
import * as GlobalHelpers from "../../../../common/GlobalHelpers";
import * as jointAuthorityRouteHelper from "../../../jointAuthorityRouteHelper";
import * as cellHelper from "../../common/permissionCellHelper";
import EmailLinkView from "../../../../common/externalLink/EmailLinkView";

export default function UserListView(props: { users: IJointAuthoritySecureUserDetailsDto[] }): React.ReactElement {
    const initialGridState: State = {
        sort: [{ field: "fullName", dir: "asc" }],
        skip: 0,
        take: 10,
    };
    const [gridState, setGridState] = useState(initialGridState);

    const lastLoginCell = (props: GridCellProps) => {
        if (props.dataItem.hasInactivityWarning) {
            return (
                <CustomGridCell gridCellProps={props} style={{ backgroundColor: "crimson", color: "white" }}>
                    {GlobalHelpers.toNoptaDatetimeString(props.dataItem.lastLoginDatetime)}
                </CustomGridCell>
            );
        }
        return <CustomGridCell gridCellProps={props}>{GlobalHelpers.toNoptaDatetimeString(props.dataItem.lastLoginDatetime)}</CustomGridCell>;
    };

    // view
    return (
        <>
            {/* results grid */}
            <Grid
                pageable
                sortable
                resizable
                navigatable
                data={process(props.users, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn
                    field="username"
                    title="Username"
                    width={160}
                    filter="text"
                    columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.users)}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            {(!props.dataItem.isSecureUserSignedIn && <Link to={jointAuthorityRouteHelper.userDetails(props.dataItem.secureUserId)}>{props.dataItem.username}</Link>) ||
                                props.dataItem.username}
                        </CustomGridCell>
                    )}
                />
                <GridColumn field="fullName" title="Full Name" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.users)} />
                <GridColumn field="lastLoginDatetime" title="Last Login (AWST)" width={200} cell={lastLoginCell} filter="date" columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="accountStatus" title="Account Status" width={160} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.users)} />
                <GridColumn
                    field="emailAddress"
                    title="Email Address"
                    width={250}
                    filter="text"
                    columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.users)}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <EmailLinkView emailAddress={props.dataItem.emailAddress} />
                        </CustomGridCell>
                    )}
                />
                <GridColumn field="badgeSortOrder" title="Information" width={160} cell={cellHelper.getInformationCell} />
            </Grid>
        </>
    );
}
