// framework
import * as Client from "../../../../api/Client";
// api
import { clone } from "lodash";

export interface IRootViewModel {
    applications: applicationListType;

    refreshSaga(response: Client.GetCompanyOpggsApplicationListResponseDto): IRootViewModel;
}

export type applicationListType = Array<Client.CompanyApplicationListItemDto> | undefined;

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.applications = undefined;
    }

    public applications: applicationListType;

    public refreshSaga(response: Client.GetCompanyOpggsApplicationListResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.applications = response.applications;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
