import { useEffect, useState } from "react";
// redux
import * as Models from "../../models/models";
// kendo
import { DropDownList } from "@progress/kendo-react-dropdowns";
// common
import { ValidationModel } from "../../../../../../../common/validation/ValidationModel";
import * as Forms from "../../../../../../../common/forms/BootstrapForms";
import ValidationMessageView from "../../../../../../../common/validation/ValidationMessageView";
import YesNoDropDownListView from "../../../../../../../common/dropdowns/YesNoDropDownListView";

export default function ApplicantDetailsView(props: { vm: Models.IRootViewModel; v: ValidationModel; updateViewModel: (vm: Models.IApplicantDetailsViewModel) => void }): React.ReactElement {
    const vm = props.vm;
    const v = props.v;

    const [state, setState] = useState<Models.IApplicantDetailsViewModel>({
        companyName: "",
        acnOrArbn: "",
        foreignInvestmentApprovalType: undefined,
        isApplicantEligibleUnderSection8: undefined,
    });

    useEffect(() => {
        setState({
            companyName: vm.applicantDetails.companyName ?? "",
            acnOrArbn: vm.applicantDetails.acnOrArbn ?? "",
            foreignInvestmentApprovalType: vm.applicantDetails.foreignInvestmentApprovalType,
            isApplicantEligibleUnderSection8: vm.applicantDetails.isApplicantEligibleUnderSection8,
        });
    }, [vm.applicantDetails]);

    function onDropdownChange(values: Models.IApplicantDetailsViewModel) {
        props.updateViewModel(values);
    }

    return (
        <>
            <Forms.Group>
                <Forms.ReadonlyField label="Company Name" id="companyNameField" value={vm.applicantDetails.companyName} />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="ACN/ARBN" id="acnOrArbnField" value={vm.applicantDetails.acnOrArbn} />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="foreignInvestmentApprovalTypeField">Foreign investment approval required?</Forms.Label>
                <p>
                    If applicable, an applicant is responsible for obtaining foreign investment approval. Confirmation of Foreign Investment approval should be provided with submission of an
                    applicable feasibility licence application. If it is not available at the time of submission, it may be provided separately once received. Applicant should be aware that the
                    Minister will not make a decision on the application or offer award of licence whilst a foreign investment decision is pending.
                </p>
                <DropDownList
                    id="foreignInvestmentApprovalTypeField"
                    valid={v.applyValidityForKendo("ApplicantDetails.ForeignInvestmentApprovalType")}
                    data={vm.availableForeignInvestmentApprovalTypes}
                    value={state.foreignInvestmentApprovalType}
                    dataItemKey="foreignInvestmentApprovalType"
                    textField="description"
                    onChange={(e) => onDropdownChange({ ...state, foreignInvestmentApprovalType: e.value })}
                />
                <ValidationMessageView name="ApplicantDetails.ForeignInvestmentApprovalType" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="isApplicantEligibleUnderSection8Field">Is the applicant an eligible person under section 8 of the OEI Act?</Forms.Label>
                <YesNoDropDownListView
                    id="isApplicantEligibleUnderSection8Field"
                    valid={v.applyValidityForKendo("ApplicantDetails.IsApplicantEligibleUnderSection8")}
                    value={state.isApplicantEligibleUnderSection8}
                    onChange={(e) => onDropdownChange({ ...state, isApplicantEligibleUnderSection8: e })}
                />
                <ValidationMessageView name="ApplicantDetails.IsApplicantEligibleUnderSection8" validationModel={v} />
            </Forms.Group>
        </>
    );
}
