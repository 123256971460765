// api
import * as ClientFactory from "../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";

export async function getCompanyOpggsApplicationDetailsApprovalTracking(id: string): Promise<Client.GetCompanyOpggsApplicationDetailsApprovalTrackingResponseDto> {
    // console.info("getCompanyOpggsApplicationDetailsApprovalTracking");
    const client = await ClientFactory.createCompanyOpggsApplicationDetailsClient();
    return await CallHelper.simpleCall(() => client.getCompanyOpggsApplicationDetailsApprovalTracking(new Client.GetCompanyOpggsApplicationDetailsApprovalTrackingRequestDto({ applicationId: id })));
}
