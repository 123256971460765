// kendo
import { orderBy } from "@progress/kendo-data-query";
// api
import * as Client from "../../../../api/Client";
// common
import LicenceLinkController from "../../../links/LicenceLinkController";
import * as Forms from "../../../forms/BootstrapForms";

export default function LicenceSummaryRelatedLicencesField(props: { label?: string; relatedLicences: Client.LicenceLinkDto[] | undefined }) {
    const relatedLicences = props.relatedLicences ?? new Array<Client.LicenceLinkDto>();
    const sortedLicences = orderBy(relatedLicences, [{ field: "licenceNumber", dir: "asc" }]);

    return (
        <>
            <Forms.Label htmlFor="relatedLicencesField">Related Licences</Forms.Label>
            {sortedLicences.length === 0 && <div>No Related Licences found.</div>}
            {sortedLicences.length !== 0 && (
                <div id="relatedLicencesField">
                    {sortedLicences.map((licence, i) => (
                        <>
                            <LicenceLinkController key={i} linkDto={licence} secureEntityType="Company" />
                            <span>{i === sortedLicences.length - 1 ? <></> : ", "}</span>
                        </>
                    ))}
                </div>
            )}
        </>
    );
}
