import * as Models from "../models/models";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "geoscienceAustralia.reprocessingsProjects.reprocessingReportAttachments.initialiseRequested",
    deleteReportAttachmentRequested = "geoscienceAustralia.reprocessingsProjects.reprocessingReportAttachments.deleteReportAttachmentRequested",
    updateReportAttachmentRequested = "geoscienceAustralia.reprocessingsProjects.reprocessingReportAttachments.updateReportAttachmentRequested",
    clearRequested = "geoscienceAustralia.reprocessingsProjects.reprocessingReportAttachments.clearRequested",
    // saga events
    sagaCompleted = "geoscienceAustralia.reprocessingsProjects.reprocessingReportAttachments.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

export interface IInitialiseRequestedPayload {
    reprocessingId: number;
}

export interface IDeleteReportAttachmentRequestedPayload {
    reportId: number;
    reportVersionNumber: number;
    reportAttachmentId: number;
}

export interface IUpdateReportAttachmentRequestedPayload {
    reportId: number;
    reportVersionNumber: number;
    reportAttachmentId: number;
    name: string;
    documentLink: string;
    fileName: string;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(reprocessingId: number): IAction<IInitialiseRequestedPayload>;
    deleteReportAttachmentRequested(reprocessingId: number, reprocessingVersionNumber: number, reportAttachmentId: number): IAction<IDeleteReportAttachmentRequestedPayload>;
    updateReportAttachmentRequested(
        reportId: number,
        reportVersionNumber: number,
        reportAttachmentId: number,
        name: string,
        documentLink: string,
        fileName: string
    ): IAction<IUpdateReportAttachmentRequestedPayload>;
    clearRequested(): IAction<undefined>;
    // saga events
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (reprocessingId: number): IAction<IInitialiseRequestedPayload> {
        return {
            type: actions.initialiseRequested,
            payload: { reprocessingId: reprocessingId },
        };
    },
    updateReportAttachmentRequested: function (
        reportId: number,
        reportVersionNumber: number,
        reportAttachmentId: number,
        name: string,
        documentLink: string,
        fileName: string
    ): IAction<IUpdateReportAttachmentRequestedPayload> {
        return {
            type: actions.updateReportAttachmentRequested,
            payload: {
                reportId: reportId,
                reportVersionNumber: reportVersionNumber,
                name: name,
                reportAttachmentId: reportAttachmentId,
                documentLink: documentLink,
                fileName: fileName,
            },
        };
    },
    deleteReportAttachmentRequested: function (reportId: number, reportVersionNumber: number, reportAttachmentId: number): IAction<IDeleteReportAttachmentRequestedPayload> {
        return {
            type: actions.deleteReportAttachmentRequested,
            payload: { reportId: reportId, reportVersionNumber: reportVersionNumber, reportAttachmentId: reportAttachmentId },
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
