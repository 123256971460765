// api
import * as ClientFactory from "../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";

export async function getCompanyOpggsApplicationDetailsContact(id: string): Promise<Client.GetCompanyOpggsApplicationDetailsContactResponseDto> {
    // console.info("getCompanyOpggsApplicationDetailsContact");
    const client = await ClientFactory.createCompanyOpggsApplicationDetailsClient();
    return await CallHelper.simpleCall(() => client.getCompanyOpggsApplicationDetailsContact(new Client.GetCompanyOpggsApplicationDetailsContactRequestDto({ applicationId: id })));
}
