// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../api/Client";
import { IPaymentViewModel } from "./paymentViewModel";

export enum ViewStateEnum {
    View,
    Pay,
}

export interface IFinancialNotificationRequiringPaymentViewModel {
    financialNotificationLink: Client.FinancialNotificationLinkDto;
    versionNumber: number;
    financialNotificationDueDate: Date;
    isDueWithinSevenDays: boolean;
    isOverdue: boolean;
    isAwaitingConfirmation: boolean;
    financialNotificationIssueDatetime: Date;
    financialNotificationStatus?: string | undefined;
    financialNotificationType?: string | undefined;
    totalAmount: number;
    outstandingAmount: number;
    paymentConfirmationDate?: Date | undefined;
    anniversaryDate?: Date | undefined;
    administrationType?: Client.AdministrationTypeEnum | undefined;
    titleLicenceNumber?: string | undefined;
    titleLink?: Client.TitleLinkDto;
    licenceLink?: Client.LicenceLinkDto;
    companyId: number;
    companyName?: string | undefined;
    draftApplicationLink?: Client.DraftApplicationLinkDto;
    applicationLink?: Client.ApplicationLinkDto;
    trackingNumberDisplay?: string | undefined;
    isSelected: boolean;
}

export class FinancialNotificationRequiringPaymentViewModel implements IFinancialNotificationRequiringPaymentViewModel {
    constructor(dto: Client.CompanyFinanceFnListDetailDto) {
        this.financialNotificationLink = dto.financialNotificationLink;
        this.versionNumber = dto.versionNumber;
        this.financialNotificationDueDate = dto.financialNotificationDueDate;
        this.isDueWithinSevenDays = dto.isDueWithinSevenDays;
        this.isOverdue = dto.isOverdue;
        this.isAwaitingConfirmation = dto.isAwaitingConfirmation;
        this.financialNotificationIssueDatetime = dto.financialNotificationIssueDatetime;
        this.financialNotificationStatus = dto.financialNotificationStatus;
        this.financialNotificationType = dto.financialNotificationType;
        this.totalAmount = dto.totalAmount;
        this.outstandingAmount = dto.outstandingAmount;
        this.paymentConfirmationDate = dto.paymentConfirmationDate;
        this.anniversaryDate = dto.anniversaryDate;
        this.administrationType = dto.administrationType;
        this.titleLicenceNumber = dto.titleLicenceNumber;
        this.titleLink = dto.titleLink;
        this.licenceLink = dto.licenceLink;
        this.companyId = dto.companyId;
        this.companyName = dto.companyName;
        this.draftApplicationLink = dto.draftApplicationLink;
        this.applicationLink = dto.applicationLink;
        this.trackingNumberDisplay = dto.trackingNumberDisplay;

        this.isSelected = false;
    }

    public financialNotificationLink: Client.FinancialNotificationLinkDto;
    public versionNumber: number;
    public financialNotificationDueDate: Date;
    public isDueWithinSevenDays: boolean;
    public isOverdue: boolean;
    public isAwaitingConfirmation: boolean;
    public financialNotificationIssueDatetime: Date;
    public financialNotificationStatus?: string | undefined;
    public financialNotificationType?: string | undefined;
    public totalAmount: number;
    public outstandingAmount: number;
    public paymentConfirmationDate?: Date | undefined;
    public anniversaryDate?: Date | undefined;
    public administrationType?: Client.AdministrationTypeEnum | undefined;
    public titleLicenceNumber?: string | undefined;
    public titleLink?: Client.TitleLinkDto;
    public licenceLink?: Client.LicenceLinkDto;
    public companyId: number;
    public companyName?: string | undefined;
    public draftApplicationLink?: Client.DraftApplicationLinkDto;
    public applicationLink?: Client.ApplicationLinkDto;
    public trackingNumberDisplay?: string | undefined;
    public isSelected: boolean;
}

export interface ISelectedFinancialNotificationsViewModel {
    totalOutstandingAmount: number | undefined;
    financialNotifications: Array<IFinancialNotificationRequiringPaymentViewModel> | undefined;
}

export interface IRootViewModel {
    financialNotificationsAwaitingConfirmation: Array<Client.CompanyFinanceFnListDetailDto>;
    financialNotificationsRequiringPayment: Array<IFinancialNotificationRequiringPaymentViewModel>;
    selectedFinancialNotifications: ISelectedFinancialNotificationsViewModel;
    paymentViewModel: IPaymentViewModel;

    isDirty: boolean;
    isConflict: boolean;
    viewState?: ViewStateEnum | undefined;
    statusMessages?: Client.StatusMessagesDto | undefined;

    refreshList(response: Client.GetCompanyFinanceFnListResponseDto): IRootViewModel;
    refreshConflict(): IRootViewModel;
    setStatusMessages(statusMessages: Client.StatusMessagesDto | undefined): IRootViewModel;
    // view triggered state changes
    refreshSelectionChanged(financialNotifications: Array<IFinancialNotificationRequiringPaymentViewModel>): IRootViewModel;
    onMakePayment(): IRootViewModel;
    onCancelPayment(): IRootViewModel;
    refreshPaymentDetails(values: IPaymentViewModel): IRootViewModel;
    refreshCreditCardSurcharge(response: Client.GetCompanyFinanceCreditCardSurchargeResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.financialNotificationsAwaitingConfirmation = new Array<Client.CompanyFinanceFnListDetailDto>();
        this.financialNotificationsRequiringPayment = new Array<IFinancialNotificationRequiringPaymentViewModel>();
        this.selectedFinancialNotifications = {
            totalOutstandingAmount: 0,
            financialNotifications: new Array<IFinancialNotificationRequiringPaymentViewModel>(),
        };
        this.paymentViewModel = {};
        this.isConflict = false;
        this.isDirty = false;
    }

    public financialNotificationsAwaitingConfirmation: Array<Client.CompanyFinanceFnListDetailDto>;
    public financialNotificationsRequiringPayment: Array<IFinancialNotificationRequiringPaymentViewModel>;
    public selectedFinancialNotifications: ISelectedFinancialNotificationsViewModel;
    public paymentViewModel: IPaymentViewModel;

    public isDirty: boolean;
    public isConflict: boolean;
    public viewState: ViewStateEnum | undefined;
    public statusMessages?: Client.StatusMessagesDto | undefined;

    public refreshList(response: Client.GetCompanyFinanceFnListResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.isDirty = false;
        vm.isConflict = false;
        vm.statusMessages = undefined;
        vm.financialNotificationsAwaitingConfirmation = response.financialNotificationsAwaitingConfirmation;
        vm.financialNotificationsRequiringPayment = response.financialNotificationsRequiringPayment.map((fn) => new FinancialNotificationRequiringPaymentViewModel(fn));
        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        vm.statusMessages = undefined;
        return vm;
    }

    public setStatusMessages(statusMessages: Client.StatusMessagesDto | undefined): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = statusMessages;
        return vm;
    }

    public refreshSelectionChanged(financialNotifications: Array<IFinancialNotificationRequiringPaymentViewModel>): IRootViewModel {
        const vm = this._clone();

        vm.financialNotificationsRequiringPayment = financialNotifications;
        return vm;
    }

    public onMakePayment(): IRootViewModel {
        const vm = this._clone();

        vm.paymentViewModel = {
            receivedAmount: vm.selectedFinancialNotifications.totalOutstandingAmount,
            comments: undefined,
            creditCardSurchargeAmount: undefined,
            secureTransactionToken: undefined,
        };

        vm.viewState = ViewStateEnum.Pay;
        vm.isDirty = false;
        vm.statusMessages = undefined;

        const selectedFns = vm.financialNotificationsRequiringPayment.filter((fn) => fn.isSelected);
        vm.selectedFinancialNotifications.financialNotifications = selectedFns;
        // client-side calculate the total; it is reconciled against the server when verifying the credit card
        // to make sure we haven't been showing them the wrong amount
        // it's for display purposes only
        vm.selectedFinancialNotifications.totalOutstandingAmount = selectedFns.reduce((p, c) => p + c.outstandingAmount, 0);
        return vm;
    }

    public onCancelPayment(): IRootViewModel {
        const vm = this._clone();

        vm.viewState = ViewStateEnum.View;
        vm.isDirty = false;
        return vm;
    }

    public refreshPaymentDetails(values: IPaymentViewModel): IRootViewModel {
        const vm = this._clone();
        vm.paymentViewModel = values;
        vm.isDirty = true;
        return vm;
    }

    public refreshCreditCardSurcharge(response: Client.GetCompanyFinanceCreditCardSurchargeResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.paymentViewModel!.creditCardSurchargeAmount = response.surchargeAmount;
        vm.statusMessages = response.statusMessages;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.selectedFinancialNotifications = clone(this.selectedFinancialNotifications);
        return vm;
    }
}
