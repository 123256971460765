// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { ICompanyReduxState } from "../../../../companyReduxRegistration";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

const getRootViewModel = (state: ICompanyReduxState) => state.Company_Finance_FinancialNotificationDetails;

export default function* getDetailsRequested(action: Actions.IAction<number>) {
    const financialNotificationId = action.payload;

    try {
        yield put(shellHelper.createSetBusyAction());
        const response: Client.GetCompanyFinanceFnDetailsResponseDto = yield call(async () => getFinancialNotificationDetails(financialNotificationId));

        const vm: Models.IRootViewModel = yield select(getRootViewModel);
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshDetails(response)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

export async function getFinancialNotificationDetails(financialNotificationId: number): Promise<Client.GetCompanyFinanceFnDetailsResponseDto> {
    const client = await ClientFactory.createCompanyFinanceClient();
    const request = new Client.GetCompanyFinanceFnDetailsRequestDto({ financialNotificationId: financialNotificationId });
    return await CallHelper.simpleCall(() => client.getCompanyFinanceFnDetails(request));
}
