// redux
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";

export async function getCompanyOeiDraftApplicationDetailsContact(id: number): Promise<Client.GetCompanyOeiDraftApplicationDetailsContactResponseDto> {
    // console.info("getCompanyOeiDraftApplicationDetailsContact");
    const client = await ClientFactory.createCompanyOeiDraftApplicationDetailsClient();
    return await CallHelper.simpleCall(() => client.getCompanyOeiDraftApplicationDetailsContact(new Client.GetCompanyOeiDraftApplicationDetailsContactRequestDto({ id: id })));
}

export async function saveCompanyOeiDraftApplicationDetailsContact(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.SaveCompanyOeiDraftApplicationDetailsContactResponseDto>> {
    const client = await ClientFactory.createCompanyOeiDraftApplicationDetailsClient();

    return await CallHelper.call(() =>
        client.saveCompanyOeiDraftApplicationDetailsContact(
            new Client.SaveCompanyOeiDraftApplicationDetailsContactRequestDto({
                id: vm.id!,
                versionNumber: vm.versionNumber!,
                primaryContactName: vm.contact.primaryContactName,
                positionHeld: vm.contact.positionHeld,
                phone: vm.contact.phone,
                email: vm.contact.email,
                fax: vm.contact.fax,
                businessAddress: vm.contact.businessAddress,
                isOverlappingContactSharingConsent: vm.contact.isOverlappingContactSharingConsent!,
                overlappingContactType: vm.contact.overlappingContactType!.overlappingContactType!,
                isElectronicCommunicationConsent: vm.contact.isElectronicCommunicationConsent!,
            })
        )
    );
}
