// redux
import { ApplicationViewStateEnum } from "./models/models";
// client
import * as Client from "../../../../api/Client";
// common
import SimpleAlertView from "../../../../common/simpleAlerts/SimpleAlertView";
import * as Forms from "../../../../common/forms/BootstrapForms";
import FinancialNotificationLinkController from "../../../../common/links/FinancialNotificationLinkController";
import * as GlobalHelpers from "../../../../common/GlobalHelpers";

export default function PaymentDetailsView(props: { vm: Client.GetCompanyOpggsDraftApplicationDetailsPaymentDto; viewState: ApplicationViewStateEnum }): React.ReactElement {
    const vm = props.vm;
    const isPreSigning = props.viewState === ApplicationViewStateEnum.Preparing || props.viewState === ApplicationViewStateEnum.ForSigning;
    const isPaying = props.viewState === ApplicationViewStateEnum.Paying;
    const showNotificationDetails = vm.isPaymentRequired && !isPreSigning;

    let infoMessage: string | undefined = undefined;

    if (isPreSigning) {
        infoMessage = vm.isPaymentRequired
            ? "Payment of Application Fee is required after Signing the Application, subject to any changes to the CRIS prior to submission."
            : "There is no payment required for this Application, subject to any changes to the CRIS prior to submission.";
    } else if (isPaying) {
        infoMessage = "Payment of Application Fee is required to progress the Application.";
    }

    return (
        <div className="mb-2">
            <div className="card" style={{ minWidth: 250 }}>
                <div className="card-body">
                    <h4 className="card-title">Application Payment</h4>

                    {/* payment information */}
                    {infoMessage && <SimpleAlertView alertType="information" dismissable={false} message={infoMessage} />}

                    {/* payment details */}
                    {showNotificationDetails ? <NotificationView vm={vm} /> : <FeeEstimateView feeEstimate={vm.feeEstimate} />}
                </div>
            </div>
        </div>
    );
}

function FeeEstimateView(props: { feeEstimate: number }): React.ReactElement {
    return (
        <Forms.Group>
            <Forms.ReadonlyField label="Application Fee Estimate" id="applicationFeeEstimateField" value={GlobalHelpers.toNoptaNumberString(props.feeEstimate, "c")} />
        </Forms.Group>
    );
}

function NotificationView(props: { vm: Client.GetCompanyOpggsDraftApplicationDetailsPaymentDto }): React.ReactElement {
    const vm = props.vm;
    return (
        <>
            <Forms.Group>
                <Forms.Element label="Notification ID">
                    <FinancialNotificationLinkController linkDto={vm.financialNotificationLink!} secureEntityType="Company" />
                </Forms.Element>
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="Notification Status" id="notificationStatusField" value={vm.financialNotificationStatus} />
            </Forms.Group>
        </>
    );
}
