// framework
import { useEffect, useState } from "react";
// redux
import * as Common from "../../../../../common/draftApplicationDetails/components/models/models";
import * as Models from "../../models/models";
import validator from "../../models/blocksValidator";
// api
import { DraftApplicationValidationStateEnum } from "../../../../../../api/Client";
// common
import * as ViewHelpers from "../../../../../common/draftApplicationDetails/ViewHelpers";
import { ValidationVisibilityEnum } from "../../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../../common/validation/ValidationMessageView";
import DirtyScope from "../../../../../../common/shell/DirtyScopeController";
import ExternalLinkView from "../../../../../../common/externalLink/ExternalLinkView";
import NumericTextBoxView from "../../../../../../common/numericTextBox/NumericTextBoxView";
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as toastHelper from "../../../../../../common/toastHelper";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";

export default function BlockDetailsView(props: {
    vm: Models.IEpRenewalBlockDetailsViewModel;
    canManage: boolean;
    validationState: DraftApplicationValidationStateEnum;
    viewState: Common.ApplicationViewStateEnum;
    inEditTask: Common.ApplicationTaskEnum | undefined;
    halvingRulesLink: string | undefined;
    updateEpRenewalBlocks: (vm: Models.IEpRenewalBlocksViewModel) => void;
    onEdit: () => void;
    onSave: () => void;
    onCancel: () => void;
}) {
    const vm = props.vm;

    return (
        <div className="card mb-2">
            <div className="card-body">
                <h4 className="card-title">Blocks</h4>
                {props.inEditTask === Common.ApplicationTaskEnum.BlockDetails ? (
                    <BlocksView vm={vm} halvingRulesLink={props.halvingRulesLink} updateViewModel={props.updateEpRenewalBlocks} onSave={props.onSave} onCancel={props.onCancel} />
                ) : (
                    <BlocksReadonlyView
                        vm={vm}
                        showCommands={ViewHelpers.showCommands(props.validationState, props.canManage, props.viewState)}
                        disableEdit={ViewHelpers.isInEdit(props.inEditTask)}
                        halvingRulesLink={props.halvingRulesLink}
                        onEdit={props.onEdit}
                    />
                )}
            </div>
        </div>
    );
}

function BlocksView(props: {
    vm: Models.IEpRenewalBlockDetailsViewModel;
    halvingRulesLink: string | undefined;
    updateViewModel: (vm: Models.IEpRenewalBlocksViewModel) => void;
    onSave: () => void;
    onCancel: () => void;
}) {
    const vm = props.vm;

    const [state, setState] = useState<Models.IEpRenewalBlocksViewModel>({
        blocksRenewed: undefined,
        blocksRelinquished: undefined,
    });

    useEffect(() => {
        setState({
            blocksRenewed: vm.blocksRenewed,
            blocksRelinquished: vm.blocksRelinquished,
        });
    }, [vm]);

    // validation
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const validation = validator(vm, validationVisibility);

    // save
    function onSaveClicked(e: any) {
        e.preventDefault();

        // validation
        // - validation is hidden until the user attempts the action... it then stays visible until they have successfully completed the action
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);

        // submit
        props.onSave();
    }

    function onCancelClicked(e: any) {
        e.preventDefault();
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        props.onCancel();
    }

    // on change - update state and viewModel together as both block values are required for validation
    function onChange(values: Models.IEpRenewalBlocksViewModel) {
        const updatedState = { ...state, ...values };

        setState(updatedState);
        props.updateViewModel(updatedState);
    }

    return (
        <DirtyScope scopeName="company/opggs/draftApplicationDetails/epRenewal/blocks" isDirty={vm.isDirty}>
            <form onSubmit={onSaveClicked}>
                <Forms.Row>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField label="Number of Blocks on current Permit" id="numberOfBlocksField" value={GlobalHelpers.toNoptaIntegerString(vm.numberOfBlocks)} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField label="Name of Location(s)" id="locationsField" value={vm.locations} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.Label htmlFor="blocksRenewedField">Number of Blocks to be Renewed</Forms.Label>
                        <NumericTextBoxView
                            id="blocksRenewedField"
                            value={state.blocksRenewed}
                            valid={validation.applyValidityForKendo("BlockDetails.BlocksRenewed")}
                            format="n0"
                            min={0}
                            max={vm.numberOfBlocks}
                            onChange={(value) => onChange({ blocksRenewed: value })}
                        />
                        <ValidationMessageView name="BlockDetails.BlocksRenewed" validationModel={validation} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField label="Number of Location Blocks" id="numberOfLocationBlocksField" value={GlobalHelpers.toNoptaIntegerString(vm.numberOfLocationBlocks)} />
                    </Forms.HalfColumn>

                    <Forms.HalfColumn>
                        <Forms.Label htmlFor="blocksRelinquishedField">Number of Blocks to be Relinquished</Forms.Label>
                        <NumericTextBoxView
                            id="blocksRelinquishedField"
                            value={state.blocksRelinquished}
                            valid={validation.applyValidityForKendo("BlockDetails.BlocksRelinquished")}
                            format="n0"
                            min={0}
                            max={vm.numberOfBlocks}
                            onChange={(value) => onChange({ blocksRelinquished: value })}
                        />
                        <ValidationMessageView name="BlockDetails.BlocksRelinquished" validationModel={validation} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <ExternalLinkView href={props.halvingRulesLink}>Halving Rules</ExternalLinkView>
                    </Forms.HalfColumn>
                </Forms.Row>
                <div className="d-flex flex-wrap mt-2">
                    <div className="ms-auto">
                        <button className="btn btn-outline-secondary me-2" type="button" onClick={onCancelClicked}>
                            Cancel
                        </button>
                        <button className="btn btn-outline-primary" type="submit">
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </DirtyScope>
    );
}

function BlocksReadonlyView(props: { vm: Models.IEpRenewalBlockDetailsViewModel; showCommands: boolean; disableEdit: boolean; halvingRulesLink: string | undefined; onEdit: () => void }) {
    const vm = props.vm;

    return (
        <>
            <Forms.Row>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField label="Number of Blocks on current Permit" id="numberOfBlocksField" value={GlobalHelpers.toNoptaIntegerString(vm.numberOfBlocks)} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField label="Name of Location(s)" id="locationsField" value={vm.locations} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField label="Number of Blocks to be Renewed" id="blocksRenewedField" value={GlobalHelpers.toNoptaIntegerString(vm.blocksRenewed)} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField label="Number of Location Blocks" id="numberOfLocationBlocksField" value={GlobalHelpers.toNoptaIntegerString(vm.numberOfLocationBlocks)} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField label="Number of Blocks to be Relinquished" id="blocksRelinquishedField" value={GlobalHelpers.toNoptaIntegerString(vm.blocksRelinquished)} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <ExternalLinkView href={props.halvingRulesLink}>Halving Rules</ExternalLinkView>
                </Forms.HalfColumn>
            </Forms.Row>
            <div className="d-flex flex-wrap mt-2">
                {props.showCommands && (
                    <div className="ms-auto">
                        <button className="btn btn-outline-primary" type="button" disabled={props.disableEdit} onClick={props.onEdit}>
                            Edit
                        </button>
                    </div>
                )}
            </div>
        </>
    );
}
