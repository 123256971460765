// framework
import { useState } from "react";
// api
import * as Client from "../../../../../api/Client";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// other
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import FinancialNotificationLinkController from "../../../../../common/links/FinancialNotificationLinkController";
import TitleLinkController from "../../../../../common/links/TitleLinkController";
import ApplicationLinkController from "../../../../../common/links/ApplicationLinkController";
import DraftApplicationLinkController from "../../../../../common/links/DraftApplicationLinkController";
import { createGridColumnFilterMenu } from "../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
import CustomGridCell from "../../../../../common/kendoGrid/CustomGridCell";
import LicenceLinkController from "../../../../../common/links/LicenceLinkController";

export default function PaymentHistoryView(props: { vm: Array<Client.CompanyFinanceFnListDetailDto> }): React.ReactElement {
    const fns = props.vm;

    const initialGridState: State = {
        sort: [{ field: "financialNotificationDueDate", dir: "desc" }],
        skip: 0,
        take: 20,
    };
    const [gridState, setGridState] = useState(initialGridState);

    return (
        <Grid
            pageable
            sortable
            resizable
            navigatable
            data={process(fns, gridState)}
            {...gridState}
            onDataStateChange={(e) => {
                setGridState(e.dataState);
            }}
        >
            <GridColumn field="financialNotificationDueDate" title="Due Date" width={120} format={GlobalHelpers.NoptaDateFormatForKendo} filter="date" columnMenu={createGridColumnFilterMenu} />
            <GridColumn field="paymentConfirmationDate" title="Payment Confirmation" width={190} format={GlobalHelpers.NoptaDateFormatForKendo} filter="date" columnMenu={createGridColumnFilterMenu} />
            <GridColumn
                field="financialNotificationLink.code"
                title="Notification ID"
                width={140}
                filter="text"
                columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, fns)}
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        <FinancialNotificationLinkController linkDto={props.dataItem.financialNotificationLink} secureEntityType="Company" />
                    </CustomGridCell>
                )}
            />
            <GridColumn
                field="financialNotificationIssueDatetime"
                title="Issue Date"
                width={140}
                format={GlobalHelpers.NoptaDateFormatForKendo}
                filter="date"
                columnMenu={createGridColumnFilterMenu}
            />
            <GridColumn
                field="titleLicenceNumber"
                title="Title/Licence Number"
                width={200}
                filter="text"
                columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, fns)}
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        {props.dataItem.administrationType === Client.AdministrationTypeEnum.Opggs && props.dataItem.titleLink && (
                            <TitleLinkController linkDto={props.dataItem.titleLink} secureEntityType="Company" />
                        )}
                        {props.dataItem.administrationType === Client.AdministrationTypeEnum.Oei && props.dataItem.licenceLink && (
                            <LicenceLinkController linkDto={props.dataItem.licenceLink} secureEntityType="Company" />
                        )}
                    </CustomGridCell>
                )}
            />
            <GridColumn field="companyName" title="Company" filter="text" width={300} columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, fns)} />
            <GridColumn field="financialNotificationType" title="Notification Type" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, fns)} />
            <GridColumn field="anniversaryDate" title="Anniversary Date" width={170} format={GlobalHelpers.NoptaDateFormatForKendo} filter="date" columnMenu={createGridColumnFilterMenu} />
            <GridColumn
                field="trackingNumberDisplay"
                title="Tracking Number"
                width={170}
                filter="text"
                columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, fns)}
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        {props.dataItem.applicationLink ? (
                            <ApplicationLinkController linkDto={props.dataItem.applicationLink} secureEntityType="Company" />
                        ) : props.dataItem.draftApplicationLink ? (
                            <DraftApplicationLinkController linkDto={props.dataItem.draftApplicationLink} secureEntityType="Company" />
                        ) : (
                            props.dataItem.trackingNumberDisplay
                        )}
                    </CustomGridCell>
                )}
            />
            <GridColumn field="totalAmount" title="Total Amount" filter="numeric" width={150} format="{0:c}" className="text-end" columnMenu={createGridColumnFilterMenu} />
            <GridColumn field="financialNotificationStatus" title="Notification Status" width={180} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, fns)} />
        </Grid>
    );
}
