// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_SurveysAcquisitions_AcquisitionReportAttachments;

export default function* deleteWellReportAttachment(action: Actions.IAction<Actions.IDeleteReportAttachmentRequestedPayload>) {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const { reportId, reportVersionNumber, reportAttachmentId } = action.payload;
    try {
        yield put(shellHelper.createSetBusyAction());
        const deleteResult: CallHelper.CallResult<Client.DeleteGeoscienceAustraliaReportAttachmentResponseDto> = yield call(
            async () => await deleteReportAttachmentCall(reportId, reportVersionNumber, reportAttachmentId)
        );
        if (deleteResult.IsSuccess) {
            // success
            let updatedVm = vm;
            const deleteResponse = deleteResult.Response!;
            if (deleteResponse.statusMessages!.isSuccess) {
                const getAttachmentsResponse: CallHelper.CallResult<Client.GetGeoscienceAustraliaSurveysAcquisitionsAcquisitionReportAttachmentsResponseDto> = yield call(
                    async () => await getReportAttachments(vm.acquisitionId!)
                );
                updatedVm = vm.refreshReportAttachments(getAttachmentsResponse.Response!);
            }
            yield put(Actions.actionFactory.sagaCompleted(updatedVm.refreshStatusMessages(deleteResponse.statusMessages)));
        } else if (deleteResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict(true)));
        } else {
            // all other errors
            deleteResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
async function deleteReportAttachmentCall(
    reportId: number,
    reportVersionNumber: number,
    reportAttachmentId: number
): Promise<CallHelper.CallResult<Client.DeleteGeoscienceAustraliaReportAttachmentResponseDto>> {
    const request = new Client.DeleteGeoscienceAustraliaReportAttachmentRequestDto({
        reportId: reportId,
        reportVersionNumber: reportVersionNumber,
        reportAttachmentId: reportAttachmentId,
    });
    const client = await ClientFactory.createGeoscienceAustraliaReportClient();
    return await CallHelper.call(() => client.deleteGeoscienceAustraliaReportAttachment(request));
}

async function getReportAttachments(acquisitionId: number): Promise<CallHelper.CallResult<Client.GetGeoscienceAustraliaSurveysAcquisitionsAcquisitionReportAttachmentsResponseDto>> {
    const request = new Client.GetGeoscienceAustraliaSurveysAcquisitionsAcquisitionReportAttachmentsRequestDto({
        acquisitionId: acquisitionId,
    });

    const client = await ClientFactory.createGeoscienceAustraliaSurveysAcquisitionsClient();
    return await CallHelper.call(() => client.getGeoscienceAustraliaSurveysAcquisitionsAcquisitionReportAttachments(request));
}
