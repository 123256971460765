// framework
import { useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
// views
import MyDetailsView from "./views/MyDetailsView";
import MyDetailsEditView from "./views/MyDetailsEditView";
import StatusMessagesAlertsView from "../../../../common/alerts/StatusMessagesAlertsView";
import ConflictAlertView from "../../../../common/alerts/ConflictAlertView";
import PendingRequestsView from "./views/PendingRequestsView";
import ExistingPermissionsView from "./views/ExistingPermissionsView";
import SimpleAuditEventsView from "../../../../common/audit/SimpleAuditEventsView";

interface IMyDetailsControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function MyDetailsController(props: IMyDetailsControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    // on mount
    useEffect(() => {
        actions.initialiseRequested();
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BodyLayout
            title="My Details"
            selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_Account_ManageMyDetails}
            onReloadRequested={() => {
                actions.initialiseRequested();
            }}
        >
            <ConflictAlertView isConflict={vm.isConflict} />
            <StatusMessagesAlertsView statusMessages={vm.service.statusMessages} />
            {!vm.isEditable && <MyDetailsView vm={vm} actions={actions} />}
            {vm.isEditable && <MyDetailsEditView vm={vm} actions={actions} />}
            <ExistingPermissionsView vm={vm} />
            <PendingRequestsView vm={vm} />
            <SimpleAuditEventsView className="mt-4" simpleAuditEvents={vm.secureUser?.audit} />
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_Account_MyDetails }), Actions.actionFactory)(MyDetailsController);
