// api
import * as Client from "../../../../api/Client";
// kendo
import { orderBy } from "@progress/kendo-data-query";
// local
import * as Models from "./jointAuthorityAdministratorRequestViewModel";
// other
import { clone, cloneDeep } from "lodash";
import { BaseJointAuthorityRoleRootViewModel, IJointAuthorityRoleRootViewModel } from "../../../common/JointAuthorityRoleRootViewModel";

export interface IJointAuthorityAdministratorRequestsDecisionViewModel {
    isSuspicious: boolean;
    comments: string;
}

export interface IJointAuthorityAdministratorRequestsViewModel extends IJointAuthorityAdministratorRequestsDecisionViewModel {
    requests: Array<Models.IJointAuthorityAdministratorRequestViewModel>;
    isRequestLimitExceeded: boolean;
}

export interface INoptaRequestsViewModel {
    requests: Array<Client.NoptaJointAuthorityRequestListItemDto>;
    isRequestLimitExceeded: boolean;
}

export interface IRootViewModel extends IJointAuthorityRoleRootViewModel {
    jointAuthorityAdministrator: IJointAuthorityAdministratorRequestsViewModel;
    nopta: INoptaRequestsViewModel;

    service: {
        statusMessages?: Client.StatusMessagesDto;
    };

    isConflict: boolean;

    refreshRequestDetails(response: Client.GetJointAuthorityUserManagementRequestListResponseDto): IRootViewModel;
    refreshBatchProcessResponse(statusMessages: Client.StatusMessagesDto): IRootViewModel;
    refreshConflict(): IRootViewModel;
    // view triggered state changes
    refreshSelectionChanged(requests: Array<Models.IJointAuthorityAdministratorRequestViewModel>): IRootViewModel;
    refreshDecisionDetailsChanged(values: IJointAuthorityAdministratorRequestsDecisionViewModel): IRootViewModel;
}

export class RootViewModel extends BaseJointAuthorityRoleRootViewModel implements IRootViewModel {
    constructor() {
        super();
        this.jointAuthorityAdministrator = {
            requests: new Array<Models.IJointAuthorityAdministratorRequestViewModel>(),
            isRequestLimitExceeded: false,
            isSuspicious: false,
            comments: "",
        };
        this.nopta = {
            requests: new Array<Client.NoptaJointAuthorityRequestListItemDto>(),
            isRequestLimitExceeded: false,
        };

        this.service = {
            statusMessages: undefined,
        };

        this.isConflict = false;
    }

    public jointAuthorityAdministrator: IJointAuthorityAdministratorRequestsViewModel;
    public nopta: INoptaRequestsViewModel;

    public service: {
        statusMessages?: Client.StatusMessagesDto;
    };

    public isConflict: boolean;

    public refreshRequestDetails(response: Client.GetJointAuthorityUserManagementRequestListResponseDto): IRootViewModel {
        const vm = this._clone();

        const jointAuthorityAdministratorRequests = response.jointAuthorityAdministratorRequests.map(
            (r) => new Models.JointAuthorityAdministratorRequestViewModel(vm.roleConfiguration.roleRelationships!, r)
        );
        vm.jointAuthorityAdministrator.requests = orderBy(jointAuthorityAdministratorRequests, [{ field: "requestDatetime", dir: "asc" }]);
        vm.jointAuthorityAdministrator.isRequestLimitExceeded = response.isJointAuthorityAdministratorRequestLimitExceeded;
        vm.nopta.requests = orderBy(response.noptaRequests, [{ field: "requestDatetime", dir: "asc" }]);
        vm.nopta.isRequestLimitExceeded = response.isNoptaRequestLimitExceeded;

        return vm;
    }

    public refreshBatchProcessResponse(statusMessages: Client.StatusMessagesDto): IRootViewModel {
        const vm = this._clone();

        vm.service.statusMessages = statusMessages;
        vm.isConflict = false;

        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        vm.service.statusMessages = undefined;
        return vm;
    }

    public refreshSelectionChanged(requests: Array<Models.IJointAuthorityAdministratorRequestViewModel>): IRootViewModel {
        const vm = this._clone();

        vm.jointAuthorityAdministrator.requests = requests;
        return vm;
    }

    public refreshDecisionDetailsChanged(values: IJointAuthorityAdministratorRequestsDecisionViewModel): IRootViewModel {
        const vm = this._clone();

        vm.jointAuthorityAdministrator = { ...vm.jointAuthorityAdministrator, ...values };
        return vm;
    }

    // private implementation
    // ----------------------
    private _clone(): RootViewModel {
        const vm = clone(this);

        vm.jointAuthorityAdministrator = cloneDeep(this.jointAuthorityAdministrator);
        vm.nopta = clone(this.nopta);
        vm.service = clone(this.service);

        return vm;
    }
}
