// redux
import * as Models from "../../models/models";
// common
import * as Forms from "../../../../../../../common/forms/BootstrapForms";

export default function ContactReadonlyView(props: { vm: Models.IRootViewModel }) {
    const vm = props.vm;

    return (
        <>
            <Forms.Row>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField label="Company Name" id="companyNameField" value={vm.companyName} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField label="Phone" id="phoneField" value={vm.contact.phone} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField label="Primary Contact Name" id="primaryContactNameField" value={vm.contact.primaryContactName} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField label="Email" id="emailField" value={vm.contact.email} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField label="Position Held" id="positionHeldField" value={vm.contact.positionHeld} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField label="Fax" id="faxField" value={vm.contact.fax} />
                </Forms.HalfColumn>
                <Forms.FullColumn>
                    <Forms.ReadonlyField label="Business Address" id="businessAddressField" value={vm.contact.businessAddress} />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.Label htmlFor="overlappingContactTypeTypeField">Overlapping Contact</Forms.Label>
                    <p>Please confirm if you would like the above contact details to be used or if you wish to provide alternative contact details for that purpose.</p>
                    <Forms.ReadonlyTextInput id="overlappingContactTypeTypeField" value={vm.contact.overlappingContactType?.description} />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.ReadonlyCheckbox
                        id="isOverlappingContactSharingConsentField"
                        label="In the case of your application’s licence area overlapping with that of another feasibility licence application, the Registrar may provide your contact information to other applicants in an overlapping application group. I acknowledge my contact information may be provided to other applicants."
                        checked={vm.contact.isOverlappingContactSharingConsent}
                    />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.Label htmlFor="isElectronicCommunicationConsentField">Do you consent to information being given by way of electronic communication.</Forms.Label>
                    {vm.contact.isElectronicCommunicationConsent !== undefined ? (
                        <Forms.ReadonlyTextInput id="electronicCommunicationField" value={vm.contact.isElectronicCommunicationConsent ? "Yes" : "No"} />
                    ) : (
                        <Forms.ReadonlyTextInput id="electronicCommunicationField" value="" />
                    )}
                </Forms.FullColumn>
            </Forms.Row>
        </>
    );
}
