// framework
import { clone } from "lodash";
// api
import * as Client from "../../../api/Client";

export interface IConfigurationModel {
    bambora: Client.GetShellBamboraConfigurationResponseDto | undefined;
    titleBadges: Client.GetShellTitleBadgesResponseDto | undefined;
    portalLinks: Client.GetShellPortalLinksResponseDto | undefined;
    featureSwitches: Client.GetShellFeatureSwitchesResponseDto | undefined;
    roleConfiguration: Client.GetShellSecureUserRoleConfigurationResponseDto | undefined;
    isStaticConfigurationLoaded: boolean;
    isDynamicConfigurationLoaded: boolean;

    refreshBambora(response: Client.GetShellBamboraConfigurationResponseDto): IConfigurationModel;
    refreshTitleBadges(response: Client.GetShellTitleBadgesResponseDto): IConfigurationModel;
    refreshPortalLinks(response: Client.GetShellPortalLinksResponseDto): IConfigurationModel;
    refreshFeatureSwitches(response: Client.GetShellFeatureSwitchesResponseDto): IConfigurationModel;
    refreshRoleConfiguration(response: Client.GetShellSecureUserRoleConfigurationResponseDto): IConfigurationModel;
}

export class ConfigurationModel implements IConfigurationModel {
    constructor() {
        this.bambora = undefined;
        this.titleBadges = undefined;
        this.portalLinks = undefined;
        this.featureSwitches = undefined;
        this.roleConfiguration = undefined;
    }

    public bambora: Client.GetShellBamboraConfigurationResponseDto | undefined;
    public titleBadges: Client.GetShellTitleBadgesResponseDto | undefined;
    public portalLinks: Client.GetShellPortalLinksResponseDto | undefined;
    public featureSwitches: Client.GetShellFeatureSwitchesResponseDto | undefined;
    public roleConfiguration: Client.GetShellSecureUserRoleConfigurationResponseDto | undefined;

    public get isStaticConfigurationLoaded() {
        return this.roleConfiguration !== undefined && this.portalLinks !== undefined;
    }
    public get isDynamicConfigurationLoaded() {
        return this.bambora !== undefined && this.titleBadges !== undefined;
    }

    private _clone(): ConfigurationModel {
        const vm = clone(this);
        return vm;
    }

    public refreshBambora(response: Client.GetShellBamboraConfigurationResponseDto): IConfigurationModel {
        const m = this._clone();
        m.bambora = response;
        return m;
    }

    public refreshTitleBadges(response: Client.GetShellTitleBadgesResponseDto): IConfigurationModel {
        const m = this._clone();
        m.titleBadges = response;
        return m;
    }

    public refreshPortalLinks(response: Client.GetShellPortalLinksResponseDto): IConfigurationModel {
        const m = this._clone();
        m.portalLinks = response;
        return m;
    }

    public refreshFeatureSwitches(response: Client.GetShellFeatureSwitchesResponseDto): IConfigurationModel {
        const m = this._clone();
        m.featureSwitches = response;
        return m;
    }

    public refreshRoleConfiguration(response: Client.GetShellSecureUserRoleConfigurationResponseDto): IConfigurationModel {
        const m = this._clone();
        m.roleConfiguration = response;
        return m;
    }
}
