// api
import * as Client from "../../../../../../../api/Client";
// common
import * as Forms from "../../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../../common/GlobalHelpers";
// views
import DataMonitoringItemsCategoryView from "./DataMonitoringItemsCategoryView";

interface IProps {
    activityType?: Client.AuthorisationActivityTypeEnum;
    category: Client.GetGeoscienceAustraliaAuthorisationsAuthorisationDetailsResponseCategoryDetailsDto;
    canViewBoreholes: boolean;
    canViewAcquisitions: boolean;
    canViewProjects: boolean;
    canViewDataSubmissions: boolean;
}

export default function ExtensionCategoryView(props: IProps): JSX.Element {
    const { activityType, category, canViewBoreholes, canViewAcquisitions, canViewProjects, canViewDataSubmissions } = props;

    return (
        <>
            <Forms.Row>
                <Forms.QuarterColumn>
                    <Forms.ReadonlyField id="extensionDueDateField" label="Extension Due Date" value={GlobalHelpers.toNoptaDateString(category.extensionDetails?.extensionDueDate)} />
                </Forms.QuarterColumn>
            </Forms.Row>
            <DataMonitoringItemsCategoryView
                activityType={activityType}
                category={category}
                canViewBoreholes={canViewBoreholes}
                canViewAcquisitions={canViewAcquisitions}
                canViewProjects={canViewProjects}
                canViewDataSubmissions={canViewDataSubmissions}
            />
        </>
    );
}
