// framework
import { useEffect, useState } from "react";
// common
import * as ConfigurationHelper from "../shell/configurationHelper";
// Bambora integration code
import * as CustomCheckoutAdaptor from "./customCheckoutAdaptor";
// Views
import CustomCheckoutView from "./CustomCheckoutView";

interface ICustomCheckoutControllerProps {
    // fired whenever the token is set or cleared
    onTokenChanged: (stt: string | undefined) => void;

    // this can be used to display some kind of error message, in the scenario where bambora has issues, will be difficult to test
    onTokenError?: () => void;

    message?: string;
}

function CustomCheckoutController(props: ICustomCheckoutControllerProps): React.ReactElement {
    const [isCustomCheckoutIntialised, setIsCustomCheckoutInitialised] = useState(false);
    const [state, setState] = useState<CustomCheckoutAdaptor.ICustomCheckoutState>(CustomCheckoutAdaptor.defaultState);

    const bamboraMerchantId = ConfigurationHelper.useBamboraMerchantId();
    if (!bamboraMerchantId) throw new Error("Bambora Merchant Id required.");

    const imageUri = process.env.REACT_APP_BAMBORA_CUSTOM_CHECKOUT_IMAGE_URI as string;

    // note, this is all a simple react wrapper around the Bambora baseline implementation
    // please keep any changes in sync with the implementation maintained in TERI

    useEffect(() => {
        if (isCustomCheckoutIntialised) return;

        CustomCheckoutAdaptor.load(bamboraMerchantId!, onStateChanged);
        setIsCustomCheckoutInitialised(true);

        return () => {
            CustomCheckoutAdaptor.unload();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // publish the new token back to the calling component, only if it has changed
        props.onTokenChanged(state.token);
    }, [state.token]); // eslint-disable-line react-hooks/exhaustive-deps

    function onStateChanged(newState: CustomCheckoutAdaptor.ICustomCheckoutState): void {
        setState(newState);
    }

    function onVerify() {
        CustomCheckoutAdaptor.startGetToken();
    }

    return <CustomCheckoutView state={state!} onVerify={onVerify} message={props.message} imageUri={imageUri} />;
}

export default CustomCheckoutController;
