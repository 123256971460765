// redux
import { call, put, select } from "redux-saga/effects";
import { IShellReduxState } from "../../../shellReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../api/clientFactory";
import * as CallHelper from "../../../../api/callHelper";
import * as Client from "../../../../api/Client";
// common
import * as shellHelper from "../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../common/LogHelper";

const getRootViewModel = (state: IShellReduxState) => state.Shell_Open;

export default function* getLinkData(action: Actions.IAction<URLSearchParams>) {
    const queryParameters = action.payload;

    try {
        yield put(shellHelper.createSetBusyAction());

        const result: CallHelper.CallResult<Client.GetShellOpenLinkResponseDto> = yield call(getOpenLink, queryParameters);
        const vm: Models.IOpenViewModel = yield select(getRootViewModel);

        if (result.IsSuccess) {
            // success
            const response = result.Response!;
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshOpenLink(response)));
        } else if (result.IsConflict) {
            // not found - interpreted as a conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            result.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

export async function getOpenLink(queryParameters: URLSearchParams): Promise<CallHelper.CallResult<Client.GetShellOpenLinkResponseDto>> {
    const queryParameterDtos = Array.from(queryParameters, ([key, value]) => new Client.GetShellOpenLinkQueryParameterDto({ key, value }));

    const request = new Client.GetShellOpenLinkRequestDto({ queryParameters: queryParameterDtos });
    const client = await ClientFactory.createShellClient();

    return await CallHelper.call(() => client.getShellOpenLink(request));
}
