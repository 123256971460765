// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useResizeDetector } from "react-resize-detector";
// header
import SupportedBrowserBanner from "./views/SupportedBrowserBannerView";
// views
import TopNavView from "./views/TopNavView";
import LhsNavView from "./views/LhsNavView";
import FooterView from "./views/FooterView";
// body
import BusyOverlayController from "./BusyOverlayController";
// error
import { ErrorBoundary } from "react-error-boundary";
import ErrorView from "./views/ErrorView";
// configuration
import ConfigurationController from "../../configuration/ConfigurationController";
// toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./react-toastify-overrides.css";
// other
import { IRootReduxState } from "../../../infrastructure/reduxRootReducer";
import * as Models from "../models/rootViewModel";
import * as Actions from "../redux/actions";
import TrySilentSignInController from "../../signIn/TrySilentSignInController";
import * as shellHelper from "../../../common/shell/shellHelper";
import SessionController from "./SessionController";

interface ILayoutControllerProps extends Actions.IActionFactory {
    rootVm: Models.IRootViewModel;
    children: React.ReactNode;
}

function LayoutController(props: ILayoutControllerProps) {
    const rootVm = props.rootVm;

    // this needs to deal with several concerns:
    // - the topnav & lhsnav always needs to remain visible
    // - topnav cannot visually overlay the lhsnav
    // - lhsnav cannot block interaction of the topnav  (z-order important)
    // - topnav needs to introduce the hamburger
    // - lhsnav needs to layout at the top, and give up attempting to always stay visible  (bootstrap's sticky-top and container-fluid does a good job at this OOTB)
    // - lhsnav cannot overlay footer

    const isSignedIn = shellHelper.useIsSignedIn();

    useEffect(() => {
        // immediately refresh configuration on sign-in/sign-out
        props.refreshLhsNavRequested(isSignedIn, false);

        if (isSignedIn) {
            // once every minute, poll for updated configuration
            // (considering changing to signalR down the track...)
            const timer = setInterval(() => {
                console.debug("Checking for updated Shell Configuration...");
                props.refreshLhsNavRequested(isSignedIn, true);
            }, 60000);

            return function () {
                clearTimeout(timer);
            };
        }
    }, [isSignedIn]); // eslint-disable-line react-hooks/exhaustive-deps

    // we need to measure the height of the navbar, so any additional floating elements can use this to set any required vertical padding
    const { ref: topNavRef, height: topNavHeight } = useResizeDetector();

    // set tab title
    const showPageTitle = rootVm.bodyLayout.pageTitle !== null && rootVm.bodyLayout.pageTitle !== "";
    let title = showPageTitle ? rootVm.bodyLayout.pageTitle + " | NEATS" : "NEATS Secure Portal";
    if (rootVm.isDirty) title = "*" + title;
    document.title = title;

    return (
        <ErrorBoundary FallbackComponent={ErrorView}>
            <TrySilentSignInController />
            <SessionController />
            <BusyOverlayController />
            <ToastContainer
                position="top-right"
                autoClose={15000} // 15 seconds to allow time for screenreaders
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false} // allow user to copy/paste content, they can still click X to close
                pauseOnFocusLoss={true} // stops timer when window has lost focus
                draggable={false}
                pauseOnHover={true} // allows user to copy/paste content
                theme="colored" // this generally matches bootstrappy themes
            />
            <header>
                <div ref={topNavRef}>
                    <TopNavView isSignedIn={isSignedIn} />
                </div>

                <SupportedBrowserBanner />
            </header>

            <main>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col mt-4" style={{ minWidth: 320, maxWidth: 320 }}>
                            {/* 280 allows a bit of padding to sit in the RHS of the LHS menu */}
                            {/* 16px = 1rem, this is from the vertical padding which helps keep the lhs nav in the right place */}
                            <LhsNavView vm={rootVm.lhsNav} selectedLhsNavItem={rootVm.bodyLayout.selectedLhsNavItem} padding={topNavHeight ? topNavHeight! + 16 : undefined} width={280} />
                        </div>
                        <div className="col mt-3" style={{ minWidth: 320 }}>
                            <ConfigurationController>{props.children}</ConfigurationController>
                        </div>
                    </div>
                </div>
            </main>

            <footer>
                <FooterView />
            </footer>
        </ErrorBoundary>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootVm: state.Shell_Layout }), Actions.actionFactory)(LayoutController);
