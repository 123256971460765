// redux
import { call, put, select } from "redux-saga/effects";
import { ICompanyReduxState } from "../../../../companyReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/rootViewModel";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

interface IResponse {
    statusMessages: Client.StatusMessagesDto;
}

const getRootViewModel = (state: ICompanyReduxState) => state.Company_UserManagement_RequestDetails;

export default function* approveRejectRequestGroup() {
    let vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        let saveResult: CallHelper.CallResult<IResponse>;

        switch (vm.summary.type) {
            case Client.SecureRequestGroupTypeEnum.CR:
                saveResult = yield call(async () => await approveRejectCr(vm));
                break;
            case Client.SecureRequestGroupTypeEnum.NU_CR:
                saveResult = yield call(async () => await approveRejectNuCr(vm));
                break;
            case Client.SecureRequestGroupTypeEnum.CS:
                saveResult = yield call(async () => await approveRejectCs(vm));
                break;
            case Client.SecureRequestGroupTypeEnum.CR_CS:
                saveResult = yield call(async () => await approveRejectCrCs(vm));
                break;
            case Client.SecureRequestGroupTypeEnum.NU_CR_CS:
                saveResult = yield call(async () => await approveRejectNuCrCs(vm));
                break;
            default:
                throw new Error("Unsupported Request Type.");
        }

        if (saveResult.IsSuccess) {
            // success
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshApproveRejectRequestGroupResponse(saveResult.Response!.statusMessages)));
        } else if (saveResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            saveResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function approveRejectCr(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createCompanyUserManagementClient();

    if (vm.companyStandardRoleDetails.isApproved === true) {
        const approveCrRequest = new Client.ApproveCompanyUserManagementRequestDetailsCrRequestDto({
            secureRequestGroupId: vm.id!,
            versionNumber: vm.versionNumber!,
            rolesApproved: vm.companyStandardRoleDetails.grantedRoles!.getDirectRoles(),
        });

        return await CallHelper.call(() => client.approveCompanyUserManagementRequestDetailsCr(approveCrRequest));
    } else {
        const rejectCrRequest = new Client.RejectCompanyUserManagementRequestDetailsCrRequestDto({
            secureRequestGroupId: vm.id!,
            versionNumber: vm.versionNumber!,
            comments: vm.rejectionDetails.comments,
            isSuspicious: vm.rejectionDetails.isSuspicious!,
        });

        return await CallHelper.call(() => client.rejectCompanyUserManagementRequestDetailsCr(rejectCrRequest));
    }
}

async function approveRejectNuCr(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createCompanyUserManagementClient();

    if (vm.companyStandardRoleDetails.isApproved === true) {
        const approveNuCrRequest = new Client.ApproveCompanyUserManagementRequestDetailsNuCrRequestDto({
            secureRequestGroupId: vm.id!,
            versionNumber: vm.versionNumber!,
            rolesApproved: vm.companyStandardRoleDetails.grantedRoles!.getDirectRoles(),
        });

        return await CallHelper.call(() => client.approveCompanyUserManagementRequestDetailsNuCr(approveNuCrRequest));
    } else {
        const rejectNuCrRequest = new Client.RejectCompanyUserManagementRequestDetailsNuCrRequestDto({
            secureRequestGroupId: vm.id!,
            versionNumber: vm.versionNumber!,
            comments: vm.rejectionDetails.comments,
            isSuspicious: vm.rejectionDetails.isSuspicious!,
        });

        return await CallHelper.call(() => client.rejectCompanyUserManagementRequestDetailsNuCr(rejectNuCrRequest));
    }
}

async function approveRejectCs(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createCompanyUserManagementClient();

    if (vm.companySignerDetails.isApproved === true) {
        const approveCsRequest = new Client.ApproveCompanyUserManagementRequestDetailsCsRequestDto({
            secureRequestGroupId: vm.id!,
            versionNumber: vm.versionNumber!,
        });

        return await CallHelper.call(() => client.approveCompanyUserManagementRequestDetailsCs(approveCsRequest));
    } else {
        const rejectCsRequest = new Client.RejectCompanyUserManagementRequestDetailsCsRequestDto({
            secureRequestGroupId: vm.id!,
            versionNumber: vm.versionNumber!,
            comments: vm.rejectionDetails.comments,
            isSuspicious: vm.rejectionDetails.isSuspicious!,
        });

        return await CallHelper.call(() => client.rejectCompanyUserManagementRequestDetailsCs(rejectCsRequest));
    }
}

async function approveRejectCrCs(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createCompanyUserManagementClient();

    const isCompanyStandardRoleRequestApproved = vm.companyStandardRoleDetails.isApproved ?? false;
    const grantedRoles = vm.companyStandardRoleDetails.grantedRoles!.getDirectRoles();

    if (vm.companySignerDetails.isApproved === true) {
        const approveCrCsRequest = new Client.ApproveCompanyUserManagementRequestDetailsCrCsRequestDto({
            secureRequestGroupId: vm.id!,
            versionNumber: vm.versionNumber!,
            isCompanyStandardRoleRequestApproved: isCompanyStandardRoleRequestApproved,
            rolesApproved: grantedRoles,
        });

        return await CallHelper.call(() => client.approveCompanyUserManagementRequestDetailsCrCs(approveCrCsRequest));
    } else {
        const rejectCrCsRequest = new Client.RejectCompanyUserManagementRequestDetailsCrCsRequestDto({
            secureRequestGroupId: vm.id!,
            versionNumber: vm.versionNumber!,
            comments: vm.rejectionDetails.comments,
            isCompanyStandardRoleRequestApproved: isCompanyStandardRoleRequestApproved,
            rolesApproved: grantedRoles,
            isSuspicious: vm.rejectionDetails.isSuspicious!,
        });

        return await CallHelper.call(() => client.rejectCompanyUserManagementRequestDetailsCrCs(rejectCrCsRequest));
    }
}

async function approveRejectNuCrCs(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createCompanyUserManagementClient();

    const isCompanyStandardRoleRequestApproved = vm.companyStandardRoleDetails.isApproved ?? false;
    const grantedRoles = vm.companyStandardRoleDetails.grantedRoles!.getDirectRoles();

    if (vm.companySignerDetails.isApproved === true) {
        const approveNuCrCsRequest = new Client.ApproveCompanyUserManagementRequestDetailsNuCrCsRequestDto({
            secureRequestGroupId: vm.id!,
            versionNumber: vm.versionNumber!,
            isCompanyStandardRoleRequestApproved: isCompanyStandardRoleRequestApproved,
            rolesApproved: grantedRoles,
        });

        return await CallHelper.call(() => client.approveCompanyUserManagementRequestDetailsNuCrCs(approveNuCrCsRequest));
    } else {
        const rejectNuCrCsRequest = new Client.RejectCompanyUserManagementRequestDetailsNuCrCsRequestDto({
            secureRequestGroupId: vm.id!,
            versionNumber: vm.versionNumber!,
            comments: vm.rejectionDetails.comments,
            isCompanyStandardRoleRequestApproved: isCompanyStandardRoleRequestApproved,
            rolesApproved: grantedRoles,
            isSuspicious: vm.rejectionDetails.isSuspicious!,
        });

        return await CallHelper.call(() => client.rejectCompanyUserManagementRequestDetailsNuCrCs(rejectNuCrCsRequest));
    }
}
