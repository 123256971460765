// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
import * as Common from "../../../../../common/draftApplicationDetails/components/models/models";
// api
import { DraftApplicationSupportingDocumentationChecklistItemTypeEnum } from "../../../../../../api/Client";
// views
import ApplicationDetailsView from "./ApplicationDetailsView";
import BlockDetailsView from "./BlockDetailsView";
// common
import * as ViewHelpers from "../../../../../common/draftApplicationDetails/ViewHelpers";
import ContactDetailsView from "../../../common/components/ContactDetailsView";
import EvaDetailsView from "../../../common/components/EvaDetailsView";
import SupportingDocumentationView from "../../../../../common/draftApplicationDetails/components/SupportingDocumentationView";
import { IChecklistTemplateViewModel } from "../../../../../common/draftApplicationDetails/controls/supportingDocumentation/models/models";
import PaymentDetailsView from "../../../../../common/draftApplicationDetails/components/PaymentDetailsView";
import SignatureTypeView from "../../../../../common/draftApplicationDetails/components/SignatureTypeView";
import FormDocumentationView from "../../../../../common/draftApplicationDetails/components/FormDocumentationView";
import CommandsView from "../../../../../common/draftApplicationDetails/components/CommandsView";
import SimpleAuditEventsView from "../../../../../../common/audit/SimpleAuditEventsView";

export default function DetailsView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; halvingRulesLink: string | undefined; lastRefreshedDatetime: Date | undefined }) {
    const vm = props.vm;
    const a = props.actions;
    const payment = vm.paymentDetails!;
    const isDigitalSignatures = vm.applicationDetails.isDigitalSignatures;

    const state = vm.state;
    const id = state.id!;
    const versionNumber = state.versionNumber!;
    const viewState = state.viewState!;

    function onSaveApplicationDetails() {
        a.saveApplicationDetailsRequested();
    }

    function onSaveContactDetails() {
        a.initialiseRequested(id);
    }

    function onSaveEvaDetails() {
        a.initialiseRequested(id);
    }

    function onSaveBlockDetails() {
        a.saveBlockDetailsRequested();
    }

    function onSaveSupportingDocumentation() {
        a.initialiseRequested(id);
    }

    function onSaveFormDocumentation() {
        a.initialiseRequested(id);
    }

    function onSaveSignatureType() {
        a.initialiseRequested(id);
    }

    function onEditRequested(task: Common.ApplicationTaskEnum) {
        a.viewChanged(vm.setInEditTask(task));
    }

    function onCancelRequested() {
        a.initialiseRequested(id);
    }

    function updateEpRenewalDetails(values: Models.IEpRenewalViewModel) {
        a.viewChanged(vm.refreshEpRenewalUpdated(values));
    }

    function updateEpRenewalBlocks(values: Models.IEpRenewalBlocksViewModel) {
        a.viewChanged(vm.refreshEpRenewalBlocksUpdated(values));
    }

    function onReadyForSigning() {
        a.setApplicationAsReadyForSigningRequested();
    }

    function onRevertToPreparing() {
        a.revertApplicationToPreparingRequested();
    }

    function onPaying() {
        a.setApplicationAsPayingRequested();
    }

    function onReadyForSubmission() {
        a.setApplicationAsReadyForSubmissionRequested();
    }

    function onSubmit() {
        a.submitApplicationToNoptaRequested();
    }

    function onDelete() {
        a.deleteApplicationRequested();
    }

    return (
        <>
            <ApplicationDetailsView
                vm={vm.applicationDetails}
                canManage={state.canManageApplicantOnly}
                validationState={state.validationState}
                viewState={viewState}
                inEditTask={state.inEditTask}
                updateEpRenewalDetails={updateEpRenewalDetails}
                onEdit={() => onEditRequested(Common.ApplicationTaskEnum.ApplicationDetails)}
                onSave={onSaveApplicationDetails}
                onCancel={onCancelRequested}
            />
            <ContactDetailsView
                id={id}
                versionNumber={versionNumber}
                canManage={state.canManageApplicantOnly}
                validationState={state.validationState}
                viewState={viewState}
                inEditTask={state.inEditTask}
                onEdit={() => onEditRequested(Common.ApplicationTaskEnum.ContactDetails)}
                onSave={onSaveContactDetails}
                onCancel={onCancelRequested}
                lastRefreshedDatetime={props.lastRefreshedDatetime}
            />
            <EvaDetailsView
                id={id}
                versionNumber={versionNumber}
                canManage={state.canManageApplicantOnly}
                validationState={state.validationState}
                viewState={viewState}
                inEditTask={state.inEditTask}
                onEdit={() => onEditRequested(Common.ApplicationTaskEnum.EvaDetails)}
                onSave={onSaveEvaDetails}
                onCancel={onCancelRequested}
                lastRefreshedDatetime={props.lastRefreshedDatetime}
            />
            <BlockDetailsView
                vm={vm.blockDetails}
                canManage={state.canManageApplicantOnly}
                validationState={state.validationState}
                viewState={viewState}
                inEditTask={state.inEditTask}
                halvingRulesLink={props.halvingRulesLink}
                updateEpRenewalBlocks={updateEpRenewalBlocks}
                onEdit={() => onEditRequested(Common.ApplicationTaskEnum.BlockDetails)}
                onSave={onSaveBlockDetails}
                onCancel={onCancelRequested}
            />
            <SupportingDocumentationView
                id={id}
                versionNumber={versionNumber}
                canManage={state.canManageApplicantOrPartnerCompany}
                validationState={state.validationState}
                viewState={viewState}
                inEditTask={state.inEditTask}
                title="Supporting Documentation"
                checklistTemplate={getChecklistTemplate()}
                onEdit={() => onEditRequested(Common.ApplicationTaskEnum.SupportingDocumentation)}
                onSave={onSaveSupportingDocumentation}
                onCancel={onCancelRequested}
                lastRefreshedDatetime={props.lastRefreshedDatetime}
            />
            <PaymentDetailsView vm={payment} viewState={viewState} />
            <SignatureTypeView
                id={id}
                versionNumber={versionNumber}
                canManage={state.canManageApplicantOnly}
                validationState={state.validationState}
                viewState={viewState}
                inEditTask={state.inEditTask}
                onEdit={() => onEditRequested(Common.ApplicationTaskEnum.SignatureType)}
                onSave={onSaveSignatureType}
                onCancel={onCancelRequested}
                lastRefreshedDatetime={props.lastRefreshedDatetime}
            />
            {ViewHelpers.showApplicationFormSection(state.validationState, viewState) && (
                <FormDocumentationView
                    id={id}
                    versionNumber={versionNumber}
                    canManage={state.canManageFormDocumentation}
                    validationState={state.validationState}
                    viewState={viewState}
                    inEditTask={state.inEditTask}
                    onEdit={() => onEditRequested(Common.ApplicationTaskEnum.FormDocumentation)}
                    onSave={onSaveFormDocumentation}
                    onCancel={onCancelRequested}
                    lastRefreshedDatetime={props.lastRefreshedDatetime}
                />
            )}
            <CommandsView
                vm={state}
                isPaymentRequired={payment.isPaymentRequired}
                isDigitalSignatures={isDigitalSignatures}
                onReadyForSigning={onReadyForSigning}
                onRevertToPreparing={onRevertToPreparing}
                onPaying={onPaying}
                onReadyForSubmission={onReadyForSubmission}
                onSubmit={onSubmit}
                onDelete={onDelete}
            />
            {viewState !== Common.ApplicationViewStateEnum.Submitted && <SimpleAuditEventsView simpleAuditEvents={state.stateAudit} />}
        </>
    );
}

function getChecklistTemplate(): IChecklistTemplateViewModel {
    return {
        items: [
            {
                type: DraftApplicationSupportingDocumentationChecklistItemTypeEnum.EpRenewal_Blocks,
                description: <span>A block listing detailing the blocks to be renewed has been included.</span>,
            },
            {
                type: DraftApplicationSupportingDocumentationChecklistItemTypeEnum.EpRenewal_Technical,
                description: (
                    <>
                        <p>
                            In relation to <strong>each</strong> titleholder, provide details of technical advice that is or will be available to the titleholder(s).
                        </p>
                        <span>
                            Where technical information has been provided to the Titles Administrator recently, and there has been no material change, the information does not need to be provided
                            again. This must be stated in the application and a reference to the relevant documentation should be provided.
                        </span>
                    </>
                ),
            },
            {
                type: DraftApplicationSupportingDocumentationChecklistItemTypeEnum.EpRenewal_Financial,
                description: (
                    <>
                        <p>
                            In relation to <strong>each</strong> titleholder, provide details of financial resources that are or will be available to the titleholder(s). The details must include one
                            of the following:
                        </p>
                        <ul>
                            <li>a copy of (or link to) the titleholder(s) current annual report including financial statements, or</li>
                            <li>a copy of the most recent financial statements for each titleholder.</li>
                        </ul>
                        <p>
                            Where financial information has been provided to the Titles Administrator recently, and there has been no material change, the information does not need to be provided
                            again. This must be stated in the application and a reference to the relevant documentation should be provided.
                        </p>
                        <span>
                            Note: If providing a related entity's annual report or financial statements, a description of the relationship between the titleholder and the related entity is also
                            required to be provided.
                        </span>
                    </>
                ),
            },
        ],
    };
}
