import { takeEvery } from "redux-saga/effects";
import * as Actions from "./actions";

import initialise from "./sagas/initialise";
import savePermissions from "./sagas/savePermissions";

export default function* watcherSaga() {
    yield takeEvery(Actions.actions.initialise, initialise);
    yield takeEvery(Actions.actions.savePermissionsRequested, savePermissions);
}
