import { call, put, select } from "redux-saga/effects";
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { ICompanyReduxState } from "../../../../companyReduxRegistration";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

const getRootViewModel = (state: ICompanyReduxState) => state.Company_Opggs_TitleDetails;

export default function* getCreateDraftApplicationReferenceData(action: Actions.IAction<string>) {
    try {
        yield put(shellHelper.createSetBusyAction());

        const response: Client.GetCompanyOpggsCreateDraftApplicationReferenceDataResponseDto = yield call(async () => {
            const client = await ClientFactory.createCompanyOpggsCreateDraftApplicationClient();
            return await CallHelper.simpleCall(() =>
                client.getCompanyOpggsCreateDraftApplicationReferenceData(new Client.GetCompanyOpggsCreateDraftApplicationReferenceDataRequestDto({ titleId: action.payload }))
            );
        });
        const vm: Models.IRootViewModel = yield select(getRootViewModel);
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshCreateDraftApplicationReferenceData(response)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
