// models
import * as Models from "../../../../common/draftApplicationDetails/components/models/models";
// api
import * as Client from "../../../../../api/Client";

export interface IIaflDetailsViewModel {
    invitationToApplyId?: number | undefined;
    invitationToApplyName?: string | undefined;
    // fields
    declaredAreaId?: number | undefined;
    projectNameOrReference?: string | undefined;
    proposedLicenceArea?: number | undefined;
    proposedGenerationCapacity?: number | undefined;
    renewableEnergyResourceTypeId?: number | undefined;
    technologyTypeId?: number | undefined;
    // reference
    declaredAreas?: Client.GetCompanyOeiDraftApplicationDetailsIaflDeclaredAreaDto[];
    renewableEnergyResources?: Client.GetCompanyOeiDraftApplicationDetailsIaflRenewableEnergyResourceTypeDto[];
    technologyTypes?: Client.GetCompanyOeiDraftApplicationDetailsIaflTechnologyTypeDto[];
}

export interface IIaflApplicationDetailsViewModel extends Models.IApplicationDetailsViewModel {
    iaflDetails: IIaflDetailsViewModel;
}

export interface IRootViewModel extends Models.IDraftApplicationRootViewModel {
    applicationDetails: IIaflApplicationDetailsViewModel;

    // view triggered state changes
    refreshIaflUpdated(values: IIaflDetailsViewModel): IRootViewModel;
}

export class RootViewModel extends Models.BaseDraftApplicationRootViewModel implements IRootViewModel {
    constructor() {
        super();
        this.applicationDetails = {
            isDigitalSignatures: false,
            iaflDetails: {
                invitationToApplyId: undefined,
                invitationToApplyName: "",
                declaredAreaId: undefined,
                projectNameOrReference: "",
                proposedLicenceArea: undefined,
                proposedGenerationCapacity: undefined,
                renewableEnergyResourceTypeId: undefined,
                technologyTypeId: undefined,
                declaredAreas: [],
                renewableEnergyResources: [],
                technologyTypes: [],
            },
            isDirty: false,
        };
    }

    public override applicationDetails: IIaflApplicationDetailsViewModel;

    public override refreshDetails(response: Client.GetCompanyOeiDraftApplicationDetailsIaflResponseDto): this {
        const vm = super.refreshDetails(response);

        vm.applicationDetails.iaflDetails.invitationToApplyId = response.invitationToApplyId;

        // fields
        vm.applicationDetails.iaflDetails.invitationToApplyId = response.invitationToApplyId;
        vm.applicationDetails.iaflDetails.invitationToApplyName = response.invitationToApplyName;
        vm.applicationDetails.iaflDetails.declaredAreaId = response.declaredAreaId;
        vm.applicationDetails.iaflDetails.projectNameOrReference = response.projectNameOrReference;
        vm.applicationDetails.iaflDetails.proposedLicenceArea = response.proposedLicenceArea;
        vm.applicationDetails.iaflDetails.proposedGenerationCapacity = response.proposedGenerationCapacity;
        vm.applicationDetails.iaflDetails.renewableEnergyResourceTypeId = response.renewableEnergyResourceTypeId;
        vm.applicationDetails.iaflDetails.technologyTypeId = response.technologyTypeId;

        // reference
        vm.applicationDetails.iaflDetails.declaredAreas = response.declaredAreas!;
        vm.applicationDetails.iaflDetails.renewableEnergyResources = response.renewableEnergyResourceTypes!;
        vm.applicationDetails.iaflDetails.technologyTypes = response.technologyTypes!;

        vm.applicationDetails.isDirty = false;

        return vm;
    }

    public refreshIaflUpdated(values: IIaflDetailsViewModel): IRootViewModel {
        const vm = this._clone();

        vm.applicationDetails.iaflDetails = { ...vm.applicationDetails.iaflDetails, ...values };
        vm.applicationDetails.isDirty = true;

        return vm;
    }
}
