// redux
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../../api/callHelper";
import * as Client from "../../../../../../../../api/Client";

export async function getCompanyOpggsLegislativeFormsFormRequestSignatureType(id: number): Promise<Client.GetCompanyOpggsLegislativeFormsFormRequestSignatureTypeResponseDto> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();
    return await CallHelper.simpleCall(() => client.getCompanyOpggsLegislativeFormsFormRequestSignatureType(new Client.GetCompanyOpggsLegislativeFormsFormRequestSignatureTypeRequestDto({ id: id })));
}

export async function saveCompanyOpggsLegislativeFormsFormRequestSignatureType(
    vm: Models.IRootViewModel
): Promise<CallHelper.CallResult<Client.SaveCompanyOpggsLegislativeFormsFormRequestSignatureTypeResponseDto>> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();
    return await CallHelper.call(() =>
        client.saveCompanyOpggsLegislativeFormsFormRequestSignatureType(
            new Client.SaveCompanyOpggsLegislativeFormsFormRequestSignatureTypeRequestDto({ id: vm.id!, versionNumber: vm.versionNumber!, signatureType: vm.signatureType!.signatureType! })
        )
    );
}
