// api
import * as Client from "../../../../api/Client";
// views
import * as Forms from "../../../../common/forms/BootstrapForms";

export default function AllTitleholdersReadonlyView(props: { titleholders: Array<Client.FormRequestTitleholderInfoDto> | undefined }) {
    const allTitleholders = props.titleholders?.map((t) => t.companyName).join(", ") ?? "";

    return (
        <Forms.Group>
            <Forms.ReadonlyTextArea label="List of all Titleholders" id="allTitleholdersField" value={allTitleholders} />
        </Forms.Group>
    );
}
