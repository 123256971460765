// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import { IInitialiseRequestedPayload } from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_ReportsAndData_SurveyDataDetails;

export default function* initialise(action: Actions.IAction<IInitialiseRequestedPayload>) {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const { surveyDataId } = action.payload;

    try {
        yield put(shellHelper.createSetBusyAction());

        const referenceResponse: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceDataResponseDto = yield call(async () => {
            const client = await ClientFactory.createGeoscienceAustraliaSurveyDataClient();
            return await CallHelper.simpleCall(() => client.getGeoscienceAustraliaSurveyDataDetailsReferenceData(new Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceDataRequestDto({})));
        });
        const detailsResponse: Client.GetGeoscienceAustraliaSurveyDataDetailsResponseDto = yield call(async () => {
            const client = await ClientFactory.createGeoscienceAustraliaSurveyDataClient();
            return await CallHelper.simpleCall(() => client.getGeoscienceAustraliaSurveyDataDetails(new Client.GetGeoscienceAustraliaSurveyDataDetailsRequestDto({ surveyDataId: surveyDataId })));
        });
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshReferenceData(referenceResponse).refreshDetails(detailsResponse)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
