// common
import * as Validation from "../../../../../common/validation/ValidationModel";
// models
import { IRootViewModel } from "./models";

export default function validate(vm: IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    const saveSurveyDataAttachment = vm.saveSurveyDataAttachment;

    if (!saveSurveyDataAttachment!.name?.trim()) {
        v.addError("Name", "Name is required.");
    }

    if (!saveSurveyDataAttachment!.fileName?.trim()) {
        v.addError("FileName", "File Name is required.");
    }

    if (!saveSurveyDataAttachment!.documentLink?.trim()) {
        v.addError("DocumentLink", "Document Link is required.");
    } else if (!saveSurveyDataAttachment!.documentLink?.trim().startsWith("https://")) {
        v.addError("DocumentLink", "Document Link must start with https://");
    }

    return v;
}
