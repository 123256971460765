// api
import * as Client from "../../../../../../api/Client";
// views
import CombineDataSubmissionCategoryView from "./categories/CombineDataSubmissionCategoryView";
import DigitalTransferCategoryView from "./categories/DigitalTransferCategoryView";
import ExemptionCategoryView from "./categories/ExemptionCategoryView";
import ExportCategoryView from "./categories/ExportCategoryView";
import ExtensionCategoryView from "./categories/ExtensionCategoryView";
import SampleViewCategoryView from "./categories/SampleViewCategoryView";
import SamplingCategoryView from "./categories/SamplingCategoryView";
import SamplingExportCategoryView from "./categories/SamplingExportCategoryView";
import VariationCategoryView from "./categories/VariationCategoryView";

interface IProps {
    activityType?: Client.AuthorisationActivityTypeEnum;
    category: Client.GetGeoscienceAustraliaAuthorisationsAuthorisationDetailsResponseCategoryDetailsDto;
    canViewBoreholes: boolean;
    canViewAcquisitions: boolean;
    canViewProjects: boolean;
    canViewDataSubmissions: boolean;
}

export default function CategoryView(props: IProps): JSX.Element {
    const { activityType, category, canViewBoreholes, canViewAcquisitions, canViewProjects, canViewDataSubmissions } = props;

    return (
        <div className="card mb-2">
            <div className="card-body">
                <h2 className="card-title">{category.title}</h2>
                <>
                    {category.type === Client.AuthorisationCategoryTypeEnum.Exemption && (
                        <ExemptionCategoryView
                            activityType={activityType}
                            category={category}
                            canViewBoreholes={canViewBoreholes}
                            canViewAcquisitions={canViewAcquisitions}
                            canViewProjects={canViewProjects}
                            canViewDataSubmissions={canViewDataSubmissions}
                        />
                    )}
                    {category.type === Client.AuthorisationCategoryTypeEnum.Extension && (
                        <ExtensionCategoryView
                            activityType={activityType}
                            category={category}
                            canViewBoreholes={canViewBoreholes}
                            canViewAcquisitions={canViewAcquisitions}
                            canViewProjects={canViewProjects}
                            canViewDataSubmissions={canViewDataSubmissions}
                        />
                    )}
                    {category.type === Client.AuthorisationCategoryTypeEnum.Variation && (
                        <VariationCategoryView
                            activityType={activityType}
                            category={category}
                            canViewBoreholes={canViewBoreholes}
                            canViewAcquisitions={canViewAcquisitions}
                            canViewProjects={canViewProjects}
                            canViewDataSubmissions={canViewDataSubmissions}
                        />
                    )}
                    {category.type === Client.AuthorisationCategoryTypeEnum.CombineDataSubmission && (
                        <CombineDataSubmissionCategoryView
                            activityType={activityType}
                            category={category}
                            canViewBoreholes={canViewBoreholes}
                            canViewAcquisitions={canViewAcquisitions}
                            canViewProjects={canViewProjects}
                            canViewDataSubmissions={canViewDataSubmissions}
                        />
                    )}
                    {category.type === Client.AuthorisationCategoryTypeEnum.DigitalTransfer && (
                        <DigitalTransferCategoryView
                            activityType={activityType}
                            category={category}
                            canViewBoreholes={canViewBoreholes}
                            canViewAcquisitions={canViewAcquisitions}
                            canViewProjects={canViewProjects}
                            canViewDataSubmissions={canViewDataSubmissions}
                        />
                    )}
                    {category.type === Client.AuthorisationCategoryTypeEnum.Export && (
                        <ExportCategoryView
                            activityType={activityType}
                            category={category}
                            canViewBoreholes={canViewBoreholes}
                            canViewAcquisitions={canViewAcquisitions}
                            canViewProjects={canViewProjects}
                            canViewDataSubmissions={canViewDataSubmissions}
                        />
                    )}
                    {category.type === Client.AuthorisationCategoryTypeEnum.ViewOnly && (
                        <SampleViewCategoryView
                            activityType={activityType}
                            category={category}
                            canViewBoreholes={canViewBoreholes}
                            canViewAcquisitions={canViewAcquisitions}
                            canViewProjects={canViewProjects}
                            canViewDataSubmissions={canViewDataSubmissions}
                        />
                    )}
                    {category.type === Client.AuthorisationCategoryTypeEnum.Sampling && (
                        <SamplingCategoryView
                            activityType={activityType}
                            category={category}
                            canViewBoreholes={canViewBoreholes}
                            canViewAcquisitions={canViewAcquisitions}
                            canViewProjects={canViewProjects}
                            canViewDataSubmissions={canViewDataSubmissions}
                        />
                    )}
                    {category.type === Client.AuthorisationCategoryTypeEnum.SamplingAndExport && (
                        <SamplingExportCategoryView
                            activityType={activityType}
                            category={category}
                            canViewBoreholes={canViewBoreholes}
                            canViewAcquisitions={canViewAcquisitions}
                            canViewProjects={canViewProjects}
                            canViewDataSubmissions={canViewDataSubmissions}
                        />
                    )}
                </>
            </div>
        </div>
    );
}
