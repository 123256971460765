// framework
import { useState, useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// common
import StatusMessagesAlertsView from "../../../../../../common/alerts/StatusMessagesAlertsView";
import ConflictView from "../../../../../../common/alerts/ConflictAlertView";
import SimpleAuditEventsView from "../../../../../../common/audit/SimpleAuditEventsView";
import DirtyScope from "../../../../../../common/shell/DirtyScopeController";
// views
import WarningsView from "./views/WarningsView";
import WorkProgramGridView from "./views/WorkProgramGridView";

interface IControllerProps extends Actions.IActionFactory {
    id: number;
    versionNumber: number;
    cardTitle: string;
    lastRefreshedDatetime: Date | undefined;
    showCommands: boolean;
    disableEdit: boolean;
    onEdit: () => void;
    onSave: () => void;
    onCancel: () => void;
    className?: string | undefined;
    rootViewModel: Models.IRootViewModel;
    guidance: React.ReactElement;
}

function Controller(props: IControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const [lastRefreshedDatetime, setLastRefreshedDatetime] = useState<Date | undefined>(undefined);

    // on mount
    // - initial load
    useEffect(() => {
        actions.initialiseRequested(props.id);
        return function () {
            actions.clearRequested();
        };
    }, [props.id]); // eslint-disable-line react-hooks/exhaustive-deps

    // refresh
    // - reasons to refresh include (1) the user pressing the refresh button, or (2) saves occurring in panels that could invalidate the content here
    // - don't refresh just because the parent refreshed, this can cause us to immediately lose status changes when a save occurs here
    // - also be careful to ensure a refresh only occurs once, it's easy to make a mistake and have the service call happen multiple times
    useEffect(() => {
        // console.info(vm.viewState, props.lastRefreshedDatetime, lastRefreshedDatetime, vm.versionNumber, props.versionNumber);

        // if it's initialising, just wait
        if (vm.viewState === Models.ViewStateEnum.Initialising) return;

        // a save has happened here, so trigger the upstream save call
        if (vm.versionNumber! > props.versionNumber) {
            props.onSave();
            return;
        }

        // a save has happened externally, so trigger a refresh here
        if (vm.versionNumber! < props.versionNumber) {
            actions.initialiseRequested(props.id);
            return;
        }

        // a manual refresh has been requested
        if (lastRefreshedDatetime !== props.lastRefreshedDatetime) {
            setLastRefreshedDatetime(props.lastRefreshedDatetime);
            actions.initialiseRequested(props.id);
        }
    }, [vm.viewState, props.lastRefreshedDatetime, lastRefreshedDatetime, vm.versionNumber, props.versionNumber]); // eslint-disable-line react-hooks/exhaustive-deps

    function onEditClick(): void {
        props.onEdit();
        actions.viewChanged(vm.onEdit());
    }

    function onSaveClick(): void {
        actions.saveRequested();
    }

    function onCancelClick(): void {
        props.onCancel();
        actions.initialiseRequested(vm.id!);
    }

    // return
    return (
        <div className={props.className}>
            <div className="card" style={{ minWidth: 250 }}>
                <div className="card-body">
                    <h4 className="card-title">{props.cardTitle}</h4>

                    <DirtyScope scopeName="company/opggs/draftApplicationDetails/controls/workProgram" isDirty={vm.isDirty} />
                    <ConflictView isConflict={vm.isConflict} />
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} scrollWindowToTop={false} />
                    <WarningsView vm={vm} />

                    {/* guidance */}
                    <div className="mb-2">{props.guidance}</div>

                    {/* work program grid */}
                    <div className="mb-2">
                        <WorkProgramGridView vm={vm} actions={actions} />
                    </div>

                    {/* audit and edit/save/cancel buttons */}
                    <div className="d-flex flex-wrap mt-2">
                        <SimpleAuditEventsView className="mb-1" simpleAuditEvents={vm.audit} />
                        {vm.viewState === Models.ViewStateEnum.View && props.showCommands && (
                            <div className="ms-auto">
                                <button className="btn btn-outline-primary" onClick={onEditClick} disabled={props.disableEdit}>
                                    Edit
                                </button>
                            </div>
                        )}
                        {vm.viewState === Models.ViewStateEnum.Edit && (
                            <div className="ms-auto">
                                <button className="btn btn-outline-secondary me-2" onClick={onCancelClick}>
                                    Cancel
                                </button>
                                <button className="btn btn-outline-primary" onClick={onSaveClick}>
                                    Save
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_Opggs_DraftApplicationDetails_Controls_WorkProgram }), Actions.actionFactory)(Controller);
