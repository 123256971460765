// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../api/Client";
// kendo
import { IPendingRequestViewModel, PendingRequestViewModel } from "./pendingRequestViewModel";
import { IGeoscienceAustraliaRoleRootViewModel, BaseGeoscienceAustraliaRoleRootViewModel } from "../../../common/GeoscienceAustraliaRoleRootViewModel";
import { ExistingRoleViewModel, IExistingRoleViewModel } from "./existingRoleViewModel";
import { IEditableSecureUser, EditableSecureUser } from "./editableSecureUserViewModel";

export interface IRootViewModel extends IGeoscienceAustraliaRoleRootViewModel {
    isDirty: boolean;
    isEditable: boolean;
    isConflict: boolean;
    secureUser: Client.GetGeoscienceAustraliaAccountMyDetailsResponseDto;

    editableSecureUser: IEditableSecureUser;
    refreshDetails(response: Client.GetGeoscienceAustraliaAccountMyDetailsResponseDto): IRootViewModel;
    refreshEditableDetails(details: IEditableSecureUser): IRootViewModel;
    setEditState(edit: boolean): IRootViewModel;

    service: {
        statusMessages?: Client.StatusMessagesDto;
    };
    setStatusMessages(statusMessages: Client.StatusMessagesDto | undefined): IRootViewModel;
    refreshConflict(): IRootViewModel;

    existingPermissions: Array<IExistingRoleViewModel>;
    pendingRequests: Array<IPendingRequestViewModel>;
}

export class RootViewModel extends BaseGeoscienceAustraliaRoleRootViewModel implements IRootViewModel {
    constructor() {
        super();
        this.isDirty = false;
        this.isEditable = false;
        this.isConflict = false;
        this.secureUser = new Client.GetGeoscienceAustraliaAccountMyDetailsResponseDto();
        this.pendingRequests = new Array<IPendingRequestViewModel>();
        this.existingPermissions = new Array<IExistingRoleViewModel>();
        this.editableSecureUser = new EditableSecureUser();
        this.service = {
            statusMessages: undefined,
        };
    }

    public isDirty: boolean;
    public isEditable: boolean;
    public isConflict: boolean;
    public secureUser: Client.GetGeoscienceAustraliaAccountMyDetailsResponseDto;
    public editableSecureUser: IEditableSecureUser;
    public existingPermissions: Array<IExistingRoleViewModel>;
    public pendingRequests: Array<IPendingRequestViewModel>;

    public service: {
        statusMessages?: Client.StatusMessagesDto;
    };

    public refreshDetails(response: Client.GetGeoscienceAustraliaAccountMyDetailsResponseDto): IRootViewModel {
        let vm = this._clone();
        vm.secureUser = response;
        vm.setEditableValues(response);

        vm.pendingRequests = response.pendingRequests.map((r) => new PendingRequestViewModel(vm.roleConfiguration.roleRelationships!, r));

        vm.existingPermissions = new Array(
            new ExistingRoleViewModel(vm.roleConfiguration.roleRelationships!, response.geoscienceAustraliaAdministrators, response.existingRoles, response.pendingRequests.length > 0)
        );

        vm.isDirty = false;
        vm.isEditable = false;
        vm.isConflict = false;
        vm.service.statusMessages = undefined;
        return vm;
    }

    public refreshEditableDetails(details: IEditableSecureUser): IRootViewModel {
        const vm = this._clone();
        vm.setEditableValues(details);
        vm.isDirty = true;
        return vm;
    }

    public setEditState(edit: boolean): IRootViewModel {
        const vm = this._clone();
        vm.isEditable = edit;
        if (vm.isEditable) {
            vm.service.statusMessages = undefined;
        } else {
            vm.setEditableValues(vm.secureUser);
            vm.isDirty = false;
        }
        return vm;
    }

    public setStatusMessages(statusMessages: Client.StatusMessagesDto | undefined): IRootViewModel {
        const vm = this._clone();
        vm.service.statusMessages = statusMessages;
        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        vm.service.statusMessages = undefined;
        return vm;
    }

    private setEditableValues(details: IEditableSecureUser) {
        this.editableSecureUser.correspondenceEmailAddress = details.correspondenceEmailAddress;
        this.editableSecureUser.position = details.position;
        this.editableSecureUser.contactNumber = details.contactNumber;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.secureUser = clone(this.secureUser);
        vm.editableSecureUser = clone(this.editableSecureUser);
        vm.service = clone(this.service);
        vm.existingPermissions = clone(this.existingPermissions);
        vm.pendingRequests = clone(this.pendingRequests);
        return vm;
    }
}
