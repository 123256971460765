// api
import * as ClientFactory from "../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";

export async function getCompanyOpggsApplicationDetailsTitleholders(id: string): Promise<Client.GetCompanyOpggsApplicationDetailsTitleholdersResponseDto> {
    // console.info("getCompanyOpggsApplicationDetailsTitleholders");
    const client = await ClientFactory.createCompanyOpggsApplicationDetailsClient();
    return await CallHelper.simpleCall(() => client.getCompanyOpggsApplicationDetailsTitleholders(new Client.GetCompanyOpggsApplicationDetailsTitleholdersRequestDto({ applicationId: id })));
}
