// framework
import React, { useState } from "react";
import ConfirmationView, { DefaultButtonEnum } from "../../../../../common/confirmation/ConfirmationView";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/rootViewModel";

export default function RemoveUserView(props: { vm: Models.IDetailsViewModel; actions: Actions.IActionFactory }): React.ReactElement {
    const vm = props.vm;
    const actions = props.actions;

    const [showConfirmation, setShowConfirmation] = useState(false);

    // view
    return (
        <div className="card my-2">
            <div className="card-body">
                <h2 className="card-title">Remove User</h2>
                <p className="card-text">
                    This Secure User has no permissions with your companies and will be removed from view on {vm.relationshipExpirationDatetime} (AWST). Click the button below to remove them
                    immediately.
                </p>
                <button className="btn btn-outline-primary" type="button" onClick={() => setShowConfirmation(true)}>
                    Remove User
                </button>
            </div>
            {showConfirmation && (
                <ConfirmationView
                    title={"Remove User"}
                    onAccepted={() => {
                        setShowConfirmation(false);
                        actions.removeUserRequested();
                    }}
                    onClosed={() => setShowConfirmation(false)}
                    defaultButton={DefaultButtonEnum.Cancel}
                >
                    <div>This will remove the Secure User from your view immediately and return you to the My Users list.</div>
                    <div>Do you wish to proceed?</div>
                </ConfirmationView>
            )}
        </div>
    );
}
