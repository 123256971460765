// api
import * as Client from "../../../../api/Client";
// models
import { BaseRoleViewModel, IRoleViewModel } from "../../../../common/roleGrid/RoleViewModel";

export interface IExistingRoleViewModel extends IRoleViewModel {
    geoscienceAustraliaAdministrators?: Client.UserContactDto[] | undefined;
    hasPendingRequest: boolean;
    lastUpdatedBy?: string | undefined;
    lastUpdatedDatetime?: Date | undefined;
}

export class ExistingRoleViewModel extends BaseRoleViewModel implements IExistingRoleViewModel {
    constructor(
        roleRelationships: Array<Client.GetShellSecureUserRoleConfigurationRoleRelationshipDto>,
        geoscienceAustraliaAdministrators: Client.UserContactDto[],
        existingRoles: Client.ISecureUserGeoscienceAustraliaRoleDto,
        hasPendingRequest: boolean
    ) {
        super(roleRelationships, existingRoles.activeRoles);

        this.geoscienceAustraliaAdministrators = geoscienceAustraliaAdministrators;
        this.hasPendingRequest = hasPendingRequest;
        this.lastUpdatedBy = existingRoles.lastUpdatedBy;
        this.lastUpdatedDatetime = existingRoles.lastUpdatedDatetime;
    }

    public readonly geoscienceAustraliaAdministrators?: Client.UserContactDto[] | undefined;
    public readonly hasPendingRequest!: boolean;
    public readonly lastUpdatedBy?: string | undefined;
    public readonly lastUpdatedDatetime?: Date | undefined;

    public getKey(): number {
        // todo investigate the inheritance on this; this is typically used to return a company or joint authority id.
        // given that's not applicable to geoscience australia, should this be refactored or just glossed over like below
        throw new Error("Invalid operation.");
    }
    public canEdit(): boolean {
        return false;
    }
}
