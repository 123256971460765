// framework
import React, { useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
// views
import CompanyListView from "./CompanyListView";

interface ICompanyListControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function CompanyListController(props: ICompanyListControllerProps) {
    const vm = props.rootViewModel;

    // on mount
    useEffect(() => {
        props.sagaGetContentRequested();
        return function () {
            props.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BodyLayout
            title="My Companies"
            selectedLhsNavItem={Level1ItemTypeEnum.Company_UserManagement_ViewCompanyList}
            onReloadRequested={() => {
                props.sagaGetContentRequested();
            }}
        >
            <CompanyListView companies={vm.companies} />
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_UserManagement_CompanyList }), Actions.actionFactory)(CompanyListController);
