// framework
import React from "react";
import { Link } from "react-router-dom";
// api
import * as Client from "../../../../../api/Client";
// other
import * as Forms from "../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import * as companyRouteHelper from "../../../../companyRouteHelper";
import * as BadgeHelper from "../../../../../common/badges/BadgeHelper";
import EmailLinkView from "../../../../../common/externalLink/EmailLinkView";

export default function ExistingUserDetailsView(props: { vm: Client.CompanyUserManagementRequestDetailsExistingUserDetailsDto | undefined }): React.ReactElement {
    const vm = props.vm;
    if (!vm) return <></>;

    return (
        <div className="card mb-2">
            <div className="card-body">
                <h2 className="card-title">Existing User Details</h2>
                <Forms.Row>
                    <Forms.FullColumn>
                        <Forms.Element label="Username">
                            {vm?.secureUserId ? <Link to={companyRouteHelper.userDetails(vm.secureUserId!)}>{vm?.username}</Link> : vm?.username}
                            {vm?.isSecureUserBlocked && <span className="ms-1">{BadgeHelper.getBlockedUserBadge()}</span>}
                            {vm?.isCompanyRevokedButVisible && <span className="ms-1">{BadgeHelper.getCompanyThirtyDayWarningBadge()}</span>}
                        </Forms.Element>
                    </Forms.FullColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="fullNameField" label="Full Name" value={vm?.fullName} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.Element label="Email">
                            <EmailLinkView emailAddress={vm?.emailAddress} />
                        </Forms.Element>
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="positionField" label="Position" value={vm?.position} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="contactNumberField" label="Contact Number" value={vm?.contactNumber} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="lastLoginField" label="Last Login (AWST)" value={GlobalHelpers.toNoptaDatetimeString(vm?.lastLoginDatetime)} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="registrationDateField" label="Registration Date (AWST)" value={GlobalHelpers.toNoptaDatetimeString(vm?.registrationDatetime)} />
                    </Forms.HalfColumn>
                </Forms.Row>
            </div>
        </div>
    );
}
