// framework
import { Link } from "react-router-dom";
// api
import {
    GetGeoscienceAustraliaSurveysAcquisitionsSurveyAcquisitionsResponseAcquisitionDto,
    GetGeoscienceAustraliaSurveysAcquisitionsSurveyAcquisitionsResponseTitleDto,
    NopimsTitleTypeEnum,
} from "../../../../../../api/Client";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import ExternalLinkView from "../../../../../../common/externalLink/ExternalLinkView";
import * as shellRouteHelper from "../../../../../../shell/shellRouteHelper";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";

interface IProps {
    surveyId: number;
    acquisition: GetGeoscienceAustraliaSurveysAcquisitionsSurveyAcquisitionsResponseAcquisitionDto;
    surveyOperator?: string | undefined;
    titleConditions: GetGeoscienceAustraliaSurveysAcquisitionsSurveyAcquisitionsResponseTitleDto[];
}

export default function SurveyAcquisitionsView(props: IProps): JSX.Element {
    const { surveyId, acquisition, surveyOperator, titleConditions } = props;

    return (
        <div className="card mb-2">
            <div className="card-body">
                <h2 className="card-title">{`Acquisition - ${acquisition.name}`}</h2>
                <Forms.Group>
                    <Forms.Row>
                        <Forms.FullColumn>
                            <Forms.Element label="Acquisition ID">
                                <Link to={geoscienceAustraliaRouteHelper.acquisitionDetails(surveyId, acquisition.id)} title="Click to view Acquisition details.">
                                    {acquisition.identifier}
                                </Link>
                            </Forms.Element>
                        </Forms.FullColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="aliasField" label="Alias" value={acquisition.alias} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="statusField" label="Status" value={acquisition.status} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="typeField" label="Type" value={acquisition.type} />
                        </Forms.QuarterColumn>
                    </Forms.Row>
                </Forms.Group>
                <Forms.Group>
                    <Forms.Row>
                        <Forms.QuarterColumn>
                            <Forms.Label htmlFor="titleConditionField">Title Condition(s)</Forms.Label>
                            <div>
                                {titleConditions && titleConditions.length > 0 ? (
                                    <>
                                        {titleConditions.map((title, index) => (
                                            <span key={index}>
                                                {title.nopimsTitleType === NopimsTitleTypeEnum.Nopta && title.titleNumber && (
                                                    <ExternalLinkView href={shellRouteHelper.publicPortalTitleDetails(title.titleNumber)}>{title.titleNumber}</ExternalLinkView>
                                                )}
                                                {title.nopimsTitleType === NopimsTitleTypeEnum.Other && <span>{title.otherTitleNumber}</span>}
                                                {index < titleConditions.length - 1 && <span>{", "}</span>}
                                            </span>
                                        ))}
                                    </>
                                ) : (
                                    <Forms.ReadonlyTextInput id="titleConditionField" />
                                )}
                            </div>
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.Label htmlFor="authorisingTitlesField">Authorising Titles</Forms.Label>
                            <div>
                                {acquisition.authorisingTitles && acquisition.authorisingTitles.length > 0 ? (
                                    <>
                                        {acquisition.authorisingTitles.map((title, index) => (
                                            <span key={index}>
                                                {title.nopimsTitleType === NopimsTitleTypeEnum.Nopta && title.titleNumber && (
                                                    <ExternalLinkView href={shellRouteHelper.publicPortalTitleDetails(title.titleNumber)}>{title.titleNumber}</ExternalLinkView>
                                                )}
                                                {title.nopimsTitleType === NopimsTitleTypeEnum.Other && <span>{title.otherTitleNumber}</span>}
                                                {index < acquisition.authorisingTitles.length - 1 && <span>{", "}</span>}
                                            </span>
                                        ))}
                                    </>
                                ) : (
                                    <Forms.ReadonlyTextInput id="authorisingTitlesField" />
                                )}
                            </div>
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.Label htmlFor="titleCoverageField">Title Coverage</Forms.Label>
                            <div>
                                {acquisition.titleCoverage && acquisition.titleCoverage.length > 0 ? (
                                    <>
                                        {acquisition.titleCoverage.map((title, index) => (
                                            <span key={index}>
                                                {title.nopimsTitleType === NopimsTitleTypeEnum.Nopta && title.titleNumber && (
                                                    <ExternalLinkView href={shellRouteHelper.publicPortalTitleDetails(title.titleNumber)}>{title.titleNumber}</ExternalLinkView>
                                                )}
                                                {title.nopimsTitleType === NopimsTitleTypeEnum.Other && <span>{title.otherTitleNumber}</span>}
                                                {index < acquisition.titleCoverage.length - 1 && <span>{", "}</span>}
                                            </span>
                                        ))}
                                    </>
                                ) : (
                                    <Forms.ReadonlyTextInput id="titleCoverageField" />
                                )}
                            </div>
                        </Forms.QuarterColumn>
                    </Forms.Row>
                </Forms.Group>
                <Forms.Group>
                    <Forms.Row>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="contractorField" label="Contractor" value={acquisition.contractor} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="vesselField" label="Vessel" value={acquisition.vessel} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="operatorField" label="Operator" value={surveyOperator} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="linePrefixField" label="Line Prefix" value={acquisition.linePrefix} />
                        </Forms.QuarterColumn>
                    </Forms.Row>
                </Forms.Group>
                <Forms.Group>
                    <Forms.Row>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="startDateField" label="Start Date" value={GlobalHelpers.toNoptaDateString(acquisition.startDate)} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="endDateField" label="End Date" value={GlobalHelpers.toNoptaDateString(acquisition.endDate)} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="basicRelevantDateField" label="Basic Relevant Date" value={GlobalHelpers.toNoptaDateString(acquisition.basicRelevantDate)} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField
                                id="interpretativeRelevantDateField"
                                label="Interpretative Relevant Date"
                                value={GlobalHelpers.toNoptaDateString(acquisition.interpretativeRelevantDate)}
                            />
                        </Forms.QuarterColumn>
                        <Forms.FullColumn>
                            <Forms.ReadonlyTextArea id="commentsField" label="Comments" value={acquisition.comments} />
                        </Forms.FullColumn>
                        <Forms.FullColumn>
                            <Forms.ReadonlyTextArea id="publicCommentsField" label="Public Comments" value={acquisition.publicComments} />
                        </Forms.FullColumn>
                    </Forms.Row>
                </Forms.Group>
            </div>
        </div>
    );
}
