// framework
import { Switch } from "react-router-dom";
// shell
import LayoutController from "./shell/layout/components/LayoutController";
// auth
import msalAuthService from "./infrastructure/msalAuthService";
// route builders
import { buildRoutes } from "./infrastructure/routeBuilder";
import * as shellHelper from "./common/shell/shellHelper";

export default function App() {
    // login redirect
    // - during sign-in, the application is actually loaded inside of the sign-in popup
    //   because the application start-up is quite busy, this can sometimes cause issues with the msal library, where the pop-up does not close
    //   in these situations we cannot have the application initialise
    const isLoginRedirect = msalAuthService.isLoginRedirect();
    if (isLoginRedirect) return <></>;

    // build routes
    const isSignedIn = shellHelper.useIsSignedIn();
    const routes = buildRoutes(isSignedIn);

    // note! the Route elements must sit directly within the Switch element, or else the routing tables won't work
    return (
        <LayoutController>
            <Switch>{routes}</Switch>
        </LayoutController>
    );
}
