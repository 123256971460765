// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../api/Client";

export interface IRootViewModel {
    rigHistory: Client.GetGeoscienceAustraliaWellsBoreholesRigHistoryListResponseDto | undefined;

    refreshRigHistoryList(response: Client.GetGeoscienceAustraliaWellsBoreholesRigHistoryListResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.rigHistory = undefined;
    }

    public rigHistory: Client.GetGeoscienceAustraliaWellsBoreholesRigHistoryListResponseDto | undefined;

    public refreshRigHistoryList(response: Client.GetGeoscienceAustraliaWellsBoreholesRigHistoryListResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.rigHistory = response;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.rigHistory = clone(this.rigHistory);
        return vm;
    }
}
