// redux
import { call, put, select } from "redux-saga/effects";
import { ICompanyReduxState } from "../../../../companyReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/rootViewModel";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

const getRootViewModel = (state: ICompanyReduxState) => state.Company_UserManagement_UserDetails;

export default function* removeUser() {
    let vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const result: CallHelper.CallResult<Client.RemoveCompanyUserManagementUserDetailsUserResponseDto> = yield call(async () => await removeCompanyUser(vm));

        if (result.IsSuccess) {
            // success
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshRemoveUserResponse(result.Response!)));
        } else if (result.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            result.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function removeCompanyUser(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.RemoveCompanyUserManagementUserDetailsUserResponseDto>> {
    const request = new Client.RemoveCompanyUserManagementUserDetailsUserRequestDto({
        secureUserId: vm.id!,
        secureUserVersionNumber: vm.versionNumber!,
    });

    const client = await ClientFactory.createCompanyUserManagementClient();
    return await CallHelper.call(() => client.removeCompanyUserManagementUserDetailsUser(request));
}
