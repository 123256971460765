// redux
import * as Validation from "../../../../common/validation/ValidationModel";
import * as Model from "./rootViewModel";

export default function validate(vm: Model.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);

    if (vm.isJointAuthorityStandardRoleRequest) validateSecureRequestJointAuthorityStandardRoleDecision(vm.jointAuthorityStandardRoleDetails, v);
    if (vm.isJointAuthoritySignerRequest) validateSecureRequestJointAuthoritySignerDecision(vm.jointAuthoritySignerDetails, v);
    validateRejectionDetails(vm.rejectionDetails, v);

    return v;
}

function validateSecureRequestJointAuthorityStandardRoleDecision(vm: Model.ISecureRequestJointAuthorityStandardRoleDetailsViewModel, v: Validation.ValidationModel): void {
    if (vm.hasAllGeneralRoles && vm.isApproved !== false) {
        v.addError("JointAuthorityStandardRoleDetails.IsApproved", "The user has all available permissions already. Please reject the request.");
    } else if (vm.isApproved === true) {
        if (!vm.grantedRoles || !vm.grantedRoles.hasRole()) {
            v.addError("JointAuthorityStandardRoleDetails.IsApproved", "The user must have at least one role assigned in order to be approved.");
        } else if (vm.grantedRoles.getDirectRoles().some((role) => vm.existingRoles!.hasRole(role))) {
            v.addError("JointAuthorityStandardRoleDetails.IsApproved", "The user cannot be granted an existing permission.");
        }
    } else if (vm.isApproved === undefined) {
        v.addError("JointAuthorityStandardRoleDetails.IsApproved", "Approve or Reject must be selected.");
    }
}

function validateSecureRequestJointAuthoritySignerDecision(vm: Model.ISecureRequestJointAuthoritySignerDetailsViewModel, v: Validation.ValidationModel): void {
    // confirmation not required to reject request
    if (vm.isApproved === false) return;

    // identification and verification section
    if (!vm.isIdentificationAndVerificationConfirmed) v.addError("JointAuthoritySignerDetails.IsIdentificationChecklistComplete", "Confirmation required.");

    // acknowledgement and signatures section
    if (!vm.isRoleConfirmed || !vm.isAuthorityConfirmed || !vm.isElectronicSignatureConfirmed)
        v.addError("JointAuthoritySignerDetails.IsAcknowledgementChecklistComplete", "Confirmation required. You must first download and read the document.");

    // power of attorney section
    if (vm.isJointAuthoritySignerPoaRequest && !vm.isPoaValidityConfirmed)
        v.addError("JointAuthoritySignerDetails.IsPowerOfAttorneyChecklistComplete", "Confirmation required. You must first download and read the document.");

    // approval section
    if (vm.isApproved === undefined) v.addError("JointAuthoritySignerDetails.IsApproved", "Approve or Reject must be selected.");
}

function validateRejectionDetails(vm: Model.ISecureRequestRejectionsDetailsViewModel, v: Validation.ValidationModel): void {
    if (vm.comments && vm.comments.length > 1000) v.addError("RejectionDetails.Comments", "Reason must be 1000 characters or less.");
}
