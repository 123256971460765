import EmailLinkView from "../../../../../../common/externalLink/EmailLinkView";

export default function ContactDetailsView(props: { phone: string | undefined; email: string | undefined }): React.ReactElement {
    return (
        <div className="col-md-6 mt-2">
            <span className="fw-bold">
                Contact Details
                <br />
            </span>
            <span className="fw-bold">Phone: </span>
            <span>
                {props.phone}

                <br />
            </span>
            <span className="fw-bold">Email: </span>
            <span>
                <EmailLinkView emailAddress={props.email} />
                <br />
            </span>
        </div>
    );
}
