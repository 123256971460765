import { useSelector } from "react-redux";
import * as Client from "../../api/Client";
import { IRootReduxState } from "../../infrastructure/reduxRootReducer";

// provide a mixture of Hooks (useXXX) and Initialisers (getXXXConfiguration)
// hooks for react components, initialisers for sagas

// this configuration helper needs access to the feature switches from the store in a non-component context, eg. building the lhs menu with feature switches
// this approach is based on: https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
let _featureSwitches: Client.NeatsFeatureSwitchEnum[];

export function injectFeatureSwitches(featureSwitches: Client.NeatsFeatureSwitchEnum[]) {
    _featureSwitches = featureSwitches;
}

export function getPortalLinksConfiguration(state: IRootReduxState): Client.GetShellPortalLinksResponseDto {
    return state.Shell_Configuration.portalLinks!;
}

export function usePortalLink(name: Client.ExternalLinkEnum): string | undefined {
    const configuration = useSelector((state: IRootReduxState) => state.Shell_Configuration);
    const portalLinks = configuration?.portalLinks?.links;

    return portalLinks ? portalLinks.filter((l) => l.name === name)[0].url : undefined;
}

export function useFeatureSwitch(featureSwitch: Client.NeatsFeatureSwitchEnum): boolean | undefined {
    const configuration = useSelector((state: IRootReduxState) => state.Shell_Configuration);
    const featureSwitches = configuration?.featureSwitches?.featureSwitches;

    // enabled features will come back, so if it's in here, it's enabled
    return featureSwitches ? featureSwitches.some((f) => f === featureSwitch) : undefined;
}

export function getFeatureSwitch(featureSwitch: Client.NeatsFeatureSwitchEnum): boolean | undefined {
    if (!_featureSwitches) throw Error("Feature Switches have not been injected. Use injectFeatureSwitches.");
    return _featureSwitches.includes(featureSwitch);
}

export function getBamboraConfiguration(state: IRootReduxState): Client.GetShellBamboraConfigurationResponseDto {
    return state.Shell_Configuration.bambora!;
}

export function useBamboraMerchantId(): string | undefined {
    const configuration = useSelector((state: IRootReduxState) => state.Shell_Configuration);
    return configuration?.bambora?.merchantId;
}

export function getTitleBadgesConfiguration(state: IRootReduxState): Client.GetShellTitleBadgesResponseDto {
    return state.Shell_Configuration.titleBadges!;
}

export function useTitleBadges(titleId: string): Client.GetShellTitleBadgesTitleDto | undefined {
    const configuration = useSelector((state: IRootReduxState) => state.Shell_Configuration);
    const titleBadges = configuration?.titleBadges?.titles;

    return titleBadges ? titleBadges.filter((t) => t.titleId === titleId)[0] : undefined;
}

export const getRoleConfiguration = (state: IRootReduxState) => state.Shell_Configuration.roleConfiguration;
