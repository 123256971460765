// bambora
import * as CustomCheckoutAdaptor from "./customCheckoutAdaptor";
// common
import SimpleAlertView from "../simpleAlerts/SimpleAlertView";
import * as Forms from "../forms/BootstrapForms";

interface ICustomCheckoutViewProps {
    state: CustomCheckoutAdaptor.ICustomCheckoutState;
    message: string | undefined;
    imageUri: string;
    onVerify: () => void;
}

export default function CustomCheckoutView(props: ICustomCheckoutViewProps): React.ReactElement {
    const state = props.state;

    function renderError(message: string | undefined): React.ReactElement {
        if (!message) return <></>;
        return <SimpleAlertView alertType="error" message={message} dismissable={false} />;
    }

    function renderInfoMessage(message: string | undefined): React.ReactElement {
        if (!message) return <></>;
        return <SimpleAlertView alertType="information" message={message} dismissable={false} />;
    }

    const cardNumberStyle: React.CSSProperties = {
        backgroundImage: props.state.cardBrand ? "url(" + props.imageUri + props.state.cardBrand + ".svg)" : "none",
        backgroundOrigin: "content-box",
        backgroundPosition: "calc(100%) center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
    };

    return (
        <div className="mb-2">
            <Forms.Group>
                {state.isComplete && <SimpleAlertView alertType="success" message="The credit card has been successfully verified." dismissable={false} />}
                {!state.isComplete && <SimpleAlertView alertType="error" message="Please enter and verify the credit card details." dismissable={false} />}
                {renderInfoMessage(props.message)}
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor={CustomCheckoutAdaptor.CardNumberElementId}>Card Number</Forms.Label>
                <div id={CustomCheckoutAdaptor.CardNumberElementId} className={Forms.ControlClass} style={cardNumberStyle}></div>
                {renderError(state.cardNumberMessage)}
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor={CustomCheckoutAdaptor.ExpiryElementId}>Expiry</Forms.Label>
                <div id={CustomCheckoutAdaptor.ExpiryElementId} className={Forms.ControlClass}></div>
                {renderError(state.cardExpiryMessage)}
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor={CustomCheckoutAdaptor.CVVElementId}>CVV</Forms.Label>
                <div id={CustomCheckoutAdaptor.CVVElementId} className={Forms.ControlClass}></div>
                {renderError(state.cardCvvMessage)}
            </Forms.Group>
            <button
                className="btn btn-outline-primary mb-1"
                onClick={(e) => {
                    e.preventDefault();
                    props.onVerify();
                }}
                disabled={!state.isValid}
            >
                Verify Details
            </button>
        </div>
    );
}
