// redux
import { IAddressDetailsViewModel } from "./addressDetailsViewModel";
// api
import * as Client from "../../../../api/Client";
// common
import * as Forms from "../../../../common/forms/BootstrapForms";

export default function AddressDetailsReadonlyView(props: { vm: IAddressDetailsViewModel }) {
    const vm = props.vm;

    return (
        <div className="card mb-2">
            <div className="card-body">
                <h3 className="card-title">Head office, registered office or a principal office address (if body corporate)</h3>
                <Forms.Row>
                    <Forms.FullColumn>
                        <Forms.ReadonlyRadioButton
                            name="addressTypeGroup"
                            id="isBusinessAddressRadioField"
                            label="Business Address"
                            value={Client.LegislativeFormCompanyAddressTypeEnum.Business}
                            checked={vm.addressType === Client.LegislativeFormCompanyAddressTypeEnum.Business}
                            inline
                        />
                        <Forms.ReadonlyRadioButton
                            name="addressTypeGroup"
                            id="isPostalAddressRadioField"
                            label="Street Address"
                            value={Client.LegislativeFormCompanyAddressTypeEnum.Postal}
                            checked={vm.addressType === Client.LegislativeFormCompanyAddressTypeEnum.Postal}
                            inline
                        />
                    </Forms.FullColumn>
                    <Forms.FullColumn>
                        <Forms.ReadonlyTextArea label="Street Address" id="streetAddressField" value={vm.streetAddress} />
                    </Forms.FullColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField label="Town/Suburb" id="suburbField" value={vm.suburb} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField label="Postcode" id="postcodeField" value={vm.postcode} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField label="State/Territory" id="stateField" value={vm.state?.countrySubdivisionName} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField label="Country" id="countryField" value={vm.country?.countryName} />
                    </Forms.HalfColumn>
                </Forms.Row>
            </div>
        </div>
    );
}
