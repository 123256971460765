// redux
import { call, put, select } from "redux-saga/effects";
import { IGeoscienceAustraliaReduxState } from "../../../../geoscienceAustraliaReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_Account_RequestAccess;

export default function* createSecureRequest() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const actions = Actions.actionFactory;

    try {
        yield put(shellHelper.createSetBusyAction());

        let response: IResponse;
        const questions = vm.user.questions;

        if (questions.isGeoscienceAustraliaAdministratorRequest) {
            response = yield call(async () => await createGaaSecureRequest(vm));
        } else if (questions.isGeoscienceAustraliaStandardRoleRequest) {
            response = yield call(async () => await createGarSecureRequest(vm));
        } else throw new Error("Invalid access request.");

        yield put(actions.sagaCompleted(vm.refreshSecureRequestCreateResponse(response.statusMessages, response.secureRequestGroupId)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

interface IResponse {
    statusMessages: Client.StatusMessagesDto;
    secureRequestGroupId?: Client.SecureRequestGroupIdDto | undefined;
}

async function createGaaSecureRequest(vm: Models.IRootViewModel): Promise<IResponse> {
    const request = new Client.CreateGeoscienceAustraliaAccountRequestAccessGaaRequestDto({
        submittedComments: vm.user.comments,
        gaaAuthoritySupportingDocument: getSupportingDocumentDto(vm.user.gaaAuthoritySupportingDocument),
    });

    const client = await ClientFactory.createGeoscienceAustraliaAccountClient();
    return await CallHelper.simpleCall(() => client.createGeoscienceAustraliaAccountRequestAccessGaa(request));
}

async function createGarSecureRequest(vm: Models.IRootViewModel): Promise<IResponse> {
    const request = new Client.CreateGeoscienceAustraliaAccountRequestAccessGarRequestDto({
        submittedComments: vm.user.comments,
        geoscienceAustraliaStandardRolesRequest: getGeoscienceAustraliaStandardRolesRequestDto(vm.user.geoscienceAustraliaStandardRoleRequest),
    });

    const client = await ClientFactory.createGeoscienceAustraliaAccountClient();
    return await CallHelper.simpleCall(() => client.createGeoscienceAustraliaAccountRequestAccessGar(request));
}

// private
function getGeoscienceAustraliaStandardRolesRequestDto(vm: Models.IGeoscienceAustraliaStandardRoleRequestViewModel): Client.GeoscienceAustraliaStandardRoleRequestDetailsDto {
    return new Client.GeoscienceAustraliaStandardRoleRequestDetailsDto({
        rolesRequested: vm.requestedRoles!.getDirectRoles(),
        rolesApproved: undefined,
    });
}

function getSupportingDocumentDto(document: Models.ISupportingDocumentViewModel): Client.SecureRequestGroupSupportingDocumentDto {
    return new Client.SecureRequestGroupSupportingDocumentDto({ fileName: document.fileName, content: document.content });
}
