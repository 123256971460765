import * as Models from "../models/models";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "geoscienceAustralia.wellsBoreholes.boreholeList.initialiseRequested",
    clearRequested = "geoscienceAustralia.wellsBoreholes.boreholeList.clearRequested",
    // user input
    viewChanged = "geoscienceAustralia.wellsBoreholes.boreholeList.viewChanged",
    // saga events
    sagaCompleted = "geoscienceAustralia.wellsBoreholes.boreholeList.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(): IAction<undefined>;
    clearRequested(): IAction<undefined>;
    // user input
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    // saga events
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (): IAction<undefined> {
        return {
            type: actions.initialiseRequested,
            payload: undefined,
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
