// redux
import * as Models from "../../models/models";
import * as Actions from "../../redux/actions";
// common
import SecureFileUploadControl, { IFile } from "../../../../../common/secureFileUpload/SecureFileUploadControl";

// NOTE this is a candidate for re-use across controls and is the same in both current documentation implementations, leaving as is for now until everything settles down
export default function UploadFileView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; onBusyChanged: (isBusy: boolean) => void }): React.ReactElement {
    const vm = props.vm;
    const actions = props.actions;

    function onFilesUploaded(files: Array<IFile>): void {
        // the files list will be updated in a single batch
        // this helps avoid the grid growing whilst the upload list is shrinking ... it's very odd to watch
        actions.viewChanged(vm.onUpload(files));
    }

    if (!vm.configuration) return <></>;

    return (
        <>
            <h5>File Upload</h5>
            <SecureFileUploadControl
                className="mb-2"
                container={vm.configuration.container}
                allowedExtensions={vm.configuration.allowedExtensions}
                onBusyChanged={props.onBusyChanged}
                onFilesUploaded={onFilesUploaded}
                maximumFileSizeMb={vm.configuration.maximumFileSizeMb}
            ></SecureFileUploadControl>
        </>
    );
}
