// framework
import { useEffect, useState } from "react";
// redux
import * as Models from "../../models/models";
// api
import * as Client from "../../../../../..../../../../../api/Client";
// common
import { ValidationModel } from "../../../../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../../../../common/validation/ValidationMessageView";
import StatusMessagesAlertsView from "../../../../../../../../common/alerts/StatusMessagesAlertsView";
import SimpleAlertView from "../../../../../../../../common/simpleAlerts/SimpleAlertView";
import * as Forms from "../../../../../../../../common/forms/BootstrapForms";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";

type Props = {
    id: number;
    vm: Models.IRootViewModel;
    v: ValidationModel;
    updateViewModel: (signatureType: Client.GetCompanyOpggsLegislativeFormsFormRequestSignatureTypeItemDto) => void;
};

export default function SignatureTypeView(props: Props) {
    const { vm, v } = props;

    const [selectedSignatureType, setSelectedSignatureType] = useState<Client.GetCompanyOpggsLegislativeFormsFormRequestSignatureTypeItemDto | null | undefined>(vm.signatureType);

    useEffect(() => {
        setSelectedSignatureType(vm.signatureType);
    }, [vm]);

    function onChange(e: DropDownListChangeEvent) {
        const selectedSignatureType = e.target.value as Client.GetCompanyOpggsLegislativeFormsFormRequestSignatureTypeItemDto;
        setSelectedSignatureType(selectedSignatureType);
        props.updateViewModel(selectedSignatureType);
    }

    const poaMessage = "If you are signing using a Power of Attorney, please ensure that a copy of the Power of Attorney has been provided to NOPTA.";

    function showPoaMessage() {
        // show the poa message when digital signatures is selected, but only in the 'Preparing' state
        // note that it displays irrespective of edit/view mode. it shows at all times in preparing mode when dig sigs is selected
        return selectedSignatureType?.signatureType === Client.LegislativeFormRequestFormDocumentationSignatureTypeEnum.Digital && vm.isDraft;
    }

    function getReadonlyView() {
        return (
            <>
                <Forms.Group>
                    <Forms.Label htmlFor="signingMethodField">Signing Method</Forms.Label>
                    <Forms.ReadonlyTextInput id="signingMethodField" value={vm.signatureType?.description} />
                </Forms.Group>
            </>
        );
    }

    function getEditView() {
        return (
            <>
                <StatusMessagesAlertsView statusMessages={vm.getStatusMessages} scrollWindowToTop={false} />
                <Forms.Group>
                    <Forms.Label htmlFor="signingMethodField">Signing Method</Forms.Label>
                    <DropDownList
                        id="signingMethodField"
                        valid={v.applyValidityForKendo("SignatureType.SignatureType")}
                        data={vm.availableSignatureTypes}
                        value={selectedSignatureType}
                        textField="description"
                        onChange={onChange}
                    />
                    <ValidationMessageView name="SignatureType.SignatureType" validationModel={v} />
                </Forms.Group>
            </>
        );
    }

    return (
        <>
            {showPoaMessage() && <SimpleAlertView alertType="information" message={poaMessage} dismissable={false} />}
            {vm.viewState === Models.ViewStateEnum.Edit ? getEditView() : getReadonlyView()}
            <p>Digital Signature option is not available to foreign registered companies.</p>
        </>
    );
}
