// framework
import { clone, cloneDeep } from "lodash";
// kendo
import { orderBy } from "@progress/kendo-data-query";
// client
import * as Client from "../../../api/Client";
// redux
import { IRegistrationRoleViewModel, RegistrationRoleViewModel } from "./registrationRoleViewModel";
// common
import { RoleCategoryEnum } from "../../../common/roleGrid/RoleViewModel";
import { BaseCompanyRoleRootViewModel, ICompanyRoleRootViewModel } from "../../common/CompanyRoleRootViewModel";

export interface IQuestionsViewModel {
    selectedCompany?: Client.GetRegistrationCompanyCompanyDto;
    isNewCompanyRequest?: boolean;
    isCompanyAdministratorRequest?: boolean;
    isCompanySignerRequest?: boolean;
    isCompanySignerPoaRequest?: boolean;
}

export interface INewUserRequestViewModel {
    email?: string;
    emailVerification: IEmailVerificationViewModel;
    firstName?: string;
    lastName?: string;
    contactNumber?: string;
    position?: string;
    password?: string;
    passwordConfirmation?: string;
}

export interface IEmailVerificationViewModel {
    isVisible: boolean;
    token?: string;
    verificationCode?: string;
    status: Client.EmailVerificationStatusEnum;
    requestEmailVerificationStatusMessages?: Client.StatusMessagesDto;
    verifyEmailVerificationStatusMessages?: Client.StatusMessagesDto;
}

export interface INewCompanyRequestViewModel {
    companyName?: string;
    companyAbn?: string;
    companyAcnOrArbn?: string;
    businessAddress?: string;
    businessSuburb?: string;
    businessPostcode?: string;
    businessState?: Client.SecurePortalCountrySubdivisionDto;
    businessCountry?: Client.SecurePortalCountryDto;
    postalAddress?: string;
    postalSuburb?: string;
    postalPostcode?: string;
    postalState?: Client.SecurePortalCountrySubdivisionDto;
    postalCountry?: Client.SecurePortalCountryDto;
    isPostalAddressSameAsBusinessAddress?: boolean;
}

export interface ISupportingDocumentViewModel {
    fileName?: string;
    content?: string;
}

export interface ICompanyStandardRoleRequestViewModel {
    requestedRoles?: IRegistrationRoleViewModel;
}

export interface IViewStateViewModel {
    showExistingCompanyNewAdministratorWizard: boolean;
    showExistingCompanyExistingAdministratorWizard: boolean;
    showNewCompanyWizard: boolean;
    showCompanySignerWizard: boolean;
    showRequestForm: boolean;
}

export interface IRootViewModel extends ICompanyRoleRootViewModel {
    reference: {
        companies: Array<Client.GetRegistrationCompanyCompanyDto>;
        countries: Array<Client.SecurePortalCountryDto>;
    };

    user: {
        questions: IQuestionsViewModel;
        newUserRequest: INewUserRequestViewModel;
        newCompanyRequest: INewCompanyRequestViewModel;
        companyStandardRoleRequest: ICompanyStandardRoleRequestViewModel;
        caAuthoritySupportingDocument: ISupportingDocumentViewModel;
        csAuthoritySupportingDocument: ISupportingDocumentViewModel;
        csPoaSupportingDocument: ISupportingDocumentViewModel;
        isUserAgreementAcknowledged: boolean;
        comments: string;
    };

    service: {
        referenceNumber?: string;
        statusMessages?: Client.StatusMessagesDto;
    };

    isDirty: boolean;

    viewState: IViewStateViewModel;

    // saga triggered state changes
    refreshReferenceData(companies: Array<Client.GetRegistrationCompanyCompanyDto>, countries: Array<Client.SecurePortalCountryDto>): IRootViewModel;
    refreshSecureRequestCreateResponse(statusMessages: Client.StatusMessagesDto, referenceNumber: string | undefined): IRootViewModel;

    // view triggered state changes
    refreshSelectedCompanyChanged(company?: Client.GetRegistrationCompanyCompanyDto): IRootViewModel;
    refreshQuestionsUpdated(values: IQuestionsViewModel): IRootViewModel;
    refreshNewUserRequestUpdated(values: INewUserRequestViewModel): IRootViewModel;
    refreshEmailVerificationUpdated(values: IEmailVerificationViewModel): IRootViewModel;
    refreshNewCompanyRequestUpdated(values: INewCompanyRequestViewModel): IRootViewModel;
    refreshCompanyStandardRoleRequestUpdated(values: IRegistrationRoleViewModel): IRootViewModel;
    refreshCaAuthoritySupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel;
    refreshCsAuthoritySupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel;
    refreshCsPoaSupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel;
    refreshUserAgreementUpdated(isUserAgreementAcknowledged: boolean): IRootViewModel;
    refreshCommentsUpdated(comments: string): IRootViewModel;
}

export class RootViewModel extends BaseCompanyRoleRootViewModel implements IRootViewModel {
    constructor() {
        super();

        // initial state
        this.reference = {
            companies: new Array<Client.GetRegistrationCompanyCompanyDto>(),
            countries: new Array<Client.SecurePortalCountryDto>(),
        };
        this.user = {
            questions: {
                selectedCompany: undefined,
                isNewCompanyRequest: undefined,
                isCompanyAdministratorRequest: undefined,
                isCompanySignerRequest: undefined,
                isCompanySignerPoaRequest: undefined,
            },
            newUserRequest: { emailVerification: { isVisible: false, status: Client.EmailVerificationStatusEnum.Unverified } },
            newCompanyRequest: {},
            companyStandardRoleRequest: {},
            caAuthoritySupportingDocument: {},
            csAuthoritySupportingDocument: {},
            csPoaSupportingDocument: {},
            comments: "",
            isUserAgreementAcknowledged: false,
        };

        this.service = {
            referenceNumber: undefined,
            statusMessages: undefined,
        };

        this.isDirty = false;
        this.viewState = {
            showExistingCompanyNewAdministratorWizard: false,
            showExistingCompanyExistingAdministratorWizard: false,
            showNewCompanyWizard: false,
            showCompanySignerWizard: false,
            showRequestForm: false,
        };
    }

    reference: {
        companies: Array<Client.GetRegistrationCompanyCompanyDto>;
        countries: Array<Client.SecurePortalCountryDto>;
    };

    user: {
        questions: IQuestionsViewModel;
        newUserRequest: INewUserRequestViewModel;
        newCompanyRequest: INewCompanyRequestViewModel;
        companyStandardRoleRequest: ICompanyStandardRoleRequestViewModel;
        caAuthoritySupportingDocument: ISupportingDocumentViewModel;
        csAuthoritySupportingDocument: ISupportingDocumentViewModel;
        csPoaSupportingDocument: ISupportingDocumentViewModel;
        comments: string;
        isUserAgreementAcknowledged: boolean;
    };

    service: {
        referenceNumber?: string;
        statusMessages?: Client.StatusMessagesDto;
    };

    isDirty: boolean;
    viewState: IViewStateViewModel;

    // saga triggered state changes
    refreshReferenceData(companies: Array<Client.GetRegistrationCompanyCompanyDto>, countries: Array<Client.SecurePortalCountryDto>): IRootViewModel {
        const vm = this._clone();
        vm.reference.companies = orderBy(companies, [{ field: "companyName", dir: "asc" }]);
        vm.reference.countries = countries;
        // set defaults
        vm.user.newCompanyRequest.businessCountry = countries.find((c) => c.countryName === "Australia");
        vm.user.companyStandardRoleRequest.requestedRoles = new RegistrationRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Requested);

        vm.isDirty = false; // reference data load is part of the initialisation phase
        return vm;
    }

    refreshSecureRequestCreateResponse(statusMessages: Client.StatusMessagesDto, referenceNumber: string | undefined): IRootViewModel {
        const vm = this._clone();
        vm.service.referenceNumber = referenceNumber;
        vm.service.statusMessages = statusMessages;

        // blow away the email verification status if the service fails
        // otherwise, they're blocked from trying to verify again
        // which is awkward, given it can fail because the token has expired
        if (!statusMessages.isSuccess) vm.user.newUserRequest.emailVerification.status = Client.EmailVerificationStatusEnum.Unverified;

        vm.isDirty = !statusMessages!.isSuccess; // on success, set the vm as clean
        return vm;
    }

    // user triggered state changes
    refreshSelectedCompanyChanged(selectedCompany?: Client.GetRegistrationCompanyCompanyDto): IRootViewModel {
        const vm = this._clone();
        vm.user.questions.selectedCompany = selectedCompany;

        if (selectedCompany) {
            vm.user.questions.isNewCompanyRequest = false;

            if (!selectedCompany.hasCompanyAdministrator) {
                // if company doesn't have an administrator, user must request admin and therefore cannot request general roles or signer
                vm.user.questions.isCompanyAdministratorRequest = true;
                vm.user.questions.isCompanySignerRequest = false;
                vm.user.questions.isCompanySignerPoaRequest = false;
            } else {
                vm.user.questions.isCompanyAdministratorRequest = undefined;
                vm.user.questions.isCompanySignerRequest = undefined;
                vm.user.questions.isCompanySignerPoaRequest = undefined;
            }
        }

        updateFlags(vm);
        vm.isDirty = true;
        return vm;
    }

    refreshQuestionsUpdated(values: IQuestionsViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.questions = { ...vm.user.questions, ...values };

        if (values.isNewCompanyRequest) {
            vm.user.questions.selectedCompany = undefined;
            vm.user.questions.isCompanyAdministratorRequest = true;
            vm.user.questions.isCompanySignerRequest = false;
            vm.user.questions.isCompanySignerPoaRequest = false;
        } else if (values.isCompanyAdministratorRequest === true) {
            vm.user.questions.isCompanySignerRequest = false;
            vm.user.questions.isCompanySignerPoaRequest = false;
        } else if (values.isCompanyAdministratorRequest === false) {
            // Clear Signer and POA selection when CA selection is changed
            vm.user.questions.isCompanySignerRequest = undefined;
            vm.user.questions.isCompanySignerPoaRequest = undefined;
        } else if (values.isCompanySignerRequest === false) {
            // Clear POA selection when CS selection is changed
            vm.user.questions.isCompanySignerPoaRequest = undefined;
        }

        updateFlags(vm);
        vm.isDirty = true;
        return vm;
    }

    refreshNewUserRequestUpdated(values: INewUserRequestViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.newUserRequest = values;
        vm.isDirty = true;
        return vm;
    }

    refreshEmailVerificationUpdated(values: IEmailVerificationViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.newUserRequest.emailVerification = { ...values };
        vm.isDirty = true;
        return vm;
    }

    refreshNewCompanyRequestUpdated(values: INewCompanyRequestViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.newCompanyRequest = values;
        vm.isDirty = true;
        return vm;
    }

    refreshCompanyStandardRoleRequestUpdated(values: IRegistrationRoleViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.companyStandardRoleRequest.requestedRoles = values;
        vm.isDirty = true;
        return vm;
    }

    refreshCaAuthoritySupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.caAuthoritySupportingDocument = value;
        vm.isDirty = true;
        return vm;
    }

    refreshCsAuthoritySupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.csAuthoritySupportingDocument = value;
        vm.isDirty = true;
        return vm;
    }

    refreshCsPoaSupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.csPoaSupportingDocument = value;
        vm.isDirty = true;
        return vm;
    }

    refreshUserAgreementUpdated(isUserAgreementAcknowledged: boolean): IRootViewModel {
        const vm = this._clone();
        vm.user.isUserAgreementAcknowledged = isUserAgreementAcknowledged;
        vm.isDirty = true;
        return vm;
    }

    refreshCommentsUpdated(comments: string): IRootViewModel {
        const vm = this._clone();
        vm.user.comments = comments;
        vm.isDirty = true;
        return vm;
    }

    // private implementation
    // ----------------------
    _clone(): RootViewModel {
        const vm = clone(this);

        vm.reference = clone(this.reference);
        vm.user = cloneDeep(this.user);
        vm.service = clone(this.service);
        vm.viewState = clone(this.viewState);

        return vm;
    }
}

// private
function updateFlags(vm: IRootViewModel): void {
    const questions = vm.user.questions;
    vm.viewState = {
        showExistingCompanyNewAdministratorWizard: false,
        showExistingCompanyExistingAdministratorWizard: false,
        showNewCompanyWizard: false,
        showCompanySignerWizard: false,
        showRequestForm: false,
    };

    // existing company selected
    if (questions.selectedCompany) {
        if (questions.selectedCompany.hasCompanyAdministrator) {
            vm.viewState.showExistingCompanyExistingAdministratorWizard = true;
        } else {
            vm.viewState.showExistingCompanyNewAdministratorWizard = true;
        }
    }
    // new company
    else if (questions.isNewCompanyRequest) {
        vm.viewState.showNewCompanyWizard = true;
    } else return;

    // break if no CA selection has been made
    if (questions.isCompanyAdministratorRequest === undefined) return;
    // CS selection is only applicable if not CA request
    if (questions.isCompanyAdministratorRequest === false) {
        vm.viewState.showCompanySignerWizard = true;
    }
    // break if no CS selection has been made
    if (questions.isCompanySignerRequest === undefined) return;

    // reveal form
    vm.viewState.showRequestForm = true;
}
