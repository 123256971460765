import { takeEvery } from "redux-saga/effects";
import * as Actions from "../redux/actions";

// sagas
import { refreshStaticConfiguration } from "./sagas/refreshStaticConfiguration";
import { refreshDynamicConfiguration } from "./sagas/refreshDynamicConfiguration";

export default function* watcherSaga() {
    yield takeEvery(Actions.actions.refreshStaticConfigurationRequested, refreshStaticConfiguration);
    yield takeEvery(Actions.actions.refreshDynamicConfigurationRequested, refreshDynamicConfiguration);
}
