// framework
import { Link } from "react-router-dom";
// common
import * as CompanyRouteHelper from "../../../../companyRouteHelper";

export default function SubmittedView() {
    const applicationsListUrl = CompanyRouteHelper.opggsApplicationList();

    return (
        <p>
            Your Application has been submitted. This will need to be processed by NOPTA and once lodged will be available in the system. Click <Link to={applicationsListUrl}>here</Link> to view the
            In Progress OPGGS Act Applications list.
        </p>
    );
}
