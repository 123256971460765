// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../../api/Client";

export interface IApplicationContactViewModel {
    primaryContactName?: string | undefined;
    positionHeld?: string | undefined;
    phone?: string | undefined;
    email?: string | undefined;
    fax?: string | undefined;
    businessAddress?: string | undefined;
    isOverlappingContactSharingConsent?: boolean | undefined;
    overlappingContactType?: Client.GetCompanyOeiDraftApplicationDetailsContactOverlappingContactTypeDto | undefined;
    isElectronicCommunicationConsent?: boolean | undefined;
}

export interface IRootViewModel {
    id: number | undefined;
    versionNumber: number | undefined;

    companyName?: string | undefined;
    contact: IApplicationContactViewModel;
    overlappingContactTypes: Client.GetCompanyOeiDraftApplicationDetailsContactOverlappingContactTypeDto[];

    viewState: ViewStateEnum;
    isDirty: boolean;
    isConflict: boolean;
    statusMessages: Client.StatusMessagesDto | undefined;
    audit: Client.SecurePortalSimpleAuditEventsDto | undefined;

    refreshDetails(id: number, response: Client.GetCompanyOeiDraftApplicationDetailsContactResponseDto): IRootViewModel;
    refreshSaveStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel;
    refreshConflict(): IRootViewModel;
    onInitialised(): IRootViewModel;
    onSaved(): IRootViewModel;
    onEdit(): IRootViewModel;
    onContactChanged(values: IApplicationContactViewModel): IRootViewModel;
}

export enum ViewStateEnum {
    Initialising,
    View,
    Edit,
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.id = undefined;
        this.versionNumber = undefined;

        this.companyName = undefined;
        this.contact = {};
        this.overlappingContactTypes = [];

        this.viewState = ViewStateEnum.Initialising;
        this.isDirty = false;
        this.isConflict = false;
        this.statusMessages = undefined;
        this.audit = undefined;
    }

    public id: number | undefined;
    public versionNumber: number | undefined;

    public companyName: string | undefined;
    public contact: IApplicationContactViewModel;
    public overlappingContactTypes: Client.GetCompanyOeiDraftApplicationDetailsContactOverlappingContactTypeDto[];

    public viewState: ViewStateEnum;
    public isDirty: boolean;
    public isConflict: boolean;
    public statusMessages: Client.StatusMessagesDto | undefined;
    public audit: Client.SecurePortalSimpleAuditEventsDto | undefined;

    public refreshDetails(id: number, response: Client.GetCompanyOeiDraftApplicationDetailsContactResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.id = id;
        vm.versionNumber = response.versionNumber;

        vm.companyName = response.applicantCompanyName;
        vm.contact.primaryContactName = response.primaryContactName;
        vm.contact.positionHeld = response.positionHeld;
        vm.contact.phone = response.phone;
        vm.contact.email = response.email;
        vm.contact.fax = response.fax;
        vm.contact.businessAddress = response.businessAddress;
        vm.contact.isOverlappingContactSharingConsent = response.isOverlappingContactSharingConsent;
        vm.contact.overlappingContactType = response.overlappingContactType;
        vm.contact.isElectronicCommunicationConsent = response.isElectronicCommunicationConsent;

        // reference
        vm.overlappingContactTypes = response.overlappingContactTypes;

        vm.isDirty = false;
        vm.isConflict = false;
        vm.audit = response.audit;

        return vm;
    }

    public refreshSaveStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.statusMessages = statusMessages;
        if (statusMessages.isSuccess) {
            vm.isDirty = false;
        }

        return vm;
    }

    public refreshConflict(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.statusMessages = undefined;
        vm.isConflict = true; // this is only reset on initialise from the reducer

        return vm;
    }

    public onInitialised(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Initialising) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    public onSaved(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    public onEdit(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.View) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.Edit;
        vm.isDirty = false;
        vm.statusMessages = undefined;

        return vm;
    }

    public onContactChanged(values: IApplicationContactViewModel): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.isDirty = true;
        vm.contact = values;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.contact = clone(this.contact);
        return vm;
    }
}
