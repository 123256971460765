// framework
import { useState } from "react";
// kendo
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { Grid, GridColumn, GridCellProps, GridSelectionChangeEvent, GridHeaderSelectionChangeEvent } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// redux
import * as Models from "../../models/models";
import * as Actions from "../../redux/actions";
// common
import CustomGridCell from "../../../../../../../common/kendoGrid/CustomGridCell";
import SecureDocumentDownloadLinkControl from "../../../../../../../common/secureDocumentDownload/SecureDocumentDownloadLinkControl";

export default function ApplicationFormView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }) {
    const { vm, actions } = props;

    const initialGridState: State = {
        sort: [{ field: "fileName", dir: "asc" }],
    };
    const [gridState, setGridState] = useState(initialGridState);

    function onSelectionChange(e: GridSelectionChangeEvent): void {
        const d = e.dataItem as Models.IDocumentViewModel;
        if (!d) return;
        actions.viewChanged(vm.onApplicationFormDocumentSelected(d.authorisationId!));
    }

    function onHeaderSelectionChange(e: GridHeaderSelectionChangeEvent): void {
        if (!vm.canSelectApplicationFormDocuments) return;

        const headerCheckbox = e.syntheticEvent.target as HTMLInputElement;
        actions.viewChanged(vm.onApplicationFormDocumentsSelected(headerCheckbox.checked));
    }

    // Note: looked at using cellRender approach, it seems cleaner but there are issues with Typescript https://www.telerik.com/forums/selectively-hide-checkbox-in-grid-selection-column-based-on-row-data
    function getSelectionCell(props: GridCellProps) {
        const handleChange = (e: CheckboxChangeEvent) => {
            props.selectionChange!({ syntheticEvent: e.syntheticEvent });
        };

        const { dataItem } = props;
        const d = dataItem as Models.IDocumentViewModel;

        // Note: using Kendo rather than native checkboxes for controls inside Kendo grids
        return <CustomGridCell gridCellProps={props}>{d.canDownload && <Checkbox checked={d.isSelected} onChange={handleChange} />}</CustomGridCell>;
    }

    return (
        <Grid
            sortable
            resizable
            navigatable
            selectedField="isSelected"
            onSelectionChange={onSelectionChange}
            onHeaderSelectionChange={onHeaderSelectionChange}
            data={process(vm.applicationFormDocuments, gridState)}
            {...gridState}
            onDataStateChange={(e) => {
                setGridState(e.dataState);
            }}
        >
            <GridColumn field="isSelected" title="Select" width={100} headerSelectionValue={vm.allApplicationFormDocumentsSelected} cell={getSelectionCell} />
            <GridColumn
                field="fileName"
                title="File Name"
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        {props.dataItem.canDownload ? (
                            <SecureDocumentDownloadLinkControl id={props.dataItem.authorisationId}>{props.dataItem.fileName}</SecureDocumentDownloadLinkControl>
                        ) : (
                            props.dataItem.fileName
                        )}
                    </CustomGridCell>
                )}
                width={600}
            />
            <GridColumn field="fileSizeMb" title="File Size" width={150} format="{0:n0}MB" />
        </Grid>
    );
}
