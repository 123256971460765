// framework
import { orderBy } from "@progress/kendo-data-query";
// api
import * as Client from "../../api/Client";

export interface IWorkProgramYearActivityViewModel {
    activityOrder: number;
    description: string | undefined;
    quantity: string | undefined;
    value: number | undefined;
}

export class WorkProgramYearActivityViewModel implements IWorkProgramYearActivityViewModel {
    constructor(activityOrder: number, description: string | undefined, quantity: string | undefined, value: number | undefined) {
        this.activityOrder = activityOrder;
        this.description = description;
        this.quantity = quantity;
        this.value = value;
    }

    public activityOrder: number;
    public description: string | undefined;
    public quantity: string | undefined;
    public value: number | undefined;

    public static fromTitleDetailsDto(dto: Client.GetCompanyOpggsTitleDetailsWorkProgramYearActivityDto): WorkProgramYearActivityViewModel {
        return new WorkProgramYearActivityViewModel(dto.activityOrder, dto.description, dto.quantity, dto.value);
    }

    public static fromTitleDetailsDtos(dtos: Client.GetCompanyOpggsTitleDetailsWorkProgramYearActivityDto[] | undefined): WorkProgramYearActivityViewModel[] {
        if (!dtos) return new Array<IWorkProgramYearActivityViewModel>();

        return orderBy(dtos, [{ field: "activityOrder", dir: "asc" }]).map((a) => WorkProgramYearActivityViewModel.fromTitleDetailsDto(a));
    }

    public static fromApplicationDetailsDto(dto: Client.ApplicationWorkProgramYearActivityDto): WorkProgramYearActivityViewModel {
        return new WorkProgramYearActivityViewModel(dto.activityOrder, dto.description, dto.quantity, dto.value);
    }

    public static fromApplicationDetailsDtos(dtos: Client.ApplicationWorkProgramYearActivityDto[] | undefined): WorkProgramYearActivityViewModel[] {
        if (!dtos) return new Array<IWorkProgramYearActivityViewModel>();

        return orderBy(dtos, [{ field: "activityOrder", dir: "asc" }]).map((a) => WorkProgramYearActivityViewModel.fromApplicationDetailsDto(a));
    }
}

export interface IWorkProgramYearViewModel {
    yearOrder: number;
    years: string;
    activities: IWorkProgramYearActivityViewModel[];
    value: number | undefined;
    startDate?: Date | undefined;
    endDate?: Date | undefined;
}

export class WorkProgramYearViewModel implements IWorkProgramYearViewModel {
    constructor(yearOrder: number, years: string, activities: IWorkProgramYearActivityViewModel[], value: number | undefined, startDate?: Date | undefined, endDate?: Date | undefined) {
        this.yearOrder = yearOrder;
        this.years = years;
        this.activities = activities;
        this.value = value;
        this.startDate = startDate;
        this.endDate = endDate;
    }

    public yearOrder: number;
    public years: string;
    public activities: IWorkProgramYearActivityViewModel[];
    public value: number | undefined;
    public startDate?: Date | undefined;
    public endDate?: Date | undefined;

    public static fromTitleDetailsDto(dto: Client.GetCompanyOpggsTitleDetailsWorkProgramYearDto): WorkProgramYearViewModel {
        return new WorkProgramYearViewModel(dto.yearOrder, dto.years, WorkProgramYearActivityViewModel.fromTitleDetailsDtos(dto.activities), dto.value, dto.startDate, dto.endDate);
    }

    public static fromTitleDetailsDtos(dtos: Client.GetCompanyOpggsTitleDetailsWorkProgramYearDto[] | undefined): WorkProgramYearViewModel[] {
        if (!dtos) return new Array<IWorkProgramYearViewModel>();

        return orderBy(dtos, [{ field: "yearOrder", dir: "asc" }]).map((a) => WorkProgramYearViewModel.fromTitleDetailsDto(a));
    }

    public static fromApplicationDetailsDto(dto: Client.ApplicationWorkProgramYearDto): WorkProgramYearViewModel {
        return new WorkProgramYearViewModel(dto.yearOrder, dto.yearType, WorkProgramYearActivityViewModel.fromApplicationDetailsDtos(dto.activities), dto.value);
    }

    public static fromApplicationDetailsDtos(dtos: Client.ApplicationWorkProgramYearDto[] | undefined): WorkProgramYearViewModel[] {
        if (!dtos) return new Array<IWorkProgramYearViewModel>();

        return orderBy(dtos, [{ field: "yearOrder", dir: "asc" }]).map((a) => WorkProgramYearViewModel.fromApplicationDetailsDto(a));
    }
}

export interface IWorkProgramViewModel {
    years: IWorkProgramYearViewModel[];
    grandTotal: number;
    primaryTermTotal: number;
    secondaryTermTotal: number;
    showPrimaryAndSecondaryTerms: boolean;
    showStartAndEndDates: boolean;
}

export class WorkProgramViewModel implements IWorkProgramViewModel {
    constructor(years: IWorkProgramYearViewModel[], grandTotal: number, primaryTermTotal: number, secondaryTermTotal: number, showPrimaryAndSecondaryTerms: boolean, showStartAndEndDates: boolean) {
        this.years = years;
        this.grandTotal = grandTotal;
        this.primaryTermTotal = primaryTermTotal;
        this.secondaryTermTotal = secondaryTermTotal;
        this.showPrimaryAndSecondaryTerms = showPrimaryAndSecondaryTerms;
        this.showStartAndEndDates = showStartAndEndDates;
    }

    public years: IWorkProgramYearViewModel[];
    public grandTotal: number;
    public primaryTermTotal: number;
    public secondaryTermTotal: number;
    public showPrimaryAndSecondaryTerms: boolean;
    public showStartAndEndDates: boolean;

    public static fromTitleDetailsDto(dto: Client.GetCompanyOpggsTitleDetailsWorkProgramDto): WorkProgramViewModel {
        return new WorkProgramViewModel(WorkProgramYearViewModel.fromTitleDetailsDtos(dto.years), dto.grandTotal, dto.primaryTermTotal, dto.secondaryTermTotal, dto.showPrimaryAndSecondaryTerms, true);
    }

    public static fromApplicationDetailsDto(dto: Client.GetCompanyOpggsApplicationDetailsWorkProgramResponseDto): WorkProgramViewModel {
        return new WorkProgramViewModel(
            WorkProgramYearViewModel.fromApplicationDetailsDtos(dto.years),
            dto.grandTotal,
            dto.primaryTermTotal,
            dto.secondaryTermTotal,
            dto.showPrimaryAndSecondaryTerms,
            false
        );
    }
}
