// redux
import * as Models from "../../models/models";
// common
import * as Forms from "../../../../../../../common/forms/BootstrapForms";
import FinancialNotificationLinkController from "../../../../../../../common/links/FinancialNotificationLinkController";

export default function FinancialNotificationView(props: { vm: Models.IRootViewModel }) {
    const vm = props.vm;

    if (!vm.hasValidFinancialNotification) return <span>There appears to be an issue with the Financial Notification for this Application. Please contact NOPTA to resolve this issue.</span>;

    return (
        <>
            <Forms.Group>
                <Forms.Element label="Notification ID">
                    {vm.financialNotificationLink && <FinancialNotificationLinkController linkDto={vm.financialNotificationLink!} secureEntityType="Company" />}
                </Forms.Element>
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="Notification Status" id="notificationStatusField" value={vm.financialNotificationStatus} />
            </Forms.Group>
        </>
    );
}
