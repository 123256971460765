// models
import { IRootViewModel } from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import SimpleAuditEventsView from "../../../../../../common/audit/SimpleAuditEventsView";

interface IProps {
    vm: IRootViewModel;
}

export default function ReadOnlySurveyDataDetailsView(props: IProps): JSX.Element {
    const { vm } = props;

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">General</h2>
                    <Forms.Row>
                        <Forms.FullColumn>
                            <Forms.ReadonlyTextArea label="Public Title" id="publicTitleField" value={vm.surveyDataDetails.publicTitle} />
                        </Forms.FullColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Data Type" id="surveyDataTypeField" value={vm.surveyDataDetails.surveyDataType?.name} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Item ID" id="itemIdField" value={vm.surveyDataDetails.itemId} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField
                                label="Is Copied to NOPIMS"
                                id="copiedToNopimsField"
                                value={vm.surveyDataDetails.isCopiedToNopims === undefined ? "" : vm.surveyDataDetails.isCopiedToNopims ? "Yes" : "No"}
                            />
                        </Forms.ThirdColumn>
                        <Forms.FullColumn>
                            <Forms.ReadonlyField label="Survey Data Title" id="titleField" value={vm.surveyDataDetails.title} />
                        </Forms.FullColumn>
                    </Forms.Row>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Confidentiality</h2>
                    <Forms.Row>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Received Date" id="receivedDateField" value={GlobalHelpers.toNoptaDateString(vm.surveyDataDetails.receivedDate)} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Written Date" id="writtenDateField" value={GlobalHelpers.toNoptaDateString(vm.surveyDataDetails.writtenDate)} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Confidentiality" id="isConfidentialField" value={GlobalHelpers.convertBooleanToConfidentialityValue(vm.surveyDataDetails.isConfidential)} />
                        </Forms.ThirdColumn>
                        <Forms.FullColumn>
                            <Forms.ReadonlyTextArea label="Confidentiality Remarks" id="commentsField" value={vm.surveyDataDetails.confidentialityRemarks} />
                        </Forms.FullColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Basic Interp Type" id="basicInterpTypeField" value={vm.surveyDataDetails.basicInterpType?.name} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Basic Relevant Date" id="basicRelevantDateField" value={GlobalHelpers.toNoptaDateString(vm.surveyDataDetails.basicRelevantDate)} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Interp Relevant Date" id="interpRelevantDateField" value={GlobalHelpers.toNoptaDateString(vm.surveyDataDetails.interpRelevantDate)} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Released Date" id="releasedDateField" value={GlobalHelpers.toNoptaDateString(vm.surveyDataDetails.releasedDate)} />
                        </Forms.ThirdColumn>
                    </Forms.Row>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Survey Data Specific</h2>
                    <Forms.Row>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Company" id="companyField" value={vm.surveyDataDetails.company?.name} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Inline Range" id="inlineRangeField" value={vm.surveyDataDetails.inlineRange} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn></Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Contractor" id="contractorField" value={vm.surveyDataDetails.contractor?.name} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Crossline Range" id="crosslineRangeField" value={vm.surveyDataDetails.crossLineRange} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn></Forms.ThirdColumn>
                    </Forms.Row>
                </div>
            </div>
            <SimpleAuditEventsView simpleAuditEvents={vm.auditDto} />
        </>
    );
}
