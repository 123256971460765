// redux
import * as Models from "../../models/models";
// common
import * as Forms from "../../../../../../../common/forms/BootstrapForms";

export default function EvaReadonlyView(props: { vm: Models.IRootViewModel }) {
    const vm = props.vm;

    return (
        <>
            <Forms.Group>
                <Forms.ReadonlyField label="Nominated Titleholder for Eligible Voluntary Action (Form 6)" id="nominatedTitleholderField" value={vm.nominatedTitleholderCompanyName} />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="Application made under a valid EVA Nomination (under subsection 775B(2) of the OPGGS Act)" id="evaTypeField" value={vm.evaTypeDisplay} />
            </Forms.Group>
        </>
    );
}
