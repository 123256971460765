import { Link } from "react-router-dom";

interface IProps {
    activeNavItemKey: any;
    navItems: {
        title: string;
        key: any;
        isDisabled?: boolean | undefined;
        isHidden?: boolean | undefined;
        url: string;
    }[];
}

export default function CommonNavBarView(props: IProps): JSX.Element {
    // provides a react-router friendly navigation bar
    // similar to nopims MVC one, except using pills and no care for dirty state!

    // using raw bootstrap html & styles over bootstrap-react to easily validate look & feel against the MVC implementation

    return (
        <div className="mb-2">
            <nav className="navbar navbar-expand navbar-light bg-none">
                <div className="collapse navbar-collapse" id="navbarText">
                    <ul className="nav nav-pills">
                        {props.navItems
                            .filter((item) => !item.isHidden)
                            .map((item, index) => (
                                <li key={index} className="nav-item">
                                    <Link className={`nav-link ${item.key === props.activeNavItemKey ? "active" : ""} ${item.isDisabled ? "disabled" : ""}`} to={item.url}>
                                        {item.title}
                                    </Link>
                                </li>
                            ))}
                    </ul>
                </div>
            </nav>
        </div>
    );
}
