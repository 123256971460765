// redux
import { call, put, select } from "redux-saga/effects";
import { IGeoscienceAustraliaReduxState } from "../../../../geoscienceAustraliaReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/rootViewModel";
import { getGeoscienceAustraliaDetails } from "./initialise";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_UserManagement_GeoscienceAustraliaDetails;

export default function* savePermissions() {
    let vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const saveResult: CallHelper.CallResult<Client.SaveGeoscienceAustraliaUserManagementGeoscienceAustraliaDetailsRolesResponseDto> = yield call(
            async () => await saveGeoscienceAustraliaPermissions(vm)
        );

        if (saveResult.IsSuccess) {
            // success
            const r = saveResult.Response!;
            if (r.statusMessages.isSuccess) {
                const geoscienceAustraliaResponse: Client.GetGeoscienceAustraliaUserManagementGeoscienceAustraliaDetailsResponseDto = yield call(getGeoscienceAustraliaDetails);
                vm = vm.refreshDetails(geoscienceAustraliaResponse, false);
            }
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshSaveGeoscienceAustraliaPermissionsResponse(saveResult.Response!)));
        } else if (saveResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            saveResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function saveGeoscienceAustraliaPermissions(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.SaveGeoscienceAustraliaUserManagementGeoscienceAustraliaDetailsRolesResponseDto>> {
    // Exclude secure users that can not be edited from the update request
    const editedPermissions = vm.permissions.filter((p) => p.hasChanges());
    if (editedPermissions.some((p) => !p.canEdit())) throw new Error("Invalid field update.");

    const permissionDtos = editedPermissions.map(
        (p) =>
            new Client.SaveGeoscienceAustraliaUserManagementGeoscienceAustraliaDetailsRoleDto({
                secureUserId: p.secureUserId,
                secureUserVersionNumber: p.secureUserVersionNumber,
                roles: p.getDirectRoles(),
            })
    );
    const request = new Client.SaveGeoscienceAustraliaUserManagementGeoscienceAustraliaDetailsRolesRequestDto({
        roles: permissionDtos,
    });

    const client = await ClientFactory.createGeoscienceAustraliaUserManagementClient();
    return await CallHelper.call(() => client.saveGeoscienceAustraliaUserManagementGeoscienceAustraliaDetailsRoles(request));
}
