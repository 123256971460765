// framework
import { useState, useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// views
import ApplicationFormView from "./views/ApplicationFormView";
import SupportingDocumentationView from "./views/SupportingDocumentationView";
import WarningsView from "./views/WarningsView";
// common
import * as SecureDocumentDownloadButtonControl from "../../../../../../common/secureDocumentDownload/SecureDocumentDownloadButtonControl";

interface IControllerProps extends Actions.IActionFactory {
    id: string;
    applicationFormCardTitle: string;
    supportingDocumentationCardTitle: string;
    lastRefreshedDatetime: Date | undefined;
    className?: string | undefined;
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const [lastRefreshedDatetime, setLastRefreshedDatetime] = useState<Date | undefined>(undefined);

    // on mount
    // - initial load
    useEffect(() => {
        actions.initialiseRequested(props.id);
        return function () {
            actions.clearRequested();
        };
    }, [props.id]); // eslint-disable-line react-hooks/exhaustive-deps

    // refresh
    // - reasons to refresh include (1) the user pressing the refresh button
    // - don't refresh just because the parent refreshed, this can cause us to immediately lose status changes when a save occurs here
    // - also be careful to ensure a refresh only occurs once, it's easy to make a mistake and have the service call happen multiple times
    useEffect(() => {
        // console.info(vm.viewState, props.lastRefreshedDatetime, lastRefreshedDatetime);

        // if it's initialising, just wait
        if (vm.viewState === Models.ViewStateEnum.Initialising) return;

        // a manual refresh has been requested
        if (lastRefreshedDatetime !== props.lastRefreshedDatetime) {
            setLastRefreshedDatetime(props.lastRefreshedDatetime);
            actions.initialiseRequested(props.id);
        }
    }, [vm.viewState, props.lastRefreshedDatetime, lastRefreshedDatetime]); // eslint-disable-line react-hooks/exhaustive-deps

    const applicationFormDocuments = vm.getApplicationFormDocumentsForDownload();
    const supportingDocumentationDocuments = vm.getSupportingDocumentationDocumentsForDownload();

    // return
    return (
        <>
            <div className={props.className}>
                <div className="card" style={{ minWidth: 250 }}>
                    <div className="card-body">
                        <h4 className="card-title">{props.applicationFormCardTitle}</h4>
                        <WarningsView viewState={vm.viewState} hasUnavailableDocuments={vm.hasUnavailableApplicationFormDocuments} />

                        {/* application form */}
                        <div className="mb-2">
                            <ApplicationFormView vm={vm} actions={actions} />
                        </div>

                        {/* grid action buttons */}
                        {vm.applicationFormDocuments.length > 0 && (
                            <div className="mb-4">
                                <SecureDocumentDownloadButtonControl.ButtonControl disabled={vm.numberOfApplicationFormDocumentsSelected === 0} documents={applicationFormDocuments} className="me-1" />
                                <div>
                                    <SecureDocumentDownloadButtonControl.ValidationControl documents={applicationFormDocuments} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={props.className}>
                <div className="card" style={{ minWidth: 250 }}>
                    <div className="card-body">
                        <h4 className="card-title">{props.supportingDocumentationCardTitle}</h4>
                        <WarningsView viewState={vm.viewState} hasUnavailableDocuments={vm.hasUnavailableSupportingDocumentationDocuments} />

                        {/* supporting documentation */}
                        <div className="mb-2">
                            <SupportingDocumentationView vm={vm} actions={actions} />
                        </div>
                        {/* grid action buttons */}
                        {vm.supportingDocumentationDocuments.length > 0 && (
                            <div className="mb-4">
                                <SecureDocumentDownloadButtonControl.ButtonControl
                                    disabled={vm.numberOfSupportingDocumentationDocumentsSelected === 0}
                                    documents={supportingDocumentationDocuments}
                                    className="me-1"
                                />
                                <div>
                                    <SecureDocumentDownloadButtonControl.ValidationControl documents={supportingDocumentationDocuments} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.JointAuthority_Opggs_ApplicationDetails_Controls_Documentation }), Actions.actionFactory)(Controller);
