// redux
import * as Models from "../../models/models";
// common
import * as Forms from "../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import * as BadgeHelper from "../../../../../common/badges/BadgeHelper";
import * as cellHelper from "../../../common/permissionCellHelper";

export default function UserDetailsView(props: { vm: Models.IRootViewModel }): React.ReactElement {
    const secureUser = props.vm.secureUser;

    // view
    return (
        <div className="card mb-2">
            <div className="card-body">
                <h2 className="card-title">User Details</h2>
                <Forms.Row>
                    <Forms.HalfColumn>
                        <Forms.Label htmlFor="usernameField">
                            Username
                            {secureUser.isSecureUserBlocked && <span className="mx-1">{BadgeHelper.getBlockedUserBadge(cellHelper.TOOL_TIP_BLOCKED)}</span>}
                            {secureUser.hasPendingRequest && <span className="mx-1">{BadgeHelper.getPendingReviewBadge(cellHelper.TOOL_TIP_PENDING_REVIEW)}</span>}
                        </Forms.Label>
                        <Forms.ReadonlyTextInput id="usernameField" value={secureUser.username} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="accountStatus" label="Account Status" value={secureUser.accountStatus} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="fullNameField" label="Full Name" value={secureUser.fullName} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.Element label="Email">
                            <a href={"mailto:" + secureUser.emailAddress}>{secureUser.emailAddress}</a>
                        </Forms.Element>
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="positionField" label="Position" value={secureUser.position} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="contactNumberField" label="Contact Number" value={secureUser.contactNumber} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="lastLoginField" label="Last Login (AWST)" value={GlobalHelpers.toNoptaDatetimeString(secureUser.lastLoginDatetime)} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="registrationDateField" label="Registration Date (AWST)" value={GlobalHelpers.toNoptaDatetimeString(secureUser.registrationDatetime)} />
                    </Forms.HalfColumn>
                </Forms.Row>
            </div>
        </div>
    );
}
