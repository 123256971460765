// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { ICompanyReduxState } from "../../../../companyReduxRegistration";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

const getRootViewModel = (state: ICompanyReduxState) => state.Company_UserManagement_UserList;

export default function* getContentRequested() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());
        const response: Client.GetCompanyUserManagementUserListResponseDto = yield call(async () => {
            const client = await ClientFactory.createCompanyUserManagementClient();
            return await CallHelper.simpleCall(() => client.getCompanyUserManagementUserList(new Client.GetCompanyUserManagementUserListRequestDto()));
        });
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshSaga(response)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
