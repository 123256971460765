// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../../../api/Client";

export interface IRootViewModel {
    id: number | undefined;
    versionNumber: number | undefined;
    audit: Client.SecurePortalSimpleAuditEventsDto | undefined;
    viewState: ViewStateEnum;
    isDirty: boolean;
    isConflict: boolean;
    getStatusMessages: Client.StatusMessagesDto | undefined;
    saveStatusMessages: Client.StatusMessagesDto | undefined;
    signatureType: Client.GetCompanyOpggsLegislativeFormsFormRequestSignatureTypeItemDto | null | undefined;
    availableSignatureTypes: Array<Client.GetCompanyOpggsLegislativeFormsFormRequestSignatureTypeItemDto>;
    isDraft: boolean;

    refreshSignatureTypeResponse(id: number, response: Client.GetCompanyOpggsLegislativeFormsFormRequestSignatureTypeResponseDto): IRootViewModel;
    refreshSaveStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel;
    refreshConflict(): IRootViewModel;
    onInitialised(): IRootViewModel;
    onSaved(): IRootViewModel;
    onEdit(): IRootViewModel;
    refreshSignatureTypeUpdated(signatureType: Client.GetCompanyOpggsLegislativeFormsFormRequestSignatureTypeItemDto): IRootViewModel;
}

export enum ViewStateEnum {
    Initialising,
    View,
    Edit,
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.id = undefined;
        this.versionNumber = undefined;
        this.audit = undefined;
        this.viewState = ViewStateEnum.Initialising;
        this.isDirty = false;
        this.isConflict = false;
        this.getStatusMessages = undefined;
        this.saveStatusMessages = undefined;
        this.signatureType = undefined;
        this.availableSignatureTypes = [];
        this.isDraft = false;
    }

    public id: number | undefined;
    public versionNumber: number | undefined;
    public audit: Client.SecurePortalSimpleAuditEventsDto | undefined;
    public viewState: ViewStateEnum;
    public isDirty: boolean;
    public isConflict: boolean;
    public getStatusMessages: Client.StatusMessagesDto | undefined;
    public saveStatusMessages: Client.StatusMessagesDto | undefined;
    public signatureType: Client.GetCompanyOpggsLegislativeFormsFormRequestSignatureTypeItemDto | null | undefined;
    public availableSignatureTypes: Array<Client.GetCompanyOpggsLegislativeFormsFormRequestSignatureTypeItemDto>;
    public isDraft: boolean;

    public refreshSignatureTypeResponse(id: number, response: Client.GetCompanyOpggsLegislativeFormsFormRequestSignatureTypeResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.versionNumber = response.versionNumber;
        vm.isDirty = false;
        vm.isConflict = false;
        vm.id = id;
        vm.audit = response.audit;
        vm.getStatusMessages = response.statusMessages;
        vm.signatureType = response.signatureType;
        vm.availableSignatureTypes = response.availableSignatureTypes!;
        vm.isDraft = response.isDraft;

        return vm;
    }

    public refreshSaveStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.getStatusMessages = undefined;
        vm.saveStatusMessages = statusMessages;
        if (statusMessages.isSuccess) {
            vm.isDirty = false;
        }
        return vm;
    }

    public refreshConflict(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.saveStatusMessages = undefined;
        vm.isConflict = true; // this is only reset on initialise from the reducer
        return vm;
    }

    public onInitialised(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Initialising) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.View;
        return vm;
    }

    public onSaved(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.View;
        return vm;
    }

    public onEdit(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.View) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.Edit;
        vm.isDirty = false;
        vm.saveStatusMessages = undefined;
        return vm;
    }

    public refreshSignatureTypeUpdated(signatureType: Client.GetCompanyOpggsLegislativeFormsFormRequestSignatureTypeItemDto): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.signatureType = signatureType;
        vm.isDirty = true;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
