// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../api/Client";
// kendo
import { orderBy } from "@progress/kendo-data-query";
// models
import { IExistingRoleViewModel, ExistingRoleViewModel } from "./existingRoleViewModel";
import { IPendingRequestViewModel, PendingRequestViewModel } from "./pendingRequestViewModel";
import { IEditableSecureUser, EditableSecureUser } from "./editableSecureUserViewModel";
import { BaseCompanyRoleRootViewModel, ICompanyRoleRootViewModel } from "../../../common/CompanyRoleRootViewModel";

export interface IRootViewModel extends ICompanyRoleRootViewModel {
    isDirty: boolean;
    isEditable: boolean;
    isConflict: boolean;
    secureUser: Client.GetCompanyAccountMyDetailsResponseDto;
    editableSecureUser: IEditableSecureUser;
    refreshDetails(response: Client.GetCompanyAccountMyDetailsResponseDto): IRootViewModel;
    refreshEditableDetails(details: IEditableSecureUser): IRootViewModel;
    setEditState(edit: boolean): IRootViewModel;

    service: {
        statusMessages?: Client.StatusMessagesDto;
    };
    setStatusMessages(statusMessages: Client.StatusMessagesDto | undefined): IRootViewModel;
    refreshConflict(): IRootViewModel;

    existingPermissions: Array<IExistingRoleViewModel>;
    pendingRequests: Array<IPendingRequestViewModel>;
}

export class RootViewModel extends BaseCompanyRoleRootViewModel implements IRootViewModel {
    constructor() {
        super();
        this.isDirty = false;
        this.isEditable = false;
        this.isConflict = false;
        this.secureUser = new Client.GetCompanyAccountMyDetailsResponseDto();
        this.editableSecureUser = new EditableSecureUser();
        this.service = {
            statusMessages: undefined,
        };
        this.existingPermissions = new Array<IExistingRoleViewModel>();
        this.pendingRequests = new Array<IPendingRequestViewModel>();
    }

    public isDirty: boolean;
    public isEditable: boolean;
    public isConflict: boolean;
    public secureUser: Client.GetCompanyAccountMyDetailsResponseDto;
    public editableSecureUser: IEditableSecureUser;

    public existingPermissions: Array<IExistingRoleViewModel>;
    public pendingRequests: Array<IPendingRequestViewModel>;

    public service: {
        statusMessages?: Client.StatusMessagesDto;
    };

    public refreshDetails(response: Client.GetCompanyAccountMyDetailsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.secureUser = response;
        vm.setEditableValues(response);

        const pendingRequests = response.pendingRequests.map((r) => new PendingRequestViewModel(vm.roleConfiguration.roleRelationships!, r));
        vm.pendingRequests = orderBy(pendingRequests, [{ field: "requestDatetime", dir: "asc" }]);

        const permissions = response.existingRoles.map((p) => new ExistingRoleViewModel(vm.roleConfiguration.roleRelationships!, p));
        vm.existingPermissions = orderBy(permissions, [{ field: "companyName", dir: "asc" }]);

        vm.isDirty = false;
        vm.isEditable = false;
        vm.isConflict = false;
        vm.service.statusMessages = undefined;
        return vm;
    }

    public refreshEditableDetails(details: IEditableSecureUser): IRootViewModel {
        const vm = this._clone();
        vm.setEditableValues(details);
        vm.isDirty = true;
        return vm;
    }

    public setEditState(edit: boolean): IRootViewModel {
        const vm = this._clone();
        vm.isEditable = edit;
        if (vm.isEditable) {
            vm.service.statusMessages = undefined;
        } else {
            vm.setEditableValues(vm.secureUser);
            vm.isDirty = false;
        }
        return vm;
    }

    public setStatusMessages(statusMessages: Client.StatusMessagesDto | undefined): IRootViewModel {
        const vm = this._clone();
        vm.service.statusMessages = statusMessages;
        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        vm.service.statusMessages = undefined;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.secureUser = clone(this.secureUser);
        vm.editableSecureUser = clone(this.editableSecureUser);
        vm.service = clone(this.service);
        vm.existingPermissions = clone(this.existingPermissions);
        vm.pendingRequests = clone(this.pendingRequests);
        return vm;
    }

    private setEditableValues(details: IEditableSecureUser) {
        this.editableSecureUser.correspondenceEmailAddress = details.correspondenceEmailAddress;
        this.editableSecureUser.position = details.position;
        this.editableSecureUser.contactNumber = details.contactNumber;
    }
}
