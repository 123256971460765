import * as Models from "../models/models";

// -------
// Actions
// -------

export enum actions {
    refreshStaticConfigurationRequested = "shell.configuration.refreshStaticConfigurationRequested",
    refreshDynamicConfigurationRequested = "shell.configuration.refreshDynamicConfigurationRequested",
    configurationLoaded = "shell.configuration.configurationLoaded",
}

// --------
// Payloads
// --------

export interface IAction<T> {
    type: actions;
    payload: T;
}

// ---------
// Factories
// ---------

export interface IActionFactory {
    refreshStaticConfigurationRequested(): IAction<undefined>;
    refreshDynamicConfigurationRequested(isInitialLoad: boolean): IAction<boolean>;
    configurationLoaded(vm: Models.IConfigurationModel): IAction<Models.IConfigurationModel>;
}

export const actionFactory: IActionFactory = {
    // configuration
    refreshStaticConfigurationRequested: function (): IAction<undefined> {
        return {
            type: actions.refreshStaticConfigurationRequested,
            payload: undefined,
        };
    },

    refreshDynamicConfigurationRequested: function (isInitialLoad: boolean): IAction<boolean> {
        return {
            type: actions.refreshDynamicConfigurationRequested,
            payload: isInitialLoad,
        };
    },

    configurationLoaded: function (vm: Models.IConfigurationModel): IAction<Models.IConfigurationModel> {
        return {
            type: actions.configurationLoaded,
            payload: vm,
        };
    },
};
