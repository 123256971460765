// framework
import { Link } from "react-router-dom";
// common
import * as CompanyRouteHelper from "../../companyRouteHelper";

export default function ApplicationDeletedView() {
    const draftApplicationsListUrl = CompanyRouteHelper.oeiDraftApplicationList();

    return (
        <div className="card mb-2">
            <div className="card-body">
                <p>
                    The Application has been deleted. Click <Link to={draftApplicationsListUrl}>here</Link> to return to the Draft OEI Act Applications list.
                </p>
            </div>
        </div>
    );
}
