// framework
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
// redux
import * as Models from "../../models/models";
import * as Actions from "../../redux/actions";
// children views
import TitleSummaryView from "./titleSummary/TitleSummaryView";
import TitleholdersView from "./TitleholdersView";
import WorkProgramView from "./workProgram/WorkProgramView";
import MemorialView from "./MemorialView";
import BlocksAndLocationsView from "./blocksAndLocations/BlocksAndLocationsView";
import LegislativeFormsView from "./legislativeForms/LegislativeFormsView";
import OnlineSubmissionsView from "./onlineSubmissions/OnlineSubmissionsView";
// other
import TitleDetailsConfiguration from "../TitleDetailsConfiguration";

export enum TitleDetailsTabsEnum {
    Summary,
    TitleHolders,
    WorkProgram,
    Memorial,
    BlocksAndLocations,
    LegislativeForms,
    OnlineSubmissions,
}

function TitleDetailsView(props: {
    vm: Models.IRootViewModel;
    actions: Actions.IActionFactory;
    configuration: TitleDetailsConfiguration;
    applicationGuideLink: string | undefined;
    preferredTab?: TitleDetailsTabsEnum;
}) {
    const vm = props.vm;
    const actions = props.actions;
    const configuration = props.configuration;
    const isTitleActive = vm.details?.isTitleActive ?? false;
    const canCreateDraftApplications = vm.details?.canManageDraftApplications ?? false;

    const preferredTab = props.preferredTab ?? TitleDetailsTabsEnum.Summary;

    return (
        <Tabs defaultActiveKey={preferredTab.toString()} variant="pills" mountOnEnter className="mb-2">
            {/* Summary Tab */}
            <Tab eventKey={TitleDetailsTabsEnum.Summary.toString()} title="Title Summary">
                <TitleSummaryView vm={vm} />
            </Tab>

            {/* Titleholders Tab */}
            <Tab eventKey={TitleDetailsTabsEnum.TitleHolders.toString()} title="Titleholders">
                <TitleholdersView vm={vm} />
            </Tab>

            {/* Work Program Tab */}
            {configuration.IsWorkProgramVisible && (
                <Tab eventKey={TitleDetailsTabsEnum.WorkProgram.toString()} title="Work Program">
                    <WorkProgramView vm={vm} />
                </Tab>
            )}

            {/* Memorial Tab */}
            {
                <Tab eventKey={TitleDetailsTabsEnum.Memorial.toString()} title="Memorial">
                    <MemorialView vm={vm} actions={actions} />
                </Tab>
            }

            {/* Blocks & Locations Tab */}
            {configuration.IsBlocksAndLocationsTabVisible && (
                <Tab eventKey={TitleDetailsTabsEnum.BlocksAndLocations.toString()} title={configuration.IsLocationsDataVisible ? "Blocks & Locations" : "Blocks"}>
                    <BlocksAndLocationsView vm={vm} configuration={configuration} />
                </Tab>
            )}

            {/* Forms Tab */}
            {configuration.IsFormsTabVisible && (
                <Tab eventKey={TitleDetailsTabsEnum.LegislativeForms.toString()} title="Legislative Forms">
                    <LegislativeFormsView vm={vm} actions={actions} />
                </Tab>
            )}

            {/* Online Submissions Tab */}
            {isTitleActive && canCreateDraftApplications && (
                <Tab eventKey={TitleDetailsTabsEnum.OnlineSubmissions.toString()} title="Online Submissions">
                    <OnlineSubmissionsView vm={vm} actions={actions} applicationGuideLink={props.applicationGuideLink} />
                </Tab>
            )}
        </Tabs>
    );
}

export default TitleDetailsView;
