// redux
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../../api/callHelper";
import * as Client from "../../../../../../../../api/Client";
// common
import * as GlobalHelpers from "../../../../../../../../common/GlobalHelpers";

export async function getCompanyOpggsLegislativeFormsFormRequestFormDocumentation(id: number): Promise<Client.GetCompanyOpggsLegislativeFormsFormRequestFormDocumentationResponseDto> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();
    return await CallHelper.simpleCall(() =>
        client.getCompanyOpggsLegislativeFormsFormRequestFormDocumentation(new Client.GetCompanyOpggsLegislativeFormsFormRequestFormDocumentationRequestDto({ id: id }))
    );
}

export async function saveCompanyOpggsLegislativeFormsFormRequestWetFormDocumentation(
    vm: Models.IRootViewModel
): Promise<CallHelper.CallResult<Client.SaveCompanyOpggsLegislativeFormsFormRequestWetFormDocumentationResponseDto>> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();
    return await CallHelper.call(() =>
        client.saveCompanyOpggsLegislativeFormsFormRequestWetFormDocumentation(
            new Client.SaveCompanyOpggsLegislativeFormsFormRequestWetFormDocumentationRequestDto({
                id: vm.id!,
                versionNumber: vm.versionNumber!,
                fileIds: vm.wetSignatureDetails.file ? [vm.wetSignatureDetails.file.fileId] : [],
            })
        )
    );
}

export async function saveCompanyOpggsLegislativeFormsFormRequestDigitalSignature(
    vm: Models.IRootViewModel
): Promise<CallHelper.CallResult<Client.SaveCompanyOpggsLegislativeFormsFormRequestDigitalSignatureResponseDto>> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();
    return await CallHelper.call(() =>
        client.saveCompanyOpggsLegislativeFormsFormRequestDigitalSignature(
            new Client.SaveCompanyOpggsLegislativeFormsFormRequestDigitalSignatureRequestDto({
                id: vm.id!,
                versionNumber: vm.versionNumber!,
                companyId: vm.digitalSignatureDetails.newCompanySignatureDetails.signingFor!.companyId,
                digitalSignatureType: vm.digitalSignatureDetails.newCompanySignatureDetails.signingAs!.type,
                clientOffsetMinutes: GlobalHelpers.getDateOffsetMinutes(new Date())!, // user's current timezone
                poaLocation: vm.digitalSignatureDetails.newCompanySignatureDetails.poaLocation,
                poaPosition: vm.digitalSignatureDetails.newCompanySignatureDetails.poaPosition,
                isPoaPreviouslyProvided: vm.digitalSignatureDetails.newCompanySignatureDetails.isPoaPreviouslyProvided,
                poaDate: vm.digitalSignatureDetails.newCompanySignatureDetails.poaDate,
                poaDateOffsetMinutes: GlobalHelpers.getDateOffsetMinutes(vm.digitalSignatureDetails.newCompanySignatureDetails.poaDate),
            })
        )
    );
}
