// framework
import { useState } from "react";
import { DashIcon } from "@primer/octicons-react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// api
import * as Client from "../../../../api/Client";
// redux
import * as Models from "../../../../company/oei/licenceDetails/models/models";
// other
import * as GlobalHelpers from "../../../GlobalHelpers";
import { createGridColumnCheckboxFilterMenu } from "../../../kendoGrid/GridColumnCheckboxFilterMenu";
import CustomGridCell from "../../../kendoGrid/CustomGridCell";
import SecureFileDownloadLinkControl from "../../../secureFileDownload/SecureFileDownloadLinkControl";

export default function MemorialView(props: { vm: Models.IRootViewModel }) {
    const details = props.vm.details;
    const applications = details?.memorial ?? new Array<Client.GetCompanyOeiLicenceDetailsMemorialApplicationDto>();

    const [gridState, setGridState] = useState({
        skip: 0,
        take: 20,
    } as State);

    function documentDownload(fileId: number) {
        if (!fileId) {
            return <DashIcon size="small" />;
        }

        return <SecureFileDownloadLinkControl id={fileId}>Download</SecureFileDownloadLinkControl>;
    }

    return (
        <div className="card mb-2">
            <div className="card-body">
                <Grid
                    pageable
                    resizable
                    navigatable
                    data={process(applications, gridState)}
                    {...gridState}
                    onDataStateChange={(e) => {
                        setGridState(e.dataState);
                    }}
                >
                    <GridColumn field="applicationTypeName" title="Application Type" width={350} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, applications)} />
                    <GridColumn field="executedDate" title="Executed Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="submittedDate" title="Submitted Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="decision" title="Decision" width={200} />
                    <GridColumn field="implementedDate" title="Implemented Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="registeredDate" title="Registered Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="hasDocument" title="Document" width={115} cell={(props) => <CustomGridCell gridCellProps={props}>{documentDownload(props.dataItem.fileId)}</CustomGridCell>} />
                </Grid>
            </div>
        </div>
    );
}
