export default function DisclaimerView(): React.ReactElement {
    return (
        <>
            <p>For an OEI Act Licence application to be considered validly made, and processing to begin, please upload:</p>
            <ul>
                <li>a correctly executed approved application form</li>
                <li>any information or documents required by the OEI Act Regulations, the approved form, any relevant Invitation to Apply instrument, and any relevant Area Declaration instrument</li>
                <li>evidence of fee payment</li>
            </ul>
            <p>Where relevant, the documentation must be submitted within the prescribed timeframe identified in an Invitation to Apply instrument.</p>
            <p>Go to the Invitation to Apply page to submit an Initial Application (Feasibility Licence).</p>
        </>
    );
}
