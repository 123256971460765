// framework
import * as Validation from "../../../../common/validation/ValidationModel";
// redux
import { IRegisteredTitleholderDetailsViewModel } from "./registeredTitleholderDetailsViewModel";

export function validate(vm: IRegisteredTitleholderDetailsViewModel, formTypeId: number | undefined, isAcnOrArbnRequired: boolean | undefined, v: Validation.ValidationModel): void {
    // registered titleholder details only required for form 2 and form 3 requests
    if (!(formTypeId === 2 || formTypeId === 3)) return;

    if (formTypeId === 2 && !vm.becomingARegisteredTitleholderDate) {
        v.addError("RegisteredTitleholderDetails.BecomingARegisteredTitleholderDate", "Date of becoming a Registered Titleholder is required.");
    }

    if (isAcnOrArbnRequired === true) {
        if (Validation.isNullOrWhiteSpace(vm.acnOrArbn)) {
            v.addError("RegisteredTitleholderDetails.AcnOrArbn", "ACN/ARBN is required.");
        } else if (!Validation.isValidAcnOrArbn(vm.acnOrArbn)) {
            v.addError("RegisteredTitleholderDetails.AcnOrArbn", "ACN/ARBN is not valid.");
        }
    }

    if (Validation.isNullOrWhiteSpace(vm.phone)) {
        v.addError("RegisteredTitleholderDetails.Phone", "Registered Titleholder's Phone is required.");
    } else if (vm.phone!.length > 100) {
        v.addError("RegisteredTitleholderDetails.Phone", "Registered Titleholder's Phone must be 100 characters or less.");
    }

    if (vm.fax && vm.fax.length > 100) v.addError("RegisteredTitleholderDetails.Fax", "Registered Titleholder's Fax must be 100 characters or less.");

    if (Validation.isNullOrWhiteSpace(vm.email)) {
        v.addError("RegisteredTitleholderDetails.Email", "Registered Titleholder's Email is required.");
    } else if (!Validation.isValidEmail(vm.email!)) {
        v.addError("RegisteredTitleholderDetails.Email", "Registered Titleholder's Email is not in the correct format.");
    } else if (vm.email!.length > 100) {
        v.addError("RegisteredTitleholderDetails.Email", "Registered Titleholder's Email must be 100 characters or less.");
    }
}
