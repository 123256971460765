// framework
import { connect } from "react-redux";
// redux
import * as Actions from "./redux/actions";

interface IProps extends Actions.IActionFactory {
    className?: string | undefined;
}

function Controller(props: IProps) {
    return (
        <span className={props.className}>
            <button className="btn btn-outline-primary" title="Sign In" onClick={() => props.signInRequestedAction()}>
                Sign In
            </button>
        </span>
    );
}

// connect redux
export default connect(null, Actions.actionFactory)(Controller);
