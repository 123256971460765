// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export enum ViewStateEnum {
    View,
    Add,
    Edit,
    BulkImport,
}

/**
 * Used to create or update surveyData lines
 */
export interface ISaveSurveyDataLine {
    surveyDataLineId?: number | undefined;
    lineName?: string;
    fsl?: string;
    lsl?: string;
    lineComments?: string;
}

export interface IImportSurveyDataLinesFile {
    fileName: string;
    fileContent: string;
}

export interface IRootViewModel {
    surveyDataId: number | undefined;
    surveyDataVersionNumber: number | undefined;
    publicTitle: string | undefined;
    itemId: string | undefined;
    pageHeader: string | undefined;
    saveSurveyDataLine?: ISaveSurveyDataLine;

    viewState: ViewStateEnum;

    lines: Client.GetGeoscienceAustraliaSurveyDataLineItemDto[] | undefined;

    isConflict: boolean;
    isDirty: boolean;
    statusMessages?: Client.StatusMessagesDto;
    dialogStatusMessages?: Client.StatusMessagesDto;

    importSurveyDataLinesFile?: IImportSurveyDataLinesFile;

    setViewState(viewState: ViewStateEnum): IRootViewModel;
    refreshLines(response: Client.GetGeoscienceAustraliaSurveyDataLinesResponseDto): IRootViewModel;
    refreshStatusMessages(statusMessages?: Client.StatusMessagesDto | undefined): IRootViewModel;
    refreshDialogStatusMessages(statusMessages?: Client.StatusMessagesDto | undefined): IRootViewModel;
    onSaveSurveyDataLineChanged(saveSurveyDataLine: ISaveSurveyDataLine): IRootViewModel;
    refreshConflict(isConflict: boolean): IRootViewModel;
    clearStatusMessages(): IRootViewModel;
    refreshImportSurveyDataLinesFile(importSurveyDataLinesFile?: IImportSurveyDataLinesFile): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.viewState = ViewStateEnum.View;
        this.surveyDataId = undefined;
        this.surveyDataVersionNumber = undefined;
        this.publicTitle = undefined;
        this.itemId = undefined;
        this.pageHeader = undefined;

        this.saveSurveyDataLine = this.defaultSaveSurveyDataLine();

        this.lines = [];

        this.isConflict = false;
        this.isDirty = false;
    }

    public surveyDataId: number | undefined;
    public surveyDataVersionNumber: number | undefined;
    public publicTitle: string | undefined;
    public itemId: string | undefined;
    public pageHeader: string | undefined;
    public saveSurveyDataLine: ISaveSurveyDataLine;

    public viewState: ViewStateEnum;

    public lines: Client.GetGeoscienceAustraliaSurveyDataLineItemDto[] | undefined;

    public isDirty: boolean;
    public isConflict: boolean;
    public statusMessages?: Client.StatusMessagesDto;
    public dialogStatusMessages?: Client.StatusMessagesDto;

    public importSurveyDataLinesFile?: IImportSurveyDataLinesFile;

    public refreshLines(response: Client.GetGeoscienceAustraliaSurveyDataLinesResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.surveyDataId = response.surveyDataId;
        vm.surveyDataVersionNumber = response.surveyDataVersionNumber;
        vm.publicTitle = response.publicTitle;
        vm.itemId = response.itemId;
        vm.pageHeader = response.publicTitle && (response.publicTitle.length > 50 ? response.publicTitle.substring(0, 47) + "..." : response.publicTitle) + ` (${response.itemId})`;

        vm.lines = response.lines;

        return vm;
    }

    public refreshImportSurveyDataLinesFile(importSurveyDataLinesFile?: IImportSurveyDataLinesFile): IRootViewModel {
        const vm = this._clone();
        vm.importSurveyDataLinesFile = importSurveyDataLinesFile;
        return vm;
    }

    public refreshStatusMessages(statusMessages?: Client.StatusMessagesDto | undefined): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = statusMessages;
        return vm;
    }

    public refreshDialogStatusMessages(statusMessages?: Client.StatusMessagesDto | undefined): IRootViewModel {
        const vm = this._clone();
        vm.dialogStatusMessages = statusMessages;
        return vm;
    }

    public setViewState(viewState: ViewStateEnum): IRootViewModel {
        const vm = this._clone();
        vm.viewState = viewState;
        return vm;
    }

    public refreshConflict(isConflict: boolean): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = isConflict;
        return vm;
    }

    public onSaveSurveyDataLineChanged(saveSurveyDataLine: ISaveSurveyDataLine): IRootViewModel {
        const vm = this._clone();
        vm.saveSurveyDataLine = saveSurveyDataLine;
        vm.isDirty = true;
        return vm;
    }

    public clearStatusMessages(): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = undefined;
        vm.dialogStatusMessages = undefined;
        vm.saveSurveyDataLine = this.defaultSaveSurveyDataLine();
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.statusMessages = undefined;
        return vm;
    }

    private defaultSaveSurveyDataLine(): ISaveSurveyDataLine {
        return {
            surveyDataLineId: undefined,
            lineName: undefined,
            fsl: undefined,
            lsl: undefined,
            lineComments: undefined,
        };
    }
}
