// framework
import { useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
// views
import ApplicationListView from "./ApplicationListView";

interface IApplicationListControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IApplicationListControllerProps) {
    const vm = props.rootViewModel;

    // on mount
    useEffect(() => {
        props.initialiseRequested();
        return function () {
            props.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BodyLayout
            title="In Progress OPGGS Act Applications"
            selectedLhsNavItem={Level1ItemTypeEnum.JointAuthority_Application_ApplicationList}
            onReloadRequested={() => {
                props.initialiseRequested();
            }}
        >
            <ApplicationListView vm={vm.applications} />
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.JointAuthority_Opggs_ApplicationList }), Actions.actionFactory)(Controller);
