// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export enum ViewStateEnum {
    Initialise = "geoscienceAustralia.wellsBoreholes.reportDetails.models.viewState.initialise",
    Edit = "geoscienceAustralia.wellsBoreholes.reportDetails.models.viewState.edit",
    Create = "geoscienceAustralia.wellsBoreholes.reportDetails.models.viewState.create",
}

export interface ICreateDetails {
    itemId?: string | undefined;
    publicTitle?: string | undefined;
    wellBoreholes?: Client.GetGeoscienceAustraliaCreateReportReferenceDataWellBorehole[];
    surveyAcquisitions?: Client.GetGeoscienceAustraliaCreateReportReferenceDataSurveyAcquisition[];
    reprocessingProjects?: Client.GetGeoscienceAustraliaCreateReportReferenceDataReprocessingProject[];
    reportType?: Client.GetGeoscienceAustraliaCreateReportReferenceDataReportType | undefined;
    isCopiedToNopims?: boolean | undefined;
    reportTitle?: string | undefined;
    writtenDate?: Date | undefined;
    receivedDate?: Date | undefined;
    isConfidential?: boolean | undefined;
    confidentialityRemarks?: string | undefined;
    reportBasicInterpType?: Client.GetGeoscienceAustraliaCreateReportReferenceDataBasicInterpType | undefined;
    basicRelevantDate?: Date | undefined;
    interpRelevantDate?: Date | undefined;
    releasedDate?: Date | undefined;
    company?: Client.GetGeoscienceAustraliaCreateReportReferenceDataContractorCompanies | undefined;
    author?: string | undefined;
    contractor?: Client.GetGeoscienceAustraliaCreateReportReferenceDataContractorCompanies | undefined;
    tapeId?: string | undefined;
    reportDataFormat?: Client.GetGeoscienceAustraliaCreateReportReferenceDataReportDataFormat | undefined;
    reportMediaType?: Client.GetGeoscienceAustraliaCreateReportReferenceDataReportMediaType | undefined;
    remarks?: string | undefined;
    reportConditionStatus?: Client.GetGeoscienceAustraliaCreateReportReferenceDataReportConditionStatus | undefined;
    qcRemarks?: string | undefined;
    reportCopyStatus?: Client.GetGeoscienceAustraliaCreateReportReferenceDataReportCopyStatus | undefined;
    reportLoanStatus?: Client.GetGeoscienceAustraliaCreateReportReferenceDataReportLoanStatus | undefined;
    rddsStatus?: Client.GetGeoscienceAustraliaCreateReportReferenceDataRddsStatus | undefined;
    rddsComments?: string | undefined;
    reportDataStatus?: Client.GetGeoscienceAustraliaCreateReportReferenceDataReportDataStatus | undefined;
    consignment?: string | undefined;
}

export interface IReferenceData {
    wellBoreholes: Client.GetGeoscienceAustraliaCreateReportReferenceDataWellBorehole[];
    surveyAcquisitions: Client.GetGeoscienceAustraliaCreateReportReferenceDataSurveyAcquisition[];
    reprocessingProjects: Client.GetGeoscienceAustraliaCreateReportReferenceDataReprocessingProject[];
    reportTypes: Client.GetGeoscienceAustraliaCreateReportReferenceDataReportType[];
    reportBasicInterpTypes: Client.GetGeoscienceAustraliaCreateReportReferenceDataBasicInterpType[];
    contractorCompanies: Client.GetGeoscienceAustraliaCreateReportReferenceDataContractorCompanies[];
    reportDataFormats: Client.GetGeoscienceAustraliaCreateReportReferenceDataReportDataFormat[];
    reportMediaTypes: Client.GetGeoscienceAustraliaCreateReportReferenceDataReportMediaType[];
    reportConditionStatuses: Client.GetGeoscienceAustraliaCreateReportReferenceDataReportConditionStatus[];
    reportCopyStatuses: Client.GetGeoscienceAustraliaCreateReportReferenceDataReportCopyStatus[];
    reportLoanStatuses: Client.GetGeoscienceAustraliaCreateReportReferenceDataReportLoanStatus[];
    rddsStatuses: Client.GetGeoscienceAustraliaCreateReportReferenceDataRddsStatus[];
    reportDataStatuses: Client.GetGeoscienceAustraliaCreateReportReferenceDataReportDataStatus[];
}

export interface IRootViewModel {
    wellId: number | undefined;
    reportId: number | undefined;

    createDetails: ICreateDetails;
    referenceData: IReferenceData;

    auditDto?: Client.SimpleAuditEventsDto;

    statusMessages?: Client.StatusMessagesDto;

    viewState: ViewStateEnum;
    relatedActivityType: Client.CreateReportRelatedActivityTypeEnum | undefined;

    isDirty: boolean;

    refreshReferenceData(relatedActivityType: Client.CreateReportRelatedActivityTypeEnum | undefined, response: Client.GetCreateGeoscienceAustraliaReportReferenceDataResponseDto): IRootViewModel;
    refreshCreateResponse(response: Client.CreateGeoscienceAustraliaReportResponseDto, createAnother: boolean): IRootViewModel;

    onCreateDetailsChanged(reportDetails: ICreateDetails): IRootViewModel;
    onDirtyClear(): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.createDetails = {};
        this.referenceData = {
            wellBoreholes: [],
            surveyAcquisitions: [],
            reprocessingProjects: [],
            reportTypes: [],
            reportBasicInterpTypes: [],
            contractorCompanies: [],
            reportDataFormats: [],
            reportMediaTypes: [],
            reportConditionStatuses: [],
            reportCopyStatuses: [],
            reportLoanStatuses: [],
            rddsStatuses: [],
            reportDataStatuses: [],
        };

        this.isDirty = false;
        this.viewState = ViewStateEnum.Initialise;
    }

    public wellId: number | undefined;
    public reportId: number | undefined;

    public createDetails: ICreateDetails;
    public referenceData: IReferenceData;

    public auditDto?: Client.SimpleAuditEventsDto;

    public statusMessages?: Client.StatusMessagesDto;
    public isDirty: boolean;

    public viewState: ViewStateEnum;
    public relatedActivityType: Client.CreateReportRelatedActivityTypeEnum | undefined;

    public refreshReferenceData(
        relatedActivityType: Client.CreateReportRelatedActivityTypeEnum | undefined,
        response: Client.GetCreateGeoscienceAustraliaReportReferenceDataResponseDto
    ): IRootViewModel {
        const vm = this._clone();
        vm.viewState = ViewStateEnum.Edit;
        vm.relatedActivityType = relatedActivityType;
        vm.referenceData = {
            wellBoreholes: response.wellBoreholes,
            surveyAcquisitions: response.surveyAcquisitions,
            reprocessingProjects: response.reprocessingProjects,
            reportTypes: response.reportTypes,
            reportBasicInterpTypes: response.basicInterpTypes,
            contractorCompanies: response.contractorCompanies,
            reportDataFormats: response.reportDataFormats,
            reportMediaTypes: response.reportMediaTypes,
            reportConditionStatuses: response.reportConditionStatuses,
            reportCopyStatuses: response.reportCopyStatuses,
            reportLoanStatuses: response.reportLoanStatuses,
            rddsStatuses: response.rddsStatuses,
            reportDataStatuses: response.reportDataStatuses,
        };

        return vm;
    }

    public refreshCreateResponse(response: Client.CreateGeoscienceAustraliaReportResponseDto, createAnother: boolean): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = response.statusMessages;

        if (response.statusMessages?.isSuccess) {
            // don't set the report Id when requested to create and create another
            if (createAnother) {
                vm.reportId = undefined;
                vm.viewState = ViewStateEnum.Edit;
                vm.createDetails = {};
            } else {
                vm.reportId = response.reportId;
                vm.viewState = ViewStateEnum.Create;
            }
            vm.isDirty = false;
        } else {
            vm.isDirty = true;
        }
        return vm;
    }

    public onCreateDetailsChanged(createDetails: ICreateDetails): IRootViewModel {
        const vm = this._clone();
        vm.createDetails = createDetails;
        vm.isDirty = true;
        return vm;
    }

    public onDirtyClear(): IRootViewModel {
        const vm = this._clone();
        vm.isDirty = false;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.createDetails = clone(this.createDetails);
        vm.referenceData = clone(this.referenceData);
        return vm;
    }
}
