// framework
import { useState } from "react";
// models
import { ISurveyDataFileAssets, IRootViewModel } from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import { IValidationModel } from "../../../../../../common/validation/ValidationModel";
import DirtyScope from "../../../../../../common/shell/DirtyScopeController";
import KendoComboBox from "../../../../../../common/kendo/KendoComboBox";
import ValidationMessageView from "../../../../../../common/validation/ValidationMessageView";
// redux
import * as Actions from "../../redux/actions";

interface IProps {
    vm: IRootViewModel;
    v: IValidationModel;
    actions: Actions.IActionFactory;
}

export default function FileAssets(props: IProps): JSX.Element {
    const { vm, v, actions } = props;

    const [state, setState] = useState<ISurveyDataFileAssets>(vm.surveyDataFileAssets);

    function updateVm() {
        actions.viewChanged(vm.onFileAssetsChanged(state));
    }

    function updateVmAndState(updatedState: ISurveyDataFileAssets) {
        setState(updatedState);
        actions.viewChanged(vm.onFileAssetsChanged(updatedState));
    }

    return (
        <DirtyScope scopeName="geoscienceAustralia/wellBoreholes/surveyData/fileAssets" isDirty={vm.isDirty}>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">File / Asset</h2>
                    <Forms.Row>
                        <Forms.ThirdColumn>
                            <Forms.TextInput
                                label="Tape ID"
                                id="tapeIdField"
                                maxLength={100}
                                valid={v.applyValidityForKendo("SurveyDataFileAsset.TapeID")}
                                value={state.tapeId}
                                onChange={(value) => setState({ ...state, tapeId: value })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="SurveyDataFileAsset.TapeID" validationModel={v} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="dataFormatField">Data Format</Forms.Label>
                            <KendoComboBox
                                id="dataFormatField"
                                value={state.surveyDataDataFormat}
                                data={vm.referenceData.surveyDataDataFormats}
                                keyField="id"
                                textField="name"
                                filterable={true}
                                suggest={true}
                                onChange={(value) => updateVmAndState({ ...state, surveyDataDataFormat: value })}
                            />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="mediaTypeField">Media Type</Forms.Label>
                            <KendoComboBox
                                id="mediaTypeField"
                                value={state.surveyDataMediaType}
                                data={vm.referenceData.surveyDataMediaTypes}
                                keyField="id"
                                textField="name"
                                filterable={true}
                                suggest={true}
                                onChange={(value) => updateVmAndState({ ...state, surveyDataMediaType: value })}
                            />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="conditionStatusField">Condition Status</Forms.Label>
                            <KendoComboBox
                                id="conditionStatusField"
                                value={state.surveyDataConditionStatus}
                                data={vm.referenceData.surveyDataConditionStatuses}
                                keyField="id"
                                textField="name"
                                filterable={true}
                                suggest={true}
                                onChange={(value) => updateVmAndState({ ...state, surveyDataConditionStatus: value })}
                            />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="copyStatusField">Copy Status</Forms.Label>
                            <KendoComboBox
                                id="copyStatusField"
                                value={state.surveyDataCopyStatus}
                                data={vm.referenceData.surveyDataCopyStatuses}
                                keyField="id"
                                textField="name"
                                filterable={true}
                                suggest={true}
                                onChange={(value) => updateVmAndState({ ...state, surveyDataCopyStatus: value })}
                            />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="loanStatusField">Loan Status</Forms.Label>
                            <KendoComboBox
                                id="loanStatusField"
                                value={state.surveyDataLoanStatus}
                                data={vm.referenceData.surveyDataLoanStatuses}
                                keyField="id"
                                textField="name"
                                filterable={true}
                                suggest={true}
                                onChange={(value) => updateVmAndState({ ...state, surveyDataLoanStatus: value })}
                            />
                        </Forms.ThirdColumn>
                        <Forms.FullColumn>
                            <Forms.TextArea
                                label="Remarks"
                                id="remarksField"
                                rows={3}
                                maxLength={1000}
                                valid={v.applyValidity("SurveyDataFileAsset.Remarks")}
                                value={state.remarks}
                                onChange={(value) => setState({ ...state, remarks: value })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="SurveyDataFileAsset.Remarks" validationModel={v} />
                        </Forms.FullColumn>
                        <Forms.FullColumn>
                            <Forms.TextArea
                                label="QC Remarks"
                                id="qcRemarks"
                                rows={3}
                                maxLength={1000}
                                valid={v.applyValidity("SurveyDataFileAsset.QcRemarks")}
                                value={state.qcRemarks}
                                onChange={(value) => setState({ ...state, qcRemarks: value })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="SurveyDataFileAsset.QcRemarks" validationModel={v} />
                        </Forms.FullColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="rdsStatusField">RDDS Status</Forms.Label>
                            <KendoComboBox
                                id="rdsStatusField"
                                value={state.surveyDataRddsStatus}
                                data={vm.referenceData.surveyDataRddsStatuses}
                                keyField="id"
                                textField="name"
                                filterable={true}
                                suggest={true}
                                onChange={(value) => updateVmAndState({ ...state, surveyDataRddsStatus: value })}
                            />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="dataStatusField">Data Status</Forms.Label>
                            <KendoComboBox
                                id="dataStatusField"
                                value={state.surveyDataDataStatus}
                                data={vm.referenceData.surveyDataDataStatuses}
                                keyField="id"
                                textField="name"
                                filterable={true}
                                suggest={true}
                                onChange={(value) => updateVmAndState({ ...state, surveyDataDataStatus: value })}
                            />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.TextInput
                                label="Consignment"
                                id="consignmentField"
                                maxLength={1000}
                                valid={v.applyValidity("SurveyDataFileAsset.Consignment")}
                                value={state.consignment}
                                onChange={(value) => setState({ ...state, consignment: value })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="SurveyDataFileAsset.Consignment" validationModel={v} />
                        </Forms.ThirdColumn>
                        <Forms.FullColumn>
                            <Forms.TextArea
                                label="RDDS Comments"
                                id="rddsComments"
                                rows={3}
                                maxLength={1000}
                                valid={v.applyValidity("SurveyDataFileAsset.RDDSComments")}
                                value={state.rddsComments}
                                onChange={(value) => setState({ ...state, rddsComments: value })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="SurveyDataFileAsset.RDDSComments" validationModel={v} />
                        </Forms.FullColumn>
                    </Forms.Row>
                </div>
            </div>
        </DirtyScope>
    );
}
