// kendo
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
// redux
import * as Models from "../../models/models";
// api
import { ForeignInvestmentApprovalTypeDto } from "../../../../../../../api/Client";
// model
import { ITitleholderForeignInvestmentApproval } from "../../models/models";
// common
import CustomGridCell from "../../../../../../../common/kendoGrid/CustomGridCell";
import { IValidationModel } from "../../../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../../../common/validation/ValidationMessageView";

export default function TitleholderForeignInvestmentApprovalTypeCell(gridCellProps: GridCellProps, data: ForeignInvestmentApprovalTypeDto[], viewState: Models.ViewStateEnum, v: IValidationModel) {
    const { field, dataItem, dataIndex, onChange } = gridCellProps;

    const handleChange = (e: DropDownListChangeEvent) => {
        if (onChange) {
            onChange({
                dataIndex: dataIndex,
                dataItem: dataItem,
                syntheticEvent: e.syntheticEvent,
                field: field,
                value: e.value,
            });
        }
    };

    const a = dataItem as ITitleholderForeignInvestmentApproval;
    const errorName = `TitleholderForeignInvestment.Titleholder${a.companyId}`;

    return (
        <CustomGridCell gridCellProps={gridCellProps}>
            {viewState === Models.ViewStateEnum.Edit ? (
                <>
                    <DropDownList
                        valid={v.applyValidityForKendo(errorName)}
                        data={data}
                        value={a.foreignInvestmentApprovalType}
                        dataItemKey="foreignInvestmentApprovalType"
                        textField="description"
                        onChange={handleChange}
                        defaultItem={null}
                    />
                    <ValidationMessageView name={errorName} validationModel={v} />
                </>
            ) : (
                a.foreignInvestmentApprovalType?.description
            )}
        </CustomGridCell>
    );
}
