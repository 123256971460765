// framework
import { Link } from "react-router-dom";
// redux
import { INoptaRequestsViewModel } from "../../models/rootViewModel";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
// other
import * as companyRouteHelper from "../../../../companyRouteHelper";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import * as BadgeHelper from "../../../../../common/badges/BadgeHelper";
import CustomGridCell from "../../../../../common/kendoGrid/CustomGridCell";
import EmailLinkView from "../../../../../common/externalLink/EmailLinkView";

export default function NoptaRequestListView(props: { vm: INoptaRequestsViewModel }): React.ReactElement {
    const vm = props.vm;

    // view
    return (
        <div className="card my-2">
            <div className="card-body">
                <h2 className="card-title">NOPTA Requests</h2>
                {vm.requests.length > 0 && (
                    <p className="card-text">
                        The following requests for access have been submitted to NOPTA for review. If you have any concerns please contact <EmailLinkView emailAddress="neats.admin@nopta.gov.au" />.
                        {vm.isRequestLimitExceeded && <span className="text-danger"> Note: Requests are limited to 20.</span>}
                    </p>
                )}
                <Grid resizable navigatable data={vm.requests}>
                    <GridColumn field="requestCode" title="Request ID" width={200} />
                    <GridColumn field="requestDatetime" title="Request Date (AWST)" width={200} format={GlobalHelpers.NoptaDatetimeFormatForKendo} />
                    <GridColumn field="roleRequested" title="Role Requested" width={150} />
                    <GridColumn
                        field="companyName"
                        title="Company"
                        width={300}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                <Link to={companyRouteHelper.companyDetails(props.dataItem.companyId)}>{props.dataItem.companyName}</Link>
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn
                        field="secureUserDisplayName"
                        title="Full Name"
                        width={200}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                {props.dataItem.secureUserId ? (
                                    <Link to={companyRouteHelper.userDetails(props.dataItem.secureUserId)}>{props.dataItem.secureUserDisplayName}</Link>
                                ) : (
                                    props.dataItem.secureUserDisplayName
                                )}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn
                        field="badgeSortOrder"
                        title="Information"
                        width={160}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                {props.dataItem.isSecureUserBlocked && BadgeHelper.getBlockedUserBadge()}
                                {props.dataItem.isCompanyRevokedButVisible && BadgeHelper.getCompanyThirtyDayWarningBadge()}
                            </CustomGridCell>
                        )}
                    />
                </Grid>
            </div>
        </div>
    );
}
