// redux
import * as Models from "../models/models";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    clearRequested = "shell.open.clearRequested",
    getLinkDataRequested = "shell.open.getLinkDataRequested",
    // saga events
    sagaCompleted = "shell.open.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

export interface IOpenRouteParameters {
    type: string | undefined;
    code: string | undefined;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    clearRequested(): IAction<undefined>;
    getLinkDataRequested(queryParameters: URLSearchParams): IAction<URLSearchParams>;
    // saga events
    sagaCompleted(vm: Models.IOpenViewModel): IAction<Models.IOpenViewModel>;
}

export const actionFactory: IActionFactory = {
    // lifecycle events
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    getLinkDataRequested: function (queryParameters: URLSearchParams): IAction<URLSearchParams> {
        return {
            type: actions.getLinkDataRequested,
            payload: queryParameters,
        };
    },
    // saga events
    sagaCompleted: function (vm: Models.IOpenViewModel): IAction<Models.IOpenViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
