import { useEffect } from "react";
//common
import { AssignmentGroupEnum } from "../../../../../api/Client";
import StatusMessagesAlertsView from "../../../../../common/alerts/StatusMessagesAlertsView";
import * as Forms from "../../../../../common/forms/BootstrapForms";
// redux
import * as Actions from "../../redux/actions";
// model
import { IRootViewModel, ViewStateEnum } from "../../models/models";

interface IProps {
    vm: IRootViewModel;
    actions: Actions.IActionFactory;
}

export default function FeedbackResponseReadOnlyView(props: IProps): JSX.Element {
    const { vm, actions } = props;
    const feedbackResponse = vm.feedbackResponse;

    useEffect(() => {
        if (feedbackResponse.statusMessages) {
            const element = document.getElementById("feedbackResponseCard");
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [feedbackResponse.statusMessages]);

    const onEdit = () => {
        actions.viewChanged(vm.onEditFeedbackResponse());
    };

    return (
        <>
            <div className="card mb-2" id="feedbackResponseCard">
                <div className="card-body">
                    <h2 className="card-title">Feedback Response</h2>
                    <StatusMessagesAlertsView statusMessages={feedbackResponse.statusMessages} />
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="statusField" label="Status" value={feedbackResponse.status} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="assignmentGroupField" label="Assignment Group" value={feedbackResponse.assignmentGroup?.name} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="assignedUserField" label="Assigned To" value={feedbackResponse.assignedUser?.name} />
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                    {(vm.feedbackResponse.assignmentGroup?.assignmentGroup === AssignmentGroupEnum.Ga || vm.feedbackResponse.isComplete) && (
                        <>
                            <Forms.Group>
                                <Forms.Row>
                                    <Forms.FullColumn>
                                        <Forms.ReadonlyCheckbox id="isCompleteField" label="Mark as Completed" checked={feedbackResponse.isComplete} />
                                    </Forms.FullColumn>
                                </Forms.Row>
                            </Forms.Group>
                            <Forms.Group>
                                <Forms.Row>
                                    <Forms.FullColumn>
                                        <Forms.ReadonlyTextArea id="completionCommentsField" label="Completion Comments" value={feedbackResponse.completionComments} />
                                    </Forms.FullColumn>
                                </Forms.Row>
                            </Forms.Group>
                        </>
                    )}
                    {vm.canManage && !vm.feedbackResponse.isComplete && (
                        <div className="mt-3">
                            <button type="button" className="btn btn-primary me-2" disabled={vm.viewState !== ViewStateEnum.View} onClick={onEdit}>
                                Edit
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
