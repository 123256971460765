// framework
import { clone } from "lodash";
// api
import { SecureRoleEnum, GetShellSecureUserRoleConfigurationRoleRelationshipDto } from "../../api/Client";

// todo - this only applies to role request / approval, but there is no obvious central location for this currently. Look at shifting when we relocate home/registration.
export enum RoleCategoryEnum {
    Existing = "Existing",
    Requested = "Requested",
    Granted = "Granted",
}

export interface IRoleViewModel {
    getKey(): any;
    canEdit(): boolean;

    hasChanges(): boolean;
    isAdministrator(): boolean;
    isSigner(): boolean;
    hasRole(role?: SecureRoleEnum): boolean;
    hasDirectRole(role: SecureRoleEnum): boolean;
    hasEffectiveRole(role: SecureRoleEnum): boolean;
    getDirectRoles(): Array<SecureRoleEnum>;
    updateRole(role: SecureRoleEnum): this;
    revokeAllRoles(allowRemovalOfSpecialRoles: boolean): this;
    resetRoles(): this;

    isSelected: boolean;
    setSelected(value: boolean): this;
}

export abstract class BaseRoleViewModel implements IRoleViewModel {
    constructor(roleRelationships: Array<GetShellSecureUserRoleConfigurationRoleRelationshipDto>, roles?: Array<SecureRoleEnum>) {
        this.roleRelationships = roleRelationships;
        this.rolesInitialState = new Set<SecureRoleEnum>(roles);
        this.directRoles = new Set<SecureRoleEnum>(roles);
        this.effectiveRoles = new Set<SecureRoleEnum>();
        this.isSelected = false;

        this._updateEffectiveRoles();
    }

    // reference data
    private readonly roleRelationships: Array<GetShellSecureUserRoleConfigurationRoleRelationshipDto>;
    // role sets represent direct roles
    // - set on creation of the view model and readonly so that roles can be reset to their initial state and assertions can be made about the state of roles before editing
    private readonly rolesInitialState: ReadonlySet<SecureRoleEnum>;
    // - set on creation of the view model and editable so that selected roles can be changed
    private directRoles: Set<SecureRoleEnum>;
    // - set on creation of the view model, not edited directly but updated to reflect the roles inherited from selected roles
    private effectiveRoles: ReadonlySet<SecureRoleEnum>;

    // selected flag, consider changing to private but doesn't feel necessary at present
    public isSelected: boolean;

    public abstract getKey(): any;
    public abstract canEdit(): boolean;

    public hasChanges(): boolean {
        if (this.rolesInitialState.size !== this.directRoles.size) return true;
        for (let role of Array.from(this.rolesInitialState)) if (!this.directRoles.has(role)) return true;
        return false;
    }

    // administrator is a unique role and referenced directly rather than from configuration
    public isAdministrator(): boolean {
        // todo #7755 - create a higher level 'administrator' flag
        return (
            this.rolesInitialState.has(SecureRoleEnum.CompanyAdministrator) ||
            this.rolesInitialState.has(SecureRoleEnum.JointAuthorityAdministrator) ||
            this.rolesInitialState.has(SecureRoleEnum.GeoscienceAustraliaAdministrator)
        );
    }

    // company signer is a unique role and referenced directly rather than from configuration
    public isSigner(): boolean {
        return this.rolesInitialState.has(SecureRoleEnum.CompanySigner);
    }

    // if a role is provided check that role is 'selected' i.e. direct or effective, if no role provided check there is at least one direct role.
    public hasRole(role?: SecureRoleEnum): boolean {
        if (role) {
            return this.directRoles.has(role) || this.effectiveRoles.has(role);
        } else {
            return this.directRoles.size > 0;
        }
    }

    // check if provided role is a direct role
    public hasDirectRole(role: SecureRoleEnum): boolean {
        return this.directRoles.has(role);
    }

    // check if provided role is a child of a direct role
    public hasEffectiveRole(role: SecureRoleEnum): boolean {
        return this.effectiveRoles.has(role);
    }

    public getDirectRoles(): Array<SecureRoleEnum> {
        return Array.from(this.directRoles);
    }

    // 'toggle' the role provided
    public updateRole(role: SecureRoleEnum): this {
        if (!this.canEdit() || this.effectiveRoles.has(role)) throw new Error("Invalid role update.");

        const effectiveRoles = this.roleRelationships.find((rr) => rr.role === role)?.effectiveRoles;

        const vm = this._clone();

        if (vm.directRoles.has(role)) {
            vm.directRoles.delete(role);
        } else {
            vm.directRoles.add(role);
            // deselect any inherited roles
            if (effectiveRoles) {
                effectiveRoles.forEach((er) => {
                    vm.directRoles.delete(er);
                });
            }
        }

        vm._updateEffectiveRoles();
        return vm;
    }

    // administrator and signer are unique roles and referenced directly rather than from configuration
    // todo #7755 - create a higher level 'administrator' flag
    public revokeAllRoles(allowRemovalOfSpecialRoles: boolean): this {
        if (!this.canEdit()) throw new Error("Invalid role update.");

        const vm = this._clone();

        // special roles to check for
        const specialRoles = [SecureRoleEnum.CompanyAdministrator, SecureRoleEnum.JointAuthorityAdministrator, SecureRoleEnum.GeoscienceAustraliaAdministrator, SecureRoleEnum.CompanySigner];

        // determine if the direct roles contain special roles
        const activeSpecialRoles = specialRoles.filter((role) => vm.directRoles.has(role));

        vm.directRoles.clear();

        // if we're now allowing the removal of special roles, add them back
        if (!allowRemovalOfSpecialRoles) {
            activeSpecialRoles.forEach((r) => {
                vm.directRoles.add(r);
            });
        }

        vm._updateEffectiveRoles();
        return vm;
    }

    // reset selected roles to the initial state
    public resetRoles(): this {
        const vm = this._clone();
        vm.directRoles = new Set(this.rolesInitialState);

        vm._updateEffectiveRoles();
        return vm;
    }

    public setSelected(value: boolean): this {
        const vm = this._clone();
        vm.isSelected = value;

        return vm;
    }

    // private implementation
    // ----------------------
    private _clone(): this {
        return clone(this);
    }

    // update effective roles based on selected roles
    private _updateEffectiveRoles(): void {
        const effectiveRoles = this.roleRelationships.filter((rr) => this.directRoles.has(rr.role)).flatMap((rr) => rr.effectiveRoles);
        this.effectiveRoles = new Set(effectiveRoles);
    }
}
