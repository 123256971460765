import { takeEvery } from "redux-saga/effects";
import * as Actions from "./actions";

import initialise from "./sagas/initialise";
import saveWetFormDocumentation from "./sagas/saveWetFormDocumentation";
import saveDigitalSignature from "./sagas/saveDigitalSignature";
import generateDocument from "./sagas/generateDocument";

export default function* watcherSaga() {
    yield takeEvery(Actions.actions.initialiseRequested, initialise);
    yield takeEvery(Actions.actions.saveWetSignatureRequested, saveWetFormDocumentation);
    yield takeEvery(Actions.actions.saveDigitalSignatureRequested, saveDigitalSignature);
    yield takeEvery(Actions.actions.generateDocumentRequested, generateDocument);
}
