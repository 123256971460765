import { call, put, select } from "redux-saga/effects";
import * as Actions from "../actions";
import * as Models from "../../models/rootViewModel";
import { IJointAuthorityReduxState } from "../../../../jointAuthorityReduxRegistration";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";
import * as ConfigurationHelper from "../../../../../common/shell/configurationHelper";

const getRootViewModel = (state: IJointAuthorityReduxState) => state.JointAuthority_UserManagement_JointAuthorityDetails;

export default function* initialise() {
    // save role reference data to the vm
    let vm: Models.IRootViewModel = yield select(getRootViewModel);
    vm = vm.refreshRoleConfiguration(yield select(ConfigurationHelper.getRoleConfiguration));
    yield put(Actions.actionFactory.sagaCompleted(vm));

    try {
        yield put(shellHelper.createSetBusyAction());

        const response: Client.GetJointAuthorityUserManagementJointAuthorityDetailsResponseDto = yield call(getJointAuthorityDetails);
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshDetails(response, true)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

export async function getJointAuthorityDetails(): Promise<Client.GetJointAuthorityUserManagementJointAuthorityDetailsResponseDto> {
    const request = new Client.GetJointAuthorityUserManagementJointAuthorityDetailsRequestDto();
    const client = await ClientFactory.createJointAuthorityUserManagementClient();
    return await CallHelper.simpleCall(() => client.getJointAuthorityUserManagementJointAuthorityDetails(request));
}
