// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_SurveysAcquisitions_SurveyReportAttachments;

export default function* updateReportAttachment(action: Actions.IAction<Actions.IUpdateReportAttachmentRequestedPayload>) {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const { reportId, reportVersionNumber: surveyVersionNumber, reportAttachmentId, name, documentLink, fileName } = action.payload;

    try {
        yield put(shellHelper.createSetBusyAction());
        const saveResult: CallHelper.CallResult<Client.UpdateGeoscienceAustraliaReportAttachmentResponseDto> = yield call(
            async () => await updateSurveyReportAttachment(reportId, surveyVersionNumber, reportAttachmentId, name, documentLink, fileName)
        );
        if (saveResult.IsSuccess) {
            // success
            let updatedVm = vm;
            const saveResponse = saveResult.Response!;
            if (saveResponse.statusMessages!.isSuccess) {
                const getAttachmentsResponse: CallHelper.CallResult<Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyReportAttachmentsResponseDto> = yield call(
                    async () => await getReportAttachments(vm.surveyId!)
                );
                updatedVm = vm.refreshSurveyReportAttachments(getAttachmentsResponse.Response!);
            }
            yield put(Actions.actionFactory.sagaCompleted(updatedVm.refreshStatusMessages(saveResponse.statusMessages!)));
        } else if (saveResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict(true)));
        } else {
            // all other errors
            saveResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function updateSurveyReportAttachment(
    reportId: number,
    reportVersionNumber: number,
    reportAttachmentId: number,
    name: string,
    documentLink: string,
    fileName: string
): Promise<CallHelper.CallResult<Client.UpdateGeoscienceAustraliaReportAttachmentResponseDto>> {
    const request = new Client.UpdateGeoscienceAustraliaReportAttachmentRequestDto({
        reportId: reportId,
        reportVersionNumber: reportVersionNumber,
        reportAttachmentId: reportAttachmentId,
        name: name,
        documentLink: documentLink,
        fileName: fileName,
    });
    const client = await ClientFactory.createGeoscienceAustraliaReportClient();
    return await CallHelper.call(() => client.updateGeoscienceAustraliaReportAttachment(request));
}

async function getReportAttachments(surveyId: number): Promise<CallHelper.CallResult<Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyReportAttachmentsResponseDto>> {
    const request = new Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyReportAttachmentsRequestDto({
        surveyId: surveyId,
    });
    const client = await ClientFactory.createGeoscienceAustraliaSurveysAcquisitionsClient();
    return await CallHelper.call(() => client.getGeoscienceAustraliaSurveysAcquisitionsSurveyReportAttachments(request));
}
