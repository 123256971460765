// api
import * as Client from "../../../../../../../api/Client";
// views
import DataMonitoringItemsCategoryView from "./DataMonitoringItemsCategoryView";

interface IProps {
    activityType?: Client.AuthorisationActivityTypeEnum;
    category: Client.GetGeoscienceAustraliaAuthorisationsAuthorisationDetailsResponseCategoryDetailsDto;
    canViewBoreholes: boolean;
    canViewAcquisitions: boolean;
    canViewProjects: boolean;
    canViewDataSubmissions: boolean;
}

export default function VariationCategoryView(props: IProps): JSX.Element {
    const { activityType, category, canViewBoreholes, canViewAcquisitions, canViewProjects, canViewDataSubmissions } = props;

    return (
        <DataMonitoringItemsCategoryView
            activityType={activityType}
            category={category}
            canViewBoreholes={canViewBoreholes}
            canViewAcquisitions={canViewAcquisitions}
            canViewProjects={canViewProjects}
            canViewDataSubmissions={canViewDataSubmissions}
        />
    );
}
