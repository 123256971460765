import { takeEvery } from "redux-saga/effects";
import * as Actions from "./actions";

import initialise from "./sagas/initialise";
import search from "./sagas/search";
import importAttachments from "./sagas/importAttachments";
import exportAttachments from "./sagas/exportAttachments";

export default function* watcherSaga() {
    yield takeEvery(Actions.actions.initialiseRequested, initialise);
    yield takeEvery(Actions.actions.searchRequested, search);
    yield takeEvery(Actions.actions.uploadAttachmentFile, importAttachments);
    yield takeEvery(Actions.actions.exportAttachmentsFile, exportAttachments);
}
