// CoCn
import CoCnController from "./coCn/components/Controller";
// EP Renewal
import EpRenewalController from "./epRenewal/components/Controller";
// RL Renewal
import RlRenewalController from "./rlRenewal/components/Controller";
// Eottlr
import EottlrController from "./eottlr/components/Controller";
// Iarl
import IarlController from "./iarl/components/Controller";
// Iarl
import IappController from "./iapp/components/Controller";

import { IRouteItem, RouteBuilder } from "../../../infrastructure/routeItem";

const draftApplicationDetailsRouteBuilder: RouteBuilder = (isSignedIn: boolean) => {
    let routes = new Array<IRouteItem>();

    // not authenticated? don't register routes
    if (!isSignedIn) return routes;

    routes.push({ path: "/Company/Opggs/DraftApplicationDetails/CoCn/:id", exact: true, element: <CoCnController /> });
    routes.push({ path: "/Company/Opggs/DraftApplicationDetails/EpRenewal/:id", exact: true, element: <EpRenewalController /> });
    routes.push({ path: "/Company/Opggs/DraftApplicationDetails/RlRenewal/:id", exact: true, element: <RlRenewalController /> });
    routes.push({ path: "/Company/Opggs/DraftApplicationDetails/Eottlr/:id", exact: true, element: <EottlrController /> });
    routes.push({ path: "/Company/Opggs/DraftApplicationDetails/Iarl/:id", exact: true, element: <IarlController /> });
    routes.push({ path: "/Company/Opggs/DraftApplicationDetails/Iapp/:id", exact: true, element: <IappController /> });

    return routes;
};

export default draftApplicationDetailsRouteBuilder;
