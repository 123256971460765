// framework
import { useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { GridColumn } from "@progress/kendo-react-grid";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/rootViewModel";
import { IUserDetailsRoleViewModel } from "../../models/userDetailsRoleViewModel";
// other
import CustomGridCell from "../../../../../common/kendoGrid/CustomGridCell";
import RoleGrid, { RoleGridModeEnum } from "../../../../../common/roleGrid/RoleGrid";
import * as Forms from "../../../../../common/forms/BootstrapForms";
import * as companyRouteHelper from "../../../../companyRouteHelper";
import * as cellHelper from "../../../common/permissionCellHelper";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";

export default function UserPermissionsEditView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }): React.ReactElement {
    const vm = props.vm;
    const actions = props.actions;

    function updateViewModel(updatedRoles: Array<IUserDetailsRoleViewModel>): void {
        actions.viewChanged(vm.refreshPermissionsChanged(updatedRoles));
    }

    function hasSpecialAccess() {
        return vm.permissions.some((p) => p.isAdministrator() || p.isSigner());
    }

    function hasPendingRequest() {
        return vm.permissions.some((p) => p.hasPendingRequest);
    }

    function hasChanges() {
        return vm.permissions.some((p) => p.hasChanges());
    }

    // admin and signer role checkbox
    const [allowRemovalOfSpecialPermissions, setAllowRemovalOfSpecialPermissions] = useState(false);

    // buttons
    function onCancelClicked() {
        actions.viewChanged(vm.clearEditState());
    }

    function onRemoveAllAccessClicked() {
        actions.viewChanged(vm.revokeAllAccess(allowRemovalOfSpecialPermissions));
    }

    // submission
    function onSubmitClicked(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        actions.savePermissionsRequested();
    }

    // view
    return (
        <form onSubmit={onSubmitClicked}>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Edit Permissions</h2>
                    {/* results grid */}
                    {vm.permissions && (
                        <RoleGrid
                            model={vm.permissions}
                            roleGroups={vm.roleConfiguration.roleGroups}
                            mode={RoleGridModeEnum.Editable}
                            showAdmin
                            showSigner
                            canRemoveSpecialRoles={allowRemovalOfSpecialPermissions}
                            onRolesChange={updateViewModel}
                            preColumns={
                                <GridColumn
                                    key="companyName"
                                    field="companyName"
                                    title="Company"
                                    width={300}
                                    cell={(props) => (
                                        <CustomGridCell gridCellProps={props}>
                                            <Link to={companyRouteHelper.companyDetails(props.dataItem.companyId)}>{props.dataItem.companyName}</Link>
                                        </CustomGridCell>
                                    )}
                                />
                            }
                            postColumns={[
                                <GridColumn key="information" title="Information" width={160} cell={cellHelper.getInformationCell} />,
                                <GridColumn key="lastUpdatedBy" field="lastUpdatedBy" title="Updated By" width={200} />,
                                <GridColumn key="lastUpdatedDatetime" field="lastUpdatedDatetime" title="Updated Date (AWST)" width={250} format={GlobalHelpers.NoptaDatetimeFormatForKendo} />,
                            ]}
                        />
                    )}
                </div>
            </div>
            {hasSpecialAccess() && (
                <Forms.Group>
                    <Forms.Checkbox
                        id="allowRemovalOfSpecialPermissionsField"
                        label="Allow removal of Company Admin or Signer Permissions"
                        checked={allowRemovalOfSpecialPermissions}
                        onChange={(checked) => setAllowRemovalOfSpecialPermissions(checked)}
                        disabled={allowRemovalOfSpecialPermissions}
                        inline
                    />
                </Forms.Group>
            )}
            <button
                className="btn btn-outline-primary me-2"
                type="submit"
                {...(vm.isDirty && hasChanges() ? {} : { disabled: true, title: "No changes have been made to permissions. Click 'Cancel' to exit." })}
            >
                Update Access
            </button>
            <button className="btn btn-outline-secondary me-2" type="button" onClick={onCancelClicked}>
                Cancel
            </button>
            <button className="btn btn-outline-danger me-2" type="button" onClick={onRemoveAllAccessClicked} disabled={hasSpecialAccess() && !allowRemovalOfSpecialPermissions}>
                Remove All Access
            </button>
            {hasPendingRequest() && <div className="text-muted mt-2">Note: Remove All Access will not remove permissions for Companies with a pending Request.</div>}
        </form>
    );
}
