// framework
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Actions from "../redux/actions";
import * as Models from "../models/models";
// api
import { ExternalLinkEnum } from "../../../../../api/Client";
// shell
import BodyLayout from "../../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// views
import HeaderView from "../../../../common/draftApplicationDetails/components/HeaderView";
import GuidanceView from "../../../../common/draftApplicationDetails/components/GuidanceView";
import ApplicationSubmittedView from "../../common/components/ApplicationSubmittedView";
import ApplicationDeletedView from "../../../common/ApplicationDeletedView";
import TabsView from "./views/TabsView";
// common
import ConflictView from "../../../../../common/alerts/ConflictAlertView";
import StatusMessagesAlertsView from "../../../../../common/alerts/StatusMessagesAlertsView";
import * as ConfigurationHelper from "../../../../../common/shell/configurationHelper";
import { ApplicationViewStateEnum } from "../../../../common/draftApplicationDetails/components/models/models";
import * as ViewHelpers from "../../../../common/draftApplicationDetails/ViewHelpers";

interface IControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

interface IRouteParameters {
    id: string;
}

function Controller(props: IControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;
    const params = useParams<IRouteParameters>();

    const petroleumGuidanceLink = ConfigurationHelper.usePortalLink(ExternalLinkEnum.NoptaFormsGuidancePetroleum);
    const ghgGuidanceLink = ConfigurationHelper.usePortalLink(ExternalLinkEnum.NoptaFormsGuidanceGreenhouseGas);

    // this is used to trigger smart components to refresh when the refresh button is manually pressed
    const [lastRefreshedDatetime, setLastRefreshedDatetime] = useState<Date | undefined>(undefined);

    // on mount
    // - initial load
    const id = parseInt(params.id);
    useEffect(() => {
        actions.initialiseRequested(id);
        return function () {
            actions.clearRequested();
        };
    }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

    const pageTitle = vm.header.trackingNumber ? `Change of Company Name (${vm.header.trackingNumber})` : "Change of Company Name";
    const state = vm.state;
    const viewState = state.viewState;
    const onReload = ViewHelpers.canReload(viewState)
        ? () => {
              actions.initialiseRequested(id);
              setLastRefreshedDatetime(new Date());
          }
        : undefined;

    // return
    return (
        <BodyLayout title={pageTitle} selectedLhsNavItem={Level1ItemTypeEnum.Company_Application_DraftApplicationList} onReloadRequested={onReload}>
            <ConflictView isConflict={state.isConflict} />
            <StatusMessagesAlertsView statusMessages={state.statusMessages} />
            {viewState === ApplicationViewStateEnum.Submitted && <ApplicationSubmittedView />}
            {viewState === ApplicationViewStateEnum.Deleted && <ApplicationDeletedView />}
            {viewState !== undefined && viewState !== ApplicationViewStateEnum.Deleted && (
                <>
                    <HeaderView vm={vm} />
                    <GuidanceView petroleumGuidanceLink={petroleumGuidanceLink} ghgGuidanceLink={ghgGuidanceLink} />
                    <TabsView vm={vm} actions={actions} lastRefreshedDatetime={lastRefreshedDatetime} />
                </>
            )}
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_Opggs_DraftApplicationDetails }), Actions.actionFactory)(Controller);
