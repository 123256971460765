import { takeEvery } from "redux-saga/effects";
import * as Actions from "./actions";

import initialise from "./sagas/initialise";
import savePermissions from "./sagas/savePermissions";
import removeUser from "./sagas/removeUser";

export default function* watcherSaga() {
    yield takeEvery(Actions.actions.initialiseRequested, (action) => initialise(action as Actions.IAction<number>));
    yield takeEvery(Actions.actions.savePermissionsRequested, savePermissions);
    yield takeEvery(Actions.actions.removeUserRequested, removeUser);
}
