// framework
import { call, put, select } from "redux-saga/effects";
// redux
import { ICompanyReduxState } from "../../../../companyReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/rootViewModel";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";
import * as ConfigurationHelper from "../../../../../common/shell/configurationHelper";

const getRootViewModel = (state: ICompanyReduxState) => state.Company_UserManagement_CompanyDetails;

export default function* initialise(action: Actions.IAction<number>) {
    const companyId = action.payload;

    // save role reference data to the vm
    let vm: Models.IRootViewModel = yield select(getRootViewModel);
    vm = vm.refreshRoleConfiguration(yield select(ConfigurationHelper.getRoleConfiguration));
    yield put(Actions.actionFactory.sagaCompleted(vm));

    // load company details
    try {
        yield put(shellHelper.createSetBusyAction());

        const response: Client.GetCompanyUserManagementCompanyDetailsResponseDto = yield call(getCompanyDetails, companyId);
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshCompanyDetails(response, true)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
export async function getCompanyDetails(companyId: number): Promise<Client.GetCompanyUserManagementCompanyDetailsResponseDto> {
    const request = new Client.GetCompanyUserManagementCompanyDetailsRequestDto({ companyId: companyId });
    const client = await ClientFactory.createCompanyUserManagementClient();
    return await CallHelper.simpleCall(() => client.getCompanyUserManagementCompanyDetails(request));
}
