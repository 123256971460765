// framework
import { Link } from "react-router-dom";
import { ShieldIcon, CpuIcon, TelescopeIcon, PlugIcon, LockIcon } from "@primer/octicons-react";
// shell
import BodyLayoutController from "../../common/shell/BodyLayoutController";
import SignInAction from "../../shell/signIn/SignInActionController";
// home
import * as homeRouteHelper from "../../home/homeRouteHelper";

// this still needs more refactoring, but will leave at this for now!

export function ConflictController(): React.ReactElement {
    return render(
        "Conflict",
        <ShieldIcon size={96} className="me-4" aria-label="conflict" />,
        <>
            <h1 className="d-inline-block">The requested resource has been changed by another user or process.</h1>
            <p className="mt-4">If the problem persists, contact support for assistance.</p>
            <p>
                <button
                    className="btn btn-link p-0"
                    onClick={(e) => {
                        e.preventDefault();
                        window.history.back();
                    }}
                >
                    Go Back
                </button>{" "}
                | <a href="/">Return Home</a>
            </p>
        </>
    );
}

export function InternalServerErrorController(): React.ReactElement {
    return render(
        "Internal Server Error",
        <CpuIcon size={96} className="me-4" aria-label="internal server error" />,
        <>
            <h1 className="d-inline-block">An unexpected error has occurred on the server.</h1>
            <p className="mt-4">If the problem persists, please contact support for assistance.</p>
            <p>
                <button
                    className="btn btn-link p-0"
                    onClick={(e) => {
                        e.preventDefault();
                        window.history.back();
                    }}
                >
                    Go Back
                </button>{" "}
                | <a href="/">Return Home</a>
            </p>
        </>
    );
}

export function NotFoundController(): React.ReactElement {
    return render(
        "Not Found",
        <TelescopeIcon size={96} className="me-4" aria-label="not found" />,
        <>
            <h1 className="d-inline-block">The requested page or resource cannot be found.</h1>
            <p className="mt-4">Please check your link. If the problem persists, contact support for assistance.</p>
            <p>
                <button
                    className="btn btn-link p-0"
                    onClick={(e) => {
                        e.preventDefault();
                        window.history.back();
                    }}
                >
                    Go Back
                </button>{" "}
                | <a href="/">Return Home</a>
            </p>
        </>
    );
}

export function ServiceUnavailableController(): React.ReactElement {
    return render(
        "Services Unavailable",
        <PlugIcon size={96} className="me-4" aria-label="network issue" />,
        <>
            <h1 className="d-inline-block">The application services are currently offline.</h1>
            <p className="mt-4">If the problem persists, contact support for assistance.</p>
            <p>
                <button
                    className="btn btn-link p-0"
                    onClick={(e) => {
                        e.preventDefault();
                        window.history.back();
                    }}
                >
                    Go Back
                </button>{" "}
                | <a href="/">Return Home</a>
            </p>
        </>
    );
}

export function UnauthorisedController(): React.ReactElement {
    return render(
        "Unauthorised",
        <LockIcon size={96} className="me-4" aria-label="unauthorised" />,
        <>
            <h1 className="d-inline-block mb-4">You are not authorised to view this page.</h1>

            <div className="mb-4">
                <div className="mb-1">You will need to sign-in to access the requested page.</div>
                <SignInAction />
            </div>

            <div className="mb-4">
                If you are having trouble signing in, please visit the <Link to={homeRouteHelper.signIn()}>Sign In</Link> page.{" "}
            </div>

            <p className="mb-4">If the problem persists, contact support for assistance.</p>
            <p>
                <button
                    className="btn btn-link p-0"
                    onClick={(e) => {
                        e.preventDefault();
                        window.history.back();
                    }}
                >
                    Go Back
                </button>{" "}
                | <a href="/">Return Home</a>
            </p>
        </>
    );
}

function render(title: string, icon: React.ReactElement, content: React.ReactElement) {
    return (
        <BodyLayoutController title={title} showPageHeader={false}>
            <div className="p-5 mb-4 bg-light rounded-3">
                <div className="container-fluid py-5">
                    <div className="row">
                        <div className="col-2" style={{ minWidth: 100 }}>
                            {icon}
                        </div>
                        <div className="col-10">{content}</div>
                    </div>
                </div>
            </div>
        </BodyLayoutController>
    );
}
