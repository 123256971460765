// framework
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
// models
import { IRootViewModel } from "../../models/models";
// common
import * as GeoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
import CommentSummaryView from "../../../../../../common/commentSummary/commentSummaryView";
// api
import * as Client from "../../../../../../api/Client";

export default function ReportListGridView(props: { vm: IRootViewModel; onDelete: (activityId: number, activityType: Client.ReportRelatedActivityTypeEnum) => void }): JSX.Element {
    const { vm, onDelete } = props;

    const initialState: State = {
        sort: [{ field: "activityId", dir: "desc" }],
        filter: { logic: "and", filters: [] },
    };

    const [gridState, setGridState] = useState(initialState);

    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [vm.RelatedActivities]); // eslint-disable-line react-hooks/exhaustive-deps

    function getRelatedActivityDetailsTooltip(reportRelatedActivityType: Client.ReportRelatedActivityTypeEnum): string {
        let reportRelatedActivityTypeName = "";

        // Decided to have a switch statement to map the enum values to a reportRelatedActivityTypeName instead of just using the enum name so that any
        // future refactoring of the enum names doesn't affect the tooltip string.
        switch (reportRelatedActivityType) {
            case Client.ReportRelatedActivityTypeEnum.Borehole:
                reportRelatedActivityTypeName = "Borehole";
                break;

            case Client.ReportRelatedActivityTypeEnum.Acquisition:
                reportRelatedActivityTypeName = "Acquisition";
                break;

            case Client.ReportRelatedActivityTypeEnum.Project:
                reportRelatedActivityTypeName = "Project";
                break;

            default:
                reportRelatedActivityTypeName = "Unknown";
        }
        return `Click to view ${reportRelatedActivityTypeName} Details.`;
    }

    function getRelatedActivityDetailsUrl(activity: Client.GetGeoscienceAustraliaReportRelatedActivityDto): string {
        switch (activity.reportRelatedActivityType) {
            case Client.ReportRelatedActivityTypeEnum.Borehole:
                return GeoscienceAustraliaRouteHelper.boreholeDetails(activity.boreholeDetails!.wellId, activity.id);

            case Client.ReportRelatedActivityTypeEnum.Acquisition:
                return GeoscienceAustraliaRouteHelper.acquisitionDetails(activity.acquisitionDetails!.surveyId, activity.id);

            case Client.ReportRelatedActivityTypeEnum.Project:
                return GeoscienceAustraliaRouteHelper.reprocessingProjectDetails(activity.projectDetails!.reprocessingId, activity.id);
        }
    }

    return (
        <>
            <Grid
                resizable
                data={process(vm.RelatedActivities, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn field="activityId" title="Activity ID" width={240} />
                <GridColumn field="reportRelatedActivityType" title="Activity Type" width={180} />
                <GridColumn
                    field="activityName"
                    title="Activity Name"
                    width={700}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <Link to={getRelatedActivityDetailsUrl(props.dataItem)} title={getRelatedActivityDetailsTooltip(props.dataItem.reportRelatedActivityType)}>
                                {props.dataItem.activityName}
                            </Link>
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    title="Comments"
                    width={300}
                    sortable={false}
                    field="comments"
                    filter="text"
                    columnMenu={createGridColumnFilterMenu}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <CommentSummaryView comment={props.dataItem.comments} maxSummaryLength={20} title="Comments"></CommentSummaryView>
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    title="Unlink"
                    width={190}
                    sortable={false}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <button
                                className="btn btn-secondary btn-sm"
                                type="button"
                                title="Click to Unlink Activity."
                                onClick={() => {
                                    onDelete(props.dataItem.id, props.dataItem.reportRelatedActivityType);
                                }}
                            >
                                Unlink
                            </button>
                        </CustomGridCell>
                    )}
                />
            </Grid>
        </>
    );
}
