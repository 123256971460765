// api
import * as Client from "../../../../api/Client";
// models
import { BaseRoleViewModel, IRoleViewModel } from "../../../../common/roleGrid/RoleViewModel";
import { ICompanyRoleInformationViewModel } from "../../common/permissionCellHelper";

export interface IUserDetailsRoleViewModel extends IRoleViewModel, ICompanyRoleInformationViewModel {
    secureUserId: number;
    secureUserVersionNumber: number;
    companyId: number;
    companyName?: string | undefined;
    isSecureUserSignedIn: boolean;
    hasCompanyMembership: boolean;
    lastUpdatedBy?: string | undefined;
    lastUpdatedDatetime?: Date | undefined;
}

export class UserDetailsRoleViewModel extends BaseRoleViewModel implements IUserDetailsRoleViewModel {
    constructor(roleRelationships: Array<Client.GetShellSecureUserRoleConfigurationRoleRelationshipDto>, data: Client.ICompanySecureUserCompanyRoleDto) {
        super(roleRelationships, data.activeRoles);

        this.secureUserId = data.secureUserId;
        this.secureUserVersionNumber = data.secureUserVersionNumber;
        this.companyId = data.companyId;
        this.companyName = data.companyName;
        this.isSecureUserSignedIn = data.isSecureUserSignedIn;
        this.isSecureUserBlocked = data.isSecureUserBlocked;
        this.hasPendingRequest = data.hasPendingRequest;
        this.hasCompanyMembership = data.hasCompanyMembership;
        this.isCompanyRevokedButVisible = data.isCompanyRevokedButVisible;
        this.lastUpdatedBy = data.lastUpdatedBy;
        this.lastUpdatedDatetime = data.lastUpdatedDatetime;
    }

    public readonly secureUserId: number;
    public readonly secureUserVersionNumber: number;
    public readonly companyId: number;
    public readonly companyName?: string | undefined;
    public readonly isSecureUserSignedIn: boolean;
    public readonly isSecureUserBlocked: boolean;
    public readonly hasPendingRequest: boolean;
    public readonly hasCompanyMembership: boolean;
    public readonly isCompanyRevokedButVisible: boolean;
    public readonly lastUpdatedBy?: string | undefined;
    public readonly lastUpdatedDatetime?: Date | undefined;

    public getKey(): number {
        return this.companyId;
    }

    public canEdit(): boolean {
        return !(this.isSecureUserSignedIn || this.hasPendingRequest);
    }
}
