// framework
import { TitleTypeEnum } from "../../../../../api/Client";
import * as Validation from "../../../../../common/validation/ValidationModel";
// models
import * as Models from "./models";

export default function validate(vm: Models.IIarlApplicationDetailsViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateIarlDetails(vm.iarlDetails, v);
    return v;
}

function validateIarlDetails(vm: Models.IIarlDetailsViewModel, v: Validation.ValidationModel): void {
    // ep
    if (vm.originatingTitleType === TitleTypeEnum.ExplorationPermit) {
        if (!vm.epDetails!.existingLocationId) {
            v.addError("IarlDetails.EpDetails.ExistingLocation", "A selection is required.");
        }
    }

    // pp
    if (vm.originatingTitleType === TitleTypeEnum.ProductionLicence) {
        if (vm.ppDetails!.isCreateNewField === undefined) {
            v.addError("IarlDetails.PpDetails.IsCreateNewField", "A selection is required.");
        }

        if (vm.ppDetails!.isCreateNewField) {
            if (Validation.isNullOrWhiteSpace(vm.ppDetails!.newFieldName)) {
                v.addError("IarlDetails.PpDetails.NewFieldName", "New Field Name is required.");
            } else if (vm.ppDetails!.newFieldName!.length > 100) {
                v.addError("IarlDetails.PpDetails.NewFieldName", "New Field Name must be 100 characters or less.");
            }
        }

        if (!vm.ppDetails!.lastDayOfPetroleumRecoveryOperationsDate) {
            v.addError("IarlDetails.PpDetails.LastDayOfPetroleumRecoveryOperationsDate", "Last Day of petroleum recovery operations is required.");
        } else if (vm.ppDetails!.lastDayOfPetroleumRecoveryOperationsDate < vm.ppDetails!.initialGrantDate!) {
            v.addError("IarlDetails.PpDetails.LastDayOfPetroleumRecoveryOperationsDate", "Last Day of petroleum recovery operations cannot be before the intial grant date.");
        }
    }
}
