// framework
import { useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../infrastructure/reduxRootReducer";
import * as Actions from "./redux/actions";
import { IConfigurationModel } from "./models/models";
// common
import * as shellHelper from "../../common/shell/shellHelper";

interface IControllerProps extends Actions.IActionFactory {
    children: React.ReactNode;
    state: IConfigurationModel;
}

// Static configuration - loaded at application startup, before the user is signed in
// Dynamic configuration - loaded immediately after sign-in, and refreshed on an interval (will consider SignalR in the future)

function ConfigurationController(props: IControllerProps) {
    useEffect(() => {
        console.debug("Updating Static Configuration...");
        props.refreshStaticConfigurationRequested();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isSignedIn = shellHelper.useIsSignedIn();
    useEffect(() => {
        if (isSignedIn) {
            console.debug("Updating Dynamic Configuration...");
            props.refreshDynamicConfigurationRequested(true);

            // once every 5 minutes, poll for updated configuration
            // (considering changing to signalR down the track...)

            const timer = setInterval(() => {
                console.debug("Updating Dynamic Configuration...");
                props.refreshDynamicConfigurationRequested(false);
            }, 300000);

            return function () {
                clearTimeout(timer);
            };
        }
    }, [isSignedIn]); // eslint-disable-line react-hooks/exhaustive-deps

    // NOTE: Blocking of rendering beyond the shell is necessary for as long as reference data is being consumed by sagas.
    // If we can move all consumption of reference data to controllers using hooks then we can remove this blocking mechanism.
    if (!props.state.isStaticConfigurationLoaded) return <></>;
    if (isSignedIn && !props.state.isDynamicConfigurationLoaded) return <></>;
    return <>{props.children}</>;
}

// connect redux
export default connect((state: IRootReduxState) => ({ state: state.Shell_Configuration }), Actions.actionFactory)(ConfigurationController);
