// framework
import { useState } from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// api
import * as Client from "../../../../api/Client";
// other
import * as GlobalHelpers from "../../../../common/GlobalHelpers";
import CustomGridCell from "../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
import { FormListItems } from "../models/models";
import TitleLinkController from "../../../../common/links/TitleLinkController";
import { DashIcon } from "@primer/octicons-react";
import { TitleDetailsTabsEnum } from "../../titleDetails/components/views/TitleDetailsView";
import SecureFileDownloadLinkControl from "../../../../common/secureFileDownload/SecureFileDownloadLinkControl";

export default function LegislativeFormsView(props: { vm: FormListItems }): React.ReactElement {
    const legislativeForms = props.vm ?? new Array<Client.GetCompanyOpggsLegislativeFormsFormListItemDto>();

    function documentDownload(fileId: number) {
        if (!fileId) {
            return <DashIcon />;
        }

        return (
            <span>
                <SecureFileDownloadLinkControl id={fileId}>Download</SecureFileDownloadLinkControl>
            </span>
        );
    }

    const initialGridState: State = {
        sort: [{ field: "relatedCompanyName", dir: "asc" }],
        skip: 0,
        take: 10,
    };
    const [gridState, setGridState] = useState(initialGridState);

    // view
    return (
        <div className="card mb-2">
            <div className="card-body">
                <h3 className="card-title">Legislative Forms</h3>
                <Grid
                    sortable
                    resizable
                    navigatable
                    pageable
                    data={process(legislativeForms, gridState)}
                    {...gridState}
                    onDataStateChange={(e) => {
                        setGridState(e.dataState);
                    }}
                >
                    <GridColumn
                        field="titleLink.titleNumber"
                        title="Title Number"
                        width={350}
                        filter="text"
                        columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, legislativeForms)}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                <TitleLinkController linkDto={props.dataItem.titleLink} tab={TitleDetailsTabsEnum.LegislativeForms} secureEntityType="Company" />
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn field="formTypeId" title="Form" width={100} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, legislativeForms)} />
                    <GridColumn field="relatedTitleholderName" title="Company" width={500} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, legislativeForms)} />
                    <GridColumn
                        field="effectiveFromDate"
                        title="Effective From Date"
                        format={GlobalHelpers.NoptaDateFormatForKendo}
                        width={200}
                        filter="date"
                        columnMenu={createGridColumnFilterMenu}
                    />
                    <GridColumn field="hasDocument" title="Document" width={115} cell={(props) => <CustomGridCell gridCellProps={props}>{documentDownload(props.dataItem.fileId)}</CustomGridCell>} />
                </Grid>
            </div>
        </div>
    );
}
