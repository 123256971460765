// Supporting Documentation
import * as SupportingDocumentationReducers from "./controls/supportingDocumentation/redux/reducers";
import * as SupportingDocumentationModels from "./controls/supportingDocumentation/models/models";
import SupportingDocumentationSaga from "./controls/supportingDocumentation/redux/saga";

// Form Documentation
import * as FormDocumentationReducers from "./controls/formDocumentation/redux/reducers";
import * as FormDocumentationModels from "./controls/formDocumentation/models/models";
import FormDocumentationSaga from "./controls/formDocumentation/redux/saga";

// Signature Type
import * as SignatureTypeReducers from "./controls/signatureType/redux/reducers";
import * as SignatureTypeModels from "./controls/signatureType/models/models";
import SignatureTypeSaga from "./controls/signatureType/redux/saga";

// state
export interface IDraftApplicationDetailsReduxState {
    Company_Common_DraftApplicationDetails_Controls_SupportingDocumentation: SupportingDocumentationModels.IRootViewModel;
    Company_Common_DraftApplicationDetails_Controls_FormDocumentation: FormDocumentationModels.IRootViewModel;
    Company_Common_DraftApplicationDetails_Controls_SignatureType: SignatureTypeModels.IRootViewModel;
}

// reducers
export function buildReducers() {
    return {
        Company_Common_DraftApplicationDetails_Controls_SupportingDocumentation: SupportingDocumentationReducers.reducer,
        Company_Common_DraftApplicationDetails_Controls_FormDocumentation: FormDocumentationReducers.reducer,
        Company_Common_DraftApplicationDetails_Controls_SignatureType: SignatureTypeReducers.reducer,
    };
}

// sagas
export function buildSagas(): Array<any> {
    return [SupportingDocumentationSaga, FormDocumentationSaga, SignatureTypeSaga];
}
