import { call, put, select } from "redux-saga/effects";
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { ICompanyReduxState } from "../../../../companyReduxRegistration";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

const getRootViewModel = (state: ICompanyReduxState) => state.Company_Oei_OeiInvitationToApplyList;

export default function* createDraftApplication() {
    let vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const response: Client.CreateCompanyOeiCreateDraftApplicationResponseDto = yield call(async () => {
            const client = await ClientFactory.createCompanyOeiCreateDraftApplicationClient();
            return await CallHelper.simpleCall(() =>
                client.createCompanyOeiCreateDraftApplication(
                    new Client.CreateCompanyOeiCreateDraftApplicationRequestDto({
                        invitationToApplyId: vm.selectedInvitationToApplyId!,
                        applicantCompanyId: vm.draftApplicationDetails.applicant!.companyId,
                        applicationType: vm.draftApplicationDetails.applicationType!.type,
                    })
                )
            );
        });

        // on both success and error, clear draft application data from vm
        vm = vm.clearDraftApplicationData();
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshCreateDraftApplicationResponse(response.statusMessages, response.id)));
    } catch (ex) {
        // on exception, clear draft application data from vm
        yield put(Actions.actionFactory.sagaCompleted(vm.clearDraftApplicationData()));
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
