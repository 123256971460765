// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    wellId?: number;
    wellName?: string;
    attachments: Client.GetGeoscienceAustraliaReportAttachmentsItemDto[];
    boreholeIdentifiers: Client.GetGeoscienceAustraliaBoreholeIdentifierDto[];
    isConflict: boolean;
    statusMessages?: Client.StatusMessagesDto;

    refreshWellReportAttachments(response: Client.GetGeoscienceAustraliaWellReportAttachmentsResponseDto): IRootViewModel;
    refreshConflict(isConflict: boolean): IRootViewModel;
    refreshStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.attachments = [];
        this.boreholeIdentifiers = [];
        this.isConflict = false;
    }

    public wellId?: number;
    public wellName?: string;
    public attachments: Client.GetGeoscienceAustraliaReportAttachmentsItemDto[];
    public boreholeIdentifiers: Client.GetGeoscienceAustraliaBoreholeIdentifierDto[];
    public isConflict: boolean;
    public statusMessages?: Client.StatusMessagesDto;

    public refreshWellReportAttachments(response: Client.GetGeoscienceAustraliaWellReportAttachmentsResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.wellId = response.wellId;
        vm.wellName = response.wellName;
        vm.attachments = response.attachments;
        vm.boreholeIdentifiers = response.boreholeIdentifiers;
        vm.isConflict = false;
        vm.statusMessages = new Client.StatusMessagesDto();

        return vm;
    }

    public refreshConflict(isConflict: boolean): IRootViewModel {
        const vm = this._clone();

        vm.isConflict = isConflict;

        return vm;
    }

    public refreshStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel {
        const vm = this._clone();

        vm.statusMessages = statusMessages;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.attachments = clone(this.attachments);
        return vm;
    }
}
