// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IJointAuthorityReduxState } from "../../../../jointAuthorityReduxRegistration";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as LogHelper from "../../../../../common/LogHelper";
import * as shellHelper from "../../../../../common/shell/shellHelper";

const getRootViewModel = (state: IJointAuthorityReduxState) => state.JointAuthority_Oei_LicenceList;

export default function* getListRequested() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const response: Client.GetJointAuthorityOeiLicenceListResponseDto = yield call(async () => {
            const client = await ClientFactory.createJointAuthorityOeiLicenceListClient();
            return await CallHelper.simpleCall(() => client.getJointAuthorityOeiLicenceList(new Client.GetJointAuthorityOeiLicenceListRequestDto()));
        });
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshSaga(response)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
