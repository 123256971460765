// redux
import { call, put, select } from "redux-saga/effects";
import { ICompanyReduxState } from "../../../../../../../companyReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../../api/callHelper";
import * as Client from "../../../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../../../common/LogHelper";

interface IResponse {
    generatedDocument: Client.SecurePortalDocumentDto;
}

const getRootViewModel = (state: ICompanyReduxState) => state.Company_Opggs_LegislativeForms_FormRequestDetails_Controls_FormDocumentation;

export default function* generateDocument(action: Actions.IAction<Actions.IFormRequestDetailsKeyPayload>) {
    const detailsKey = action.payload;

    try {
        yield put(shellHelper.createSetBusyAction());

        const response: IResponse = yield call(generateFormRequestDocument, detailsKey.id, detailsKey.type);
        const vm: Models.IRootViewModel = yield select(getRootViewModel);

        yield put(Actions.actionFactory.sagaCompleted(vm.refreshGeneratedDocument(response.generatedDocument)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function generateFormRequestDocument(formRequestId: number, formRequestType: Client.LegislativeFormTypeEnum): Promise<IResponse> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();

    switch (formRequestType) {
        case Client.LegislativeFormTypeEnum.Form2:
            const form2Request = new Client.GenerateCompanyOpggsLegislativeFormsForm2RequestDocumentRequestDto({ id: formRequestId });
            return await CallHelper.simpleCall(() => client.generateCompanyOpggsLegislativeFormsForm2RequestDocument(form2Request));
        case Client.LegislativeFormTypeEnum.Form3:
            const form3Request = new Client.GenerateCompanyOpggsLegislativeFormsForm3RequestDocumentRequestDto({ id: formRequestId });
            return await CallHelper.simpleCall(() => client.generateCompanyOpggsLegislativeFormsForm3RequestDocument(form3Request));
        case Client.LegislativeFormTypeEnum.Form4:
            const form4Request = new Client.GenerateCompanyOpggsLegislativeFormsForm4RequestDocumentRequestDto({ id: formRequestId });
            return await CallHelper.simpleCall(() => client.generateCompanyOpggsLegislativeFormsForm4RequestDocument(form4Request));
        case Client.LegislativeFormTypeEnum.Form5:
            const form5Request = new Client.GenerateCompanyOpggsLegislativeFormsForm5RequestDocumentRequestDto({ id: formRequestId });
            return await CallHelper.simpleCall(() => client.generateCompanyOpggsLegislativeFormsForm5RequestDocument(form5Request));
        case Client.LegislativeFormTypeEnum.Form6:
            const form6Request = new Client.GenerateCompanyOpggsLegislativeFormsForm6RequestDocumentRequestDto({ id: formRequestId });
            return await CallHelper.simpleCall(() => client.generateCompanyOpggsLegislativeFormsForm6RequestDocument(form6Request));
        case Client.LegislativeFormTypeEnum.Form7:
            const form7Request = new Client.GenerateCompanyOpggsLegislativeFormsForm7RequestDocumentRequestDto({ id: formRequestId });
            return await CallHelper.simpleCall(() => client.generateCompanyOpggsLegislativeFormsForm7RequestDocument(form7Request));
        default:
            throw new Error(`Unsupported Legislative Form '${formRequestType}'.`);
    }
}
