// models
import { IRootViewModel } from "../../models/models";
// api
import { NopimsTitleTypeEnum } from "../../../../../../api/Client";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import SimpleAuditEventsView from "../../../../../../common/audit/SimpleAuditEventsView";

interface IProps {
    vm: IRootViewModel;
}

export default function wellView(props: IProps): JSX.Element {
    const { vm } = props;

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">General</h2>
                    <Forms.Row>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField label="UWI" id="uwiField" value={vm.well?.uwi} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField label="Name" id="nameField" value={vm.well?.name} />
                        </Forms.QuarterColumn>
                        {/* NOTE: Temporarily hiding Well Status (see 9549 for details) */}
                        {/*
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="statusField" label="Status" value={vm.well?.wellStatus} />
                        </Forms.QuarterColumn>
                        */}
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField label="Confidentiality" id="confidentialityField" value={GlobalHelpers.convertBooleanToConfidentialityValue(vm.well?.isConfidential)} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn></Forms.QuarterColumn> {/* TODO: Remove this when Well status comes back */}
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="jurisdictionField" label="Jurisdiction" value={vm.well?.nopimsJurisdiction} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="legislationField" label="Legislation" value={vm.well?.legislation} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="spudDateField" label="Spud Date" value={GlobalHelpers.toNoptaDateString(vm.well?.spudDate)} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn></Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="nopimsTitleType" label="Title Type" value={vm.well?.nopimsTitleTypeName} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            {vm.well?.nopimsTitleType === NopimsTitleTypeEnum.Nopta && <Forms.ReadonlyField id="titleField" label="Title" value={vm.well?.titleNumber} />}
                            {vm.well?.nopimsTitleType === NopimsTitleTypeEnum.Other && <Forms.ReadonlyField id="titleField" label="Title" value={vm.well?.otherTitleNumber} />}
                        </Forms.QuarterColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="titleholderField" label="Titleholder" value={vm.well?.titleholderCompany} />
                        </Forms.HalfColumn>
                        <Forms.FullColumn>
                            <Forms.ReadonlyTextArea id="commentsField" label="Comments" value={vm.well?.comments} />
                        </Forms.FullColumn>
                    </Forms.Row>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Spatial</h2>
                    <Forms.Row>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="isOffshoreField" label="Is Offshore" value={GlobalHelpers.convertBooleanToStringValue(vm.well?.isOffshore)} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="waterDepthField" label="Water Depth (m)" value={vm.well?.waterDepthMeters?.toLocaleString()} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="groundElevationField" label="Ground Elevation (m)" value={vm.well?.groundElevationMeters?.toString()} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="permanentDatumField" label="Permanent Datum" value={vm.well?.permanentDatum} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="latitudeField" label="Latitude" value={GlobalHelpers.toNoptaNumberString(vm.well?.latitude, "n8")} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="longitudeField" label="Longitude" value={GlobalHelpers.toNoptaNumberString(vm.well?.longitude, "n8")} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="spatialDatumField" label="Spatial Datum" value={vm.well?.spatialDatum} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn></Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="regionField" label="Region" value={vm.well?.stateTerritory} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="basinField" label="Basin" value={vm.well?.basin} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="subBasinField" label="Sub Basin" value={vm.well?.subBasin} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="fieldField" label="Field" value={vm.well?.field} />
                        </Forms.QuarterColumn>
                    </Forms.Row>
                </div>
            </div>
            <SimpleAuditEventsView simpleAuditEvents={vm.well?.wellAudit} />
        </>
    );
}
