// framework
import { clone, cloneDeep } from "lodash";
// api
import * as Client from "../../../../api/Client";
// redux
import { IRequestDetailsRoleViewModel, RequestDetailsRoleViewModel } from "./requestDetailsRoleViewModel";
// other
import { RoleCategoryEnum } from "../../../../common/roleGrid/RoleViewModel";
import { BaseJointAuthorityRoleRootViewModel, IJointAuthorityRoleRootViewModel } from "../../../common/JointAuthorityRoleRootViewModel";

export interface ISecureRequestRejectionsDetailsViewModel {
    isSuspicious?: boolean | undefined;
    comments?: string | undefined;
}

export interface ISecureRequestJointAuthorityStandardRoleDecisionViewModel {
    isApproved?: boolean | undefined;
}

export interface ISecureRequestJointAuthoritySignerDecisionViewModel {
    isApproved?: boolean | undefined;
    isIdentificationAndVerificationConfirmed?: boolean | undefined;
    isRoleConfirmed?: boolean | undefined;
    isAuthorityConfirmed?: boolean | undefined;
    isElectronicSignatureConfirmed?: boolean | undefined;
    isPoaValidityConfirmed?: boolean | undefined;
}

export interface ISecureRequestJointAuthorityStandardRoleDetailsViewModel extends ISecureRequestJointAuthorityStandardRoleDecisionViewModel {
    existingRoles?: IRequestDetailsRoleViewModel;
    requestedRoles?: IRequestDetailsRoleViewModel;
    grantedRoles?: IRequestDetailsRoleViewModel;
    hasAllGeneralRoles?: boolean;
}

export interface ISecureRequestJointAuthoritySignerDetailsViewModel extends ISecureRequestJointAuthoritySignerDecisionViewModel {
    isJointAuthoritySignerPoaRequest?: boolean;
    jasAuthoritySupportingDocument?: Client.SupportingDocumentDto | undefined;
    jasPoaSupportingDocument?: Client.SupportingDocumentDto | undefined;
    downloadedDocument?: Client.SecurePortalDocumentDto | undefined;
}

export interface IRootViewModel extends IJointAuthorityRoleRootViewModel {
    id?: number;
    versionNumber?: number;
    isNewUserRequest?: boolean;
    isJointAuthorityStandardRoleRequest?: boolean;
    isJointAuthoritySignerRequest?: boolean;

    summary: Client.JointAuthorityUserManagementRequestDetailsSummaryDto;
    newUserDetails?: Client.JointAuthorityUserManagementRequestDetailsNewUserDetailsDto;
    existingUserDetails?: Client.JointAuthorityUserManagementRequestDetailsExistingUserDetailsDto;
    jointAuthorityStandardRoleDetails: ISecureRequestJointAuthorityStandardRoleDetailsViewModel;
    jointAuthoritySignerDetails: ISecureRequestJointAuthoritySignerDetailsViewModel;
    rejectionDetails: ISecureRequestRejectionsDetailsViewModel;

    service: {
        statusMessages?: Client.StatusMessagesDto;
    };

    isDirty: boolean;
    isConflict: boolean;

    refreshRequestDetails(
        summary: Client.JointAuthorityUserManagementRequestDetailsSummaryDto,
        newUserDetails?: Client.JointAuthorityUserManagementRequestDetailsNewUserDetailsDto | undefined,
        existingUserDetails?: Client.JointAuthorityUserManagementRequestDetailsExistingUserDetailsDto | undefined,
        jointAuthorityStandardRoleDetails?: Client.JointAuthorityUserManagementRequestDetailsStandardRoleDetailsDto | undefined
        //jointAuthoritySignerDetails?: Client.JointAuthorityUserManagementRequestDetailsSignerDetailsDto | undefined
    ): IRootViewModel;
    refreshApproveRejectRequestGroupResponse(statusMessages: Client.StatusMessagesDto | undefined): IRootViewModel;
    refreshConflict(): IRootViewModel;
    // view triggered state changes
    refreshJointAuthorityStandardRoleDecisionChanged(jointAuthorityStandardRoleDecision: ISecureRequestJointAuthorityStandardRoleDecisionViewModel): IRootViewModel;
    refreshJointAuthorityStandardRolesGrantedChanged(values: IRequestDetailsRoleViewModel): IRootViewModel;
    refreshJointAuthoritySignerDecisionChanged(jointAuthoritySignerDecision: ISecureRequestJointAuthoritySignerDecisionViewModel): IRootViewModel;
    refreshDownloadedDocument(response: Client.SecurePortalDocumentDto): IRootViewModel;
    refreshRejectionDetailsChanged(values: ISecureRequestRejectionsDetailsViewModel): IRootViewModel;
    clearDownloadedDocument(): IRootViewModel;
}

export class RootViewModel extends BaseJointAuthorityRoleRootViewModel implements IRootViewModel {
    constructor() {
        super();

        // initial state
        this.id = undefined;
        this.versionNumber = undefined;
        this.isNewUserRequest = undefined;
        this.isJointAuthorityStandardRoleRequest = undefined;
        this.isJointAuthoritySignerRequest = undefined;

        this.summary = new Client.JointAuthorityUserManagementRequestDetailsSummaryDto();
        this.newUserDetails = undefined;
        this.existingUserDetails = undefined;
        this.jointAuthorityStandardRoleDetails = {};
        this.jointAuthoritySignerDetails = {};
        this.rejectionDetails = {};

        this.service = { statusMessages: undefined };

        this.isDirty = false;
        this.isConflict = false;
    }

    public id: number | undefined;
    public versionNumber: number | undefined;
    public isNewUserRequest: boolean | undefined;
    public isJointAuthorityStandardRoleRequest: boolean | undefined;
    public isJointAuthoritySignerRequest: boolean | undefined;

    public summary: Client.JointAuthorityUserManagementRequestDetailsSummaryDto;
    public newUserDetails: Client.JointAuthorityUserManagementRequestDetailsNewUserDetailsDto | undefined;
    public existingUserDetails: Client.JointAuthorityUserManagementRequestDetailsExistingUserDetailsDto | undefined;
    public jointAuthorityStandardRoleDetails: ISecureRequestJointAuthorityStandardRoleDetailsViewModel;
    public jointAuthoritySignerDetails: ISecureRequestJointAuthoritySignerDetailsViewModel;
    public rejectionDetails: ISecureRequestRejectionsDetailsViewModel;

    public service: { statusMessages?: Client.StatusMessagesDto };

    public isDirty: boolean;
    public isConflict: boolean;

    public refreshRequestDetails(
        summary: Client.JointAuthorityUserManagementRequestDetailsSummaryDto,
        newUserDetails?: Client.JointAuthorityUserManagementRequestDetailsNewUserDetailsDto | undefined,
        existingUserDetails?: Client.JointAuthorityUserManagementRequestDetailsExistingUserDetailsDto | undefined,
        jointAuthorityStandardRoleDetails?: Client.JointAuthorityUserManagementRequestDetailsStandardRoleDetailsDto | undefined
        //jointAuthoritySignerDetails?: Client.JointAuthorityUserManagementRequestDetailsSignerDetailsDto | undefined
    ): IRootViewModel {
        const vm = this._clone();

        vm.id = summary.id;
        vm.versionNumber = summary.versionNumber;
        vm.isNewUserRequest = summary.isNewUserRequest;
        vm.isJointAuthorityStandardRoleRequest = summary.isJointAuthorityStandardRoleRequest;
        vm.isJointAuthoritySignerRequest = summary.isJointAuthoritySignerRequest;

        vm.summary = summary;
        vm.newUserDetails = newUserDetails;
        vm.existingUserDetails = existingUserDetails;

        // standard role details
        // - reset
        vm.jointAuthorityStandardRoleDetails = {};
        // refresh and set default selections - note this will need to be expanded if there is a future requirement to show completed requests
        if (jointAuthorityStandardRoleDetails) {
            const existingRoles = new RequestDetailsRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Existing, jointAuthorityStandardRoleDetails.rolesExisting);
            const requestedRoles = new RequestDetailsRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Requested, jointAuthorityStandardRoleDetails.rolesRequested);
            const defaultGrantedRoles = requestedRoles.getDirectRoles().filter((r) => !existingRoles.hasRole(r));
            const grantedRoles = new RequestDetailsRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Granted, defaultGrantedRoles);
            vm.jointAuthorityStandardRoleDetails.existingRoles = existingRoles;
            vm.jointAuthorityStandardRoleDetails.requestedRoles = requestedRoles;
            vm.jointAuthorityStandardRoleDetails.grantedRoles = grantedRoles;
            vm.jointAuthorityStandardRoleDetails.hasAllGeneralRoles = jointAuthorityStandardRoleDetails.hasAllGeneralRoles;
        }

        // signer details
        // - reset
        vm.jointAuthoritySignerDetails = {};
        // - refresh
        // todo nduja
        // if (jointAuthoritySignerDetails) {
        //     vm.jointAuthoritySignerDetails.isJointAuthoritySignerPoaRequest = jointAuthoritySignerDetails.isJointAuthoritySignerPoaRequest;
        //     vm.jointAuthoritySignerDetails.jasAuthoritySupportingDocument = jointAuthoritySignerDetails.jasAuthoritySupportingDocument;
        //     vm.jointAuthoritySignerDetails.jasPoaSupportingDocument = jointAuthoritySignerDetails.jasPoaSupportingDocument;
        // }

        // rejection details
        // - reset
        vm.rejectionDetails = {};

        // reset state - on a successful approve / reject we redirect to the request list i.e. refreshRequestDetails is only called on initialise and we always want to reset state
        vm.service = { statusMessages: undefined };
        vm.isDirty = false;
        vm.isConflict = false;

        return vm;
    }

    public refreshApproveRejectRequestGroupResponse(statusMessages: Client.StatusMessagesDto | undefined): IRootViewModel {
        const vm = this._clone();
        const isSuccess = statusMessages?.isSuccess;

        vm.service.statusMessages = statusMessages;
        // on success set the vm as clean, otherwise leave dirty
        vm.isDirty = !isSuccess;
        vm.isConflict = false;

        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        vm.service.statusMessages = undefined;
        return vm;
    }

    refreshJointAuthorityStandardRoleDecisionChanged(values: ISecureRequestJointAuthorityStandardRoleDecisionViewModel): IRootViewModel {
        const vm = this._clone();

        vm.jointAuthorityStandardRoleDetails = { ...vm.jointAuthorityStandardRoleDetails, ...values };

        // clear reject-specific fields if request is approved
        if (vm.jointAuthorityStandardRoleDetails.isApproved === true) {
            vm.rejectionDetails = {};
        }
        // clear all roles if request is rejected
        else if (vm.jointAuthorityStandardRoleDetails.isApproved === false) {
            vm.jointAuthorityStandardRoleDetails.grantedRoles = new RequestDetailsRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Granted);
        }

        vm.isDirty = true;
        return vm;
    }

    refreshJointAuthorityStandardRolesGrantedChanged(values: IRequestDetailsRoleViewModel): IRootViewModel {
        const vm = this._clone();

        vm.jointAuthorityStandardRoleDetails.grantedRoles = values;

        vm.isDirty = true;
        return vm;
    }

    refreshJointAuthoritySignerDecisionChanged(values: ISecureRequestJointAuthoritySignerDecisionViewModel): IRootViewModel {
        const vm = this._clone();

        vm.jointAuthoritySignerDetails = { ...vm.jointAuthoritySignerDetails, ...values };

        // clear reject-specific fields if request is approved
        if (vm.jointAuthoritySignerDetails.isApproved === true) {
            vm.rejectionDetails = {};
        }

        vm.isDirty = true;

        return vm;
    }

    refreshRejectionDetailsChanged(values: ISecureRequestRejectionsDetailsViewModel): IRootViewModel {
        const vm = this._clone();

        vm.rejectionDetails = values;

        vm.isDirty = true;
        return vm;
    }

    public refreshDownloadedDocument(response: Client.SecurePortalDocumentDto): IRootViewModel {
        const vm = this._clone();

        vm.jointAuthoritySignerDetails.downloadedDocument = response;

        return vm;
    }

    public clearDownloadedDocument(): IRootViewModel {
        const vm = this._clone();

        vm.jointAuthoritySignerDetails.downloadedDocument = undefined;

        return vm;
    }

    // private implementation
    // ----------------------
    private _clone(): RootViewModel {
        const vm = clone(this);

        vm.summary = clone(this.summary);
        vm.newUserDetails = clone(this.newUserDetails);
        vm.existingUserDetails = clone(this.existingUserDetails);
        vm.jointAuthorityStandardRoleDetails = cloneDeep(this.jointAuthorityStandardRoleDetails);
        vm.jointAuthoritySignerDetails = cloneDeep(this.jointAuthoritySignerDetails);
        vm.rejectionDetails = clone(this.rejectionDetails);
        vm.service = clone(this.service);

        return vm;
    }
}
