// framework
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
// models
import { ISurveyDataItemViewModel, IRootViewModel, ViewStateEnum } from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as Client from "../../../../../../api/Client";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import * as GeoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
import CommentSummaryView from "../../../../../../common/commentSummary/commentSummaryView";

export default function SurveyDataListGridView(props: { vm: IRootViewModel; surveyDataUpdated: (item: ISurveyDataItemViewModel) => void }): JSX.Element {
    const { vm, surveyDataUpdated } = props;

    const [surveyDataItemsState, setSurveyDataItemsState] = useState(new Array<ISurveyDataItemViewModel>());

    const initialState: State = {
        sort: [
            { field: "surveyAcquisitionDescriptions", dir: "asc" },
            { field: "receivedDate", dir: "desc" },
        ],
        filter: { logic: "and", filters: [] },
        skip: 0,
        take: 20,
    };

    const [gridState, setGridState] = useState(initialState);

    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [vm.surveyData]); // eslint-disable-line react-hooks/exhaustive-deps

    function onCheckedChanged(item: ISurveyDataItemViewModel) {
        const updatedSurveyDataItemsState = surveyDataItemsState.map((r) => {
            if (r.id !== item.id) return r;
            return { ...r, isSelected: r.isSelected };
        });

        setSurveyDataItemsState(updatedSurveyDataItemsState);
        surveyDataUpdated(item);
    }

    useEffect(() => {
        switch (vm.viewState) {
            case ViewStateEnum.View:
                setSurveyDataItemsState(vm.surveyData);
                setGridState(gridState);
                break;
            case ViewStateEnum.SelectBatchUpdate:
                setSurveyDataItemsState(vm.surveyData);
                setGridState(gridState);
                break;
            case ViewStateEnum.EditBatchUpdate:
                setSurveyDataItemsState(vm.surveyData.filter((item) => item.isSelected));
                break;
        }
    }, [vm.viewState, vm.surveyData, gridState]);

    const rowRender = (trElement: any, props: any) => {
        const failedStyle = {
            backgroundColor: "#F8D7DA",
        };
        const trProps = {
            style: props.dataItem.updateFailed ? failedStyle : undefined,
        };
        return React.cloneElement(
            trElement,
            {
                ...trProps,
            },
            trElement.props.children
        );
    };

    return (
        <>
            <Grid
                pageable
                sortable={vm.viewState === ViewStateEnum.EditBatchUpdate ? false : true}
                resizable
                navigatable
                rowRender={rowRender}
                data={process(surveyDataItemsState, vm.viewState === ViewStateEnum.EditBatchUpdate ? initialState : gridState)}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
                {...gridState}
            >
                {vm.viewState === ViewStateEnum.SelectBatchUpdate && (
                    <GridColumn
                        width={32}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                <input type="checkbox" className={Forms.CheckInputClass} checked={props.dataItem.isSelected} onChange={() => onCheckedChanged(props.dataItem)} />
                            </CustomGridCell>
                        )}
                    />
                )}

                {vm.viewState === ViewStateEnum.EditBatchUpdate ? (
                    <GridColumn
                        field="surveyAcquisitionDescriptions"
                        title="Acquisition(s)"
                        width={240}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                {props.dataItem.surveyAcquisitions.map((acquisition: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySurveyDataSurveyAcquisitionItemDto, index: number) => (
                                    <span key={acquisition.id}>
                                        <Link to={GeoscienceAustraliaRouteHelper.acquisitionSurveyData(vm.surveyId!, acquisition.id)} title="Click to view Acquisition Survey Data.">
                                            {acquisition.name}
                                        </Link>
                                        <span>{index === props.dataItem.surveyAcquisitions.length - 1 ? <></> : ", "}</span>
                                    </span>
                                ))}
                            </CustomGridCell>
                        )}
                    />
                ) : (
                    <GridColumn
                        field="surveyAcquisitionDescriptions"
                        title="Acquisition(s)"
                        width={240}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                {props.dataItem.surveyAcquisitions.map((acquisition: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySurveyDataSurveyAcquisitionItemDto, index: number) => (
                                    <span key={acquisition.id}>
                                        <Link to={GeoscienceAustraliaRouteHelper.acquisitionSurveyData(vm.surveyId!, acquisition.id)} title="Click to view Acquisition Survey Data.">
                                            {acquisition.name}
                                        </Link>
                                        <span>{index === props.dataItem.surveyAcquisitions.length - 1 ? <></> : ", "}</span>
                                    </span>
                                ))}
                            </CustomGridCell>
                        )}
                        filter="text"
                        columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.surveyData)}
                    />
                )}

                <GridColumn
                    field="itemId"
                    title="Item ID"
                    width={140}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <Link to={GeoscienceAustraliaRouteHelper.surveyDataDetails(props.dataItem.id)} title="Click to view Survey Data Details.">
                                {props.dataItem.itemId}
                            </Link>
                        </CustomGridCell>
                    )}
                    filter="text"
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                />
                <GridColumn title="Title" field="title" width={500} columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu} sortable={false} />
                {vm.viewState === ViewStateEnum.EditBatchUpdate ? (
                    <GridColumn field="dataType" title="Data Type" width={240} />
                ) : (
                    <GridColumn field="dataType" title="Data Type" width={240} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.surveyData)} />
                )}

                <GridColumn field="tapeId" title="Tape ID" width={190} filter="text" columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu} />

                <GridColumn
                    field="receivedDate"
                    title="Received Date"
                    width={220}
                    filter="date"
                    format={GlobalHelpers.NoptaDateFormatForKendo}
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                />

                <GridColumn
                    field="isConfidential"
                    title="Confidentiality"
                    width={190}
                    filter="text"
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : (p) => createGridColumnCheckboxFilterMenu(p, vm.surveyData)}
                />

                <GridColumn
                    title="Confidentiality Remarks"
                    width={260}
                    sortable={false}
                    field="confidentialityRemarks"
                    filter="text"
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <CommentSummaryView comment={props.dataItem.confidentialityRemarks} maxSummaryLength={20} title="Confidentiality Remarks"></CommentSummaryView>
                        </CustomGridCell>
                    )}
                />

                <GridColumn
                    field="conditionStatus"
                    title="Condition Status"
                    width={200}
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : (p) => createGridColumnCheckboxFilterMenu(p, vm.surveyData)}
                />

                <GridColumn
                    field="loanStatus"
                    title="Loan Status"
                    width={170}
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : (p) => createGridColumnCheckboxFilterMenu(p, vm.surveyData)}
                />

                <GridColumn
                    title="Consignment"
                    width={250}
                    sortable={false}
                    field="consignment"
                    filter="text"
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <CommentSummaryView comment={props.dataItem.consignment} maxSummaryLength={20} title="Consignment"></CommentSummaryView>
                        </CustomGridCell>
                    )}
                />
            </Grid>
        </>
    );
}
