import { takeEvery } from "redux-saga/effects";
import * as Actions from "./actions";

import initialise from "./sagas/initialise";
import add from "./sagas/add";
import deleteRelatedActivity from "./sagas/deleteRelatedActivity";

export default function* watcherSaga() {
    yield takeEvery(Actions.actions.initialiseRequested, initialise);
    yield takeEvery(Actions.actions.addRequested, add);
    yield takeEvery(Actions.actions.deleteRequested, deleteRelatedActivity);
}
