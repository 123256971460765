import { getServicesUri } from "../../api/uriHelper";
import { Fetch } from "../../api/fetchHelpers";

export interface IClient {
    initialise(ids: Array<string>): void;
    getToken(): Promise<void>;
    getUrl(): string;
}

export class Client implements IClient {
    private _ids: Array<string> | undefined;

    public initialise(ids: Array<string>) {
        this._assertNotInitialised();
        this._ids = ids;
    }

    public async getToken(): Promise<void> {
        this._assertInitialised();

        // this call will generate a server-side token
        // this token is returned as a short lived cookie

        const query = this._getQueryString();
        const fetch = new Fetch();
        await fetch.getTokenWithQuery("document/secure/download/token", query);
    }

    public getUrl(): string {
        this._assertInitialised();

        // this returns the unauthenticated URL which uses the token
        // the ids are returned to help avoid caching and provide better traceability in logs
        // they are also cross-checked server-side

        const query = this._getQueryString();
        return getServicesUri() + "/document/secure/download/content?" + query;
    }

    private _getQueryString() {
        return "ids=" + this._ids!.join("&ids=");
    }

    private _assertInitialised() {
        if (!this._ids) throw new Error("Not yet intialised.");
    }

    private _assertNotInitialised() {
        if (this._ids) throw new Error("Already intialised.");
    }
}
