// framework
import { SecurePortalCountryDto } from "../../../../api/Client";
import * as Validation from "../../../../common/validation/ValidationModel";
// redux
import { IAddressDetailsViewModel } from "./addressDetailsViewModel";

export function validate(vm: IAddressDetailsViewModel, formTypeId: number | undefined, countries: Array<SecurePortalCountryDto>, v: Validation.ValidationModel): void {
    // address details only required for form 2 and form 3 requests
    if (!(formTypeId === 2 || formTypeId === 3)) return;

    if (!vm.addressType) {
        v.addError("AddressDetails.AddressType", "Address Type is required.");
    }

    if (Validation.isNullOrWhiteSpace(vm.streetAddress)) {
        v.addError("AddressDetails.StreetAddress", "Street Address is required.");
    } else if (vm.streetAddress!.length > 1000) {
        v.addError("AddressDetails.StreetAddress", "Street Address must be 1000 characters or less.");
    }

    if (Validation.isNullOrWhiteSpace(vm.suburb)) {
        v.addError("AddressDetails.Suburb", "Town/Suburb is required.");
    } else if (vm.suburb!.length > 100) {
        v.addError("AddressDetails.Suburb", "Town/Suburb must be 100 characters or less.");
    }

    if (Validation.isNullOrWhiteSpace(vm.postcode)) {
        v.addError("AddressDetails.Postcode", "Postcode is required.");
    } else if (vm.postcode!.length > 100) {
        v.addError("AddressDetails.Postcode", "Postcode must be 100 characters or less.");
    }

    if (!vm.country) {
        v.addError("AddressDetails.Country", "Country is required.");

        if (vm.state) {
            v.addError("AddressDetails.State", "State is invalid.");
        }
    } else {
        if (!countries.some((c) => c.countryId === vm.country?.countryId)) {
            v.addError("AddressDetails.Country", "Country is invalid.");
        }

        if (vm.country.subdivisions && vm.country.subdivisions.length > 0) {
            if (!vm.state) {
                v.addError("AddressDetails.State", "State/Territory is required.");
            } else if (!vm.country.subdivisions.some((s) => s.countrySubdivisionId === vm.state?.countrySubdivisionId)) {
                v.addError("AddressDetails.State", "State/Territory is invalid.");
            }
        } else {
            if (vm.state) {
                v.addError("AddressDetails.State", "State is invalid.");
            }
        }
    }
}
