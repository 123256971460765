export default function Form6NoActiveFormView(): React.ReactElement {
    return (
        <div className="card mb-2">
            <div className="card-body">
                <h3 className="card-title">Form 6</h3>
                <span>There is no current Form 6.</span>
            </div>
        </div>
    );
}
