// redux
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";

export async function getCompanyCommonDraftApplicationDetailsSignatureType(id: number): Promise<Client.GetCompanyCommonDraftApplicationDetailsSignatureTypeResponseDto> {
    const client = await ClientFactory.createCompanyCommonDraftApplicationDetailsClient();
    return await CallHelper.simpleCall(() => client.getCompanyCommonDraftApplicationDetailsSignatureType(new Client.GetCompanyCommonDraftApplicationDetailsSignatureTypeRequestDto({ id: id })));
}

export async function saveCompanyCommonDraftApplicationDetailsSignatureType(
    vm: Models.IRootViewModel
): Promise<CallHelper.CallResult<Client.SaveCompanyCommonDraftApplicationDetailsSignatureTypeResponseDto>> {
    const client = await ClientFactory.createCompanyCommonDraftApplicationDetailsClient();
    return await CallHelper.call(() =>
        client.saveCompanyCommonDraftApplicationDetailsSignatureType(
            new Client.SaveCompanyCommonDraftApplicationDetailsSignatureTypeRequestDto({ id: vm.id!, versionNumber: vm.versionNumber!, signatureType: vm.signatureType!.signatureType! })
        )
    );
}
