export function home(): string {
    return "/";
}

export function signIn(): string {
    return "/Home/SignIn";
}

export function registration(): string {
    return "/Home/Registration";
}
