import * as Models from "../models/models";

// -------
// Actions
// -------

export enum actions {
    // lifecycle events
    clearRequested = "jointAuthority.licence.licenceList.clearRequested",

    // saga
    sagaGetListRequested = "jointAuthority.licence.licenceList.sagaGetListRequested",
    sagaCompleted = "jointAuthority.licence.licenceList.sagaCompleted",
}

// --------
// Payloads
// --------

export interface IAction<T> {
    type: actions;
    payload: T;
}

// ---------
// Factories
// ---------

export interface IActionFactory {
    clearRequested(): IAction<undefined>;
    sagaGetListRequested(): IAction<undefined>;
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    sagaGetListRequested: function (): IAction<undefined> {
        return {
            type: actions.sagaGetListRequested,
            payload: undefined,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
