// framework
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
// models
import { IRootViewModel } from "../../models/models";
// common
import * as GeoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
import CommentSummaryView from "../../../../../../common/commentSummary/commentSummaryView";
// api
import * as Client from "../../../../../../api/Client";

export default function SurveyDataListGridView(props: { vm: IRootViewModel; onDelete: (activityId: number, activityType: Client.SurveyDataRelatedActivityTypeEnum) => void }): JSX.Element {
    const { vm, onDelete } = props;

    const initialState: State = {
        sort: [{ field: "activityId", dir: "desc" }],
        filter: { logic: "and", filters: [] },
    };

    const [gridState, setGridState] = useState(initialState);

    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [vm.relatedActivities]); // eslint-disable-line react-hooks/exhaustive-deps

    function getRelatedActivityUrl(activity: Client.GetGeoscienceAustraliaSurveyDataRelatedActivityDto): string {
        switch (activity.surveyDataRelatedActivityType) {
            case Client.SurveyDataRelatedActivityTypeEnum.Borehole:
                return GeoscienceAustraliaRouteHelper.boreholeDetails(activity.boreholeDetails!.wellId, activity.id);

            case Client.SurveyDataRelatedActivityTypeEnum.Acquisition:
                return GeoscienceAustraliaRouteHelper.acquisitionSurveyData(activity.acquisitionDetails!.surveyId, activity.id);

            case Client.SurveyDataRelatedActivityTypeEnum.Project:
                return GeoscienceAustraliaRouteHelper.reprocessingProjectDetails(activity.projectDetails!.reprocessingId, activity.id);
        }
    }
    function getRelatedActivityTitle(activity: Client.GetGeoscienceAustraliaSurveyDataRelatedActivityDto): string {
        switch (activity.surveyDataRelatedActivityType) {
            case Client.SurveyDataRelatedActivityTypeEnum.Borehole:
                return `Click to view Borehole Details.`;

            case Client.SurveyDataRelatedActivityTypeEnum.Acquisition:
                return `Click to view Acquisition Survey Data.`;

            case Client.SurveyDataRelatedActivityTypeEnum.Project:
                return `Click to view Project Details.`;
        }
    }

    return (
        <>
            <Grid
                resizable
                data={process(vm.relatedActivities, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn field="activityId" title="Activity ID" width={240} />
                <GridColumn field="surveyDataRelatedActivityType" title="Activity Type" width={180} />
                <GridColumn
                    field="activityName"
                    title="Activity Name"
                    width={700}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <Link to={getRelatedActivityUrl(props.dataItem)} title={getRelatedActivityTitle(props.dataItem)}>
                                {props.dataItem.activityName}
                            </Link>
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    title="Comments"
                    width={300}
                    sortable={false}
                    field="comments"
                    filter="text"
                    columnMenu={createGridColumnFilterMenu}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <CommentSummaryView comment={props.dataItem.comments} maxSummaryLength={20} title="Comments"></CommentSummaryView>
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    title="Unlink"
                    width={190}
                    sortable={false}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <button
                                className="btn btn-secondary btn-sm"
                                type="button"
                                title="Click to Unlink Activity."
                                onClick={() => {
                                    onDelete(props.dataItem.id, props.dataItem.surveyDataRelatedActivityType);
                                }}
                            >
                                Unlink
                            </button>
                        </CustomGridCell>
                    )}
                />
            </Grid>
        </>
    );
}
