// kendo
import { Grid, GridColumn, GridCellProps, GridItemChangeEvent } from "@progress/kendo-react-grid";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
// redux
import * as Models from "../../models/models";
import * as Actions from "../../redux/actions";
// common
import CustomGridCell from "../../../../../../../common/kendoGrid/CustomGridCell";

export default function ChecklistView(props: { vm: Models.IRootViewModel; template: Models.IChecklistTemplateViewModel; actions: Actions.IActionFactory; isBusy: boolean }): React.ReactElement {
    const vm = props.vm;
    const actions = props.actions;
    const items = props.template.items;

    if (!vm.checklist || vm.checklist.length === 0) return <></>;
    // Blow up if there is a checklist item without a corresponding template item, we don't wan't the user to be able to interact with an invalid checklist
    if (vm.checklist.some((c) => !items.some((i) => i.type === c.type))) throw new Error("Checklist item with missing template entry detected.");

    function onGridItemChange(e: GridItemChangeEvent): void {
        if (props.isBusy) return;
        if (vm.viewState === Models.ViewStateEnum.View) return;

        const checklistItem = e.dataItem as Models.IChecklistItemViewModel;
        if (!checklistItem) return;

        const isChecked = e.value as boolean | undefined;
        if (isChecked === undefined) return;

        const updatedChecklistItem = { ...checklistItem, isChecked: isChecked };
        const updatedChecklist = vm.checklist.map((c) => (c.type === checklistItem.type ? updatedChecklistItem : c));

        actions.viewChanged(vm.onChecklistChanged(updatedChecklist));
    }

    return (
        <div>
            <h5>Checklist</h5>
            <Grid resizable navigatable data={vm.checklist} onItemChange={onGridItemChange}>
                <GridColumn field="isChecked" title="Attached" width={100} cell={(p) => getIsCheckedCell(p, props.isBusy, vm.viewState)} />
                <GridColumn title="Description" cell={(props) => <CustomGridCell gridCellProps={props}>{items.find((item) => item.type === props.dataItem.type)!.description}</CustomGridCell>} />
            </Grid>
        </div>
    );
}

function getIsCheckedCell(props: GridCellProps, isBusy: boolean, viewState: Models.ViewStateEnum) {
    const { dataItem, dataIndex, field } = props;

    // disabled if control is busy or in view mode
    const disabled = isBusy || viewState === Models.ViewStateEnum.View;

    const handleChange = (e: CheckboxChangeEvent) => {
        props.onChange!({
            dataItem: dataItem,
            dataIndex: dataIndex,
            syntheticEvent: e.syntheticEvent,
            field: field,
            value: e.value,
        });
    };

    // Note: using Kendo rather than native checkboxes for controls inside Kendo grids
    return (
        <CustomGridCell gridCellProps={props}>
            <Checkbox checked={dataItem.isChecked} onChange={handleChange} disabled={disabled} />
        </CustomGridCell>
    );
}
