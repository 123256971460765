// redux
import * as Models from "../../models/models";
import * as Actions from "../../redux/actions";
// api
import { GetCompanyOpggsDraftApplicationDetailsEvaTypeDto } from "../../../../../../../api/Client";
// common
import { ValidationModel } from "../../../../../../../common/validation/ValidationModel";
// views
import EvaView from "./EvaView";
import EvaReadonlyView from "./EvaReadonlyView";

export default function EvaDetailsView(props: { vm: Models.IRootViewModel; v: ValidationModel; actions: Actions.IActionFactory }): React.ReactElement {
    const vm = props.vm;
    const v = props.v;
    const actions = props.actions;

    function updateViewModel(value: GetCompanyOpggsDraftApplicationDetailsEvaTypeDto | undefined): void {
        if (vm.viewState !== Models.ViewStateEnum.Edit) return;
        actions.viewChanged(vm.onEvaChanged(value));
    }

    return vm.viewState === Models.ViewStateEnum.Edit ? <EvaView vm={vm} v={v} updateViewModel={updateViewModel} /> : <EvaReadonlyView vm={vm} />;
}
