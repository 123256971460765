// framework
import { useEffect, useState } from "react";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
import { IPaymentViewModel } from "../../models/paymentViewModel";
import validator from "../../models/validator";
// common
import { ValidationVisibilityEnum } from "../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
import CustomCheckoutController from "../../../../../common/bambora/CustomCheckoutController";
import SimpleAlertView from "../../../../../common/simpleAlerts/SimpleAlertView";
import { toNoptaNumberString } from "../../../../../common/GlobalHelpers";
import * as Forms from "../../../../../common/forms/BootstrapForms";
import * as toastHelper from "../../../../../common/toastHelper";

export default function MakePaymentView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; onCancelPayment: () => void }): React.ReactElement {
    let vm = props.vm;
    let actions = props.actions;

    const [successMessage, setSuccessMessage] = useState<string>();

    const [state, setState] = useState<IPaymentViewModel>({
        receivedAmount: vm.selectedFinancialNotifications.totalOutstandingAmount,
        comments: undefined,
        creditCardSurchargeAmount: undefined,
        secureTransactionToken: undefined,
    });

    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.None);

    const v = validator(vm, validationVisibility);

    useEffect(() => {
        if (vm.paymentViewModel?.creditCardSurchargeAmount !== undefined) {
            let surcharge = vm.paymentViewModel.creditCardSurchargeAmount;
            setSuccessMessage(
                "Total Amount Payable is " +
                    toNoptaNumberString(state.receivedAmount! + surcharge, "c") +
                    ", which includes a Surcharge of " +
                    toNoptaNumberString(surcharge, "c") +
                    ". Press Submit to proceed with payment."
            );
            const newState = { ...state, ...vm.paymentViewModel };
            setState(newState);
            actions.viewChanged(vm.refreshPaymentDetails({ ...newState }));
        } else {
            setSuccessMessage(undefined);
        }
    }, [vm.paymentViewModel.creditCardSurchargeAmount, vm.paymentViewModel?.secureTransactionToken]); // eslint-disable-line react-hooks/exhaustive-deps

    function onChanged(values: any) {
        setState({ ...state, ...values });
    }

    function onTokenSet(stt: string | undefined) {
        setSuccessMessage(undefined);
        const newState = { ...state, secureTransactionToken: stt, creditCardSurchargeAmount: undefined };
        setState(newState);
        if (stt) {
            actions.getCreditCardSurcharge(vm.refreshPaymentDetails(newState));
        } else {
            actions.viewChanged(vm.refreshPaymentDetails(newState));
        }
    }

    function updateViewModel() {
        actions.viewChanged(vm.refreshPaymentDetails({ ...state }));
    }

    function onSubmitClicked(e: any) {
        e.preventDefault();
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.None);
        actions.createPaymentRequested(vm);
    }

    return (
        <form onSubmit={onSubmitClicked}>
            <div className="card-body">
                <h2 className="card-title">Choose Payment Method</h2>
                <SimpleAlertView alertType="information" message="Please contact NOPTA to make payment via a Direct Deposit or Cheque." />
                {/* strange dummy radiobuttons */}
                <Forms.Group>
                    <Forms.ReadonlyRadioButton name="paymentMethodGroup" id="isCreditCardRadioField" label="Credit Card" checked inline />
                    <Forms.ReadonlyRadioButton name="paymentMethodGroup" id="isDirectDepositRadioField" label="Direct Deposit" inline />
                    <Forms.ReadonlyRadioButton name="paymentMethodGroup" id="isChequeRadioField" label="Cheque" inline />
                </Forms.Group>
                <Forms.Group>
                    <Forms.TextArea
                        id="comments"
                        label="Comments"
                        rows={5}
                        maxLength={1000}
                        value={state.comments}
                        valid={v.applyValidity("MakePayment.Comments")}
                        onChange={(value) => onChanged({ comments: value })}
                        onBlur={updateViewModel}
                    />
                    <ValidationMessageView name="MakePayment.Comments" validationModel={v} />
                </Forms.Group>
                <div className="card-body border mb-4 pb-0">
                    <h4 className="card-title">Credit Card Details</h4>
                    <CustomCheckoutController onTokenChanged={(stt) => onTokenSet(stt)} message={successMessage} />
                    <ValidationMessageView name="MakePayment.VerifyDetails" validationModel={v} />
                </div>
                <Forms.Group>
                    <button className="btn btn-outline-primary me-2" type="submit" disabled={!vm.isDirty}>
                        Submit
                    </button>
                    <button className="btn btn-outline-secondary me-2" onClick={props.onCancelPayment}>
                        Cancel
                    </button>
                </Forms.Group>
            </div>
        </form>
    );
}
