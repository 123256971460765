// api
import * as Client from "../../../../api/Client";
// kendo
import { orderBy } from "@progress/kendo-data-query";
// local
import * as Models from "./jointAuthorityDetailsRoleViewModel";

// other
import { clone, cloneDeep } from "lodash";
import { BaseJointAuthorityRoleRootViewModel, IJointAuthorityRoleRootViewModel } from "../../../common/JointAuthorityRoleRootViewModel";

export enum ViewStateEnum {
    View = "jointAuthority.userManagement.jointAuthorityDetails.models.viewState.view",
    Edit = "jointAuthority.userManagement.jointAuthorityDetails.models.viewState.edit",
}

export interface IDetailsViewModel {
    jointAuthorityName?: string;
}

export interface IRootViewModel extends IJointAuthorityRoleRootViewModel {
    details: IDetailsViewModel;

    permissions: Models.IJointAuthorityDetailsRoleViewModel[];

    service: {
        statusMessages?: Client.StatusMessagesDto;
    };

    isDirty: boolean;
    isConflict: boolean;
    viewState: ViewStateEnum;

    refreshDetails(response: Client.GetJointAuthorityUserManagementJointAuthorityDetailsResponseDto, resetState: boolean): IRootViewModel;
    refreshSaveJointAuthorityPermissionsResponse(response: Client.SaveJointAuthorityUserManagementJointAuthorityDetailsRolesResponseDto): IRootViewModel;
    refreshConflict(): IRootViewModel;
    // view triggered state changes
    refreshPermissionsChanged(permissions: Models.IJointAuthorityDetailsRoleViewModel[]): IRootViewModel;
    setEditState(): IRootViewModel;
    clearEditState(): IRootViewModel;
}

export class RootViewModel extends BaseJointAuthorityRoleRootViewModel implements IRootViewModel {
    constructor() {
        super();

        // initial state
        this.details = {};
        this.permissions = [];
        this.service = { statusMessages: undefined };
        this.isDirty = false;
        this.isConflict = false;
        this.viewState = ViewStateEnum.View;
    }

    public details: IDetailsViewModel;
    public permissions: Models.IJointAuthorityDetailsRoleViewModel[];

    public service: {
        statusMessages?: Client.StatusMessagesDto;
    };

    public isDirty: boolean;
    public isConflict: boolean;
    public viewState: ViewStateEnum;

    public refreshDetails(response: Client.GetJointAuthorityUserManagementJointAuthorityDetailsResponseDto, resetState: boolean): IRootViewModel {
        const vm = this._clone();

        if (resetState) {
            vm.service = { statusMessages: undefined };
            vm.isDirty = false;
            vm.isConflict = false;
            vm.viewState = ViewStateEnum.View;
        }

        vm.details.jointAuthorityName = response.jointAuthorityName;

        const permissions = response.secureUsers.map((p) => new Models.JointAuthorityDetailsRoleViewModel(vm.roleConfiguration.roleRelationships!, p));
        vm.permissions = orderBy(permissions, [
            { field: "hasJointAuthorityMembership", dir: "desc" },
            { field: "fullName", dir: "asc" },
        ]);

        return vm;
    }

    public refreshSaveJointAuthorityPermissionsResponse(response: Client.SaveJointAuthorityUserManagementJointAuthorityDetailsRolesResponseDto): IRootViewModel {
        const vm = this._clone();
        const isSuccess = response.statusMessages?.isSuccess;

        vm.service.statusMessages = response.statusMessages;
        // on success, set the vm as clean and view, otherwise leave dirty and edit
        vm.isDirty = !isSuccess;
        vm.isConflict = false;
        vm.viewState = isSuccess ? ViewStateEnum.View : ViewStateEnum.Edit;

        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        vm.service.statusMessages = undefined;
        return vm;
    }

    public refreshPermissionsChanged(permissions: Models.IJointAuthorityDetailsRoleViewModel[]): IRootViewModel {
        const vm = this._clone();

        vm.permissions = permissions;
        vm.isDirty = true;

        return vm;
    }

    public setEditState(): IRootViewModel {
        const vm = this._clone();

        vm.viewState = ViewStateEnum.Edit;
        return vm;
    }

    public clearEditState(): IRootViewModel {
        const vm = this._clone();

        const resetPermissions = this.permissions.map((p) => p.resetRoles());

        vm.permissions = resetPermissions;
        vm.isDirty = false;
        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    // private implementation
    // ----------------------
    private _clone(): RootViewModel {
        const vm = clone(this);

        vm.details = clone(this.details);
        vm.permissions = cloneDeep(this.permissions);
        vm.service = clone(this.service);

        return vm;
    }
}
