// redux
import { call, put, select } from "redux-saga/effects";
import { ICompanyReduxState } from "../../../../../companyReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import { IAddressDetailsViewModel } from "../../../common/addressDetailsViewModel";
import * as LogHelper from "../../../../../../common/LogHelper";
import * as shellHelper from "../../../../../../common/shell/shellHelper";

const getRootViewModel = (state: ICompanyReduxState) => state.Company_Opggs_LegislativeForms_FormRequestCreate;

export default function* createFormRequest() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const actions = Actions.actionFactory;

    try {
        yield put(shellHelper.createSetBusyAction());

        let response: IResponse;
        const formTypeId = vm.formRequestDetails.formTypeId;

        switch (formTypeId) {
            case 2:
                response = yield call(async () => await createForm2Request(vm));
                break;
            case 3:
                response = yield call(async () => await createForm3Request(vm));
                break;
            case 4:
                response = yield call(async () => await createForm4Request(vm));
                break;
            case 5:
                response = yield call(async () => await createForm5Request(vm));
                break;
            case 6:
                response = yield call(async () => await createForm6Request(vm));
                break;
            case 7:
                response = yield call(async () => await createForm7Request(vm));
                break;
            default:
                throw new Error(`Unsupported Legislative Form '${formTypeId}'.`);
        }

        yield put(actions.sagaCompleted(vm.refreshCreateFormRequestResponse(response.statusMessages!, response.id)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

interface IResponse {
    statusMessages?: Client.StatusMessagesDto;
    id?: Client.FormRequestIdDto | undefined;
}

async function createForm2Request(vm: Models.IRootViewModel): Promise<IResponse> {
    const request = new Client.CreateCompanyOpggsLegislativeFormsForm2RequestRequestDto({
        titleId: vm.titleId!,
        registeredTitleholderCompanyId: vm.formRequestDetails.selectedTitleholder!.companyId,
        registeredTitleholderDetails: getRegisteredTitleholderDetailsDto(vm),
        address: getAddressDetailsDto(vm.addressDetails),
    });

    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();
    return await CallHelper.simpleCall(() => client.createCompanyOpggsLegislativeFormsForm2Request(request));
}

async function createForm3Request(vm: Models.IRootViewModel): Promise<IResponse> {
    const additionalTitleIds = vm.formRequestDetails.additionalTitles?.map((t) => t.id) ?? new Array<string>();

    const request = new Client.CreateCompanyOpggsLegislativeFormsForm3RequestRequestDto({
        titleId: vm.titleId!,
        additionalTitleIds: additionalTitleIds,
        registeredTitleholderCompanyId: vm.formRequestDetails.selectedTitleholder!.companyId,
        registeredTitleholderDetails: getRegisteredTitleholderDetailsDto(vm),
        address: getAddressDetailsDto(vm.addressDetails),
    });

    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();
    return await CallHelper.simpleCall(() => client.createCompanyOpggsLegislativeFormsForm3Request(request));
}

async function createForm4Request(vm: Models.IRootViewModel): Promise<IResponse> {
    const request = new Client.CreateCompanyOpggsLegislativeFormsForm4RequestRequestDto({
        titleId: vm.titleId!,
        nomineeTitleholderCompanyId: vm.formRequestDetails.selectedTitleholder!.companyId,
    });

    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();
    return await CallHelper.simpleCall(() => client.createCompanyOpggsLegislativeFormsForm4Request(request));
}

async function createForm5Request(vm: Models.IRootViewModel): Promise<IResponse> {
    const request = new Client.CreateCompanyOpggsLegislativeFormsForm5RequestRequestDto({
        titleId: vm.titleId!,
        titleholderGivingNoticeCompanyId: vm.formRequestDetails.selectedTitleholder!.companyId,
    });

    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();
    return await CallHelper.simpleCall(() => client.createCompanyOpggsLegislativeFormsForm5Request(request));
}

async function createForm6Request(vm: Models.IRootViewModel): Promise<IResponse> {
    const request = new Client.CreateCompanyOpggsLegislativeFormsForm6RequestRequestDto({
        titleId: vm.titleId!,
        nomineeTitleholderCompanyId: vm.formRequestDetails.selectedTitleholder!.companyId,
    });

    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();
    return await CallHelper.simpleCall(() => client.createCompanyOpggsLegislativeFormsForm6Request(request));
}

async function createForm7Request(vm: Models.IRootViewModel): Promise<IResponse> {
    const request = new Client.CreateCompanyOpggsLegislativeFormsForm7RequestRequestDto({
        titleId: vm.titleId!,
        titleholderGivingNoticeCompanyId: vm.formRequestDetails.selectedTitleholder!.companyId,
    });

    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();
    return await CallHelper.simpleCall(() => client.createCompanyOpggsLegislativeFormsForm7Request(request));
}

// private
function getRegisteredTitleholderDetailsDto(vm: Models.IRootViewModel): Client.FormRequestRegisteredTitleholderDetailsDto {
    // only persist acn if registered titleholder requires it
    const acn = vm.formRequestDetails.selectedTitleholder!.isAcnOrArbnRequired ? vm.registeredTitleholderDetails.acnOrArbn : "";

    return new Client.FormRequestRegisteredTitleholderDetailsDto({
        acnOrArbn: acn,
        phone: vm.registeredTitleholderDetails.phone,
        fax: vm.registeredTitleholderDetails.fax,
        email: vm.registeredTitleholderDetails.email,
    });
}

function getAddressDetailsDto(vm: IAddressDetailsViewModel): Client.FormRequestAddressDetailsDto {
    return new Client.FormRequestAddressDetailsDto({
        addressType: vm.addressType!,
        streetAddress: vm.streetAddress,
        suburb: vm.suburb,
        postcode: vm.postcode,
        countryId: vm.country!.countryId,
        countrySubdivisionId: vm.state?.countrySubdivisionId,
    });
}
