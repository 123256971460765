// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../../../api/Client";

export interface IReferenceData {
    batchItems: Client.GetDeleteGeoscienceAustraliaAttachmentsImportReferenceDataAttachmentBatchItem[];
}

export interface IRootViewModel {
    bulkDeleteBatchItem?: Client.GetDeleteGeoscienceAustraliaAttachmentsImportReferenceDataAttachmentBatchItem | undefined;

    referenceData: IReferenceData;

    statusMessages?: Client.StatusMessagesDto;
    isConflict: boolean;
    isDirty: boolean;

    refreshReferenceData(response: Client.GetDeleteGeoscienceAustraliaAttachmentsImportReferenceDataResponseDto): IRootViewModel;
    refreshDeleteResponse(response: Client.DeleteGeoscienceAustraliaAttachmentsImportResponseDto): IRootViewModel;

    refreshBulkDeleteBatchItem(bulkDeleteBatchItem: Client.GetDeleteGeoscienceAustraliaAttachmentsImportReferenceDataAttachmentBatchItem | undefined): IRootViewModel;

    refreshConflict(): IRootViewModel;
    clearStatusMessages(): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.referenceData = {
            batchItems: [],
        };

        this.isConflict = false;
        this.isDirty = false;
    }

    public bulkDeleteBatchItem?: Client.GetDeleteGeoscienceAustraliaAttachmentsImportReferenceDataAttachmentBatchItem | undefined;

    public referenceData: IReferenceData;

    public statusMessages?: Client.StatusMessagesDto;
    public isConflict: boolean;
    public isDirty: boolean;

    public refreshReferenceData(response: Client.GetDeleteGeoscienceAustraliaAttachmentsImportReferenceDataResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.referenceData = {
            batchItems: response.attachmentBatchItems,
        };
        vm.isDirty = false;
        vm.isConflict = false;
        return vm;
    }

    public refreshDeleteResponse(response: Client.DeleteGeoscienceAustraliaAttachmentsImportResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = response.statusMessages;
        vm.isDirty = false;
        return vm;
    }

    public refreshBulkDeleteBatchItem(bulkDeleteBatchItem: Client.GetDeleteGeoscienceAustraliaAttachmentsImportReferenceDataAttachmentBatchItem | undefined): IRootViewModel {
        const vm = this._clone();
        vm.bulkDeleteBatchItem = bulkDeleteBatchItem;
        vm.isDirty = true;
        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        return vm;
    }
    public clearStatusMessages(): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = undefined;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.referenceData = clone(this.referenceData);
        vm.bulkDeleteBatchItem = clone(this.bulkDeleteBatchItem);
        return vm;
    }
}
