// redux
import { call, put, select } from "redux-saga/effects";
import { IJointAuthorityReduxState } from "../../../../jointAuthorityReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";
import * as ConfigurationHelper from "../../../../../common/shell/configurationHelper";

const getRootViewModel = (state: IJointAuthorityReduxState) => state.JointAuthority_Account_RequestAccess;

export default function* initialise() {
    // save role reference data to the vm
    let vm: Models.IRootViewModel = yield select(getRootViewModel);
    vm = vm.refreshRoleConfiguration(yield select(ConfigurationHelper.getRoleConfiguration));
    yield put(Actions.actionFactory.sagaCompleted(vm));

    // load access request reference data
    try {
        yield put(shellHelper.createSetBusyAction());

        const response: Client.GetJointAuthorityAccountRequestAccessReferenceDataResponseDto = yield call(async () => {
            const client = await ClientFactory.createJointAuthorityAccountClient();
            return await CallHelper.simpleCall(() => client.getJointAuthorityAccountRequestAccessReferenceData(new Client.GetJointAuthorityAccountRequestAccessReferenceDataRequestDto()));
        });
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshReferenceData(response.jointAuthorities!)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
