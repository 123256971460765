// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IDataMonitoringItemViewModel {
    name?: string | undefined;
    identifier?: string | undefined;

    well?: Client.GetGeoscienceAustraliaWellsBoreholesDataMonitoringItemDetailsReferenceDto | undefined;
    borehole?: Client.GetGeoscienceAustraliaWellsBoreholesDataMonitoringItemDetailsReferenceDto | undefined;
    duplicateDataMonitoringItems: Client.GetGeoscienceAustraliaWellsBoreholesDataMonitoringItemDetailsDuplicateDataMonitoringItemDto[];
    dataSubmission?: Client.GetGeoscienceAustraliaWellsBoreholesDataMonitoringItemDetailsDataSubmissionDto | undefined;
    authorisations: Client.GetGeoscienceAustraliaWellsBoreholesDataMonitoringItemDetailsAuthorisationDto[];
    resubmissions: Client.GetGeoscienceAustraliaWellsBoreholesDataMonitoringItemDetailsResubmissionDto[];

    status?: string | undefined;
    dueDate?: Date | undefined;
    rigReleaseDueDate?: Date | undefined;
    extensionDueDate?: Date | undefined;
    resubmissionDueDate?: Date | undefined;
    receivedDate?: Date | undefined;
    contentsCheckCompletedDate?: Date | undefined;
    dataCheckCompletedDate?: Date | undefined;
    sentToGaDate?: Date | undefined;

    comments?: string | undefined;
}

export interface IRootViewModel {
    dataMonitoringItem: IDataMonitoringItemViewModel;

    permissions: {
        canViewWells: boolean;
        canViewBoreholes: boolean;
        canViewDataSubmissions: boolean;
        canViewAuthorisations: boolean;
    };

    audit?: Client.SecurePortalSimpleAuditEventsDto;

    refreshDataMonitoringItemDetails(response: Client.GetGeoscienceAustraliaWellsBoreholesDataMonitoringItemDetailsResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.dataMonitoringItem = {
            well: undefined,
            borehole: undefined,
            duplicateDataMonitoringItems: [],
            dataSubmission: undefined,
            authorisations: [],
            resubmissions: [],

            dueDate: undefined,
            rigReleaseDueDate: undefined,
            extensionDueDate: undefined,
            resubmissionDueDate: undefined,
            receivedDate: undefined,
            contentsCheckCompletedDate: undefined,
            dataCheckCompletedDate: undefined,
            sentToGaDate: undefined,

            comments: undefined,
        };

        this.permissions = {
            canViewWells: false,
            canViewBoreholes: false,
            canViewDataSubmissions: false,
            canViewAuthorisations: false,
        };
    }

    public dataMonitoringItem: IDataMonitoringItemViewModel;

    public permissions: {
        canViewWells: boolean;
        canViewBoreholes: boolean;
        canViewDataSubmissions: boolean;
        canViewAuthorisations: boolean;
    };

    public audit?: Client.SecurePortalSimpleAuditEventsDto | undefined;

    public refreshDataMonitoringItemDetails(response: Client.GetGeoscienceAustraliaWellsBoreholesDataMonitoringItemDetailsResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.dataMonitoringItem = {
            name: response.name,
            identifier: response.identifier,

            well: response.wellDetails?.well,
            borehole: response.wellDetails?.borehole,
            duplicateDataMonitoringItems: response.duplicateDataMonitoringItems,
            dataSubmission: response.dataSubmission,
            authorisations: response.authorisations,
            resubmissions: response.resubmissions,

            status: response.status,
            dueDate: response.dueDate,
            rigReleaseDueDate: response.wellDetails?.rigReleaseDueDate,
            extensionDueDate: response.extensionDueDate,
            resubmissionDueDate: response.resubmissionDueDate,
            receivedDate: response.receivedDate,
            contentsCheckCompletedDate: response.contentsCheckCompletedDate,
            dataCheckCompletedDate: response.dataCheckCompletedDate,
            sentToGaDate: response.sentToGaDate,

            comments: response.comments,
        };

        vm.permissions = {
            canViewWells: response.canViewWells,
            canViewBoreholes: response.canViewBoreholes,
            canViewDataSubmissions: response.canViewDataSubmissions,
            canViewAuthorisations: response.canViewAuthorisations,
        };

        vm.audit = response.audit;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.dataMonitoringItem = clone(this.dataMonitoringItem);
        return vm;
    }
}
