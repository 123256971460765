// redux
import * as Models from "../../models/models";
// common
import * as Forms from "../../../../../../../common/forms/BootstrapForms";

export default function ApplicationDetailsView(props: { vm: Models.IRootViewModel }) {
    const vm = props.vm;

    if (!vm.items) return <></>;

    return (
        <Forms.Row>
            {vm.items.map((item, i) => {
                const fieldName = item.name + "Field";
                return (
                    <Forms.HalfColumn key={i}>
                        <Forms.ReadonlyField label={item.name} id={fieldName} value={item.value} />
                    </Forms.HalfColumn>
                );
            })}
        </Forms.Row>
    );
}
