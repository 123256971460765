// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IResubmissionViewModel {
    status?: Client.DataSubmissionResubmissionStatusEnum | undefined;
    requestedDate?: Date | undefined;
    dueDate?: Date | undefined;
    receivedDate?: Date | undefined;
    commentsHtml?: string | undefined;
    resubmissionItems: Client.GetGeoscienceAustraliaDataSubmissionDataResubmissionDetailsResponseItemDto[];
}

export interface IRootViewModel {
    dataSubmissionId: number | undefined;
    resubmissionId: number | undefined;
    dataSubmissionName?: string | undefined;
    resubmissionName?: string | undefined;
    dataSubmissionType?: Client.NopimsActivityTypeEnum | undefined;

    resubmission: IResubmissionViewModel;

    permissions: {
        canViewBoreholes: boolean;
        canViewAcquisitions: boolean;
        canViewReprocessingProjects: boolean;
    };

    audit?: Client.SecurePortalSimpleAuditEventsDto;

    refreshDataSubmissionResubmissionDetails(response: Client.GetGeoscienceAustraliaDataSubmissionDataResubmissionDetailsResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.dataSubmissionId = undefined;
        this.resubmissionId = undefined;
        this.dataSubmissionName = undefined;
        this.resubmissionName = undefined;
        this.dataSubmissionType = undefined;

        this.resubmission = {
            status: undefined,
            requestedDate: undefined,
            dueDate: undefined,
            receivedDate: undefined,
            commentsHtml: undefined,
            resubmissionItems: [],
        };

        this.permissions = {
            canViewBoreholes: false,
            canViewAcquisitions: false,
            canViewReprocessingProjects: false,
        };
    }

    public dataSubmissionId: number | undefined;
    public resubmissionId: number | undefined;
    public dataSubmissionName?: string | undefined;
    public resubmissionName?: string | undefined;
    public dataSubmissionType?: Client.NopimsActivityTypeEnum | undefined;

    public resubmission: IResubmissionViewModel;

    public permissions: {
        canViewBoreholes: boolean;
        canViewAcquisitions: boolean;
        canViewReprocessingProjects: boolean;
    };

    public audit?: Client.SecurePortalSimpleAuditEventsDto | undefined;

    public refreshDataSubmissionResubmissionDetails(response: Client.GetGeoscienceAustraliaDataSubmissionDataResubmissionDetailsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.dataSubmissionId = response.dataSubmissionId;
        vm.resubmissionId = response.resubmissionId;
        vm.dataSubmissionName = response.dataSubmissionName;
        vm.resubmissionName = response.resubmissionName;
        vm.dataSubmissionType = response.type;

        vm.resubmission = {
            status: response.status,
            requestedDate: response.requestedDate,
            dueDate: response.dueDate,
            receivedDate: response.receivedDate,
            commentsHtml: response.commentsHtml,
            resubmissionItems: response.resubmissionItems,
        };

        vm.permissions = {
            canViewBoreholes: response.canViewBoreholes,
            canViewAcquisitions: response.canViewAcquisitions,
            canViewReprocessingProjects: response.canViewReprocessingProjects,
        };

        vm.audit = response.audit;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.resubmission = clone(this.resubmission);
        return vm;
    }
}
