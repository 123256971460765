// framework
import { connect } from "react-redux";
import { SignInIcon, SignOutIcon } from "@primer/octicons-react";
// custom
import * as Actions from "./redux/actions";
import * as Models from "./models/models";
import { IShellReduxState } from "../shellReduxRegistration";

interface IProps extends Actions.IActionFactory {
    viewModel: Models.IRootViewModel;
    className: string | undefined;
}

// provides the sign-in/sign-out link in the shell's top nav (rhs)

function Controller(props: IProps) {
    const isSignedIn = props.viewModel.isSignedIn;

    // Not Signed In
    if (!isSignedIn) {
        return (
            <span className={props.className}>
                <span className="d-inline-block align-items-center ms-md-0 ms-lg-0 ms-xl-0 ms-xxl-0" style={{ whiteSpace: "nowrap" }}>
                    <button className="btn btn-outline-warning" title="Sign In" onClick={() => props.signInRequestedAction()}>
                        <SignInIcon size="medium" aria-label="Sign In" />
                    </button>
                </span>
            </span>
        );
    }

    // Signed In
    return (
        <span className={props.className}>
            <span className="d-inline-block align-items-center ms-md-0 ms-lg-0 ms-xl-0 ms-xxl-0" style={{ whiteSpace: "nowrap" }}>
                <button className="btn btn-outline-warning" title="Sign Out" onClick={() => props.signOutRequestedAction()}>
                    <SignOutIcon size="medium" aria-label="Sign Out" />
                </button>
            </span>
        </span>
    );
}

// connect redux
export default connect((state: IShellReduxState) => ({ viewModel: state.Shell_SignIn }), Actions.actionFactory)(Controller);
