import * as Models from "../models/models";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "company.title.titleDetails.initialiseRequested",
    clearRequested = "company.title.titleDetails.clearRequested",
    getMapRequested = "company.title.titleDetails.getMapRequested",
    getApplicationDocumentRequested = "company.title.titleDetails.getApplicationDocumentRequested",
    clearApplicationDocumentRequested = "company.title.titleDetails.clearApplicationDocumentRequested",
    getLegislativeFormDocumentRequested = "company.title.titleDetails.getLegislativeFormDocumentRequested",
    clearLegislativeFormDocumentRequested = "company.title.titleDetails.clearLegislativeFormDocumentRequested",
    getCreateDraftApplicationReferenceDataRequested = "company.title.titleDetails.getCreateDraftApplicationReferenceDataRequested",
    clearDraftApplicationDataRequested = "company.title.titleDetails.clearDraftApplicationDataRequested",
    createDraftApplicationRequested = "company.title.titleDetails.createDraftApplicationRequested",
    // user input
    viewChanged = "company.title.titleDetails.viewChanged",
    // saga events
    sagaCompleted = "company.title.titleDetails.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

export interface ITitleApplicationKeyPayload {
    titleId: string;
    applicationId: string;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(titleId: string): IAction<string>;
    clearRequested(): IAction<undefined>;
    getMapRequested(titleId: string): IAction<string>;
    getApplicationDocumentRequested(titleId: string, applicationId: string): IAction<ITitleApplicationKeyPayload>;
    clearApplicationDocumentRequested(): IAction<undefined>;
    getLegislativeFormDocumentRequested(formId: number): IAction<number>;
    clearLegislativeFormDocumentRequested(): IAction<undefined>;
    getCreateDraftApplicationReferenceDataRequested(titleId: string): IAction<string>;
    clearDraftApplicationDataRequested(): IAction<undefined>;
    createDraftApplicationRequested(): IAction<undefined>;
    // user input
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    // saga events
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (titleId: string): IAction<string> {
        return {
            type: actions.initialiseRequested,
            payload: titleId,
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    getMapRequested: function (titleId: string): IAction<string> {
        return {
            type: actions.getMapRequested,
            payload: titleId,
        };
    },
    getApplicationDocumentRequested: function (titleId: string, applicationId: string): IAction<ITitleApplicationKeyPayload> {
        return {
            type: actions.getApplicationDocumentRequested,
            payload: { titleId, applicationId },
        };
    },
    clearApplicationDocumentRequested: function (): IAction<undefined> {
        return {
            type: actions.clearApplicationDocumentRequested,
            payload: undefined,
        };
    },
    getLegislativeFormDocumentRequested: function (formId: number): IAction<number> {
        return {
            type: actions.getLegislativeFormDocumentRequested,
            payload: formId,
        };
    },
    clearLegislativeFormDocumentRequested: function (): IAction<undefined> {
        return {
            type: actions.clearLegislativeFormDocumentRequested,
            payload: undefined,
        };
    },
    getCreateDraftApplicationReferenceDataRequested: function (titleId: string): IAction<string> {
        return {
            type: actions.getCreateDraftApplicationReferenceDataRequested,
            payload: titleId,
        };
    },
    clearDraftApplicationDataRequested: function (): IAction<undefined> {
        return {
            type: actions.clearDraftApplicationDataRequested,
            payload: undefined,
        };
    },
    createDraftApplicationRequested: function (): IAction<undefined> {
        return {
            type: actions.createDraftApplicationRequested,
            payload: undefined,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
