// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
// api
import * as Client from "../../../../../../../api/Client";
import * as GlobalHelpers from "../../../../../../../common/GlobalHelpers";

export default function ApprovalTrackingView(props: { items: Client.GetCompanyOpggsApplicationDetailsApprovalTrackingItemDto[] }) {
    return (
        <Grid resizable navigatable data={props.items}>
            <GridColumn field="status" title="Status" width={400} />
            <GridColumn field="startedDatetime" title="Started Date" width={200} format={GlobalHelpers.NoptaDateFormatForKendo} />
            <GridColumn field="daysAtStatus" title="Days At Status" width={200} format="{0:n0}" />
        </Grid>
    );
}
