// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Models from "../../models/models";
import * as Actions from "../actions";
// api
import * as ClientFactory from "../../../../api/clientFactory";
import * as CallHelper from "../../../../api/callHelper";
import * as Client from "../../../../api/Client";
// common
import * as shellHelper from "../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../common/LogHelper";
import { IShellReduxState } from "../../../shellReduxRegistration";
import * as configurationHelper from "../../../../common/shell/configurationHelper";

const getRootViewModel = (state: IShellReduxState) => state.Shell_Configuration;

export function* refreshStaticConfiguration() {
    try {
        yield put(shellHelper.createSetBusyAction());

        // always show errors during load
        const result: IConfiguration = yield call(async () => await CallHelper.simpleCall(() => load()));

        let vm: Models.IConfigurationModel = yield select(getRootViewModel);
        vm = vm.refreshPortalLinks(result.shellPortalLinks);
        vm = vm.refreshRoleConfiguration(result.roleConfiguration);
        vm = vm.refreshFeatureSwitches(result.featureSwitches);
        // inject the feature switches for use in non-component files
        configurationHelper.injectFeatureSwitches(result.featureSwitches.featureSwitches);

        yield put(Actions.actionFactory.configurationLoaded(vm));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

interface IConfiguration {
    shellPortalLinks: Client.GetShellPortalLinksResponseDto;
    roleConfiguration: Client.GetShellSecureUserRoleConfigurationResponseDto;
    featureSwitches: Client.GetShellFeatureSwitchesResponseDto;
}

async function load(): Promise<IConfiguration> {
    const client = await ClientFactory.createShellAnonymousClient();

    return {
        shellPortalLinks: await client.getShellPortalLinks(new Client.GetShellPortalLinksRequestDto()),
        roleConfiguration: await client.getShellSecureUserRoleConfiguration(new Client.GetShellSecureUserRoleConfigurationRequestDto()),
        featureSwitches: await client.getShellFeatureSwitches(new Client.GetShellFeatureSwitchesRequestDto()),
    };
}
