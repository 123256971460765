import { takeEvery } from "redux-saga/effects";
import * as Actions from "./actions";

import initialise from "./sagas/initialise";
import deleteReportAttachment from "./sagas/deleteReportAttachment";
import updateReportAttachment from "./sagas/updateReportAttachment";

export default function* watcherSaga() {
    yield takeEvery(Actions.actions.initialiseRequested, initialise);
    yield takeEvery(Actions.actions.deleteReportAttachmentRequested, deleteReportAttachment);
    yield takeEvery(Actions.actions.updateReportAttachmentRequested, updateReportAttachment);
}
