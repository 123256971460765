// framework
import { useEffect, useState } from "react";
// kendo
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
// api
import * as Client from "../../../../api/Client";
// common
import Help from "../../../../common/help/HelpView";
import * as Forms from "../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../common/GlobalHelpers";

export function QuestionsView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const questions = vm.user.questions;
    const actions = props.actions;

    return (
        <div className="card mb-2">
            <div className="card-body">
                <Forms.Group>
                    <Forms.Label htmlFor="jointAuthoritiesList">
                        <strong>Select the Jurisdiction that you are requesting access to</strong>
                    </Forms.Label>
                    <JointAuthoritiesListView id="jointAuthoritiesList" vm={vm} actions={actions} />
                </Forms.Group>
                {(vm.viewState.showExistingJointAuthorityNewAdministratorWizard || vm.viewState.showExistingJointAuthorityExistingAdministratorWizard) && (
                    <p>
                        You have requested access to <i>{questions.selectedJointAuthority?.jointAuthorityName} Joint Authority</i>
                    </p>
                )}
                {vm.viewState.showExistingJointAuthorityNewAdministratorWizard && (
                    <Forms.Group>
                        <JointAuthorityAdministratorInformationView />
                    </Forms.Group>
                )}
                {vm.viewState.showExistingJointAuthorityExistingAdministratorWizard && (
                    <Forms.Group>
                        <p className="fw-bold">Are you applying to be a Joint Authority Administrator?</p>
                        <JointAuthorityAdministratorRadioButtonView vm={vm} actions={actions} />
                        <JointAuthorityAdministratorInformationView />
                    </Forms.Group>
                )}
                {/* {vm.viewState.showCompanySignerWizard && (
                    <Forms.Group>
                        <p className="fw-bold">Are you applying to be a Company Signer?</p>
                        <CompanySignerRadioButtonView vm={vm} actions={actions} />
                        <CompanySignerInformationView csNoptaSignatureGuidanceLink={props.csNoptaSignatureGuidanceLink} />
                    </Forms.Group>
                )} */}
            </div>
        </div>
    );
}

// TODO, find a way to make this re-useable.  probably not straight away, but next combobox we need let's look at this.
function JointAuthoritiesListView(props: { id: string; vm: Models.IRootViewModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const questions = vm.user.questions;
    const jointAuthorities = vm.reference.jointAuthorities;
    const actions = props.actions;

    const [data, setData] = useState<Array<Client.GetRegistrationJointAuthorityJointAuthorityDto>>();
    const [selectedItem, setSelectedItem] = useState<Client.GetRegistrationJointAuthorityJointAuthorityDto | undefined | null>(questions.selectedJointAuthority);

    // update reference data if changed
    useEffect(() => {
        setData(jointAuthorities);
    }, [jointAuthorities]);

    // update selected item if changed - must be set to null in order to programatically clear the combobox
    useEffect(() => {
        if (questions.selectedJointAuthority) {
            setSelectedItem(questions.selectedJointAuthority);
        } else {
            setSelectedItem(null);
            setData(jointAuthorities);
        }
    }, [questions.selectedJointAuthority, jointAuthorities]);

    // filter
    const filterChange = (event: any) => {
        const data = filterBy(jointAuthorities!.slice(), event.filter);
        setData(data);
    };

    // on change
    const onChange = (event: any) => {
        const i: Client.GetRegistrationJointAuthorityJointAuthorityDto | undefined = event.value;

        setSelectedItem(i);
        actions.viewChanged(vm.refreshSelectedJointAuthorityChanged(i ?? undefined));
    };

    return (
        <ComboBox
            id={props.id}
            className="mb-2"
            data={data}
            dataItemKey="jointAuthorityId"
            value={selectedItem}
            textField="jointAuthorityName"
            filterable={true}
            onFilterChange={filterChange}
            onChange={onChange}
            popupSettings={{
                height: "auto",
            }}
        />
    );
}

function JointAuthorityAdministratorInformationView() {
    return (
        <Help title="What is a Joint Authority Administrator?">
            A Joint Authority Administrator is a role that is approved by delegated decision maker of the Joint Authority, Commonwealth Minister, or Greenhouse Gas Cross Boundary Authority under the
            OPGGSA 2006 and then is able to approve access for other users. A Joint Authority Administrator is able to assign all permissions within NEATS for their Joint Authority with the exception
            of approving another Joint Authority Administrator.
        </Help>
    );
}

function JointAuthorityAdministratorRadioButtonView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const questions = vm.user.questions;
    const actions = props.actions;

    const isJaaRequestRadioValue = "isJaaRequest";
    const isNotJaaRequestRadioValue = "isNotJaaRequest";
    const [selectedJaaRequestRadioValue, setSelectedJaaRequestRadioValue] = useState(
        GlobalHelpers.convertBooleanToRadioValue(questions.isJointAuthorityAdministratorRequest, isJaaRequestRadioValue, isNotJaaRequestRadioValue)
    );

    // update selection if changed
    useEffect(() => {
        setSelectedJaaRequestRadioValue(GlobalHelpers.convertBooleanToRadioValue(questions.isJointAuthorityAdministratorRequest, isJaaRequestRadioValue, isNotJaaRequestRadioValue));
    }, [questions.isJointAuthorityAdministratorRequest]);

    // on change
    function onChange(value: string) {
        setSelectedJaaRequestRadioValue(value);
        if (value === isJaaRequestRadioValue) actions.viewChanged(vm.refreshQuestionsUpdated({ isJointAuthorityAdministratorRequest: true }));
        if (value === isNotJaaRequestRadioValue) actions.viewChanged(vm.refreshQuestionsUpdated({ isJointAuthorityAdministratorRequest: false }));
    }

    return (
        <>
            <Forms.RadioButton
                name="isJaaRequestGroup"
                id="isJaaRequestRadioField"
                value={isJaaRequestRadioValue}
                checked={selectedJaaRequestRadioValue === isJaaRequestRadioValue}
                label="Yes"
                onChange={onChange}
                inline
            />
            <Forms.RadioButton
                name="isCaRequestGroup"
                id="isNotCaRequestRadioField"
                value={isNotJaaRequestRadioValue}
                checked={selectedJaaRequestRadioValue === isNotJaaRequestRadioValue}
                label="No"
                onChange={onChange}
                inline
            />
        </>
    );
}

// function CompanySignerRadioButtonView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }) {
//     const vm = props.vm;
//     const questions = vm.user.questions;
//     const actions = props.actions;

//     const isCsRequestRadioValue = "isCsRequest";
//     const isNotCsRequestRadioValue = "isNotCsRequest";
//     const [selectedCsRequestRadioValue, setSelectedCsRequestRadioValue] = useState(
//         GlobalHelpers.convertBooleanToRadioValue(questions.isCompanySignerRequest, isCsRequestRadioValue, isNotCsRequestRadioValue)
//     );

//     // update selection if changed
//     useEffect(() => {
//         setSelectedCsRequestRadioValue(GlobalHelpers.convertBooleanToRadioValue(questions.isCompanySignerRequest, isCsRequestRadioValue, isNotCsRequestRadioValue));
//     }, [questions.isCompanySignerRequest]);

//     // on change
//     function onChange(value: string) {
//         setSelectedCsRequestRadioValue(value);
//         if (value === isCsRequestRadioValue) actions.viewChanged(vm.refreshQuestionsUpdated({ isCompanySignerRequest: true }));
//         if (value === isNotCsRequestRadioValue) actions.viewChanged(vm.refreshQuestionsUpdated({ isCompanySignerRequest: false }));
//     }

//     return (
//         <>
//             <Forms.RadioButton
//                 name="isCsRequestGroup"
//                 id="isCsRequestRadioField"
//                 value={isCsRequestRadioValue}
//                 checked={selectedCsRequestRadioValue === isCsRequestRadioValue}
//                 label="Yes"
//                 onChange={onChange}
//                 inline
//             />
//             <Forms.RadioButton
//                 name="isCsRequestGroup"
//                 id="isNotCsRequestRadioField"
//                 value={isNotCsRequestRadioValue}
//                 checked={selectedCsRequestRadioValue === isNotCsRequestRadioValue}
//                 label="No"
//                 onChange={onChange}
//                 inline
//             />
//         </>
//     );
// }
