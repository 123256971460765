// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_ReportsAndData_SurveyDataLines;

export default function* update() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const importResponse: CallHelper.CallResult<Client.CreateGeoscienceAustraliaSurveyDataLineResponseDto> = yield call(async () => await importSurveyDataLines(vm));

        if (importResponse.IsSuccess) {
            // success
            const r = importResponse.Response!;
            if (r.statusMessages?.isSuccess) {
                let updatedVm = vm;
                const getLinesResponse: CallHelper.CallResult<Client.GetGeoscienceAustraliaSurveyDataLinesResponseDto> = yield call(async () => await getSurveyDataLines(vm));
                updatedVm = vm.refreshLines(getLinesResponse.Response!);
                yield put(Actions.actionFactory.sagaCompleted(updatedVm.refreshImportSurveyDataLinesFile(undefined).setViewState(Models.ViewStateEnum.View).refreshStatusMessages(r.statusMessages)));
            } else {
                yield put(Actions.actionFactory.sagaCompleted(vm.refreshImportSurveyDataLinesFile(undefined).refreshStatusMessages(r.statusMessages)));
            }
        } else if (importResponse.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.setViewState(Models.ViewStateEnum.View).refreshConflict(true)));
        } else {
            // all other errors
            importResponse.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function importSurveyDataLines(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.ImportGeoscienceAustraliaSurveyDataLinesResponseDto>> {
    const request = new Client.ImportGeoscienceAustraliaSurveyDataLinesRequestDto({
        surveyDataId: vm.surveyDataId!,
        surveyDataVersionNumber: vm.surveyDataVersionNumber!,
        fileName: vm.importSurveyDataLinesFile?.fileName,
        fileContent: vm.importSurveyDataLinesFile?.fileContent,
    });

    const client = await ClientFactory.createGeoscienceAustraliaSurveyDataClient();
    return await CallHelper.call(() => client.importGeoscienceAustraliaSurveyDataLines(request));
}

async function getSurveyDataLines(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.GetGeoscienceAustraliaSurveyDataLinesResponseDto>> {
    const request = new Client.GetGeoscienceAustraliaSurveyDataLinesRequestDto({
        surveyDataId: vm.surveyDataId!,
    });

    const client = await ClientFactory.createGeoscienceAustraliaSurveyDataClient();
    return await CallHelper.call(() => client.getGeoscienceAustraliaSurveyDataLines(request));
}
