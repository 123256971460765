// models
import { IRootViewModel } from "../../models/models";
// common
import * as Forms from "../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";

interface IProps {
    vm: IRootViewModel;
}

export default function RigDetailsView(props: IProps): JSX.Element {
    const { vm } = props;

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Details</h2>
                    <Forms.Row>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="nameField" label="Name" value={vm.rig?.name} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="statusField" label="Status" value={vm.rig?.status} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="companyOwnerField" label="Company Owner" value={vm.rig?.companyOwner} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="typeField" label="Type" value={vm.rig?.type} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="makeField" label="Make" value={vm.rig?.make} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="modelField" label="Model" value={vm.rig?.model} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="effectiveFromDateField" label="Effective From Date" value={GlobalHelpers.toNoptaDateString(vm.rig?.effectiveFromDate)} />
                        </Forms.HalfColumn>
                    </Forms.Row>
                </div>
            </div>
        </>
    );
}
