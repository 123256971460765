// redux
import * as Models from "../../../models/models";
// common
import SimpleAlertView from "../../../../../../../../../common/simpleAlerts/SimpleAlertView";

export default function InformationView(props: { vm: Models.IDigitalSignatureViewModel }): React.ReactElement {
    const { vm } = props;

    const showNoCompaniesMessage = vm.viewState === Models.ViewStateEnum.Edit && !vm.digitalSignatureDetails.canSign;
    const message = showNoCompaniesMessage ? `There are no companies that you can sign for. Click "Cancel" to exit.` : null;

    return <>{message && <SimpleAlertView alertType="information" dismissable={false} message={message} />}</>;
}
