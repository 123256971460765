// framework
import { cloneDeep } from "lodash";
// models
import * as Models from "../../../../common/draftApplicationDetails/components/models/models";
// api
import * as Client from "../../../../../api/Client";

export interface IEpRenewalViewModel {
    isExtensionAccepted?: boolean | undefined;
}

export interface IEpRenewalDetailsViewModel extends IEpRenewalViewModel {
    titleId?: string | undefined;
    titleNumber?: string | undefined;
    titleExpiryDate?: Date | undefined;
}

export interface IEpRenewalApplicationDetailsViewModel extends Models.IApplicationDetailsViewModel {
    epRenewalDetails: IEpRenewalDetailsViewModel;
}

export interface IEpRenewalBlocksViewModel {
    blocksRenewed?: number | undefined;
    blocksRelinquished?: number | undefined;
}

export interface IEpRenewalBlockDetailsViewModel extends IEpRenewalBlocksViewModel {
    numberOfBlocks?: number | undefined;
    numberOfLocationBlocks?: number | undefined;
    locations?: string | undefined;
    isDirty: boolean;
}

export interface IRootViewModel extends Models.IDraftApplicationRootViewModel {
    applicationDetails: IEpRenewalApplicationDetailsViewModel;
    blockDetails: IEpRenewalBlockDetailsViewModel;

    // view triggered state changes
    refreshEpRenewalUpdated(values: IEpRenewalViewModel): IRootViewModel;
    refreshEpRenewalBlocksUpdated(values: IEpRenewalBlocksViewModel): IRootViewModel;
}

export class RootViewModel extends Models.BaseDraftApplicationRootViewModel implements IRootViewModel {
    constructor() {
        super();
        this.applicationDetails = {
            isDigitalSignatures: false,
            epRenewalDetails: {},
            isDirty: false,
        };
        this.blockDetails = {
            isDirty: false,
        };
    }

    public override applicationDetails: IEpRenewalApplicationDetailsViewModel;
    public blockDetails: IEpRenewalBlockDetailsViewModel;

    public override refreshDetails(response: Client.GetCompanyOpggsDraftApplicationDetailsEpRenewalResponseDto): this {
        const vm = super.refreshDetails(response);

        // EP Renewal details
        vm.applicationDetails.epRenewalDetails.titleId = response.titleId;
        vm.applicationDetails.epRenewalDetails.titleNumber = response.titleNumber;
        vm.applicationDetails.epRenewalDetails.titleExpiryDate = response.titleExpiryDate;
        vm.applicationDetails.epRenewalDetails.isExtensionAccepted = response.isExtensionAccepted;
        vm.applicationDetails.isDirty = false;
        // Block details
        vm.blockDetails.numberOfBlocks = response.numberOfBlocks;
        vm.blockDetails.numberOfLocationBlocks = response.numberOfLocationBlocks;
        vm.blockDetails.locations = response.locations;
        vm.blockDetails.blocksRenewed = response.blocksRenewed;
        vm.blockDetails.blocksRelinquished = response.blocksRelinquished;
        vm.blockDetails.isDirty = false;

        return vm;
    }

    public refreshEpRenewalUpdated(values: IEpRenewalViewModel): IRootViewModel {
        const vm = this._clone();

        vm.applicationDetails.epRenewalDetails = { ...vm.applicationDetails.epRenewalDetails, ...values };
        vm.applicationDetails.isDirty = true;

        return vm;
    }

    public refreshEpRenewalBlocksUpdated(values: IEpRenewalBlocksViewModel): IRootViewModel {
        const vm = this._clone();

        vm.blockDetails = { ...vm.blockDetails, ...values };
        vm.blockDetails.isDirty = true;

        return vm;
    }

    protected override _clone(): this {
        const vm = super._clone();

        vm.blockDetails = cloneDeep(this.blockDetails);

        return vm;
    }
}
