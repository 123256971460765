// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// common
import * as geoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";
// views
import NavBar, { TabsEnum } from "../../components/views/NavBarView";
import DataMonitoringListGridView from "./views/DataMonitoringListGridView";
import LinksView, { LinkDetail } from "../../../../common/links/LinksView";

interface IRouteParameters {
    surveyId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { surveyId } = params;

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(surveyId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {vm.surveyName && (
                <BodyLayout
                    title={`Survey - ${vm.surveyName}`}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_SurveysAcquisitions_Surveys}
                    onReloadRequested={() => {
                        props.initialiseRequested(Number(surveyId));
                    }}
                >
                    <NavBar surveyId={Number(surveyId)} activeNavItem={TabsEnum.DataMonitoring} />
                    <LinksView
                        title="Acquisition(s)"
                        canViewLink={vm.permissions.canViewSurveyAcquisitions}
                        linkTooltip="Click to view Acquisition Data Monitoring."
                        links={vm.acquisitions.map((a) => new LinkDetail(a.name, geoscienceAustraliaRouteHelper.acquisitionDataMonitoring(vm.surveyId!, a.id)))}
                    />
                    <DataMonitoringListGridView vm={vm} />
                </BodyLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_SurveysAcquisitions_SurveyDataMonitoring }), Actions.actionFactory)(Controller);
