// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../../api/Client";
// common
import * as SecureDocumentDownloadButtonControl from "../../../../../../common/secureDocumentDownload/SecureDocumentDownloadButtonControl";

export interface IDocumentViewModel {
    authorisationId: string | undefined;
    fileName: string;
    fileSize: number;
    fileSizeMb: number;
    sensitiveToCompanyName: string | undefined;
    canDownload: boolean;
    isSelected: boolean;
}

export interface IRootViewModel {
    id: string | undefined;
    applicationFormDocuments: IDocumentViewModel[];
    supportingDocumentationDocuments: IDocumentViewModel[];
    hasUnavailableApplicationFormDocuments: boolean;
    hasUnavailableSupportingDocumentationDocuments: boolean;

    viewState: ViewStateEnum;
    numberOfApplicationFormDocumentsSelected: number;
    canSelectApplicationFormDocuments: boolean;
    allApplicationFormDocumentsSelected: boolean;
    numberOfSupportingDocumentationDocumentsSelected: number;
    canSelectSupportingDocumentationDocuments: boolean;
    allSupportingDocumentationDocumentsSelected: boolean;

    refreshDetails(id: string, response: Client.GetJointAuthorityOpggsApplicationDetailsDocumentationResponseDto): IRootViewModel;
    onInitialised(): IRootViewModel;
    getApplicationFormDocumentsForDownload(): Array<SecureDocumentDownloadButtonControl.IDocument>;
    onApplicationFormDocumentsSelected(isSelected: boolean): IRootViewModel;
    onApplicationFormDocumentSelected(authorisationId: string): IRootViewModel;
    getSupportingDocumentationDocumentsForDownload(): Array<SecureDocumentDownloadButtonControl.IDocument>;
    onSupportingDocumentationDocumentsSelected(isSelected: boolean): IRootViewModel;
    onSupportingDocumentationDocumentSelected(authorisationId: string): IRootViewModel;
}

export enum ViewStateEnum {
    Initialising,
    View,
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.id = undefined;
        this.applicationFormDocuments = [];
        this.supportingDocumentationDocuments = [];
        this.hasUnavailableApplicationFormDocuments = false;
        this.hasUnavailableSupportingDocumentationDocuments = false;

        this.viewState = ViewStateEnum.Initialising;
        this.numberOfApplicationFormDocumentsSelected = 0;
        this.canSelectApplicationFormDocuments = false;
        this.allApplicationFormDocumentsSelected = false;
        this.numberOfSupportingDocumentationDocumentsSelected = 0;
        this.canSelectSupportingDocumentationDocuments = false;
        this.allSupportingDocumentationDocumentsSelected = false;
    }

    public id: string | undefined;
    public applicationFormDocuments: IDocumentViewModel[];
    public supportingDocumentationDocuments: IDocumentViewModel[];
    public hasUnavailableApplicationFormDocuments: boolean;
    public hasUnavailableSupportingDocumentationDocuments: boolean;

    public viewState: ViewStateEnum;
    public numberOfApplicationFormDocumentsSelected: number;
    public allApplicationFormDocumentsSelected: boolean;
    public canSelectApplicationFormDocuments: boolean;
    public numberOfSupportingDocumentationDocumentsSelected: number;
    public allSupportingDocumentationDocumentsSelected: boolean;
    public canSelectSupportingDocumentationDocuments: boolean;

    public refreshDetails(id: string, response: Client.GetJointAuthorityOpggsApplicationDetailsDocumentationResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.id = id;
        vm.applicationFormDocuments = response.applicationFormDocuments.map((d) => new DocumentViewModel(d.authorisationId, d.documentName, d.documentSize, d.canDownload, false));
        vm.supportingDocumentationDocuments = response.supportingDocumentationDocuments.map(
            (d) => new DocumentViewModel(d.authorisationId, d.documentName, d.documentSize, d.canDownload, false, d.sensitiveToCompanyName)
        );
        vm.hasUnavailableApplicationFormDocuments = response.hasUnavailableApplicationFormDocuments;
        vm.hasUnavailableSupportingDocumentationDocuments = response.hasUnavailableSupportingDocumentationDocuments;

        this._setCalculatedFields(vm);
        return vm;
    }

    public onInitialised(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Initialising) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    public getApplicationFormDocumentsForDownload(): Array<SecureDocumentDownloadButtonControl.IDocument> {
        if (this.applicationFormDocuments.some((d) => d.isSelected && !d.canDownload)) throw new Error("Invalid operation.");

        return this.applicationFormDocuments
            .filter((d) => d.isSelected)
            .map((d) => {
                return { id: d.authorisationId!, size: d.fileSize };
            });
    }

    // does not trigger dirty state
    public onApplicationFormDocumentSelected(authorisationId: string): IRootViewModel {
        if (this.viewState !== ViewStateEnum.View) throw new Error("Invalid state.");

        const selectedDocument = this.applicationFormDocuments.find((d) => d.authorisationId === authorisationId);
        if (!selectedDocument) throw new Error("Invalid document.");
        if (!selectedDocument.canDownload) throw new Error("Invalid operation.");

        const vm = this._clone();

        vm.applicationFormDocuments = this.applicationFormDocuments.map((d) => {
            return new DocumentViewModel(
                d.authorisationId,
                d.fileName,
                d.fileSize,
                d.canDownload,
                d.authorisationId === authorisationId ? !d.isSelected : d.isSelected // toggles the selection
            );
        });

        this._setCalculatedFields(vm);
        return vm;
    }

    // does not trigger dirty state
    public onApplicationFormDocumentsSelected(isSelected: boolean): IRootViewModel {
        if (this.viewState !== ViewStateEnum.View) throw new Error("Invalid state.");

        const vm = this._clone();

        vm.applicationFormDocuments = this.applicationFormDocuments.map((d) => {
            return new DocumentViewModel(
                d.authorisationId,
                d.fileName,
                d.fileSize,
                d.canDownload,
                d.canDownload ? isSelected : d.isSelected // sets all that can be selected to a specific value
            );
        });

        this._setCalculatedFields(vm);
        return vm;
    }

    public getSupportingDocumentationDocumentsForDownload(): Array<SecureDocumentDownloadButtonControl.IDocument> {
        if (this.supportingDocumentationDocuments.some((d) => d.isSelected && !d.canDownload)) throw new Error("Invalid operation.");

        return this.supportingDocumentationDocuments
            .filter((d) => d.isSelected)
            .map((d) => {
                return { id: d.authorisationId!, size: d.fileSize };
            });
    }

    // does not trigger dirty state
    public onSupportingDocumentationDocumentSelected(authorisationId: string): IRootViewModel {
        if (this.viewState !== ViewStateEnum.View) throw new Error("Invalid state.");

        const selectedDocument = this.supportingDocumentationDocuments.find((d) => d.authorisationId === authorisationId);
        if (!selectedDocument) throw new Error("Invalid document.");
        if (!selectedDocument.canDownload) throw new Error("Invalid operation.");

        const vm = this._clone();

        vm.supportingDocumentationDocuments = this.supportingDocumentationDocuments.map((d) => {
            return new DocumentViewModel(
                d.authorisationId,
                d.fileName,
                d.fileSize,
                d.canDownload,
                d.authorisationId === authorisationId ? !d.isSelected : d.isSelected, // toggles the selection
                d.sensitiveToCompanyName
            );
        });

        this._setCalculatedFields(vm);
        return vm;
    }

    // does not trigger dirty state
    public onSupportingDocumentationDocumentsSelected(isSelected: boolean): IRootViewModel {
        if (this.viewState !== ViewStateEnum.View) throw new Error("Invalid state.");

        const vm = this._clone();

        vm.supportingDocumentationDocuments = this.supportingDocumentationDocuments.map((d) => {
            return new DocumentViewModel(
                d.authorisationId,
                d.fileName,
                d.fileSize,
                d.canDownload,
                d.canDownload ? isSelected : d.isSelected, // sets all that can be selected to a specific value
                d.sensitiveToCompanyName
            );
        });

        this._setCalculatedFields(vm);
        return vm;
    }

    private _setCalculatedFields(vm: RootViewModel) {
        vm.numberOfApplicationFormDocumentsSelected = vm.applicationFormDocuments.filter((d) => d.isSelected).length;
        const selectableApplicationFormDocuments = vm.applicationFormDocuments.filter((d) => d.canDownload);
        vm.canSelectApplicationFormDocuments = selectableApplicationFormDocuments.length > 0;
        vm.allApplicationFormDocumentsSelected = vm.canSelectApplicationFormDocuments && selectableApplicationFormDocuments.every((d) => d.isSelected);

        vm.numberOfSupportingDocumentationDocumentsSelected = vm.supportingDocumentationDocuments.filter((d) => d.isSelected).length;
        const selectableSupportingDocumentationDocuments = vm.supportingDocumentationDocuments.filter((d) => d.canDownload);
        vm.canSelectSupportingDocumentationDocuments = selectableSupportingDocumentationDocuments.length > 0;
        vm.allSupportingDocumentationDocumentsSelected = vm.canSelectSupportingDocumentationDocuments && selectableSupportingDocumentationDocuments.every((d) => d.isSelected);
    }

    private _clone(): RootViewModel {
        const vm = clone(this);

        return vm;
    }
}

class DocumentViewModel implements IDocumentViewModel {
    authorisationId: string | undefined;
    fileName: string;
    fileSize: number;
    fileSizeMb: number;
    canDownload: boolean;
    isSelected: boolean;
    sensitiveToCompanyName: string | undefined;

    constructor(authorisationId: string | undefined, fileName: string, fileSize: number, canDownload: boolean, isSelected: boolean, sensitiveToCompanyName?: string | undefined) {
        this.authorisationId = authorisationId;
        this.fileName = fileName;
        this.fileSize = fileSize;
        this.fileSizeMb = fileSize / 1024 / 1024;
        this.canDownload = canDownload;
        this.isSelected = isSelected;
        this.sensitiveToCompanyName = sensitiveToCompanyName;
    }
}
