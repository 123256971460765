// framework
import { combineReducers } from "redux";
// user components
import * as shell from "../shell/shellReduxRegistration";
import * as home from "../home/homeReduxRegistration";
import * as company from "../company/companyReduxRegistration";
import * as jointAuthority from "../jointAuthority/jointAuthorityReduxRegistration";
import * as geoscienceAustralia from "../geoscienceAustralia/geoscienceAustraliaReduxRegistration";

export interface IRootReduxState
    extends shell.IShellReduxState,
        home.IHomeReduxState,
        company.ICompanyReduxState,
        jointAuthority.IJointAuthorityReduxState,
        geoscienceAustralia.IGeoscienceAustraliaReduxState {}

// root reducer
const reducers = Object.assign({}, shell.buildReducers(), home.buildReducers(), company.buildReducers(), jointAuthority.buildReducers(), geoscienceAustralia.buildReducers());
const rootReducer = combineReducers(reducers);
export default rootReducer;
