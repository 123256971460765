// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";

export default function NoptaFeedbackView(props: { vm: string }) {
    const vm = props.vm;

    return (
        <div className="card mb-2">
            <div className="card-body">
                <h2 className="card-title">NOPTA Feedback</h2>
                <Forms.Group>
                    <Forms.ReadonlyTextArea id="noptaFeedbackField" value={vm} label="This Form Request was reviewed by NOPTA. Please refer to the feedback below." />
                </Forms.Group>
            </div>
        </div>
    );
}
