// framework
import { useEffect, useState } from "react";
import { connect } from "react-redux";
// kendo
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
// redux
import { IRootReduxState } from "../../../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import validator from "../models/validator";
import * as Actions from "../redux/actions";
// common
import * as toastHelper from "../../../../../../../common/toastHelper";
import StatusMessagesAlertsView from "../../../../../../../common/alerts/StatusMessagesAlertsView";
import { ValidationVisibilityEnum } from "../../../../../../../common/validation/ValidationModel";
import ConflictAlertView from "../../../../../../../common/alerts/ConflictAlertView";
// views
import BulkDeleteView from "./views/BulkDeleteView";

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
    onDelete: () => void;
    onCancel: () => void;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const { onDelete, onCancel } = props;
    const actions: Actions.IActionFactory = props;

    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const v = validator(vm, validationVisibility);

    // on mount
    useEffect(() => {
        actions.initialiseRequested();
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (vm.statusMessages && vm.statusMessages.isSuccess) onDelete();
    }, [vm.statusMessages, onDelete]); // eslint-disable-line react-hooks/exhaustive-deps

    const onBulkDelete = () => {
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        actions.bulkDeleteRequested(vm.bulkDeleteBatchItem!);
    };

    return (
        <>
            <Dialog title={"Delete Bulk Import"} width={600} onClose={() => onCancel()}>
                <ConflictAlertView isConflict={vm.isConflict} />
                <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
                <BulkDeleteView vm={vm} v={v} actions={actions} />
                <DialogActionsBar>
                    <button type="button" className="btn btn-primary m-2" onClick={() => onBulkDelete()}>
                        Delete
                    </button>
                    <button type="button" className="btn btn-secondary m-2" onClick={() => onCancel()}>
                        Cancel
                    </button>
                </DialogActionsBar>
            </Dialog>
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_WellsBoreholes_AttachmentList_BulkDelete }), Actions.actionFactory)(Controller);
