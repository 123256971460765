import { takeEvery } from "redux-saga/effects";
import * as Actions from "./actions";

import initialise from "./sagas/initialise";
import getMap from "./sagas/getMap";
import getCreateDraftApplicationReferenceData from "./sagas/getCreateDraftApplicationReferenceData";
import createDraftApplication from "./sagas/createDraftApplication";

export default function* watcherSaga() {
    // note, this also fires an event to  initialise the map
    yield takeEvery(Actions.actions.initialiseRequested, initialise);

    yield takeEvery(Actions.actions.getMapRequested, getMap);
    yield takeEvery(Actions.actions.getCreateDraftApplicationReferenceDataRequested, getCreateDraftApplicationReferenceData);
    yield takeEvery(Actions.actions.createDraftApplicationRequested, createDraftApplication);
}
