// redux
import * as Models from "../../models/models";
// common
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import TitleLinkController from "../../../../../common/links/TitleLinkController";

export default function HeaderView(props: { vm: Models.IApplicationHeaderViewModel }) {
    const header = props.vm;

    return (
        <>
            <div className="d-flex flex-row flex-wrap">
                <div className="d-flex flex-row me-2 mb-2">
                    <span className="label me-2" style={{ fontWeight: "bold" }}>
                        Application Status:
                    </span>
                    <span>{header.applicationStatus}</span>
                </div>
                {/* Requirement is to display this as a date only */}
                <div className="d-flex flex-row me-2 mb-2">
                    <span className="label me-2" style={{ fontWeight: "bold" }}>
                        Application Status Date:
                    </span>
                    <span>{GlobalHelpers.toNoptaDateString(header.applicationStatusDatetime)}</span>
                </div>
            </div>
            <div className="d-flex flex-row flex-wrap">
                <div className="d-flex flex-row me-2 mb-2">
                    <span className="label me-2" style={{ fontWeight: "bold" }}>
                        Application Name:
                    </span>
                    <span>{header.applicationName}</span>
                </div>
                <div className="d-flex flex-row me-2 mb-2">
                    <span className="label me-2" style={{ fontWeight: "bold" }}>
                        Submitted Date:
                    </span>
                    <span>{GlobalHelpers.toNoptaDateString(header.submittedDate)}</span>
                </div>
                {header.titlesLabel && header.titleLinks && (
                    <div className="d-flex flex-row me-2 mb-2">
                        <span className="label me-2" style={{ fontWeight: "bold" }}>
                            {header.titlesLabel}
                        </span>
                        <span>{header.titleLinks.map((t, i) => [i > 0 && ", ", <TitleLinkController key={i} linkDto={t} secureEntityType="JointAuthority" />])}</span>
                    </div>
                )}
            </div>
        </>
    );
}
