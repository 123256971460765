// framework
import * as Validation from "../../../../common/validation/ValidationModel";
// api
// import { SecurePortalCountryDto } from "../../../../api/Client";
// models
import * as Models from "./models";

export default function validate(vm: Models.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateJointAuthorityStandardRoleRequest(vm.user.jointAuthorityStandardRoleRequest, vm.user.questions, v);
    //validateJointAuthoritySignerRequest(vm.user.questions, v); todo nduja still coming
    validateJaaAuthoritySupportingDocument(vm.user.jaaAuthoritySupportingDocument, vm.user.questions, v);
    // validateJasAuthoritySupportingDocument(vm.user.csAuthoritySupportingDocument, vm.user.questions, v);
    // validateCsPoaAuthoritySupportingDocument(vm.user.csPoaSupportingDocument, vm.user.questions, v);
    validateComments(vm.user.comments, v);
    return v;
}

function validateJointAuthorityStandardRoleRequest(vm: Models.IJointAuthorityStandardRoleRequestViewModel, questions: Models.IQuestionsViewModel, v: Validation.ValidationModel): void {
    if (!questions.isJointAuthorityStandardRoleRequest) return;

    if (!vm.requestedRoles!.hasRole()) {
        // todo nduja reinstate the line below once signer is online
        //if (!questions.isJointAuthoritySignerRequest && !vm.requestedRoles!.hasRole()) {
        v.addError("JointAuthorityStandardRoleRequest", "At least one permission must be requested.");
    }

    if (vm.requestedRoles!.getDirectRoles().some((role) => vm.existingRoles!.hasRole(role))) {
        v.addError("JointAuthorityStandardRoleRequest", "Existing permissions cannot be requested.");
    }
}

// todo nduja still coming
// function validateJointAuthoritySignerRequest(questions: Models.IQuestionsViewModel, v: Validation.ValidationModel): void {
//     if (!questions.isJointAuthoritySignerRequest) return;

//     if (questions.isJointAuthoritySignerPoaRequest === undefined) v.addError("JointAuthoritySignerRequest.isCsPoaRequestGroup", "An option must be selected.");
// }

function validateSupportingDocument(validatorName: string, supportingDocument: Models.ISupportingDocumentViewModel, v: Validation.ValidationModel) {
    if (Validation.isNullOrWhiteSpace(supportingDocument.fileName) || !supportingDocument.content) {
        v.addError(validatorName, "Supporting Documentation must be supplied.");
        return;
    }

    if (Validation.isNullOrWhiteSpace(supportingDocument.fileName) || supportingDocument.fileName!.length === 0) {
        v.addError(validatorName, "Supporting Documentation file names cannot be blank.");
    }

    if (supportingDocument.fileName && supportingDocument.fileName.length > 100) {
        v.addError(validatorName, "Supporting Documentation file names must be 100 characters or less.");
    }
}

function validateJaaAuthoritySupportingDocument(jaaAuthoritySupportingDocument: Models.ISupportingDocumentViewModel, questions: Models.IQuestionsViewModel, v: Validation.ValidationModel): void {
    if (!questions.isJointAuthorityAdministratorRequest) return;

    validateSupportingDocument("JaaAuthoritySupportingDocument", jaaAuthoritySupportingDocument, v);
}

// function validateJasAuthoritySupportingDocument(jasAuthoritySupportingDocument: Models.ISupportingDocumentViewModel, questions: Models.IQuestionsViewModel, v: Validation.ValidationModel): void {
//     if (!questions.isJointAuthoritySignerRequest) return;

//     validateSupportingDocument("JasAuthoritySupportingDocument", jasAuthoritySupportingDocument, v);
// }

// function validateCsPoaAuthoritySupportingDocument(jasPoaSupportingDocument: Models.ISupportingDocumentViewModel, questions: Models.IQuestionsViewModel, v: Validation.ValidationModel): void {
//     if (!questions.isJointAuthoritySignerPoaRequest) return;

//     validateSupportingDocument("JasPoaSupportingDocument", jasPoaSupportingDocument, v);
// }

function validateComments(comments: string, v: Validation.ValidationModel): void {
    if (!comments) return;
    if (comments.length > 1000) v.addError("Comments.Comments", "Comments must be 1000 characters or less.");
}
