// framework
import * as Validation from "../../../../../common/validation/ValidationModel";
// models
import * as Models from "./models";

export default function validate(vm: Models.IEpRenewalApplicationDetailsViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateEpRenewalDetails(vm.epRenewalDetails, v);
    return v;
}

function validateEpRenewalDetails(vm: Models.IEpRenewalDetailsViewModel, v: Validation.ValidationModel): void {
    if (vm.isExtensionAccepted === undefined) {
        v.addError("EpRenewalDetails.IsExtensionAccepted", "A selection is required.");
    }
}
