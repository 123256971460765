// framework
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import validator from "../models/validator";
import * as Actions from "../redux/actions";
// common
import * as toastHelper from "../../../../../common/toastHelper";
import BodyLayout from "../../../../../common/shell/BodyLayoutController";
import { ValidationVisibilityEnum } from "../../../../../common/validation/ValidationModel";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
import * as GeoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";
import DirtyScope from "../../../../../common/shell/DirtyScopeController";
import ConflictAlertView from "../../../../../common/alerts/ConflictAlertView";
import StatusMessagesAlertsView from "../../../../../common/alerts/StatusMessagesAlertsView";
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
// views
import NavBar, { TabsEnum } from "../../components/views/NavBarView";
import ReportListGridView from "./views/ReportListGridView";
import ReportBatchUpdateView from "./views/ReportBatchUpdateView";
import LinksView, { LinkDetail } from "../../../../common/links/LinksView";
// api
import { CreateReportRelatedActivityTypeEnum } from "../../../../../api/Client";

interface IRouteParameters {
    wellId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { wellId } = params;

    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const validation = validator(vm, validationVisibility);

    const history = useHistory();

    const onCreate = () => {
        history.push(GeoscienceAustraliaRouteHelper.reportCreate(CreateReportRelatedActivityTypeEnum.WellBorehole, vm.wellId));
    };

    function onSelectBatchUpdate(): void {
        actions.viewChanged(vm.onSelectBatchUpdate());
    }

    function onEditBatchUpdate(): void {
        actions.viewChanged(vm.onEditBatchUpdate());
    }

    function onBatchUpdate(): void {
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        actions.batchUpdateRequested(vm);
    }

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(wellId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {vm.wellName && vm.wellBoreholes && (
                <BodyLayout
                    title={`Well - ${vm.wellName}`}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_WellsBoreholes_Wells}
                    onReloadRequested={() => {
                        props.clearRequested();
                        props.initialiseRequested(Number(wellId));
                    }}
                >
                    <NavBar wellId={Number(wellId)} activeNavItem={TabsEnum.Reports} />
                    <ConflictAlertView isConflict={vm.isConflict} />
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
                    <DirtyScope scopeName="geoscienceAustralia/wellBoreholes/borehole/reports" isDirty={vm.isDirty}></DirtyScope>
                    <LinksView
                        title="Borehole(s)"
                        canViewLink={true}
                        linkTooltip="Click to view Borehole Reports."
                        links={vm.wellBoreholes.map((wellBorehole) => new LinkDetail(wellBorehole.name, GeoscienceAustraliaRouteHelper.boreholeReports(vm.wellId!, wellBorehole.id)))}
                    />
                    <ReportListGridView vm={vm} reportUpdated={(report) => actions.viewChanged(vm.onReportUpdated(report))} />
                    {vm.viewState === Models.ViewStateEnum.SelectBatchUpdate && <ValidationMessageView name="batchUpdate.ItemSelected" validationModel={validation} />}
                    {vm.viewState === Models.ViewStateEnum.EditBatchUpdate && <ReportBatchUpdateView vm={vm} updateVm={(m) => actions.viewChanged(m)} v={validation} />}
                    <div className="container-fluid p-2 form">
                        <div className="mt-3">
                            {vm.viewState === Models.ViewStateEnum.View && (
                                <>
                                    {vm.reports.length > 0 && (
                                        <button className="btn btn-primary me-2" onClick={() => onSelectBatchUpdate()}>
                                            Batch Update
                                        </button>
                                    )}
                                    <button type="button" className="btn btn-primary me-2" onClick={onCreate}>
                                        Create Report
                                    </button>
                                </>
                            )}
                            {vm.viewState === Models.ViewStateEnum.SelectBatchUpdate && (
                                <>
                                    <button
                                        className="btn btn-primary me-2"
                                        disabled={!vm.reports.some((item) => item.isSelected) || vm.reports.filter((item) => item.isSelected).length > 100}
                                        onClick={() => onEditBatchUpdate()}
                                    >
                                        Confirm Reports
                                    </button>
                                    <button className="btn btn-secondary me-2" onClick={() => actions.initialiseRequested(Number(wellId))}>
                                        Cancel
                                    </button>
                                </>
                            )}
                            {vm.viewState === Models.ViewStateEnum.EditBatchUpdate && (
                                <>
                                    <button className="btn btn-primary me-2" onClick={() => onBatchUpdate()}>
                                        Batch Update
                                    </button>
                                    <button className="btn btn-secondary me-2" onClick={() => actions.initialiseRequested(Number(wellId))}>
                                        Cancel
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </BodyLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_WellsBoreholes_WellReports }), Actions.actionFactory)(Controller);
