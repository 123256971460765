// framework
import { useEffect, useState } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import validator from "../models/validator";
import * as Actions from "../redux/actions";
// common
import DirtyScope from "../../../../../common/shell/DirtyScopeController";
import ConflictAlertView from "../../../../../common/alerts/ConflictAlertView";
import StatusMessagesAlertsView from "../../../../../common/alerts/StatusMessagesAlertsView";
import * as toastHelper from "../../../../../common/toastHelper";
import BodyLayout from "../../../../../common/shell/BodyLayoutController";
import { ValidationVisibilityEnum } from "../../../../../common/validation/ValidationModel";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
import { IAdvancedSearchFilter } from "../../../../../common/advancedSearch/models";
import SearchBarView from "../../../../../common/advancedSearch/SearchBarView";
import SimpleAlertView from "../../../../../common/simpleAlerts/SimpleAlertView";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import DownloadLinkView from "../../../../../common/externalLink/DownloadLinkView";
import { KendoSingleSmallFileUpload } from "../../../../../common/fileUpload/KendoSingleSmallFileUpload";
// views
import SurveyDataListGridView from "./views/SurveyDataListGridView";
import SurveyDataBatchUpdateView from "./views/SurveyDataBatchUpdateView";

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const [searchSubmitted, setSearchSubmitted] = useState<boolean>();
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [enableImportButton, setEnableImportButton] = useState(false);

    // on mount
    useEffect(() => {
        actions.initialiseRequested();
        return function () {
            actions.clearRequested();
            setEnableImportButton(false);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSearchSubmit = (value: string, advancedFilter: IAdvancedSearchFilter) => {
        setSearchSubmitted(true);
        actions.viewChanged(vm.changeSearchFilter(value, advancedFilter));
        actions.searchRequested();
    };

    const onGridClear = () => {
        setSearchSubmitted(false);
        // note that this also triggers a grid state reset
        actions.viewChanged(vm.clearAllSearchFilters());
        //actions.searchRequested();
        actions.initialiseRequested();
    };

    function onExport(): void {
        actions.exportRequested(true);
    }

    function onCancelBulkImport(): void {
        actions.viewChanged(vm.refreshStatusMessages().setViewState(Models.ViewStateEnum.View).refreshImportSurveyDataFile());
    }

    function onUploadedFileChange(fileName: string | undefined, content?: string | undefined): void {
        actions.viewChanged(vm.refreshImportSurveyDataFile({ fileName: fileName!, fileContent: content! }));
        setEnableImportButton(true);
    }

    function onImport(): void {
        actions.uploadSurveyDataFile(vm);
        actions.viewChanged(vm.refreshImportSurveyDataFile());
        setEnableImportButton(false);
    }

    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const validation = validator(vm, validationVisibility);

    // const history = useHistory();
    // const onCreate = () => {
    //     history.push(GeoscienceAustraliaRouteHelper.surveyDataCreate());
    // };

    function onSelectBatchUpdate(): void {
        actions.viewChanged(vm.onSelectBatchUpdate());
    }

    function onEditBatchUpdate(): void {
        actions.viewChanged(vm.onEditBatchUpdate());
    }

    function onBatchUpdate(): void {
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        actions.batchUpdateRequested(vm);
    }

    const searchTextDescription =
        "The Survey Data search returns records where the word or phrase is contained in either Item ID (formerly Store Barcode), Survey Data Title, Data Type or Confidentiality Remarks.";
    const advancedSearchDescription = "The Advanced search filters are only applied when set, and returns records for a defined search from Surveys, Acquisitions, Reprocessings and/or Projects.";

    return (
        <>
            {vm.advancedSearchFilter && vm.viewState !== Models.ViewStateEnum.BulkImport && (
                <BodyLayout title="Search Survey Data" selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_ReportsAndData_SurveyData}>
                    {(vm.viewState === Models.ViewStateEnum.View || vm.viewState === Models.ViewStateEnum.Searching) && (
                        <>
                            <SearchBarView
                                searchText={vm.searchText}
                                searchTextDescription={searchTextDescription}
                                advancedSearchFilter={vm.advancedSearchFilter!}
                                advancedSearchDescription={advancedSearchDescription}
                                advancedSearchReferenceData={vm.advancedSearchReferenceData}
                                onSearchSubmit={onSearchSubmit}
                                onGridClear={onGridClear}
                                showAdvanced={showAdvanced}
                                setShowAdvanced={setShowAdvanced}
                            />
                        </>
                    )}
                    {vm.viewState !== Models.ViewStateEnum.Searching && (
                        <>
                            <ConflictAlertView isConflict={vm.isConflict} />
                            <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
                            <DirtyScope scopeName="geoscienceAustralia/reportsAndData/surveyData" isDirty={vm.isDirty}></DirtyScope>
                            {vm.isResultSetLimited && vm.viewState !== Models.ViewStateEnum.EditBatchUpdate && (
                                <SimpleAlertView
                                    alertType="warning"
                                    message={`For performance reasons, only the first ${GlobalHelpers.toNoptaNumberString(
                                        vm.surveyData.length,
                                        "n0"
                                    )} records have been returned. Please adjust your search criteria to locate relevant data.`}
                                    dismissable={true}
                                />
                            )}
                            {!searchSubmitted && (
                                <SimpleAlertView alertType="warning" message="No records have been returned on this screen. Please perform a search to locate relevant data." dismissable={true} />
                            )}
                            <SurveyDataListGridView vm={vm} surveyDataUpdated={(surveyData) => actions.viewChanged(vm.onSurveyDataUpdated(surveyData))} />
                            {vm.viewState === Models.ViewStateEnum.SelectBatchUpdate && <ValidationMessageView name="batchUpdate.ItemSelected" validationModel={validation} />}
                            {vm.viewState === Models.ViewStateEnum.EditBatchUpdate && <SurveyDataBatchUpdateView vm={vm} updateVm={(m) => actions.viewChanged(m)} v={validation} />}
                            <div className="container-fluid p-2 form">
                                <div className="mt-3">
                                    {vm.viewState === Models.ViewStateEnum.View && (
                                        <>
                                            <div className="row">
                                                {vm.surveyData.length > 0 && (
                                                    <>
                                                        <div className="col-auto">
                                                            <button className="btn btn-primary" onClick={() => onSelectBatchUpdate()}>
                                                                Batch Update
                                                            </button>
                                                        </div>
                                                        <div className="col-auto">
                                                            <button className="btn btn-primary" onClick={() => onExport()}>
                                                                Export to Excel
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                                {/* <div className="col-auto">
                                                    <button type="button" className="btn btn-primary" onClick={onCreate}>
                                                        Create Survey Data
                                                    </button>
                                                </div> */}
                                                {/* Hiding Bulk Import button while validation rules are finalised */}
                                                {/* <div className="col-auto">
                                                    <button type="button" className="btn btn-primary" onClick={() => actions.viewChanged(vm.setViewState(Models.ViewStateEnum.BulkImport))}>
                                                        Bulk Import
                                                    </button>
                                                </div> */}
                                            </div>
                                        </>
                                    )}
                                    {vm.viewState === Models.ViewStateEnum.SelectBatchUpdate && (
                                        <>
                                            <button
                                                className="btn btn-primary me-2"
                                                disabled={!vm.surveyData.some((item) => item.isSelected) || vm.surveyData.filter((item) => item.isSelected).length > 100}
                                                onClick={() => onEditBatchUpdate()}
                                            >
                                                Confirm Survey Data
                                            </button>
                                            <button className="btn btn-secondary me-2" onClick={() => actions.searchRequested()}>
                                                Cancel
                                            </button>
                                        </>
                                    )}
                                    {vm.viewState === Models.ViewStateEnum.EditBatchUpdate && (
                                        <>
                                            <button className="btn btn-primary me-2" onClick={() => onBatchUpdate()}>
                                                Batch Update
                                            </button>
                                            <button className="btn btn-secondary me-2" onClick={() => actions.searchRequested()}>
                                                Cancel
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </BodyLayout>
            )}

            {vm.viewState === Models.ViewStateEnum.BulkImport && (
                <BodyLayout title="Survey Data Bulk Import" selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_ReportsAndData_SurveyData}>
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
                    <p>
                        Use the template{" "}
                        <DownloadLinkView href="/SurveyDataBulkImport.xlsx" title="Click to download the document.">
                            SurveyDataBulkImport.xlsx
                        </DownloadLinkView>
                    </p>

                    <h5>File Upload</h5>

                    <KendoSingleSmallFileUpload onFileValidlySelected={onUploadedFileChange} allowedExtensions={[".xlsx"]} maxFileSizeMb={20} />

                    <div className="container-fluid p-2 form">
                        <div className="mt-3">
                            <>
                                <div className="row">
                                    <div className="col-auto">
                                        <button type="button" className="btn btn-primary" disabled={!enableImportButton} onClick={() => onImport()}>
                                            Import
                                        </button>
                                        <button type="button" className="btn btn-secondary m-2" onClick={() => onCancelBulkImport()}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </BodyLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_ReportsAndData_SurveyDataList }), Actions.actionFactory)(Controller);
