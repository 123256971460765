// api
import * as Client from "../../../../api/Client";
// kendo
import { orderBy } from "@progress/kendo-data-query";
// local
import * as Models from "./userDetailsRoleViewModel";
// other
import { clone, cloneDeep } from "lodash";
import * as GlobalHelpers from "../../../../common/GlobalHelpers";
import { BaseCompanyRoleRootViewModel, ICompanyRoleRootViewModel } from "../../../common/CompanyRoleRootViewModel";

export enum ViewStateEnum {
    View = "company.userManagement.userDetails.models.viewState.view",
    Edit = "company.userManagement.userDetails.models.viewState.edit",
    Removed = "company.userManagement.userDetails.models.viewState.removed",
}

export interface IDetailsViewModel {
    username?: string;
    accountStatus?: string;
    fullName?: string;
    isSecureUserBlocked?: boolean;
    emailAddress?: string;
    position?: string;
    contactNumber?: string;
    lastLoginDatetime?: string;
    registrationDatetime?: string;
    hasInactivityWarning?: boolean;
    relationshipExpirationDatetime?: string;
}

export interface IRootViewModel extends ICompanyRoleRootViewModel {
    id?: number;
    versionNumber?: number;

    details: IDetailsViewModel;

    permissions: Array<Models.IUserDetailsRoleViewModel>;

    service: {
        statusMessages?: Client.StatusMessagesDto;
    };

    isDirty: boolean;
    isConflict: boolean;
    viewState: ViewStateEnum;

    refreshUserDetails(response: Client.GetCompanyUserManagementUserDetailsResponseDto, resetState: boolean): IRootViewModel;
    refreshSaveUserPermissionsResponse(response: Client.SaveCompanyUserManagementUserDetailsRolesResponseDto): IRootViewModel;
    refreshRemoveUserResponse(response: Client.RemoveCompanyUserManagementUserDetailsUserResponseDto): IRootViewModel;
    refreshConflict(): IRootViewModel;
    // view triggered state changes
    refreshPermissionsChanged(permissions: Array<Models.IUserDetailsRoleViewModel>): IRootViewModel;
    setEditState(): IRootViewModel;
    clearEditState(): IRootViewModel;
    revokeAllAccess(allowRemovalOfSpecialPermissions: boolean): IRootViewModel;
}

export class RootViewModel extends BaseCompanyRoleRootViewModel implements IRootViewModel {
    constructor() {
        super();

        // initial state
        this.id = undefined;
        this.versionNumber = undefined;

        this.details = {};

        this.permissions = new Array<Models.IUserDetailsRoleViewModel>();

        this.service = {
            statusMessages: undefined,
        };

        this.isDirty = false;
        this.isConflict = false;
        this.viewState = ViewStateEnum.View;
    }

    public id: number | undefined;
    public versionNumber: number | undefined;

    public details: IDetailsViewModel;

    public permissions: Array<Models.IUserDetailsRoleViewModel>;

    public service: {
        statusMessages?: Client.StatusMessagesDto;
    };

    public isDirty: boolean;
    public isConflict: boolean;
    public viewState: ViewStateEnum;

    public refreshUserDetails(response: Client.GetCompanyUserManagementUserDetailsResponseDto, resetState: boolean): IRootViewModel {
        const vm = this._clone();

        if (resetState) {
            vm.service = { statusMessages: undefined };
            vm.isDirty = false;
            vm.isConflict = false;
            vm.viewState = ViewStateEnum.View;
        }

        vm.id = response.id;
        vm.versionNumber = response.versionNumber;

        vm.details.username = response.username;
        vm.details.accountStatus = response.accountStatus;
        vm.details.fullName = response.fullName;
        vm.details.isSecureUserBlocked = response.isSecureUserBlocked;
        vm.details.emailAddress = response.emailAddress;
        vm.details.position = response.position;
        vm.details.contactNumber = response.contactNumber;
        vm.details.lastLoginDatetime = GlobalHelpers.toNoptaDatetimeString(response.lastLoginDatetime);
        vm.details.registrationDatetime = GlobalHelpers.toNoptaDatetimeString(response.registrationDatetime);
        vm.details.hasInactivityWarning = response.hasInactivityWarning;
        vm.details.relationshipExpirationDatetime = GlobalHelpers.toNoptaDatetimeString(response.relationshipExpirationDatetime);

        const permissions = response.roles.map((p) => new Models.UserDetailsRoleViewModel(vm.roleConfiguration.roleRelationships!, p));
        vm.permissions = orderBy(permissions, [
            { field: "hasCompanyMembership", dir: "desc" },
            { field: "companyName", dir: "asc" },
        ]);

        return vm;
    }

    public refreshSaveUserPermissionsResponse(response: Client.SaveCompanyUserManagementUserDetailsRolesResponseDto): IRootViewModel {
        const vm = this._clone();
        const isSuccess = response.statusMessages.isSuccess;

        vm.service.statusMessages = response.statusMessages;
        // on success, set the vm as clean and view, otherwise leave dirty and edit
        vm.isDirty = !isSuccess;
        vm.isConflict = false;
        vm.viewState = isSuccess ? ViewStateEnum.View : ViewStateEnum.Edit;

        return vm;
    }

    public refreshRemoveUserResponse(response: Client.RemoveCompanyUserManagementUserDetailsUserResponseDto): IRootViewModel {
        const vm = this._clone();
        const isSuccess = response.statusMessages.isSuccess;

        vm.service.statusMessages = response.statusMessages;
        // on success, set the vm as clean and removed, otherwise leave dirty and edit
        vm.isDirty = !isSuccess;
        vm.isConflict = false;
        vm.viewState = isSuccess ? ViewStateEnum.Removed : ViewStateEnum.Edit;

        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        vm.service.statusMessages = undefined;
        return vm;
    }

    public refreshPermissionsChanged(permissions: Array<Models.IUserDetailsRoleViewModel>): IRootViewModel {
        const vm = this._clone();

        vm.permissions = permissions;
        vm.isDirty = true;

        return vm;
    }

    public setEditState(): IRootViewModel {
        const vm = this._clone();

        vm.viewState = ViewStateEnum.Edit;
        return vm;
    }

    public clearEditState(): IRootViewModel {
        const vm = this._clone();

        const resetPermissions = this.permissions.map((p) => p.resetRoles());

        vm.permissions = resetPermissions;
        vm.isDirty = false;
        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    public revokeAllAccess(allowRemovalOfSpecialPermissions: boolean): IRootViewModel {
        const vm = this._clone();

        const revokePermissions = this.permissions.map((p) => (p.canEdit() ? p.revokeAllRoles(allowRemovalOfSpecialPermissions) : p));

        vm.permissions = revokePermissions;
        vm.isDirty = true;

        return vm;
    }

    // private implementation
    // ----------------------
    private _clone(): RootViewModel {
        const vm = clone(this);

        vm.details = clone(this.details);
        vm.permissions = cloneDeep(this.permissions);
        vm.service = clone(this.service);

        return vm;
    }
}
