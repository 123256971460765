import { IRootViewModel } from "../../models/models";
import ReportAttachments from "../../../../../../common/reportAttachments/ReportAttachmentsControl";
import { GetGeoscienceAustraliaReportAttachmentsItemDto } from "../../../../../../api/Client";
import * as GeoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
import LinksView, { LinkDetail } from "../../../../../common/links/LinksView";

interface IProps {
    vm: IRootViewModel;
    onEdit: (reportId: number, reportVersionNumber: number, reportAttachmentId: number, name: string, documentLink: string, fileName: string) => void;
    onDelete: (reportId: number, reportVersionNumber: number, reportAttachmentId: number) => void;
    onBatchDownload?: (reportAttachments: GetGeoscienceAustraliaReportAttachmentsItemDto[]) => void;
}

export default function AcquisitionReportAttachmentsView(props: IProps): JSX.Element {
    const { vm, onEdit, onDelete, onBatchDownload } = props;

    return (
        <>
            <LinksView
                title="Survey"
                canViewLink={vm.canViewSurveys}
                linkTooltip="Click to view Survey Attachments."
                links={[new LinkDetail(vm.surveyName, GeoscienceAustraliaRouteHelper.surveyReportAttachments(vm.surveyId!))]}
            />
            <ReportAttachments attachments={vm.attachments} statusMessages={vm.statusMessages} onEdit={onEdit} onDelete={onDelete} onBatchDownload={onBatchDownload}></ReportAttachments>
        </>
    );
}
