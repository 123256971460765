// api
import { GetCompanyOpggsTitleDetailsLegislativeForm7Dto } from "../../../../../../api/Client";
// views
import DocumentDownloadView from "./DocumentDownloadView";
import FormDetailsField from "./FormDetailsField";
// other
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";

export default function Form7View(props: { form: GetCompanyOpggsTitleDetailsLegislativeForm7Dto; downloadDocument: (formId: number) => void }): React.ReactElement {
    const form = props.form;

    // view
    return (
        <div className="card mb-2">
            <div className="card-body">
                <h3 className="card-title">Form 7</h3>
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <FormDetailsField label="Titleholder Giving Notice" value={form.titleholderGivingNoticeCompanyName} />
                                <FormDetailsField label="Effective Date" value={GlobalHelpers.toNoptaDateString(form.effectiveFromDatetime)} />
                            </div>
                        </div>
                        <DocumentDownloadView fileId={form.fileId} />
                    </div>
                </div>
            </div>
        </div>
    );
}
