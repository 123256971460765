// framework
import { clone, cloneDeep } from "lodash";
// kendo
import { orderBy } from "@progress/kendo-data-query";
// client
import * as Client from "../../../../../api/Client";
// common
import { IAddressDetailsViewModel } from "../../common/addressDetailsViewModel";
import { IRegisteredTitleholderDetailsViewModel } from "../../common/registeredTitleholderDetailsViewModel";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import * as LegislativeFormsHelper from "../../common/LegislativeFormsHelper";

export interface IFormRequestDetailsViewModel {
    formTypeId?: number | undefined;
    titleholderDisplayName?: string | undefined;
    selectedTitleholder?: Client.GetCompanyOpggsLegislativeFormsFormRequestTitleholderDetailsDto | undefined;
    additionalTitles?: Array<Client.TitleInfoDto> | undefined;
    form4NomineeTitleholder?: Client.FormRequestTitleholderInfoDto | undefined;
    form6NomineeTitleholder?: Client.FormRequestTitleholderInfoDto | undefined;
}

export interface IRootViewModel {
    titleId?: string | undefined;
    titleNumber?: string | undefined;

    reference: {
        formTypeIds: Array<number>;
        selectableTitleholders: Array<Client.GetCompanyOpggsLegislativeFormsFormRequestTitleholderDetailsDto>;
        allTitleholders: Array<Client.FormRequestTitleholderInfoDto>;
        countries: Array<Client.SecurePortalCountryDto>;
    };

    formRequestDetails: IFormRequestDetailsViewModel;
    addressDetails: IAddressDetailsViewModel;
    registeredTitleholderDetails: IRegisteredTitleholderDetailsViewModel;

    service: {
        statusMessages?: Client.StatusMessagesDto;
        id?: Client.FormRequestIdDto | undefined;
    };

    isDirty: boolean;

    // saga triggered state changes
    refreshTitle(titleId: string): IRootViewModel;
    refreshReferenceData(response: Client.GetCompanyOpggsLegislativeFormsFormRequestReferenceDataResponseDto): IRootViewModel;
    refreshCreateFormRequestResponse(statusMessages: Client.StatusMessagesDto, id: Client.FormRequestIdDto | undefined): IRootViewModel;

    // view triggered state changes
    refreshSelectedFormTypeChanged(formTypeId?: number | undefined): IRootViewModel;
    refreshSelectedTitleholderChanged(titleholder?: Client.GetCompanyOpggsLegislativeFormsFormRequestTitleholderDetailsDto | undefined): IRootViewModel;
    refreshSelectedAdditionalTitlesChanged(titles: Array<Client.TitleInfoDto>): IRootViewModel;
    refreshAddressDetailsUpdated(values: IAddressDetailsViewModel): IRootViewModel;
    refreshRegisteredTitleholderDetailsUpdated(values: IRegisteredTitleholderDetailsViewModel): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.titleId = undefined;
        this.titleNumber = undefined;

        this.reference = {
            formTypeIds: new Array<number>(),
            selectableTitleholders: new Array<Client.GetCompanyOpggsLegislativeFormsFormRequestTitleholderDetailsDto>(),
            allTitleholders: new Array<Client.FormRequestTitleholderInfoDto>(),
            countries: new Array<Client.SecurePortalCountryDto>(),
        };

        this.formRequestDetails = {};
        this.addressDetails = {};
        this.registeredTitleholderDetails = {};

        this.service = {
            statusMessages: undefined,
            id: undefined,
        };

        this.isDirty = false;
    }

    public titleId?: string | undefined;
    public titleNumber?: string | undefined;

    public reference: {
        formTypeIds: Array<number>;
        selectableTitleholders: Array<Client.GetCompanyOpggsLegislativeFormsFormRequestTitleholderDetailsDto>;
        allTitleholders: Array<Client.FormRequestTitleholderInfoDto>;
        countries: Array<Client.SecurePortalCountryDto>;
    };

    public formRequestDetails: IFormRequestDetailsViewModel;
    public addressDetails: IAddressDetailsViewModel;
    public registeredTitleholderDetails: IRegisteredTitleholderDetailsViewModel;

    public service: {
        statusMessages?: Client.StatusMessagesDto;
        id?: Client.FormRequestIdDto | undefined;
    };

    public isDirty: boolean;

    public refreshTitle(titleId: string): IRootViewModel {
        const vm = this._clone();
        vm.titleId = titleId;
        return vm;
    }

    public refreshReferenceData(response: Client.GetCompanyOpggsLegislativeFormsFormRequestReferenceDataResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.titleNumber = response.titleNumber;

        vm.reference.formTypeIds = response.formTypeIds.sort((a, b) => a - b);
        vm.reference.selectableTitleholders = orderBy(response.selectableTitleholders, [{ field: "companyName", dir: "asc" }]);
        vm.reference.allTitleholders = orderBy(response.allTitleholders, [{ field: "companyName", dir: "asc" }]);
        vm.reference.countries = orderBy(response.countries, [{ field: "countryName", dir: "asc" }]);
        // set defaults
        vm.addressDetails.addressType = Client.LegislativeFormCompanyAddressTypeEnum.Business;
        vm.addressDetails.country = vm.reference.countries.find((c) => c.countryName === "Australia");
        // set selected titleholder if there is only one to choose from
        if (vm.reference.selectableTitleholders.length === 1) {
            const selectedTitleholder = vm.reference.selectableTitleholders[0];
            vm.formRequestDetails.selectedTitleholder = selectedTitleholder;
            vm.registeredTitleholderDetails.acnOrArbn = selectedTitleholder.isAcnOrArbnRequired === true ? "" : selectedTitleholder.acnOrArbn;
            vm.registeredTitleholderDetails.becomingARegisteredTitleholderDate = GlobalHelpers.toNoptaDateString(selectedTitleholder.becomingARegisteredTitleholderDate);
        }
        vm.formRequestDetails.form4NomineeTitleholder = response.form4NomineeTitleholder;
        vm.formRequestDetails.form6NomineeTitleholder = response.form6NomineeTitleholder;

        vm.isDirty = false; // reference data load is part of the initialisation phase
        return vm;
    }

    refreshCreateFormRequestResponse(statusMessages: Client.StatusMessagesDto, id: Client.FormRequestIdDto | undefined): IRootViewModel {
        const vm = this._clone();

        vm.service.id = id;
        vm.service.statusMessages = statusMessages;

        vm.isDirty = !statusMessages!.isSuccess; // on success, set the vm as clean
        return vm;
    }

    // user triggered state changes
    refreshSelectedFormTypeChanged(formTypeId?: number | undefined): IRootViewModel {
        const vm = this._clone();
        vm.formRequestDetails.formTypeId = formTypeId;
        vm.formRequestDetails.titleholderDisplayName = LegislativeFormsHelper.getTitleholderDisplayName(formTypeId);

        // TODO: may have to introduce flags and update here as more form types are added

        vm.isDirty = true;
        return vm;
    }

    refreshSelectedTitleholderChanged(titleholder?: Client.GetCompanyOpggsLegislativeFormsFormRequestTitleholderDetailsDto | undefined): IRootViewModel {
        const vm = this._clone();

        // only clear selected additional titles if the registered titleholder has changed
        if (vm.formRequestDetails.selectedTitleholder?.companyId !== titleholder?.companyId) {
            vm.formRequestDetails.additionalTitles = new Array<Client.TitleInfoDto>();
        }

        vm.formRequestDetails.selectedTitleholder = titleholder;
        vm.registeredTitleholderDetails.acnOrArbn = titleholder?.isAcnOrArbnRequired === true ? "" : titleholder?.acnOrArbn;
        vm.registeredTitleholderDetails.becomingARegisteredTitleholderDate = GlobalHelpers.toNoptaDateString(titleholder?.becomingARegisteredTitleholderDate);

        // TODO: may have to introduce flags and update here as more form types are added

        vm.isDirty = true;
        return vm;
    }

    refreshSelectedAdditionalTitlesChanged(titles: Array<Client.TitleInfoDto>): IRootViewModel {
        const vm = this._clone();
        vm.formRequestDetails.additionalTitles = titles;

        vm.isDirty = true;
        return vm;
    }

    refreshAddressDetailsUpdated(values: IAddressDetailsViewModel): IRootViewModel {
        const vm = this._clone();
        vm.addressDetails = values;

        vm.isDirty = true;
        return vm;
    }

    refreshRegisteredTitleholderDetailsUpdated(values: IRegisteredTitleholderDetailsViewModel): IRootViewModel {
        const vm = this._clone();
        vm.registeredTitleholderDetails = values;

        vm.isDirty = true;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);

        vm.reference = clone(this.reference);
        vm.formRequestDetails = cloneDeep(this.formRequestDetails);
        vm.addressDetails = cloneDeep(this.addressDetails);
        vm.registeredTitleholderDetails = cloneDeep(this.registeredTitleholderDetails);
        vm.service = clone(this.service);

        return vm;
    }
}
