// framework
import { useEffect, useState } from "react";
// kendo
import { orderBy } from "@progress/kendo-data-query";
import { DropDownList } from "@progress/kendo-react-dropdowns";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
// api
import * as Client from "../../../../api/Client";
// common
import { ValidationModel } from "../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../common/validation/ValidationMessageView";
import MaskedTextBoxView from "../../../../common/maskedTextbox/MaskedTextboxView";
import * as Forms from "../../../../common/forms/BootstrapForms";

export function NewCompanyRequestView(props: { vm: Models.IRootViewModel; v: ValidationModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const request = vm.user.newCompanyRequest;
    const countries = vm.reference.countries;
    const v = props.v;
    const actions = props.actions;

    const [state, setState] = useState<Models.INewCompanyRequestViewModel>({
        companyName: "",
        companyAbn: "",
        companyAcnOrArbn: "",
        businessAddress: "",
        businessSuburb: "",
        businessPostcode: "",
        businessState: undefined,
        businessCountry: undefined,
        postalAddress: "",
        postalSuburb: "",
        postalPostcode: "",
        postalState: undefined,
        postalCountry: undefined,
        isPostalAddressSameAsBusinessAddress: false,
    });

    useEffect(() => {
        setState({
            companyName: request.companyName ?? "",
            companyAbn: request.companyAbn ?? "",
            companyAcnOrArbn: request.companyAcnOrArbn ?? "",
            businessAddress: request.businessAddress ?? "",
            businessSuburb: request.businessSuburb ?? "",
            businessPostcode: request.businessPostcode ?? "",
            businessState: request.businessState,
            businessCountry: request.businessCountry,
            postalAddress: request.postalAddress ?? "",
            postalSuburb: request.postalSuburb ?? "",
            postalPostcode: request.postalPostcode ?? "",
            postalState: request.postalState,
            postalCountry: request.postalCountry,
            isPostalAddressSameAsBusinessAddress: request.isPostalAddressSameAsBusinessAddress,
        });
    }, [request]);

    // reference data
    const [countryData, setCountryData] = useState<Array<Client.SecurePortalCountryDto>>();

    useEffect(() => {
        setCountryData(orderBy(countries, [{ field: "countryName", dir: "asc" }]));
    }, [countries]);

    // business address dropdowns
    const [businessStateData, setBusinessStateData] = useState<Array<Client.SecurePortalCountrySubdivisionDto>>();
    const [selectedBusinessState, setSelectedBusinessState] = useState<Client.SecurePortalCountrySubdivisionDto | undefined | null>();
    const [selectedBusinessCountry, setSelectedBusinessCountry] = useState<Client.SecurePortalCountryDto | undefined | null>();

    // update selected business country if changed - must be set to null in order to programatically clear the combobox and update state dropdown data
    useEffect(() => {
        setSelectedBusinessCountry(request.businessCountry ?? null);
        const stateData = request.businessCountry?.subdivisions ?? new Array<Client.SecurePortalCountrySubdivisionDto>();
        setBusinessStateData(orderBy(stateData, [{ field: "countrySubdivisionName", dir: "asc" }]));
    }, [request.businessCountry, countries]);
    // update selected business state if changed - must be set to null in order to programatically clear the combobox
    useEffect(() => {
        setSelectedBusinessState(request.businessState ?? null);
    }, [request.businessState]);

    // business country dropdown change - set state dropdown data and clear current state selection
    const onBusinessCountryChange = (event: any) => {
        const i: Client.SecurePortalCountryDto | undefined = event.value;
        const stateData = i?.subdivisions ?? new Array<Client.SecurePortalCountrySubdivisionDto>();

        setSelectedBusinessCountry(i);
        setBusinessStateData(orderBy(stateData, [{ field: "countrySubdivisionName", dir: "asc" }]));
        setSelectedBusinessState(null);
        setState({ ...state, businessCountry: i, businessState: undefined });
    };
    // business state dropdown change
    const onBusinessStateChange = (event: any) => {
        const i: Client.SecurePortalCountrySubdivisionDto | undefined = event.value;

        setSelectedBusinessState(i);
        setState({ ...state, businessState: i });
    };

    // postal address dropdowns
    const [postalStateData, setPostalStateData] = useState<Array<Client.SecurePortalCountrySubdivisionDto>>();
    const [selectedPostalState, setSelectedPostalState] = useState<Client.SecurePortalCountrySubdivisionDto | undefined | null>();
    const [selectedPostalCountry, setSelectedPostalCountry] = useState<Client.SecurePortalCountryDto | undefined | null>();

    // update selected postal country if changed - must be set to null in order to programatically clear the combobox and update state dropdown data
    useEffect(() => {
        setSelectedPostalCountry(request.postalCountry ?? null);
        const stateData = request.postalCountry?.subdivisions ?? new Array<Client.SecurePortalCountrySubdivisionDto>();
        setPostalStateData(orderBy(stateData, [{ field: "countrySubdivisionName", dir: "asc" }]));
    }, [request.postalCountry]);
    // update selected postal state if changed - must be set to null in order to programatically clear the combobox
    useEffect(() => {
        setSelectedPostalState(request.postalState ?? null);
    }, [request.postalState]);

    // postal country dropdown change - set state dropdown data and clear current state selection
    const onPostalCountryChange = (event: any) => {
        const i: Client.SecurePortalCountryDto | undefined = event.value;
        const stateData = i?.subdivisions ?? new Array<Client.SecurePortalCountrySubdivisionDto>();

        setSelectedPostalCountry(i);
        setPostalStateData(orderBy(stateData, [{ field: "countrySubdivisionName", dir: "asc" }]));
        setSelectedPostalState(null);
        setState({ ...state, postalCountry: i, postalState: undefined });
    };
    // postal state dropdown change
    const onPostalStateChange = (event: any) => {
        const i: Client.SecurePortalCountrySubdivisionDto | undefined = event.value;

        setSelectedPostalState(i);
        setState({ ...state, postalState: i });
    };

    // postal checkbox change - clear and disable postal address inputs, update viewModel
    const onCheckboxChange = (isChecked: boolean) => {
        let updatedState: Models.INewCompanyRequestViewModel;

        if (isChecked) {
            setSelectedPostalCountry(null);
            setPostalStateData(undefined);
            setSelectedPostalState(null);

            updatedState = {
                ...state,
                postalAddress: "",
                postalSuburb: "",
                postalPostcode: "",
                postalState: undefined,
                postalCountry: undefined,
                isPostalAddressSameAsBusinessAddress: true,
            };
        } else {
            updatedState = { ...state, isPostalAddressSameAsBusinessAddress: false };
        }
        setState(updatedState);
        actions.viewChanged(vm.refreshNewCompanyRequestUpdated(updatedState));
    };

    // generic input change
    function onChanged(values: any) {
        setState({ ...state, ...values });
    }

    function updateViewModel() {
        actions.viewChanged(vm.refreshNewCompanyRequestUpdated({ ...state }));
    }

    return (
        <div className="card mb-2">
            <div className="card-header">New Company Details</div>
            <div className="card-body">
                <Forms.Row>
                    <Forms.FullColumn>
                        <Forms.TextInput
                            id="companyNameField"
                            label="Company Name"
                            value={state.companyName}
                            valid={v.applyValidity("NewCompanyRequest.CompanyName")}
                            onChange={(value) => onChanged({ companyName: value })}
                            onBlur={updateViewModel}
                        />
                        <ValidationMessageView name="NewCompanyRequest.CompanyName" validationModel={v} />
                    </Forms.FullColumn>
                    <Forms.HalfColumn>
                        <Forms.Label htmlFor="companyAbnField">ABN</Forms.Label>
                        <MaskedTextBoxView
                            id="companyAbnField"
                            mask="00 000 000 000"
                            value={state.companyAbn}
                            valid={v.applyValidityForKendo("NewCompanyRequest.CompanyAbn")}
                            onChange={(value) => onChanged({ companyAbn: value })}
                            onBlur={updateViewModel}
                        />
                        <ValidationMessageView name="NewCompanyRequest.CompanyAbn" validationModel={v} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.Label htmlFor="companyAcnOrArbnField">ACN / ARBN</Forms.Label>
                        <MaskedTextBoxView
                            id="companyAcnOrArbnField"
                            mask="000 000 000"
                            value={state.companyAcnOrArbn}
                            valid={v.applyValidityForKendo("NewCompanyRequest.CompanyAcnOrArbn")}
                            onChange={(value) => onChanged({ companyAcnOrArbn: value })}
                            onBlur={updateViewModel}
                        />
                        <ValidationMessageView name="NewCompanyRequest.CompanyAcnOrArbn" validationModel={v} />
                    </Forms.HalfColumn>
                    <Forms.FullColumn>
                        <span className="fs-5">Business Address</span>
                    </Forms.FullColumn>
                    <Forms.FullColumn>
                        <Forms.TextArea
                            id="businessAddressField"
                            label="Street Address"
                            rows={5}
                            maxLength={255}
                            value={state.businessAddress}
                            valid={v.applyValidity("NewCompanyRequest.BusinessAddress")}
                            onChange={(value) => onChanged({ businessAddress: value })}
                            onBlur={updateViewModel}
                        />
                        <ValidationMessageView name="NewCompanyRequest.BusinessAddress" validationModel={v} />
                    </Forms.FullColumn>
                    <Forms.HalfColumn>
                        <Forms.TextInput
                            id="businessSuburbField"
                            label="Town/Suburb"
                            value={state.businessSuburb}
                            valid={v.applyValidity("NewCompanyRequest.BusinessSuburb")}
                            onChange={(value) => onChanged({ businessSuburb: value })}
                            onBlur={updateViewModel}
                        />
                        <ValidationMessageView name="NewCompanyRequest.BusinessSuburb" validationModel={v} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.TextInput
                            id="businessPostcodeField"
                            label="Postcode"
                            value={state.businessPostcode}
                            valid={v.applyValidity("NewCompanyRequest.BusinessPostcode")}
                            onChange={(value) => onChanged({ businessPostcode: value })}
                            onBlur={updateViewModel}
                        />
                        <ValidationMessageView name="NewCompanyRequest.BusinessPostcode" validationModel={v} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.Label htmlFor="businessStateField">State/Territory</Forms.Label>
                        <DropDownList
                            id="businessStateField"
                            valid={v.applyValidityForKendo("NewCompanyRequest.BusinessState")}
                            data={businessStateData}
                            disabled={!selectedBusinessCountry}
                            value={selectedBusinessState}
                            textField="countrySubdivisionName"
                            onChange={onBusinessStateChange}
                            onBlur={updateViewModel}
                        />
                        <ValidationMessageView name="NewCompanyRequest.BusinessState" validationModel={v} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.Label htmlFor="businessCountryField">Country</Forms.Label>
                        <DropDownList
                            id="businessCountryField"
                            valid={v.applyValidityForKendo("NewCompanyRequest.BusinessCountry")}
                            data={countryData}
                            value={selectedBusinessCountry}
                            textField="countryName"
                            onChange={onBusinessCountryChange}
                            onBlur={updateViewModel}
                        />
                        <ValidationMessageView name="NewCompanyRequest.BusinessCountry" validationModel={v} />
                    </Forms.HalfColumn>
                    <Forms.FullColumn>
                        <span className="fs-5 me-4">Postal Address</span>
                        <Forms.Checkbox
                            id="isPostalAddressSameAsBusinessAddressField"
                            label="Same as business address"
                            checked={state.isPostalAddressSameAsBusinessAddress}
                            onChange={onCheckboxChange}
                            inline
                        />
                    </Forms.FullColumn>
                    <Forms.FullColumn>
                        <Forms.TextArea
                            id="postalAddressField"
                            label="Street Address"
                            rows={5}
                            maxLength={255}
                            value={state.postalAddress}
                            disabled={state.isPostalAddressSameAsBusinessAddress}
                            valid={v.applyValidity("NewCompanyRequest.PostalAddress")}
                            onChange={(value) => onChanged({ postalAddress: value })}
                            onBlur={updateViewModel}
                        />
                        <ValidationMessageView name="NewCompanyRequest.PostalAddress" validationModel={v} />
                    </Forms.FullColumn>
                    <Forms.HalfColumn>
                        <Forms.TextInput
                            id="postalSuburbField"
                            label="Town/Suburb"
                            value={state.postalSuburb}
                            disabled={state.isPostalAddressSameAsBusinessAddress}
                            valid={v.applyValidity("NewCompanyRequest.PostalSuburb")}
                            onChange={(value) => onChanged({ postalSuburb: value })}
                            onBlur={updateViewModel}
                        />
                        <ValidationMessageView name="NewCompanyRequest.PostalSuburb" validationModel={v} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.TextInput
                            id="postalPostcodeField"
                            label="Postcode"
                            value={state.postalPostcode}
                            disabled={state.isPostalAddressSameAsBusinessAddress}
                            valid={v.applyValidity("NewCompanyRequest.PostalPostcode")}
                            onChange={(value) => onChanged({ postalPostcode: value })}
                            onBlur={updateViewModel}
                        />
                        <ValidationMessageView name="NewCompanyRequest.PostalPostcode" validationModel={v} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.Label htmlFor="postalStateField">State/Territory</Forms.Label>
                        <DropDownList
                            id="postalStateField"
                            valid={v.applyValidityForKendo("NewCompanyRequest.PostalState")}
                            data={postalStateData}
                            disabled={!selectedPostalCountry || state.isPostalAddressSameAsBusinessAddress}
                            value={selectedPostalState}
                            textField="countrySubdivisionName"
                            onChange={onPostalStateChange}
                            onBlur={updateViewModel}
                        />
                        <ValidationMessageView name="NewCompanyRequest.PostalState" validationModel={v} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.Label htmlFor="postalCountryField">Country</Forms.Label>
                        <DropDownList
                            id="postalCountryField"
                            valid={v.applyValidityForKendo("NewCompanyRequest.PostalCountry")}
                            data={countryData}
                            value={selectedPostalCountry}
                            disabled={state.isPostalAddressSameAsBusinessAddress}
                            textField="countryName"
                            onChange={onPostalCountryChange}
                            onBlur={updateViewModel}
                        />
                        <ValidationMessageView name="NewCompanyRequest.PostalCountry" validationModel={v} />
                    </Forms.HalfColumn>
                </Forms.Row>
            </div>
        </div>
    );
}
