// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
import { IAddressDetailsViewModel } from "../../../common/addressDetailsViewModel";
import { IRegisteredTitleholderDetailsViewModel } from "../../../common/registeredTitleholderDetailsViewModel";
// views
import FormTypeReadonlyView from "./FormTypeReadonlyView";
import AddressDetailsView from "../../../common/AddressDetailsView";
import RegisteredTitleholderDetailsView from "../../../common/RegisteredTitleholderDetailsView";
import AllTitleholdersReadonlyView from "../../../common/AllTitleholdersReadonlyView";
// common
import { ValidationModel } from "../../../../../../common/validation/ValidationModel";
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as LegislativeFormsHelper from "../../../common/LegislativeFormsHelper";
// views
import TitlesListView from "./TitlesListView";
import AddressDetailsReadonlyView from "../../../common/AddressDetailsReadonlyView";
import RegisteredTitleholderDetailsReadonlyView from "../../../common/RegisteredTitleholderDetailsReadonlyView";
import TitlesListReadonlyView from "./TitlesListReadonlyView";

export default function FormRequestDetailsView(props: {
    vm: Models.IRootViewModel;
    v: ValidationModel;
    actions: Actions.IActionFactory;
    onEdit: () => void;
    onSave: () => void;
    onCancel: () => void;
}) {
    const vm = props.vm;
    const v = props.v;
    const a = props.actions;

    function updateAddressDetails(addressDetails: IAddressDetailsViewModel) {
        a.viewChanged(vm.refreshAddressDetailsUpdated(addressDetails));
    }

    function updateRegisteredTitleholderDetails(registeredTitleholderDetails: IRegisteredTitleholderDetailsViewModel) {
        a.viewChanged(vm.refreshRegisteredTitleholderDetailsUpdated(registeredTitleholderDetails));
    }

    function onCancelClicked(e: any) {
        e.preventDefault();
        props.onCancel();
    }

    const formTypeId = vm.formRequestDetails.formTypeId;

    return (
        <div className="card mb-2">
            <div className="card-body">
                <h2 className="card-title">Details</h2>
                {vm.inEditTask === Models.FormRequestTaskEnum.FormRequestDetails ? (
                    <>
                        {formTypeId && <FormTypeReadonlyView vm={vm.formRequestDetails} />}
                        {(formTypeId === 2 || formTypeId === 3) && (
                            <>
                                {formTypeId === 3 && <TitlesListView vm={vm} v={v} actions={a} />}
                                <AddressDetailsView vm={vm.addressDetails} v={v} countries={vm.reference.countries} updateViewModel={updateAddressDetails} />
                                <RegisteredTitleholderDetailsView
                                    vm={vm.registeredTitleholderDetails}
                                    v={v}
                                    formTypeId={formTypeId}
                                    isAcnOrArbnRequired={vm.formRequestDetails.isAcnOrArbnRequired}
                                    updateViewModel={updateRegisteredTitleholderDetails}
                                />
                            </>
                        )}
                        {(formTypeId === 4 || formTypeId === 6) && <AllTitleholdersReadonlyView titleholders={vm.formRequestDetails.allTitleholders} />}
                        {formTypeId === 5 && (
                            <Forms.Group>
                                <Forms.ReadonlyField label="Name of Nominee Titleholder (Form 4)" id="nomineeTitleholderField" value={vm.formRequestDetails.nomineeTitleholder?.companyName} />
                            </Forms.Group>
                        )}
                        {formTypeId === 7 && (
                            <Forms.Group>
                                <Forms.ReadonlyField label="Name of Nominee Titleholder (Form 6)" id="nomineeTitleholderField" value={vm.formRequestDetails.nomineeTitleholder?.companyName} />
                            </Forms.Group>
                        )}
                        {/* task buttons */}
                        <div className="d-flex flex-wrap mt-2">
                            <div className="ms-auto">
                                <button className="btn btn-outline-secondary me-2" type="button" onClick={onCancelClicked}>
                                    Cancel
                                </button>
                                <button className="btn btn-outline-primary" disabled={!vm.isDirty && !vm.formRequestDetails.hasInvalidTitlesOnForm} onClick={props.onSave}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {formTypeId && <FormTypeReadonlyView vm={vm.formRequestDetails} />}
                        {(formTypeId === 2 || formTypeId === 3) && (
                            <>
                                {formTypeId === 3 && <TitlesListReadonlyView vm={vm} />}
                                <AddressDetailsReadonlyView vm={vm.addressDetails} />
                                <RegisteredTitleholderDetailsReadonlyView vm={vm.registeredTitleholderDetails} formTypeId={formTypeId} />
                            </>
                        )}
                        {(formTypeId === 4 || formTypeId === 6) && <AllTitleholdersReadonlyView titleholders={vm.formRequestDetails.allTitleholders} />}
                        {formTypeId === 5 && (
                            <Forms.Group>
                                <Forms.ReadonlyField label="Name of Nominee Titleholder (Form 4)" id="nomineeTitleholderField" value={vm.formRequestDetails.nomineeTitleholder?.companyName} />
                            </Forms.Group>
                        )}
                        {formTypeId === 7 && (
                            <Forms.Group>
                                <Forms.ReadonlyField label="Name of Nominee Titleholder (Form 6)" id="nomineeTitleholderField" value={vm.formRequestDetails.nomineeTitleholder?.companyName} />
                            </Forms.Group>
                        )}
                        {/* task buttons */}
                        <div className="d-flex flex-wrap mt-2">
                            {LegislativeFormsHelper.showCommands(vm.viewState) && LegislativeFormsHelper.isEditableFormType(formTypeId) && (
                                <div className="ms-auto">
                                    <button className="btn btn-outline-primary float-end" type="button" disabled={LegislativeFormsHelper.isInEdit(vm.inEditTask)} onClick={props.onEdit}>
                                        Edit
                                    </button>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
