// framework
import * as Validation from "../../../../../common/validation/ValidationModel";
// models
import * as Models from "./models";
// common
import * as AddressDetailsValidator from "../../common/addressDetailsValidator";
import * as RegisteredTitleholderDetailsValidator from "../../common/registeredTitleholderDetailsValidator";

export default function validate(vm: Models.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateFormRequestDetails(vm, v);
    AddressDetailsValidator.validate(vm.addressDetails, vm.formRequestDetails.formTypeId, vm.reference.countries, v);
    RegisteredTitleholderDetailsValidator.validate(vm.registeredTitleholderDetails, vm.formRequestDetails.formTypeId, vm.formRequestDetails.selectedTitleholder?.isAcnOrArbnRequired, v);
    return v;
}

function validateFormRequestDetails(vm: Models.IRootViewModel, v: Validation.ValidationModel): void {
    if (!vm.formRequestDetails.formTypeId) {
        v.addError("FormRequestDetails.FormType", "Legislative Form is required.");
    } else if (!vm.reference.formTypeIds.some((id) => id === vm.formRequestDetails.formTypeId)) {
        v.addError("FormRequestDetails.FormType", "Legislative Form is invalid.");
    }

    if (!vm.formRequestDetails.selectedTitleholder) {
        v.addError("FormRequestDetails.Titleholder", vm.formRequestDetails.titleholderDisplayName + " is required.");
    } else if (!vm.reference.selectableTitleholders.some((t) => t.companyId === vm.formRequestDetails.selectedTitleholder!.companyId)) {
        v.addError("FormRequestDetails.Titleholder", vm.formRequestDetails.titleholderDisplayName + " is invalid.");
    }

    // additional titles only allowed for form 3 requests
    if (vm.formRequestDetails.formTypeId !== 3) return;

    // additional titles are optional, only validate if selected
    if (vm.formRequestDetails.additionalTitles && vm.formRequestDetails.additionalTitles.length > 0) {
        const availableTitles = vm.formRequestDetails.selectedTitleholder?.additionalTitles;
        if (!availableTitles) {
            v.addError("FormRequestDetails.AdditionalTitles", "Additional Titles are invalid.");
        } else {
            const invalidTitles = vm.formRequestDetails.additionalTitles.filter((t) => !availableTitles.some((rt) => rt.id === t.id));

            if (invalidTitles.length === 0) return;
            v.addError("FormRequestDetails.AdditionalTitles", "The following Additional Titles are invalid: " + invalidTitles.map((t) => t.titleNumber).join(", "));
        }
    }
}
