// redux
import * as Models from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";

export default function IarlDetailsPpReadonlyView(props: { vm: Models.IIarlPpDetailsViewModel }) {
    const vm = props.vm;
    const isCreateNewFieldRadioValue = "isCreateNewField";
    const isNotCreateNewFieldRadioValue = "isNotCreateNewField";
    const selectedCreateNewFieldRadioValue = GlobalHelpers.convertBooleanToRadioValue(vm.isCreateNewField, isCreateNewFieldRadioValue, isNotCreateNewFieldRadioValue);

    return (
        <>
            <div className="mb-2">
                <Forms.ReadonlyRadioButton
                    name="isCreateNewFieldGroup"
                    id="ppDetailsIsNotCreateNewFieldField"
                    checked={selectedCreateNewFieldRadioValue === isNotCreateNewFieldRadioValue}
                    label="Use Existing Field"
                    inline
                />
                <Forms.ReadonlyRadioButton
                    name="isCreateNewFieldGroup"
                    id="ppDetailsIsCreateNewFieldField"
                    checked={selectedCreateNewFieldRadioValue === isCreateNewFieldRadioValue}
                    label="Create New Field"
                    inline
                />
            </div>
            <Forms.Group>
                <Forms.ReadonlyField label="Field Name" id="ppDetailsExistingLocationField" value={vm.existingNeatsFieldName} />
            </Forms.Group>
            {selectedCreateNewFieldRadioValue === isCreateNewFieldRadioValue && (
                <Forms.Group>
                    <Forms.ReadonlyField label="New Field Name" id="ppDetailsNewFieldNameField" value={vm.newFieldName} />
                </Forms.Group>
            )}
            <strong>If titleholder of a life-of-field petroleum production licence (section 147 of the OPGGS Act)</strong>
            <Forms.Group>
                <Forms.ReadonlyField
                    label="Last day of petroleum recovery operations"
                    id="ppDetailslastDayOfPetroleumRecoveryOperationsField"
                    value={GlobalHelpers.toNoptaDateString(vm.lastDayOfPetroleumRecoveryOperationsDate)}
                />
            </Forms.Group>
        </>
    );
}
