// framework
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Actions from "../redux/actions";
import * as Models from "../models/models";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
// views
import LicenceDetailsView from "../../../../common/oei/licenceDetails/views/LicenceDetailsView";
import { LicenceDetailsTabsEnum } from "../../../../common/oei/licenceDetails/views/LicenceDetailsView";
// common
import * as documentHelper from "../../../../common/documentHelper";

interface IControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

interface IRouteParameters {
    id: string;
    tab?: string;
}

function LicenceDetailsController(props: IControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;
    const params = useParams<IRouteParameters>();
    const licenceId = params.id;
    let defaultTab = LicenceDetailsTabsEnum.Summary;
    if (params.tab) {
        defaultTab = parseInt(params.tab) as LicenceDetailsTabsEnum;
    }

    // on mount
    // - initial licence load
    useEffect(() => {
        actions.initialiseRequested(licenceId);
        return function () {
            actions.clearRequested();
        };
    }, [licenceId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Application document
    const applicationDocumentResponse = vm.applicationDocument;
    // - download when response received
    useEffect(() => {
        documentHelper.tryDownloadDocument(applicationDocumentResponse);
        return function () {
            actions.clearApplicationDocumentRequested();
        };
    }, [applicationDocumentResponse]); // eslint-disable-line react-hooks/exhaustive-deps

    const licence = vm.details ? vm.details.summary.licenceTypeDescription + " " + vm.details.summary.licenceNumber : "Licence Details";

    // return
    return (
        <BodyLayout
            title={licence}
            selectedLhsNavItem={Level1ItemTypeEnum.Company_Oei_LicenceList}
            onReloadRequested={() => {
                actions.initialiseRequested(licenceId);
            }}
        >
            <LicenceDetailsView vm={vm} preferredTab={defaultTab} />
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_Oei_LicenceDetails }), Actions.actionFactory)(LicenceDetailsController);
