// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/rootViewModel";
import { IJointAuthorityReduxState } from "../../../../jointAuthorityReduxRegistration";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";
import * as ConfigurationHelper from "../../../../../common/shell/configurationHelper";

const getRootViewModel = (state: IJointAuthorityReduxState) => state.JointAuthority_UserManagement_RequestList;

export default function* initialise() {
    // save role reference data to the vm
    let vm: Models.IRootViewModel = yield select(getRootViewModel);
    vm = vm.refreshRoleConfiguration(yield select(ConfigurationHelper.getRoleConfiguration));
    yield put(Actions.actionFactory.sagaCompleted(vm));

    // load request list
    try {
        yield put(shellHelper.createSetBusyAction());

        const response: Client.GetJointAuthorityUserManagementRequestListResponseDto = yield call(getRequestList);
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshRequestDetails(response)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

export async function getRequestList(): Promise<Client.GetJointAuthorityUserManagementRequestListResponseDto> {
    const client = await ClientFactory.createJointAuthorityUserManagementClient();
    return await CallHelper.simpleCall(() => client.getJointAuthorityUserManagementRequestList(new Client.GetJointAuthorityUserManagementRequestListRequestDto()));
}
