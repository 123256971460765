// framework
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
// api
import { GetGeoscienceAustraliaSurveysAcquisitionsSurveyTitleDetailsResponseTitleDto } from "../../../../../../api/Client";
// common
import ExternalLinkView from "../../../../../../common/externalLink/ExternalLinkView";
import * as shellRouteHelper from "../../../../../../shell/shellRouteHelper";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
import CommentSummaryView from "../../../../../../common/commentSummary/commentSummaryView";

export default function TitlesListGridView(props: { surveyId: number; surveyTitles: GetGeoscienceAustraliaSurveysAcquisitionsSurveyTitleDetailsResponseTitleDto[] }): JSX.Element {
    const { surveyId, surveyTitles } = props;

    const initialState: State = {
        sort: [
            { field: "titleRelationshipTypeName", dir: "asc" },
            { field: "titleNumber", dir: "asc" },
        ],
    };

    const [gridState, setGridState] = useState(initialState);
    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [surveyTitles]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <h2 className="mt-2">Title Relationships</h2>
            <Grid
                resizable
                sortable
                data={process(surveyTitles, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn field="titleRelationshipTypeName" title="Relationship" width={300} />
                <GridColumn
                    field="titleNumber"
                    title="Title Number"
                    width={150}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.titleId ? (
                                <ExternalLinkView href={shellRouteHelper.publicPortalTitleDetails(props.dataItem.titleNumber)}>{props.dataItem.titleNumber}</ExternalLinkView>
                            ) : (
                                props.dataItem.titleNumber
                            )}
                        </CustomGridCell>
                    )}
                />
                <GridColumn field="titleTypeDescription" title="Title Type" width={400} />
                <GridColumn
                    field="acquisitionName"
                    title="Acquisition"
                    width={580}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.acquisitionId ? (
                                <Link to={geoscienceAustraliaRouteHelper.acquisitionDetails(surveyId, props.dataItem.acquisitionId)} title="Click to view Acquisition details.">
                                    {props.dataItem.acquisitionName}
                                </Link>
                            ) : (
                                props.dataItem.acquisitionName
                            )}
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    title="Comments"
                    width={250}
                    field="comments"
                    filter="text"
                    columnMenu={createGridColumnFilterMenu}
                    cell={(props: GridCellProps) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <CommentSummaryView comment={props.dataItem.comments} maxSummaryLength={20} title="Comments"></CommentSummaryView>
                        </CustomGridCell>
                    )}
                />
            </Grid>
        </>
    );
}
