// redux
import * as Models from "../../models/models";
// common
import * as Forms from "../../../../../../../common/forms/BootstrapForms";

export default function ContactReadonlyView(props: { vm: Models.IRootViewModel }) {
    const vm = props.vm;

    return (
        <Forms.Row>
            <Forms.HalfColumn>
                <Forms.ReadonlyField label="Company Name" id="companyNameField" value={vm.companyName} />
            </Forms.HalfColumn>
            <Forms.HalfColumn>
                <Forms.ReadonlyField label="Phone" id="phoneField" value={vm.contact.phone} />
            </Forms.HalfColumn>
            <Forms.HalfColumn>
                <Forms.ReadonlyField label="Contact Name" id="contactNameField" value={vm.contact.contactName} />
            </Forms.HalfColumn>
            <Forms.HalfColumn>
                <Forms.ReadonlyField label="Email" id="emailField" value={vm.contact.email} />
            </Forms.HalfColumn>
            <Forms.HalfColumn>
                <Forms.ReadonlyField label="Position Held" id="positionHeldField" value={vm.contact.positionHeld} />
            </Forms.HalfColumn>
        </Forms.Row>
    );
}
