// redux
import { ApplicationViewStateEnum } from "./models/models";
// kendo
import { Stepper, StepProps } from "@progress/kendo-react-layout";
import { checkIcon, pencilIcon, changeManuallyIcon, dollarIcon, caretAltRightIcon } from "@progress/kendo-svg-icons";

const items: StepProps[] = [
    {
        key: ApplicationViewStateEnum.Created,
        label: "Created",
        svgIcon: checkIcon,
    },
    {
        key: ApplicationViewStateEnum.Preparing,
        label: "Preparing",
        svgIcon: pencilIcon,
    },
    {
        key: ApplicationViewStateEnum.ForSigning,
        label: "Signing",
        svgIcon: changeManuallyIcon,
    },
    {
        key: ApplicationViewStateEnum.Paying,
        label: "Paying",
        svgIcon: dollarIcon,
    },
    {
        key: ApplicationViewStateEnum.ForSubmission,
        label: "Submission",
        svgIcon: caretAltRightIcon,
    },
    {
        key: ApplicationViewStateEnum.Submitted,
        label: "Submitted",
        svgIcon: checkIcon,
    },
];

export default function ProgressStepperView(props: { viewState: ApplicationViewStateEnum }) {
    const currentStep = items.findIndex((i) => i.key === props.viewState);

    return <Stepper value={currentStep} items={items} />;
}
