import * as Models from "../models/models";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "ja.account.myDetails.initialiseRequested",
    clearRequested = "ja.account.myDetails.clearRequested",
    saveMyDetailsRequested = "ja.account.myDetails.saveMyDetailsRequested",
    // user input
    viewChanged = "ja.account.myDetails.viewChanged",
    // saga
    sagaCompleted = "ja.account.myDetails.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(): IAction<undefined>;
    clearRequested(): IAction<undefined>;
    saveMyDetailsRequested(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    // user input
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    // saga
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (): IAction<undefined> {
        return {
            type: actions.initialiseRequested,
            payload: undefined,
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    saveMyDetailsRequested: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.saveMyDetailsRequested,
            payload: vm,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
