// common
import * as Validation from "../../../../../common/validation/ValidationModel";
// models
import { IRootViewModel } from "./models";

export default function validate(vm: IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    const fileAssets = vm.reportFileAssets;

    if (fileAssets.tapeId && fileAssets.tapeId.length > 100) {
        v.addError("ReportFileAsset.TapeID", "Tape ID must be 100 characters or less.");
    }

    if (fileAssets.remarks && fileAssets.remarks.length > 1000) {
        v.addError("ReportFileAsset.Remarks", "Remarks must be 1,000 characters or less.");
    }

    if (fileAssets.qcRemarks && fileAssets.qcRemarks.length > 4000) {
        v.addError("ReportFileAsset.QcRemarks", "QC Remarks must be 4,000 characters or less.");
    }

    if (fileAssets.consignment && fileAssets.consignment.length > 1000) {
        v.addError("ReportFileAsset.Consignment", "Consignment must be 1,000 characters or less.");
    }

    if (fileAssets.rddsComments && fileAssets.rddsComments.length > 4000) {
        v.addError("ReportFileAsset.RDDSComments", "RDDS Comments must be 4,000 characters or less.");
    }

    return v;
}
