// kendo
import { orderBy } from "@progress/kendo-data-query";
// api
import * as Client from "../../../../../../api/Client";
// common
import TitleLinkController from "../../../../../../common/links/TitleLinkController";
// other
import TitleDetailsField from "../TitleDetailsField";

function TitleSummaryRelatedTitlesField(props: { label?: string; relatedTitles: Client.TitleLinkDto[] | undefined }) {
    const relatedTitles = props.relatedTitles ?? new Array<Client.TitleLinkDto>();
    const sortedTitles = orderBy(relatedTitles, [{ field: "titleNumber", dir: "asc" }]);

    return <TitleDetailsField label={props.label}>{sortedTitles.map((t, i) => [i > 0 && ", ", <TitleLinkController key={i} linkDto={t} secureEntityType="Company" />])}</TitleDetailsField>;
}

export default TitleSummaryRelatedTitlesField;
