// framework
import { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
// api
import * as Client from "../../../../../../api/Client";
// models
import { IActivityViewModel, IRootViewModel } from "../../models/models";
// common
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import * as StringHelper from "../../../../../../common/stringHelper";
import ListSummaryView from "../../../../../../common/listSummary/listSummaryView";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";

export default function AuthorisationListGridView(props: { vm: IRootViewModel }): JSX.Element {
    const { vm } = props;

    const initialState: State = {
        sort: [{ field: "requestDate", dir: "desc" }],
        filter: { logic: "and", filters: [] },
        skip: 0,
        take: 20,
    };

    const [gridState, setGridState] = useState(initialState);
    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [vm.authorisations]); // eslint-disable-line react-hooks/exhaustive-deps

    function generateActivityListSummaryViewChild(activity: IActivityViewModel, truncatedDisplayName?: string): ReactElement {
        return (
            <span key={activity.key}>
                {activity.activityType === Client.NopimsActivityTypeEnum.Borehole &&
                    (vm.canViewBoreholes ? (
                        <Link
                            to={geoscienceAustraliaRouteHelper.boreholeDetails(activity.borehole!.wellId, activity.borehole!.id)}
                            className="form-link"
                            key={activity.key}
                            title="Click to view Borehole Details."
                        >
                            {truncatedDisplayName ?? activity.displayName}
                        </Link>
                    ) : (
                        <>{truncatedDisplayName ?? activity.displayName}</>
                    ))}

                {activity.activityType === Client.NopimsActivityTypeEnum.Acquisition &&
                    (vm.canViewAcquisitions ? (
                        <Link
                            to={geoscienceAustraliaRouteHelper.acquisitionDetails(activity.acquisition!.surveyId, activity.acquisition!.id)}
                            className="form-link"
                            key={activity.key}
                            title="Click to view Acquisition Details."
                        >
                            {truncatedDisplayName ?? activity.displayName}
                        </Link>
                    ) : (
                        <>{truncatedDisplayName ?? activity.displayName}</>
                    ))}

                {activity.activityType === Client.NopimsActivityTypeEnum.Project &&
                    (vm.canViewProjects ? (
                        <Link
                            to={geoscienceAustraliaRouteHelper.reprocessingProjectDetails(activity.reprocessingProject!.reprocessingId, activity.reprocessingProject!.id)}
                            className="form-link"
                            key={activity.key}
                            title="Click to view Project Details."
                        >
                            {truncatedDisplayName ?? activity.displayName}
                        </Link>
                    ) : (
                        <>{truncatedDisplayName ?? activity.displayName}</>
                    ))}
            </span>
        );
    }

    return (
        <Grid
            pageable
            sortable
            resizable
            navigatable
            data={process(vm.authorisations, gridState)}
            {...gridState}
            onDataStateChange={(e) => {
                setGridState(e.dataState);
            }}
        >
            <GridColumn
                field="identifier"
                title="Identifier"
                width={160}
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        <Link to={geoscienceAustraliaRouteHelper.authorisationDetails(props.dataItem.id)} className="form-link" title="Click to view Authorisation details">
                            {props.dataItem.identifier}
                        </Link>
                    </CustomGridCell>
                )}
                filter="text"
                columnMenu={createGridColumnFilterMenu}
            />
            <GridColumn field="requestDate" title="Request Date" width={180} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
            <GridColumn field="operatorCompanyName" title="Operator Company" width={340} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.authorisations)} />
            <GridColumn field="activityType" title="Activity Type" width={180} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.authorisations)} />
            <GridColumn
                field="activitySearchText"
                title="Activities"
                width={500}
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        <ListSummaryView
                            title="Activities"
                            maxChildrenDisplayCount={3}
                            children={props.dataItem.activities.map((activity: IActivityViewModel) =>
                                activity.displayName.length <= 25
                                    ? { displayElement: generateActivityListSummaryViewChild(activity) }
                                    : {
                                          displayElement: generateActivityListSummaryViewChild(activity, StringHelper.truncate(activity.displayName, 25)),
                                          popupDisplayElement: generateActivityListSummaryViewChild(activity),
                                      }
                            )}
                        />
                    </CustomGridCell>
                )}
                filter="text"
                columnMenu={createGridColumnFilterMenu}
            />
            <GridColumn field="type" title="Authorisation Type" width={210} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.authorisations)} />
            <GridColumn
                field="categorySearchText"
                title="Categories"
                width={190}
                cell={(props) => <CustomGridCell gridCellProps={props}>{props.dataItem.categories && <>{props.dataItem.categories.join(", ")}</>}</CustomGridCell>}
                filter="text"
                columnMenu={createGridColumnFilterMenu}
            />
            <GridColumn field="status" title="Status" width={150} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.authorisations)} />
            <GridColumn field="decisionDate" title="Decision Date" width={180} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
        </Grid>
    );
}
