import * as Client from "../../../../api/Client";
import { clone } from "lodash";

export interface IRootViewModel {
    licences: Client.GetJointAuthorityOeiLicenceListLicenceDto[];

    refreshSaga(response: Client.GetJointAuthorityOeiLicenceListResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.licences = new Array<Client.GetJointAuthorityOeiLicenceListLicenceDto>();
    }

    public licences: Client.GetJointAuthorityOeiLicenceListLicenceDto[];

    public refreshSaga(response: Client.GetJointAuthorityOeiLicenceListResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.licences = response.licences;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
