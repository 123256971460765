import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Models from "../../models/lhsNavViewModel";
import { PanelBar, PanelBarItem, PanelBarSelectEventArguments } from "@progress/kendo-react-layout";
import { Level1ItemTypeEnum } from "../../navigationItemTypes";
import logoNopta from "./logoNopta.png";
import logoOir from "./logoOir.png";

interface ILhsNavViewProps {
    padding: number | undefined;
    vm: Models.ILhsNavViewModel;
    width: number;
    selectedLhsNavItem: Level1ItemTypeEnum | undefined;
}

export default function LhsNavView(props: ILhsNavViewProps) {
    // some important layout notes:
    // - prefer sticky-top over fixed-top, as sticky-top has a lower z-order than fixed-top
    //   this allows the sidebar to not interfere with the topnav
    //   see https://getbootstrap.com/docs/5.2/layout/z-index/
    // - 'top:padding' ensures the sidebar to never go lower than the specified value
    //   otherwise you'll see a chunk of padding when at the top of the page
    // - sticky-top allows the menu to be scrolled vertically in the scenario where we have very tall menus
    //   fixed-top doesn't allow the user to access menu items further down the list

    // other notes:
    // - some notes on how to arrive at a working solution:
    //   https://www.telerik.com/account/support-tickets/view-ticket/1510858
    //   https://www.telerik.com/kendo-react-ui/components/layout/panelbar/data-binding/#toc-binding-to-local-data
    // - may 'jitter' when the LHS counts are updated - and the user is making a change!
    //   be careful to ensure this only happens if something actually changes

    const history = useHistory();

    const [items, setItems] = useState<any>(undefined);

    useEffect(() => {
        const l0Items = props.vm.visibleItems.map((l0) => {
            let isExpanded = false;
            const l0Title = l0.count ? `${l0.text} (${l0.count!})` : l0.text;

            const l1Items = l0.children.map((l1) => {
                const l1Title = l1.count ? `${l1.text} (${l1.count!})` : l1.text;
                const isDisabled = l1.route === undefined;
                const isSelected = l1.type === props.selectedLhsNavItem;
                if (isSelected) isExpanded = true;

                return <PanelBarItem key={l1.type} title={l1Title} disabled={isDisabled} selected={isSelected} route={l1.route} />;
            });

            return <PanelBarItem key={l0.type} title={l0Title} expanded={isExpanded} children={l1Items} />;
        });

        setItems(l0Items);
    }, [props.vm.visibleItems, props.selectedLhsNavItem]);

    return (
        <div className="sticky-top" style={{ top: props.padding, width: props.width }}>
            {items && <PanelBar className="mb-4" key={JSON.stringify(items)} children={items} expandMode={"single"} onSelect={onSelect} />}
            <img src={logoNopta} className="mb-4" width={props.width * 0.83} style={{ opacity: 0.7 }} alt="NOPTA logo" />
            <img src={logoOir} width={props.width} style={{ opacity: 0.75 }} alt="OIR logo" />
        </div>
    );

    function onSelect(e: PanelBarSelectEventArguments): void {
        // navigate
        const route = e.target.props.route;
        if (route) {
            history.push(route);
        }

        // navigation cancelled
        if (window.location.pathname !== route) {
            // TODO reassess after the panelbar is changed to a controlled component
            // see https://github.com/telerik/kendo-react/issues/583
            // i can't seem to find a way to cancel the selection, which occurs inside the control
        }
    }
}
