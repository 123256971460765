import * as Models from "../models/models";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "geoscienceAustralia.reportsAndData.surveyDataList.initialiseRequested",
    searchRequested = "geoscienceAustralia.reportsAndData.surveyDataList.searchRequested",
    batchUpdateRequested = "geoscienceAustralia.reportsAndData.surveyDataList.batchUpdateRequested",
    exportRequested = "geoscienceAustralia.reportsAndData.surveyDataList.exportRequested",
    clearRequested = "geoscienceAustralia.reportsAndData.surveyDataList.clearRequested",
    uploadSurveyDataFile = "geoscienceAustralia.reportsAndData.surveyDataList.uploadSurveyDataFile",
    // user input
    viewChanged = "geoscienceAustralia.reportsAndData.surveyDataList.viewChanged",
    // saga events
    sagaCompleted = "geoscienceAustralia.reportsAndData.surveyDataList.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

export interface IExportRequestedPayload {
    onlySearchResults: boolean;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(): IAction<undefined>;
    searchRequested(): IAction<undefined>;
    batchUpdateRequested(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    exportRequested(onlySearchResults: boolean): IAction<IExportRequestedPayload>;
    clearRequested(): IAction<undefined>;
    uploadSurveyDataFile(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    // user input
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    // saga events
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (): IAction<undefined> {
        return {
            type: actions.initialiseRequested,
            payload: undefined,
        };
    },
    searchRequested: function (): IAction<undefined> {
        return {
            type: actions.searchRequested,
            payload: undefined,
        };
    },
    batchUpdateRequested: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.batchUpdateRequested,
            payload: vm,
        };
    },
    exportRequested: function (onlySearchResults: boolean): IAction<IExportRequestedPayload> {
        return {
            type: actions.exportRequested,
            payload: { onlySearchResults: onlySearchResults },
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
    uploadSurveyDataFile: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.uploadSurveyDataFile,
            payload: vm,
        };
    },
};
