// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    reprocessingProjectTitles: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectTitlesResponseDto | undefined;

    refreshReprocessingProjectTitles(response: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectTitlesResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.reprocessingProjectTitles = undefined;
    }

    public reprocessingProjectTitles: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectTitlesResponseDto | undefined;

    public refreshReprocessingProjectTitles(response: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectTitlesResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.reprocessingProjectTitles = response;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.reprocessingProjectTitles = clone(this.reprocessingProjectTitles);
        return vm;
    }
}
