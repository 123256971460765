// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    reprocessingProjects: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectsResponseDto | undefined;

    refreshReprocessingProjects(response: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectsResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.reprocessingProjects = undefined;
    }

    public reprocessingProjects: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectsResponseDto | undefined;

    public refreshReprocessingProjects(response: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.reprocessingProjects = response;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.reprocessingProjects = clone(this.reprocessingProjects);
        return vm;
    }
}
