// framework
import * as Validation from "../../../../../../common/validation/ValidationModel";
// redux
import * as Models from "./models";
// api
import * as Client from "../../../../../../api/Client";

export default function validate(vm: Models.IDigitalSignatureDetailsNewCompanySignatureViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateNewCompanySignatureDetails(vm, v);
    return v;
}

function validateNewCompanySignatureDetails(vm: Models.IDigitalSignatureDetailsNewCompanySignatureViewModel, v: Validation.ValidationModel): void {
    if (!vm.signingFor) {
        v.addError("NewCompanySignature.SigningFor", "This field is required.");
    }

    if (!vm.signingAs) {
        v.addError("NewCompanySignature.SigningAs", "This field is required.");
    }

    // poa fields
    if (vm.signingAs?.type === Client.DraftApplicationFormDocumentationDigitalSignatureTypeEnum.PowerOfAttorney) {
        if (Validation.isNullOrWhiteSpace(vm.poaLocation)) {
            v.addError("NewCompanySignature.PoaLocation", "Location is required.");
        } else if (vm.poaLocation!.length > 100) {
            v.addError("NewCompanySignature.PoaLocation", "Location must be 100 characters or less.");
        }
        if (Validation.isNullOrWhiteSpace(vm.poaPosition)) {
            v.addError("NewCompanySignature.PoaPosition", "Position is required.");
        } else if (vm.poaPosition!.length > 100) {
            v.addError("NewCompanySignature.PoaPosition", "Position must be 100 characters or less.");
        }
        if (vm.isPoaPreviouslyProvided === undefined) {
            v.addError("NewCompanySignature.IsPoaPreviouslyProvided", "This field is required.");
        }
        if (!vm.poaDate) {
            v.addError("NewCompanySignature.PoaDate", "Date of POA is required.");
        } else if (vm.poaDate > new Date()) {
            v.addError("NewCompanySignature.PoaDate", "Date of POA cannot be in the future.");
        }
    }
}
