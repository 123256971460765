// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import DirtyScope from "../../../../common/shell/DirtyScopeController";
import { useHistory } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
// views
import AwaitingConfirmationView from "./views/AwaitingConfirmationView";
import RequiringPaymentView from "./views/RequiringPaymentView";
import SelectedFinancialNotificationsView from "./views/SelectedFinancialNotificationsView";
import ConflictAlertView from "../../../../common/alerts/ConflictAlertView";
import StatusMessagesAlertsView from "../../../../common/alerts/StatusMessagesAlertsView";
import MakePaymentView from "./views/MakePaymentView";
// common
import * as companyRouteHelper from "../../../companyRouteHelper";

interface IFinancialNotificationListControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IFinancialNotificationListControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;
    const title = vm.viewState === Models.ViewStateEnum.Pay ? "Pay Financial Notifications" : "Outstanding Financial Notifications";

    const history = useHistory();

    // on mount
    useEffect(() => {
        actions.initialiseRequested();
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function onSelectionChange(financialNotifications: Array<Models.IFinancialNotificationRequiringPaymentViewModel>) {
        actions.viewChanged(vm.refreshSelectionChanged(financialNotifications));
    }

    function onMakePayment() {
        const selectedFns = vm.financialNotificationsRequiringPayment.filter((fns) => fns.isSelected);
        if (selectedFns.length === 1) {
            // selecting a single fn should redirect to the fn details single payment view
            history.push(`${companyRouteHelper.financialNotificationDetails(selectedFns[0].financialNotificationLink.id, selectedFns[0].financialNotificationLink.isComplete)}`);
        } else {
            // selecting multiple fns toggles to the multi fn payment view
            // scroll to the top
            window.scrollTo({ top: 0, behavior: "smooth" });
            actions.viewChanged(vm.onMakePayment());
        }
    }

    function onCancelPayment() {
        actions.viewChanged(vm.onCancelPayment());
    }

    return (
        <BodyLayout
            title={title}
            selectedLhsNavItem={Level1ItemTypeEnum.Company_Finance_FinancialNotificationList}
            onReloadRequested={() => {
                actions.initialiseRequested();
            }}
        >
            <DirtyScope scopeName="company/finance/financialNotificationList" isDirty={vm.isDirty}>
                <ConflictAlertView isConflict={vm.isConflict} />
                <StatusMessagesAlertsView statusMessages={vm.statusMessages} />

                {/* Requiring payment/Make payment */}
                {vm.viewState === Models.ViewStateEnum.Pay ? (
                    <div className="mb-2">
                        <SelectedFinancialNotificationsView vm={vm.selectedFinancialNotifications} />
                        <div className="card my-2">
                            <MakePaymentView vm={vm} actions={actions} onCancelPayment={onCancelPayment} />
                        </div>
                    </div>
                ) : (
                    <>
                        {/* Awaiting confirmation */}
                        <div className="mb-4">
                            <AwaitingConfirmationView vm={vm.financialNotificationsAwaitingConfirmation} />
                        </div>

                        <div className="mb-2">
                            <RequiringPaymentView vm={vm.financialNotificationsRequiringPayment} onSelectionChange={onSelectionChange} />
                            <div className="row">
                                <div className="col">
                                    <button className="btn btn-outline-primary mt-2" disabled={!vm.financialNotificationsRequiringPayment?.some((f) => f.isSelected)} onClick={onMakePayment}>
                                        Make/Record Payment
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </DirtyScope>
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_Finance_FinancialNotificationList }), Actions.actionFactory)(Controller);
