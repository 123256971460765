// framework
import { useState } from "react";
// redux
import * as Models from "../../../models/models";
import * as Actions from "../../../redux/actions";
// common
import ConfirmationView, { DefaultButtonEnum } from "../../../../../../../../../common/confirmation/ConfirmationView";
import * as SecureFileDownloadButtonControl from "../../../../../../../../../common/secureFileDownload/SecureFileDownloadButtonControl";
// views
import UploadFileView from "./UploadFileView";
import WarningsView from "./WarningsView";
import FilesGridView from "../FilesGridView";

type WetSignatureViewProps = {
    vm: Models.IWetSignatureViewModel;
    actions: Actions.IActionFactory;
    showCommands: boolean;
    isBusy: boolean;
    onBusyChanged: (busy: boolean) => void;
    generatedDocumentRequested: () => void;
    updateSelection: (fileId: number) => void;
    updateHeaderSelection: (isChecked: boolean) => void;
};

export default function WetSignatureView(props: WetSignatureViewProps): React.ReactElement {
    const { vm, actions, showCommands, isBusy, onBusyChanged, generatedDocumentRequested, updateSelection, updateHeaderSelection } = props;

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    function onDeleteRequestClick(): void {
        setShowDeleteConfirmation(true);
    }

    function onDeleteAccepted(): void {
        setShowDeleteConfirmation(false);
        actions.viewChanged(vm.onWetSigDelete());
    }

    function onDeleteCancelled(): void {
        setShowDeleteConfirmation(false);
    }

    const files = vm.wetSignatureDetails.file ? [vm.wetSignatureDetails.file] : [];

    const downloadFiles: Array<SecureFileDownloadButtonControl.IFile> = files
        .filter((f) => f.isSelected)
        .map((f) => {
            return { id: f.fileId, size: f.fileSize };
        });
    return (
        <>
            <WarningsView vm={vm} />

            {/* guidance */}
            {showCommands && (
                <div>
                    <p>Download a prepopulated Legislative Form.</p>
                    <button className="btn btn-outline-secondary mb-2" type="button" title="Download Document" onClick={generatedDocumentRequested}>
                        Download
                    </button>
                    <p>The document must be downloaded, signed and uploaded when complete. The information on the document must not be edited, you may only sign the document.</p>
                </div>
            )}

            {/* file grid */}
            <div className="mb-2">
                <FilesGridView files={vm.wetSignatureDetails.file ? [vm.wetSignatureDetails.file] : []} updateSelection={updateSelection} updateHeaderSelection={updateHeaderSelection} />
            </div>

            {/* grid action buttons */}
            {vm.wetSignatureDetails.file && (
                <div className="mb-4">
                    <SecureFileDownloadButtonControl.ButtonControl disabled={isBusy || vm.wetSignatureDetails.numberOfFilesSelected === 0} files={downloadFiles} className="me-1" />
                    {vm.viewState === Models.ViewStateEnum.Edit && (
                        <button className="btn btn-outline-secondary" type="button" disabled={isBusy || vm.wetSignatureDetails.numberOfFilesSelected === 0} onClick={onDeleteRequestClick}>
                            Delete
                        </button>
                    )}
                    <div>
                        <SecureFileDownloadButtonControl.ValidationControl files={downloadFiles} />
                    </div>
                </div>
            )}

            {/* delete confirmation */}
            {showDeleteConfirmation && (
                <ConfirmationView title="Confirm File Deletion" onAccepted={onDeleteAccepted} onClosed={onDeleteCancelled} defaultButton={DefaultButtonEnum.Cancel}>
                    <p>You have selected {vm.wetSignatureDetails.numberOfFilesSelected} file(s) for deletion.</p>
                    <p>Do you wish to continue?</p>
                </ConfirmationView>
            )}

            {/* upload view */}
            {vm.viewState === Models.ViewStateEnum.Edit && (
                <div className="mb-2">
                    <UploadFileView vm={vm} actions={actions} onBusyChanged={onBusyChanged} />
                </div>
            )}
        </>
    );
}
