// framework
import { useEffect, useState } from "react";
// redux
import * as Models from "../../models/models";
// common
import { ValidationModel } from "../../../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../../../common/validation/ValidationMessageView";
import * as Forms from "../../../../../../../common/forms/BootstrapForms";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import YesNoDropDownList from "../../../../../../../common/dropdowns/YesNoDropDownListView";
import { IApplicationContactViewModel } from "../../models/models";

export default function ContactView(props: { vm: Models.IRootViewModel; v: ValidationModel; updateViewModel: (vm: Models.IApplicationContactViewModel) => void }) {
    const vm = props.vm;
    const v = props.v;

    const [state, setState] = useState<Models.IApplicationContactViewModel>({
        primaryContactName: "",
        positionHeld: "",
        phone: "",
        email: "",
        fax: "",
        businessAddress: "",
        isOverlappingContactSharingConsent: undefined,
        overlappingContactType: undefined,
        isElectronicCommunicationConsent: undefined,
    });

    useEffect(() => {
        setState({
            primaryContactName: vm.contact.primaryContactName ?? "",
            positionHeld: vm.contact.positionHeld ?? "",
            phone: vm.contact.phone ?? "",
            email: vm.contact.email ?? "",
            fax: vm.contact.fax ?? "",
            businessAddress: vm.contact.businessAddress ?? "",
            isOverlappingContactSharingConsent: vm.contact.isOverlappingContactSharingConsent ?? false,
            overlappingContactType: vm.contact.overlappingContactType ?? undefined,
            isElectronicCommunicationConsent: vm.contact.isElectronicCommunicationConsent ?? undefined,
        });
    }, [vm.contact]);

    // text input change
    function onChange(values: IApplicationContactViewModel) {
        setState({ ...state, ...values });
    }

    function onBlur() {
        props.updateViewModel(state);
    }

    // checkbox/dropdown change
    function onDropdownChange(values: Models.IApplicationContactViewModel) {
        props.updateViewModel(values);
    }

    return (
        <>
            <Forms.Row>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField label="Company Name" id="companyNameField" value={vm.companyName} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.TextInput
                        id="phoneField"
                        label="Phone"
                        value={state.phone}
                        valid={v.applyValidity("ContactDetails.Phone")}
                        onChange={(value) => onChange({ phone: value })}
                        onBlur={onBlur}
                    />
                    <ValidationMessageView name="ContactDetails.Phone" validationModel={v} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.TextInput
                        id="primaryContactNameField"
                        label="Primary Contact Name"
                        value={state.primaryContactName}
                        valid={v.applyValidity("ContactDetails.PrimaryContactName")}
                        onChange={(value) => onChange({ primaryContactName: value })}
                        onBlur={onBlur}
                    />
                    <ValidationMessageView name="ContactDetails.PrimaryContactName" validationModel={v} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.TextInput
                        id="emailField"
                        label="Email"
                        value={state.email}
                        valid={v.applyValidity("ContactDetails.Email")}
                        onChange={(value) => onChange({ email: value })}
                        onBlur={onBlur}
                    />
                    <ValidationMessageView name="ContactDetails.Email" validationModel={v} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.TextInput
                        id="positionHeldField"
                        label="Position Held"
                        value={state.positionHeld}
                        valid={v.applyValidity("ContactDetails.PositionHeld")}
                        onChange={(value) => onChange({ positionHeld: value })}
                        onBlur={onBlur}
                    />
                    <ValidationMessageView name="ContactDetails.PositionHeld" validationModel={v} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.TextInput id="faxField" label="Fax" value={state.fax} valid={v.applyValidity("ContactDetails.Fax")} onChange={(value) => onChange({ fax: value })} onBlur={onBlur} />
                    <ValidationMessageView name="ContactDetails.Fax" validationModel={v} />
                </Forms.HalfColumn>
                <Forms.FullColumn>
                    <Forms.TextArea
                        id="businessAddressField"
                        label="Business Address"
                        value={state.businessAddress}
                        valid={v.applyValidity("ContactDetails.BusinessAddress")}
                        onChange={(value) => onChange({ businessAddress: value })}
                        onBlur={onBlur}
                    />
                    <ValidationMessageView name="ContactDetails.BusinessAddress" validationModel={v} />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.Label htmlFor="overlappingContactTypeField">Overlapping Contact</Forms.Label>
                    <p>Please confirm if you would like the above contact details to be used or if you wish to provide alternative contact details for that purpose.</p>
                    <DropDownList
                        id="overlappingContactTypeField"
                        valid={v.applyValidityForKendo("ContactDetails.OverlappingContactType")}
                        data={vm.overlappingContactTypes}
                        value={state.overlappingContactType}
                        dataItemKey="overlappingContactType"
                        textField="description"
                        onChange={(e) => onDropdownChange({ ...state, overlappingContactType: e.value })}
                    />
                    <ValidationMessageView name="ContactDetails.OverlappingContactType" validationModel={v} />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.Checkbox
                        id="isOverlappingContactSharingConsentField"
                        label="In the case of your application’s licence area overlapping with that of another feasibility licence application, the Registrar may provide your contact information to other applicants in an overlapping application group. I acknowledge my contact information may be provided to other applicants."
                        checked={state.isOverlappingContactSharingConsent}
                        valid={v.applyValidity("ContactDetails.IsOverlappingContactSharingConsent")}
                        onChange={(checked) => onDropdownChange({ ...state, isOverlappingContactSharingConsent: checked })}
                    />
                    <ValidationMessageView name="ContactDetails.IsOverlappingContactSharingConsent" validationModel={v} />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.Label htmlFor="isElectronicCommunicationConsentField">Do you consent to information being given by way of electronic communication.</Forms.Label>
                    <YesNoDropDownList
                        id="isElectronicCommunicationConsentField"
                        valid={v.applyValidityForKendo("ContactDetails.IsElectronicCommunicationConsent")}
                        value={state.isElectronicCommunicationConsent}
                        onChange={(value) => onChange({ isElectronicCommunicationConsent: value })}
                        onBlur={onBlur}
                    />
                    <ValidationMessageView name="ContactDetails.IsElectronicCommunicationConsent" validationModel={v} />
                </Forms.FullColumn>
            </Forms.Row>
        </>
    );
}
