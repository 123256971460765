// framework
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
// redux
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// common
import BodyLayout from "../../../../../common/shell/BodyLayoutController";
import * as toastHelper from "../../../../../common/toastHelper";
import { ValidationVisibilityEnum } from "../../../../../common/validation/ValidationModel";
import ConfirmationView, { DefaultButtonEnum } from "../../../../../common/confirmation/ConfirmationView";
import StatusMessagesAlertsView from "../../../../../common/alerts/StatusMessagesAlertsView";
import ConflictAlertView from "../../../../../common/alerts/ConflictAlertView";
// views
import NavBar, { TabsEnum } from "../../components/views/NavBarView";
import ReportAttachmentsListGridView from "./views/ReportAttachmentsListGridView";
import ReportAttachmentView from "./views/ReportAttachmentView";
import validator from "../models/validator";

interface IRouteParameters {
    reportId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { reportId } = params;

    const [showAddElementDialog, setShowAddElementDialog] = useState(false);
    const [showUpdateElementDialog, setShowUpdateElementDialog] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const [deleteItem, setDeleteItem] = useState<{ reportAttachmentId?: number }>();
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const v = validator(vm, validationVisibility);

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(reportId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onCreateClicked = () => {
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        actions.addRequested(vm);
        window.scrollTo(0, 0);
        setShowAddElementDialog(false);
    };

    const onUpdateClicked = () => {
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        actions.updateRequested(vm);
        window.scrollTo(0, 0);
        setShowUpdateElementDialog(false);
    };

    const onCreateCancelClicked = () => {
        setShowAddElementDialog(false);
        actions.viewChanged(vm.clearStatusMessages());
        setValidationVisibility(ValidationVisibilityEnum.Messages);
    };

    const onUpdateCancelClicked = () => {
        setShowUpdateElementDialog(false);
        actions.viewChanged(vm.clearStatusMessages());
        setValidationVisibility(ValidationVisibilityEnum.Messages);
    };

    const onUpdateShow = (saveReportAttachment: Models.ISaveReportAttachment) => {
        actions.viewChanged(vm.clearStatusMessages());
        setShowUpdateElementDialog(true);
        actions.viewChanged(vm.onSaveReportAttachmentChanged(saveReportAttachment));
    };

    const onDeleteShow = (reportAttachmentId: number) => {
        setShowDeleteConfirmation(true);
        setDeleteItem({ reportAttachmentId: reportAttachmentId });
    };

    const onDeleteCancel = () => {
        setDeleteItem({});
        setShowDeleteConfirmation(false);
    };

    const onDeleteAccept = () => {
        actions.deleteRequested(vm.reportId!, vm.reportVersionNumber!, deleteItem!.reportAttachmentId!);
        setShowDeleteConfirmation(false);
    };

    return (
        <>
            {vm.Attachments && vm.reportHeader && (
                <BodyLayout
                    title={`Report - ${vm.reportHeader}`}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_ReportsAndData_Reports}
                    onReloadRequested={() => {
                        props.initialiseRequested(Number(reportId));
                    }}
                >
                    <ConflictAlertView isConflict={vm.isConflict} />
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
                    <NavBar reportId={Number(reportId)} activeNavItem={TabsEnum.Attachments} />
                    <ReportAttachmentsListGridView vm={vm} onDelete={onDeleteShow} onEdit={onUpdateShow} />
                    <div className="mt-3">
                        <button
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={() => {
                                actions.viewChanged(vm.clearStatusMessages());
                                setShowAddElementDialog(true);
                            }}
                        >
                            Create
                        </button>
                    </div>
                </BodyLayout>
            )}
            {showAddElementDialog && <ReportAttachmentView vm={vm} v={v} actions={actions} onSave={onCreateClicked} onCancel={onCreateCancelClicked} />}
            {showUpdateElementDialog && <ReportAttachmentView vm={vm} v={v} actions={actions} onSave={onUpdateClicked} onCancel={onUpdateCancelClicked} />}
            {showDeleteConfirmation && (
                <ConfirmationView title="Delete Report Attachment" onAccepted={onDeleteAccept} onClosed={onDeleteCancel} defaultButton={DefaultButtonEnum.Cancel}>
                    <p>Are you sure you want to delete this report attachment?</p>
                </ConfirmationView>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_WellsBoreholes_ReportAttachmentList }), Actions.actionFactory)(Controller);
