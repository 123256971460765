import * as Actions from "./actions";
import * as Models from "../models/rootViewModel";

// reducers
export function reducer(state: Models.IRootViewModel = new Models.RootViewModel(), action: Actions.IAction<any>): Models.IRootViewModel {
    switch (action.type) {
        case Actions.actions.updateBodyLayout:
            const updateBodyLayoutPayload: Actions.IBodyLayoutPayload = action.payload;
            return state.updateBodyLayout(
                updateBodyLayoutPayload.pageTitle,
                updateBodyLayoutPayload.showPageHeader,
                updateBodyLayoutPayload.includePadding,
                updateBodyLayoutPayload.selectedLhsNavItem
            );

        case Actions.actions.setBusy:
            return state.setBusy();

        case Actions.actions.clearBusy:
            return state.clearBusy();

        case Actions.actions.setDirty:
            const setDirtyPayload: string = action.payload;
            return state.setDirty(setDirtyPayload);

        case Actions.actions.clearDirty:
            const clearDirtyPayload: string = action.payload;
            return state.clearDirty(clearDirtyPayload);

        case Actions.actions.refreshLhsNavRequested:
            return state;

        case Actions.actions.sagaCompleted:
            return action.payload;

        default:
            return state;
    }
}
