// views
import CommonNavBar from "../../../../common/CommonNavBarView";
// common
import * as geoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";

export enum TabsEnum {
    Details,
    History,
}

export interface IProps {
    rigId: number;
    activeNavItem: TabsEnum;
}

export default function NavBarView(props: IProps): JSX.Element {
    return (
        <CommonNavBar
            activeNavItemKey={props.activeNavItem}
            navItems={[
                {
                    key: TabsEnum.Details,
                    title: "Details",
                    url: geoscienceAustraliaRouteHelper.rigDetails(props.rigId),
                },
                {
                    key: TabsEnum.History,
                    title: "History",
                    url: geoscienceAustraliaRouteHelper.rigHistoryList(props.rigId),
                },
            ]}
        />
    );
}
