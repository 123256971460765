// framework
import React from "react";
import { useState, useEffect } from "react";
// kendo
import { ListItemProps, MultiSelect, MultiSelectChangeEvent, MultiSelectFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { FilterDescriptor, filterBy } from "@progress/kendo-data-query";

interface IProps<T> {
    id: string;
    value: T[] | undefined;
    data: T[] | undefined;
    keyField: string;
    textField: string;
    recordsToShow?: number | undefined;
    disabled?: boolean | undefined;
    valid?: boolean | undefined;
    className?: string | undefined;
    onChange: (value: T[]) => void;
    onBlur?: (() => void) | undefined;
}

// Note: Kendo has an upcoming version that supports disabled items, using this will allow keyField/textField to be made optional and the custom itemRender to be removed.
// https://www.telerik.com/kendo-react-ui/components/dropdowns/multiselect/disabled-item/
export default function KendoLargeMultiSelect<T>(props: IProps<T>) {
    const [value, setValue] = useState<T[]>();

    useEffect(() => {
        setValue(props.value ?? []);
    }, [props.value]);

    const recordsToShow = props.recordsToShow ?? 100;
    const hintMessage = { [props.keyField]: null, [props.textField]: "Enter details to filter..." } as T;

    const showAllData = props.data && props.data!.length < recordsToShow;

    const [data, setData] = React.useState<T[]>(showAllData ? props.data! : [hintMessage]);
    const [filterText, setFilterText] = React.useState<string>("");

    const filterData = (filter: FilterDescriptor) => {
        if (!filter.value || filter.value.length === 0) {
            if (showAllData) return props.data!.slice();
            else return [hintMessage];
        }

        const data = props.data?.slice();
        const filteredData = filterBy(data ?? [], filter);

        if (filteredData.length === 0) return [{ [props.keyField]: null, [props.textField]: "No records found" } as T];
        if (filteredData.length <= recordsToShow) return filteredData;

        const filterMessage = { [props.keyField]: null, [props.textField]: `Displaying first ${recordsToShow} of ${filteredData.length} records` } as T;
        return filteredData?.slice(0, recordsToShow).concat([filterMessage]);
    };

    const filterChange = (event: MultiSelectFilterChangeEvent) => {
        setFilterText(event.filter.value);
        setData(filterData(event.filter));
    };

    function onChange(event: MultiSelectChangeEvent) {
        const value = event.value.filter((item: T) => item[props.keyField as keyof T] !== null);
        setValue(value);
        props.onChange(value ?? undefined);
    }

    function onBlur() {
        setFilterText("");
        props.onBlur?.();
    }

    // ToDo: Remove itemRender once using disabled items.
    const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
        const dataItem = itemProps.dataItem;
        if (dataItem[props.keyField] === null) {
            return (
                <span className="k-list-item k-state-disabled" style={{ userSelect: "none", pointerEvents: "none", color: "#adb5bd" }}>
                    {dataItem[props.textField]}
                </span>
            );
        } else {
            return li;
        }
    };

    return (
        <MultiSelect
            id={props.id}
            className={props.className}
            data={data}
            value={value}
            dataItemKey={props.keyField}
            textField={props.textField}
            disabled={props.disabled}
            valid={props.valid}
            filterable={true}
            filter={filterText}
            onFilterChange={filterChange}
            onChange={onChange}
            onBlur={onBlur}
            itemRender={itemRender}
            placeholder="Enter details to filter..."
            popupSettings={{ height: 390 }}
        />
    );
}
