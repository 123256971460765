// redux
import * as Models from "../../../models/models";
// common
import SimpleAlertView from "../../../../../../../../common/simpleAlerts/SimpleAlertView";

export default function WarningsView(props: { vm: Models.IWetSignatureViewModel }): React.ReactElement {
    const vm = props.vm;

    // using a warnings view in lieu of having a warnings capability built into our validation framework

    const showFileValidation = vm.viewState === Models.ViewStateEnum.Edit && vm.wetSignatureDetails.files.length === 0;
    const message = showFileValidation ? "Please upload a signed Application Form." : null;

    return <>{message && <SimpleAlertView alertType="warning" dismissable={false} message={message} />}</>;
}
