import * as Models from "../models/models";

// -------
// Actions
// -------

export enum actions {
    // view events
    getReferenceDataRequested = "company.title.legislativeForms.formRequestCreate.getReferenceDataRequested",
    clearRequested = "company.title.legislativeForms.formRequestCreate.clearRequested",
    // user input
    viewChanged = "company.title.legislativeForms.formRequestCreate.viewChanged",
    // saga events
    sagaCreateFormRequestRequested = "company.title.legislativeForms.formRequestCreate.sagaCreateFormRequestRequested",
    sagaCompleted = "company.title.legislativeForms.formRequestCreate.sagaCompleted",
}

// --------
// Payloads
// --------

export interface IAction<T> {
    type: actions;
    payload: T;
}

// ---------
// Factories
// ---------

export interface IActionFactory {
    getReferenceDataRequested(titleId: string): IAction<string>;
    clearRequested(): IAction<undefined>;
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    sagaCreateFormRequestRequested(): IAction<undefined>;
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    getReferenceDataRequested: function (titleId: string): IAction<string> {
        return {
            type: actions.getReferenceDataRequested,
            payload: titleId,
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    sagaCreateFormRequestRequested: function (): IAction<undefined> {
        return {
            type: actions.sagaCreateFormRequestRequested,
            payload: undefined,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
