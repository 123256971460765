// framework
import { Link } from "react-router-dom";
// models
import { IRootViewModel } from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import SimpleAuditEventsView from "../../../../../../common/audit/SimpleAuditEventsView";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";

interface IProps {
    vm: IRootViewModel;
}

export default function DataMonitoringItemDetailsView(props: IProps): JSX.Element {
    const { vm } = props;
    return (
        <>
            {vm.dataMonitoringItem && (
                <>
                    <div className="card mb-2">
                        <div className="card-body">
                            <h2 className="card-title">{vm.dataMonitoringItem.name}</h2>
                            <Forms.Row>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="identifierField" label="Identifier" value={vm.dataMonitoringItem.identifier} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.Label htmlFor={"surveyField"}>Survey</Forms.Label>
                                    <br />
                                    {vm.permissions.canViewSurveys && vm.dataMonitoringItem.survey ? (
                                        <Link
                                            id="surveyField"
                                            to={geoscienceAustraliaRouteHelper.surveyDataMonitoring(vm.dataMonitoringItem.survey.id)}
                                            className="form-link"
                                            title="Click to view Survey Data Monitoring."
                                        >
                                            {vm.dataMonitoringItem.survey.name}
                                        </Link>
                                    ) : (
                                        <p id="surveyField" className="form-label">
                                            {vm.dataMonitoringItem.survey?.name}
                                        </p>
                                    )}
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.Label htmlFor={"acquisitionField"}>Acquisition</Forms.Label>
                                    <br />
                                    {vm.permissions.canViewAcquisitions && vm.dataMonitoringItem.acquisition ? (
                                        <Link
                                            id="acquisitionField"
                                            to={geoscienceAustraliaRouteHelper.acquisitionDataMonitoring(vm.dataMonitoringItem.survey!.id, vm.dataMonitoringItem.acquisition.id)}
                                            className="form-link"
                                            title="Click to view Acquisition Data Monitoring."
                                        >
                                            {vm.dataMonitoringItem.acquisition.name}
                                        </Link>
                                    ) : (
                                        <p id="acquisitionField" className="form-label">
                                            {vm.dataMonitoringItem.acquisition?.name}
                                        </p>
                                    )}
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.Label htmlFor={"duplicateDataMonitoringItemField"}>Duplicate(s)</Forms.Label>
                                    <br />
                                    {vm.dataMonitoringItem.duplicateDataMonitoringItems && vm.dataMonitoringItem.duplicateDataMonitoringItems.length > 0 ? (
                                        <div>
                                            {vm.dataMonitoringItem.duplicateDataMonitoringItems.map((item, index) => (
                                                <span key={item.id}>
                                                    <Link
                                                        to={geoscienceAustraliaRouteHelper.surveyDataMonitoringDetails(vm.dataMonitoringItem!.survey!.id, item.id)}
                                                        className="form-link"
                                                        title="Click to view Data Monitoiring Item Details."
                                                        key={item.id}
                                                    >
                                                        {item.identifier}
                                                    </Link>
                                                    {item.isInvalid && (
                                                        <span className={`badge rounded-pill text-bg-warning ms-1`} title={`Duplicates are not valid for this DMI Type.`}>
                                                            Duplicate
                                                        </span>
                                                    )}
                                                    <span>{index === vm.dataMonitoringItem!.duplicateDataMonitoringItems.length - 1 ? <></> : ", "}</span>
                                                </span>
                                            ))}
                                        </div>
                                    ) : (
                                        <p id="duplicateDataMonitoringItemField" className="form-label">
                                            No duplicate items
                                        </p>
                                    )}
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="statusField" label="Status" value={vm.dataMonitoringItem.status} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.Label htmlFor={"dataSubmissionField"}>Data Submission</Forms.Label>
                                    <br />
                                    {vm.dataMonitoringItem.dataSubmission ? (
                                        vm.permissions.canViewDataSubmissions ? (
                                            <Link
                                                id="dataSubmissionField"
                                                to={geoscienceAustraliaRouteHelper.dataSubmissionDataMonitoring(vm.dataMonitoringItem.dataSubmission!.id)}
                                                className="form-link"
                                                title="Click to view Data Submission Data Monitoring."
                                            >
                                                {vm.dataMonitoringItem.dataSubmission.name}
                                            </Link>
                                        ) : (
                                            <p id="dataSubmissionField" className="form-label">
                                                {vm.dataMonitoringItem.dataSubmission.name}
                                            </p>
                                        )
                                    ) : (
                                        <p id="dataSubmissionField" className="form-label">
                                            No Data Submission
                                        </p>
                                    )}
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.Label htmlFor={"authorisationsField"}>Linked Authorisation(s)</Forms.Label>
                                    <br />
                                    {vm.permissions.canViewAuthorisations && vm.dataMonitoringItem.authorisations && vm.dataMonitoringItem.authorisations.length > 0 ? (
                                        <div>
                                            {vm.dataMonitoringItem.authorisations.map((auth, index) => (
                                                <span key={auth.id}>
                                                    <Link
                                                        to={geoscienceAustraliaRouteHelper.authorisationDetails(auth.id)}
                                                        className="form-link"
                                                        title="Click to view Authorisation Details."
                                                        key={auth.id}
                                                    >
                                                        {auth.identifier}
                                                    </Link>
                                                    <span>{index === vm.dataMonitoringItem.authorisations.length - 1 ? <></> : ", "}</span>
                                                </span>
                                            ))}
                                        </div>
                                    ) : (
                                        <p id="authorisationsField" className="form-label">
                                            {!vm.dataMonitoringItem.authorisations || vm.dataMonitoringItem.authorisations.length === 0
                                                ? "No linked authorisations"
                                                : vm.dataMonitoringItem.authorisations.map((auth) => auth.identifier).join(", ")}
                                        </p>
                                    )}
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.Label htmlFor={"resubmissionsField"}>Linked Resubmission(s)</Forms.Label>
                                    <br />
                                    {vm.permissions.canViewDataSubmissions && vm.dataMonitoringItem.resubmissions && vm.dataMonitoringItem.resubmissions.length > 0 ? (
                                        <div>
                                            {vm.dataMonitoringItem.resubmissions.map((resubmission, index) => (
                                                <span key={resubmission.id}>
                                                    <Link
                                                        to={geoscienceAustraliaRouteHelper.dataSubmissionResubmissionDetails(vm.dataMonitoringItem!.dataSubmission!.id, resubmission.id)}
                                                        className="form-link"
                                                        title="Click to view Resubmission Details."
                                                        key={resubmission.id}
                                                    >
                                                        {resubmission.name}
                                                    </Link>
                                                    <span>{index === vm.dataMonitoringItem!.resubmissions.length - 1 ? <></> : ", "}</span>
                                                </span>
                                            ))}
                                        </div>
                                    ) : (
                                        <p id="resubmissionsField" className="form-label">
                                            {!vm.dataMonitoringItem.resubmissions || vm.dataMonitoringItem.resubmissions.length === 0
                                                ? "No linked resubmissions"
                                                : vm.dataMonitoringItem.resubmissions.map((resub) => resub.name).join(", ")}
                                        </p>
                                    )}
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="dueDateField" label="Due Date" value={GlobalHelpers.toNoptaDateString(vm.dataMonitoringItem.dueDate)} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField
                                        id="acquisitionCalcDueDateField"
                                        label="Acquisition Calc Due Date"
                                        value={GlobalHelpers.toNoptaDateString(vm.dataMonitoringItem.acquisitionCalcDueDate)}
                                    />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="ExtendedDueDateField" label="Extended Due Date" value={GlobalHelpers.toNoptaDateString(vm.dataMonitoringItem.extensionDueDate)} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField
                                        id="resubmissionDueDateField"
                                        label="Resubmission Due Date"
                                        value={GlobalHelpers.toNoptaDateString(vm.dataMonitoringItem.resubmissionDueDate)}
                                    />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="receivedDateField" label="Received Date" value={GlobalHelpers.toNoptaDateString(vm.dataMonitoringItem.receivedDate)} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField
                                        id="contentsCheckCompletedDateField"
                                        label="Contents Check Completed Date"
                                        value={GlobalHelpers.toNoptaDateString(vm.dataMonitoringItem.contentsCheckCompletedDate)}
                                    />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField
                                        id="dataCheckCompletedDateField"
                                        label="Data Check Completed Date"
                                        value={GlobalHelpers.toNoptaDateString(vm.dataMonitoringItem.dataCheckCompletedDate)}
                                    />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="sentToGADateField" label="Sent to GA Date" value={GlobalHelpers.toNoptaDateString(vm.dataMonitoringItem.sentToGaDate)} />
                                </Forms.QuarterColumn>
                                <Forms.FullColumn>
                                    <Forms.ReadonlyTextArea id="commentsField" label="Comments" value={vm.dataMonitoringItem.comments} />
                                </Forms.FullColumn>
                            </Forms.Row>
                        </div>
                    </div>
                    <SimpleAuditEventsView simpleAuditEvents={vm.audit} />
                </>
            )}
        </>
    );
}
