import * as Models from "../models/models";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "company.registration.initialiseRequested",
    clearRequested = "company.registration.clearRequested",
    createSecureRequestRequested = "company.registration.createSecureRequestRequested",
    // user input
    viewChanged = "company.registration.viewChanged",
    requestEmailVerificationRequested = "company.registration.requestEmailVerificationRequested",
    verifyEmailVerificationCodeRequested = "company.registration.verifyEmailVerificationCodeRequested",

    // saga
    sagaCompleted = "company.registration.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(): IAction<undefined>;
    clearRequested(): IAction<undefined>;
    createSecureRequestRequested(): IAction<undefined>;
    // user input
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    requestEmailVerificationRequested(): IAction<undefined>;
    verifyEmailVerificationCodeRequested(): IAction<undefined>;
    // saga
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (): IAction<undefined> {
        return {
            type: actions.initialiseRequested,
            payload: undefined,
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    createSecureRequestRequested: function (): IAction<undefined> {
        return {
            type: actions.createSecureRequestRequested,
            payload: undefined,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    requestEmailVerificationRequested: function (): IAction<undefined> {
        return {
            type: actions.requestEmailVerificationRequested,
            payload: undefined,
        };
    },
    verifyEmailVerificationCodeRequested: function (): IAction<undefined> {
        return {
            type: actions.verifyEmailVerificationCodeRequested,
            payload: undefined,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
