// framework
import { call, put, select } from "redux-saga/effects";
// redux
import { IJointAuthorityReduxState } from "../../../../jointAuthorityReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// shell
import * as shellHelper from "../../../../../common/shell/shellHelper";
// common
import * as LogHelper from "../../../../../common/LogHelper";
import * as ConfigurationHelper from "../../../../../common/shell/configurationHelper";

const getRootViewModel = (state: IJointAuthorityReduxState) => state.JointAuthority_Account_MyDetails;

export default function* initialise() {
    // save role reference data to the vm
    let vm: Models.IRootViewModel = yield select(getRootViewModel);

    vm = vm.refreshRoleConfiguration(yield select(ConfigurationHelper.getRoleConfiguration));
    yield put(Actions.actionFactory.sagaCompleted(vm));

    // load user details
    try {
        yield put(shellHelper.createSetBusyAction());

        const response: Client.GetJointAuthorityAccountMyDetailsResponseDto = yield call(getMyUserDetails);
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshDetails(response)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

export async function getMyUserDetails(): Promise<Client.GetJointAuthorityAccountMyDetailsResponseDto> {
    const client = await ClientFactory.createJointAuthorityAccountClient();
    return await CallHelper.simpleCall(() => client.getJointAuthorityAccountMyDetails(new Client.GetJointAuthorityAccountMyDetailsRequestDto()));
}
