// api
import * as ClientFactory from "../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";

export async function getCompanyOpggsApplicationDetailsApplicationDetails(id: string): Promise<Client.GetCompanyOpggsApplicationDetailsApplicationDetailsResponseDto> {
    // console.info("getCompanyOpggsApplicationDetailsApplicationDetails");
    const client = await ClientFactory.createCompanyOpggsApplicationDetailsClient();
    return await CallHelper.simpleCall(() =>
        client.getCompanyOpggsApplicationDetailsApplicationDetails(new Client.GetCompanyOpggsApplicationDetailsApplicationDetailsRequestDto({ applicationId: id }))
    );
}
