// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../api/Client";

export interface IRootViewModel {
    rigs: Client.GetGeoscienceAustraliaWellsBoreholesRigListItemDto[];
    isResultSetLimited: boolean;
    searchText: string;

    refreshList(response: Client.GetGeoscienceAustraliaWellsBoreholesRigListResponseDto): IRootViewModel;
    changeSearchText(value: string): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.rigs = [];
        this.isResultSetLimited = false;
        this.searchText = "";
    }

    public rigs: Client.GetGeoscienceAustraliaWellsBoreholesRigListItemDto[];
    public isResultSetLimited: boolean;
    public searchText: string;

    public refreshList(response: Client.GetGeoscienceAustraliaWellsBoreholesRigListResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.rigs = response.rigs;
        vm.isResultSetLimited = response.isResultSetLimited;
        return vm;
    }

    public changeSearchText(value: string): IRootViewModel {
        const vm = this._clone();
        vm.searchText = value;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
