// api
import * as Client from "../../../../api/Client";
// other
import { BaseRoleViewModel, IRoleViewModel } from "../../../../common/roleGrid/RoleViewModel";

export interface IJointAuthorityAdministratorRequestViewModel extends IRoleViewModel {
    requestLink: Client.SecureRequestGroupLinkDto;
    requestVersionNumber: number;
    requestDatetime: Date;
    jointAuthorityId: number;
    jointAuthorityName?: string | undefined;
    secureUserId?: number | undefined;
    secureUserDisplayName?: string | undefined;
    isSecureUserBlocked: boolean;
    isSignerRequest: boolean;
}

export class JointAuthorityAdministratorRequestViewModel extends BaseRoleViewModel implements IJointAuthorityAdministratorRequestViewModel {
    constructor(
        roleRelationships: Array<Client.GetShellSecureUserRoleConfigurationRoleRelationshipDto>,
        data: Client.IJointAuthorityUserManagementJointAuthorityRequestListAdministratorJointAuthorityRequestListItemDto
    ) {
        super(roleRelationships, data.rolesRequested);

        this.requestLink = data.secureRequestGroupLink;
        this.requestVersionNumber = data.requestVersionNumber;
        this.requestDatetime = data.requestDatetime;
        this.jointAuthorityId = data.jointAuthorityId;
        this.jointAuthorityName = data.jointAuthorityName;
        this.secureUserId = data.secureUserId;
        this.secureUserDisplayName = data.secureUserDisplayName;
        this.isSecureUserBlocked = data.isSecureUserBlocked;
        this.isSignerRequest = data.isSignerRequest;
    }

    public readonly requestLink: Client.SecureRequestGroupLinkDto;
    public readonly requestVersionNumber: number;
    public readonly requestDatetime: Date;
    public readonly jointAuthorityId: number;
    public readonly jointAuthorityName?: string | undefined;
    public readonly secureUserId?: number | undefined;
    public readonly secureUserDisplayName?: string | undefined;
    public readonly isSecureUserBlocked: boolean;
    public readonly isSignerRequest: boolean;

    public getKey(): number {
        return this.requestLink.id;
    }

    public canEdit(): boolean {
        return false;
    }
}
