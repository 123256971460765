// api
import * as ClientFactory from "../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";

export async function getJointAuthorityOpggsApplicationDetailsApprovalTracking(id: string): Promise<Client.GetJointAuthorityOpggsApplicationDetailsApprovalTrackingResponseDto> {
    const client = await ClientFactory.createJointAuthorityOpggsApplicationDetailsClient();
    return await CallHelper.simpleCall(() =>
        client.getJointAuthorityOpggsApplicationDetailsApprovalTracking(new Client.GetJointAuthorityOpggsApplicationDetailsApprovalTrackingRequestDto({ applicationId: id }))
    );
}
