// redux
import * as Models from "../../models/models";
import * as Actions from "../../redux/actions";
// common
import { ValidationModel } from "../../../../../../../common/validation/ValidationModel";
// views
import ContactView from "./ContactView";
import ContactReadonlyView from "./ContactReadonlyView";

export default function ContactDetailsView(props: { vm: Models.IRootViewModel; v: ValidationModel; actions: Actions.IActionFactory }): React.ReactElement {
    const vm = props.vm;
    const v = props.v;
    const actions = props.actions;

    function updateViewModel(values: Models.IApplicationContactViewModel): void {
        if (vm.viewState !== Models.ViewStateEnum.Edit) return;
        actions.viewChanged(vm.onContactChanged(values));
    }

    return vm.viewState === Models.ViewStateEnum.Edit ? <ContactView vm={vm} v={v} updateViewModel={updateViewModel} /> : <ContactReadonlyView vm={vm} />;
}
