// framework
import { clone, cloneDeep } from "lodash";
// api
import * as Client from "../../../../../../api/Client";
// common
import { IFile } from "../../../../../../common/secureFileUpload/SecureFileUploadControl";

export interface IRootViewModel extends IWetSignatureViewModel, IDigitalSignatureViewModel {
    id: number | undefined;
    viewState: ViewStateEnum;
    versionNumber: number | undefined;
    audit: Client.SecurePortalSimpleAuditEventsDto | undefined;
    isDirty: boolean;
    isConflict: boolean;
    statusMessages: Client.StatusMessagesDto | undefined;
    signatureType: Client.DraftApplicationFormDocumentationSignatureTypeEnum | undefined;

    // called from saga
    refreshSignatureDetails(id: number, response: Client.GetCompanyCommonDraftApplicationDetailsFormDocumentationResponseDto): IRootViewModel;
    refreshSaveStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel;
    clearGeneratedDocument(): IRootViewModel;
    refreshConflict(): IRootViewModel;
    onInitialised(): IRootViewModel;
    // called from view
    onEdit(): IRootViewModel;
    onSaved(): IRootViewModel;
    onFilesSelected(isSelected: boolean): IRootViewModel;
    onFileSelected(fileId: number): IRootViewModel;
}

// note, this hasn't been used for anything, i'll keep it around for a bit just in case we want to view differently depending on states
export enum FileStateEnum {
    Unchanged,
    Uploaded,
    Deleted,
}

export interface IFileViewModel {
    fileId: number;
    fileName: string;
    fileSize: number;
    fileSizeMb: number;
    uploadedDatetime: Date | undefined;
    state: FileStateEnum;
    isSelected: boolean;
}

export interface IWetSignatureViewModel {
    viewState: ViewStateEnum;
    wetSignatureDetails: IWetSignatureDetailsViewModel;

    // called from saga
    refreshGeneratedDocument(response: Client.SecurePortalDocumentDto): IRootViewModel;
    // called from view
    onWetSigUpload(files: Array<IFile>): IRootViewModel;
    onWetSigDelete(): IRootViewModel;
}

export interface IWetSignatureDetailsViewModel {
    generatedDocument: Client.SecurePortalDocumentDto | undefined;
    configuration: Client.DocumentationConfigurationDto | undefined;
    files: Array<IFileViewModel>;
    numberOfFilesSelected: number | undefined;
}

export interface IDigitalSignatureViewModel {
    viewState: ViewStateEnum;
    digitalSignatureDetails: IDigitalSignatureDetailsViewModel;

    // called from view
    refreshNewCompanySignatureDetailsUpdated(values: IDigitalSignatureDetailsNewCompanySignatureViewModel): IRootViewModel;
}

export interface IDigitalSignatureDetailsViewModel {
    companySignatures: Array<Client.GetCompanyOpggsDraftApplicationDetailsDigitalSignatureCompanySignatureDto> | undefined;
    availableSignatureTypes: Array<Client.GetCompanyOpggsDraftApplicationDetailsDigitalSignatureAvailableSignatureTypesDto> | undefined;
    newCompanySignatureDetails: IDigitalSignatureDetailsNewCompanySignatureViewModel;
    canSign: boolean;
    isSigned: boolean;
    isForSigning: boolean;
    files: Array<IFileViewModel>;
    numberOfFilesSelected: number | undefined;
}

export interface IDigitalSignatureDetailsNewCompanySignatureViewModel {
    signingFor?: Client.GetCompanyOpggsDraftApplicationDetailsDigitalSignatureAvailableSignatureTypesDto | undefined;
    signingAs?: Client.GetCompanyOpggsDraftApplicationDetailsDigitalSignatureAvailableSignatureTypeItemDto | null | undefined;
    poaLocation?: string | undefined;
    poaPosition?: string | undefined;
    isPoaPreviouslyProvided?: boolean | undefined;
    poaDate?: Date | undefined;
}

export enum ViewStateEnum {
    Initialising,
    View,
    Edit,
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.id = undefined;
        this.versionNumber = undefined;
        this.audit = undefined;
        this.isDirty = false;
        this.isConflict = false;
        this.statusMessages = undefined;
        this.signatureType = undefined;
        this.viewState = ViewStateEnum.Initialising;
        this.wetSignatureDetails = {
            generatedDocument: undefined,
            configuration: undefined,
            files: new Array<IFileViewModel>(),
            numberOfFilesSelected: 0,
        };
        this.digitalSignatureDetails = {
            companySignatures: undefined,
            availableSignatureTypes: undefined,
            newCompanySignatureDetails: {},
            canSign: false,
            isSigned: false,
            isForSigning: false,
            files: new Array<IFileViewModel>(),
            numberOfFilesSelected: 0,
        };
    }

    public id: number | undefined;
    public versionNumber: number | undefined;
    public audit: Client.SecurePortalSimpleAuditEventsDto | undefined;
    public isDirty: boolean;
    public isConflict: boolean;
    public statusMessages: Client.StatusMessagesDto | undefined;
    public signatureType: Client.DraftApplicationFormDocumentationSignatureTypeEnum | undefined;
    public viewState: ViewStateEnum;
    public wetSignatureDetails: IWetSignatureDetailsViewModel;
    public digitalSignatureDetails: IDigitalSignatureDetailsViewModel;

    public refreshSignatureDetails(id: number, response: Client.GetCompanyCommonDraftApplicationDetailsFormDocumentationResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.isDirty = false;
        vm.isConflict = false;
        vm.id = id;
        vm.versionNumber = response.versionNumber;
        vm.audit = response.audit;
        vm.signatureType = response.signatureType;
        vm.statusMessages = response.statusMessages;
        switch (vm.signatureType) {
            case Client.DraftApplicationFormDocumentationSignatureTypeEnum.Wet:
                // wet sig
                vm.wetSignatureDetails.configuration = response.wetSignatureDetails!.configuration;
                vm.wetSignatureDetails.files = response.files.map((f) => {
                    return new FileViewModel(f.fileId, f.fileName, f.fileSize, f.uploadedDatetime, FileStateEnum.Unchanged, false);
                });
                break;
            case Client.DraftApplicationFormDocumentationSignatureTypeEnum.Digital:
                // dig sig
                vm.digitalSignatureDetails.isSigned = response.isSigned;
                vm.digitalSignatureDetails.isForSigning = response.isForSigning;
                vm.digitalSignatureDetails.companySignatures = response.digitalSignatureDetails!.companySignatures;
                vm.digitalSignatureDetails.availableSignatureTypes = response.digitalSignatureDetails!.availableSignatureTypes;
                vm.digitalSignatureDetails.canSign = vm.digitalSignatureDetails.availableSignatureTypes!.length > 0;
                vm.digitalSignatureDetails.files = response.files.map((f) => {
                    return new FileViewModel(f.fileId, f.fileName, f.fileSize, f.uploadedDatetime, FileStateEnum.Unchanged, false);
                });
                vm.digitalSignatureDetails.newCompanySignatureDetails = {
                    signingFor: undefined,
                    signingAs: undefined,
                    poaLocation: undefined,
                    poaPosition: undefined,
                    isPoaPreviouslyProvided: undefined,
                    poaDate: undefined,
                };
                break;
            default:
                throw new Error("Invalid Signature Type.");
        }

        this._setCalculatedFields(vm);
        return vm;
    }

    public refreshSaveStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.statusMessages = statusMessages;
        if (statusMessages.isSuccess) {
            vm.isDirty = false;
        }
        this._setCalculatedFields(vm);
        return vm;
    }

    // document can be generated regardless of viewState so no need to assert / set viewState
    public refreshGeneratedDocument(response: Client.SecurePortalDocumentDto): IRootViewModel {
        const vm = this._clone();
        vm.wetSignatureDetails.generatedDocument = response;

        return vm;
    }

    public clearGeneratedDocument(): IRootViewModel {
        const vm = this._clone();
        vm.wetSignatureDetails.generatedDocument = undefined;

        return vm;
    }

    public refreshConflict(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.statusMessages = undefined;
        vm.isConflict = true; // this is only reset on initialise from the reducer
        this._setCalculatedFields(vm);
        return vm;
    }

    public onInitialised(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Initialising) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.View;
        return vm;
    }

    public onSaved(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.View;
        return vm;
    }

    public onEdit(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.View) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.Edit;
        vm.isDirty = false;
        vm.statusMessages = undefined;
        this._setCalculatedFields(vm);
        return vm;
    }

    public onFileSelected(fileId: number): IRootViewModel {
        // can be set in both View and Edit states, does not trigger dirty state

        const vm = this._clone();

        switch (vm.signatureType) {
            case Client.DraftApplicationFormDocumentationSignatureTypeEnum.Wet:
                // wet sig
                vm.wetSignatureDetails.files = this.wetSignatureDetails.files.map((f) => {
                    return new FileViewModel(f.fileId, f.fileName, f.fileSize, f.uploadedDatetime, f.state, f.fileId === fileId ? !f.isSelected : f.isSelected); // toggles the selection
                });
                break;
            case Client.DraftApplicationFormDocumentationSignatureTypeEnum.Digital:
                // dig sig
                vm.digitalSignatureDetails.files = this.digitalSignatureDetails.files.map((f) => {
                    return new FileViewModel(f.fileId, f.fileName, f.fileSize, f.uploadedDatetime, f.state, f.fileId === fileId ? !f.isSelected : f.isSelected); // toggles the selection
                });
                break;
            default:
                throw new Error("Invalid Signature Type.");
        }

        this._setCalculatedFields(vm);
        return vm;
    }

    public onFilesSelected(isSelected: boolean): IRootViewModel {
        // can be set in both View and Edit states, does not trigger dirty state

        const vm = this._clone();

        switch (vm.signatureType) {
            case Client.DraftApplicationFormDocumentationSignatureTypeEnum.Wet:
                // wet sig
                vm.wetSignatureDetails.files = this.wetSignatureDetails.files.map((f) => {
                    return new FileViewModel(f.fileId, f.fileName, f.fileSize, f.uploadedDatetime, f.state, isSelected); // sets all to a specific value
                });
                break;
            case Client.DraftApplicationFormDocumentationSignatureTypeEnum.Digital:
                // dig sig
                vm.digitalSignatureDetails.files = this.digitalSignatureDetails.files.map((f) => {
                    return new FileViewModel(f.fileId, f.fileName, f.fileSize, f.uploadedDatetime, f.state, isSelected); // sets all to a specific value
                });
                break;
            default:
                throw new Error("Invalid Signature Type.");
        }

        this._setCalculatedFields(vm);
        return vm;
    }

    public onWetSigUpload(files: Array<IFile>): IRootViewModel {
        if (this.signatureType !== Client.DraftApplicationFormDocumentationSignatureTypeEnum.Wet) throw new Error("Invalid operation.");
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.isDirty = true;
        vm.wetSignatureDetails.files = cloneDeep(vm.wetSignatureDetails.files);
        for (const f of files) {
            vm.wetSignatureDetails.files.push(new FileViewModel(f.id, f.fileName, f.size, undefined, FileStateEnum.Uploaded, false));
        }
        this._setCalculatedFields(vm);
        return vm;
    }

    public onWetSigDelete(): IRootViewModel {
        if (this.signatureType !== Client.DraftApplicationFormDocumentationSignatureTypeEnum.Wet) throw new Error("Invalid operation.");
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.isDirty = true;
        vm.wetSignatureDetails.files = this.wetSignatureDetails.files
            .filter((f) => !f.isSelected)
            .map((f) => {
                return new FileViewModel(f.fileId, f.fileName, f.fileSize, f.uploadedDatetime, f.state, false);
            });
        this._setCalculatedFields(vm);
        return vm;
    }

    public refreshNewCompanySignatureDetailsUpdated(values: IDigitalSignatureDetailsNewCompanySignatureViewModel): IRootViewModel {
        if (this.signatureType !== Client.DraftApplicationFormDocumentationSignatureTypeEnum.Digital) throw new Error("Invalid operation.");

        const vm = this._clone();
        vm.isDirty = true;
        vm.digitalSignatureDetails.newCompanySignatureDetails = values;
        return vm;
    }

    private _setCalculatedFields(vm: RootViewModel) {
        switch (vm.signatureType) {
            case Client.DraftApplicationFormDocumentationSignatureTypeEnum.Wet:
                // wet sig
                vm.wetSignatureDetails.numberOfFilesSelected = vm.wetSignatureDetails.files.filter((f) => f.isSelected).length;
                break;
            case Client.DraftApplicationFormDocumentationSignatureTypeEnum.Digital:
                // dig sig
                vm.digitalSignatureDetails.numberOfFilesSelected = vm.digitalSignatureDetails.files.filter((f) => f.isSelected).length;
                break;
            default:
                throw new Error("Invalid Signature Type.");
        }
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.wetSignatureDetails = cloneDeep(this.wetSignatureDetails);
        vm.digitalSignatureDetails = cloneDeep(this.digitalSignatureDetails);
        return vm;
    }
}

class FileViewModel implements IFileViewModel {
    fileId: number;
    fileName: string;
    fileSize: number;
    fileSizeMb: number;
    uploadedDatetime: Date | undefined;
    state: FileStateEnum;
    isSelected: boolean;

    constructor(fileId: number, fileName: string, fileSize: number, uploadedDatetime: Date | undefined, state: FileStateEnum, isSelected: boolean) {
        this.fileId = fileId;
        this.fileName = fileName;
        this.fileSize = fileSize;
        this.fileSizeMb = fileSize / 1024 / 1024;
        this.uploadedDatetime = uploadedDatetime;
        this.state = state;
        this.isSelected = isSelected;
    }
}
