// framework
import * as Validation from "../../../../../../common/validation/ValidationModel";
// redux
import * as Models from "./models";

export default function validate(vm: Models.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateEvaDetails(vm, v);
    return v;
}

function validateEvaDetails(vm: Models.IRootViewModel, v: Validation.ValidationModel): void {
    if (!vm.evaType) {
        v.addError("Eva.EvaType", "A selection is required.");
    } else if (!vm.availableEvaTypes || !vm.availableEvaTypes.some((t) => t.type === vm.evaType?.type)) {
        v.addError("Eva.EvaType", "The selection is invalid.");
    }
}
