// framework
import { useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
// common
import DirtyScope from "../../../common/shell/DirtyScopeController";
import { Label, ReadonlyField } from "../../../common/forms/BootstrapForms";
import * as homeRouteHelpers from "../../../home/homeRouteHelper";
import * as ConfigurationHelper from "../../../common/shell/configurationHelper";
import { NeatsFeatureSwitchEnum } from "../../../api/Client";

export enum RegistrationType {
    Company = "Company",
    JointAuthority = "Joint Authority",
    GeoscienceAustralia = "Geoscience Australia",
}

type RegistrationTypeViewProps = {
    onSave: (value: RegistrationType | undefined) => void;
};

export default function RegistrationTypeView(props: RegistrationTypeViewProps) {
    const { onSave } = props;

    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [selectedRegistrationType, setSelectedRegistrationType] = useState<RegistrationType | undefined>(undefined);

    // todo ga - remove when live
    const isGaFeatureSwitchEnabled = ConfigurationHelper.useFeatureSwitch(NeatsFeatureSwitchEnum.Ga);

    const registrationTypes = isGaFeatureSwitchEnabled
        ? [RegistrationType.Company, RegistrationType.GeoscienceAustralia, RegistrationType.JointAuthority]
        : [RegistrationType.Company, RegistrationType.JointAuthority];

    function onDropDownListChange(event: DropDownListChangeEvent) {
        setSelectedRegistrationType(event.value);
        setIsDirty(true);
    }

    function onSaveClick() {
        setIsSaved(true);
        setIsDirty(false);
        onSave(selectedRegistrationType);
    }

    return (
        <div className="mb-2">
            <div className="card">
                <div className="card-body">
                    <DirtyScope scopeName="home/registration/registrationType" isDirty={isDirty}>
                        <p>
                            To use the NEATS Secure Portal you must have a relationship with a stakeholder entity. If you have an existing NEATS Account then please{" "}
                            <Link to={homeRouteHelpers.signIn()}>sign in</Link> before making a request.
                        </p>
                        {isSaved ? (
                            <ReadonlyField id="registrationType" label="Select the stakeholder relationship that you are requesting" value={selectedRegistrationType!} />
                        ) : (
                            <>
                                <Label htmlFor="registrationType">Select the stakeholder relationship that you are requesting</Label>
                                <DropDownList id="registrationType" data={registrationTypes} value={selectedRegistrationType} onChange={onDropDownListChange} />
                            </>
                        )}

                        <div className="d-flex flex-wrap mt-2">
                            <button className="btn btn-outline-primary" type="button" onClick={onSaveClick} disabled={isSaved || !selectedRegistrationType}>
                                OK
                            </button>
                        </div>
                    </DirtyScope>
                </div>
            </div>
        </div>
    );
}
