// framework
import { ReactNode } from "react";
// kendo
import { LocalizationProvider, IntlProvider, load, loadMessages } from "@progress/kendo-react-intl";
// CLDR data
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import auNumbers from "cldr-numbers-full/main/en-AU/numbers.json";
import auLocalCurrency from "cldr-numbers-full/main/en-AU/currencies.json";
import auCaGregorian from "cldr-dates-full/main/en-AU/ca-gregorian.json";
import auDateFields from "cldr-dates-full/main/en-AU/dateFields.json";
// other
import * as GlobalHelpers from "../common/GlobalHelpers";

export enum UploadValidationErrors {
    invalidFileCount = "invalidFileCount",
    invalidMinFileName = "invalidMinFileName",
    invalidMaxFileName = "invalidMaxFileName",
}

const customUploadMessages = {
    upload: {
        invalidFileCount: "Maximum number of files exceeded.",
        invalidMinFileName: "File names cannot be blank.",
        invalidMaxFileName: "File names must be 100 characters or less.",
    },
};

load(likelySubtags, currencyData, weekData, auNumbers, auLocalCurrency, auCaGregorian, auDateFields);
loadMessages(customUploadMessages, GlobalHelpers.NoptaLanguage);

function KendoIntlProvider(props: { children: ReactNode }) {
    return (
        <LocalizationProvider language={GlobalHelpers.NoptaLanguage}>
            <IntlProvider locale={GlobalHelpers.NoptaLocale}>{props.children}</IntlProvider>
        </LocalizationProvider>
    );
}

export default KendoIntlProvider;
