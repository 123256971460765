import * as Client from "../../../../api/Client";
import { clone } from "lodash";

export interface IDraftApplicationDetailsViewModel {
    applicant?: Client.GetInvitationToApplyReferenceDataDraftApplicationApplicantDto;
    applicationType?: Client.GetInvitationToApplyReferenceDataDraftApplicationTypeDto;
}

export interface IRootViewModel {
    // invitation to apply list
    canCreateInvitationToApply: boolean | undefined;
    invitationsToApply: Array<Client.GetCompanyOeiInvitationToApplyListInvitationToApplyDto> | undefined;
    selectedInvitationToApplyId: number | undefined;

    // create draft application
    createDraftApplicationReferenceData?: Client.GetCompanyOeiCreateDraftApplicationReferenceDataResponseDto | undefined;
    draftApplicationDetails: IDraftApplicationDetailsViewModel;
    service: {
        statusMessages?: Client.StatusMessagesDto;
        draftApplicationId?: Client.DraftApplicationIdDto | undefined;
    };

    // invitation to apply list
    refreshInvitationsToApply(response: Client.GetCompanyOeiInvitationToApplyListResponseDto): IRootViewModel;
    refreshSelectedInvitationToApply(invitationToApplyId: number): IRootViewModel;

    // create draft application
    refreshCreateDraftApplicationReferenceData(response: Client.GetCompanyOeiCreateDraftApplicationReferenceDataResponseDto | undefined): IRootViewModel;
    refreshDraftApplicationDetailsUpdated(values: IDraftApplicationDetailsViewModel): IRootViewModel;
    refreshCreateDraftApplicationResponse(statusMessages: Client.StatusMessagesDto, id: Client.DraftApplicationIdDto | undefined): IRootViewModel;
    clearDraftApplicationData(): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.canCreateInvitationToApply = undefined;
        this.invitationsToApply = undefined;
        this.selectedInvitationToApplyId = undefined;

        this.createDraftApplicationReferenceData = undefined;
        this.draftApplicationDetails = {};
        this.service = {
            statusMessages: undefined,
            draftApplicationId: undefined,
        };
    }

    public canCreateInvitationToApply: boolean | undefined;
    public invitationsToApply: Array<Client.GetCompanyOeiInvitationToApplyListInvitationToApplyDto> | undefined;
    public selectedInvitationToApplyId: number | undefined;

    public createDraftApplicationReferenceData?: Client.GetCompanyOeiCreateDraftApplicationReferenceDataResponseDto | undefined;
    public draftApplicationDetails: IDraftApplicationDetailsViewModel;
    public service: {
        statusMessages?: Client.StatusMessagesDto;
        draftApplicationId?: Client.DraftApplicationIdDto | undefined;
    };

    public refreshInvitationsToApply(response: Client.GetCompanyOeiInvitationToApplyListResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.canCreateInvitationToApply = response.canCreateInvitationToApply;
        vm.invitationsToApply = response.invitationsToApply;
        vm.selectedInvitationToApplyId = undefined;
        vm.service.statusMessages = undefined;
        return vm;
    }

    public refreshSelectedInvitationToApply(invitationToApplyId: number): IRootViewModel {
        const vm = this._clone();
        vm.selectedInvitationToApplyId = invitationToApplyId;
        return vm;
    }

    public refreshCreateDraftApplicationReferenceData(response: Client.GetCompanyOeiCreateDraftApplicationReferenceDataResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.createDraftApplicationReferenceData = response;
        return vm;
    }

    refreshDraftApplicationDetailsUpdated(values: IDraftApplicationDetailsViewModel): IRootViewModel {
        const vm = this._clone();
        vm.draftApplicationDetails = values;
        return vm;
    }

    refreshCreateDraftApplicationResponse(statusMessages: Client.StatusMessagesDto, id: Client.DraftApplicationIdDto | undefined): IRootViewModel {
        const vm = this._clone();
        vm.service.draftApplicationId = id;
        vm.service.statusMessages = statusMessages;
        return vm;
    }

    public clearDraftApplicationData(): IRootViewModel {
        const vm = this._clone();
        vm.selectedInvitationToApplyId = undefined;
        vm.draftApplicationDetails.applicant = undefined;
        vm.draftApplicationDetails.applicationType = undefined;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.createDraftApplicationReferenceData = clone(this.createDraftApplicationReferenceData);
        vm.draftApplicationDetails = clone(this.draftApplicationDetails);
        vm.service = clone(this.service);
        return vm;
    }
}
