// redux
import { call, put, select } from "redux-saga/effects";
import { IGeoscienceAustraliaReduxState } from "../../../geoscienceAustraliaReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../api/clientFactory";
import * as CallHelper from "../../../../api/callHelper";
import * as Client from "../../../../api/Client";
// shell
import * as ShellActions from "../../../../shell/layout/redux/actions";
// common
import * as LogHelper from "../../../../common/LogHelper";
import * as ConfigurationHelper from "../../../../common/shell/configurationHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_Home_Registration;

export default function* initialise() {
    // save role reference data to the vm
    let vm: Models.IRootViewModel = yield select(getRootViewModel);
    vm = vm.refreshRoleConfiguration(yield select(ConfigurationHelper.getRoleConfiguration));
    yield put(Actions.actionFactory.sagaCompleted(vm));

    // load access request reference data
    try {
        yield put(ShellActions.actionFactory.setBusy());

        const response: Client.GetRegistrationGeoscienceAustraliaReferenceDataResponseDto = yield call(async () => {
            const client = ClientFactory.createRegistrationAnonymousRequestService();
            return await CallHelper.simpleCall(() => client.getRegistrationGeoscienceAustraliaReferenceData(new Client.GetRegistrationGeoscienceAustraliaReferenceDataRequestDto()));
        });
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshGeoscienceAustraliaDetails(response.hasGeoscienceAustraliaAdministrator)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(ShellActions.actionFactory.clearBusy());
    }
}
