// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IDataSubmissionViewModel {
    name?: string | undefined;
    type: Client.NopimsActivityTypeEnum | undefined;
    classificationType?: string | undefined;
    contactName?: string | undefined;
    contactEmailAddress?: string | undefined;
    contactPhoneNumber?: string | undefined;
    receivedDate?: Date | undefined;
    comments?: string | undefined;
}

export interface IRootViewModel {
    dataSubmission: IDataSubmissionViewModel;

    wellDetails: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDetailsResponseWellDto | undefined;
    surveyDetails: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDetailsResponseSurveyDto | undefined;
    reprocessingDetails: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDetailsResponseReprocessingDto | undefined;

    permissions: {
        canViewWells: boolean;
        canViewBoreholes: boolean;
        canViewSurveys: boolean;
        canViewAcquisitions: boolean;
        canViewReprocessing: boolean;
        canViewReprocessingProjects: boolean;
    };

    audit?: Client.SecurePortalSimpleAuditEventsDto;

    refreshDataSubmissionDetails(response: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDetailsResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.dataSubmission = {
            name: undefined,
            type: undefined,
            contactName: undefined,
            contactEmailAddress: undefined,
            contactPhoneNumber: undefined,
            receivedDate: undefined,
            comments: undefined,
        };

        this.permissions = {
            canViewWells: false,
            canViewBoreholes: false,
            canViewSurveys: false,
            canViewAcquisitions: false,
            canViewReprocessing: false,
            canViewReprocessingProjects: false,
        };
    }

    public dataSubmission: IDataSubmissionViewModel;

    public wellDetails: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDetailsResponseWellDto | undefined;
    public surveyDetails: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDetailsResponseSurveyDto | undefined;
    public reprocessingDetails: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDetailsResponseReprocessingDto | undefined;

    public permissions: {
        canViewWells: boolean;
        canViewBoreholes: boolean;
        canViewSurveys: boolean;
        canViewAcquisitions: boolean;
        canViewReprocessing: boolean;
        canViewReprocessingProjects: boolean;
    };

    public audit?: Client.SecurePortalSimpleAuditEventsDto | undefined;

    public refreshDataSubmissionDetails(response: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDetailsResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.dataSubmission = {
            name: response.name,
            type: response.type,
            classificationType: response.classificationType,
            contactName: response.contactName,
            contactEmailAddress: response.contactEmailAddress,
            contactPhoneNumber: response.contactPhoneNumber,
            receivedDate: response.receivedDate,
            comments: response.comments,
        };

        vm.wellDetails = response.wellDetails;
        vm.surveyDetails = response.surveyDetails;
        vm.reprocessingDetails = response.reprocessingDetails;

        vm.permissions = {
            canViewWells: response.canViewWells,
            canViewBoreholes: response.canViewBoreholes,
            canViewSurveys: response.canViewSurveys,
            canViewAcquisitions: response.canViewAcquisitions,
            canViewReprocessing: response.canViewReprocessing,
            canViewReprocessingProjects: response.canViewReprocessingProjects,
        };

        vm.audit = response.audit;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.dataSubmission = clone(this.dataSubmission);
        return vm;
    }
}
