// api
import * as Client from "../../../../../api/Client";
// common
import * as Forms from "../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
// model
import { IRequestDetailsViewModel } from "../../models/models";

interface IProps {
    vm: IRequestDetailsViewModel;
}

export default function RequestDetailsView(props: IProps): JSX.Element {
    const { vm } = props;

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Request Details</h2>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="feedbackNumberField" label="Feedback Number" value={vm.feedbackNumber} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="submittedDateField" label="Submitted Date" value={GlobalHelpers.toNoptaDateString(vm.submittedDate)} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="feedbackTypeNameField" label="Request Type" value={vm.feedbackTypeName} />
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.FullColumn>
                                <Forms.ReadonlyTextArea id="customerCommentsField" label="Customer Comments" value={vm.customerComments} />
                            </Forms.FullColumn>
                        </Forms.Row>
                    </Forms.Group>
                    {vm.feedbackType === Client.FeedbackTypeEnum.DataSpecificFeedback && (
                        <>
                            <Forms.Group>
                                <Forms.Row>
                                    <Forms.ThirdColumn>
                                        <Forms.ReadonlyField id="nopimsActivityTypeNameField" label="Activity Type" value={vm.nopimsActivityTypeName} />
                                    </Forms.ThirdColumn>
                                    <Forms.ThirdColumn>
                                        <Forms.ReadonlyField id="dataFeedbackActivityNameField" label="Activity Name" value={vm.dataFeedbackActivityName} />
                                    </Forms.ThirdColumn>
                                    <Forms.ThirdColumn>
                                        <Forms.ReadonlyField id="dataMonitoringItemTypeNameField" label="Data Type" value={vm.dataMonitoringItemTypeName} />
                                    </Forms.ThirdColumn>
                                </Forms.Row>
                            </Forms.Group>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
