// api
import * as ClientFactory from "../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";

export async function getJointAuthorityOpggsApplicationDetailsBlockDetails(id: string): Promise<Client.GetJointAuthorityOpggsApplicationDetailsBlockDetailsResponseDto> {
    const client = await ClientFactory.createJointAuthorityOpggsApplicationDetailsClient();
    return await CallHelper.simpleCall(() =>
        client.getJointAuthorityOpggsApplicationDetailsBlockDetails(new Client.GetJointAuthorityOpggsApplicationDetailsBlockDetailsRequestDto({ applicationId: id }))
    );
}
