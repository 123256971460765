import { call, put } from "redux-saga/effects";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as LogHelper from "../../../../../common/LogHelper";
import * as shellHelper from "../../../../../common/shell/shellHelper";

export default function* getCreditCardSurcharge(action: Actions.IAction<Models.IRootViewModel>) {
    let vm: Models.IRootViewModel = action.payload;

    try {
        yield put(shellHelper.createSetBusyAction());

        const response: CallHelper.CallResult<Client.GetCompanyFinanceCreditCardSurchargeResponseDto> = yield call(async () => {
            const client = await ClientFactory.createCompanyFinanceClient();
            const request = new Client.GetCompanyFinanceCreditCardSurchargeRequestDto({
                financialNotifications: [
                    new Client.GetCompanyFinanceCreditCardSurchargeFinancialNotificationDto({
                        financialNotificationId: vm.financialNotificationId!,
                        versionNumber: vm.financialNotification!.versionNumber!,
                    }),
                ],
                secureTransactionToken: vm.paymentViewModel!.secureTransactionToken!,
            });
            return await CallHelper.call(() => client.getCompanyFinanceCreditCardSurcharge(request));
        });

        if (response.IsSuccess) {
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshCreditCardSurcharge(response.Response!)));
        } else if (response.IsConflict) {
            // conflict
            vm = vm.refreshConflict();
            yield put(Actions.actionFactory.sagaCompleted(vm));
        } else {
            // all other errors
            response.ShowToastNotification();
            vm = vm.setStatusMessages(undefined);
            yield put(Actions.actionFactory.sagaCompleted(vm));
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
