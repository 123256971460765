// framework
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Actions from "../redux/actions";
import * as Models from "../models/models";
import validator from "../models/validator";
// shell
import BodyLayout from "../../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// views
import FormRequestDetailsView from "./views/FormRequestDetailsView";
import FormRequestDetailsReadonlyView from "./views/FormRequestDetailsReadonlyView";
import FormRequestDeletedView from "./views/FormRequestDeletedView";
import NoptaFeedbackView from "./views/NoptaFeedbackView";
import SignatureTypeView from "./views/SignatureTypeView";
import FormDocumentationView from "./views/FormDocumentationView";
// common
import ConflictView from "../../../../../common/alerts/ConflictAlertView";
import StatusMessagesAlertsView from "../../../../../common/alerts/StatusMessagesAlertsView";
import { ValidationVisibilityEnum } from "../../../../../common/validation/ValidationModel";
import DirtyScope from "../../../../../common/shell/DirtyScopeController";
import ConfirmationView, { DefaultButtonEnum } from "../../../../../common/confirmation/ConfirmationView";
import * as toastHelper from "../../../../../common/toastHelper";
import * as LegislativeFormsHelper from "../../common/LegislativeFormsHelper";
// other
import SimpleAuditEventsView from "../../../../../common/audit/SimpleAuditEventsView";

interface IControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

interface IRouteParameters {
    type: string;
    id: string;
}

function Controller(props: IControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;
    const params = useParams<IRouteParameters>();

    // on mount
    // - initial title load
    const formRequestType = parseInt(params.type);
    const formRequestId = parseInt(params.id);
    useEffect(() => {
        actions.getDetailsRequested(formRequestId, formRequestType);
        return function () {
            actions.clearDetailsRequested();
        };
    }, [formRequestId, formRequestType]); // eslint-disable-line react-hooks/exhaustive-deps

    // this is used to trigger smart components to refresh when the refresh button is manually pressed
    const [lastRefreshedDatetime, setLastRefreshedDatetime] = useState<Date | undefined>(undefined);

    // validation
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const validation = validator(vm, validationVisibility);

    // save
    function onSaveFormRequestDetailsClicked() {
        if (!LegislativeFormsHelper.isEditableFormType(formRequestType)) throw new Error("Invalid Form Request Type.");

        // validation
        // - validation is hidden until the user attempts the action... it then stays visible until they have successfully completed the action
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);

        // save form request details
        actions.saveFormRequestDetailsRequested();
    }

    // set as ready for signing
    function onReadyForSigningClicked() {
        // validation
        // - validation is hidden until the user attempts the action... it then stays visible until they have successfully completed the action
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);

        // confirmation
        setShowConfirmation(true);
    }

    // confirmation
    const [showConfirmation, setShowConfirmation] = useState(false);

    function onConfirmationAccepted() {
        setShowConfirmation(false);
        // submit
        actions.setFormRequestAsReadyForSigningRequested();
    }

    function onConfirmationClosed() {
        setShowConfirmation(false);
    }

    // submit to NOPTA
    function onSubmitToNoptaClicked(e: any) {
        e.preventDefault();

        // validation
        // - validation is hidden until the user attempts the action... it then stays visible until they have successfully completed the action
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);

        // submit
        actions.submitFormRequestToNoptaForApprovalRequested();
    }

    function onEditRequested(task: Models.FormRequestTaskEnum) {
        actions.viewChanged(vm.setInEditTask(task));
    }

    function onCancelRequested() {
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        actions.getDetailsRequested(formRequestId, formRequestType);
    }

    function onSaveSignatureType() {
        actions.getDetailsRequested(formRequestId, formRequestType);
    }

    function getSignatureTypeView() {
        return (
            <SignatureTypeView
                id={formRequestId}
                versionNumber={vm.versionNumber!}
                viewState={vm.viewState}
                inEditTask={vm.inEditTask}
                onEdit={() => onEditRequested(Models.FormRequestTaskEnum.SignatureType)}
                onSave={onSaveSignatureType}
                onCancel={onCancelRequested}
                lastRefreshedDatetime={lastRefreshedDatetime}
            />
        );
    }

    function getFormDocumentationView() {
        return (
            <FormDocumentationView
                id={formRequestId}
                versionNumber={vm.versionNumber!}
                viewState={vm.viewState}
                inEditTask={vm.inEditTask}
                onEdit={() => onEditRequested(Models.FormRequestTaskEnum.FormDocumentation)}
                onSave={onSaveSignatureType}
                onCancel={onCancelRequested}
                lastRefreshedDatetime={lastRefreshedDatetime}
            />
        );
    }

    const pageTitle = LegislativeFormsHelper.getFormRequestHeader(vm.formRequestDetails.formTypeId, vm.formRequestDetails.titleNumber, vm.formRequestDetails.titleholder);
    const onReload = LegislativeFormsHelper.canReload(vm.viewState)
        ? () => {
              actions.getDetailsRequested(formRequestId, formRequestType);
              setLastRefreshedDatetime(new Date());
          }
        : undefined;

    // return
    return (
        <BodyLayout title={pageTitle} selectedLhsNavItem={Level1ItemTypeEnum.Company_Opggs_TitlesList} onReloadRequested={onReload}>
            <ConflictView isConflict={vm.isConflict} />
            <StatusMessagesAlertsView statusMessages={vm.service.statusMessages} />
            {vm.viewState === Models.ViewStateEnum.Deleted && <FormRequestDeletedView />}
            {vm.viewState === Models.ViewStateEnum.Draft && (
                <DirtyScope scopeName="company/opggs/legislativeForms/formRequestDetails" isDirty={vm.isDirty}>
                    <FormRequestDetailsView
                        vm={vm}
                        v={validation}
                        actions={actions}
                        onEdit={() => onEditRequested(Models.FormRequestTaskEnum.FormRequestDetails)}
                        onSave={onSaveFormRequestDetailsClicked}
                        onCancel={onCancelRequested}
                    />
                    {getSignatureTypeView()}
                    <button className="btn btn-outline-secondary me-2" type="button" onClick={onReadyForSigningClicked} disabled={vm.isDirty || vm.isProgressBlocked}>
                        Ready for Signing
                    </button>
                    <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={() => {
                            actions.deleteFormRequestRequested();
                        }}
                    >
                        Delete Form Request
                    </button>
                    {showConfirmation && (
                        <ConfirmationView title="Confirmation" onAccepted={onConfirmationAccepted} onClosed={onConfirmationClosed} defaultButton={DefaultButtonEnum.Cancel}>
                            You won't be able to make any more changes to the Form Request.
                        </ConfirmationView>
                    )}
                </DirtyScope>
            )}
            {vm.viewState !== Models.ViewStateEnum.Deleted && vm.viewState !== Models.ViewStateEnum.Draft && (
                <>
                    <FormRequestDetailsReadonlyView vm={vm} />
                    {getSignatureTypeView()}
                </>
            )}
            {vm.viewState === Models.ViewStateEnum.ForSigning && (
                <DirtyScope scopeName="company/opggs/legislativeForms/formRequestDetails" isDirty={vm.isDirty}>
                    <form onSubmit={onSubmitToNoptaClicked}>
                        {vm.formRequestDetails.noptaReturnToCompanyComment && <NoptaFeedbackView vm={vm.formRequestDetails.noptaReturnToCompanyComment} />}
                        {getFormDocumentationView()}
                        <button className="btn btn-outline-primary me-2" type="submit" disabled={vm.isDirty || vm.isProgressBlocked}>
                            Submit to NOPTA for Approval
                        </button>
                        <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={() => {
                                actions.deleteFormRequestRequested();
                            }}
                        >
                            Delete Form Request
                        </button>
                    </form>
                </DirtyScope>
            )}
            {vm.viewState === Models.ViewStateEnum.Submitted && getFormDocumentationView()}
            {vm.viewState !== Models.ViewStateEnum.Deleted && <SimpleAuditEventsView className="mt-4" simpleAuditEvents={vm.formRequestDetails.audit} />}
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_Opggs_LegislativeForms_FormRequestDetails }), Actions.actionFactory)(Controller);
