// framework
import { useEffect, useState } from "react";
// redux
import * as Models from "../../models/models";
// api
import * as Client from "../../../../../../api/Client";
// common
import { ValidationModel } from "../../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../../common/validation/ValidationMessageView";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import { orderBy } from "@progress/kendo-data-query";

export default function IappDetailsEpView(props: { vm: Models.IIappEpDetailsViewModel; v: ValidationModel; updateViewModel: (vm: Models.IIappViewModel) => void }) {
    const vm = props.vm;
    const v = props.v;
    const isLongerApplicationPeriodAllowed = GlobalHelpers.convertBooleanToStringValue(vm.isLongerApplicationPeriodAllowed);

    const [locationId, setLocationId] = useState<number | undefined>();

    useEffect(() => {
        setLocationId(vm.locationId);
    }, [vm.locationId]);

    const [locationData, setLocationData] = useState<Array<Client.GetCompanyOpggsDraftApplicationDetailsIappEpDetailsLocationDto>>();

    useEffect(() => {
        setLocationData(orderBy(vm.locations, [{ field: "name", dir: "asc" }]));
    }, [vm.locations]);

    function onChange(e: DropDownListChangeEvent) {
        const location = e.target.value as Client.GetCompanyOpggsDraftApplicationDetailsIappEpDetailsLocationDto;
        setLocationId(location.id);
    }

    function onBlur() {
        props.updateViewModel({ epDetails: { ...vm, locationId: locationId } });
    }

    return (
        <>
            <Forms.Group>
                <Forms.Label htmlFor="epDetailsLocation">Location Name</Forms.Label>
                <DropDownList
                    id="epDetailsLocation"
                    valid={v.applyValidityForKendo("IappDetails.EpDetails.Location")}
                    data={locationData}
                    value={locationData?.find((l) => l.id === locationId)}
                    dataItemKey="id"
                    textField="name"
                    onChange={onChange}
                    onBlur={onBlur}
                />
                <ValidationMessageView name="IappDetails.EpDetails.Location" validationModel={v} />
            </Forms.Group>
            <strong>If titleholder of a petroleum exploration permit (section 168 of the OPGGS Act)</strong>
            <Forms.Group>
                <Forms.ReadonlyField label="Date location declared" id="dateLocationDeclaredField" value={GlobalHelpers.toNoptaDateString(vm.dateLocationDeclared)} />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="Longer application period allowed (if applicable)" id="isLongerApplicationPeriodAllowedField" value={isLongerApplicationPeriodAllowed || ""} />
            </Forms.Group>
        </>
    );
}
