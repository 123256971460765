import React from "react";
import EmailLinkView from "../../../../../common/externalLink/EmailLinkView";

export default function ContactsView(): React.ReactElement {
    return (
        <>
            <h2>Contact Details</h2>
            <div className="container-fluid p-0">
                <div className="row">
                    {getSectionWithPhoneAndEmail("Payment Enquiries", "+61 8 6424 5300", "corporate@nopta.gov.au")}
                    {getSectionWithPhoneAndEmail("Petroleum & GHG Application Enquiries", "+61 8 6424 5317", "titles@nopta.gov.au")}
                    {getSectionWithPhoneAndEmail("Offshore Electricity Infrastructure Enquiries", "+61 8 6424 5317", "offshoreelectricity@nopta.gov.au")}
                    {getSectionWithPhoneAndEmail("NEATS Technical and Data Queries", null, "neats.admin@nopta.gov.au")}
                </div>
            </div>
        </>
    );
}

function getSectionWithPhoneAndEmail(header: string, phone: string | null, email: string | null): React.ReactElement {
    return (
        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <span className="fw-bold">{header}</span>
            <p>
                {email && (
                    <span>
                        Email: <EmailLinkView emailAddress={email} />
                        <br />
                    </span>
                )}
                {phone && (
                    <span>
                        Telephone:{" "}
                        <a href={"tel:" + phone.replaceAll(" ", "")} className="telno">
                            {phone}
                        </a>
                        <br />
                    </span>
                )}
            </p>
        </div>
    );
}
