// framework
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import CustomGridCell from "../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
// models
import { IRootViewModel } from "../../models/models";
// common
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import * as geoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";

export default function FeedbackListGridView(props: { vm: IRootViewModel }): JSX.Element {
    const { vm } = props;

    const initialState: State = {
        sort: [
            { field: "submittedDate", dir: "desc" },
            { field: "identifier", dir: "desc" },
        ],
        filter: { logic: "and", filters: [] },
        skip: 0,
        take: 20,
    };

    const [gridState, setGridState] = useState(initialState);
    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [vm.items]); // eslint-disable-line react-hooks/exhaustive-deps

    function getIdentifierCell(props: GridCellProps): JSX.Element | null {
        return (
            <CustomGridCell gridCellProps={props}>
                <Link to={geoscienceAustraliaRouteHelper.feedbackDetails(props.dataItem.id)}>{props.dataItem.identifier}</Link>
            </CustomGridCell>
        );
    }

    return (
        <Grid
            pageable
            sortable
            resizable
            navigatable
            data={process(vm.items, gridState)}
            {...gridState}
            onDataStateChange={(e) => {
                setGridState(e.dataState);
            }}
        >
            <GridColumn field="identifier" title="Feedback Number" width={230} filter="text" columnMenu={createGridColumnFilterMenu} cell={getIdentifierCell} />
            <GridColumn field="status" title="Status" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.items)} />
            <GridColumn field="submittedDate" title="Submitted Date" width={210} filter="date" columnMenu={createGridColumnFilterMenu} format={GlobalHelpers.NoptaDateFormatForKendo} />
            <GridColumn field="assignmentGroupName" title="Assignment Group" width={210} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.items)} />
            <GridColumn field="assignedUserName" title="Assigned To" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.items)} />
            <GridColumn field="feedbackTypeName" title="Request Type" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.items)} />
            <GridColumn field="customerCompanyName" title="Company" width={400} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.items)} />
            <GridColumn field="customerFullName" title="Contact Name" width={400} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.items)} />
        </Grid>
    );
}
