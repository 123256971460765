// redux
import * as Models from "../../../../common/draftApplicationDetails/components/models/models";
// api
import { DraftApplicationValidationStateEnum } from "../../../../../api/Client";
// common
import TitleholderForeignInvestmentViewControl from "../../controls/titleholderForeignInvestment/components/Controller";
import * as ViewHelpers from "../../../../common/draftApplicationDetails/ViewHelpers";

interface IProps {
    id: number;
    versionNumber: number;
    canManage: boolean;
    validationState: DraftApplicationValidationStateEnum;
    viewState: Models.ApplicationViewStateEnum;
    inEditTask: Models.ApplicationTaskEnum | undefined;
    onEdit: () => void;
    onSave: () => void;
    onCancel: () => void;
    lastRefreshedDatetime: Date | undefined;
}

export default function TitleholderForeignInvestmentView(props: IProps) {
    return (
        <TitleholderForeignInvestmentViewControl
            id={props.id}
            versionNumber={props.versionNumber}
            cardTitle="Titleholders"
            showCommands={ViewHelpers.showCommands(props.validationState, props.canManage, props.viewState)}
            disableEdit={ViewHelpers.isInEdit(props.inEditTask)}
            lastRefreshedDatetime={props.lastRefreshedDatetime}
            onEdit={props.onEdit}
            onSave={props.onSave}
            onCancel={props.onCancel}
            className="mb-2"
        />
    );
}
