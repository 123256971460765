// framework
import { call, put } from "redux-saga/effects";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { getDetails } from "./initialise";

export default function* save(action: Actions.IAction<Models.IRootViewModel>) {
    let vm: Models.IRootViewModel = action.payload;
    try {
        yield put(shellHelper.createSetBusyAction());
        const result: CallHelper.CallResult<Client.SaveGeoscienceAustraliaFeedbackDetailsResponseDto> = yield call(() => saveDetails(vm));
        if (result.IsSuccess) {
            // success
            if (result.Response!.statusMessages!.isSuccess) {
                const response: Client.GetGeoscienceAustraliaFeedbackDetailsResponseDto = yield call(() => getDetails(vm.id!));
                vm = vm.refreshFeedbackDetails(response);
            }
            vm = vm.refreshStatusMessages(result.Response!.isComplete, result.Response!.statusMessages);
            yield put(Actions.actionFactory.sagaCompleted(vm));
        } else if (result.IsConflict) {
            // conflict
            vm = vm.refreshConflict();
            yield put(Actions.actionFactory.sagaCompleted(vm));
        } else {
            // all other errors
            result.ShowToastNotification();
            vm = vm.refreshStatusMessages(result.Response!.isComplete, result.Response!.statusMessages);
            yield put(Actions.actionFactory.sagaCompleted(vm));
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function saveDetails(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.SaveGeoscienceAustraliaFeedbackDetailsResponseDto>> {
    const client = await ClientFactory.createGeoscienceAustraliaFeedbackClient();
    const request = new Client.SaveGeoscienceAustraliaFeedbackDetailsRequestDto({
        id: vm.id!,
        versionNumber: vm.versionNumber!,
        assignmentGroup: vm.feedbackResponse.assignmentGroup!.assignmentGroup,
        assignedUserId: vm.feedbackResponse.assignedUser!.id,
        completionComments: vm.feedbackResponse.completionComments,
        isComplete: vm.feedbackResponse.isComplete!,
    });
    return await CallHelper.call(() => client.saveGeoscienceAustraliaFeedbackDetails(request));
}
