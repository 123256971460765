// framework
import { ReactElement } from "react";
import { Link } from "react-router-dom";
// kendo
import { GridCellProps, GridColumn, GridColumnProps } from "@progress/kendo-react-grid";
// common
import CustomGridCell from "../../../../common/kendoGrid/CustomGridCell";
import * as GlobalHelpers from "../../../../common/GlobalHelpers";
import * as geoscienceAustraliaRouteHelper from "../../../geoscienceAustraliaRouteHelper";
import CommentSummaryView from "../../../../common/commentSummary/commentSummaryView";

export default function DataMonitoringListGridColumnsView(canViewDataSubmissions: boolean, canViewAuthorisations: boolean): Array<ReactElement<GridColumnProps>> {
    return [
        <GridColumn key="dataMonitoringItemTypename" field="dataMonitoringItemType.name" title="Name" width={350} />,
        <GridColumn key="dataMonitoringItemTypeclassificationType" field="dataMonitoringItemType.classificationType" title="Classification Type" width={150} />,
        <GridColumn
            key="dueDate"
            field="dueDate"
            title="Due Date"
            format={GlobalHelpers.NoptaDateFormatForKendo}
            width={150}
            cell={(props: GridCellProps) => (
                <CustomGridCell gridCellProps={props}>
                    <>
                        <span>{GlobalHelpers.toNoptaDateString(props.dataItem.dueDate)}</span>
                        {props.dataItem.isDueDateExtended && (
                            <span className="badge rounded-pill text-bg-success mx-2" title="Extension approved by Authorisation">
                                EXT
                            </span>
                        )}
                    </>
                </CustomGridCell>
            )}
        />,
        <GridColumn key="receivedStatus" field="status" title="Status" width={170} />,
        <GridColumn
            key="dataSubmissionName"
            field="dataSubmission.name"
            title="Data Submission"
            width={140}
            cell={(props: GridCellProps) => (
                <CustomGridCell gridCellProps={props}>
                    {props.dataItem.dataSubmission?.id && canViewDataSubmissions ? (
                        <Link to={geoscienceAustraliaRouteHelper.dataSubmissionDataMonitoring(props.dataItem.dataSubmission.id)} title="Click to view Data Submission.">
                            {props.dataItem.dataSubmission.name}
                        </Link>
                    ) : (
                        props.dataItem.dataSubmission?.name
                    )}
                </CustomGridCell>
            )}
        />,
        <GridColumn key="receivedDate" field="receivedDate" title="Received Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={115} />,
        <GridColumn key="contentsCheckCompletedDate" field="contentsCheckCompletedDate" title="Contents Check Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={160} />,
        <GridColumn key="dataCheckCompletedDate" field="dataCheckCompletedDate" title="Data Check Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={125} />,
        <GridColumn key="sentToGaDate" field="sentToGaDate" title="Sent to GA Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={130} />,
        <GridColumn
            key="information"
            field="information"
            title="Information"
            width={270}
            cell={(props: GridCellProps) => (
                <CustomGridCell gridCellProps={props}>
                    {props.dataItem.isInvalidDuplicate && (
                        <span className={`badge rounded-pill text-bg-warning me-1`} title={`Duplicates are not valid for this DMI Type.`}>
                            Duplicate
                        </span>
                    )}
                    {props.dataItem.resubmissions?.map((resubmission: any) => (
                        <span key={resubmission.id}>
                            {canViewDataSubmissions ? (
                                <Link
                                    to={geoscienceAustraliaRouteHelper.dataSubmissionResubmissionDetails(props.dataItem.dataSubmission.id, resubmission.id)}
                                    className={`badge rounded-pill ${resubmission.isCompleted ? "text-bg-secondary" : "text-bg-danger"}`}
                                    title={`Click to view Resubmission.`}
                                >
                                    {resubmission.name}
                                </Link>
                            ) : (
                                <div className={`badge rounded-pill ${resubmission.isCompleted ? "text-bg-secondary" : "text-bg-danger"}`}>{resubmission.name}</div>
                            )}
                            <span> </span>
                        </span>
                    ))}
                    {props.dataItem.authorisations?.map((authorisation: any) => (
                        <span key={authorisation.id}>
                            {canViewAuthorisations ? (
                                <Link
                                    to={geoscienceAustraliaRouteHelper.authorisationDetails(authorisation.id)}
                                    className={`badge rounded-pill ${authorisation.isFinalised ? "text-bg-secondary" : "text-bg-danger"}`}
                                    title={`Click to view Authorisation.`}
                                >
                                    {authorisation.name}
                                </Link>
                            ) : (
                                <span className={`badge rounded-pill ${authorisation.isFinalised ? "text-bg-secondary" : "text-bg-danger"}`} title={`Linked Authorisation.`}>
                                    {authorisation.name}
                                </span>
                            )}
                            <span> </span>
                        </span>
                    ))}
                </CustomGridCell>
            )}
        />,
        <GridColumn
            key="Comments"
            title="Comments"
            width={250}
            field="comments"
            filter="text"
            cell={(props: GridCellProps) => (
                <CustomGridCell gridCellProps={props} className="k-command-cell">
                    <CommentSummaryView comment={props.dataItem.comments} maxSummaryLength={20} title="Comments"></CommentSummaryView>
                </CustomGridCell>
            )}
        />,
    ];
}
