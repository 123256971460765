// framework
import { clone, cloneDeep } from "lodash";
// kendo
import { orderBy } from "@progress/kendo-data-query";
// client
import * as Client from "../../../../api/Client";
// redux
import { IRequestAccessRoleViewModel, RequestAccessRoleViewModel } from "./requestAccessRoleViewModel";
// common
import { RoleCategoryEnum } from "../../../../common/roleGrid/RoleViewModel";
import { BaseCompanyRoleRootViewModel, ICompanyRoleRootViewModel } from "../../../common/CompanyRoleRootViewModel";

export interface IQuestionsViewModel {
    selectedCompany?: Client.SecureUserCompanyDto;
    isNewCompanyRequest?: boolean;
    isCompanyAdministratorRequest?: boolean;
    isCompanySignerRequest?: boolean;
    isCompanySignerPoaRequest?: boolean;
    isCompanyStandardRoleRequest?: boolean;
}

export interface INewCompanyRequestViewModel {
    companyName?: string;
    companyAbn?: string;
    companyAcnOrArbn?: string;
    businessAddress?: string;
    businessSuburb?: string;
    businessPostcode?: string;
    businessState?: Client.SecurePortalCountrySubdivisionDto;
    businessCountry?: Client.SecurePortalCountryDto;
    postalAddress?: string;
    postalSuburb?: string;
    postalPostcode?: string;
    postalState?: Client.SecurePortalCountrySubdivisionDto;
    postalCountry?: Client.SecurePortalCountryDto;
    isPostalAddressSameAsBusinessAddress?: boolean;
}

export interface ISupportingDocumentViewModel {
    fileName?: string;
    content?: string;
}

export interface ICompanyStandardRoleRequestViewModel {
    existingRoles?: IRequestAccessRoleViewModel;
    requestedRoles?: IRequestAccessRoleViewModel;
}

export interface IViewStateViewModel {
    showExistingAccessRequestWizard: boolean;
    showExistingFullAccessWizard: boolean;
    showExistingCompanyAdministratorWizard: boolean;
    showExistingCompanyStandardRolesWizard: boolean;
    showExistingCompanyNewAdministratorWizard: boolean;
    showExistingCompanyExistingAdministratorWizard: boolean;
    showNewCompanyWizard: boolean;
    showCompanySignerWizard: boolean;
    showInvalidRequestWizard: boolean;
    showRequestForm: boolean;
}

export interface IRootViewModel extends ICompanyRoleRootViewModel {
    reference: {
        companies: Array<Client.SecureUserCompanyDto>;
        countries: Array<Client.SecurePortalCountryDto>;
    };

    user: {
        questions: IQuestionsViewModel;
        newCompanyRequest: INewCompanyRequestViewModel;
        companyStandardRoleRequest: ICompanyStandardRoleRequestViewModel;
        caAuthoritySupportingDocument: ISupportingDocumentViewModel;
        csAuthoritySupportingDocument: ISupportingDocumentViewModel;
        csPoaSupportingDocument: ISupportingDocumentViewModel;
        comments: string;
    };

    service: {
        id?: Client.SecureRequestGroupIdDto | undefined;
        statusMessages?: Client.StatusMessagesDto;
    };

    isDirty: boolean;

    viewState: IViewStateViewModel;

    // saga triggered state changes
    refreshReferenceData(companies: Array<Client.SecureUserCompanyDto>, countries: Array<Client.SecurePortalCountryDto>): IRootViewModel;
    refreshSecureRequestCreateResponse(statusMessages: Client.StatusMessagesDto, id: Client.SecureRequestGroupIdDto | undefined): IRootViewModel;

    // view triggered state changes
    refreshSelectedCompanyChanged(company?: Client.SecureUserCompanyDto): IRootViewModel;
    refreshQuestionsUpdated(values: IQuestionsViewModel): IRootViewModel;
    refreshNewCompanyRequestUpdated(values: INewCompanyRequestViewModel): IRootViewModel;
    refreshCompanyStandardRoleRequestUpdated(values: IRequestAccessRoleViewModel): IRootViewModel;
    refreshCaAuthoritySupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel;
    refreshCsAuthoritySupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel;
    refreshCsPoaSupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel;
    refreshCommentsUpdated(comments: string): IRootViewModel;
}

export class RootViewModel extends BaseCompanyRoleRootViewModel implements IRootViewModel {
    constructor() {
        super();
        // initial state
        this.reference = {
            companies: new Array<Client.SecureUserCompanyDto>(),
            countries: new Array<Client.SecurePortalCountryDto>(),
        };
        this.user = {
            questions: {
                selectedCompany: undefined,
                isNewCompanyRequest: undefined,
                isCompanyAdministratorRequest: undefined,
                isCompanySignerRequest: undefined,
                isCompanyStandardRoleRequest: undefined,
                isCompanySignerPoaRequest: undefined,
            },
            newCompanyRequest: {},
            companyStandardRoleRequest: {},
            caAuthoritySupportingDocument: {},
            csAuthoritySupportingDocument: {},
            csPoaSupportingDocument: {},
            comments: "",
        };

        this.service = {
            id: undefined,
            statusMessages: undefined,
        };

        this.isDirty = false;
        this.viewState = {
            showExistingAccessRequestWizard: false,
            showExistingFullAccessWizard: false,
            showExistingCompanyAdministratorWizard: false,
            showExistingCompanyStandardRolesWizard: false,
            showExistingCompanyNewAdministratorWizard: false,
            showExistingCompanyExistingAdministratorWizard: false,
            showNewCompanyWizard: false,
            showCompanySignerWizard: false,
            showInvalidRequestWizard: false,
            showRequestForm: false,
        };
    }

    reference: {
        companies: Array<Client.SecureUserCompanyDto>;
        countries: Array<Client.SecurePortalCountryDto>;
    };

    user: {
        questions: IQuestionsViewModel;
        newCompanyRequest: INewCompanyRequestViewModel;
        companyStandardRoleRequest: ICompanyStandardRoleRequestViewModel;
        caAuthoritySupportingDocument: ISupportingDocumentViewModel;
        csAuthoritySupportingDocument: ISupportingDocumentViewModel;
        csPoaSupportingDocument: ISupportingDocumentViewModel;
        comments: string;
    };

    service: {
        id?: Client.SecureRequestGroupIdDto | undefined;
        statusMessages?: Client.StatusMessagesDto;
    };

    isDirty: boolean;
    viewState: IViewStateViewModel;

    // saga triggered state changes
    refreshReferenceData(companies: Array<Client.SecureUserCompanyDto>, countries: Array<Client.SecurePortalCountryDto>): IRootViewModel {
        const vm = this._clone();
        vm.reference.companies = orderBy(companies, [{ field: "companyName", dir: "asc" }]);
        vm.reference.countries = countries;
        // set defaults
        vm.user.newCompanyRequest.businessCountry = countries.find((c) => c.countryName === "Australia");
        vm.user.companyStandardRoleRequest.existingRoles = new RequestAccessRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Existing);
        vm.user.companyStandardRoleRequest.requestedRoles = new RequestAccessRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Requested);

        vm.isDirty = false; // reference data load is part of the initialisation phase
        return vm;
    }

    refreshSecureRequestCreateResponse(statusMessages: Client.StatusMessagesDto, id: Client.SecureRequestGroupIdDto | undefined): IRootViewModel {
        const vm = this._clone();
        vm.service.id = id;
        vm.service.statusMessages = statusMessages;
        vm.isDirty = !statusMessages!.isSuccess; // on success, set the vm as clean
        return vm;
    }

    // user triggered state changes
    refreshSelectedCompanyChanged(selectedCompany?: Client.SecureUserCompanyDto): IRootViewModel {
        const vm = this._clone();
        vm.user.questions.selectedCompany = selectedCompany;

        if (selectedCompany) {
            vm.user.questions.isNewCompanyRequest = false;

            const existingCompanyRoles = new RequestAccessRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Existing, selectedCompany.activeRoles);
            vm.user.companyStandardRoleRequest.existingRoles = existingCompanyRoles;
            // if user already has a standard role they cannot request it, otherwise do not change existing form answers as per requirements
            if (selectedCompany.activeRoles.length > 0) {
                const currentRequestedRoles = vm.user.companyStandardRoleRequest.requestedRoles!.getDirectRoles();
                const allowedRequestedRoles = currentRequestedRoles.filter((r) => !existingCompanyRoles.hasRole(r));
                vm.user.companyStandardRoleRequest.requestedRoles = new RequestAccessRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Requested, allowedRequestedRoles);
            }

            if (!selectedCompany.hasCompanyAdministrator) {
                // if company doesn't have an administrator, user must request admin and therefore cannot request general roles or signer
                vm.user.questions.isCompanyAdministratorRequest = true;
                vm.user.questions.isCompanyStandardRoleRequest = false;
                vm.user.questions.isCompanySignerRequest = false;
                vm.user.questions.isCompanySignerPoaRequest = false;
            } else {
                if (selectedCompany.isCompanyAdministrator) {
                    // if user already has administrator role they cannot request anything other than signer
                    vm.user.questions.isCompanyAdministratorRequest = false;
                    vm.user.questions.isCompanyStandardRoleRequest = false;
                } else {
                    // if user already has all general roles they cannot request more, otherwise reset existing question answers
                    vm.user.questions.isCompanyAdministratorRequest = undefined;
                    vm.user.questions.isCompanyStandardRoleRequest = selectedCompany.hasAllGeneralRoles ? false : undefined;
                }

                const isSignerRequest = selectedCompany.isCompanySigner ? false : undefined;
                vm.user.questions.isCompanySignerRequest = isSignerRequest;
                vm.user.questions.isCompanySignerPoaRequest = isSignerRequest;
            }
        }

        updateFlags(vm);
        vm.isDirty = true;
        return vm;
    }

    refreshQuestionsUpdated(values: IQuestionsViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.questions = { ...vm.user.questions, ...values };

        if (values.isNewCompanyRequest) {
            vm.user.questions.selectedCompany = undefined;
            vm.user.questions.isCompanyAdministratorRequest = true;
            vm.user.questions.isCompanySignerRequest = false;
            vm.user.questions.isCompanySignerPoaRequest = false;
            vm.user.questions.isCompanyStandardRoleRequest = false;
        } else if (values.isCompanyAdministratorRequest === true) {
            vm.user.questions.isCompanySignerRequest = false;
            vm.user.questions.isCompanySignerPoaRequest = false;
            vm.user.questions.isCompanyStandardRoleRequest = false;
        } else if (values.isCompanyAdministratorRequest === false) {
            vm.user.questions.isCompanyStandardRoleRequest = !vm.user.questions.selectedCompany!.hasAllGeneralRoles;

            // Clear Signer and POA selection when CA selection is changed
            const isCompanySigner = vm.user.questions.selectedCompany!.isCompanySigner ? false : undefined;
            vm.user.questions.isCompanySignerRequest = isCompanySigner;
            vm.user.questions.isCompanySignerPoaRequest = isCompanySigner;
        } else if (values.isCompanySignerRequest === false) {
            // Clear POA selection when CS selection is changed
            vm.user.questions.isCompanySignerPoaRequest = undefined;
        }

        updateFlags(vm);
        vm.isDirty = true;
        return vm;
    }

    refreshNewCompanyRequestUpdated(values: INewCompanyRequestViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.newCompanyRequest = values;
        vm.isDirty = true;
        return vm;
    }

    refreshCompanyStandardRoleRequestUpdated(values: IRequestAccessRoleViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.companyStandardRoleRequest.requestedRoles = values;
        vm.isDirty = true;
        return vm;
    }

    refreshCaAuthoritySupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.caAuthoritySupportingDocument = value;
        vm.isDirty = true;
        return vm;
    }

    refreshCsAuthoritySupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.csAuthoritySupportingDocument = value;
        vm.isDirty = true;
        return vm;
    }

    refreshCsPoaSupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.csPoaSupportingDocument = value;
        vm.isDirty = true;
        return vm;
    }

    refreshCommentsUpdated(comments: string): IRootViewModel {
        const vm = this._clone();
        vm.user.comments = comments;
        vm.isDirty = true;
        return vm;
    }

    // private implementation
    // ----------------------
    _clone(): RootViewModel {
        const vm = clone(this);
        vm.reference = clone(this.reference);
        vm.user = cloneDeep(this.user);
        vm.service = clone(this.service);
        vm.viewState = clone(this.viewState);
        return vm;
    }
}

// private
function updateFlags(vm: IRootViewModel): void {
    const questions = vm.user.questions;
    const selectedCompany = questions.selectedCompany;

    vm.viewState = {
        showExistingAccessRequestWizard: false,
        showExistingFullAccessWizard: false,
        showExistingCompanyAdministratorWizard: false,
        showExistingCompanyStandardRolesWizard: false,
        showExistingCompanyNewAdministratorWizard: false,
        showExistingCompanyExistingAdministratorWizard: false,
        showNewCompanyWizard: false,
        showCompanySignerWizard: false,
        showInvalidRequestWizard: false,
        showRequestForm: false,
    };

    // existing company
    if (selectedCompany) {
        if (selectedCompany.hasPendingRequest) {
            // pending request - cannot proceed
            vm.viewState.showExistingAccessRequestWizard = true;
            return;
        } else if (!selectedCompany.hasCompanyAdministrator) {
            // no CA - must select new CA
            vm.viewState.showExistingCompanyNewAdministratorWizard = true;
        } else {
            if (selectedCompany.hasAllSpecialRoles) {
                // full access - cannot proceed
                vm.viewState.showExistingFullAccessWizard = true;
                return;
            } else if (selectedCompany.isCompanyAdministrator) {
                // CA access - can only select CS
                vm.viewState.showExistingCompanyAdministratorWizard = true;
            } else {
                // all general roles - can only select CA or CS
                vm.viewState.showExistingCompanyStandardRolesWizard = selectedCompany.hasAllGeneralRoles;
                // show CA access wizard
                vm.viewState.showExistingCompanyExistingAdministratorWizard = true;
            }
        }
    }
    // new company
    else if (questions.isNewCompanyRequest) {
        vm.viewState.showNewCompanyWizard = true;
    } else return;

    // break if no CA selection has been made
    if (questions.isCompanyAdministratorRequest === undefined) return;
    // CS selection is only applicable if the user is not already a signer and if the request is not a CA request
    if (questions.isCompanyAdministratorRequest === false && selectedCompany?.isCompanySigner === false) {
        vm.viewState.showCompanySignerWizard = true;
    }
    // break if no CS selection has been made
    if (questions.isCompanySignerRequest === undefined) return;

    // reveal form
    vm.viewState.showInvalidRequestWizard = questions.isCompanyAdministratorRequest === false && questions.isCompanySignerRequest === false && questions.isCompanyStandardRoleRequest === false;
    vm.viewState.showRequestForm = questions.isCompanyAdministratorRequest === true || questions.isCompanySignerRequest === true || questions.isCompanyStandardRoleRequest === true;
}
