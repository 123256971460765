// framework
import { useState } from "react";
// api
import * as Client from "../../../../api/Client";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// redux
import * as Models from "../models/models";
// other
import * as GlobalHelpers from "../../../../common/GlobalHelpers";
import TitleLinkController from "../../../../common/links/TitleLinkController";
import { createGridColumnFilterMenu } from "../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
import CustomGridCell from "../../../../common/kendoGrid/CustomGridCell";

export default function TitlesView(props: { vm: Models.titlesType }): React.ReactElement {
    const titles = props.vm ?? new Array<Client.GetCompanyOpggsTitleListTitleDto>();

    const initialGridState: State = {
        sort: [
            { field: "renewalDate", dir: "desc" },
            { field: "grantedDate", dir: "desc" },
        ],
        skip: 0,
        take: 20,
    };
    const [gridState, setGridState] = useState(initialGridState);

    return (
        <Grid
            pageable
            sortable
            resizable
            navigatable
            data={process(titles, gridState)}
            {...gridState}
            onDataStateChange={(e) => {
                setGridState(e.dataState);
            }}
        >
            <GridColumn
                field="titleLink.titleNumber"
                title="Title"
                width={150}
                filter="text"
                columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, titles)}
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        <TitleLinkController linkDto={props.dataItem.titleLink} secureEntityType="Company" />
                    </CustomGridCell>
                )}
            />
            <GridColumn field="titleTypeDisplay" title="Title Type" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, titles)} />
            <GridColumn field="titleStatusDisplay" title="Title Status" width={150} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, titles)} />
            <GridColumn field="titleHoldersDisplay" title="Titleholders" width={350} filter="text" columnMenu={createGridColumnFilterMenu} />
            <GridColumn field="currentExpiryDate" title="Expiry" width={160} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
            <GridColumn field="renewalDate" title="Renewal" width={160} format={GlobalHelpers.NoptaDateFormatForKendo} filter="date" columnMenu={createGridColumnFilterMenu} />
            <GridColumn field="grantedDate" title="Grant" width={160} format={GlobalHelpers.NoptaDateFormatForKendo} filter="date" columnMenu={createGridColumnFilterMenu} />
            <GridColumn field="offshoreArea" title="Offshore Area" filter="text" width={200} columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, titles)} />
            <GridColumn field="basin" title="Basin" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, titles)} />
            <GridColumn field="subBasin" title="Sub-Basin" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, titles)} />
        </Grid>
    );
}
