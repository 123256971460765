// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    acquisition: Client.GetGeoscienceAustraliaSurveysAcquisitionsAcquisitionDetailsResponseDto | undefined;

    refreshAcquisitionDetails(response: Client.GetGeoscienceAustraliaSurveysAcquisitionsAcquisitionDetailsResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.acquisition = undefined;
    }

    public acquisition: Client.GetGeoscienceAustraliaSurveysAcquisitionsAcquisitionDetailsResponseDto | undefined;

    public refreshAcquisitionDetails(response: Client.GetGeoscienceAustraliaSurveysAcquisitionsAcquisitionDetailsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.acquisition = response;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.acquisition = clone(this.acquisition);
        return vm;
    }
}
