// framework
import { useEffect, useState } from "react";
// redux
import * as Actions from "../../redux/actions";
// models
import * as Models from "../../models/rootViewModel";
// common
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
import * as Validation from "../../../../../common/validation/ValidationModel";
import * as Forms from "../../../../../common/forms/BootstrapForms";

export default function RejectionDetailsView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; validation: Validation.IValidationModel }): React.ReactElement {
    const vm = props.vm;
    const actions = props.actions;
    const v = props.validation;
    const rejectionDetails = vm.rejectionDetails;

    // standard role decision state
    const [state, setState] = useState<Models.ISecureRequestRejectionsDetailsViewModel>({
        isSuspicious: false,
        comments: "",
    });
    // update decision if changed
    useEffect(() => {
        setState({
            isSuspicious: rejectionDetails.isSuspicious,
            comments: rejectionDetails.comments,
        });
    }, [rejectionDetails]);

    // checkbox change - update state and viewModel
    function onCheckboxChanged(values: any) {
        const updatedState = { ...state, ...values };
        setState(updatedState);
        actions.viewChanged(vm.refreshRejectionDetailsChanged(updatedState));
    }

    // generic input change
    function onChanged(values: any) {
        setState({ ...state, ...values });
    }

    function updateDecisionDetails() {
        actions.viewChanged(vm.refreshRejectionDetailsChanged(state));
    }

    // view
    return (
        <div className="card mb-2">
            <div className="card-body">
                <h2 className="card-title">Rejection Details</h2>
                <Forms.Group>
                    <Forms.TextArea
                        id="reasonField"
                        label="Reason"
                        rows={5}
                        maxLength={1000}
                        value={state.comments}
                        valid={v.applyValidity("RejectionDetails.Comments")}
                        onChange={(value) => onChanged({ comments: value })}
                        onBlur={updateDecisionDetails}
                    />
                    <ValidationMessageView name="RejectionDetails.Comments" validationModel={v} />
                </Forms.Group>
                <Forms.Group>
                    <Forms.Checkbox
                        id="isSuspiciousField"
                        label="This request appears to be suspicious"
                        checked={state.isSuspicious}
                        onChange={(checked) => onCheckboxChanged({ isSuspicious: checked })}
                        inline
                    />
                </Forms.Group>
            </div>
        </div>
    );
}
