// framework
import { clone } from "lodash";
// api
import * as Client from "../../../api/Client";

export interface IOpenViewModel {
    linkType: Client.SepiLinkTypeEnum | undefined;
    applicationLink: Client.GetShellOpenApplicationLinkDto | undefined;
    draftApplicationLink: Client.GetShellOpenDraftApplicationLinkDto | undefined;
    financialNotificationLink: Client.GetShellOpenFinancialNotificationLinkDto | undefined;
    secureRequestGroupLink: Client.GetShellOpenSecureRequestGroupLinkDto | undefined;
    feedbackLink: Client.GetShellOpenFeedbackLinkDto | undefined;
    titleLink: Client.GetShellOpenTitleLinkDto | undefined;
    licenceLink: Client.GetShellOpenLicenceLinkDto | undefined;

    isConflict: boolean;

    refreshOpenLink(response: Client.GetShellOpenLinkResponseDto): IOpenViewModel;
    refreshConflict(): IOpenViewModel;
}

export class OpenLinkModel implements IOpenViewModel {
    constructor() {
        this.linkType = undefined;
        this.applicationLink = undefined;
        this.draftApplicationLink = undefined;
        this.financialNotificationLink = undefined;
        this.secureRequestGroupLink = undefined;
        this.feedbackLink = undefined;
        this.titleLink = undefined;
        this.licenceLink = undefined;

        this.isConflict = false;
    }

    public linkType: Client.SepiLinkTypeEnum | undefined;
    public applicationLink: Client.GetShellOpenApplicationLinkDto | undefined;
    public draftApplicationLink: Client.GetShellOpenDraftApplicationLinkDto | undefined;
    public financialNotificationLink: Client.GetShellOpenFinancialNotificationLinkDto | undefined;
    public secureRequestGroupLink: Client.GetShellOpenSecureRequestGroupLinkDto | undefined;
    public feedbackLink: Client.GetShellOpenFeedbackLinkDto | undefined;
    public titleLink: Client.GetShellOpenTitleLinkDto | undefined;
    public licenceLink: Client.GetShellOpenLicenceLinkDto | undefined;

    public isConflict: boolean;

    public refreshOpenLink(response: Client.GetShellOpenLinkResponseDto): IOpenViewModel {
        const vm = this._clone();

        vm.linkType = response.linkType;
        vm.applicationLink = response.applicationLink;
        vm.draftApplicationLink = response.draftApplicationLink;
        vm.financialNotificationLink = response.financialNotificationLink;
        vm.secureRequestGroupLink = response.secureRequestGroupLink;
        vm.feedbackLink = response.feedbackLink;
        vm.titleLink = response.titleLink;
        vm.licenceLink = response.licenceLink;

        vm.isConflict = false;

        return vm;
    }

    public refreshConflict(): IOpenViewModel {
        const vm = this._clone();

        vm.linkType = undefined;
        vm.applicationLink = undefined;
        vm.draftApplicationLink = undefined;
        vm.financialNotificationLink = undefined;
        vm.secureRequestGroupLink = undefined;
        vm.titleLink = undefined;
        vm.licenceLink = undefined;

        vm.isConflict = true;

        return vm;
    }

    private _clone(): IOpenViewModel {
        const vm = clone(this);
        return vm;
    }
}
