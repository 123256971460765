// framework
import { useState, useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Actions from "../redux/actions";
import * as Models from "../models/models";
import validator from "../models/validator";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
// common
import StatusMessagesAlertsView from "../../../../common/alerts/StatusMessagesAlertsView";
import DirtyScope from "../../../../common/shell/DirtyScopeController";
import * as SecureFileDownloadButtonControl from "../../../../common/secureFileDownload/SecureFileDownloadButtonControl";
import ConfirmationView, { DefaultButtonEnum } from "../../../../common/confirmation/ConfirmationView";
import { ValidationVisibilityEnum } from "../../../../common/validation/ValidationModel";
import * as toastHelper from "../../../../common/toastHelper";
// views
import SubmittedView from "./views/SubmittedView";
import DisclaimerView from "./views/DisclaimerView";
import FilesGridView from "./views/FilesGridView";
import UploadFileView from "./views/UploadFileView";
import CommentsView from "./views/CommentsView";
import ApplicantView from "./views/ApplicantView";

interface IControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const [isBusy, setIsBusy] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    // on mount
    useEffect(() => {
        actions.initialiseRequested();
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // validation
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const validation = validator(vm, validationVisibility);

    function onBusyChanged(busy: boolean): void {
        setIsBusy(busy);
    }

    function onDeleteRequestClick(): void {
        setShowDeleteConfirmation(true);
    }

    function onDeleteAccepted(): void {
        setShowDeleteConfirmation(false);
        actions.viewChanged(vm.onDelete());
    }

    function onDeleteCancelled(): void {
        setShowDeleteConfirmation(false);
    }

    // submission
    function onSubmitClick(e: any) {
        e.preventDefault();

        // validation
        // - validation is hidden until the user attempts the action... it then stays visible until they have successfully completed the action
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);

        actions.createApplicationAdhocUploadRequested();
    }

    const selectedFiles: Array<SecureFileDownloadButtonControl.IFile> = vm.files
        .filter((f) => f.isSelected)
        .map((f) => {
            return { id: f.fileId, size: f.fileSize };
        });

    // return
    return (
        <BodyLayout title="Upload OPGGS Act Application, reporting and data submissions" selectedLhsNavItem={Level1ItemTypeEnum.Company_Application_UploadTitleApplication}>
            <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
            {vm.isSubmitted ? (
                <SubmittedView />
            ) : (
                <DirtyScope scopeName="company/opggs/applicationAdhocUpload" isDirty={vm.isDirty}>
                    {/* guidance */}
                    <DisclaimerView vm={vm} />

                    {/* applicant view */}
                    <div className="mb-2">
                        <ApplicantView vm={vm} actions={actions} v={validation} isBusy={isBusy} />
                    </div>

                    {/* file grid */}
                    <div className="mb-2">
                        <FilesGridView vm={vm} actions={actions} v={validation} />
                    </div>

                    {/* grid action buttons */}
                    {vm.files.length > 0 && (
                        <div className="mb-4">
                            <SecureFileDownloadButtonControl.ButtonControl disabled={isBusy || vm.numberOfFilesSelected === 0} files={selectedFiles} className="me-1" />
                            <button className="btn btn-outline-secondary" disabled={isBusy || vm.numberOfFilesSelected === 0} onClick={onDeleteRequestClick}>
                                Delete
                            </button>
                            <div>
                                <SecureFileDownloadButtonControl.ValidationControl files={selectedFiles} />
                            </div>
                        </div>
                    )}

                    {/* delete confirmation */}
                    {showDeleteConfirmation && (
                        <ConfirmationView title="Confirm File Deletion" onAccepted={onDeleteAccepted} onClosed={onDeleteCancelled} defaultButton={DefaultButtonEnum.Cancel}>
                            <p>You have selected {vm.numberOfFilesSelected} file(s) for deletion.</p>
                            <p>Do you wish to continue?</p>
                        </ConfirmationView>
                    )}

                    {/* upload view */}
                    <div className="mb-2">
                        <UploadFileView vm={vm} actions={actions} onBusyChanged={onBusyChanged} />
                    </div>

                    {/* comments view */}
                    <div className="mb-2">
                        <CommentsView vm={vm} actions={actions} v={validation} isBusy={isBusy} />
                    </div>

                    {/* submit */}
                    <div className="d-flex flex-wrap">
                        <button className="btn btn-outline-primary" onClick={onSubmitClick} disabled={isBusy}>
                            Submit
                        </button>
                    </div>
                </DirtyScope>
            )}
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_Opggs_ApplicationAdhocUpload }), Actions.actionFactory)(Controller);
