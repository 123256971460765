// framework
import { useEffect } from "react";
import { connect } from "react-redux";
// custom
import * as Actions from "./redux/actions";
import * as Models from "./models/models";
import { IShellReduxState } from "../shellReduxRegistration";

interface IProps extends Actions.IActionFactory {
    viewModel: Models.IRootViewModel;
}

function Controller(props: IProps) {
    const isSignedIn = props.viewModel.isSignedIn;

    // Immediately attempt a silent sign-in to detect current state
    useEffect(() => {
        if (!isSignedIn) props.trySilentSignInRequestedAction();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <></>;
}

// connect redux
export default connect((state: IShellReduxState) => ({ viewModel: state.Shell_SignIn }), Actions.actionFactory)(Controller);
