// framework
import { clone, cloneDeep } from "lodash";
// kendo
import { orderBy } from "@progress/kendo-data-query";
// client
import * as Client from "../../../api/Client";
// redux
import { IRegistrationRoleViewModel, RegistrationRoleViewModel } from "./registrationRoleViewModel";
// common
import { BaseJointAuthorityRoleRootViewModel, IJointAuthorityRoleRootViewModel } from "../../common/JointAuthorityRoleRootViewModel";
import { RoleCategoryEnum } from "../../../common/roleGrid/RoleViewModel";

export interface IRootViewModel extends IJointAuthorityRoleRootViewModel {
    reference: {
        jointAuthorities: Array<Client.GetRegistrationJointAuthorityJointAuthorityDto>;
    };

    user: {
        questions: IQuestionsViewModel;
        newUserRequest: INewUserRequestViewModel;
        jointAuthorityStandardRoleRequest: IJointAuthorityStandardRoleRequestViewModel;
        jaaAuthoritySupportingDocument: ISupportingDocumentViewModel;
        // todo nduja
        //jasAuthoritySupportingDocument: ISupportingDocumentViewModel;
        //jasPoaSupportingDocument: ISupportingDocumentViewModel;
        isUserAgreementAcknowledged: boolean;
        comments: string;
    };

    service: {
        referenceNumber?: string;
        statusMessages?: Client.StatusMessagesDto;
    };

    isDirty: boolean;

    viewState: IViewStateViewModel;

    // saga triggered state changes
    refreshReferenceData(jointAuthorities: Array<Client.GetRegistrationJointAuthorityJointAuthorityDto>): IRootViewModel;
    refreshSecureRequestCreateResponse(statusMessages: Client.StatusMessagesDto, referenceNumber: string | undefined): IRootViewModel;

    // view triggered state changes
    refreshSelectedJointAuthorityChanged(jointAuthority?: Client.GetRegistrationJointAuthorityJointAuthorityDto): IRootViewModel;
    refreshQuestionsUpdated(values: IQuestionsViewModel): IRootViewModel;
    refreshNewUserRequestUpdated(values: INewUserRequestViewModel): IRootViewModel;
    refreshEmailVerificationUpdated(values: IEmailVerificationViewModel): IRootViewModel;
    refreshJarStandardRoleRequestUpdated(values: IRegistrationRoleViewModel): IRootViewModel;
    refreshJaaAuthoritySupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel;
    // todo nduja
    //refreshJasAuthoritySupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel;
    //refreshJasPoaSupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel;
    refreshUserAgreementUpdated(isUserAgreementAcknowledged: boolean): IRootViewModel;
    refreshCommentsUpdated(comments: string): IRootViewModel;
}

export interface IQuestionsViewModel {
    selectedJointAuthority?: Client.GetRegistrationJointAuthorityJointAuthorityDto;
    isJointAuthorityAdministratorRequest?: boolean;
    // todo nduja still coming
    // isJointAuthoritySignerRequest?: boolean;
    // isJointAuthoritySignerPoaRequest?: boolean;
}

export interface INewUserRequestViewModel {
    email?: string;
    emailVerification: IEmailVerificationViewModel;
    firstName?: string;
    lastName?: string;
    contactNumber?: string;
    position?: string;
    password?: string;
    passwordConfirmation?: string;
}

export interface IEmailVerificationViewModel {
    isVisible: boolean;
    token?: string;
    verificationCode?: string;
    status: Client.EmailVerificationStatusEnum;
    requestEmailVerificationStatusMessages?: Client.StatusMessagesDto;
    verifyEmailVerificationStatusMessages?: Client.StatusMessagesDto;
}

export interface ISupportingDocumentViewModel {
    fileName?: string;
    content?: string;
}

export interface IJointAuthorityStandardRoleRequestViewModel {
    requestedRoles?: IRegistrationRoleViewModel;
}

export interface IViewStateViewModel {
    showExistingJointAuthorityNewAdministratorWizard: boolean;
    showExistingJointAuthorityExistingAdministratorWizard: boolean;
    // todo nduja not yet
    // showJointAuthoritySignerWizard: boolean;
    showRequestForm: boolean;
}

export class RootViewModel extends BaseJointAuthorityRoleRootViewModel implements IRootViewModel {
    constructor() {
        super();

        // initial state
        this.reference = {
            jointAuthorities: new Array<Client.GetRegistrationJointAuthorityJointAuthorityDto>(),
        };
        this.user = {
            questions: {
                selectedJointAuthority: undefined,
                isJointAuthorityAdministratorRequest: undefined,
                //isJointAuthoritySignerRequest: undefined,
                //isJointAuthoritySignerPoaRequest: undefined,
            },
            newUserRequest: { emailVerification: { isVisible: false, status: Client.EmailVerificationStatusEnum.Unverified } },
            jointAuthorityStandardRoleRequest: {},
            jaaAuthoritySupportingDocument: {},
            // todo nduja
            //jasAuthoritySupportingDocument: {},
            //jasPoaSupportingDocument: {},
            comments: "",
            isUserAgreementAcknowledged: false,
        };

        this.service = {
            referenceNumber: undefined,
            statusMessages: undefined,
        };

        this.isDirty = false;
        this.viewState = {
            showExistingJointAuthorityNewAdministratorWizard: false,
            showExistingJointAuthorityExistingAdministratorWizard: false,
            // showJointAuthoritySignerWizard: false,
            showRequestForm: false,
        };
    }

    reference: {
        jointAuthorities: Array<Client.GetRegistrationJointAuthorityJointAuthorityDto>;
    };

    user: {
        questions: IQuestionsViewModel;
        newUserRequest: INewUserRequestViewModel;
        jointAuthorityStandardRoleRequest: IJointAuthorityStandardRoleRequestViewModel;
        jaaAuthoritySupportingDocument: ISupportingDocumentViewModel;
        // todo nduja
        // jasAuthoritySupportingDocument: ISupportingDocumentViewModel;
        // jasPoaSupportingDocument: ISupportingDocumentViewModel;
        comments: string;
        isUserAgreementAcknowledged: boolean;
    };

    service: {
        referenceNumber?: string;
        statusMessages?: Client.StatusMessagesDto;
    };

    isDirty: boolean;
    viewState: IViewStateViewModel;

    // saga triggered state changes
    refreshReferenceData(jointAuthorities: Array<Client.GetRegistrationJointAuthorityJointAuthorityDto>): IRootViewModel {
        const vm = this._clone();
        vm.reference.jointAuthorities = orderBy(jointAuthorities, [{ field: "JointAuthorityName", dir: "asc" }]);
        // set defaults
        vm.user.jointAuthorityStandardRoleRequest.requestedRoles = new RegistrationRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Requested);

        vm.isDirty = false; // reference data load is part of the initialisation phase
        return vm;
    }

    refreshSecureRequestCreateResponse(statusMessages: Client.StatusMessagesDto, referenceNumber: string | undefined): IRootViewModel {
        const vm = this._clone();
        vm.service.referenceNumber = referenceNumber;
        vm.service.statusMessages = statusMessages;

        // blow away the email verification status if the service fails
        // otherwise, they're blocked from trying to verify again
        // which is awkward, given it can fail because the token has expired
        if (!statusMessages.isSuccess) vm.user.newUserRequest.emailVerification.status = Client.EmailVerificationStatusEnum.Unverified;

        vm.isDirty = !statusMessages!.isSuccess; // on success, set the vm as clean
        return vm;
    }

    // user triggered state changes
    refreshSelectedJointAuthorityChanged(selectedJointAuthority?: Client.GetRegistrationJointAuthorityJointAuthorityDto): IRootViewModel {
        const vm = this._clone();
        vm.user.questions.selectedJointAuthority = selectedJointAuthority;

        if (selectedJointAuthority) {
            if (!selectedJointAuthority.hasJointAuthorityAdministrator) {
                // if JointAuthority doesn't have an administrator, user must request admin and therefore cannot request general roles or signer
                vm.user.questions.isJointAuthorityAdministratorRequest = true;
                // vm.user.questions.isJointAuthoritySignerRequest = false;
                // vm.user.questions.isJointAuthoritySignerPoaRequest = false;
            } else {
                vm.user.questions.isJointAuthorityAdministratorRequest = undefined;
                // vm.user.questions.isJointAuthoritySignerRequest = undefined;
                // vm.user.questions.isJointAuthoritySignerPoaRequest = undefined;
            }
        }

        updateFlags(vm);
        vm.isDirty = true;
        return vm;
    }

    refreshQuestionsUpdated(values: IQuestionsViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.questions = { ...vm.user.questions, ...values };

        // todo nduja this is all pretty raw, leaving it intact for when signers are implemented
        if (values.isJointAuthorityAdministratorRequest === true) {
            // vm.user.questions.isJointAuthoritySignerRequest = false;
            // vm.user.questions.isJointAuthoritySignerPoaRequest = false;
        } else if (values.isJointAuthorityAdministratorRequest === false) {
            // Clear Signer and POA selection when CA selection is changed
            // vm.user.questions.isJointAuthoritySignerRequest = undefined;
            // vm.user.questions.isJointAuthoritySignerPoaRequest = undefined;
        } // else if (values.isJointAuthoritySignerRequest === false) {
        //     // Clear POA selection when CS selection is changed
        //     vm.user.questions.isJointAuthoritySignerPoaRequest = undefined;
        // }

        updateFlags(vm);
        vm.isDirty = true;
        return vm;
    }

    refreshNewUserRequestUpdated(values: INewUserRequestViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.newUserRequest = values;
        vm.isDirty = true;
        return vm;
    }

    refreshEmailVerificationUpdated(values: IEmailVerificationViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.newUserRequest.emailVerification = { ...values };
        vm.isDirty = true;
        return vm;
    }

    refreshJarStandardRoleRequestUpdated(values: IRegistrationRoleViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.jointAuthorityStandardRoleRequest.requestedRoles = values;
        vm.isDirty = true;
        return vm;
    }

    refreshJaaAuthoritySupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel {
        const vm = this._clone();
        vm.user.jaaAuthoritySupportingDocument = value;
        vm.isDirty = true;
        return vm;
    }

    // todo nduja coming with signing
    // refreshJasAuthoritySupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel {
    //     const vm = this._clone();
    //     vm.user.csAuthoritySupportingDocument = value;
    //     vm.isDirty = true;
    //     return vm;
    // }

    // refreshJasPoaSupportingDocumentUpdated(value: ISupportingDocumentViewModel): IRootViewModel {
    //     const vm = this._clone();
    //     vm.user.csPoaSupportingDocument = value;
    //     vm.isDirty = true;
    //     return vm;
    // }

    refreshUserAgreementUpdated(isUserAgreementAcknowledged: boolean): IRootViewModel {
        const vm = this._clone();
        vm.user.isUserAgreementAcknowledged = isUserAgreementAcknowledged;
        vm.isDirty = true;
        return vm;
    }

    refreshCommentsUpdated(comments: string): IRootViewModel {
        const vm = this._clone();
        vm.user.comments = comments;
        vm.isDirty = true;
        return vm;
    }

    // private implementation
    // ----------------------
    _clone(): RootViewModel {
        const vm = clone(this);

        vm.reference = clone(this.reference);
        vm.user = cloneDeep(this.user);
        vm.service = clone(this.service);
        vm.viewState = clone(this.viewState);

        return vm;
    }
}

// private
function updateFlags(vm: IRootViewModel): void {
    const questions = vm.user.questions;
    vm.viewState = {
        showExistingJointAuthorityNewAdministratorWizard: false,
        showExistingJointAuthorityExistingAdministratorWizard: false,
        // showJointAuthoritySignerWizard: false,
        showRequestForm: false,
    };

    // existing JointAuthority selected
    if (questions.selectedJointAuthority) {
        if (questions.selectedJointAuthority.hasJointAuthorityAdministrator) {
            vm.viewState.showExistingJointAuthorityExistingAdministratorWizard = true;
        } else {
            vm.viewState.showExistingJointAuthorityNewAdministratorWizard = true;
        }
    } else return;

    // break if no JAA selection has been made
    if (questions.isJointAuthorityAdministratorRequest === undefined) return;
    // JAS selection is only applicable if not JAA request
    if (questions.isJointAuthorityAdministratorRequest === false) {
        // vm.viewState.showJointAuthoritySignerWizard = true;
    }
    // break if no JAS selection has been made
    // if (questions.isJointAuthoritySignerRequest === undefined) return;

    // reveal form
    vm.viewState.showRequestForm = true;
}
