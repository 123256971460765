// redux
import { call, put, select } from "redux-saga/effects";
import { IGeoscienceAustraliaReduxState } from "../../../../geoscienceAustraliaReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/rootViewModel";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

interface IResponse {
    statusMessages: Client.StatusMessagesDto;
}

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_UserManagement_RequestDetails;

export default function* approveRejectRequestGroup() {
    let vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        let saveResult: CallHelper.CallResult<IResponse>;

        switch (vm.summary.type) {
            case Client.SecureRequestGroupTypeEnum.GAR:
                saveResult = yield call(async () => await approveRejectGar(vm));
                break;
            case Client.SecureRequestGroupTypeEnum.NU_GAR:
                saveResult = yield call(async () => await approveRejectNuGar(vm));
                break;
            default:
                throw new Error("Unsupported Request Type.");
        }

        if (saveResult.IsSuccess) {
            // success
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshApproveRejectRequestGroupResponse(saveResult.Response!.statusMessages)));
        } else if (saveResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            saveResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function approveRejectGar(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createGeoscienceAustraliaUserManagementClient();

    if (vm.geoscienceAustraliaStandardRoleDetails.isApproved === true) {
        const approveGarRequest = new Client.ApproveGeoscienceAustraliaUserManagementRequestDetailsGarRequestDto({
            secureRequestGroupId: vm.id!,
            versionNumber: vm.versionNumber!,
            rolesApproved: vm.geoscienceAustraliaStandardRoleDetails.grantedRoles!.getDirectRoles(),
        });

        return await CallHelper.call(() => client.approveGeoscienceAustraliaUserManagementRequestDetailsGar(approveGarRequest));
    } else {
        const rejectGarRequest = new Client.RejectGeoscienceAustraliaUserManagementRequestDetailsGarRequestDto({
            secureRequestGroupId: vm.id!,
            versionNumber: vm.versionNumber!,
            comments: vm.rejectionDetails.comments,
            isSuspicious: vm.rejectionDetails.isSuspicious!,
        });

        return await CallHelper.call(() => client.rejectGeoscienceAustraliaUserManagementRequestDetailsGar(rejectGarRequest));
    }
}

async function approveRejectNuGar(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createGeoscienceAustraliaUserManagementClient();

    if (vm.geoscienceAustraliaStandardRoleDetails.isApproved === true) {
        const approveNuGarRequest = new Client.ApproveGeoscienceAustraliaUserManagementRequestDetailsNuGarRequestDto({
            secureRequestGroupId: vm.id!,
            versionNumber: vm.versionNumber!,
            rolesApproved: vm.geoscienceAustraliaStandardRoleDetails.grantedRoles!.getDirectRoles(),
        });

        return await CallHelper.call(() => client.approveGeoscienceAustraliaUserManagementRequestDetailsNuGar(approveNuGarRequest));
    } else {
        const rejectNuGarRequest = new Client.RejectGeoscienceAustraliaUserManagementRequestDetailsNuGarRequestDto({
            secureRequestGroupId: vm.id!,
            versionNumber: vm.versionNumber!,
            comments: vm.rejectionDetails.comments,
            isSuspicious: vm.rejectionDetails.isSuspicious!,
        });

        return await CallHelper.call(() => client.rejectGeoscienceAustraliaUserManagementRequestDetailsNuGar(rejectNuGarRequest));
    }
}
