// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams, Redirect } from "react-router-dom";
import StatusMessagesAlertsView from "../../../../common/alerts/StatusMessagesAlertsView";
import ConflictView from "../../../../common/alerts/ConflictAlertView";
import DirtyScope from "../../../../common/shell/DirtyScopeController";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/rootViewModel";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
// views
import UserDetailsView from "./views/UserDetailsView";
import RemoveUserView from "./views/RemoveUserView";
import UserPermissionsView from "./views/UserPermissionsView";
import UserPermissionsEditView from "./views/UserPermissionsEditView";
// other
import * as companyRouteHelper from "../../../companyRouteHelper";

interface IUserDetailsControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}
interface IUserDetailsRouteParameters {
    id: string;
}

function UserDetailsController(props: IUserDetailsControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    // route parameters
    const params = useParams<IUserDetailsRouteParameters>();
    const secureUserId = parseInt(params.id);

    // on mount
    useEffect(() => {
        props.initialiseRequested(secureUserId);
        return function () {
            props.clearRequested();
        };
    }, [secureUserId]); // eslint-disable-line react-hooks/exhaustive-deps

    if (vm.viewState === Models.ViewStateEnum.Removed) {
        return <Redirect to={companyRouteHelper.userList()} />;
    }

    return (
        <BodyLayout
            title="Manage User Access"
            selectedLhsNavItem={Level1ItemTypeEnum.Company_UserManagement_ViewUserList}
            onReloadRequested={() => {
                props.initialiseRequested(secureUserId);
            }}
        >
            <ConflictView isConflict={vm.isConflict} />
            <StatusMessagesAlertsView statusMessages={vm.service.statusMessages} />
            <UserDetailsView vm={vm.details} />
            {vm.viewState === Models.ViewStateEnum.View && vm.details.relationshipExpirationDatetime && <RemoveUserView vm={vm.details} actions={actions} />}
            {vm.viewState === Models.ViewStateEnum.View && <UserPermissionsView vm={vm} actions={actions} />}
            {vm.viewState === Models.ViewStateEnum.Edit && (
                <DirtyScope scopeName="company/userManagement/userDetails" isDirty={vm.isDirty}>
                    <UserPermissionsEditView vm={vm} actions={actions} />
                </DirtyScope>
            )}
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_UserManagement_UserDetails }), Actions.actionFactory)(UserDetailsController);
