import { useEffect, useState } from "react";
// kendo
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
// common
import * as GlobalHelpers from "../GlobalHelpers";

type YesNoDropDownListProps = {
    id: string;
    value: boolean | undefined;
    onChange: (value: boolean | undefined) => void;
    onBlur?: () => void;
    className?: string;
    valid?: boolean;
    /** Optional - Defines the values displayed in the drop down for the 'true' value. Defaults to 'Yes' when not specified */
    trueValue?: string;
    /** Optional - Defines the values displayed in the drop down for the 'false' value. Defaults to 'No' when not specified */
    falseValue?: string;
    /** Optional - When allowNulls is set to True, then a blank entry is added to the options. Defaults to False when not specified */
    allowNulls?: boolean;
};

export default function YesNoDropDownListView(props: YesNoDropDownListProps) {
    const { id, className, valid, value, onChange, onBlur } = props;

    const trueValue = props.trueValue ?? "Yes";
    const falseValue = props.falseValue ?? "No";
    const blankValue = "";
    const allowNulls = props.allowNulls ?? false;

    let data = [falseValue, trueValue].sort(); // sort the data array so that when a user overrides the true and false values, they are still presented in alphanumeric order

    if (allowNulls) data = [blankValue, ...data]; // add the blank value in front of the sorted list of true & false values

    const [selectedItem, setSelectedItem] = useState(GlobalHelpers.convertBooleanToStringValue(value, trueValue, falseValue, null));

    // update selected item if changed - must be set to null in order to programatically clear the dropdown
    useEffect(() => {
        setSelectedItem(GlobalHelpers.convertBooleanToStringValue(value, trueValue, falseValue, null));
    }, [value, trueValue, falseValue]);

    // on change - update state and viewModel
    function onDropDownListChange(event: DropDownListChangeEvent) {
        setSelectedItem(event.value);
        if (event.value === trueValue) onChange(true);
        if (event.value === falseValue) onChange(false);
        if (event.value === blankValue) onChange(undefined);
    }

    return <DropDownList id={id} className={className} valid={valid} data={data} value={selectedItem} onChange={onDropDownListChange} onBlur={onBlur} />;
}
