// framework
import { useEffect, useState } from "react";
// redux
import * as Models from "../../models/models";
// common
import { ValidationModel } from "../../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../../common/validation/ValidationMessageView";
import MaskedTextBoxView from "../../../../../../common/maskedTextbox/MaskedTextboxView";
import * as Forms from "../../../../../../common/forms/BootstrapForms";

export default function CoCnDetailsView(props: { vm: Models.ICoCnDetailsViewModel; v: ValidationModel; updateViewModel: (vm: Models.ICoCnDetailsViewModel) => void }) {
    const vm = props.vm;
    const v = props.v;

    const [state, setState] = useState<Models.ICoCnDetailsViewModel>({
        currentCompanyName: "",
        acnOrArbn: "",
        isAcnOrArbnRequired: undefined,
        newCompanyName: "",
    });

    useEffect(() => {
        setState({
            currentCompanyName: vm.currentCompanyName ?? "",
            acnOrArbn: vm.acnOrArbn ?? "",
            isAcnOrArbnRequired: vm.isAcnOrArbnRequired,
            newCompanyName: vm.newCompanyName ?? "",
        });
    }, [vm]);

    // generic input change
    function onChange(values: any) {
        setState({ ...state, ...values });
    }

    function onBlur() {
        props.updateViewModel(state);
    }

    return (
        <>
            <Forms.Group>
                <Forms.ReadonlyField label="Current Company Name" id="currentCompanyNameField" value={state.currentCompanyName} />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="acnOrArbnField">ACN/ARBN</Forms.Label>
                {state.isAcnOrArbnRequired ? (
                    <MaskedTextBoxView
                        id="acnOrArbnField"
                        mask="000 000 000"
                        value={state.acnOrArbn}
                        valid={v.applyValidityForKendo("CoCnDetails.AcnOrArbn")}
                        onChange={(value) => onChange({ acnOrArbn: value })}
                        onBlur={onBlur}
                    />
                ) : (
                    <Forms.ReadonlyTextInput id="acnOrArbnField" value={state.acnOrArbn} />
                )}
                <ValidationMessageView name="CoCnDetails.AcnOrArbn" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.TextInput
                    id="newCompanyNameField"
                    label="New Company Name"
                    value={state.newCompanyName}
                    valid={v.applyValidity("CoCnDetails.NewCompanyName")}
                    onChange={(value) => onChange({ newCompanyName: value })}
                    onBlur={onBlur}
                />
                <ValidationMessageView name="CoCnDetails.NewCompanyName" validationModel={v} />
            </Forms.Group>
        </>
    );
}
