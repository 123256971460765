// api
import * as ClientFactory from "../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";

export async function getJointAuthorityOpggsApplicationDetailsApplicationDetails(id: string): Promise<Client.GetJointAuthorityOpggsApplicationDetailsApplicationDetailsResponseDto> {
    const client = await ClientFactory.createJointAuthorityOpggsApplicationDetailsClient();
    return await CallHelper.simpleCall(() =>
        client.getJointAuthorityOpggsApplicationDetailsApplicationDetails(new Client.GetJointAuthorityOpggsApplicationDetailsApplicationDetailsRequestDto({ applicationId: id }))
    );
}
