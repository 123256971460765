import * as Validation from "../../../../common/validation/ValidationModel";
import * as Model from "./models";
import { IPaymentViewModel } from "./paymentViewModel";

export default function validate(vm: Model.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    if (vm.paymentViewModel) {
        validateMakePaymentRequest(vm.paymentViewModel, vm.selectedFinancialNotifications.totalOutstandingAmount!, v);
    }
    return v;
}

function validateMakePaymentRequest(vm: IPaymentViewModel, maxAmount: number, v: Validation.ValidationModel): void {
    if (typeof vm.secureTransactionToken === "undefined") {
        v.addError("MakePayment.VerifyDetails", "The Credit Card must be verified.");
    }

    if (vm.comments && vm.comments.length > 1000) {
        v.addError("Comments.Comments", "Comments must be 1000 characters or less.");
    }
}
