// framework
import { useState } from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// redux
import * as Models from "../../../models/models";
import * as Actions from "../../../redux/actions";
// api
import { GetCompanyOpggsDraftApplicationDetailsDigitalSignatureCompanySignatureDto } from "../../../../../../../../api/Client";
// common
import * as GlobalHelpers from "../../../../../../../../common/GlobalHelpers";

export default function DigitalSignaturesGridView(props: { vm: Models.IDigitalSignatureViewModel; actions: Actions.IActionFactory }): React.ReactElement {
    const vm = props.vm;

    const companySignaturesSummary = vm.digitalSignatureDetails?.companySignatures ?? new Array<GetCompanyOpggsDraftApplicationDetailsDigitalSignatureCompanySignatureDto>();

    const initialGridState = {
        sort: [{ field: "companyName", dir: "asc" }],
    } as State;

    const [gridState, setGridState] = useState(initialGridState);

    return (
        <>
            <Grid
                className="mb-2"
                data={process(companySignaturesSummary, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn field="companyName" title="Applicant" width={350} />
                <GridColumn field="status" title="Progress" width={300} />
                <GridColumn field="signedSignatureTypes" title="Signed By" width={300} />
                <GridColumn field="lastSignedDatetime" title="Date (AWST)" width={200} format={GlobalHelpers.NoptaDatetimeFormatForKendo} />
            </Grid>
        </>
    );
}
