// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../../api/Client";

export interface IReferenceDataViewModel {
    validYears: Array<Client.DraftApplicationWorkProgramYearDto>;
    activityList: Array<string>;
    units: Array<Client.DraftApplicationWorkProgramUnitDto>;
}

export interface IRootViewModel {
    id: number | undefined;
    versionNumber: number | undefined;
    // Note: because we are not using inline grid editing for activities, there is no need to map the collection of activity dtos to a model and back.
    activities: Array<Client.DraftApplicationWorkProgramActivityDto>;
    isComplete: boolean | undefined;

    referenceData: IReferenceDataViewModel;

    viewState: ViewStateEnum;
    isDirty: boolean;
    isConflict: boolean;
    statusMessages: Client.StatusMessagesDto | undefined;
    audit: Client.SecurePortalSimpleAuditEventsDto | undefined;

    refreshDetails(id: number, response: Client.GetCompanyOpggsDraftApplicationDetailsWorkProgramResponseDto): IRootViewModel;
    refreshSaveStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel;
    refreshConflict(): IRootViewModel;
    onInitialised(): IRootViewModel;
    onSaved(): IRootViewModel;
    onEdit(): IRootViewModel;
    onActivityAdded(activity: IActivityViewModel): IRootViewModel;
    onActivityUpdated(activity: IActivityViewModel): IRootViewModel;
    onActivityRemoved(key: number): IRootViewModel;
}

export interface IActivityViewModel {
    key: number | undefined;
    year: Client.DraftApplicationWorkProgramYearDto | undefined;
    quantity: number | undefined;
    unit: Client.DraftApplicationWorkProgramUnitDto | undefined;
    activity: string | undefined;
    description: string | undefined;
    indicativeExpenditure: number | undefined;
}

export enum ViewStateEnum {
    Initialising,
    View,
    Edit,
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.id = undefined;
        this.versionNumber = undefined;
        this.activities = new Array<Client.DraftApplicationWorkProgramActivityDto>();
        this.isComplete = false;
        this.referenceData = {
            validYears: new Array<Client.DraftApplicationWorkProgramYearDto>(),
            activityList: new Array<string>(),
            units: new Array<Client.DraftApplicationWorkProgramUnitDto>(),
        };
        this.viewState = ViewStateEnum.Initialising;
        this.isDirty = false;
        this.isConflict = false;
        this.statusMessages = undefined;
        this.audit = undefined;
    }

    public id: number | undefined;
    public versionNumber: number | undefined;

    public activities: Array<Client.DraftApplicationWorkProgramActivityDto>;
    public isComplete: boolean | undefined;

    public referenceData: IReferenceDataViewModel;

    public viewState: ViewStateEnum;
    public isDirty: boolean;
    public isConflict: boolean;
    public statusMessages: Client.StatusMessagesDto | undefined;
    public audit: Client.SecurePortalSimpleAuditEventsDto | undefined;

    public refreshDetails(id: number, response: Client.GetCompanyOpggsDraftApplicationDetailsWorkProgramResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.id = id;
        vm.versionNumber = response.versionNumber;
        vm.activities = response.activities;
        vm.isComplete = response.isComplete;

        // Note: filtering valid years by work program type and ordering of reference data is a service responsibility.
        vm.referenceData.validYears = response.validYears;
        vm.referenceData.activityList = response.activityList;
        vm.referenceData.units = response.units;

        vm.isDirty = false;
        vm.isConflict = false;
        vm.audit = response.audit;

        return vm;
    }

    public refreshSaveStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.statusMessages = statusMessages;
        if (statusMessages.isSuccess) {
            vm.isDirty = false;
        }

        return vm;
    }

    public refreshConflict(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.statusMessages = undefined;
        vm.isConflict = true; // this is only reset on initialise from the reducer

        return vm;
    }

    public onInitialised(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Initialising) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    public onSaved(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    public onEdit(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.View) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.Edit;
        vm.isDirty = false;
        vm.statusMessages = undefined;

        return vm;
    }

    // Note: this all works fine. I would like to make it smarter (maybe an IActivitiesViewModel that encapsulates generation of new order etc.) but will wait until it all settles down.
    public onActivityAdded(activity: IActivityViewModel): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");
        if (activity.key !== undefined) throw new Error("A new Activity must not have a key.");

        const vm = this._clone();

        // new activity key is one more than the max existing activity key
        let newOrder = 1;
        if (vm.activities.length > 0) newOrder = Math.max(...vm.activities.map((a) => a.key)) + 1;

        vm.isDirty = true;
        vm.activities = [
            ...vm.activities,
            new Client.DraftApplicationWorkProgramActivityDto({
                key: newOrder,
                yearTypeId: activity.year!.yearTypeId,
                quantity: activity.quantity,
                unitId: activity.unit?.id,
                activity: activity.activity,
                description: activity.description,
                indicativeExpenditure: activity.indicativeExpenditure,
            }),
        ];

        return vm;
    }

    // Note: this all works fine. I would like to make it smarter (maybe an IActivitiesViewModel that encapsualtes editing of existing activity) but will wait until it all settles down.
    public onActivityUpdated(activity: IActivityViewModel): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");
        if (activity.key === undefined) throw new Error("An existing Activity must have a key.");

        const vm = this._clone();

        vm.isDirty = true;
        vm.activities = vm.activities.map((a) =>
            a.key === activity.key!
                ? new Client.DraftApplicationWorkProgramActivityDto({
                      key: activity.key,
                      yearTypeId: activity.year!.yearTypeId,
                      quantity: activity.quantity,
                      unitId: activity.unit?.id,
                      activity: activity.activity,
                      description: activity.description,
                      indicativeExpenditure: activity.indicativeExpenditure,
                  })
                : a
        );

        return vm;
    }

    public onActivityRemoved(activityKey: number): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();

        vm.isDirty = true;
        vm.activities = vm.activities.filter((a) => a.key !== activityKey);

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.referenceData = clone(this.referenceData);
        return vm;
    }
}
