// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../api/Client";

export interface IRootViewModel {
    rig: Client.GetGeoscienceAustraliaWellsBoreholesRigDetailsResponseDto | undefined;

    refreshRigDetails(response: Client.GetGeoscienceAustraliaWellsBoreholesRigDetailsResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.rig = undefined;
    }

    public rig: Client.GetGeoscienceAustraliaWellsBoreholesRigDetailsResponseDto | undefined;

    public refreshRigDetails(response: Client.GetGeoscienceAustraliaWellsBoreholesRigDetailsResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.rig = response;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.rig = clone(this.rig);
        return vm;
    }
}
