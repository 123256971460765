// framework
import { useState, useEffect } from "react";
// kendo
import { MultiSelect, MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
// redux
import * as Models from "../../models/models";
import * as Actions from "../../redux/actions";
// api
import * as Client from "../../../../../../../api/Client";
// common
import { IValidationModel } from "../../../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../../../common/validation/ValidationMessageView";
import * as Forms from "../../../../../../../common/forms/BootstrapForms";

export default function SuppliedOfflineView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; v: IValidationModel; isBusy: boolean }): React.ReactElement {
    const vm = props.vm;
    const actions = props.actions;
    const v = props.v;
    const offlineDetails = vm.suppliedOfflineDetails;

    const [state, setState] = useState<Models.ISuppliedOfflineDetailsViewModel>({
        isSuppliedOffline: false,
        comments: "",
        selectedCompanies: new Array<Client.GetCompanyOpggsDraftApplicationDetailsSupportingDocumentationPartnerCompanyDto>(),
    });

    useEffect(() => {
        setState({
            isSuppliedOffline: offlineDetails.isSuppliedOffline ?? false,
            comments: offlineDetails.comments ?? "",
            selectedCompanies: offlineDetails.selectedCompanies,
        });
    }, [offlineDetails]);

    function onChange(values: Models.ISuppliedOfflineDetailsViewModel) {
        if (props.isBusy) return;
        if (vm.viewState === Models.ViewStateEnum.View) return;

        const updatedState = { ...state, ...values };

        setState(updatedState);
        actions.viewChanged(vm.onSuppliedOfflineDetailsChanged(updatedState));
    }

    // multiselect change
    function onMultiselectChange(e: MultiSelectChangeEvent) {
        if (props.isBusy) return;
        if (vm.viewState === Models.ViewStateEnum.View) return;

        const updatedState = { ...state, selectedCompanies: e.value };

        setState(updatedState);
        actions.viewChanged(vm.onSuppliedOfflineDetailsChanged(updatedState));
    }

    // disabled if control is busy or in view mode
    const disabled = props.isBusy || vm.viewState === Models.ViewStateEnum.View;

    return (
        <>
            <Forms.Group>
                <Forms.Checkbox
                    id="isSuppliedOfflineField"
                    label="Additional supporting document has been sent to NOPTA separately"
                    checked={state.isSuppliedOffline}
                    onChange={(checked) => onChange({ isSuppliedOffline: checked })}
                    disabled={disabled}
                />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="companyIdsField">Companies providing supporting documentation separately</Forms.Label>
                <MultiSelect
                    id="companyIdsField"
                    valid={v.applyValidityForKendo("SuppliedOfflineDetails.CompanyIds")}
                    data={vm.partnerCompanies}
                    value={state.selectedCompanies}
                    dataItemKey="companyId"
                    textField="companyName"
                    onChange={onMultiselectChange}
                    disabled={disabled || state.isSuppliedOffline !== true}
                />
                <ValidationMessageView name="SuppliedOfflineDetails.CompanyIds" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.TextArea
                    id="commentsField"
                    label="Additional information for supporting documentation sent separately"
                    valid={v.applyValidity("SuppliedOfflineDetails.Comments")}
                    value={state.comments}
                    rows={5}
                    maxLength={1000}
                    onChange={(value) => onChange({ comments: value })}
                    disabled={disabled || state.isSuppliedOffline !== true}
                />
                <ValidationMessageView name="SuppliedOfflineDetails.Comments" validationModel={v} />
            </Forms.Group>
        </>
    );
}
