// framework
import { Link } from "react-router-dom";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
// common
import * as Forms from "../../../../../common/forms/BootstrapForms";
import * as jointAuthorityRouteHelper from "../../../../jointAuthorityRouteHelper";

export function RequestSubmittedView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const actions = props.actions;
    const questions = vm.user.questions;
    const secureRequestGroupId = vm.service.id;

    // provide a hyperlink to the created request for a JAA of the selected joint authority requesting JAS access
    const isJaaRequestingJas = questions.selectedJointAuthority?.isJointAuthorityAdministrator === true; // todo nduja && questions.isJointAuthoritySignerRequest === true;

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <Forms.Group>
                        {isJaaRequestingJas && secureRequestGroupId ? (
                            <>
                                <p>
                                    Your request has been submitted (Reference:{" "}
                                    {<Link to={jointAuthorityRouteHelper.requestDetails(secureRequestGroupId.id, secureRequestGroupId.type)}>{secureRequestGroupId.code}</Link>}).
                                </p>
                                <p>Click on the link to approve your request.</p>
                            </>
                        ) : (
                            <p>Your request has been submitted (Reference: {secureRequestGroupId?.code}).</p>
                        )}
                    </Forms.Group>
                </div>
            </div>
            <button className="btn btn-outline-primary" type="button" onClick={() => actions.initialiseRequested()}>
                Request Additional Access
            </button>
        </>
    );
}
