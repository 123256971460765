// framework
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
import { ValidationVisibilityEnum } from "../../../../../common/validation/ValidationModel";
// kendo
import { GridColumn } from "@progress/kendo-react-grid";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/rootViewModel";
import { ICompanyAdministratorRequestViewModel } from "../../models/companyAdministratorRequestViewModel";
import validator from "../../models/validator";
// common
import CustomGridCell from "../../../../../common/kendoGrid/CustomGridCell";
import RoleGrid, { RoleGridModeEnum } from "../../../../../common/roleGrid/RoleGrid";
import ConfirmationView, { DefaultButtonEnum } from "../../../../../common/confirmation/ConfirmationView";
import RequestLinkController from "../../../../../common/links/SecureRequestGroupLinkController";
import * as Forms from "../../../../../common/forms/BootstrapForms";
import * as companyRouteHelper from "../../../../companyRouteHelper";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import * as toastHelper from "../../../../../common/toastHelper";
import * as BadgeHelper from "../../../../../common/badges/BadgeHelper";
import * as AlertsHelper from "../../../../../common/alerts/AlertsHelper";

export default function CompanyAdministratorRequestListView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }): React.ReactElement {
    const vm = props.vm;
    const ca = vm.companyAdministrator;
    const actions = props.actions;

    // validation
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const v = validator(vm, validationVisibility);

    // request decision state
    const [state, setState] = useState<Models.ICompanyAdministratorRequestsDecisionViewModel>({
        isSuspicious: ca.isSuspicious,
        comments: ca.comments,
    });

    useEffect(() => {
        setState({ isSuspicious: ca.isSuspicious, comments: ca.comments });
    }, [ca]);

    function hasRequests() {
        return ca.requests.length > 0;
    }

    function hasSelections() {
        return ca.requests.some((r) => r.isSelected);
    }

    function hasSelectedSignerRequests() {
        return ca.requests.some((r) => r.isSignerRequest && r.isSelected);
    }

    function hasSignerRequests() {
        return ca.requests.some((r) => r.isSignerRequest);
    }

    function updateViewModel(updatedRoles: Array<ICompanyAdministratorRequestViewModel>): void {
        actions.viewChanged(vm.refreshSelectionChanged(updatedRoles));
    }

    function batchApproveEnabled() {
        if (!hasSelections()) return { disabled: true, title: "No requests have been selected." };

        if (hasSelectedSignerRequests()) return { disabled: true, title: "Company Signer requests cannot be batch approved." };

        return {};
    }

    // batch reject confirmation
    const [showBatchRejectConfirmation, setShowBatchRejectConfirmation] = useState(false);

    // batch approve confirmation
    const [showBatchApproveConfirmation, setShowBatchApproveConfirmation] = useState(false);

    // popup input change - update state and viewModel
    function onPopupInputChanged(values: any) {
        const updatedState = { ...state, ...values };
        setState(updatedState);
        actions.viewChanged(vm.refreshDecisionDetailsChanged(updatedState));
    }

    // submission
    function onBatchRejectRequested() {
        // validation
        // - validation is hidden until the user attempts the action... it then stays visible until they have successfully completed the action
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);

        // submission
        actions.batchRejectRequested();
    }

    // view
    return (
        <>
            <div className="card my-2">
                <div className="card-body">
                    {hasSignerRequests() && AlertsHelper.renderInfo(["Company Signer requests cannot be batch approved."])}
                    <h2 className="card-title">Manage Requests</h2>
                    {hasRequests() && (
                        <p className="card-text">
                            The following requests are awaiting your approval.{ca.isRequestLimitExceeded && <span className="text-danger"> Note: Requests are limited to 20.</span>}
                        </p>
                    )}
                    <RoleGrid
                        model={ca.requests}
                        roleGroups={vm.roleConfiguration.roleGroups}
                        mode={RoleGridModeEnum.Selectable}
                        onRolesChange={updateViewModel}
                        showSigner
                        preColumns={[
                            <GridColumn
                                key="requestLink"
                                field="requestLink.code"
                                title="Request ID"
                                width={200}
                                cell={(props) => (
                                    <CustomGridCell gridCellProps={props}>
                                        <RequestLinkController linkDto={props.dataItem.requestLink} secureEntityType="Company" />
                                    </CustomGridCell>
                                )}
                            />,
                            <GridColumn key="requestDatetime" field="requestDatetime" title="Request Date (AWST)" width={200} format={GlobalHelpers.NoptaDatetimeFormatForKendo} />,
                            <GridColumn
                                key="companyName"
                                field="companyName"
                                title="Company"
                                width={300}
                                cell={(props) => (
                                    <CustomGridCell gridCellProps={props}>
                                        <Link to={companyRouteHelper.companyDetails(props.dataItem.companyId)}>{props.dataItem.companyName}</Link>
                                    </CustomGridCell>
                                )}
                            />,
                            <GridColumn
                                key="secureUserDisplayName"
                                field="secureUserDisplayName"
                                title="Full Name"
                                width={200}
                                cell={(props) => (
                                    <CustomGridCell gridCellProps={props}>
                                        {props.dataItem.secureUserId ? (
                                            <Link to={companyRouteHelper.userDetails(props.dataItem.secureUserId)}>{props.dataItem.secureUserDisplayName}</Link>
                                        ) : (
                                            props.dataItem.secureUserDisplayName
                                        )}
                                    </CustomGridCell>
                                )}
                            />,
                        ]}
                        postColumns={
                            <GridColumn
                                key="badgeSortOrder"
                                field="badgeSortOrder"
                                title="Information"
                                width={160}
                                cell={(props) => (
                                    <CustomGridCell gridCellProps={props}>
                                        {props.dataItem.isSecureUserBlocked && BadgeHelper.getBlockedUserBadge()}
                                        {props.dataItem.isCompanyRevokedButVisible && BadgeHelper.getCompanyThirtyDayWarningBadge()}
                                    </CustomGridCell>
                                )}
                            />
                        }
                    />
                </div>
            </div>
            {hasRequests() && (
                <>
                    <button
                        className="btn btn-outline-primary me-2"
                        type="button"
                        onClick={() => setShowBatchRejectConfirmation(true)}
                        {...(hasSelections() ? {} : { disabled: true, title: "No requests have been selected." })}
                    >
                        Batch Reject
                    </button>
                    <button className="btn btn-outline-primary me-2" type="button" onClick={() => setShowBatchApproveConfirmation(true)} {...batchApproveEnabled()}>
                        Batch Approve
                    </button>
                </>
            )}
            {showBatchRejectConfirmation && (
                <ConfirmationView
                    title="Batch Reject"
                    onAccepted={() => {
                        setShowBatchRejectConfirmation(false);
                        onBatchRejectRequested();
                    }}
                    onClosed={() => setShowBatchRejectConfirmation(false)}
                    defaultButton={DefaultButtonEnum.Cancel}
                >
                    <Forms.Group>
                        <div>This will reject the selected requests and remove them from this list.</div>
                        <div>Do you wish to proceed?</div>
                    </Forms.Group>
                    <Forms.Group>
                        <Forms.TextArea
                            id="reasonField"
                            label="Reason"
                            rows={5}
                            maxLength={1000}
                            value={state.comments}
                            valid={v.applyValidity("CompanyAdministratorRequestList.Comments")}
                            onChange={(value) => onPopupInputChanged({ comments: value })}
                        />
                        <ValidationMessageView name="CompanyAdministratorRequestList.Comments" validationModel={v} />
                    </Forms.Group>
                    <Forms.Group>
                        <Forms.Checkbox
                            id="isSuspiciousField"
                            label="This request appears to be suspicious"
                            checked={state.isSuspicious}
                            onChange={(checked) => onPopupInputChanged({ isSuspicious: checked })}
                            inline
                        />
                    </Forms.Group>
                </ConfirmationView>
            )}
            {showBatchApproveConfirmation && (
                <ConfirmationView
                    title="Batch Approve"
                    onAccepted={() => {
                        setShowBatchApproveConfirmation(false);
                        actions.batchApproveRequested();
                    }}
                    onClosed={() => setShowBatchApproveConfirmation(false)}
                    defaultButton={DefaultButtonEnum.Cancel}
                >
                    <Forms.Group>
                        <div>This will approve the selected requests and remove them from this list.</div>
                        <div>Do you wish to proceed?</div>
                    </Forms.Group>
                </ConfirmationView>
            )}
        </>
    );
}
