// framework
import { call, put, select } from "redux-saga/effects";
// redux
import { IGeoscienceAustraliaReduxState } from "../../../../geoscienceAustraliaReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/rootViewModel";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";
import * as ConfigurationHelper from "../../../../../common/shell/configurationHelper";

interface IResponse {
    summary: Client.GeoscienceAustraliaUserManagementRequestDetailsSummaryDto;
    newUserDetails?: Client.GeoscienceAustraliaUserManagementRequestDetailsNewUserDetailsDto;
    existingUserDetails?: Client.GeoscienceAustraliaUserManagementRequestDetailsExistingUserDetailsDto;
    geoscienceAustraliaStandardRoleDetails?: Client.GeoscienceAustraliaUserManagementRequestDetailsStandardRoleDetailsDto;
}

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_UserManagement_RequestDetails;

export default function* initialise(action: Actions.IAction<Actions.IRequestDetailsKeyPayload>) {
    const requestDetailsKey = action.payload;

    // save role reference data to the vm
    let vm: Models.IRootViewModel = yield select(getRootViewModel);
    vm = vm.refreshRoleConfiguration(yield select(ConfigurationHelper.getRoleConfiguration));
    yield put(Actions.actionFactory.sagaCompleted(vm));

    // load request details
    try {
        yield put(shellHelper.createSetBusyAction());

        const response: IResponse = yield call(getRequestDetails, requestDetailsKey.id, requestDetailsKey.type);
        yield put(
            Actions.actionFactory.sagaCompleted(
                vm.refreshRequestDetails(response.summary, response.newUserDetails, response.existingUserDetails, response.geoscienceAustraliaStandardRoleDetails) // todo nduja replace null with response.geoscienceAustraliaSignerDetails
            )
        );
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

export async function getRequestDetails(secureRequestGroupId: number, secureRequestGroupType: Client.SecureRequestGroupTypeEnum): Promise<IResponse> {
    const client = await ClientFactory.createGeoscienceAustraliaUserManagementClient();

    switch (secureRequestGroupType) {
        case Client.SecureRequestGroupTypeEnum.GAR:
            const garRequest = new Client.GetGeoscienceAustraliaUserManagementRequestDetailsGarRequestDto({ secureRequestGroupId: secureRequestGroupId });
            return await CallHelper.simpleCall(() => client.getGeoscienceAustraliaUserManagementRequestDetailsGar(garRequest));
        case Client.SecureRequestGroupTypeEnum.NU_GAR:
            const nuGarRequest = new Client.GetGeoscienceAustraliaUserManagementRequestDetailsNuGarRequestDto({ secureRequestGroupId: secureRequestGroupId });
            return await CallHelper.simpleCall(() => client.getGeoscienceAustraliaUserManagementRequestDetailsNuGar(nuGarRequest));
        default:
            throw new Error("Unsupported Request Type.");
    }
}
