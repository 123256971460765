import React from 'react';
import Bowser from "bowser";
import SimpleAlertControl from "../../../../common/simpleAlerts/SimpleAlertView";

// https://www.npmjs.com/package/bowser

// see https://dev.azure.com/nopta/Custom/_wiki/wikis/Custom.wiki/1040/Browser-Compatibility

export default function SupportedBrowserBannerView(): React.ReactElement {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isSupportedBrowser = browser.satisfies({
        chrome: ">=72",
        opera: ">=58",
        safari: ">=13",
        edge: ">=80",
        firefox: ">=65",
    });

    if (isSupportedBrowser) {
        return (<></>);
    }

    return (
        <div className="m-1">
            <SimpleAlertControl message="You are using an unsupported browser. To ensure the best security and user experience, please update your web browser. NOPTA recommends using the latest versions of Chrome, Edge, Firefox, Opera and Safari." alertType="warning" />
        </div>
    );
}
