import * as Client from "../../../../api/Client";
import { clone } from "lodash";

export interface IRootViewModel {
    showNoAssociatedJointAuthoritiesTile: boolean;
    showApplicationsTile: boolean;
    showTitlesTile: boolean;

    applications: applicationsType;
    titles: titlesType;

    refreshSaga(response: Client.GetJointAuthorityHomeDashboardResponseDto): IRootViewModel;
}

export type applicationsType = Array<Client.JointAuthorityApplicationListItemDto> | undefined;
export type titlesType = Array<Client.JointAuthorityTitleListItemDto> | undefined;

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.showNoAssociatedJointAuthoritiesTile = false;
        this.showApplicationsTile = false;
        this.showTitlesTile = false;

        this.applications = undefined;
        this.titles = undefined;
    }

    public showNoAssociatedJointAuthoritiesTile: boolean;
    public showApplicationsTile: boolean;
    public showTitlesTile: boolean;

    public applications: applicationsType;
    public titles: titlesType;

    public refreshSaga(response: Client.GetJointAuthorityHomeDashboardResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.showNoAssociatedJointAuthoritiesTile = !response.hasAssociatedJointAuthorities;
        vm.showApplicationsTile = response.isApplicationsTileVisible;
        vm.showTitlesTile = response.isTitlesTileVisible;

        vm.applications = response.applications;
        vm.titles = response.titles;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
