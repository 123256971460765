import { Redirect } from "react-router";
// components
import SignInController from "./signIn/SignInController";
import RegistrationController from "./registration/RegistrationController";
// common
import { IRouteItem, RouteBuilder } from "../infrastructure/routeItem";

const routeBuilder: RouteBuilder = (isSignedIn: boolean) => {
    let routes = new Array<IRouteItem>();

    // new user registration
    if (!isSignedIn) routes.push({ path: "/Home/Registration", exact: true, element: <RegistrationController /> });

    // always map the sign-in route, even if you're signed in
    // you'll typically land here immediately after signing in, and after signing out
    // when coming back from a sign-in, we'll force the page to redirect
    routes.push({ path: "/Home/SignIn", exact: true, element: <SignInController /> });
    // adding a redirect just in case anyone has bookmarked this lower level page
    routes.push({ path: "/SignIn", exact: true, render: () => <Redirect to="/Home/SignIn" /> });

    return routes;
};

export default routeBuilder;
