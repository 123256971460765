// redux
import * as Models from "./models/models";
// api
import { DraftApplicationValidationStateEnum } from "../../../../api/Client";
// common
import SupportingDocumentationControl from "../controls/supportingDocumentation/components/Controller";
import { IChecklistTemplateViewModel } from "../controls/supportingDocumentation/models/models";
import * as ViewHelpers from "../ViewHelpers";

interface IProps {
    id: number;
    versionNumber: number;
    canManage: boolean;
    validationState: DraftApplicationValidationStateEnum;
    viewState: Models.ApplicationViewStateEnum;
    inEditTask: Models.ApplicationTaskEnum | undefined;
    title: string;
    checklistTemplate: IChecklistTemplateViewModel;
    onEdit: () => void;
    onSave: () => void;
    onCancel: () => void;
    lastRefreshedDatetime: Date | undefined;
}

export default function SupportingDocumentationView(props: IProps) {
    return (
        <SupportingDocumentationControl
            id={props.id}
            versionNumber={props.versionNumber}
            cardTitle={props.title}
            checklistTemplate={props.checklistTemplate}
            showCommands={ViewHelpers.showCommands(props.validationState, props.canManage, props.viewState)}
            disableEdit={ViewHelpers.isInEdit(props.inEditTask)}
            lastRefreshedDatetime={props.lastRefreshedDatetime}
            onEdit={props.onEdit}
            onSave={props.onSave}
            onCancel={props.onCancel}
            className="mb-2"
        />
    );
}
