// models
import * as Models from "../../../../common/draftApplicationDetails/components/models/models";
// api
import * as Client from "../../../../../api/Client";

export interface IEottlrViewModel {
    extensionDate?: Date | undefined;
    isWarningPeriod?: boolean | undefined;
}

export interface IEottlrDetailsViewModel extends IEottlrViewModel {
    titleId?: string | undefined;
    titleNumber?: string | undefined;
    titleExpiryDate?: Date | undefined;
}

export interface IEottlrApplicationDetailsViewModel extends Models.IApplicationDetailsViewModel {
    eottlrDetails: IEottlrDetailsViewModel;
}

export interface IRootViewModel extends Models.IDraftApplicationRootViewModel {
    applicationDetails: IEottlrApplicationDetailsViewModel;

    // view triggered state changes
    refreshEottlrUpdated(values: IEottlrViewModel): IRootViewModel;
}

export class RootViewModel extends Models.BaseDraftApplicationRootViewModel implements IRootViewModel {
    constructor() {
        super();
        this.applicationDetails = {
            isDigitalSignatures: false,
            eottlrDetails: {},
            isDirty: false,
        };
    }

    public override applicationDetails: IEottlrApplicationDetailsViewModel;

    public override refreshDetails(response: Client.GetCompanyOpggsDraftApplicationDetailsEottlrResponseDto): this {
        const vm = super.refreshDetails(response);

        // Eottlr details
        vm.applicationDetails.eottlrDetails.titleId = response.titleId;
        vm.applicationDetails.eottlrDetails.titleNumber = response.titleNumber;
        vm.applicationDetails.eottlrDetails.titleExpiryDate = response.titleExpiryDate;
        vm.applicationDetails.eottlrDetails.extensionDate = response.extensionDate;
        vm.applicationDetails.eottlrDetails.isWarningPeriod = response.isWarningPeriod;
        vm.applicationDetails.isDirty = false;

        return vm;
    }

    public refreshEottlrUpdated(values: IEottlrViewModel): IRootViewModel {
        const vm = this._clone();

        vm.applicationDetails.eottlrDetails = { ...vm.applicationDetails.eottlrDetails, ...values };
        vm.applicationDetails.isDirty = true;

        return vm;
    }
}
