// redux
import * as Models from "../../models/models";
// api
import * as Client from "../../../../../../../api/Client";
import * as ClientFactory from "../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../api/callHelper";

export async function getCompanyCommonDraftApplicationDetailsSupportingDocumentation(id: number): Promise<Client.GetCompanyCommonDraftApplicationDetailsSupportingDocumentationResponseDto> {
    // console.info("getCompanyCommonDraftApplicationDetailsSupportingDocumentation");
    const client = await ClientFactory.createCompanyCommonDraftApplicationDetailsClient();
    return await CallHelper.simpleCall(() =>
        client.getCompanyCommonDraftApplicationDetailsSupportingDocumentation(new Client.GetCompanyCommonDraftApplicationDetailsSupportingDocumentationRequestDto({ id: id }))
    );
}

export async function saveCompanyCommonDraftApplicationDetailsSupportingDocumentation(
    vm: Models.IRootViewModel
): Promise<CallHelper.CallResult<Client.SaveCompanyCommonDraftApplicationDetailsSupportingDocumentationResponseDto>> {
    const client = await ClientFactory.createCompanyCommonDraftApplicationDetailsClient();
    return await CallHelper.call(() =>
        client.saveCompanyCommonDraftApplicationDetailsSupportingDocumentation(
            new Client.SaveCompanyCommonDraftApplicationDetailsSupportingDocumentationRequestDto({
                id: vm.id!,
                versionNumber: vm.versionNumber!,
                addedFiles: vm.files
                    .filter((f) => f.state === Models.FileStateEnum.Uploaded)
                    .map((f) => new Client.SaveCompanyCommonDraftApplicationDetailsSupportingDocumentationFileDto({ fileId: f.fileId, sensitiveToCompanyId: f.sensitiveToCompany?.companyId })),
                modifiedFiles: vm.files
                    .filter((f) => f.state === Models.FileStateEnum.Modified)
                    .map((f) => new Client.SaveCompanyCommonDraftApplicationDetailsSupportingDocumentationFileDto({ fileId: f.fileId, sensitiveToCompanyId: f.sensitiveToCompany?.companyId })),
                deletedFileIds: vm.files.filter((f) => f.state === Models.FileStateEnum.Deleted).map((f) => f.fileId),
                checklistItems: vm.checklist.map((i) => new Client.SaveCompanyCommonDraftApplicationDetailsSupportingDocumentationChecklistItemDto({ type: i.type, isChecked: i.isChecked })),
                suppliedOfflineDetails: new Client.SupportingDocumentationSuppliedOfflineDetailsDto({
                    isSuppliedOffline: vm.suppliedOfflineDetails.isSuppliedOffline!,
                    suppliedOfflineComments: vm.suppliedOfflineDetails.comments,
                    suppliedOfflineCompanyIds: vm.suppliedOfflineDetails.selectedCompanies!.map((pc) => pc.companyId),
                }),
            })
        )
    );
}
