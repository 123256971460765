// other
import TitleDetailsField from "../TitleDetailsField";
import ExternalLink from "../../../../../../common/externalLink/ExternalLinkView";

function TitleSummaryPriorUsageRightField(props: { label?: string; hasPriorUsageRight: boolean | undefined }) {
    return (
        <>
            {props.hasPriorUsageRight && (
                <TitleDetailsField>
                    <ExternalLink href="https://parksaustralia.gov.au/marine/activities/approvals/mining/">Prior Usage Right Title</ExternalLink>
                </TitleDetailsField>
            )}
        </>
    );
}

export default TitleSummaryPriorUsageRightField;
