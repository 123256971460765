// api
import * as Client from "../../../../../api/Client";
// common
import * as Forms from "../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";

export default function RequestDetailsView(props: { vm: Client.JointAuthorityUserManagementRequestDetailsSummaryDto }): React.ReactElement {
    const vm = props.vm;

    // view
    return (
        <div className="card my-2">
            <div className="card-body">
                <h2 className="card-title">Request Details</h2>
                <Forms.Row>
                    <Forms.FullColumn>
                        <Forms.ReadonlyField id="requestIdField" label="Request ID" value={vm.requestGroupCode} />
                    </Forms.FullColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="statusField" label="Status" value={vm.isComplete ? "Complete" : "Pending"} />
                    </Forms.HalfColumn>
                    <Forms.HalfColumn>
                        <Forms.ReadonlyField id="requestDateField" label="Request Date (AWST)" value={GlobalHelpers.toNoptaDatetimeString(vm.requestDatetime)} />
                    </Forms.HalfColumn>
                    <Forms.FullColumn>
                        <Forms.ReadonlyField id="jointAuthorityField" label="Joint Authority" value={vm.jointAuthorityName} />
                    </Forms.FullColumn>
                    <Forms.FullColumn>
                        <Forms.ReadonlyTextArea id="commentsField" label="Comments" value={vm.comments} />
                    </Forms.FullColumn>
                </Forms.Row>
            </div>
        </div>
    );
}
