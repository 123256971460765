// framework
import { useHistory } from "react-router-dom";
// kendo
import { orderBy } from "@progress/kendo-data-query";
// api
import * as Client from "../../../../../../api/Client";
// redux
import * as Models from "../../../models/models";
import * as Actions from "../../../redux/actions";
// views
import Form2View from "./Form2View";
import Form3View from "./Form3View";
import Form4View from "./Form4View";
import Form4NoActiveFormView from "./Form4NoActiveFormView";
import Form5View from "./Form5View";
import Form6View from "./Form6View";
import Form6NoActiveFormView from "./Form6NoActiveFormView";
import Form7View from "./Form7View";
import PendingFormRequestsView from "./PendingFormRequestsView";
// common
import * as companyRouteHelper from "../../../../../companyRouteHelper";

function LegislativeFormsView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }) {
    const details = props.vm.details;
    const actions = props.actions;
    const titleId = details?.titleId;
    const isTitleActive = details?.isTitleActive ?? false;
    const forms = details?.legislativeForms;
    const canManageLegislativeForms = forms?.canManageLegislativeForms ?? false;

    const form2Forms = forms?.form2Forms ?? new Array<Client.GetCompanyOpggsTitleDetailsLegislativeForm2Dto>();
    const sortedForm2Forms = orderBy(form2Forms, [{ field: "registeredTitleholderCompanyName", dir: "asc" }]);

    const form3Forms = forms?.form3Forms ?? new Array<Client.GetCompanyOpggsTitleDetailsLegislativeForm3Dto>();
    const sortedForm3Forms = orderBy(form3Forms, [{ field: "registeredTitleholderCompanyName", dir: "asc" }]);

    const pendingFormRequests = forms?.pendingFormRequests ?? new Array<Client.GetCompanyOpggsTitleDetailsLegislativeFormRequestDto>();

    function downloadDocument(formId: number) {
        actions.getLegislativeFormDocumentRequested(formId);
    }

    const history = useHistory();

    return (
        <>
            <h2>Active Forms</h2>
            {sortedForm2Forms.map((f) => (
                <Form2View key={f.id} form={f} downloadDocument={downloadDocument} />
            ))}
            {sortedForm3Forms.map((f) => (
                <Form3View key={f.id} form={f} downloadDocument={downloadDocument} />
            ))}
            {forms?.hasActiveForm4Form === false && <Form4NoActiveFormView />}
            {forms?.form4Form && <Form4View form={forms.form4Form} downloadDocument={downloadDocument} />}
            {forms?.form5Form && <Form5View form={forms.form5Form} downloadDocument={downloadDocument} />}
            {forms?.hasActiveForm6Form === false && <Form6NoActiveFormView />}
            {forms?.form6Form && <Form6View form={forms.form6Form} downloadDocument={downloadDocument} />}
            {forms?.form7Form && <Form7View form={forms.form7Form} downloadDocument={downloadDocument} />}
            <PendingFormRequestsView vm={pendingFormRequests} />
            {titleId && isTitleActive && canManageLegislativeForms && (
                <button className="btn btn-outline-primary" type="button" onClick={() => history.push(companyRouteHelper.legislativeFormsFormRequestCreate(titleId))}>
                    New Form Request
                </button>
            )}
        </>
    );
}

export default LegislativeFormsView;
