// redux
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";

export async function getCompanyOpggsDraftApplicationDetailsWorkProgram(id: number): Promise<Client.GetCompanyOpggsDraftApplicationDetailsWorkProgramResponseDto> {
    // console.info("getCompanyOpggsDraftApplicationDetailsWorkProgram");
    const client = await ClientFactory.createCompanyOpggsDraftApplicationDetailsClient();
    return await CallHelper.simpleCall(() => client.getCompanyOpggsDraftApplicationDetailsWorkProgram(new Client.GetCompanyOpggsDraftApplicationDetailsWorkProgramRequestDto({ id: id })));
}

export async function saveCompanyOpggsDraftApplicationDetailsWorkProgram(
    vm: Models.IRootViewModel
): Promise<CallHelper.CallResult<Client.SaveCompanyOpggsDraftApplicationDetailsWorkProgramResponseDto>> {
    const client = await ClientFactory.createCompanyOpggsDraftApplicationDetailsClient();

    return await CallHelper.call(() =>
        client.saveCompanyOpggsDraftApplicationDetailsWorkProgram(
            new Client.SaveCompanyOpggsDraftApplicationDetailsWorkProgramRequestDto({
                id: vm.id!,
                versionNumber: vm.versionNumber!,
                activities: vm.activities,
            })
        )
    );
}
