// framework
import { useEffect, useState } from "react";
// redux
import * as Models from "../../models/models";
// common
import { ValidationModel } from "../../../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../../../common/validation/ValidationMessageView";
import * as Forms from "../../../../../../../common/forms/BootstrapForms";

export default function ContactView(props: { vm: Models.IRootViewModel; v: ValidationModel; updateViewModel: (vm: Models.IApplicationContactViewModel) => void }) {
    const vm = props.vm;
    const v = props.v;

    const [state, setState] = useState<Models.IApplicationContactViewModel>({
        contactName: "",
        positionHeld: "",
        phone: "",
        email: "",
    });

    useEffect(() => {
        setState({
            contactName: vm.contact.contactName ?? "",
            positionHeld: vm.contact.positionHeld ?? "",
            phone: vm.contact.phone ?? "",
            email: vm.contact.email ?? "",
        });
    }, [vm.contact]);

    // generic input change
    function onChange(values: any) {
        setState({ ...state, ...values });
    }

    function onBlur() {
        props.updateViewModel(state);
    }

    return (
        <Forms.Row>
            <Forms.HalfColumn>
                <Forms.ReadonlyField label="Company Name" id="companyNameField" value={vm.companyName} />
            </Forms.HalfColumn>
            <Forms.HalfColumn>
                <Forms.TextInput id="phoneField" label="Phone" value={state.phone} valid={v.applyValidity("ContactDetails.Phone")} onChange={(value) => onChange({ phone: value })} onBlur={onBlur} />
                <ValidationMessageView name="ContactDetails.Phone" validationModel={v} />
            </Forms.HalfColumn>
            <Forms.HalfColumn>
                <Forms.TextInput
                    id="contactNameField"
                    label="Contact Name"
                    value={state.contactName}
                    valid={v.applyValidity("ContactDetails.ContactName")}
                    onChange={(value) => onChange({ contactName: value })}
                    onBlur={onBlur}
                />
                <ValidationMessageView name="ContactDetails.ContactName" validationModel={v} />
            </Forms.HalfColumn>
            <Forms.HalfColumn>
                <Forms.TextInput id="emailField" label="Email" value={state.email} valid={v.applyValidity("ContactDetails.Email")} onChange={(value) => onChange({ email: value })} onBlur={onBlur} />
                <ValidationMessageView name="ContactDetails.Email" validationModel={v} />
            </Forms.HalfColumn>
            <Forms.HalfColumn>
                <Forms.TextInput
                    id="positionHeldField"
                    label="Position Held"
                    value={state.positionHeld}
                    valid={v.applyValidity("ContactDetails.PositionHeld")}
                    onChange={(value) => onChange({ positionHeld: value })}
                    onBlur={onBlur}
                />
                <ValidationMessageView name="ContactDetails.PositionHeld" validationModel={v} />
            </Forms.HalfColumn>
        </Forms.Row>
    );
}
