// kendo
import { GridColumn } from "@progress/kendo-react-grid";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
import { IRequestAccessRoleViewModel } from "../../models/requestAccessRoleViewModel";
// common
import { ValidationModel } from "../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
import RoleGrid, { RoleGridModeEnum } from "../../../../../common/roleGrid/RoleGrid";
import { RoleCategoryEnum } from "../../../../../common/roleGrid/RoleViewModel";
import * as Forms from "../../../../../common/forms/BootstrapForms";

export function CompanyStandardRoleRequestView(props: { vm: Models.IRootViewModel; v: ValidationModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const request = vm.user.companyStandardRoleRequest;
    const v = props.v;
    const actions = props.actions;

    // grid data
    function updateViewModel(updatedRole: IRequestAccessRoleViewModel): void {
        if (updatedRole.getKey() !== RoleCategoryEnum.Requested) throw new Error("Invalid field update.");
        actions.viewChanged(vm.refreshCompanyStandardRoleRequestUpdated(updatedRole));
    }

    return (
        <div className="card mb-2">
            <div className="card-header">General Permissions Request</div>
            <div className="card-body">
                <Forms.Group>
                    <p>Select the permissions that you are requesting.</p>
                    <RoleGrid
                        model={[request.existingRoles!, request.requestedRoles!]}
                        roleGroups={vm.roleConfiguration.roleGroups}
                        mode={RoleGridModeEnum.Editable}
                        onRoleChange={updateViewModel}
                        preColumns={<GridColumn key="category" field="category" title=" " width={200} />}
                    />
                    <ValidationMessageView name="CompanyStandardRoleRequest" validationModel={v} />
                </Forms.Group>
            </div>
        </div>
    );
}
