// redux
import { IWorkProgramViewModel } from "./models";
// other
import * as GlobalHelpers from "../../common/GlobalHelpers";

function ModernWorkProgramView(props: { workProgram: IWorkProgramViewModel }) {
    const wp = props.workProgram;
    const colSpan = wp.showStartAndEndDates ? 5 : 3;

    return (
        <div className="table-responsive">
            <table className="table table-bordered">
                <thead className="table-light">
                    <tr>
                        <th scope="col">Year</th>
                        {wp.showStartAndEndDates && (
                            <>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                            </>
                        )}
                        <th scope="col" className="text-end">
                            Quantity
                        </th>
                        <th scope="col">Activity Description</th>
                        <th scope="col" className="text-end">
                            Indicative Expenditure (AUD)
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {wp.years.map((y) => {
                        const rowSpan = y.activities.length;

                        return y.activities.map((a, i) => {
                            let rowKey = `${y.yearOrder}-${a.activityOrder}`;

                            return i === 0 ? (
                                <tr key={rowKey}>
                                    <td rowSpan={rowSpan}>{y.years}</td>
                                    {wp.showStartAndEndDates && (
                                        <>
                                            <td rowSpan={rowSpan}>{GlobalHelpers.toNoptaDateString(y.startDate)}</td>
                                            <td rowSpan={rowSpan}>{GlobalHelpers.toNoptaDateString(y.endDate)}</td>
                                        </>
                                    )}
                                    <td className="text-end">{a.quantity}</td>
                                    <td style={{ maxWidth: 700, whiteSpace: "pre-line", wordWrap: "break-word" }}>{a.description}</td>
                                    {y.value ? (
                                        <td className="text-end" rowSpan={rowSpan}>
                                            {GlobalHelpers.toNoptaNumberString(y.value, "c0")}
                                        </td>
                                    ) : (
                                        <td className="text-end">{a.value && a.value > 0 ? GlobalHelpers.toNoptaNumberString(a.value, "c0") : null}</td>
                                    )}
                                </tr>
                            ) : (
                                <tr key={rowKey}>
                                    <td className="text-end">{a.quantity}</td>
                                    <td style={{ maxWidth: 700, whiteSpace: "pre-line", wordWrap: "break-word" }}>{a.description}</td>
                                    {!y.value && <td className="text-end">{a.value && a.value > 0 ? GlobalHelpers.toNoptaNumberString(a.value, "c0") : null}</td>}
                                </tr>
                            );
                        });
                    })}
                </tbody>
                <tfoot className="table-light">
                    {wp.showPrimaryAndSecondaryTerms && (
                        <>
                            <tr>
                                <th className="fw-normal" colSpan={colSpan}>
                                    Primary Term
                                </th>
                                <th className="fw-normal text-end">{GlobalHelpers.toNoptaNumberString(wp.primaryTermTotal, "c0")}</th>
                            </tr>
                            <tr>
                                <th className="fw-normal" colSpan={colSpan}>
                                    Secondary Term
                                </th>
                                <th className="fw-normal text-end">{GlobalHelpers.toNoptaNumberString(wp.secondaryTermTotal, "c0")}</th>
                            </tr>
                        </>
                    )}
                    <tr>
                        <th colSpan={colSpan}>Total</th>
                        <th className="text-end">{GlobalHelpers.toNoptaNumberString(wp.grandTotal, "c0")}</th>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
}

export default ModernWorkProgramView;
