// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_ReportsAndData_SurveyDataAttachments;

export default function* deleteSurveyDataAttachment(action: Actions.IAction<Actions.IDeleteRequestedPayload>) {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const { surveyDataId, surveyDataVersionNumber, surveyDataAttachmentId } = action.payload;

    try {
        yield put(shellHelper.createSetBusyAction());

        const deleteResult: CallHelper.CallResult<Client.DeleteGeoscienceAustraliaSurveyDataAttachmentResponseDto> = yield call(
            async () => await deleteSurveyDataAttachmentCall(surveyDataId, surveyDataVersionNumber, surveyDataAttachmentId)
        );

        if (deleteResult.IsSuccess) {
            // success
            let updatedVm = vm;
            const r = deleteResult.Response!;
            if (r.statusMessages.isSuccess) {
                const getAttachmentsResponse: CallHelper.CallResult<Client.GetGeoscienceAustraliaSurveyDataAttachmentsResponseDto> = yield call(async () => await getSurveyDataAttachments(vm));
                updatedVm = vm.refreshAttachments(getAttachmentsResponse.Response!);
            }
            yield put(Actions.actionFactory.sagaCompleted(updatedVm.refreshStatusMessages(deleteResult.Response!.statusMessages)));
        } else if (deleteResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict(true)));
        } else {
            // all other errors
            deleteResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function deleteSurveyDataAttachmentCall(
    surveyDataId: number,
    surveyDataVersionNumber: number,
    surveyDataAttachmentId: number
): Promise<CallHelper.CallResult<Client.DeleteGeoscienceAustraliaSurveyDataAttachmentResponseDto>> {
    const request = new Client.DeleteGeoscienceAustraliaSurveyDataAttachmentRequestDto({
        surveyDataId: surveyDataId,
        surveyDataVersionNumber: surveyDataVersionNumber,
        surveyDataAttachmentId: surveyDataAttachmentId,
    });

    const client = await ClientFactory.createGeoscienceAustraliaSurveyDataClient();
    return await CallHelper.call(() => client.deleteGeoscienceAustraliaSurveyDataAttachment(request));
}

async function getSurveyDataAttachments(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.GetGeoscienceAustraliaSurveyDataAttachmentsResponseDto>> {
    const request = new Client.GetGeoscienceAustraliaSurveyDataAttachmentsRequestDto({
        surveyDataId: vm.surveyDataId!,
    });

    const client = await ClientFactory.createGeoscienceAustraliaSurveyDataClient();
    return await CallHelper.call(() => client.getGeoscienceAustraliaSurveyDataAttachments(request));
}
