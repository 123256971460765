// framework
import { useState } from "react";
// models
import { IRootViewModel } from "../../models/models";
// api
import * as Client from "../../../../../../../../api/Client";
// common
import * as Forms from "../../../../../../../../common/forms/BootstrapForms";
import { IValidationModel } from "../../../../../../../../common/validation/ValidationModel";
import DirtyScope from "../../../../../../../../common/shell/DirtyScopeController";
import KendoComboBox from "../../../../../../../../common/kendo/KendoComboBox";
import ValidationMessageView from "../../../../../../../../common/validation/ValidationMessageView";
// redux
import * as Actions from "../../redux/actions";

interface IProps {
    vm: IRootViewModel;
    v: IValidationModel;
    actions: Actions.IActionFactory;
}

export default function BulkDeleteView(props: IProps): JSX.Element {
    const { vm, v, actions } = props;

    const [state, setState] = useState(vm.bulkDeleteBatchItem);

    function updateStateAndVm(selectedBulkDeleteBatchItem: Client.GetDeleteGeoscienceAustraliaAttachmentsImportReferenceDataAttachmentBatchItem | undefined) {
        actions.viewChanged(vm.refreshBulkDeleteBatchItem(selectedBulkDeleteBatchItem));
        setState(selectedBulkDeleteBatchItem);
    }

    return (
        <DirtyScope scopeName="geoscienceAustralia/wellBoreholes/attachments/list/bulkDelete" isDirty={vm.isDirty}>
            <Forms.Row>
                <Forms.FullColumn>
                    <Forms.Label htmlFor="batchItemField">Bulk Import</Forms.Label>
                    <KendoComboBox
                        id="batchItemField"
                        valid={v.applyValidityForKendo("AttachmentBulkDelete.batchItem")}
                        data={vm.referenceData.batchItems}
                        value={state}
                        keyField="batchNumber"
                        textField="batchDisplayName"
                        filterable={true}
                        suggest={true}
                        onChange={(value) => updateStateAndVm(value)}
                    />
                    <ValidationMessageView name="AttachmentBulkDelete.batchItem" validationModel={v} />
                </Forms.FullColumn>
                {state && <Forms.Label htmlFor="batchItemField">{`This will delete ${state.attachmentItems.length} attachments. Would you like to proceed?`}</Forms.Label>}
            </Forms.Row>
        </DirtyScope>
    );
}
