// framework
import * as Validation from "../../../../../../common/validation/ValidationModel";
// redux
import * as Models from "./models";

export default function validate(vm: Models.IApplicationContactViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateContactDetails(vm, v);
    return v;
}

function validateContactDetails(vm: Models.IApplicationContactViewModel, v: Validation.ValidationModel): void {
    if (Validation.isNullOrWhiteSpace(vm.primaryContactName)) {
        v.addError("ContactDetails.PrimaryContactName", "Primary Contact Name is required.");
    } else if (vm.primaryContactName!.length > 100) {
        v.addError("ContactDetails.PrimaryContactName", "Primary Contact Name must be 100 characters or less.");
    }

    if (Validation.isNullOrWhiteSpace(vm.positionHeld)) {
        v.addError("ContactDetails.PositionHeld", "Position Held is required.");
    } else if (vm.positionHeld!.length > 100) {
        v.addError("ContactDetails.PositionHeld", "Position Held must be 100 characters or less.");
    }

    if (Validation.isNullOrWhiteSpace(vm.phone)) {
        v.addError("ContactDetails.Phone", "Phone is required.");
    } else if (vm.phone!.length > 100) {
        v.addError("ContactDetails.Phone", "Phone must be 100 characters or less.");
    }

    if (Validation.isNullOrWhiteSpace(vm.email)) {
        v.addError("ContactDetails.Email", "Email is required.");
    } else if (!Validation.isValidEmail(vm.email!)) {
        v.addError("ContactDetails.Email", "Email is not in the correct format.");
    } else if (vm.email!.length > 100) {
        v.addError("ContactDetails.Email", "Email must be 100 characters or less.");
    }

    if (vm.fax && vm.fax.length > 100) {
        v.addError("ContactDetails.Fax", "Fax must be 100 characters or less.");
    }

    if (vm.businessAddress && vm.businessAddress.length > 100) {
        v.addError("ContactDetails.BusinessAddress", "Business Address must be 100 characters or less.");
    }

    if (vm.isOverlappingContactSharingConsent !== true) {
        v.addError("ContactDetails.IsOverlappingContactSharingConsent", "You are required to acknowledge that your contact information may be shared.");
    }

    if (!vm.overlappingContactType?.overlappingContactType) {
        v.addError("ContactDetails.OverlappingContactType", "A selection is required.");
    }

    if (vm.isElectronicCommunicationConsent === undefined) {
        v.addError("ContactDetails.IsElectronicCommunicationConsent", "A selection is required.");
    }
}
