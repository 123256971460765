// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_WellsBoreholes_AttachmentList;

export default function* importAttachments() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const exportResponse: Client.ImportGeoscienceAustraliaAttachmentsResponseDto = yield call(async () => {
            const client = await ClientFactory.createGeoscienceAustraliaAttachmentClient();
            return await CallHelper.simpleCall(() =>
                client.importGeoscienceAustraliaAttachments(
                    new Client.ImportGeoscienceAustraliaAttachmentsRequestDto({
                        fileName: vm.importAttachmentFile?.fileName,
                        fileContent: vm.importAttachmentFile?.fileContent,
                    })
                )
            );
        });

        yield put(Actions.actionFactory.sagaCompleted(vm.refreshImportAttachmentFile(undefined).refreshStatusMessages(exportResponse.statusMessages)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
