import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { LockIcon } from "@primer/octicons-react";
import { useIsSignedIn } from "../../../common/shell/shellHelper";
import SignInExpiredController from "../../signIn/SignInExpiredController";

// configured timeout
const sessionTimeoutMinutes = Number(process.env.REACT_APP_SESSION_TIMEOUT_MINUTES);
const sessionTimeout = sessionTimeoutMinutes * 60 * 1000;

/*
    SEPI Sessions
    - avoids stale data
    - forces regeneration of file store authorisations
    - marks the system as signed out (but does not sign out)

    https://dev.azure.com/nopta/Custom/_wiki/wikis/Custom.wiki/1609/Sessions
*/

export default function SessionController(): JSX.Element {
    const [showAuthenticatedSessionTimeoutOverlay, setShowAuthenticatedSessionTimeoutOverlay] = useState(false);
    const [showUnauthenticatedSessionTimeoutOverlay, setShowUnauthenticatedSessionTimeoutOverlay] = useState(false);

    const isSignedIn = useIsSignedIn();

    function onIdle(): void {
        if (isSignedIn === undefined) return;
        if (showAuthenticatedSessionTimeoutOverlay || showUnauthenticatedSessionTimeoutOverlay) return;

        if (isSignedIn) {
            setShowAuthenticatedSessionTimeoutOverlay(true);
        } else {
            setShowUnauthenticatedSessionTimeoutOverlay(true);
        }
    }
    useIdleTimer({ onIdle, timeout: sessionTimeout });

    if (isSignedIn === undefined) return <></>;
    return (
        <>
            {showAuthenticatedSessionTimeoutOverlay && (
                <div>
                    <AuthenticatedSessionTimeoutOverlay />
                    <SignInExpiredController />
                </div>
            )}
            {showUnauthenticatedSessionTimeoutOverlay && <UnauthenticatedSessionTimeoutOverlay />}
        </>
    );
}

function UnauthenticatedSessionTimeoutOverlay(): JSX.Element {
    return (
        <Dialog title="Session Timeout" closeIcon={false}>
            <div className="mb-1">
                <span className="me-2">
                    <LockIcon size={24} />
                </span>
                <strong>Your session has timed out.</strong>
            </div>
            <div>Please reload to continue using the system.</div>
            <DialogActionsBar>
                <button className="btn btn-outline-primary" onClick={() => window.location.reload()}>
                    Reload
                </button>
            </DialogActionsBar>
        </Dialog>
    );
}

function AuthenticatedSessionTimeoutOverlay(): JSX.Element {
    return (
        <Dialog title="Session Timeout" closeIcon={false}>
            <div className="mb-1">
                <span className="me-2">
                    <LockIcon size={24} />
                </span>
                <strong>Your session has timed out.</strong>
            </div>
            <div>Please reload to sign in and continue using the system.</div>
            <DialogActionsBar>
                <button className="btn btn-outline-primary" onClick={() => window.location.reload()}>
                    Reload
                </button>
            </DialogActionsBar>
        </Dialog>
    );
}
