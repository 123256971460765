// framework
import { useEffect, useRef } from "react";
// kendo
import { NumericTextBox, NumericTextBoxChangeEvent, NumericTextBoxHandle } from "@progress/kendo-react-inputs";

interface INumericTextBoxViewProps {
    id: string;
    value: number | undefined;
    format: string;
    min: number | undefined;
    max: number | undefined;
    onChange: (value: number | undefined) => void;
    onBlur?: () => void;
    className?: string;
    valid?: boolean;
}

// TODO even with spinners disabled the NumericTextBox still responds to the mousewheel event when in focus, I have raised a feature request to address this, workaround use ref implemented below
// https://feedback.telerik.com/kendo-react-ui/1554698-disable-step-on-scroll-in-numerictextbox
export default function NumericTextBoxView(props: INumericTextBoxViewProps) {
    const ref = useRef<NumericTextBoxHandle>(null);

    function onWheel(event: WheelEvent) {
        event.stopPropagation();
    }

    useEffect(() => {
        let refValue: NumericTextBoxHandle | null = null;

        if (ref.current) {
            ref.current.element?.addEventListener("wheel", onWheel);
            refValue = ref.current;
        }

        return () => {
            refValue?.element?.removeEventListener("wheel", onWheel);
        };
    }, []);

    function onChange(e: NumericTextBoxChangeEvent) {
        const value = e.value !== null ? e.value : undefined;
        props.onChange(value);
    }

    return (
        <NumericTextBox
            ref={ref}
            id={props.id}
            value={props.value}
            format={props.format}
            min={props.min}
            max={props.max}
            spinners={false}
            className={props.className}
            valid={props.valid}
            onChange={onChange}
            onBlur={props.onBlur}
        />
    );
}
