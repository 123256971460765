// redux
import { call, put, select } from "redux-saga/effects";
import { IJointAuthorityReduxState } from "../../../../jointAuthorityReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

const getRootViewModel = (state: IJointAuthorityReduxState) => state.JointAuthority_Account_RequestAccess;

export default function* createSecureRequest() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const actions = Actions.actionFactory;

    try {
        yield put(shellHelper.createSetBusyAction());

        let response: IResponse;
        const questions = vm.user.questions;

        if (questions.isJointAuthorityAdministratorRequest) {
            response = yield call(async () => await createJaaSecureRequest(vm));
            // } else if (questions.isJointAuthorityStandardRoleRequest && questions.isJointAuthoritySignerRequest) {
            //     response = yield call(async () => await createCrCsSecureRequest(vm));
            // } else if (questions.isJointAuthoritySignerRequest) {
            //     response = yield call(async () => await createCsSecureRequest(vm));
        } else if (questions.isJointAuthorityStandardRoleRequest) {
            response = yield call(async () => await createJarSecureRequest(vm));
        } else throw new Error("Invalid access request.");

        yield put(actions.sagaCompleted(vm.refreshSecureRequestCreateResponse(response.statusMessages, response.secureRequestGroupId)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

interface IResponse {
    statusMessages: Client.StatusMessagesDto;
    secureRequestGroupId?: Client.SecureRequestGroupIdDto | undefined;
}

async function createJaaSecureRequest(vm: Models.IRootViewModel): Promise<IResponse> {
    const request = new Client.CreateJointAuthorityAccountRequestAccessJaaRequestDto({
        jointAuthorityId: vm.user.questions.selectedJointAuthority!.jointAuthorityId,
        submittedComments: vm.user.comments,
        jaaAuthoritySupportingDocument: getSupportingDocumentDto(vm.user.jaaAuthoritySupportingDocument),
    });

    const client = await ClientFactory.createJointAuthorityAccountClient();
    return await CallHelper.simpleCall(() => client.createJointAuthorityAccountRequestAccessJaa(request));
}

// todo nduja
// async function createCrCsSecureRequest(vm: Models.IRootViewModel): Promise<IResponse> {
//     const request = new Client.CreateJointAuthorityAccountRequestAccessCrCsRequestDto({
//         jointAuthorityId: vm.user.questions.selectedJointAuthority!.jointAuthorityId,
//         submittedComments: vm.user.comments,
//         jointAuthorityStandardRolesRequest: getJointAuthorityStandardRolesRequestDto(vm.user.jointAuthorityStandardRoleRequest),
//         csAuthoritySupportingDocument: getSupportingDocumentDto(vm.user.csAuthoritySupportingDocument),
//         isJointAuthoritySignerPoaRequest: vm.user.questions.isJointAuthoritySignerPoaRequest!,
//         csPoaSupportingDocument: vm.user.questions.isJointAuthoritySignerPoaRequest ? getSupportingDocumentDto(vm.user.csPoaSupportingDocument) : undefined,
//     });

//     const client = await ClientFactory.createJointAuthorityAccountClient();
//     return await CallHelper.simpleCall(() => client.createJointAuthorityAccountRequestAccessCrCs(request));
// }

async function createJarSecureRequest(vm: Models.IRootViewModel): Promise<IResponse> {
    const request = new Client.CreateJointAuthorityAccountRequestAccessJarRequestDto({
        jointAuthorityId: vm.user.questions.selectedJointAuthority!.jointAuthorityId,
        submittedComments: vm.user.comments,
        jointAuthorityStandardRolesRequest: getJointAuthorityStandardRolesRequestDto(vm.user.jointAuthorityStandardRoleRequest),
    });

    const client = await ClientFactory.createJointAuthorityAccountClient();
    return await CallHelper.simpleCall(() => client.createJointAuthorityAccountRequestAccessJar(request));
}

// todo nduja
// async function createCsSecureRequest(vm: Models.IRootViewModel): Promise<IResponse> {
//     const request = new Client.CreateJointAuthorityAccountRequestAccessCsRequestDto({
//         jointAuthorityId: vm.user.questions.selectedJointAuthority!.jointAuthorityId,
//         submittedComments: vm.user.comments,
//         csAuthoritySupportingDocument: getSupportingDocumentDto(vm.user.csAuthoritySupportingDocument),
//         isJointAuthoritySignerPoaRequest: vm.user.questions.isJointAuthoritySignerPoaRequest!,
//         csPoaSupportingDocument: vm.user.questions.isJointAuthoritySignerPoaRequest ? getSupportingDocumentDto(vm.user.csPoaSupportingDocument) : undefined,
//     });

//     const client = await ClientFactory.createJointAuthorityAccountClient();
//     return await CallHelper.simpleCall(() => client.createJointAuthorityAccountRequestAccessCs(request));
// }

// private
function getJointAuthorityStandardRolesRequestDto(vm: Models.IJointAuthorityStandardRoleRequestViewModel): Client.JointAuthorityStandardRoleRequestDetailsDto {
    return new Client.JointAuthorityStandardRoleRequestDetailsDto({
        rolesRequested: vm.requestedRoles!.getDirectRoles(),
        rolesApproved: undefined,
    });
}

function getSupportingDocumentDto(document: Models.ISupportingDocumentViewModel): Client.SecureRequestGroupSupportingDocumentDto {
    return new Client.SecureRequestGroupSupportingDocumentDto({ fileName: document.fileName, content: document.content });
}
