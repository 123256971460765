// framework
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// models
import { IRootViewModel } from "../../models/models";
// common
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
import { createGridColumnCheckboxFilterMenu } from "../../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
import { toNoptaNumberString } from "../../../../../../common/GlobalHelpers";

interface IProps {
    vm: IRootViewModel;
}

export default function ParametersListGridView(props: IProps): JSX.Element {
    const { vm } = props;

    const initialState: State = {
        sort: [
            { field: "acquisition.name", dir: "asc" },
            { field: "shotPointInterval", dir: "asc" },
        ],
        skip: 0,
        take: 10,
    };

    const [gridState, setGridState] = useState(initialState);
    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [vm.items]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid
                pageable
                sortable
                data={process(vm.items, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn
                    field="acquisition.name"
                    title="Acquisition"
                    width={350}
                    cell={(props: GridCellProps) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.acquisition?.id && vm.canViewAcquisitions ? (
                                <Link to={geoscienceAustraliaRouteHelper.acquisitionParameters(vm.surveyId!, props.dataItem.acquisition.id)} title="Click to view Acquisition Parameters.">
                                    {props.dataItem.acquisition.name}
                                </Link>
                            ) : (
                                props.dataItem.acquisition?.name
                            )}
                        </CustomGridCell>
                    )}
                    filter="text"
                    columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.items)}
                />
                <GridColumn
                    field="shotPointInterval"
                    title="Shot Point Interval"
                    width={240}
                    filter="numeric"
                    columnMenu={createGridColumnFilterMenu}
                    cell={(props: GridCellProps) => <td className="text-end">{toNoptaNumberString(props.dataItem.shotPointInterval, "n")}</td>}
                />
                <GridColumn
                    field="receiverGroupInterval"
                    title="Receiver Group Interval"
                    width={240}
                    filter="numeric"
                    columnMenu={createGridColumnFilterMenu}
                    cell={(props: GridCellProps) => <td className="text-end">{toNoptaNumberString(props.dataItem.receiverGroupInterval, "n")}</td>}
                />
                <GridColumn
                    field="groupChannelCount"
                    title="Group Channel Count"
                    width={240}
                    filter="numeric"
                    columnMenu={createGridColumnFilterMenu}
                    cell={(props: GridCellProps) => <td className="text-end">{toNoptaNumberString(props.dataItem.groupChannelCount, "n")}</td>}
                />
                <GridColumn field="surveyEnergySourceType" title="Energy Source" width={350} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.items)} />
            </Grid>
        </>
    );
}
