// redux
import * as Models from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";

export default function IaflDetailsReadonlyView(props: { vm: Models.IIaflDetailsViewModel }) {
    const vm = props.vm;

    return (
        <>
            <Forms.Group>
                <Forms.ReadonlyField label="Invitation to Apply" id="invitationToApplyNameField" value={vm.invitationToApplyName} />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="Declared Area" id="oeiDeclaredAreaNameField" value={vm.declaredAreas!.find((d) => d.id === vm.declaredAreaId)?.name} />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="Project Name or Reference" id="projectNameOrReferenceField" value={vm.projectNameOrReference} />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="proposedLicenceAreaField">
                    Proposed Licence Area km<sup>2</sup>
                </Forms.Label>
                <Forms.ReadonlyTextInput id="proposedLicenceAreaField" value={GlobalHelpers.toNoptaNumberString(vm.proposedLicenceArea, "n2")} />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField
                    label="Proposed generation capacity within Licence Area (in GW)"
                    id="proposedGenearationCapacityField"
                    value={GlobalHelpers.toNoptaNumberString(vm.proposedGenerationCapacity, "n2")} // todo iafl check these numbers appear with the correct decimal placing etc
                />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField
                    label="Renewable energy resource"
                    id="oeiRenewableEnergyResourceField"
                    value={vm.renewableEnergyResources!.find((d) => d.id === vm.renewableEnergyResourceTypeId)?.name}
                />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="Technology to be investigated" id="oeiTechnologyField" value={vm.technologyTypes!.find((d) => d.id === vm.technologyTypeId)?.name} />
            </Forms.Group>
        </>
    );
}
