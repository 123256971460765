// state
export interface IHomeReduxState {}

// reducers
export function buildReducers() {
    return {};
}

// sagas
export function buildSagas(): Array<any> {
    return [];
}
