// framework
import { useEffect, useState } from "react";
// redux
import * as Models from "../../models/models";
// common
import { ValidationModel } from "../../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../../common/validation/ValidationMessageView";
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import { DatePicker } from "@progress/kendo-react-dateinputs";

export default function IarlDetailsRlView(props: { vm: Models.IIappRlDetailsViewModel; v: ValidationModel; updateViewModel: (vm: Models.IIappViewModel) => void }) {
    const vm = props.vm;
    const v = props.v;

    const [state, setState] = useState<Models.IIappRlDetailsViewModel>({
        fieldName: undefined,
        initialGrantDate: undefined,
        dateOfRevocationOrRefusalOfRenewalOfRetentionLeaseDate: undefined,
    });

    useEffect(() => {
        setState({
            fieldName: vm.fieldName,
            initialGrantDate: vm.initialGrantDate,
            dateOfRevocationOrRefusalOfRenewalOfRetentionLeaseDate: vm.dateOfRevocationOrRefusalOfRenewalOfRetentionLeaseDate,
        });
    }, [vm.fieldName, vm.initialGrantDate, vm.dateOfRevocationOrRefusalOfRenewalOfRetentionLeaseDate]);

    function onChange(values: Models.IIappRlDetailsViewModel) {
        setState({ ...state, ...values });
    }

    function onBlur() {
        props.updateViewModel({ rlDetails: state });
    }

    return (
        <>
            <Forms.Group>
                <Forms.ReadonlyField label="Field Name" id="rlDetailsExistingField" value={vm.fieldName} />
            </Forms.Group>
            <strong>If titleholder of a petroleum retention lease (section 170 of the OPGGS Act)</strong>
            <Forms.Group>
                <Forms.Label htmlFor="dateOfRevocationOrRefusalOfRenewalOfRetentionLeaseDate">
                    Date of revocation or refusal of renewal of retention lease (if applicable, see section 155 or 158 of the OPGGS Act)
                </Forms.Label>
                <DatePicker
                    id="dateOfRevocationOrRefusalOfRenewalOfRetentionLeaseDate"
                    value={state.dateOfRevocationOrRefusalOfRenewalOfRetentionLeaseDate}
                    min={vm.initialGrantDate}
                    valid={v.applyValidityForKendo("IappDetails.RlDetails.DateOfRevocationOrRefusalOfRenewalOfRetentionLeaseDate")}
                    onChange={(e) => onChange({ ...state, dateOfRevocationOrRefusalOfRenewalOfRetentionLeaseDate: e.value })}
                    onBlur={onBlur}
                />
                <ValidationMessageView name="IappDetails.RlDetails.DateOfRevocationOrRefusalOfRenewalOfRetentionLeaseDate" validationModel={v} />
            </Forms.Group>
        </>
    );
}
