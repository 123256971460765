// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

/**
 * Used to create or update report attachments
 */
export interface ISaveReportAttachment {
    reportAttachmentId?: number | undefined;
    name?: string;
    fileName?: string;
    documentLink?: string;
}

export interface IRootViewModel {
    reportId: number | undefined;
    reportVersionNumber: number | undefined;
    reportHeader: string | undefined;
    publicTitle: string | undefined;
    saveReportAttachment?: ISaveReportAttachment;

    Attachments: Client.GetGeoscienceAustraliaReportAttachmentsItemDto[] | undefined;

    isConflict: boolean;
    isDirty: boolean;
    statusMessages?: Client.StatusMessagesDto;

    refreshAttachments(response: Client.GetGeoscienceAustraliaReportAttachmentsResponseDto): IRootViewModel;
    refreshStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel;
    onSaveReportAttachmentChanged(saveReportAttachment: ISaveReportAttachment): IRootViewModel;
    refreshConflict(isConflict: boolean): IRootViewModel;
    clearStatusMessages(): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.reportId = undefined;
        this.reportVersionNumber = undefined;
        this.reportHeader = undefined;
        this.publicTitle = undefined;

        this.saveReportAttachment = this.defaultSaveReportAttachment();

        this.Attachments = [];

        this.isConflict = false;
        this.isDirty = false;
    }

    public reportId: number | undefined;
    public reportVersionNumber: number | undefined;
    public reportHeader: string | undefined;
    public publicTitle: string | undefined;
    public saveReportAttachment: ISaveReportAttachment;

    public Attachments: Client.GetGeoscienceAustraliaReportAttachmentsItemDto[] | undefined;

    public isDirty: boolean;
    public isConflict: boolean;
    public statusMessages?: Client.StatusMessagesDto;

    public refreshAttachments(response: Client.GetGeoscienceAustraliaReportAttachmentsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.reportId = response.reportId;
        vm.reportVersionNumber = response.reportVersionNumber;
        vm.reportHeader = response.reportHeader;
        vm.publicTitle = response.publicTitle;

        vm.Attachments = response.attachments;

        return vm;
    }

    public refreshStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = statusMessages;
        return vm;
    }

    public refreshConflict(isConflict: boolean): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = isConflict;
        return vm;
    }

    public onSaveReportAttachmentChanged(saveReportAttachment: ISaveReportAttachment): IRootViewModel {
        const vm = this._clone();
        vm.saveReportAttachment = saveReportAttachment;
        vm.isDirty = true;
        return vm;
    }

    public clearStatusMessages(): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = undefined;
        vm.saveReportAttachment = this.defaultSaveReportAttachment();
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.statusMessages = undefined;
        return vm;
    }

    private defaultSaveReportAttachment(): ISaveReportAttachment {
        return {
            reportAttachmentId: undefined,
            name: undefined,
            fileName: undefined,
            documentLink: undefined,
        };
    }
}
