// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Models from "../../models/rootViewModel";
import * as Actions from "../actions";
import { IShellReduxState } from "../../../shellReduxRegistration";
// api
import * as ClientFactory from "../../../../api/clientFactory";
import * as CallHelper from "../../../../api/callHelper";
import * as Client from "../../../../api/Client";
// common
import * as LogHelper from "../../../../common/LogHelper";

const getRootViewModel = (state: IShellReduxState) => state.Shell_Layout;

export function* refreshLhsNav(action: Actions.IAction<{ isSignedIn: boolean; isSilent: boolean }>) {
    const isSignedIn = action.payload.isSignedIn;
    const isSilent = action.payload.isSilent;

    try {
        if (!isSilent) yield put(Actions.actionFactory.setBusy());

        let response: Client.GetShellLhsNavigationResponseDto | undefined = undefined;

        // load the lhs configuration (only if signed in)
        if (isSignedIn) {
            response = yield call(async () => {
                const client = await ClientFactory.createShellClient();
                return await CallHelper.simpleCall(() => client.getShellLhsNavigation(new Client.GetShellLhsNavigationRequestDto()));
            });
        }

        // update vm
        let vm: Models.IRootViewModel = yield select(getRootViewModel);
        vm = vm.refreshLhsNavConfiguration(response);
        yield put(Actions.actionFactory.sagaCompleted(vm));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        if (!isSilent) yield put(Actions.actionFactory.clearBusy());
    }
}
