// redux
import { call, put, select } from "redux-saga/effects";
import { ICompanyReduxState } from "../../../../../companyReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { getFormRequestDetails } from "./getDetails";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

interface IResponse {
    statusMessages: Client.StatusMessagesDto;
}

const getRootViewModel = (state: ICompanyReduxState) => state.Company_Opggs_LegislativeForms_FormRequestDetails;

// TODO add refresh on success
export default function* setFormRequestAsReadyForSigning() {
    let vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        let result: CallHelper.CallResult<IResponse>;

        switch (vm.formRequestDetails.formTypeId) {
            case 2:
                result = yield call(async () => await setForm2RequestAsReadyForSigning(vm));
                break;
            case 3:
                result = yield call(async () => await setForm3RequestAsReadyForSigning(vm));
                break;
            case 4:
                result = yield call(async () => await setForm4RequestAsReadyForSigning(vm));
                break;
            case 5:
                result = yield call(async () => await setForm5RequestAsReadyForSigning(vm));
                break;
            case 6:
                result = yield call(async () => await setForm6RequestAsReadyForSigning(vm));
                break;
            case 7:
                result = yield call(async () => await setForm7RequestAsReadyForSigning(vm));
                break;
            default:
                throw new Error(`Unsupported Legislative Form '${vm.formRequestDetails.formTypeId}'.`);
        }

        if (result.IsSuccess) {
            // success
            const r = result.Response!;
            let fetchStatusMessages: Client.StatusMessagesDto | undefined = undefined;
            if (r.statusMessages.isSuccess) {
                const formRequestResponse: Models.IGetFormRequestDetailsResponse = yield call(getFormRequestDetails, vm.id!, vm.formRequestDetails.formTypeId);
                fetchStatusMessages = formRequestResponse.statusMessages;
                vm = vm.refreshRequestDetails(formRequestResponse);
            }
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshFormRequestStatusMessages(r.statusMessages, fetchStatusMessages)));
        } else if (result.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            result.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function setForm2RequestAsReadyForSigning(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();

    const request = new Client.SetCompanyOpggsLegislativeFormsForm2RequestAsReadyForSigningRequestDto({
        id: vm.id!,
        versionNumber: vm.versionNumber!,
    });

    return await CallHelper.call(() => client.setCompanyOpggsLegislativeFormsForm2RequestAsReadyForSigning(request));
}

async function setForm3RequestAsReadyForSigning(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();

    const request = new Client.SetCompanyOpggsLegislativeFormsForm3RequestAsReadyForSigningRequestDto({
        id: vm.id!,
        versionNumber: vm.versionNumber!,
    });

    return await CallHelper.call(() => client.setCompanyOpggsLegislativeFormsForm3RequestAsReadyForSigning(request));
}

async function setForm4RequestAsReadyForSigning(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();

    const request = new Client.SetCompanyOpggsLegislativeFormsForm4RequestAsReadyForSigningRequestDto({
        id: vm.id!,
        versionNumber: vm.versionNumber!,
    });

    return await CallHelper.call(() => client.setCompanyOpggsLegislativeFormsForm4RequestAsReadyForSigning(request));
}

async function setForm5RequestAsReadyForSigning(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();

    const request = new Client.SetCompanyOpggsLegislativeFormsForm5RequestAsReadyForSigningRequestDto({
        id: vm.id!,
        versionNumber: vm.versionNumber!,
    });

    return await CallHelper.call(() => client.setCompanyOpggsLegislativeFormsForm5RequestAsReadyForSigning(request));
}

async function setForm6RequestAsReadyForSigning(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();

    const request = new Client.SetCompanyOpggsLegislativeFormsForm6RequestAsReadyForSigningRequestDto({
        id: vm.id!,
        versionNumber: vm.versionNumber!,
    });

    return await CallHelper.call(() => client.setCompanyOpggsLegislativeFormsForm6RequestAsReadyForSigning(request));
}

async function setForm7RequestAsReadyForSigning(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();

    const request = new Client.SetCompanyOpggsLegislativeFormsForm7RequestAsReadyForSigningRequestDto({
        id: vm.id!,
        versionNumber: vm.versionNumber!,
    });

    return await CallHelper.call(() => client.setCompanyOpggsLegislativeFormsForm7RequestAsReadyForSigning(request));
}
