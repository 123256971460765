import { takeEvery } from "redux-saga/effects";
import * as Actions from "./actions";

import initialise from "./sagas/initialise";
import approveRejectRequestGroup from "./sagas/approveRejectRequestGroup";

export default function* watcherSaga() {
    yield takeEvery(Actions.actions.initialiseRequested, (action) => initialise(action as Actions.IAction<Actions.IRequestDetailsKeyPayload>));
    yield takeEvery(Actions.actions.approveRejectRequestGroupRequested, approveRejectRequestGroup);
}
