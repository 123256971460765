import * as Client from "../../../../api/Client";
import { clone } from "lodash";

export interface IRootViewModel {
    financialNotificationId?: number;
    financialNotification: Client.GetCompanyFinanceFnDetailsResponseDto;
    refreshDetails(response: Client.GetCompanyFinanceFnDetailsResponseDto, showMakePayment: boolean): IRootViewModel;

    document: Client.SecurePortalDocumentDto | undefined;
    refreshDocument(response: Client.SecurePortalDocumentDto | undefined): IRootViewModel;
    clearDocument(): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.financialNotificationId = undefined;
        this.financialNotification = new Client.GetCompanyFinanceFnDetailsResponseDto();
        this.document = undefined;
    }

    public financialNotificationId: number | undefined;
    public financialNotification: Client.GetCompanyFinanceFnDetailsResponseDto;
    public document: Client.SecurePortalDocumentDto | undefined;

    public refreshDetails(response: Client.GetCompanyFinanceFnDetailsResponseDto, showMakePayment: boolean): IRootViewModel {
        const vm = this._clone();
        vm.financialNotificationId = response.financialNotificationId;
        vm.financialNotification = response;
        return vm;
    }

    public clearDocument(): IRootViewModel {
        const vm = this._clone();
        vm.document = undefined;
        return vm;
    }

    public refreshDocument(response: Client.SecurePortalDocumentDto | undefined): IRootViewModel {
        const vm = this._clone();
        vm.document = response;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.financialNotification = clone(this.financialNotification);

        return vm;
    }
}
