// framework
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
// redux
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// common
import BodyLayout from "../../../../../common/shell/BodyLayoutController";
import * as toastHelper from "../../../../../common/toastHelper";
import { ValidationVisibilityEnum } from "../../../../../common/validation/ValidationModel";
import ConfirmationView, { DefaultButtonEnum } from "../../../../../common/confirmation/ConfirmationView";
import StatusMessagesAlertsView from "../../../../../common/alerts/StatusMessagesAlertsView";
import ConflictAlertView from "../../../../../common/alerts/ConflictAlertView";
// views
import NavBar, { TabsEnum } from "../../components/views/NavBarView";
import SurveyDataAttachmentsListGridView from "./views/SurveyDataAttachmentsListGridView";
import SurveyDataAttachmentView from "./views/SurveyDataAttachmentView";
import validator from "../models/validator";

interface IRouteParameters {
    surveyDataId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { surveyDataId } = params;

    const [showAddElementDialog, setShowAddElementDialog] = useState(false);
    const [showUpdateElementDialog, setShowUpdateElementDialog] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const [deleteItem, setDeleteItem] = useState<{ surveyDataAttachmentId?: number }>();
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const v = validator(vm, validationVisibility);

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(surveyDataId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onCreateClicked = () => {
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        actions.addRequested(vm);
        window.scrollTo(0, 0);
        setShowAddElementDialog(false);
    };

    const onUpdateClicked = () => {
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        actions.updateRequested(vm);
        window.scrollTo(0, 0);
        setShowUpdateElementDialog(false);
    };

    const onCreateCancelClicked = () => {
        setShowAddElementDialog(false);
        actions.viewChanged(vm.clearStatusMessages());
        setValidationVisibility(ValidationVisibilityEnum.Messages);
    };

    const onUpdateCancelClicked = () => {
        setShowUpdateElementDialog(false);
        actions.viewChanged(vm.clearStatusMessages());
        setValidationVisibility(ValidationVisibilityEnum.Messages);
    };

    const onUpdateShow = (saveSurveyDataAttachment: Models.ISaveSurveyDataAttachment) => {
        actions.viewChanged(vm.clearStatusMessages());
        setShowUpdateElementDialog(true);
        actions.viewChanged(vm.onSaveSurveyDataAttachmentChanged(saveSurveyDataAttachment));
    };

    const onDeleteShow = (surveyDataAttachmentId: number) => {
        setShowDeleteConfirmation(true);
        setDeleteItem({ surveyDataAttachmentId: surveyDataAttachmentId });
    };

    const onDeleteCancel = () => {
        setDeleteItem({});
        setShowDeleteConfirmation(false);
    };

    const onDeleteAccept = () => {
        actions.deleteRequested(vm.surveyDataId!, vm.surveyDataVersionNumber!, deleteItem!.surveyDataAttachmentId!);
        setShowDeleteConfirmation(false);
    };

    return (
        <>
            {vm.Attachments && vm.publicTitle && (
                <BodyLayout
                    title={`Survey Data - ${vm.pageHeader}`}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_ReportsAndData_SurveyData}
                    onReloadRequested={() => {
                        props.initialiseRequested(Number(surveyDataId));
                    }}
                >
                    <ConflictAlertView isConflict={vm.isConflict} />
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
                    <NavBar surveyDataId={Number(surveyDataId)} activeNavItem={TabsEnum.Attachments} />
                    <SurveyDataAttachmentsListGridView vm={vm} onDelete={onDeleteShow} onEdit={onUpdateShow} />
                    <div className="mt-3">
                        <button
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={() => {
                                actions.viewChanged(vm.clearStatusMessages());
                                setShowAddElementDialog(true);
                            }}
                        >
                            Create
                        </button>
                    </div>
                </BodyLayout>
            )}
            {showAddElementDialog && <SurveyDataAttachmentView vm={vm} v={v} actions={actions} onSave={onCreateClicked} onCancel={onCreateCancelClicked} />}
            {showUpdateElementDialog && <SurveyDataAttachmentView vm={vm} v={v} actions={actions} onSave={onUpdateClicked} onCancel={onUpdateCancelClicked} />}
            {showDeleteConfirmation && (
                <ConfirmationView title="Delete Survey Data Attachment" onAccepted={onDeleteAccept} onClosed={onDeleteCancel} defaultButton={DefaultButtonEnum.Cancel}>
                    <p>Are you sure you want to delete this Survey Data attachment?</p>
                </ConfirmationView>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_ReportsAndData_SurveyDataAttachments }), Actions.actionFactory)(Controller);
