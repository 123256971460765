// redux
import { call, put, select } from "redux-saga/effects";
import { IJointAuthorityReduxState } from "../../../../jointAuthorityReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/rootViewModel";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

interface IResponse {
    statusMessages: Client.StatusMessagesDto;
}

const getRootViewModel = (state: IJointAuthorityReduxState) => state.JointAuthority_UserManagement_RequestDetails;

export default function* approveRejectRequestGroup() {
    let vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        let saveResult: CallHelper.CallResult<IResponse>;

        switch (vm.summary.type) {
            case Client.SecureRequestGroupTypeEnum.JAR:
                saveResult = yield call(async () => await approveRejectJar(vm));
                break;
            case Client.SecureRequestGroupTypeEnum.NU_JAR:
                saveResult = yield call(async () => await approveRejectNuJar(vm));
                break;
            // case Client.SecureRequestGroupTypeEnum.CS:
            //     saveResult = yield call(async () => await approveRejectJar(vm));
            //     break;
            // case Client.SecureRequestGroupTypeEnum.CR_CS:
            //     saveResult = yield call(async () => await approveRejectCrCs(vm));
            //     break;
            // case Client.SecureRequestGroupTypeEnum.NU_CR_CS:
            //     saveResult = yield call(async () => await approveRejectNuCrCs(vm));
            //     break;
            default:
                throw new Error("Unsupported Request Type.");
        }

        if (saveResult.IsSuccess) {
            // success
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshApproveRejectRequestGroupResponse(saveResult.Response!.statusMessages)));
        } else if (saveResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            saveResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function approveRejectJar(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createJointAuthorityUserManagementClient();

    if (vm.jointAuthorityStandardRoleDetails.isApproved === true) {
        const approveJarRequest = new Client.ApproveJointAuthorityUserManagementRequestDetailsJarRequestDto({
            secureRequestGroupId: vm.id!,
            versionNumber: vm.versionNumber!,
            rolesApproved: vm.jointAuthorityStandardRoleDetails.grantedRoles!.getDirectRoles(),
        });

        return await CallHelper.call(() => client.approveJointAuthorityUserManagementRequestDetailsJar(approveJarRequest));
    } else {
        const rejectJarRequest = new Client.RejectJointAuthorityUserManagementRequestDetailsJarRequestDto({
            secureRequestGroupId: vm.id!,
            versionNumber: vm.versionNumber!,
            comments: vm.rejectionDetails.comments,
            isSuspicious: vm.rejectionDetails.isSuspicious!,
        });

        return await CallHelper.call(() => client.rejectJointAuthorityUserManagementRequestDetailsJar(rejectJarRequest));
    }
}

async function approveRejectNuJar(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createJointAuthorityUserManagementClient();

    if (vm.jointAuthorityStandardRoleDetails.isApproved === true) {
        const approveNuJarRequest = new Client.ApproveJointAuthorityUserManagementRequestDetailsNuJarRequestDto({
            secureRequestGroupId: vm.id!,
            versionNumber: vm.versionNumber!,
            rolesApproved: vm.jointAuthorityStandardRoleDetails.grantedRoles!.getDirectRoles(),
        });

        return await CallHelper.call(() => client.approveJointAuthorityUserManagementRequestDetailsNuJar(approveNuJarRequest));
    } else {
        const rejectNuJarRequest = new Client.RejectJointAuthorityUserManagementRequestDetailsNuJarRequestDto({
            secureRequestGroupId: vm.id!,
            versionNumber: vm.versionNumber!,
            comments: vm.rejectionDetails.comments,
            isSuspicious: vm.rejectionDetails.isSuspicious!,
        });

        return await CallHelper.call(() => client.rejectJointAuthorityUserManagementRequestDetailsNuJar(rejectNuJarRequest));
    }
}

// async function approveRejectCs(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
//     const client = await ClientFactory.createJointAuthorityUserManagementClient();

//     if (vm.jointAuthoritySignerDetails.isApproved === true) {
//         const approveCsRequest = new Client.ApproveJointAuthorityUserManagementRequestDetailsCsRequestDto({
//             secureRequestGroupId: vm.id!,
//             versionNumber: vm.versionNumber!,
//         });

//         return await CallHelper.call(() => client.approveJointAuthorityUserManagementRequestDetailsCs(approveCsRequest));
//     } else {
//         const rejectCsRequest = new Client.RejectJointAuthorityUserManagementRequestDetailsCsRequestDto({
//             secureRequestGroupId: vm.id!,
//             versionNumber: vm.versionNumber!,
//             comments: vm.rejectionDetails.comments,
//             isSuspicious: vm.rejectionDetails.isSuspicious!,
//         });

//         return await CallHelper.call(() => client.rejectJointAuthorityUserManagementRequestDetailsCs(rejectCsRequest));
//     }
// }

// async function approveRejectCrCs(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
//     const client = await ClientFactory.createJointAuthorityUserManagementClient();

//     const isJointAuthorityStandardRoleRequestApproved = vm.jointAuthorityStandardRoleDetails.isApproved ?? false;
//     const grantedRoles = vm.jointAuthorityStandardRoleDetails.grantedRoles!.getDirectRoles();

//     if (vm.jointAuthoritySignerDetails.isApproved === true) {
//         const approveCrCsRequest = new Client.ApproveJointAuthorityUserManagementRequestDetailsCrCsRequestDto({
//             secureRequestGroupId: vm.id!,
//             versionNumber: vm.versionNumber!,
//             isJointAuthorityStandardRoleRequestApproved: isJointAuthorityStandardRoleRequestApproved,
//             rolesApproved: grantedRoles,
//         });

//         return await CallHelper.call(() => client.approveJointAuthorityUserManagementRequestDetailsCrCs(approveCrCsRequest));
//     } else {
//         const rejectCrCsRequest = new Client.RejectJointAuthorityUserManagementRequestDetailsCrCsRequestDto({
//             secureRequestGroupId: vm.id!,
//             versionNumber: vm.versionNumber!,
//             comments: vm.rejectionDetails.comments,
//             isJointAuthorityStandardRoleRequestApproved: isJointAuthorityStandardRoleRequestApproved,
//             rolesApproved: grantedRoles,
//             isSuspicious: vm.rejectionDetails.isSuspicious!,
//         });

//         return await CallHelper.call(() => client.rejectJointAuthorityUserManagementRequestDetailsCrCs(rejectCrCsRequest));
//     }
// }

// async function approveRejectNuCrCs(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
//     const client = await ClientFactory.createJointAuthorityUserManagementClient();

//     const isJointAuthorityStandardRoleRequestApproved = vm.jointAuthorityStandardRoleDetails.isApproved ?? false;
//     const grantedRoles = vm.jointAuthorityStandardRoleDetails.grantedRoles!.getDirectRoles();

//     if (vm.jointAuthoritySignerDetails.isApproved === true) {
//         const approveNuCrCsRequest = new Client.ApproveJointAuthorityUserManagementRequestDetailsNuCrCsRequestDto({
//             secureRequestGroupId: vm.id!,
//             versionNumber: vm.versionNumber!,
//             isJointAuthorityStandardRoleRequestApproved: isJointAuthorityStandardRoleRequestApproved,
//             rolesApproved: grantedRoles,
//         });

//         return await CallHelper.call(() => client.approveJointAuthorityUserManagementRequestDetailsNuCrCs(approveNuCrCsRequest));
//     } else {
//         const rejectNuCrCsRequest = new Client.RejectJointAuthorityUserManagementRequestDetailsNuCrCsRequestDto({
//             secureRequestGroupId: vm.id!,
//             versionNumber: vm.versionNumber!,
//             comments: vm.rejectionDetails.comments,
//             isJointAuthorityStandardRoleRequestApproved: isJointAuthorityStandardRoleRequestApproved,
//             rolesApproved: grantedRoles,
//             isSuspicious: vm.rejectionDetails.isSuspicious!,
//         });

//         return await CallHelper.call(() => client.rejectJointAuthorityUserManagementRequestDetailsNuCrCs(rejectNuCrCsRequest));
//     }
// }
