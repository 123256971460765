// redux
import * as Models from "../../models/models";
// common
import SimpleAlertView from "../../../../../../../common/simpleAlerts/SimpleAlertView";

export default function WarningsView(props: { vm: Models.IRootViewModel }): React.ReactElement {
    const vm = props.vm;

    // using a warnings view in lieu of having a warnings capability built into our validation framework

    const showWorkProgramValidation = vm.viewState === Models.ViewStateEnum.Edit && vm.isComplete !== true;
    const message = showWorkProgramValidation ? "A Work Program is required to progress the Application." : null;

    return <>{message && <SimpleAlertView alertType="warning" dismissable={false} message={message} />}</>;
}
