import * as Client from "../../../../../api/Client";
import * as Models from "../models/models";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "geoscienceAustralia.wellsBoreholes.authorisationList.initialiseRequested",
    clearRequested = "geoscienceAustralia.wellsBoreholes.authorisationList.clearRequested",
    // user input
    viewChanged = "geoscienceAustralia.wellsBoreholes.authorisationList.viewChanged",
    // saga events
    sagaCompleted = "geoscienceAustralia.wellsBoreholes.authorisationList.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

export interface IInitialiseRequestedPayload {
    searchType: Client.SearchAuthorisationsTypeEnum;
    searchText: string;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(searchType: Client.SearchAuthorisationsTypeEnum, searchText: string): IAction<IInitialiseRequestedPayload>;
    clearRequested(): IAction<undefined>;
    // user input
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    // saga events
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (searchType: Client.SearchAuthorisationsTypeEnum, searchText: string): IAction<IInitialiseRequestedPayload> {
        return {
            type: actions.initialiseRequested,
            payload: { searchType: searchType, searchText: searchText },
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
