// api
import * as ClientFactory from "../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";

export async function getCompanyOpggsApplicationDetailsFinancialNotification(id: string): Promise<Client.GetCompanyOpggsApplicationDetailsFinancialNotificationResponseDto> {
    // console.info("getCompanyOpggsApplicationDetailsFinancialNotification");
    const client = await ClientFactory.createCompanyOpggsApplicationDetailsClient();
    return await CallHelper.simpleCall(() =>
        client.getCompanyOpggsApplicationDetailsFinancialNotification(new Client.GetCompanyOpggsApplicationDetailsFinancialNotificationRequestDto({ applicationId: id }))
    );
}
