import { useEffect, useState } from "react";
// redux
import * as Models from "../../models/models";
// api
import * as Client from "../../../../../../api/Client";
// common
import { ValidationModel } from "../../../../../../common/validation/ValidationModel";
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import { orderBy } from "@progress/kendo-data-query";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import ValidationMessageView from "../../../../../../common/validation/ValidationMessageView";
import NumericTextBoxView from "../../../../../../common/numericTextBox/NumericTextBoxView";

export default function IaflDetailsView(props: { vm: Models.IIaflDetailsViewModel; v: ValidationModel; updateViewModel: (vm: Models.IIaflDetailsViewModel) => void }) {
    const vm = props.vm;
    const v = props.v;

    // generic
    const [state, setState] = useState<Models.IIaflDetailsViewModel>({
        invitationToApplyId: undefined,
        invitationToApplyName: "",
        declaredAreaId: undefined,
        projectNameOrReference: "",
        proposedLicenceArea: undefined,
        proposedGenerationCapacity: undefined,
        renewableEnergyResourceTypeId: undefined,
        technologyTypeId: undefined,
    });

    useEffect(() => {
        setState({
            invitationToApplyId: vm.invitationToApplyId,
            invitationToApplyName: vm.invitationToApplyName ?? "",
            declaredAreaId: vm.declaredAreaId,
            projectNameOrReference: vm.projectNameOrReference ?? "",
            proposedLicenceArea: vm.proposedLicenceArea,
            proposedGenerationCapacity: vm.proposedGenerationCapacity,
            renewableEnergyResourceTypeId: vm.renewableEnergyResourceTypeId,
            technologyTypeId: vm.technologyTypeId,
        });
    }, [vm]);

    // generic input change
    function onChange(values: Models.IIaflDetailsViewModel) {
        setState({ ...state, ...values });
    }

    function onBlur() {
        props.updateViewModel(state);
    }

    // declared area
    const [declaredAreaId, setDeclaredAreaId] = useState<number | undefined>();

    useEffect(() => {
        setDeclaredAreaId(vm.declaredAreaId);
    }, [vm.declaredAreaId]);

    const [declaredAreaData, setDeclaredAreaData] = useState<Array<Client.GetCompanyOeiDraftApplicationDetailsIaflDeclaredAreaDto>>();

    useEffect(() => {
        setDeclaredAreaData(orderBy(vm.declaredAreas!, [{ field: "name", dir: "asc" }]));
    }, [vm.declaredAreas]);

    function onChangeDeclaredArea(e: DropDownListChangeEvent) {
        const declaredArea = e.target.value as Client.GetCompanyOeiDraftApplicationDetailsIaflDeclaredAreaDto;
        props.updateViewModel({ ...vm, declaredAreaId: declaredArea.id });
    }

    // renewable energy resource
    const [renewableEnergyResourceId, setRenewableEnergyResourceId] = useState<number | undefined>();

    useEffect(() => {
        setRenewableEnergyResourceId(vm.renewableEnergyResourceTypeId);
    }, [vm.renewableEnergyResourceTypeId]);

    const [renewableEnergyResourceData, setRenewableEnergyResourceData] = useState<Array<Client.GetCompanyOeiDraftApplicationDetailsIaflRenewableEnergyResourceTypeDto>>();

    useEffect(() => {
        setRenewableEnergyResourceData(orderBy(vm.renewableEnergyResources!, [{ field: "name", dir: "asc" }]));
    }, [vm.renewableEnergyResources]);

    function onChangeRenewableEnergyResource(e: DropDownListChangeEvent) {
        const renewableEnergyResource = e.target.value as Client.GetCompanyOeiDraftApplicationDetailsIaflRenewableEnergyResourceTypeDto;
        props.updateViewModel({ ...vm, renewableEnergyResourceTypeId: renewableEnergyResource.id });
    }

    // technology
    const [technologyTypeId, setTechnologyId] = useState<number | undefined>();

    useEffect(() => {
        setTechnologyId(vm.technologyTypeId);
    }, [vm.technologyTypeId]);

    const [technologyData, setTechnologyData] = useState<Array<Client.GetCompanyOeiDraftApplicationDetailsIaflTechnologyTypeDto>>();

    useEffect(() => {
        setTechnologyData(orderBy(vm.technologyTypes!, [{ field: "name", dir: "asc" }]));
    }, [vm.technologyTypes]);

    function onChangeTechnology(e: DropDownListChangeEvent) {
        const technology = e.target.value as Client.GetCompanyOeiDraftApplicationDetailsIaflTechnologyTypeDto;
        props.updateViewModel({ ...vm, technologyTypeId: technology.id });
    }

    return (
        <>
            <Forms.Group>
                <Forms.ReadonlyField label="Invitation to Apply" id="invitationToApplyNameField" value={vm.invitationToApplyName} />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="declaredAreaField">Declared Area</Forms.Label>
                <DropDownList
                    id="declaredAreaField"
                    valid={v.applyValidityForKendo("IaflDetails.DeclaredArea")}
                    data={declaredAreaData}
                    value={declaredAreaData?.find((l) => l.id === declaredAreaId)}
                    dataItemKey="id"
                    textField="name"
                    onChange={onChangeDeclaredArea}
                />
                <ValidationMessageView name="IaflDetails.DeclaredArea" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.TextInput
                    id="projectNameOrReferenceField"
                    label="Project Name or Reference"
                    value={state.projectNameOrReference}
                    valid={v.applyValidity("IaflDetails.ProjectNameOrReference")}
                    onChange={(value) => onChange({ projectNameOrReference: value })}
                    onBlur={onBlur}
                />
                <ValidationMessageView name="IaflDetails.ProjectNameOrReference" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="proposedLicenceAreaField">
                    Proposed Licence Area km<sup>2</sup>
                </Forms.Label>
                <NumericTextBoxView
                    id="proposedLicenceAreaField"
                    value={state.proposedLicenceArea}
                    valid={v.applyValidityForKendo("IaflDetails.ProposedLicenceAreaField")}
                    format="n2"
                    min={0}
                    max={999999999999999999}
                    onChange={(value) => onChange({ proposedLicenceArea: value })}
                    onBlur={onBlur}
                />
                <ValidationMessageView name="IaflDetails.ProposedLicenceArea" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="proposedGenerationCapacityField">Proposed generation capacity within Licence Area (in GW)</Forms.Label>
                <NumericTextBoxView
                    id="proposedGenerationCapacityField"
                    value={state.proposedGenerationCapacity}
                    valid={v.applyValidityForKendo("IaflDetails.ProposedGenerationCapacity")}
                    format="n2"
                    min={0}
                    max={999999999999999999}
                    onChange={(value) => onChange({ proposedGenerationCapacity: value })}
                    onBlur={onBlur}
                />
                <ValidationMessageView name="IaflDetails.ProposedGenerationCapacity" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="renewableEnergyResourceField">Renewable energy resource</Forms.Label>
                <DropDownList
                    id="renewableEnergyResourceField"
                    valid={v.applyValidityForKendo("IaflDetails.RenewableEnergyResource")}
                    data={renewableEnergyResourceData}
                    value={renewableEnergyResourceData?.find((l) => l.id === renewableEnergyResourceId)}
                    dataItemKey="id"
                    textField="name"
                    onChange={onChangeRenewableEnergyResource}
                />
                <ValidationMessageView name="IaflDetails.RenewableEnergyResource" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="technologyToBeInvestigatedField">Technology to be investigated</Forms.Label>
                <DropDownList
                    id="technologyToBeInvestigatedField"
                    valid={v.applyValidityForKendo("IaflDetails.TechnologyToBeInvestigated")}
                    data={technologyData}
                    value={technologyData?.find((l) => l.id === technologyTypeId)}
                    dataItemKey="id"
                    textField="name"
                    onChange={onChangeTechnology}
                />
                <ValidationMessageView name="IaflDetails.TechnologyToBeInvestigated" validationModel={v} />
            </Forms.Group>
        </>
    );
}
