import { takeEvery } from "redux-saga/effects";
import * as Actions from "./actions";

import initialise from "./sagas/initialise";
import createSecureRequest from "./sagas/createSecureRequest";
import requestEmailVerification from "./sagas/requestEmailVerification";
import verifyEmailVerificationCodeRequested from "./sagas/verifyEmailVerificationCode";

export default function* watcherSaga() {
    yield takeEvery(Actions.actions.initialiseRequested, initialise);
    yield takeEvery(Actions.actions.createSecureRequestRequested, createSecureRequest);
    yield takeEvery(Actions.actions.requestEmailVerificationRequested, requestEmailVerification);
    yield takeEvery(Actions.actions.verifyEmailVerificationCodeRequested, verifyEmailVerificationCodeRequested);
}
