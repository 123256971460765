import * as Models from "../models/rootViewModel";
import { SecureRequestGroupTypeEnum } from "../../../../api/Client";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "company.userManagement.requestDetails.initialiseRequested",
    clearRequested = "company.userManagement.requestDetails.clearRequested",
    approveRejectRequestGroupRequested = "company.userManagement.requestDetails.approveRejectRequestGroupRequested",
    downloadDocumentRequested = "company.userManagement.requestDetails.downloadDocumentRequested",
    clearDownloadedDocumentRequested = "company.userManagement.requestDetails.clearDownloadedDocumentRequested",
    // user input
    viewChanged = "company.userManagement.requestDetails.viewChanged",
    // saga
    sagaCompleted = "company.userManagement.requestDetails.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

export interface IRequestDetailsKeyPayload {
    id: number;
    type: SecureRequestGroupTypeEnum;
}

export interface ISupportingDocumentKeyPayload {
    secureRequestGroupId: number;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(secureRequestGroupId: number, secureRequestGroupType: SecureRequestGroupTypeEnum): IAction<IRequestDetailsKeyPayload>;
    clearRequested(): IAction<undefined>;
    approveRejectRequestGroupRequested(): IAction<undefined>;
    clearDownloadedDocumentRequested(): IAction<undefined>;
    // user input
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    // saga
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (secureRequestGroupId: number, secureRequestGroupType: SecureRequestGroupTypeEnum): IAction<IRequestDetailsKeyPayload> {
        return {
            type: actions.initialiseRequested,
            payload: { id: secureRequestGroupId, type: secureRequestGroupType },
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    approveRejectRequestGroupRequested: function (): IAction<undefined> {
        return {
            type: actions.approveRejectRequestGroupRequested,
            payload: undefined,
        };
    },
    clearDownloadedDocumentRequested: function (): IAction<undefined> {
        return {
            type: actions.clearDownloadedDocumentRequested,
            payload: undefined,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
