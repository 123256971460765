// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, Link, Redirect } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../infrastructure/reduxRootReducer";
import * as Actions from "../redux/actions";
import * as Models from "../models/models";
// api
import { SecureEntityTypeEnum, SepiLinkTypeEnum } from "../../../api/Client";
// shell
import BodyLayout from "../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../layout/navigationItemTypes";
import SignInAction from "../../../shell/signIn/SignInActionController";
import { NotFoundController } from "../../error/ErrorControllers";
// common
import * as shellHelper from "../../../common/shell/shellHelper";
// company
import * as companyRouteHelper from "../../../company/companyRouteHelper";
// joint authority
import * as jointAuthorityRouteHelper from "../../../jointAuthority/jointAuthorityRouteHelper";
// geoscience Australia
import * as geoscienceAustraliaRouteHelper from "../../../geoscienceAustralia/geoscienceAustraliaRouteHelper";
// home
import * as homeRouteHelper from "../../../home/homeRouteHelper";

interface IOpenControllerProps extends Actions.IActionFactory {
    viewModel: Models.IOpenViewModel;
}

function OpenController(props: IOpenControllerProps) {
    const vm = props.viewModel;
    const isSignedIn = shellHelper.useIsSignedIn();

    // query parameters
    const { search } = useLocation();
    const query = new URLSearchParams(search);

    // only request link details after-sign in
    useEffect(() => {
        if (isSignedIn) {
            props.getLinkDataRequested(query);
        }
    }, [isSignedIn]); // eslint-disable-line react-hooks/exhaustive-deps

    if (vm.isConflict) return <NotFoundController />;

    return (
        <BodyLayout title={isSignedIn ? "Redirecting..." : "Sign In"} selectedLhsNavItem={Level1ItemTypeEnum.Common_Home_SignIn}>
            {isSignedIn ? (
                <RedirectView vm={vm} />
            ) : (
                <>
                    <div className="mb-2">
                        <div className="mb-1">You will need to sign-in to access the requested link. Please click the link below to start the sign-in process.</div>
                        <SignInAction className="mb-2" />
                    </div>
                    <div className="mb-2">
                        <div className="mb-1">
                            If you are having trouble signing in, please visit the <Link to={homeRouteHelper.signIn()}>Sign In</Link> page.
                        </div>
                    </div>
                </>
            )}
        </BodyLayout>
    );
}

// Note: Portal.Services is responsible for checking that link data corresponding to requested link type is returned and generating Not Found / Unauthorised errors as appropriate.
function RedirectView(props: { vm: Models.IOpenViewModel }): React.ReactElement {
    const vm = props.vm;

    // do not attempt to redirect until a link type has been provided.
    if (!vm.linkType) return <></>;

    const secureEntityType = shellHelper.useGetEntityType();

    switch (secureEntityType) {
        case SecureEntityTypeEnum.Company:
            return <CompanyRedirectView vm={vm} />;
        case SecureEntityTypeEnum.JointAuthority:
            return <JointAuthorityRedirectView vm={vm} />;
        case SecureEntityTypeEnum.GeoscienceAustralia:
            return <GeoscienceAustraliaRedirectView vm={vm} />;
        default:
            throw new Error("Invalid Entity Type encountered.");
    }
}

function CompanyRedirectView(props: { vm: Models.IOpenViewModel }): React.ReactElement {
    const vm = props.vm;

    switch (vm.linkType) {
        case SepiLinkTypeEnum.OpggsApplicationList:
            return <Redirect to={companyRouteHelper.opggsApplicationList()} />;
        case SepiLinkTypeEnum.DraftApplicationList:
            return <Redirect to={companyRouteHelper.draftApplicationList()} />;
        case SepiLinkTypeEnum.OeiApplicationList:
            return <Redirect to={companyRouteHelper.oeiApplicationList()} />;
        case SepiLinkTypeEnum.FinancialNotificationList:
            return <Redirect to={companyRouteHelper.financialNotificationList()} />;
        case SepiLinkTypeEnum.SecureRequestGroupList:
            return <Redirect to={companyRouteHelper.requestList()} />;
        case SepiLinkTypeEnum.TitleList:
            return <Redirect to={companyRouteHelper.titlesList()} />;
        case SepiLinkTypeEnum.Application:
            return <Redirect to={companyRouteHelper.applicationDetails(vm.applicationLink!.id)} />;
        case SepiLinkTypeEnum.DraftApplication:
            return <Redirect to={companyRouteHelper.draftApplicationDetails(vm.draftApplicationLink!.id, vm.draftApplicationLink!.type)} />;
        case SepiLinkTypeEnum.FinancialNotification:
            return <Redirect to={companyRouteHelper.financialNotificationDetails(vm.financialNotificationLink!.id, vm.financialNotificationLink!.isComplete)} />;
        case SepiLinkTypeEnum.SecureRequestGroup:
            return <Redirect to={companyRouteHelper.requestDetails(vm.secureRequestGroupLink!.id, vm.secureRequestGroupLink!.type)} />;
        case SepiLinkTypeEnum.Title:
            return <Redirect to={companyRouteHelper.titleDetails(vm.titleLink!.id)} />;
        case SepiLinkTypeEnum.Licence:
            return <Redirect to={companyRouteHelper.licenceDetails(vm.licenceLink!.id)} />;
        default:
            return <NotFoundController />;
    }
}

function JointAuthorityRedirectView(props: { vm: Models.IOpenViewModel }): React.ReactElement {
    const vm = props.vm;

    switch (vm.linkType) {
        // as these types are not supported, return not found
        case SepiLinkTypeEnum.OeiApplicationList:
        case SepiLinkTypeEnum.DraftApplicationList:
        case SepiLinkTypeEnum.FinancialNotificationList:
        case SepiLinkTypeEnum.DraftApplication:
        case SepiLinkTypeEnum.FinancialNotification:
        case SepiLinkTypeEnum.Title:
        case SepiLinkTypeEnum.Licence:
            return <NotFoundController />;

        case SepiLinkTypeEnum.OpggsApplicationList:
            return <Redirect to={jointAuthorityRouteHelper.applicationList()} />;
        case SepiLinkTypeEnum.Application:
            return <Redirect to={jointAuthorityRouteHelper.applicationDetails(vm.applicationLink!.id)} />;
        case SepiLinkTypeEnum.TitleList:
            return <Redirect to={jointAuthorityRouteHelper.titlesList()} />;
        case SepiLinkTypeEnum.SecureRequestGroupList:
            return <Redirect to={jointAuthorityRouteHelper.requestList()} />;
        case SepiLinkTypeEnum.SecureRequestGroup:
            return <Redirect to={jointAuthorityRouteHelper.requestDetails(vm.secureRequestGroupLink!.id, vm.secureRequestGroupLink!.type)} />;

        default:
            return <NotFoundController />;
    }
}

function GeoscienceAustraliaRedirectView(props: { vm: Models.IOpenViewModel }): React.ReactElement {
    const vm = props.vm;

    switch (vm.linkType) {
        case SepiLinkTypeEnum.SecureRequestGroupList:
            return <Redirect to={geoscienceAustraliaRouteHelper.requestList()} />;
        case SepiLinkTypeEnum.NopimsFeedback:
            return <Redirect to={geoscienceAustraliaRouteHelper.feedbackDetails(vm.feedbackLink!.id)} />;
        // as these types are not supported, return not found
        case SepiLinkTypeEnum.OpggsApplicationList:
        case SepiLinkTypeEnum.OeiApplicationList:
        case SepiLinkTypeEnum.DraftApplicationList:
        case SepiLinkTypeEnum.FinancialNotificationList:
        case SepiLinkTypeEnum.Application:
        case SepiLinkTypeEnum.DraftApplication:
        case SepiLinkTypeEnum.FinancialNotification:
        case SepiLinkTypeEnum.Title:
        case SepiLinkTypeEnum.TitleList:
        case SepiLinkTypeEnum.Licence:
        case SepiLinkTypeEnum.SecureRequestGroup:
            return <NotFoundController />;
        default:
            return <NotFoundController />;
    }
}

// connect redux
export default connect((state: IRootReduxState) => ({ viewModel: state.Shell_Open }), Actions.actionFactory)(OpenController);
