// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import { BasicInterpTypeEnum } from "../../../../../../api/Client";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_WellsBoreholes_ReportCreate;

export default function* create(action: Actions.IAction<Actions.ICreateRequestedPayload>) {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const { createAnother } = action.payload;

    try {
        yield put(shellHelper.createSetBusyAction());

        const createResult: CallHelper.CallResult<Client.CreateGeoscienceAustraliaReportResponseDto> = yield call(async () => await createReport(vm));

        if (createResult.IsSuccess) {
            // success
            let updatedVm = vm;

            yield put(Actions.actionFactory.sagaCompleted(updatedVm.refreshCreateResponse(createResult.Response!, createAnother!)));
        } else {
            // all other errors
            createResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function createReport(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.CreateGeoscienceAustraliaReportResponseDto>> {
    const request = new Client.CreateGeoscienceAustraliaReportRequestDto({
        wellBoreholeIds: vm.createDetails.wellBoreholes?.map((wb) => wb.id) ?? [],
        surveyAcquisitionIds: vm.createDetails.surveyAcquisitions?.map((sa) => sa.id) ?? [],
        reprocessingProjectIds: vm.createDetails.reprocessingProjects?.map((rp) => rp.id) ?? [],
        itemId: vm.createDetails.itemId,
        reportTypeId: vm.createDetails.reportType!.id,
        isCopiedToNopims: vm.createDetails.isCopiedToNopims,
        reportTitle: vm.createDetails.reportTitle,
        writtenDate: vm.createDetails.writtenDate,
        writtenDateOffset: GlobalHelpers.getDateOffsetMinutes(vm.createDetails.writtenDate),
        receivedDate: vm.createDetails.receivedDate,
        receivedDateOffset: GlobalHelpers.getDateOffsetMinutes(vm.createDetails.receivedDate),
        isConfidential: vm.createDetails.isConfidential!,
        confidentialityRemarks: vm.createDetails.confidentialityRemarks,
        basicInterpType: vm.createDetails.reportBasicInterpType?.value,
        basicRelevantDate:
            vm.createDetails.reportBasicInterpType?.value === BasicInterpTypeEnum.B || vm.createDetails.reportBasicInterpType?.value === BasicInterpTypeEnum.Bi
                ? vm.createDetails.basicRelevantDate
                : undefined,
        basicRelevantDateOffset:
            vm.createDetails.reportBasicInterpType?.value === BasicInterpTypeEnum.B || vm.createDetails.reportBasicInterpType?.value === BasicInterpTypeEnum.Bi
                ? GlobalHelpers.getDateOffsetMinutes(vm.createDetails.basicRelevantDate)
                : undefined,
        interpRelevantDate:
            vm.createDetails.reportBasicInterpType?.value === BasicInterpTypeEnum.I || vm.createDetails.reportBasicInterpType?.value === BasicInterpTypeEnum.Bi
                ? vm.createDetails.interpRelevantDate
                : undefined,
        interpRelevantDateOffset:
            vm.createDetails.reportBasicInterpType?.value === BasicInterpTypeEnum.I || vm.createDetails.reportBasicInterpType?.value === BasicInterpTypeEnum.Bi
                ? GlobalHelpers.getDateOffsetMinutes(vm.createDetails.interpRelevantDate)
                : undefined,
        releasedDate: vm.createDetails.releasedDate,
        releasedDateOffset: GlobalHelpers.getDateOffsetMinutes(vm.createDetails.releasedDate),
        companyId: vm.createDetails.company?.id,
        author: vm.createDetails.author,
        contractorId: vm.createDetails.contractor?.id,
        tapeId: vm.createDetails.tapeId,
        reportDataFormatId: vm.createDetails.reportDataFormat?.id,
        reportMediaTypeId: vm.createDetails.reportMediaType?.id,
        remarks: vm.createDetails.remarks,
        reportConditionStatusId: vm.createDetails.reportConditionStatus?.id,
        qcRemarks: vm.createDetails.qcRemarks,
        reportCopyStatusId: vm.createDetails.reportCopyStatus?.id,
        reportLoanStatusId: vm.createDetails.reportLoanStatus?.id,
        rddsStatusId: vm.createDetails.rddsStatus?.id,
        rddsComments: vm.createDetails.rddsComments,
        reportDataStatusId: vm.createDetails.reportDataStatus?.id,
        consignment: vm.createDetails.consignment,
    });

    const client = await ClientFactory.createGeoscienceAustraliaReportClient();
    return await CallHelper.call(() => client.createGeoscienceAustraliaReport(request));
}
