import * as Models from "../models/models";

// -------
// Actions
// -------

export enum actions {
    // lifecycle events
    clearRequested = "geoscienceAustralia.userManagement.userDetails.clearRequested",

    // saga
    initialise = "geoscienceAustralia.userManagement.userDetails.initialise",
    sagaCompleted = "geoscienceAustralia.userManagement.userDetails.sagaCompleted",
}

// --------
// Payloads
// --------

export interface IAction<T> {
    type: actions;
    payload: T;
}

// ---------
// Factories
// ---------

export interface IActionFactory {
    clearRequested(): IAction<undefined>;
    initialise(secureUserId: number): IAction<number>;
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    initialise: function (secureUserId: number): IAction<number> {
        return {
            type: actions.initialise,
            payload: secureUserId,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
