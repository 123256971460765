// models
import { IRootViewModel } from "../../models/models";
// api
import { NopimsTitleTypeEnum } from "../../../../../../api/Client";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import ExternalLinkView from "../../../../../../common/externalLink/ExternalLinkView";
import SimpleAuditEventsView from "../../../../../../common/audit/SimpleAuditEventsView";
import * as shellRouteHelper from "../../../../../../shell/shellRouteHelper";

interface IProps {
    vm: IRootViewModel;
}

export default function SurveyDetailsView(props: IProps): JSX.Element {
    const { vm } = props;
    const survey = vm.survey;

    return (
        <>
            {survey && (
                <>
                    <div className="card mb-2">
                        <div className="card-body">
                            <h2 className="card-title">General</h2>
                            <Forms.Row>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="identifierField" label="Survey ID" value={survey.identifier} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="nameField" label="Name" value={survey.name} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="statusField" label="Status" value={survey.status} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="confidentialityField" label="Confidentiality" value={GlobalHelpers.convertBooleanToConfidentialityValue(survey.isConfidential)} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="surveyType" label="Type" value={survey.type} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="aliasField" label="Alias(es)" value={survey.alias} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="basinsField" label="Basin(s)" value={survey.basinNames} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="legislationField" label="Legislation" value={survey.nopimsLegislation} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="operatorField" label="Operator" value={survey.operatorCompanyName} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.Label htmlFor="titlesField">Title Condition(s)</Forms.Label>
                                    <div>
                                        {survey.titles && survey.titles.length > 0 ? (
                                            <>
                                                {survey.titles.map((title, index) => (
                                                    <span key={index}>
                                                        {title.nopimsTitleType === NopimsTitleTypeEnum.Nopta && title.titleNumber && (
                                                            <ExternalLinkView href={shellRouteHelper.publicPortalTitleDetails(title.titleNumber)}>{title.titleNumber}</ExternalLinkView>
                                                        )}
                                                        {title.nopimsTitleType === NopimsTitleTypeEnum.Other && <span>{title.otherTitleNumber}</span>}
                                                        {index < survey.titles.length - 1 && <span>{", "}</span>}
                                                    </span>
                                                ))}
                                            </>
                                        ) : (
                                            <Forms.ReadonlyTextInput id="titlesField" />
                                        )}
                                    </div>
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="countriesField" label="Countries" value={survey.countryNames} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="regionsField" label="Region(s)" value={survey.regionNames} />
                                </Forms.QuarterColumn>
                                <Forms.FullColumn>
                                    <Forms.ReadonlyTextArea id="commentsField" label="Comments" value={survey.comments} />
                                </Forms.FullColumn>
                            </Forms.Row>
                        </div>
                    </div>
                    <div className="card mb-2">
                        <div className="card-body">
                            <h2 className="card-title">Dates</h2>
                            <Forms.Row>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="startDateField" label="Start Date" value={GlobalHelpers.toNoptaDateString(survey.startDate)} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="endDateField" label="End Date" value={GlobalHelpers.toNoptaDateString(survey.endDate)} />
                                </Forms.QuarterColumn>
                                {survey.showBasicRelevantDate && (
                                    <Forms.QuarterColumn>
                                        <Forms.ReadonlyField id="basicRelevantField" label="Basic Relevant Date" value={GlobalHelpers.toNoptaDateString(survey.basicRelevantDate)} />
                                    </Forms.QuarterColumn>
                                )}
                                {survey.showInterpretativeRelevantDate && (
                                    <Forms.QuarterColumn>
                                        <Forms.ReadonlyField
                                            id="interpretativeRelevantField"
                                            label="Interpretative Relevant Date"
                                            value={GlobalHelpers.toNoptaDateString(survey.interpretativeRelevantDate)}
                                        />
                                    </Forms.QuarterColumn>
                                )}
                                {survey.showSubset2dRelevantDate && (
                                    <Forms.QuarterColumn>
                                        <Forms.ReadonlyField id="subset2dRelevantDateField" label="Subset 2D Relevant Date" value={GlobalHelpers.toNoptaDateString(survey.subset2dRelevantDate)} />
                                    </Forms.QuarterColumn>
                                )}
                            </Forms.Row>
                        </div>
                    </div>
                    <SimpleAuditEventsView simpleAuditEvents={vm.survey?.audit} />
                </>
            )}
        </>
    );
}
