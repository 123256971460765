// controllers
import RootRedirectController from "./rootRedirect/Controller";
import OpenController from "./open/components/OpenController";
import DisclaimerController from "./footer/DisclaimerController";
import AccessibilityController from "./footer/AccessibilityController";
import * as ErrorControllers from "./error/ErrorControllers";
// infrastructure
import { IRouteItem, RouteBuilder } from "../infrastructure/routeItem";
// registration

const shellRouteBuilder: RouteBuilder = (isSignedIn: boolean) => {
    let routes = new Array<IRouteItem>();

    root(routes);
    layout(isSignedIn, routes);
    open(routes);

    return routes;
};

export default shellRouteBuilder;

function root(routes: IRouteItem[]) {
    routes.push({ path: "/", exact: true, element: <RootRedirectController /> });
}

function layout(isSignedIn: boolean, routes: IRouteItem[]) {
    // footer pages
    routes.push({ path: "/Disclaimer", exact: true, element: <DisclaimerController /> });
    routes.push({ path: "/Accessibility", exact: true, element: <AccessibilityController /> });

    // error pages
    routes.push({ path: "/NotFound", exact: true, element: <ErrorControllers.NotFoundController /> });
    routes.push({ path: "/Conflict", exact: true, element: <ErrorControllers.ConflictController /> });
    routes.push({ path: "/Unauthorised", exact: true, element: <ErrorControllers.UnauthorisedController /> });
    routes.push({ path: "/InternalServerError", exact: true, element: <ErrorControllers.InternalServerErrorController /> });
    routes.push({ path: "/ServiceUnavailable", exact: true, element: <ErrorControllers.ServiceUnavailableController /> });

    // default
    // - if you're signed in, show pages as not found
    if (isSignedIn) routes.push({ exact: true, element: <ErrorControllers.NotFoundController />, isDefault: true });
    // - if you're not signed in, don't give away that a page does or doesn't exist
    if (!isSignedIn) routes.push({ exact: true, element: <ErrorControllers.UnauthorisedController />, isDefault: true });
}

function open(routes: IRouteItem[]) {
    // always map the open route, even if you're signed in
    // you'll typically land here immediately after signing in from an open link
    // when coming back from a sign-in, the OpenController will force the page to redirect
    routes.push({ path: "/Open", exact: true, element: <OpenController /> });
}
