// framework
import { CheckCircleFillIcon, CircleIcon } from "@primer/octicons-react";
// api
import * as Client from "../../../../api/Client";

export default function ProgressChecklistView(props: { vm: Client.ProgressChecklistItemDto[] | undefined }) {
    const checklist = props.vm;

    if (!checklist) return <></>;

    return (
        <div className="card mt-2">
            <div className="card-body">
                <h4 className="card-title">Required Steps</h4>
                <ul className="list-group">
                    {checklist.map((item, i) => (
                        <li key={i} className="list-group-item border-0">
                            {item.isChecked ? <CheckCircleFillIcon size="small" className="me-2" fill="blue" /> : <CircleIcon size="small" className="me-2" fill="silver" />}
                            <span>{item.description}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
