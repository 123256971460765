// redux
import * as Models from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";

export default function CoCnDetailsReadonlyView(props: { vm: Models.ICoCnDetailsViewModel }) {
    const vm = props.vm;

    return (
        <>
            <Forms.Group>
                <Forms.ReadonlyField label="Current Company Name" id="currentCompanyNameField" value={vm.currentCompanyName} />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="ACN/ARBN" id="acnOrArbnField" value={vm.acnOrArbn} />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="New Company Name" id="newCompanyNameField" value={vm.newCompanyName} />
            </Forms.Group>
        </>
    );
}
