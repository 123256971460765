import { takeEvery } from "redux-saga/effects";
import * as Actions from "./actions";

import getReferenceData from "./sagas/getReferenceData";
import createFormRequest from "./sagas/createFormRequest";

export default function* watcherSaga() {
    yield takeEvery(Actions.actions.getReferenceDataRequested, getReferenceData);
    yield takeEvery(Actions.actions.sagaCreateFormRequestRequested, createFormRequest);
}
