import * as ClientFactory from "../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";
import * as Models from "../../models/models";

export async function getCompanyOpggsDraftApplicationDetailsTitleholderForeignInvestment(id: number): Promise<Client.GetCompanyOpggsDraftApplicationDetailsTitleholderForeignInvestmentResponseDto> {
    const client = await ClientFactory.createCompanyOpggsDraftApplicationDetailsClient();
    return await CallHelper.simpleCall(() =>
        client.getCompanyOpggsDraftApplicationDetailsTitleholderForeignInvestment(new Client.GetCompanyOpggsDraftApplicationDetailsTitleholderForeignInvestmentRequestDto({ id: id }))
    );
}

export async function saveCompanyOpggsDraftApplicationDetailsTitleholderForeignInvestment(
    vm: Models.IRootViewModel
): Promise<CallHelper.CallResult<Client.SaveCompanyOpggsDraftApplicationDetailsTitleholderForeignInvestmentResponseDto>> {
    const client = await ClientFactory.createCompanyOpggsDraftApplicationDetailsClient();

    return await CallHelper.call(() =>
        client.saveCompanyOpggsDraftApplicationDetailsTitleholderForeignInvestment(
            new Client.SaveCompanyOpggsDraftApplicationDetailsTitleholderForeignInvestmentRequestDto({
                id: vm.id!,
                versionNumber: vm.versionNumber!,
                titleholderForeignInvestmentApprovals: vm.titleholderForeignInvestmentTypeApprovals?.map((t) => {
                    return new Client.SaveCompanyOpggsDraftApplicationDetailsTitleholderForeignInvestmentApprovalDto({
                        companyId: t.companyId,
                        foreignInvestmentApprovalType: t.foreignInvestmentApprovalType?.foreignInvestmentApprovalType,
                    });
                }),
            })
        )
    );
}
