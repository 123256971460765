// framework
import { useEffect, useState } from "react";
import { ValidationModel } from "../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../common/validation/ValidationMessageView";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { CheckCircleIcon } from "@primer/octicons-react";
import { EmailVerificationStatusEnum } from "../../../../api/Client";
import StatusMessagesAlertsView from "../../../../common/alerts/StatusMessagesAlertsView";

export function EmailVerificationView(props: { vm: Models.IRootViewModel; v: ValidationModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const { token, status, isVisible, verificationCode, requestEmailVerificationStatusMessages, verifyEmailVerificationStatusMessages } = vm.user.newUserRequest.emailVerification;
    const actions = props.actions;
    const v = props.v;

    const [state, setState] = useState<Models.IEmailVerificationViewModel>({
        isVisible: false,
        token: "",
        verificationCode: "",
        status: status,
        requestEmailVerificationStatusMessages: undefined,
        verifyEmailVerificationStatusMessages: undefined,
    });

    useEffect(() => {
        setState({
            isVisible: isVisible,
            token: token,
            verificationCode: verificationCode,
            status: status,
            requestEmailVerificationStatusMessages: requestEmailVerificationStatusMessages,
            verifyEmailVerificationStatusMessages: verifyEmailVerificationStatusMessages,
        });
    }, [token, verificationCode, status, isVisible, requestEmailVerificationStatusMessages, verifyEmailVerificationStatusMessages]);

    function isValid() {
        return v.isValid("NewUserRequest.Email");
    }

    function onVerify() {
        actions.verifyEmailVerificationCodeRequested();
    }

    function onClick() {
        // if it's a valid email, then start requesting verification
        actions.requestEmailVerificationRequested();
    }

    function onCancel() {
        setState({ ...state, isVisible: false });
    }

    function onChanged(values: any) {
        setState({ ...state, ...values });
    }

    function updateViewModel() {
        actions.viewChanged(vm.refreshEmailVerificationUpdated({ ...state }));
    }

    return (
        <>
            <div className="mt-2">
                {state.status !== EmailVerificationStatusEnum.Verified && (
                    <button className="btn btn-outline-secondary" type="button" disabled={!isValid()} title="A verification e-mail will be sent to this address." onClick={onClick}>
                        Verify Email
                    </button>
                )}
                {state.status === EmailVerificationStatusEnum.Verified && (
                    <button className="btn btn-outline-success" type="button" disabled={true} title="This e-mail address has been verified.">
                        <CheckCircleIcon className="me-2" size="small" />
                        Email Verified
                    </button>
                )}
                {requestEmailVerificationStatusMessages && !requestEmailVerificationStatusMessages.isSuccess && (
                    <div className="mt-2">
                        <StatusMessagesAlertsView statusMessages={vm.user.newUserRequest.emailVerification.requestEmailVerificationStatusMessages} scrollWindowToTop={false} />
                    </div>
                )}
                <ValidationMessageView name="EmailVerification.Status" validationModel={v} />
            </div>
            {state.isVisible && (
                <Dialog>
                    <p>A verification code has been sent to your email address. Please enter the code.</p>
                    <div className="text-center">
                        <input width={200} onChange={(e) => onChanged({ verificationCode: e.target.value })} onBlur={updateViewModel} autoFocus />
                        <ValidationMessageView name="EmailVerification.VerificationCode" validationModel={v} />
                    </div>
                    <DialogActionsBar>
                        <button type="button" className="btn btn-primary m-2" style={{ width: 75 }} onClick={onVerify}>
                            Verify
                        </button>
                        <button type="button" className="btn btn-primary m-2" style={{ width: 75 }} onClick={onCancel}>
                            Cancel
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
        </>
    );
}
