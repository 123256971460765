// redux
import { IRootViewModel, ViewStateEnum } from "../../models/models";
// common
import SimpleAlertView from "../../../../../../../common/simpleAlerts/SimpleAlertView";

// using a warnings view in lieu of having a warnings capability built into our validation framework
export default function SupportingDocumentationWarningsView(props: { vm: IRootViewModel }): React.ReactElement {
    const { vm } = props;
    // don't show warnings while loading
    if (vm.viewState === ViewStateEnum.Initialising) return <></>;

    return (
        <>
            {vm.hasUnavailableSupportingDocumentationDocuments && (
                <SimpleAlertView alertType="warning" dismissable={false} message="One or more files are unable to be downloaded via Secure Portal. Please contact NOPTA if you require a copy." />
            )}
            {vm.hasSupportingDocumentationSensitiveToCompanyAuthorisationWarning && (
                <SimpleAlertView alertType="warning" dismissable={false} message="Some documents may no longer be visible due to a change in Companies on this Application." />
            )}
        </>
    );
}
