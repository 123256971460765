// api
import * as ClientFactory from "../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";

export async function getJointAuthorityOpggsApplicationDetailsDocumentation(id: string): Promise<Client.GetJointAuthorityOpggsApplicationDetailsDocumentationResponseDto> {
    const client = await ClientFactory.createJointAuthorityOpggsApplicationDetailsClient();
    return await CallHelper.simpleCall(() =>
        client.getJointAuthorityOpggsApplicationDetailsDocumentation(new Client.GetJointAuthorityOpggsApplicationDetailsDocumentationRequestDto({ applicationId: id }))
    );
}
