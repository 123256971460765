// redux
import * as Models from "../../models/models";
// common
import FormDocumentationControl from "../../controls/formDocumentation/components/Controller";
import * as LegislativeFormsHelper from "../../../common/LegislativeFormsHelper";

interface IProps {
    id: number;
    versionNumber: number;
    viewState: Models.ViewStateEnum;
    inEditTask: Models.FormRequestTaskEnum | undefined;
    onEdit: () => void;
    onSave: () => void;
    onCancel: () => void;
    lastRefreshedDatetime: Date | undefined;
}

export default function FormDocumentationView(props: IProps) {
    return (
        <FormDocumentationControl
            id={props.id}
            versionNumber={props.versionNumber}
            cardTitle="Legislative Form"
            showCommands={LegislativeFormsHelper.showCommands(props.viewState, Models.ViewStateEnum.ForSigning)}
            disableEdit={LegislativeFormsHelper.isInEdit(props.inEditTask)}
            lastRefreshedDatetime={props.lastRefreshedDatetime}
            onEdit={props.onEdit}
            onSave={props.onSave}
            onCancel={props.onCancel}
            className="mb-2"
        />
    );
}
