// framework
import { Link } from "react-router-dom";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/rootViewModel";
// kendo
import { GridColumn } from "@progress/kendo-react-grid";
// other
import * as companyRouteHelper from "../../../../companyRouteHelper";
import * as cellHelper from "../../../common/permissionCellHelper";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import CustomGridCell from "../../../../../common/kendoGrid/CustomGridCell";
import RoleGrid from "../../../../../common/roleGrid/RoleGrid";

export default function CompanyPermissionsView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }): React.ReactElement {
    const vm = props.vm;
    const actions = props.actions;

    // buttons
    const onEditClicked = () => {
        actions.viewChanged(vm.setEditState());
    };

    // view
    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">View Permissions</h2>
                    <RoleGrid
                        model={vm.permissions}
                        roleGroups={vm.roleConfiguration.roleGroups}
                        showAdmin
                        showSigner
                        preColumns={[
                            <GridColumn
                                key="username"
                                field="username"
                                title="Username"
                                width={200}
                                cell={(props) => (
                                    <CustomGridCell gridCellProps={props}>
                                        {(!props.dataItem.isSecureUserSignedIn && <Link to={companyRouteHelper.userDetails(props.dataItem.secureUserId)}>{props.dataItem.username}</Link>) ||
                                            props.dataItem.username}
                                    </CustomGridCell>
                                )}
                            />,
                            <GridColumn key="fullName" field="fullName" title="Full Name" width={200} />,
                        ]}
                        postColumns={[
                            <GridColumn key="information" title="Information" width={160} cell={cellHelper.getInformationCell} />,
                            <GridColumn key="lastUpdatedBy" field="lastUpdatedBy" title="Updated By" width={200} />,
                            <GridColumn key="lastUpdatedDatetime" field="lastUpdatedDatetime" title="Updated Date (AWST)" width={250} format={GlobalHelpers.NoptaDatetimeFormatForKendo} />,
                        ]}
                    />
                </div>
            </div>
            {vm.permissions.some((p) => p.canEdit()) && (
                <button className="btn btn-outline-primary" type="button" onClick={onEditClicked}>
                    Edit Access
                </button>
            )}
        </>
    );
}
