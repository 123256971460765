// api
import * as Client from "../../../../api/Client";
// models
import { BaseRoleViewModel, IRoleViewModel } from "../../../../common/roleGrid/RoleViewModel";

export interface IPendingRequestViewModel extends IRoleViewModel {
    secureRequestGroupId: number;
    secureRequestGroupCode?: string | undefined;
    requestDatetime: Date;
    companyName?: string | undefined;
}

export class PendingRequestViewModel extends BaseRoleViewModel implements IPendingRequestViewModel {
    constructor(roleRelationships: Array<Client.GetShellSecureUserRoleConfigurationRoleRelationshipDto>, data: Client.ICompanyAccountMyDetailsPendingRequestsDto) {
        super(roleRelationships, data.rolesRequested);

        this.secureRequestGroupId = data.secureRequestGroupId;
        this.secureRequestGroupCode = data.secureRequestGroupCode;
        this.requestDatetime = data.requestDatetime;
        this.companyName = data.companyName;
    }

    public readonly secureRequestGroupId!: number;
    public readonly secureRequestGroupCode?: string | undefined;
    public readonly requestDatetime!: Date;
    public readonly companyName?: string | undefined;

    public getKey(): number {
        return this.secureRequestGroupId;
    }
    public canEdit(): boolean {
        return false;
    }
}
