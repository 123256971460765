import BodyLayout from "../../common/shell/BodyLayoutController";
import ExternalLink from "../../common/externalLink/ExternalLinkView";
import EmailLinkView from "../../common/externalLink/EmailLinkView";

export default function AccessibilityController() {
    return (
        <BodyLayout title="Accessibility">
            <p>
                We are committed to providing websites that are accessible to everyone. This is a requirement under the{" "}
                <ExternalLink href="https://www.legislation.gov.au/Series/C2004A04426">Disability Discrimination Act 1992</ExternalLink>.
            </p>
            <p>
                We aim to meet the <ExternalLink href="https://www.w3.org/TR/WCAG20/">Web Content Accessibility Guidelines (WCAG) 2.0</ExternalLink> standard at level AA. This is the web standard
                recommended by the Australian Government.
            </p>
            <h2>Browser compatibility</h2>
            <p>If you have issues using NEATS, there could be a browser compatibility problem.</p>
            <p>The following browsers are compatible with NEATS:</p>
            <table className="table table-striped table-bordered w-50">
                <thead className="table-dark">
                    <tr>
                        <th scope="col">Web browser</th>
                        <th scope="col">Series version</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Edge</td>
                        <td>80 or later</td>
                    </tr>
                    <tr>
                        <td>Firefox</td>
                        <td>65 or later</td>
                    </tr>
                    <tr>
                        <td>Safari</td>
                        <td>13 or later</td>
                    </tr>
                    <tr>
                        <td>Opera</td>
                        <td>58 or later</td>
                    </tr>
                    <tr>
                        <td>Google Chrome</td>
                        <td>72 or later</td>
                    </tr>
                </tbody>
            </table>
            <h2>Contact us</h2>
            <p>We want our website to meet the highest accessibility level possible.</p>
            <p>
                If you’re having difficulty accessing our documents or experience any accessibility or usability issues with our website please contact us at{" "}
                <EmailLinkView emailAddress="neats.admin@nopta.gov.au" />.
            </p>
        </BodyLayout>
    );
}
