// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_ReportsAndData_SurveyDataLines;

export default function* deleteSurveyDataAllLines(action: Actions.IAction<Actions.IDeleteRequestedPayload>) {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const { surveyDataId, surveyDataVersionNumber } = action.payload;

    try {
        yield put(shellHelper.createSetBusyAction());

        const deleteResult: CallHelper.CallResult<Client.DeleteGeoscienceAustraliaSurveyDataAllLinesResponseDto> = yield call(
            async () => await deleteSurveyDataAllLinesCall(surveyDataId, surveyDataVersionNumber)
        );

        if (deleteResult.IsSuccess) {
            // success
            let updatedVm = vm;
            const r = deleteResult.Response!;
            if (r.statusMessages.isSuccess) {
                const getLinesResponse: CallHelper.CallResult<Client.GetGeoscienceAustraliaSurveyDataLinesResponseDto> = yield call(async () => await getSurveyDataLines(vm));
                updatedVm = vm.refreshLines(getLinesResponse.Response!);
            }
            yield put(Actions.actionFactory.sagaCompleted(updatedVm.refreshStatusMessages(deleteResult.Response!.statusMessages)));
        } else if (deleteResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict(true)));
        } else {
            // all other errors
            deleteResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function deleteSurveyDataAllLinesCall(surveyDataId: number, surveyDataVersionNumber: number): Promise<CallHelper.CallResult<Client.DeleteGeoscienceAustraliaSurveyDataAllLinesResponseDto>> {
    const request = new Client.DeleteGeoscienceAustraliaSurveyDataAllLinesRequestDto({
        surveyDataId: surveyDataId,
        surveyDataVersionNumber: surveyDataVersionNumber,
    });

    const client = await ClientFactory.createGeoscienceAustraliaSurveyDataClient();
    return await CallHelper.call(() => client.deleteGeoscienceAustraliaSurveyDataAllLines(request));
}

async function getSurveyDataLines(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.GetGeoscienceAustraliaSurveyDataLinesResponseDto>> {
    const request = new Client.GetGeoscienceAustraliaSurveyDataLinesRequestDto({
        surveyDataId: vm.surveyDataId!,
    });

    const client = await ClientFactory.createGeoscienceAustraliaSurveyDataClient();
    return await CallHelper.call(() => client.getGeoscienceAustraliaSurveyDataLines(request));
}
