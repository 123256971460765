// framework
import { useState, useEffect } from "react";
import { Collapse } from "react-bootstrap";
// common
import { SearchAdvancedTypeEnum } from "../../api/Client";
import * as Forms from "../../common/forms/BootstrapForms";
import HelpView from "../../common/help/HelpView";
import KendoDropDownList from "../../common/kendo/KendoDropDownList";
import KendoLargeMultiSelect from "../../common/kendo/KendoLargeMultiSelect";
// model
import { IAdvancedSearchFilter, IAdvancedSearchFilterItem, IAdvancedSearchReferenceData } from "./models";

interface IProps {
    searchText: string;
    searchTextDescription: string;
    advancedSearchFilter: IAdvancedSearchFilter;
    advancedSearchDescription: string;
    advancedSearchReferenceData: IAdvancedSearchReferenceData;
    onSearchSubmit: (value: string, filter: IAdvancedSearchFilter) => void;
    onGridClear: () => void;
    showAdvanced: boolean;
    setShowAdvanced: (showAdvanced: boolean) => void;
}

export default function SearchBarView(props: IProps): JSX.Element {
    const {
        searchText,
        searchTextDescription,
        advancedSearchFilter,
        advancedSearchDescription,
        advancedSearchReferenceData: referenceData,
        onSearchSubmit,
        onGridClear,
        showAdvanced,
        setShowAdvanced,
    } = props;

    const [searchValue, setSearchValue] = useState<string>("");
    const [advancedFilterValue, setAdvancedFilterValue] = useState<IAdvancedSearchFilter>(advancedSearchFilter);

    function onSetShowAdvanced() {
        if (showAdvanced) {
            onSearchSubmit(searchValue, advancedSearchFilter.clearItems());
        }
        setShowAdvanced(!showAdvanced);
    }

    function onClear() {
        setSearchValue("");
        onGridClear();
        setShowAdvanced(false);
    }

    useEffect(() => {
        setSearchValue(searchText);
    }, [searchText]);

    useEffect(() => {
        setAdvancedFilterValue(advancedSearchFilter);
    }, [advancedSearchFilter]);

    return (
        <>
            <div className="row my-2">
                <div className="col-3">
                    <input
                        id="searchText"
                        className="form-control"
                        maxLength={100}
                        type="text"
                        placeholder="Search"
                        value={searchValue}
                        onKeyUp={(e) => {
                            if (e.key === "Enter") onSearchSubmit(searchValue, advancedFilterValue);
                        }}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                </div>
                <button type="button" className="btn btn-secondary me-2 col-auto" onClick={() => onSearchSubmit(searchValue, advancedFilterValue)}>
                    Search
                </button>
                <button type="button" className="btn btn-secondary me-2 col-auto" onClick={onSetShowAdvanced}>
                    Advanced
                </button>
                <button type="button" className="btn btn-secondary me-2 col-auto" onClick={onClear}>
                    Clear
                </button>
            </div>
            <Collapse in={showAdvanced}>
                <div className="card my-2">
                    <div className="card-body">
                        <div>
                            {advancedFilterValue.filterItems.map((filterItem: IAdvancedSearchFilterItem, index) => {
                                return (
                                    <div key={index} className="mb-3">
                                        <Forms.Row>
                                            {index !== 0 && (
                                                <div className="col-1">
                                                    <Forms.Label htmlFor="operationTypeField">Operation</Forms.Label>
                                                    <KendoDropDownList
                                                        id="operationTypeField"
                                                        value={filterItem.operationType}
                                                        data={referenceData.searchOperations}
                                                        keyField="value"
                                                        textField="name"
                                                        onChange={(value) => setAdvancedFilterValue(advancedFilterValue.modifyItem(index, { ...filterItem, operationType: value }))}
                                                    />
                                                </div>
                                            )}
                                            <div className={index === 0 ? "col-4" : "col-3"}>
                                                <>
                                                    <Forms.Label htmlFor="filterTypeField">Type</Forms.Label>
                                                    <KendoDropDownList
                                                        id="filterTypeField"
                                                        value={filterItem.searchType}
                                                        data={referenceData.searchTypes}
                                                        keyField="value"
                                                        textField="name"
                                                        onChange={(value) =>
                                                            setAdvancedFilterValue(
                                                                advancedFilterValue.modifyItem(index, {
                                                                    ...filterItem,
                                                                    searchType: value!,
                                                                    titles: [],
                                                                    surveys: [],
                                                                    acquisitions: [],
                                                                    wells: [],
                                                                    wellBoreholes: [],
                                                                    reprocessings: [],
                                                                    projects: [],
                                                                    enos: [],
                                                                    text: "",
                                                                })
                                                            )
                                                        }
                                                    />
                                                </>
                                            </div>
                                            <div className="col-7">
                                                {filterItem.searchType?.value === SearchAdvancedTypeEnum.Titles && (
                                                    <>
                                                        <Forms.Label htmlFor="filterTitles">Title(s)</Forms.Label>
                                                        <KendoLargeMultiSelect
                                                            id="filterTitles"
                                                            value={filterItem.titles}
                                                            data={referenceData.titles}
                                                            keyField="titleId"
                                                            textField="titleNumber"
                                                            recordsToShow={100}
                                                            onChange={(value) => setAdvancedFilterValue(advancedFilterValue.modifyItem(index, { ...filterItem, titles: value }))}
                                                        />
                                                    </>
                                                )}
                                                {filterItem.searchType?.value === SearchAdvancedTypeEnum.Reprocessings && (
                                                    <>
                                                        <Forms.Label htmlFor="filterProjects">Reprocessing(s)</Forms.Label>
                                                        <KendoLargeMultiSelect
                                                            id="filterReprocessing"
                                                            value={filterItem.reprocessings}
                                                            data={referenceData.reprocessings}
                                                            keyField="id"
                                                            textField="name"
                                                            recordsToShow={100}
                                                            onChange={(value) => setAdvancedFilterValue(advancedFilterValue.modifyItem(index, { ...filterItem, reprocessings: value }))}
                                                        />
                                                    </>
                                                )}
                                                {filterItem.searchType?.value === SearchAdvancedTypeEnum.Projects && (
                                                    <>
                                                        <Forms.Label htmlFor="filterProjects">Project(s)</Forms.Label>
                                                        <KendoLargeMultiSelect
                                                            id="filterProjects"
                                                            value={filterItem.projects}
                                                            data={referenceData.projects}
                                                            keyField="id"
                                                            textField="displayName"
                                                            recordsToShow={100}
                                                            onChange={(value) => setAdvancedFilterValue(advancedFilterValue.modifyItem(index, { ...filterItem, projects: value }))}
                                                        />
                                                    </>
                                                )}
                                                {filterItem.searchType?.value === SearchAdvancedTypeEnum.Surveys && (
                                                    <>
                                                        <Forms.Label htmlFor="filterSurveys">Survey(s)</Forms.Label>
                                                        <KendoLargeMultiSelect
                                                            id="filterSurveys"
                                                            value={filterItem.surveys}
                                                            data={referenceData.surveys}
                                                            keyField="id"
                                                            textField="name"
                                                            recordsToShow={100}
                                                            onChange={(value) => setAdvancedFilterValue(advancedFilterValue.modifyItem(index, { ...filterItem, surveys: value }))}
                                                        />
                                                    </>
                                                )}
                                                {filterItem.searchType?.value === SearchAdvancedTypeEnum.Acquisitions && (
                                                    <>
                                                        <Forms.Label htmlFor="filterAcquisitions">Acquisition(s)</Forms.Label>
                                                        <KendoLargeMultiSelect
                                                            id="filterAcquisitions"
                                                            value={filterItem.acquisitions}
                                                            data={referenceData.acquisitions}
                                                            keyField="acquisitionId"
                                                            textField="acquisitionDisplay"
                                                            recordsToShow={100}
                                                            onChange={(value) => setAdvancedFilterValue(advancedFilterValue.modifyItem(index, { ...filterItem, acquisitions: value }))}
                                                        />
                                                    </>
                                                )}
                                                {filterItem.searchType?.value === SearchAdvancedTypeEnum.Wells && (
                                                    <>
                                                        <Forms.Label htmlFor="filterWells">Well(s)</Forms.Label>
                                                        <KendoLargeMultiSelect
                                                            id="filterWells"
                                                            value={filterItem.wells}
                                                            data={referenceData.wells}
                                                            keyField="id"
                                                            textField="name"
                                                            recordsToShow={100}
                                                            onChange={(value) => setAdvancedFilterValue(advancedFilterValue.modifyItem(index, { ...filterItem, wells: value }))}
                                                        />
                                                    </>
                                                )}
                                                {filterItem.searchType?.value === SearchAdvancedTypeEnum.Borehole && (
                                                    <>
                                                        <Forms.Label htmlFor="filterBoreholes">Borehole(s)</Forms.Label>
                                                        <KendoLargeMultiSelect
                                                            id="filterBoreholes"
                                                            value={filterItem.wellBoreholes}
                                                            data={referenceData.wellBoreholes}
                                                            keyField="id"
                                                            textField="name"
                                                            recordsToShow={100}
                                                            onChange={(value) => setAdvancedFilterValue(advancedFilterValue.modifyItem(index, { ...filterItem, wellBoreholes: value }))}
                                                        />
                                                    </>
                                                )}
                                                {filterItem.searchType?.value === SearchAdvancedTypeEnum.Eno && (
                                                    <>
                                                        <Forms.Label htmlFor="filterEnos">ENO(s) (Wells, Boreholes, Surveys, Acquisitions, Reprocessings, Projects)</Forms.Label>
                                                        <KendoLargeMultiSelect
                                                            id="filterEnos"
                                                            value={filterItem.enos}
                                                            data={referenceData.enos}
                                                            keyField="eno"
                                                            textField="name"
                                                            recordsToShow={100}
                                                            onChange={(value) => setAdvancedFilterValue(advancedFilterValue.modifyItem(index, { ...filterItem, enos: value }))}
                                                        />
                                                    </>
                                                )}
                                                {filterItem.searchType?.value === SearchAdvancedTypeEnum.EnoList && (
                                                    <Forms.TextInput
                                                        label="ENO List (comma separated)"
                                                        id="filterEnoList"
                                                        value={filterItem.items}
                                                        onChange={(value) => setAdvancedFilterValue(advancedFilterValue.modifyItem(index, { ...filterItem, items: value }))}
                                                    />
                                                )}
                                                {filterItem.searchType?.value === SearchAdvancedTypeEnum.ItemIdList && (
                                                    <Forms.TextInput
                                                        label="Item ID List (comma separated)"
                                                        id="filterItemIdList"
                                                        value={filterItem.items}
                                                        onChange={(value) => setAdvancedFilterValue(advancedFilterValue.modifyItem(index, { ...filterItem, items: value }))}
                                                    />
                                                )}
                                                {filterItem.searchType?.value === SearchAdvancedTypeEnum.ConfidentialityRemarks && (
                                                    <Forms.TextInput
                                                        label="Confidentiality Remarks"
                                                        id="filterConfidentialityRemarks"
                                                        value={filterItem.text}
                                                        onChange={(value) => setAdvancedFilterValue(advancedFilterValue.modifyItem(index, { ...filterItem, text: value }))}
                                                    />
                                                )}
                                                {filterItem.searchType?.value === SearchAdvancedTypeEnum.Comments && (
                                                    <Forms.TextInput
                                                        label="Comments"
                                                        id="filterComments"
                                                        value={filterItem.text}
                                                        onChange={(value) => setAdvancedFilterValue(advancedFilterValue.modifyItem(index, { ...filterItem, text: value }))}
                                                    />
                                                )}
                                            </div>
                                            <div className="col-1 mt-auto">
                                                {
                                                    <button
                                                        type="button"
                                                        style={{ width: "100px" }}
                                                        className="btn btn-primary"
                                                        disabled={advancedFilterValue.filterItems.length === 1}
                                                        onClick={() => setAdvancedFilterValue(advancedFilterValue.removeItem(index))}
                                                    >
                                                        Delete
                                                    </button>
                                                }
                                            </div>
                                        </Forms.Row>
                                    </div>
                                );
                            })}
                            <Forms.Row>
                                <div className="col-11" />
                                <div className="col-1">
                                    <button type="button" className="btn btn-primary" style={{ width: "100px" }} onClick={() => setAdvancedFilterValue(advancedFilterValue.addItem())}>
                                        Add
                                    </button>
                                </div>
                            </Forms.Row>
                        </div>
                    </div>
                </div>
            </Collapse>
            <HelpView title="What does this search on?" className="mb-2">
                {searchTextDescription}
                <br />
                {advancedSearchDescription}
            </HelpView>
        </>
    );
}
