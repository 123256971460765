// framework
import { clone, cloneDeep } from "lodash";
// api
import * as Client from "../../../../api/Client";
// redux
import { IRequestDetailsRoleViewModel, RequestDetailsRoleViewModel } from "./requestDetailsRoleViewModel";
// other
import { RoleCategoryEnum } from "../../../../common/roleGrid/RoleViewModel";
import { BaseCompanyRoleRootViewModel, ICompanyRoleRootViewModel } from "../../../common/CompanyRoleRootViewModel";

export interface ISecureRequestRejectionsDetailsViewModel {
    isSuspicious?: boolean | undefined;
    comments?: string | undefined;
}

export interface ISecureRequestCompanyStandardRoleDecisionViewModel {
    isApproved?: boolean | undefined;
}

export interface ISecureRequestCompanySignerDecisionViewModel {
    isApproved?: boolean | undefined;
    isIdentificationAndVerificationConfirmed?: boolean | undefined;
    isRoleConfirmed?: boolean | undefined;
    isAuthorityConfirmed?: boolean | undefined;
    isElectronicSignatureConfirmed?: boolean | undefined;
    isPoaValidityConfirmed?: boolean | undefined;
}

export interface ISecureRequestCompanyStandardRoleDetailsViewModel extends ISecureRequestCompanyStandardRoleDecisionViewModel {
    existingRoles?: IRequestDetailsRoleViewModel;
    requestedRoles?: IRequestDetailsRoleViewModel;
    grantedRoles?: IRequestDetailsRoleViewModel;
    hasAllGeneralRoles?: boolean;
}

export interface ISecureRequestCompanySignerDetailsViewModel extends ISecureRequestCompanySignerDecisionViewModel {
    isCompanySignerPoaRequest?: boolean;
    csAuthoritySupportingDocument?: Client.SupportingDocumentDto | undefined;
    csPoaSupportingDocument?: Client.SupportingDocumentDto | undefined;
    downloadedDocument?: Client.SecurePortalDocumentDto | undefined;
}

export interface IRootViewModel extends ICompanyRoleRootViewModel {
    id?: number;
    versionNumber?: number;
    isNewUserRequest?: boolean;
    isCompanyStandardRoleRequest?: boolean;
    isCompanySignerRequest?: boolean;

    summary: Client.CompanyUserManagementRequestDetailsSummaryDto;
    newUserDetails?: Client.CompanyUserManagementRequestDetailsNewUserDetailsDto;
    existingUserDetails?: Client.CompanyUserManagementRequestDetailsExistingUserDetailsDto;
    companyStandardRoleDetails: ISecureRequestCompanyStandardRoleDetailsViewModel;
    companySignerDetails: ISecureRequestCompanySignerDetailsViewModel;
    rejectionDetails: ISecureRequestRejectionsDetailsViewModel;

    service: {
        statusMessages?: Client.StatusMessagesDto;
    };

    isDirty: boolean;
    isConflict: boolean;

    refreshRequestDetails(
        summary: Client.CompanyUserManagementRequestDetailsSummaryDto,
        newUserDetails?: Client.CompanyUserManagementRequestDetailsNewUserDetailsDto | undefined,
        existingUserDetails?: Client.CompanyUserManagementRequestDetailsExistingUserDetailsDto | undefined,
        companyStandardRoleDetails?: Client.CompanyUserManagementRequestDetailsStandardRoleDetailsDto | undefined,
        companySignerDetails?: Client.CompanyUserManagementRequestDetailsSignerDetailsDto | undefined
    ): IRootViewModel;
    refreshApproveRejectRequestGroupResponse(statusMessages: Client.StatusMessagesDto | undefined): IRootViewModel;
    refreshConflict(): IRootViewModel;
    // view triggered state changes
    refreshCompanyStandardRoleDecisionChanged(companyStandardRoleDecision: ISecureRequestCompanyStandardRoleDecisionViewModel): IRootViewModel;
    refreshCompanyStandardRolesGrantedChanged(values: IRequestDetailsRoleViewModel): IRootViewModel;
    refreshCompanySignerDecisionChanged(companySignerDecision: ISecureRequestCompanySignerDecisionViewModel): IRootViewModel;
    refreshDownloadedDocument(response: Client.SecurePortalDocumentDto): IRootViewModel;
    refreshRejectionDetailsChanged(values: ISecureRequestRejectionsDetailsViewModel): IRootViewModel;
    clearDownloadedDocument(): IRootViewModel;
}

export class RootViewModel extends BaseCompanyRoleRootViewModel implements IRootViewModel {
    constructor() {
        super();

        // initial state
        this.id = undefined;
        this.versionNumber = undefined;
        this.isNewUserRequest = undefined;
        this.isCompanyStandardRoleRequest = undefined;
        this.isCompanySignerRequest = undefined;

        this.summary = new Client.CompanyUserManagementRequestDetailsSummaryDto();
        this.newUserDetails = undefined;
        this.existingUserDetails = undefined;
        this.companyStandardRoleDetails = {};
        this.companySignerDetails = {};
        this.rejectionDetails = {};

        this.service = { statusMessages: undefined };

        this.isDirty = false;
        this.isConflict = false;
    }

    public id: number | undefined;
    public versionNumber: number | undefined;
    public isNewUserRequest: boolean | undefined;
    public isCompanyStandardRoleRequest: boolean | undefined;
    public isCompanySignerRequest: boolean | undefined;

    public summary: Client.CompanyUserManagementRequestDetailsSummaryDto;
    public newUserDetails: Client.CompanyUserManagementRequestDetailsNewUserDetailsDto | undefined;
    public existingUserDetails: Client.CompanyUserManagementRequestDetailsExistingUserDetailsDto | undefined;
    public companyStandardRoleDetails: ISecureRequestCompanyStandardRoleDetailsViewModel;
    public companySignerDetails: ISecureRequestCompanySignerDetailsViewModel;
    public rejectionDetails: ISecureRequestRejectionsDetailsViewModel;

    public service: { statusMessages?: Client.StatusMessagesDto };

    public isDirty: boolean;
    public isConflict: boolean;

    public refreshRequestDetails(
        summary: Client.CompanyUserManagementRequestDetailsSummaryDto,
        newUserDetails?: Client.CompanyUserManagementRequestDetailsNewUserDetailsDto | undefined,
        existingUserDetails?: Client.CompanyUserManagementRequestDetailsExistingUserDetailsDto | undefined,
        companyStandardRoleDetails?: Client.CompanyUserManagementRequestDetailsStandardRoleDetailsDto | undefined,
        companySignerDetails?: Client.CompanyUserManagementRequestDetailsSignerDetailsDto | undefined
    ): IRootViewModel {
        const vm = this._clone();

        vm.id = summary.id;
        vm.versionNumber = summary.versionNumber;
        vm.isNewUserRequest = summary.isNewUserRequest;
        vm.isCompanyStandardRoleRequest = summary.isCompanyStandardRoleRequest;
        vm.isCompanySignerRequest = summary.isCompanySignerRequest;

        vm.summary = summary;
        vm.newUserDetails = newUserDetails;
        vm.existingUserDetails = existingUserDetails;

        // standard role details
        // - reset
        vm.companyStandardRoleDetails = {};
        // refresh and set default selections - note this will need to be expanded if there is a future requirement to show completed requests
        if (companyStandardRoleDetails) {
            const existingRoles = new RequestDetailsRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Existing, companyStandardRoleDetails.rolesExisting);
            const requestedRoles = new RequestDetailsRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Requested, companyStandardRoleDetails.rolesRequested);
            const defaultGrantedRoles = requestedRoles.getDirectRoles().filter((r) => !existingRoles.hasRole(r));
            const grantedRoles = new RequestDetailsRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Granted, defaultGrantedRoles);
            vm.companyStandardRoleDetails.existingRoles = existingRoles;
            vm.companyStandardRoleDetails.requestedRoles = requestedRoles;
            vm.companyStandardRoleDetails.grantedRoles = grantedRoles;
            vm.companyStandardRoleDetails.hasAllGeneralRoles = companyStandardRoleDetails.hasAllGeneralRoles;
        }

        // signer details
        // - reset
        vm.companySignerDetails = {};
        // - refresh
        if (companySignerDetails) {
            vm.companySignerDetails.isCompanySignerPoaRequest = companySignerDetails.isCompanySignerPoaRequest;
            vm.companySignerDetails.csAuthoritySupportingDocument = companySignerDetails.csAuthoritySupportingDocument;
            vm.companySignerDetails.csPoaSupportingDocument = companySignerDetails.csPoaSupportingDocument;
        }

        // rejection details
        // - reset
        vm.rejectionDetails = {};

        // reset state - on a successful approve / reject we redirect to the request list i.e. refreshRequestDetails is only called on initialise and we always want to reset state
        vm.service = { statusMessages: undefined };
        vm.isDirty = false;
        vm.isConflict = false;

        return vm;
    }

    public refreshApproveRejectRequestGroupResponse(statusMessages: Client.StatusMessagesDto | undefined): IRootViewModel {
        const vm = this._clone();
        const isSuccess = statusMessages?.isSuccess;

        vm.service.statusMessages = statusMessages;
        // on success set the vm as clean, otherwise leave dirty
        vm.isDirty = !isSuccess;
        vm.isConflict = false;

        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        vm.service.statusMessages = undefined;
        return vm;
    }

    refreshCompanyStandardRoleDecisionChanged(values: ISecureRequestCompanyStandardRoleDecisionViewModel): IRootViewModel {
        const vm = this._clone();

        vm.companyStandardRoleDetails = { ...vm.companyStandardRoleDetails, ...values };

        // clear reject-specific fields if request is approved
        if (vm.companyStandardRoleDetails.isApproved === true) {
            vm.rejectionDetails = {};
        }
        // clear all roles if request is rejected
        else if (vm.companyStandardRoleDetails.isApproved === false) {
            vm.companyStandardRoleDetails.grantedRoles = new RequestDetailsRoleViewModel(vm.roleConfiguration.roleRelationships!, RoleCategoryEnum.Granted);
        }

        vm.isDirty = true;
        return vm;
    }

    refreshCompanyStandardRolesGrantedChanged(values: IRequestDetailsRoleViewModel): IRootViewModel {
        const vm = this._clone();

        vm.companyStandardRoleDetails.grantedRoles = values;

        vm.isDirty = true;
        return vm;
    }

    refreshCompanySignerDecisionChanged(values: ISecureRequestCompanySignerDecisionViewModel): IRootViewModel {
        const vm = this._clone();

        vm.companySignerDetails = { ...vm.companySignerDetails, ...values };

        // clear reject-specific fields if request is approved
        if (vm.companySignerDetails.isApproved === true) {
            vm.rejectionDetails = {};
        }

        vm.isDirty = true;

        return vm;
    }

    refreshRejectionDetailsChanged(values: ISecureRequestRejectionsDetailsViewModel): IRootViewModel {
        const vm = this._clone();

        vm.rejectionDetails = values;

        vm.isDirty = true;
        return vm;
    }

    public refreshDownloadedDocument(response: Client.SecurePortalDocumentDto): IRootViewModel {
        const vm = this._clone();

        vm.companySignerDetails.downloadedDocument = response;

        return vm;
    }

    public clearDownloadedDocument(): IRootViewModel {
        const vm = this._clone();

        vm.companySignerDetails.downloadedDocument = undefined;

        return vm;
    }

    // private implementation
    // ----------------------
    private _clone(): RootViewModel {
        const vm = clone(this);

        vm.summary = clone(this.summary);
        vm.newUserDetails = clone(this.newUserDetails);
        vm.existingUserDetails = clone(this.existingUserDetails);
        vm.companyStandardRoleDetails = cloneDeep(this.companyStandardRoleDetails);
        vm.companySignerDetails = cloneDeep(this.companySignerDetails);
        vm.rejectionDetails = clone(this.rejectionDetails);
        vm.service = clone(this.service);

        return vm;
    }
}
