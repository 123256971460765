// redux
import { call, put, select } from "redux-saga/effects";
import { ICompanyReduxState } from "../../../../../companyReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { getFormRequestDetails } from "./getDetails";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: ICompanyReduxState) => state.Company_Opggs_LegislativeForms_FormRequestDetails;

interface IResponse {
    statusMessages: Client.StatusMessagesDto;
}

export default function* submitFormRequestToNoptaForApproval() {
    let vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        let result: CallHelper.CallResult<IResponse>;

        switch (vm.formRequestDetails.formTypeId) {
            case 2:
                result = yield call(async () => await setForm2RequestAsToNoptaForApproval(vm));
                break;
            case 3:
                result = yield call(async () => await setForm3RequestAsToNoptaForApproval(vm));
                break;
            case 4:
                result = yield call(async () => await setForm4RequestAsToNoptaForApproval(vm));
                break;
            case 5:
                result = yield call(async () => await setForm5RequestAsToNoptaForApproval(vm));
                break;
            case 6:
                result = yield call(async () => await setForm6RequestAsToNoptaForApproval(vm));
                break;
            case 7:
                result = yield call(async () => await setForm7RequestAsToNoptaForApproval(vm));
                break;
            default:
                throw new Error(`Unsupported Legislative Form '${vm.formRequestDetails.formTypeId}'.`);
        }

        if (result.IsSuccess) {
            // success
            const r = result.Response!;
            let fetchStatusMessages: Client.StatusMessagesDto | undefined = undefined;
            if (r.statusMessages.isSuccess) {
                const formRequestResponse: Models.IGetFormRequestDetailsResponse = yield call(getFormRequestDetails, vm.id!, vm.formRequestDetails.formTypeId!);
                fetchStatusMessages = formRequestResponse.statusMessages;
                vm = vm.refreshRequestDetails(formRequestResponse);
            }
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshFormRequestStatusMessages(r.statusMessages, fetchStatusMessages)));
        } else if (result.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            result.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function setForm2RequestAsToNoptaForApproval(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();
    const request = new Client.SubmitCompanyOpggsLegislativeFormsForm2RequestToNoptaForApprovalRequestDto({
        id: vm.id!,
        versionNumber: vm.versionNumber!,
    });
    return await CallHelper.call(() => client.submitCompanyOpggsLegislativeFormsForm2RequestToNoptaForApproval(request));
}

async function setForm3RequestAsToNoptaForApproval(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();
    const request = new Client.SubmitCompanyOpggsLegislativeFormsForm3RequestToNoptaForApprovalRequestDto({
        id: vm.id!,
        versionNumber: vm.versionNumber!,
    });
    return await CallHelper.call(() => client.submitCompanyOpggsLegislativeFormsForm3RequestToNoptaForApproval(request));
}

async function setForm4RequestAsToNoptaForApproval(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();
    const request = new Client.SubmitCompanyOpggsLegislativeFormsForm4RequestToNoptaForApprovalRequestDto({
        id: vm.id!,
        versionNumber: vm.versionNumber!,
    });
    return await CallHelper.call(() => client.submitCompanyOpggsLegislativeFormsForm4RequestToNoptaForApproval(request));
}

async function setForm5RequestAsToNoptaForApproval(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();
    const request = new Client.SubmitCompanyOpggsLegislativeFormsForm5RequestToNoptaForApprovalRequestDto({
        id: vm.id!,
        versionNumber: vm.versionNumber!,
    });
    return await CallHelper.call(() => client.submitCompanyOpggsLegislativeFormsForm5RequestToNoptaForApproval(request));
}

async function setForm6RequestAsToNoptaForApproval(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();
    const request = new Client.SubmitCompanyOpggsLegislativeFormsForm6RequestToNoptaForApprovalRequestDto({
        id: vm.id!,
        versionNumber: vm.versionNumber!,
    });
    return await CallHelper.call(() => client.submitCompanyOpggsLegislativeFormsForm6RequestToNoptaForApproval(request));
}

async function setForm7RequestAsToNoptaForApproval(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();
    const request = new Client.SubmitCompanyOpggsLegislativeFormsForm7RequestToNoptaForApprovalRequestDto({
        id: vm.id!,
        versionNumber: vm.versionNumber!,
    });
    return await CallHelper.call(() => client.submitCompanyOpggsLegislativeFormsForm7RequestToNoptaForApproval(request));
}
