// framework
import { Link } from "react-router-dom";
// api
import * as Client from "../../../../../../api/Client";
// models
import { IRootViewModel } from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import EmailLinkView from "../../../../../../common/externalLink/EmailLinkView";
import ExternalLinkView from "../../../../../../common/externalLink/ExternalLinkView";
import CategoryView from "./CategoryView";
import SimpleAuditEventsView from "../../../../../../common/audit/SimpleAuditEventsView";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
import * as shellRouteHelper from "../../../../../../shell/shellRouteHelper";

interface IProps {
    vm: IRootViewModel;
}

export default function AuthorisationDetailsView(props: IProps): JSX.Element {
    const { vm } = props;
    const authorisation = vm.authorisation;

    return (
        <>
            {authorisation && (
                <>
                    <div className="card mb-2">
                        <div className="card-body">
                            <h2 className="card-title">Details</h2>
                            <Forms.Row>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="requestDateField" label="Request Date" value={GlobalHelpers.toNoptaDateString(authorisation.requestDate)} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="typeField" label="Authorisation Type" value={authorisation.type} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="statusField" label="Authorisation Status" value={authorisation.status} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.Label htmlFor={"resubmissionField"}>Resubmission</Forms.Label>
                                    <div>
                                        {authorisation.canViewDataSubmissions && authorisation.resubmission ? (
                                            <div>
                                                <Link
                                                    to={geoscienceAustraliaRouteHelper.dataSubmissionResubmissionDetails(authorisation.resubmission.dataSubmissionId, authorisation.resubmission.id)}
                                                    className="form-link"
                                                    title="Click to view Resubmission."
                                                >
                                                    {authorisation.resubmission.name}
                                                </Link>
                                            </div>
                                        ) : (
                                            <p id="resubmissionField" className="form-label">
                                                {authorisation.resubmission ? authorisation.resubmission.name : "No Linked Resubmission"}
                                            </p>
                                        )}
                                    </div>
                                </Forms.QuarterColumn>
                                <Forms.HalfColumn>
                                    <Forms.Label htmlFor={"requestingCompanyField"}>Requesting Company</Forms.Label>
                                    <div>
                                        <p id="requestingCompanyField" className="form-label">
                                            {authorisation.requestingCompanyName ? authorisation.requestingCompanyName : "No Requesting Company"}
                                        </p>
                                    </div>
                                </Forms.HalfColumn>
                                <Forms.HalfColumn>
                                    <Forms.ReadonlyField id="contactNameField" label="Contact Name" value={authorisation.contactName} />
                                </Forms.HalfColumn>
                                {authorisation.operatorCompanyName !== authorisation.requestingCompanyName && (
                                    <>
                                        <Forms.HalfColumn>
                                            <Forms.Label htmlFor={"operatorCompanyField"}>Operator Company</Forms.Label>
                                            <div>
                                                <p id="operatorCompanyField" className="form-label">
                                                    {authorisation.operatorCompanyName ? authorisation.operatorCompanyName : "No Operating Company"}
                                                </p>
                                            </div>
                                        </Forms.HalfColumn>
                                        <Forms.HalfColumn />
                                    </>
                                )}
                                {authorisation.activityType === Client.AuthorisationActivityTypeEnum.Borehole && (
                                    <Forms.FullColumn>
                                        <Forms.Label htmlFor={"boreholeField"}>Borehole(s)</Forms.Label>
                                        <div>
                                            {authorisation.canViewBoreholes && authorisation.boreholes && authorisation.boreholes.length > 0 ? (
                                                <>
                                                    {authorisation.boreholes.map((borehole, index) => (
                                                        <span key={borehole.id}>
                                                            <Link
                                                                to={geoscienceAustraliaRouteHelper.boreholeDetails(borehole.wellId, borehole.id)}
                                                                className="form-link"
                                                                title="Click to view Borehole Details."
                                                                key={borehole.id}
                                                            >
                                                                {borehole!.name}
                                                            </Link>
                                                            <span>{index === authorisation.boreholes!.length - 1 ? <></> : ", "}</span>
                                                        </span>
                                                    ))}
                                                </>
                                            ) : (
                                                <p id="boreholeField" className="form-label">
                                                    {authorisation.boreholes && authorisation.boreholes.length > 0
                                                        ? authorisation.boreholes.map((borehole) => borehole.name).join(", ")
                                                        : "No Boreholes"}
                                                </p>
                                            )}
                                        </div>
                                    </Forms.FullColumn>
                                )}
                                {authorisation.activityType === Client.AuthorisationActivityTypeEnum.AcquisitionProject && (
                                    <>
                                        <Forms.FullColumn>
                                            <Forms.Label htmlFor={"acquisitionField"}>Acquisition(s)</Forms.Label>
                                            <div>
                                                {authorisation.canViewAcquisitions && authorisation.acquisitions && authorisation.acquisitions.length > 0 ? (
                                                    <>
                                                        {authorisation.acquisitions.map((acquisition, index) => (
                                                            <span key={acquisition.id}>
                                                                <Link
                                                                    to={geoscienceAustraliaRouteHelper.acquisitionDetails(acquisition.surveyId, acquisition.id)}
                                                                    className="form-link"
                                                                    title="Click to view Acquisition Details."
                                                                    key={acquisition.id}
                                                                >
                                                                    {acquisition!.name}
                                                                </Link>
                                                                <span>{index === authorisation.acquisitions!.length - 1 ? <></> : ", "}</span>
                                                            </span>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <p id="acquisitionField" className="form-label">
                                                        {authorisation.acquisitions && authorisation.acquisitions.length > 0
                                                            ? authorisation.acquisitions.map((acquisition) => acquisition.name).join(", ")
                                                            : "No Acquisitions"}
                                                    </p>
                                                )}
                                            </div>
                                        </Forms.FullColumn>
                                        <Forms.FullColumn>
                                            <Forms.Label htmlFor={"projectField"}>Project(s)</Forms.Label>
                                            <div>
                                                {authorisation.canViewProjects && authorisation.reprocessingProjects && authorisation.reprocessingProjects.length > 0 ? (
                                                    <>
                                                        {authorisation.reprocessingProjects.map((project, index) => (
                                                            <span key={project.id}>
                                                                <Link
                                                                    to={geoscienceAustraliaRouteHelper.reprocessingProjectDetails(project.reprocessingId, project.id)}
                                                                    className="form-link"
                                                                    title="Click to view Project Details."
                                                                    key={project.id}
                                                                >
                                                                    {project!.name}
                                                                </Link>
                                                                <span>{index === authorisation.reprocessingProjects!.length - 1 ? <></> : ", "}</span>
                                                            </span>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <p id="projectField" className="form-label">
                                                        {authorisation.reprocessingProjects && authorisation.reprocessingProjects.length > 0
                                                            ? authorisation.reprocessingProjects.map((project) => project.name).join(", ")
                                                            : "No Projects"}
                                                    </p>
                                                )}
                                            </div>
                                        </Forms.FullColumn>
                                    </>
                                )}
                                <Forms.FullColumn>
                                    <Forms.Label htmlFor={"titlesField"}>Title(s)</Forms.Label>
                                    <div>
                                        {authorisation.titles && authorisation.titles.length > 0 ? (
                                            <>
                                                {authorisation.titles.map((title, index) => (
                                                    <span key={title.id}>
                                                        <ExternalLinkView href={shellRouteHelper.publicPortalTitleDetails(title.titleNumber)}>{title.titleNumber}</ExternalLinkView>
                                                        <span>{index === authorisation.titles!.length - 1 ? <></> : ", "}</span>
                                                    </span>
                                                ))}
                                            </>
                                        ) : (
                                            <p id="titlesField" className="form-label">
                                                No Titles
                                            </p>
                                        )}
                                    </div>
                                </Forms.FullColumn>
                                <Forms.FullColumn>
                                    <Forms.ReadonlyCheckbox id="isNonTitleholderRequestField" label="Non-Titleholder Request" checked={authorisation.isNonTitleholderRequest} />
                                </Forms.FullColumn>
                                {authorisation.isNonTitleholderRequest && (
                                    <>
                                        <Forms.HalfColumn>
                                            <Forms.ReadonlyField id="nonTitleholderContactorCompanyField" label="Contractor Company" value={authorisation.nonTitleholderContractorCompanyName} />
                                        </Forms.HalfColumn>
                                        <Forms.HalfColumn />
                                        <Forms.HalfColumn>
                                            <Forms.ReadonlyField id="nonTitleholderContactPhoneNumberField" label="Contact Phone Number" value={authorisation.nonTitleholderContactPhoneNumber} />
                                        </Forms.HalfColumn>
                                        <Forms.HalfColumn>
                                            <Forms.Label htmlFor={"nonTitleholderContactEmailAddressField"}>Contact Email Address</Forms.Label>
                                            <div>
                                                <EmailLinkView emailAddress={authorisation.nonTitleholderContactEmailAddress} />
                                            </div>
                                        </Forms.HalfColumn>
                                    </>
                                )}
                                <Forms.FullColumn>
                                    <Forms.ReadonlyTextArea id="originalRequestField" label="Original Request" value={authorisation.originalRequest} />
                                </Forms.FullColumn>
                            </Forms.Row>
                        </div>
                    </div>
                    {authorisation.categories?.map((category: Client.GetGeoscienceAustraliaAuthorisationsAuthorisationDetailsResponseCategoryDetailsDto, index: number) => {
                        return (
                            <div key={index}>
                                <CategoryView
                                    category={category}
                                    activityType={authorisation.activityType}
                                    canViewBoreholes={authorisation.canViewBoreholes}
                                    canViewAcquisitions={authorisation.canViewAcquisitions}
                                    canViewProjects={authorisation.canViewProjects}
                                    canViewDataSubmissions={authorisation.canViewDataSubmissions}
                                />
                            </div>
                        );
                    })}
                    <SimpleAuditEventsView simpleAuditEvents={authorisation.audit} />
                </>
            )}
        </>
    );
}
