export default function TitleDetailsField(props: { label?: string; children: React.ReactNode }) {
    const label = props.label;

    return (
        <div className="d-flex flex-row me-2 mb-2" style={{ width: 400 }}>
            {label && (
                <span className="label me-2 w-50" style={{ fontWeight: "bold" }}>
                    {label}
                </span>
            )}
            <div className="w-50">{props.children}</div>
        </div>
    );
}
