// redux
import * as Models from "../models/models";
// api
import * as Client from "../../../../../../../api/Client";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "company.opggs.formRequestDetails.controls.formDocumentation.initialiseRequested",
    clearRequested = "company.opggs.formRequestDetails.controls.formDocumentation.clearRequested",
    saveWetSignatureRequested = "company.opggs.formRequestDetails.controls.formDocumentation.saveWetSignatureRequested",
    saveDigitalSignatureRequested = "company.opggs.formRequestDetails.controls.formDocumentation.saveDigitalSignatureRequested",
    generateDocumentRequested = "company.opggs.formRequestDetails.controls.formDocumentation.generateDocumentRequested",
    clearGeneratedDocumentRequested = "company.opggs.formRequestDetails.controls.formDocumentation.clearGeneratedDocumentRequested",
    // user input
    viewChanged = "company.opggs.formRequestDetails.controls.formDocumentation.viewChanged",
    // saga events
    sagaCompleted = "company.opggs.formRequestDetails.controls.formDocumentation.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

export interface IFormRequestDetailsKeyPayload {
    id: number;
    type: Client.LegislativeFormTypeEnum;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(id: number): IAction<number>;
    clearRequested(): IAction<undefined>;
    saveWetSignatureRequested(): IAction<undefined>;
    saveDigitalSignatureRequested(): IAction<undefined>;
    generateDocumentRequested(formRequestId: number, formRequestType: Client.LegislativeFormTypeEnum): IAction<IFormRequestDetailsKeyPayload>;
    clearGeneratedDocumentRequested(): IAction<undefined>;
    // user input
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    // saga events
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (id: number): IAction<number> {
        return {
            type: actions.initialiseRequested,
            payload: id,
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    saveWetSignatureRequested: function (): IAction<undefined> {
        return {
            type: actions.saveWetSignatureRequested,
            payload: undefined,
        };
    },
    saveDigitalSignatureRequested: function (): IAction<undefined> {
        return {
            type: actions.saveDigitalSignatureRequested,
            payload: undefined,
        };
    },
    generateDocumentRequested: function (formRequestId: number, formRequestType: Client.LegislativeFormTypeEnum): IAction<IFormRequestDetailsKeyPayload> {
        return {
            type: actions.generateDocumentRequested,
            payload: { id: formRequestId, type: formRequestType },
        };
    },
    clearGeneratedDocumentRequested: function (): IAction<undefined> {
        return {
            type: actions.clearGeneratedDocumentRequested,
            payload: undefined,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
