// framework
import { useEffect, useState } from "react";
// kendo
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
// api
import * as Client from "../../../../../api/Client";
// common
import Help from "../../../../../common/help/HelpView";
import ExternalLink from "../../../../../common/externalLink/ExternalLinkView";
import * as Forms from "../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";

export function QuestionsView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; csNoptaSignatureGuidanceLink: string | undefined }) {
    const vm = props.vm;
    const questions = vm.user.questions;
    const actions = props.actions;

    return (
        <div className="card mb-2">
            <div className="card-body">
                <Forms.Group>
                    <Forms.Label htmlFor="companiesList">
                        <strong>Select the company that you are requesting access to</strong>
                    </Forms.Label>
                    <CompaniesListView id="companiesList" vm={vm} actions={actions} />
                    <NewCompanyCheckboxView vm={vm} actions={actions} />
                </Forms.Group>
                {vm.viewState.showExistingAccessRequestWizard && <ExistingAccessRequestView />}
                {vm.viewState.showExistingFullAccessWizard && <ExistingFullAccessView />}
                {vm.viewState.showExistingCompanyAdministratorWizard && <ExistingCompanyAdministratorView />}
                {vm.viewState.showExistingCompanyStandardRolesWizard && <ExistingCompanyStandardRolesView />}
                {vm.viewState.showNewCompanyWizard && (
                    <Forms.Group>
                        <p>You have requested to register a new company.</p>
                        <CompanyAdministratorInformationView />
                    </Forms.Group>
                )}
                {(vm.viewState.showExistingCompanyNewAdministratorWizard || vm.viewState.showExistingCompanyExistingAdministratorWizard || vm.viewState.showCompanySignerWizard) && (
                    <p>
                        You have requested access to <i>{questions.selectedCompany?.companyName}</i>
                    </p>
                )}
                {vm.viewState.showExistingCompanyNewAdministratorWizard && (
                    <Forms.Group>
                        <CompanyAdministratorInformationView />
                    </Forms.Group>
                )}
                {vm.viewState.showExistingCompanyExistingAdministratorWizard && (
                    <Forms.Group>
                        <p className="fw-bold">Are you applying to be a Company Administrator?</p>
                        <CompanyAdministratorRadioButtonView vm={vm} actions={actions} />
                        <CompanyAdministratorInformationView />
                    </Forms.Group>
                )}
                {vm.viewState.showCompanySignerWizard && (
                    <Forms.Group>
                        <p className="fw-bold">Are you applying to be a Company Signer?</p>
                        <CompanySignerRadioButtonView vm={vm} actions={actions} />
                        <CompanySignerInformationView csNoptaSignatureGuidanceLink={props.csNoptaSignatureGuidanceLink} />
                    </Forms.Group>
                )}
                {vm.viewState.showInvalidRequestWizard && <InvalidRequestView />}
            </div>
        </div>
    );
}

// TODO, find a way to make this re-useable.  probably not straight away, but next combobox we need let's look at this.
function CompaniesListView(props: { id: string; vm: Models.IRootViewModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const questions = vm.user.questions;
    const companies = vm.reference.companies;
    const actions = props.actions;

    const [data, setData] = useState<Array<Client.SecureUserCompanyDto>>();
    const [selectedItem, setSelectedItem] = useState<Client.SecureUserCompanyDto | undefined | null>(questions.selectedCompany);

    // update reference data if changed
    useEffect(() => {
        setData(companies);
    }, [companies]);

    // update selected item if changed - must be set to null in order to programatically clear the combobox
    useEffect(() => {
        if (questions.selectedCompany) {
            setSelectedItem(questions.selectedCompany);
        } else {
            setSelectedItem(null);
            setData(companies);
        }
    }, [questions.selectedCompany, companies]);

    // filter
    const filterChange = (event: any) => {
        const data = filterBy(companies!.slice(), event.filter);
        setData(data);
    };

    // on change
    const onChange = (event: any) => {
        const i: Client.SecureUserCompanyDto | undefined = event.value;

        setSelectedItem(i);
        actions.viewChanged(vm.refreshSelectedCompanyChanged(i ?? undefined));
    };

    return (
        <ComboBox id={props.id} className="mb-2" data={data} dataItemKey="companyId" value={selectedItem} textField="companyName" filterable={true} onFilterChange={filterChange} onChange={onChange} />
    );
}

function NewCompanyCheckboxView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const questions = vm.user.questions;
    const actions = props.actions;

    const [isSelected, setIsSelected] = useState(questions.isNewCompanyRequest);

    // update selection if changed
    useEffect(() => {
        setIsSelected(questions.isNewCompanyRequest);
    }, [questions.isNewCompanyRequest]);

    // on change
    const onChange = (checked: boolean) => {
        setIsSelected(checked);
        actions.viewChanged(vm.refreshQuestionsUpdated({ isNewCompanyRequest: checked }));
    };

    return <Forms.Checkbox id="isCompanyInListField" label="My company is not in this list" checked={isSelected} onChange={onChange} />;
}

function ExistingAccessRequestView() {
    return <Forms.Group>You have an existing request in place for this company. Please wait for this to complete before lodging any further requests.</Forms.Group>;
}

function ExistingFullAccessView() {
    return (
        <Forms.Group>
            <span className="fw-bold">You already have full access for this company. Please select another company if you would like to make an access request.</span>
        </Forms.Group>
    );
}

function ExistingCompanyAdministratorView() {
    return <Forms.Group>You already have Company Administrator access which includes all access except Company Application Signer.</Forms.Group>;
}

function ExistingCompanyStandardRolesView() {
    return (
        <Forms.Group>
            You already have all <span className="fw-bold">Company General Permissions</span> which includes all access except Company Administrator and Application Signer.
        </Forms.Group>
    );
}

function CompanyAdministratorInformationView() {
    return (
        <Help title="What is a Company Administrator?">
            A Company Administrator is a role that is approved by Company Directors and then is able to approve access for other users. A Company administrator is able to assign all permissions within
            NEATS for their company with the exception of approving a Company Application Signer or another Company Administrator.
        </Help>
    );
}

function CompanySignerInformationView(props: { csNoptaSignatureGuidanceLink: string | undefined }) {
    return (
        <Help title="What is a Company Signer?">
            A Company Signer will have the authority to digitally sign on behalf of the company within this portal. The form needs to be executed in accordance with NOPTA's{" "}
            <ExternalLink href={props.csNoptaSignatureGuidanceLink}>Signature Guidance</ExternalLink>.
        </Help>
    );
}

function CompanyAdministratorRadioButtonView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const questions = vm.user.questions;
    const actions = props.actions;

    const isCaRequestRadioValue = "isCaRequest";
    const isNotCaRequestRadioValue = "isNotCaRequest";
    const [selectedCaRequestRadioValue, setSelectedCaRequestRadioValue] = useState(
        GlobalHelpers.convertBooleanToRadioValue(questions.isCompanyAdministratorRequest, isCaRequestRadioValue, isNotCaRequestRadioValue)
    );

    // update selection if changed
    useEffect(() => {
        setSelectedCaRequestRadioValue(GlobalHelpers.convertBooleanToRadioValue(questions.isCompanyAdministratorRequest, isCaRequestRadioValue, isNotCaRequestRadioValue));
    }, [questions.isCompanyAdministratorRequest]);

    // on change
    function onChange(value: string) {
        setSelectedCaRequestRadioValue(value);
        if (value === isCaRequestRadioValue) actions.viewChanged(vm.refreshQuestionsUpdated({ isCompanyAdministratorRequest: true }));
        if (value === isNotCaRequestRadioValue) actions.viewChanged(vm.refreshQuestionsUpdated({ isCompanyAdministratorRequest: false }));
    }

    return (
        <>
            <Forms.RadioButton
                name="isCaRequestGroup"
                id="isCaRequestRadioField"
                value={isCaRequestRadioValue}
                checked={selectedCaRequestRadioValue === isCaRequestRadioValue}
                label="Yes"
                onChange={onChange}
                inline
            />
            <Forms.RadioButton
                name="isCaRequestGroup"
                id="isNotCaRequestRadioField"
                value={isNotCaRequestRadioValue}
                checked={selectedCaRequestRadioValue === isNotCaRequestRadioValue}
                label="No"
                onChange={onChange}
                inline
            />
        </>
    );
}

function CompanySignerRadioButtonView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const questions = vm.user.questions;
    const actions = props.actions;

    const isCsRequestRadioValue = "isCsRequest";
    const isNotCsRequestRadioValue = "isNotCsRequest";
    const [selectedCsRequestRadioValue, setSelectedCsRequestRadioValue] = useState(
        GlobalHelpers.convertBooleanToRadioValue(questions.isCompanySignerRequest, isCsRequestRadioValue, isNotCsRequestRadioValue)
    );

    // update selection if changed
    useEffect(() => {
        setSelectedCsRequestRadioValue(GlobalHelpers.convertBooleanToRadioValue(questions.isCompanySignerRequest, isCsRequestRadioValue, isNotCsRequestRadioValue));
    }, [questions.isCompanySignerRequest]);

    // on change
    function onChange(value: string) {
        setSelectedCsRequestRadioValue(value);
        if (value === isCsRequestRadioValue) actions.viewChanged(vm.refreshQuestionsUpdated({ isCompanySignerRequest: true }));
        if (value === isNotCsRequestRadioValue) actions.viewChanged(vm.refreshQuestionsUpdated({ isCompanySignerRequest: false }));
    }

    return (
        <>
            <Forms.RadioButton
                name="isCsRequestGroup"
                id="isCsRequestRadioField"
                value={isCsRequestRadioValue}
                checked={selectedCsRequestRadioValue === isCsRequestRadioValue}
                label="Yes"
                onChange={onChange}
                inline
            />
            <Forms.RadioButton
                name="isCsRequestGroup"
                id="isNotCsRequestRadioField"
                value={isNotCsRequestRadioValue}
                checked={selectedCsRequestRadioValue === isNotCsRequestRadioValue}
                label="No"
                onChange={onChange}
                inline
            />
        </>
    );
}

function InvalidRequestView() {
    return (
        <Forms.Group>
            <span className="fw-bold">You have not requested any new permissions. Change your selections to make a request.</span>
        </Forms.Group>
    );
}
