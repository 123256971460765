// views
import CommonNavBar from "../../../../common/CommonNavBarView";
// common
import * as geoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";

export enum TabsEnum {
    Details,
    FileAssets,
    RelatedActivities,
    Attachments,
}

export interface IProps {
    reportId: number;
    activeNavItem: TabsEnum;
}

export default function NavBarView(props: IProps): JSX.Element {
    return (
        <CommonNavBar
            activeNavItemKey={props.activeNavItem}
            navItems={[
                {
                    key: TabsEnum.Details,
                    title: "Details",
                    url: geoscienceAustraliaRouteHelper.reportDetails(props.reportId),
                },
                {
                    key: TabsEnum.FileAssets,
                    title: "File/Asset",
                    url: geoscienceAustraliaRouteHelper.reportFileAssets(props.reportId),
                },
                {
                    key: TabsEnum.Attachments,
                    title: "Attachments",
                    url: geoscienceAustraliaRouteHelper.reportAttachments(props.reportId),
                },
                {
                    key: TabsEnum.RelatedActivities,
                    title: "Related Activities",
                    url: geoscienceAustraliaRouteHelper.reportRelatedActivities(props.reportId),
                },
            ]}
        />
    );
}
