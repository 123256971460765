const neatsPublicPortalUri = process.env.REACT_APP_NEATS_PUBLIC_PORTAL_URI;

export function disclaimer(): string {
    return "/Disclaimer";
}

export function accessibility(): string {
    return "/Accessibility";
}

export function unauthorised(): string {
    return "/Unauthorised";
}

export function notFound(): string {
    return "/NotFound";
}

export function publicPortalTitleDetails(titleNumber: string): string {
    return encodeURI(`${neatsPublicPortalUri}/Open/Title?titleNumber=${titleNumber}`);
}

export function publicPortalLicenceDetails(licenceNumber: string): string {
    return encodeURI(`${neatsPublicPortalUri}/Open/Licence?licenceNumber=${licenceNumber}`);
}
