import { clone } from "lodash";
import * as Client from "../../../api/Client";
import { ILhsNavViewModel, LhsNavViewModel } from "./lhsNavViewModel";
import { Level1ItemTypeEnum } from "../navigationItemTypes";

export interface IRootViewModel {
    bodyLayout: IBodyLayout;
    busyCount: number;
    isDirty: boolean;
    dirtyScopes: Array<string>;
    lhsNav: ILhsNavViewModel;

    updateBodyLayout(pageTitle: string, showPageHeader: boolean, includePadding: boolean, selectedLhsNavItem: Level1ItemTypeEnum | undefined): IRootViewModel;

    setBusy(): IRootViewModel;
    clearBusy(): IRootViewModel;

    setDirty(scope: string): IRootViewModel;
    clearDirty(scope: string): IRootViewModel;

    refreshLhsNavConfiguration(response: Client.GetShellLhsNavigationResponseDto | undefined): IRootViewModel;
}

interface IBodyLayout {
    pageTitle: string;
    showPageHeader: boolean;
    includePadding: boolean;
    selectedLhsNavItem: Level1ItemTypeEnum | undefined;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.bodyLayout = {
            pageTitle: "",
            includePadding: true,
            showPageHeader: true,
            selectedLhsNavItem: undefined,
        };
        this.busyCount = 0;
        this.isDirty = false;
        this.dirtyScopes = new Array<string>();
        this.lhsNav = new LhsNavViewModel(undefined);
        this.lhsNavConfiguration = undefined;
    }

    bodyLayout: IBodyLayout;
    busyCount: number;
    isDirty: boolean;
    dirtyScopes: Array<string>;
    lhsNav: ILhsNavViewModel;
    lhsNavConfiguration: Client.GetShellLhsNavigationResponseDto | undefined;

    updateBodyLayout(pageTitle: string, showPageHeader: boolean, includePadding: boolean, selectedLhsNavItem: Level1ItemTypeEnum | undefined): IRootViewModel {
        const vm = this._clone();
        vm.bodyLayout = {
            pageTitle: pageTitle,
            includePadding: includePadding,
            showPageHeader: showPageHeader,
            selectedLhsNavItem: selectedLhsNavItem,
        };
        return vm;
    }

    setBusy(): IRootViewModel {
        const vm = this._clone();
        vm.busyCount++;
        return vm;
    }

    clearBusy(): IRootViewModel {
        if (this.busyCount === 0) return this; // no state change

        const vm = this._clone();
        vm.busyCount--;
        return vm;
    }

    setDirty(scope: string): IRootViewModel {
        if (this.dirtyScopes.includes(scope)) return this; // no state change

        const vm = this._clone();
        vm.dirtyScopes = [...this.dirtyScopes, scope];
        vm.isDirty = vm.dirtyScopes.length > 0;
        return vm;
    }

    clearDirty(scope: string): IRootViewModel {
        if (!this.dirtyScopes.includes(scope)) return this; // no state change

        const vm = this._clone();
        vm.dirtyScopes = vm.dirtyScopes.filter((s) => s !== scope);
        vm.isDirty = vm.dirtyScopes.length > 0;
        return vm;
    }

    refreshLhsNavConfiguration(response: Client.GetShellLhsNavigationResponseDto | undefined): IRootViewModel {
        const vm = this._clone();
        vm.lhsNavConfiguration = response;
        vm.lhsNav = new LhsNavViewModel(response);
        return vm;
    }

    // private implementation
    // ----------------------

    _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
