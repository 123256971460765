// api
import * as Client from "../../../../../api/Client";
// models
import * as Models from "../../../../common/draftApplicationDetails/components/models/models";

export interface ICoCnDetailsViewModel {
    currentCompanyName?: string | undefined;
    acnOrArbn?: string | undefined;
    isAcnOrArbnRequired?: boolean | undefined;
    newCompanyName?: string | undefined;
}

export interface ICoCnApplicationDetailsViewModel extends Models.IApplicationDetailsViewModel {
    coCnDetails: ICoCnDetailsViewModel;
}

export interface IRootViewModel extends Models.IDraftApplicationRootViewModel {
    applicationDetails: ICoCnApplicationDetailsViewModel;

    // view triggered state changes
    refreshCoCnDetailsUpdated(values: ICoCnDetailsViewModel): IRootViewModel;
}

export class RootViewModel extends Models.BaseDraftApplicationRootViewModel implements IRootViewModel {
    constructor() {
        super();
        this.applicationDetails = {
            isDigitalSignatures: false,
            coCnDetails: {},
            isDirty: false,
        };
    }

    public override applicationDetails: ICoCnApplicationDetailsViewModel;

    public override refreshDetails(response: Client.GetCompanyOpggsDraftApplicationDetailsCoCnResponseDto): this {
        const vm = super.refreshDetails(response);

        // CoCN details
        vm.applicationDetails.coCnDetails.currentCompanyName = response.currentCompanyName;
        vm.applicationDetails.coCnDetails.acnOrArbn = response.acnOrArbn;
        vm.applicationDetails.coCnDetails.isAcnOrArbnRequired = response.isAcnOrArbnMissing;
        vm.applicationDetails.coCnDetails.newCompanyName = response.newCompanyName;
        vm.applicationDetails.isDirty = false;

        return vm;
    }

    public refreshCoCnDetailsUpdated(values: ICoCnDetailsViewModel): IRootViewModel {
        const vm = this._clone();

        vm.applicationDetails.coCnDetails = values;
        vm.applicationDetails.isDirty = true;

        return vm;
    }
}
