// framework
import { useEffect, useState } from "react";
// kendo
import { DropDownList, DropDownListChangeEvent, MultiSelect, MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { orderBy } from "@progress/kendo-data-query";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
import { IAddressDetailsViewModel } from "../../../common/addressDetailsViewModel";
import { IRegisteredTitleholderDetailsViewModel } from "../../../common/registeredTitleholderDetailsViewModel";
// api
import * as Client from "../../../../../../api/Client";
// views
import AddressDetailsView from "../../../common/AddressDetailsView";
import RegisteredTitleholderDetailsView from "../../../common/RegisteredTitleholderDetailsView";
import AllTitleholdersReadonlyView from "../../../common/AllTitleholdersReadonlyView";
// common
import { ValidationModel } from "../../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../../common/validation/ValidationMessageView";
import * as Forms from "../../../../../../common/forms/BootstrapForms";

export default function FormRequestDetailsView(props: { vm: Models.IRootViewModel; v: ValidationModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const v = props.v;
    const a = props.actions;

    function updateAddressDetails(addressDetails: IAddressDetailsViewModel) {
        a.viewChanged(vm.refreshAddressDetailsUpdated(addressDetails));
    }

    function updateRegisteredTitleholderDetails(registeredTitleholderDetails: IRegisteredTitleholderDetailsViewModel) {
        a.viewChanged(vm.refreshRegisteredTitleholderDetailsUpdated(registeredTitleholderDetails));
    }

    const formTypeId = vm.formRequestDetails.formTypeId;

    return (
        <div className="card mb-2">
            <div className="card-body">
                <h2 className="card-title">Details</h2>
                <FormTypeView vm={vm} v={v} actions={a} />
                {formTypeId && <TitleholderListView vm={vm} v={v} actions={a} />}
                {(formTypeId === 2 || formTypeId === 3) && (
                    <>
                        {formTypeId === 3 && <AdditionalTitlesListView vm={vm} v={v} actions={a} />}
                        <AddressDetailsView vm={vm.addressDetails} v={v} countries={vm.reference.countries} updateViewModel={updateAddressDetails} />
                        <RegisteredTitleholderDetailsView
                            vm={vm.registeredTitleholderDetails}
                            v={v}
                            formTypeId={formTypeId}
                            isAcnOrArbnRequired={vm.formRequestDetails.selectedTitleholder?.isAcnOrArbnRequired}
                            updateViewModel={updateRegisteredTitleholderDetails}
                        />
                    </>
                )}
                {(formTypeId === 4 || formTypeId === 6) && <AllTitleholdersReadonlyView titleholders={vm.reference.allTitleholders} />}
                {formTypeId === 5 && (
                    <Forms.Group>
                        <Forms.ReadonlyField label="Name of Nominee Titleholder (Form 4)" id="nomineeTitleholderField" value={vm.formRequestDetails.form4NomineeTitleholder?.companyName} />
                    </Forms.Group>
                )}
                {formTypeId === 7 && (
                    <Forms.Group>
                        <Forms.ReadonlyField label="Name of Nominee Titleholder (Form 6)" id="nomineeTitleholderField" value={vm.formRequestDetails.form6NomineeTitleholder?.companyName} />
                    </Forms.Group>
                )}
            </div>
        </div>
    );
}

function FormTypeView(props: { vm: Models.IRootViewModel; v: ValidationModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const v = props.v;
    const formRequestDetails = vm.formRequestDetails;
    const a = props.actions;

    const [data, setData] = useState<Array<number>>();
    const [selectedItem, setSelectedItem] = useState<number | undefined | null>();

    // update reference data if changed
    useEffect(() => {
        setData(vm.reference.formTypeIds);
    }, [vm.reference.formTypeIds]);

    // update selected item if changed - must be set to null in order to programatically clear the combobox
    useEffect(() => {
        setSelectedItem(formRequestDetails.formTypeId ?? null);
    }, [formRequestDetails.formTypeId]);

    // on change - update state and viewModel as form type is used to drive screen visibility and validation
    const onChange = (event: DropDownListChangeEvent) => {
        setSelectedItem(event.value);
        a.viewChanged(vm.refreshSelectedFormTypeChanged(event.value ?? undefined));
    };

    return (
        <Forms.Row>
            <Forms.HalfColumn>
                <Forms.Label htmlFor="formTypeField">Legislative Form</Forms.Label>
                <DropDownList id="formTypeField" valid={v.applyValidityForKendo("FormRequestDetails.FormType")} data={data} value={selectedItem} onChange={onChange} />
                <ValidationMessageView name="FormRequestDetails.FormType" validationModel={v} />
            </Forms.HalfColumn>
            <Forms.HalfColumn>
                <Forms.ReadonlyField label="Title" id="titleField" value={vm.titleNumber} />
            </Forms.HalfColumn>
        </Forms.Row>
    );
}

function TitleholderListView(props: { vm: Models.IRootViewModel; v: ValidationModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const v = props.v;
    const formRequestDetails = vm.formRequestDetails;
    const a = props.actions;

    const [data, setData] = useState<Array<Client.GetCompanyOpggsLegislativeFormsFormRequestTitleholderDetailsDto>>();
    const [selectedItem, setSelectedItem] = useState<Client.GetCompanyOpggsLegislativeFormsFormRequestTitleholderDetailsDto | undefined | null>(formRequestDetails.selectedTitleholder);

    // update reference data if changed
    useEffect(() => {
        setData(vm.reference.selectableTitleholders);
    }, [vm.reference.selectableTitleholders]);

    // update selected titleholder if changed - must be set to null in order to programatically clear the combobox
    useEffect(() => {
        setSelectedItem(formRequestDetails.selectedTitleholder ?? null);
    }, [formRequestDetails.selectedTitleholder]);

    // on titleholder change - update state and viewModel as selected titleholder details are used to drive screen visibility and validation
    const onTitleholderChange = (event: DropDownListChangeEvent) => {
        setSelectedItem(event.value);

        a.viewChanged(vm.refreshSelectedTitleholderChanged(event.value ?? undefined));
    };

    return (
        <Forms.Group>
            <Forms.Label htmlFor="titleholderField">{formRequestDetails.titleholderDisplayName}</Forms.Label>
            <DropDownList
                id="titleholderField"
                valid={v.applyValidityForKendo("FormRequestDetails.Titleholder")}
                data={data}
                value={selectedItem}
                dataItemKey="companyId"
                textField="companyName"
                onChange={onTitleholderChange}
            />
            <ValidationMessageView name="FormRequestDetails.Titleholder" validationModel={v} />
        </Forms.Group>
    );
}

function AdditionalTitlesListView(props: { vm: Models.IRootViewModel; v: ValidationModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const v = props.v;
    const formRequestDetails = vm.formRequestDetails;
    const a = props.actions;

    const [titleData, setTitleData] = useState(new Array<Client.TitleInfoDto>());
    const [selectedTitles, setSelectedTitles] = useState(new Array<Client.TitleInfoDto>());

    // update title reference data if selected titleholder is changed
    useEffect(() => {
        const titles = formRequestDetails.selectedTitleholder?.additionalTitles ?? new Array<Client.TitleInfoDto>();
        setTitleData(orderBy(titles, [{ field: "titleNumber", dir: "asc" }]));
    }, [formRequestDetails.selectedTitleholder]);

    // update selected titles if changed
    useEffect(() => {
        const titles = formRequestDetails.additionalTitles ?? new Array<Client.TitleInfoDto>();
        setSelectedTitles(orderBy(titles, [{ field: "titleNumber", dir: "asc" }]));
        setAllSelected(titleData.every((t) => titles.some((et) => et.id === t.id)));
    }, [formRequestDetails.additionalTitles, titleData]);

    function onChange(e: MultiSelectChangeEvent) {
        setSelectedTitles(e.value);
        a.viewChanged(vm.refreshSelectedAdditionalTitlesChanged(e.value));
    }

    // select all state
    const [allSelected, setAllSelected] = useState(false);

    function onSelectAllClicked(checked: boolean) {
        setAllSelected(checked);

        if (checked) {
            setSelectedTitles(titleData);
            a.viewChanged(vm.refreshSelectedAdditionalTitlesChanged(titleData));
        } else {
            setSelectedTitles(new Array<Client.TitleInfoDto>());
            a.viewChanged(vm.refreshSelectedAdditionalTitlesChanged(new Array<Client.TitleInfoDto>()));
        }
    }

    return (
        <Forms.Group>
            <span className="me-2">
                <Forms.Label htmlFor="additionalTitlesField">Additional Titles</Forms.Label>
            </span>
            {titleData && titleData.length > 0 && <Forms.Checkbox id="isAllSelectedField" label="Select All" checked={allSelected} onChange={onSelectAllClicked} inline />}
            <MultiSelect
                id="additionalTitlesField"
                valid={v.applyValidityForKendo("FormRequestDetails.AdditionalTitles")}
                data={titleData}
                value={selectedTitles}
                dataItemKey="id"
                textField="titleNumber"
                onChange={onChange}
            />
            <ValidationMessageView name="FormRequestDetails.AdditionalTitles" validationModel={v} />
        </Forms.Group>
    );
}
