// framework
import { useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
// models
import { ISaveSurveyDataLine, IRootViewModel } from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import { IValidationModel } from "../../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../../common/validation/ValidationMessageView";
import StatusMessagesAlertsView from "../../../../../../common/alerts/StatusMessagesAlertsView";
// redux
import * as Actions from "../../redux/actions";

interface IProps {
    vm: IRootViewModel;
    v: IValidationModel;
    actions: Actions.IActionFactory;
    onSave: () => void;
    onCancel: () => void;
}

export default function SurveyDataLine(props: IProps): JSX.Element {
    const { vm, v, actions, onSave, onCancel } = props;

    const [state, setState] = useState(vm.saveSurveyDataLine);

    function updateVmAndState(updatedState: ISaveSurveyDataLine) {
        setState(updatedState);
        actions.viewChanged(vm.onSaveSurveyDataLineChanged(updatedState));
    }

    return (
        <Dialog title={`${state?.surveyDataLineId === undefined ? "Create" : "Update"} Survey Data Line`} width={650} onClose={onCancel}>
            <StatusMessagesAlertsView statusMessages={vm.dialogStatusMessages} />
            <Forms.Row>
                <Forms.FullColumn>
                    <Forms.ReadonlyField label="Public Title" id="publicTitleField" value={vm.publicTitle} />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.TextInput
                        label="Line Name"
                        id="lineNameField"
                        value={state?.lineName}
                        maxLength={100}
                        onChange={(value) => updateVmAndState({ ...state, lineName: value })}
                        valid={v.applyValidityForKendo("LineName")}
                    />
                    <ValidationMessageView name="LineName" validationModel={v} />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.TextInput
                        label="First Shot Point"
                        id="fslField"
                        value={state?.fsl}
                        maxLength={100}
                        onChange={(value) => updateVmAndState({ ...state, fsl: value })}
                        valid={v.applyValidityForKendo("Fsl")}
                    />
                    <ValidationMessageView name="Fsl" validationModel={v} />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.TextInput
                        label="Last Shot Point"
                        id="lslField"
                        value={state?.lsl}
                        maxLength={100}
                        onChange={(value) => updateVmAndState({ ...state, lsl: value })}
                        valid={v.applyValidityForKendo("Lsl")}
                    />
                    <ValidationMessageView name="Lsl" validationModel={v} />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.TextArea
                        label="Line Comments"
                        id="documentLinkField"
                        value={state?.lineComments}
                        rows={4}
                        maxLength={4000}
                        onChange={(value) => updateVmAndState({ ...state, lineComments: value })}
                        valid={v.applyValidityForKendo("LineComments")}
                    />
                    <ValidationMessageView name="LineComments" validationModel={v} />
                </Forms.FullColumn>
            </Forms.Row>
            <DialogActionsBar>
                <button type="button" className="btn btn-primary me-2" onClick={onSave}>
                    {`${state?.surveyDataLineId === undefined ? "Create" : "Update"}`}
                </button>
                <button type="button" className="btn btn-secondary me-2" onClick={onCancel}>
                    Cancel
                </button>
            </DialogActionsBar>
        </Dialog>
    );
}
