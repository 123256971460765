// framework
import { useState } from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// redux
import { ISelectedFinancialNotificationsViewModel } from "../../models/models";
// common
import FinancialNotificationLinkController from "../../../../../common/links/FinancialNotificationLinkController";
import TitleLinkController from "../../../../../common/links/TitleLinkController";
import CustomGridCell from "../../../../../common/kendoGrid/CustomGridCell";
import { toNoptaNumberString } from "../../../../../common/GlobalHelpers";
import { AdministrationTypeEnum } from "../../../../../api/Client";
import LicenceLinkController from "../../../../../common/links/LicenceLinkController";

export default function SelectedFinancialNotificationsView(props: { vm: ISelectedFinancialNotificationsViewModel }): React.ReactElement {
    const fns = props.vm.financialNotifications ?? [];
    const totalOutstandingAmount = props.vm.totalOutstandingAmount ?? 0;

    const initialGridState: State = {
        sort: [{ field: "financialNotificationDueDate", dir: "asc" }],
        skip: 0,
        take: 20,
    };
    const [gridState, setGridState] = useState(initialGridState);

    return (
        <>
            <h2>Pay Financial Notifications</h2>
            <Grid
                pageable
                sortable
                resizable
                navigatable
                data={process(fns, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn
                    field="financialNotificationLink.code"
                    title="Notification ID"
                    width={140}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <FinancialNotificationLinkController linkDto={props.dataItem.financialNotificationLink} secureEntityType="Company" />
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    field="titleLicenceNumber"
                    title="Title/Licence Number"
                    width={200}
                    filter="text"
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.administrationType === AdministrationTypeEnum.Opggs && props.dataItem.titleLink && (
                                <TitleLinkController linkDto={props.dataItem.titleLink} secureEntityType="Company" />
                            )}
                            {props.dataItem.administrationType === AdministrationTypeEnum.Oei && props.dataItem.licenceLink && (
                                <LicenceLinkController linkDto={props.dataItem.licenceLink} secureEntityType="Company" />
                            )}
                        </CustomGridCell>
                    )}
                />
                <GridColumn field="financialNotificationType" title="Notification Type" width={200} />
                <GridColumn field="totalAmount" title="Total Amount" width={150} format="{0:c}" className="text-end" />
                <GridColumn field="outstandingAmount" title="Balance" width={140} format="{0:c}" className="text-end" />
                <GridColumn field="financialNotificationStatus" title="Notification Status" width={180} />
            </Grid>
            <div className="row mt-2">
                <div className="col">
                    <h4>Total Payment: {toNoptaNumberString(totalOutstandingAmount, "c")}</h4>
                </div>
            </div>
        </>
    );
}
