import * as Actions from "./actions";
import * as Models from "../models/models";

// reducers
export function reducer(vm: Models.IOpenViewModel = new Models.OpenLinkModel(), action: Actions.IAction<any>): Models.IOpenViewModel {
    switch (action.type) {
        // lifecycle events
        case Actions.actions.clearRequested:
            return new Models.OpenLinkModel();
        // saga events
        case Actions.actions.sagaCompleted:
            return action.payload;
        // default
        default:
            return vm;
    }
}
