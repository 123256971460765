// redux
import * as Models from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";

export default function IappDetailsEpReadonlyView(props: { vm: Models.IIappEpDetailsViewModel }) {
    const vm = props.vm;
    const isLongerApplicationPeriodAllowed = GlobalHelpers.convertBooleanToStringValue(vm.isLongerApplicationPeriodAllowed);
    const locationName = vm.locations.find((l) => l.id === vm.locationId)?.name;

    return (
        <>
            <Forms.Group>
                <Forms.ReadonlyField label="Location Name" id="epDetailsLocation" value={locationName} />
            </Forms.Group>
            <strong>If titleholder of a petroleum exploration permit (section 168 of the OPGGS Act)</strong>
            <Forms.Group>
                <Forms.ReadonlyField label="Date location declared" id="dateLocationDeclaredField" value={GlobalHelpers.toNoptaDateString(vm.dateLocationDeclared)} />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="Longer application period allowed (if applicable)" id="isLongerApplicationPeriodAllowedField" value={isLongerApplicationPeriodAllowed || ""} />
            </Forms.Group>
        </>
    );
}
