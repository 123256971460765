// framework
import * as Validation from "../../../../../common/validation/ValidationModel";
// models
import * as Models from "./models";

export default function validate(vm: Models.IRlRenewalApplicationDetailsViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateRlRenewalDetails(vm.rlRenewalDetails, v);
    return v;
}

function validateRlRenewalDetails(vm: Models.IRlRenewalDetailsViewModel, v: Validation.ValidationModel): void {
    if (vm.isExtensionAccepted === undefined) {
        v.addError("RlRenewalDetails.IsExtensionAccepted", "A selection is required.");
    }
}
