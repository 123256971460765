// framework
import { useState, useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
import validator from "../models/validator";
// common
import StatusMessagesAlertsView from "../../../../../../common/alerts/StatusMessagesAlertsView";
import ConflictView from "../../../../../../common/alerts/ConflictAlertView";
import DirtyScope from "../../../../../../common/shell/DirtyScopeController";
import { ValidationVisibilityEnum } from "../../../../../../common/validation/ValidationModel";
import * as toastHelper from "../../../../../../common/toastHelper";
// views
import ApplicantDetailsView from "./views/ApplicantDetailsView";
import ApplicantDetailsReadonlyView from "./views/ApplicantDetailsReadonlyView";

interface IControllerProps extends Actions.IActionFactory {
    id: number;
    versionNumber: number;
    cardTitle: string;
    lastRefreshedDatetime: Date | undefined;
    showCommands: boolean;
    disableEdit: boolean;
    onEdit: () => void;
    onSave: () => void;
    onCancel: () => void;
    className?: string | undefined;
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const [lastRefreshedDatetime, setLastRefreshedDatetime] = useState<Date | undefined>(undefined);

    // on mount
    // - initial load
    useEffect(() => {
        actions.initialiseRequested(props.id);
        return function () {
            actions.clearRequested();
        };
    }, [props.id]); // eslint-disable-line react-hooks/exhaustive-deps

    // refresh
    // - reasons to refresh include (1) the user pressing the refresh button, or (2) saves occurring in panels that could invalidate the content here
    // - don't refresh just because the parent refreshed, this can cause us to immediately lose status changes when a save occurs here
    // - also be careful to ensure a refresh only occurs once, it's easy to make a mistake and have the service call happen multiple times
    useEffect(() => {
        // console.info(vm.viewState, props.lastRefreshedDatetime, lastRefreshedDatetime, vm.versionNumber, props.versionNumber);

        // if it's initialising, just wait
        if (vm.viewState === Models.ViewStateEnum.Initialising) return;

        // a save has happened here, so trigger the upstream save call
        if (vm.versionNumber! > props.versionNumber) {
            props.onSave();
            return;
        }

        // a save has happened externally, so trigger a refresh here
        if (vm.versionNumber! < props.versionNumber) {
            actions.initialiseRequested(props.id);
            return;
        }

        // a manual refresh has been requested
        if (lastRefreshedDatetime !== props.lastRefreshedDatetime) {
            setLastRefreshedDatetime(props.lastRefreshedDatetime);
            actions.initialiseRequested(props.id);
        }
    }, [vm.viewState, props.lastRefreshedDatetime, lastRefreshedDatetime, vm.versionNumber, props.versionNumber]); // eslint-disable-line react-hooks/exhaustive-deps

    // validation
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const validation = validator(vm, validationVisibility);

    function onEditClick(): void {
        props.onEdit();
        actions.viewChanged(vm.onEdit());
    }

    function onSaveClick(): void {
        // validation
        // - validation is hidden until the user attempts the action... it then stays visible until they have successfully completed the action
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);

        actions.saveRequested();
    }

    function onCancelClick(): void {
        props.onCancel();
        actions.initialiseRequested(vm.id!);
    }

    function updateViewModel(values: Models.IApplicantDetailsViewModel) {
        actions.viewChanged(vm.onChange(values));
    }

    // return
    return (
        <div className={props.className}>
            <div className="card" style={{ minWidth: 250 }}>
                <div className="card-body">
                    <h4 className="card-title">{props.cardTitle}</h4>
                    <DirtyScope scopeName="company/oei/draftApplicationDetails/controls/applicant" isDirty={vm.isDirty} />
                    <ConflictView isConflict={vm.isConflict} />
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} scrollWindowToTop={false} />

                    {/* applicant details */}
                    <div className="mb-2">
                        {vm.viewState === Models.ViewStateEnum.Edit ? <ApplicantDetailsView vm={vm} v={validation} updateViewModel={updateViewModel} /> : <ApplicantDetailsReadonlyView vm={vm} />}
                    </div>

                    {/* edit/save/cancel buttons */}
                    <div className="d-flex flex-wrap mt-2">
                        {vm.viewState === Models.ViewStateEnum.View && props.showCommands && (
                            <div className="ms-auto">
                                <button className="btn btn-outline-primary" onClick={onEditClick} disabled={props.disableEdit}>
                                    Edit
                                </button>
                            </div>
                        )}
                        {vm.viewState === Models.ViewStateEnum.Edit && (
                            <div className="ms-auto">
                                <button className="btn btn-outline-secondary me-2" onClick={onCancelClick}>
                                    Cancel
                                </button>
                                <button className="btn btn-outline-primary" onClick={onSaveClick}>
                                    Save
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_Oei_DraftApplicationDetails_Controls_Applicant }), Actions.actionFactory)(Controller);
