// framework
import { clone, cloneDeep } from "lodash";
// kendo
import { orderBy } from "@progress/kendo-data-query";
// client
import * as Client from "../../../../../api/Client";
// common
import { IAddressDetailsViewModel } from "../../common/addressDetailsViewModel";
import { IRegisteredTitleholderDetailsViewModel } from "../../common/registeredTitleholderDetailsViewModel";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import * as LegislativeFormsHelper from "../../common/LegislativeFormsHelper";

export enum ViewStateEnum {
    Readonly,
    Draft,
    ForSigning,
    Submitted,
    Deleted,
}

export enum FormRequestTaskEnum {
    FormRequestDetails,
    SignatureType,
    FormDocumentation,
}

export interface IFormRequestDetailsViewModel {
    formTypeId?: number | undefined;
    formTypeName?: string | undefined;
    formState?: Client.LegislativeFormRequestStateEnum | undefined;
    formStateName?: string | undefined;
    titles?: Array<Client.TitleInfoDto> | undefined;
    titleNumber?: string | undefined;
    titleholder?: string | undefined;
    titleholderDisplayName?: string | undefined;
    isAcnOrArbnRequired?: boolean | undefined;
    allTitleholders?: Array<Client.FormRequestTitleholderInfoDto> | undefined;
    nomineeTitleholder?: Client.FormRequestTitleholderInfoDto | undefined;
    noptaReturnToCompanyComment?: string | undefined;
    hasInvalidTitlesOnForm?: boolean | undefined;
    audit?: Client.SecurePortalSimpleAuditEventsDto | undefined;
}

export interface IUploadedDocumentViewModel {
    fileName: string;
    content: string;
}

export interface IGetFormRequestDetailsResponse {
    canProgress: boolean;
    statusMessages: Client.StatusMessagesDto;
    summary: Client.CompanyOpggsLegislativeFormsFormRequestSummaryDto;
    registeredTitleholderDetails?: Client.FormRequestRegisteredTitleholderDetailsDto;
    addressDetails?: Client.FormRequestAddressDetailsDto;
    countries?: Array<Client.SecurePortalCountryDto>;
    additionalTitles?: Array<Client.TitleInfoDto>;
    allTitleholders?: Array<Client.FormRequestTitleholderInfoDto>;
    nomineeTitleholder?: Client.FormRequestTitleholderInfoDto;
    hasInvalidTitlesOnForm?: boolean;
}

export interface IRootViewModel {
    id?: number | undefined;
    versionNumber?: number | undefined;

    reference: {
        countries: Array<Client.SecurePortalCountryDto>;
        titles: Array<Client.TitleInfoDto>;
    };

    formRequestDetails: IFormRequestDetailsViewModel;
    addressDetails: IAddressDetailsViewModel;
    registeredTitleholderDetails: IRegisteredTitleholderDetailsViewModel;

    service: {
        statusMessages?: Client.StatusMessagesDto | undefined;
    };

    isDirty: boolean;
    isConflict: boolean;
    isProgressBlocked: boolean;
    viewState: ViewStateEnum;
    inEditTask?: FormRequestTaskEnum | undefined;

    // saga triggered state changes
    refreshRequestDetails(response: IGetFormRequestDetailsResponse): IRootViewModel;
    refreshFormRequestStatusMessages(actionStatusMessages: Client.StatusMessagesDto, fetchStatusMessages: Client.StatusMessagesDto | undefined): IRootViewModel;
    refreshDeleteFormRequestResponse(statusMessages: Client.StatusMessagesDto): IRootViewModel;
    refreshConflict(): IRootViewModel;
    // view triggered state changes
    refreshSelectedTitlesChanged(titles: Array<Client.TitleInfoDto>): IRootViewModel;
    refreshAddressDetailsUpdated(values: IAddressDetailsViewModel): IRootViewModel;
    refreshRegisteredTitleholderDetailsUpdated(values: IRegisteredTitleholderDetailsViewModel): IRootViewModel;
    setInEditTask(task: FormRequestTaskEnum): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.id = undefined;
        this.versionNumber = undefined;

        this.reference = {
            countries: new Array<Client.SecurePortalCountryDto>(),
            titles: new Array<Client.TitleInfoDto>(),
        };

        this.formRequestDetails = {};
        this.addressDetails = {};
        this.registeredTitleholderDetails = {};
        this.service = {
            statusMessages: undefined,
        };

        this.isDirty = false;
        this.isConflict = false;
        this.isProgressBlocked = false;
        this.viewState = ViewStateEnum.Readonly;
        this.inEditTask = undefined;
    }

    public id?: number | undefined;
    public versionNumber?: number | undefined;

    public reference: {
        countries: Array<Client.SecurePortalCountryDto>;
        titles: Array<Client.TitleInfoDto>;
    };

    public formRequestDetails: IFormRequestDetailsViewModel;
    public addressDetails: IAddressDetailsViewModel;
    public registeredTitleholderDetails: IRegisteredTitleholderDetailsViewModel;

    public service: {
        statusMessages?: Client.StatusMessagesDto | undefined;
    };

    public isDirty: boolean;
    public isConflict: boolean;
    public isProgressBlocked: boolean;
    public viewState: ViewStateEnum;
    public inEditTask: FormRequestTaskEnum | undefined;

    public refreshRequestDetails(response: IGetFormRequestDetailsResponse): IRootViewModel {
        const vm = this._clone();

        vm.id = response.summary.id;
        vm.versionNumber = response.summary.versionNumber;

        if (response.countries) vm.reference.countries = orderBy(response.countries, [{ field: "countryName", dir: "asc" }]);
        if (response.additionalTitles) vm.reference.titles = orderBy(response.additionalTitles, [{ field: "titleNumber", dir: "asc" }]);
        // get selections based on reference data
        const selectedCountry = vm.reference.countries.find((c) => c.countryId === response.addressDetails?.countryId);
        const selectedState = selectedCountry?.subdivisions?.find((s) => s.countrySubdivisionId === response.addressDetails?.countrySubdivisionId);
        const selectedTitles = vm.reference.titles.filter((t) => response.summary.titleIds?.includes(t.id));

        vm.formRequestDetails.formTypeId = response.summary.formTypeId;
        vm.formRequestDetails.formTypeName = GlobalHelpers.toNoptaIntegerString(response.summary.formTypeId);
        vm.formRequestDetails.formState = response.summary.formRequestState;
        vm.formRequestDetails.formStateName = response.summary.formRequestStateDisplayName;
        vm.formRequestDetails.titles = selectedTitles;
        vm.formRequestDetails.titleNumber = response.summary.titleNumber;
        vm.formRequestDetails.titleholderDisplayName = LegislativeFormsHelper.getTitleholderDisplayName(response.summary.formTypeId);
        vm.formRequestDetails.titleholder = response.summary.titleholder.companyName;
        vm.formRequestDetails.isAcnOrArbnRequired = response.summary.isAcnOrArbnRequired;
        if (response.allTitleholders) vm.formRequestDetails.allTitleholders = orderBy(response.allTitleholders, [{ field: "companyName", dir: "asc" }]);
        vm.formRequestDetails.hasInvalidTitlesOnForm = response.hasInvalidTitlesOnForm;
        vm.formRequestDetails.nomineeTitleholder = response.nomineeTitleholder;
        vm.formRequestDetails.noptaReturnToCompanyComment = response.summary.noptaReturnToCompanyComment;
        vm.formRequestDetails.audit = response.summary.audit;

        vm.addressDetails.addressType = response.addressDetails?.addressType;
        vm.addressDetails.streetAddress = response.addressDetails?.streetAddress;
        vm.addressDetails.suburb = response.addressDetails?.suburb;
        vm.addressDetails.postcode = response.addressDetails?.postcode;
        vm.addressDetails.state = selectedState;
        vm.addressDetails.country = selectedCountry;

        vm.registeredTitleholderDetails.acnOrArbn = response.registeredTitleholderDetails?.acnOrArbn;
        vm.registeredTitleholderDetails.becomingARegisteredTitleholderDate = GlobalHelpers.toNoptaDateString(response.summary.becomingARegisteredTitleholderDate);
        vm.registeredTitleholderDetails.phone = response.registeredTitleholderDetails?.phone;
        vm.registeredTitleholderDetails.fax = response.registeredTitleholderDetails?.fax;
        vm.registeredTitleholderDetails.email = response.registeredTitleholderDetails?.email;

        vm.viewState = this._getViewState(response.summary.canManageForm, response.summary.formRequestState);
        vm.isDirty = false;
        vm.isConflict = false;
        vm.inEditTask = undefined;
        vm.service.statusMessages = response.statusMessages;
        vm.isProgressBlocked = !response.statusMessages.isSuccess || !response.canProgress;

        return vm;
    }

    public refreshFormRequestStatusMessages(actionStatusMessages: Client.StatusMessagesDto, fetchStatusMessages: Client.StatusMessagesDto | undefined): IRootViewModel {
        const vm = this._clone();
        vm.service.statusMessages = actionStatusMessages;

        if (actionStatusMessages.isSuccess) vm.isDirty = false;
        vm.isConflict = false;

        // show the warnings and errors from the page load.
        if (fetchStatusMessages) {
            vm.service.statusMessages.error = fetchStatusMessages.error;
            vm.service.statusMessages.warning = fetchStatusMessages.warning!.filter((item) => fetchStatusMessages.error!.indexOf(item) < 0);
        }
        return vm;
    }

    public refreshDeleteFormRequestResponse(statusMessages: Client.StatusMessagesDto): IRootViewModel {
        const vm = this._clone();

        vm.service.statusMessages = statusMessages;
        if (statusMessages.isSuccess) vm.viewState = ViewStateEnum.Deleted;

        vm.isConflict = false;
        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.service.statusMessages = undefined;

        vm.isConflict = true;
        vm.isProgressBlocked = true;

        return vm;
    }

    public refreshSelectedTitlesChanged(titles: Array<Client.TitleInfoDto>): IRootViewModel {
        const vm = this._clone();
        vm.formRequestDetails.titles = titles;

        vm.isDirty = true;
        return vm;
    }

    public refreshAddressDetailsUpdated(values: IAddressDetailsViewModel): IRootViewModel {
        const vm = this._clone();
        vm.addressDetails = values;

        vm.isDirty = true;
        return vm;
    }

    public refreshRegisteredTitleholderDetailsUpdated(values: IRegisteredTitleholderDetailsViewModel): IRootViewModel {
        const vm = this._clone();
        vm.registeredTitleholderDetails = values;

        vm.isDirty = true;
        return vm;
    }

    public setInEditTask(task: FormRequestTaskEnum): IRootViewModel {
        if (this.inEditTask !== undefined) throw new Error("A Form Request Task is already being edited.");
        const vm = this._clone();

        vm.inEditTask = task;
        vm.isProgressBlocked = true;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);

        vm.reference = clone(this.reference);
        vm.formRequestDetails = clone(this.formRequestDetails);
        vm.addressDetails = cloneDeep(this.addressDetails);
        vm.registeredTitleholderDetails = cloneDeep(this.registeredTitleholderDetails);
        vm.service = clone(this.service);

        return vm;
    }

    private _getViewState(canManageForm: boolean, formState: Client.LegislativeFormRequestStateEnum): ViewStateEnum {
        if (!canManageForm) return ViewStateEnum.Readonly;

        switch (formState) {
            case Client.LegislativeFormRequestStateEnum.Draft:
                return ViewStateEnum.Draft;
            case Client.LegislativeFormRequestStateEnum.ForSigning:
                return ViewStateEnum.ForSigning;
            case Client.LegislativeFormRequestStateEnum.ScreeningNopta:
                return ViewStateEnum.Submitted;
            default:
                throw new Error(`Unsupported Form State '${formState}'.`);
        }
    }
}
