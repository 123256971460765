// framework
import { useEffect, useState } from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// models
import { IRootViewModel } from "../../models/models";
// common
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";

interface IProps {
    vm: IRootViewModel;
}

export default function RigDetailsView(props: IProps): JSX.Element {
    const { vm } = props;

    const initialState: State = {
        sort: [{ field: "effectiveFromDate", dir: "desc" }],
        filter: { logic: "and", filters: [] },
    };

    const [gridState, setGridState] = useState(initialState);
    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [vm.rigHistory]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid
                resizable
                navigatable
                data={process(vm.rigHistory!.rigHistory, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn field="name" title="Name" width={250} />
                <GridColumn field="companyOwner" title="Company Owner" width={250} />
                <GridColumn field="type" title="Type" width={200} />
                <GridColumn field="make" title="Make" width={200} />
                <GridColumn field="model" title="Model" width={200} />
                <GridColumn field="effectiveFromDate" title="Effective From Date" width={200} format={GlobalHelpers.NoptaDateFormatForKendo} />
                <GridColumn field="status" title="Status" width={200} />
            </Grid>
        </>
    );
}
