// framework
import { put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../geoscienceAustraliaReduxRegistration";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_Home_Dashboard;

export default function* initialise() {
    try {
        yield put(shellHelper.createSetBusyAction());
        const response = undefined;
        const vm: Models.IRootViewModel = yield select(getRootViewModel);
        yield put(Actions.actionFactory.initialiseCompleted(vm.refreshSaga(response)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
