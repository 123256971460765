// framework
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
// api
import * as Client from "../../../../../../api/Client";
// redux
import * as Models from "../../../models/models";
// children views
import BlocksView from "./BlocksView";
import LocationsView from "./LocationsView";
// other
import TitleDetailsConfiguration from "../../TitleDetailsConfiguration";

function BlocksAndLocationsView(props: { vm: Models.IRootViewModel; configuration: TitleDetailsConfiguration }) {
    const vm = props.vm;
    const configuration = props.configuration;
    const blocks = vm.details?.blocksAndLocations?.blocks ?? new Array<Client.GetCompanyOpggsTitleDetailsBlockDto>();
    const locations = vm.details?.blocksAndLocations?.locations ?? new Array<Client.GetCompanyOpggsTitleDetailsLocationDto>();
    const areaSqKm = vm.map?.areaSqKm;

    return (
        <div>
            <Tabs defaultActiveKey="blocks" className="mb-2">
                {/* Blocks Tab */}
                <Tab eventKey="blocks" title="Title Blocks">
                    <BlocksView blocks={blocks} areaSqKm={areaSqKm} showLocations={configuration.IsLocationsDataVisible} />
                </Tab>

                {/* Locations Tab */}
                {configuration.IsLocationsDataVisible && (
                    <Tab eventKey="locations" title="Locations">
                        <LocationsView locations={locations} />
                    </Tab>
                )}
            </Tabs>
        </div>
    );
}

export default BlocksAndLocationsView;
