// redux
import * as Models from "../../models/models";
// common
import { ValidationModel } from "../../../../../../common/validation/ValidationModel";
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as companyRouteHelper from "../../../../../companyRouteHelper";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import IarlDetailsEpView from "./IarlDetailsEpView";
import IarlDetailsPpView from "./IarlDetailsPpView";
import { TitleTypeEnum } from "../../../../../../api/Client";

export default function IarlDetailsView(props: { vm: Models.IIarlDetailsViewModel; v: ValidationModel; updateViewModel: (vm: Models.IIarlViewModel) => void }) {
    const vm = props.vm;
    const v = props.v;

    function updateViewModel(values: Models.IIarlViewModel) {
        props.updateViewModel({ epDetails: values.epDetails, ppDetails: values.ppDetails });
    }

    return (
        <>
            <Forms.Group>
                <Forms.Link label="Originating Title" linkText={vm.titleNumber} linkTo={vm.titleId && companyRouteHelper.titleDetails(vm.titleId)} />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="Expiry Date" id="expiryDateField" value={vm.titleExpiryDate ? GlobalHelpers.toNoptaDateString(vm.titleExpiryDate) : "Life-of-Field"} />
            </Forms.Group>
            {vm.originatingTitleType === TitleTypeEnum.ExplorationPermit && <IarlDetailsEpView vm={vm.epDetails!} v={v} updateViewModel={updateViewModel} />}
            {vm.originatingTitleType === TitleTypeEnum.ProductionLicence && <IarlDetailsPpView vm={vm.ppDetails!} v={v} updateViewModel={updateViewModel} />}
        </>
    );
}
