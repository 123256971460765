// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../../api/Client";

export interface IRootViewModel {
    id: number | undefined;
    versionNumber: number | undefined;

    evaType?: Client.GetCompanyOpggsDraftApplicationDetailsEvaTypeDto | undefined;
    evaTypeDisplay?: string | undefined;
    isActive?: boolean | undefined;
    isApplicant?: boolean | undefined;
    nominatedTitleholderCompanyName?: string | undefined;
    availableEvaTypes?: Array<Client.GetCompanyOpggsDraftApplicationDetailsEvaTypeDto> | undefined;

    viewState: ViewStateEnum;
    isDirty: boolean;
    isConflict: boolean;
    statusMessages: Client.StatusMessagesDto | undefined;

    refreshDetails(id: number, response: Client.GetCompanyOpggsDraftApplicationDetailsEvaResponseDto): IRootViewModel;
    refreshSaveStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel;
    refreshConflict(): IRootViewModel;
    onInitialised(): IRootViewModel;
    onSaved(): IRootViewModel;
    onEdit(): IRootViewModel;
    onEvaChanged(value: Client.GetCompanyOpggsDraftApplicationDetailsEvaTypeDto | undefined): IRootViewModel;
}

export enum ViewStateEnum {
    Initialising,
    View,
    Edit,
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.id = undefined;
        this.versionNumber = undefined;

        this.evaType = undefined;
        this.evaTypeDisplay = undefined;
        this.isActive = undefined;
        this.isApplicant = undefined;
        this.nominatedTitleholderCompanyName = undefined;
        this.availableEvaTypes = undefined;

        this.viewState = ViewStateEnum.Initialising;
        this.isDirty = false;
        this.isConflict = false;
        this.statusMessages = undefined;
    }

    public id: number | undefined;
    public versionNumber: number | undefined;

    public evaType?: Client.GetCompanyOpggsDraftApplicationDetailsEvaTypeDto | undefined;
    public evaTypeDisplay?: string | undefined;
    public isActive?: boolean | undefined;
    public isApplicant?: boolean | undefined;
    public nominatedTitleholderCompanyName?: string | undefined;
    public availableEvaTypes?: Array<Client.GetCompanyOpggsDraftApplicationDetailsEvaTypeDto> | undefined;

    public viewState: ViewStateEnum;
    public isDirty: boolean;
    public isConflict: boolean;
    public statusMessages: Client.StatusMessagesDto | undefined;

    public refreshDetails(id: number, response: Client.GetCompanyOpggsDraftApplicationDetailsEvaResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.id = id;
        vm.versionNumber = response.versionNumber;

        vm.evaType = response.availableEvaTypes.find((e) => e.type === response.evaType);
        vm.evaTypeDisplay = response.evaTypeDisplay;
        vm.isActive = response.isActive;
        vm.isApplicant = response.isApplicant;
        vm.nominatedTitleholderCompanyName = response.isActive ? response.nominatedTitleholderCompanyName : "No Nominated Titleholder";
        vm.availableEvaTypes = response.availableEvaTypes;

        vm.statusMessages = response.statusMessages;
        vm.isDirty = false;
        vm.isConflict = false;

        return vm;
    }

    public refreshSaveStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.statusMessages = statusMessages;
        if (statusMessages.isSuccess) {
            vm.isDirty = false;
        }

        return vm;
    }

    public refreshConflict(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.statusMessages = undefined;
        vm.isConflict = true; // this is only reset on initialise from the reducer

        return vm;
    }

    public onInitialised(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Initialising) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    public onSaved(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    public onEdit(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.View) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.Edit;
        vm.isDirty = false;
        vm.statusMessages = undefined;

        return vm;
    }

    public onEvaChanged(value: Client.GetCompanyOpggsDraftApplicationDetailsEvaTypeDto | undefined): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.isDirty = true;
        vm.evaType = value;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
