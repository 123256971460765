export interface IOrderedSet<T> {
    length: number;

    add(item: T): void;
    remove(item: T): void;
    first(): T;
}

// Ordered list built on a set to maintain insertion order and enforce unique values
export class OrderedSet<T> implements IOrderedSet<T> {
    private _items = new Set<T>();

    // add item to set - no change if value is already in set
    add(item: T): void {
        this._items.add(item);
    }

    // remove item from set - no change if value is not in set
    remove(item: T): void {
        this._items.delete(item);
    }

    // returns the first item in the set
    first(): T {
        const [first] = this._items;
        return first;
    }

    get length() {
        return this._items.size;
    }
}
