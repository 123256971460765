// redux
import * as Models from "../../models/models";
// common
import SimpleAlertView from "../../../../../../../common/simpleAlerts/SimpleAlertView";

export default function WarningsView(props: { vm: Models.IRootViewModel }): React.ReactElement {
    const vm = props.vm;

    // using a warnings view in lieu of having a warnings capability built into our validation framework
    const showIncompleteWarning = vm.viewState === Models.ViewStateEnum.Edit && !vm.isComplete;

    return showIncompleteWarning ? (
        <SimpleAlertView alertType="warning" dismissable={false} message="Please upload all required supporting documentation and ensure the checklist is completed." />
    ) : (
        <></>
    );
}
