// api
import * as Client from "../../../../api/Client";
// kendo
import { orderBy } from "@progress/kendo-data-query";
// local
import * as Models from "./geoscienceAustraliaAdministratorRequestViewModel";
// other
import { clone, cloneDeep } from "lodash";
import { BaseGeoscienceAustraliaRoleRootViewModel, IGeoscienceAustraliaRoleRootViewModel } from "../../../common/GeoscienceAustraliaRoleRootViewModel";

export interface IGeoscienceAustraliaAdministratorRequestsDecisionViewModel {
    isSuspicious: boolean;
    comments: string;
}

export interface IGeoscienceAustraliaAdministratorRequestsViewModel extends IGeoscienceAustraliaAdministratorRequestsDecisionViewModel {
    requests: Array<Models.IGeoscienceAustraliaAdministratorRequestViewModel>;
    isRequestLimitExceeded: boolean;
}

export interface INoptaRequestsViewModel {
    requests: Array<Client.NoptaGeoscienceAustraliaRequestListItemDto>;
    isRequestLimitExceeded: boolean;
}

export interface IRootViewModel extends IGeoscienceAustraliaRoleRootViewModel {
    geoscienceAustraliaAdministrator: IGeoscienceAustraliaAdministratorRequestsViewModel;
    nopta: INoptaRequestsViewModel;

    service: {
        statusMessages?: Client.StatusMessagesDto;
    };

    isConflict: boolean;

    refreshRequestDetails(response: Client.GetGeoscienceAustraliaUserManagementRequestListResponseDto): IRootViewModel;
    refreshBatchProcessResponse(statusMessages: Client.StatusMessagesDto): IRootViewModel;
    refreshConflict(): IRootViewModel;
    // view triggered state changes
    refreshSelectionChanged(requests: Array<Models.IGeoscienceAustraliaAdministratorRequestViewModel>): IRootViewModel;
    refreshDecisionDetailsChanged(values: IGeoscienceAustraliaAdministratorRequestsDecisionViewModel): IRootViewModel;
}

export class RootViewModel extends BaseGeoscienceAustraliaRoleRootViewModel implements IRootViewModel {
    constructor() {
        super();
        this.geoscienceAustraliaAdministrator = {
            requests: new Array<Models.IGeoscienceAustraliaAdministratorRequestViewModel>(),
            isRequestLimitExceeded: false,
            isSuspicious: false,
            comments: "",
        };
        this.nopta = {
            requests: new Array<Client.NoptaGeoscienceAustraliaRequestListItemDto>(),
            isRequestLimitExceeded: false,
        };

        this.service = {
            statusMessages: undefined,
        };

        this.isConflict = false;
    }

    public geoscienceAustraliaAdministrator: IGeoscienceAustraliaAdministratorRequestsViewModel;
    public nopta: INoptaRequestsViewModel;

    public service: {
        statusMessages?: Client.StatusMessagesDto;
    };

    public isConflict: boolean;

    public refreshRequestDetails(response: Client.GetGeoscienceAustraliaUserManagementRequestListResponseDto): IRootViewModel {
        const vm = this._clone();

        const geoscienceAustraliaAdministratorRequests = response.geoscienceAustraliaAdministratorRequests.map(
            (r) => new Models.GeoscienceAustraliaAdministratorRequestViewModel(vm.roleConfiguration.roleRelationships!, r)
        );
        vm.geoscienceAustraliaAdministrator.requests = orderBy(geoscienceAustraliaAdministratorRequests, [{ field: "requestDatetime", dir: "asc" }]);
        vm.geoscienceAustraliaAdministrator.isRequestLimitExceeded = response.isGeoscienceAustraliaAdministratorRequestLimitExceeded;
        vm.nopta.requests = orderBy(response.noptaRequests, [{ field: "requestDatetime", dir: "asc" }]);
        vm.nopta.isRequestLimitExceeded = response.isNoptaRequestLimitExceeded;

        return vm;
    }

    public refreshBatchProcessResponse(statusMessages: Client.StatusMessagesDto): IRootViewModel {
        const vm = this._clone();

        vm.service.statusMessages = statusMessages;
        vm.isConflict = false;

        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        vm.service.statusMessages = undefined;
        return vm;
    }

    public refreshSelectionChanged(requests: Array<Models.IGeoscienceAustraliaAdministratorRequestViewModel>): IRootViewModel {
        const vm = this._clone();

        vm.geoscienceAustraliaAdministrator.requests = requests;
        return vm;
    }

    public refreshDecisionDetailsChanged(values: IGeoscienceAustraliaAdministratorRequestsDecisionViewModel): IRootViewModel {
        const vm = this._clone();

        vm.geoscienceAustraliaAdministrator = { ...vm.geoscienceAustraliaAdministrator, ...values };
        return vm;
    }

    // private implementation
    // ----------------------
    private _clone(): RootViewModel {
        const vm = clone(this);

        vm.geoscienceAustraliaAdministrator = cloneDeep(this.geoscienceAustraliaAdministrator);
        vm.nopta = clone(this.nopta);
        vm.service = clone(this.service);

        return vm;
    }
}
