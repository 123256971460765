import { takeEvery } from "redux-saga/effects";
import * as Actions from "./actions";

import initialise from "./sagas/initialise";
import saveApplicationDetails from "./sagas/saveApplicationDetails";
import saveBlockDetails from "./sagas/saveBlockDetails";
import setApplicationAsReadyForSigning from "./sagas/setApplicationAsReadyForSigning";
import revertApplicationToPreparing from "./sagas/revertApplicationToPreparing";
import setApplicationAsPaying from "./sagas/setApplicationAsPaying";
import setApplicationAsReadyForSubmission from "./sagas/setApplicationAsReadyForSubmission";
import submitApplicationToNopta from "./sagas/submitApplicationToNopta";
import deleteApplication from "./sagas/deleteApplication";

export default function* watcherSaga() {
    yield takeEvery(Actions.actions.initialiseRequested, initialise);
    yield takeEvery(Actions.actions.saveApplicationDetailsRequested, saveApplicationDetails);
    yield takeEvery(Actions.actions.saveBlockDetailsRequested, saveBlockDetails);
    yield takeEvery(Actions.actions.setApplicationAsReadyForSigningRequested, setApplicationAsReadyForSigning);
    yield takeEvery(Actions.actions.revertApplicationToPreparingRequested, revertApplicationToPreparing);
    yield takeEvery(Actions.actions.setApplicationAsPayingRequested, setApplicationAsPaying);
    yield takeEvery(Actions.actions.setApplicationAsReadyForSubmissionRequested, setApplicationAsReadyForSubmission);
    yield takeEvery(Actions.actions.submitApplicationToNoptaRequested, submitApplicationToNopta);
    yield takeEvery(Actions.actions.deleteApplicationRequested, deleteApplication);
}
