// framework
import { call, put, select } from "redux-saga/effects";
// redux
import { ICompanyReduxState } from "../../../../../companyReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: ICompanyReduxState) => state.Company_Opggs_LegislativeForms_FormRequestDetails;

export default function* getDetails(action: Actions.IAction<Actions.IFormRequestDetailsKeyPayload>) {
    const formRequestDetailsKey = action.payload;
    let vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());
        const response: Models.IGetFormRequestDetailsResponse = yield call(async () => getFormRequestDetails(formRequestDetailsKey.id, formRequestDetailsKey.type));
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshRequestDetails(response)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

export async function getFormRequestDetails(formRequestId: number, formRequestType: number): Promise<Models.IGetFormRequestDetailsResponse> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();

    switch (formRequestType) {
        case 2:
            const form2Request = new Client.GetCompanyOpggsLegislativeFormsForm2RequestRequestDto({ id: formRequestId });
            return await CallHelper.simpleCall(() => client.getCompanyOpggsLegislativeFormsForm2Request(form2Request));
        case 3:
            const form3Request = new Client.GetCompanyOpggsLegislativeFormsForm3RequestRequestDto({ id: formRequestId });
            return await CallHelper.simpleCall(() => client.getCompanyOpggsLegislativeFormsForm3Request(form3Request));
        case 4:
            const form4Request = new Client.GetCompanyOpggsLegislativeFormsForm4RequestRequestDto({ id: formRequestId });
            return await CallHelper.simpleCall(() => client.getCompanyOpggsLegislativeFormsForm4Request(form4Request));
        case 5:
            const form5Request = new Client.GetCompanyOpggsLegislativeFormsForm5RequestRequestDto({ id: formRequestId });
            return await CallHelper.simpleCall(() => client.getCompanyOpggsLegislativeFormsForm5Request(form5Request));
        case 6:
            const form6Request = new Client.GetCompanyOpggsLegislativeFormsForm6RequestRequestDto({ id: formRequestId });
            return await CallHelper.simpleCall(() => client.getCompanyOpggsLegislativeFormsForm6Request(form6Request));
        case 7:
            const form7Request = new Client.GetCompanyOpggsLegislativeFormsForm7RequestRequestDto({ id: formRequestId });
            return await CallHelper.simpleCall(() => client.getCompanyOpggsLegislativeFormsForm7Request(form7Request));
        default:
            throw new Error(`Unsupported Legislative Form '${formRequestType}'.`);
    }
}
