import { takeEvery } from "redux-saga/effects";
import * as Actions from "./actions";

import getDetails from "./sagas/getDetails";
import saveFormRequestDetails from "./sagas/saveFormRequestDetails";
import setFormRequestAsReadyForSigning from "./sagas/setFormRequestAsReadyForSigning";
import submitFormRequestToNoptaForApproval from "./sagas/submitFormRequestToNoptaForApproval";
import deleteFormRequest from "./sagas/deleteFormRequest";

export default function* watcherSaga() {
    yield takeEvery(Actions.actions.getDetailsRequested, getDetails);
    yield takeEvery(Actions.actions.saveFormRequestDetailsRequested, saveFormRequestDetails);
    yield takeEvery(Actions.actions.setFormRequestAsReadyForSigningRequested, setFormRequestAsReadyForSigning);
    yield takeEvery(Actions.actions.submitFormRequestToNoptaForApprovalRequested, submitFormRequestToNoptaForApproval);
    yield takeEvery(Actions.actions.deleteFormRequestRequested, deleteFormRequest);
}
