// framework
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
// redux
import * as Models from "../../models/models";
// children views
import DetailsView from "./DetailsView";
import ApprovalTrackingView from "./ApprovalTrackingView";
import TitleholdersView from "./TitleholdersView";
import WorkProgramView from "./WorkProgramView";
import BlocksView from "./BlocksView";

export enum TabsEnum {
    Details,
    ApprovalTracking,
    Titleholders,
    WorkProgram,
    Blocks,
}

// note: whilst preferred tab isn't used, it is likely this will be needed down the track.
export default function TabsView(props: { vm: Models.IRootViewModel; preferredTab?: TabsEnum; lastRefreshedDatetime: Date | undefined }) {
    const vm = props.vm;
    const preferredTab = props.preferredTab ?? TabsEnum.Details;

    return (
        <Tabs defaultActiveKey={preferredTab} variant="pills" className="mb-2">
            <Tab eventKey={TabsEnum.Details} title="Details">
                <DetailsView vm={vm} lastRefreshedDatetime={props.lastRefreshedDatetime} />
            </Tab>
            <Tab eventKey={TabsEnum.ApprovalTracking} title="Approval Tracking">
                <ApprovalTrackingView vm={vm} lastRefreshedDatetime={props.lastRefreshedDatetime} />
            </Tab>
            {vm.layout.hasTitleholders && (
                <Tab eventKey={TabsEnum.Titleholders} title="Titleholders">
                    <TitleholdersView vm={vm} lastRefreshedDatetime={props.lastRefreshedDatetime} />
                </Tab>
            )}
            {vm.layout.hasWorkProgram && (
                <Tab eventKey={TabsEnum.WorkProgram} title="Work Program">
                    <WorkProgramView vm={vm} lastRefreshedDatetime={props.lastRefreshedDatetime} />
                </Tab>
            )}
            {vm.layout.hasBlockDetails && (
                <Tab eventKey={TabsEnum.Blocks} title="Blocks">
                    <BlocksView vm={vm} lastRefreshedDatetime={props.lastRefreshedDatetime} />
                </Tab>
            )}
        </Tabs>
    );
}
