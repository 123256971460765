// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IAddReportRelatedActivities {
    activityType?: Client.ReportRelatedActivityTypeEnum | undefined;
    activities: Client.GetGeoscienceAustraliaReportRelatedActivityReferenceDataDto[];
    comments?: string | undefined;
}

export interface IRootViewModel {
    reportId: number | undefined;
    reportVersionNumber: number | undefined;
    reportHeader: string | undefined;
    publicTitle: string | undefined;

    RelatedActivities: Client.GetGeoscienceAustraliaReportRelatedActivityDto[];

    referenceData: {
        RelatedActivities: Client.GetGeoscienceAustraliaReportRelatedActivityReferenceDataDto[];
    };

    addRelatedActivities: IAddReportRelatedActivities;

    statusMessages?: Client.StatusMessagesDto;

    isDirty: boolean;
    isConflict: boolean;

    refreshRelatedActivities(response: Client.GetGeoscienceAustraliaReportRelatedActivitiesResponseDto): IRootViewModel;

    refreshAddResponse(response: Client.AddGeoscienceAustraliaReportRelatedActivitiesResponseDto): IRootViewModel;
    refreshDeleteResponse(response: Client.DeleteGeoscienceAustraliaReportRelatedActivityResponseDto): IRootViewModel;
    refreshConflict(): IRootViewModel;
    clearStatusMessages(): IRootViewModel;

    onAddRelatedActivitiesChanged(addRelatedActivities: IAddReportRelatedActivities): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.reportId = undefined;
        this.reportVersionNumber = undefined;
        this.publicTitle = undefined;
        this.reportHeader = undefined;

        this.RelatedActivities = [];
        this.referenceData = {
            RelatedActivities: [],
        };

        this.addRelatedActivities = {
            activityType: undefined,
            activities: [],
            comments: undefined,
        };

        this.isDirty = false;
        this.isConflict = false;
    }

    public reportId: number | undefined;
    public reportVersionNumber: number | undefined;
    public reportHeader: string | undefined;
    public publicTitle: string | undefined;

    public RelatedActivities: Client.GetGeoscienceAustraliaReportRelatedActivityDto[];

    public referenceData: {
        RelatedActivities: Client.GetGeoscienceAustraliaReportRelatedActivityReferenceDataDto[];
    };

    public addRelatedActivities: IAddReportRelatedActivities;

    public statusMessages?: Client.StatusMessagesDto;

    public isDirty: boolean;
    public isConflict: boolean;

    public refreshRelatedActivities(response: Client.GetGeoscienceAustraliaReportRelatedActivitiesResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.reportId = response.reportId;
        vm.reportVersionNumber = response.reportVersionNumber;
        vm.reportHeader = response.reportHeader;
        vm.publicTitle = response.publicTitle;

        vm.RelatedActivities = response.relatedActivities;
        vm.referenceData = {
            RelatedActivities: response.relatedActivityReferenceData,
        };

        vm.addRelatedActivities = {
            activityType: undefined,
            activities: [],
            comments: undefined,
        };

        vm.statusMessages = undefined;

        vm.isDirty = false;
        vm.isConflict = false;

        return vm;
    }

    public refreshAddResponse(response: Client.AddGeoscienceAustraliaReportRelatedActivitiesResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = response.statusMessages;
        return vm;
    }

    public refreshDeleteResponse(response: Client.DeleteGeoscienceAustraliaReportRelatedActivityResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = response.statusMessages;
        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        return vm;
    }

    public clearStatusMessages(): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = undefined;
        vm.addRelatedActivities = {
            activityType: undefined,
            activities: [],
            comments: undefined,
        };
        return vm;
    }

    public onAddRelatedActivitiesChanged(addRelatedActivities: IAddReportRelatedActivities): IRootViewModel {
        const vm = this._clone();
        vm.addRelatedActivities = addRelatedActivities;
        vm.isDirty = true;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.referenceData = clone(this.referenceData);
        vm.addRelatedActivities = clone(this.addRelatedActivities);
        return vm;
    }
}
