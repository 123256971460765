import * as Actions from "./actions";
import * as Models from "../models/models";

// reducers
export function reducer(state: Models.IRootViewModel = new Models.RootViewModel(), action: Actions.IAction<any>): Models.IRootViewModel {
    switch (action.type) {
        case Actions.actions.sagaCompleted:
            return action.payload;
        default:
            return state;
    }
}
