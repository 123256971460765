// api
import * as Client from "../../../../api/Client";
// kendo
import { orderBy } from "@progress/kendo-data-query";
// local
import * as Models from "./companyAdministratorRequestViewModel";
// other
import { clone, cloneDeep } from "lodash";
import { BaseCompanyRoleRootViewModel, ICompanyRoleRootViewModel } from "../../../common/CompanyRoleRootViewModel";

export interface ICompanyAdministratorRequestsDecisionViewModel {
    isSuspicious: boolean;
    comments: string;
}

export interface ICompanyAdministratorRequestsViewModel extends ICompanyAdministratorRequestsDecisionViewModel {
    requests: Array<Models.ICompanyAdministratorRequestViewModel>;
    isRequestLimitExceeded: boolean;
}

export interface INoptaRequestsViewModel {
    requests: Array<Client.NoptaCompanyRequestListItemDto>;
    isRequestLimitExceeded: boolean;
}

export interface IRootViewModel extends ICompanyRoleRootViewModel {
    companyAdministrator: ICompanyAdministratorRequestsViewModel;
    nopta: INoptaRequestsViewModel;

    service: {
        statusMessages?: Client.StatusMessagesDto;
    };

    isConflict: boolean;

    refreshRequestDetails(response: Client.GetCompanyUserManagementRequestListResponseDto): IRootViewModel;
    refreshBatchProcessResponse(statusMessages: Client.StatusMessagesDto): IRootViewModel;
    refreshConflict(): IRootViewModel;
    // view triggered state changes
    refreshSelectionChanged(requests: Array<Models.ICompanyAdministratorRequestViewModel>): IRootViewModel;
    refreshDecisionDetailsChanged(values: ICompanyAdministratorRequestsDecisionViewModel): IRootViewModel;
}

export class RootViewModel extends BaseCompanyRoleRootViewModel implements IRootViewModel {
    constructor() {
        super();
        this.companyAdministrator = {
            requests: new Array<Models.ICompanyAdministratorRequestViewModel>(),
            isRequestLimitExceeded: false,
            isSuspicious: false,
            comments: "",
        };
        this.nopta = {
            requests: new Array<Client.NoptaCompanyRequestListItemDto>(),
            isRequestLimitExceeded: false,
        };

        this.service = {
            statusMessages: undefined,
        };

        this.isConflict = false;
    }

    public companyAdministrator: ICompanyAdministratorRequestsViewModel;
    public nopta: INoptaRequestsViewModel;

    public service: {
        statusMessages?: Client.StatusMessagesDto;
    };

    public isConflict: boolean;

    public refreshRequestDetails(response: Client.GetCompanyUserManagementRequestListResponseDto): IRootViewModel {
        const vm = this._clone();

        const companyAdministratorRequests = response.companyAdministratorRequests.map((r) => new Models.CompanyAdministratorRequestViewModel(vm.roleConfiguration.roleRelationships!, r));
        vm.companyAdministrator.requests = orderBy(companyAdministratorRequests, [{ field: "requestDatetime", dir: "asc" }]);
        vm.companyAdministrator.isRequestLimitExceeded = response.isCompanyAdministratorRequestLimitExceeded;
        vm.nopta.requests = orderBy(response.noptaRequests, [{ field: "requestDatetime", dir: "asc" }]);
        vm.nopta.isRequestLimitExceeded = response.isNoptaRequestLimitExceeded;

        return vm;
    }

    public refreshBatchProcessResponse(statusMessages: Client.StatusMessagesDto): IRootViewModel {
        const vm = this._clone();

        vm.service.statusMessages = statusMessages;
        vm.isConflict = false;

        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        vm.service.statusMessages = undefined;
        return vm;
    }

    public refreshSelectionChanged(requests: Array<Models.ICompanyAdministratorRequestViewModel>): IRootViewModel {
        const vm = this._clone();

        vm.companyAdministrator.requests = requests;
        return vm;
    }

    public refreshDecisionDetailsChanged(values: ICompanyAdministratorRequestsDecisionViewModel): IRootViewModel {
        const vm = this._clone();

        vm.companyAdministrator = { ...vm.companyAdministrator, ...values };
        return vm;
    }

    // private implementation
    // ----------------------
    private _clone(): RootViewModel {
        const vm = clone(this);

        vm.companyAdministrator = cloneDeep(this.companyAdministrator);
        vm.nopta = clone(this.nopta);
        vm.service = clone(this.service);

        return vm;
    }
}
