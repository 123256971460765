import * as Client from "../../../../api/Client";
import { clone } from "lodash";

export interface IRootViewModel {
    id?: number;
    secureUser: Client.IGeoscienceAustraliaSecureUserDetailsDto;

    refreshUserDetails(response: Client.GetGeoscienceAustraliaUserManagementUserDetailsResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.secureUser = new Client.GeoscienceAustraliaSecureUserDetailsDto();
    }

    public id: number | undefined;
    public secureUser: Client.IGeoscienceAustraliaSecureUserDetailsDto;

    public refreshUserDetails(response: Client.GetGeoscienceAustraliaUserManagementUserDetailsResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.secureUser = response.userDetails;
        return vm;
    }

    // private implementation
    // ----------------------
    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
