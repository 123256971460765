// dashboard
import * as DashboardReducers from "./home/dashboard/redux/reducers";
import * as DashboardModels from "./home/dashboard/models/models";
import DashboardSaga from "./home/dashboard/redux/saga";
// applications - in progress OPGGS Act application list
import * as ApplicationListReducers from "./opggs/applicationList/redux/reducers";
import * as ApplicationListModels from "./opggs/applicationList/models/models";
import ApplicationListSaga from "./opggs/applicationList/redux/saga";
// application - details
import * as applicationDetails from "./opggs/applicationDetails/applicationDetailsReduxRegistration";
import { buildSagas as applicationDetailsBuildSagas } from "./opggs/applicationDetails/applicationDetailsReduxRegistration";
// titles - list
import * as JointAuthorityTitleListReducers from "./opggs/titleList/redux/reducers";
import * as JointAuthorityTitleListModels from "./opggs/titleList/models/models";
import JointAuthorityTitleListSaga from "./opggs/titleList/redux/saga";
// licence - list
import * as JointAuthorityLicenceListReducers from "./oei/licenceList/redux/reducers";
import * as JointAuthorityLicenceListModels from "./oei/licenceList/models/models";
import JointAuthorityLicenceListSaga from "./oei/licenceList/redux/saga";
// licence - details
import * as JointAuthorityLicenceDetailsReducers from "./oei/licenceDetails/redux/reducers";
import * as JointAuthorityLicenceDetailsModels from "./oei/licenceDetails/models/models";
import JointAuthorityLicenceDetailsSaga from "./oei/licenceDetails/redux/saga";
// User Management - jointAuthority
import * as JointAuthorityReducers from "./userManagement/jointAuthorityDetails/redux/reducers";
import * as JointAuthorityModels from "./userManagement/jointAuthorityDetails/models/rootViewModel";
import JointAuthoritySaga from "./userManagement/jointAuthorityDetails/redux/saga";
// User Management - users list
import * as UserListReducers from "./userManagement/userList/redux/reducers";
import * as UserListModels from "./userManagement/userList/models/models";
import UserListSaga from "./userManagement/userList/redux/saga";
// User Management - users details
import * as UserDetailsReducers from "./userManagement/userDetails/redux/reducers";
import * as UserDetailsModels from "./userManagement/userDetails/models/models";
import UserDetailsSaga from "./userManagement/userDetails/redux/saga";
// request list
import * as RequestListReducers from "./userManagement/requestList/redux/reducers";
import * as RequestListModels from "./userManagement/requestList/models/rootViewModel";
import RequestListSaga from "./userManagement/requestList/redux/saga";
// request details
import * as RequestDetailsReducers from "./userManagement/requestDetails/redux/reducers";
import * as RequestDetailsModels from "./userManagement/requestDetails/models/rootViewModel";
import RequestDetailsSaga from "./userManagement/requestDetails/redux/saga";
// my account - myDetails
import * as MyDetailsReducers from "./account/myDetails/redux/reducers";
import * as MyDetailsModels from "./account/myDetails/models/models";
import MyDetailsSaga from "./account/myDetails/redux/saga";
// my account - request access
import * as RequestAccessReducers from "./account/requestAccess/redux/reducers";
import * as RequestAccessModels from "./account/requestAccess/models/models";
import RequestAccessSaga from "./account/requestAccess/redux/saga";
// registration
import * as RegistrationReducers from "./registration/redux/reducers";
import * as RegistrationModels from "./registration/models/models";
import RegistrationSaga from "./registration/redux/saga";

// state
export interface IJointAuthorityReduxState extends applicationDetails.IApplicationDetailsReduxState {
    JointAuthority_Home_Dashboard: DashboardModels.IRootViewModel;
    JointAuthority_Home_Registration: RegistrationModels.IRootViewModel;
    JointAuthority_Opggs_ApplicationList: ApplicationListModels.IRootViewModel;
    JointAuthority_Opggs_TitleList: JointAuthorityTitleListModels.IRootViewModel;
    JointAuthority_Oei_LicenceList: JointAuthorityLicenceListModels.IRootViewModel;
    JointAuthority_Oei_LicenceDetails: JointAuthorityLicenceDetailsModels.IRootViewModel;
    JointAuthority_UserManagement_JointAuthorityDetails: JointAuthorityModels.IRootViewModel;
    JointAuthority_UserManagement_UserList: UserListModels.IRootViewModel;
    JointAuthority_UserManagement_UserDetails: UserDetailsModels.IRootViewModel;
    JointAuthority_UserManagement_RequestList: RequestListModels.IRootViewModel;
    JointAuthority_UserManagement_RequestDetails: RequestDetailsModels.IRootViewModel;
    JointAuthority_Account_RequestAccess: RequestAccessModels.IRootViewModel;
    JointAuthority_Account_MyDetails: MyDetailsModels.IRootViewModel;
}

// reducers
export function buildReducers(): any {
    const applicationDetailsReducers = applicationDetails.buildReducers();
    return {
        ...applicationDetailsReducers,
        JointAuthority_Home_Dashboard: DashboardReducers.reducer,
        JointAuthority_Home_Registration: RegistrationReducers.reducer,
        JointAuthority_Opggs_ApplicationList: ApplicationListReducers.reducer,
        JointAuthority_Opggs_TitleList: JointAuthorityTitleListReducers.reducer,
        JointAuthority_Oei_LicenceList: JointAuthorityLicenceListReducers.reducer,
        JointAuthority_Oei_LicenceDetails: JointAuthorityLicenceDetailsReducers.reducer,
        JointAuthority_UserManagement_JointAuthorityDetails: JointAuthorityReducers.reducer,
        JointAuthority_UserManagement_UserList: UserListReducers.reducer,
        JointAuthority_UserManagement_UserDetails: UserDetailsReducers.reducer,
        JointAuthority_UserManagement_RequestList: RequestListReducers.reducer,
        JointAuthority_UserManagement_RequestDetails: RequestDetailsReducers.reducer,
        JointAuthority_Account_RequestAccess: RequestAccessReducers.reducer,
        JointAuthority_Account_MyDetails: MyDetailsReducers.reducer,
    };
}

// sagas
export function buildSagas(): Array<any> {
    const jointAuthoritySagas = [
        DashboardSaga,
        RegistrationSaga,
        ApplicationListSaga,
        JointAuthorityTitleListSaga,
        JointAuthoritySaga,
        JointAuthorityLicenceListSaga,
        JointAuthorityLicenceDetailsSaga,
        UserListSaga,
        UserDetailsSaga,
        RequestListSaga,
        RequestDetailsSaga,
        RequestAccessSaga,
        MyDetailsSaga,
    ];

    return jointAuthoritySagas.concat(applicationDetailsBuildSagas());
}
