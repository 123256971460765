import * as ClientFactory from "../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";
import * as Models from "../../models/models";

export async function getCompanyOpggsDraftApplicationDetailsEva(id: number): Promise<Client.GetCompanyOpggsDraftApplicationDetailsEvaResponseDto> {
    // console.info("getCompanyOpggsDraftApplicationDetailsEva");
    const client = await ClientFactory.createCompanyOpggsDraftApplicationDetailsClient();
    return await CallHelper.simpleCall(() => client.getCompanyOpggsDraftApplicationDetailsEva(new Client.GetCompanyOpggsDraftApplicationDetailsEvaRequestDto({ id: id })));
}

export async function saveCompanyOpggsDraftApplicationDetailsEva(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.SaveCompanyOpggsDraftApplicationDetailsEvaResponseDto>> {
    const client = await ClientFactory.createCompanyOpggsDraftApplicationDetailsClient();

    return await CallHelper.call(() =>
        client.saveCompanyOpggsDraftApplicationDetailsEva(
            new Client.SaveCompanyOpggsDraftApplicationDetailsEvaRequestDto({
                id: vm.id!,
                versionNumber: vm.versionNumber!,
                evaType: vm.evaType!.type,
            })
        )
    );
}
