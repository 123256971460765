// framework
import { Link } from "react-router-dom";
// common
import * as CompanyRouteHelper from "../../../../companyRouteHelper";

export default function ApplicationSubmittedView() {
    const applicationsListUrl = CompanyRouteHelper.opggsApplicationList();

    return (
        <p>
            Click <Link to={applicationsListUrl}>here</Link> to view the In Progress OPGGS Act Applications list.
        </p>
    );
}
