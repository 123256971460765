import * as Models from "../models/models";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "geoscienceAustralia.wellsBoreholes.boreholeDetails.initialiseRequested",
    clearRequested = "geoscienceAustralia.wellsBoreholes.boreholeDetails.clearRequested",
    // saga events
    sagaCompleted = "geoscienceAustralia.wellsBoreholes.boreholeDetails.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

export interface IInitialiseRequestedPayload {
    wellId: number;
    boreholeId: number;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(wellId: number, boreholeId: number): IAction<IInitialiseRequestedPayload>;
    clearRequested(): IAction<undefined>;
    // saga events
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (wellId: number, boreholeId: number): IAction<IInitialiseRequestedPayload> {
        return {
            type: actions.initialiseRequested,
            payload: { wellId: wellId, boreholeId: boreholeId },
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
