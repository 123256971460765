// framework
import { useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
// views
import UserDetailsView from "./views/UserDetailsView";
import { useParams } from "react-router-dom";

interface IUserDetailsControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}
interface IUserDetailsRouteParameters {
    id: string;
}

function UserDetailsController(props: IUserDetailsControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    // route parameters
    const params = useParams<IUserDetailsRouteParameters>();
    const secureUserId = parseInt(params.id);

    // on mount
    useEffect(() => {
        actions.initialise(secureUserId);
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BodyLayout
            title="User Details"
            selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_UserManagement_ViewMyUserList}
            onReloadRequested={() => {
                actions.initialise(secureUserId);
            }}
        >
            <UserDetailsView vm={vm} />
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_UserManagement_UserDetails }), Actions.actionFactory)(UserDetailsController);
