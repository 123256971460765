// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Models from "../../models/models";
import * as Actions from "../actions";
import { IShellReduxState } from "../../../shellReduxRegistration";
// api
import * as ClientFactory from "../../../../api/clientFactory";
import * as CallHelper from "../../../../api/callHelper";
import * as Client from "../../../../api/Client";
// common
import * as shellHelper from "../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../common/LogHelper";

const getRootViewModel = (state: IShellReduxState) => state.Shell_Configuration;

export function* refreshDynamicConfiguration(action: Actions.IAction<boolean>) {
    const isInitialLoad = action.payload;
    try {
        if (isInitialLoad) yield put(shellHelper.createSetBusyAction());

        // during initial load, use simpleCall to provide an error dialog
        // after initial load, errors will be suppressed, especially given things are unlikely to have changed
        // (note, duplicate messages may be shown as the static configuration may also show an error)
        const result: IConfiguration = isInitialLoad ? yield call(() => CallHelper.simpleCall(() => load())) : yield call(() => load());

        let vm: Models.IConfigurationModel = yield select(getRootViewModel);
        vm = vm.refreshBambora(result.bambora);
        vm = vm.refreshTitleBadges(result.titleBadges);
        yield put(Actions.actionFactory.configurationLoaded(vm));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        if (isInitialLoad) yield put(shellHelper.createClearBusyAction());
    }
}

interface IConfiguration {
    bambora: Client.GetShellBamboraConfigurationResponseDto;
    titleBadges: Client.GetShellTitleBadgesResponseDto;
}

async function load(): Promise<IConfiguration> {
    const client = await ClientFactory.createShellClient();

    return {
        bambora: await client.getShellBamboraConfiguration(new Client.GetShellBamboraConfigurationRequestDto()),
        titleBadges: await client.getShellTitleBadges(new Client.GetShellTitleBadgesRequestDto()),
    };
}
