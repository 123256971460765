// framework
import { useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";

import TitlesView from "./TitlesView";

interface ITitlesListControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function TitlesListController(props: ITitlesListControllerProps) {
    const vm = props.rootViewModel;

    // on mount
    useEffect(() => {
        props.sagaGetListRequested();
        return function () {
            props.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BodyLayout
            title="Titles"
            selectedLhsNavItem={Level1ItemTypeEnum.JointAuthority_Opggs_TitlesList}
            onReloadRequested={() => {
                props.sagaGetListRequested();
            }}
        >
            <TitlesView vm={vm.titles} />
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.JointAuthority_Opggs_TitleList }), Actions.actionFactory)(TitlesListController);
