import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reduxRootReducer";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension";
import createSagaMiddleware from "redux-saga";
// user sagas
import { buildSagas as shellBuildSagas } from "../shell/shellReduxRegistration";
import { buildSagas as homeBuildSagas } from "../home/homeReduxRegistration";
import { buildSagas as companyBuildSagas } from "../company/companyReduxRegistration";
import { buildSagas as jointAuthorityBuildSagas } from "../jointAuthority/jointAuthorityReduxRegistration";
import { buildSagas as geoscienceAustraliaBuildSagas } from "../geoscienceAustralia/geoscienceAustraliaReduxRegistration";

const initialiseSagaMiddleware = createSagaMiddleware();

const store = createStore(
    rootReducer,
    // this will warn us if we accidentally mutate state
    composeWithDevToolsDevelopmentOnly(applyMiddleware(reduxImmutableStateInvariant(), initialiseSagaMiddleware))
);

// register Sagas
let sagas = [...companyBuildSagas(), ...homeBuildSagas(), ...shellBuildSagas(), ...jointAuthorityBuildSagas(), ...geoscienceAustraliaBuildSagas()];
sagas.forEach((s) => {
    initialiseSagaMiddleware.run(s);
});

export default store;
