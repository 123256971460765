// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_ReportsAndData_SurveyDataRelatedActivities;

export default function* add() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const saveResult: CallHelper.CallResult<Client.AddGeoscienceAustraliaSurveyDataRelatedActivitiesResponseDto> = yield call(async () => await addRelatedActivities(vm));

        if (saveResult.IsSuccess) {
            // success
            let updatedVm = vm;
            const r = saveResult.Response!;
            if (r.statusMessages!.isSuccess) {
                const getRelatedActivitiesResponse: CallHelper.CallResult<Client.GetGeoscienceAustraliaSurveyDataRelatedActivitiesResponseDto> = yield call(async () => await getRelatedActivities(vm));
                updatedVm = vm.refreshRelatedActivities(getRelatedActivitiesResponse.Response!);
            }
            yield put(Actions.actionFactory.sagaCompleted(updatedVm.refreshAddResponse(saveResult.Response!)));
        } else if (saveResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            saveResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function addRelatedActivities(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.AddGeoscienceAustraliaSurveyDataRelatedActivitiesResponseDto>> {
    const request = new Client.AddGeoscienceAustraliaSurveyDataRelatedActivitiesRequestDto({
        surveyDataId: vm.surveyDataId!,
        surveyDataVersionNumber: vm.surveyDataVersionNumber!,
        surveyDataRelatedActivityType: vm.addRelatedActivities.activityType!,
        activityIds: vm.addRelatedActivities.activities.map((a) => a.elementId),
        comments: vm.addRelatedActivities.comments,
    });

    const client = await ClientFactory.createGeoscienceAustraliaSurveyDataClient();
    return await CallHelper.call(() => client.addGeoscienceAustraliaSurveyDataRelatedActivities(request));
}

async function getRelatedActivities(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.GetGeoscienceAustraliaSurveyDataRelatedActivitiesResponseDto>> {
    const request = new Client.GetGeoscienceAustraliaSurveyDataRelatedActivitiesRequestDto({
        surveyDataId: vm.surveyDataId!,
    });

    const client = await ClientFactory.createGeoscienceAustraliaSurveyDataClient();
    return await CallHelper.call(() => client.getGeoscienceAustraliaSurveyDataRelatedActivities(request));
}
