// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    reprocessingId: number | undefined;
    reprocessingTitles: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingTitlesResponseDto | undefined;

    refreshReprocessingTitles(reprocessingId: number, response: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingTitlesResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.reprocessingId = undefined;
        this.reprocessingTitles = undefined;
    }

    public reprocessingId: number | undefined;
    public reprocessingTitles: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingTitlesResponseDto | undefined;

    public refreshReprocessingTitles(reprocessingId: number, response: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingTitlesResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.reprocessingId = reprocessingId;
        vm.reprocessingTitles = response;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.reprocessingTitles = clone(this.reprocessingTitles);
        return vm;
    }
}
