// framework
import { useState } from "react";
// api
import * as Client from "../../../../api/Client";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// common
import * as GlobalHelpers from "../../../../common/GlobalHelpers";
import LicenceLinkController from "../../../../common/links/LicenceLinkController";
import { createGridColumnFilterMenu } from "../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
import CustomGridCell from "../../../../common/kendoGrid/CustomGridCell";
import { LicenceDetailsTabsEnum } from "../../../../common/oei/licenceDetails/views/LicenceDetailsView";

export default function LicencesView(props: { vm: Client.GetJointAuthorityOeiLicenceListLicenceDto[] }): React.ReactElement {
    const licences = props.vm;

    const initialGridState: State = {
        sort: [
            { field: "comesIntoForceDate", dir: "asc" },
            { field: "licenceLink.licenceNumber", dir: "asc" },
        ],
        skip: 0,
        take: 20,
    };
    const [gridState, setGridState] = useState(initialGridState);

    return (
        <Grid
            pageable
            sortable
            resizable
            navigatable
            data={process(licences, gridState)}
            {...gridState}
            onDataStateChange={(e) => {
                setGridState(e.dataState);
            }}
        >
            <GridColumn
                field="licenceLink.licenceNumber"
                title="Licence"
                width={200}
                filter="text"
                columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, licences)}
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        <LicenceLinkController linkDto={props.dataItem.licenceLink} secureEntityType="JointAuthority" tab={LicenceDetailsTabsEnum.Summary} />
                    </CustomGridCell>
                )}
            />
            <GridColumn field="licenceTypeDisplay" title="Licence Type" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, licences)} />
            <GridColumn field="licenceStatusDisplay" title="Licence Status" width={150} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, licences)} />
            <GridColumn field="licenceHolderDisplay" title="Licence Holder" width={400} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, licences)} />
            <GridColumn field="comesIntoForceDate" title="Comes into Force Date" width={300} format={GlobalHelpers.NoptaDateFormatForKendo} filter="date" columnMenu={createGridColumnFilterMenu} />
            <GridColumn field="endDate" title="End Date" width={200} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
        </Grid>
    );
}
