// redux
import * as Models from "../../models/models";
// views
import FormTypeReadonlyView from "./FormTypeReadonlyView";
import AddressDetailsReadonlyView from "../../../common/AddressDetailsReadonlyView";
import RegisteredTitleholderDetailsReadonlyView from "../../../common/RegisteredTitleholderDetailsReadonlyView";
import AllTitleholdersReadonlyView from "../../../common/AllTitleholdersReadonlyView";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";

export default function FormRequestDetailsReadonlyView(props: { vm: Models.IRootViewModel }) {
    const vm = props.vm;
    const formTypeId = vm.formRequestDetails.formTypeId;

    return (
        <div className="card mb-2">
            <div className="card-body">
                <h2 className="card-title">Details</h2>
                {formTypeId && <FormTypeReadonlyView vm={vm.formRequestDetails} />}
                {(formTypeId === 2 || formTypeId === 3) && (
                    <>
                        <TitlesListReadonlyView vm={vm.formRequestDetails} />
                        <AddressDetailsReadonlyView vm={vm.addressDetails} />
                        <RegisteredTitleholderDetailsReadonlyView vm={vm.registeredTitleholderDetails} formTypeId={formTypeId} />
                    </>
                )}
                {(formTypeId === 4 || formTypeId === 6) && <AllTitleholdersReadonlyView titleholders={vm.formRequestDetails.allTitleholders} />}
                {formTypeId === 5 && (
                    <Forms.Group>
                        <Forms.ReadonlyField label="Name of Nominee Titleholder (Form 4)" id="nomineeTitleholderField" value={vm.formRequestDetails.nomineeTitleholder?.companyName} />
                    </Forms.Group>
                )}
                {formTypeId === 7 && (
                    <Forms.Group>
                        <Forms.ReadonlyField label="Name of Nominee Titleholder (Form 6)" id="nomineeTitleholderField" value={vm.formRequestDetails.nomineeTitleholder?.companyName} />
                    </Forms.Group>
                )}
            </div>
        </div>
    );
}

function TitlesListReadonlyView(props: { vm: Models.IFormRequestDetailsViewModel }) {
    if (props.vm.formTypeId !== 3) return <></>;

    const selectedTitles = props.vm.titles?.map((t) => t.titleNumber).join(", ") ?? "";

    return (
        <Forms.Group>
            <Forms.ReadonlyTextArea label="Titles" id="titlesField" value={selectedTitles} />
        </Forms.Group>
    );
}
