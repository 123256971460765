// api
import * as Client from "../../../../../../api/Client";
// redux
import * as Models from "../../../models/models";
// common
import ModernWorkProgramView from "../../../../../../common/workProgram/ModernWorkProgramView";

function WorkProgramView(props: { vm: Models.IRootViewModel }) {
    const vm = props.vm;
    const details = vm.details;
    const wp = vm.workProgram;
    const title2SubType = details?.title2SubType;

    return (
        <div className="card mb-2">
            <div className="card-body">
                <h2 className="card-title">Work Program</h2>
                {wp ? (
                    <ModernWorkProgramView workProgram={wp} />
                ) : title2SubType === Client.Title2SubTypeEnum.CashBid ? (
                    details?.title2SubTypeTypeName + " Title - this Title does not have a Work Program."
                ) : (
                    "This Title does not have a Work Program."
                )}
            </div>
        </div>
    );
}

export default WorkProgramView;
