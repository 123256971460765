import * as Models from "../models/models";

// -------
// Actions
// -------

export enum actions {
    // lifecycle events
    clearRequested = "geoscienceAustralia.dashboard.clearRequested",

    // saga
    initialiseRequested = "geoscienceAustralia.dashboard.initialiseRequested",
    initialiseCompleted = "geoscienceAustralia.dashboard.initialiseCompleted",
}

// --------
// Payloads
// --------

export interface IAction<T> {
    type: actions;
    payload: T;
}

// ---------
// Factories
// ---------

export interface IActionFactory {
    clearRequested(): IAction<undefined>;
    initialiseRequested(): IAction<undefined>;
    initialiseCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    initialiseRequested: function (): IAction<undefined> {
        return {
            type: actions.initialiseRequested,
            payload: undefined,
        };
    },
    initialiseCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.initialiseCompleted,
            payload: vm,
        };
    },
};
