// framework
import { clone } from "lodash";
// api
import * as Client from "../../api/Client";

export interface IAdvancedSearchFilterItem {
    searchType: Client.AdvancedSearchTypeDto;
    operationType?: Client.AdvancedSearchOperationDto | undefined;

    titles: Client.AdvancedSearchReferenceDataTitleDto[];
    surveys: Client.AdvancedSearchReferenceDataSurveyDto[];
    acquisitions: Client.AdvancedSearchReferenceDataAcquisitionDto[];
    wells: Client.AdvancedSearchReferenceDataWellDto[];
    wellBoreholes: Client.AdvancedSearchReferenceDataWellBoreholeDto[];
    reprocessings: Client.AdvancedSearchReferenceDataReprocessingDto[];
    projects: Client.AdvancedSearchReferenceDataReprocessingProjectDto[];
    enos: Client.AdvancedSearchReferenceDataEnoDto[];
    items: string;
    text: string;
}

export interface IAdvancedSearchFilter {
    filterItems: IAdvancedSearchFilterItem[];

    addItem(): IAdvancedSearchFilter;
    modifyItem(index: number, item: IAdvancedSearchFilterItem): IAdvancedSearchFilter;
    removeItem(index: number): IAdvancedSearchFilter;
    clearItems(): IAdvancedSearchFilter;
}

export interface IAdvancedSearchReferenceData {
    searchTypes: Client.AdvancedSearchTypeDto[];
    searchOperations: Client.AdvancedSearchOperationDto[];

    titles?: Client.AdvancedSearchReferenceDataTitleDto[];
    surveys?: Client.AdvancedSearchReferenceDataSurveyDto[];
    acquisitions?: Client.AdvancedSearchReferenceDataAcquisitionDto[];
    wells?: Client.AdvancedSearchReferenceDataWellDto[];
    wellBoreholes?: Client.AdvancedSearchReferenceDataWellBoreholeDto[];
    reprocessings?: Client.AdvancedSearchReferenceDataReprocessingDto[];
    projects?: Client.AdvancedSearchReferenceDataReprocessingDto[];
    enos?: Client.AdvancedSearchReferenceDataEnoDto[];
}

export class AdvancedSearchFilter implements IAdvancedSearchFilter {
    constructor(defaultSearchType: Client.AdvancedSearchTypeDto, defaultSearchOperation: Client.AdvancedSearchOperationDto) {
        this.defaultSearchType = defaultSearchType;
        this.defaultSearchOperation = defaultSearchOperation;
        this.filterItems = [
            {
                searchType: defaultSearchType,
                operationType: defaultSearchOperation,
                titles: [],
                surveys: [],
                acquisitions: [],
                wells: [],
                wellBoreholes: [],
                reprocessings: [],
                projects: [],
                enos: [],
                items: "",
                text: "",
            },
        ];
    }

    public filterItems: IAdvancedSearchFilterItem[];
    public defaultSearchType: Client.AdvancedSearchTypeDto;
    public defaultSearchOperation: Client.AdvancedSearchOperationDto;

    public addItem(): IAdvancedSearchFilter {
        const af = this._clone();
        af.filterItems = [
            ...af.filterItems,
            {
                searchType: af.defaultSearchType,
                operationType: af.defaultSearchOperation,
                titles: [],
                surveys: [],
                acquisitions: [],
                wells: [],
                wellBoreholes: [],
                reprocessings: [],
                projects: [],
                enos: [],
                items: "",
                text: "",
            },
        ];
        return af;
    }

    public modifyItem(index: number, item: IAdvancedSearchFilterItem): IAdvancedSearchFilter {
        const af = this._clone();
        af.filterItems = af.filterItems.map((filterItem, filterIndex) => {
            if (filterIndex === index) return item;
            else return filterItem;
        });

        return af;
    }

    public removeItem(index: number): IAdvancedSearchFilter {
        const af = this._clone();
        if (af.filterItems.length === 1)
            af.filterItems = [
                {
                    searchType: af.defaultSearchType,
                    operationType: af.defaultSearchOperation,
                    titles: [],
                    surveys: [],
                    acquisitions: [],
                    wells: [],
                    wellBoreholes: [],
                    reprocessings: [],
                    projects: [],
                    enos: [],
                    items: "",
                    text: "",
                },
            ];
        else af.filterItems = af.filterItems.filter((_, i) => i !== index);
        return af;
    }

    public clearItems(): IAdvancedSearchFilter {
        const af = this._clone();
        af.filterItems = [
            {
                searchType: af.defaultSearchType,
                operationType: af.defaultSearchOperation,
                titles: [],
                surveys: [],
                acquisitions: [],
                wells: [],
                wellBoreholes: [],
                reprocessings: [],
                projects: [],
                enos: [],
                items: "",
                text: "",
            },
        ];
        return af;
    }

    private _clone(): AdvancedSearchFilter {
        const vm = clone(this);
        return vm;
    }
}
