// models
import { IRootViewModel } from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import SimpleAuditEventsView from "../../../../../../common/audit/SimpleAuditEventsView";

interface IProps {
    vm: IRootViewModel;
}

export default function ReadOnlyReportDetailsView(props: IProps): JSX.Element {
    const { vm } = props;

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">General</h2>
                    <Forms.Row>
                        <Forms.FullColumn>
                            <Forms.ReadonlyTextArea label="Report Title" id="reportTitleField" value={vm.reportDetails.reportTitle} />
                        </Forms.FullColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Report Type" id="reportTypeField" value={vm.reportDetails.reportType?.name} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Item ID" id="itemIdField" value={vm.reportDetails.itemId} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField
                                label="Is Copied to NOPIMS"
                                id="copiedToNopimsField"
                                value={vm.reportDetails.isCopiedToNopims === undefined ? "" : vm.reportDetails.isCopiedToNopims ? "Yes" : "No"}
                            />
                        </Forms.ThirdColumn>
                        <Forms.FullColumn>
                            <Forms.ReadonlyField label="Public Title" id="publicTitleField" value={vm.reportDetails.publicTitle} />
                        </Forms.FullColumn>
                    </Forms.Row>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Confidentiality</h2>
                    <Forms.Row>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Received Date" id="receivedDateField" value={GlobalHelpers.toNoptaDateString(vm.reportDetails.receivedDate)} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Written Date" id="writtenDateField" value={GlobalHelpers.toNoptaDateString(vm.reportDetails.writtenDate)} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Confidentiality" id="isConfidentialField" value={GlobalHelpers.convertBooleanToConfidentialityValue(vm.reportDetails.isConfidential)} />
                        </Forms.ThirdColumn>
                        <Forms.FullColumn>
                            <Forms.ReadonlyTextArea label="Confidentiality Remarks" id="commentsField" value={vm.reportDetails.confidentialityRemarks} />
                        </Forms.FullColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Basic Interp Type" id="basicInterpTypeField" value={vm.reportDetails.basicInterpType?.name} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Basic Relevant Date" id="basicRelevantDateField" value={GlobalHelpers.toNoptaDateString(vm.reportDetails.basicRelevantDate)} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Interp Relevant Date" id="interpRelevantDateField" value={GlobalHelpers.toNoptaDateString(vm.reportDetails.interpRelevantDate)} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Released Date" id="releasedDateField" value={GlobalHelpers.toNoptaDateString(vm.reportDetails.releasedDate)} />
                        </Forms.ThirdColumn>
                    </Forms.Row>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Destruction Analysis Report</h2>
                    <Forms.Row>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Company" id="companyField" value={vm.reportDetails.company?.name} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Contractor" id="contractorField" value={vm.reportDetails.contractor?.name} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Author" id="authorField" value={vm.reportDetails.author} />
                        </Forms.ThirdColumn>
                    </Forms.Row>
                </div>
            </div>
            <SimpleAuditEventsView simpleAuditEvents={vm.auditDto} />
        </>
    );
}
