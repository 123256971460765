// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    dataSubmissionId: number | undefined;
    dataSubmissionName: string | undefined;
    dataSubmissionType: Client.NopimsActivityTypeEnum | undefined;

    items: {
        borehole?: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDataMonitoringItemsResponseBoreholeDto | undefined;
        acquisition?: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDataMonitoringItemsResponseAcquisitionDto | undefined;
        project?: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDataMonitoringItemsResponseProjectDto | undefined;
        dataMonitoringItem: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDataMonitoringItemsResponseDataMonitoringItemDto;
        dataMonitoringItemType: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDataMonitoringItemsResponseDataMonitoringItemTypeDto;
        status: string | undefined;
        receivedDate?: Date | undefined;
    }[];

    permissions: {
        canViewBoreholes: boolean;
        canViewAcquisitions: boolean;
        canViewReprocessingProject: boolean;
    };

    refreshDataSubmissionDataMonitoring(dataSubmissionId: number, response: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDataMonitoringItemsResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.dataSubmissionId = undefined;
        this.dataSubmissionName = undefined;
        this.dataSubmissionType = undefined;
        this.items = [];
        this.permissions = {
            canViewBoreholes: false,
            canViewAcquisitions: false,
            canViewReprocessingProject: false,
        };
    }

    public dataSubmissionId: number | undefined;
    public dataSubmissionName: string | undefined;
    public dataSubmissionType: Client.NopimsActivityTypeEnum | undefined;

    public items: {
        borehole?: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDataMonitoringItemsResponseBoreholeDto | undefined;
        acquisition?: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDataMonitoringItemsResponseAcquisitionDto | undefined;
        project?: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDataMonitoringItemsResponseProjectDto | undefined;
        dataMonitoringItem: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDataMonitoringItemsResponseDataMonitoringItemDto;
        dataMonitoringItemType: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDataMonitoringItemsResponseDataMonitoringItemTypeDto;
        status: string | undefined;
        receivedDate?: Date | undefined;
    }[];

    public permissions: {
        canViewBoreholes: boolean;
        canViewAcquisitions: boolean;
        canViewReprocessingProject: boolean;
    };

    public refreshDataSubmissionDataMonitoring(dataSubmissionId: number, response: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDataMonitoringItemsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.dataSubmissionId = dataSubmissionId;
        vm.dataSubmissionName = response.dataSubmissionName;
        vm.dataSubmissionType = response.type;

        vm.items = response.items;

        vm.permissions = {
            canViewBoreholes: response.canViewBoreholes,
            canViewAcquisitions: response.canViewAcquisitions,
            canViewReprocessingProject: response.canViewReprocessingProjects,
        };
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.items = clone(this.items);
        return vm;
    }
}
