// framework
import { call, put } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IPaymentViewModel } from "../../models/paymentViewModel";
import { getFinancialNotificationDetails } from "./sagaGetDetailsRequested";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";

interface IResponse {
    statusMessages?: Client.StatusMessagesDto;
}

export default function* createPaymentRequested(action: Actions.IAction<Models.IRootViewModel>) {
    let vm: Models.IRootViewModel = action.payload;
    const params: IPaymentViewModel = vm.paymentViewModel!;

    try {
        yield put(shellHelper.createSetBusyAction());

        let result: CallHelper.CallResult<IResponse>;

        if (params.paymentMethod === Client.Payment2MethodEnum.CreditCard) {
            result = yield call(async () => await makeCreditCardPaymentRequest(vm));
        } else {
            result = yield call(async () => await makePaymentRequest(vm));
        }

        if (result.IsSuccess) {
            // success
            const r = result.Response!;
            if (r.statusMessages?.isSuccess) {
                const fnDetailsResponse: Client.GetCompanyFinanceFnDetailsResponseDto = yield call(getFinancialNotificationDetails, vm.financialNotification.financialNotificationId);
                vm = vm.refreshDetails(fnDetailsResponse);
            }
            vm = vm.setStatusMessages(r.statusMessages);
            yield put(Actions.actionFactory.sagaCompleted(vm));
        } else if (result.IsConflict) {
            // conflict
            vm = vm.refreshConflict();
            yield put(Actions.actionFactory.sagaCompleted(vm));
        } else {
            // all other errors
            result.ShowToastNotification();
            vm = vm.setStatusMessages(undefined);
            yield put(Actions.actionFactory.sagaCompleted(vm));
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }

    async function makePaymentRequest(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
        const client = await ClientFactory.createCompanyFinanceClient();
        const request = new Client.CreateCompanyFinanceFnDetailsPaymentRequestDto({
            financialNotificationId: vm.financialNotification.financialNotificationId,
            versionNumber: vm.financialNotification.versionNumber,
            paymentMethod: params.paymentMethod!,
            paymentDate: params.paymentMadeDate!,
            paymentDateOffsetMinutes: GlobalHelpers.getDateOffsetMinutes(params.paymentMadeDate)!,
            receivedAmount: params.receivedAmount!,
            bankName: params.paymentMethod === Client.Payment2MethodEnum.DirectDeposit ? params.bankName : undefined,
            bankReceiptNumber: params.paymentMethod === Client.Payment2MethodEnum.DirectDeposit ? params.bankReceiptNumber : undefined,
            chequeNumber: params.paymentMethod === Client.Payment2MethodEnum.Cheque ? params.chequeNumber : undefined,
            comments: params.comments,
        });
        return await CallHelper.call(() => client.createCompanyFinanceFnDetailsPayment(request));
    }

    async function makeCreditCardPaymentRequest(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
        const client = await ClientFactory.createCompanyFinanceClient();
        const request = new Client.CreateCompanyFinanceFnDetailsCreditCardPaymentRequestDto({
            financialNotificationId: vm.financialNotification.financialNotificationId,
            versionNumber: vm.financialNotification.versionNumber,
            comments: params.comments,
            secureTransactionToken: params.secureTransactionToken,
        });
        return await CallHelper.call(() => client.createCompanyFinanceFnDetailsCreditCardPayment(request));
    }
}
