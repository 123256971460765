// framework
import { useState } from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// redux
import * as Models from "../../models/models";
// other
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import ApplicationLinkController from "../../../../../common/links/ApplicationLinkController";
import TitleLinkController from "../../../../../common/links/TitleLinkController";
import { createGridColumnFilterMenu } from "../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
import CustomGridCell from "../../../../../common/kendoGrid/CustomGridCell";
import { AdministrationTypeEnum } from "../../../../../api/Client";
import LicenceLinkController from "../../../../../common/links/LicenceLinkController";

export default function ApplicationsTileView(props: { vm: Models.applicationsType }): React.ReactElement {
    if (!props.vm) throw new Error("Applications must not be undefined.");
    const apps = props.vm;

    const initialGridState: State = {
        sort: [{ field: "statusDate", dir: "desc" }],
        skip: 0,
        take: 10,
    };
    const [gridState, setGridState] = useState(initialGridState);

    // view
    return (
        <>
            <div className="card mb-2">
                <div className="card-header">My Applications</div>
                <div className="card-body">
                    <Grid
                        pageable
                        sortable
                        resizable
                        navigatable
                        data={process(apps, gridState)}
                        {...gridState}
                        onDataStateChange={(e) => {
                            setGridState(e.dataState);
                        }}
                    >
                        <GridColumn
                            field="applicationLink.trackingNumber"
                            title="Tracking Number"
                            width={170}
                            filter="text"
                            columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, apps)}
                            cell={(props) => (
                                <CustomGridCell gridCellProps={props}>
                                    <ApplicationLinkController linkDto={props.dataItem.applicationLink} secureEntityType="Company" />
                                </CustomGridCell>
                            )}
                        />
                        <GridColumn field="applicationTypeName" title="Application Type" width={300} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, apps)} />
                        <GridColumn field="applicationStatusDisplay" title="Application Status" width={180} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, apps)} />
                        <GridColumn field="statusDate" title="Status Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} filter="date" columnMenu={createGridColumnFilterMenu} />
                        <GridColumn
                            field="titleLink.titleNumber"
                            title="Title/Licence Number"
                            width={200}
                            filter="text"
                            columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, apps)}
                            cell={(props) => (
                                <CustomGridCell gridCellProps={props}>
                                    {props.dataItem.administrationType === AdministrationTypeEnum.Opggs && props.dataItem.titleLink && (
                                        <TitleLinkController linkDto={props.dataItem.titleLink} secureEntityType="Company" />
                                    )}
                                    {props.dataItem.administrationType === AdministrationTypeEnum.Oei && props.dataItem.titleLink && (
                                        <LicenceLinkController linkDto={props.dataItem.titleLink} secureEntityType="Company" />
                                    )}
                                </CustomGridCell>
                            )}
                        />

                        <GridColumn field="titleLicenceTypeDisplay" title="Title/Licence Type" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, apps)} />
                        <GridColumn field="companyName" title="Applicant" width={400} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, apps)} />
                    </Grid>
                </div>
            </div>
        </>
    );
}
