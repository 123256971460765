// framework
import { useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";

interface IControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IControllerProps): React.ReactElement {
    const selectedLhsNavItem = Level1ItemTypeEnum.GeoscienceAustralia_Home_Dashboard;

    // on mount
    useEffect(() => {
        props.initialiseRequested();
        return function () {
            props.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BodyLayout
            title="Dashboard"
            selectedLhsNavItem={selectedLhsNavItem}
            onReloadRequested={() => {
                props.initialiseRequested();
            }}
        >
            <></>
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_Home_Dashboard }), Actions.actionFactory)(Controller);
