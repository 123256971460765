// framework
import { useState } from "react";
import { PencilIcon, XIcon } from "@primer/octicons-react";
// kendo
import { Grid, GridCellProps, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
// redux
import * as Models from "../../models/models";
import * as Actions from "../../redux/actions";
import validator from "../../models/validator";
// api
import * as Client from "../../../../../../../api/Client";
// editor
import WorkProgramActivityEditor from "./WorkProgramActivityEditor";
// common
import CustomGridCell from "../../../../../../../common/kendoGrid/CustomGridCell";
import { ValidationVisibilityEnum } from "../../../../../../../common/validation/ValidationModel";
import * as toastHelper from "../../../../../../../common/toastHelper";

export enum ActivityEditModeEnum {
    Add = "Add",
    Update = "Update",
}

export default function WorkProgramGridView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }): React.ReactElement {
    const vm = props.vm;
    const actions = props.actions;

    const [editMode, setEditMode] = useState<ActivityEditModeEnum | undefined>(undefined);
    const [activityModel, setActivityModel] = useState<Models.IActivityViewModel | undefined>(undefined);

    // validation
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const v = validator(activityModel, vm.referenceData, validationVisibility);

    function getYear(yearTypeId: number | undefined) {
        if (yearTypeId === undefined) return undefined;
        return vm.referenceData.validYears.find((y) => y.yearTypeId === yearTypeId);
    }

    function getUnit(unitId: number | undefined) {
        if (unitId === undefined) return undefined;
        return vm.referenceData.units.find((u) => u.id === unitId);
    }

    function addNew() {
        if (vm.viewState !== Models.ViewStateEnum.Edit) return;

        setActivityModel({
            key: undefined,
            year: undefined,
            quantity: undefined,
            unit: undefined,
            activity: "",
            description: "",
            indicativeExpenditure: undefined,
        });
        setEditMode(ActivityEditModeEnum.Add);
    }

    function editExisting(a: Client.DraftApplicationWorkProgramActivityDto) {
        if (vm.viewState !== Models.ViewStateEnum.Edit) return;

        setActivityModel({
            key: a.key,
            year: getYear(a.yearTypeId),
            quantity: a.quantity,
            unit: getUnit(a.unitId),
            activity: a.activity,
            description: a.description,
            indicativeExpenditure: a.indicativeExpenditure,
        });
        setEditMode(ActivityEditModeEnum.Update);
    }

    function removeExisting(a: Client.DraftApplicationWorkProgramActivityDto): void {
        if (vm.viewState !== Models.ViewStateEnum.Edit) return;

        actions.viewChanged(vm.onActivityRemoved(a.key));
    }

    function onChange(values: any) {
        setActivityModel({ ...activityModel, ...values });
    }

    function onSave() {
        // validate
        if (vm.viewState !== Models.ViewStateEnum.Edit) return;
        if (!activityModel) throw new Error("Activity not provided.");

        // validation
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);

        switch (editMode) {
            case ActivityEditModeEnum.Add:
                actions.viewChanged(vm.onActivityAdded(activityModel));
                break;
            case ActivityEditModeEnum.Update:
                actions.viewChanged(vm.onActivityUpdated(activityModel));
                break;
            default:
                break;
        }

        clearEditor();
    }

    // close editor, clear activity model and reset validation visibility
    function clearEditor() {
        setActivityModel(undefined);
        setEditMode(undefined);
        setValidationVisibility(ValidationVisibilityEnum.Messages);
    }

    function commandCell(props: GridCellProps) {
        const { dataItem } = props;
        const a = dataItem as Client.DraftApplicationWorkProgramActivityDto;
        if (!a) return <></>;

        return (
            <CustomGridCell gridCellProps={props} className="k-command-cell">
                <button className="btn btn-outline-primary me-2" onClick={() => editExisting(a)}>
                    <PencilIcon size="small" />
                </button>
                <button className="btn btn-outline-secondary" onClick={() => removeExisting(a)}>
                    <XIcon size="small" />
                </button>
            </CustomGridCell>
        );
    }

    return (
        <>
            <Grid className="mb-2" resizable navigatable data={vm.activities}>
                {vm.viewState === Models.ViewStateEnum.Edit && (
                    <GridToolbar>
                        <button title="Add New" className="btn btn-primary" onClick={addNew}>
                            Add New
                        </button>
                    </GridToolbar>
                )}
                <GridColumn field="year" title="Year" width={75} cell={(props) => <CustomGridCell gridCellProps={props}>{getYear(props.dataItem.yearTypeId)?.yearDisplay}</CustomGridCell>} />
                <GridColumn field="quantity" title="Quantity" width={150} format="{0:n0}" />
                <GridColumn field="unit" title="Unit" width={75} cell={(props) => <CustomGridCell gridCellProps={props}>{getUnit(props.dataItem.unitId)?.name}</CustomGridCell>} />
                <GridColumn field="activity" title="Activity" width={325} />
                <GridColumn field="description" title="Description" width={550} />
                <GridColumn field="indicativeExpenditure" title="Indicative Expenditure (AUD)" width={225} format="{0:c0}" />
                {vm.viewState === Models.ViewStateEnum.Edit && <GridColumn width={125} cell={commandCell} />}
            </Grid>
            {editMode && (
                <WorkProgramActivityEditor activity={activityModel} referenceData={vm.referenceData} editMode={editMode} validation={v} onChange={onChange} onSave={onSave} onCancel={clearEditor} />
            )}
        </>
    );
}
