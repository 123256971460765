// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// views
import BoreholeDetailsView from "./views/BoreholeDetailsView";
import NavBar, { TabsEnum } from "../../components/views/NavBarView";
import CustomBoreholeTitleView from "../../components/views/CustomBoreholeTitleView";

interface IRouteParameters {
    wellId: string;
    boreholeId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { wellId, boreholeId } = params;

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(wellId), Number(boreholeId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {vm.borehole && (
                <BodyLayout
                    title={`Borehole - ${vm.borehole?.boreholeName}`}
                    customTitle={CustomBoreholeTitleView({ boreholeName: vm.borehole?.boreholeName, isBoreholePrimary: vm.borehole?.isPrimary })}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_WellsBoreholes_Boreholes}
                    onReloadRequested={() => {
                        props.initialiseRequested(Number(wellId), Number(boreholeId));
                    }}
                >
                    <NavBar wellId={Number(wellId)} boreholeId={Number(boreholeId)} activeNavItem={TabsEnum.Details} />
                    <BoreholeDetailsView vm={vm} />
                </BodyLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_WellsBoreholes_BoreholeDetails }), Actions.actionFactory)(Controller);
