// framework
import { useState, useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
import validator from "../models/validator";
// common
import StatusMessagesAlertsView from "../../../../../../common/alerts/StatusMessagesAlertsView";
import ConflictView from "../../../../../../common/alerts/ConflictAlertView";
import SimpleAuditEventsView from "../../../../../../common/audit/SimpleAuditEventsView";
import ConfirmationView, { DefaultButtonEnum } from "../../../../../../common/confirmation/ConfirmationView";
import * as SecureFileDownloadButtonControl from "../../../../../../common/secureFileDownload/SecureFileDownloadButtonControl";
import DirtyScope from "../../../../../../common/shell/DirtyScopeController";
import { ValidationVisibilityEnum } from "../../../../../../common/validation/ValidationModel";
import * as toastHelper from "../../../../../../common/toastHelper";
// views
import WarningsView from "./views/WarningsView";
import FilesGridView from "./views/FilesGridView";
import UploadFileView from "./views/UploadFileView";
import ChecklistView from "./views/ChecklistView";
import SuppliedOfflineView from "./views/SuppliedOfflineView";

interface IControllerProps extends Actions.IActionFactory {
    id: number;
    versionNumber: number;
    cardTitle: string;
    lastRefreshedDatetime: Date | undefined;
    showCommands: boolean;
    disableEdit: boolean;
    onEdit: () => void;
    onSave: () => void;
    onCancel: () => void;
    className?: string | undefined;
    rootViewModel: Models.IRootViewModel;
    checklistTemplate: Models.IChecklistTemplateViewModel;
}

function Controller(props: IControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const [isBusy, setIsBusy] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [lastRefreshedDatetime, setLastRefreshedDatetime] = useState<Date | undefined>(undefined);

    // on mount
    // - initial load
    useEffect(() => {
        actions.initialiseRequested(props.id);
        return function () {
            actions.clearRequested();
        };
    }, [props.id]); // eslint-disable-line react-hooks/exhaustive-deps

    // refresh
    // - reasons to refresh include (1) the user pressing the refresh button, or (2) saves occurring in panels that could invalidate the content here
    // - don't refresh just because the parent refreshed, this can cause us to immediately lose status changes when a save occurs here
    // - also be careful to ensure a refresh only occurs once, it's easy to make a mistake and have the get-list service call happen multiple times
    useEffect(() => {
        //console.info(vm.viewState, props.lastRefreshedDatetime, lastRefreshedDatetime, vm.versionNumber, props.versionNumber);

        // if it's initialising, just wait
        if (vm.viewState === Models.ViewStateEnum.Initialising) return;

        // a save has happened here, so trigger the upstream save call
        if (vm.versionNumber! > props.versionNumber) {
            props.onSave();
            return;
        }

        // a save has happened externally, so trigger a refresh here
        if (vm.versionNumber! < props.versionNumber) {
            actions.initialiseRequested(props.id);
            return;
        }

        // a manual refresh has been requested
        if (lastRefreshedDatetime !== props.lastRefreshedDatetime) {
            setLastRefreshedDatetime(props.lastRefreshedDatetime);
            actions.initialiseRequested(props.id);
        }
    }, [vm.viewState, props.lastRefreshedDatetime, lastRefreshedDatetime, vm.versionNumber, props.versionNumber]); // eslint-disable-line react-hooks/exhaustive-deps

    // validation
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const validation = validator(vm, validationVisibility);

    function onBusyChanged(busy: boolean): void {
        setIsBusy(busy);
    }

    function onEditClick(): void {
        props.onEdit();
        actions.viewChanged(vm.onEdit());
    }

    function onSaveClick(): void {
        // validation
        // - validation is hidden until the user attempts the action... it then stays visible until they have successfully completed the action
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);

        actions.saveRequested();
    }

    function onCancelClick(): void {
        props.onCancel();
        actions.initialiseRequested(vm.id!);
    }

    function onDeleteRequestClick(): void {
        setShowDeleteConfirmation(true);
    }

    function onDeleteAccepted(): void {
        setShowDeleteConfirmation(false);
        actions.viewChanged(vm.onDelete());
    }

    function onDeleteCancelled(): void {
        setShowDeleteConfirmation(false);
    }

    const files = vm.getFilesForDownload();

    // return
    return (
        <div className={props.className}>
            <div className="card" style={{ minWidth: 250 }}>
                <div className="card-body">
                    <h4 className="card-title">{props.cardTitle}</h4>

                    <DirtyScope scopeName="company/opggs/draftApplicationDetails/controls/supportingDocumentation" isDirty={vm.isDirty} />
                    <ConflictView isConflict={vm.isConflict} />
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} scrollWindowToTop={false} />
                    <WarningsView vm={vm} />

                    {/* guidance */}
                    <div>
                        <p>Documentation to support application.</p>
                    </div>

                    {/* file grid */}
                    <div className="mb-2">
                        <FilesGridView vm={vm} actions={actions} v={validation} isBusy={isBusy} />
                    </div>

                    {/* grid action buttons */}
                    {vm.files.length > 0 && (
                        <div className="mb-4">
                            <SecureFileDownloadButtonControl.ButtonControl disabled={isBusy || vm.numberOfFilesSelected === 0} files={files} className="me-1" />
                            {vm.viewState === Models.ViewStateEnum.Edit && (
                                <button className="btn btn-outline-secondary" disabled={isBusy || vm.numberOfFilesSelected === 0} onClick={onDeleteRequestClick}>
                                    Delete
                                </button>
                            )}
                            <div>
                                <SecureFileDownloadButtonControl.ValidationControl files={files} />
                            </div>
                        </div>
                    )}

                    {/* delete confirmation */}
                    {showDeleteConfirmation && (
                        <ConfirmationView title="Confirm File Deletion" onAccepted={onDeleteAccepted} onClosed={onDeleteCancelled} defaultButton={DefaultButtonEnum.Cancel}>
                            <p>You have selected {vm.numberOfFilesSelected} file(s) for deletion.</p>
                            <p>Do you wish to continue?</p>
                        </ConfirmationView>
                    )}

                    {/* upload view */}
                    {vm.viewState === Models.ViewStateEnum.Edit && (
                        <div className="mb-2">
                            <UploadFileView vm={vm} actions={actions} onBusyChanged={onBusyChanged} />
                        </div>
                    )}

                    {/* checklist view */}
                    <div className="mb-2">
                        <ChecklistView vm={vm} template={props.checklistTemplate} actions={actions} isBusy={isBusy} />
                    </div>

                    {/* supplied offline view */}
                    {vm.canSupplyOffline && (
                        <div className="mb-2">
                            <SuppliedOfflineView vm={vm} actions={actions} v={validation} isBusy={isBusy} />
                        </div>
                    )}

                    {/* audit and edit/save/cancel buttons */}
                    <div className="d-flex flex-wrap mt-2">
                        <SimpleAuditEventsView className="mb-1" simpleAuditEvents={vm.audit} />
                        {vm.viewState === Models.ViewStateEnum.View && props.showCommands && (
                            <div className="ms-auto">
                                <button className="btn btn-outline-primary" onClick={onEditClick} disabled={props.disableEdit}>
                                    Edit
                                </button>
                            </div>
                        )}
                        {vm.viewState === Models.ViewStateEnum.Edit && (
                            <div className="ms-auto">
                                <button className="btn btn-outline-secondary me-2" onClick={onCancelClick} disabled={isBusy}>
                                    Cancel
                                </button>
                                <button className="btn btn-outline-primary" onClick={onSaveClick} disabled={isBusy}>
                                    Save
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_Common_DraftApplicationDetails_Controls_SupportingDocumentation }), Actions.actionFactory)(Controller);
