// api
import * as Client from "../api/Client";
// common
import * as applicationDetailsRouteHelper from "./opggs/applicationDetails/applicationDetailsRouteHelper";
import { LicenceDetailsTabsEnum } from "../common/oei/licenceDetails/views/LicenceDetailsView";

export function dashboard(): string {
    return "/JointAuthority/Home/Dashboard";
}

export function requestList(): string {
    return "/JointAuthority/User/RequestList";
}

export function requestDetails(secureRequestId: number, secureRequestGroupType: Client.SecureRequestGroupTypeEnum): string {
    return `/JointAuthority/User/RequestDetails/${secureRequestGroupType}/${secureRequestId}`;
}

// User Management My Joint Authority
export function myJointAuthority(): string {
    return "/JointAuthority/User/MyJointAuthority";
}

// User Management User List
export function myUserList(): string {
    return "/JointAuthority/User/UserList";
}

// User Management User List
export function userDetails(secureUserId: number): string {
    return `/JointAuthority/User/UserDetails/${secureUserId}`;
}

// My Account
export function myDetails(): string {
    return "/JointAuthority/Account/MyDetails";
}

export function requestAccess(): string {
    return "/JointAuthority/Account/RequestAccess";
}

// Application
export function applicationList(): string {
    return "/JointAuthority/Opggs/ApplicationList";
}

// Applications Details
export function applicationDetails(applicationId: string): string {
    return applicationDetailsRouteHelper.details(applicationId);
}

// Titles
export function titlesList(): string {
    return "/JointAuthority/Opggs/TitleList";
}

// Licence
export function licenceList(): string {
    return "/JointAuthority/Oei/LicenceList";
}

export function licenceDetails(licenceId: string, tab?: LicenceDetailsTabsEnum): string {
    if (tab) {
        return `/JointAuthority/Oei/LicenceDetails/${licenceId}/${tab}`;
    }
    return `/JointAuthority/Oei/LicenceDetails/${licenceId}`;
}
