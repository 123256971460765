// framework
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
// redux
import * as Models from "../../models/models";
import * as Actions from "../../redux/actions";
// children views
import DetailsView from "./DetailsView";
import TitleholderForeignInvestmentView from "./TitleholderForeignInvestmentView";
// common
import * as ViewHelpers from "../../../../../common/draftApplicationDetails/ViewHelpers";

export enum TabsEnum {
    Details,
    WorkProgram,
    TitleholderForeignInvestment,
}

// note: whilst preferred tab isn't used, it is likely this will be needed down the track.
export default function TabsView(props: {
    vm: Models.IRootViewModel;
    actions: Actions.IActionFactory;
    halvingRulesLink: string | undefined;
    lastRefreshedDatetime: Date | undefined;
    preferredTab?: TabsEnum;
}) {
    const vm = props.vm;
    const a = props.actions;

    const preferredTab = props.preferredTab ?? TabsEnum.Details;
    const inEdit = ViewHelpers.isInEdit(vm.state.inEditTask);

    // NOTE: We are not using lazy loading (mountOnEnter / unmountOnExit) because of the requirement to be able to view draft application details post-submission i.e. after the app should no longer be visible to the user.
    return (
        <Tabs defaultActiveKey={preferredTab} variant="pills" className="mb-2">
            {/* Details Tab */}
            <Tab eventKey={TabsEnum.Details} title="Details" disabled={inEdit}>
                <DetailsView vm={vm} actions={a} halvingRulesLink={props.halvingRulesLink} lastRefreshedDatetime={props.lastRefreshedDatetime} />
            </Tab>
            {/* Titleholder Foreign Investment Tab */}
            <Tab eventKey={TabsEnum.TitleholderForeignInvestment} title="Titleholders" disabled={inEdit}>
                <TitleholderForeignInvestmentView vm={vm} actions={a} lastRefreshedDatetime={props.lastRefreshedDatetime} />
            </Tab>
        </Tabs>
    );
}
