// redux
import * as Models from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";

export default function IarlDetailsRlReadonlyView(props: { vm: Models.IIappRlDetailsViewModel }) {
    const vm = props.vm;

    return (
        <>
            <Forms.Group>
                <Forms.ReadonlyField label="Field Name" id="rlDetailsExistingField" value={vm.fieldName} />
            </Forms.Group>
            <strong>If titleholder of a petroleum retention lease (section 170 of the OPGGS Act)</strong>
            <Forms.Group>
                <Forms.ReadonlyField
                    label="Date of revocation or refusal of renewal of retention lease (if applicable, see section 155 or 158 of the OPGGS Act)"
                    id="rlDetailsDateOfRevocationOrRefusalOfRenewalOfRetentionLeaseDate"
                    value={GlobalHelpers.toNoptaDateString(vm.dateOfRevocationOrRefusalOfRenewalOfRetentionLeaseDate)}
                />
            </Forms.Group>
        </>
    );
}
