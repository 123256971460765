// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
// common
import { ValidationModel } from "../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
import { KendoSingleSmallFileUpload } from "../../../../../common/fileUpload/KendoSingleSmallFileUpload";
import ExternalLink from "../../../../../common/externalLink/ExternalLinkView";
import * as Forms from "../../../../../common/forms/BootstrapForms";

export function NewJointAuthorityAdministratorRequestView(props: { vm: Models.IRootViewModel; v: ValidationModel; actions: Actions.IActionFactory; requestFormLink: string | undefined }) {
    const vm = props.vm;
    const validation = props.v;
    const actions = props.actions;
    const requestFormLink = props.requestFormLink;

    function onFileUploadChange(fileName: string | undefined, content?: string | undefined) {
        actions.viewChanged(vm.refreshJaaAuthoritySupportingDocumentUpdated({ fileName, content }));
    }

    return (
        <div className="card mb-2">
            <h2 className="card-header">Joint Authority Administrator Supporting Documentation</h2>
            <div className="card-body">
                <Forms.Group>
                    <p>A Joint Authority Administration request form signed by Joint Authority Directors is required in order to apply for Joint Authority Administrator access.</p>
                    <ExternalLink href={requestFormLink}>Joint Authority Administrator Request Form</ExternalLink>
                </Forms.Group>
                <h3 className="card-subtitle">Upload Joint Authority Application Request Form</h3>
                <Forms.Group>
                    <p>A copy of the signed Joint Authority Administrator form must be supplied</p>
                    <KendoSingleSmallFileUpload onFileValidlySelected={onFileUploadChange} allowedExtensions={[".pdf", ".tiff", ".png", ".jpg", ".jpeg", ".docx"]} maxFileSizeMb={20} />
                    <ValidationMessageView name="JaaAuthoritySupportingDocument" validationModel={validation} />
                </Forms.Group>
            </div>
        </div>
    );
}
