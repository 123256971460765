// common
import * as Validation from "../../../../../../../common/validation/ValidationModel";
// models
import { IRootViewModel } from "./models";

export default function validate(vm: IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);

    if (!vm.bulkDeleteBatchItem) {
        v.addError("AttachmentBulkDelete.batchItem", "Bulk Import is required.");
    }

    return v;
}
