// redux
import { IRegisteredTitleholderDetailsViewModel } from "./registeredTitleholderDetailsViewModel";
// common
import * as Forms from "../../../../common/forms/BootstrapForms";

export default function RegisteredTitleholderDetailsReadonlyView(props: { vm: IRegisteredTitleholderDetailsViewModel; formTypeId: number | undefined }) {
    const vm = props.vm;

    return (
        <Forms.Row>
            <Forms.HalfColumn>
                <Forms.ReadonlyField label="ACN/ARBN" id="acnOrArbnField" value={vm.acnOrArbn} />
            </Forms.HalfColumn>
            <Forms.HalfColumn>
                {props.formTypeId === 2 && (
                    <Forms.ReadonlyField label="Date of becoming a Registered Titleholder" id="becomingARegisteredTitleholderDateField" value={vm.becomingARegisteredTitleholderDate} />
                )}
            </Forms.HalfColumn>
            <Forms.HalfColumn>
                <Forms.ReadonlyField label="Registered Titleholder's Phone" id="phoneField" value={vm.phone} />
            </Forms.HalfColumn>
            <Forms.HalfColumn>
                <Forms.ReadonlyField label="Registered Titleholder's Fax" id="faxField" value={vm.fax} />
            </Forms.HalfColumn>
            <Forms.HalfColumn>
                <Forms.ReadonlyField label="Registered Titleholder's Email" id="emailField" value={vm.email} />
            </Forms.HalfColumn>
        </Forms.Row>
    );
}
