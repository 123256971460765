// framework
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, Redirect } from "react-router-dom";
import ConflictView from "../../../../common/alerts/ConflictAlertView";
import StatusMessagesAlertsView from "../../../../common/alerts/StatusMessagesAlertsView";
import { ValidationVisibilityEnum } from "../../../../common/validation/ValidationModel";
import DirtyScope from "../../../../common/shell/DirtyScopeController";
// api
import { SecureRequestGroupTypeEnum, ExternalLinkEnum } from "../../../../api/Client";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Actions from "../redux/actions";
// models
import * as Models from "../models/rootViewModel";
import validator from "../models/validator";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
// views
import RequestDetailsView from "./views/RequestDetailsView";
import NewUserDetailsView from "./views/NewUserDetailsView";
import ExistingUserDetailsView from "./views/ExistingUserDetailsView";
import CompanyStandardRoleDetailsView from "./views/CompanyStandardRoleDetailsView";
import CompanySignerDetailsView from "./views/CompanySignerDetailsView";
import RejectionDetailsView from "./views/RejectionDetailsView";
// other
import * as companyRouteHelper from "../../../companyRouteHelper";
import * as ConfigurationHelper from "../../../../common/shell/configurationHelper";
import * as toastHelper from "../../../../common/toastHelper";

interface IRequestDetailsControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

interface IRequestDetailsRouteParameters {
    type: SecureRequestGroupTypeEnum;
    id: string;
}

function RequestDetailsController(props: IRequestDetailsControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    // route parameters
    const params = useParams<IRequestDetailsRouteParameters>();
    const secureRequestGroupId = parseInt(params.id);
    const secureRequestGroupType = params.type;

    const csNoptaSignatureGuidanceLink = ConfigurationHelper.usePortalLink(ExternalLinkEnum.SignatureGuidance);

    // on mount
    useEffect(() => {
        props.initialiseRequested(secureRequestGroupId, secureRequestGroupType);
        return function () {
            props.clearRequested();
        };
    }, [secureRequestGroupId, secureRequestGroupType]); // eslint-disable-line react-hooks/exhaustive-deps

    // validation
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const v = validator(vm, validationVisibility);

    // submission
    function onSubmitClicked(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        // validation
        // - validation is hidden until the user attempts the action... it then stays visible until they have successfully completed the action
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);

        // submission
        actions.approveRejectRequestGroupRequested();
    }

    // display the rejection details card only when the entire request is rejected
    // if it's partially rejected, the information in the rejection details card isn't required
    function showRejectionDetails() {
        return (!vm.isCompanySignerRequest || vm.companySignerDetails.isApproved === false) && (!vm.isCompanyStandardRoleRequest || vm.companyStandardRoleDetails.isApproved === false);
    }

    const statusMessages = vm.service.statusMessages;
    if (statusMessages && statusMessages.isSuccess) {
        return <Redirect to={companyRouteHelper.requestList()} />;
    }

    return (
        <BodyLayout
            title={vm.isNewUserRequest ? "Review User Request" : "Review Access Request"}
            selectedLhsNavItem={Level1ItemTypeEnum.Company_UserManagement_ViewRequestList}
            onReloadRequested={() => {
                props.initialiseRequested(secureRequestGroupId, secureRequestGroupType);
                setValidationVisibility(ValidationVisibilityEnum.Messages);
            }}
        >
            <ConflictView isConflict={vm.isConflict} />
            <StatusMessagesAlertsView statusMessages={vm.service.statusMessages} />
            <RequestDetailsView vm={vm.summary} />
            {vm.isNewUserRequest ? <NewUserDetailsView vm={vm.newUserDetails} /> : <ExistingUserDetailsView vm={vm.existingUserDetails} />}
            <DirtyScope scopeName="company/userManagement/requestDetails" isDirty={vm.isDirty}>
                <form onSubmit={onSubmitClicked}>
                    {vm.isCompanyStandardRoleRequest && <CompanyStandardRoleDetailsView vm={vm} actions={actions} validation={v} />}
                    {vm.isCompanySignerRequest && <CompanySignerDetailsView vm={vm} actions={actions} validation={v} csNoptaSignatureGuidanceLink={csNoptaSignatureGuidanceLink} />}
                    {showRejectionDetails() && <RejectionDetailsView vm={vm} actions={actions} validation={v} />}
                    <button className="btn btn-outline-primary me-2" type="submit" disabled={!vm.isDirty}>
                        Submit
                    </button>
                </form>
            </DirtyScope>
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_UserManagement_RequestDetails }), Actions.actionFactory)(RequestDetailsController);
