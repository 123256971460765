// framework
import React from "react";
import { Link } from "react-router-dom";
import * as Client from "../../api/Client";
// bootstrap
import { Badge } from "react-bootstrap";
// common
import ExternalLinkView from "../externalLink/ExternalLinkView";
import * as companyRouteHelper from "../../company/companyRouteHelper";
import * as shellRouteHelper from "../../shell/shellRouteHelper";
import * as ConfigurationHelper from "../shell/configurationHelper";
import { TitleDetailsTabsEnum } from "../../company/opggs/titleDetails/components/views/TitleDetailsView";

interface ITitleLinkControllerProps {
    linkDto: Client.TitleLinkDto;
    secureEntityType: string;
    tab?: TitleDetailsTabsEnum;
}

// provides for the following capabilities:
// (1) Company links only  (JA currently unsupported, but support theoretically will be expected in the very near future, GA unknown)
// (2) Authorised links will route to:
//     - Company
//       - my titles will route to the internal Title Details page
//       - not my titles will route to Public Portal
// (3) Badges - will render appropriate Title Badges  (TODO how does this affect JA? is there anything sensitive in the badges data?)

function TitleLinkController(props: ITitleLinkControllerProps): React.ReactElement {
    if (!props.linkDto) throw new Error("Title Link cannot be undefined!");
    const link = props.linkDto;
    const secureEntityType = props.secureEntityType;

    const titleId = link.id;
    const titleBadges = ConfigurationHelper.useTitleBadges(titleId);

    const titleNumber = link.titleNumber;
    const isAuthorised = link.isAuthorised; // Currently only company links may be authorised, JA title links will be external
    const tab = props.tab;

    function renderTitleLink(titleId: string, titleNumber: string, isAuthorised: boolean, secureEntityType: string): React.ReactElement {
        if (isAuthorised && secureEntityType === "Company") {
            return <Link to={companyRouteHelper.titleDetails(titleId, tab)}>{titleNumber}</Link>;
        }
        return <ExternalLinkView href={shellRouteHelper.publicPortalTitleDetails(titleNumber)}>{titleNumber}</ExternalLinkView>;
    }

    if (!titleBadges) return renderTitleLink(titleId, titleNumber, isAuthorised, secureEntityType);

    return (
        <>
            {renderTitleLink(titleId, titleNumber, isAuthorised, secureEntityType)}

            {titleBadges.hasPriorUseRights && (
                <Badge pill bg="info" title="Prior Usage Right" className="ms-1">
                    PUR
                </Badge>
            )}
            {titleBadges.hasRoyaltyAct && (
                <Badge pill bg="primary" title="Royalty Act" className="ms-1">
                    RA
                </Badge>
            )}
            {titleBadges.hasPerthTreaty && (
                <Badge pill bg="danger" title="Perth Treaty" className="ms-1">
                    PT
                </Badge>
            )}
        </>
    );
}

export default TitleLinkController;
