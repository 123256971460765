// redux
import * as Models from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as companyRouteHelper from "../../../../../companyRouteHelper";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";

export default function EpRenewalDetailsReadonlyView(props: { vm: Models.IEpRenewalDetailsViewModel }) {
    const vm = props.vm;

    return (
        <>
            <Forms.Group>
                <Forms.Link label="Title Number" linkText={vm.titleNumber} linkTo={vm.titleId && companyRouteHelper.titleDetails(vm.titleId)} />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="Expiry Date" id="expiryDateField" value={GlobalHelpers.toNoptaDateString(vm.titleExpiryDate)} />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="Accepted extension of time to lodge Application" id="isExtensionAcceptedField" value={GlobalHelpers.convertBooleanToStringValue(vm.isExtensionAccepted)!} />
            </Forms.Group>
        </>
    );
}
