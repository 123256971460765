// framework
import * as Validation from "../../../../../../common/validation/ValidationModel";
// models
import * as Models from "./models";

export default function validate(vm: Models.IActivityViewModel | undefined, rd: Models.IReferenceDataViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateActivity(vm, rd, v);
    return v;
}

function validateActivity(a: Models.IActivityViewModel | undefined, rd: Models.IReferenceDataViewModel, v: Validation.ValidationModel): void {
    if (a === undefined) return;

    if (!a.year) v.addError("WorkProgram.Year", "Year is required.");
    else if (!rd.validYears.some((y) => y.yearTypeId === a.year?.yearTypeId)) v.addError("WorkProgram.Year", "Year is invalid.");

    if (Validation.isNullOrWhiteSpace(a.activity)) v.addError("WorkProgram.Activity", "Activity is required.");
    else if (!rd.activityList.some((al) => al === a.activity!)) v.addError("WorkProgram.Activity", "Activity is invalid.");

    if (a.indicativeExpenditure === undefined) v.addError("WorkProgram.IndicativeExpenditure", "Indicative Expenditure is required.");
    else if (a.indicativeExpenditure < 0) v.addError("WorkProgram.IndicativeExpenditure", "Indicative Expenditure cannot be less than zero.");
    else if (a.indicativeExpenditure > 99999999999999) v.addError("WorkProgram.IndicativeExpenditure", "Indicative Expenditure cannot have more than 14 digits.");

    if (Validation.isNullOrWhiteSpace(a.description)) v.addError("WorkProgram.Description", "Description is required.");
    if (a.unit && !rd.units.some((u) => u.id === a.unit?.id)) v.addError("WorkProgram.Unit", "Unit is invalid.");
    if (a.unit && !a.quantity) v.addError("WorkProgram.Unit", "Units can only be provided when a Quantity is provided.");

    if (a.quantity === undefined) return;
    else if (a.quantity <= 0) v.addError("WorkProgram.Quantity", "Quantity must be greater than zero.");
    else if (a.quantity > 999999999999) v.addError("WorkProgram.Quantity", "Quantity cannot have more than 12 digits.");
}
