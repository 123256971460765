// framework
import React from "react";
import { NavLink } from "react-router-dom";
import ExternalLinkView from "../../../../common/externalLink/ExternalLinkView";
// views
import * as shellRouteHelper from "../../../shellRouteHelper";
import EnvironmentFootnoteView from "./EnvironmentFootnoteView";
// home
import * as homeRouteHelper from "../../../../home/homeRouteHelper";

export default function FooterView(): React.ReactElement {
    return (
        <div className="container-fluid p-2">
            <hr />
            <div className="d-flex flex-wrap align-items-center">
                <NavLink className="me-4" to={homeRouteHelper.home()}>
                    Home
                </NavLink>
                <NavLink className="me-4" to={shellRouteHelper.accessibility()}>
                    Accessibility
                </NavLink>
                <ExternalLinkView href="https://www.nopta.gov.au/copyright.html" className="me-4">
                    Copyright
                </ExternalLinkView>
                <NavLink className="me-4" to={shellRouteHelper.disclaimer()}>
                    Disclaimer
                </NavLink>
                <ExternalLinkView href="https://www.nopta.gov.au/privacy.html" className="me-4">
                    Privacy
                </ExternalLinkView>
                <div style={{ marginLeft: "auto" }}>
                    <EnvironmentFootnoteView />
                </div>
            </div>
        </div>
    );
}
