// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_WellsBoreholes_ReportDetails;

export default function* deleteReport() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const deleteResult: CallHelper.CallResult<Client.DeleteGeoscienceAustraliaReportResponseDto> = yield call(async () => await deleteReportCall(vm));

        if (deleteResult.IsSuccess) {
            // success
            let updatedVm = vm;
            yield put(Actions.actionFactory.sagaCompleted(updatedVm.refreshDeleteResponse(deleteResult.Response!)));
        } else if (deleteResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            deleteResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function deleteReportCall(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.DeleteGeoscienceAustraliaReportResponseDto>> {
    const request = new Client.DeleteGeoscienceAustraliaReportRequestDto({
        reportId: vm.reportId!,
        reportVersionNumber: vm.reportVersionNumber!,
    });

    const client = await ClientFactory.createGeoscienceAustraliaReportClient();
    return await CallHelper.call(() => client.deleteGeoscienceAustraliaReport(request));
}
