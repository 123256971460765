// redux
import * as Models from "../../models/models";
// controls
import ApplicationDetailsControl from "../../controls/applicationDetails/components/Controller";
import DocumentationControl from "../../controls/documentation/components/Controller";
import { useFeatureSwitch } from "../../../../../common/shell/configurationHelper";
import { NeatsFeatureSwitchEnum } from "../../../../../api/Client";

export default function DetailsView(props: { vm: Models.IRootViewModel; lastRefreshedDatetime: Date | undefined }) {
    const vm = props.vm;
    const id = vm.id!;

    // TODO not sure this will hold up for every application type, may have to calculate in NEATS services and send up the stack
    const title = vm.header.applicationTypeName + " Application Details";

    // TODO remove on DAM completion
    const allowViewDocuments = useFeatureSwitch(NeatsFeatureSwitchEnum.Dam2ViewDocuments);

    if (!id) return <></>;

    return (
        <>
            <ApplicationDetailsControl id={id} cardTitle={title} lastRefreshedDatetime={props.lastRefreshedDatetime} className="mb-2" />
            {allowViewDocuments && (
                <DocumentationControl
                    id={id}
                    applicationFormCardTitle="Application Form"
                    supportingDocumentationCardTitle="Supporting Documentation"
                    lastRefreshedDatetime={props.lastRefreshedDatetime}
                    className="mb-2"
                />
            )}
        </>
    );
}
