import * as Client from "../../../../api/Client";
import { clone } from "lodash";

export interface IRootViewModel {
    showNoAssociatedCompaniesTile: boolean;
    showApplicationsTile: boolean;
    showFinancialNotificationsTile: boolean;
    showTitlesTile: boolean;

    applications: applicationsType;
    financialNotifications: financialNotificationsType;
    titles: titlesType;

    refreshSaga(response: Client.GetCompanyHomeDashboardResponseDto): IRootViewModel;
}

export type applicationsType = Array<Client.CompanyApplicationListItemDto> | undefined;
export type financialNotificationsType = Array<Client.CompanyFinanceFnListDetailDto> | undefined;
export type titlesType = Array<Client.GetCompanyHomeDashboardResponseTitleDto> | undefined;

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.showNoAssociatedCompaniesTile = false;
        this.showApplicationsTile = false;
        this.showFinancialNotificationsTile = false;
        this.showTitlesTile = false;

        this.applications = undefined;
        this.financialNotifications = undefined;
        this.titles = undefined;
    }

    public showNoAssociatedCompaniesTile: boolean;
    public showApplicationsTile: boolean;
    public showFinancialNotificationsTile: boolean;
    public showTitlesTile: boolean;

    public applications: applicationsType;
    public financialNotifications: financialNotificationsType;
    public titles: titlesType;

    public refreshSaga(response: Client.GetCompanyHomeDashboardResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.showNoAssociatedCompaniesTile = !response.hasAssociatedCompanies;
        vm.showApplicationsTile = response.isApplicationsTileVisible;
        vm.showFinancialNotificationsTile = response.isFinancialNotificationsTileVisible;
        vm.showTitlesTile = response.isTitlesTileVisible;

        vm.applications = response.applications;
        vm.financialNotifications = response.financialNotifications;
        vm.titles = response.titles;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
