// redux
import { call, put, select } from "redux-saga/effects";
import { ICompanyReduxState } from "../../../../companyReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// shell
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

const getRootViewModel = (state: ICompanyReduxState) => state.Company_Oei_OeiAdhocUpload;

export default function* createApplicationAdhocUpload() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const response: Client.CreateCompanyOeiOeiAdhocUploadResponseDto = yield call(async () => {
            const client = await ClientFactory.createCompanyOeiOeiAdhocUploadClient();
            return await CallHelper.simpleCall(() =>
                client.createCompanyOeiOeiAdhocUpload(
                    new Client.CreateCompanyOeiOeiAdhocUploadRequestDto({
                        fileIds: vm.files.map((f) => f.fileId),
                        comments: vm.comments,
                        applicantCompanyId: vm.applicantCompany!.companyId,
                    })
                )
            );
        });

        yield put(Actions.actionFactory.sagaCompleted(vm.refreshCreateStatusMessages(response.statusMessages)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
