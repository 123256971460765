// framework
import { useState } from "react";
// kendo
import { process, State } from "@progress/kendo-data-query";
import { Grid, GridCellProps, GridColumn, GridItemChangeEvent, GridSelectionChangeEvent, GridHeaderSelectionChangeEvent } from "@progress/kendo-react-grid";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
// redux
import * as Models from "../../models/models";
import * as Actions from "../../redux/actions";
// api
import { GetCompanyOpggsDraftApplicationDetailsSupportingDocumentationSensitiveToCompanyDto as SensitiveToCompanyDto } from "../../../../../../../api/Client";
// views
import SensitivityCell from "./SensitivityCell";
// common
import SecureFileDownloadLinkControl from "../../../../../../../common/secureFileDownload/SecureFileDownloadLinkControl";
import { IValidationModel } from "../../../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../../../common/validation/ValidationMessageView";
import CustomGridCell from "../../../../../../../common/kendoGrid/CustomGridCell";
import * as GlobalHelpers from "../../../../../../../common/GlobalHelpers";

export default function FilesGridView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; v: IValidationModel; isBusy: boolean }): React.ReactElement {
    const { vm, actions, v, isBusy } = props;

    // remove deleted files from view
    const files = vm.files.filter((f) => f.state !== Models.FileStateEnum.Deleted);

    const initialGridState = {
        sort: [{ field: "fileName", dir: "asc" }],
        skip: 0,
        take: 10,
    } as State;
    const [gridState, setGridState] = useState(initialGridState);

    function onGridItemChange(e: GridItemChangeEvent): void {
        if (isBusy) return;
        if (vm.viewState === Models.ViewStateEnum.View) return;
        if (!vm.canSetSensitivity) return;

        const f = e.dataItem as Models.IFileViewModel;
        const sensitiveToCompany = e.value as SensitiveToCompanyDto;

        actions.viewChanged(vm.onFileChanged(f.fileId, sensitiveToCompany));
    }

    function onSelectionChange(e: GridSelectionChangeEvent): void {
        const f = e.dataItem as Models.IFileViewModel;
        if (!f) return;
        actions.viewChanged(vm.onFileSelected(f.fileId));
    }

    function onHeaderSelectionChange(e: GridHeaderSelectionChangeEvent): void {
        const headerCheckbox = e.syntheticEvent.target as HTMLInputElement;
        actions.viewChanged(vm.onFilesSelected(headerCheckbox.checked));
    }

    // Note: looked at using cellRender approach, it seems cleaner but there are issues with Typescript https://www.telerik.com/forums/selectively-hide-checkbox-in-grid-selection-column-based-on-row-data
    function getSelectionCell(props: GridCellProps) {
        const handleChange = (e: CheckboxChangeEvent) => {
            props.selectionChange!({ syntheticEvent: e.syntheticEvent });
        };

        const { dataItem } = props;
        const f = dataItem as Models.IFileViewModel;

        // Note: using Kendo rather than native checkboxes for controls inside Kendo grids
        return <CustomGridCell gridCellProps={props}>{f.canSelect(vm.viewState) && <Checkbox checked={f.isSelected} onChange={handleChange} />}</CustomGridCell>;
    }

    return (
        <>
            <Grid
                className="mb-2"
                pageable
                sortable
                resizable
                navigatable
                onItemChange={onGridItemChange}
                selectedField="isSelected"
                onSelectionChange={onSelectionChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
                pageSize={10}
                data={process(files, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn field="isSelected" title="Select" width={100} headerSelectionValue={vm.allFilesSelected} cell={getSelectionCell} />
                <GridColumn
                    field="fileName"
                    title="File Name"
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.canDownload ? <SecureFileDownloadLinkControl id={props.dataItem.fileId}>{props.dataItem.fileName}</SecureFileDownloadLinkControl> : props.dataItem.fileName}
                        </CustomGridCell>
                    )}
                    width={600}
                />
                {vm.canSetSensitivity && (
                    <GridColumn field="sensitiveToCompany.displayName" title="Sensitivity" cell={(props) => SensitivityCell(props, vm.sensitiveToCompanies, vm.viewState, v)} width={400} />
                )}
                <GridColumn
                    field="fileSizeMb"
                    title="File Size"
                    width={150}
                    cell={(props) => <CustomGridCell gridCellProps={props}>{GlobalHelpers.convertMegabytesToString(props.dataItem.fileSizeMb)}</CustomGridCell>}
                />
                <GridColumn field="uploadedDatetime" title="Uploaded" width={200} format={GlobalHelpers.NoptaDatetimeFormatForKendo} />
            </Grid>
            <ValidationMessageView name="SupportingDocumentationFiles.AllFiles" validationModel={v} />
        </>
    );
}
