// models
import * as Models from "../../../../common/draftApplicationDetails/components/models/models";
// api
import * as Client from "../../../../../api/Client";

export interface IIarlEpDetailsViewModel {
    existingLocations: Array<Client.GetCompanyOpggsDraftApplicationDetailsIarlEpDetailsExistingLocationDto>;
    existingLocationId: number | undefined;
    dateLocationDeclared?: Date | undefined;
    isLongerApplicationPeriodAllowed?: boolean | undefined;
}

export interface IIarlPpDetailsViewModel {
    existingNeatsFieldName: string | undefined;
    initialGrantDate?: Date | undefined;
    isCreateNewField: boolean | undefined;
    newFieldName: string | undefined;
    lastDayOfPetroleumRecoveryOperationsDate?: Date | null | undefined;
}

export interface IIarlViewModel {
    epDetails?: IIarlEpDetailsViewModel | undefined;
    ppDetails?: IIarlPpDetailsViewModel | undefined;
}

export interface IIarlDetailsViewModel extends IIarlViewModel {
    titleId?: string | undefined;
    titleNumber?: string | undefined;
    titleExpiryDate?: Date | undefined;
    originatingTitleType?: Client.TitleTypeEnum | undefined;
}

export interface IIarlApplicationDetailsViewModel extends Models.IApplicationDetailsViewModel {
    iarlDetails: IIarlDetailsViewModel;
}

export interface IRootViewModel extends Models.IDraftApplicationRootViewModel {
    applicationDetails: IIarlApplicationDetailsViewModel;

    // view triggered state changes
    refreshIarlUpdated(values: IIarlViewModel): IRootViewModel;
}

export class RootViewModel extends Models.BaseDraftApplicationRootViewModel implements IRootViewModel {
    constructor() {
        super();
        this.applicationDetails = {
            isDigitalSignatures: false,
            iarlDetails: {
                epDetails: {
                    existingLocations: [],
                    existingLocationId: undefined,
                    dateLocationDeclared: undefined,
                    isLongerApplicationPeriodAllowed: undefined,
                },
                ppDetails: {
                    existingNeatsFieldName: undefined,
                    initialGrantDate: undefined,
                    isCreateNewField: undefined,
                    newFieldName: undefined,
                    lastDayOfPetroleumRecoveryOperationsDate: undefined,
                },
            },
            isDirty: false,
        };
    }

    public override applicationDetails: IIarlApplicationDetailsViewModel;

    public override refreshDetails(response: Client.GetCompanyOpggsDraftApplicationDetailsIarlResponseDto): this {
        const vm = super.refreshDetails(response);

        vm.applicationDetails.iarlDetails.titleId = response.titleId;
        vm.applicationDetails.iarlDetails.titleNumber = response.titleNumber;
        vm.applicationDetails.iarlDetails.titleExpiryDate = response.titleExpiryDate;
        vm.applicationDetails.iarlDetails.originatingTitleType = response.originatingTitleType;

        switch (vm.applicationDetails.iarlDetails.originatingTitleType) {
            case Client.TitleTypeEnum.ExplorationPermit:
                var existingLocationId = response.epDetails!.existingLocationId;
                vm.applicationDetails.iarlDetails.epDetails!.existingLocations = response.epDetails!.existingLocations!;
                vm.applicationDetails.iarlDetails.epDetails!.existingLocationId = existingLocationId;
                vm.applicationDetails.iarlDetails.epDetails!.dateLocationDeclared = response.epDetails!.existingLocations?.find((l) => l.id === existingLocationId)?.dateLocationDeclared;
                vm.applicationDetails.iarlDetails.epDetails!.isLongerApplicationPeriodAllowed = response.epDetails!.existingLocations?.find(
                    (l) => l.id === existingLocationId
                )?.isLongerApplicationPeriodAllowed;
                break;
            case Client.TitleTypeEnum.ProductionLicence:
                vm.applicationDetails.iarlDetails.ppDetails!.existingNeatsFieldName = response.ppDetails!.existingNeatsFieldName;
                vm.applicationDetails.iarlDetails.ppDetails!.initialGrantDate = response.ppDetails!.initialGrantDate;
                vm.applicationDetails.iarlDetails.ppDetails!.isCreateNewField = response.ppDetails!.isCreateNewField;
                vm.applicationDetails.iarlDetails.ppDetails!.newFieldName = response.ppDetails!.newFieldName;
                vm.applicationDetails.iarlDetails.ppDetails!.lastDayOfPetroleumRecoveryOperationsDate = response.ppDetails!.lastDayOfPetroleumRecoveryOperationsDate;
                break;
            default:
                throw new Error("Invalid Title Type.");
        }

        vm.applicationDetails.isDirty = false;

        return vm;
    }

    public refreshIarlUpdated(values: IIarlViewModel): IRootViewModel {
        const vm = this._clone();

        vm.applicationDetails.iarlDetails = { ...vm.applicationDetails.iarlDetails, ...values };
        vm.applicationDetails.isDirty = true;

        return vm;
    }
}
