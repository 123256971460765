// redux
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";

export async function getCompanyOpggsDraftApplicationDetailsContact(id: number): Promise<Client.GetCompanyOpggsDraftApplicationDetailsContactResponseDto> {
    // console.info("getCompanyOpggsDraftApplicationDetailsContact");
    const client = await ClientFactory.createCompanyOpggsDraftApplicationDetailsClient();
    return await CallHelper.simpleCall(() => client.getCompanyOpggsDraftApplicationDetailsContact(new Client.GetCompanyOpggsDraftApplicationDetailsContactRequestDto({ id: id })));
}

export async function saveCompanyOpggsDraftApplicationDetailsContact(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.SaveCompanyOpggsDraftApplicationDetailsContactResponseDto>> {
    const client = await ClientFactory.createCompanyOpggsDraftApplicationDetailsClient();

    return await CallHelper.call(() =>
        client.saveCompanyOpggsDraftApplicationDetailsContact(
            new Client.SaveCompanyOpggsDraftApplicationDetailsContactRequestDto({
                id: vm.id!,
                versionNumber: vm.versionNumber!,
                contactName: vm.contact.contactName,
                positionHeld: vm.contact.positionHeld,
                phone: vm.contact.phone,
                email: vm.contact.email,
            })
        )
    );
}
