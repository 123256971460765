// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_WellsBoreholes_ReportRelatedActivities;

export default function* deleteRelatedActivity(action: Actions.IAction<Actions.IDeleteRequestedPayload>) {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const { reportId, reportVersionNumber, activityId, activityType } = action.payload;

    try {
        yield put(shellHelper.createSetBusyAction());

        const deleteResult: CallHelper.CallResult<Client.DeleteGeoscienceAustraliaReportRelatedActivityResponseDto> = yield call(
            async () => await deleteRelatedActivityCall(reportId, reportVersionNumber, activityId, activityType)
        );

        if (deleteResult.IsSuccess) {
            // success
            let updatedVm = vm;
            const r = deleteResult.Response!;
            if (r.statusMessages!.isSuccess) {
                const getRelatedActivitiesResponse: CallHelper.CallResult<Client.GetGeoscienceAustraliaReportRelatedActivitiesResponseDto> = yield call(async () => await getRelatedActivities(vm));
                updatedVm = updatedVm.refreshRelatedActivities(getRelatedActivitiesResponse.Response!);
            }
            yield put(Actions.actionFactory.sagaCompleted(updatedVm.refreshDeleteResponse(deleteResult.Response!)));
        } else if (deleteResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            deleteResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function deleteRelatedActivityCall(
    reportId: number,
    reportVersionNumber: number,
    activityId: number,
    activityType: Client.ReportRelatedActivityTypeEnum
): Promise<CallHelper.CallResult<Client.DeleteGeoscienceAustraliaReportRelatedActivityResponseDto>> {
    const request = new Client.DeleteGeoscienceAustraliaReportRelatedActivityRequestDto({
        reportId: reportId,
        reportVersionNumber: reportVersionNumber!,
        activityId: activityId,
        reportRelatedActivityType: activityType,
    });

    const client = await ClientFactory.createGeoscienceAustraliaReportClient();
    return await CallHelper.call(() => client.deleteGeoscienceAustraliaReportRelatedActivity(request));
}

async function getRelatedActivities(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.GetGeoscienceAustraliaReportRelatedActivitiesResponseDto>> {
    const request = new Client.GetGeoscienceAustraliaReportRelatedActivitiesRequestDto({
        reportId: vm.reportId!,
    });

    const client = await ClientFactory.createGeoscienceAustraliaReportClient();
    return await CallHelper.call(() => client.getGeoscienceAustraliaReportRelatedActivities(request));
}
