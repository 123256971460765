// kendo
import { GridColumn, GridCellProps, GridColumnProps } from "@progress/kendo-react-grid";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
// model
import { IRoleViewModel } from "./RoleViewModel";
// api
import * as Client from "../../api/Client";
// other
import CustomGridCell from "../../common/kendoGrid/CustomGridCell";

// Note: ordering role groups and roles is a Portal Services responsibility so the UI doesn't have to do it on each render.
export function getRoleColumns(
    roleGroups: Array<Client.GetShellSecureUserRoleConfigurationRoleGroupDto> | undefined,
    showAdmin: boolean,
    showSigner: boolean,
    isReadonly: boolean,
    canRemoveSpecialRoles: boolean
): Array<React.ReactElement<GridColumnProps>> {
    if (!roleGroups) return new Array<React.ReactElement<GridColumnProps>>();

    // TODO temporary hack for le forms column width, auto columns widths now available, remove after next round of app patching https://feedback.telerik.com/kendo-react-ui/1494821-grid-column-width-auto-sizing-is-not-available
    return getVisibleRoleGroups(showAdmin, showSigner, roleGroups).map((rg) => (
        <GridColumn
            key={rg.group}
            title={rg.title}
            children={getVisibleRoleColumns(showAdmin, showSigner, rg.roles).map((rc) => {
                return (
                    <GridColumn
                        key={rc.role}
                        field={rc.role}
                        title={rc.title}
                        width={rg.group === Client.SecureRoleGroupEnum.CompanyLegislativeForms ? 140 : 110}
                        cell={(p) => getRoleCell(p, isReadonly, canRemoveSpecialRoles)}
                    />
                );
            })}
        />
    ));
}

// filters out the 'special' role group if it is redundant
function getVisibleRoleGroups(showAdmin: boolean, showSigner: boolean, roleGroups: Array<Client.GetShellSecureUserRoleConfigurationRoleGroupDto>) {
    // hide the 'special' group if neither the signer or admin columns are required
    return roleGroups.filter((rg) => showAdmin || showSigner || !rg.isSpecial);
}

// filters out 'special' roles which are not applicable
function getVisibleRoleColumns(showAdmin: boolean, showSigner: boolean, roleColumns: Array<Client.GetShellSecureUserRoleConfigurationRoleDto>) {
    // todo #7755 - create a higher level 'administrator' flag
    return (
        roleColumns
            // hide admin column if it's not required
            .filter((rc) => (rc.role === Client.SecureRoleEnum.CompanyAdministrator && showAdmin) || rc.role !== Client.SecureRoleEnum.CompanyAdministrator)
            .filter((rc) => (rc.role === Client.SecureRoleEnum.JointAuthorityAdministrator && showAdmin) || rc.role !== Client.SecureRoleEnum.JointAuthorityAdministrator)
            .filter((rc) => (rc.role === Client.SecureRoleEnum.GeoscienceAustraliaAdministrator && showAdmin) || rc.role !== Client.SecureRoleEnum.GeoscienceAustraliaAdministrator)
            // hide signer column if it's not required
            .filter((rc) => (rc.role === Client.SecureRoleEnum.CompanySigner && showSigner) || rc.role !== Client.SecureRoleEnum.CompanySigner)
    );
}

// one cell for all roles, break up into general / special if it gets too complicated
function getRoleCell(props: GridCellProps, isReadonly: boolean, allowRemovalOfSpecialRoles: boolean) {
    const { dataItem, dataIndex, field } = props;

    const userRole = dataItem as IRoleViewModel;
    const roleEnum = field as Client.SecureRoleEnum;
    if (!roleEnum) throw new Error("Invalid RoleGrid field definition.");

    // disabled if: whole grid is readonly, this row is readonly, this column is an inherited role, or this is a CA/CS column and CA/CS conditions aren't met
    const disabled =
        isReadonly ||
        !userRole.canEdit() ||
        userRole.hasEffectiveRole(roleEnum) ||
        ((roleEnum === Client.SecureRoleEnum.CompanyAdministrator ||
            roleEnum === Client.SecureRoleEnum.JointAuthorityAdministrator ||
            roleEnum === Client.SecureRoleEnum.GeoscienceAustraliaAdministrator) &&
            (!allowRemovalOfSpecialRoles || !userRole.isAdministrator())) ||
        (roleEnum === Client.SecureRoleEnum.CompanySigner && (!allowRemovalOfSpecialRoles || !userRole.isSigner()));

    const handleChange = (e: CheckboxChangeEvent) => {
        props.onChange!({
            dataItem: dataItem,
            dataIndex: dataIndex,
            syntheticEvent: e.syntheticEvent,
            field: field,
            value: roleEnum,
        });
    };

    // Note: using Kendo rather than native checkboxes for controls inside Kendo grids
    // checked if: this row has the role that corresponds to this column, either directly or as a result of inheritance
    return (
        <CustomGridCell gridCellProps={props}>
            <Checkbox checked={userRole.hasRole(roleEnum)} onChange={handleChange} disabled={disabled} />
        </CustomGridCell>
    );
}
