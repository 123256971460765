// framework
import { useEffect } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
// common
import SearchBarView from "../../../../common/search/SearchBarView";
import SimpleAlertView from "../../../../common/simpleAlerts/SimpleAlertView";
import * as GlobalHelpers from "../../../../common/GlobalHelpers";
// views
import RigListGridView from "./views/RigListGridView";

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    // on mount
    useEffect(() => {
        actions.initialiseRequested();
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSearchSubmit = (value: string) => {
        actions.viewChanged(vm.changeSearchText(value));
        actions.initialiseRequested();
    };

    const onGridClear = () => {
        // note that this also triggers a grid state reset
        actions.viewChanged(vm.changeSearchText(""));
        actions.initialiseRequested();
    };

    const searchTextDescription = "The Rigs search returns records where the word or phrase is contained in either Name, Type, Make, Model, Company Owner or Status.";

    return (
        <BodyLayout title="Search Rigs" selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_WellsBoreholes_Rigs}>
            <SearchBarView searchText={vm.searchText} searchTextDescription={searchTextDescription} onSearchSubmit={onSearchSubmit} onSearchClear={onGridClear} />
            {vm.isResultSetLimited && (
                <SimpleAlertView
                    alertType="warning"
                    message={`For performance reasons, only the first ${GlobalHelpers.toNoptaNumberString(
                        vm.rigs.length,
                        "n0"
                    )} records have been returned. Please adjust your search criteria to locate relevant data.`}
                    dismissable={true}
                />
            )}
            <RigListGridView vm={vm} />
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_WellsBoreholes_RigList }), Actions.actionFactory)(Controller);
