// framework
import { clone, cloneDeep } from "lodash";
// api
import * as Client from "../../../../../../api/Client";
// common
import * as SecureFileUploadControl from "../../../../../../common/secureFileUpload/SecureFileUploadControl";
import * as SecureFileDownloadButtonControl from "../../../../../../common/secureFileDownload/SecureFileDownloadButtonControl";

export interface IRootViewModel {
    id: number | undefined;
    versionNumber: number | undefined;
    configuration: Client.DocumentationConfigurationDto | undefined;
    audit: Client.SecurePortalSimpleAuditEventsDto | undefined;
    sensitiveToCompanies: Array<Client.GetCompanyOpggsDraftApplicationDetailsSupportingDocumentationSensitiveToCompanyDto>;
    files: Array<IFileViewModel>;
    checklist: Array<IChecklistItemViewModel>;
    canSetSensitivity: boolean;
    canSupplyOffline: boolean;
    partnerCompanies: Array<Client.GetCompanyOpggsDraftApplicationDetailsSupportingDocumentationPartnerCompanyDto>;
    suppliedOfflineDetails: ISuppliedOfflineDetailsViewModel;
    isComplete: boolean;
    viewState: ViewStateEnum;
    isDirty: boolean;
    isConflict: boolean;
    statusMessages: Client.StatusMessagesDto | undefined;
    numberOfFilesSelected: number;
    allFilesSelected: boolean;

    refreshDetails(id: number, response: Client.GetCompanyCommonDraftApplicationDetailsSupportingDocumentationResponseDto): IRootViewModel;
    refreshSaveStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel;
    refreshConflict(): IRootViewModel;
    onInitialised(): IRootViewModel;
    onSaved(): IRootViewModel;
    onUpload(files: Array<SecureFileUploadControl.IFile>): IRootViewModel;
    onEdit(): IRootViewModel;
    onDelete(): IRootViewModel;
    getFilesForDownload(): Array<SecureFileDownloadButtonControl.IFile>;
    onFilesSelected(isSelected: boolean): IRootViewModel;
    onFileSelected(fileId: number): IRootViewModel;
    onFileChanged(fileId: number, sensitiveToCompany: Client.GetCompanyOpggsDraftApplicationDetailsSupportingDocumentationSensitiveToCompanyDto): IRootViewModel;
    onChecklistChanged(checklist: Array<IChecklistItemViewModel>): IRootViewModel;
    onSuppliedOfflineDetailsChanged(values: ISuppliedOfflineDetailsViewModel): IRootViewModel;
}

export enum FileStateEnum {
    Unmodified,
    Modified,
    Uploaded,
    Deleted,
}

export interface IFileViewModel {
    fileId: number;
    fileName: string;
    fileSize: number;
    fileSizeMb: number;
    uploadedDatetime: Date | undefined;
    sensitiveToCompany: Client.GetCompanyOpggsDraftApplicationDetailsSupportingDocumentationSensitiveToCompanyDto | undefined;
    state: FileStateEnum;
    canDownload: boolean;
    canManage: boolean;
    isSelected: boolean;

    canSelect(viewState: ViewStateEnum): boolean;
}

export interface IChecklistItemViewModel {
    type: Client.DraftApplicationSupportingDocumentationChecklistItemTypeEnum;
    isChecked: boolean;
}

export interface IChecklistItemDescriptionViewModel {
    type: Client.DraftApplicationSupportingDocumentationChecklistItemTypeEnum;
    description: React.ReactElement;
}

export interface IChecklistTemplateViewModel {
    items: Array<IChecklistItemDescriptionViewModel>;
}

export interface ISuppliedOfflineDetailsViewModel {
    isSuppliedOffline?: boolean | undefined;
    comments?: string | undefined;
    selectedCompanies?: Array<Client.GetCompanyOpggsDraftApplicationDetailsSupportingDocumentationPartnerCompanyDto> | undefined;
}

export enum ViewStateEnum {
    Initialising,
    View,
    Edit,
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.id = undefined;
        this.versionNumber = undefined;
        this.configuration = undefined;
        this.audit = undefined;
        this.sensitiveToCompanies = new Array<Client.GetCompanyOpggsDraftApplicationDetailsSupportingDocumentationSensitiveToCompanyDto>();
        this.files = new Array<IFileViewModel>();
        this.checklist = new Array<IChecklistItemViewModel>();
        this.canSetSensitivity = false;
        this.canSupplyOffline = false;
        this.partnerCompanies = new Array<Client.GetCompanyOpggsDraftApplicationDetailsSupportingDocumentationPartnerCompanyDto>();
        this.suppliedOfflineDetails = {
            isSuppliedOffline: false,
            comments: "",
            selectedCompanies: new Array<Client.GetCompanyOpggsDraftApplicationDetailsSupportingDocumentationPartnerCompanyDto>(),
        };
        this.isComplete = false;
        this.viewState = ViewStateEnum.Initialising;
        this.isDirty = false;
        this.isConflict = false;
        this.statusMessages = undefined;
        this.numberOfFilesSelected = 0;
        this.allFilesSelected = false;
    }

    public id: number | undefined;
    public versionNumber: number | undefined;
    public configuration: Client.DocumentationConfigurationDto | undefined;
    public audit: Client.SecurePortalSimpleAuditEventsDto | undefined;
    public sensitiveToCompanies: Array<Client.GetCompanyOpggsDraftApplicationDetailsSupportingDocumentationSensitiveToCompanyDto>;
    public files: Array<IFileViewModel>;
    public checklist: Array<IChecklistItemViewModel>;
    public canSetSensitivity: boolean;
    public canSupplyOffline: boolean;
    public partnerCompanies: Array<Client.GetCompanyOpggsDraftApplicationDetailsSupportingDocumentationPartnerCompanyDto>;
    public suppliedOfflineDetails: ISuppliedOfflineDetailsViewModel;
    public isComplete: boolean;
    public viewState: ViewStateEnum;
    public isDirty: boolean;
    public isConflict: boolean;
    public statusMessages: Client.StatusMessagesDto | undefined;
    public numberOfFilesSelected: number;
    public allFilesSelected: boolean;

    public refreshDetails(id: number, response: Client.GetCompanyCommonDraftApplicationDetailsSupportingDocumentationResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.isDirty = false;
        vm.isConflict = false;
        vm.id = id;
        vm.versionNumber = response.versionNumber;
        vm.configuration = response.configuration;
        vm.audit = response.audit;
        vm.isComplete = response.isComplete;

        // Note: ordering sensitive companies is a Portal Services responsibility so the UI doesn't have to do it on each render.
        vm.sensitiveToCompanies = response.sensitiveToCompanies;
        vm.canSetSensitivity = response.canSetSensitivity;
        vm.files = response.files.map((f) => {
            return new FileViewModel(f.fileId, f.fileName, f.fileSize, f.uploadedDatetime, f.sensitiveToCompany, FileStateEnum.Unmodified, f.canDownload, f.canManage, false);
        });

        // Note: ordering checklist items is a Portal Services responsibility so the UI doesn't have to do it on each render.
        vm.checklist = response.checklistItems.map((i) => {
            return new ChecklistItemViewModel(i.type, i.isChecked);
        });

        // Note: ordering partner companies is a Portal Services responsibility so the UI doesn't have to do it on each render.
        vm.partnerCompanies = response.partnerCompanies;
        vm.canSupplyOffline = response.canSupplyOffline;
        if (response.canSupplyOffline) {
            vm.suppliedOfflineDetails.isSuppliedOffline = response.suppliedOfflineDetails.isSuppliedOffline;
            vm.suppliedOfflineDetails.comments = response.suppliedOfflineDetails.suppliedOfflineComments;
            vm.suppliedOfflineDetails.selectedCompanies = vm.partnerCompanies.filter((pc) => response.suppliedOfflineDetails.suppliedOfflineCompanyIds?.includes(pc.companyId));
        }

        this._setCalculatedFields(vm);
        return vm;
    }

    public refreshSaveStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.statusMessages = statusMessages;
        if (statusMessages.isSuccess) {
            vm.isDirty = false;
        }
        this._setCalculatedFields(vm);
        return vm;
    }

    public refreshConflict(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.statusMessages = undefined;
        vm.isConflict = true; // this is only reset on initialise from the reducer
        this._setCalculatedFields(vm);
        return vm;
    }

    public onInitialised(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Initialising) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.View;
        return vm;
    }

    public onSaved(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.View;
        return vm;
    }

    public onUpload(files: Array<SecureFileUploadControl.IFile>): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.isDirty = true;
        vm.files = cloneDeep(vm.files);
        for (const f of files) {
            vm.files.push(new FileViewModel(f.id, f.fileName, f.size, undefined, undefined, FileStateEnum.Uploaded, true, true, false));
        }
        this._setCalculatedFields(vm);
        return vm;
    }

    public onEdit(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.View) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.Edit;
        vm.isDirty = false;
        vm.statusMessages = undefined;

        vm.files = this.files.map((f) => {
            return new FileViewModel(
                f.fileId,
                f.fileName,
                f.fileSize,
                f.uploadedDatetime,
                f.sensitiveToCompany,
                f.state,
                f.canDownload,
                f.canManage,
                f.canSelect(vm.viewState) ? f.isSelected : false // clears selection on any files the user doesn't have manage access to
            );
        });

        this._setCalculatedFields(vm);
        return vm;
    }

    public onDelete(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");
        if (this.files.some((f) => f.isSelected && !f.canSelect(this.viewState))) throw new Error("Invalid operation.");

        const vm = this._clone();
        vm.isDirty = true;
        vm.files = this.files.map((f) => {
            return new FileViewModel(f.fileId, f.fileName, f.fileSize, f.uploadedDatetime, f.sensitiveToCompany, f.isSelected ? FileStateEnum.Deleted : f.state, f.canDownload, f.canManage, false);
        });
        this._setCalculatedFields(vm);
        return vm;
    }

    public getFilesForDownload(): Array<SecureFileDownloadButtonControl.IFile> {
        // can get in both View and Edit states
        if (this.files.some((f) => f.isSelected && !f.canSelect(this.viewState))) throw new Error("Invalid operation.");

        return this.files
            .filter((f) => f.isSelected)
            .map((f) => {
                return { id: f.fileId, size: f.fileSize };
            });
    }

    public onFileSelected(fileId: number): IRootViewModel {
        // can be set in both View and Edit states, does not trigger dirty state
        const selectedFile = this.files.find((f) => f.fileId === fileId);
        if (!selectedFile) throw new Error("Invalid file.");
        if (!selectedFile.canSelect(this.viewState)) throw new Error("Invalid operation.");

        const vm = this._clone();

        vm.files = this.files.map((f) => {
            return new FileViewModel(
                f.fileId,
                f.fileName,
                f.fileSize,
                f.uploadedDatetime,
                f.sensitiveToCompany,
                f.state,
                f.canDownload,
                f.canManage,
                f.fileId === fileId ? !f.isSelected : f.isSelected // toggles the selection
            );
        });

        this._setCalculatedFields(vm);
        return vm;
    }

    public onFilesSelected(isSelected: boolean): IRootViewModel {
        // can be set in both View and Edit states, does not trigger dirty state
        const vm = this._clone();

        vm.files = this.files.map((f) => {
            return new FileViewModel(
                f.fileId,
                f.fileName,
                f.fileSize,
                f.uploadedDatetime,
                f.sensitiveToCompany,
                f.state,
                f.canDownload,
                f.canManage,
                f.canSelect(this.viewState) ? isSelected : f.isSelected // sets all that can be selected to a specific value
            );
        });

        this._setCalculatedFields(vm);
        return vm;
    }

    public onFileChanged(fileId: number, sensitiveToCompany: Client.GetCompanyOpggsDraftApplicationDetailsSupportingDocumentationSensitiveToCompanyDto): IRootViewModel {
        // assert application validity
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");
        if (!this.canSetSensitivity) throw new Error("Invalid operation.");
        // assert file validity
        const file = this.files.find((f) => f.fileId === fileId);
        if (!file) throw new Error("Invalid file.");
        if (!file.canManage || file.state === FileStateEnum.Deleted) throw new Error("Invalid operation.");

        const updatedFile = new FileViewModel(
            file.fileId,
            file.fileName,
            file.fileSize,
            file.uploadedDatetime,
            // set sensitive to company
            sensitiveToCompany,
            // update file state if file is unmodified - if file has been uploaded we want to preserve this state to differentiate from modified (i.e. existing) files
            file.state === FileStateEnum.Unmodified ? FileStateEnum.Modified : file.state,
            file.canDownload,
            file.canManage,
            file.isSelected
        );
        const vm = this._clone();

        vm.isDirty = true;
        vm.files = this.files.map((f) => (f.fileId === fileId ? updatedFile : f));

        this._setCalculatedFields(vm);
        return vm;
    }

    public onChecklistChanged(checklist: Array<IChecklistItemViewModel>): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.isDirty = true;
        vm.checklist = checklist;

        this._setCalculatedFields(vm);
        return vm;
    }

    public onSuppliedOfflineDetailsChanged(values: ISuppliedOfflineDetailsViewModel): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Edit) throw new Error("Invalid state.");
        if (!this.canSupplyOffline) throw new Error("This application does not allow offline supporting documentation.");

        const vm = this._clone();
        vm.isDirty = true;
        vm.suppliedOfflineDetails = { ...vm.suppliedOfflineDetails, ...values };

        // clear supplied offline details if supplied offline checkbox is cleared
        if (values.isSuppliedOffline === false) {
            vm.suppliedOfflineDetails.selectedCompanies = new Array<Client.GetCompanyOpggsDraftApplicationDetailsSupportingDocumentationPartnerCompanyDto>();
            vm.suppliedOfflineDetails.comments = "";
        }

        this._setCalculatedFields(vm);
        return vm;
    }

    private _setCalculatedFields(vm: RootViewModel) {
        vm.numberOfFilesSelected = vm.files.filter((f) => f.isSelected).length;

        const selectableFiles = vm.files.filter((f) => f.canSelect(vm.viewState));
        vm.allFilesSelected = selectableFiles.length > 0 && selectableFiles.every((f) => f.isSelected);
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.suppliedOfflineDetails = clone(this.suppliedOfflineDetails);
        return vm;
    }
}

class FileViewModel implements IFileViewModel {
    fileId: number;
    fileName: string;
    fileSize: number;
    fileSizeMb: number;
    uploadedDatetime: Date | undefined;
    sensitiveToCompany: Client.GetCompanyOpggsDraftApplicationDetailsSupportingDocumentationSensitiveToCompanyDto | undefined;
    state: FileStateEnum;
    canDownload: boolean;
    canManage: boolean;
    isSelected: boolean;

    constructor(
        fileId: number,
        fileName: string,
        fileSize: number,
        uploadedDatetime: Date | undefined,
        sensitiveToCompany: Client.GetCompanyOpggsDraftApplicationDetailsSupportingDocumentationSensitiveToCompanyDto | undefined,
        state: FileStateEnum,
        canDownload: boolean,
        canManage: boolean,
        isSelected: boolean
    ) {
        this.fileId = fileId;
        this.fileName = fileName;
        this.fileSize = fileSize;
        this.fileSizeMb = fileSize / 1024 / 1024;
        this.uploadedDatetime = uploadedDatetime;
        this.sensitiveToCompany = sensitiveToCompany;
        this.state = state;
        this.canDownload = canDownload;
        this.canManage = canManage;
        this.isSelected = isSelected;
    }

    public canSelect(viewState: ViewStateEnum): boolean {
        // cannot selected deleted files in any view state
        if (this.state === FileStateEnum.Deleted) return false;
        // download access required to select files in view mode
        if (viewState === ViewStateEnum.View) return this.canDownload;
        // modify access required to select files in edit mode
        if (viewState === ViewStateEnum.Edit) return this.canManage;

        // cannot select under any other conditions
        return false;
    }
}

class ChecklistItemViewModel implements IChecklistItemViewModel {
    constructor(type: Client.DraftApplicationSupportingDocumentationChecklistItemTypeEnum, isChecked: boolean) {
        this.type = type;
        this.isChecked = isChecked;
    }

    type: Client.DraftApplicationSupportingDocumentationChecklistItemTypeEnum;
    isChecked: boolean;
}
