import { takeEvery } from "redux-saga/effects";
import * as Actions from "./actions";

import initialise from "./sagas/initialise";
import save from "./sagas/save";

export default function* watcherSaga() {
    yield takeEvery(Actions.actions.initialiseRequested, initialise);
    yield takeEvery(Actions.actions.saveRequested, save);
}
