// framework
import { useState } from "react";
// api
import * as Client from "../../../../../../api/Client";
// models
import { IAddSurveyDataRelatedActivities, IRootViewModel } from "../../models/models";
// redux
import * as Actions from "../../redux/actions";
// kendo
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import { IValidationModel } from "../../../../../../common/validation/ValidationModel";
import DirtyScope from "../../../../../../common/shell/DirtyScopeController";
import ValidationMessageView from "../../../../../../common/validation/ValidationMessageView";
import StatusMessagesAlertsView from "../../../../../../common/alerts/StatusMessagesAlertsView";
import KendoLargeMultiSelect from "../../../../../../common/kendo/KendoLargeMultiSelect";

interface IProps {
    vm: IRootViewModel;
    v: IValidationModel;
    actions: Actions.IActionFactory;
    onAssign: () => void;
    onCancel: () => void;
}

export default function AssignRelatedActivityView(props: IProps): JSX.Element {
    const { vm, v, actions, onAssign, onCancel } = props;

    const [state, setState] = useState(vm.addRelatedActivities);

    function updateVmAndState(updatedState: IAddSurveyDataRelatedActivities) {
        setState(updatedState);
        actions.viewChanged(vm.onAddRelatedActivitiesChanged(updatedState));
    }

    return (
        <DirtyScope scopeName="geoscienceAustralia/reportsAndData/surveyData/RelatedActivities" isDirty={vm.isDirty}>
            <Dialog title="Assign Related Activity" width={650} onClose={onCancel}>
                <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
                <Forms.Row>
                    <Forms.FullColumn>
                        <Forms.ReadonlyField label="Public Title" id="publicTitleField" value={vm.publicTitle} />
                    </Forms.FullColumn>
                    <Forms.FullColumn>
                        <Forms.Label htmlFor="activityTypeField">Activity Type</Forms.Label>
                        <DropDownList
                            id="activityTypeField"
                            value={state.activityType}
                            data={vm.referenceData.allowableActivityTypes}
                            valid={v.applyValidityForKendo("relatedElement.ActivityType")}
                            onChange={(event) => updateVmAndState({ ...state, activityType: event.value, activities: [] })}
                        />
                        <ValidationMessageView name="relatedElement.ActivityType" validationModel={v} />
                    </Forms.FullColumn>
                    {state.activityType === Client.SurveyDataRelatedActivityTypeEnum.Borehole && (
                        <Forms.FullColumn>
                            <>
                                <Forms.Label htmlFor="activityIdField">Borehole</Forms.Label>
                                <KendoLargeMultiSelect
                                    id="activityIdField"
                                    value={state.activities}
                                    data={vm.referenceData.relatedActivities.filter((activity) => activity.surveyDataRelatedActivityType === Client.SurveyDataRelatedActivityTypeEnum.Borehole)}
                                    keyField="elementId"
                                    textField="activityId"
                                    valid={v.applyValidityForKendo("relatedElement.ActivityID")}
                                    recordsToShow={100}
                                    onChange={(value) => updateVmAndState({ ...state, activities: value })}
                                />
                                <ValidationMessageView name="relatedElement.ActivityID" validationModel={v} />
                            </>
                        </Forms.FullColumn>
                    )}
                    {state.activityType === Client.SurveyDataRelatedActivityTypeEnum.Acquisition && (
                        <Forms.FullColumn>
                            <>
                                <Forms.Label htmlFor="activityIdField">Acquisition</Forms.Label>
                                <KendoLargeMultiSelect
                                    id="activityIdField"
                                    value={state.activities}
                                    data={vm.referenceData.relatedActivities.filter((activity) => activity.surveyDataRelatedActivityType === Client.SurveyDataRelatedActivityTypeEnum.Acquisition)}
                                    keyField="elementId"
                                    textField="activityId"
                                    valid={v.applyValidityForKendo("relatedElement.ActivityID")}
                                    recordsToShow={100}
                                    onChange={(value) => updateVmAndState({ ...state, activities: value })}
                                />
                                <ValidationMessageView name="relatedElement.ActivityID" validationModel={v} />
                            </>
                        </Forms.FullColumn>
                    )}
                    {state.activityType === Client.SurveyDataRelatedActivityTypeEnum.Project && (
                        <Forms.FullColumn>
                            <>
                                <Forms.Label htmlFor="activityIdField">Project</Forms.Label>
                                <KendoLargeMultiSelect
                                    id="activityIdField"
                                    value={state.activities}
                                    data={vm.referenceData.relatedActivities.filter((activity) => activity.surveyDataRelatedActivityType === Client.SurveyDataRelatedActivityTypeEnum.Project)}
                                    keyField="elementId"
                                    textField="activityId"
                                    valid={v.applyValidityForKendo("relatedElement.ActivityID")}
                                    recordsToShow={100}
                                    onChange={(value) => updateVmAndState({ ...state, activities: value })}
                                />
                                <ValidationMessageView name="relatedElement.ActivityID" validationModel={v} />
                            </>
                        </Forms.FullColumn>
                    )}
                    <Forms.FullColumn>
                        <Forms.TextArea label="Comment" id="commentField" value={state.comments} maxLength={1000} rows={3} onChange={(value) => updateVmAndState({ ...state, comments: value })} />
                        <ValidationMessageView name="relatedElement.Comments" validationModel={v} />
                    </Forms.FullColumn>
                </Forms.Row>
                <DialogActionsBar>
                    <button type="button" className="btn btn-primary me-2" onClick={onAssign}>
                        Assign
                    </button>
                    <button type="button" className="btn btn-secondary me-2" onClick={onCancel}>
                        Cancel
                    </button>
                </DialogActionsBar>
            </Dialog>
        </DirtyScope>
    );
}
