// models
import { BaseRoleViewModel, RoleCategoryEnum, IRoleViewModel } from "../../../common/roleGrid/RoleViewModel";
// api
import * as Client from "../../../api/Client";

export interface IRegistrationRoleViewModel extends IRoleViewModel {
    category: RoleCategoryEnum;
}

export class RegistrationRoleViewModel extends BaseRoleViewModel implements IRegistrationRoleViewModel {
    constructor(roleRelationships: Array<Client.GetShellSecureUserRoleConfigurationRoleRelationshipDto>, category: RoleCategoryEnum) {
        super(roleRelationships);

        this.category = category;
    }

    public readonly category: RoleCategoryEnum;

    public getKey(): RoleCategoryEnum {
        return this.category;
    }
    public canEdit(): boolean {
        return this.category === RoleCategoryEnum.Requested;
    }
}
