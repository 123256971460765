// models
import * as Models from "../../../../common/draftApplicationDetails/components/models/models";
// api
import * as Client from "../../../../../api/Client";

export interface IRlRenewalViewModel {
    isExtensionAccepted?: boolean | undefined;
}

export interface IRlRenewalDetailsViewModel extends IRlRenewalViewModel {
    titleId?: string | undefined;
    titleNumber?: string | undefined;
    field?: string | undefined;
    titleExpiryDate?: Date | undefined;
}

export interface IRlRenewalApplicationDetailsViewModel extends Models.IApplicationDetailsViewModel {
    rlRenewalDetails: IRlRenewalDetailsViewModel;
}

export interface IRootViewModel extends Models.IDraftApplicationRootViewModel {
    applicationDetails: IRlRenewalApplicationDetailsViewModel;

    // view triggered state changes
    refreshRlRenewalUpdated(values: IRlRenewalViewModel): IRootViewModel;
}

export class RootViewModel extends Models.BaseDraftApplicationRootViewModel implements IRootViewModel {
    constructor() {
        super();
        this.applicationDetails = {
            isDigitalSignatures: false,
            rlRenewalDetails: {},
            isDirty: false,
        };
    }

    public override applicationDetails: IRlRenewalApplicationDetailsViewModel;

    public override refreshDetails(response: Client.GetCompanyOpggsDraftApplicationDetailsRlRenewalResponseDto): this {
        const vm = super.refreshDetails(response);

        // RL Renewal details
        vm.applicationDetails.rlRenewalDetails.titleId = response.titleId;
        vm.applicationDetails.rlRenewalDetails.titleNumber = response.titleNumber;
        vm.applicationDetails.rlRenewalDetails.field = response.field;
        vm.applicationDetails.rlRenewalDetails.titleExpiryDate = response.titleExpiryDate;
        vm.applicationDetails.rlRenewalDetails.isExtensionAccepted = response.isExtensionAccepted;
        vm.applicationDetails.isDirty = false;

        return vm;
    }

    public refreshRlRenewalUpdated(values: IRlRenewalViewModel): IRootViewModel {
        const vm = this._clone();

        vm.applicationDetails.rlRenewalDetails = { ...vm.applicationDetails.rlRenewalDetails, ...values };
        vm.applicationDetails.isDirty = true;

        return vm;
    }
}
