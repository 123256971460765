// framework
import { put, select } from "redux-saga/effects";
// redux
import * as Models from "../../models/models";
import * as Actions from "../actions";
import { IShellReduxState } from "../../../shellReduxRegistration";
// common
import * as logHelper from "../../../../common/LogHelper";

const getRootViewModel = (state: IShellReduxState) => state.Shell_SignIn;

export function* signInExpired() {
    try {
        let vm: Models.IRootViewModel = yield select(getRootViewModel);
        vm = vm.refreshOnSignInExpired();
        yield put(Actions.actionFactory.sagaCompletedAction(vm));
    } catch (ex) {
        // log & suppress
        logHelper.logError(ex);
    }
}
