// common
import EmailLinkView from "../../../../../common/externalLink/EmailLinkView";
import * as Forms from "../../../../../common/forms/BootstrapForms";
// model
import { IContactDetailsViewModel } from "../../models/models";

interface IProps {
    vm: IContactDetailsViewModel;
}

export default function ContactDetailsView(props: IProps): JSX.Element {
    const { vm } = props;

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Contact Details</h2>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="honorificNameField" label="Title" value={vm.honorificName} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="customerFirstNameField" label="First Name" value={vm.customerFirstName} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="customerLastNameField" label="Last Name" value={vm.customerLastName} />
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="customerCompanyNameField" label="Company" value={vm.customerCompanyName} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="customerContactNumberField" label="Contact Number" value={vm.customerContactNumber} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.Label htmlFor={"nonTitleholderContactEmailAddressField"}>Email</Forms.Label>
                                {vm.customerEmailAddress && (
                                    <div>
                                        <EmailLinkView emailAddress={vm.customerEmailAddress} />
                                    </div>
                                )}
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                </div>
            </div>
        </>
    );
}
