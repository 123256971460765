// redux
import { call, put, select } from "redux-saga/effects";
import { IDraftApplicationDetailsReduxState } from "../../../../draftApplicationDetailsReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";
// common
import * as LogHelper from "../../../../../../../common/LogHelper";
import * as shellHelper from "../../../../../../../common/shell/shellHelper";
// calls
import { getCompanyCommonDraftApplicationDetailsFormDocumentation, saveCompanyCommonDraftApplicationDetailsWetFormDocumentation } from "./calls";

const getRootViewModel = (state: IDraftApplicationDetailsReduxState) => state.Company_Common_DraftApplicationDetails_Controls_FormDocumentation;

export default function* saveWetFormDocumentation() {
    let vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        // save
        const saveResult: CallHelper.CallResult<Client.SaveCompanyCommonDraftApplicationDetailsWetFormDocumentationResponseDto> = yield call(async () => {
            return await saveCompanyCommonDraftApplicationDetailsWetFormDocumentation(vm);
        });

        if (saveResult.IsSuccess) {
            // success - refresh
            const r = saveResult.Response!;
            vm = vm.refreshSaveStatusMessages(r.statusMessages);
            if (r.statusMessages.isSuccess) {
                const listResponse: Client.GetCompanyCommonDraftApplicationDetailsFormDocumentationResponseDto = yield call(async () => {
                    return await getCompanyCommonDraftApplicationDetailsFormDocumentation(vm.id!);
                });
                vm = vm.refreshSignatureDetails(vm.id!, listResponse);
                vm = vm.onSaved();
            }
            yield put(Actions.actionFactory.sagaCompleted(vm));
        } else if (saveResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            saveResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
