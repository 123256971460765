import { FormRequestTaskEnum as LegislativeFormRequestTaskEnum, ViewStateEnum } from "../formRequestDetails/models/models";

export function getFormRequestHeader(formTypeId: number | undefined, titleNumber: string = "", titleholder: string = ""): string {
    if (!formTypeId) return "Form Request";

    let header = "Form " + formTypeId + " Request";

    if (formTypeId === 3) {
        return titleholder ? header + ": " + titleholder : header;
    } else {
        return titleNumber ? header + ": " + titleNumber : header;
    }
}

export function getTitleholderDisplayName(formTypeId: number | undefined): string {
    switch (formTypeId) {
        case 2:
        case 3:
            return "Registered Titleholder";
        case 4:
        case 6:
            return "Nominee Titleholder";
        case 5:
        case 7:
            return "Titleholder giving notice";
        default:
            return "Titleholder";
    }
}

export function showCommands(viewState: ViewStateEnum, editableViewState: ViewStateEnum = ViewStateEnum.Draft): boolean {
    return viewState === editableViewState;
}

export function isInEdit(inEditTask?: LegislativeFormRequestTaskEnum | undefined): boolean {
    return inEditTask !== undefined;
}

export function isEditableFormType(formTypeId: number | undefined): boolean {
    // form types 2 and 3 are editable, the others have no editable fields and are hence read only
    switch (formTypeId) {
        case 2:
        case 3:
            return true;
        default:
            return false;
    }
}

export function canReload(viewState: ViewStateEnum): boolean {
    return activeViewStates.includes(viewState);
}

const activeViewStates: readonly ViewStateEnum[] = [ViewStateEnum.Readonly, ViewStateEnum.Draft, ViewStateEnum.ForSigning];
