// framework
import { useState } from "react";
import { DashIcon } from "@primer/octicons-react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// api
import * as Client from "../../../../../api/Client";
// redux
import * as Models from "../../models/models";
import * as Actions from "../../redux/actions";
// other
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import { createGridColumnCheckboxFilterMenu } from "../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
import CustomGridCell from "../../../../../common/kendoGrid/CustomGridCell";
import SecureFileDownloadLinkControl from "../../../../../common/secureFileDownload/SecureFileDownloadLinkControl";

function MemorialView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }) {
    const details = props.vm.details;
    const applications = details?.memorial?.applications ?? new Array<Client.GetCompanyOpggsTitleDetailsMemorialApplicationDto>(); // applications are sorted in the service

    const [gridState, setGridState] = useState({
        skip: 0,
        take: 10,
    } as State);

    function documentDownload(fileId: number) {
        if (!fileId) {
            return <DashIcon size="small" />;
        }

        return (
            <span>
                <SecureFileDownloadLinkControl id={fileId}>Download</SecureFileDownloadLinkControl>
            </span>
        );
    }

    return (
        <div className="card mb-2">
            <div className="card-body">
                {/* applications grid */}
                <Grid
                    pageable
                    resizable
                    navigatable
                    data={process(applications, gridState)}
                    {...gridState}
                    onDataStateChange={(e) => {
                        setGridState(e.dataState);
                    }}
                >
                    <GridColumn field="applicationTypeName" title="Application Type" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, applications)} />
                    <GridColumn field="applicationName" title="Application Name" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, applications)} />
                    <GridColumn field="executedDate" title="Executed Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="submittedDate" title="Submitted Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="decision" title="Decision" width={200} />
                    <GridColumn field="implementedDate" title="Implemented Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="registeredDatetime" title="Registered Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="fileId" title="Document" width={115} cell={(props) => <CustomGridCell gridCellProps={props}>{documentDownload(props.dataItem.fileId)}</CustomGridCell>} />
                </Grid>
            </div>
        </div>
    );
}

export default MemorialView;
