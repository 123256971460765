// common
import { ReportRelatedActivityTypeEnum } from "../../../../../api/Client";
import * as Validation from "../../../../../common/validation/ValidationModel";
// models
import { IRootViewModel } from "./models";

export default function validate(vm: IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    const addRelatedActivities = vm.addRelatedActivities;

    if (!addRelatedActivities.activityType) {
        v.addError("relatedElement.ActivityType", "Activity Type is required.");
    }

    if (addRelatedActivities.activities && addRelatedActivities.activities.length < 1) {
        let activityTypeName = "";
        switch (addRelatedActivities.activityType) {
            case ReportRelatedActivityTypeEnum.Borehole:
                activityTypeName = "Borehole";
                break;
            case ReportRelatedActivityTypeEnum.Acquisition:
                activityTypeName = "Acquisition";
                break;
            case ReportRelatedActivityTypeEnum.Project:
                activityTypeName = "Project";
                break;
        }
        v.addError("relatedElement.ActivityID", `At least one ${activityTypeName} must be selected.`);
    }

    if (addRelatedActivities.comments && addRelatedActivities.comments.length > 1000) {
        v.addError("relatedElement.Comments", "Comments must be 1,000 characters or less.");
    }

    return v;
}
