// redux
import * as Models from "../../models/models";

export default function DisclaimerView(props: { vm: Models.IRootViewModel }): React.ReactElement {
    const vm = props.vm;

    return (
        <>
            <p>
                This includes any applications, reporting and data submissions requirements under the OPGGSA and related Regulations. Please include a description of the submission in the Comments
                field.
            </p>
            <p>
                For data submission requirements under the OPGGS (RMA) a submission variation authorisation must be granted prior to uploading. Please quote the approval number in the Comments field.
            </p>
            <p>Go to the relevant Title to submit any of the following Application types:</p>
            <ul>
                {vm.creatableDraftApplicationTypes?.map((type, i) => {
                    return <li key={i}>{type}</li>;
                })}
            </ul>
        </>
    );
}
