// framework
import { call, put } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

export default function* getReferenceData(action: Actions.IAction<string>) {
    const titleId = action.payload;

    // save the titleId to the vm
    let vm: Models.IRootViewModel = new Models.RootViewModel();
    vm = vm.refreshTitle(titleId);
    yield put(Actions.actionFactory.sagaCompleted(vm));

    // load details
    try {
        yield put(shellHelper.createSetBusyAction());

        const result: CallHelper.CallResult<Client.GetCompanyOpggsLegislativeFormsFormRequestReferenceDataResponseDto> = yield call(async () => {
            const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();
            return await CallHelper.call(() =>
                client.getCompanyOpggsLegislativeFormsFormRequestReferenceData(new Client.GetCompanyOpggsLegislativeFormsFormRequestReferenceDataRequestDto({ titleId: vm.titleId! }))
            );
        });

        if (!result.IsSuccess) {
            result.ShowToastNotification();
        } else {
            // refresh vm
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshReferenceData(result.Response!)));
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
