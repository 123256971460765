// framework
import { useState } from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// redux
import * as Models from "../../models/models";
// api
import { JointAuthorityApplicationListItemDto, TitleLinkDto } from "../../../../../api/Client";
// other
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import ApplicationLinkController from "../../../../../common/links/ApplicationLinkController";
import TitleLinkController from "../../../../../common/links/TitleLinkController";
import { createGridColumnFilterMenu } from "../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
import CustomGridCell from "../../../../../common/kendoGrid/CustomGridCell";

export default function ApplicationsTileView(props: { vm: Models.applicationsType; isJaApplications: boolean }): React.ReactElement {
    if (!props.vm) throw new Error("Applications must not be undefined.");
    const isJaApplications = props.isJaApplications;
    const apps = props.vm?.filter((a: JointAuthorityApplicationListItemDto) => a.isWithJa === isJaApplications);

    const initialGridState: State = {
        sort: [{ field: "statusDate", dir: "asc" }],
        skip: 0,
        take: 10,
    };
    const [gridState, setGridState] = useState(initialGridState);

    // view
    return (
        <>
            <div className="card mb-2">
                <div className="card-header">{isJaApplications ? "Applications with your Joint Authority" : "Applications with NOPTA"}</div>
                <div className="card-body">
                    <Grid
                        pageable
                        sortable
                        resizable
                        navigatable
                        data={process(apps, gridState)}
                        {...gridState}
                        onDataStateChange={(e) => {
                            setGridState(e.dataState);
                        }}
                    >
                        <GridColumn
                            field="applicationLink.trackingNumber"
                            title="Tracking Number"
                            width={170}
                            filter="text"
                            columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, apps)}
                            cell={(props) => (
                                <CustomGridCell gridCellProps={props}>
                                    <ApplicationLinkController linkDto={props.dataItem.applicationLink} secureEntityType="JointAuthority" />
                                </CustomGridCell>
                            )}
                        />
                        <GridColumn field="applicationTypeName" title="Application Type" width={300} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, apps)} />
                        <GridColumn field="applicationName" title="Application Name" width={300} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, apps)} />
                        <GridColumn field="applicationStatusDisplay" title="Application Status" width={180} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, apps)} />
                        <GridColumn field="statusDate" title="Status Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} filter="date" columnMenu={createGridColumnFilterMenu} />
                        <GridColumn field="daysAtStatus" title="Days at Status" width={200} filter="numeric" columnMenu={createGridColumnFilterMenu} />
                        <GridColumn field="timeframe" title="Timeframe" width={200} filter="numeric" columnMenu={createGridColumnFilterMenu} />
                        <GridColumn
                            field="titleNumbers"
                            title="Title Number(s)"
                            width={150}
                            filter="text"
                            columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, apps)}
                            cell={(props) => (
                                <CustomGridCell gridCellProps={props}>
                                    {props.dataItem.titleLinks.map((l: TitleLinkDto, i: number) => [i > 0 && ", ", <TitleLinkController key={i} linkDto={l} secureEntityType="JointAuthority" />])}
                                </CustomGridCell>
                            )}
                        />
                        <GridColumn field="titleTypeDisplay" title="Title Type" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, apps)} />
                        <GridColumn field="companyName" title="Applicant" width={400} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, apps)} />
                        <GridColumn field="submittedDate" title="Submitted Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} filter="date" columnMenu={createGridColumnFilterMenu} />
                    </Grid>
                </div>
            </div>
        </>
    );
}
