// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// views
import NavBar, { TabsEnum } from "../../components/views/NavBarView";
import BoreholeReportAttachmentsView from "./views/BoreholeReportAttachmentsView";
import { GetGeoscienceAustraliaReportAttachmentsItemDto } from "../../../../../api/Client";
import CustomBoreholeTitleView from "../../components/views/CustomBoreholeTitleView";
// common
import * as documentHelper from "../../../../../common/documentHelper";
import StatusMessagesAlertsView from "../../../../../common/alerts/StatusMessagesAlertsView";
import ConflictAlertView from "../../../../../common/alerts/ConflictAlertView";

interface IRouteParameters {
    wellId: string;
    boreholeId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { wellId, boreholeId } = params;

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(boreholeId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function onEditHandle(reportId: number, reportVersionNumber: number, reportAttachmentId: number, name: string, documentLink: string, fileName: string) {
        actions.updateReportAttachmentRequested(reportId, reportVersionNumber, reportAttachmentId, name, documentLink, fileName);
    }

    function onDeleteHandle(reportId: number, reportVersionNumber: number, reportAttachmentId: number) {
        actions.deleteReportAttachmentRequested(reportId, reportVersionNumber, reportAttachmentId);
    }

    function onBatchDownloadHandle(reportAttachments: GetGeoscienceAustraliaReportAttachmentsItemDto[]) {
        documentHelper.multipleDownloads(
            reportAttachments.map((attachment) => {
                return { url: attachment.documentLink!, fileName: attachment.fileName! };
            })
        );
    }

    return (
        <>
            {vm.attachments && vm.boreholeName && (
                <BodyLayout
                    title={`Borehole - ${vm.boreholeName}`}
                    customTitle={CustomBoreholeTitleView({ boreholeName: vm.boreholeName, isBoreholePrimary: vm.isPrimaryBorehole })}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_WellsBoreholes_Boreholes}
                    onReloadRequested={() => {
                        props.initialiseRequested(Number(boreholeId));
                    }}
                >
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
                    <ConflictAlertView isConflict={vm.isConflict} />
                    <NavBar wellId={Number(wellId)} boreholeId={Number(boreholeId)} activeNavItem={TabsEnum.Attachments} />
                    <BoreholeReportAttachmentsView vm={vm} onDelete={onDeleteHandle} onEdit={onEditHandle} onBatchDownload={onBatchDownloadHandle} />
                </BodyLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_WellsBoreholes_BoreholeReportAttachments }), Actions.actionFactory)(Controller);
