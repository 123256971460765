// framework
import { useState, ReactElement } from "react";
import { Client } from "./client";
// common
import SimpleValidationMessageView from "../validation/SimpleValidationMessageView";

export interface IFile {
    id: number;
    size: number;
}

interface IProps {
    className?: string | undefined;
    disabled?: boolean | undefined;
    files: Array<IFile>;
}

export function ButtonControl(props: IProps): ReactElement {
    // we won't trigger a global busy state, however we'll just show the link as acknowledging the download by disabling it
    const [isBusy, setIsBusy] = useState(false);

    async function onClick(): Promise<void> {
        setIsBusy(true);

        const ids = props.files.map((f) => f.id);

        // generate the token and get the URL
        const client = new Client();
        client.initialise(ids);
        await client.getToken();
        const url = client.getUrl();

        // initiate the download
        // - this opens a new browser window, but given it's a file response it will automatically close
        // - if anything goes wrong, the window is left open
        window.open(url);

        setIsBusy(false);
    }

    const isError = isFileLimitError(props.files);

    return (
        <span className={props.className}>
            <button
                className={"btn btn-outline-secondary"}
                disabled={isBusy || isError || props.disabled === true}
                onClick={async (e) => {
                    await onClick();
                }}
            >
                Download
            </button>
        </span>
    );
}

interface IValidationControlProps {
    files: Array<IFile>;
}

export function ValidationControl(props: IValidationControlProps): ReactElement {
    const isError = isFileLimitError(props.files);
    if (!isError) return <></>;
    return <SimpleValidationMessageView message="You may only select 100 files, or the combined size must not exceed 1GB." />;
}

function isFileLimitError(files: Array<IFile>): boolean {
    const totalSize = files.reduce((sum, f) => sum + f.size, 0);

    // exceeds 1gb, or 100 files
    return totalSize > 1024 * 1024 * 1024 || files.length > 100;
}
