// framework
import * as Validation from "../../../../common/validation/ValidationModel";
// api
import { SecurePortalCountryDto } from "../../../../api/Client";
// models
import * as Models from "./models";

export default function validate(vm: Models.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateNewCompanyRequest(vm.user.newCompanyRequest, vm.user.questions, vm.reference.countries, v);
    validateCompanyStandardRoleRequest(vm.user.companyStandardRoleRequest, vm.user.questions, v);
    validateCompanySignerRequest(vm.user.questions, v);
    validateCaAuthoritySupportingDocument(vm.user.caAuthoritySupportingDocument, vm.user.questions, v);
    validateCsAuthoritySupportingDocument(vm.user.csAuthoritySupportingDocument, vm.user.questions, v);
    validateCsPoaAuthoritySupportingDocument(vm.user.csPoaSupportingDocument, vm.user.questions, v);
    validateComments(vm.user.comments, v);
    return v;
}

function validateCompanyStandardRoleRequest(vm: Models.ICompanyStandardRoleRequestViewModel, questions: Models.IQuestionsViewModel, v: Validation.ValidationModel): void {
    if (!questions.isCompanyStandardRoleRequest) return;

    if (!questions.isCompanySignerRequest && !vm.requestedRoles!.hasRole()) {
        v.addError("CompanyStandardRoleRequest", "At least one permission must be requested.");
    }

    if (vm.requestedRoles!.getDirectRoles().some((role) => vm.existingRoles!.hasRole(role))) {
        v.addError("CompanyStandardRoleRequest", "Existing permissions cannot be requested.");
    }
}

function validateNewCompanyRequest(vm: Models.INewCompanyRequestViewModel, questions: Models.IQuestionsViewModel, countries: Array<SecurePortalCountryDto>, v: Validation.ValidationModel): void {
    if (!questions.isNewCompanyRequest) return;

    if (Validation.isNullOrWhiteSpace(vm.companyName)) {
        v.addError("NewCompanyRequest.CompanyName", "Company Name is required.");
    } else if (vm.companyName!.length > 255) {
        v.addError("NewCompanyRequest.CompanyName", "Company Name must be 255 characters or less.");
    }

    if (!Validation.isValidAbn(vm.companyAbn)) v.addError("NewCompanyRequest.CompanyAbn", "The ABN is not valid.");
    if (!Validation.isValidAcnOrArbn(vm.companyAcnOrArbn)) v.addError("NewCompanyRequest.CompanyAcnOrArbn", "The ACN / ARBN is not valid.");

    if (vm.businessAddress && vm.businessAddress.length > 255) v.addError("NewCompanyRequest.BusinessAddress", "Street Address must be 255 characters or less.");
    if (Validation.isNullOrWhiteSpace(vm.businessSuburb)) {
        v.addError("NewCompanyRequest.BusinessSuburb", "Town/Suburb is required.");
    } else if (vm.businessSuburb!.length > 255) {
        v.addError("NewCompanyRequest.BusinessSuburb", "Town/Suburb must be 255 characters or less.");
    }
    if (vm.businessPostcode && vm.businessPostcode.length > 255) v.addError("NewCompanyRequest.BusinessPostcode", "Postcode must be 255 characters or less.");
    if (!vm.businessCountry) {
        v.addError("NewCompanyRequest.BusinessCountry", "Country is required.");
        if (vm.businessState) v.addError("NewCompanyRequest.BusinessState", "State is invalid.");
    } else {
        if (!countries.some((c) => c.countryId === vm.businessCountry?.countryId)) v.addError("NewCompanyRequest.BusinessCountry", "Country is invalid.");
        if (vm.businessState && !(vm.businessCountry.subdivisions && vm.businessCountry.subdivisions.some((s) => s.countrySubdivisionId === vm.businessState?.countrySubdivisionId)))
            v.addError("NewCompanyRequest.BusinessState", "State is invalid.");
    }

    if (vm.isPostalAddressSameAsBusinessAddress) return;

    if (vm.postalAddress && vm.postalAddress.length > 255) v.addError("NewCompanyRequest.PostalAddress", "Street Address must be 255 characters or less.");
    if (Validation.isNullOrWhiteSpace(vm.postalSuburb)) {
        v.addError("NewCompanyRequest.PostalSuburb", "Town/Suburb is required.");
    } else if (vm.postalSuburb!.length > 255) {
        v.addError("NewCompanyRequest.PostalSuburb", "Town/Suburb must be 255 characters or less.");
    }
    if (vm.postalPostcode && vm.postalPostcode.length > 255) v.addError("NewCompanyRequest.PostalPostcode", "Postcode must be 255 characters or less.");
    if (!vm.postalCountry) {
        v.addError("NewCompanyRequest.PostalCountry", "Country is required.");
        if (vm.postalState) v.addError("NewCompanyRequest.PostalState", "State is invalid.");
    } else {
        if (!countries.some((c) => c.countryId === vm.postalCountry?.countryId)) v.addError("NewCompanyRequest.PostalCountry", "Country is invalid.");
        if (vm.postalState && !(vm.postalCountry.subdivisions && vm.postalCountry.subdivisions.some((s) => s.countrySubdivisionId === vm.postalState?.countrySubdivisionId)))
            v.addError("NewCompanyRequest.PostalState", "State is invalid.");
    }
}

function validateCompanySignerRequest(questions: Models.IQuestionsViewModel, v: Validation.ValidationModel): void {
    if (!questions.isCompanySignerRequest) return;

    if (questions.isCompanySignerPoaRequest === undefined) v.addError("CompanySignerRequest.isCsPoaRequestGroup", "An option must be selected.");
}

// TODO review this, it looks off
function validateSupportingDocument(validatorName: string, supportingDocument: Models.ISupportingDocumentViewModel, v: Validation.ValidationModel) {
    if (Validation.isNullOrWhiteSpace(supportingDocument.fileName) || !supportingDocument.content) {
        v.addError(validatorName, "Supporting Documentation must be supplied.");
        return;
    }

    if (Validation.isNullOrWhiteSpace(supportingDocument.fileName) || supportingDocument.fileName!.length === 0) {
        v.addError(validatorName, "Supporting Documentation file names cannot be blank.");
    }

    if (supportingDocument.fileName && supportingDocument.fileName.length > 100) {
        v.addError(validatorName, "Supporting Documentation file names must be 100 characters or less.");
    }
}

function validateCaAuthoritySupportingDocument(caAuthoritySupportingDocument: Models.ISupportingDocumentViewModel, questions: Models.IQuestionsViewModel, v: Validation.ValidationModel): void {
    if (!questions.isCompanyAdministratorRequest) return;

    validateSupportingDocument("CaAuthoritySupportingDocument", caAuthoritySupportingDocument, v);
}

function validateCsAuthoritySupportingDocument(csAuthoritySupportingDocument: Models.ISupportingDocumentViewModel, questions: Models.IQuestionsViewModel, v: Validation.ValidationModel): void {
    if (!questions.isCompanySignerRequest) return;

    validateSupportingDocument("CsAuthoritySupportingDocument", csAuthoritySupportingDocument, v);
}

function validateCsPoaAuthoritySupportingDocument(csPoaSupportingDocument: Models.ISupportingDocumentViewModel, questions: Models.IQuestionsViewModel, v: Validation.ValidationModel): void {
    if (!questions.isCompanySignerPoaRequest) return;

    validateSupportingDocument("CsPoaSupportingDocument", csPoaSupportingDocument, v);
}

// TODO: this will probably need a common textarea maxlength solution
function validateComments(comments: string, v: Validation.ValidationModel): void {
    if (!comments) return;
    if (comments.length > 1000) v.addError("Comments.Comments", "Comments must be 1000 characters or less.");
}
