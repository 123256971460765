// framework
import { Link } from "react-router-dom";
// models
import { IRootViewModel } from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import SimpleAuditEventsView from "../../../../../../common/audit/SimpleAuditEventsView";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
// api
import { NopimsActivityTypeEnum } from "../../../../../../api/Client";

interface IProps {
    vm: IRootViewModel;
}

export default function DataSubmissionDetailsView(props: IProps): JSX.Element {
    const { vm } = props;

    return (
        <>
            {vm.dataSubmission && vm.dataSubmission.type && (
                <>
                    <div className="card mb-2">
                        <div className="card-body">
                            <Forms.Row>
                                {vm.dataSubmission.type === NopimsActivityTypeEnum.Borehole && vm.wellDetails && (
                                    <>
                                        <Forms.HalfColumn>
                                            <Forms.Label htmlFor={"boreholeOperatorsField"}>Borehole Operator(s)</Forms.Label>
                                            <br />
                                            <p id="boreholeOperatorsField" className="form-label">
                                                {!vm.wellDetails.boreholeOperatorCompanies || vm.wellDetails.boreholeOperatorCompanies.length === 0
                                                    ? "No Borehole Operators"
                                                    : vm.wellDetails.boreholeOperatorCompanies.map((company) => company.name).join(", ")}
                                            </p>
                                        </Forms.HalfColumn>
                                        <Forms.HalfColumn>
                                            <Forms.Label htmlFor={"wellField"}>Well</Forms.Label>
                                            <br />
                                            {vm.permissions.canViewWells && vm.wellDetails.well && (
                                                <Link id="wellField" to={geoscienceAustraliaRouteHelper.wellDetails(vm.wellDetails.well?.id)} className="form-link" title="Click to view Well Details.">
                                                    {vm.wellDetails.well?.name}
                                                </Link>
                                            )}
                                            {!vm.permissions.canViewWells && (
                                                <p id="wellField" className="form-label">
                                                    {vm.wellDetails.well?.name}
                                                </p>
                                            )}
                                        </Forms.HalfColumn>
                                        <Forms.HalfColumn>
                                            <Forms.Label htmlFor={"boreholeField"}>Borehole(s)</Forms.Label>
                                            <br />
                                            {vm.permissions.canViewBoreholes && vm.wellDetails.boreholes && vm.wellDetails.boreholes.length > 0 ? (
                                                <div>
                                                    {vm.wellDetails.boreholes.map((borehole, index) => (
                                                        <span key={borehole.id}>
                                                            <Link
                                                                to={geoscienceAustraliaRouteHelper.boreholeDetails(vm.wellDetails!.well.id, borehole.id)}
                                                                className="form-link"
                                                                title="Click to view Borehole Details."
                                                                key={borehole.id}
                                                            >
                                                                {borehole.name}
                                                            </Link>
                                                            <span>{index === vm.wellDetails!.boreholes.length - 1 ? <></> : ", "}</span>
                                                        </span>
                                                    ))}
                                                </div>
                                            ) : (
                                                <p id="boreholeField" className="form-label">
                                                    {!vm.wellDetails.boreholes || vm.wellDetails.boreholes.length === 0
                                                        ? "No Boreholes"
                                                        : vm.wellDetails.boreholes.map((borehole) => borehole.name).join(", ")}
                                                </p>
                                            )}
                                        </Forms.HalfColumn>
                                    </>
                                )}
                                {vm.dataSubmission.type === NopimsActivityTypeEnum.Acquisition && vm.surveyDetails && (
                                    <>
                                        <Forms.HalfColumn>
                                            <Forms.Label htmlFor={"acquisitionOperatorsField"}>Acquisition Operator(s)</Forms.Label>
                                            <br />
                                            <p id="acquisitionOperatorsField" className="form-label">
                                                {!vm.surveyDetails.acquisitionOperatorCompanies || vm.surveyDetails.acquisitionOperatorCompanies.length === 0
                                                    ? "No Acquisition Operators"
                                                    : vm.surveyDetails.acquisitionOperatorCompanies.map((company) => company.name).join(", ")}
                                            </p>
                                        </Forms.HalfColumn>
                                        <Forms.HalfColumn>
                                            <Forms.Label htmlFor={"surveyField"}>Survey</Forms.Label>
                                            <br />
                                            {vm.permissions.canViewSurveys && vm.surveyDetails.survey && (
                                                <Link
                                                    id="surveyField"
                                                    to={geoscienceAustraliaRouteHelper.surveyDetails(vm.surveyDetails.survey.id)}
                                                    className="form-link"
                                                    title="Click to view Survey Details."
                                                >
                                                    {vm.surveyDetails.survey?.name}
                                                </Link>
                                            )}
                                            {!vm.permissions.canViewSurveys && (
                                                <p id="surveyField" className="form-label">
                                                    {vm.surveyDetails.survey.name}
                                                </p>
                                            )}
                                        </Forms.HalfColumn>
                                        <Forms.HalfColumn>
                                            <Forms.Label htmlFor={"acquisitionField"}>Acquisition(s)</Forms.Label>
                                            <br />
                                            {vm.permissions.canViewAcquisitions && vm.surveyDetails.acquisitions && vm.surveyDetails.acquisitions.length > 0 ? (
                                                <div>
                                                    {vm.surveyDetails.acquisitions.map((acquisition, index) => (
                                                        <span key={acquisition.id}>
                                                            <Link
                                                                to={geoscienceAustraliaRouteHelper.acquisitionDetails(vm.surveyDetails!.survey.id, acquisition.id)}
                                                                className="form-link"
                                                                title="Click to view Acquisition Details."
                                                                key={acquisition.id}
                                                            >
                                                                {acquisition.name}
                                                            </Link>
                                                            <span>{index === vm.surveyDetails!.acquisitions.length - 1 ? <></> : ", "}</span>
                                                        </span>
                                                    ))}
                                                </div>
                                            ) : (
                                                <p id="acquisitionField" className="form-label">
                                                    {!vm.surveyDetails.acquisitions || vm.surveyDetails.acquisitions.length === 0
                                                        ? "No Acquisitions"
                                                        : vm.surveyDetails.acquisitions.map((acquisition) => acquisition.name).join(", ")}
                                                </p>
                                            )}
                                        </Forms.HalfColumn>
                                    </>
                                )}
                                {vm.dataSubmission.type === NopimsActivityTypeEnum.Project && vm.reprocessingDetails && (
                                    <>
                                        <Forms.HalfColumn>
                                            <Forms.Label htmlFor={"projectOperatorsField"}>Project Operator(s)</Forms.Label>
                                            <br />
                                            <p id="projectOperatorsField" className="form-label">
                                                {!vm.reprocessingDetails.projectOperatorCompanies || vm.reprocessingDetails.projectOperatorCompanies.length === 0
                                                    ? "No Project Operators"
                                                    : vm.reprocessingDetails.projectOperatorCompanies.map((company) => company.name).join(", ")}
                                            </p>
                                        </Forms.HalfColumn>
                                        <Forms.HalfColumn>
                                            <Forms.Label htmlFor={"reprocessingField"}>Reprocessing</Forms.Label>
                                            <br />
                                            {vm.permissions.canViewReprocessing && vm.reprocessingDetails.reprocessing && (
                                                <Link
                                                    id="reprocessingField"
                                                    to={geoscienceAustraliaRouteHelper.reprocessingDetails(vm.reprocessingDetails.reprocessing.id)}
                                                    className="form-link"
                                                    title="Click to view Reprocessing Details."
                                                >
                                                    {vm.reprocessingDetails.reprocessing?.name}
                                                </Link>
                                            )}
                                            {!vm.permissions.canViewReprocessing && (
                                                <p id="reprocessingField" className="form-label">
                                                    {vm.reprocessingDetails.reprocessing.name}
                                                </p>
                                            )}
                                        </Forms.HalfColumn>
                                        <Forms.HalfColumn>
                                            <Forms.Label htmlFor={"projectField"}>Project(s)</Forms.Label>
                                            <br />
                                            {vm.permissions.canViewReprocessingProjects && vm.reprocessingDetails.projects && vm.reprocessingDetails.projects.length > 0 ? (
                                                <div>
                                                    {vm.reprocessingDetails.projects.map((project, index) => (
                                                        <span key={project.id}>
                                                            <Link
                                                                to={geoscienceAustraliaRouteHelper.reprocessingProjectDetails(vm.reprocessingDetails!.reprocessing.id, project.id)}
                                                                className="form-link"
                                                                title="Click to view Project Details."
                                                                key={project.id}
                                                            >
                                                                {project.name}
                                                            </Link>
                                                            <span>{index === vm.reprocessingDetails!.projects.length - 1 ? <></> : ", "}</span>
                                                        </span>
                                                    ))}
                                                </div>
                                            ) : (
                                                <p id="projectField" className="form-label">
                                                    {!vm.reprocessingDetails.projects || vm.reprocessingDetails.projects.length === 0
                                                        ? "No Projects"
                                                        : vm.reprocessingDetails.projects.map((project) => project.name).join(", ")}
                                                </p>
                                            )}
                                        </Forms.HalfColumn>
                                    </>
                                )}
                                <Forms.HalfColumn>
                                    <Forms.ReadonlyField id="typeField" label="Type" value={vm.dataSubmission.classificationType} />
                                </Forms.HalfColumn>
                                <Forms.HalfColumn>
                                    <Forms.ReadonlyField id="contactNameField" label="Contact Name" value={vm.dataSubmission.contactName} />
                                </Forms.HalfColumn>
                                <Forms.HalfColumn>
                                    <Forms.ReadonlyField id="contactEmailField" label="Contact Email Address" value={vm.dataSubmission.contactEmailAddress} />
                                </Forms.HalfColumn>
                                <Forms.HalfColumn>
                                    <Forms.ReadonlyField id="contactPhoneField" label="Contact Phone Number" value={vm.dataSubmission.contactPhoneNumber} />
                                </Forms.HalfColumn>
                                <Forms.HalfColumn />
                                <Forms.HalfColumn>
                                    <Forms.ReadonlyField id="receivedDateField" label="Received Date" value={GlobalHelpers.toNoptaDateString(vm.dataSubmission.receivedDate)} />
                                </Forms.HalfColumn>
                                <Forms.FullColumn>
                                    <Forms.ReadonlyTextArea id="commentsField" label="Comments" value={vm.dataSubmission.comments} />
                                </Forms.FullColumn>
                            </Forms.Row>
                        </div>
                    </div>
                    <SimpleAuditEventsView simpleAuditEvents={vm.audit} />
                </>
            )}
        </>
    );
}
