import * as Models from "../models/models";
import { IDocumentViewModel } from "../../common/models/documentViewModel";

// -------
// Actions
// -------

export enum actions {
    // lifecycle events
    clearRequested = "company.finance.financialNotificationDetails.clearRequested",
    viewChanged = "company.finance.financialNotificationDetails.viewChanged",

    clearDocumentRequested = "company.finance.financialNotificationDetails.clearDocumentRequested",

    // saga
    sagaGetDetailsRequested = "company.finance.financialNotificationDetails.sagaGetDetailsRequested",
    sagaCompleted = "company.finance.financialNotificationDetails.sagaCompleted",
    sagaGetDocumentRequested = "company.finance.financialNotificationDetails.sagaGetDocumentRequested",
    sagaGetDocumentCompleted = "company.finance.financialNotificationDetails.sagaGetDocumentCompleted",
    sagaCreatePaymentRequested = "company.finance.financialNotificationDetails.sagaCreatePaymentRequested",
    getCreditCardSurcharge = "company.finance.financialNotificationDetails.getCreditCardSurcharge",
}

// --------
// Payloads
// --------

export interface IAction<T> {
    type: actions;
    payload: T;
}

// ---------
// Factories
// ---------

export interface IActionFactory {
    clearRequested(): IAction<undefined>;
    clearDocumentRequested(): IAction<undefined>;

    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;

    sagaGetDetailsRequested(financialNotificationId: number): IAction<number>;
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    sagaGetDocumentRequested(vm: IDocumentViewModel): IAction<IDocumentViewModel>;
    sagaGetDocumentCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    sagaCreatePaymentRequested(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    getCreditCardSurcharge(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    clearDocumentRequested: function (): IAction<undefined> {
        return {
            type: actions.clearDocumentRequested,
            payload: undefined,
        };
    },
    sagaGetDetailsRequested: function (financialNotificationId: number): IAction<number> {
        return {
            type: actions.sagaGetDetailsRequested,
            payload: financialNotificationId,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
    sagaGetDocumentRequested: function (vm: IDocumentViewModel): IAction<IDocumentViewModel> {
        return {
            type: actions.sagaGetDocumentRequested,
            payload: vm,
        };
    },
    sagaGetDocumentCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaGetDocumentCompleted,
            payload: vm,
        };
    },
    sagaCreatePaymentRequested: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCreatePaymentRequested,
            payload: vm,
        };
    },
    getCreditCardSurcharge: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.getCreditCardSurcharge,
            payload: vm,
        };
    },
};
