// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// views
import NavBar, { TabsEnum } from "../../components/views/NavBarView";
import DataMonitoringListGridView from "./views/DataMonitoringListGridView";
// common
import * as geoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";
import LinksView, { LinkDetail } from "../../../../common/links/LinksView";

interface IRouteParameters {
    reprocessingId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { reprocessingId } = params;

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(reprocessingId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {vm.reprocessingName && (
                <BodyLayout
                    title={`Reprocessing - ${vm.reprocessingName}`}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_ReprocessingsProjects_Reprocessings}
                    onReloadRequested={() => {
                        props.initialiseRequested(Number(reprocessingId));
                    }}
                >
                    <NavBar reprocessingId={Number(reprocessingId)} activeNavItem={TabsEnum.DataMonitoring} />
                    <LinksView
                        title="Project(s)"
                        canViewLink={vm.permissions.canViewReprocessingProjects}
                        linkTooltip="Click to view Project Data Monitoring."
                        links={vm.projects.map((p) => new LinkDetail(p.name, geoscienceAustraliaRouteHelper.reprocessingProjectDataMonitoring(vm.reprocessingId!, p.id)))}
                    />
                    <DataMonitoringListGridView vm={vm} />
                </BodyLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_ReprocessingsProjects_ReprocessingDataMonitoring }), Actions.actionFactory)(Controller);
