import * as Client from "./Client";
import { logError } from "../infrastructure/TelemetryService";
import * as toastHelper from "../common/toastHelper";

// Simple
// - makes the service call
// - only use this for simple/lightweight operations that are likely to succeed (i.e. loading reference data)
// - throws exceptions to manage control flow
// - will show default toast notifications
export async function simpleCall<T>(operation: () => Promise<T>): Promise<T> {
    try {
        return await withRetry(operation);
    } catch (ex: any) {
        const apiExceptionDetails = getApiExceptionDetails(ex);
        showToastNotification(apiExceptionDetails.errorType, apiExceptionDetails.unitOfWorkCorrelation);
        throw new Error("The operation failed.");
    }
}

// Call
// - makes the service call
// - use this for operations that may reasonably fail for more complicated entities
// - logs and wraps exceptions into the result object
// - caller is in full control of what to do for specific error scenarios
export async function call<T>(operation: () => Promise<T>): Promise<CallResult<T>> {
    try {
        const response = await withRetry(operation);
        return new CallResult<T>(true, response, undefined, undefined);
    } catch (ex: any) {
        const apiExceptionDetails = getApiExceptionDetails(ex);
        return new CallResult<T>(false, undefined, apiExceptionDetails.errorType, apiExceptionDetails.unitOfWorkCorrelation);
    }
}

export interface ICallResultHandlers<T> {
    OnSuccess: (response: T) => void;
    OnNotAuthorised403?: () => void | undefined;
    OnNotFound404?: () => void | undefined;
    OnConflict409?: () => void | undefined;
}

export enum ErrorTypeEnum {
    BadRequest400,
    NotAuthenticated401,
    NotAuthorised403,
    NotFound404,
    Conflict409,
    ServerTooBusy429,
    InternalServerError500,
    ServiceUnavailable,
    UnexpectedError,
}

export class CallResult<T> {
    constructor(isSuccess: boolean, response: T | undefined, error: ErrorTypeEnum | undefined, correlationId: string | undefined) {
        this.IsSuccess = isSuccess;
        this.Response = response;
        this.Error = error;
        this.CorrelationId = correlationId;

        // in the context of a service call, 404 signifies that the resource that was there is no longer, which is typical for a delete conflict
        this.IsConflict = error === ErrorTypeEnum.NotFound404 || error === ErrorTypeEnum.Conflict409;
        this.IsUnauthorised = error === ErrorTypeEnum.NotAuthorised403;
    }

    IsSuccess: boolean;
    IsConflict: boolean;
    IsUnauthorised: boolean;
    Response: T | undefined;
    Error: ErrorTypeEnum | undefined;
    CorrelationId: string | undefined;

    ShowToastNotification(): void {
        showToastNotification(this.Error!, this.CorrelationId!);
    }
}

async function withRetry<T>(operation: () => Promise<T>): Promise<T> {
    const attempts: number = 3;
    const transientErrorCodes: Array<number | undefined> = [500, undefined];
    const delay: number = 1000;
    let attempt: number = 0;

    while (true) {
        attempt++;
        try {
            // give it a crack
            return await operation();
        } catch (ex: any) {
            //console.log(JSON.stringify(ex));
            const apiExceptionDetails = getApiExceptionDetails(ex);
            console.warn(`Failed service call; attempt ${attempt} of ${attempts} with correlation '${apiExceptionDetails.unitOfWorkCorrelation}'.`);
            // always log (to appinsights)
            logError(JSON.stringify(ex));
            // all exceptions from nswag are wrapped with this type
            // intransient errors do not get retries, immediately fail
            if (!transientErrorCodes.includes(apiExceptionDetails.status)) {
                console.error("Non-transient error encountered, not retrying.");
                throw ex;
            }
            // if we're exceeded the number of attempts, fail
            if (attempt === attempts) throw ex;
            // wait before retrying
            await new Promise((r) => setTimeout(r, delay));
        }
    }
}

function getApiExceptionDetails(ex: any): { unitOfWorkCorrelation: string; status: number; errorType: ErrorTypeEnum } {
    const apiException: Client.ApiException = ex;
    const headers = apiException?.headers;

    // yes, it must be lowercase "unit-of-work-correlation"

    return {
        unitOfWorkCorrelation: headers ? headers["unit-of-work-correlation"] : "",
        status: apiException?.status,
        errorType: getErrorType(ex),
    };
}

function getErrorType(ex: Client.ApiException): ErrorTypeEnum {
    switch (ex.status) {
        case undefined: // was unable to fetch (i.e. the endpoint was inaccessible)
            return ErrorTypeEnum.ServiceUnavailable;
        case 500: // internal server error
            return ErrorTypeEnum.InternalServerError500;
        case 401: // not authenticated
            return ErrorTypeEnum.NotAuthenticated401;
        case 403: // unauthorised
            return ErrorTypeEnum.NotAuthorised403;
        case 400: // bad request
            return ErrorTypeEnum.BadRequest400;
        case 404: // not found
            return ErrorTypeEnum.NotFound404;
        case 409: // conflict
            return ErrorTypeEnum.Conflict409;
        case 429: // too busy
            return ErrorTypeEnum.ServerTooBusy429;
        default:
            // unknown
            return ErrorTypeEnum.UnexpectedError;
    }
}

export function showToastNotification(result: ErrorTypeEnum, correlationId: string) {
    let message = "Please try the operation again. If the issue persists, please contact support.";
    if (correlationId.length > 0) {
        message = `Please try the operation again. If the issue persists, please contact support and quote ${correlationId.substring(0, 6).toUpperCase()}.`;
    }

    switch (result) {
        case ErrorTypeEnum.ServiceUnavailable:
            toastHelper.showExceptionNotification("Service Unavailable", message);
            return;
        case ErrorTypeEnum.InternalServerError500:
            toastHelper.showExceptionNotification("Internal Error", message);
            return;
        case ErrorTypeEnum.NotAuthenticated401:
            toastHelper.showExceptionNotification("Not Authenticated", message);
            return;
        case ErrorTypeEnum.NotAuthorised403:
            toastHelper.showExceptionNotification("Not Authorised", message);
            return;
        case ErrorTypeEnum.BadRequest400:
            toastHelper.showExceptionNotification("Bad Request", message);
            return;
        case ErrorTypeEnum.NotFound404:
            toastHelper.showExceptionNotification("Not Found", message);
            return;
        case ErrorTypeEnum.Conflict409:
            toastHelper.showExceptionNotification("Conflict", message);
            return;
        case ErrorTypeEnum.ServerTooBusy429:
            toastHelper.showExceptionNotification("Server Too Busy", message);
            return;
        case ErrorTypeEnum.UnexpectedError:
            toastHelper.showExceptionNotification("Unexpected Error", message);
            return;
    }
}
