// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    reprocessingProjectDetails: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectDetailsResponseDto | undefined;

    refreshReprocessingProjectDetails(response: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectDetailsResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.reprocessingProjectDetails = undefined;
    }

    public reprocessingProjectDetails: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectDetailsResponseDto | undefined;

    public refreshReprocessingProjectDetails(response: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectDetailsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.reprocessingProjectDetails = response;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.reprocessingProjectDetails = clone(this.reprocessingProjectDetails);
        return vm;
    }
}
