// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    dataSubmissionId: number | undefined;
    dataSubmissionName: string | undefined;
    dataSubmissionType: Client.NopimsActivityTypeEnum | undefined;
    items: Client.GetGeoscienceAustraliaDataSubmissionResubmissionListResponseItemDto[];

    refreshDataSubmissionResubmissions(dataSubmissionId: number, response: Client.GetGeoscienceAustraliaDataSubmissionResubmissionListResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.dataSubmissionId = undefined;
        this.dataSubmissionName = undefined;
        this.dataSubmissionType = undefined;
        this.items = [];
    }

    public dataSubmissionId: number | undefined;
    public dataSubmissionName: string | undefined;
    public dataSubmissionType: Client.NopimsActivityTypeEnum | undefined;
    public items: Client.GetGeoscienceAustraliaDataSubmissionResubmissionListResponseItemDto[];

    public refreshDataSubmissionResubmissions(dataSubmissionId: number, response: Client.GetGeoscienceAustraliaDataSubmissionResubmissionListResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.dataSubmissionId = dataSubmissionId;
        vm.dataSubmissionName = response.dataSubmissionName;
        vm.dataSubmissionType = response.dataSubmissionType;
        vm.items = response.items;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.items = clone(this.items);
        return vm;
    }
}
