import * as Models from "../models/models";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "company.finance.financialNotificationList.initialiseRequested",
    clearRequested = "company.finance.financialNotificationList.clearRequested",

    // user input
    viewChanged = "company.finance.financialNotificationList.viewChanged",

    // saga events
    sagaCompleted = "company.finance.financialNotificationList.sagaCompleted",
    getCreditCardSurcharge = "company.finance.financialNotificationList.getCreditCardSurcharge",
    createPaymentRequested = "company.finance.financialNotificationList.createPaymentRequested",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(): IAction<undefined>;
    clearRequested(): IAction<undefined>;
    // user input
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    // saga events
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    getCreditCardSurcharge(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    createPaymentRequested(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (): IAction<undefined> {
        return {
            type: actions.initialiseRequested,
            payload: undefined,
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
    getCreditCardSurcharge: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.getCreditCardSurcharge,
            payload: vm,
        };
    },
    createPaymentRequested: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.createPaymentRequested,
            payload: vm,
        };
    },
};
