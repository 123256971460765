// views
import CommonNavBar from "../../../../common/CommonNavBarView";
// common
import * as geoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";

export enum TabsEnum {
    Details,
    Titles,
    Acquisitions,
    DataMonitoring,
    Reports,
    Attachments,
}

export interface IProps {
    reprocessingId: number;
    reprocessingProjectId: number;
    activeNavItem: TabsEnum;
}

export default function NavBarView(props: IProps): JSX.Element {
    return (
        <CommonNavBar
            activeNavItemKey={props.activeNavItem}
            navItems={[
                {
                    key: TabsEnum.Details,
                    title: "Details",
                    url: geoscienceAustraliaRouteHelper.reprocessingProjectDetails(props.reprocessingId, props.reprocessingProjectId),
                },
                {
                    key: TabsEnum.Titles,
                    title: "Title Relationships",
                    url: geoscienceAustraliaRouteHelper.reprocessingProjectTitles(props.reprocessingId, props.reprocessingProjectId),
                },
                {
                    key: TabsEnum.Acquisitions,
                    title: "Related Acquisitions",
                    url: geoscienceAustraliaRouteHelper.reprocessingProjectAcquisitions(props.reprocessingId, props.reprocessingProjectId),
                },
                {
                    key: TabsEnum.DataMonitoring,
                    title: "Data Monitoring",
                    url: geoscienceAustraliaRouteHelper.reprocessingProjectDataMonitoring(props.reprocessingId, props.reprocessingProjectId),
                },
                {
                    key: TabsEnum.Reports,
                    title: "Reports",
                    url: geoscienceAustraliaRouteHelper.reprocessingProjectReports(props.reprocessingId, props.reprocessingProjectId),
                },
                {
                    key: TabsEnum.Attachments,
                    title: "Attachments",
                    url: geoscienceAustraliaRouteHelper.reprocessingProjectAttachments(props.reprocessingId, props.reprocessingProjectId),
                },
            ]}
        />
    );
}
