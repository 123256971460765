// redux
import { call, put, select } from "redux-saga/effects";
import { IJointAuthorityReduxState } from "../../../jointAuthorityReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IRegistrationRoleViewModel } from "../../models/registrationRoleViewModel";
// api
import * as ClientFactory from "../../../../api/clientFactory";
import * as CallHelper from "../../../../api/callHelper";
import * as Client from "../../../../api/Client";
// shell
import * as ShellActions from "../../../../shell/layout/redux/actions";
// common
import * as LogHelper from "../../../../common/LogHelper";

const getRootViewModel = (state: IJointAuthorityReduxState) => state.JointAuthority_Home_Registration;

export default function* createSecureRequest() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const actions = Actions.actionFactory;

    try {
        yield put(ShellActions.actionFactory.setBusy());

        let response: IResponse;
        const questions = vm.user.questions;

        if (questions.isJointAuthorityAdministratorRequest) {
            response = yield call(async () => await createNuJaaSecureRequest(vm));
            // } else if (questions.isCompanySignerRequest) {
            //     response = yield call(async () => await createNuCrCsSecureRequest(vm));
        } else {
            response = yield call(async () => await createNuJarSecureRequest(vm));
        }

        yield put(actions.sagaCompleted(vm.refreshSecureRequestCreateResponse(response.statusMessages, response.secureRequestGroupId?.code)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(ShellActions.actionFactory.clearBusy());
    }
}

interface IResponse {
    statusMessages: Client.StatusMessagesDto;
    secureRequestGroupId?: Client.SecureRequestGroupIdDto | undefined;
}

async function createNuJaaSecureRequest(vm: Models.IRootViewModel): Promise<IResponse> {
    const request = new Client.CreateRegistrationJointAuthorityNuJaaRequestDto({
        jointAuthorityId: vm.user.questions.selectedJointAuthority!.jointAuthorityId,
        submittedComments: vm.user.comments,
        newUserRequest: getNewJointAuthorityUserRequestDto(vm.user.newUserRequest),
        jaaAuthoritySupportingDocument: getSupportingDocumentDto(vm.user.jaaAuthoritySupportingDocument),
    });

    const client = ClientFactory.createRegistrationAnonymousRequestService();
    return await CallHelper.simpleCall(() => client.createRegistrationJointAuthorityNuJaa(request));
}

async function createNuJarSecureRequest(vm: Models.IRootViewModel): Promise<IResponse> {
    const request = new Client.CreateRegistrationJointAuthorityNuJarRequestDto({
        jointAuthorityId: vm.user.questions.selectedJointAuthority!.jointAuthorityId,
        submittedComments: vm.user.comments,
        newUserRequest: getNewJointAuthorityUserRequestDto(vm.user.newUserRequest),
        jointAuthorityStandardRolesRequest: getJointAuthorityStandardRolesRequestDto(vm.user.jointAuthorityStandardRoleRequest.requestedRoles!),
    });

    const client = ClientFactory.createRegistrationAnonymousRequestService();
    return await CallHelper.simpleCall(() => client.createRegistrationJointAuthorityNuJar(request));
}

function getNewJointAuthorityUserRequestDto(vm: Models.INewUserRequestViewModel): Client.NewJointAuthorityUserRequestDetailsWithPasswordDto {
    return new Client.NewJointAuthorityUserRequestDetailsWithPasswordDto({
        token: vm.emailVerification.token!,
        emailAddress: vm.email,
        firstName: vm.firstName,
        lastName: vm.lastName,
        position: vm.position,
        contactNumber: vm.contactNumber,
        password: vm.password,
    });
}

function getJointAuthorityStandardRolesRequestDto(vm: IRegistrationRoleViewModel): Client.JointAuthorityStandardRoleRequestDetailsDto {
    return new Client.JointAuthorityStandardRoleRequestDetailsDto({
        rolesRequested: vm.getDirectRoles(),
        rolesApproved: undefined,
    });
}

function getSupportingDocumentDto(document: Models.ISupportingDocumentViewModel): Client.SecureRequestGroupSupportingDocumentDto {
    return new Client.SecureRequestGroupSupportingDocumentDto({ fileName: document.fileName, content: document.content });
}
