// framework
import { call, put, select } from "redux-saga/effects";
// redux
import { ICompanyReduxState } from "../../../../companyReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as LogHelper from "../../../../../common/LogHelper";
import * as shellHelper from "../../../../../common/shell/shellHelper";

const getRootViewModel = (state: ICompanyReduxState) => state.Company_Finance_PaymentHistoryDetails;

export default function* getDetailsRequested(action: Actions.IAction<number>) {
    const financialNotificationId = action.payload;

    try {
        yield put(shellHelper.createSetBusyAction());
        const response: Client.GetCompanyFinanceFnDetailsResponseDto = yield call(async () => {
            const client = await ClientFactory.createCompanyFinanceClient();
            const request = new Client.GetCompanyFinanceFnDetailsRequestDto({ financialNotificationId: financialNotificationId });
            return await CallHelper.simpleCall(() => client.getCompanyFinanceFnDetails(request));
        });

        const vm: Models.IRootViewModel = yield select(getRootViewModel);
        yield put(Actions.actionFactory.sagaCompleted(vm.refreshDetails(response, false)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
