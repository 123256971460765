// framework
import { useState, useEffect } from "react";
// redux
import * as Models from "../../models/models";
import * as Actions from "../../redux/actions";
// common
import { IValidationModel } from "../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
import * as Forms from "../../../../../common/forms/BootstrapForms";
import { DropDownList } from "@progress/kendo-react-dropdowns";

export default function ApplicantView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory; v: IValidationModel; isBusy: boolean }): React.ReactElement {
    const vm = props.vm;
    const actions = props.actions;
    const v = props.v;

    const [applicantCompany, setApplicantCompany] = useState(vm.applicantCompany);

    useEffect(() => {
        setApplicantCompany(vm.applicantCompany);
    }, [vm.applicantCompany]);

    return (
        <Forms.Group>
            <Forms.Label htmlFor="applicantField">Applicant/Company</Forms.Label>
            <DropDownList
                id="applicantField"
                data={vm.applicantCompanies}
                value={applicantCompany}
                dataItemKey="companyId"
                textField="companyName"
                disabled={props.isBusy}
                onChange={(e) => actions.viewChanged(vm.onApplicantChanged(e.value))}
            />
            <ValidationMessageView name="AdhocUpload.Applicant" validationModel={v} />
        </Forms.Group>
    );
}
