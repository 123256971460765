// framework
import React from "react";
import { Link } from "react-router-dom";
// api
import * as Client from "../../api/Client";
// company
import * as companyRouteHelper from "../../company/companyRouteHelper";
// common
import * as jointAuthorityRouteHelper from "../../jointAuthority/jointAuthorityRouteHelper";
import * as geoscienceAustraliaRouteHelper from "../../geoscienceAustralia/geoscienceAustraliaRouteHelper";

interface IControllerProps {
    linkDto: Client.SecureRequestGroupLinkDto;
    secureEntityType: "Company" | "JointAuthority" | "GeoscienceAustralia";
}

// provides for the following capabilities:
// (1) Company, JA and GA links only
// (2) Secure Request Group links will route to the internal Secure Request Group Details page
// (3) Unauthorised links will render Secure Request Group Code only

function Controller(props: IControllerProps): React.ReactElement {
    if (!props.linkDto) throw new Error("SecureRequestGroup Link cannot be undefined!");
    const link = props.linkDto;
    const secureEntityType = props.secureEntityType;

    if (!link.isAuthorised) return <>{link.code}</>;

    let path: string;
    switch (secureEntityType) {
        case "Company":
            path = companyRouteHelper.requestDetails(link.id, link.type);
            break;
        case "JointAuthority":
            path = jointAuthorityRouteHelper.requestDetails(link.id, link.type);
            break;
        case "GeoscienceAustralia":
            path = geoscienceAustraliaRouteHelper.requestDetails(link.id, link.type);
            break;
        default:
            throw new Error("Unknown entity type.");
    }

    return <Link to={path}>{link.code}</Link>;
}

export default Controller;
