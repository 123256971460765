import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export enum DefaultButtonEnum {
    None = "None",
    Ok = "Ok",
    Cancel = "Cancel",
}

export default function ConfirmationView(props: { title: string; onAccepted: () => void; onClosed: () => void; children: React.ReactNode; defaultButton: DefaultButtonEnum }) {
    return (
        <Dialog title={props.title} onClose={(e) => props.onClosed()}>
            {props.children}
            <DialogActionsBar>
                <button
                    className="btn btn-outline-primary m-2"
                    style={{ width: 75 }}
                    {...(props.defaultButton === DefaultButtonEnum.Ok ? { autoFocus: true } : {})}
                    onClick={(e) => props.onAccepted()}
                >
                    OK
                </button>
                <button
                    className="btn btn-outline-primary m-2"
                    style={{ width: 75 }}
                    {...(props.defaultButton === DefaultButtonEnum.Cancel ? { autoFocus: true } : {})}
                    onClick={(e) => props.onClosed()}
                >
                    Cancel
                </button>
            </DialogActionsBar>
        </Dialog>
    );
}
