// framework
import { useEffect, useState } from "react";
// api
import * as Client from "../../../../../api/Client";
// kendo
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// redux
import * as Models from "../../models/models";
import * as Actions from "../../redux/actions";
// other
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import CustomGridCell from "../../../../../common/kendoGrid/CustomGridCell";
import CreateDraftApplicationView from "./CreateDraftApplicationView";

interface IProps {
    vm: Models.IRootViewModel;
    actions: Actions.IActionFactory;
}

export default function InvitationToApplyListView(props: IProps): JSX.Element {
    const invitationsToApply = props.vm.invitationsToApply ?? new Array<Client.GetCompanyOeiInvitationToApplyListInvitationToApplyDto>();
    const { vm, actions } = props;

    // create draft application
    const [showCreateWindow, setShowCreateWindow] = useState(false);

    // - selectedInvitationToApplyId is set when a user clicks the Create button
    useEffect(() => {
        if (vm.selectedInvitationToApplyId) {
            setShowCreateWindow(true);
        }
    }, [vm.selectedInvitationToApplyId]);

    function onCreateRequested() {
        actions.createDraftApplicationRequested();
        setShowCreateWindow(false);
    }

    function onCreateCancelled() {
        actions.clearDraftApplicationDataRequested();
        setShowCreateWindow(false);
    }

    const initialGridState: State = {
        sort: [{ field: "bidClosedDatetime", dir: "asc" }],
        skip: 0,
        take: 20,
    };
    const [gridState, setGridState] = useState(initialGridState);

    function commandCell(props: GridCellProps) {
        const { dataItem } = props;
        const i = dataItem as Client.GetCompanyOeiInvitationToApplyListInvitationToApplyDto;
        if (!i) return <></>;

        return (
            <CustomGridCell gridCellProps={props} className="k-command-cell">
                {vm.canCreateInvitationToApply && i.isBidOpen && (
                    <button className="btn btn-outline-primary me-2" onClick={() => actions.viewChanged(vm.refreshSelectedInvitationToApply(i.id))}>
                        Create
                    </button>
                )}
            </CustomGridCell>
        );
    }

    return (
        <>
            <Grid
                pageable
                resizable
                navigatable
                data={process(invitationsToApply, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn field="name" title="Name" width={500} />
                <GridColumn field="licenceType" title="Type" width={150} />
                <GridColumn field="bidOpenDate" title="Bid Open Date" width={200} format={GlobalHelpers.NoptaDateFormatForKendo} />
                <GridColumn field="bidClosedDatetime" title="Bid Close Date (AWST)" width={200} format={GlobalHelpers.NoptaDatetimeFormatForKendo} />
                <GridColumn width={125} cell={commandCell} />
            </Grid>
            {showCreateWindow && <CreateDraftApplicationView vm={vm} actions={actions} onCreate={onCreateRequested} onCancel={onCreateCancelled} />}
        </>
    );
}
