import * as Client from "../../../../api/Client";
import { clone } from "lodash";

export interface IRootViewModel {
    legislativeFormRequests: FormRequestsListItems;
    legislativeForms: FormListItems;
    legislativeFormDocument?: Client.SecurePortalDocumentDto | undefined;

    refreshList(response: Client.GetCompanyOpggsLegislativeFormsListResponseDto): IRootViewModel;
    refreshLegislativeFormDocument(response: Client.SecurePortalDocumentDto | undefined): IRootViewModel;
    clearLegislativeFormDocument(): IRootViewModel;
}

export type FormRequestsListItems = Array<Client.GetCompanyOpggsLegislativeFormsFormRequestListItemDto> | undefined;
export type FormListItems = Array<Client.GetCompanyOpggsLegislativeFormsFormListItemDto> | undefined;

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.legislativeFormRequests = undefined;
        this.legislativeForms = undefined;
    }

    public legislativeFormRequests: FormRequestsListItems;
    public legislativeForms: FormListItems;
    public legislativeFormDocument?: Client.SecurePortalDocumentDto | undefined;

    public refreshList(response: Client.GetCompanyOpggsLegislativeFormsListResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.legislativeFormRequests = response.legislativeFormRequests;
        vm.legislativeForms = response.legislativeForms;
        return vm;
    }

    public refreshLegislativeFormDocument(response: Client.SecurePortalDocumentDto): IRootViewModel {
        const vm = this._clone();
        vm.legislativeFormDocument = response;
        return vm;
    }

    public clearLegislativeFormDocument(): IRootViewModel {
        const vm = this._clone();
        vm.legislativeFormDocument = undefined;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
