// redux
import { call, put, select } from "redux-saga/effects";
import { ICompanyReduxState } from "../../../../companyReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/rootViewModel";
import { getCompanyDetails } from "./initialise";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

const getRootViewModel = (state: ICompanyReduxState) => state.Company_UserManagement_CompanyDetails;

export default function* savePermissions() {
    let vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const saveResult: CallHelper.CallResult<Client.SaveCompanyUserManagementCompanyDetailsRolesResponseDto> = yield call(async () => await saveCompanyPermissions(vm));

        if (saveResult.IsSuccess) {
            // success
            const r = saveResult.Response!;
            if (r.statusMessages.isSuccess) {
                const companyResponse: Client.GetCompanyUserManagementCompanyDetailsResponseDto = yield call(getCompanyDetails, vm.id!);
                vm = vm.refreshCompanyDetails(companyResponse, false);
            }
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshSaveCompanyPermissionsResponse(saveResult.Response!)));
        } else if (saveResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            saveResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function saveCompanyPermissions(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.SaveCompanyUserManagementCompanyDetailsRolesResponseDto>> {
    // Exclude secure users that can not be edited from the update request
    const editedPermissions = vm.permissions.filter((p) => p.hasChanges());
    if (editedPermissions.some((p) => !p.canEdit())) throw new Error("Invalid field update.");

    const permissionDtos = editedPermissions.map(
        (p) =>
            new Client.SaveCompanyUserManagementCompanyDetailsRoleDto({
                secureUserId: p.secureUserId,
                secureUserVersionNumber: p.secureUserVersionNumber,
                companyId: p.companyId,
                roles: p.getDirectRoles(),
            })
    );
    const request = new Client.SaveCompanyUserManagementCompanyDetailsRolesRequestDto({
        companyId: vm.id!,
        roles: permissionDtos,
    });

    const client = await ClientFactory.createCompanyUserManagementClient();
    return await CallHelper.call(() => client.saveCompanyUserManagementCompanyDetailsRoles(request));
}
