// api
import * as Client from "../../../../../../api/Client";
// other
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import TitleDetailsField from "../TitleDetailsField";

function TitleSummaryIlView(props: { summary: Client.GetCompanyOpggsTitleDetailsSummaryDto | undefined; areaSqKm: number | undefined }) {
    const summary = props.summary;
    const areaSqKm = props.areaSqKm;

    return (
        <>
            <TitleDetailsField label="Status">{summary?.titleStatus}</TitleDetailsField>
            <TitleDetailsField label="Offshore Area">{summary?.offshoreAreaName}</TitleDetailsField>
            <TitleDetailsField label="Basin">{summary?.basinName}</TitleDetailsField>
            <TitleDetailsField label="Initial Grant Date">{GlobalHelpers.toNoptaDateString(summary?.initialGrantDate)}</TitleDetailsField>
            <TitleDetailsField label="Expiry Date">{GlobalHelpers.toNoptaDateString(summary?.currentExpiryDate, "Indefinite")}</TitleDetailsField>
            <TitleDetailsField label="Area">{GlobalHelpers.toNoptaNumberString(areaSqKm, "n2", "km²")}</TitleDetailsField>
        </>
    );
}

export default TitleSummaryIlView;
