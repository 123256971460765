// redux
import { call, put, select } from "redux-saga/effects";
import { ICompanyReduxState } from "../../../../../companyReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { getFormRequestDetails } from "./getDetails";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import { IAddressDetailsViewModel } from "../../../common/addressDetailsViewModel";
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

interface IResponse {
    statusMessages: Client.StatusMessagesDto;
}

const getRootViewModel = (state: ICompanyReduxState) => state.Company_Opggs_LegislativeForms_FormRequestDetails;

export default function* saveFormRequestDetails() {
    let vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        let saveResult: CallHelper.CallResult<IResponse>;

        switch (vm.formRequestDetails.formTypeId) {
            case 2:
                saveResult = yield call(async () => await saveForm2Request(vm));
                break;
            case 3:
                saveResult = yield call(async () => await saveForm3Request(vm));
                break;
            default:
                throw new Error(`Save is not supported for Legislative Form '${vm.formRequestDetails.formTypeId}'.`);
        }

        if (saveResult.IsSuccess) {
            // success
            const r = saveResult.Response!;
            let fetchStatusMessages: Client.StatusMessagesDto | undefined = undefined;
            if (r.statusMessages.isSuccess) {
                const formRequestResponse: Models.IGetFormRequestDetailsResponse = yield call(getFormRequestDetails, vm.id!, vm.formRequestDetails.formTypeId);
                fetchStatusMessages = formRequestResponse.statusMessages;
                vm = vm.refreshRequestDetails(formRequestResponse);
            }
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshFormRequestStatusMessages(r.statusMessages, fetchStatusMessages)));
        } else if (saveResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            saveResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function saveForm2Request(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();

    const request = new Client.SaveCompanyOpggsLegislativeFormsForm2RequestDetailsRequestDto({
        id: vm.id!,
        versionNumber: vm.versionNumber!,
        registeredTitleholderDetails: getRegisteredTitleholderDetailsDto(vm),
        addressDetails: getAddressDetailsDto(vm.addressDetails),
    });

    return await CallHelper.call(() => client.saveCompanyOpggsLegislativeFormsForm2RequestDetails(request));
}

async function saveForm3Request(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<IResponse>> {
    const client = await ClientFactory.createCompanyOpggsLegislativeFormsClient();
    const titleIds = vm.formRequestDetails.titles?.map((t) => t.id) ?? new Array<string>();

    const request = new Client.SaveCompanyOpggsLegislativeFormsForm3RequestDetailsRequestDto({
        id: vm.id!,
        versionNumber: vm.versionNumber!,
        titleIds: titleIds,
        registeredTitleholderDetails: getRegisteredTitleholderDetailsDto(vm),
        addressDetails: getAddressDetailsDto(vm.addressDetails),
    });

    return await CallHelper.call(() => client.saveCompanyOpggsLegislativeFormsForm3RequestDetails(request));
}

// private
function getRegisteredTitleholderDetailsDto(vm: Models.IRootViewModel): Client.FormRequestRegisteredTitleholderDetailsDto {
    // only persist acn if registered titleholder requires it
    const acn = vm.formRequestDetails.isAcnOrArbnRequired ? vm.registeredTitleholderDetails.acnOrArbn : "";

    return new Client.FormRequestRegisteredTitleholderDetailsDto({
        acnOrArbn: acn,
        phone: vm.registeredTitleholderDetails.phone,
        fax: vm.registeredTitleholderDetails.fax,
        email: vm.registeredTitleholderDetails.email,
    });
}

function getAddressDetailsDto(vm: IAddressDetailsViewModel): Client.FormRequestAddressDetailsDto {
    return new Client.FormRequestAddressDetailsDto({
        addressType: vm.addressType!,
        streetAddress: vm.streetAddress,
        suburb: vm.suburb,
        postcode: vm.postcode,
        countryId: vm.country!.countryId,
        countrySubdivisionId: vm.state?.countrySubdivisionId,
    });
}
