export default function Form4NoActiveFormView(): React.ReactElement {
    return (
        <div className="card mb-2">
            <div className="card-body">
                <h3 className="card-title">Form 4</h3>
                <span>There is no current Form 4.</span>
            </div>
        </div>
    );
}
