// framework
import { Link } from "react-router-dom";
// kendo
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
// common
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
// models
import { IRootViewModel } from "../../models/models";

interface IProps {
    vm: IRootViewModel;
}

export default function ResubmissionListGridView(props: IProps): JSX.Element {
    const { vm } = props;

    return (
        <>
            <div className="my-2">
                <div className="card-body">
                    <Grid data={vm.items}>
                        <GridNoRecords>There are currently no resubmissions for this Data Submission.</GridNoRecords>
                        <GridColumn
                            field="name"
                            title="Name"
                            cell={(props) => (
                                <CustomGridCell gridCellProps={props}>
                                    <Link
                                        to={geoscienceAustraliaRouteHelper.dataSubmissionResubmissionDetails(vm.dataSubmissionId!, props.dataItem.resubmissionId)}
                                        title="Click to view Resubmission."
                                    >
                                        {props.dataItem.name}
                                    </Link>
                                </CustomGridCell>
                            )}
                            filter="text"
                            width={120}
                        />
                        <GridColumn field="status" title="Status" width={180} filter="text" />
                        <GridColumn field="requestedDate" title="Requested Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={150} />
                        <GridColumn field="dueDate" title="Due Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={150} />
                        <GridColumn field="receivedDate" title="Received Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={150} />
                    </Grid>
                </div>
            </div>
        </>
    );
}
