// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export enum ViewStateEnum {
    Initialise = "geoscienceAustralia.reportsAndData.surveyDataDetails.models.viewState.initialise",
    Edit = "geoscienceAustralia.reportsAndData.surveyDataDetails.models.viewState.edit",
    Create = "geoscienceAustralia.reportsAndData.surveyDataDetails.models.viewState.create",
}

export interface ICreateDetails {
    itemId?: string | undefined;
    publicTitle?: string | undefined;
    surveyAcquisitions?: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseSurveyAcquisitionDto[];
    surveyDataType?: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseSurveyDataTypeDto | undefined;
    isCopiedToNopims?: boolean | undefined;
    surveyDataTitle?: string | undefined;
    writtenDate?: Date | undefined;
    receivedDate?: Date | undefined;
    isConfidential?: boolean | undefined;
    confidentialityRemarks?: string | undefined;
    surveyDataBasicInterpType?: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseBasicInterpTypeDto | undefined;
    basicRelevantDate?: Date | undefined;
    interpRelevantDate?: Date | undefined;
    releasedDate?: Date | undefined;
    company?: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseContractorCompaniesDto | undefined;
    contractor?: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseContractorCompaniesDto | undefined;
    tapeId?: string | undefined;
    surveyDataDataFormat?: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseSurveyDataDataFormatDto | undefined;
    surveyDataMediaType?: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseSurveyDataMediaTypeDto | undefined;
    remarks?: string | undefined;
    surveyDataConditionStatus?: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseSurveyDataConditionStatusDto | undefined;
    qcRemarks?: string | undefined;
    surveyDataCopyStatus?: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseSurveyDataCopyStatusDto | undefined;
    surveyDataLoanStatus?: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseSurveyDataLoanStatusDto | undefined;
    surveyDataRddsStatus?: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseSurveyDataRddsStatusDto | undefined;
    rddsComments?: string | undefined;
    surveyDataDataStatus?: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseSurveyDataDataStatusDto | undefined;
    consignment?: string | undefined;
    inlineRange?: string | undefined;
    crossLineRange?: string | undefined;
}

export interface IReferenceData {
    surveyAcquisitions: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseSurveyAcquisitionDto[];
    surveyDataTypes: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseSurveyDataTypeDto[];
    surveyDataBasicInterpTypes: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseBasicInterpTypeDto[];
    contractorCompanies: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseContractorCompaniesDto[];
    surveyDataDataFormats: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseSurveyDataDataFormatDto[];
    surveyDataMediaTypes: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseSurveyDataMediaTypeDto[];
    surveyDataConditionStatuses: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseSurveyDataConditionStatusDto[];
    surveyDataCopyStatuses: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseSurveyDataCopyStatusDto[];
    surveyDataLoanStatuses: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseSurveyDataLoanStatusDto[];
    surveyDataRddsStatuses: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseSurveyDataRddsStatusDto[];
    surveyDataDataStatuses: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseSurveyDataDataStatusDto[];
}

export interface IRootViewModel {
    surveyDataId: number | undefined;

    createDetails: ICreateDetails;
    referenceData: IReferenceData;

    auditDto?: Client.SimpleAuditEventsDto;

    statusMessages?: Client.StatusMessagesDto;

    viewState: ViewStateEnum;
    relatedActivityType: Client.CreateSurveyDataRelatedActivityTypeEnum | undefined;

    isDirty: boolean;

    refreshReferenceData(
        relatedActivityType: Client.CreateSurveyDataRelatedActivityTypeEnum | undefined,
        response: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseDto
    ): IRootViewModel;
    refreshCreateResponse(response: Client.CreateGeoscienceAustraliaSurveyDataResponseDto, createAnother: boolean): IRootViewModel;

    onCreateDetailsChanged(surveyDataDetails: ICreateDetails): IRootViewModel;
    onDirtyClear(): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.createDetails = {};
        this.referenceData = {
            surveyAcquisitions: [],
            surveyDataTypes: [],
            surveyDataBasicInterpTypes: [],
            contractorCompanies: [],
            surveyDataDataFormats: [],
            surveyDataMediaTypes: [],
            surveyDataConditionStatuses: [],
            surveyDataCopyStatuses: [],
            surveyDataLoanStatuses: [],
            surveyDataRddsStatuses: [],
            surveyDataDataStatuses: [],
        };

        this.isDirty = false;
        this.viewState = ViewStateEnum.Initialise;
    }

    public surveyDataId: number | undefined;

    public createDetails: ICreateDetails;
    public referenceData: IReferenceData;

    public auditDto?: Client.SimpleAuditEventsDto;

    public statusMessages?: Client.StatusMessagesDto;
    public isDirty: boolean;

    public viewState: ViewStateEnum;
    public relatedActivityType: Client.CreateSurveyDataRelatedActivityTypeEnum | undefined;

    public refreshReferenceData(
        relatedActivityType: Client.CreateSurveyDataRelatedActivityTypeEnum | undefined,
        response: Client.GetCreateGeoscienceAustraliaSurveyDataReferenceDataResponseDto
    ): IRootViewModel {
        const vm = this._clone();
        vm.viewState = ViewStateEnum.Edit;
        vm.relatedActivityType = relatedActivityType;
        vm.referenceData = {
            surveyAcquisitions: response.surveyAcquisitions,
            surveyDataTypes: response.surveyDataTypes,
            surveyDataBasicInterpTypes: response.basicInterpTypes,
            contractorCompanies: response.contractorCompanies,
            surveyDataDataFormats: response.surveyDataDataFormats,
            surveyDataMediaTypes: response.surveyDataMediaTypes,
            surveyDataConditionStatuses: response.surveyDataConditionStatuses,
            surveyDataCopyStatuses: response.surveyDataCopyStatuses,
            surveyDataLoanStatuses: response.surveyDataLoanStatuses,
            surveyDataRddsStatuses: response.surveyDataRddsStatuses,
            surveyDataDataStatuses: response.surveyDataDataStatuses,
        };

        return vm;
    }

    public refreshCreateResponse(response: Client.CreateGeoscienceAustraliaSurveyDataResponseDto, createAnother: boolean): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = response.statusMessages;

        if (response.statusMessages?.isSuccess) {
            // don't set the surveyData Id when requested to create and create another
            if (createAnother) {
                vm.surveyDataId = undefined;
                vm.viewState = ViewStateEnum.Edit;
                vm.createDetails = {};
            } else {
                vm.surveyDataId = response.surveyDataId;
                vm.viewState = ViewStateEnum.Create;
            }
            vm.isDirty = false;
        } else {
            vm.isDirty = true;
        }
        return vm;
    }

    public onCreateDetailsChanged(createDetails: ICreateDetails): IRootViewModel {
        const vm = this._clone();
        vm.createDetails = createDetails;
        vm.isDirty = true;
        return vm;
    }

    public onDirtyClear(): IRootViewModel {
        const vm = this._clone();
        vm.isDirty = false;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.createDetails = clone(this.createDetails);
        vm.referenceData = clone(this.referenceData);
        return vm;
    }
}
