// kendo
import { GridColumn } from "@progress/kendo-react-grid";
// redux
import * as Models from "../../models/models";
// other
import RoleGrid from "../../../../../common/roleGrid/RoleGrid";
import * as Forms from "../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";

export default function PendingRequestsView(props: { vm: Models.IRootViewModel }): React.ReactElement {
    const pendingRequests = props.vm.pendingRequests;

    // view
    return (
        <div className="card my-2">
            <div className="card-body">
                <h2 className="card-title">Pending Requests</h2>
                {pendingRequests.length > 0 && <Forms.Group>The following requests are pending approval.</Forms.Group>}
                <RoleGrid
                    model={pendingRequests}
                    showAdmin
                    roleGroups={props.vm.roleConfiguration.roleGroups}
                    preColumns={[
                        <GridColumn key="secureRequestGroupCode" field="secureRequestGroupCode" title="Request ID" width={200} />,
                        <GridColumn key="requestDatetime" field="requestDatetime" title="Request Date (AWST)" width={250} format={GlobalHelpers.NoptaDatetimeFormatForKendo} />,
                    ]}
                />
            </div>
        </div>
    );
}
