// framework
import * as Validation from "../../../../../common/validation/ValidationModel";
// models
import * as Models from "./models";
// common
import * as AddressDetailsValidator from "../../common/addressDetailsValidator";
import * as RegisteredTitleholderDetailsValidator from "../../common/registeredTitleholderDetailsValidator";

export default function validate(vm: Models.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateFormRequestDetails(vm, v);
    AddressDetailsValidator.validate(vm.addressDetails, vm.formRequestDetails.formTypeId, vm.reference.countries, v);
    RegisteredTitleholderDetailsValidator.validate(vm.registeredTitleholderDetails, vm.formRequestDetails.formTypeId, vm.formRequestDetails.isAcnOrArbnRequired, v);
    return v;
}

function validateFormRequestDetails(vm: Models.IRootViewModel, v: Validation.ValidationModel): void {
    // titles only editable for form 3 requests
    if (vm.formRequestDetails.formTypeId !== 3) return;

    if (!vm.formRequestDetails.titles || vm.formRequestDetails.titles.length === 0) {
        v.addError("FormRequestDetails.Titles", "At least one Title is required.");
    } else {
        const invalidTitles = vm.formRequestDetails.titles.filter((t) => !vm.reference.titles.some((rt) => rt.id === t.id));

        if (invalidTitles.length === 0) return;
        v.addError("FormRequestDetails.Titles", "The following Titles are invalid: " + invalidTitles.map((t) => t.titleNumber).join(", "));
    }
}
