// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_SurveysAcquisitions_AcquisitionReports;

export default function* batchUpdate() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const saveResult: CallHelper.CallResult<Client.UpdateGeoscienceAustraliaSurveysAcquisitionsAcquisitionReportsResponseDto> = yield call(async () => await updateReports(vm));

        if (saveResult.IsSuccess) {
            // success
            let updatedVm = vm;
            const r = saveResult.Response!;
            if (r.statusMessages!.success?.length! > 0) {
                // Allows for the partial success
                const detailsResponse: CallHelper.CallResult<Client.GetGeoscienceAustraliaSurveysAcquisitionsAcquisitionReportsResponseDto> = yield call(async () => await getReports(vm));
                updatedVm = vm.refreshAcquisitionReports(vm.surveyId!, vm.acquisitionId!, detailsResponse.Response!);
            }
            yield put(Actions.actionFactory.sagaCompleted(updatedVm.refreshUpdateResponse(saveResult.Response!)));
        } else if (saveResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            saveResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function updateReports(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.UpdateGeoscienceAustraliaSurveysAcquisitionsAcquisitionReportsResponseDto>> {
    const request = new Client.UpdateGeoscienceAustraliaSurveysAcquisitionsAcquisitionReportsRequestDto({
        reports: vm.reports.filter((r) => r.isSelected).map((r) => new Client.UpdateGeoscienceAustraliaSurveysAcquisitionsAcquisitionReportsReportDto({ id: r.id, versionNumber: r.versionNumber })),

        updateConfidentiality: vm.batchUpdateDetails.updateConfidentiality,
        confidentiality: vm.batchUpdateDetails.confidentiality,

        updateConfidentialityRemarks: vm.batchUpdateDetails.updateConfidentialityRemarks,
        confidentialityRemarksUpdateType: vm.batchUpdateDetails.confidentialityRemarksUpdateType,
        confidentialityRemarks: vm.batchUpdateDetails.confidentialityRemarks,

        updateReceivedDate: vm.batchUpdateDetails.updateReceivedDate,
        receivedDate: vm.batchUpdateDetails.receivedDate,
        receivedDateOffset: GlobalHelpers.getDateOffsetMinutes(vm.batchUpdateDetails.receivedDate),

        updateConsignment: vm.batchUpdateDetails.updateConsignment,
        consignmentUpdateType: vm.batchUpdateDetails.consignmentUpdateType,
        consignment: vm.batchUpdateDetails.consignment,

        updateConditionStatus: vm.batchUpdateDetails.updateConditionStatus,
        conditionStatusId: vm.batchUpdateDetails.conditionStatus?.id,

        updateLoanStatus: vm.batchUpdateDetails.updateLoanStatus,
        loanStatusId: vm.batchUpdateDetails.loanStatus?.id,
    });

    const client = await ClientFactory.createGeoscienceAustraliaSurveysAcquisitionsClient();
    return await CallHelper.call(() => client.updateGeoscienceAustraliaSurveysAcquisitionsAcquisitionReports(request));
}

async function getReports(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.GetGeoscienceAustraliaSurveysAcquisitionsAcquisitionReportsResponseDto>> {
    const request = new Client.GetGeoscienceAustraliaSurveysAcquisitionsAcquisitionReportsRequestDto({
        surveyId: vm.surveyId!,
        acquisitionId: vm.acquisitionId!,
    });

    const client = await ClientFactory.createGeoscienceAustraliaSurveysAcquisitionsClient();
    return await CallHelper.call(() => client.getGeoscienceAustraliaSurveysAcquisitionsAcquisitionReports(request));
}
