// framework
import { v4 as generateToken } from "uuid";
// redux
import { call, put, select } from "redux-saga/effects";
import { ICompanyReduxState } from "../../../companyReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as ClientFactory from "../../../../api/clientFactory";
import * as CallHelper from "../../../../api/callHelper";
import * as Client from "../../../../api/Client";
// common
import * as shellHelper from "../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../common/LogHelper";

const getRootViewModel = (state: ICompanyReduxState) => state.Company_Home_Registration;

export default function* requestEmailVerification() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const actions = Actions.actionFactory;

    try {
        yield put(shellHelper.createSetBusyAction());

        const token = generateToken();

        const response: Client.RequestRegistrationEmailVerificationResponseDto = yield call(async () => {
            const client = ClientFactory.createRegistrationAnonymousRequestService();
            return await CallHelper.simpleCall(() =>
                client.requestRegistrationEmailVerification(
                    new Client.RequestRegistrationEmailVerificationRequestDto({
                        email: vm.user.newUserRequest.email!,
                        token: token,
                    })
                )
            );
        });

        yield put(
            actions.sagaCompleted(
                vm.refreshEmailVerificationUpdated({
                    status: Client.EmailVerificationStatusEnum.Unverified,
                    token: token,
                    requestEmailVerificationStatusMessages: response.statusMessages,
                    isVisible: response.statusMessages.isSuccess,
                })
            )
        );
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
