// framework
import { useState } from "react";
// redux
import * as Models from "../../../models/models";
import * as Actions from "../../../redux/actions";
// common
import SecureFileUploadControl, { IFile } from "../../../../../../../../../common/secureFileUpload/SecureFileUploadControl";
import ConfirmationView, { DefaultButtonEnum } from "../../../../../../../../../common/confirmation/ConfirmationView";

export default function UploadFileView(props: { vm: Models.IWetSignatureViewModel; actions: Actions.IActionFactory; onBusyChanged: (isBusy: boolean) => void }): React.ReactElement {
    const vm = props.vm;
    const actions = props.actions;

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [files, setFiles] = useState<Array<IFile>>(); // this is an array as the SecureFileUploadControl returns an array

    // existing files
    const existingFile = vm.wetSignatureDetails.file;

    function onFilesUploaded(files: Array<IFile>): void {
        if (files.length > 1) throw new Error("Only a single file can be uploaded.");

        setFiles(files);

        if (!existingFile) {
            actions.viewChanged(vm.onWetSigUpload(files[0]));
            return;
        }

        setShowConfirmation(true);
    }

    function onConfirmationAccepted() {
        setShowConfirmation(false);
        // submit
        actions.viewChanged(vm.onWetSigUpload(files![0]));
    }

    function onConfirmationClosed() {
        setShowConfirmation(false);
    }

    return (
        <>
            <h5>File Upload</h5>
            <SecureFileUploadControl
                className="mb-2"
                container={vm.wetSignatureDetails!.configuration!.container!}
                allowedExtensions={vm.wetSignatureDetails!.configuration!.allowedExtensions!}
                onBusyChanged={props.onBusyChanged}
                onFilesUploaded={onFilesUploaded}
                maximumFileSizeMb={vm.wetSignatureDetails!.configuration!.maximumFileSizeMb}
                isSingleFile
            ></SecureFileUploadControl>
            {showConfirmation && (
                <ConfirmationView title="Confirmation" onAccepted={onConfirmationAccepted} onClosed={onConfirmationClosed} defaultButton={DefaultButtonEnum.Cancel}>
                    <div>The original document will be overwritten with this one.</div>
                    <div>Click 'OK' to proceed or 'Cancel' to clear your selection and keep the original document.</div>
                </ConfirmationView>
            )}
        </>
    );
}
