// redux
import { call, put, select } from "redux-saga/effects";
import { IDraftApplicationDetailsReduxState } from "../../../../../draftApplicationDetails/draftApplicationDetailsReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as Client from "../../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../../common/LogHelper";
// calls
import { generateCompanyCommonDraftApplicationDetailsDraftApplicationFormDocument } from "./calls";

const getRootViewModel = (state: IDraftApplicationDetailsReduxState) => state.Company_Common_DraftApplicationDetails_Controls_FormDocumentation;

export default function* generateDocument(action: Actions.IAction<number>) {
    try {
        yield put(shellHelper.createSetBusyAction());

        const response: Client.GenerateCompanyCommonDraftApplicationDetailsDraftApplicationFormDocumentResponseDto = yield call(async () => {
            return await generateCompanyCommonDraftApplicationDetailsDraftApplicationFormDocument(action.payload);
        });

        const vm: Models.IRootViewModel = yield select(getRootViewModel);

        yield put(Actions.actionFactory.sagaCompleted(vm.refreshGeneratedDocument(response.generatedDocument)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
