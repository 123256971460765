// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_WellsBoreholes_ReportFileAssets;

export default function* update() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const saveResult: CallHelper.CallResult<Client.UpdateGeoscienceAustraliaReportFileAssetsResponseDto> = yield call(async () => await updateFileAssets(vm));

        if (saveResult.IsSuccess) {
            // success
            let updatedVm = vm;
            const r = saveResult.Response!;
            if (r.statusMessages.isSuccess) {
                const getFileAssetsResponse: CallHelper.CallResult<Client.GetGeoscienceAustraliaReportFileAssetsResponseDto> = yield call(async () => await getReportFileAssets(vm));
                updatedVm = vm.refreshFileAssets(getFileAssetsResponse.Response!);
            }
            yield put(Actions.actionFactory.sagaCompleted(updatedVm.refreshUpdateResponse(saveResult.Response!)));
        } else if (saveResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            saveResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function updateFileAssets(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.UpdateGeoscienceAustraliaReportFileAssetsResponseDto>> {
    const request = new Client.UpdateGeoscienceAustraliaReportFileAssetsRequestDto({
        reportId: vm.reportId!,
        reportVersionNumber: vm.reportVersionNumber!,
        tapeId: vm.reportFileAssets.tapeId,
        reportDataFormatId: vm.reportFileAssets.reportDataFormat?.id,
        reportMediaTypeId: vm.reportFileAssets.reportMediaType?.id,
        remarks: vm.reportFileAssets.remarks,
        reportConditionStatusId: vm.reportFileAssets.reportConditionStatus?.id,
        qcRemarks: vm.reportFileAssets.qcRemarks,
        reportCopyStatusId: vm.reportFileAssets.reportCopyStatus?.id,
        reportLoanStatusId: vm.reportFileAssets.reportLoanStatus?.id,
        rddsStatusId: vm.reportFileAssets.rddsStatus?.id,
        rddsComments: vm.reportFileAssets.rddsComments,
        reportDataStatusId: vm.reportFileAssets.reportDataStatus?.id,
        consignment: vm.reportFileAssets.consignment,
    });

    const client = await ClientFactory.createGeoscienceAustraliaReportClient();
    return await CallHelper.call(() => client.updateGeoscienceAustraliaReportFileAssets(request));
}

async function getReportFileAssets(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.GetGeoscienceAustraliaReportFileAssetsResponseDto>> {
    const request = new Client.GetGeoscienceAustraliaReportFileAssetsRequestDto({
        reportId: vm.reportId!,
    });

    const client = await ClientFactory.createGeoscienceAustraliaReportClient();
    return await CallHelper.call(() => client.getGeoscienceAustraliaReportFileAssets(request));
}
