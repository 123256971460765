// framework
import * as Validation from "../../../../common/validation/ValidationModel";
// api
// import { SecurePortalCountryDto } from "../../../../api/Client";
// models
import * as Models from "./models";

export default function validate(vm: Models.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateGeoscienceAustraliaStandardRoleRequest(vm.user.geoscienceAustraliaStandardRoleRequest, vm.user.questions, v);
    //validateGeoscienceAustraliaSignerRequest(vm.user.questions, v); todo nduja still coming
    validateGaaAuthoritySupportingDocument(vm.user.gaaAuthoritySupportingDocument, vm.user.questions, v);
    // validateJasAuthoritySupportingDocument(vm.user.csAuthoritySupportingDocument, vm.user.questions, v);
    // validateCsPoaAuthoritySupportingDocument(vm.user.csPoaSupportingDocument, vm.user.questions, v);
    validateComments(vm.user.comments, v);
    return v;
}

function validateGeoscienceAustraliaStandardRoleRequest(vm: Models.IGeoscienceAustraliaStandardRoleRequestViewModel, questions: Models.IQuestionsViewModel, v: Validation.ValidationModel): void {
    if (!questions.isGeoscienceAustraliaStandardRoleRequest) return;

    if (!vm.requestedRoles!.hasRole()) {
        // todo nduja reinstate the line below once signer is online
        //if (!questions.isGeoscienceAustraliaSignerRequest && !vm.requestedRoles!.hasRole()) {
        v.addError("GeoscienceAustraliaStandardRoleRequest", "At least one permission must be requested.");
    }

    if (vm.requestedRoles!.getDirectRoles().some((role) => vm.existingRoles!.hasRole(role))) {
        v.addError("GeoscienceAustraliaStandardRoleRequest", "Existing permissions cannot be requested.");
    }
}

// todo nduja still coming
// function validateGeoscienceAustraliaSignerRequest(questions: Models.IQuestionsViewModel, v: Validation.ValidationModel): void {
//     if (!questions.isGeoscienceAustraliaSignerRequest) return;

//     if (questions.isGeoscienceAustraliaSignerPoaRequest === undefined) v.addError("GeoscienceAustraliaSignerRequest.isCsPoaRequestGroup", "An option must be selected.");
// }

function validateSupportingDocument(validatorName: string, supportingDocument: Models.ISupportingDocumentViewModel, v: Validation.ValidationModel) {
    if (Validation.isNullOrWhiteSpace(supportingDocument.fileName) || !supportingDocument.content) {
        v.addError(validatorName, "Supporting Documentation must be supplied.");
        return;
    }

    if (Validation.isNullOrWhiteSpace(supportingDocument.fileName) || supportingDocument.fileName!.length === 0) {
        v.addError(validatorName, "Supporting Documentation file names cannot be blank.");
    }

    if (supportingDocument.fileName && supportingDocument.fileName.length > 100) {
        v.addError(validatorName, "Supporting Documentation file names must be 100 characters or less.");
    }
}

function validateGaaAuthoritySupportingDocument(gaaAuthoritySupportingDocument: Models.ISupportingDocumentViewModel, questions: Models.IQuestionsViewModel, v: Validation.ValidationModel): void {
    if (!questions.isGeoscienceAustraliaAdministratorRequest) return;

    validateSupportingDocument("GaaAuthoritySupportingDocument", gaaAuthoritySupportingDocument, v);
}

// function validateJasAuthoritySupportingDocument(jasAuthoritySupportingDocument: Models.ISupportingDocumentViewModel, questions: Models.IQuestionsViewModel, v: Validation.ValidationModel): void {
//     if (!questions.isGeoscienceAustraliaSignerRequest) return;

//     validateSupportingDocument("JasAuthoritySupportingDocument", jasAuthoritySupportingDocument, v);
// }

// function validateCsPoaAuthoritySupportingDocument(jasPoaSupportingDocument: Models.ISupportingDocumentViewModel, questions: Models.IQuestionsViewModel, v: Validation.ValidationModel): void {
//     if (!questions.isGeoscienceAustraliaSignerPoaRequest) return;

//     validateSupportingDocument("JasPoaSupportingDocument", jasPoaSupportingDocument, v);
// }

function validateComments(comments: string, v: Validation.ValidationModel): void {
    if (!comments) return;
    if (comments.length > 1000) v.addError("Comments.Comments", "Comments must be 1000 characters or less.");
}
