// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../../api/Client";

export interface IRootViewModel {
    id: string | undefined;
    titleholders: Client.GetTitleholdersItemDto[];

    viewState: ViewStateEnum;

    refreshDetails(id: string, response: Client.GetCompanyOpggsApplicationDetailsTitleholdersResponseDto): IRootViewModel;
    onInitialised(): IRootViewModel;
}

export enum ViewStateEnum {
    Initialising,
    View,
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.id = undefined;
        this.titleholders = [];

        this.viewState = ViewStateEnum.Initialising;
    }

    public id: string | undefined;
    public titleholders: Client.GetTitleholdersItemDto[];

    public viewState: ViewStateEnum;

    public refreshDetails(id: string, response: Client.GetCompanyOpggsApplicationDetailsTitleholdersResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.id = id;
        vm.titleholders = response.titleholders;

        return vm;
    }

    public onInitialised(): IRootViewModel {
        if (this.viewState !== ViewStateEnum.Initialising) throw new Error("Invalid state.");

        const vm = this._clone();
        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);

        return vm;
    }
}
