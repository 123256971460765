// dashboard
import * as DashboardReducers from "./home/dashboard/redux/reducers";
import * as DashboardModels from "./home/dashboard/models/models";
import DashboardSaga from "./home/dashboard/redux/saga";
// registration
import * as RegistrationReducers from "./registration/redux/reducers";
import * as RegistrationModels from "./registration/models/models";
import RegistrationSaga from "./registration/redux/saga";
// applications - in progress OPGGS Act application list
import * as ApplicationListReducers from "./opggs/applicationList/redux/reducers";
import * as ApplicationListModels from "./opggs/applicationList/models/models";
import ApplicationListSaga from "./opggs/applicationList/redux/saga";
// applications - in progress OPGGS Act application details
import * as applicationDetails from "./opggs/applicationDetails/applicationDetailsReduxRegistration";
import { buildSagas as applicationDetailsBuildSagas } from "./opggs/applicationDetails/applicationDetailsReduxRegistration";
// applications - application adhoc upload
import * as ApplicationAdhocUploadReducers from "./opggs/applicationAdhocUpload/redux/reducers";
import * as ApplicationAdhocUploadModels from "./opggs/applicationAdhocUpload/models/models";
import ApplicationAdhocUploadSaga from "./opggs/applicationAdhocUpload/redux/saga";
// applications - draft OPGGS Act application list
import * as DraftApplicationListReducers from "./opggs/draftApplicationList/redux/reducers";
import * as DraftApplicationListModels from "./opggs/draftApplicationList/models/models";
import DraftApplicationListSaga from "./opggs/draftApplicationList/redux/saga";
// applications - common draft Act application details
import * as commonDraftApplicationDetails from "./common/draftApplicationDetails/draftApplicationDetailsReduxRegistration";
import { buildSagas as commonDraftApplicationDetailsBuildSagas } from "./common/draftApplicationDetails/draftApplicationDetailsReduxRegistration";
// applications - draft OPGGS Act application details
import * as opggsDraftApplicationDetails from "./opggs/draftApplicationDetails/draftApplicationDetailsReduxRegistration";
import { buildSagas as opggsDraftApplicationDetailsBuildSagas } from "./opggs/draftApplicationDetails/draftApplicationDetailsReduxRegistration";
// applications - draft OEI Act application details
import * as oeiDraftApplicationDetails from "./oei/draftApplicationDetails/draftApplicationDetailsReduxRegistration";
import { buildSagas as oeiDraftApplicationDetailsBuildSagas } from "./oei/draftApplicationDetails/draftApplicationDetailsReduxRegistration";
// legislative forms list
import * as CompanyLegislativeFormsListReducers from "./opggs/legislativeFormsList/redux/reducers";
import * as CompanyLegislativeFormsListModels from "./opggs/legislativeFormsList/models/models";
import CompanyLegislativeFormsListSaga from "./opggs/legislativeFormsList/redux/saga";
// titles - list
import * as CompanyTitleListReducers from "./opggs/titleList/redux/reducers";
import * as CompanyTitleListModels from "./opggs/titleList/models/models";
import CompanyTitleListSaga from "./opggs/titleList/redux/saga";
// titles - details
import * as CompanyTitleDetailsReducers from "./opggs/titleDetails/redux/reducers";
import * as CompanyTitleDetailsModels from "./opggs/titleDetails/models/models";
import CompanyTitleDetailsSaga from "./opggs/titleDetails/redux/saga";
// titles - legislative forms - form request create
import * as CompanyOpggsLegislativeFormsFormRequestCreateReducers from "./opggs/legislativeForms/formRequestCreate/redux/reducers";
import * as CompanyOpggsLegislativeFormsFormRequestCreateModels from "./opggs/legislativeForms/formRequestCreate/models/models";
import CompanyOpggsLegislativeFormsFormRequestCreateSaga from "./opggs/legislativeForms/formRequestCreate/redux/saga";
// titles - legislative forms - form request details
import * as CompanyOpggsLegislativeFormsFormRequestDetailsReducers from "./opggs/legislativeForms/formRequestDetails/redux/reducers";
import * as CompanyOpggsLegislativeFormsFormRequestDetailsModels from "./opggs/legislativeForms/formRequestDetails/models/models";
import CompanyOpggsLegislativeFormsFormRequestDetailsSaga from "./opggs/legislativeForms/formRequestDetails/redux/saga";
// titles - legislative forms - form request details - controls - signature type
import * as CompanyOpggsLegislativeFormsFormRequestDetailsControlsSignatureTypeReducers from "./opggs/legislativeForms/formRequestDetails/controls/signatureType/redux/reducers";
import * as CompanyOpggsLegislativeFormsFormRequestDetailsControlsSignatureTypeModels from "./opggs/legislativeForms/formRequestDetails/controls/signatureType/models/models";
import CompanyOpggsLegislativeFormsFormRequestDetailsControlsSignatureTypeSaga from "./opggs/legislativeForms/formRequestDetails/controls/signatureType/redux/saga";
// titles - legislative forms - form request details - controls - form documentation
import * as CompanyOpggsLegislativeFormsFormRequestDetailsControlsFormDocumentationReducers from "./opggs/legislativeForms/formRequestDetails/controls/formDocumentation/redux/reducers";
import * as CompanyOpggsLegislativeFormsFormRequestDetailsControlsFormDocumentationModels from "./opggs/legislativeForms/formRequestDetails/controls/formDocumentation/models/models";
import CompanyOpggsLegislativeFormsFormRequestDetailsControlsFormDocumentationSaga from "./opggs/legislativeForms/formRequestDetails/controls/formDocumentation/redux/saga";
// applications - in progress OEI Act application list
import * as OeiApplicationListReducers from "./oei/oeiApplicationList/redux/reducers";
import * as OeiApplicationListModels from "./oei/oeiApplicationList/models/models";
import OeiApplicationListSaga from "./oei/oeiApplicationList/redux/saga";
// OEI - licence adhoc upload
import * as OeiAdhocUploadReducers from "./oei/oeiAdhocUpload/redux/reducers";
import * as OeiAdhocUploadModels from "./oei/oeiAdhocUpload/models/models";
import OeiAdhocUploadSaga from "./oei/oeiAdhocUpload/redux/saga";
// OEI - draft OEI Act application list
import * as OeiDraftApplicationListReducers from "./oei/oeiDraftApplicationList/redux/reducers";
import * as OeiDraftApplicationListModels from "./oei/oeiDraftApplicationList/models/models";
import OeiDraftApplicationListSaga from "./oei/oeiDraftApplicationList/redux/saga";
// OEI - invitation to apply list
import * as OeiInvitationToApplyListReducers from "./oei/invitationToApplyList/redux/reducers";
import * as OeiInvitationToApplyListModels from "./oei/invitationToApplyList/models/models";
import OeiInvitationToApplyListSaga from "./oei/invitationToApplyList/redux/saga";
// OEI - licence list
import * as CompanyLicenceListReducers from "./oei/licenceList/redux/reducers";
import * as CompanyLicenceListModels from "./oei/licenceList/models/models";
import CompanyLienceListSaga from "./oei/licenceList/redux/saga";
// OEI - licence details
import * as CompanyLicenceDetailsReducers from "./oei/licenceDetails/redux/reducers";
import * as CompanyLicenceDetailsModels from "./oei/licenceDetails/models/models";
import CompanyLienceDetailsSaga from "./oei/licenceDetails/redux/saga";
// company list
import * as CompanyListReducers from "./userManagement/companyList/redux/reducers";
import * as CompanyListModels from "./userManagement/companyList/models/models";
import CompanyListSaga from "./userManagement/companyList/redux/saga";
// company details
import * as CompanyDetailsReducers from "./userManagement/companyDetails/redux/reducers";
import * as CompanyDetailsModels from "./userManagement/companyDetails/models/rootViewModel";
import CompanyDetailsSaga from "./userManagement/companyDetails/redux/saga";
// user list
import * as UserListReducers from "./userManagement/userList/redux/reducers";
import * as UserListModels from "./userManagement/userList/models/models";
import UserListSaga from "./userManagement/userList/redux/saga";
// user details
import * as UserDetailsReducers from "./userManagement/userDetails/redux/reducers";
import * as UserDetailsModels from "./userManagement/userDetails/models/rootViewModel";
import UserDetailsSaga from "./userManagement/userDetails/redux/saga";
// request list
import * as RequestListReducers from "./userManagement/requestList/redux/reducers";
import * as RequestListModels from "./userManagement/requestList/models/rootViewModel";
import RequestListSaga from "./userManagement/requestList/redux/saga";
// request details
import * as RequestDetailsReducers from "./userManagement/requestDetails/redux/reducers";
import * as RequestDetailsModels from "./userManagement/requestDetails/models/rootViewModel";
import RequestDetailsSaga from "./userManagement/requestDetails/redux/saga";
// finance - list
import * as FinancialNotificationListReducers from "./finance/financialNotificationList/redux/reducers";
import * as FinancialNotificationListModels from "./finance/financialNotificationList/models/models";
import FinancialNotificationListSaga from "./finance/financialNotificationList/redux/saga";
// finance - details
import * as FinancialNotificationDetailsReducers from "./finance/financialNotificationDetails/redux/reducers";
import * as FinancialNotificationDetailsModels from "./finance/financialNotificationDetails/models/models";
import FinancialNotificationDetailsSaga from "./finance/financialNotificationDetails/redux/saga";
// payment history - list
import * as PaymentHistoryListReducers from "./finance/paymentHistoryList/redux/reducers";
import * as PaymentHistoryListModels from "./finance/paymentHistoryList/models/models";
import PaymentHistoryListSaga from "./finance/paymentHistoryList/redux/saga";
// payment history - details
import * as PaymentHistoryDetailsReducers from "./finance/paymentHistoryDetails/redux/reducers";
import * as PaymentHistoryDetailsModels from "./finance/paymentHistoryDetails/models/models";
import PaymentHistoryDetailsSaga from "./finance/paymentHistoryDetails/redux/saga";
// my account - request access
import * as RequestAccessReducers from "./account/requestAccess/redux/reducers";
import * as RequestAccessModels from "./account/requestAccess/models/models";
import RequestAccessSaga from "./account/requestAccess/redux/saga";
// my account - myDetails
import * as MyDetailsReducers from "./account/myDetails/redux/reducers";
import * as MyDetailsModels from "./account/myDetails/models/models";
import MyDetailsSaga from "./account/myDetails/redux/saga";

// state
export interface ICompanyReduxState
    extends applicationDetails.IApplicationDetailsReduxState,
        opggsDraftApplicationDetails.IDraftApplicationDetailsReduxState,
        oeiDraftApplicationDetails.IDraftApplicationDetailsReduxState,
        commonDraftApplicationDetails.IDraftApplicationDetailsReduxState {
    Company_Home_Dashboard: DashboardModels.IRootViewModel;
    Company_Home_Registration: RegistrationModels.IRootViewModel;
    Company_Opggs_ApplicationList: ApplicationListModels.IRootViewModel;
    Company_Opggs_ApplicationAdhocUpload: ApplicationAdhocUploadModels.IRootViewModel;
    Company_Opggs_DraftApplicationList: DraftApplicationListModels.IRootViewModel;
    Company_Opggs_TitleList: CompanyTitleListModels.IRootViewModel;
    Company_Opggs_TitleDetails: CompanyTitleDetailsModels.IRootViewModel;
    Company_Opggs_LegislativeFormsList: CompanyLegislativeFormsListModels.IRootViewModel;
    Company_Opggs_LegislativeForms_FormRequestCreate: CompanyOpggsLegislativeFormsFormRequestCreateModels.IRootViewModel;
    Company_Opggs_LegislativeForms_FormRequestDetails: CompanyOpggsLegislativeFormsFormRequestDetailsModels.IRootViewModel;
    Company_Opggs_LegislativeForms_FormRequestDetails_Controls_SignatureType: CompanyOpggsLegislativeFormsFormRequestDetailsControlsSignatureTypeModels.IRootViewModel;
    Company_Opggs_LegislativeForms_FormRequestDetails_Controls_FormDocumentation: CompanyOpggsLegislativeFormsFormRequestDetailsControlsFormDocumentationModels.IRootViewModel;
    Company_Oei_ApplicationList: OeiApplicationListModels.IRootViewModel;
    Company_Oei_OeiAdhocUpload: OeiAdhocUploadModels.IRootViewModel;
    Company_Oei_DraftApplicationList: OeiDraftApplicationListModels.IRootViewModel;
    Company_Oei_OeiInvitationToApplyList: OeiInvitationToApplyListModels.IRootViewModel;
    Company_Oei_LicenceList: CompanyLicenceListModels.IRootViewModel;
    Company_Oei_LicenceDetails: CompanyLicenceDetailsModels.IRootViewModel;
    Company_UserManagement_CompanyList: CompanyListModels.IRootViewModel;
    Company_UserManagement_CompanyDetails: CompanyDetailsModels.IRootViewModel;
    Company_UserManagement_UserList: UserListModels.IRootViewModel;
    Company_UserManagement_UserDetails: UserDetailsModels.IRootViewModel;
    Company_UserManagement_RequestList: RequestListModels.IRootViewModel;
    Company_UserManagement_RequestDetails: RequestDetailsModels.IRootViewModel;
    Company_Finance_FinancialNotificationList: FinancialNotificationListModels.IRootViewModel;
    Company_Finance_FinancialNotificationDetails: FinancialNotificationDetailsModels.IRootViewModel;
    Company_Finance_PaymentHistoryList: PaymentHistoryListModels.IRootViewModel;
    Company_Finance_PaymentHistoryDetails: PaymentHistoryDetailsModels.IRootViewModel;
    Company_Account_MyDetails: MyDetailsModels.IRootViewModel;
    Company_Account_RequestAccess: RequestAccessModels.IRootViewModel;
}

// reducers
export function buildReducers() {
    const applicationDetailsReducers = applicationDetails.buildReducers();
    const commonDraftApplicationDetailsReducers = commonDraftApplicationDetails.buildReducers();
    const opggsDraftApplicationDetailsReducers = opggsDraftApplicationDetails.buildReducers();
    const oeiDraftApplicationDetailsReducers = oeiDraftApplicationDetails.buildReducers();
    return {
        ...applicationDetailsReducers,
        ...commonDraftApplicationDetailsReducers,
        ...opggsDraftApplicationDetailsReducers,
        ...oeiDraftApplicationDetailsReducers,
        Company_Home_Dashboard: DashboardReducers.reducer,
        Company_Home_Registration: RegistrationReducers.reducer,
        Company_Opggs_ApplicationList: ApplicationListReducers.reducer,
        Company_Opggs_ApplicationAdhocUpload: ApplicationAdhocUploadReducers.reducer,
        Company_Opggs_DraftApplicationList: DraftApplicationListReducers.reducer,
        Company_Opggs_TitleList: CompanyTitleListReducers.reducer,
        Company_Opggs_TitleDetails: CompanyTitleDetailsReducers.reducer,
        Company_Opggs_LegislativeFormsList: CompanyLegislativeFormsListReducers.reducer,
        Company_Opggs_LegislativeForms_FormRequestCreate: CompanyOpggsLegislativeFormsFormRequestCreateReducers.reducer,
        Company_Opggs_LegislativeForms_FormRequestDetails: CompanyOpggsLegislativeFormsFormRequestDetailsReducers.reducer,
        Company_Opggs_LegislativeForms_FormRequestDetails_Controls_SignatureType: CompanyOpggsLegislativeFormsFormRequestDetailsControlsSignatureTypeReducers.reducer,
        Company_Opggs_LegislativeForms_FormRequestDetails_Controls_FormDocumentation: CompanyOpggsLegislativeFormsFormRequestDetailsControlsFormDocumentationReducers.reducer,
        Company_Oei_ApplicationList: OeiApplicationListReducers.reducer,
        Company_Oei_OeiAdhocUpload: OeiAdhocUploadReducers.reducer,
        Company_Oei_DraftApplicationList: OeiDraftApplicationListReducers.reducer,
        Company_Oei_OeiInvitationToApplyList: OeiInvitationToApplyListReducers.reducer,
        Company_Oei_LicenceList: CompanyLicenceListReducers.reducer,
        Company_Oei_LicenceDetails: CompanyLicenceDetailsReducers.reducer,
        Company_UserManagement_CompanyDetails: CompanyDetailsReducers.reducer,
        Company_UserManagement_CompanyList: CompanyListReducers.reducer,
        Company_UserManagement_UserList: UserListReducers.reducer,
        Company_UserManagement_UserDetails: UserDetailsReducers.reducer,
        Company_UserManagement_RequestList: RequestListReducers.reducer,
        Company_UserManagement_RequestDetails: RequestDetailsReducers.reducer,
        Company_Finance_FinancialNotificationList: FinancialNotificationListReducers.reducer,
        Company_Finance_FinancialNotificationDetails: FinancialNotificationDetailsReducers.reducer,
        Company_Finance_PaymentHistoryList: PaymentHistoryListReducers.reducer,
        Company_Finance_PaymentHistoryDetails: PaymentHistoryDetailsReducers.reducer,
        Company_Account_RequestAccess: RequestAccessReducers.reducer,
        Company_Account_MyDetails: MyDetailsReducers.reducer,
    };
}

// sagas
export function buildSagas(): Array<any> {
    const companySagas = [
        DashboardSaga,
        RegistrationSaga,
        ApplicationListSaga,
        ApplicationAdhocUploadSaga,
        DraftApplicationListSaga,
        CompanyLegislativeFormsListSaga,
        CompanyListSaga,
        CompanyDetailsSaga,
        UserListSaga,
        UserDetailsSaga,
        RequestListSaga,
        RequestDetailsSaga,
        FinancialNotificationListSaga,
        FinancialNotificationDetailsSaga,
        PaymentHistoryListSaga,
        PaymentHistoryDetailsSaga,
        CompanyTitleListSaga,
        CompanyTitleDetailsSaga,
        CompanyOpggsLegislativeFormsFormRequestCreateSaga,
        CompanyOpggsLegislativeFormsFormRequestDetailsSaga,
        CompanyOpggsLegislativeFormsFormRequestDetailsControlsSignatureTypeSaga,
        CompanyOpggsLegislativeFormsFormRequestDetailsControlsFormDocumentationSaga,
        RequestAccessSaga,
        MyDetailsSaga,
        OeiApplicationListSaga,
        OeiDraftApplicationListSaga,
        OeiAdhocUploadSaga,
        OeiInvitationToApplyListSaga,
        CompanyLienceListSaga,
        CompanyLienceDetailsSaga,
    ];

    return companySagas.concat(applicationDetailsBuildSagas(), commonDraftApplicationDetailsBuildSagas(), opggsDraftApplicationDetailsBuildSagas(), oeiDraftApplicationDetailsBuildSagas());
}
