// framework
import { useState } from "react";
// kendo
import { filterBy } from "@progress/kendo-data-query";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ComboBox, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
// redux
import * as Models from "../../models/models";
import { ActivityEditModeEnum } from "./WorkProgramGridView";
// common
import ValidationMessageView from "../../../../../../../common/validation/ValidationMessageView";
import { IValidationModel } from "../../../../../../../common/validation/ValidationModel";
import NumericTextBoxView from "../../../../../../../common/numericTextBox/NumericTextBoxView";
import * as Forms from "../../../../../../../common/forms/BootstrapForms";

export default function WorkProgramActivityEditor(props: {
    activity: Models.IActivityViewModel | undefined;
    referenceData: Models.IReferenceDataViewModel;
    editMode: ActivityEditModeEnum;
    validation: IValidationModel;
    onChange: (value: any) => void;
    onSave: () => void;
    onCancel: () => void;
}): React.ReactElement {
    const a = props.activity;
    const rd = props.referenceData;
    const v = props.validation;
    const onChange = props.onChange;

    if (!a) throw new Error("Activity not provided.");

    // activity combo filter
    const [activityData, setActivityData] = useState(rd.activityList);
    const onActivityFilterChange = (event: ComboBoxFilterChangeEvent) => {
        setActivityData(filterBy(rd.activityList, event.filter));
    };

    // year combo filter
    const [yearsData, setYearsData] = useState(rd.validYears);
    const onYearFilterChange = (event: ComboBoxFilterChangeEvent) => {
        setYearsData(filterBy(rd.validYears, event.filter));
    };

    return (
        <Dialog width={400} title={props.editMode + " Activity"} onClose={props.onCancel}>
            <Forms.Group>
                <Forms.Label htmlFor="yearField">Year</Forms.Label>
                <ComboBox
                    id="yearField"
                    valid={v.applyValidityForKendo("WorkProgram.Year")}
                    data={yearsData}
                    dataItemKey="yearTypeId"
                    textField="yearDisplay"
                    value={a.year}
                    filterable
                    onFilterChange={onYearFilterChange}
                    onChange={(e) => onChange({ year: e.value })}
                />
                <ValidationMessageView name="WorkProgram.Year" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="quantityField">Quantity</Forms.Label>
                <NumericTextBoxView
                    id="quantityField"
                    value={a.quantity}
                    valid={v.applyValidityForKendo("WorkProgram.Quantity")}
                    format="n0"
                    min={0}
                    max={1000000000000}
                    onChange={(value) => onChange({ quantity: value })}
                />
                <ValidationMessageView name="WorkProgram.Quantity" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="unitField">Unit</Forms.Label>
                <ComboBox
                    id="unitField"
                    valid={v.applyValidityForKendo("WorkProgram.Unit")}
                    data={rd.units}
                    value={a.unit}
                    dataItemKey="id"
                    textField="name"
                    onChange={(e) => onChange({ unit: e.value })}
                />
                <ValidationMessageView name="WorkProgram.Unit" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="activityField">Activity</Forms.Label>
                <ComboBox
                    id="activityField"
                    valid={v.applyValidityForKendo("WorkProgram.Activity")}
                    data={activityData}
                    value={a.activity}
                    filterable
                    onFilterChange={onActivityFilterChange}
                    onChange={(e) => onChange({ activity: e.value })}
                    onBlur={(e) => {
                        if (!a.description) onChange({ description: e.target.value ?? "" });
                    }}
                />
                <ValidationMessageView name="WorkProgram.Activity" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.TextArea
                    id="descriptionField"
                    label="Description"
                    valid={v.applyValidity("WorkProgram.Description")}
                    value={a.description}
                    rows={5}
                    maxLength={5000}
                    onChange={(value) => onChange({ description: value })}
                />
                <ValidationMessageView name="WorkProgram.Description" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="indicativeExpenditureField">Indicative Expenditure (AUD)</Forms.Label>
                <NumericTextBoxView
                    id="indicativeExpenditureField"
                    value={a.indicativeExpenditure}
                    valid={v.applyValidityForKendo("WorkProgram.IndicativeExpenditure")}
                    format="c0"
                    min={0}
                    max={100000000000000}
                    onChange={(value) => onChange({ indicativeExpenditure: value })}
                />
                <ValidationMessageView name="WorkProgram.IndicativeExpenditure" validationModel={v} />
            </Forms.Group>
            <DialogActionsBar>
                <button className="btn btn-primary m-2" style={{ width: 75 }} onClick={props.onSave}>
                    {props.editMode}
                </button>
                <button className="btn btn-primary m-2" style={{ width: 75 }} autoFocus onClick={props.onCancel}>
                    Cancel
                </button>
            </DialogActionsBar>
        </Dialog>
    );
}
