// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_ReportsAndData_SurveyDataList;

export default function* update() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const importResponse: CallHelper.CallResult<Client.CreateGeoscienceAustraliaSurveyDataResponseDto> = yield call(async () => await importSurveyData(vm));

        if (importResponse.IsSuccess) {
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshStatusMessages(importResponse.Response!.statusMessages)));
        } else {
            // all other errors
            importResponse.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function importSurveyData(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.ImportGeoscienceAustraliaSurveyDataResponseDto>> {
    const request = new Client.ImportGeoscienceAustraliaSurveyDataRequestDto({
        fileName: vm.importSurveyDataFile?.fileName,
        fileContent: vm.importSurveyDataFile?.fileContent,
    });

    const client = await ClientFactory.createGeoscienceAustraliaSurveyDataClient();
    return await CallHelper.call(() => client.importGeoscienceAustraliaSurveyData(request));
}
