// Main
import * as MainReducers from "./redux/reducers";
import * as MainModels from "./models/models";
import MainSaga from "./redux/saga";
// Application Details
import * as ApplicationDetailsReducers from "./controls/applicationDetails/redux/reducers";
import * as ApplicationDetailsModels from "./controls/applicationDetails/models/models";
import ApplicationDetailsSaga from "./controls/applicationDetails/redux/saga";
// Documentation
import * as DocumentationReducers from "./controls/documentation/redux/reducers";
import * as DocumentationModels from "./controls/documentation/models/models";
import DocumentationSaga from "./controls/documentation/redux/saga";
// Approval Tracking
import * as ApprovalTrackingReducers from "./controls/approvalTracking/redux/reducers";
import * as ApprovalTrackingModels from "./controls/approvalTracking/models/models";
import ApprovalTrackingSaga from "./controls/approvalTracking/redux/saga";
// Work Program
import * as WorkProgramReducers from "./controls/workProgram/redux/reducers";
import * as WorkProgramModels from "./controls/workProgram/models/models";
import WorkProgramSaga from "./controls/workProgram/redux/saga";
// Block Details
import * as BlockDetailsReducers from "./controls/blockDetails/redux/reducers";
import * as BlockDetailsModels from "./controls/blockDetails/models/models";
import BlockDetailsSaga from "./controls/blockDetails/redux/saga";
// Titleholders
import * as TitleholdersReducers from "./controls/titleholders/redux/reducers";
import * as TitleholdersModels from "./controls/titleholders/models/models";
import TitleholdersSaga from "./controls/titleholders/redux/saga";

// state
export interface IApplicationDetailsReduxState {
    JointAuthority_Opggs_ApplicationDetails: MainModels.IRootViewModel;
    JointAuthority_Opggs_ApplicationDetails_Controls_ApplicationDetails: ApplicationDetailsModels.IRootViewModel;
    JointAuthority_Opggs_ApplicationDetails_Controls_Documentation: DocumentationModels.IRootViewModel;
    JointAuthority_Opggs_ApplicationDetails_Controls_ApprovalTracking: ApprovalTrackingModels.IRootViewModel;
    JointAuthority_Opggs_ApplicationDetails_Controls_WorkProgram: WorkProgramModels.IRootViewModel;
    JointAuthority_Opggs_ApplicationDetails_Controls_BlockDetails: BlockDetailsModels.IRootViewModel;
    JointAuthority_Opggs_ApplicationDetails_Controls_Titleholders: TitleholdersModels.IRootViewModel;
}

// reducers
export function buildReducers() {
    return {
        JointAuthority_Opggs_ApplicationDetails: MainReducers.reducer,
        JointAuthority_Opggs_ApplicationDetails_Controls_ApplicationDetails: ApplicationDetailsReducers.reducer,
        JointAuthority_Opggs_ApplicationDetails_Controls_Documentation: DocumentationReducers.reducer,
        JointAuthority_Opggs_ApplicationDetails_Controls_ApprovalTracking: ApprovalTrackingReducers.reducer,
        JointAuthority_Opggs_ApplicationDetails_Controls_WorkProgram: WorkProgramReducers.reducer,
        JointAuthority_Opggs_ApplicationDetails_Controls_BlockDetails: BlockDetailsReducers.reducer,
        JointAuthority_Opggs_ApplicationDetails_Controls_Titleholders: TitleholdersReducers.reducer,
    };
}

// sagas
export function buildSagas(): Array<any> {
    return [MainSaga, ApplicationDetailsSaga, DocumentationSaga, ApprovalTrackingSaga, WorkProgramSaga, BlockDetailsSaga, TitleholdersSaga];
}
