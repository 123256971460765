export interface IEditableSecureUser {
    correspondenceEmailAddress?: string | undefined;
    position?: string | undefined;
    contactNumber?: string | undefined;
}
export class EditableSecureUser implements IEditableSecureUser {
    constructor() {
        this.correspondenceEmailAddress = undefined;
        this.position = undefined;
        this.contactNumber = undefined;
    }

    public correspondenceEmailAddress?: string;
    public position?: string;
    public contactNumber?: string;
}
