import { Link } from "react-router-dom";
import SimpleAlertView from "../../../../../common/simpleAlerts/SimpleAlertView";
import * as CompanyRouteHelper from "../../../../companyRouteHelper";

export default function NoAssociatedCompaniesTileView(): React.ReactElement {
    const requestAccessUrl = CompanyRouteHelper.requestAccess();

    return (
        <>
            <SimpleAlertView alertType="information" message="You currently have limited functionality as your account is not linked to any Companies." dismissable={false} />
            <span>
                You can request access via the <Link to={requestAccessUrl}>Request Additional Access</Link> screen.
            </span>
        </>
    );
}
