// redux
import * as Models from "../../models/models";
import * as Actions from "../../redux/actions";
// common
import * as Forms from "../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import ResetPasswordActionController from "../../../../../shell/signIn/ResetPasswordActionController";
import EmailLinkView from "../../../../../common/externalLink/EmailLinkView";

export default function UserDetailsView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }): React.ReactElement {
    const rootViewModel = props.vm;
    const secureUser = props.vm.secureUser;
    const actions = props.actions;

    // view
    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">JA User Details</h2>
                    <Forms.Row>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="usernameField" label="Username" value={secureUser.username} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.Element label="Email">
                                <EmailLinkView emailAddress={secureUser.emailAddress} />
                            </Forms.Element>
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="fullNameField" label="Full Name" value={secureUser.fullName} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.Element label="Correspondence Email">
                                <EmailLinkView emailAddress={secureUser.correspondenceEmailAddress} />
                            </Forms.Element>
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="positionField" label="Position" value={secureUser.position} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="contactNumberField" label="Contact Number" value={secureUser.contactNumber} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="lastLoginField" label="Last Login (AWST)" value={GlobalHelpers.toNoptaDatetimeString(secureUser.lastLoginDatetime)} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="registrationDateField" label="Registration Date (AWST)" value={GlobalHelpers.toNoptaDatetimeString(secureUser.registrationDatetime)} />
                        </Forms.HalfColumn>
                    </Forms.Row>
                    <Forms.Row>
                        <div className="mt-4">
                            <h2 className="card-title mt-4">Notification Preferences</h2>
                        </div>
                        <Forms.FullColumn>
                            <span className="ms-2">
                                {secureUser.notificationTypes!.map((nt, i) => (
                                    <Forms.ReadonlyCheckbox key={i} id={nt.name} label={nt.name} checked={nt.isSelected} inline={true} />
                                ))}
                            </span>
                        </Forms.FullColumn>
                    </Forms.Row>
                    <div className="mt-2">
                        <button
                            className="btn btn-outline-primary me-2"
                            type="button"
                            onClick={() => {
                                actions.viewChanged(rootViewModel.setEditState(true));
                            }}
                        >
                            Edit Details
                        </button>
                        <ResetPasswordActionController />
                    </div>
                </div>
            </div>
        </>
    );
}
