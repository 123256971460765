// redux
import * as Models from "../../models/models";
// common
import { ValidationModel } from "../../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../../common/validation/ValidationMessageView";
import YesNoDropDownList from "../../../../../../common/dropdowns/YesNoDropDownListView";
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as companyRouteHelper from "../../../../../companyRouteHelper";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";

export default function EpRenewalDetailsView(props: { vm: Models.IEpRenewalDetailsViewModel; v: ValidationModel; updateViewModel: (vm: Models.IEpRenewalViewModel) => void }) {
    const vm = props.vm;
    const v = props.v;

    function updateViewModel(value: boolean | undefined) {
        props.updateViewModel({ isExtensionAccepted: value });
    }
    return (
        <>
            <Forms.Group>
                <Forms.Link label="Title Number" linkText={vm.titleNumber} linkTo={vm.titleId && companyRouteHelper.titleDetails(vm.titleId)} />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="Expiry Date" id="expiryDateField" value={GlobalHelpers.toNoptaDateString(vm.titleExpiryDate)} />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="isExtensionAcceptedField">Accepted extension of time to lodge Application</Forms.Label>
                <YesNoDropDownList id="isExtensionAcceptedField" valid={v.applyValidityForKendo("EpRenewalDetails.IsExtensionAccepted")} value={vm.isExtensionAccepted} onChange={updateViewModel} />
                <ValidationMessageView name="EpRenewalDetails.IsExtensionAccepted" validationModel={v} />
            </Forms.Group>
        </>
    );
}
