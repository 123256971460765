// api
import * as Client from "../../../../api/Client";
// models
import { BaseRoleViewModel, IRoleViewModel } from "../../../../common/roleGrid/RoleViewModel";

export interface IExistingRoleViewModel extends IRoleViewModel {
    companyId: number;
    companyName?: string | undefined;
    companyAdministrators?: Client.UserContactDto[] | undefined;
    hasPendingRequest: boolean;
    lastUpdatedBy?: string | undefined;
    lastUpdatedDatetime?: Date | undefined;
}

export class ExistingRoleViewModel extends BaseRoleViewModel implements IExistingRoleViewModel {
    constructor(roleRelationships: Array<Client.GetShellSecureUserRoleConfigurationRoleRelationshipDto>, data: Client.ICompanySecureUserCompanyRoleDto) {
        super(roleRelationships, data.activeRoles);

        this.companyId = data.companyId;
        this.companyName = data.companyName;
        this.companyAdministrators = data.companyAdministrators;
        this.hasPendingRequest = data.hasPendingRequest;
        this.lastUpdatedBy = data.lastUpdatedBy;
        this.lastUpdatedDatetime = data.lastUpdatedDatetime;
    }

    public readonly companyId!: number;
    public readonly companyName?: string | undefined;
    public readonly companyAdministrators?: Client.UserContactDto[] | undefined;
    public readonly hasPendingRequest!: boolean;
    public readonly lastUpdatedBy?: string | undefined;
    public readonly lastUpdatedDatetime?: Date | undefined;

    public getKey(): number {
        return this.companyId;
    }
    public canEdit(): boolean {
        return false;
    }
}
