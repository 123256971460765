// api
import * as Client from "../../../../../../api/Client";
// other
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import TitleDetailsField from "../TitleDetailsField";
import TitleSummaryRelatedTitlesField from "./TitleSummaryRelatedTitlesField";
import TitleSummaryPriorUsageRightField from "./TitleSummaryPriorUsageRightField";

function TitleSummaryRlPpView(props: { summary: Client.GetCompanyOpggsTitleDetailsSummaryDto | undefined; areaSqKm: number | undefined }) {
    const summary = props.summary;
    const areaSqKm = props.areaSqKm;

    return (
        <>
            <TitleDetailsField label="Status">{summary?.titleStatus}</TitleDetailsField>
            <TitleDetailsField label="Offshore Area">{summary?.offshoreAreaName}</TitleDetailsField>
            <TitleDetailsField label="Basin">{summary?.basinName}</TitleDetailsField>
            <TitleDetailsField label="Number of Renewals">{GlobalHelpers.toNoptaIntegerString(summary?.numberOfRenewals)}</TitleDetailsField>
            <TitleDetailsField label="Initial Grant Date">{GlobalHelpers.toNoptaDateString(summary?.initialGrantDate)}</TitleDetailsField>
            <TitleDetailsField label="Original Expiry Date">{GlobalHelpers.toNoptaDateString(summary?.originalExpiryDate)}</TitleDetailsField>
            <TitleDetailsField label="Current Expiry Date">{GlobalHelpers.toNoptaDateString(summary?.currentExpiryDate)}</TitleDetailsField>
            <TitleDetailsField label="Number of Blocks">{GlobalHelpers.toNoptaIntegerString(summary?.numberOfBlocks)}</TitleDetailsField>
            <TitleDetailsField label="Last Renewal Date">{GlobalHelpers.toNoptaDateString(summary?.lastRenewalDate)}</TitleDetailsField>
            <TitleSummaryRelatedTitlesField label="Related Titles" relatedTitles={summary?.relatedTitleLinks} />
            <TitleDetailsField label="Field">{summary?.fieldName}</TitleDetailsField>
            <TitleDetailsField label="Field Type">{summary?.fieldType}</TitleDetailsField>
            <TitleSummaryPriorUsageRightField hasPriorUsageRight={summary?.hasPriorUsageRight} />
            <TitleDetailsField label="Area">{GlobalHelpers.toNoptaNumberString(areaSqKm, "n2", "km²")}</TitleDetailsField>
        </>
    );
}

export default TitleSummaryRlPpView;
