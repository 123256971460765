// framework
import { useEffect, useState } from "react";
// kendo
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { orderBy } from "@progress/kendo-data-query";
// redux
import * as Models from "../../models/models";
// api
import * as Client from "../../../../../../api/Client";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";

export default function TitlesListReadonlyView(props: { vm: Models.IRootViewModel }) {
    const vm = props.vm;
    const formRequestDetails = vm.formRequestDetails;

    const [titleData, setTitleData] = useState(new Array<Client.TitleInfoDto>());
    const [selectedTitles, setSelectedTitles] = useState(new Array<Client.TitleInfoDto>());

    // update title reference data if changed
    useEffect(() => {
        setTitleData(vm.reference.titles);
    }, [vm.reference.titles]);

    // update selected titles if changed
    useEffect(() => {
        const titles = formRequestDetails.titles ?? new Array<Client.TitleInfoDto>();
        setSelectedTitles(orderBy(titles, [{ field: "titleNumber", dir: "asc" }]));
    }, [formRequestDetails.titles, titleData]);

    return (
        <Forms.Group>
            <Forms.Label htmlFor="titlesField">Titles</Forms.Label>
            <MultiSelect id="titlesField" data={titleData} value={selectedTitles} dataItemKey="id" textField="titleNumber" disabled />
        </Forms.Group>
    );
}
