// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export enum ViewStateEnum {
    View = "geoscienceAustralia.wellsBoreholes.reportDetails.models.viewState.view",
    Edit = "geoscienceAustralia.wellsBoreholes.reportDetails.models.viewState.edit",
    Delete = "geoscienceAustralia.wellsBoreholes.reportDetails.models.viewState.delete",
}

export interface IReportDetails {
    itemId?: string | undefined;
    publicTitle?: string | undefined;
    reportType?: Client.GetGeoscienceAustraliaReportDetailsReferenceDataReportType | undefined;
    isCopiedToNopims?: boolean | undefined;
    reportTitle?: string | undefined;
    writtenDate?: Date | undefined;
    receivedDate?: Date | undefined;
    isConfidential?: boolean | undefined;
    confidentialityRemarks?: string | undefined;
    basicInterpType?: Client.GetGeoscienceAustraliaReportDetailsReferenceDataBasicInterpTypes | undefined;
    basicRelevantDate?: Date | undefined;
    interpRelevantDate?: Date | undefined;
    releasedDate?: Date | undefined;
    company?: Client.GetGeoscienceAustraliaReportDetailsReferenceDataContractorCompanies | undefined;
    author?: string | undefined;
    contractor?: Client.GetGeoscienceAustraliaReportDetailsReferenceDataContractorCompanies | undefined;
}

export interface IReferenceData {
    reportTypes: Client.GetGeoscienceAustraliaReportDetailsReferenceDataReportType[];
    basicInterpTypes: Client.GetGeoscienceAustraliaReportDetailsReferenceDataBasicInterpTypes[];
    contractorCompanies: Client.GetGeoscienceAustraliaReportDetailsReferenceDataContractorCompanies[];
}

export interface IRootViewModel {
    reportId: number | undefined;
    reportVersionNumber: number | undefined;
    reportHeader: string | undefined;

    reportDetails: IReportDetails;
    referenceData: IReferenceData;

    auditDto?: Client.SimpleAuditEventsDto;

    statusMessages?: Client.StatusMessagesDto;

    isDirty: boolean;
    isConflict: boolean;
    viewState: ViewStateEnum;

    refreshDetails(response: Client.GetGeoscienceAustraliaReportDetailsResponseDto): IRootViewModel;
    refreshReferenceData(response: Client.GetGeoscienceAustraliaReportDetailsReferenceDataResponseDto): IRootViewModel;
    refreshUpdateResponse(response: Client.UpdateGeoscienceAustraliaReportDetailsResponseDto): IRootViewModel;
    refreshDeleteResponse(response: Client.DeleteGeoscienceAustraliaReportResponseDto): IRootViewModel;
    refreshConflict(): IRootViewModel;

    onEditState(): IRootViewModel;

    onReportDetailsChanged(reportDetails: IReportDetails): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.reportId = undefined;
        this.reportVersionNumber = undefined;
        this.reportHeader = undefined;

        this.reportDetails = {};
        this.referenceData = {
            reportTypes: [],
            basicInterpTypes: [],
            contractorCompanies: [],
        };

        this.isDirty = false;
        this.isConflict = false;
        this.viewState = ViewStateEnum.View;
    }

    public reportId: number | undefined;
    public reportVersionNumber: number | undefined;
    public reportHeader: string | undefined;

    public reportDetails: IReportDetails;
    public referenceData: IReferenceData;

    public auditDto?: Client.SimpleAuditEventsDto;

    public statusMessages?: Client.StatusMessagesDto;

    public isDirty: boolean;
    public isConflict: boolean;
    public viewState: ViewStateEnum;

    public refreshDetails(response: Client.GetGeoscienceAustraliaReportDetailsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.reportId = response.reportId;
        vm.reportVersionNumber = response.reportVersionNumber;
        vm.reportHeader = response.reportHeader;

        vm.reportDetails = {
            itemId: response.itemId,
            publicTitle: response.publicTitle,
            reportType: vm.referenceData.reportTypes.find((rt) => rt.id === response.reportTypeId),
            isCopiedToNopims: response.isCopiedToNopims,
            reportTitle: response.reportTitle,
            writtenDate: response.writtenDate,
            receivedDate: response.receivedDate,
            isConfidential: response.isConfidential,
            confidentialityRemarks: response.confidentialityRemarks,
            basicInterpType: vm.referenceData.basicInterpTypes.find((t) => t.value === response.basicInterpType)!,
            basicRelevantDate: response.basicRelevantDate,
            interpRelevantDate: response.interpRelevantDate,
            releasedDate: response.releasedDate,
            company: vm.referenceData.contractorCompanies.find((c) => c.id === response.companyId)!,
            author: response.author,
            contractor: vm.referenceData.contractorCompanies.find((c) => c.id === response.contractorId)!,
        };

        vm.statusMessages = undefined;

        vm.isDirty = false;
        vm.isConflict = false;
        vm.auditDto = response.auditDto;

        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    public refreshReferenceData(response: Client.GetGeoscienceAustraliaReportDetailsReferenceDataResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.referenceData = {
            reportTypes: response.reportTypes,
            basicInterpTypes: response.basicInterpTypes,
            contractorCompanies: response.contractorCompanies,
        };
        return vm;
    }

    public refreshUpdateResponse(response: Client.UpdateGeoscienceAustraliaReportDetailsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = response.statusMessages;
        return vm;
    }

    public refreshDeleteResponse(response: Client.DeleteGeoscienceAustraliaReportResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = response.statusMessages;

        if (response.statusMessages?.isSuccess) {
            vm.viewState = ViewStateEnum.Delete;
        }
        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        return vm;
    }

    public onEditState(): IRootViewModel {
        const vm = this._clone();
        vm.viewState = ViewStateEnum.Edit;
        vm.statusMessages = undefined;
        return vm;
    }

    public onReportDetailsChanged(reportDetails: IReportDetails): IRootViewModel {
        const vm = this._clone();
        vm.reportDetails = reportDetails;
        vm.isDirty = true;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.reportDetails = clone(this.reportDetails);
        vm.referenceData = clone(this.referenceData);
        return vm;
    }
}
