//react
import { Link } from "react-router-dom";
// kendo
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
// api
import * as Client from "../../../../../../../api/Client";
// common
import * as Forms from "../../../../../../../common/forms/BootstrapForms";
import CustomGridCell from "../../../../../../../common/kendoGrid/CustomGridCell";
import * as geoscienceAustraliaRouteHelper from "../../../../../../geoscienceAustraliaRouteHelper";

interface IProps {
    activityType?: Client.AuthorisationActivityTypeEnum;
    category: Client.GetGeoscienceAustraliaAuthorisationsAuthorisationDetailsResponseCategoryDetailsDto;
    canViewBoreholes: boolean;
    canViewAcquisitions: boolean;
    canViewProjects: boolean;
    canViewDataSubmissions: boolean;
}

export default function DataMonitoringItemsCategoryView(props: IProps): JSX.Element {
    const { activityType, category, canViewBoreholes, canViewAcquisitions, canViewProjects, canViewDataSubmissions } = props;

    return (
        <Forms.Row>
            <Forms.FullColumn>
                <div className="mt-2">
                    <Forms.Label htmlFor={"dataMonitoringItemSelectionField"}>Data Monitoring Item(s)</Forms.Label>
                    <br />
                    <Grid dataItemKey={"id"} data={category.dataMonitoringItems}>
                        <GridNoRecords>There are currently no Data Monitoring Items for this Category.</GridNoRecords>
                        <GridColumn
                            field="activity"
                            title="Activity"
                            cell={(props) => (
                                <CustomGridCell gridCellProps={props}>
                                    {activityType === Client.AuthorisationActivityTypeEnum.Borehole && props.dataItem.activityType === Client.NopimsActivityTypeEnum.Borehole && (
                                        <div>
                                            {canViewBoreholes && props.dataItem.boreholeDetails ? (
                                                <div>
                                                    <Link
                                                        to={geoscienceAustraliaRouteHelper.boreholeDataMonitoring(props.dataItem.boreholeDetails.wellId, props.dataItem.boreholeDetails.boreholeId)}
                                                        title="Click to view Borehole Data Monitoring Items."
                                                    >
                                                        {props.dataItem.boreholeDetails.boreholeName}
                                                    </Link>
                                                </div>
                                            ) : (
                                                <p className="form-label">{props.dataItem.boreholeDetails.boreholeName}</p>
                                            )}
                                        </div>
                                    )}
                                    {activityType === Client.AuthorisationActivityTypeEnum.AcquisitionProject && props.dataItem.activityType === Client.NopimsActivityTypeEnum.Acquisition && (
                                        <div>
                                            {canViewAcquisitions && props.dataItem.acquisitionDetails ? (
                                                <div>
                                                    <Link
                                                        to={geoscienceAustraliaRouteHelper.acquisitionDataMonitoring(
                                                            props.dataItem.acquisitionDetails.surveyId,
                                                            props.dataItem.acquisitionDetails.acquisitionId
                                                        )}
                                                        title="Click to view Acquisition Details."
                                                    >
                                                        {props.dataItem.acquisitionDetails.acquisitionName}
                                                    </Link>
                                                </div>
                                            ) : (
                                                <p className="form-label">{props.dataItem.acquisitionDetails.acquisitionName}</p>
                                            )}
                                        </div>
                                    )}
                                    {activityType === Client.AuthorisationActivityTypeEnum.AcquisitionProject && props.dataItem.activityType === Client.NopimsActivityTypeEnum.Project && (
                                        <div>
                                            {canViewProjects && props.dataItem.projectDetails ? (
                                                <div>
                                                    <Link
                                                        to={geoscienceAustraliaRouteHelper.reprocessingProjectDataMonitoring(
                                                            props.dataItem.projectDetails.reprocessingId,
                                                            props.dataItem.projectDetails.projectId
                                                        )}
                                                        title="Click to view Project Details."
                                                    >
                                                        {props.dataItem.projectDetails.projectName}
                                                    </Link>
                                                </div>
                                            ) : (
                                                <p className="form-label">{props.dataItem.projectDetails.projectName}</p>
                                            )}
                                        </div>
                                    )}
                                </CustomGridCell>
                            )}
                            filter="text"
                            width={400}
                        />
                        <GridColumn
                            field="dataMonitoringItemIdentifier"
                            title="Identifier"
                            width={180}
                            cell={(props) => (
                                <CustomGridCell gridCellProps={props}>
                                    {activityType === Client.AuthorisationActivityTypeEnum.Borehole &&
                                    props.dataItem.activityType === Client.NopimsActivityTypeEnum.Borehole &&
                                    props.dataItem.boreholeDetails &&
                                    canViewDataSubmissions ? (
                                        <Link
                                            to={geoscienceAustraliaRouteHelper.wellDataMonitoringDetails(props.dataItem.boreholeDetails.wellId, props.dataItem.dataMonitoringItemId)}
                                            title="Click to view Data Monitoring Item."
                                        >
                                            {props.dataItem.dataMonitoringItemIdentifier}
                                        </Link>
                                    ) : activityType === Client.AuthorisationActivityTypeEnum.AcquisitionProject &&
                                      props.dataItem.activityType === Client.NopimsActivityTypeEnum.Acquisition &&
                                      props.dataItem.acquisitionDetails &&
                                      canViewDataSubmissions ? (
                                        <Link
                                            to={geoscienceAustraliaRouteHelper.surveyDataMonitoringDetails(props.dataItem.acquisitionDetails.surveyId, props.dataItem.dataMonitoringItemId)}
                                            title="Click to view Data Monitoring Item."
                                        >
                                            {props.dataItem.dataMonitoringItemIdentifier}
                                        </Link>
                                    ) : activityType === Client.AuthorisationActivityTypeEnum.AcquisitionProject &&
                                      props.dataItem.activityType === Client.NopimsActivityTypeEnum.Project &&
                                      props.dataItem.projectDetails &&
                                      canViewDataSubmissions ? (
                                        <Link
                                            to={geoscienceAustraliaRouteHelper.reprocessingDataMonitoringDetails(props.dataItem.projectDetails.reprocessingId, props.dataItem.dataMonitoringItemId)}
                                            title="Click to view Data Monitoring Item."
                                        >
                                            {props.dataItem.dataMonitoringItemIdentifier}
                                        </Link>
                                    ) : (
                                        props.dataItem.dataMonitoringItemIdentifier
                                    )}
                                </CustomGridCell>
                            )}
                        />
                        <GridColumn field="dataMonitoringItemTypeName" title="Name" width={300} />
                        <GridColumn field="comments" title="Comments" />
                    </Grid>
                </div>
            </Forms.FullColumn>
        </Forms.Row>
    );
}
