// framework
import { useState } from "react";
// kendo
import { Grid, GridColumn, GridSelectionChangeEvent, GridHeaderSelectionChangeEvent } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// redux
import { IFileViewModel } from "../../models/models";
// common
import CustomGridCell from "../../../../../../../../common/kendoGrid/CustomGridCell";
import SecureFileDownloadLinkControl from "../../../../../../../../common/secureFileDownload/SecureFileDownloadLinkControl";
import * as GlobalHelpers from "../../../../../../../../common/GlobalHelpers";

type FilesGridViewProps = {
    files: IFileViewModel[];
    updateSelection: (fileId: number) => void;
    updateHeaderSelection: (isChecked: boolean) => void;
};

export default function FilesGridView(props: FilesGridViewProps): React.ReactElement {
    const { files, updateSelection, updateHeaderSelection } = props;

    const initialGridState = {
        sort: [{ field: "fileName", dir: "asc" }],
        skip: 0,
        take: 10,
    } as State;
    const [gridState, setGridState] = useState(initialGridState);

    function onSelectionChange(e: GridSelectionChangeEvent): void {
        const gridItem = e.dataItem as IFileViewModel;
        if (!gridItem) return;
        updateSelection(gridItem.fileId);
    }

    function onHeaderSelectionChange(e: GridHeaderSelectionChangeEvent): void {
        const headerCheckbox = e.syntheticEvent.target as HTMLInputElement;
        updateHeaderSelection(headerCheckbox.checked);
    }

    return (
        <>
            <Grid
                className="mb-2"
                pageable
                sortable
                resizable
                navigatable
                selectedField="isSelected"
                onSelectionChange={onSelectionChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
                pageSize={10}
                data={process(files, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn field="isSelected" title="Select" width={100} headerSelectionValue={files.length > 0 && files.every((f) => f.isSelected)} />
                <GridColumn
                    field="fileName"
                    title="File Name"
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <SecureFileDownloadLinkControl id={props.dataItem.fileId}>{props.dataItem.fileName}</SecureFileDownloadLinkControl>
                        </CustomGridCell>
                    )}
                    width={750}
                />
                <GridColumn
                    field="fileSizeMb"
                    title="File Size"
                    width={150}
                    cell={(props) => <CustomGridCell gridCellProps={props}>{GlobalHelpers.convertMegabytesToString(props.dataItem.fileSizeMb)}</CustomGridCell>}
                />
                <GridColumn field="uploadedDatetime" title="Updated (AWST)" width={200} format={GlobalHelpers.NoptaDatetimeFormatForKendo} />
            </Grid>
        </>
    );
}
