import { takeEvery } from "redux-saga/effects";
import * as Actions from "./actions";

import initialise from "./sagas/initialise";
import load from "./sagas/load";
import update from "./sagas/update";
import deleteSurveyData from "./sagas/delete";

export default function* watcherSaga() {
    yield takeEvery(Actions.actions.initialiseRequested, initialise);
    yield takeEvery(Actions.actions.loadRequested, load);
    yield takeEvery(Actions.actions.updateRequested, update);
    yield takeEvery(Actions.actions.deleteRequested, deleteSurveyData);
}
