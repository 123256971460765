// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
// common
import * as Forms from "../../../../../common/forms/BootstrapForms";

export function RequestSubmittedView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const actions = props.actions;
    const secureRequestGroupId = vm.service.id;

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <Forms.Group>
                        <p>Your request has been submitted (Reference: {secureRequestGroupId?.code}).</p>
                    </Forms.Group>
                </div>
            </div>
            <button className="btn btn-outline-primary" type="button" onClick={() => actions.initialiseRequested()}>
                Request Additional Access
            </button>
        </>
    );
}
