// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
// common
import ConflictAlertView from "../../../../common/alerts/ConflictAlertView";
import SimpleAuditEventsView from "../../../../common/audit/SimpleAuditEventsView";
import StatusMessagesAlertsView from "../../../../common/alerts/StatusMessagesAlertsView";
// models
import { ViewStateEnum } from "../models/models";
// views
import NavBar, { TabsEnum } from "./views/NavBarView";
import RequestDetailsView from "./views/RequestDetailsView";
import ContactDetailsView from "./views/ContactDetailsView";
import FeedbackResponseReadOnlyView from "./views/FeedbackResponseReadOnlyView";
import FeedbackResponseEditView from "./views/FeedbackResponseEditView";

interface IRouteParameters {
    feedbackId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { feedbackId } = params;

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(feedbackId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {vm.identifier && (
                <BodyLayout
                    title={`Feedback - ${vm.identifier}`}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_FeedbackManagement_ViewFeedback}
                    onReloadRequested={() => {
                        props.initialiseRequested(Number(feedbackId));
                    }}
                >
                    <NavBar feedbackId={Number(feedbackId)} activeNavItem={TabsEnum.Details} />
                    <ConflictAlertView isConflict={vm.isConflict} />
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
                    <RequestDetailsView vm={vm.requestDetails} />
                    <ContactDetailsView vm={vm.contactDetails} />
                    {vm.viewState !== ViewStateEnum.EditFeedbackResponse && <FeedbackResponseReadOnlyView vm={vm} actions={actions} />}
                    {vm.viewState === ViewStateEnum.EditFeedbackResponse && <FeedbackResponseEditView vm={vm} actions={actions} />}
                    <SimpleAuditEventsView simpleAuditEvents={vm.audit} />
                </BodyLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_Feedback_FeedbackDetails }), Actions.actionFactory)(Controller);
