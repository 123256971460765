// models
import { BaseRoleViewModel, RoleCategoryEnum, IRoleViewModel } from "../../../../common/roleGrid/RoleViewModel";
// api
import * as Client from "../../../../api/Client";

export interface IRequestDetailsRoleViewModel extends IRoleViewModel {
    category: RoleCategoryEnum;
}

export class RequestDetailsRoleViewModel extends BaseRoleViewModel implements IRequestDetailsRoleViewModel {
    constructor(roleRelationships: Array<Client.GetShellSecureUserRoleConfigurationRoleRelationshipDto>, category: RoleCategoryEnum, roles?: Array<Client.SecureRoleEnum>) {
        super(roleRelationships, roles);
        this.category = category;
    }

    public readonly category: RoleCategoryEnum;

    public getKey(): RoleCategoryEnum {
        return this.category;
    }
    public canEdit(): boolean {
        return this.category === RoleCategoryEnum.Granted;
    }
}
