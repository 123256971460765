// redux
import * as Models from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";

export default function FormTypeReadonlyView(props: { vm: Models.IFormRequestDetailsViewModel }) {
    const vm = props.vm;

    return (
        <div className="mb-4">
            <Forms.Row>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField label="Legislative Form" id="formTypeField" value={vm.formTypeName} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>{vm.formTypeId !== 3 && <Forms.ReadonlyField label="Title" id="titleField" value={vm.titleNumber} />}</Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField label={vm.titleholderDisplayName!} id="titleholderField" value={vm.titleholder} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField label="Status" id="formStateField" value={vm.formStateName} />
                </Forms.HalfColumn>
            </Forms.Row>
        </div>
    );
}
