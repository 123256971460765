// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../api/Client";
// common
import * as SecureFileUploadControl from "../../../../common/secureFileUpload/SecureFileUploadControl";

export interface IFileViewModel {
    fileId: number;
    fileName: string;
    fileSize: number;
    fileSizeMb: number;
    isSelected: boolean;
}

export interface IRootViewModel {
    configuration: Client.GetCompanyOeiOeiAdhocUploadDetailsConfigurationDto | undefined;
    applicantCompanies: Array<Client.ICompanyApplicationApplicantDto> | undefined;
    files: Array<IFileViewModel>;
    comments: string;
    applicantCompany: Client.ICompanyApplicationApplicantDto | undefined;

    isDirty: boolean;
    isSubmitted: boolean;
    statusMessages: Client.StatusMessagesDto | undefined;
    numberOfFilesSelected: number;

    refreshDetails(response: Client.GetCompanyOeiOeiAdhocUploadDetailsResponseDto): IRootViewModel;
    refreshCreateStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel;
    onUpload(files: Array<SecureFileUploadControl.IFile>): IRootViewModel;
    onDelete(): IRootViewModel;
    onCommentsChanged(comments: string | undefined): IRootViewModel;
    onApplicantChanged(applicantCompany: Client.ICompanyApplicationApplicantDto | undefined): IRootViewModel;
    onFilesSelected(isSelected: boolean): IRootViewModel;
    onFileSelected(fileId: number): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.configuration = undefined;
        this.applicantCompanies = new Array<Client.ICompanyApplicationApplicantDto>();
        this.files = new Array<IFileViewModel>();
        this.comments = "";
        this.applicantCompany = undefined;
        this.isDirty = false;
        this.isSubmitted = false;
        this.statusMessages = undefined;
        this.numberOfFilesSelected = 0;
    }

    public configuration: Client.GetCompanyOeiOeiAdhocUploadDetailsConfigurationDto | undefined;
    public applicantCompanies: Array<Client.ICompanyApplicationApplicantDto>;
    public files: Array<IFileViewModel>;
    public comments: string;
    public applicantCompany: Client.ICompanyApplicationApplicantDto | undefined;

    public isDirty: boolean;
    public isSubmitted: boolean;
    public statusMessages: Client.StatusMessagesDto | undefined;
    public numberOfFilesSelected: number;

    public refreshDetails(response: Client.GetCompanyOeiOeiAdhocUploadDetailsResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.configuration = response.configuration;
        vm.applicantCompanies = response.applicants;

        this._setCalculatedFields(vm);
        return vm;
    }

    public refreshCreateStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel {
        const vm = this._clone();

        vm.statusMessages = statusMessages;
        if (statusMessages.isSuccess) {
            vm.isSubmitted = true;
            vm.isDirty = false;
        }

        this._setCalculatedFields(vm);
        return vm;
    }

    public onUpload(files: Array<SecureFileUploadControl.IFile>): IRootViewModel {
        const vm = this._clone();
        vm.isDirty = true;

        vm.files = clone(vm.files);
        for (const f of files) {
            vm.files.push(new FileViewModel(f.id, f.fileName, f.size, false));
        }
        this._setCalculatedFields(vm);
        return vm;
    }

    public onDelete(): IRootViewModel {
        const vm = this._clone();
        vm.isDirty = true;

        vm.files = this.files
            .filter((f) => !f.isSelected)
            .map((f) => {
                return new FileViewModel(f.fileId, f.fileName, f.fileSize, false);
            });

        this._setCalculatedFields(vm);
        return vm;
    }

    public onCommentsChanged(comments: string): IRootViewModel {
        const vm = this._clone();
        vm.isDirty = true;

        vm.comments = comments;

        this._setCalculatedFields(vm);
        return vm;
    }

    public onApplicantChanged(applicantCompany: Client.ICompanyApplicationApplicantDto | undefined): IRootViewModel {
        const vm = this._clone();
        vm.isDirty = true;

        vm.applicantCompany = applicantCompany;

        this._setCalculatedFields(vm);
        return vm;
    }

    public onFileSelected(fileId: number): IRootViewModel {
        // does not trigger dirty state
        const vm = this._clone();

        vm.files = this.files.map((f) => {
            return new FileViewModel(f.fileId, f.fileName, f.fileSize, f.fileId === fileId ? !f.isSelected : f.isSelected); // toggles the selection
        });

        this._setCalculatedFields(vm);
        return vm;
    }

    public onFilesSelected(isSelected: boolean): IRootViewModel {
        // does not trigger dirty state
        const vm = this._clone();

        vm.files = this.files.map((f) => {
            return new FileViewModel(f.fileId, f.fileName, f.fileSize, isSelected); // sets all to a specific value
        });

        this._setCalculatedFields(vm);
        return vm;
    }

    private _setCalculatedFields(vm: RootViewModel) {
        vm.numberOfFilesSelected = vm.files.filter((f) => f.isSelected).length;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}

class FileViewModel implements IFileViewModel {
    fileId: number;
    fileName: string;
    fileSize: number;
    fileSizeMb: number;
    isSelected: boolean;

    constructor(fileId: number, fileName: string, fileSize: number, isSelected: boolean) {
        this.fileId = fileId;
        this.fileName = fileName;
        this.fileSize = fileSize;
        this.fileSizeMb = fileSize / 1024 / 1024;
        this.isSelected = isSelected;
    }
}
