// framework
import * as Validation from "../../../../../../common/validation/ValidationModel";
// redux
import * as Models from "./models";

export default function validate(vm: Models.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    // validateTitleholderForeignInvestmentApprovalTypes(vm, v);
    // leaving this stub here -- no longer needed as anyone can save this panel at any stage of completion. want it to work through UAT before i rip
    // out all of the validation
    return v;
}

// function validateTitleholderForeignInvestmentApprovalTypes(vm: Models.IRootViewModel, v: Validation.ValidationModel): void {
//     // make sure each company has a corresponding foreign investment approval type set
//     vm.titleholderForeignInvestmentTypeApprovals.forEach((a) => {
//         const errorName = `TitleholderForeignInvestment.Titleholder${a.companyId}`;
//         if (!a.titleholderForeignInvestmentApproval?.titleholderForeignInvestmentApprovalType) {
//             v.addError(errorName, "A selection is required.");
//         }
//     });
// }
