// layout
import * as LayoutReducers from "./layout/redux/reducers";
import * as LayoutModel from "./layout/models/rootViewModel";
import LayoutSaga from "./layout/redux/saga";

// configuration
import * as ConfigurationReducers from "./configuration/redux/reducers";
import * as ConfigurationModels from "./configuration/models/models";
import ConfigurationSaga from "./configuration/redux/saga";

// open
import * as OpenReducers from "./open/redux/reducers";
import * as OpenModels from "./open/models/models";
import OpenSaga from "./open/redux/saga";

// sign-in
import * as SignInReducers from "./signIn/redux/reducers";
import * as SignInModels from "./signIn/models/models";
import SignInSaga from "./signIn/redux/saga";

// state
export interface IShellReduxState {
    toastr: any;
    Shell_Layout: LayoutModel.IRootViewModel;
    Shell_Configuration: ConfigurationModels.IConfigurationModel;
    Shell_Open: OpenModels.IOpenViewModel;
    Shell_SignIn: SignInModels.IRootViewModel;
}

// reducers
export function buildReducers() {
    return {
        Shell_Layout: LayoutReducers.reducer,
        Shell_Configuration: ConfigurationReducers.reducer,
        Shell_Open: OpenReducers.reducer,
        Shell_SignIn: SignInReducers.reducer,
    };
}

// sagas
export function buildSagas(): Array<any> {
    return [LayoutSaga, ConfigurationSaga, OpenSaga, SignInSaga];
}
