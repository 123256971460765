// api
import * as Client from "../../../../../../api/Client";
// redux
import * as Models from "../../../models/models";
// children views
import TitleSummaryEpView from "./TitleSummaryEpView";
import TitleSummaryRlPpView from "./TitleSummaryRlPpView";
import TitleSummaryIlView from "./TitleSummaryIlView";
import TitleSummaryPlView from "./TitleSummaryPlView";
import TitleSummaryAaSpaView from "./TitleSummaryAaSpaView";
import TitleSummaryGhgApView from "./TitleSummaryGhgApView";
import TitleMapView from "../TitleMapView";

function TitleSummaryView(props: { vm: Models.IRootViewModel }) {
    const vm = props.vm;
    const summary = vm.details?.summary;
    const titleType = vm.details?.titleType;
    const mapResponse = vm.map;
    const areaSqKm = mapResponse?.areaSqKm;

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Title Summary</h2>
                    <div className="d-flex flex-row flex-wrap">
                        {titleType &&
                            (() => {
                                switch (titleType) {
                                    case Client.TitleTypeEnum.ExplorationPermit:
                                        return <TitleSummaryEpView summary={summary} areaSqKm={areaSqKm} />;
                                    case Client.TitleTypeEnum.RetentionLease:
                                    case Client.TitleTypeEnum.ProductionLicence:
                                        return <TitleSummaryRlPpView summary={summary} areaSqKm={areaSqKm} />;
                                    case Client.TitleTypeEnum.InfrastructureLicence:
                                        return <TitleSummaryIlView summary={summary} areaSqKm={areaSqKm} />;
                                    case Client.TitleTypeEnum.PipelineLicence:
                                        return <TitleSummaryPlView summary={summary} />;
                                    case Client.TitleTypeEnum.AccessAuthority:
                                    case Client.TitleTypeEnum.SpecialProspectingAuthority:
                                        return <TitleSummaryAaSpaView summary={summary} areaSqKm={areaSqKm} />;
                                    case Client.TitleTypeEnum.GreenhouseGasAssessmentPermit:
                                        return <TitleSummaryGhgApView summary={summary} areaSqKm={areaSqKm} />;
                                    default:
                                        throw new Error(`Unsupported Title Type '${titleType}'.`);
                                }
                            })()}
                    </div>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Title Map</h2>
                    <TitleMapView response={mapResponse} />
                </div>
            </div>
        </>
    );
}

export default TitleSummaryView;
