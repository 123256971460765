import * as ClientFactory from "../../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";
import * as Models from "../../models/models";

export async function getCompanyOeiDraftApplicationDetailsApplicant(id: number): Promise<Client.GetCompanyOeiDraftApplicationDetailsApplicantResponseDto> {
    const client = await ClientFactory.createCompanyOeiDraftApplicationDetailsClient();
    return await CallHelper.simpleCall(() => client.getCompanyOeiDraftApplicationDetailsApplicant(new Client.GetCompanyOeiDraftApplicationDetailsApplicantRequestDto({ id: id })));
}

export async function saveCompanyOeiDraftApplicationDetailsApplicant(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.SaveCompanyOeiDraftApplicationDetailsApplicantResponseDto>> {
    const client = await ClientFactory.createCompanyOeiDraftApplicationDetailsClient();

    return await CallHelper.call(() =>
        client.saveCompanyOeiDraftApplicationDetailsApplicant(
            new Client.SaveCompanyOeiDraftApplicationDetailsApplicantRequestDto({
                id: vm.id!,
                versionNumber: vm.versionNumber!,
                foreignInvestmentApprovalType: vm.applicantDetails.foreignInvestmentApprovalType!.foreignInvestmentApprovalType,
                isApplicantEligibleUnderSection8: vm.applicantDetails.isApplicantEligibleUnderSection8,
            })
        )
    );
}
