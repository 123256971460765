import * as Client from "../../../../../../../api/Client";
import * as Models from "../models/models";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "geoscienceAustralia.reportsAndData.attachmentList.bulkDelete.initialiseRequested",
    bulkDeleteRequested = "geoscienceAustralia.reportsAndData.attachmentList.bulkDelete.bulkDeleteRequested",
    clearRequested = "geoscienceAustralia.reportsAndData.attachmentList.bulkDelete.clearRequested",
    // user input
    viewChanged = "geoscienceAustralia.reportsAndData.attachmentList.bulkDelete.viewChanged",
    // saga events
    sagaCompleted = "geoscienceAustralia.reportsAndData.attachmentList.bulkDelete.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

export interface IDeleteRequestedPayload {
    batchItem: Client.GetDeleteGeoscienceAustraliaAttachmentsImportReferenceDataAttachmentBatchItem;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(): IAction<undefined>;
    bulkDeleteRequested(batchItem: Client.GetDeleteGeoscienceAustraliaAttachmentsImportReferenceDataAttachmentBatchItem): IAction<IDeleteRequestedPayload>;
    clearRequested(): IAction<undefined>;
    // user input
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    // saga events
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (): IAction<undefined> {
        return {
            type: actions.initialiseRequested,
            payload: undefined,
        };
    },
    bulkDeleteRequested: function (batchItem: Client.GetDeleteGeoscienceAustraliaAttachmentsImportReferenceDataAttachmentBatchItem): IAction<IDeleteRequestedPayload> {
        return {
            type: actions.bulkDeleteRequested,
            payload: { batchItem: batchItem },
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
