// react
import { useState } from "react";
// framework
import DirtyScope from "../../../../../common/shell/DirtyScopeController";
import { Alert } from "react-bootstrap";
import { InfoIcon } from "@primer/octicons-react";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
import validator from "../../models/validator";
import * as EditableSecureUser from "../../models/editableSecureUserViewModel";
// common
import { ValidationVisibilityEnum } from "../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
import * as Forms from "../../../../../common/forms/BootstrapForms";
import * as toastHelper from "../../../../../common/toastHelper";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
import EmailLinkView from "../../../../../common/externalLink/EmailLinkView";

export default function MyDetailsEditView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }): React.ReactElement {
    let vm = props.vm;
    let secureUser = vm.secureUser;
    let actions = props.actions;

    const [state, setState] = useState<EditableSecureUser.IEditableSecureUser>({
        correspondenceEmailAddress: secureUser.correspondenceEmailAddress,
        position: secureUser.position,
        contactNumber: secureUser.contactNumber,
    });
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const v = validator(vm, validationVisibility);

    function onChanged(values: any) {
        setState({ ...state, ...values });
    }

    function updateViewModel() {
        actions.viewChanged(vm.refreshEditableDetails({ ...state }));
    }

    function onSubmitClicked(e: any) {
        e.preventDefault();
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.None);
        actions.saveMyDetailsRequested(vm);
    }

    function onCancelClicked(e: any) {
        e.preventDefault();
        actions.viewChanged(vm.setEditState(false));
    }

    return (
        <>
            <DirtyScope scopeName="geoscienceAustralia/account/myDetails" isDirty={vm.isDirty}>
                <form onSubmit={onSubmitClicked}>
                    <div className="card mb-2">
                        <div className="card-body">
                            <h2 className="card-title">Geoscience Australia User Details</h2>
                            <div className="my-2">
                                <Alert variant="info" className="mb-2">
                                    <span className="me-1">
                                        <InfoIcon />
                                    </span>
                                    <span>
                                        {" "}
                                        Users can update their Contact Number, Position and Correspondence Email. For changing any other user details please contact{" "}
                                        <EmailLinkView emailAddress="neats.admin@nopta.gov.au" />.
                                    </span>
                                </Alert>
                            </div>
                            <Forms.Row>
                                <Forms.HalfColumn>
                                    <Forms.ReadonlyField id="usernameField" label="Username" value={secureUser.username} />
                                </Forms.HalfColumn>
                                <Forms.HalfColumn>
                                    <Forms.Element label="Email">
                                        <a href={"mailto:" + secureUser.emailAddress}>{secureUser.emailAddress}</a>
                                    </Forms.Element>
                                </Forms.HalfColumn>
                                <Forms.HalfColumn>
                                    <Forms.ReadonlyField id="fullNameField" label="Full Name" value={secureUser.fullName} />
                                </Forms.HalfColumn>
                                <Forms.HalfColumn>
                                    <Forms.TextInput
                                        id="correspondenceEmailAddressField"
                                        label="Correspondence Email"
                                        maxLength={100}
                                        value={state.correspondenceEmailAddress}
                                        valid={v.applyValidity("SecureUser.CorrespondenceEmailAddress")}
                                        onChange={(value) => onChanged({ correspondenceEmailAddress: value })}
                                        onBlur={updateViewModel}
                                    />
                                    <ValidationMessageView name="SecureUser.CorrespondenceEmailAddress" validationModel={v} />
                                </Forms.HalfColumn>
                                <Forms.HalfColumn>
                                    <Forms.TextInput
                                        id="positionField"
                                        label="Position"
                                        maxLength={100}
                                        value={state.position}
                                        valid={v.applyValidity("SecureUser.Position")}
                                        onChange={(value) => onChanged({ position: value })}
                                        onBlur={updateViewModel}
                                    />
                                    <ValidationMessageView name="SecureUser.Position" validationModel={v} />
                                </Forms.HalfColumn>
                                <Forms.HalfColumn>
                                    <Forms.TextInput
                                        id="contactNumberField"
                                        label="Contact Number"
                                        maxLength={100}
                                        value={state.contactNumber}
                                        valid={v.applyValidity("SecureUser.ContactNumber")}
                                        onChange={(value) => onChanged({ contactNumber: value })}
                                        onBlur={updateViewModel}
                                    />
                                    <ValidationMessageView name="SecureUser.ContactNumber" validationModel={v} />
                                </Forms.HalfColumn>
                                <Forms.HalfColumn>
                                    <Forms.ReadonlyField id="lastLoginField" label="Last Login (AWST)" value={GlobalHelpers.toNoptaDatetimeString(secureUser.lastLoginDatetime)} />
                                </Forms.HalfColumn>
                                <Forms.HalfColumn>
                                    <Forms.ReadonlyField id="registrationDateField" label="Registration Date (AWST)" value={GlobalHelpers.toNoptaDatetimeString(secureUser.registrationDatetime)} />
                                </Forms.HalfColumn>
                            </Forms.Row>
                            <div className="mt-2">
                                <button className="btn btn-outline-primary me-2" type="submit" disabled={!vm.isDirty}>
                                    Save
                                </button>
                                <button className="btn btn-outline-secondary" onClick={onCancelClicked}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </DirtyScope>
        </>
    );
}
