// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// views
import NavBar, { TabsEnum } from "../../components/views/NavBarView";
import WellDetailsView from "./views/WellDetailsView";

interface IRouteParameters {
    wellId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { wellId } = params;

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(wellId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {vm.well && (
                <BodyLayout
                    title={`Well - ${vm.well?.name}`}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_WellsBoreholes_Wells}
                    onReloadRequested={() => {
                        props.initialiseRequested(Number(wellId));
                    }}
                >
                    <NavBar wellId={Number(wellId)} activeNavItem={TabsEnum.Details} />
                    <WellDetailsView vm={vm} />
                </BodyLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_WellsBoreholes_WellDetails }), Actions.actionFactory)(Controller);
