// framework
import { useEffect, useState } from "react";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
// common
import { ValidationModel } from "../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
import * as Forms from "../../../../../common/forms/BootstrapForms";

export function CommentsView(props: { vm: Models.IRootViewModel; v: ValidationModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const v = props.v;
    const actions = props.actions;

    const [comments, setComments] = useState(vm.user.comments);

    useEffect(() => {
        setComments(vm.user.comments);
    }, [vm.user.comments]);

    function onChanged(value: string) {
        setComments(value);
    }

    function updateViewModel() {
        actions.viewChanged(vm.refreshCommentsUpdated(comments));
    }

    return (
        <div className="card mb-2">
            <div className="card-header">Comments</div>
            <div className="card-body">
                <p>Please provide any additional comments that will assist with the approval of this request.</p>
                <Forms.Group>
                    <Forms.TextArea
                        id="commentsField"
                        label="Comments"
                        rows={5}
                        maxLength={1000}
                        value={comments}
                        valid={v.applyValidity("Comments.Comments")}
                        onChange={onChanged}
                        onBlur={updateViewModel}
                    />
                    <ValidationMessageView name="Comments.Comments" validationModel={v} />
                </Forms.Group>
            </div>
        </div>
    );
}
