import { Fetch } from "../../api/fetchHelpers";

export interface IClient {
    initialise(container: string, file: File, onProgress: (id: number, uploaded: number, total: number) => void, onError: (message: string) => void): Promise<void>;
    getId(): number;
    upload(): Promise<boolean>;
    cancel(): Promise<void>;
}

export class Client implements IClient {
    private _initialised: boolean = false;
    private _cancelled: boolean = false;
    private _id: number | undefined;
    private _maximumChunkIndex: number | undefined;
    private _firstUploadChunkSize: number | undefined;
    private _file: File | undefined;
    private _correlationId: string;
    private _onProgress: ((id: number, uploaded: number, total: number) => void) | undefined;
    private _onError: ((message: string) => void) | undefined;
    private _fetch: Fetch = new Fetch();

    constructor() {
        this._correlationId = crypto.randomUUID();
    }

    public async initialise(container: string, file: File, onProgress: (id: number, uploaded: number, total: number) => void, onError: (message: string) => void): Promise<void> {
        if (!container) throw new Error();
        if (!file) throw new Error();
        if (!onProgress) throw new Error();
        if (!onError) throw new Error();

        this._assertNotInitialised();

        // register the upload with the api
        const [id, maximumChunkIndex, firstUploadChunkSize] = await this._createUpload(container, file.name, file.size);

        // set as 0%
        onProgress!(id, 0, file.size);

        // assign local state
        this._id = id;
        this._maximumChunkIndex = maximumChunkIndex;
        this._firstUploadChunkSize = firstUploadChunkSize;
        this._file = file;
        this._onProgress = onProgress;
        this._onError = onError;
        this._initialised = true;
    }

    public getId(): number {
        this._assertInitialised();

        return this._id!;
    }

    public async upload(): Promise<boolean> {
        this._assertInitialised();

        if (this._cancelled) return false;

        const id = this._id!;
        const file = this._file!;

        const started = new Date();

        let offset = 0;

        // first chunk
        let [success, nextUploadChunkSize] = await this._uploadInternal(0, offset, this._firstUploadChunkSize!);
        if (!success) return false;
        offset += this._firstUploadChunkSize!;

        // subsequent chunks
        for (let i = 1; i <= this._maximumChunkIndex!; i++) {
            if (this._cancelled) return false;
            const thisUploadChunkSize = nextUploadChunkSize;
            [success, nextUploadChunkSize] = await this._uploadInternal(i, offset, thisUploadChunkSize!);
            if (!success) return false;
            offset += thisUploadChunkSize!;
        }

        // log performance
        const now = new Date();
        const duration = now.valueOf() - started.valueOf();
        const rate = file.size / 1024 / 1024 / (duration / 1000);
        console.debug(`SecureFileUpload/client: completed in ${duration / 1000}s at ${Math.round(rate * 100) / 100}MB/sec`);

        // finalise
        var isFinalised = await this._finaliseUpload(id);
        if (!isFinalised) throw new Error(); // throw an error to show the file as failed in the upload control

        return true;
    }

    private async _uploadInternal(chunkIndex: number, offset: number, chunkSize: number): Promise<[boolean, number | undefined]> {
        const id = this._id!;
        const file = this._file!;
        const onProgress = this._onProgress!;

        // get chunk
        let blob = file.slice(offset, offset + chunkSize);
        let content = await this._getByteArray(blob);

        // check binary contents - useful for debugging to ensure we're downloading the right chunk
        //console.log("last char");
        //console.log([offset, chunkSize]);
        //console.log([content![chunkSize - 6], content![chunkSize - 5], content![chunkSize - 4], content![chunkSize - 3], content![chunkSize - 2], content![chunkSize - 1]]);

        // upload chunk
        const [success, nextUploadChunkSize] = await this._uploadChunk(id, chunkIndex, content!);
        if (!success) return [false, undefined];

        // report progress
        onProgress(id, offset + chunkSize, file.size);
        return [true, nextUploadChunkSize!];
    }

    public async cancel(): Promise<void> {
        this._assertInitialised();

        // note, cancel can be called during initialisation
        // simply mark it as cancelled and the upload will be cancelled when it is ready to do so
        this._cancelled = true;
    }

    private async _createUpload(container: string, fileName: string, fileSize: number): Promise<[number, number, number]> {
        const request = { container, name: fileName, size: fileSize };
        const response = await this._fetch.post("file/secure/upload/create", request, this._correlationId);
        return [response.id, response.maximumChunkIndex, response.firstUploadChunkSize];
    }

    private async _uploadChunk(id: number, chunkIndex: number, content: Uint8Array): Promise<[boolean, number | undefined]> {
        const response = await this._fetch.postBlob(`file/secure/upload/chunk/${id}/${chunkIndex}`, content, this._correlationId);

        const containsMalware = response.containsMalware;
        const nextUploadChunkSize = response.nextUploadChunkSize;

        if (containsMalware) {
            this._reportMalware();
            return [false, undefined];
        }

        return [true, nextUploadChunkSize];
    }

    private async _finaliseUpload(id: number): Promise<boolean> {
        const request = { id };
        const response = await this._fetch.post("file/secure/upload/finalise", request, this._correlationId);

        if (response.containsMalware) {
            this._reportMalware();
            return false;
        } else if (response.isInvalidPackage) {
            this._reportInvalidPackage();
            return false;
        }

        return true;
    }

    private _reportMalware(): void {
        const message = `The file '${this._file?.name}' was not uploaded as it contains malware.`;
        this._onError!(message);
    }

    private _reportInvalidPackage(): void {
        const message = `The package file '${this._file?.name}' was not uploaded as it is invalid; the file may be corrupt.`;
        this._onError!(message);
    }

    private _getByteArray(blob: Blob): Promise<Uint8Array | undefined> {
        // stripping the data-url declaration as per https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onload = () => {
                const bytes = new Uint8Array(reader.result as ArrayBuffer);
                resolve(bytes);
            };
            reader.readAsArrayBuffer(blob);
        });
    }

    private _assertInitialised() {
        if (!this._initialised) throw new Error("Not yet intialised.");
    }

    private _assertNotInitialised() {
        if (this._initialised) throw new Error("Already intialised.");
    }
}
