// api
import * as Client from "../../../../../../api/Client";
// models
import { IRootViewModel } from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import SimpleAuditEventsView from "../../../../../../common/audit/SimpleAuditEventsView";
// views
import ResubmissionDataItemsGrid from "./ResubmissionDataItemsGrid";

interface IProps {
    vm: IRootViewModel;
}

export default function DataSubmissionResubmissionDetailsView(props: IProps): JSX.Element {
    const { vm } = props;

    return (
        <>
            {vm.resubmission && (
                <>
                    <div className="card mb-2">
                        <div className="card-body">
                            <Forms.Row>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="statusField" label="Status" value={Client.DataSubmissionResubmissionStatusEnum[vm.resubmission.status!]} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    {vm.resubmission.status && vm.resubmission.status !== Client.DataSubmissionResubmissionStatusEnum.Preparing && (
                                        <Forms.ReadonlyField id="requestedDateField" label="Requested Date" value={GlobalHelpers.toNoptaDateString(vm.resubmission.requestedDate)} />
                                    )}
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    {vm.resubmission.status && vm.resubmission.status !== Client.DataSubmissionResubmissionStatusEnum.Preparing && (
                                        <Forms.ReadonlyField id="dueDateField" label="Due Date" value={GlobalHelpers.toNoptaDateString(vm.resubmission.dueDate)} />
                                    )}
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    {vm.resubmission.status &&
                                        vm.resubmission.status !== Client.DataSubmissionResubmissionStatusEnum.Preparing &&
                                        vm.resubmission.status !== Client.DataSubmissionResubmissionStatusEnum.Requested && (
                                            <Forms.ReadonlyField id="receivedDateField" label="Received Date" value={GlobalHelpers.toNoptaDateString(vm.resubmission.receivedDate)} />
                                        )}
                                </Forms.QuarterColumn>
                                <Forms.FullColumn>
                                    <Forms.Label htmlFor="dataItemsField">Data Monitoring Items</Forms.Label>
                                    <ResubmissionDataItemsGrid
                                        resubmission={vm.resubmission}
                                        dataSubmissionType={vm.dataSubmissionType}
                                        canViewBoreholes={vm.permissions.canViewBoreholes}
                                        canViewAcquisitions={vm.permissions.canViewAcquisitions}
                                        canViewReprocessingProjects={vm.permissions.canViewReprocessingProjects}
                                    />
                                </Forms.FullColumn>
                                <Forms.FullColumn>
                                    <Forms.ReadonlyHtml id="commentsField" label="Comments" value={vm.resubmission.commentsHtml} />
                                </Forms.FullColumn>
                            </Forms.Row>
                        </div>
                    </div>
                    <SimpleAuditEventsView simpleAuditEvents={vm.audit} />
                </>
            )}
        </>
    );
}
