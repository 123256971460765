// framework
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
import InvitationToApplyListView from "./views/InvitationToApplyListView";
// common
import * as companyRouteHelper from "../../../companyRouteHelper";
import StatusMessagesAlertsView from "../../../../common/alerts/StatusMessagesAlertsView";

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    // on mount
    useEffect(() => {
        props.initialiseRequested();
        return function () {
            props.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // - redirect on successful create
    const id = vm.service.draftApplicationId;
    const isSuccess = vm.service.statusMessages?.isSuccess ?? false;
    if (id && isSuccess) {
        return <Redirect to={companyRouteHelper.draftApplicationDetails(id.draftApplicationId, id.draftApplicationType)} />;
    }

    return (
        <BodyLayout
            title="Invitation To Apply"
            selectedLhsNavItem={Level1ItemTypeEnum.Company_Oei_InvitationToApplyList}
            onReloadRequested={() => {
                props.initialiseRequested();
            }}
        >
            <StatusMessagesAlertsView statusMessages={vm.service.statusMessages} />
            <p>This list shows any Invitation to Apply that is currently open.</p>
            <InvitationToApplyListView vm={vm} actions={actions} />
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_Oei_OeiInvitationToApplyList }), Actions.actionFactory)(Controller);
