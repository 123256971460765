// framework
import { useState } from "react";
import { Link } from "react-router-dom";
// api
import { GetCompanyUserManagementCompanyListItemDto } from "../../../../api/Client";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// other
import * as companyRouteHelper from "../../../companyRouteHelper";
import { createGridColumnFilterMenu } from "../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
import CustomGridCell from "../../../../common/kendoGrid/CustomGridCell";

export default function CompanyListView(props: { companies: Array<GetCompanyUserManagementCompanyListItemDto> }): React.ReactElement {
    const initialGridState: State = {
        sort: [{ field: "companyName", dir: "asc" }],
        skip: 0,
        take: 10,
    };
    const [gridState, setGridState] = useState(initialGridState);

    // view
    return (
        <>
            {/* results grid */}
            <Grid
                pageable
                sortable
                resizable
                navigatable
                data={process(props.companies, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn
                    field="companyName"
                    title="Company Name"
                    width={400}
                    filter="text"
                    columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.companies)}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <Link to={companyRouteHelper.companyDetails(props.dataItem.companyId)}>{props.dataItem.companyName}</Link>
                        </CustomGridCell>
                    )}
                />
                <GridColumn field="abn" title="ABN" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.companies)} />
                <GridColumn field="acnOrArbn" title="ACN / ARBN" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.companies)} />
                <GridColumn field="companyAdministratorCount" title="No. of Administrators" width={200} filter="numeric" columnMenu={createGridColumnFilterMenu} />
            </Grid>
        </>
    );
}
