// framework
import { useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../infrastructure/reduxRootReducer";
import * as Actions from "../redux/actions";
import * as Models from "../models/models";
// api
import { ExternalLinkEnum } from "../../../../api/Client";
// shell
import BodyLayout from "../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
// views
import TitleDetailsView from "./views/TitleDetailsView";
import TitleDetailsConfiguration from "./TitleDetailsConfiguration";
import { TitleDetailsTabsEnum } from "./views/TitleDetailsView";
// common
import * as companyRouteHelper from "../../../companyRouteHelper";
import * as documentHelper from "../../../../common/documentHelper";
import * as ConfigurationHelper from "../../../../common/shell/configurationHelper";
import StatusMessagesAlertsView from "../../../../common/alerts/StatusMessagesAlertsView";

interface IControllerProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

interface IRouteParameters {
    id: string;
    tab?: string;
}

function TitleDetailsController(props: IControllerProps) {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;
    const params = useParams<IRouteParameters>();
    const titleId = params.id;
    let defaultTab = TitleDetailsTabsEnum.Summary;
    if (params.tab) {
        defaultTab = parseInt(params.tab) as TitleDetailsTabsEnum;
    }

    // on mount
    // - initial title load
    useEffect(() => {
        actions.initialiseRequested(titleId);
        return function () {
            actions.clearRequested();
        };
    }, [titleId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Configuration
    const configuration = new TitleDetailsConfiguration(vm.details?.titleType, vm.details?.canViewLegislativeForms);

    // Application document
    const applicationDocumentResponse = vm.applicationDocument;
    // - download when response received
    useEffect(() => {
        documentHelper.tryDownloadDocument(applicationDocumentResponse);
        return function () {
            actions.clearApplicationDocumentRequested();
        };
    }, [applicationDocumentResponse]); // eslint-disable-line react-hooks/exhaustive-deps

    // Legislative Form document
    const legislativeFormDocumentResponse = vm.legislativeFormDocument;
    // - download when response received
    useEffect(() => {
        documentHelper.tryDownloadDocument(legislativeFormDocumentResponse);
        return function () {
            actions.clearLegislativeFormDocumentRequested();
        };
    }, [legislativeFormDocumentResponse]); // eslint-disable-line react-hooks/exhaustive-deps

    const title = vm.details ? vm.details.titleTypeName + " " + vm.details.titleNumber : "Title Details";

    // Create Application
    // - NOPTA forms guide
    const applicationGuideLink = ConfigurationHelper.usePortalLink(ExternalLinkEnum.NoptaFormsGuidancePetroleum);
    // - redirect on successful create
    const id = vm.service.draftApplicationId;
    const isSuccess = vm.service.statusMessages?.isSuccess ?? false;
    if (id && isSuccess) {
        return <Redirect to={companyRouteHelper.draftApplicationDetails(id.draftApplicationId, id.draftApplicationType)} />;
    }

    // return
    return (
        <BodyLayout
            title={title}
            selectedLhsNavItem={Level1ItemTypeEnum.Company_Opggs_TitlesList}
            onReloadRequested={() => {
                actions.initialiseRequested(titleId);
            }}
        >
            <StatusMessagesAlertsView statusMessages={vm.service.statusMessages} />
            <TitleDetailsView vm={vm} actions={actions} preferredTab={defaultTab} configuration={configuration} applicationGuideLink={applicationGuideLink} />
        </BodyLayout>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.Company_Opggs_TitleDetails }), Actions.actionFactory)(TitleDetailsController);
