// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { ICompanyReduxState } from "../../../../companyReduxRegistration";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as shellHelper from "../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../common/LogHelper";

const getRootViewModel = (state: ICompanyReduxState) => state.Company_Opggs_TitleDetails;

export default function* initialise(action: Actions.IAction<string>) {
    const titleId = action.payload;

    // save the Title Id to the VM
    let vm: Models.IRootViewModel = yield select(getRootViewModel);
    vm = vm.refreshTitle(titleId);
    yield put(Actions.actionFactory.sagaCompleted(vm));

    // load details
    try {
        yield put(shellHelper.createSetBusyAction());

        const result: CallHelper.CallResult<Client.GetCompanyOpggsTitleDetailsResponseDto> = yield call(async () => {
            const client = await ClientFactory.createCompanyOpggsTitleDetailsClient();
            return await CallHelper.call(() => client.getCompanyOpggsTitleDetails(new Client.GetCompanyOpggsTitleDetailsRequestDto({ titleId: vm.titleId! })));
        });

        if (!result.IsSuccess) {
            result.ShowToastNotification();
        } else {
            // refresh vm
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshDetails(result.Response!)));
            // start loading map
            yield put(Actions.actionFactory.getMapRequested(titleId));
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
