// redux
import { call, put, select } from "redux-saga/effects";
import { IDraftApplicationDetailsReduxState } from "../../../../../draftApplicationDetails/draftApplicationDetailsReduxRegistration";
import * as Actions from "../actions";
import * as Models from "../../models/models";
// api
import * as CallHelper from "../../../../../../../api/callHelper";
import * as Client from "../../../../../../../api/Client";
// shell
import * as ShellActions from "../../../../../../../shell/layout/redux/actions";
// common
import * as LogHelper from "../../../../../../../common/LogHelper";
// calls
import { getCompanyCommonDraftApplicationDetailsSupportingDocumentation, saveCompanyCommonDraftApplicationDetailsSupportingDocumentation } from "./calls";

const getRootViewModel = (state: IDraftApplicationDetailsReduxState) => state.Company_Common_DraftApplicationDetails_Controls_SupportingDocumentation;

export default function* save() {
    let vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(ShellActions.actionFactory.setBusy());

        // save
        const saveResult: CallHelper.CallResult<Client.SaveCompanyCommonDraftApplicationDetailsSupportingDocumentationResponseDto> = yield call(async () => {
            return await saveCompanyCommonDraftApplicationDetailsSupportingDocumentation(vm);
        });

        if (saveResult.IsSuccess) {
            // success - refresh
            const r = saveResult.Response!;
            vm = vm.refreshSaveStatusMessages(r.statusMessages);
            if (r.statusMessages.isSuccess) {
                const detailsResponse: Client.GetCompanyCommonDraftApplicationDetailsSupportingDocumentationResponseDto = yield call(async () => {
                    return await getCompanyCommonDraftApplicationDetailsSupportingDocumentation(vm.id!);
                });
                vm = vm.refreshDetails(vm.id!, detailsResponse);
                vm = vm.onSaved();
            }
            yield put(Actions.actionFactory.sagaCompleted(vm));
        } else if (saveResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            saveResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(ShellActions.actionFactory.clearBusy());
    }
}
