// api
import * as Client from "../../../../api/Client";
// models
import { BaseRoleViewModel, IRoleViewModel } from "../../../../common/roleGrid/RoleViewModel";
import { IJointAuthorityRoleInformationViewModel } from "../../common/permissionCellHelper";

export interface IJointAuthorityDetailsRoleViewModel extends IRoleViewModel, IJointAuthorityRoleInformationViewModel {
    secureUserId: number;
    secureUserVersionNumber: number;
    username?: string | undefined;
    fullName?: string | undefined;
    isSecureUserSignedIn: boolean;
    lastUpdatedBy?: string | undefined;
    lastUpdatedDatetime?: Date | undefined;
}

export class JointAuthorityDetailsRoleViewModel extends BaseRoleViewModel implements IJointAuthorityDetailsRoleViewModel {
    constructor(roleRelationships: Client.GetShellSecureUserRoleConfigurationRoleRelationshipDto[], data: Client.IJointAuthoritySecureUserDetailsDto) {
        super(roleRelationships, data.activeRoles);

        this.secureUserId = data.secureUserId;
        this.secureUserVersionNumber = data.secureUserVersionNumber;
        this.username = data.username;
        this.fullName = data.fullName;
        this.isSecureUserBlocked = data.isSecureUserBlocked;
        this.isSecureUserSignedIn = data.isSecureUserSignedIn;
        this.hasPendingRequest = data.hasPendingRequest;
        this.lastUpdatedBy = data.lastUpdatedBy;
        this.lastUpdatedDatetime = data.lastUpdatedDatetime;
    }

    public readonly secureUserId: number;
    public readonly secureUserVersionNumber: number;
    public readonly username?: string | undefined;
    public readonly fullName?: string | undefined;
    public readonly isSecureUserBlocked: boolean;
    public readonly isSecureUserSignedIn: boolean;
    public readonly hasPendingRequest: boolean;
    public readonly lastUpdatedBy?: string | undefined;
    public readonly lastUpdatedDatetime?: Date | undefined;

    public getKey(): number {
        return this.secureUserId;
    }

    public canEdit(): boolean {
        return !(this.isSecureUserSignedIn || this.hasPendingRequest);
    }
}
