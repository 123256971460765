import * as Client from "../../../../api/Client";
import { clone } from "lodash";

export interface IRootViewModel {
    users: Client.IJointAuthoritySecureUserDetailsDto[];

    refreshSaga(response: Client.GetJointAuthorityUserManagementUserListResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.users = [];
    }

    public users: Client.IJointAuthoritySecureUserDetailsDto[];

    public refreshSaga(response: Client.GetJointAuthorityUserManagementUserListResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.users = response.secureUsers;
        return vm;
    }

    // private implementation
    // ----------------------
    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
