// redux
import * as Models from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as companyRouteHelper from "../../../../../companyRouteHelper";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import { TitleTypeEnum } from "../../../../../../api/Client";
import IappDetailsEpReadonlyView from "./IappDetailsEpReadonlyView";
import IappDetailsRlReadonlyView from "./IappDetailsRlReadonlyView";

export default function IappDetailsReadonlyView(props: { vm: Models.IIappDetailsViewModel }) {
    const vm = props.vm;

    return (
        <>
            <Forms.Group>
                <Forms.Link label="Originating Title" linkText={vm.titleNumber} linkTo={vm.titleId && companyRouteHelper.titleDetails(vm.titleId)} />
            </Forms.Group>
            <Forms.Group>
                <Forms.ReadonlyField label="Expiry Date" id="expiryDateField" value={GlobalHelpers.toNoptaDateString(vm.titleExpiryDate) || "Life-of-Field"} />
            </Forms.Group>
            {vm.originatingTitleType === TitleTypeEnum.ExplorationPermit && <IappDetailsEpReadonlyView vm={vm.epDetails!} />}
            {vm.originatingTitleType === TitleTypeEnum.RetentionLease && <IappDetailsRlReadonlyView vm={vm.rlDetails!} />}
        </>
    );
}
