// framework
import * as Validation from "../../../../../../common/validation/ValidationModel";
// models
import * as Models from "./models";

export default function validate(vm: Models.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateFiles(vm, v);
    validateSuppliedOfflineDetails(vm, v);
    return v;
}

function validateFiles(vm: Models.IRootViewModel, v: Validation.ValidationModel): void {
    // Only validate if sensitivity is applicable
    if (!vm.canSetSensitivity) return;

    // Only validate files the user can manage - we can't validate all files as the list can include items that are deleted or sensitive to other partner companies
    const filesToValidate = vm.files.filter((f) => f.canManage && f.state !== Models.FileStateEnum.Deleted);
    if (filesToValidate.length === 0) return;

    let hasValidationErrors = false;

    filesToValidate.forEach((f) => {
        const errorName = `SupportingDocumentationFiles.File${f.fileId}`;
        if (!f.sensitiveToCompany) {
            v.addError(errorName, "A selection is required.");
            hasValidationErrors = true;
        } else if (!vm.sensitiveToCompanies.some((c) => c.companyId === f.sensitiveToCompany!.companyId)) {
            v.addError(errorName, "The selection is invalid.");
            hasValidationErrors = true;
        }
    });

    if (hasValidationErrors) v.addError("SupportingDocumentationFiles.AllFiles", "Please check all pages to ensure Sensitivity is provided for all uploaded files.");
}

function validateSuppliedOfflineDetails(vm: Models.IRootViewModel, v: Validation.ValidationModel): void {
    // Only applies to applications that allow for offline supporting documentation
    if (!vm.canSupplyOffline) return;

    const soDetails = vm.suppliedOfflineDetails;

    // Only applies if offine supporting documentation is provided
    if (!soDetails.isSuppliedOffline) return;

    if (soDetails.comments && soDetails.comments.length > 1000) v.addError("SuppliedOfflineDetails.Comments", "Additional information must be 1000 characters or less.");
    if (!soDetails.selectedCompanies || soDetails.selectedCompanies.length < 1) v.addError("SuppliedOfflineDetails.CompanyIds", "At least one Company is required.");
    else {
        const invalidCompanies = soDetails.selectedCompanies.filter((c) => !vm.partnerCompanies.some((pc) => pc.companyId === c.companyId));

        if (invalidCompanies.length === 0) return;
        v.addError("SuppliedOfflineDetails.CompanyIds", "The following Companies are invalid: " + invalidCompanies.map((c) => c.companyName).join(", "));
    }
}
