// redux
import * as Models from "../../models/models";
// common
import SimpleAlertView from "../../../../../../../common/simpleAlerts/SimpleAlertView";

export default function InformationView(props: { vm: Models.IRootViewModel }): React.ReactElement {
    const vm = props.vm;

    // using an information view in lieu of having information / warnings capability built into our validation framework

    const showNonApplicantNomineeTitleholderInformation = vm.viewState === Models.ViewStateEnum.Edit && vm.isActive === true && vm.isApplicant === false;

    return showNonApplicantNomineeTitleholderInformation ? (
        <SimpleAlertView alertType="information" dismissable={false} message="The active EVA (Form 6) may not be used for this Application, as the Applicant is not the Nominated Titleholder." />
    ) : (
        <></>
    );
}
