import * as Models from "../models/models";

// -------
// Actions
// -------

export enum actions {
    trySilentSignInRequested = "shell.signIn.trySilentSignInRequested",
    signInExpired = "shell.signIn.signInExpired",
    signInRequested = "shell.signIn.signInRequested",
    signOutRequested = "shell.signIn.signOutRequested",
    resetPasswordRequested = "shell.signIn.resetPasswordRequested",
    sagaCompleted = "shell.signIn.sagaCompleted",
}

// --------
// Payloads
// --------

export interface IAction<T> {
    type: actions;
    payload: T;
}

// ---------
// Factories
// ---------

export interface IActionFactory {
    trySilentSignInRequestedAction(): IAction<undefined>;
    signInExpiredAction(): IAction<undefined>;
    signInRequestedAction(): IAction<undefined>;
    signOutRequestedAction(): IAction<undefined>;
    resetPasswordRequestedAction(): IAction<undefined>;
    sagaCompletedAction(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    trySilentSignInRequestedAction: function (): IAction<undefined> {
        return {
            type: actions.trySilentSignInRequested,
            payload: undefined,
        };
    },
    signInExpiredAction: function (): IAction<undefined> {
        return {
            type: actions.signInExpired,
            payload: undefined,
        };
    },
    signInRequestedAction: function (): IAction<undefined> {
        return {
            type: actions.signInRequested,
            payload: undefined,
        };
    },
    signOutRequestedAction: function (): IAction<undefined> {
        return {
            type: actions.signOutRequested,
            payload: undefined,
        };
    },
    resetPasswordRequestedAction: function (): IAction<undefined> {
        return {
            type: actions.resetPasswordRequested,
            payload: undefined,
        };
    },
    sagaCompletedAction: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
