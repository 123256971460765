// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../api/Client";
// common
import { IWorkProgramViewModel, WorkProgramViewModel } from "../../../../common/workProgram/models";

export interface IDraftApplicationDetailsViewModel {
    applicant?: Client.GetTitleReferenceDataDraftApplicationApplicantDto;
    applicationType?: Client.GetTitleReferenceDataDraftApplicationTypeDto;
}

export interface IRootViewModel {
    titleId?: string | undefined;
    details?: Client.GetCompanyOpggsTitleDetailsResponseDto | undefined;
    workProgram: IWorkProgramViewModel | undefined;
    map?: Client.GetCompanyOpggsTitleDetailsMapResponseDto | undefined;
    applicationDocument?: Client.SecurePortalDocumentDto | undefined;
    legislativeFormDocument?: Client.SecurePortalDocumentDto | undefined;
    createDraftApplicationReferenceData?: Client.GetCompanyOpggsCreateDraftApplicationReferenceDataResponseDto | undefined;
    draftApplicationDetails: IDraftApplicationDetailsViewModel;

    service: {
        statusMessages?: Client.StatusMessagesDto;
        draftApplicationId?: Client.DraftApplicationIdDto | undefined;
    };

    refreshTitle(titleId: string): IRootViewModel;
    refreshDetails(response: Client.GetCompanyOpggsTitleDetailsResponseDto): IRootViewModel;
    refreshMap(response: Client.GetCompanyOpggsTitleDetailsMapResponseDto): IRootViewModel;
    refreshApplicationDocument(response: Client.SecurePortalDocumentDto | undefined): IRootViewModel;
    refreshLegislativeFormDocument(response: Client.SecurePortalDocumentDto | undefined): IRootViewModel;
    refreshCreateDraftApplicationReferenceData(response: Client.GetCompanyOpggsCreateDraftApplicationReferenceDataResponseDto | undefined): IRootViewModel;
    refreshDraftApplicationDetailsUpdated(values: IDraftApplicationDetailsViewModel): IRootViewModel;
    refreshCreateDraftApplicationResponse(statusMessages: Client.StatusMessagesDto, id: Client.DraftApplicationIdDto | undefined): IRootViewModel;
    clearApplicationDocument(): IRootViewModel;
    clearLegislativeFormDocument(): IRootViewModel;
    clearDraftApplicationData(): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.titleId = undefined;
        this.details = undefined;
        this.workProgram = undefined;
        this.map = undefined;
        this.applicationDocument = undefined;
        this.legislativeFormDocument = undefined;
        this.createDraftApplicationReferenceData = undefined;
        this.draftApplicationDetails = {};
        this.service = {
            statusMessages: undefined,
            draftApplicationId: undefined,
        };
    }

    public titleId?: string | undefined;
    public details?: Client.GetCompanyOpggsTitleDetailsResponseDto | undefined;
    public workProgram: IWorkProgramViewModel | undefined;
    public map?: Client.GetCompanyOpggsTitleDetailsMapResponseDto | undefined;
    public applicationDocument?: Client.SecurePortalDocumentDto | undefined;
    public legislativeFormDocument?: Client.SecurePortalDocumentDto | undefined;
    public createDraftApplicationReferenceData?: Client.GetCompanyOpggsCreateDraftApplicationReferenceDataResponseDto | undefined;
    public draftApplicationDetails: IDraftApplicationDetailsViewModel;

    public service: {
        statusMessages?: Client.StatusMessagesDto;
        draftApplicationId?: Client.DraftApplicationIdDto | undefined;
    };

    public refreshTitle(titleId: string): IRootViewModel {
        const vm = this._clone();
        vm.titleId = titleId;
        return vm;
    }

    public refreshDetails(response: Client.GetCompanyOpggsTitleDetailsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.details = response;

        // TODO split the rest of the title details into vms? Or at least the constituent dtos?
        if (response.workProgram) {
            vm.workProgram = WorkProgramViewModel.fromTitleDetailsDto(response.workProgram);
        }
        return vm;
    }

    public refreshMap(response: Client.GetCompanyOpggsTitleDetailsMapResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.map = response;
        return vm;
    }

    public refreshApplicationDocument(response: Client.SecurePortalDocumentDto): IRootViewModel {
        const vm = this._clone();
        vm.applicationDocument = response;
        return vm;
    }

    public refreshLegislativeFormDocument(response: Client.SecurePortalDocumentDto): IRootViewModel {
        const vm = this._clone();
        vm.legislativeFormDocument = response;
        return vm;
    }

    public refreshCreateDraftApplicationReferenceData(response: Client.GetCompanyOpggsCreateDraftApplicationReferenceDataResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.createDraftApplicationReferenceData = response;
        return vm;
    }

    refreshDraftApplicationDetailsUpdated(values: IDraftApplicationDetailsViewModel): IRootViewModel {
        const vm = this._clone();
        vm.draftApplicationDetails = values;
        return vm;
    }

    refreshCreateDraftApplicationResponse(statusMessages: Client.StatusMessagesDto, id: Client.DraftApplicationIdDto | undefined): IRootViewModel {
        const vm = this._clone();
        vm.service.draftApplicationId = id;
        vm.service.statusMessages = statusMessages;
        return vm;
    }

    public clearApplicationDocument(): IRootViewModel {
        const vm = this._clone();
        vm.applicationDocument = undefined;
        return vm;
    }

    public clearLegislativeFormDocument(): IRootViewModel {
        const vm = this._clone();
        vm.legislativeFormDocument = undefined;
        return vm;
    }

    public clearDraftApplicationData(): IRootViewModel {
        const vm = this._clone();
        vm.createDraftApplicationReferenceData = undefined;
        vm.draftApplicationDetails.applicant = undefined;
        vm.draftApplicationDetails.applicationType = undefined;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.createDraftApplicationReferenceData = clone(this.createDraftApplicationReferenceData);
        vm.draftApplicationDetails = clone(this.draftApplicationDetails);
        vm.service = clone(this.service);
        return vm;
    }
}
