// framework
import * as Validation from "../../../../../common/validation/ValidationModel";
// models
import * as Models from "./models";

export default function validate(vm: Models.ICoCnApplicationDetailsViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    validateCoCnDetails(vm.coCnDetails, v);
    return v;
}

function validateCoCnDetails(vm: Models.ICoCnDetailsViewModel, v: Validation.ValidationModel): void {
    if (vm.isAcnOrArbnRequired === true) {
        if (Validation.isNullOrWhiteSpace(vm.acnOrArbn)) {
            v.addError("CoCnDetails.AcnOrArbn", "ACN/ARBN is required.");
        } else if (!Validation.isValidAcnOrArbn(vm.acnOrArbn)) {
            v.addError("CoCnDetails.AcnOrArbn", "ACN/ARBN is not valid.");
        }
    }

    if (Validation.isNullOrWhiteSpace(vm.newCompanyName)) {
        v.addError("CoCnDetails.NewCompanyName", "New Company Name is required.");
    } else if (vm.newCompanyName!.length > 100) {
        v.addError("CoCnDetails.NewCompanyName", "New Company Name must be 100 characters or less.");
    }
}
