// kendo
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
// redux
import * as Models from "../../models/models";
// api
import { GetCompanyOpggsDraftApplicationDetailsSupportingDocumentationSensitiveToCompanyDto as SensitiveToCompanyDto } from "../../../../../../../api/Client";
// common
import CustomGridCell from "../../../../../../../common/kendoGrid/CustomGridCell";
import { IValidationModel } from "../../../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../../../common/validation/ValidationMessageView";

export default function SensitivityCell(gridCellProps: GridCellProps, data: Array<SensitiveToCompanyDto>, viewState: Models.ViewStateEnum, v: IValidationModel) {
    const { field, dataItem, dataIndex, onChange } = gridCellProps;

    const handleChange = (e: DropDownListChangeEvent) => {
        if (onChange) {
            onChange({
                dataIndex: dataIndex,
                dataItem: dataItem,
                syntheticEvent: e.syntheticEvent,
                field: field,
                value: e.value,
            });
        }
    };

    const f = dataItem as Models.IFileViewModel;
    const canEdit = viewState === Models.ViewStateEnum.Edit && f.canManage;
    const errorName = `SupportingDocumentationFiles.File${f.fileId}`;

    return (
        <CustomGridCell gridCellProps={gridCellProps}>
            {canEdit ? (
                <>
                    <DropDownList valid={v.applyValidityForKendo(errorName)} data={data} value={f.sensitiveToCompany} dataItemKey="companyId" textField="editName" onChange={handleChange} />
                    <ValidationMessageView name={errorName} validationModel={v} />
                </>
            ) : (
                f.sensitiveToCompany?.displayName
            )}
        </CustomGridCell>
    );
}
