// framework
import { ReactElement, useEffect, useState } from "react";
// redux
import { IRoleViewModel } from "./RoleViewModel";
// kendo
import { Grid, GridProps, GridColumn, GridHeaderSelectionChangeEvent, GridItemChangeEvent, GridSelectionChangeEvent, GridColumnProps } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// api
import * as Client from "../../api/Client";
// other
import * as CellHelper from "./RoleCellHelper";

export enum RoleGridModeEnum {
    Readonly, // default, grid is view only
    Sortable, // readonly and sortable
    Selectable, // distinct roles are not editable but rows are selectable
    Editable, // rows are not selectable but distinct roles are editable
}

/*
    SUMMARY:    
    - Uses a Kendo grid to render roles where rows represent secure user role membership and columns represent distinct roles.
    - To be used wherever roles are displayed or modified in the system in order to centralise the complex requirement of role inheritance.
    - The control will return the same type of model passed to it, but the model used must extend IRoleViewModel / BaseRoleViewModel.
    - The roles displayed and the inheritance relationships between them are managed dynamically based on configuration data passed to the Grid and ViewModels respectively.
    LIMITATIONS:
    - The Grid and ViewModels are tightly coupled. The ViewModel is responsible for calculating inheritance concerns but relies on the Grid cell template to display and deny / allow role modifications accordingly.
    - The Grid and ViewModels cannot operate without role group and relationship reference data. Awareness of inheritance is required before and after ViewModels are displayed,
    and as a result the onus is on the caller to load role configuration. The consuming RootViewModel must extend IRoleRootViewModel.
    - Modes of operation are mutually exclusive. If the grid is editable then it is not selectable or sortable, etc.
    - Extra information can be displayed before and after role columns by passing arrays of GridColumn definitions via props preColumns and postColumns respectively.
    The React documentation recommends this approach (over just using children) if children need to be treated differently. https://reactjs.org/docs/composition-vs-inheritance.html#containment
    Because we are passing arrays of GridColumns the caller needs to add a key to the GridColumn - generally the field can be used but the onus is on the caller.
    - Role columns will be shown in the exact same manner everywhere. Additional requirements to customise the appearance of the role columns will add to the complexity
    of this control or neccessitate the creation of a separate control, which ultimately defeats the purpose of the design.
*/
export default function RoleGrid<T extends IRoleViewModel>(props: {
    model: Array<T>;
    roleGroups: Array<Client.GetShellSecureUserRoleConfigurationRoleGroupDto> | undefined;
    mode?: RoleGridModeEnum | undefined;
    showAdmin?: boolean | undefined;
    showSigner?: boolean | undefined;
    canRemoveSpecialRoles?: boolean | undefined;
    preColumns?: Array<ReactElement<GridColumnProps>> | ReactElement<GridColumnProps> | undefined;
    postColumns?: Array<ReactElement<GridColumnProps>> | ReactElement<GridColumnProps> | undefined;
    onRoleChange?: (updatedRole: T) => void;
    onRolesChange?: (updatedRoles: Array<T>) => void;
}): React.ReactElement {
    const showAdmin = props.showAdmin ?? false;
    const showSigner = props.showSigner ?? false;
    const mode = props.mode ?? RoleGridModeEnum.Readonly;
    const isReadonly = mode !== RoleGridModeEnum.Editable;
    const canRemoveSpecialRoles = props.canRemoveSpecialRoles ?? false;

    const [roleColumns, setRoleColumns] = useState(CellHelper.getRoleColumns(props.roleGroups, showAdmin, showSigner, isReadonly, canRemoveSpecialRoles));
    // - update if column parameters change
    useEffect(() => {
        setRoleColumns(CellHelper.getRoleColumns(props.roleGroups, showAdmin, showSigner, isReadonly, canRemoveSpecialRoles));
    }, [props.roleGroups, showAdmin, showSigner, isReadonly, canRemoveSpecialRoles]);

    // grid state for sorting
    const [gridState, setGridState] = useState<State>({});

    // role state
    const [gridRoles, setGridRoles] = useState<Array<T>>(props.model);
    // - update if parent model changes
    useEffect(() => {
        setGridRoles(props.model);
    }, [props.model]);

    // selectable
    // - select one
    function onSelectionChange(e: GridSelectionChangeEvent): void {
        const gridItem = e.dataItem as T;
        if (!gridItem) return;

        const updatedRole = gridItem.setSelected(!gridItem.isSelected);
        const updatedRoles = gridRoles.map((r) => (r.getKey() === gridItem.getKey() ? updatedRole : r));
        setGridRoles(updatedRoles);

        props.onRoleChange?.(updatedRole);
        props.onRolesChange?.(updatedRoles);
    }

    // - select all
    function onHeaderSelectionChange(e: GridHeaderSelectionChangeEvent): void {
        const headerCheckbox = e.syntheticEvent.target as HTMLInputElement;

        const updatedRoles = gridRoles.map((r) => r.setSelected(headerCheckbox.checked));
        setGridRoles(updatedRoles);

        props.onRolesChange?.(updatedRoles);
    }

    // editable
    function onGridItemChange(e: GridItemChangeEvent): void {
        const gridItem = e.dataItem as T;
        if (!gridItem) return;

        // note not putting any type checks in here because it's not called externally i.e. for this to not be SecureRoleEnum | undefined someone would have to mess with RoleCellHelper.
        const role = e.value as Client.SecureRoleEnum | undefined;
        if (!role) return;

        const updatedRole = gridItem.updateRole(role);
        const updatedRoles = gridRoles.map((r) => (r.getKey() === gridItem.getKey() ? updatedRole : r));
        setGridRoles(updatedRoles);

        props.onRoleChange?.(updatedRole);
        props.onRolesChange?.(updatedRoles);
    }

    // get grid props applicable to the requested mode of operation only
    function getGridProps(mode: RoleGridModeEnum): GridProps {
        const baseProps: GridProps = { resizable: true, navigatable: true };

        switch (mode) {
            case RoleGridModeEnum.Readonly:
                return { ...baseProps, data: gridRoles };
            case RoleGridModeEnum.Sortable:
                return {
                    ...baseProps,
                    sortable: true,
                    data: process(gridRoles, gridState),
                    ...gridState,
                    onDataStateChange: (e) => {
                        setGridState(e.dataState);
                    },
                };
            case RoleGridModeEnum.Selectable:
                return { ...baseProps, data: gridRoles, selectedField: "isSelected", onSelectionChange: onSelectionChange, onHeaderSelectionChange: onHeaderSelectionChange };
            case RoleGridModeEnum.Editable:
                return { ...baseProps, data: gridRoles, onItemChange: onGridItemChange };
            default:
                throw new Error("Invalid Role Grid mode.");
        }
    }

    return (
        <Grid {...getGridProps(mode)}>
            {mode === RoleGridModeEnum.Selectable && <GridColumn field="isSelected" title="Select" width={100} headerSelectionValue={gridRoles.length > 0 && gridRoles.every((r) => r.isSelected)} />}
            {props.preColumns}
            {roleColumns}
            {props.postColumns}
        </Grid>
    );
}
