// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    surveys: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyListResponseItemDto[];
    isResultSetLimited: boolean;
    searchText: string;

    refreshList(response: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyListResponseDto): IRootViewModel;
    changeSearchText(value: string): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.surveys = [];
        this.isResultSetLimited = false;
        this.searchText = "";
    }

    public surveys: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyListResponseItemDto[];
    public isResultSetLimited: boolean;
    public searchText: string;

    public refreshList(response: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyListResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.surveys = response.surveys;
        vm.isResultSetLimited = response.isResultSetLimited;
        return vm;
    }

    public changeSearchText(value: string): IRootViewModel {
        const vm = this._clone();
        vm.searchText = value;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        return vm;
    }
}
