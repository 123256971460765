import * as Models from "../models/models";

// -------
// Actions
// -------
export enum actions {
    // lifecycle events
    initialiseRequested = "geoscienceAustralia.reportsAndData.surveyDataAttachments.initialiseRequested",
    createRequested = "geoscienceAustralia.reportsAndData.surveyDataAttachments.createRequested",
    updateRequested = "geoscienceAustralia.reportsAndData.surveyDataAttachments.updateRequested",
    deleteRequested = "geoscienceAustralia.reportsAndData.surveyDataAttachments.deleteRequested",
    viewChanged = "geoscienceAustralia.reportsAndData.surveyDataAttachments.viewChanged",
    clearRequested = "geoscienceAustralia.reportsAndData.surveyDataAttachments.clearRequested",
    // saga events
    sagaCompleted = "geoscienceAustralia.reportsAndData.surveyDataAttachments.sagaCompleted",
}

// --------
// Payloads
// --------
export interface IAction<T> {
    type: actions;
    payload: T;
}

export interface IInitialiseRequestedPayload {
    surveyDataId: number;
}

export interface IDeleteRequestedPayload {
    surveyDataId: number;
    surveyDataVersionNumber: number;
    surveyDataAttachmentId: number;
}

// ---------
// Factories
// ---------
export interface IActionFactory {
    // lifecycle events
    initialiseRequested(surveyDataId: number): IAction<IInitialiseRequestedPayload>;
    addRequested(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    updateRequested(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    deleteRequested(surveyDataId: number, surveyDataVersionNumber: number, surveyDataAttachmentId: number): IAction<IDeleteRequestedPayload>;
    viewChanged(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
    clearRequested(): IAction<undefined>;
    // saga events
    sagaCompleted(vm: Models.IRootViewModel): IAction<Models.IRootViewModel>;
}

export const actionFactory: IActionFactory = {
    initialiseRequested: function (surveyDataId: number): IAction<IInitialiseRequestedPayload> {
        return {
            type: actions.initialiseRequested,
            payload: { surveyDataId: surveyDataId },
        };
    },
    addRequested: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.createRequested,
            payload: vm,
        };
    },
    updateRequested: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.updateRequested,
            payload: vm,
        };
    },
    deleteRequested: function (surveyDataId: number, surveyDataVersionNumber: number, surveyDataAttachmentId: number): IAction<IDeleteRequestedPayload> {
        return {
            type: actions.deleteRequested,
            payload: { surveyDataId: surveyDataId, surveyDataVersionNumber: surveyDataVersionNumber, surveyDataAttachmentId: surveyDataAttachmentId },
        };
    },
    viewChanged: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.viewChanged,
            payload: vm,
        };
    },
    clearRequested: function (): IAction<undefined> {
        return {
            type: actions.clearRequested,
            payload: undefined,
        };
    },
    sagaCompleted: function (vm: Models.IRootViewModel): IAction<Models.IRootViewModel> {
        return {
            type: actions.sagaCompleted,
            payload: vm,
        };
    },
};
